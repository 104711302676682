var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("EVELUASI KONDUK / "), _c('i', [_vm._v("CONDUCT EVALUATION")])])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Crew Information")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "10"
          }
        }, [_c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Crew")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("No KTP")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("3174050507930002")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Tempat/Tanggal Lahir")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Jakarta / 05 Juli 1993")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("No NPWP")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("4151 2010 0036")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Jabatan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Master")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("ID Crew")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("TCP202138001")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Perusahaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Transcoal Pacific")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Seaferer Code")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("33273 99328 01")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kapal")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("TB. TCP 201")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Seaman Book")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("3275 23366 2177")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Pelabuhan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Bengalon")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("No Passport")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("32761 121133 01")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Sign On")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("15 Desember 2021")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nomor Tlp / Hp")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("0812 1214 3758")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Join Onboard")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("17 Desember 2021")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Email")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("nurulakbar93@gmail.com")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Sign Off")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("15 Desember 2022")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Alamat")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(" Jl. Pondok Pinang 3, RT.005, RW.002 No. 38, Kel. Pondok Pinang, Kec. Kebayoran Lama, Jakarta Selatan, DKI Jakarat (12310) ")])])])])]), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "md": "2"
          }
        }, [_c('b-img', {
          staticClass: "ml-0",
          attrs: {
            "thumbnail": "",
            "src": require('../../assets/images/page-img/10.jpg'),
            "alt": "Responsive image"
          }
        })], 1)], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Form Evaluasi Conduct")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', {
          staticClass: "text-center"
        }, [_c('th', {
          attrs: {
            "width": "40%"
          }
        }, [_vm._v("DESKRIPSI")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("PENILAIAN")]), _c('th', {
          attrs: {
            "width": "40%"
          }
        }, [_vm._v("NOTES")])])]), _c('tbody', [_c('tr', [_c('th', [_vm._v("Kebijakan Perusahaan")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1), _c('td', [_c('b-form-textarea', {
          attrs: {
            "rows": "4",
            "value": "Notes"
          }
        })], 1)]), _c('tr', [_c('th', [_vm._v("Keahlian")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1), _c('td', [_c('b-form-textarea', {
          attrs: {
            "rows": "4",
            "value": "Notes"
          }
        })], 1)]), _c('tr', [_c('th', [_vm._v("Inisiatif")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1), _c('td', [_c('b-form-textarea', {
          attrs: {
            "rows": "4",
            "value": "Notes"
          }
        })], 1)]), _c('tr', [_c('th', [_vm._v("Sikap")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1), _c('td', [_c('b-form-textarea', {
          attrs: {
            "rows": "4",
            "value": "Notes"
          }
        })], 1)]), _c('tr', [_c('th', [_vm._v("Komunikasi")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1), _c('td', [_c('b-form-textarea', {
          attrs: {
            "rows": "4",
            "value": "Notes"
          }
        })], 1)]), _c('tr', [_c('th', [_vm._v("Motivasi")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1), _c('td', [_c('b-form-textarea', {
          attrs: {
            "rows": "4",
            "value": "Notes"
          }
        })], 1)]), _c('tr', [_c('th', [_vm._v("Harmonisasi")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1), _c('td', [_c('b-form-textarea', {
          attrs: {
            "rows": "4",
            "value": "Notes"
          }
        })], 1)]), _c('tr', [_c('th', [_vm._v("Implementasi ISM Code")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1), _c('td', [_c('b-form-textarea', {
          attrs: {
            "rows": "4",
            "value": "Notes"
          }
        })], 1)]), _c('tr', [_c('th', [_vm._v("Laporan ISM Code")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1), _c('td', [_c('b-form-textarea', {
          attrs: {
            "rows": "4",
            "value": "Notes"
          }
        })], 1)]), _c('tr', [_c('th', [_vm._v("Latihan Keselamatan")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1), _c('td', [_c('b-form-textarea', {
          attrs: {
            "rows": "4",
            "value": "Notes"
          }
        })], 1)]), _c('tr', [_c('th', [_vm._v("Pengoperasian Kapal")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1), _c('td', [_c('b-form-textarea', {
          attrs: {
            "rows": "4",
            "value": "Notes"
          }
        })], 1)]), _c('tr', [_c('th', [_vm._v("Pemeliharaan Kapal")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1), _c('td', [_c('b-form-textarea', {
          attrs: {
            "rows": "4",
            "value": "Notes"
          }
        })], 1)]), _c('tr', [_c('th', [_vm._v("Pemeliharaan Perlengkapan Kapal")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1), _c('td', [_c('b-form-textarea', {
          attrs: {
            "rows": "4",
            "value": "Notes"
          }
        })], 1)]), _c('tr', [_c('th', [_vm._v("Pemeliharaan Perlengkapan")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1), _c('td', [_c('b-form-textarea', {
          attrs: {
            "rows": "4",
            "value": "Notes"
          }
        })], 1)]), _c('tr', [_c('th', [_vm._v("Keselamatan Kapal")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1), _c('td', [_c('b-form-textarea', {
          attrs: {
            "rows": "4",
            "value": "Notes"
          }
        })], 1)]), _c('tr', [_c('th', [_vm._v("Kejadian & Kecelaan Kapal / Keselamatan Kerja")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1), _c('td', [_c('b-form-textarea', {
          attrs: {
            "rows": "4",
            "value": "Notes"
          }
        })], 1)])])])])], 1)], 1), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('h4', {
          staticClass: "text-primary"
        }, [_vm._v("Evaluasi Oleh / "), _c('i', [_vm._v("Evaluation by ")])]), _c('hr'), _c('b-form-group', {
          attrs: {
            "label": "PIC",
            "label-for": "pic"
          }
        }, [_c('b-form-select', {
          staticStyle: {
            "border": "solid 1px gray"
          },
          attrs: {
            "plain": "",
            "options": _vm.pic,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select PIC")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedPIC,
            callback: function callback($$v) {
              _vm.selectedPIC = $$v;
            },
            expression: "selectedPIC"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Jabatan",
            "label-for": "jabatan"
          }
        }, [_c('b-form-select', {
          staticStyle: {
            "border": "solid 1px gray"
          },
          attrs: {
            "plain": "",
            "options": _vm.optionsJabatan,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Jabatan")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedJabatan,
            callback: function callback($$v) {
              _vm.selectedJabatan = $$v;
            },
            expression: "selectedJabatan"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Notes / Remarks",
            "label-for": "pic"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "rows": "2",
            "value": "Comments if any / Komentar jika ada"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('h4', {
          staticClass: "text-primary"
        }, [_vm._v("Diketahui Oleh / "), _c('i', [_vm._v("Acknowledge by ")])]), _c('hr'), _c('b-form-group', {
          attrs: {
            "label": "PIC",
            "label-for": "pic"
          }
        }, [_c('b-form-select', {
          staticStyle: {
            "border": "solid 1px gray"
          },
          attrs: {
            "plain": "",
            "options": _vm.pic,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select PIC")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedPIC,
            callback: function callback($$v) {
              _vm.selectedPIC = $$v;
            },
            expression: "selectedPIC"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Jabatan",
            "label-for": "jabatan"
          }
        }, [_c('b-form-select', {
          staticStyle: {
            "border": "solid 1px gray"
          },
          attrs: {
            "plain": "",
            "options": _vm.optionsJabatan,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Jabatan")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedJabatan,
            callback: function callback($$v) {
              _vm.selectedJabatan = $$v;
            },
            expression: "selectedJabatan"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Notes / Remarks",
            "label-for": "pic"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "rows": "2",
            "value": "Comments if any / Komentar jika ada"
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "float-right ml-3",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-save"
        }), _vm._v(" Save")]), _c('b-button', {
          staticClass: "iq-bg-danger float-right",
          attrs: {
            "type": "reset",
            "variant": "none"
          }
        }, [_c('i', {
          staticClass: "fa fa-close"
        }), _vm._v(" Reset Form")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }