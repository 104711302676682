var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Display headings")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-1",
            modifiers: {
              "collapse-1": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Traditional heading elements are designed to work best in the meat of your page content. When you need a heading to stand out, consider using a "), _c('strong', [_vm._v("display heading")]), _vm._v("—a larger, slightly more opinionated heading style. Keep in mind these headings are not responsive by default, but it’s possible to enable "), _c('a', {
          attrs: {
            "href": "https://getbootstrap.com/",
            "target": "_blank"
          }
        }, [_vm._v("responsive font sizes")]), _vm._v(".")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-1"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "display-headings"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<h1 class=\"display-1\">Display headings</h1>\n<h1 class=\"display-2\">Display headings</h1>\n<h1 class=\"display-3\">Display headings</h1>\n<h1 class=\"display-4\">Display headings</h1>\n<h1 class=\"display-5\">Display headings</h1>\n<h1 class=\"display-6 mb-0\">Display headings</h1>\n")])])])])]), _c('h1', {
          staticClass: "display-1"
        }, [_vm._v("Display headings")]), _c('h1', {
          staticClass: "display-2"
        }, [_vm._v("Display headings")]), _c('h1', {
          staticClass: "display-3"
        }, [_vm._v("Display headings")]), _c('h1', {
          staticClass: "display-4"
        }, [_vm._v("Display headings")]), _c('h1', {
          staticClass: "display-5"
        }, [_vm._v("Display headings")]), _c('h1', {
          staticClass: "display-6 mb-0"
        }, [_vm._v("Display headings")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Typography")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-2",
            modifiers: {
              "collapse-2": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("All HTML headings, "), _c('code', [_vm._v("<h1>")]), _vm._v(" through "), _c('code', [_vm._v("<h6>")]), _vm._v(", are available.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-2"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "typography"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<h1>h1. Bootstrap heading</h1>\n<h2>h2. Bootstrap heading</h2>\n<h3>h3. Bootstrap heading</h3>\n<h4>h4. Bootstrap heading</h4>\n<h5>h5. Bootstrap heading</h5>\n<h6 class=\"mb-0\">h6. Bootstrap heading</h6>\n")])])])])]), _c('h1', [_vm._v("h1. Bootstrap heading")]), _c('h2', [_vm._v("h2. Bootstrap heading")]), _c('h3', [_vm._v("h3. Bootstrap heading")]), _c('h4', [_vm._v("h4. Bootstrap heading")]), _c('h5', [_vm._v("h5. Bootstrap heading")]), _c('h6', {
          staticClass: "mb-0"
        }, [_vm._v("h6. Bootstrap heading")])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Inline text elements")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-3",
            modifiers: {
              "collapse-3": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Styling for common inline HTML5 elements.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-3"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "inline-text-element"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<p>You can use the mark tag to <mark>highlight</mark> text.</p>\n<p><del>This line of text is meant to be treated as deleted text.</del></p>\n<p><s>This line of text is meant to be treated as no longer accurate.</s></p>\n<p><ins>This line of text is meant to be treated as an addition to the document.</ins></p>\n<p><u>This line of text will render as underlined</u></p>\n<p><small>This line of text is meant to be treated as fine print.</small></p>\n<p><strong>This line rendered as bold text.</strong></p>\n<p><em>This line rendered as italicized text.</em></p>\n")])])])])]), _c('p', [_vm._v("You can use the mark tag to "), _c('mark', [_vm._v("highlight")]), _vm._v(" text.")]), _c('p', [_c('del', [_vm._v("This line of text is meant to be treated as deleted text.")])]), _c('p', [_c('s', [_vm._v("This line of text is meant to be treated as no longer accurate.")])]), _c('p', [_c('ins', [_vm._v("This line of text is meant to be treated as an addition to the document.")])]), _c('p', [_c('u', [_vm._v("This line of text will render as underlined")])]), _c('p', [_c('small', [_vm._v("This line of text is meant to be treated as fine print.")])]), _c('p', [_c('strong', [_vm._v("This line rendered as bold text.")])]), _c('p', [_c('em', [_vm._v("This line rendered as italicized text.")])])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Lists Inline")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-4",
            modifiers: {
              "collapse-4": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Remove a list’s bullets and apply some light "), _c('code', [_vm._v("margin")]), _vm._v(" with a combination of two classes, "), _c('code', [_vm._v(".list-inline")]), _vm._v(" and "), _c('code', [_vm._v(".list-inline-item")]), _vm._v(".")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-4"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "lists-inline"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<ul class=\"list-inline\">\n  <li class=\"list-inline-item\">Lorem ipsum</li>\n  <li class=\"list-inline-item\">Phasellus iaculis</li>\n  <li class=\"list-inline-item\">Nulla volutpat</li>\n</ul>\n")])])])])]), _c('ul', {
          staticClass: "list-inline"
        }, [_c('li', {
          staticClass: "list-inline-item"
        }, [_vm._v("Lorem ipsum")]), _c('li', {
          staticClass: "list-inline-item"
        }, [_vm._v("Phasellus iaculis")]), _c('li', {
          staticClass: "list-inline-item"
        }, [_vm._v("Nulla volutpat")])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Headings")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-5",
            modifiers: {
              "collapse-5": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_c('code', [_vm._v(".h1")]), _vm._v(" through "), _c('code', [_vm._v(".h6")]), _vm._v(" classes are also available, for when you want to match the font styling of a heading but cannot use the associated HTML element.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-5"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "headings"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<p class=\"h1 mb-3\">h1. Bootstrap heading</p>\n<p class=\"h2 mb-3\">h2. Bootstrap heading</p>\n<p class=\"h3 mb-3\">h3. Bootstrap heading</p>\n<p class=\"h4 mb-3\">h4. Bootstrap heading</p>\n<p class=\"h5 mb-3\">h5. Bootstrap heading</p>\n<p class=\"h6 mb-0\">h6. Bootstrap heading</p>\n")])])])])]), _c('p', {
          staticClass: "h1 mb-3"
        }, [_vm._v("h1. Bootstrap heading")]), _c('p', {
          staticClass: "h2 mb-3"
        }, [_vm._v("h2. Bootstrap heading")]), _c('p', {
          staticClass: "h3 mb-3"
        }, [_vm._v("h3. Bootstrap heading")]), _c('p', {
          staticClass: "h4 mb-3"
        }, [_vm._v("h4. Bootstrap heading")]), _c('p', {
          staticClass: "h5 mb-3"
        }, [_vm._v("h5. Bootstrap heading")]), _c('p', {
          staticClass: "h6 mb-0"
        }, [_vm._v("h6. Bootstrap heading")])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Lists Unstyled")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-6",
            modifiers: {
              "collapse-6": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Remove the default "), _c('code', [_vm._v("list-style")]), _vm._v(" and left margin on list items (immediate children only). "), _c('strong', [_vm._v("This only applies to immediate children list items")]), _vm._v(", meaning you will need to add the class for any nested lists as well.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-6"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "lists-unstyled"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<ul class=\"list-unstyled\">\n  <li>Lorem ipsum dolor sit amet</li>\n  <li>Consectetur adipiscing elit</li>\n  <li>Integer molestie lorem at massa</li>\n  <li>Facilisis in pretium nisl aliquet</li>\n  <li>\n      Nulla volutpat aliquam velit\n      <ul>\n        <li>Phasellus iaculis neque</li>\n        <li>Purus sodales ultricies</li>\n        <li>Vestibulum laoreet porttitor sem</li>\n        <li>Ac tristique libero volutpat at</li>\n      </ul>\n  </li>\n  <li>Faucibus porta lacus fringilla vel</li>\n  <li>Aenean sit amet erat nunc</li>\n  <li>Eget porttitor lorem</li>\n</ul>\n")])])])])]), _c('ul', {
          staticClass: "list-unstyled"
        }, [_c('li', [_vm._v("Lorem ipsum dolor sit amet")]), _c('li', [_vm._v("Consectetur adipiscing elit")]), _c('li', [_vm._v("Integer molestie lorem at massa")]), _c('li', [_vm._v("Facilisis in pretium nisl aliquet")]), _c('li', [_vm._v(" Nulla volutpat aliquam velit "), _c('ul', [_c('li', [_vm._v("Phasellus iaculis neque")]), _c('li', [_vm._v("Purus sodales ultricies")]), _c('li', [_vm._v("Vestibulum laoreet porttitor sem")]), _c('li', [_vm._v("Ac tristique libero volutpat at")])])]), _c('li', [_vm._v("Faucibus porta lacus fringilla vel")]), _c('li', [_vm._v("Aenean sit amet erat nunc")]), _c('li', [_vm._v("Eget porttitor lorem")])])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Blockquotes")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-7",
            modifiers: {
              "collapse-7": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("For quoting blocks of content from another source within your document. Wrap "), _c('code', [_vm._v("<blockquote class=\"blockquote\">")]), _vm._v(" around any "), _c('abbr', {
          attrs: {
            "title": "HyperText Markup Language"
          }
        }, [_vm._v("HTML")]), _vm._v(" as the quote.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-7"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "blockquotes"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<blockquote class=\"blockquote\">\n  <p class=\"mb-0\">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>\n  <footer class=\"blockquote-footer\">Someone famous in <cite title=\"Source Title\">Source Title</cite></footer>\n</blockquote>\n")])])])])]), _c('blockquote', {
          staticClass: "blockquote"
        }, [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
          staticClass: "blockquote-footer"
        }, [_vm._v("Someone famous in "), _c('cite', {
          attrs: {
            "title": "Source Title"
          }
        }, [_vm._v("Source Title")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Description list alignment")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-8",
            modifiers: {
              "collapse-8": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Align terms and descriptions horizontally by using our grid system’s predefined classes (or semantic mixins). For longer terms, you can optionally add a "), _c('code', [_vm._v(".text-truncate")]), _vm._v(" class to truncate the text with an ellipsis.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-8"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "description-list-alignment"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<dl class=\"row\">\n  <dt class=\"col-sm-3\">Description lists</dt>\n  <dd class=\"col-sm-9\">A description list is perfect for defining terms.</dd>\n  <dt class=\"col-sm-3\">Euismod</dt>\n  <dd class=\"col-sm-9\">\n    <p>Vestibulum id ligula porta felis euismod semper eget lacinia odio sem nec elit.</p>\n    <p>Donec id elit non mi porta gravida at eget metus.</p>\n  </dd>\n  <dt class=\"col-sm-3\">Malesuada porta</dt>\n  <dd class=\"col-sm-9\">Etiam porta sem malesuada magna mollis euismod.</dd>\n  <dt class=\"col-sm-3 text-truncate\">Truncated term is truncated</dt>\n  <dd class=\"col-sm-9\">Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.</dd>\n  <dt class=\"col-sm-3\">Nesting</dt>\n  <dd class=\"col-sm-9\">\n    <dl class=\"row\">\n      <dt class=\"col-sm-4\">Nested definition list</dt>\n      <dd class=\"col-sm-8\">Aenean posuere, tortor sed cursus feugiat, nunc augue blandit nunc.</dd>\n    </dl>\n  </dd>\n</dl>\n")])])])])]), _c('dl', {
          staticClass: "row"
        }, [_c('dt', {
          staticClass: "col-sm-3"
        }, [_vm._v("Description lists")]), _c('dd', {
          staticClass: "col-sm-9"
        }, [_vm._v("A description list is perfect for defining terms.")]), _c('dt', {
          staticClass: "col-sm-3"
        }, [_vm._v("Euismod")]), _c('dd', {
          staticClass: "col-sm-9"
        }, [_c('p', [_vm._v("Vestibulum id ligula porta felis euismod semper eget lacinia odio sem nec elit.")]), _c('p', [_vm._v("Donec id elit non mi porta gravida at eget metus.")])]), _c('dt', {
          staticClass: "col-sm-3"
        }, [_vm._v("Malesuada porta")]), _c('dd', {
          staticClass: "col-sm-9"
        }, [_vm._v("Etiam porta sem malesuada magna mollis euismod.")]), _c('dt', {
          staticClass: "col-sm-3 text-truncate"
        }, [_vm._v("Truncated term is truncated")]), _c('dd', {
          staticClass: "col-sm-9"
        }, [_vm._v("Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.")]), _c('dt', {
          staticClass: "col-sm-3"
        }, [_vm._v("Nesting")]), _c('dd', {
          staticClass: "col-sm-9"
        }, [_c('dl', {
          staticClass: "row"
        }, [_c('dt', {
          staticClass: "col-sm-4"
        }, [_vm._v("Nested definition list")]), _c('dd', {
          staticClass: "col-sm-8"
        }, [_vm._v("Aenean posuere, tortor sed cursus feugiat, nunc augue blandit nunc.")])])])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }