var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.detailSurvey ? _c('div', {
    staticStyle: {
      "background-color": "#fff"
    }
  }, [_c('div', {
    staticClass: "no-print pull-right"
  }, [_c('b-button-group', {
    staticClass: "mr-2"
  }, [_c('button', {
    staticClass: "btn btn-sm btn-primary",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.print();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" Save")])])], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12 text-left"
  }, [_c('img', {
    staticClass: "admin_img",
    attrs: {
      "src": _vm.loadImg('energy_transporter'),
      "alt": "energy_transporter"
    }
  }), _c('img', {
    staticClass: "admin_img",
    attrs: {
      "src": _vm.loadImg('sentra_makmur'),
      "alt": "sentra_makmur"
    }
  }), _c('img', {
    staticClass: "admin_img",
    attrs: {
      "src": _vm.loadImg('renjani_maritim'),
      "alt": "renjani_maritim"
    }
  }), _c('img', {
    staticClass: "admin_img",
    attrs: {
      "src": _vm.loadImg('trans_energi_logistik'),
      "alt": "trans_energi_logistik"
    }
  }), _c('img', {
    staticClass: "admin_img",
    attrs: {
      "src": _vm.loadImg('marine_insurance'),
      "alt": "marine_insurance"
    }
  })])]), _vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-3"
  }, [_c('table', {
    attrs: {
      "border": "0",
      "width": "100%"
    }
  }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("TB/BG")]), _c('td', [_vm._v(": " + _vm._s(_vm.detailMi.vehicle.name))])]), _c('tr', [_c('td', [_vm._v("Cause Of Loss")]), _c('td', [_vm._v(": " + _vm._s(_vm.detailMi.type_loss.name))])]), _c('tr', [_c('td', [_vm._v("DOL")]), _c('td', [_vm._v(": " + _vm._s(_vm.detailMi.date_of_loss))])]), _c('tr', [_c('td', [_vm._v("Policy Number")]), _c('td', [_vm._v(": " + _vm._s(_vm.form.police_number))])]), _c('tr', [_c('td', [_vm._v("Periode Of Cover")]), _c('td', [_vm._v(": " + _vm._s(_vm.form.coverage_period))])])])])]), _c('div', {
    staticClass: "col-lg-6"
  }), _vm._m(1)]), _c('div', [_vm._v(" ")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('table', {
    attrs: {
      "border": "1",
      "width": "100%"
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("No.")]), _vm._l(_vm.detailSurvey.headers, function (header, headerIndex) {
    return _c('th', {
      key: headerIndex,
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(header.label_name))]);
  })], 2)]), _vm.detailSurvey.headers[0] ? _c('tbody', [_vm._l(_vm.detailSurvey.headers[0].contents.length, function (number) {
    return _c('tr', {
      key: number
    }, [_c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(number))]), _vm._l(_vm.detailSurvey.headers, function (header, headerIndex) {
      return _c('td', {
        key: headerIndex
      }, [header.contents[number - 1] ? [header.contents[number - 1].text ? [_vm._v(" " + _vm._s(header.contents[number - 1].text) + " ")] : header.contents[number - 1].input ? [header.contents[number - 1].input.alias === 'max100' ? _c('b-form-input', {
        staticClass: "text-center",
        attrs: {
          "type": "number",
          "maxlength": 3,
          "min": 1,
          "max": 100
        },
        model: {
          value: header.contents[number - 1].input.value,
          callback: function callback($$v) {
            _vm.$set(header.contents[number - 1].input, "value", $$v);
          },
          expression: "header.contents[number-1].input.value"
        }
      }) : _c('p', [_vm._v(_vm._s(header.contents[number - 1].input.value))])] : _vm._e()] : _vm._e()], 2);
    })], 2);
  }), _c('tr', {
    staticStyle: {
      "background-color": "yellow"
    }
  }, [_vm._m(2), _c('td', {
    staticClass: "text-center"
  }, [_c('h5', [_c('b', [_vm._v(_vm._s(_vm.totalAll))])])]), _c('td', [_vm._v(" ")])])], 2) : _vm._e()])])]), _c('div', [_vm._v(" ")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-3"
  }, [_c('table', {
    attrs: {
      "border": "0",
      "width": "100%"
    }
  }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("Conclusion (Kesimpulan) ")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    domProps: {
      "innerHTML": _vm._s(_vm.form.conclusion || '-')
    }
  })]), _c('tr', [_vm._m(3), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    domProps: {
      "innerHTML": _vm._s(_vm.form.comments || '-')
    }
  })])])])])])]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row",
    staticStyle: {
      "margin-top": "-20px"
    }
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('h4', {
    staticClass: "mt-5",
    staticStyle: {
      "text-decoration": "underline"
    }
  }, [_vm._v("INTIAL CLAIM ANALYSIS "), _c('br'), _vm._v("MARINE INSURANCE GROUP TRANS ENERGI LOGISTIK")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-3"
  }, [_c('table', {
    staticClass: "border border-dark",
    attrs: {
      "border": "0",
      "width": "100%"
    }
  }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("80 - 100")]), _c('td', [_vm._v("= Recommended (direkomendasikan).")])]), _c('tr', [_c('td', [_vm._v("65 - 75")]), _c('td', [_vm._v("= with consideration (dengan pertimbangan)")])]), _c('tr', [_c('td', [_vm._v("55 - 60")]), _c('td', [_vm._v("= It needs special attention (perlu perhatian khusus )")])]), _c('tr', [_c('td', [_vm._v("0 - 50")]), _c('td', [_vm._v("= Not recommended (tidak disarankan)")])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "3"
    }
  }, [_c('h5', [_c('b', [_vm._v("Nilai Seluruhnya")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_vm._v("Comments or Suggestions "), _c('br'), _vm._v("Komentar atau Saran-saran ")]);

}]

export { render, staticRenderFns }