var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("DOCKING HISTORY - TB. ETI 201")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.docking_progress_card",
            modifiers: {
              "docking_progress_card": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "visible": "",
            "id": "docking_progress_card"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet Category",
            "label-for": "fleet_category"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Fleet Category",
            "label": "text",
            "track-by": "value",
            "options": _vm.fleetCategory,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.fleetCategorySelect,
            callback: function callback($$v) {
              _vm.fleetCategorySelect = $$v;
            },
            expression: "fleetCategorySelect"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Docking Type",
            "label-for": "docking_type"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.docking_type,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Docking Type")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedDockingType,
            callback: function callback($$v) {
              _vm.selectedDockingType = $$v;
            },
            expression: "selectedDockingType"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.dockingStatusFinish,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Status")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedDockingStatusFinish,
            callback: function callback($$v) {
              _vm.selectedDockingStatusFinish = $$v;
            },
            expression: "selectedDockingStatusFinish"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Period Docking (Arrival)",
            "label-for": "Period"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1)], 1), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DOCKING TYPE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("SHIPYARD")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ARRIVAL")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("UPLSIP")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DELIVERY")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("JOB")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("1")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. CAHAYA BAHARI SHIPYARD")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2022-05-10")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2022-05-16")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2022-06-18")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("ON TIME")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary]"
          },
          on: {
            "click": function click($event) {
              _vm.slideDockingDetail = !_vm.slideDockingDetail;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _c('router-link', {
          attrs: {
            "to": "docking-history"
          }
        }, [_vm._v("DOCKING DETAIL")])], 1)], 1)], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("2")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("SPECIAL SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. GALANGAN CISWANTO")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2022-01-20")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2022-01-21")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2022-02-28")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("22")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("DELAY")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary]"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _c('router-link', {
          attrs: {
            "to": "docking-history"
          }
        }, [_vm._v("DOCKING DETAIL")])], 1)], 1)], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("3")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("EMERGENCY DOCKING")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. BUMI AYU")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2021-09-28")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2021-09-30")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2021-11-05")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("19")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("ON TIME")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary]"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _c('router-link', {
          attrs: {
            "to": "docking-history"
          }
        }, [_vm._v("DOCKING DETAIL")])], 1)], 1)], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("4")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. CAHAYA BAHARI SHIPYARD")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2021-04-12")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2021-04-13")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2021-06-03")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("32")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("ON TIME")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary]"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _c('router-link', {
          attrs: {
            "to": "docking-history"
          }
        }, [_vm._v("DOCKING DETAIL")])], 1)], 1)], 1)])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('b-sidebar', {
          attrs: {
            "id": "docking_detail",
            "title": "DOCKING DETAIL - TB. ETI 201",
            "width": "60%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var hide = _ref.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Select Docking Job")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "10"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "job_name",
                  "type": "text",
                  "placeholder": "Search Job Name"
                }
              })], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-button', {
                attrs: {
                  "variant": "success",
                  "block": ""
                }
              }, [_vm._v("SEARCH")])], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('v-jstree', {
                staticClass: "mt-3",
                attrs: {
                  "data": _vm.tree,
                  "show-checkbox": "",
                  "allow-batch": "",
                  "whole-row": "",
                  "item-events": _vm.itemEvents
                },
                on: {
                  "item-click": _vm.itemClick
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_) {
                    return [_c('div', {
                      staticStyle: {
                        "display": "inherit",
                        "width": "200px"
                      },
                      on: {
                        "click": function click($event) {
                          if (!$event.ctrlKey) return null;
                          return _vm.customItemClickWithCtrl.apply(null, arguments);
                        }
                      }
                    }, [!_.model.loading ? _c('i', {
                      class: _.vm.themeIconClasses,
                      attrs: {
                        "role": "presentation"
                      }
                    }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
                  }
                }], null, true)
              })], 1), _c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "8"
                }
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Arrival Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "arrival_shipyard"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Up Slip")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "upslip"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Waiting Dock Space")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("5 Hari")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Down Slip")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "downslip"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Complete Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "delivery_date"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Budget")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 385.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA BAHARI SHIPYARD")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "real_budget",
                  "placeholder": "324.000.000"
                }
              })])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("NURUL AKBAR")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-primary rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-primary"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Job Category")])])])];
                  },
                  proxy: true
                }], null, true)
              })], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-1",
                  modifiers: {
                    "jobCat-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v("DOCKING / UNDOCKING "), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-1",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('b-modal', {
                attrs: {
                  "id": "reason_reject",
                  "size": "md",
                  "title": "Reject - Docking Up Slip",
                  "ok-title": "Save",
                  "cancel-title": "Close"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Reason Reject",
                  "label-for": "reason_reject"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "reason_reject",
                  "rows": "3"
                }
              })], 1), _c('b-button', {
                attrs: {
                  "block": "",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-close mr-2"
              }), _vm._v("Reject Docking Job")])], 1)], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Up Slip "), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Internal Man Power")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Down Slip "), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-2",
                  modifiers: {
                    "jobCat-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v("SAND BLASTING"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 70%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-2",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-1",
                  modifiers: {
                    "job-2-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Scraping teritip di lambung dan lunas"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-2",
                  modifiers: {
                    "job-2-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Pencucian lambung dengan air tawar"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-3",
                  modifiers: {
                    "job-2-3": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Sand blasting lunas"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-4",
                  modifiers: {
                    "job-2-4": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Sand blasting lambung"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 40%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-5",
                  modifiers: {
                    "job-2-5": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Sand blasting deck dan bulwark"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-5",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-3",
                  modifiers: {
                    "jobCat-3": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v("MACHINERY EQUIPMENT"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-3",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-1",
                  modifiers: {
                    "job-3-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" General Overhaul ME (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-2",
                  modifiers: {
                    "job-3-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Kalibrasi injector (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-3",
                  modifiers: {
                    "job-3-3": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Service/ganti baru dynamo starter (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-4",
                  modifiers: {
                    "job-3-4": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Service/ganti baru alternator (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 40%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-5",
                  modifiers: {
                    "job-3-5": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Service turbocharger"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-5",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', {
                staticClass: "pull-right"
              }, [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('br'), _c('br'), _c('b-button', {
                staticClass: "pull-right mr-1",
                attrs: {
                  "type": "submit",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save")])], 1)], 1)], 1)], 1)], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.slideDockingDetail,
            callback: function callback($$v) {
              _vm.slideDockingDetail = $$v;
            },
            expression: "slideDockingDetail"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }