var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('iq-card', {
    staticClass: "calender-small",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('flat-pickr', {
          staticClass: "d-none",
          attrs: {
            "config": _vm.config,
            "value": ""
          }
        })];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Classification")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-0",
          attrs: {
            "aria-hidden": "true"
          }
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('ul', {
          staticClass: "m-0 p-0 job-classification"
        }, [_c('li', {}, [_c('i', {
          staticClass: "ri-check-line bg-danger"
        }), _vm._v("Meeting")]), _c('li', {}, [_c('i', {
          staticClass: "ri-check-line bg-success"
        }), _vm._v("Business travel")]), _c('li', {}, [_c('i', {
          staticClass: "ri-check-line bg-warning"
        }), _vm._v("Personal Work")]), _c('li', {}, [_c('i', {
          staticClass: "ri-check-line bg-info"
        }), _vm._v("Team Project")])])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Today's Schedule")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('ul', {
          staticClass: "m-0 p-0 today-schedule"
        }, [_c('li', {
          staticClass: "d-flex"
        }, [_c('div', {
          staticClass: "schedule-icon"
        }, [_c('i', {
          staticClass: "ri-checkbox-blank-circle-fill text-primary"
        })]), _c('div', {
          staticClass: "schedule-text"
        }, [_c('span', [_vm._v("Web Design")]), _c('span', [_vm._v("09:00 to 12:00")])])]), _c('li', {
          staticClass: "d-flex"
        }, [_c('div', {
          staticClass: "schedule-icon"
        }, [_c('i', {
          staticClass: "ri-checkbox-blank-circle-fill text-success"
        })]), _c('div', {
          staticClass: "schedule-text"
        }, [_c('span', [_vm._v("Participate in Design")]), _c('span', [_vm._v("09:00 to 12:00")])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Book Appointment")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          staticClass: "btn btn-primary",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-add-line mr-2"
        }), _vm._v("Book Appointment")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('FullCalendar', {
          attrs: {
            "calendarEvents": _vm.events
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }