<template>
  <b-container fluid>
    <b-row>
      <b-col lg="4">
        <iq-card body-class="iq-card-body pl-0 pr-0 pt-0">
          <template v-slot:body>
            <div class="docter-details-block py-4">
              <div class="docter-profile text-center">
                <b-img
                  :src="
                    user.userDetail.photo
                      | checkPhoto(
                        require('@src/assets/images/user/01.jpg')
                      )
                  "
                  :alt="user.userDetail.name"
                  v-bind="{ height: 144 }"
                />
              </div>
              <div class="text-center mt-3 pl-3 pr-3">
                <h4
                  ><b>{{ user.userDetail.name }}</b></h4
                >
                <p>{{ user.role.roles }}</p>
                <p class="mb-0"
                  >{{ user.company.location }} - {{ user.company.company }}</p
                >
                <p class="mb-0"
                  ><b-badge
                    :variant="user.active ? 'success' : 'warning'"
                    class="ml-2"
                    >{{ user.active ? 'Active' : 'Not Active' }}</b-badge
                  >
                </p>
              </div>
            </div>
          </template>
        </iq-card>
        <iq-card body-class="iq-card-body">
          <template v-slot:body>
            <div class="iq-card-header d-flex justify-content-between">
              <div class="iq-header-title">
                <h4 class="card-title">Personal Information</h4>
              </div>
            </div>
            <div class="iq-card-body">
              <div class="about-info m-0 p-0">
              <div class="row">
                <div class="col-4">Birthday:</div>
                <div class="col-8">{{
                  user.userDetail.birthday | parseDate('DD MMMM YYYY')
                }}</div>
                <div class="col-4">Age:</div>
                <div class="col-8">{{ user.userDetail.age }}</div>
                <div class="col-4">Email:</div>
                <div class="col-8"
                  ><a :href="`mailto:${user.email}`">
                    {{ user.email }}
                  </a></div
                >
                <div class="col-4">Phone:</div>
                <div class="col-8"
                  ><a :ref="`tel:${user.userDetail.phone}`">
                    {{ user.userDetail.phone ? user.userDetail.phone : '-' }}</a
                  ></div
                >
                <div class="col-4">Address:</div>
                <div class="col-8">{{
                  user.userDetail.address ? user.userDetail.address : '-'
                }}</div>
                <div class="col-4 mt-4">
                  <router-link
                    :to="{ name: 'setting.EditUser', params: { id: user.id }, query: { menuObject: $route.query.menuObject } }"
                  >
                    <b-button block variant="primary" class="mb-3 mr-1"
                      ><i class="ri-edit-2-line"></i>Edit</b-button
                    >
                  </router-link>
                </div>
              </div>
            </div>
            </div>
          </template>
        </iq-card>
        <iq-card body-class="iq-card-body">
          <template v-slot:body>
            <div class="iq-card-header d-flex justify-content-between">
              <div class="iq-header-title">
                <h4 class="card-title">Fleet Responsible</h4>
              </div>
            </div>
            <div class="iq-card-body">
              <b-row v-if="user.vehicle.length > 0">
              <b-col
                md="4"
                class="text-center"
                v-for="(fleet, i) in user.vehicle"
                :key="`fleet-${i}`"
              >
                <label>
                  <img
                    :src="
                      fleet.vehicleType.icon.url
                        | checkPhoto(
                          require('@src/assets/images/Km3FO09.png')
                        )
                    "
                    :alt="fleet.name"
                    class="img-fluid"
                  />
                </label>
                <small
                  ><strong>{{ fleet.name }}</strong></small
                >
              </b-col>
            </b-row>
            <p v-else>No fleet found.</p>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="8">
        <b-row>
          <b-col md="6">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Log History</h4>
              </template>
              <template v-slot:body>
                <ul class="iq-timeline" v-if="user.userLog.length > 0">
                  <li v-for="(log, i) in user.userLog" :key="`log-${i}`">
                    <div class="timeline-dots border-success"></div>
                    <h6 class="">{{ log.activity }}</h6>
                    <small class="mt-1">{{ log.date }}</small>
                  </li>
                </ul>
                <p v-else>No log history found.</p>
              </template>
            </iq-card>
          </b-col>
          <b-col md="6">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Notifications</h4>
              </template>
              <template v-slot:body>
                <ul class="iq-timeline" v-if="user.userNotification.length > 0">
                  <li
                    v-for="(notif, i) in user.userNotification"
                    :key="`notif-${i}`"
                  >
                    <div class="timeline-dots border-success"></div>
                    <h6 class="">{{ notif.title }}</h6>
                    <small class="mt-1">{{ notif.createAt }}</small>
                  </li>
                </ul>
                <p v-else>There is no notification found.</p>
              </template>
            </iq-card>
          </b-col>
          <b-col md="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">User Privileges</h4>
              </template>
              <template v-slot:body>
                <div v-if="loading_menu" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div v-if="menus.length > 0" class="table-responsive">
                    <table class="table mb-0 table-striped table-hover">
                      <thead class="thead-dark text-center">
                        <tr>
                          <th width="5%">No.</th>
                          <th width="35%">Data</th>
                          <th width="10%">Add</th>
                          <th width="10%">Update</th>
                          <th width="10%">Delete</th>
                          <th width="10%">Export</th>
                          <th width="10%">Review</th>
                          <th width="10%">Approval</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(item, key) in menus">
                          <tr :key="`menu-${key}`">
                            <td class="text-center">
                              <b-form-checkbox
                                v-model="checkedMenus[key].active"
                                :name="`basic-checkbox-${key}`"
                                disabled
                              ></b-form-checkbox>
                            </td>
                            <td>{{ item.menu }}</td>
                            <td class="text-center">
                              <template v-if="item.add">
                                <b-form-checkbox
                                  v-model="checkedMenus[key].add"
                                  name="check-button"
                                  switch
                                  inline
                                  disabled
                                >
                                </b-form-checkbox>
                              </template>
                              <template v-else>&nbsp;</template>
                            </td>
                            <td class="text-center">
                              <template v-if="item.update">
                                <b-form-checkbox
                                  :key="key"
                                  v-model="checkedMenus[key].update"
                                  name="check-button"
                                  switch
                                  inline
                                  disabled
                                >
                                </b-form-checkbox>
                              </template>
                              <template v-else>&nbsp;</template>
                            </td>
                            <td class="text-center">
                              <template v-if="item.delete">
                                <b-form-checkbox
                                  :key="key"
                                  v-model="checkedMenus[key].delete"
                                  name="check-button"
                                  switch
                                  inline
                                  disabled
                                >
                                </b-form-checkbox>
                              </template>
                              <template v-else>&nbsp;</template>
                            </td>
                            <td class="text-center">
                              <template v-if="item.export">
                                <b-form-checkbox
                                  :key="key"
                                  v-model="checkedMenus[key].export"
                                  name="check-button"
                                  switch
                                  inline
                                  disabled
                                >
                                </b-form-checkbox>
                              </template>
                              <template v-else>&nbsp;</template>
                            </td>
                            <td class="text-center">
                              <template v-if="item.review">
                                <b-form-checkbox
                                  :key="key"
                                  v-model="checkedMenus[key].review"
                                  name="check-button"
                                  switch
                                  inline
                                  disabled
                                >
                                </b-form-checkbox>
                              </template>
                              <template v-else>&nbsp;</template>
                            </td>
                            <td class="text-center">
                              <template v-if="item.approval">
                                <b-form-checkbox
                                  :key="key"
                                  v-model="checkedMenus[key].approval"
                                  name="check-button"
                                  switch
                                  inline
                                  disabled
                                >
                                </b-form-checkbox>
                              </template>
                              <template v-else>&nbsp;</template>
                            </td>
                          </tr>

                          <!-- SUB MENU -->
                          <template v-for="(subMenuItem, keySub) in item.child">
                            <tr :key="`submenu-${key}${keySub}`">
                              <td class="text-center">
                                <b-form-checkbox
                                  v-model="checkedMenus[key].child[keySub].active"
                                  :name="`submenu-ck-${keySub}`"
                                  disabled
                                ></b-form-checkbox>
                              </td>
                              <td
                                ><span style="margin-right: 60px"></span
                                >{{ subMenuItem.menu }}</td
                              >
                              <td class="text-center">
                                <template v-if="subMenuItem.add">
                                  <b-form-checkbox
                                    :key="keySub"
                                    v-model="checkedMenus[key].child[keySub].add"
                                    name="check-button"
                                    switch
                                    inline
                                    disabled
                                  >
                                  </b-form-checkbox>
                                </template>
                                <template v-else>&nbsp;</template>
                              </td>
                              <td class="text-center">
                                <template v-if="subMenuItem.update">
                                  <b-form-checkbox
                                    :key="keySub"
                                    v-model="
                                      checkedMenus[key].child[keySub].update
                                    "
                                    name="check-button"
                                    switch
                                    inline
                                    disabled
                                  >
                                  </b-form-checkbox>
                                </template>
                                <template v-else>&nbsp;</template>
                              </td>
                              <td class="text-center">
                                <template v-if="subMenuItem.delete">
                                  <b-form-checkbox
                                    :key="keySub"
                                    v-model="
                                      checkedMenus[key].child[keySub].delete
                                    "
                                    name="check-button"
                                    switch
                                    inline
                                    disabled
                                  >
                                  </b-form-checkbox>
                                </template>
                                <template v-else>&nbsp;</template>
                              </td>
                              <td class="text-center">
                                <template v-if="subMenuItem.export">
                                  <b-form-checkbox
                                    :key="keySub"
                                    v-model="
                                      checkedMenus[key].child[keySub].export
                                    "
                                    name="check-button"
                                    switch
                                    inline
                                    disabled
                                  >
                                  </b-form-checkbox>
                                </template>
                                <template v-else>&nbsp;</template>
                              </td>
                              <td class="text-center">
                                <template v-if="subMenuItem.review">
                                  <b-form-checkbox
                                    :key="keySub"
                                    v-model="
                                      checkedMenus[key].child[keySub].review
                                    "
                                    name="check-button"
                                    switch
                                    inline
                                    disabled
                                  >
                                  </b-form-checkbox>
                                </template>
                                <template v-else>&nbsp;</template>
                              </td>
                              <td class="text-center">
                                <template v-if="subMenuItem.approval">
                                  <b-form-checkbox
                                    :key="keySub"
                                    v-model="
                                      checkedMenus[key].child[keySub].approval
                                    "
                                    name="check-button"
                                    switch
                                    inline
                                    disabled
                                  >
                                  </b-form-checkbox>
                                </template>
                                <template v-else>&nbsp;</template>
                              </td>
                            </tr>

                            <!-- TAB MENU / SUB CHILD -->
                            <template
                              v-for="(tabMenuItem, keyTab) in subMenuItem.child"
                            >
                              <tr :key="`keytab-${key}${keySub}${keyTab}`">
                                <td class="text-center">
                                  <b-form-checkbox
                                    v-model="
                                      checkedMenus[key].child[keySub].child[
                                        keyTab
                                      ].active
                                    "
                                    :name="`tab-ck-${keySub}`"
                                    disabled
                                  ></b-form-checkbox>
                                </td>
                                <td
                                  ><span style="margin-right: 100px"></span
                                  >{{ tabMenuItem.menu }}</td
                                >
                                <td class="text-center">
                                  <template v-if="tabMenuItem.add">
                                    <b-form-checkbox
                                      :key="keySub"
                                      v-model="checkedMenus[key].child[keySub].child[
                                          keyTab
                                        ].add"
                                      name="check-button"
                                      switch
                                      inline
                                      disabled
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                                <td class="text-center">
                                  <template v-if="tabMenuItem.update">
                                    <b-form-checkbox
                                      v-model="
                                        checkedMenus[key].child[keySub].child[
                                          keyTab
                                        ].update
                                      "
                                      name="check-button"
                                      switch
                                      inline
                                      disabled
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                                <td class="text-center">
                                  <template v-if="tabMenuItem.delete">
                                    <b-form-checkbox
                                      v-model="
                                        checkedMenus[key].child[keySub].child[
                                          keyTab
                                        ].delete
                                      "
                                      name="check-button"
                                      switch
                                      inline
                                      disabled
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                                <td class="text-center">
                                  <template v-if="tabMenuItem.export">
                                    <b-form-checkbox
                                      v-model="
                                        checkedMenus[key].child[keySub].child[
                                          keyTab
                                        ].export
                                      "
                                      name="check-button"
                                      switch
                                      inline
                                      disabled
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                                <td class="text-center">
                                  <template v-if="tabMenuItem.review">
                                    <b-form-checkbox
                                      v-model="
                                        checkedMenus[key].child[keySub].child[
                                          keyTab
                                        ].review
                                      "
                                      name="check-button"
                                      switch
                                      inline
                                      disabled
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                                <td class="text-center">
                                  <template v-if="tabMenuItem.approval">
                                    <b-form-checkbox
                                      v-model="
                                        checkedMenus[key].child[keySub].child[
                                          keyTab
                                        ].approval
                                      "
                                      name="check-button"
                                      switch
                                      inline
                                      disabled
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                              </tr>
                            </template>
                          </template>
                        </template>
                      </tbody>
                    </table>
                  </div>
                  <p v-else class="mb-0 text-muted text-center">
                    No privilege data data found.
                  </p>
                </template>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { usersActions, menusActions } from '@src/Utils/helper'

export default {
  name: 'Profile',
  data() {
    return {
      title: 'User View',
      user: {
        company: {},
        menu: [],
        role: {},
        userDetail: {},
        userLog: [],
        userNotification: {},
        vehicle: [],
      },
      menus: [],
      checkedMenus: [],
      loading_menu: true
    }
  },
  async mounted() {
    await this.getUser()
    this.fetchMenus()
  },
  methods: {
    ...usersActions,
    ...menusActions,
    async getUser() {
      let user_id = this.$route.params.id

      let res = await this.getUserById(user_id)
      if (res.status != 'success') {
        if (res.data.message != null)
          this.$swal('Error', res.data.message, 'error')
        else
          this.$swal(
            'Error',
            'Something wrong with the server. Cannot get user data.',
            'error'
          )
      } else {
        this.user = res.data
        console.log(res.data)
      }
    },
    async fetchMenus() {
      this.loading_menu = true
      let res = await this.getMenus()

      this.checkedMenus = []

      if (res.status == 'error') {
        this.menus = []
      } else {
        let menus = res.data
        let checked_menus = this.user.menu
        
        this.menus = menus
        this.checkedMenus = this.mergeMenus(menus, checked_menus)
      }

      this.loading_menu = false
    },
    mergeMenus(menus, checked_menus) {
      let merged_menus = []

      menus.forEach((menu) => {
        let sub_menus = []
        let found_menu = checked_menus.find((m) => m.menuId == menu.menuId)

        if (menu.child.length > 0) {
          menu.child.forEach((sub_menu) => {
            let tabs = []
            let found_sub_menu = null

            if (found_menu)
              found_sub_menu = found_menu.child.find(
                (m) => m.menuId == sub_menu.menuId
              )

            if (sub_menu.child.length > 0) {
              sub_menu.child.forEach((tab) => {
                let found_tab = []

                if (found_sub_menu)
                  found_tab = found_sub_menu.child.find(
                    (m) => m.menuId == tab.menuId
                  )

                let to_push_tabs = {
                  menuId: tab.menuId,
                  active: false,
                  add: false,
                  update: false,
                  delete: false,
                  export: false,
                  review: false,
                  approval: false,
                }
                if (found_sub_menu) {
                  if (found_tab) {
                    to_push_tabs = {
                      menuId: found_tab.menuId,
                      active: found_tab.active,
                      add: found_tab.active ? found_tab.add : false,
                      update: found_tab.active ? found_tab.update : false,
                      delete: found_tab.active ? found_tab.delete : false,
                      export: found_tab.active ? found_tab.export : false,
                      review: found_tab.active ? found_tab.review : false,
                      approval: found_tab.active ? found_tab.approval : false,
                    }
                  }
                }

                tabs.push(to_push_tabs)
              })
            }

            let to_push_sub_menu = {
              menuId: sub_menu.menuId,
              active: false,
              add: false,
              update: false,
              delete: false,
              export: false,
              review: false,
              approval: false,
              child: tabs,
            }

            if (found_menu) {
              if (found_sub_menu) {
                to_push_sub_menu = {
                  menuId: found_sub_menu.menuId,
                  active: found_sub_menu.active,
                  add: found_sub_menu.active ? found_sub_menu.add : false,
                  update: found_sub_menu.active ? found_sub_menu.update : false,
                  delete: found_sub_menu.active ? found_sub_menu.delete : false,
                  export: found_sub_menu.active ? found_sub_menu.export : false,
                  review: found_sub_menu.active ? found_sub_menu.review : false,
                  approval: found_sub_menu.active
                    ? found_sub_menu.approval
                    : false,
                  child: tabs,
                }
              }
            }

            sub_menus.push(to_push_sub_menu)
          })
        }

        let to_push = {
          menuId: menu.menuId,
          active: false,
          add: false,
          update: false,
          delete: false,
          export: false,
          review: false,
          approval: false,
          child: sub_menus,
        }

        if (found_menu) {
          to_push = {
            menuId: found_menu.menuId,
            active: found_menu.active,
            add: found_menu.active ? found_menu.add : false,
            update: found_menu.active ? found_menu.update : false,
            delete: found_menu.active ? found_menu.delete : false,
            export: found_menu.active ? found_menu.export : false,
            review: found_menu.active ? found_menu.review : false,
            approval: found_menu.active ? found_menu.approval : false,
            child: sub_menus,
          }
        }

        merged_menus.push(to_push)
      })

      return merged_menus
    },
  },
}
</script>
