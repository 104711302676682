var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-warning"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "fas fa-file-invoice"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/procurement-report-gl"
          }
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("ALL JOURNAL")])])])], 1)])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-warning"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "fas fa-file-invoice"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("GENERAL LEDGER")])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-warning"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "fas fa-file-invoice"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/procurement-report-arus-kas"
          }
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("CASH FLOW")])])])], 1)])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-warning"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "fas fa-file-invoice"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/procurement-report-laba-rugi"
          }
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("PROFIT / LOSS")])])])], 1)])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("FILTER DATA")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.filter",
            modifiers: {
              "filter": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-4",
          attrs: {
            "visible": "",
            "id": "filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          staticStyle: {
            "heidght": "35px"
          },
          attrs: {
            "label": "Fleet Category",
            "label-for": "fleet_category"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Fleet Category",
            "label": "text",
            "track-by": "value",
            "options": _vm.fleetCategory,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.fleetCategorySelect,
            callback: function callback($$v) {
              _vm.fleetCategorySelect = $$v;
            },
            expression: "fleetCategorySelect"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet",
            "label-for": "fleet"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsFleet,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Fleet")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedFleet,
            callback: function callback($$v) {
              _vm.selectedFleet = $$v;
            },
            expression: "selectedFleet"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Ownership",
            "label-for": "ownership"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsOwnership,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Ownership")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedOwnership,
            callback: function callback($$v) {
              _vm.selectedOwnership = $$v;
            },
            expression: "selectedOwnership"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Company Name",
            "label-for": "companyName"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionCompany,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Company")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedCompany,
            callback: function callback($$v) {
              _vm.selectedCompany = $$v;
            },
            expression: "selectedCompany"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Period",
            "label-for": "Period"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        }), _vm._v(" Filter Data")])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("SALES DASHBOARD")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('HighChart', {
          ref: _vm.salesChartCargo.type,
          attrs: {
            "option": _vm.salesChartCargo.bodyData
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('HighChart', {
          ref: _vm.salesChartTrip.type,
          attrs: {
            "option": _vm.salesChartTrip.bodyData
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('HighChart', {
          ref: _vm.salesChartReveneu.type,
          attrs: {
            "option": _vm.salesChartReveneu.bodyData
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("CARGO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TRIP")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("AMOUNT")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. 001")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("85.000 Tons")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("25 Trips")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1.524..000.000")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. 002")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("73.000 Tons")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("21 Trips")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1.763.000.000")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. 003")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("26.000 Tons")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("18 Trips")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("539.053.044")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. 004")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("34.000 Tons")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("35 Trips")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("672.000.000")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. 005")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("56.000 Tons")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15 Trips")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("88.472.200")])]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("351.000 Tons")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("102 Trips")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("150.472.200.000")])])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1)])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("PURCHASE DASHBOARD")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.filter",
            modifiers: {
              "filter": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('HighChart', {
          ref: _vm.purchaseChart.type,
          attrs: {
            "option": _vm.purchaseChart.bodyData
          }
        })], 1), _c('b-col', {
          staticClass: "mt-5",
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("FLEET")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("BUNGKER")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("HSE EQUIPMENT")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("RUNNING STORE")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("MAINTENANCE")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("SPAREPART")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("REPAIR")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LUB OIL")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. 001")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1.541.612.785")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("11.875.000")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1.200.000")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1.355.000")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("6.900.000")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("10.990.000")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("5.432.000")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. 002")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("516.000.000")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1.885.000")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("13.000.000")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("180.500")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("4.917.200")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("14.094.200")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("13.000.000")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. 003")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("707.777.600")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("6.602.500")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1.202.700")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("617.500")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("11.942.853")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("60.320.250")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("8.260.000")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. 004")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("88.472.200")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("14.725.000")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2.898.000")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("180.500")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("53.614.650")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("14.402.000")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("532.000")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. 005")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("88.472.200")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("14.725.000")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2.898.000")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("180.500")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("53.614.650")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("14.402.000")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("532.000")])]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("ALL FLEET")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("36.837.810.000")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("771.014.725.000")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("212.000.898.000")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("180.029.000.500")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("102.053.614.650")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("14.000.402.000")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("532.000.000")])])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1)])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }