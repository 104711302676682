<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items
                  class="col-md-4 p-0"
                  :active="true"
                  href="#crew"
                  title="CREW"
                />
                <tab-nav-items
                  class="col-md-4 p-0"
                  :active="false"
                  href="#candidate"
                  title="CANDIDATE"
                />
                <tab-nav-items
                  class="col-md-4 p-0"
                  :active="false"
                  href="#blacklist"
                  title="BLACK LIST"
                />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <div class="iq-edit-list-data">
          <div id="pills-tabContent-2" class="tab-content">
            <!-- CREW -->
            <tab-content-item :active="true" id="crew">
              <iq-card>
                <template v-slot:headerTitle>
                  <h5 class="card-title text-primary"
                    ><strong>LIST CREW</strong></h5
                  >
                </template>
                <template v-slot:headerAction>
                  <router-link to="/crewing/add-crew">
                    <b-button variant="primary" class="mr-3"
                      ><i class="fa fa-users"></i> &nbsp; Add Crew</b-button
                    >
                  </router-link>
                  <a
                    class="text-primary float-right"
                    v-b-toggle.collapse-filter
                    role="button"
                    style="cursor: pointer"
                  >
                    <i class="fa fa-filter" />
                  </a>
                </template>
                <template v-slot:body>
                  <b-collapse visible id="collapse-filter" class="mb-2">
                    <b-row>
                      <b-col md="2">
                        <b-form-group label="Search" label-for="search">
                          <b-form-input
                            v-model="crewList.params.fullname"
                            type="text"
                            placeholder="Ketikan Nama"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="Fleet" label-for="Fleet">
                          <b-form-select
                            plain
                            v-model="crewList.params.vehicle_id"
                            :options="fleetOptions"
                            size="lg"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Pilih Kapal</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="Jabatan" label-for="jabatan">
                          <b-form-select
                            plain
                            v-model="crewList.params.position_id"
                            :options="level"
                            size="lg"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Pilih Jabatan</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <!-- <b-col md="2">
                        <b-form-group label="Status" label-for="status">
                          <b-form-select
                            plain
                            v-model="selectedStatusCrew"
                            :options="statusCrew"
                            size="lg"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Pilih Status</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col> -->
                      <b-col md="3">
                        <b-form-group label="Sign Off" label-for="Sign Off">
                          <date-picker
                            @change="onCrewChangeDate"
                            :lang="lang"
                            v-model="dateRange"
                            type="date"
                            range
                            placeholder="Select date range"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col md="1">
                        <b-form-group label="Filter" label-for="button_search">
                          <b-button
                            @click="filterCrewList"
                            variant="primary"
                            class="mb-3 mr-1"
                            ><i class="fa fa-filter"></i
                          ></b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-collapse>
                  <!-- TABLE CONTENT CREWLIST -->
                  <TableContentCrewList
                    :table-data="crewList.data"
                    v-model="form_crew"
                    :current-page="crewList.meta.currentPage"
                  />
                  <div class="mt-3">
                    <b-pagination
                      v-model="crewList.meta.currentPage"
                      :total-rows="crewList.meta.total"
                      :per-page="crewList.meta.perPage"
                      first-number
                      @change="getCrewList"
                      align="center"
                    />
                  </div>
                </template>
              </iq-card>
            </tab-content-item>
            <!-- CANDIDATE -->
            <tab-content-item :active="false" id="candidate">
              <iq-card>
                <template v-slot:headerTitle>
                  <h5 class="card-title text-primary"
                    ><strong>CANDIDATE</strong></h5
                  >
                </template>
                <template v-slot:headerAction>
                  <router-link to="/crewing/add-candidate">
                    <b-button variant="primary" class="mr-3"
                      ><i class="fa fa-users"></i> &nbsp; Add
                      Candidate</b-button
                    >
                  </router-link>
                  <a
                    class="text-primary float-right"
                    v-b-toggle.collapse-filter
                    role="button"
                    style="cursor: pointer"
                  >
                    <i class="fa fa-filter" />
                  </a>
                </template>
                <template v-slot:body>
                  <b-collapse visible id="collapse-filter" class="mb-2">
                    <b-row>
                      <b-col md="2">
                        <b-form-group label="Search" label-for="search">
                          <b-form-input
                            v-model="candidateList.params.full_name"
                            type="text"
                            placeholder="Ketikan Nama"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="Jabatan" label-for="jabatan">
                          <b-form-select
                            plain
                            v-model="candidateList.params.position_id"
                            :options="level"
                            size="lg"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Pilih Jabatan</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group
                          label="Jadawal Interview"
                          label-for="jadawal-interview"
                        >
                          <date-picker
                            @change="onCandidateChangeDate"
                            :lang="lang"
                            v-model="dateRange"
                            type="datetime"
                            range
                            placeholder="Select date range"
                          ></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="Status" label-for="status">
                          <b-form-select
                            plain
                            v-model="candidateList.params.status"
                            :options="statusCandidate"
                            size="lg"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Status</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="1">
                        <b-form-group label="Filter" label-for="button_search">
                          <b-button
                            @click="filterCandidateList"
                            variant="primary"
                            class="mb-3 mr-1"
                            ><i class="fa fa-filter"></i
                          ></b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-collapse>
                  <!-- TABLE CONTENT CANDIDATELIST -->
                  <TableContentCandidateList
                    :table-data="candidateList.data"
                    v-model="form_candidate"
                    :current-page="candidateList.meta.currentPage"
                  />
                  <div class="mt-3" v-if="candidateList.data.body.length > 0">
                    <b-pagination
                      v-model="candidateList.meta.currentPage"
                      :total-rows="candidateList.meta.total"
                      :per-page="candidateList.meta.perPage"
                      first-number
                      @change="getCandidateList"
                      align="center"
                    />
                  </div>
                  <p v-else class="text-center my-4"
                    >List Candidate not found.</p
                  >
                  <!-- SET INTERVIEW -->
                  <b-modal
                    id="setup-interview"
                    size="lg"
                    title="Set Interview"
                    @ok="onSaveSetInterview"
                    ok-title="save"
                    cancel-title="Close"
                    :ok-disabled="saving"
                  >
                    <form>
                      <b-row>
                        <b-col md="6">
                          <b-form-group
                            label="Expert User *"
                            label-for="expert_user"
                          >
                            <v-select
                              v-model="
                                form.setInterviewCandidate.expert_user_id
                              "
                              label="text"
                              :options="optionsExpertUsers"
                              :reduce="
                                (optionsExpertUsers) => optionsExpertUsers.value
                              "
                              placeholder="Select Type of Expert User"
                              required
                            ></v-select>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group
                            label="Interview Date & Time"
                            label-for="interview_date"
                          >
                            <date-picker
                              required
                              v-model="
                                form.setInterviewCandidate.date_interview
                              "
                              type="datetime"
                              placeholder="Select date range"
                              format="DD-MM-YYYY HH:mm"
                            ></date-picker>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group
                            label="for Position *"
                            label-for="expert_user"
                          >
                            <b-form-select
                              plain
                              v-model="form.setInterviewCandidate.position_id"
                              :options="optionsPosition"
                              size="lg"
                              required
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Select Position</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group label="Lokasi" label-for="expert_user">
                            <b-form-input
                              type="text"
                              v-model="form.setInterviewCandidate.location"
                              required
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label="Urgensi"
                            label-for="interview_date"
                          >
                            <template v-for="(item, index) in typeInterview">
                              <b-form-radio
                                v-model="form.setInterviewCandidate.urgensi"
                                class="custom-radio-color-checked"
                                inline
                                :color="item.color"
                                name="color"
                                :key="index"
                                :value="item.value"
                                :disabled="item.disabled"
                                required
                                ><strong
                                  ><h5>{{ item.label }}</h5></strong
                                ></b-form-radio
                              >
                            </template>
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label="Notes"
                            label-for="interview_date"
                            required
                          >
                            <wysiwyg
                              v-model="
                                form.setInterviewCandidate.notes_set_interview
                              "
                            />
                          </b-form-group>
                        </b-col>
                        <b-col md="12">
                          <h3>Eskalasi Notification User</h3>
                          <hr />
                        </b-col>
                        <b-col md="12">
                          <b-form-group
                            label="Expert User *"
                            label-for="expert_user"
                          >
                            <div
                              class="mb-3 align-content-center w-auto d-flex"
                              v-for="(state, index) in form
                                .setInterviewCandidate.notification"
                              v-bind:key="index"
                            >
                              <v-select
                                v-model="state.expert_user_id"
                                class="w-100 mr-2"
                                label="text"
                                :options="optionsExpertUsers"
                                :reduce="
                                  (optionsExpertUsers) =>
                                    optionsExpertUsers.value
                                "
                                placeholder="Select Type of Expert User"
                              ></v-select>
                              <b-button
                                class="w-20"
                                v-if="
                                  form.setInterviewCandidate.notification
                                    .length > 1 && index > 0
                                "
                                variant="danger"
                                size="sm"
                                @click="() => deleteNotificationUser(index)"
                                ><i class="fa fa-trash"></i>
                              </b-button>
                            </div>
                          </b-form-group>
                          <b-button
                            block
                            variant="info"
                            size="md"
                            @click="addNotificationUser"
                            ><i class="fa fa-plus"></i> Add More Data
                          </b-button>
                        </b-col>
                      </b-row>
                    </form>
                  </b-modal>
                  <!-- SET INTERVIEW END -->
                  <!-- MODAL VERIFICATION DOCUMENT -->
                  <b-modal
                    id="verification_document"
                    size="md"
                    title="Verification Document"
                    @ok="onSaveVerificationDocument"
                    ok-title="Verification Document"
                    cancel-title="Close"
                  >
                    <b-row>
                      <b-col md="12">
                        <table class="table table-striped">
                          <tbody>
                            <tr class="bg-info">
                              <td colspan="2" class="text-center"
                                ><strong>LIST DOCUMENT</strong></td
                              >
                            </tr>
                            <tr
                              v-for="(state, index) in master_list_document"
                              v-bind:key="index"
                            >
                              <td width="90%"
                                ><strong>{{ state?.document_name }}</strong></td
                              >
                              <td width="10%">
                                <i
                                  v-if="state?.status_checklist === 1"
                                  class="fa fa-check text-primary"
                                ></i>
                                <i v-else class="fa fa-ban text-primary"></i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                    <div
                      v-if="loading"
                      class="d-flex justify-content-center w-100"
                    >
                      <span class="spinner-border spinner-border-lg" />
                    </div>
                  </b-modal>
                  <!-- MODAL VERIFICATION DOCUMENT END -->
                  <!-- MODAL INDUCTION -->
                  <b-modal
                    id="induction"
                    size="md"
                    title="Induction Checklist"
                    :ok-disabled="saving"
                    @ok="onSaveInduction"
                    ok-title="Submit"
                    cancel-title="Close"
                  >
                    <b-row
                      v-for="(state, index) in department_form"
                      v-bind:key="index"
                    >
                      <b-col md="12">
                        <b-card no-body class="mb-1">
                          <b-card-header
                            header-tag="header"
                            class="p-1"
                            role="tab"
                          >
                            <b-button
                              :disabled="state?.checked ? true : false"
                              block
                              v-b-toggle="`accordion-` + index"
                              :variant="`${
                                state?.checked ? 'success' : 'info'
                              }`"
                              :class="`${
                                state?.checked &&
                                `justify-content-between d-flex align-items-center`
                              }`"
                              ><span>{{ state?.name }}</span>
                              <i v-if="state?.checked" class="fas fa-check"></i>
                            </b-button>
                          </b-card-header>
                          <b-collapse
                            :id="`accordion-` + index"
                            :visible="false"
                            accordion="my-accordion"
                            role="tabpanel"
                          >
                            <b-card-body>
                              <b-form-group
                                label="Induksi Oleh"
                                label-for="induction_by"
                              >
                                <input
                                  type="text"
                                  class="form-control"
                                  id="induction_by"
                                  v-model="state.induction_by"
                                />
                              </b-form-group>
                              <b-form-group
                                label="Tanggal Induksi"
                                label-for="induction_date"
                              >
                                <input
                                  type="date"
                                  class="form-control"
                                  id="induction_date"
                                  v-model="state.induction_date"
                                />
                              </b-form-group>
                              <b-form-group
                                label="Attachment"
                                label-for="attachment"
                              >
                                <div class="custom-file">
                                  <input
                                    type="file"
                                    class="custom-file-input"
                                    id="validatedCustomFile"
                                    @change="
                                      (event) =>
                                        prefiewFilesInduction(event, state)
                                    "
                                  />
                                  <label
                                    class="custom-file-label"
                                    for="validatedCustomFile"
                                    v-if="!state.attachment"
                                    >Browse file...</label
                                  >
                                  <label
                                    class="custom-file-label"
                                    for="validatedCustomFile"
                                    v-else
                                    >{{ state.attachment_label }}</label
                                  >
                                </div>
                              </b-form-group>
                              <b-form-group label="Notes" label-for="notes">
                                <b-form-textarea
                                  id="notes"
                                  name="notes"
                                  rows="3"
                                  v-model="state.notes"
                                ></b-form-textarea>
                              </b-form-group>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </b-col>
                    </b-row>

                    <div
                      v-if="loading"
                      class="d-flex justify-content-center w-100"
                    >
                      <span class="spinner-border spinner-border-lg" />
                    </div>
                  </b-modal>
                  <!-- MODAL INDUCTION END -->
                  <!-- MEDICAL CHECKUP -->
                  <b-modal
                    id="medical-checkup"
                    size="md"
                    title="Medical Checkup"
                    @ok="onSaveMedichalCheckup"
                    ok-title="Submit"
                    cancel-title="Close"
                    :ok-disabled="saving"
                  >
                    <b-row
                      v-for="(state, index) in medical_checkup"
                      v-bind:key="index"
                    >
                      <b-col md="12">
                        <div
                          v-if="medical_checkup.length > 1 && index > 0"
                          class="d-flex w-100 px-4 my-1 justify-content-end cursor-pointer position-absolute top-0"
                          @click="deleteMedicalCheckupForm(index)"
                        >
                          <i class="fa fa-trash"></i>
                        </div>
                        <b-form-group label="Attachment" label-for="attachment">
                          <div class="custom-file">
                            <input
                              type="file"
                              class="custom-file-input"
                              id="validatedCustomFile"
                              @change="(event) => prefiewFiles(event, state)"
                            />

                            <label
                              class="custom-file-label"
                              for="validatedCustomFile"
                              v-if="!state.file"
                              >Browse file...</label
                            ><label
                              class="custom-file-label"
                              for="validatedCustomFile"
                              v-else
                              >{{ state.label_file }}</label
                            >
                            <!-- this.medical_checkup -->
                          </div>
                        </b-form-group>
                        <b-form-group label="Notes" label-for="notes">
                          <b-form-textarea
                            id="notes"
                            name="notes"
                            rows="3"
                            v-model="state.nama_document"
                          ></b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-button
                          variant="success"
                          class="mt-1"
                          @click="addMedicalCheckupForm"
                          ><i class="fa fa-plus"></i> ADD FORM</b-button
                        >
                      </b-col>
                    </b-row>
                  </b-modal>
                  <!-- MEDICAL CHECKUP END -->
                </template>
              </iq-card>
            </tab-content-item>
            <!-- BLACKLIST -->
            <tab-content-item :active="false" id="blacklist">
              <iq-card>
                <template v-slot:headerTitle>
                  <h5 class="card-title text-primary"
                    ><strong>BLACK LIST</strong></h5
                  >
                </template>
                <template v-slot:headerAction>
                  <a
                    class="text-primary float-right"
                    v-b-toggle.collapse-filter
                    role="button"
                    style="cursor: pointer"
                  >
                    <i class="fa fa-filter" />
                  </a>
                </template>
                <template v-slot:body>
                  <b-collapse visible id="collapse-filter" class="mb-2">
                    <b-row>
                      <b-col md="2">
                        <b-form-group label="Search" label-for="search">
                          <b-form-input
                            v-model="blackList.params.full_name"
                            type="text"
                            placeholder="Ketikan Nama"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group label="Jabatan" label-for="jabatan">
                          <b-form-select
                            plain
                            v-model="levelSelected"
                            :options="level"
                            size="lg"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Pilih Jabatan</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="1">
                        <b-form-group label="Filter" label-for="button_search">
                          <b-button
                            @click="getBlackList(null)"
                            variant="primary"
                            class="mb-3 mr-1"
                            ><i class="fa fa-filter"></i
                          ></b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-collapse>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th width="10%" class="text-center">PHOTO</th>
                        <th width="15%" class="text-center">NAMA</th>
                        <th width="15%" class="text-center">JABATAN</th>
                        <th width="10%" class="text-center">VESSEL</th>
                        <th width="15%" class="text-center">COMPANY</th>
                        <th width="15%" class="text-center">REASON OFF</th>
                        <th width="20%" class="text-center">REMARKS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in blackList.data" :key="index">
                        <td class="text-center"
                          ><b-img
                            thumbnail
                            class="ml-3"
                            :src="
                              data.photo ||
                              require('../../assets/images/page-img/10.jpg')
                            "
                            alt="Responsive image"
                          ></b-img
                        ></td>
                        <td class="text-center"
                          >{{ data.full_name }} <br /><small style="color: gray"
                            >NIP : <b>{{ data.nip }}</b
                            ><br />Seaferer Code :
                            <b>{{ data.seaferer_code }}</b></small
                          ></td
                        >
                        <td class="text-center">{{ data.name_position }}</td>
                        <td class="text-center"
                          >{{ data.vehicle }}<br /><small style="color: gray"
                            >Port : Banjarmasin</small
                          ></td
                        >
                        <td class="text-center">{{ data.company }}</td>
                        <td class="text-center"
                          ><b-badge variant="warning">{{
                            data.reason_off
                          }}</b-badge></td
                        >
                        <td>{{ data.remarks || 'Tanpa keterangan' }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-3">
                    <b-pagination
                      v-model="blackList.meta.currentPage"
                      :total-rows="blackList.meta.total"
                      :per-page="blackList.meta.perPage"
                      first-number
                      @change="getBlackList"
                      align="center"
                    />
                  </div>
                </template>
              </iq-card>
            </tab-content-item>

            <!-- FORM SIGN ON -->
            <b-sidebar
              id="sign-on"
              title="SIGN ON CANDIDATE"
              width="70%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow
              ref="formSignOn"
            >
              <template #default="{ hide }">
                <form action="#" method="post" @submit="onSignOnSave">
                  <b-row class="m-3">
                    <b-col cols="12" class="text-right">
                      <b-button variant="primary"
                        ><i class="fa fa-download"></i> DOWNLOAD DOCUMENT
                        CONTRACT</b-button
                      >
                    </b-col>
                    <b-col md="12">
                      <!-- INFORMASI CANDIDATE -->
                      <fieldset
                        class="form-group border p-3"
                        v-if="currentStep === 0"
                      >
                        <legend class="w-auto px-2" style="font-size: 14pt"
                          >Informasi Crew</legend
                        >
                        <b-row>
                          <b-col md="6">
                            <b-form>
                              <div class="form-group row">
                                <label
                                  label="nama_crew"
                                  for="nama_crew"
                                  class="col-sm-6"
                                  ><strong>Nama Crew</strong></label
                                >
                                <div class="col-sm-6">
                                  {{
                                    candidateProfile?.personal_data
                                      ?.full_name ?? '-'
                                  }}
                                </div>
                              </div>
                              <div class="form-group row">
                                <label label="nik" for="nik" class="col-sm-6"
                                  ><strong>NIK</strong></label
                                >
                                <div class="col-sm-6">
                                  {{
                                    candidateProfile?.personal_data?.nik_ktp ??
                                    '-'
                                  }}
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  label="jabatan"
                                  for="jabatan"
                                  class="col-sm-6"
                                  ><strong>Jabatan</strong></label
                                >
                                <div class="col-sm-6">
                                  {{
                                    candidateProfile?.personal_data?.position
                                      ?.name ?? '-'
                                  }}
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  label="seaman_book"
                                  for="seaman_book"
                                  class="col-sm-6"
                                  ><strong>Seaman Book</strong></label
                                >
                                <div class="col-sm-6">
                                  {{
                                    candidateProfile?.personal_data
                                      ?.seaman_book_number ?? '-'
                                  }}
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  label="seafarer_code"
                                  for="seafarer_code"
                                  class="col-sm-6"
                                  ><strong>Seaferer Code</strong></label
                                >
                                <div class="col-sm-6">
                                  {{
                                    candidateProfile?.personal_data
                                      ?.seaferer_code ?? '-'
                                  }}
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  label="passport_number"
                                  for="passport_number"
                                  class="col-sm-6"
                                  ><strong>Passport Number</strong></label
                                >
                                <div class="col-sm-6">
                                  {{
                                    candidateProfile?.personal_data
                                      ?.passport_number ?? '-'
                                  }}
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  label="file_contract"
                                  for="file_contract"
                                  class="col-sm-6"
                                  ><strong>Attachment File</strong></label
                                >
                                <div class="col-sm-6">
                                  <b-form-file
                                    plain
                                    id="file-contract"
                                    v-model="form_signon.attachment"
                                    :state="Boolean(form_signon.attachment)"
                                  ></b-form-file>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label
                                  label="file_contract"
                                  for="file_contract"
                                  class="col-sm-6"
                                  ><strong>Notes</strong></label
                                >
                                <div class="col-sm-6">
                                  <input
                                    type="text"
                                    id="join_onboard"
                                    class="form-control"
                                    v-model="form_signon.remarks"
                                    placeholder="notes..."
                                    required
                                  />
                                </div>
                              </div>
                            </b-form>
                          </b-col>
                          <b-col md="6">
                            <div class="form-group row">
                              <label
                                label="perusahaan"
                                for="perusahaan"
                                class="col-sm-6"
                                ><strong>Perusahaan</strong></label
                              >
                              <b-form-group class="col-sm-6">
                                <b-form-select
                                  plain
                                  v-model="form_signon.company_id"
                                  :options="optionsCompanies"
                                  size="lg"
                                  class="col-md-12"
                                  required
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="null"
                                      >Pilih Perusahaan</b-form-select-option
                                    >
                                  </template>
                                </b-form-select>
                              </b-form-group>
                            </div>
                            <div class="form-group row">
                              <label
                                label="perusahaan"
                                for="perusahaan"
                                class="col-sm-6"
                                ><strong>Department</strong></label
                              >
                              <b-form-group class="col-sm-6">
                                <b-form-select
                                  plain
                                  v-model="form_signon.department_id"
                                  :options="optionsDepartment"
                                  size="lg"
                                  class="col-md-12"
                                  required
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="null"
                                      >Pilih Department</b-form-select-option
                                    >
                                  </template>
                                </b-form-select>
                              </b-form-group>
                            </div>
                            <div class="form-group row">
                              <label
                                label="Jabatan"
                                for="jabatan"
                                class="col-sm-6"
                                ><strong>Jabatan</strong></label
                              >
                              <b-form-group class="col-sm-6">
                                <b-form-select
                                  plain
                                  v-model="form_signon.position_id"
                                  :options="optionsPosition"
                                  size="lg"
                                  class="col-md-12"
                                  required
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="null"
                                      >Pilih Jabatan</b-form-select-option
                                    >
                                  </template>
                                </b-form-select>
                              </b-form-group>
                            </div>
                            <div class="form-group row">
                              <label
                                label="nama_kapal"
                                for="nama_kapal"
                                class="col-sm-6"
                                ><strong>Nama Kapal</strong></label
                              >
                              <div class="col-sm-6">
                                <b-form-select
                                  plain
                                  v-model="form_signon.vehicle_id"
                                  :options="optionsVehicles"
                                  size="lg"
                                  class="col-md-12"
                                  required
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="null"
                                      >Pilih Kapal</b-form-select-option
                                    >
                                  </template>
                                </b-form-select>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="nama_kapal"
                                for="nama_kapal"
                                class="col-sm-6"
                                ><strong>Work Area</strong></label
                              >
                              <div class="col-sm-6">
                                <b-form-select
                                  plain
                                  v-model="form_signon.work_area_id"
                                  :options="optionsWorkArea"
                                  size="lg"
                                  class="col-md-12"
                                  required
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="null"
                                      >Pilih Work Area</b-form-select-option
                                    >
                                  </template>
                                </b-form-select>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="pelabuhan"
                                for="pelabuhan"
                                class="col-sm-6"
                                ><strong>Pelabuhan</strong></label
                              >
                              <div class="col-sm-6">
                                <b-form-select
                                  plain
                                  v-model="form_signon.port_id"
                                  :options="optionsPorts"
                                  size="lg"
                                  class="col-md-12"
                                  required
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="null"
                                      >Pilih Pelabuhan</b-form-select-option
                                    >
                                  </template>
                                </b-form-select>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="sign_on"
                                for="sign_on"
                                class="col-sm-6"
                                ><strong>Sign On</strong></label
                              >
                              <div class="col-sm-6">
                                <b-form-input
                                  type="date"
                                  id="sign_on"
                                  v-model="form_signon.sign_on"
                                  required
                                ></b-form-input>
                                <small style="float: right"
                                  >click <i class="fa fa-calendar"></i> to
                                  show</small
                                >
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="join_onboard"
                                for="join_onboard"
                                class="col-sm-6"
                                ><strong>Join Onboard</strong></label
                              >
                              <div class="col-sm-6">
                                <b-form-input
                                  type="date"
                                  id="join_onboard"
                                  v-model="form_signon.join_on_board"
                                  required
                                ></b-form-input>
                                <small style="float: right"
                                  >click <i class="fa fa-calendar"></i> to
                                  show</small
                                >
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="naik_kapal"
                                for="naik_kapal"
                                class="col-sm-6"
                                ><strong>Sign Off / Turun Kapal</strong></label
                              >
                              <div class="col-sm-6">
                                <b-form-input
                                  type="date"
                                  id="naik_kapal"
                                  v-model="form_signon.sign_off"
                                  required
                                ></b-form-input>
                                <small style="float: right"
                                  >click <i class="fa fa-calendar"></i> to
                                  show</small
                                >
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </fieldset>
                      <!-- PAYROLL COMPONENT -->
                      <fieldset
                        class="form-group border p-3"
                        v-if="currentStep === 1"
                      >
                        <legend class="w-auto px-2" style="font-size: 14pt"
                          >Payroll Component</legend
                        >
                        <b-row>
                          <b-col md="12">
                            <div class="form-group row">
                              <label
                                label="gaji_pokok"
                                for="gaji_pokok"
                                class="col-sm-6"
                                ><strong>Basic Salary</strong></label
                              >
                              <div class="col-sm-6">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text bg-primary"
                                      id="rp"
                                      >Rp.</span
                                    >
                                  </div>
                                  <input
                                    type="number"
                                    class="form-control"
                                    id="gaji_pokok"
                                    v-model="form_signon.basic_sallary"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="gaji_pokok"
                                for="gaji_pokok"
                                class="col-sm-6"
                                ><strong>Overtime</strong></label
                              >
                              <div class="col-sm-6">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text bg-primary"
                                      id="rp"
                                      >Rp.</span
                                    >
                                  </div>
                                  <input
                                    type="number"
                                    class="form-control"
                                    id="gaji_pokok"
                                    v-model="form_signon.overtime"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="gaji_pokok"
                                for="gaji_pokok"
                                class="col-sm-6"
                                ><strong>Daily Fee</strong></label
                              >
                              <div class="col-sm-6">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text bg-primary"
                                      id="rp"
                                      >Rp.</span
                                    >
                                  </div>
                                  <input
                                    type="number"
                                    class="form-control"
                                    id="gaji_pokok"
                                    v-model="form_signon.daily_fee"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              class="form-group row"
                              v-for="(
                                state, index
                              ) in form_signon.allowance_fix"
                              v-bind:key="index"
                            >
                              <label
                                label="lembur"
                                for="lembur"
                                class="col-sm-6"
                                ><strong>{{ state?.text }}</strong></label
                              >
                              <div class="col-sm-6">
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <span
                                      class="input-group-text bg-primary"
                                      id="rp"
                                      >Rp.</span
                                    >
                                  </div>
                                  <input
                                    type="number"
                                    class="form-control"
                                    id="uang_lembur"
                                    v-model="state.amount"
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </fieldset>
                      <!-- BPJS SETTING -->
                      <fieldset
                        class="form-group border p-3"
                        v-if="currentStep === 2"
                      >
                        <legend class="w-auto px-2" style="font-size: 14pt"
                          >BPJS Setting</legend
                        >
                        <b-row>
                          <b-col md="6">
                            <div
                              class="d-flex justify-content-between align-items-center w-100"
                            >
                              <legend style="font-size: 14pt"
                                >BPJS Ketenagakerjaan</legend
                              >
                              <b-form-checkbox
                                id="bpjstk_config"
                                v-model="form_signon.bpjstk"
                                :value="1"
                                :unchecked-value="0"
                              >
                              </b-form-checkbox>
                            </div>
                            <div v-if="form_signon.bpjstk === 1">
                              <div class="form-group">
                                <label
                                  label="gaji_pokok"
                                  for="gaji_pokok"
                                  class="col-sm-12 px-2"
                                  ><strong>Basis Penggali</strong></label
                                >
                                <div class="px-2 row">
                                  <div class="col-sm-6">
                                    <b-form-select
                                      plain
                                      :options="optionsSetting"
                                      size="lg"
                                      class="col-md-12"
                                      v-model="form_signon.bpjstk_config"
                                    >
                                    </b-form-select>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.bpjstk_config === 1"
                                  >
                                    <b-form-select
                                      plain
                                      :options="optionsGaji"
                                      size="lg"
                                      class="col-md-12"
                                      v-model="form_signon.basispengali_bpjstk"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="null"
                                          >Pilih Gaji</b-form-select-option
                                        >
                                      </template>
                                    </b-form-select>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label
                                  label="gaji_pokok"
                                  for="gaji_pokok"
                                  class="col-sm-12 px-2"
                                  ><strong>JHT Configuration</strong></label
                                >
                                <div class="px-2 row">
                                  <div class="col-sm-12 mb-2">
                                    <b-form-select
                                      plain
                                      :options="optionsSetting"
                                      size="lg"
                                      class="col-md-12"
                                      required
                                      v-model="form_signon.jht_config"
                                    >
                                    </b-form-select>
                                  </div>

                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.jht_config === 1"
                                  >
                                    <label
                                      label="gaji_pokok"
                                      for="gaji_pokok"
                                      class="col-xs-12"
                                      >JHT (DITANGGUNG PERUSAHAAN)</label
                                    >
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id="uang_lembur"
                                        v-model="
                                          form_signon.jht_company_set_value
                                        "
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text bg-primary"
                                          id="rp"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.jht_config === 1"
                                  >
                                    <label
                                      label="gaji_pokok"
                                      for="gaji_pokok"
                                      class="col-xs-12"
                                      >JHT (DITANGGUNG KARYAWAN)</label
                                    >
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id="uang_lembur"
                                        v-model="
                                          form_signon.jht_employee_set_value
                                        "
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text bg-primary"
                                          id="rp"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label
                                  label="gaji_pokok"
                                  for="gaji_pokok"
                                  class="col-sm-12 px-2"
                                  ><strong>JP Configuration</strong></label
                                >
                                <div class="px-2 row">
                                  <div class="col-sm-12 mb-2">
                                    <b-form-select
                                      plain
                                      :options="optionsSetting"
                                      size="lg"
                                      class="col-md-12"
                                      required
                                      v-model="form_signon.jp_config"
                                    >
                                    </b-form-select>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.jp_config === 1"
                                  >
                                    <label
                                      label="gaji_pokok"
                                      for="gaji_pokok"
                                      class="col-xs-12"
                                      >JP (DITANGGUNG PERUSAHAAN)</label
                                    >
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id="uang_lembur"
                                        v-model="
                                          form_signon.jp_company_set_value
                                        "
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text bg-primary"
                                          id="rp"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.jp_config === 1"
                                  >
                                    <label
                                      label="gaji_pokok"
                                      for="gaji_pokok"
                                      class="col-xs-12"
                                      >JP (DITANGGUNG KARYAWAN)</label
                                    >
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id="uang_lembur"
                                        v-model="
                                          form_signon.jp_employee_set_value
                                        "
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text bg-primary"
                                          id="rp"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-12 px-2 mb-2">
                                  <label
                                    label="gaji_pokok"
                                    for="gaji_pokok"
                                    class="col-xs-12"
                                    ><strong
                                      >NILAI MAKSIMAL PENGGALI JAMINAN PENSIUN
                                      (JP)</strong
                                    ></label
                                  >
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span
                                        class="input-group-text bg-primary"
                                        id="rp"
                                        >Rp.</span
                                      >
                                    </div>
                                    <input
                                      type="number"
                                      class="form-control"
                                      id="uang_lembur"
                                      v-model="form_signon.jp_max_gaji"
                                    />
                                  </div>
                                </div>
                                <div class="col-sm-12 px-2 mb-2">
                                  <label
                                    label="gaji_pokok"
                                    for="gaji_pokok"
                                    class="col-xs-12"
                                    ><strong
                                      >APAKAH UNTUK KARYAWAN BERKEWARGANEGARAAN
                                      ASING DIHITUNG JP ATAU TIDAK?</strong
                                    ></label
                                  >
                                  <b-form-select
                                    plain
                                    size="lg"
                                    class="col-md-12"
                                    v-model="form_signon.jp_wna"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="null"
                                        >Select Option</b-form-select-option
                                      >
                                      <b-form-select-option value="YES"
                                        >Ya</b-form-select-option
                                      >
                                      <b-form-select-option value="NO"
                                        >Tidak</b-form-select-option
                                      >
                                    </template>
                                  </b-form-select>
                                </div>

                                <div class="col-sm-12 px-2 mb-2">
                                  <label
                                    label="gaji_pokok"
                                    for="gaji_pokok"
                                    class="col-xs-12"
                                    ><strong
                                      >MAX USIA YANG DICOVER BPJS
                                      KETENAGAKERJAAN</strong
                                    ></label
                                  >
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span
                                        class="input-group-text bg-primary"
                                        id="rp"
                                        >Usia</span
                                      >
                                    </div>
                                    <input
                                      type="number"
                                      class="form-control"
                                      id="uang_lembur"
                                      v-model="form_signon.jp_max_usia"
                                    />
                                  </div>
                                </div>
                                <div class="col-sm-12 px-2 mb-2">
                                  <label
                                    label="gaji_pokok"
                                    for="gaji_pokok"
                                    class="col-xs-12"
                                    ><strong
                                      >APAKAH UNTUK KARYAWAN DENGAN USIA > 56
                                      TAHUN DIHITUNG JP ATAU TIDAK?</strong
                                    ></label
                                  >
                                  <b-form-select
                                    plain
                                    size="lg"
                                    class="col-md-12"
                                    v-model="form_signon.jp_lebihusia_max"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="null"
                                        >Select Option
                                      </b-form-select-option>
                                      <b-form-select-option value="YES"
                                        >Ya</b-form-select-option
                                      >
                                      <b-form-select-option value="NO"
                                        >Tidak</b-form-select-option
                                      >
                                    </template>
                                  </b-form-select>
                                </div>
                              </div>

                              <div class="form-group">
                                <label
                                  label="gaji_pokok"
                                  for="gaji_pokok"
                                  class="col-sm-12 px-2"
                                  ><strong>JKM Configuration</strong></label
                                >
                                <div class="px-2 row">
                                  <div class="col-sm-6">
                                    <b-form-select
                                      plain
                                      :options="optionsSetting"
                                      size="lg"
                                      class="col-md-12"
                                      v-model="form_signon.jkm_config"
                                    >
                                    </b-form-select>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.jkm_config === 1"
                                  >
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id="uang_lembur"
                                        v-model="form_signon.jkm_set_value"
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text bg-primary"
                                          id="rp"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label
                                  label="gaji_pokok"
                                  for="gaji_pokok"
                                  class="col-sm-12 px-2"
                                  ><strong>JKK Configuration</strong></label
                                >
                                <div class="px-2 row">
                                  <div class="col-sm-6">
                                    <b-form-select
                                      plain
                                      :options="optionsSetting"
                                      size="lg"
                                      class="col-md-12"
                                      v-model="form_signon.jkk_config"
                                    >
                                    </b-form-select>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.jkk_config === 1"
                                  >
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id="uang_lembur"
                                        v-model="form_signon.jkk_set_value"
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text bg-primary"
                                          id="rp"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                          <b-col md="6">
                            <div
                              class="d-flex justify-content-between align-items-center w-100"
                            >
                              <legend style="font-size: 14pt"
                                >BPJS Kesehatan</legend
                              >

                              <b-form-checkbox
                                id="bpjsksh_config"
                                v-model="form_signon.bpjsksh"
                                :value="1"
                                :unchecked-value="0"
                              >
                              </b-form-checkbox>
                            </div>

                            <div v-if="form_signon.bpjsksh === 1">
                              <div class="form-group">
                                <label
                                  label="gaji_pokok"
                                  for="gaji_pokok"
                                  class="col-sm-12 px-2"
                                  ><strong>Basis Penggali</strong></label
                                >
                                <div class="px-2 row">
                                  <div class="col-sm-6">
                                    <b-form-select
                                      plain
                                      :options="optionsSetting"
                                      size="lg"
                                      class="col-md-12"
                                      v-model="
                                        form_signon.basispengali_bpjsksh_config
                                      "
                                    >
                                    </b-form-select>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="
                                      form_signon.basispengali_bpjsksh_config ===
                                      1
                                    "
                                  >
                                    <b-form-select
                                      plain
                                      :options="optionsGaji"
                                      size="lg"
                                      class="col-md-12"
                                      v-model="form_signon.basispengali_bpjsksh"
                                    >
                                      <template v-slot:first>
                                        <b-form-select-option :value="null"
                                          >Pilih Gaji</b-form-select-option
                                        >
                                      </template>
                                    </b-form-select>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <label
                                  label="gaji_pokok"
                                  for="gaji_pokok"
                                  class="col-sm-12 px-2"
                                  ><strong>BPJS Configuration</strong></label
                                >

                                <div class="px-2 row">
                                  <div class="col-sm-12 mb-2">
                                    <b-form-select
                                      plain
                                      :options="optionsSetting"
                                      size="lg"
                                      class="col-md-12"
                                      required
                                      v-model="form_signon.bpjsksh_config"
                                    >
                                    </b-form-select>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.bpjsksh_config === 1"
                                  >
                                    <label
                                      label="gaji_pokok"
                                      for="gaji_pokok"
                                      class="col-xs-12"
                                      >BPJS KSH (DITANGGUNG PERUSAHAAN)</label
                                    >
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id="uang_lembur"
                                        v-model="
                                          form_signon.bpjsksh_company_set_value
                                        "
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text bg-primary"
                                          id="rp"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="col-sm-6"
                                    v-if="form_signon.bpjsksh_config === 1"
                                  >
                                    <label
                                      label="gaji_pokok"
                                      for="gaji_pokok"
                                      class="col-xs-12"
                                      >BPJS KSH (DITANGGUNG KARYAWAN)</label
                                    >
                                    <div class="input-group">
                                      <input
                                        type="number"
                                        class="form-control"
                                        id="uang_lembur"
                                        v-model="
                                          form_signon.bpjsksh_employee_set_value
                                        "
                                      />
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text bg-primary"
                                          id="rp"
                                          >%</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="form-group">
                                <div class="col-sm-12 px-2 mb-2">
                                  <label
                                    label="gaji_pokok"
                                    for="gaji_pokok"
                                    class="col-xs-12"
                                    ><strong
                                      >MAX GAJI BPJS Kesehatan</strong
                                    ></label
                                  >
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span
                                        class="input-group-text bg-primary"
                                        id="rp"
                                        >Rp.</span
                                      >
                                    </div>
                                    <input
                                      type="number"
                                      class="form-control"
                                      id="uang_lembur"
                                      v-model="form_signon.max_gaji_bpjsksh"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col>
                        </b-row>
                      </fieldset>
                      <!-- ONSAVE -->
                      <b-row>
                        <b-col md="4"
                          ><b-button
                            :variant="`${
                              currentStep === 0 ? 'secondary' : 'primary'
                            }`"
                            :disabled="currentStep === 0"
                            block
                            @click="prevStep"
                            type="button"
                            ><i class="fa fa-chevron-left"></i> PREVIOUS FORM
                          </b-button>
                        </b-col>
                        <b-col md="8" v-if="currentStep !== 2"
                          ><b-button variant="primary" block type="submit">
                            NEXT FORM
                            <i class="fa fa-chevron-right"></i> </b-button
                        ></b-col>
                        <b-col md="8" v-if="currentStep === 2"
                          ><b-button
                            :disabled="saving"
                            variant="primary"
                            block
                            type="submit"
                          >
                            <span
                              v-if="saving"
                              class="spinner-border spinner-border-sm"
                            />
                            <i v-if="!saving" class="fa fa-file-signature"></i>
                            <span v-if="!saving">SUBMIT</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </form>
              </template>
            </b-sidebar>
            <!-- FORM SIGN ON END -->

            <!-- FORM SIGN OFF -->
            <b-sidebar
              id="sign-off"
              title="SIGN OFF CREW"
              width="50%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow
            >
              <template #default="{ hide }">
                <b-row class="m-3">
                  <b-col md="12">
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2" style="font-size: 14pt"
                        >Informasi Crew</legend
                      >
                      <b-row>
                        <b-col md="6">
                          <b-form>
                            <div class="form-group row">
                              <label
                                label="nama_crew"
                                for="nama_crew"
                                class="col-sm-6"
                                ><strong>Nama Crew</strong></label
                              >
                              <div class="col-sm-6">
                                {{ crewProfile?.fullname ?? '-' }}
                              </div>
                            </div>
                            <div class="form-group row">
                              <label label="nik" for="nik" class="col-sm-6"
                                ><strong>NIK</strong></label
                              >
                              <div class="col-sm-6">
                                {{ crewProfile?.no_ktp ?? '-' }}
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="jabatan"
                                for="jabatan"
                                class="col-sm-6"
                                ><strong>Jabatan</strong></label
                              >
                              <div class="col-sm-6">
                                {{ crewProfile?.position?.name ?? '-' }}
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="seaman_book"
                                for="seaman_book"
                                class="col-sm-6"
                                ><strong>Seaman Book</strong></label
                              >
                              <div class="col-sm-6">
                                {{
                                  crewProfile?.personal_data
                                    ?.seaman_book_number ?? '-'
                                }}
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="seafarer_code"
                                for="seafarer_code"
                                class="col-sm-6"
                                ><strong>Seafarer Code</strong></label
                              >
                              <div class="col-sm-6">
                                {{
                                  crewProfile?.personal_data?.seaferer_code ??
                                  '-'
                                }}
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="passport_number"
                                for="passport_number"
                                class="col-sm-6"
                                ><strong>Passport Number</strong></label
                              >
                              <div class="col-sm-6">
                                {{
                                  crewProfile?.personal_data?.passport_number ??
                                  '-'
                                }}
                              </div>
                            </div>
                          </b-form>
                        </b-col>
                        <b-col md="6">
                          <b-form>
                            <div class="form-group row">
                              <label
                                label="perusahaan"
                                for="perusahaan"
                                class="col-sm-6"
                                ><strong>Perusahaan</strong></label
                              >
                              <div class="col-sm-6"> N/A </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="nama_kapal"
                                for="nama_kapal"
                                class="col-sm-6"
                                ><strong>Nama Kapal</strong></label
                              >
                              <div class="col-sm-6">
                                {{ crewProfile?.vehicle?.name ?? '-' }}
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="pelabuhan"
                                for="pelabuhan"
                                class="col-sm-6"
                                ><strong>Pelabuhan</strong></label
                              >
                              <div class="col-sm-6">
                                {{ crewProfile?.port?.port_name ?? '-' }}
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="naik_kapal"
                                for="naik_kapal"
                                class="col-sm-6"
                                ><strong>Sign On / Naik Kapal</strong></label
                              >
                              <div class="col-sm-6">
                                {{ crewProfile?.sign_on ?? '-' }}
                              </div>
                            </div>
                            <div class="form-group row">
                              <label
                                label="turun_kapal"
                                for="turun_kapal"
                                class="col-sm-6"
                                ><strong>Sign Off / Turun Kapal</strong></label
                              >
                              <div class="col-sm-6">
                                <b-form-input
                                  type="date"
                                  id="signon"
                                  v-model="form_signoff_crew.sign_off"
                                  required
                                ></b-form-input>
                              </div>
                            </div>
                          </b-form>
                        </b-col>
                      </b-row>
                    </fieldset>

                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2" style="font-size: 14pt"
                        >Reason Sign Off</legend
                      >
                      <table class="table table-striped">
                        <thead>
                          <tr class="text-center">
                            <th width="90%">DESKRIPSI</th>
                            <th width="10%">STATUS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(state, index) in master_signoff_reasons"
                            v-bind:key="index"
                          >
                            <th> {{ state.name }}</th>
                            <td class="text-center">
                              <template>
                                <b-form-checkbox
                                  v-model="state.checked"
                                  name="check-button"
                                  class="ml-4"
                                  switch
                                  inline
                                  :disabled="state.disabled"
                                  @change="
                                    selectStatusSignOffCrew(state, index)
                                  "
                                >
                                </b-form-checkbox>
                              </template>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <b-form-textarea
                        placeholder="Notes..."
                        id="remarks"
                        v-model="form_signoff_crew.remarks"
                      ></b-form-textarea>
                    </fieldset>
                    <b-col cols="12" class="text-right"
                      ><span
                        ><b-button
                          variant="primary"
                          block
                          @click="onSaveSignOffCrew"
                          ><i class="fa fa-file-signature"></i> SUBMIT
                        </b-button></span
                      ></b-col
                    >
                  </b-col>
                </b-row>
              </template>
            </b-sidebar>
            <!-- FORM SIGN OFF END -->

            <!-- FORM BLACK LIST  -->
            <b-sidebar
              id="blacklist-crew"
              title="BLACK LIST"
              width="50%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow
            >
              <template #default="{ hide }">
                <b-row class="m-3">
                  <b-col md="12">
                    <fieldset
                      class="form-group border p-3"
                      v-if="form_crew.key === 'crew'"
                    >
                      <legend class="w-auto px-2 text-primary"
                        >Informasi Crew</legend
                      >
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <td width="25%"><strong>Nama Crew</strong></td>
                            <td width="25%">{{
                              crewProfile?.fullname ?? '-'
                            }}</td>
                            <td width="25%"><strong>Perusahaan</strong></td>
                            <td width="25%">{{
                              crewProfile?.fullname ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Jabatan</strong></td>
                            <td width="25%">{{
                              crewProfile?.position?.name ?? '-'
                            }}</td>
                            <td width="25%"><strong>Pelabuhan</strong></td>
                            <td width="25%">{{
                              crewProfile?.port?.port_name ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>NIK</strong></td>
                            <td width="25%">{{
                              crewProfile?.personal_data?.nik_ktp ?? '-'
                            }}</td>
                            <td width="25%"><strong>Nama Kapal</strong></td>
                            <td width="25%">{{
                              crewProfile?.vehicle?.name ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Nomor Pegawai</strong></td>
                            <td width="25%">{{ crewProfile?.nip ?? '-' }}</td>
                            <td width="25%"><strong>Sign On</strong></td>
                            <td width="25%">{{
                              crewProfile?.sign_on ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Seaferer Code</strong></td>
                            <td width="25%">{{
                              crewProfile?.personal_data?.seaferer_code ?? '-'
                            }}</td>
                            <td width="25%"><strong>Sign Off</strong></td>
                            <td width="25%">{{
                              crewProfile?.sign_off ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Nomor NPWP</strong></td>
                            <td width="25%">{{
                              crewProfile?.personal_data?.npwp_number ?? '-'
                            }}</td>
                            <td width="25%"><strong>No. Tlp / Hp</strong></td>
                            <td width="25%">{{
                              crewProfile?.personal_data?.hp_number ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Nomor Passport</strong></td>
                            <td width="25%">{{
                              crewProfile?.personal_data?.passport_number ?? '-'
                            }}</td>
                            <td width="25%"><strong>Email</strong></td>
                            <td width="25%">{{
                              crewProfile?.personal_data?.email ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"
                              ><strong>Nomor Seamanbook</strong></td
                            >
                            <td width="25%">{{
                              crewProfile?.personal_data?.seaman_book_number ??
                              '-'
                            }}</td>
                            <td width="25%"
                              ><strong>Kewarganegaraan</strong></td
                            >
                            <td width="25%">{{
                              crewProfile?.personal_data?.citizenship ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Alamat</strong></td>
                            <td colspan="3">{{
                              crewProfile?.personal_data?.citizenship ?? '-'
                            }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>

                    <fieldset
                      class="form-group border p-3"
                      v-if="form_candidate.key === 'candidate'"
                    >
                      <legend class="w-auto px-2 text-primary"
                        >Informasi Candidate</legend
                      >
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <td width="25%"><strong>Nama Candidate</strong></td>
                            <td width="25%">{{
                              candidateProfile?.personal_data?.full_name ?? '-'
                            }}</td>
                            <td width="25%"><strong>Nomor Passport</strong></td>
                            <td width="25%">{{
                              candidateProfile?.personal_data
                                ?.passport_number ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Jabatan</strong></td>
                            <td width="25%">{{
                              candidateProfile?.personal_data?.position?.name
                            }}</td>
                            <td width="25%"
                              ><strong>Nomor Seamanbook</strong></td
                            >
                            <td width="25%">{{
                              candidateProfile?.personal_data
                                ?.seaman_book_number ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>NIK</strong></td>
                            <td width="25%">{{
                              candidateProfile?.personal_data?.nik_ktp ?? '-'
                            }}</td>
                            <td width="25%"><strong>Apply Date</strong></td>
                            <td width="25%">{{
                              candidateProfile?.apply_date ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Seaferer Code</strong></td>
                            <td width="25%">{{
                              candidateProfile?.personal_data?.seaferer_code ??
                              '-'
                            }}</td>
                            <td width="25%"><strong>No. Tlp / Hp</strong></td>
                            <td width="25%">{{
                              candidateProfile?.personal_data?.hp_number ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Nomor NPWP</strong></td>
                            <td width="25%">{{
                              candidateProfile?.personal_data?.npwp_number ??
                              '-'
                            }}</td>
                            <td width="25%"><strong>Email</strong></td>
                            <td width="25%">{{
                              candidateProfile?.personal_data?.email ?? '-'
                            }}</td>
                          </tr>
                          <tr>
                            <td width="25%"><strong>Alamat</strong></td>
                            <td colspan="3">{{
                              candidateProfile?.alamat_ktp?.[0]?.alamat ?? '-'
                            }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>

                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2 text-primary"
                        >Reason Black List</legend
                      >
                      <b-form-group>
                        <b-form-select
                          plain
                          v-model="form.blacklist.reason_off"
                          :options="reason"
                          size="lg"
                          aria-placeholder="Pilih Alasan"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="null"
                              >Pilih Alasan</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>
                      <b-form-textarea
                        rows="3"
                        value="Notes"
                        v-model="form.blacklist.remarks"
                        required
                        placeholder="Remarks..."
                      ></b-form-textarea>
                    </fieldset>
                    <b-col cols="12" class="text-right"
                      ><span
                        ><b-button
                          variant="primary"
                          block
                          @click="onSaveBlacklist"
                          :disabled="loading"
                          ><i class="fa fa-file-signature"></i> SUBMIT
                        </b-button></span
                      ></b-col
                    >
                  </b-col>
                </b-row>
              </template>
            </b-sidebar>
            <!-- FORM BLACK LIST END -->

            <!-- FORM MEDICAL CHECKUP -->
            <b-sidebar
              id="medical-checkup"
              title="MEDICAL CHECKUP"
              width="50%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow
            >
              <template #default="{ hide }">
                <b-row class="m-3">
                  <b-col md="12">
                    <fieldset class="form-group border p-3 iq-bg-info">
                      <legend class="w-auto px-2" style="font-size: 14pt"
                        ><strong>Medical Check Up</strong></legend
                      >
                      <b-form-row>
                        <b-col>
                          <b-form-group
                            label="Check Up Name"
                            label-for="checkup_name"
                          >
                            <b-form-input
                              type="text"
                              id="checkup_name"
                              placeholder="Check Up Name"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="Expired Date"
                            label-for="expired_date"
                          >
                            <b-form-input
                              type="date"
                              id="expired_date"
                            ></b-form-input>
                            <small style="float: right"
                              >click <i class="fa fa-calendar"></i> to
                              show</small
                            >
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-form-group
                            label="File Check Up"
                            label-for="file_medical_checkup"
                          >
                            <div class="custom-file">
                              <input
                                type="file"
                                class="custom-file-input"
                                id="validatedCustomFile"
                                required
                              />
                              <label
                                class="custom-file-label"
                                for="validatedCustomFile"
                                >Browse file...</label
                              >
                            </div>
                            <small class="text-muted">
                              File Type * :
                              <b-badge variant="info">.pdf / Maks 3 MB</b-badge>
                            </small>
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                      <b-button variant="success" class="mb-3 mr-1"
                        ><i class="fa fa-plus pr-0"></i> Add Document</b-button
                      >
                      <b-button variant="danger" class="mb-3 mr-1"
                        ><i class="fa fa-minus pr-0"></i> Delete
                        Document</b-button
                      >
                    </fieldset>
                    <b-button variant="primary" block
                      ><i class="fa fa-file-signature"></i> SUBMIT
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-sidebar>
            <!-- FORM MEDICAL CHECKUP END -->
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="reject-modal"
      size="l"
      title="Reject (Navigation Equipment - Navigation Light)"
      ok-title="Save Changes"
      cancel-title="Close"
    >
      <b-row>
        <b-col md="12">
          <b-form-group label="Reason *" label-for="reason">
            <b-form-textarea id="reason" rows="2"></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>
<script>
import moment from 'moment'
import { xray } from '../../config/pluginInit'
import { crewingActions, fleetsActions } from '@src/Utils/helper'
import listCrewOptionsData from '@src/core/mock/data/ListCrewOptionsData'
import FormBlacklist from '@src/components/crewing/FormBlackList.vue'
import TableContentCrewList from '@/src/components/crewing/crew/TableContentCrewList.vue'
import TableContentCandidateList from '@/src/components/crewing/candidate/TableContentCandidateList.vue'
import _ from 'lodash'

export default {
  name: 'ProfileEdit',
  data() {
    return {
      currentStep: 0,
      // Slide Form Checklist
      text: [
        {
          activeLabel: 'On',
          inactiveLabel: 'Off',
          label: 'Primary',
          class: 'primary',
          checked: true,
        },
        {
          activeLabel: 'True',
          inactiveLabel: 'False',
          label: 'Success',
          class: 'success',
          checked: true,
        },
        {
          activeLabel: 'Yes',
          inactiveLabel: 'No',
          label: 'Danger',
          class: 'danger',
          checked: true,
        },
      ],
      medical_checkup: [
        {
          label_file: '',
          file: '',
          nama_document: '',
        },
      ],
      optionsExpertUsers: [],
      ...listCrewOptionsData,
      saving: false,
      loading: false,
      form_crew: {
        id: null,
        key: '',
      },
      form_candidate: {
        id: null,
        key: '',
      },
      form: {
        _saving: false,
        name_of_the_experts: '',
        blacklist: {
          reason_off: '',
          remarks: '',
        },
        setInterviewCandidate: {
          expert_user_id: null,
          date_interview: '',
          position_id: '',
          location: '',
          urgensi: '',
          notes_set_interview: '',
          notification: [
            {
              expert_user_id: null,
            },
          ],
        },
      },
      form_signon: {
        bpjstk_config: 1,
        bpjsksh_config: 1,
        company_id: null,
        department_id: null,
        position_id: null,
        work_area_id: null,
        vehicle_id: null,
        port_id: null,
        sign_on: null,
        join_on_board: null,
        sign_off: null,
        attachment: null,
        remarks: '',
        basic_sallary: null,
        overtime: null,
        daily_fee: null,
        bpjsksh: 1,
        bpjstk: 1,
        bpjsksh_config: 0,
        basispengali_bpjsksh_config: 0,
        basispengali_bpjsksh: '',
        max_gaji_bpjsksh: null,
        bpjsksh_company_set_value: null,
        bpjsksh_employee_set_value: null,
        bpjstk_config: 0,
        basispengali_bpjstk: null,
        jht_config: 0,
        jht_company_set_value: null,
        jht_employee_set_value: null,
        jp_config: 0,
        jp_company_set_value: null,
        jp_employee_set_value: null,
        jp_max_gaji: null,
        jp_wna: null,
        jp_lebihusia_max: null,
        jp_max_usia: null,
        jkk_config: 0,
        jkk_set_value: null,
        jkm_config: 0,
        jkm_set_value: null,
        allowance_fix: [],
      },
      form_signoff_crew: {
        reason_signoff_id: [],
        sign_off: null,
        remarks: '',
        is_blacklist: '',
      },
      levelSelected: null,
      selectedStatusCrew: null,
      selectStatusCandidate: null,
      selectedPerusahaan: null,
      selectedFleets: null,
      selectedPort: null,
      selectedPic: null,
      selectedReason: null,

      dateRange: [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD'),
      ],
      text: [
        {
          activeLabel: 'Yes',
          inactiveLabel: 'No',
          class: 'primary',
          checked: false,
        },
      ],
      crewList: {
        isFiltering: false,
        params: {
          fullname: '',
          vehicle_id: null,
          from: null,
          to: null,
          position_id: null,
        },
        data: {
          head: [],
          body: [],
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        },
      },
      candidateList: {
        isFiltering: false,
        params: {
          full_name: '',
          status: null,
          from: null,
          to: null,
          position_id: null,
        },
        data: {
          head: [],
          body: [],
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        },
      },
      blackList: {
        params: {
          full_name: '',
          status: null,
          from: null,
          to: null,
          jabatan_id: null,
        },
        data: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        },
      },
      optionsVehicles: [],
      optionsCompanies: [],
      optionsWorkArea: [],
      optionsPorts: [],
      optionsPayrollAllowance: [],
      optionsDepartment: [],
      optionsPosition: [],
      master_list_document: [],
      master_list_department: [],
      department_form: [],
      master_signoff_reasons: [],

      optionsSetting: [
        {
          value: 0,
          text: 'Default',
        },
        {
          value: 1,
          text: 'Tentukan Sendiri',
        },
      ],

      optionsGaji: [
        {
          value: 'GP',
          text: 'Gaji Pokok',
        },
        {
          value: 'GPT',
          text: 'Gaji Pokok + Tunjangan',
        },
        {
          value: 'UMP',
          text: 'UMP',
        },
      ],
      candidateProfile: null,
      crewProfile: null,
    }
  },
  created() {
    this.getCandidateList = _.debounce(this.getCandidateList, 500)
    this.getCrewList = _.debounce(this.getCrewList, 500)
    this.getBlackList = _.debounce(this.getBlackList, 500)
  },
  mounted() {
    xray.index()
    this.fetchFleets()
    this.getCrewList()
    this.getCandidateList()
    this.getBlackList()
    this.fetchDataUserExpert()
    this.fetchKapal()
    this.fetchPerusahaan()
    this.fetchAreaKerja()
    this.fetchPelabuhan()
    this.getPayrollAllowance()
    this.getDepartment()
    this.getPositionList()
    this.getMasterSignOffReasons()
  },
  components: {
    FormBlacklist,
    TableContentCrewList,
    TableContentCandidateList,
  },
  watch: {
    'form_candidate.id': {
      async handler() {
        this.form_crew.key = ''

        await this.fetchProfileCandidate()
        await this.fetchDocumentList()
        await this.getMasterDepartmentList()
      },
      deep: true,
    },
    'form_crew.id': {
      async handler() {
        this.form_candidate.key = ''

        await this.fetchProfileCrew()
      },
      deep: true,
    },
  },
  methods: {
    ...crewingActions,
    ...fleetsActions,
    closeSidebar(id) {
      this.$root.$emit('bv::toggle::collapse', `${id}`)
    },
    prefiewFiles: function (event, state) {
      const input = event.target

      if (input.files && input.files[0]) {
        state.label_file = input.files[0].name
        state.file = input.files[0]
      }
    },
    prefiewFilesInduction: function (event, state) {
      const input = event.target

      if (input.files && input.files[0]) {
        state.attachment_label = input.files[0].name
        state.attachment = input.files[0]
      }
    },
    selectStatusSignOffCrew: function (state, index) {
      if (state.id === 7 && state.checked) {
        this.master_signoff_reasons.forEach((val, i) => {
          if (state.id !== val.id) {
            val.disabled = true
            val.checked = false
          }
        })
      } else if (state.id === 7 && !state.checked) {
        this.master_signoff_reasons.forEach((val, i) => {
          if (state.id !== val.id) {
            val.disabled = false
            val.checked = false
          }
        })
      }
    },
    async fetchKapal() {
      let params = {
        page: 1,
        active: true,
        perPage: 100,
      }
      let res = await this.fetchVehicles(params)
      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.map((state) => {
            this.optionsVehicles.push({
              value: state.id,
              text: state.name,
            })
          })
        }
      }
    },
    async fetchPerusahaan() {
      let res = await this.fetchCompanies()
      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.map((state) => {
            this.optionsCompanies.push({
              value: state.id,
              text: state.company,
            })
          })
        }
      }
    },
    async getPositionList() {
      let res = await this.fetchPositionList()
      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.map((state) => {
            this.optionsPosition.push({
              value: state.id_position,
              text: state.name_position,
            })
          })
        }
      }
    },
    async fetchAreaKerja() {
      let res = await this.fetchWorkArea()

      if (res.status) {
        if (res.data.data.length > 0) {
          res.data.data.map((state) => {
            this.optionsWorkArea.push({
              value: state.id,
              text: state.name,
            })
          })
        }
      }
    },
    async fetchPelabuhan() {
      let params = {
        page: 1,
      }
      let res = await this.fetchPorts(params)
      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.map((state) => {
            this.optionsPorts.push({
              value: state.id,
              text: state.portName,
            })
          })
        }
      }
    },
    async getPayrollAllowance() {
      let params = {
        type: 'T',
        status_penggunaan: 1,
      }
      let res = await this.fetchPayrollAllowance(params)
      if (res.status) {
        if (res.data.length > 0) {
          res.data.map((state) => {
            this.form_signon.allowance_fix.push({
              id_m_allowance: state.id,
              amount: null,
              text: state.name,
            })
          })
        }
      }
    },
    async getDepartment() {
      let params = {
        is_induction: 1,
      }
      let res = await this.fetchMasterDepartmentList(params)
      if (res.status) {
        if (res.data.data.length > 0) {
          res.data.data.map((state) => {
            this.optionsDepartment.push({
              value: state.id,
              text: state.name,
            })
          })
        }
      }
    },
    async getMasterSignOffReasons() {
      let res = await this.masterSignOffReasons()
      if (res.status) {
        res.data.data.map((val) => {
          this.master_signoff_reasons.push({
            ...val,
            disabled: false,
            checked: false,
          })
        })
      }
    },

    async fetchDataUserExpert() {
      let params = {
        page: 1,
        active: true,
        perPage: 200,
      }

      const { status, data } = await this.fetchUserExpert(params)

      if (status) {
        if (data && data.length > 0) {
          data.map((state) => {
            this.optionsExpertUsers.push({
              value: state.id,
              text: state.username,
            })
          })
        }
      }
    },
    async getCrewList(page) {
      const params = {
        page: page || 1,
      }

      for (const key in this.crewList.params) {
        if (this.crewList.params[key]) {
          params[key] = this.crewList.params[key]
        }
      }

      const { status, data } = await this.fetchCrewList(params)

      console.log(data.data, 'CREW LIST DATA')

      if (status === 'success') {
        this.crewList.meta.perPage = data.per_page
        this.crewList.meta.currentPage = data.current_page
        this.crewList.meta.total = data.total
        this.crewList.meta.totalPage = Math.round(data.total / data.per_page)
        if (data.data) {
          ;(this.crewList.data.body = data.data),
            (this.crewList.data.head = [
              'NO',
              'NAMA',
              'FLEET',
              'JABATAN',
              'SIGN ON',
              'SIGN OFF',
              'STATUS',
              'ACTION',
            ])
        }
      }
    },
    async getCandidateList(page) {
      const params = {
        page: page || 1,
      }

      for (const key in this.candidateList.params) {
        if (this.candidateList.params[key]) {
          params[key] = this.candidateList.params[key]
        }
      }

      const { status, data } = await this.fetchCandidateList(params)

      if (status === 'success') {
        this.candidateList.meta.perPage = data.per_page
        this.candidateList.meta.currentPage = data.current_page
        this.candidateList.meta.total = data.total
        this.candidateList.meta.totalPage = Math.round(
          data.total / data.per_page
        )
        if (data.data) {
          console.log(data.data, 'LIST CANDIDATE')
          this.candidateList.data.body = data.data
          this.candidateList.data.head = [
            'NO',
            'NAMA',
            'JABATAN',
            'APPLY DATE',
            'JADWAL INTERVIEW',
            'EXPERT',
            'STATUS',
            'ACTION',
          ]
        }
      }
    },
    async getBlackList(page = 1) {
      const params = {
        page: page || 1,
      }

      for (const key in this.blackList.params) {
        if (this.blackList.params[key]) {
          params[key] = this.blackList.params[key]
        }
      }

      const { status, data } = await this.fetchBlackList(params)

      if (status === 'success') {
        this.blackList.meta.perPage = data.per_page
        this.blackList.meta.currentPage = data.current_page
        this.blackList.meta.total = data.total
        this.blackList.meta.totalPage = Math.round(data.total / data.per_page)
        if (data.data) {
          this.blackList.data = data.data
        }
      }
    },
    async fetchFleets() {
      this.fleetsLoading = true
      this.fleets = []

      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        search: '',
      })
      let res = await this.getFleets(params)

      if (res.status) {
        this.fleetsLoading = false
        if (res.data && res.data.length > 0) {
          this.fleetOptions = res.data.map((val) => ({
            text: val.name,
            value: val.id,
          }))
        }
      }
    },
    onCrewChangeDate() {
      this.crewList.params.from = this.dateRange
        ? `${moment(this.dateRange[0]).format('YYYY-MM-DD')}`
        : null
      this.crewList.params.to = this.dateRange
        ? `${moment(this.dateRange[1]).format('YYYY-MM-DD')}`
        : null
    },
    onCandidateChangeDate() {
      this.candidateList.params.from = this.dateRange
        ? `${moment(this.dateRange[0]).format('YYYY-MM-DD')}`
        : null
      this.candidateList.params.to = this.dateRange
        ? `${moment(this.dateRange[1]).format('YYYY-MM-DD')}`
        : null
    },
    async filterCrewList(page = 1) {
      const params = {
        ...this.crewList.params,
        page,
      }

      // for (const key in this.candidateList.params) {
      //   if (this.candidateList.params[key]) {
      //     params[key] = this.candidateList.params[key]
      //   }
      // }

      const { status, data } = await this.fetchCrewList(params)
      this.crewList.isFiltering = true
      if (status === 'success') {
        this.crewList.meta.perPage = data.per_page
        this.crewList.meta.currentPage = data.current_page
        this.crewList.meta.total = data.total
        this.crewList.meta.totalPage = Math.round(data.total / data.per_page)
        if (data.data) {
          this.crewList.data.body = data.data
          this.crewList.data.head = [
            'NO',
            'NAMA',
            'FLEET',
            'JABATAN',
            'SIGN ON',
            'SIGN OFF',
            'STATUS',
            'ACTION',
          ]
        }
      }
    },
    async filterCandidateList(page = 1) {
      const params = {
        ...this.candidateList.params,
        page,
      }

      // for (const key in this.candidateList.params) {
      //   if (this.candidateList.params[key]) {
      //     params[key] = this.candidateList.params[key]
      //   }
      // }

      const { status, data } = await this.fetchCandidateList(params)
      this.candidateList.isFiltering = true
      if (status === 'success') {
        this.candidateList.meta.perPage = data.per_page
        this.candidateList.meta.currentPage = data.current_page
        this.candidateList.meta.total = data.total
        this.candidateList.meta.totalPage = Math.round(
          data.total / data.per_page
        )
        if (data.data) {
          this.candidateList.data.body = data.data
          this.candidateList.data.head = [
            'NO',
            'NAMA',
            'JABATAN',
            'APPLY DATE',
            'JADWAL INTERVIEW',
            'EXPERT',
            'STATUS',
            'ACTION',
          ]
        }
      }
    },

    async fetchProfileCandidate() {
      if (this.form_candidate.id) {
        let method = 'getProfileCandidate'

        const { status, data } = await this[method](this.form_candidate.id)

        console.log(this.form_candidate.id, 'ID CANDIDATE')
        console.log(data, 'THIS IS PROFILE CANDIDATE')

        if (status !== 'success') {
          this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
          return
        }

        this.candidateProfile = data
      }
    },
    async fetchProfileCrew() {
      if (this.form_crew.id) {
        let method = 'getCrewDetail'

        const { status, data } = await this[method](this.form_crew.id)

        if (status !== 'success') {
          this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
          return
        }

        this.crewProfile = data[0]

        this.form_signoff_crew.sign_off = data[0].sign_off
        console.log(this.crewProfile, 'THIS IS PROFILE CREW')
      }
    },
    async fetchDocumentList() {
      this.loading = true
      this.master_list_document = []
      let supportDocument = []
      let masterDocument = []

      const { status, data } = await this.masterDocumentList()

      if (status !== 'success') {
        this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
        return
      }
      data?.data?.map((val) => {
        if (val.type === 'system') {
          masterDocument.push({
            document_list_id: val.id,
            status_checklist: 1,
            document_name: val.name,
          })
        } else {
          masterDocument.push({
            document_list_id: val.id,
            status_checklist: 0,
            document_name: val.name,
          })
        }
      })

      this?.candidateProfile?.support_document?.map((item) => {
        supportDocument.push({
          document_list_id: item.document_list_id,
          status_checklist: 1,
          document_name: item.document_list.name,
        })
      })

      this.master_list_document.push(...masterDocument, ...supportDocument)

      let mapObj = new Map()
      this.master_list_document.forEach((v) => {
        let prevValue = mapObj.get(v.document_list_id)
        if (!prevValue || prevValue.status_checklist === 0) {
          mapObj.set(v.document_list_id, v)
        }
      })
      this.master_list_document = [...mapObj.values()]
      this.loading = false
    },
    async getMasterDepartmentList() {
      this.loading = true
      this.department_form = []
      this.master_list_department = []
      let induction_checklist = []
      let tempForm = []

      let params = {
        is_induction: 1,
      }
      const { status, data } = await this.fetchMasterDepartmentList(params)
      const res = await this.fetchInductionChecklist(this.form_candidate.id)

      if (status !== 'success') {
        this.$swal(`Oops!`, `Data tidak ada.`, 'error')
        this.loading = false
        return
      }

      this.master_list_department = data?.data
      induction_checklist = res.data

      if (induction_checklist.length <= 0) {
        this.master_list_department.map((val) => {
          this.department_form.push({
            department_id: val.id,
            name: `${val.name} Department`,
            induction_by: null,
            induction_date: null,
            attachment: null,
            attachment_label: '',
            notes: '',
            checked: false,
          })
        })
      } else {
        this.master_list_department.map((val) => {
          induction_checklist.map((item) => {
            if (val.id === item.department_id) {
              this.department_form.push({
                department_id: val.id,
                name: `${val.name} Department`,
                induction_by: item.induction_by,
                induction_date: item.induction_date,
                attachment: item.attachment,
                notes: item.notes,
                checked: true,
              })
            } else {
              this.department_form.push({
                department_id: val.id,
                name: `${val.name} Department`,
                induction_by: null,
                induction_date: null,
                attachment: null,
                attachment_label: '',
                notes: '',
                checked: false,
              })
            }
          })
        })
      }

      let mapObj = new Map()
      this.department_form.forEach((v) => {
        let prevValue = mapObj.get(v.department_id)
        if (!prevValue || prevValue.checked === false) {
          mapObj.set(v.department_id, v)
        }
      })
      this.department_form = [...mapObj.values()]

      await Promise.all(this.department_form).then(() => {
        this.loading = false
      })
      this.loading = false
    },
    // Medical Checkup Adding and Removing Forms
    addMedicalCheckupForm() {
      this.medical_checkup.push({
        label_file: '',
        file: '',
        nama_document: '',
      })
    },
    deleteMedicalCheckupForm(index) {
      this.medical_checkup.splice(index, 1)
    },
    addNotificationUser() {
      this.form.setInterviewCandidate.notification.push({
        expert_user_id: null,
      })
    },
    deleteNotificationUser(index) {
      this.form.setInterviewCandidate.notification.splice(index, 1)
    },

    async onSaveMedichalCheckup(e) {
      e.preventDefault()
      this.saving = true

      let method = 'medicalCheckup'

      let tempMedicalCheckup = []
      this.medical_checkup.map((item) => {
        tempMedicalCheckup.push({
          file: item.file,
          nama_document: item.nama_document,
        })
      })

      let formData = new FormData()

      tempMedicalCheckup.forEach((item, index) => {
        for (const property in item) {
          formData.append(
            `medical_checkup[${index}][${property}]`,
            item[property]
          )
        }
      })

      const { status, data } = await this[method]({
        data: formData,
        id: this.form_candidate.id,
      })

      let changeStatus = new FormData()

      changeStatus.append('change_status', 'contract')

      await this.changeStatusCandidate({
        id: this.form_candidate.id,
        change_status: changeStatus,
      })

      if (status !== 'success') {
        this.saving = false
        this.$swal(`Oops!`, `Semua Data Harus Terisi`, 'error')
        return
      }

      this.candidateList.data.body.map((val) => {
        if (val.id === this.form_candidate.id) {
          val.status = 'contract'
        }
      })

      this.$swal(`Success!`, `${data}`, 'success')

      this.saving = false

      this.medical_checkup = [
        {
          label_file: '',
          file: '',
          nama_document: '',
        },
      ]
      this.$nextTick(() => {
        this.$bvModal.hide('medical-checkup')
      })
    },

    prevStep() {
      if (this.currentStep <= 0) {
        return
      }

      this.currentStep--
    },

    async onSignOnSave(e) {
      e.preventDefault()
      if (this.currentStep !== 2) {
        this.currentStep++
        return
      }
      this.saving = true
      let newAllowanceFix = []
      this.form_signon.allowance_fix.map((val) => {
        newAllowanceFix.push({
          id_m_allowance: val.id_m_allowance,
          amount: val.amount,
        })
      })

      let method = 'setSignOnCandidate'
      let formData = new FormData()

      formData.append('bpjstk_config', this.form_signon.bpjstk_config)
      formData.append('bpjsksh_config', this.form_signon.bpjsksh_config)
      formData.append('company_id', this.form_signon.company_id)
      formData.append('department_id', this.form_signon.department_id)
      formData.append('position_id', this.form_signon.position_id)
      formData.append('work_area_id', this.form_signon.work_area_id)
      formData.append('vehicle_id', this.form_signon.vehicle_id)
      formData.append('port_id', this.form_signon.port_id)
      formData.append('sign_on', this.form_signon.sign_on)
      formData.append('join_on_board', this.form_signon.join_on_board)
      formData.append('sign_off', this.form_signon.sign_off)
      formData.append('attachment', this.form_signon.attachment)
      formData.append('remarks', this.form_signon.remarks)
      formData.append('basic_sallary', this.form_signon.basic_sallary)
      formData.append('overtime', this.form_signon.overtime)
      formData.append('daily_fee', this.form_signon.daily_fee)
      formData.append('bpjsksh', this.form_signon.bpjsksh)
      formData.append('bpjstk', this.form_signon.bpjstk)
      formData.append('bpjsksh_config', this.form_signon.bpjsksh_config)
      formData.append(
        'basispengali_bpjsksh_config',
        this.form_signon.basispengali_bpjsksh_config
      )
      formData.append(
        'basispengali_bpjsksh',
        this.form_signon.basispengali_bpjsksh
      )
      formData.append('max_gaji_bpjsksh', this.form_signon.max_gaji_bpjsksh)
      formData.append(
        'bpjsksh_company_set_value',
        this.form_signon.bpjsksh_company_set_value
      )
      formData.append(
        'bpjsksh_employee_set_value',
        this.form_signon.bpjsksh_employee_set_value
      )
      formData.append('bpjstk_config', this.form_signon.bpjstk_config)
      formData.append(
        'basispengali_bpjstk',
        this.form_signon.basispengali_bpjstk
      )
      formData.append('jht_config', this.form_signon.jht_config)
      formData.append(
        'jht_company_set_value',
        this.form_signon.jht_company_set_value
      )
      formData.append(
        'jht_employee_set_value',
        this.form_signon.jht_employee_set_value
      )
      formData.append('jp_config', this.form_signon.jp_config)
      formData.append(
        'jp_company_set_value',
        this.form_signon.jp_company_set_value
      )
      formData.append(
        'jp_employee_set_value',
        this.form_signon.jp_employee_set_value
      )
      formData.append('jp_max_gaji', this.form_signon.jp_max_gaji)
      formData.append('jp_wna', this.form_signon.jp_wna)
      formData.append('jp_lebihusia_max', this.form_signon.jp_lebihusia_max)
      formData.append('jp_max_usia', this.form_signon.jp_max_usia)
      formData.append('jkk_config', this.form_signon.jkk_config)
      formData.append('jkk_set_value', this.form_signon.jkk_set_value)
      formData.append('jkm_config', this.form_signon.jkm_config)
      formData.append('jkm_set_value', this.form_signon.jkm_set_value)
      formData.append('jkm_set_value', this.form_signon.jkm_set_value)

      newAllowanceFix.forEach((item, index) => {
        for (const property in item) {
          formData.append(
            `allowance_fix[${index}][${property}]`,
            item[property]
          )
        }
      })

      const { status, data } = await this[method]({
        id: this.form_candidate.id,
        data: formData,
      })

      let changeStatus = new FormData()

      changeStatus.append('change_status', 'induction')

      await this.changeStatusCandidate({
        id: this.form_candidate.id,
        change_status: changeStatus,
      })

      this.candidateList.data.body.map((val) => {
        console.log(val)
        if (val.id === this.form_candidate.id) {
          val.status = 'induction'
        }
      })

      console.log('Done ')

      if (status !== 'success') {
        this.saving = false
        this.$swal(`Oops!`, `${data}`, 'error')
        return
      }
      this.closeSidebar('sign-on')

      this.$swal(
        `Tersimpan`,
        'Berhasil melakukan contract candidate.',
        'success'
      )
      this.saving = false
    },

    async onSaveInduction(e) {
      e.preventDefault()
      this.saving = true

      let method = 'setInductionCandidate'

      let readyToSave = []

      this?.department_form?.map((val) => {
        if (!val.checked) {
          readyToSave.push({
            department_id: val.department_id,
            induction_by: val.induction_by,
            induction_date: val.induction_date,
            attachment: val.attachment,
            notes: val.notes,
          })
        }
      })

      let statusFetching = null

      const promises = readyToSave.map(async (val) => {
        let formData = new FormData()
        formData.append('department_id', val.department_id)
        formData.append('induction_by', val.induction_by)
        formData.append('induction_date', val.induction_date)
        formData.append('attachment', val.attachment)
        formData.append('notes', val.notes)

        const { status, data } = await this[method]({
          formData,
          id: this.form_candidate.id,
        })

        if (status === 422) {
          statusFetching = status
          this.saving = false
          return this.$swal(`Oops!`, `Harap isi semua data.`, 'error')
        }
      })

      await Promise.all(promises).then(async () => {
        if (statusFetching === null) {
          this.saving = false

          let changeStatus = new FormData()

          changeStatus.append('change_status', 'crew')

          await this.changeStatusCandidate({
            id: this.form_candidate.id,
            change_status: changeStatus,
          })

          this.candidateList.data.body = this.candidateList.data.body.filter(
            (val) => {
              return val.id !== this.form_candidate.id
            }
          )

          this.$swal(`Tersimpan`, 'Candidate Berhasil Menjadi Crew.', 'success')

          this.$nextTick(() => {
            this.$bvModal.hide('induction')
          })
        } else {
          this.saving = false
          return this.$swal(`Oops!`, `Harap isi semua data.`, 'error')
        }

        this.saving = false
      })
    },

    async onSaveVerificationDocument(e) {
      e.preventDefault()
      this.saving = true

      let changeStatus = new FormData()

      changeStatus.append('change_status', 'candidate')

      await this.changeStatusCandidate({
        id: this.form_candidate.id,
        change_status: changeStatus,
      })

      this.candidateList.data.body.map((val) => {
        console.log(val)
        if (val.id === this.form_candidate.id) {
          console.log(val.id, this.form_candidate.id)
          val.status = 'candidate'
        }
      })

      this.$swal(`Tersimpan`, 'Verification Berhasil.', 'success')

      this.$nextTick(() => {
        this.$bvModal.hide('verification_document')
      })

      this.saving = false
    },

    async onSaveSetInterview(e) {
      e.preventDefault()
      this.saving = true

      let method = 'setInterviewCandidate'
      let formData = new FormData()

      let tempDateInterview = moment(
        this.form.setInterviewCandidate.date_interview
      ).format('YYYY-MM-DD HH:mm:ss')

      formData.append(
        'expert_user_id',
        this.form.setInterviewCandidate.expert_user_id
      )
      formData.append('date_interview', tempDateInterview)
      formData.append(
        'position_id',
        this.form.setInterviewCandidate.position_id
      )
      formData.append('location', this.form.setInterviewCandidate.location)
      formData.append('urgensi', this.form.setInterviewCandidate.urgensi)
      formData.append(
        'notes_set_interview',
        this.form.setInterviewCandidate.notes_set_interview
      )
      this.form.setInterviewCandidate.notification.forEach((item, index) => {
        for (const property in item) {
          formData.append(`notification[${index}][${property}]`, item[property])
        }
      })

      if (this.form_candidate.id) {
        const { status, data } = await this[method]({
          formData,
          id: this.form_candidate.id,
        })

        if (status !== 'success') {
          this.saving = false
          this.$swal(`Oops!`, `Semua Data Harus Terisi`, 'error')
          return
        }

        this.optionsExpertUsers.map((val) => {
          if (val.value === this.form.setInterviewCandidate.expert_user_id) {
            this.form.name_of_the_experts = val.text
          }
        })
        let changeStatus = new FormData()

        changeStatus.append('change_status', 'interview')

        await this.changeStatusCandidate({
          id: this.form_candidate.id,
          change_status: changeStatus,
        })

        this.candidateList.data.body.map((val) => {
          if (val.id === this.form_candidate.id) {
            val.status = 'interview'
            val.date_interview = this.form.setInterviewCandidate.date_interview
            val.expert = this.form.name_of_the_experts
          }
        })

        this.form.setInterviewCandidate.expert_user_id = null
        this.form.setInterviewCandidate.date_interview = null
        this.form.setInterviewCandidate.position_id = null
        this.form.setInterviewCandidate.location = null
        this.form.setInterviewCandidate.urgensi = null
        this.form.setInterviewCandidate.notes_set_interview = null
        this.form.setInterviewCandidate.notification = [
          { expert_user_id: null },
        ]

        this.$swal(`Tersimpan`, 'Data Tersimpan', 'success')
        this.saving = false
        this.$nextTick(() => {
          this.$bvModal.hide('setup-interview')
        })
      }
    },

    async onSaveSignOffCrew(e) {
      e.preventDefault()
      this.saving = true
      this.form_signoff_crew.reason_signoff_id = []

      let method = 'setSignOffCrew'

      this.master_signoff_reasons.map((val) => {
        if (val.checked) {
          this.form_signoff_crew.reason_signoff_id.push(val.id)
        }

        if (val.id === 7 || val.checked) {
          this.form_signoff_crew.is_blacklist = 'yes'
        } else {
          this.form_signoff_crew.is_blacklist = 'no'
        }
      })

      console.log(this.form_signoff_crew, 'THIS IS REASON READY TO SAVE')

      const { status, data } = await this[method]({
        id: this.form_crew.id,
        data: this.form_signoff_crew,
      })

      if (status !== 'success') {
        this.saving = false
        this.$swal(`Oops!`, `Semua Data Harus Terisi`, 'error')
        return
      }

      this.$swal(`Tersimpan`, 'Data Sign Off Tersimpan', 'success')
      this.saving = false
    },
    async onSaveBlacklist() {
      this.loading = true
      let formData = new FormData()

      formData.append('reason_off', this.form.blacklist.reason_off)
      formData.append('remarks', this.form.blacklist.remarks)

      if (this.form_candidate.key === 'candidate') {
        const { status, data } = await this.setBlacklistCandidate({
          formData,
          id: this.form_candidate.id,
        })
        if (status !== 'success') {
          this.$swal(`Oops!`, `${data}`, 'error')
          this.loading = false
          this.form.blacklist.reason_off = ''
          this.form.blacklist.remarks = ''
          return
        }
        this.candidateList.data.body = this.candidateList.data.body.filter(
          (val) => {
            return val.id !== this.form_candidate.id
          }
        )

        this.$swal(`Tersimpan`, 'Blacklist candidate berhasil', 'success')
      } else if (this.form_crew.key === 'crew') {
        const { status, data } = await this.setBlacklistCrew({
          formData,
          id: this.form_crew.id,
        })
        if (status !== 'success') {
          this.$swal(`Oops!`, `${data}`, 'error')
          this.loading = false
          this.form.blacklist.reason_off = ''
          this.form.blacklist.remarks = ''
          return
        }
        this.crewList.data.body = this.crewList.data.body.filter((val) => {
          return val.id !== this.form_crew.id
        })

        this.$swal(`Tersimpan`, 'Blacklist crew berhasil', 'success')
      }

      this.loading = false
      this.closeSidebar('blacklist-crew')
      this.form.blacklist.reason_off = ''
      this.form.blacklist.remarks = ''
    },
  },
}
</script>

<style scoped>
/* Hide the modal footer */
#example-modal .modal-footer {
  display: none;
}
</style>
