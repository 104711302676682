var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-input', {
    staticClass: "mb-3",
    attrs: {
      "placeholder": "Search..."
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  })], 1), _c('b-col', {
    staticClass: "mt-4 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-bordered"
  }, [_c('thead', [_c('tr', {
    staticClass: "bg-warning"
  }, [_c('th', {}, [_vm._v("Vehicle")]), _c('th', {}, [_vm._v("Classification of Loss")]), _c('th', {}, [_vm._v("Date of Loss")]), _c('th', {}, [_vm._v("Amount of Loss")])])]), _c('tbody', [_vm.nonClaims.length === 0 ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("No Data or Search not found")])]) : _vm._e(), _vm._l(_vm.nonClaims, function (list, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(" " + _vm._s(list.vehicle ? list.vehicle.name : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(list.classification_of_loss ? list.classification_of_loss.name : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(list.date_of_loss) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.numberFormat(list.cost_of_loss)) + " ")])]);
  })], 2)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }