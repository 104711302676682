import axios from './auth'

const POWER_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/power`;

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getPowerTypes({ commit }, payload) {
    const url = `${POWER_BASE_URL}/types`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params: payload.params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getPowerSeries({ commit }, payload) {
    const url = `${POWER_BASE_URL}/series`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params: payload.params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getPowerTypeById({ commit }, id) {
    const url = `${POWER_BASE_URL}/types/${id}`;

    const method = "get";
    const headers = {};
    const config = {
        url,
        method,
        headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getPowerSeriesById({ commit }, id) {
    const url = `${POWER_BASE_URL}/series/${id}`;

    const method = "get";
    const headers = {};
    const config = {
        url,
        method,
        headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async savePowerType({ commit }, payload) {
    const url = `${POWER_BASE_URL}/types`;

    const method = "post";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async savePowerSeries({ commit }, payload) {
    const url = `${POWER_BASE_URL}/series`;

    const method = "post";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
async deletePowerTypeById({ commit }, id) {
  const url = `${POWER_BASE_URL}/types/${id}`;

  const method = "delete";
  const headers = {};
  const config = {
      url,
      method,
      headers
  };

  try {
      await axios(config);
      return { status: 'success' };
  } catch (e) {
      return {
          status: 'error',
          data: e.response.data
      };
  }
},
async deletePowerSeriesById({ commit }, id) {
  const url = `${POWER_BASE_URL}/series/${id}`;

  const method = "delete";
  const headers = {};
  const config = {
      url,
      method,
      headers
  };

  try {
      await axios(config);
      return { status: 'success' };
  } catch (e) {
      return {
          status: 'error',
          data: e.response.data
      };
  }
},
async updatePowerType({ commit }, payload) {
  const url = `${POWER_BASE_URL}/types/${payload.id}`;

  const method = "put";
  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
  };
  const config = {
    url,
    method,
    data: payload.data,
    headers
  };

  try {
    const response = await axios(config);
    let data = response.data.data;
    return {
      status: 'success',
      data,
    };
  } catch (e) {
    return {
      status: 'error',
      data: e.response.data
    };
  }
},
async updatePowerSeries({ commit }, payload) {
  const url = `${POWER_BASE_URL}/series/${payload.id}`;

  const method = "put";
  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
  };
  const config = {
    url,
    method,
    data: payload.data,
    headers
  };

  try {
    const response = await axios(config);
    let data = response.data.data;
    return {
      status: 'success',
      data,
    };
  } catch (e) {
    return {
      status: 'error',
      data: e.response.data
    };
  }
},

}
