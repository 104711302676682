<template>
  <div>
    <b-row
      v-for="(data, index) in lists"
      :key="index"
    >
      <b-col md="12">
        <b-row>
          <b-col md="3">
            <strong>Vessel:</strong>
            <span class="ml-2">{{ data.vehicle.name }}</span>
          </b-col>
          <b-col md="3">
            <strong>DOL:</strong>
            <span class="ml-2">{{ data.date_of_loss }}</span>
          </b-col>
          <b-col md="6">
            <strong>Cause of Loss:</strong>
            <span class="ml-2">{{
                data.classification_of_loss.name
              }}</span>
          </b-col>
          <b-col md="12" class="mt-3">
            <table class="table table-bordered">
              <thead>
              <tr class="bg-warning">
                <th class="">Claim Status</th>
                <th class="">Status</th>
                <th class="">Remarks</th>
                <th class="">Taken Days</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="data.progress.length === 0">
                <td colspan="4">No Data</td>
              </tr>
              <tr v-for="progress in data.progress">
                <td>
                  {{ progress.actual_claim_progress }}
                </td>
                <td>
                  {{ progress.status }}
                </td>
                <td>
                  {{ progress.remarks }}
                </td>
                <td>
                  {{ progress.total_days }}
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { numberFormat } from '@src/plugins/helpers'

export default {
  name: 'SummaryManagementReportUnSettled',
  props: {
    lists: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  methods: {
    numberFormat (number) {
      return numberFormat(number)
    },
  }
}
</script>

<style scoped>

</style>
