var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Line Awesome Icon")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Use Class "), _c('code', [_vm._v("<i class=\"las la-bell\"></i>")])]), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-bell"
        }), _vm._v("bell")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-phone-volume"
        }), _vm._v("phone")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-radiation"
        }), _vm._v("radiation")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-dog"
        }), _vm._v("dog")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-fish"
        }), _vm._v("fish")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-spider"
        }), _vm._v("la-spider")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-angle-down"
        }), _vm._v("angle-down")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-angle-left"
        }), _vm._v("angle-left")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-angle-right"
        }), _vm._v("angle-right")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-angle-up"
        }), _vm._v("angle-up")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-caret-left"
        }), _vm._v("caret-left")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-caret-right"
        }), _vm._v("caret-right")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-download"
        }), _vm._v("download")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-location-arrow"
        }), _vm._v("location-arrow")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-share"
        }), _vm._v("share")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-backward"
        }), _vm._v("backward")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-play"
        }), _vm._v("play")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-pause"
        }), _vm._v("pause")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-sync"
        }), _vm._v("sync")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-volume-down"
        }), _vm._v("volume-down")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-volume-mute"
        }), _vm._v("volume-mute")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-volume-off"
        }), _vm._v("volume-off")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-volume-up"
        }), _vm._v("volume-up")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "lab la-youtube"
        }), _vm._v("youtube")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-car"
        }), _vm._v("car")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-truck"
        }), _vm._v("truck")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-tree"
        }), _vm._v("tree")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "lab la-pinterest-p"
        }), _vm._v("pinterest")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "lab la-java"
        }), _vm._v("java")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-city"
        }), _vm._v("city")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-edit"
        }), _vm._v("edit")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-copy"
        }), _vm._v("copy")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-cut"
        }), _vm._v("cut")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-pen"
        }), _vm._v("pen")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-tag"
        }), _vm._v("tag")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-save"
        }), _vm._v("save")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-tasks"
        }), _vm._v("tasks")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-comment"
        }), _vm._v("comment")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-video"
        }), _vm._v("video")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-smile"
        }), _vm._v("smile")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-smile"
        }), _vm._v("smile")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "las la-smile"
        }), _vm._v("smile")])], 1), _c('b-col', {
          staticClass: "text-center mt-3",
          attrs: {
            "sm": "12"
          }
        }, [_c('b-link', {
          staticClass: "btn btn-primary",
          attrs: {
            "href": "https://icons8.com/line-awesome",
            "target": "blank"
          }
        }, [_vm._v("View All Icon")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }