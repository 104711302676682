<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Goods In & Out</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="3">
                <b-form-group label="Selecte Date Range" label-for="serial">
                  <date-picker v-model="historiesParamsDate" type="datetime" range placeholder="Select date range" format="DD-MM-YYYY HH:mm"></date-picker>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Choose Type of Stock" label-for="serial">
                  <v-select label="text" v-model="typeStockSelected" :options="category" :reduce="(type) => type.value" placeholder="select type of stock" :multiple="true"></v-select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Goods" label-for="serial">
                  <v-select label="text" v-model="goodsSelected" :options="categoryGoods" :reduce="(type) => type.value" placeholder="select category"></v-select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-button type="submit" variant="primary" class="mt-4"><i class="fa fa-filter"></i> Filter</b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="8">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Data</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th width="3%">No</th>
                    <th>Letter Number</th>
                    <th>Date</th>
                    <th>Goods (Code - Name)</th>
                    <th>Qty</th>
                    <th>In Stock</th>
                    <th>Notes</th>
                    <th>System Log</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                </table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="4">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Add Data</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12">
                <b-form-group label="Choose Type of Stock" label-for="serial">
                  <v-select label="text" v-model="typeStockSelected" :options="category" :reduce="(type) => type.value" placeholder="select type of stock" :multiple="true"></v-select>
                </b-form-group>
                <b-form-group label="Letter Number">
                  <b-form-input id="fleet_name" class="w-100" type="text" placeholder="Letter Number..."></b-form-input>
                </b-form-group>
                <b-form-group label="Date">
                  <date-picker type="date" placeholder="Select date"></date-picker>
                </b-form-group>
                <hr/>
                <b-form-group label="Goods" label-for="serial">
                  <v-select label="text" v-model="goodsSelected" :options="categoryGoods" :reduce="(type) => type.value" placeholder="select category"></v-select>
                </b-form-group>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Qty" label-for="serial">
                      <b-form-input id="fleet_name" class="w-100" type="number" placeholder="Qty..."></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="In Stock" label-for="serial">
                      <strong>50</strong>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group label="Notes" label-for="serial">
                  <b-form-textarea id="description" rows="2"></b-form-textarea>
                  <small style="float: left"><i class="fa fa-warning"></i> Max 500 Character</small>
                </b-form-group>
              </b-col>
              <b-col md="12" class="mt-4 text-right">
                <hr/>
                <b-button type="submit" variant="primary">Save</b-button>
                <b-button type="submit" variant="none" class="iq-bg-danger ml-3">Cancel</b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'

export default {
  name: 'GoodsInOut',
  mounted () {
    xray.index()
  },
  data () {
    return {
      historiesParamsDate: [],
      goodsSelected: [],
      typeStockSelected: [
        { value: 'stock-in', text: 'Stock In' },
        { value: 'stock-out', text: 'Stock Out' }
      ],
      category: [
        { value: 'stock-in', text: 'Stock In' },
        { value: 'stock-out', text: 'Stock Out' }
      ],
      categoryGoods: [
        { value: '-', text: 'Oil Machine' }
      ],
    }
  }
}
</script>
