<template>
  <div v-if="detailMi">
    <div class="accessor-of-the-incident mb-5">
      <b-row class="mb-4 position-relative">
        <b-col md="12" class="text-center mb-4"><h4>FINAL REPORT ROOT CAUSE ANALYSIS</h4></b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col class="m-auto" md="7">
          <table border="1" class="w-100 mb-3">
            <tr>
              <th width="60%" bgcolor="#1e90ff" style="color:#ffe89a">Assessor of the Accident</th>
              <td rowspan="2">
                <table class="w-100">
                  <thead>
                  <tr>
                    <th width="40%">Report Number</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">MI-03/III/2021/HM</td>
                  </tr>
                  <tr>
                    <th width="40%">Vessel</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">{{ detailMi.vehicle.name }}</td>
                  </tr>
                  <tr>
                    <th width="40%">Date of Loss</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">{{rootCauseAnalysis.date_of_loss}}</td>
                  </tr>
                  <tr>
                    <th width="40%">Place of Loss</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">{{detailMi.place_of_loss}}</td>
                  </tr>
                  <tr>
                    <th width="40%">Type of Loss</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">{{ detailMi.type_loss.name }}</td>
                  </tr>
                  <tr>
                    <th width="40%">Type of Coverage</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">{{ detailMi.type_coverage.name }}</td>
                  </tr>
                  </thead>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table class="w-100">
                  <thead>
                  <tr>
                    <th width="40%">Name</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">{{rootCauseAnalysis.assessor_fullname}}</td>
                  </tr>
                  <tr>
                    <th width="40%">Company</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">{{company ? company.text : '-'}}</td>
                  </tr>
                  <tr>
                    <th width="40%">Current Position</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">Junior Manager</td>
                  </tr>
                  </thead>
                </table>
              </td>
            </tr>
            <tr>
              <th bgcolor="#1e90ff" class="text-center" style="color:#ffe89a" colspan="3">all data belongs to Marine Insurance Departement Group Trans Energi Logistik </th>
            </tr>
          </table>
          <div class="d-flex flex-wrap">
            <img v-for="img in form.images" :src="img" alt="" style="width: auto;max-width: 100%;">
          </div>
          <table border="1" class="w-100 mb-3">
            <tr>
              <th width="100%" bgcolor="aqua" class="text-center">
                Never Judge the case without knowing the whole Story, Only God Can Judge Me and How this case Ended
              </th>
            </tr>
          </table>
          <table class="w-100 mb-3">
            <tr>
              <th colspan="2">
                REFERENCE & CHECKLIST :
              </th>
            </tr>
            <tr>
              <td width="80%">
                THE DEFINE WORIKSHEET : PROBLEM STATEMENT WORKSHEET
              </td>
              <td width="20%">
                V
              </td>
            </tr>
            <tr>
              <td width="80%">
                COLLECTING IMFORMATION : THE 5-WHYs WORKSHEET & COMPARATIVE ANALYSIS WORKSHEET
              </td>
              <td width="20%">
                V
              </td>
            </tr>
            <tr>
              <td width="80%">
                INDETIFY THE ROOT CAUSE : FAILURE MODE AND EFFECT ANALYSIS (FMEA)
              </td>
              <td width="20%">
                V
              </td>
            </tr>
            <tr>
              <td width="80%">
                IMPLEMENT CORRECTIVE ACTIONS
              </td>
              <td width="20%">
                V
              </td>
            </tr>
            <tr>
              <td width="80%">
                CAUSE AND EFFECT DIAGRAM (FISH BONE)
              </td>
              <td width="20%">
                V
              </td>
            </tr>
            <tr>
              <td class="pt-5">
                <table class="w-50">
                  <tr>
                    <td width="60%">Starting Date</td>
                    <td>{{form.start_date || '-'}}</td>
                  </tr>
                  <tr>
                    <td>Closing Date</td>
                    <td>{{form.closing_date || '-'}}</td>
                  </tr>
                </table>
              </td>
              <td>
                <table class="w-25">
                  <tr>
                    <td class="confidential">CONFIDENTIAL</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr class="pt-5 pb-5">
              <td colspan="2" class="without-prejudice text-center">
                "WITHOUT PREJUDICE"
              </td>
            </tr>
            <tr class="pt-5">
              <td colspan="2" class="footer-info text-center">
                <h5>MARINE INSURANCE DEPARTEMENT OF GROUP TRANS ENERGI LOGISTIK</h5>
                <div>
                  Bakrie Tower, 9th Floor, Complex Rasuna Said, Jln H.R RASUNA SAID, Kuningan, Jakarta Selatan, Indonesia, 12940
                </div>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </div>
    <div class="daftar-isi d-none mb-5">
      <b-row class="mb-4 position-relative">
        <b-col md="12" class="text-center mb-4"><h4>DAFTAR ISI</h4></b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col class="m-auto" md="7">
          <table class="w-100">
            <tr>
              <th width="5%">A</th>
              <th width="25%">INVESTIGATION DETAILS</th>
              <td colspan="2"></td>
            </tr>
            <tr>
              <td>A.1</td>
              <td>Investigation Team</td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">3</td>
            </tr>
            <tr>
              <td>A.2</td>
              <td>Direct Witness</td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">3</td>
            </tr>
            <tr>
              <td>A.3</td>
              <td>Indirect Witness</td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">3</td>
            </tr>

            <tr>
              <th width="5%">B</th>
              <th width="25%">SUPPORTING DOCUMENT</th>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">3</td>
            </tr>

            <tr>
              <th width="5%">C</th>
              <th width="25%">DETAILS OF INVESTIGATION</th>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">4</td>
            </tr>

            <tr>
              <th width="5%">D</th>
              <th width="25%">SUMMARY OF ACCIDENT</th>
              <td colspan="2"></td>
            </tr>
            <tr>
              <td>D.1</td>
              <td>The Problem</td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">5</td>
            </tr>
            <tr>
              <td>D.2</td>
              <td>Cause of Loss</td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">5</td>
            </tr>
            <tr>
              <td>D.3</td>
              <td>Impact of Damage</td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">5</td>
            </tr>
            <tr>
              <td>D.4</td>
              <td>Cost of Damage</td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">5</td>
            </tr>
            <tr>
              <td>D.5</td>
              <td><strong>Cause & Effect (Findings)</strong></td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">5</td>
            </tr>
            <tr>
              <td>D.5.1</td>
              <td>Human</td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">6</td>
            </tr>
            <tr>
              <td>D.5.2</td>
              <td>Machinery/Equipment etc.</td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">6</td>
            </tr>
            <tr>
              <td>D.5.3</td>
              <td>Control, System, Method,Process …</td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">6</td>
            </tr>
            <tr>
              <td>D.5.4</td>
              <td>Other Factor</td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">7</td>
            </tr>

            <tr>
              <th width="5%">E</th>
              <th width="25%">CLAIMS OPPORTUNITY</th>
              <td colspan="2"></td>
            </tr>
            <tr>
              <td>E.1</td>
              <td>Initial Claim Analysis</td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">8</td>
            </tr>
            <tr>
              <td>E.2</td>
              <td>Document Control </td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">8</td>
            </tr>
            <tr>
              <td>E.3</td>
              <td>Cost of Loss </td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">9</td>
            </tr>
            <tr>
              <td>E.4</td>
              <td>Impact to Loss Ratio </td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">9</td>
            </tr>
            <tr>
              <td>E.5</td>
              <td> Percentage of Claims Handling </td>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">9</td>
            </tr>

            <tr>
              <th width="5%">F</th>
              <th width="25%">CONCLUSION</th>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">9</td>
            </tr>
            <tr>
              <th width="5%">G</th>
              <th width="25%">RECOMMENDATION</th>
              <td width="40%">
                <span v-for="i in 180">.</span>
              </td>
              <td width="10%" class="text-right">10</td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </div>
    <div class="investigation-details mb-5">
      <b-row class="mb-4 position-relative">
        <b-col md="12" class="text-center mb-4"><h4>A. INVESTIGATION DETAILS</h4></b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col class="m-auto" md="7">
          <div class="mb-3">
            <div class="mb-3">
              <h5>A.1 Investigation Team</h5>
            </div>
            <table class="table w-100">
              <thead>
              <tr>
                <th width="20%">Team List</th>
                <th width="20%">Name</th>
                <th width="20%">Position</th>
                <th width="20%">Remarks</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(data, index) in form.investigation_teams">
                <td>
                  {{data.team || '-'}}
                </td>
                <td>
                  {{data.name || '-'}}
                </td>
                <td>
                  {{data.position || '-'}}
                </td>
                <td>
                  {{data.remarks || '-'}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <h5>A.2 Direct Witness</h5>
            </div>
            <table class="table w-100">
              <thead>
              <tr>
                <th width="5%">No</th>
                <th width="20%">Name</th>
                <th width="20%">Position</th>
                <th width="20%">Remarks</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(data, index) in form.direct_witnesses">
                <td>
                  {{index+1}}
                </td>
                <td>
                  {{data.detail ? data.detail.name : '-'}}
                </td>
                <td>
                  {{data.detail ? data.detail.position : '-'}}
                </td>
                <td>
                  {{data.detail ? data.detail.remarks : '-'}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <h5>A.3 Indirect Witness</h5>
            </div>
            <table class="table w-100">
              <thead>
              <tr>
                <th width="5%">No</th>
                <th width="20%">Name</th>
                <th width="20%">Position</th>
                <th width="20%">Remarks</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(data, index) in form.indirect_witnesses">
                <td>
                  {{index+1}}
                </td>
                <td>
                  {{data.detail ? data.detail.name : '-'}}
                </td>
                <td>
                  {{data.detail ? data.detail.position : '-'}}
                </td>
                <td>
                  {{data.detail ? data.detail.remarks : '-'}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="supporting-document mb-5">
      <b-row class="mb-4 position-relative">
        <b-col md="12" class="text-center mb-4"><h4>B. SUPPORTING DOCUMENT</h4></b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col class="m-auto" md="7">
          <table class="table w-100">
            <thead>
            <tr>
              <th width="5%">No</th>
              <th width="20%">Document</th>
              <th width="5%">Original</th>
              <th width="5%">Copy</th>
              <th width="20%">PIC</th>
              <th width="20%">Remarks</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data, index) in form.supporting_documents">
              <td>
                {{index+1}}
              </td>
              <td>
                {{data.document}}
              </td>
              <td>
                {{ data.original || '-' }}
              </td>
              <td>
                {{ data.copy || '-' }}
              </td>
              <td>
                {{ data.pic || '-' }}
              </td>
              <td>
                {{ data.remarks || '-' }}
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </div>
    <div class="detailf-of-investigation mb-5">
      <b-row class="mb-4 position-relative">
        <b-col md="12" class="text-center mb-4"><h4>C. DETAILS OF INVESTIGATION</h4></b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col class="m-auto" md="7">
          <div class="d-flex flex-wrap">
            <img v-for="img in form.images" :src="img" alt="" style="width: auto;max-width: 100%;">
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="summary-of-accident mb-5">
      <b-row class="mb-4 position-relative">
        <b-col md="12" class="text-center mb-4"><h4>D. SUMMARY OF ACCIDENT</h4></b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col class="m-auto" md="7">
          <div class="mb-3">
            <div class="mb-3">
              <h5>D.1 The Problem</h5>
            </div>
            <div class="mb-3">
              <table class="table w-100 table-bordered">
                <tbody>
                <tr>
                  <th width="25%" bgcolor="#ffd700">THE PROBLEM </th>
                  <td width="75%">
                    {{form.problem}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <h5>D.2 Cause of Loss</h5>
            </div>
            <div class="mb-3">
              <table class="table w-100 table-bordered">
                <tbody>
                <tr>
                  <th width="25%" bgcolor="#ffd700">CAUSE OF LOSS </th>
                  <td width="75%">
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    {{form.cause_of_loss}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <h5>D.3 Impact of Damage</h5>
            </div>
            <div class="mb-3">
              <table class="table w-100 table-bordered">
                <tbody>
                <tr>
                  <th width="25%" bgcolor="#ffd700">IMPACT OF DAMAGE</th>
                  <td width="75%">
                    {{form.impact}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <h5>D.4 Cost of Damage</h5>
            </div>
            <div class="mb-3">
              <table class="w-100 mb-3">
                <tr>
                  <td><strong>ESTIMATION DAMAGE AND LOSS VALUE</strong></td>
                  <td class="text-right"></td>
                </tr>
                <tr>
                  <td><strong>LIST OF MATERIAL AND COST </strong></td>
                  <td class="text-right">VESSEL: TB ETI 309</td>
                </tr>
              </table>
              <table class="table w-100">
                <thead>
                <tr>
                  <th width="5%">No</th>
                  <th width="20%">Description</th>
                  <th width="20%">Cost</th>
                  <th width="20%">Total</th>
                  <th width="15%">Remarks</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in form.damage_costs">
                  <td>
                    {{index+1}}
                  </td>
                  <td>
                    {{data.description || '-'}}
                  </td>
                  <td>
                    {{data.cost ? `Rp${numberFormat(data.cost)}` : '-'}}
                  </td>
                  <td>
                    {{data.total ? `Rp${numberFormat(data.total)}` : '-'}}
                  </td>
                  <td>
                    {{data.remarks || '-'}}
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <th bgcolor="#ffd700" colspan="2" class="text-center">
                    TOTAL
                  </th>
                  <th bgcolor="#ffd700">
                    Rp.{{ numberFormat(costOfDamagesSumCost) }}
                  </th>
                  <th bgcolor="#ffd700">
                    Rp.{{ numberFormat(costOfDamagesSumTotal) }}
                  </th>
                  <th bgcolor="#ffd700">
                  </th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <h5>D.5 Cause & Effect (Findings) - For Details please see attachment of Fishbone Diagram for each Witness
              </h5>
            </div>
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <h5>D.5.1 Human
              </h5>
            </div>
            <div class="mb-3">
              <table class="w-100">
                <tr>
                  <td width="80%">
                    <table class="table w-100">
                      <thead>
                      <tr>
                        <th width="10%">No</th>
                        <th width="50%">Findings</th>
                        <th width="20%">Proximity Level</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(data, index) in findingsByPrefix(1)">
                        <td>
                          {{data.value}}
                        </td>
                        <td>
                          {{data.text}}
                        </td>
                        <td class="text-center">
                          {{data.proximity}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                  <td width="20%" style="vertical-align: baseline;"><img src="/img/level-priority.png" alt=""></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <h5>D.5.2 Machinery/Equipment etc.
              </h5>
            </div>
            <div class="mb-3">
              <table class="w-100">
                <tr>
                  <td width="80%">
                    <table class="table w-100">
                      <thead>
                      <tr>
                        <th width="10%">No</th>
                        <th width="50%">Findings</th>
                        <th width="20%">Proximity Level</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(data, index) in findingsByPrefix(2)">
                        <td>
                          {{data.value}}
                        </td>
                        <td>
                          {{data.text}}
                        </td>
                        <td class="text-center">
                          {{data.proximity}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                  <td width="20%" style="vertical-align: baseline;"><img src="/img/level-priority.png" alt=""></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <h5>D.5.3 Control, System, Method,Process
              </h5>
            </div>
            <div class="mb-3">
              <table class="w-100">
                <tr>
                  <td width="80%">
                    <table class="table w-100">
                      <thead>
                      <tr>
                        <th width="10%">No</th>
                        <th width="50%">Findings</th>
                        <th width="20%">Proximity Level</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(data, index) in findingsByPrefix(3)">
                        <td>
                          {{data.value}}
                        </td>
                        <td>
                          {{data.text}}
                        </td>
                        <td class="text-center">
                          {{data.proximity}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                  <td width="20%" style="vertical-align: baseline;"><img src="/img/level-priority.png" alt=""></td>
                </tr>
              </table>
            </div>
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <h5>D.5.4 Other Factor
              </h5>
            </div>
            <div class="mb-3">
              <table class="w-100">
                <tr>
                  <td width="80%">
                    <table class="table w-100">
                      <thead>
                      <tr>
                        <th width="10%">No</th>
                        <th width="50%">Findings</th>
                        <th width="20%">Proximity Level</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(data, index) in findingsByPrefix(4)">
                        <td>
                          {{data.value}}
                        </td>
                        <td>
                          {{data.text}}
                        </td>
                        <td class="text-center">
                          {{data.proximity}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                  <td width="20%" style="vertical-align: baseline;"><img src="/img/level-priority.png" alt=""></td>
                </tr>
              </table>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="claims-opportunity mb-5">
      <b-row class="mb-4 position-relative">
        <b-col md="12" class="text-center mb-4"><h4>E. CLAIMS OPPORTUNITY</h4></b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col class="m-auto" md="7">
          <div class="mb-3">
            <div class="mb-3">
              <h5>E.1 Initial Claim Analysis</h5>
            </div>
            <div v-if="detailSurvey">
              <b-table-simple responsive width="100%" striped>
                <b-thead>
                  <b-th width="5%">NO</b-th>
                  <b-th v-for="(header, headerIndex) in detailSurvey.headers">{{header.label_name}}</b-th>
                </b-thead>
                <b-tbody v-if="detailSurvey.headers[0]">
                  <b-tr v-for="number in detailSurvey.headers[0].contents.length">
                    <b-td>{{number}}</b-td>
                    <b-td v-for="(header, headerIndex) in detailSurvey.headers">
                      <template v-if="header.contents[number-1]">
                        <template v-if="header.contents[number-1].text">
                          {{header.contents[number-1].text}}
                        </template>
                        <template v-else-if="header.contents[number-1].input">
                          {{header.contents[number-1].input.value}}
                        </template>
                        <template v-else>
                          -
                        </template>
                      </template>
                    </b-td>
                  </b-tr>
                  <b-tr class="iq-bg-primary">
                    <b-td colspan="3" class="text-right"><h5><b>Nilai Seluruhnya</b></h5></b-td>
                    <b-td class="text-center"><h5><b>{{ totalAll }}</b></h5></b-td>
                    <b-td>&nbsp;</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <h5>E.2 Document Control</h5>
            </div>
            <div class="mb-3">
              Saat dilakukan joint survey seluruh dokumen sudah dicek dan clear, sehingga tidak ada " hot issue" dalam calaim ini
            </div>
            <table class="table w-100">
              <thead>
              <tr>
                <th width="5%">No</th>
                <th width="20%">Document</th>
                <th width="20%">Remarks</th>
                <th width="10%">Status</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(data, index) in form.document_controls">
                <td>
                  {{index+1}}
                </td>
                <td>
                  {{data.document}}
                </td>
                <td>
                  {{data.remarks}}
                </td>
                <td>
                  <strong>{{data.status}}</strong>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <h5>E.3 Cost of Loss</h5>
            </div>
            <div class="mb-3">
              <table class="table w-100">
                <thead>
                <tr>
                  <th width="5%">No</th>
                  <th width="20%">Description</th>
                  <th width="20%">Cost</th>
                  <th width="20%">Total</th>
                  <th width="15%">Remarks</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in form.loss_costs">
                  <td>
                    {{index+1}}
                  </td>
                  <td>
                    {{data.description || '-'}}
                  </td>
                  <td>
                    {{data.cost ? `Rp${numberFormat(data.cost)}` : '-'}}
                  </td>
                  <td>
                    {{data.total ? `Rp${numberFormat(data.total)}` : '-'}}
                  </td>
                  <td>
                    {{data.remarks || '-'}}
                  </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                  <th bgcolor="#ffd700" colspan="2" class="text-center">
                    TOTAL
                  </th>
                  <th bgcolor="#ffd700">
                    Rp.{{ numberFormat(costOfLossSumCost) }}
                  </th>
                  <th bgcolor="#ffd700">
                    Rp.{{ numberFormat(costOfLossSumTotal) }}
                  </th>
                  <th bgcolor="#ffd700">
                  </th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <h5>E.4 Impact to Loss Ratio</h5>
            </div>
            <div class="mb-3">
            </div>
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <h5>E.5 Percentage of Claims Handling
              </h5>
            </div>
            <div class="mb-3">
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="conclusion mb-5">
      <b-row class="mb-4 position-relative">
        <b-col md="12" class="text-center mb-4"><h4>F. CONCLUSION</h4></b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col class="m-auto" md="11">
          <table class="w-100 table">
            <thead>
            <tr>
              <th rowspan="2" width="5%" class="text-center" style="vertical-align: middle">No</th>
              <th rowspan="2" width="25%" class="text-center" style="vertical-align: middle">Damage</th>
              <th colspan="4" width="40%" class="text-center">Root Cause & Effect (Findings) </th>
              <th rowspan="2" width="10%" class="text-center" style="vertical-align: middle">Status</th>
            </tr>
            <tr>
              <th width="25%">Human</th>
              <th width="25%">Machinery/
                Equipment
              </th>
              <th width="25%">
                Control,
                System,
                Method,Process
              </th>
              <th width="25%">
                Other Factor
              </th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(data, index) in form.conclusions">
              <tr v-for="(row, indexRow) in data.rows">
                <td v-if="indexRow === 0" class="text-center" :rowspan="data.rows.length">
                  {{index+1}}
                </td>
                <td v-if="indexRow === 0" :rowspan="data.rows.length">
                  {{data.damage}}
                </td>
                <td class="text-center">
                  {{row.human}}
                </td>
                <td class="text-center">
                  {{row.equipment}}
                </td>
                <td class="text-center">
                  {{row.csmp}}
                </td>
                <td class="text-center">
                  {{row.other}}
                </td>
                <td class="text-center" :class="{
                        'bg-danger text-white' : row.status === 'fatal',
                        'bg-warning text-white' : row.status === 'danger',
                        'bg-info text-white' : row.status === 'alert',
                        'bg-success text-white' : row.status === 'normal',
                      }">
                  {{row.status}}
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </div>
    <div class="recommendation mb-5">
      <b-row class="mb-4 position-relative">
        <b-col md="12" class="text-center mb-4"><h4>G. RECOMMENDATION</h4></b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col class="m-auto" md="7">
          <table class="w-100 table">
            <thead>
            <tr>
              <td width="10%"></td>
              <th width="30%" class="text-center" bgcolor="#ffd700">HELPFUL</th>
              <th width="30%" class="text-center" bgcolor="#ffd700">HARMFUL</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th bgcolor="#a9a9a9" class="text-center text-white">
                INTERNAL
              </th>
              <td style="vertical-align: top">
                <div><strong>STRENGTH</strong></div>
                <div v-html="form.recommendation.strength"></div>
              </td>
              <td style="vertical-align: top">
                <div><strong>WEAKNESS</strong></div>
                <div v-html="form.recommendation.weakness"></div>
              </td>
            </tr>
            <tr>
              <th bgcolor="#8b4513" class="text-center text-white">
                EXTERNAL
              </th>
              <td style="vertical-align: top">
                <div><strong>OPPORTUNITY</strong></div>
                <div v-html="form.recommendation.opportunity"></div>
              </td>
              <td style="vertical-align: top">
                <div><strong>THREAT</strong></div>
                <div v-html="form.recommendation.threat"></div>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { companiesActions, fleetsActions, miActions } from '@src/Utils/helper'
import { numberFormat } from '@src/plugins/helpers'

export default {
  name: 'FinalReportRootCauseAnalysisPreview',
  async mounted () {
    xray.index()
    if (this.$route.query.formId) {
      this.fetchCompanies()
      await this.fetchMi()
      await this.fetchDetailRCAFinal()
      await this.fetchFormSurvey()
      await this.fetchSurvey()
    }
  },
  data () {
    return {
      detailMi: null,
      detailFillSurvey: null,
      detailSurvey: null,
      detailFinalRCA: null,
      form: {
        _saving: false,
        'images': [],
        'investigation_teams': [],
        'direct_witnesses': [],
        'indirect_witnesses': [],
        'supporting_documents': [],
        'document_controls': [],
        'problem': '',
        'cause_of_loss': '',
        'impact': '',
        'report_number': '',
        'start_date': '',
        'closing_date': '',
        'damage_costs': [],
        'loss_costs': [],
        'findings': [],
        'conclusions': [],
        'recommendation': {
          'strength': '',
          'weakness': '',
          'opportunity': '',
          'threat': ''
        }
      },
      companies: []
    }
  },
  computed: {
    totalAll () {
      let ret = 0

      if (this.detailSurvey) {
        let header = this.detailSurvey.headers.find(header => header.type === 'user_input' && header.contents[0] && header.contents[0].input && header.contents[0].input.alias === 'max100')

        if (header) {
          let filteredContents = header.contents.filter(content => content.input.value)

          filteredContents.forEach(content => {
            ret += parseInt(content.input.value)
          })

          if (filteredContents.length > 0) {
            ret = ret / filteredContents.length
          }
        }
      }

      return Math.round(ret)
    },
    company () {
      let getCompany = this.companies.find(company => company.value === this.rootCauseAnalysis.assessor_company_id)
      return this.detailMi && getCompany ? getCompany : null
    },
    rootCauseAnalysis () {
      return this.detailMi && this.detailMi.rcas ? this.detailMi.rcas[0] : null
    },
    listRootCauseAnalysis () {
      return this.detailMi && this.detailMi.rcas ? this.detailMi.rcas : []
    },
    costOfDamagesSumCost () {
      let total = 0
      this.form.damage_costs.map(cod => total += (cod.cost ? parseInt(cod.cost) : 0))
      return total
    },
    costOfDamagesSumTotal () {
      let total = 0
      this.form.damage_costs.map(cod => total += (cod.total ? parseInt(cod.total) : 0))
      return total
    },
    costOfLossSumCost () {
      let total = 0
      this.form.loss_costs.map(cod => total += (cod.cost ? parseInt(cod.cost) : 0))
      return total
    },
    costOfLossSumTotal () {
      let total = 0
      this.form.loss_costs.map(cod => total += (cod.total ? parseInt(cod.total) : 0))
      return total
    }
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    ...miActions,
    numberFormat (number) {
      return numberFormat(number)
    },
    async fetchCompanies () {
      let res = await this.getCompanies()
      this.companies = res.data.map(val => ({ value: val.id, text: val.company }))
    },
    async fetchMi () {
      const { data, status } = await this.fetchDetailMi({
        id: this.$route.query.formId,
        with: 'vehicle,type_coverage,type_loss,company,documents,company_claimant,status,creator,histories,loss_ratio,rcas,rca_final,cost_breakdown'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      if (data) {
        this.detailMi = data
        if (data.witnesses) {
          if (data.witnesses.direct) {
            this.form.direct_witnesses = data.witnesses.direct.map(direct => {
              return {
                detail: direct
              }
            })
          }
          if (data.witnesses.indirect) {
            this.form.indirect_witnesses = data.witnesses.indirect.map(indirect => {
              return {
                detail: indirect
              }
            })
          }
        }
        if (data.cost_breakdown && data.cost_breakdown.data) {
          this.form.damage_costs = data.cost_breakdown.data.map(cost => {
            return {
              'description': cost.description,
              'cost': cost.value,
              'total': cost.value,
              'remarks': ''
            }
          })
          this.form.loss_costs = data.cost_breakdown.data.map(cost => {
            return {
              'description': cost.description,
              'cost': cost.value,
              'total': cost.value,
              'remarks': ''
            }
          })
        }
      }
    },
    async fetchDetailRCAFinal () {
      const { data, status } = await this.getRCAFinal(this.$route.query.formId)

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      if (data) {
        this.form.images = data.images
        this.form.start_date = data.start_date
        this.form.closing_date = data.closing_date
        this.form.investigation_teams = data.investigation_teams
        // TODO delete soon if not used anymore
        // this.form.direct_witnesses = []
        // this.form.indirect_witnesses = []
        // data.direct_witnesses.map(d => {
        //   this.form.direct_witnesses.push({
        //     detail: this.listRootCauseAnalysis.find(rca => rca.id === d)
        //   })
        // })
        // data.indirect_witnesses.map(d => {
        //   this.form.indirect_witnesses.push({
        //     detail: this.listRootCauseAnalysis.find(rca => rca.id === d)
        //   })
        // })
        this.form.supporting_documents = data.supporting_documents
        this.form.images = data.images
        this.form.document_controls = data.document_controls
        this.form.damage_costs = data.damage_costs.length > 0 ? data.damage_costs : this.form.damage_costs
        this.form.problem = data.problem
        this.form.cause_of_loss = data.cause_of_loss
        this.form.impact = data.impact
        this.form.loss_costs = data.loss_costs
        this.form.findings = data.findings
        this.form.conclusions = data.conclusions
        this.form.report_number = data.report_number
        this.form.recommendation = data.recommendation
      }
    },
    async fetchSurvey () {
      const { data, status } = await this.getClaimAnalysisByFormId({
        id: this.$route.query.formId,
        survey: 'init_claim'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.detailSurvey = data

      if (this.detailSurvey.headers && this.detailFillSurvey.headers) {
        this.detailSurvey.headers.map((header, headerIndex) => {
          if (this.detailFillSurvey.headers[headerIndex] && header.contents) {
            header.contents.map((content, contentIndex) => {
              if (this.detailFillSurvey.headers[headerIndex].contents[contentIndex] &&
                this.detailFillSurvey.headers[headerIndex].contents[contentIndex].value &&
                content.input) {
                content.input.value = this.detailFillSurvey.headers[headerIndex].contents[contentIndex].value.value_anything
              }
            })
          }
        })
      }
    },
    async fetchFormSurvey () {
      const { data, status } = await this.getFormClaimAnalysisByFormId({
        id: this.$route.query.formId,
        survey: 'init_claim'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.detailFillSurvey = data
    },
    findingsByPrefix (prefixNumber) {
      return this.form.findings.filter(finding => finding.value.includes(`D.5.${prefixNumber}-`))
    },
  }
}
</script>
<style>
.confidential {
  padding: 0 10px;
  color: #ad2b35;
  font-weight: bold;
  font-size: 22pt;
  border: 10px solid #ad2b35;
}
.without-prejudice {
  color: #ad2b35;
  font-weight: bold;
  font-size: 16pt;
}
.footer-info {
  color: #333;
  border: 5px solid #333;
}
</style>
