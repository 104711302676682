var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": true,
            "href": "#structure",
            "title": "Ship Particular"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#parts",
            "title": "Master Equipment"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#jobClass",
            "title": "Job Class"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#job",
            "title": "Job Type / Index"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "structure"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Ship Particular")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.getComponentStructList();
            }
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Ship Particular:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.componentStruct.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.componentStruct.params, "search", $$v);
            },
            expression: "componentStruct.params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Ship Particular")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.getComponentStructList();
            }
          }
        }, [_c('b-col', {
          attrs: {
            "md": "9"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.typeFleet,
            "reduce": function reduce(typeFleet) {
              return typeFleet.code;
            }
          },
          model: {
            value: _vm.componentStruct.params.vehicleTypeId,
            callback: function callback($$v) {
              _vm.$set(_vm.componentStruct.params, "vehicleTypeId", $$v);
            },
            expression: "componentStruct.params.vehicleTypeId"
          }
        })], 1), _c('b-col', {
          staticClass: "mr-2",
          attrs: {
            "md": "2"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "variant": "success mb-3",
            "show": _vm.structure_notification.show,
            "dismissible": "",
            "fade": ""
          }
        }, [_vm._v(_vm._s(_vm.structure_notification.text))]), _c('vue-nestable', {
          on: {
            "change": _vm.structuresChanged
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var item = _ref.item;
              return _c('vue-nestable-handle', {
                staticClass: "bg-white shadow rounded mb-5 pl-3 pr-3 pt-1 pb-1 border border-white",
                attrs: {
                  "item": item
                }
              }, [_vm._v(" " + _vm._s(item.text) + "          "), _c('small', [_c('span', {
                staticClass: "text-info ml-5 cursor-pointer",
                on: {
                  "click": function click($event) {
                    return _vm.getComponentStructDetail(item.id);
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-edit"
              })])]), _c('small', [_c('span', {
                staticClass: "text-danger ml-1 cursor-pointer",
                on: {
                  "click": function click($event) {
                    return _vm.onDeleteComponentStructure(item);
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-trash"
              })])])]);
            }
          }]),
          model: {
            value: _vm.componentStruct.data,
            callback: function callback($$v) {
              _vm.$set(_vm.componentStruct, "data", $$v);
            },
            expression: "componentStruct.data"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.componentStruct.editMode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.componentStruct.editMode ? 'Edit' : 'Add') + " Ship Particular")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.saveComponentStruct();
            }
          }
        }, [_c('b-col', {
          staticClass: "text-center mb-4",
          attrs: {
            "md": "12"
          }
        }, [_vm._l(_vm.typeFleet, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            staticClass: "custom-radio-color-checked",
            attrs: {
              "inline": "",
              "name": "color",
              "value": item.code,
              "disabled": item.disabled
            },
            model: {
              value: _vm.componentStruct.form.vehicleTypeId,
              callback: function callback($$v) {
                _vm.$set(_vm.componentStruct.form, "vehicleTypeId", $$v);
              },
              expression: "componentStruct.form.vehicleTypeId"
            }
          }, [_vm._v(_vm._s(item.name))])];
        })], 2), _c('b-form-group', {
          attrs: {
            "label": "Ship Particular Name",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "Power Name",
            "type": "text",
            "placeholder": "Ship Particular Name",
            "maxlength": "30"
          },
          model: {
            value: _vm.componentStruct.form.componentName,
            callback: function callback($$v) {
              _vm.$set(_vm.componentStruct.form, "componentName", $$v);
            },
            expression: "componentStruct.form.componentName"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label-for": "Description",
            "label": "Description"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "notes",
            "rows": "3",
            "placeholder": "Description"
          },
          model: {
            value: _vm.componentStruct.form.description,
            callback: function callback($$v) {
              _vm.$set(_vm.componentStruct.form, "description", $$v);
            },
            expression: "componentStruct.form.description"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Parent",
            "label-for": "parent"
          }
        }, [_c('v-select', {
          class: {
            disabled: true
          },
          attrs: {
            "append-to-body": "",
            "calculate-position": _vm.withPopper,
            "label": "text",
            "options": _vm.structureParent,
            "reduce": function reduce(structureParent) {
              return structureParent.value;
            }
          },
          model: {
            value: _vm.componentStruct.form.parentId,
            callback: function callback($$v) {
              _vm.$set(_vm.componentStruct.form, "parentId", $$v);
            },
            expression: "componentStruct.form.parentId"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Set as Header (No Have Equipment Apply)",
            "label-for": "technical_module"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.componentStruct.form.haveEquipment,
            callback: function callback($$v) {
              _vm.$set(_vm.componentStruct.form, "haveEquipment", $$v);
            },
            expression: "componentStruct.form.haveEquipment"
          }
        }, [_vm.componentStruct.form.haveEquipment === false ? [_vm._v("No")] : [_vm._v("Yes")]], 2)], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.componentStruct.editMode ? 'Save' : 'Add'))])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.initFormComponentStruct();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "parts"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Component Parts")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.getMasterEquipmentList();
            }
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Ship Particular:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.masterEquipment.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.masterEquipment.params, "search", $$v);
            },
            expression: "masterEquipment.params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Component Parts - Equipment")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("No.")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("ID Equipment")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Equipment Name")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Description")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Component For")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Action")])])]), _vm.masterEquipment.data && _vm.masterEquipment.data.length > 0 ? _c('tbody', _vm._l(_vm.masterEquipment.data, function (each, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [_vm._v(_vm._s(_vm.getOverallIndex(i, 'masterEquipment')))]), _c('td', [_vm._v(_vm._s(each.equipmentId || '-'))]), _c('td', [_vm._v(_vm._s(each.equipmentName || '-'))]), _c('td', [_vm._v(_vm._s(each.description || '-'))]), _c('td', _vm._l(each.vehicleTypes, function (vehicleData, j) {
            return each.vehicleTypes && each.vehicleTypes.length > 0 ? _c('b-badge', {
              key: j,
              staticClass: "mr-1",
              attrs: {
                "variant": "secondary"
              }
            }, [_vm._v(_vm._s(vehicleData.name))]) : _c('span', [_vm._v("-")]);
          }), 1), _c('td', [_c('b-badge', {
            attrs: {
              "variant": each.active ? 'success' : 'danger'
            }
          }, [_vm._v(_vm._s(each.active ? 'Active' : 'Inactive'))])], 1), _c('td', [_c('span', {
            staticClass: "text-info mr-1 cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.initFormMasterEquipment(each);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('span', {
            staticClass: "text-danger cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.onDeleteMasterEquipment(each);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])])]);
        }), 0) : _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "8"
          }
        }, [_vm._v("No Data")])])])])])]), _vm.masterEquipment.data && _vm.masterEquipment.data.length > 0 ? _c('b-col', {
          staticClass: "mt-4"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.masterEquipment.meta.total,
            "per-page": _vm.masterEquipment.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getMasterEquipmentList
          },
          model: {
            value: _vm.masterEquipment.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.masterEquipment.meta, "currentPage", $$v);
            },
            expression: "masterEquipment.meta.currentPage"
          }
        })], 1) : _vm._e()], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.masterEquipment.editMode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.masterEquipment.editMode ? 'Edit' : 'Add') + " Component Parts - Equipment")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.saveMasterEquipment();
            },
            "reset": function reset($event) {
              return _vm.initFormMasterEquipment();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "ID Equipment",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "Power Name",
            "type": "text",
            "placeholder": "ID Equipment",
            "maxlength": "30"
          },
          model: {
            value: _vm.masterEquipment.form.equipmentId,
            callback: function callback($$v) {
              _vm.$set(_vm.masterEquipment.form, "equipmentId", $$v);
            },
            expression: "masterEquipment.form.equipmentId"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Equipment Name",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "Power Name",
            "type": "text",
            "placeholder": "Equipment Name",
            "maxlength": "30"
          },
          model: {
            value: _vm.masterEquipment.form.equipmentName,
            callback: function callback($$v) {
              _vm.$set(_vm.masterEquipment.form, "equipmentName", $$v);
            },
            expression: "masterEquipment.form.equipmentName"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label-for": "Description",
            "label": "Description"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "notes",
            "rows": "3",
            "placeholder": "Description"
          },
          model: {
            value: _vm.masterEquipment.form.description,
            callback: function callback($$v) {
              _vm.$set(_vm.masterEquipment.form, "description", $$v);
            },
            expression: "masterEquipment.form.description"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Component For",
            "label-for": "parent"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "ag-placeholder": "Category",
            "placeholder": "Search or add a category",
            "label": "name",
            "track-by": "code",
            "options": _vm.typeFleet,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.masterEquipment.form.vehicleTypes,
            callback: function callback($$v) {
              _vm.$set(_vm.masterEquipment.form, "vehicleTypes", $$v);
            },
            expression: "masterEquipment.form.vehicleTypes"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "technical_module"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.masterEquipment.form.active,
            callback: function callback($$v) {
              _vm.$set(_vm.masterEquipment.form, "active", $$v);
            },
            expression: "masterEquipment.form.active"
          }
        }, [_vm.masterEquipment.form.active === false ? [_vm._v("Inactive")] : [_vm._v("Active")]], 2)], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.masterEquipment.editMode ? 'Save' : 'Add'))])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "reset",
            "variant": "danger"
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "jobClass"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Job Type / Job Index Search")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.getJobClassList();
            }
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Ship Particular:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.jobClass.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.jobClass.params, "search", $$v);
            },
            expression: "jobClass.params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Job Class Data")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("No.")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Job Class Code")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Job Class")]), _c('th', {
          attrs: {
            "width": "30%"
          }
        }, [_vm._v("Description")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Action")])])]), _vm.jobClass.data && _vm.jobClass.data.length > 0 ? _c('tbody', _vm._l(_vm.jobClass.data, function (each, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [_vm._v(_vm._s(_vm.getOverallIndex(i, 'jobClass')))]), _c('td', [_vm._v(_vm._s(each.jobClassCode))]), _c('td', [_vm._v(_vm._s(each.jobClass))]), _c('td', [each.description ? _c('span', {
            domProps: {
              "innerHTML": _vm._s(each.description)
            }
          }) : _c('span', [_vm._v("-")])]), _c('td', [_c('b-badge', {
            attrs: {
              "variant": each.active ? 'success' : 'danger'
            }
          }, [_vm._v(_vm._s(each.active ? 'Active' : 'Inactive'))])], 1), _c('td', [_c('span', {
            staticClass: "text-info mr-1 cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.initFormJobClass(each);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('span', {
            staticClass: "text-danger cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.onDeleteJobClass(each);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])])]);
        }), 0) : _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "8"
          }
        }, [_vm._v("No Data")])])])])])]), _vm.jobClass.data && _vm.jobClass.data.length > 0 ? _c('b-col', {
          staticClass: "mt-4"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.jobClass.meta.total,
            "per-page": _vm.jobClass.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getJobClassList
          },
          model: {
            value: _vm.jobClass.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.jobClass.meta, "currentPage", $$v);
            },
            expression: "jobClass.meta.currentPage"
          }
        })], 1) : _vm._e()], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.jobClass.editMode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.jobClass.editMode ? 'Edit' : 'Add') + " Job Class")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.saveJobClass();
            },
            "reset": function reset($event) {
              $event.preventDefault();
              return _vm.initFormJobClass();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Job Class Code",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "Power Name",
            "type": "text",
            "placeholder": "Job Class Code",
            "maxlength": "30"
          },
          model: {
            value: _vm.jobClass.form.jobClassCode,
            callback: function callback($$v) {
              _vm.$set(_vm.jobClass.form, "jobClassCode", $$v);
            },
            expression: "jobClass.form.jobClassCode"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Job Class",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "Power Name",
            "type": "text",
            "placeholder": "Job Class",
            "maxlength": "30"
          },
          model: {
            value: _vm.jobClass.form.jobClass,
            callback: function callback($$v) {
              _vm.$set(_vm.jobClass.form, "jobClass", $$v);
            },
            expression: "jobClass.form.jobClass"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label-for": "Description",
            "label": "Description"
          }
        }, [_c('tinymce', {
          attrs: {
            "id": "d1"
          },
          model: {
            value: _vm.jobClass.form.description,
            callback: function callback($$v) {
              _vm.$set(_vm.jobClass.form, "description", $$v);
            },
            expression: "jobClass.form.description"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "technical_module"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.jobClass.form.active,
            callback: function callback($$v) {
              _vm.$set(_vm.jobClass.form, "active", $$v);
            },
            expression: "jobClass.form.active"
          }
        }, [_vm.jobType.form.active === false ? [_vm._v("Inactive")] : [_vm._v("Active")]], 2)], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.jobClass.editMode ? 'Save' : 'Add'))])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "reset",
            "variant": "danger"
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "job"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Job Type / Job Index Search")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.getJobTypeList();
            }
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Ship Particular:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.jobType.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.jobType.params, "search", $$v);
            },
            expression: "jobType.params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Job Type / Job Index Data")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("No.")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Job Type Code")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Job Type / Job Index")]), _c('th', {
          attrs: {
            "width": "30%"
          }
        }, [_vm._v("Description")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Action")])])]), _vm.jobType.data && _vm.jobType.data.length > 0 ? _c('tbody', _vm._l(_vm.jobType.data, function (each, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [_vm._v(_vm._s(_vm.getOverallIndex(i, 'jobType')))]), _c('td', [_vm._v(_vm._s(each.jobTypeCode))]), _c('td', [_vm._v(_vm._s(each.jobTypeIndex))]), _c('td', [_vm._v(_vm._s(each.description))]), _c('td', [_c('b-badge', {
            attrs: {
              "variant": each.active ? 'success' : 'danger'
            }
          }, [_vm._v(_vm._s(each.active ? 'Active' : 'Inactive'))])], 1), _c('td', [_c('span', {
            staticClass: "text-info mr-1 cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.initFormJobType(each);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('span', {
            staticClass: "text-danger cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.onDeleteJobType(each);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])])]);
        }), 0) : _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "8"
          }
        }, [_vm._v("No Data")])])])])])]), _vm.jobType.data && _vm.jobType.data.length > 0 ? _c('b-col', {
          staticClass: "mt-4"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.jobType.meta.total,
            "per-page": _vm.jobType.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getJobTypeList
          },
          model: {
            value: _vm.jobType.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.jobType.meta, "currentPage", $$v);
            },
            expression: "jobType.meta.currentPage"
          }
        })], 1) : _vm._e()], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.jobType.editMode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.jobType.editMode ? 'Edit' : 'Add') + " Job Type / Job Index")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.saveJobType();
            },
            "reset": function reset($event) {
              $event.preventDefault();
              return _vm.initFormJobType();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Job Type Code",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "Power Name",
            "type": "text",
            "placeholder": "Job Type Code",
            "maxlength": "30"
          },
          model: {
            value: _vm.jobType.form.jobTypeCode,
            callback: function callback($$v) {
              _vm.$set(_vm.jobType.form, "jobTypeCode", $$v);
            },
            expression: "jobType.form.jobTypeCode"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Job Type / Job Index",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "Power Name",
            "type": "text",
            "placeholder": "Job Type",
            "maxlength": "30"
          },
          model: {
            value: _vm.jobType.form.jobTypeIndex,
            callback: function callback($$v) {
              _vm.$set(_vm.jobType.form, "jobTypeIndex", $$v);
            },
            expression: "jobType.form.jobTypeIndex"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label-for": "Description",
            "label": "Description"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "textarea",
            "placeholder": "Enter something...",
            "rows": "3",
            "max-rows": "6"
          },
          model: {
            value: _vm.jobType.form.description,
            callback: function callback($$v) {
              _vm.$set(_vm.jobType.form, "description", $$v);
            },
            expression: "jobType.form.description"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "technical_module"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.jobType.form.active,
            callback: function callback($$v) {
              _vm.$set(_vm.jobType.form, "active", $$v);
            },
            expression: "jobType.form.active"
          }
        }, [_vm.jobType.form.status === false ? [_vm._v("Inactive")] : [_vm._v("Active")]], 2)], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.jobType.editMode ? 'Save' : 'Add'))])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "reset",
            "variant": "danger"
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }