var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('iq-card', [_c('b-row', {
    staticClass: "d-flex align-items-center mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('h4', {
    staticClass: "mb-0 p-3 ml-2"
  }, [_vm._v(_vm._s(_vm.page.name))]), _c('b-breadcrumb', {
    staticClass: "bg-transparent mb-0 mt-1",
    attrs: {
      "items": _vm.page.breadCrumb
    }
  })], 1)]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "3"
    }
  }, [!_vm.selectedBookmark ? _c('b-button', {
    staticClass: "text-primary",
    attrs: {
      "variant": "none"
    },
    on: {
      "click": function click($event) {
        return _vm.addToBookmark(_vm.currentRoute);
      }
    }
  }, [_c('i', {
    staticClass: "ri-star-line font-size-24"
  })]) : _c('b-button', {
    staticClass: "text-warning",
    attrs: {
      "variant": "none"
    },
    on: {
      "click": function click($event) {
        return _vm.removeToBookmark(_vm.currentRoute);
      }
    }
  }, [_c('i', {
    staticClass: "ri-star-fill font-size-24"
  })])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }