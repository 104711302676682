var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "iq-accordion career-style faq-style"
  }, _vm._l(_vm.faqList, function (item, index) {
    return _c('iq-card', {
      key: index,
      staticClass: "iq-card iq-accordion-block",
      class: item.active ? 'accordion-active' : ''
    }, [_c('div', {
      staticClass: "clearfix",
      class: item.active ? 'active-faq' : ''
    }, [_c('div', {
      staticClass: "container"
    }, [_c('b-row', {
      staticClass: "py-2"
    }, [_c('b-col', {
      attrs: {
        "sm": "12"
      }
    }, [_c('a', {
      staticClass: "accordion-title"
    }, [_c('span', [_vm._v(" " + _vm._s(item.title) + " ")])])])], 1)], 1)]), _c('div', {
      staticClass: "accordion-details"
    }, [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(item.description) + " ")])])]);
  }), 1)]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "iq-accordion career-style faq-style"
  }, _vm._l(_vm.faqList, function (item, index) {
    return _c('iq-card', {
      key: index,
      staticClass: "iq-card iq-accordion-block",
      class: item.active ? 'accordion-active' : ''
    }, [_c('div', {
      staticClass: "clearfix",
      class: item.active ? 'active-faq' : ''
    }, [_c('div', {
      staticClass: "container"
    }, [_c('b-row', {
      staticClass: "py-2"
    }, [_c('b-col', {
      attrs: {
        "sm": "12"
      }
    }, [_c('a', {
      staticClass: "accordion-title"
    }, [_c('span', [_vm._v(" " + _vm._s(item.title) + " ")])])])], 1)], 1)]), _c('div', {
      staticClass: "accordion-details"
    }, [_c('p', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(item.description) + " ")])])]);
  }), 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }