var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "row-eq-height"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('ComposeBody')], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }