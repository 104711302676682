<template>
  <div class="forecast-final-result">
    <b-container fluid>
      <b-row>
        <b-col md="8">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">List Forecast Final Independent</h4>
            </template>
            <template v-slot:body>
              <div v-if="loading" class="text-center my-5">
                <b-spinner type="grow" label="Spinning"></b-spinner>
              </div>
              <template v-else>
                <div class="table-responsive" v-if="lists.data.length > 0">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th width="8%">No</th>
                      <th width="25%">Vehicle Type Group</th>
                      <th width="12%">Year</th>
                      <th width="10%">Final Rate HM</th>
                      <th width="10%">Final Rate IV</th>
                      <th width="10%">Final Rate WAR</th>
                      <th width="15%">Companies</th>
                      <th width="10%">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(c, i) in lists.data" :key="`c-${i}`">
                      <td>{{ i + 1 }}</td>
                      <td>{{ typeFleet.find(type => type.code === c.vehicle_type_group_id) ? typeFleet.find(type => type.code === c.vehicle_type_group_id).name : '-' }}</td>
                      <td>
                        {{c.year}}
                      </td>
                      <td>
                        {{c.final_rate_hm}}
                      </td>
                      <td>
                        {{c.final_rate_iv}}
                      </td>
                      <td>
                        {{c.final_rate_war}}
                      </td>
                      <td>
                        <b-badge variant="primary" v-for="company in c.company.filter(company => companies.find(com => com.id === company)).map(company => companies.find(com => com.id === company).company)" >
                          {{company}}
                        </b-badge>
                      </td>
                      <td>
                        <b-button
                          variant="success"
                          size="sm"
                          class="mr-2"
                          @click="handeEditList(c)"
                        ><i class="fa fa-edit"></i
                        ></b-button>
                        <b-button
                          variant="danger"
                          size="sm"
                          @click="handleDeleteList(c)"
                        ><i class="fa fa-trash"></i
                        ></b-button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <p v-else class="text-center my-4"
                >No data found.</p
                >
              </template>
            </template>
          </iq-card>
        </b-col>
        <b-col md="4">
          <iq-card :class="{ 'border border-danger shadow-lg': editMode }">
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ editMode ? 'Edit' : 'Add' }} Data</h4>
            </template>
            <template v-slot:body>
              <b-form @submit.prevent="handleSubmitForm">
                <b-form-group
                  label="Year *"
                >
                  <v-select v-model="form.year" :options="yearOption" placeholder="Select Year"
                            :clearable="false"></v-select>
                </b-form-group>
                <b-form-group
                  label="Vehicle Type Group *"
                >
                  <v-select v-model="form.vehicle_type_group_id" label="name"
                            :options="typeFleet" :reduce="type => type.code">
                  </v-select>
                </b-form-group>
                <b-form-group
                  label="Companies *"
                >
                  <v-select v-model="form.company" multiple label="company"
                            :options="companies" :reduce="type => type.id">
                  </v-select>
                </b-form-group>
                <b-form-group
                  label="Final Rate HM *"
                >
                  <b-form-input type="number" v-model="form.final_rate_hm" step=".001" required></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Final Rate IV *"
                >
                  <b-form-input type="number" v-model="form.final_rate_iv" step=".001" required></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Final Rate War"
                >
                  <b-form-input type="number" v-model="form.final_rate_war" step=".001"></b-form-input>
                </b-form-group>
                <b-button type="submit" variant="primary">Save</b-button>
              </b-form>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { companiesActions, fleetsActions, miActions } from '@src/Utils/helper'

export default {
  name: 'ForecastFinalResultIndependent',
  data () {
    return {
      selectedData: null,
      editMode: false,
      loading: true,
      typeFleet: [],
      companies: [],
      yearOption: [],
      lists: {
        data: []
      },
      form: {
        saving: false,
        id: '',
        'vehicle_type_group_id': '',
        'company': [],
        'year': '',
        'final_rate_hm': '',
        'final_rate_iv': '',
        'final_rate_war': ''
      }
    }
  },
  async mounted () {
    this.setYearOption()
    await this.handleGetCompanies()
    await this.handleGetVehicleGroup()
    this.handleGetLists()
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    ...miActions,
    async handleGetVehicleGroup () {
      const res = await this.getFleetTypeGroups()

      if (res.status === 'success' && res.data && res.data.length) {
        res.data.forEach(element => {
          this.typeFleet.push({
            name: element.name,
            code: element.id,
            types: element.types
          })
        })
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async handleGetCompanies () {
      let res = await this.getCompanies()
      this.companies = res.data
    },
    async handleGetLists () {
      this.loading = true
      let { data } = await this.getForecastFinalIndependent()

      if (data.data) {
        this.lists.data = data.data
      }

      this.loading = false
    },
    setYearOption () {
      const yearNow = new Date(Date.now()).getFullYear()
      const arr = [yearNow]
      for (let i = 1; i <= 5; i++) {
        arr.unshift(yearNow + i)
      }
      for (let i = 1; i <= 10; i++) {
        arr.push(yearNow - i)
      }
      this.yearOption = arr
    },
    handleResetForm () {
      this.editMode = false
      this.form = {
        saving: false,
        id: '',
        'vehicle_type_group_id': '',
        'company': [],
        'year': '',
        'final_rate_hm': '',
        'final_rate_iv': '',
        'final_rate_war': ''
      }
    },
    async handleSubmitForm () {
      this.form.saving = true

      let payload = { ...this.form }

      if (!this.editMode) {
        let res = await this.saveForecastFinalIndependent(payload)
        if (res.status === 'success') {
          this.$swal('Data added!', 'Data added successfully.', 'success')
          this.handleResetForm()
          this.handleGetLists()
        } else {
          if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
            this.$swal(
              'Error',
              'Something wrong with the server. Please try again later.',
              'error'
            )
          }
        }
      } else {
        let res = await this.updateForecastFinalIndependent({
          id: this.selectedData.id,
          data: payload
        })
        if (res.status === 'success') {
          this.handleResetForm()
          this.handleGetLists()
          this.$swal(
            'Data updated!',
            'Data updated successfully.',
            'success'
          )
        } else {
          if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
            this.$swal(
              'Error',
              'Something wrong with the server. Please try again later.',
              'error'
            )
          }
        }
      }

      this.form.saving = false
    },
    handeEditList (data) {
      this.editMode = true
      this.selectedData = data
      this.form.id = data.id
      this.form.year = data.year
      this.form.final_rate_war = data.final_rate_war
      this.form.final_rate_iv = data.final_rate_iv
      this.form.final_rate_hm = data.final_rate_hm
      this.form.vehicle_type_group_id = data.vehicle_type_group_id
      this.form.company = data.company
    },
    handleDeleteList (data) {
      this.$swal({
        title: 'Delete Data?',
        text: `Data will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteForecastFinalIndependent({
            id: data.id
          })
          if (res.status !== 'success') {
            if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.handleResetForm()
            this.handleGetLists()
            this.$swal(
              `Data deleted!`,
              'success'
            )
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
