import axios from './auth'

const PROJECT_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/projects`

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getProjects ({ commit }, params = {}) {
    const url = PROJECT_BASE_URL

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getProjectById ({ commit }, payload) {
    const url = `${PROJECT_BASE_URL}/${payload}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async addProject ({ commit }, payload) {
    const url = PROJECT_BASE_URL
    let urlencoded = new URLSearchParams()
    urlencoded.append('projectName', payload)

    const method = 'post'
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    const config = {
      url,
      method,
      data: urlencoded,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async addProjectDetail ({ commit }, payload) {
    const url = `${PROJECT_BASE_URL}/${payload.id}`

    const method = 'post'
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    const config = {
      url,
      method,
      data: payload.data,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async updateProjectDetail ({ commit }, payload) {
    const url = `${PROJECT_BASE_URL}/${payload.id}`

    const method = 'put'
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
    const config = {
      url,
      method,
      data: payload.data,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async deleteProject ({ commit }, payload) {
    const url = `${PROJECT_BASE_URL}/${payload}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      await axios(config)
      return { status: 'success' }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async exportProjects ({ commit }, params = {}) {
    const url = `${PROJECT_BASE_URL}/export`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
      responseType: 'blob'
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  }
}
