import axios from './auth'

const MODULE_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/modules`;
const MODULE_CATEGORY_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/module-categories`;

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getModules({ commit }, params = {}) {
    const url = MODULE_BASE_URL;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getModuleCategories({ commit }, params = {}) {
    const url = MODULE_CATEGORY_BASE_URL;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async addModule({ commit }, payload) {
    const url = MODULE_BASE_URL;

    const method = "post";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async saveModuleCategories({ commit }, payload) {
    const url = MODULE_CATEGORY_BASE_URL;

    const method = "post";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async updateModule({ commit }, payload) {
    const url = `${MODULE_BASE_URL}/${payload.id}`;

    const method = "put";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      data: payload.data,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async deleteModule({ commit }, payload) {
    const url = `${MODULE_BASE_URL}/${payload}`;

    const method = "delete";
    const headers = {};
    const config = {
        url,
        method,
        headers
    };

    try {
        await axios(config);
        return { status: 'success' };
    } catch (e) {
        return {
            status: 'error',
            data: e.response.data
        };
    }
},
}
