var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "iq-comingsoon pt-5"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-sm-9 text-center"
  }, [_c('div', {
    staticClass: "iq-comingsoon-info"
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "img-fluid w-25",
    attrs: {
      "src": require('../../assets/images/logo.png'),
      "alt": "logo"
    }
  })]), _c('h2', {
    staticClass: "mt-4 mb-1"
  }, [_vm._v("Stay tunned, we're launching very soon")]), _c('p', [_vm._v("We are working very hard to give you the best experience possible!")]), _vm._m(0)])])]), _vm._m(1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "countdown",
    attrs: {
      "countdown": "",
      "data-date": "Feb 02 2022 20:20:22"
    }
  }, [_c('li', [_c('span', {
    attrs: {
      "data-days": ""
    }
  }, [_vm._v("0")]), _vm._v("Days")]), _c('li', [_c('span', {
    attrs: {
      "data-hours": ""
    }
  }, [_vm._v("0")]), _vm._v("Hours")]), _c('li', [_c('span', {
    attrs: {
      "data-minutes": ""
    }
  }, [_vm._v("0")]), _vm._v("Minutes")]), _c('li', [_c('span', {
    attrs: {
      "data-seconds": ""
    }
  }, [_vm._v("0")]), _vm._v("Seconds")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-4"
  }, [_c('form', {
    staticClass: "iq-comingsoon-form mt-5"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('input', {
    staticClass: "form-control mb-0",
    attrs: {
      "type": "email",
      "id": "exampleInputEmail1",
      "aria-describedby": "emailHelp",
      "placeholder": "Enter email address"
    }
  })]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("Subscribe")])])])]);

}]

export { render, staticRenderFns }