var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Goods Setup in Fleets")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Search fleets..."
          },
          on: {
            "input": _vm.fetchFleets
          },
          model: {
            value: _vm.fleetParams.search,
            callback: function callback($$v) {
              _vm.$set(_vm.fleetParams, "search", $$v);
            },
            expression: "fleetParams.search"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "8"
          }
        })], 1), _vm.fleetsLoading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.fleets.length > 0 ? _c('b-row', _vm._l(_vm.fleets, function (fleet, i) {
          return _c('b-col', {
            key: "fleet-".concat(i),
            staticClass: "text-center py-5 border-top",
            attrs: {
              "md": "3"
            }
          }, [_c('div', {
            staticClass: "custom-control custom-checkbox image-checkbox"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.checkedFleets,
              expression: "checkedFleets"
            }],
            staticClass: "custom-control-input",
            attrs: {
              "id": "ck-fl-".concat(i),
              "type": "checkbox",
              "disabled": _vm.queryVehicleId
            },
            domProps: {
              "value": fleet,
              "checked": Array.isArray(_vm.checkedFleets) ? _vm._i(_vm.checkedFleets, fleet) > -1 : _vm.checkedFleets
            },
            on: {
              "change": function change($event) {
                var $$a = _vm.checkedFleets,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = fleet,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && (_vm.checkedFleets = $$a.concat([$$v]));
                  } else {
                    $$i > -1 && (_vm.checkedFleets = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.checkedFleets = $$c;
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": "ck-fl-".concat(i)
            }
          }, [_c('img', {
            staticClass: "img-fluid",
            attrs: {
              "src": fleet.vehicleType.icon.url,
              "alt": "#"
            }
          })]), _c('small', [_c('strong', [_vm._v(_vm._s(fleet.name))])])])]);
        }), 1) : _c('div', {
          staticClass: "text-center my-5"
        }, [_c('p', {
          staticClass: "mb-0 text-muted"
        }, [_vm._v("No fleets data found.")])])], _c('b-row', {
          staticClass: "mt-2"
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('small', [_vm._v("Total = 100 | Per Pages = 25 ")])]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.rows,
            "align": "right"
          },
          model: {
            value: _vm.currentPage,
            callback: function callback($$v) {
              _vm.currentPage = $$v;
            },
            expression: "currentPage"
          }
        })], 1), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "12"
          }
        }, [_c('hr'), _c('b-row', {
          staticClass: "mt-2 mb-2"
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Goods",
            "label-for": "serial"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.category,
            "reduce": function reduce(type) {
              return type.value;
            },
            "placeholder": "select category"
          },
          model: {
            value: _vm.form.goods,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "goods", $$v);
            },
            expression: "form.goods"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Maximum Limit"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Unit Name..."
          },
          model: {
            value: _vm.form.maximumLimit,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "maximumLimit", $$v);
            },
            expression: "form.maximumLimit"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "serial"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.status,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status", $$v);
            },
            expression: "form.status"
          }
        }, [_vm.form.status === true || _vm.form.status === 'true' ? [_vm._v("Yes")] : [_vm._v("No")]], 2)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-button', {
          staticClass: "mt-4",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_vm._v("Add")])], 1)], 1), _c('hr')], 1), _c('b-col', {
          staticClass: "mt-3 mb-3",
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("No.")]), _c('th', [_vm._v("Goods")]), _c('th', [_vm._v("Current Stock")]), _c('th', [_vm._v("Minimum Limit Alert")]), _c('th', [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("1.")]), _c('td', [_vm._v("PQ3-01 || Piston")]), _c('td', [_vm._v("50")]), _c('td', [_vm._v("20")]), _c('td', [_c('span', [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "warning"
          }
        }, [_vm._v("history")])], 1), _c('span', [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        })])], 1), _c('span', [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash"
        })])], 1)])])])])]), _c('b-col', {
          staticClass: "mb-5",
          attrs: {
            "md": "12"
          }
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.rows,
            "align": "center"
          },
          model: {
            value: _vm.currentPage,
            callback: function callback($$v) {
              _vm.currentPage = $$v;
            },
            expression: "currentPage"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }