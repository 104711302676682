import axios from 'axios'
import CryptoJS from "crypto-js"

const CRYPTO_SECRET_KEY = process.env.VUE_APP_CRYPTO_SECRET_KEY
const AUTH_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/auth`
const USERS_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/users`

// ===
// Private helpers
// ===
function encrypt(text) {
	return CryptoJS.AES.encrypt(text, CRYPTO_SECRET_KEY).toString()
}

function decrypt(cipherText) {
	const bytes = CryptoJS.AES.decrypt(cipherText, CRYPTO_SECRET_KEY)
	return bytes.toString(CryptoJS.enc.Utf8);
}

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key))
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}

function setDefaultAuthHeaders(state) {
  axios.defaults.headers.common.Authorization = state.currentUser
    ? `Bearer ${decrypt(state.currentUser.token)}`
    : ''
}
// ===
// End Private helpers
// ===

export const state = {
  currentUser: getSavedState('currentUser'),
  profile: getSavedState('profile'),
}

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    saveState('currentUser', newValue)
    setDefaultAuthHeaders(state)
  },
  SET_PROFILE(state, newValue) {
    state.profile = newValue
    saveState('profile', newValue)
  },
}

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser
  },
  profile(state) {
    return state.profile
  },
}

export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch }) {
    dispatch('validate')
    setDefaultAuthHeaders(state)
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  async validate({ commit, state }) {
    if (!state.currentUser) return Promise.resolve(null)

    var urlencoded = new URLSearchParams();
    urlencoded.append("refreshToken", decrypt(state.currentUser.refreshToken));

    const url = `${AUTH_BASE_URL}/refresh-token`;

    const method = "POST";
    const data = urlencoded;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const config = {
      url,
      method,
      headers,
      data
    };

    try {
      const response = await axios(config);
      const data = response.data;
      const current_user = {
        token: encrypt(data.token),
        refreshToken: encrypt(data.refreshToken)
      }

      commit("SET_CURRENT_USER", current_user);

      return current_user;
    } catch (e) {
      if (e.response && e.response.status === 401) {
        commit('SET_CURRENT_USER', null)
        commit('SET_PROFILE', null)
      }
      return null
    }
  },

  // Logs in the current user.
  async logIn({ commit }, { username, password } = {}) {
    const url = `${AUTH_BASE_URL}/login`
    const method = "POST"
    const data = { username, password }
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      headers,
      data
    };

    try {
      const response = await axios(config)
      const user = response.data.data
      const current_user = {
        token: encrypt(user.token),
        refreshToken: encrypt(user.refreshToken)
      }

      commit('SET_CURRENT_USER', current_user)

      return {
        status: 'success',
        data: user,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }

  },

  // Logs out the current user.
  logOut({ commit }) {
    commit('SET_CURRENT_USER', null)
    commit('SET_PROFILE', null)
  },

  async getProfile({ commit }) {
    const url = `${USERS_BASE_URL}/profile/me`;

    const method = "get";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      commit("SET_PROFILE", data);
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },

  async updateProfile({ commit }, payload) {
    const url = `${USERS_BASE_URL}/profile`;

    const method = "put";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json",
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },

  async updatePhoto({ commit }, payload) {
    const url = `${USERS_BASE_URL}/profile/photo`;

    const method = "post";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },

  async updateUsername({ commit }, payload) {
    const url = `${USERS_BASE_URL}/profile/username`;
    const method = "put";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },

  async updatePassword({ commit }, payload) {
    const url = `${USERS_BASE_URL}/profile/password`;

    const method = "put";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },

  async forgotPassword({ commit }, payload) {
    const url = `${AUTH_BASE_URL}/forgot-password`;

    const method = "post";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },

  validateForgotToken({ commit }, payload) {
    const url = `${AUTH_BASE_URL}/token/validate`;

    const method = "get";
    const headers = {
      "Authorization": `Bearer ${payload}`,
    };
    const config = {
      url,
      method,
      headers
    };

    return axios(config)
    .then(res => {
      return { status: 'success' };
    }).catch(err => {
      return { status: 'error' };
    });
  },

  async changePassword({ commit }, payload) {
    const url = `${USERS_BASE_URL}/password`;
    const method = "post";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `Bearer ${payload.token}`,
    };
    const config = {
      url,
      method,
      data: payload.data,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
}

export default axios
