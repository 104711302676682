var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Popovers")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-1",
            modifiers: {
              "collapse-1": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-1"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "popover-1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button size=\"lg\" variant=\"danger\" id=\"popover-button-variant\">Click to toggle popover</b-button>\n<b-popover target=\"popover-button-variant\" variant=\"danger\">\n  <template v-slot:title>Popover title</template>\n  And here's some amazing content. It's very engaging. Right?\n</b-popover>\n")])])])])]), _c('b-button', {
          attrs: {
            "size": "lg",
            "variant": "danger",
            "id": "popover-button-variant"
          }
        }, [_vm._v("Click to toggle popover")]), _c('b-popover', {
          attrs: {
            "target": "popover-button-variant",
            "variant": "danger"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function fn() {
              return [_vm._v("Popover title")];
            },
            proxy: true
          }])
        }, [_vm._v(" And here's some amazing content. It's very engaging. Right? ")])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Dismiss on next click")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-2",
            modifiers: {
              "collapse-2": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Use the "), _c('code', [_vm._v("focus")]), _vm._v(" trigger to dismiss popovers on the user’s next click of a different element than the toggle element.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-2"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "popover-2"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button href=\"#\" variant=\"danger\" id=\"popover-button-dismiss\">Dismissible popover</b-button>\n<b-popover target=\"popover-button-dismiss\" variant=\"danger\" triggers=\"focus\">\n  <template v-slot:title>Dismissible popover</template>\n  And here's some amazing content. It's very engaging. Right?\n</b-popover>\n")])])])])]), _c('b-button', {
          attrs: {
            "href": "#",
            "variant": "danger",
            "id": "popover-button-dismiss"
          }
        }, [_vm._v("Dismissible popover")]), _c('b-popover', {
          attrs: {
            "target": "popover-button-dismiss",
            "variant": "danger",
            "triggers": "focus"
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function fn() {
              return [_vm._v("Dismissible popover")];
            },
            proxy: true
          }])
        }, [_vm._v(" And here's some amazing content. It's very engaging. Right? ")])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Disabled elements")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-3",
            modifiers: {
              "collapse-3": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Elements with the "), _c('code', [_vm._v("disabled")]), _vm._v(" attribute aren’t interactive, meaning users cannot hover or click them to trigger a popover (or tooltip). As a workaround, you’ll want to trigger the popover from a wrapper "), _c('code', [_vm._v("<div>")]), _vm._v(" or "), _c('code', [_vm._v("<span>")]), _vm._v(" and override the "), _c('code', [_vm._v("pointer-events")]), _vm._v(" on the disabled element.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-3"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "popover-3"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<span class=\"d-inline-block\" tabindex=\"0\" v-b-popover title=\"Disabled popover\">\n  <b-button variant=\"primary\" style=\"pointer-events: none;\" disabled>Disabled button</b-button>\n</span>\n")])])])])]), _c('span', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover"
          }],
          staticClass: "d-inline-block",
          attrs: {
            "tabindex": "0",
            "title": "Disabled popover"
          }
        }, [_c('b-button', {
          staticStyle: {
            "pointer-events": "none"
          },
          attrs: {
            "variant": "primary",
            "disabled": ""
          }
        }, [_vm._v("Disabled button")])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Hover elements")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-4",
            modifiers: {
              "collapse-4": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("For disabled popover triggers, you may also prefer "), _c('code', [_vm._v("data-trigger=\"hover\"")]), _vm._v(" so that the popover appears as immediate visual feedback to your users as they may not expect to "), _c('em', [_vm._v("click")]), _vm._v(" on a disabled element.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-4"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "popover-4"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<span class=\"d-inline-block\" tabindex=\"0\" v-b-popover.hover title=\"Disabled popover\">\n  <b-button variant=\"primary\" style=\"pointer-events: none;\" disabled>Disabled button</b-button>\n</span>\n")])])])])]), _c('span', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "d-inline-block",
          attrs: {
            "tabindex": "0",
            "title": "Disabled popover"
          }
        }, [_c('b-button', {
          staticStyle: {
            "pointer-events": "none"
          },
          attrs: {
            "variant": "primary",
            "disabled": ""
          }
        }, [_vm._v("Disabled button")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Four directions")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-5",
            modifiers: {
              "collapse-5": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Four options are available: top, right, bottom, and left aligned.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-5"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "popover-5"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button v-b-popover.click.top=\"'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'\">\n  Popover on top\n</b-button>\n<b-button v-b-popover.click.right=\"'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'\">\n  Popover on right\n</b-button>\n<b-button v-b-popover.click.bottom=\"'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'\">\n  Popover on bottom\n</b-button>\n<b-button v-b-popover.click.left=\"'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'\">\n  Popover on left\n</b-button>\n")])])])])]), _c('b-button', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.click.top",
            value: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
            expression: "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
            modifiers: {
              "click": true,
              "top": true
            }
          }]
        }, [_vm._v(" Popover on top ")]), _c('b-button', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.click.right",
            value: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
            expression: "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
            modifiers: {
              "click": true,
              "right": true
            }
          }]
        }, [_vm._v(" Popover on right ")]), _c('b-button', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.click.bottom",
            value: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
            expression: "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
            modifiers: {
              "click": true,
              "bottom": true
            }
          }]
        }, [_vm._v(" Popover on bottom ")]), _c('b-button', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.click.left",
            value: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
            expression: "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
            modifiers: {
              "click": true,
              "left": true
            }
          }]
        }, [_vm._v(" Popover on left ")])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Popovers With Color")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-6",
            modifiers: {
              "collapse-6": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Four options are available: top, right, bottom, and left aligned.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-6"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "popover-6"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button variant=\"primary\" v-b-popover.click.top=\"'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'\">\n  Popover on top\n</b-button>\n<b-button variant=\"success\" v-b-popover.click.right=\"'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'\">\n  Popover on right\n</b-button>\n<b-button variant=\"danger\" v-b-popover.click.bottom=\"'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'\">\n  Popover on bottom\n</b-button>\n<b-button variant=\"info\" v-b-popover.click.left=\"'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'\">\n  Popover on left\n</b-button>\n")])])])])]), _c('b-button', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.click.top",
            value: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
            expression: "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
            modifiers: {
              "click": true,
              "top": true
            }
          }],
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(" Popover on top ")]), _c('b-button', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.click.right",
            value: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
            expression: "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
            modifiers: {
              "click": true,
              "right": true
            }
          }],
          attrs: {
            "variant": "success"
          }
        }, [_vm._v(" Popover on right ")]), _c('b-button', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.click.bottom",
            value: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
            expression: "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
            modifiers: {
              "click": true,
              "bottom": true
            }
          }],
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v(" Popover on bottom ")]), _c('b-button', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.click.left",
            value: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
            expression: "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
            modifiers: {
              "click": true,
              "left": true
            }
          }],
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Popover on left ")])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Popovers With Color")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-7",
            modifiers: {
              "collapse-7": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Four options are available: top, right, bottom, and left aligned.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-7"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "popover-7"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button  variant=\"none\" class=\"iq-bg-primary\" v-b-popover.click.top=\"'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'\">\n  Popover on top\n</b-button>\n<b-button  variant=\"none\" class=\"iq-bg-success\" v-b-popover.click.right=\"'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'\">\n  Popover on right\n</b-button>\n<b-button  variant=\"none\" class=\"iq-bg-danger\" v-b-popover.click.bottom=\"'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'\">\n  Popover on bottom\n</b-button>\n<b-button  variant=\"none\" class=\"iq-bg-info\" v-b-popover.click.left=\"'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'\">\n  Popover on left\n</b-button>\n")])])])])]), _c('b-button', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.click.top",
            value: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
            expression: "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
            modifiers: {
              "click": true,
              "top": true
            }
          }],
          staticClass: "iq-bg-primary",
          attrs: {
            "variant": "none"
          }
        }, [_vm._v(" Popover on top ")]), _c('b-button', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.click.right",
            value: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
            expression: "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
            modifiers: {
              "click": true,
              "right": true
            }
          }],
          staticClass: "iq-bg-success",
          attrs: {
            "variant": "none"
          }
        }, [_vm._v(" Popover on right ")]), _c('b-button', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.click.bottom",
            value: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
            expression: "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
            modifiers: {
              "click": true,
              "bottom": true
            }
          }],
          staticClass: "iq-bg-danger",
          attrs: {
            "variant": "none"
          }
        }, [_vm._v(" Popover on bottom ")]), _c('b-button', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.click.left",
            value: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
            expression: "'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.'",
            modifiers: {
              "click": true,
              "left": true
            }
          }],
          staticClass: "iq-bg-info",
          attrs: {
            "variant": "none"
          }
        }, [_vm._v(" Popover on left ")])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }