<template>
  <iq-card>
    <template v-slot:headerTitle>
      <h5 class="card-title text-primary"><strong>DOCKING SCHEDULE</strong></h5>
    </template>
    <template v-slot:headerAction>
      <b-button variant="primary" @click="showInitDockingFleetModal = true" class="mr-1"><i class="fa fa-edit"></i> &nbsp; CREATE EMERGENCY DOCKING</b-button>
      <a href="#" @click="showSetDockingScheduleModal = true" class="btn btn-primary mr-2"><i class="fa fa-calendar mr-2"></i>SET SCHEDULE DOCKING</a>
<!--      <router-link to="/ops/docking-create"></router-link>-->
      <a class="text-primary float-right ml-3" v-b-toggle.docking_schedule_card role="button" style="cursor: pointer">
        <i class="fa fa-filter" />
      </a>

      <!-- MODAL ALL FLEET -->
      <ModalSelectFleets
        v-model="showInitDockingFleetModal"
        :multiple="false"
        @submit="onOpenModalCreateDocking"
      />
      <!-- MODAL ALL FLEET END -->

      <!-- MODAL CREATE EMERGENCY DOCKING -->
      <ModalDocking
        v-model="showDockingModal"
        :action="dockingAction"
        :vehicle-id="selectedVehicleId"
        :docking-id="selectedDockingId"
        @cancel="onCloseModalCreateDocking"
        @submit="onSubmitCreateDocking"
      />
      <!-- MODAL CREATE EMERGENCY DOCKING END -->

      <!-- SETUP SCHEDULE FROM CALENDAR -->
      <ModalDockingSetSchedule
        v-model="showSetDockingScheduleModal"
        @cancel="onCloseSetDockingScheduleModal()"
        @submit="onSubmitSetDockingSchedule()"
      />
      <!-- SETUP SCHEDULE FROM CALENDAR END -->

      <!-- PENDING DOCKING -->
      <ModalDockingPending
        v-model="showPendingDockingModal"
        @cancel="onClosePendingDocking()"
        @submit="onSubmitPendingDocking()"
        :docking-id="selectedDockingId"
      />
      <!-- PENDING DOCKING END -->
    </template>
    <template v-slot:body>
      <b-collapse visible id="docking_schedule_card" class="mb-2">
        <!-- FILTER DOCKING SCHEDULE-->
        <b-row>
          <b-col md="4">
            <b-form-group label="Fleet Category" label-for="fleet_category">
              <v-select v-model="lists.params.vehicleTypeIds" multiple label="name"
                        :options="vehicleTypes" :reduce="type => type.id">
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Docking Type" label-for="docking_type">
              <v-select v-model="lists.params.dockingType" :options="dockingTypes">
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Status" label-for="status">
              <v-select v-model="lists.params.dockingStatus" :options="dockingStatus">
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Period" label-for="Period">
              <date-picker style="width: 100%" @change="onFilterChangeRangeDate()" v-model="lists.dateRange" format="DD MMM YYYY" type="date" range placeholder="Select date range"></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="1">
            <b-form-group label="Filter" label-for="button_search">
              <b-button variant="primary" @click="getDockingScheduleActiveData()" class="mb-3 mr-1"><i class="fa fa-filter"></i></b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- FILTER DOCKING SCHEDULE END -->

        <!-- DOCKING SCHEDULE -->
        <table class="table table-striped">
          <thead>
          <tr>
            <th class="text-center">NO</th>
            <th class="text-center">FLEET</th>
            <th class="text-center">DOCKING TYPE</th>
            <th class="text-center">REMAINING DAYS</th>
            <th class="text-center">REMAINING DATE</th>
            <th class="text-center">STATUS</th>
            <th class="text-center">ACTION</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="lists.loading">
            <td class="text-center" colspan="7">Loading...</td>
          </tr>
          <template v-else>
            <tr v-for="(data, index) in lists.data"
                :class="{
                  'bg-danger' : ['OVER_SCHEDULE'].includes(data.status),
                  'bg-info' : ['PROGRESS'].includes(data.status),
                  'bg-warning' : ['REVIEW'].includes(data.status),
                  'bg-primary' : ['APPROVE'].includes(data.status),
                }"
                :key="index">
              <th class="text-center">{{index+1}}</th>
              <td class="text-center">{{ data.vehicle.name }}</td>
              <td class="text-center">{{ data.dockingType }}</td>
              <td class="text-center">{{ data.remainingDays ? `${data.remainingDays} days` : '-' }}</td>
              <td class="text-center">{{ data.dockingSchedule }}</td>
              <template v-if="['PROGRESS', 'REVIEW', 'APPROVE'].includes(data.status)">
                <td class="text-center" colspan="2">
                  <strong v-if="data.status === 'PROGRESS'">PROGRESS - CHECK TAB PROGRESS</strong>
                  <strong v-if="data.status === 'REVIEW'">NEED REVIEW - CHECK TAB PROGRESS</strong>
                  <strong v-if="data.status === 'APPROVE'">NEED APPROVE - CHECK TAB PROGRESS</strong>
                </td>
              </template>
              <template v-else>
                <td class="text-center">
                  <b-badge v-if="['ACTIVE'].includes(data.status)" variant="success d-block">ACTIVE</b-badge>
                  <b-badge v-else-if="['DUE_DATE'].includes(data.status)" variant="warning d-block">DUE DATE</b-badge>
                  <b-badge v-else-if="['PENDING'].includes(data.status)" variant="primary d-block">FIRST PENDING</b-badge>
                  <b-badge v-else-if="['OVER_SCHEDULE'].includes(data.status)" variant="light d-block">OVER SCHEDULE</b-badge>
                </td>
                <td class="text-center">
                  <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-ship m-0 text-primary"></i>
                              </span>
                    </template>
                    <b-dropdown-item v-if="['DUE_DATE', 'PENDING', 'OVER_SCHEDULE'].includes(data.status)" @click="onOpenModalDocking(data, 'CREATE_FROM_SCHEDULE')" variant="primary"><i class="fa fa-pencil-square-o mr-2"></i>DOCKING</b-dropdown-item>
                    <b-dropdown-item v-if="['DUE_DATE', 'PENDING', 'OVER_SCHEDULE'].includes(data.status)" @click="onOpenModalPendingDocking(data)" variant="danger"><i class="fa fa-clock mr-2"></i>PENDING</b-dropdown-item>
                    <b-dropdown-item href="#" variant="dark"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</b-dropdown-item>
                  </b-dropdown>
                </td>
              </template>
            </tr>
          </template>
          </tbody>
        </table>
        <div class="mt-3">
          <b-pagination
            v-model="lists.params.page"
            :total-rows="lists.meta.total"
            :per-page="lists.meta.perPage"
            align="center"
            @input="getDockingScheduleActiveData"
          ></b-pagination>
        </div>
        <!-- DOCKING SCHEDULE END -->
      </b-collapse>
    </template>
  </iq-card>
</template>

<script>
import ModalSelectFleets from '@components/Modal/ModalSelectFleets'
import ModalDockingSetSchedule from '@components/Docking/ModalDockingSetSchedule'
import moment from 'moment'
import { dockingActions, fleetsActions } from '@src/Utils/helper'
import ModalDocking from '@components/Docking/ModalDocking'
import ModalDockingPending from '@components/Docking/ModalDockingPending'
export default {
  name: 'DockingDashboardSchedule',
  components: { ModalDockingPending, ModalDocking, ModalDockingSetSchedule, ModalSelectFleets },
  data () {
    return {
      showInitDockingFleetModal: false,
      showDockingModal: false,
      showSetDockingScheduleModal: false,
      showPendingDockingModal: false,
      dockingAction: 'CREATE_EMERGENCY',
      lists: {
        loading: true,
        data: [],
        dateRange: [new Date(moment().subtract(6, 'months').startOf('month')), new Date(moment().endOf('month'))],
        params: {
          page: 1,
          perPage: 25,
          vehicleTypeIds: [],
          dockingType: '',
          periodeStart: '',
          periodeEnd: '',
          dockingStatus: ''
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      vehicleTypes: [],
      dockingTypes: ['SPECIAL', 'INTERMEDIATE', 'EMERGENCY'],
      dockingStatus: ['ACTIVE', 'DUE_DATE', 'PENDING', 'PROGRESS', 'APPROVE', 'REVIEW', 'SEA_TRIAL', 'FINISH', 'REJECT', 'OVER_SCHEDULE'],
      selectedDockingId: null,
      selectedVehicleId: null,
    }
  },
  mounted () {
    this.onFilterChangeRangeDate()
    this.fetchFleetTypes()
    this.getDockingScheduleActiveData()
  },
  methods: {
    ...fleetsActions,
    ...dockingActions,

    onOpenModalCreateDocking (data) {
      this.selectedVehicleId = data
      this.showDockingModal = true
      this.showInitDockingFleetModal = false
    },
    onOpenModalDocking (data, action) {
      this.selectedVehicleId = data.vehicle.id
      this.selectedDockingId = data.id
      this.dockingAction = action
      this.showDockingModal = true
    },
    onOpenModalPendingDocking (data) {
      this.selectedVehicleId = data.vehicle.id
      this.selectedDockingId = data.id
      this.showPendingDockingModal = true
    },
    onCloseModalCreateDocking () {
      this.selectedVehicleId = null
      this.selectedDockingId = null
      this.showDockingModal = false
      this.showInitDockingFleetModal = false
    },
    onClosePendingDocking () {
      this.showPendingDockingModal = false
      this.selectedVehicleId = null
      this.selectedDockingId = null
    },
    onCloseSetDockingScheduleModal () {
      this.showSetDockingScheduleModal = false
    },
    onSubmitSetDockingSchedule () {
      this.getDockingScheduleActiveData()
      this.showSetDockingScheduleModal = false
    },
    onSubmitCreateDocking () {
      location.reload()
      this.getDockingScheduleActiveData()
      this.onCloseModalCreateDocking()
    },
    onSubmitPendingDocking () {
      this.getDockingScheduleActiveData()
      this.onClosePendingDocking()
    },
    onFilterChangeRangeDate () {
      this.lists.params.periodeStart = this.lists.dateRange[0] ? moment(this.lists.dateRange[0]).format('DD-MM-YYYY') : ''
      this.lists.params.periodeEnd = this.lists.dateRange[1] ? moment(this.lists.dateRange[1]).format('DD-MM-YYYY') : ''
    },
    async fetchFleetTypes () {
      let params = { active: true }
      let res = await this.getFleetTypes(params)

      let data = []

      if (res.data) {
        data = res.data
      }

      this.vehicleTypes = data
    },
    async getDockingScheduleActiveData () {
      this.lists.loading = true
      this.lists.data = []

      const params = {
        ...this.lists.params,
        vehicleTypeIds: this.lists.params.vehicleTypeIds.join(',')
      }

      if (params.dockingType === '') {
        delete params.dockingType
      }
      if (params.dockingStatus === '') {
        delete params.dockingStatus
      }
      if (params.vehicleTypeIds === '') {
        delete params.vehicleTypeIds
      }
      if (params.periodeStart === '') {
        delete params.periodeStart
      }
      if (params.periodeEnd === '') {
        delete params.periodeEnd
      }

      const { status, data } = await this.getActiveDockingSchedules(params)

      if (status === 'success') {
        if (data.data) {
          this.lists.data = data.data
          this.lists.meta.perPage = data.perPage
          this.lists.meta.currentPage = data.currentPage
          this.lists.meta.total = data.total
          this.lists.meta.totalPage = data.totalPage
        }
        this.lists.loading = false
      } else {
        this.lists.data = []
        this.lists.loading = false
        this.$swal(
          `Oops!`,
          data,
          'error'
        )
      }
    }
  }
}
</script>

<style scoped>

</style>
