var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "avatar mr-3"
  }, [_c('img', {
    staticClass: "avatar-50 rounded",
    attrs: {
      "src": _vm.item.image,
      "alt": _vm.item.image
    }
  }), _c('span', {
    staticClass: "avatar-status"
  }, [_c('i', {
    staticClass: "ri-checkbox-blank-circle-fill",
    class: "text-".concat(_vm.item.status)
  })])]), _c('div', {
    staticClass: "chat-sidebar-name"
  }, [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.item.name))]), _c('span', [_vm._v(_vm._s(_vm.item.role))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }