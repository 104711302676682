var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('img', {
    staticClass: "rounded-circle",
    attrs: {
      "src": "https://dummyimage.com/88x88/000/fff",
      "alt": "user-image"
    }
  }), _c('h4', {
    staticClass: "mt-3 mb-0"
  }, [_vm._v("Hi ! Michael Smith")]), _c('p', [_vm._v("Enter your password to access the admin.")]), _c('form', {
    staticClass: "mt-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "exampleInputEmail1"
    }
  }, [_vm._v("Password")]), _c('input', {
    staticClass: "form-control mb-0",
    attrs: {
      "type": "Password",
      "id": "exampleInputEmail1",
      "aria-describedby": "emailHelp",
      "placeholder": "Password"
    }
  })]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('button', {
    staticClass: "btn btn-primary float-right",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("Log In")])])])]);

}]

export { render, staticRenderFns }