var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": true,
            "href": "#crew",
            "title": "CREW"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": false,
            "href": "#candidate",
            "title": "CANDIDATE"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": false,
            "href": "#blacklist",
            "title": "BLACK LIST"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "iq-edit-list-data"
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "crew"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("LIST CREW")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('router-link', {
          attrs: {
            "to": "/crewing/add-crew"
          }
        }, [_c('b-button', {
          staticClass: "mr-3",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-users"
        }), _vm._v("   Add Crew")])], 1), _c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-filter",
            modifiers: {
              "collapse-filter": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "visible": "",
            "id": "collapse-filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "search"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Ketikan Nama"
          },
          model: {
            value: _vm.crewList.params.fullname,
            callback: function callback($$v) {
              _vm.$set(_vm.crewList.params, "fullname", $$v);
            },
            expression: "crewList.params.fullname"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet",
            "label-for": "Fleet"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.fleetOptions,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Kapal")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.crewList.params.vehicle_id,
            callback: function callback($$v) {
              _vm.$set(_vm.crewList.params, "vehicle_id", $$v);
            },
            expression: "crewList.params.vehicle_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Jabatan",
            "label-for": "jabatan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.level,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Jabatan")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.crewList.params.position_id,
            callback: function callback($$v) {
              _vm.$set(_vm.crewList.params, "position_id", $$v);
            },
            expression: "crewList.params.position_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Sign Off",
            "label-for": "Sign Off"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          on: {
            "change": _vm.onCrewChangeDate
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.filterCrewList
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1)], 1), _c('TableContentCrewList', {
          attrs: {
            "table-data": _vm.crewList.data,
            "current-page": _vm.crewList.meta.currentPage
          },
          model: {
            value: _vm.form_crew,
            callback: function callback($$v) {
              _vm.form_crew = $$v;
            },
            expression: "form_crew"
          }
        }), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.crewList.meta.total,
            "per-page": _vm.crewList.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getCrewList
          },
          model: {
            value: _vm.crewList.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.crewList.meta, "currentPage", $$v);
            },
            expression: "crewList.meta.currentPage"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "candidate"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("CANDIDATE")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('router-link', {
          attrs: {
            "to": "/crewing/add-candidate"
          }
        }, [_c('b-button', {
          staticClass: "mr-3",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-users"
        }), _vm._v("   Add Candidate")])], 1), _c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-filter",
            modifiers: {
              "collapse-filter": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "visible": "",
            "id": "collapse-filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "search"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Ketikan Nama"
          },
          model: {
            value: _vm.candidateList.params.full_name,
            callback: function callback($$v) {
              _vm.$set(_vm.candidateList.params, "full_name", $$v);
            },
            expression: "candidateList.params.full_name"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Jabatan",
            "label-for": "jabatan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.level,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Jabatan")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.candidateList.params.position_id,
            callback: function callback($$v) {
              _vm.$set(_vm.candidateList.params, "position_id", $$v);
            },
            expression: "candidateList.params.position_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Jadawal Interview",
            "label-for": "jadawal-interview"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "datetime",
            "range": "",
            "placeholder": "Select date range"
          },
          on: {
            "change": _vm.onCandidateChangeDate
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.statusCandidate,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Status")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.candidateList.params.status,
            callback: function callback($$v) {
              _vm.$set(_vm.candidateList.params, "status", $$v);
            },
            expression: "candidateList.params.status"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.filterCandidateList
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1)], 1), _c('TableContentCandidateList', {
          attrs: {
            "table-data": _vm.candidateList.data,
            "current-page": _vm.candidateList.meta.currentPage
          },
          model: {
            value: _vm.form_candidate,
            callback: function callback($$v) {
              _vm.form_candidate = $$v;
            },
            expression: "form_candidate"
          }
        }), _vm.candidateList.data.body.length > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.candidateList.meta.total,
            "per-page": _vm.candidateList.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getCandidateList
          },
          model: {
            value: _vm.candidateList.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.candidateList.meta, "currentPage", $$v);
            },
            expression: "candidateList.meta.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("List Candidate not found.")]), _c('b-modal', {
          attrs: {
            "id": "setup-interview",
            "size": "lg",
            "title": "Set Interview",
            "ok-title": "save",
            "cancel-title": "Close",
            "ok-disabled": _vm.saving
          },
          on: {
            "ok": _vm.onSaveSetInterview
          }
        }, [_c('form', [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Expert User *",
            "label-for": "expert_user"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.optionsExpertUsers,
            "reduce": function reduce(optionsExpertUsers) {
              return optionsExpertUsers.value;
            },
            "placeholder": "Select Type of Expert User",
            "required": ""
          },
          model: {
            value: _vm.form.setInterviewCandidate.expert_user_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form.setInterviewCandidate, "expert_user_id", $$v);
            },
            expression: "\n                              form.setInterviewCandidate.expert_user_id\n                            "
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Interview Date & Time",
            "label-for": "interview_date"
          }
        }, [_c('date-picker', {
          attrs: {
            "required": "",
            "type": "datetime",
            "placeholder": "Select date range",
            "format": "DD-MM-YYYY HH:mm"
          },
          model: {
            value: _vm.form.setInterviewCandidate.date_interview,
            callback: function callback($$v) {
              _vm.$set(_vm.form.setInterviewCandidate, "date_interview", $$v);
            },
            expression: "\n                              form.setInterviewCandidate.date_interview\n                            "
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "for Position *",
            "label-for": "expert_user"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsPosition,
            "size": "lg",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Position")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.form.setInterviewCandidate.position_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form.setInterviewCandidate, "position_id", $$v);
            },
            expression: "form.setInterviewCandidate.position_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Lokasi",
            "label-for": "expert_user"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "required": ""
          },
          model: {
            value: _vm.form.setInterviewCandidate.location,
            callback: function callback($$v) {
              _vm.$set(_vm.form.setInterviewCandidate, "location", $$v);
            },
            expression: "form.setInterviewCandidate.location"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Urgensi",
            "label-for": "interview_date"
          }
        }, [_vm._l(_vm.typeInterview, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            staticClass: "custom-radio-color-checked",
            attrs: {
              "inline": "",
              "color": item.color,
              "name": "color",
              "value": item.value,
              "disabled": item.disabled,
              "required": ""
            },
            model: {
              value: _vm.form.setInterviewCandidate.urgensi,
              callback: function callback($$v) {
                _vm.$set(_vm.form.setInterviewCandidate, "urgensi", $$v);
              },
              expression: "form.setInterviewCandidate.urgensi"
            }
          }, [_c('strong', [_c('h5', [_vm._v(_vm._s(item.label))])])])];
        })], 2)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Notes",
            "label-for": "interview_date",
            "required": ""
          }
        }, [_c('wysiwyg', {
          model: {
            value: _vm.form.setInterviewCandidate.notes_set_interview,
            callback: function callback($$v) {
              _vm.$set(_vm.form.setInterviewCandidate, "notes_set_interview", $$v);
            },
            expression: "\n                              form.setInterviewCandidate.notes_set_interview\n                            "
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('h3', [_vm._v("Eskalasi Notification User")]), _c('hr')]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Expert User *",
            "label-for": "expert_user"
          }
        }, _vm._l(_vm.form.setInterviewCandidate.notification, function (state, index) {
          return _c('div', {
            key: index,
            staticClass: "mb-3 align-content-center w-auto d-flex"
          }, [_c('v-select', {
            staticClass: "w-100 mr-2",
            attrs: {
              "label": "text",
              "options": _vm.optionsExpertUsers,
              "reduce": function reduce(optionsExpertUsers) {
                return optionsExpertUsers.value;
              },
              "placeholder": "Select Type of Expert User"
            },
            model: {
              value: state.expert_user_id,
              callback: function callback($$v) {
                _vm.$set(state, "expert_user_id", $$v);
              },
              expression: "state.expert_user_id"
            }
          }), _vm.form.setInterviewCandidate.notification.length > 1 && index > 0 ? _c('b-button', {
            staticClass: "w-20",
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click() {
                return _vm.deleteNotificationUser(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e()], 1);
        }), 0), _c('b-button', {
          attrs: {
            "block": "",
            "variant": "info",
            "size": "md"
          },
          on: {
            "click": _vm.addNotificationUser
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add More Data ")])], 1)], 1)], 1)]), _c('b-modal', {
          attrs: {
            "id": "verification_document",
            "size": "md",
            "title": "Verification Document",
            "ok-title": "Verification Document",
            "cancel-title": "Close"
          },
          on: {
            "ok": _vm.onSaveVerificationDocument
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', {
          staticClass: "bg-info"
        }, [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_c('strong', [_vm._v("LIST DOCUMENT")])])]), _vm._l(_vm.master_list_document, function (state, index) {
          return _c('tr', {
            key: index
          }, [_c('td', {
            attrs: {
              "width": "90%"
            }
          }, [_c('strong', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.document_name))])]), _c('td', {
            attrs: {
              "width": "10%"
            }
          }, [(state === null || state === void 0 ? void 0 : state.status_checklist) === 1 ? _c('i', {
            staticClass: "fa fa-check text-primary"
          }) : _c('i', {
            staticClass: "fa fa-ban text-primary"
          })])]);
        })], 2)])])], 1), _vm.loading ? _c('div', {
          staticClass: "d-flex justify-content-center w-100"
        }, [_c('span', {
          staticClass: "spinner-border spinner-border-lg"
        })]) : _vm._e()], 1), _c('b-modal', {
          attrs: {
            "id": "induction",
            "size": "md",
            "title": "Induction Checklist",
            "ok-disabled": _vm.saving,
            "ok-title": "Submit",
            "cancel-title": "Close"
          },
          on: {
            "ok": _vm.onSaveInduction
          }
        }, [_vm._l(_vm.department_form, function (state, index) {
          return _c('b-row', {
            key: index
          }, [_c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('b-card', {
            staticClass: "mb-1",
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-header', {
            staticClass: "p-1",
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('b-button', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: "accordion-" + index,
              expression: "`accordion-` + index"
            }],
            class: "".concat((state === null || state === void 0 ? void 0 : state.checked) && "justify-content-between d-flex align-items-center"),
            attrs: {
              "disabled": state !== null && state !== void 0 && state.checked ? true : false,
              "block": "",
              "variant": "".concat(state !== null && state !== void 0 && state.checked ? 'success' : 'info')
            }
          }, [_c('span', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), state !== null && state !== void 0 && state.checked ? _c('i', {
            staticClass: "fas fa-check"
          }) : _vm._e()])], 1), _c('b-collapse', {
            attrs: {
              "id": "accordion-" + index,
              "visible": false,
              "accordion": "my-accordion",
              "role": "tabpanel"
            }
          }, [_c('b-card-body', [_c('b-form-group', {
            attrs: {
              "label": "Induksi Oleh",
              "label-for": "induction_by"
            }
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: state.induction_by,
              expression: "state.induction_by"
            }],
            staticClass: "form-control",
            attrs: {
              "type": "text",
              "id": "induction_by"
            },
            domProps: {
              "value": state.induction_by
            },
            on: {
              "input": function input($event) {
                if ($event.target.composing) return;
                _vm.$set(state, "induction_by", $event.target.value);
              }
            }
          })]), _c('b-form-group', {
            attrs: {
              "label": "Tanggal Induksi",
              "label-for": "induction_date"
            }
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: state.induction_date,
              expression: "state.induction_date"
            }],
            staticClass: "form-control",
            attrs: {
              "type": "date",
              "id": "induction_date"
            },
            domProps: {
              "value": state.induction_date
            },
            on: {
              "input": function input($event) {
                if ($event.target.composing) return;
                _vm.$set(state, "induction_date", $event.target.value);
              }
            }
          })]), _c('b-form-group', {
            attrs: {
              "label": "Attachment",
              "label-for": "attachment"
            }
          }, [_c('div', {
            staticClass: "custom-file"
          }, [_c('input', {
            staticClass: "custom-file-input",
            attrs: {
              "type": "file",
              "id": "validatedCustomFile"
            },
            on: {
              "change": function change(event) {
                return _vm.prefiewFilesInduction(event, state);
              }
            }
          }), !state.attachment ? _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v("Browse file...")]) : _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v(_vm._s(state.attachment_label))])])]), _c('b-form-group', {
            attrs: {
              "label": "Notes",
              "label-for": "notes"
            }
          }, [_c('b-form-textarea', {
            attrs: {
              "id": "notes",
              "name": "notes",
              "rows": "3"
            },
            model: {
              value: state.notes,
              callback: function callback($$v) {
                _vm.$set(state, "notes", $$v);
              },
              expression: "state.notes"
            }
          })], 1)], 1)], 1)], 1)], 1)], 1);
        }), _vm.loading ? _c('div', {
          staticClass: "d-flex justify-content-center w-100"
        }, [_c('span', {
          staticClass: "spinner-border spinner-border-lg"
        })]) : _vm._e()], 2), _c('b-modal', {
          attrs: {
            "id": "medical-checkup",
            "size": "md",
            "title": "Medical Checkup",
            "ok-title": "Submit",
            "cancel-title": "Close",
            "ok-disabled": _vm.saving
          },
          on: {
            "ok": _vm.onSaveMedichalCheckup
          }
        }, [_vm._l(_vm.medical_checkup, function (state, index) {
          return _c('b-row', {
            key: index
          }, [_c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_vm.medical_checkup.length > 1 && index > 0 ? _c('div', {
            staticClass: "d-flex w-100 px-4 my-1 justify-content-end cursor-pointer position-absolute top-0",
            on: {
              "click": function click($event) {
                return _vm.deleteMedicalCheckupForm(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e(), _c('b-form-group', {
            attrs: {
              "label": "Attachment",
              "label-for": "attachment"
            }
          }, [_c('div', {
            staticClass: "custom-file"
          }, [_c('input', {
            staticClass: "custom-file-input",
            attrs: {
              "type": "file",
              "id": "validatedCustomFile"
            },
            on: {
              "change": function change(event) {
                return _vm.prefiewFiles(event, state);
              }
            }
          }), !state.file ? _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v("Browse file...")]) : _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v(_vm._s(state.label_file))])])]), _c('b-form-group', {
            attrs: {
              "label": "Notes",
              "label-for": "notes"
            }
          }, [_c('b-form-textarea', {
            attrs: {
              "id": "notes",
              "name": "notes",
              "rows": "3"
            },
            model: {
              value: state.nama_document,
              callback: function callback($$v) {
                _vm.$set(state, "nama_document", $$v);
              },
              expression: "state.nama_document"
            }
          })], 1)], 1)], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.addMedicalCheckupForm
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM")])], 1)], 1)], 2)];
      },
      proxy: true
    }])
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "blacklist"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("BLACK LIST")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-filter",
            modifiers: {
              "collapse-filter": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "visible": "",
            "id": "collapse-filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "search"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Ketikan Nama"
          },
          model: {
            value: _vm.blackList.params.full_name,
            callback: function callback($$v) {
              _vm.$set(_vm.blackList.params, "full_name", $$v);
            },
            expression: "blackList.params.full_name"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Jabatan",
            "label-for": "jabatan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.level,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Jabatan")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.levelSelected,
            callback: function callback($$v) {
              _vm.levelSelected = $$v;
            },
            expression: "levelSelected"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getBlackList(null);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1)], 1), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("PHOTO")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("NAMA")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("JABATAN")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("VESSEL")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("COMPANY")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("REASON OFF")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("REMARKS")])])]), _c('tbody', _vm._l(_vm.blackList.data, function (data, index) {
          return _c('tr', {
            key: index
          }, [_c('td', {
            staticClass: "text-center"
          }, [_c('b-img', {
            staticClass: "ml-3",
            attrs: {
              "thumbnail": "",
              "src": data.photo || require('../../assets/images/page-img/10.jpg'),
              "alt": "Responsive image"
            }
          })], 1), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.full_name) + " "), _c('br'), _c('small', {
            staticStyle: {
              "color": "gray"
            }
          }, [_vm._v("NIP : "), _c('b', [_vm._v(_vm._s(data.nip))]), _c('br'), _vm._v("Seaferer Code : "), _c('b', [_vm._v(_vm._s(data.seaferer_code))])])]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.name_position))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.vehicle)), _c('br'), _c('small', {
            staticStyle: {
              "color": "gray"
            }
          }, [_vm._v("Port : Banjarmasin")])]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.company))]), _c('td', {
            staticClass: "text-center"
          }, [_c('b-badge', {
            attrs: {
              "variant": "warning"
            }
          }, [_vm._v(_vm._s(data.reason_off))])], 1), _c('td', [_vm._v(_vm._s(data.remarks || 'Tanpa keterangan'))])]);
        }), 0)]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.blackList.meta.total,
            "per-page": _vm.blackList.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getBlackList
          },
          model: {
            value: _vm.blackList.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.blackList.meta, "currentPage", $$v);
            },
            expression: "blackList.meta.currentPage"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-sidebar', {
    ref: "formSignOn",
    attrs: {
      "id": "sign-on",
      "title": "SIGN ON CANDIDATE",
      "width": "70%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$candidateProfile$, _vm$candidateProfile, _vm$candidateProfile$2, _vm$candidateProfile$3, _vm$candidateProfile2, _vm$candidateProfile3, _vm$candidateProfile$4, _vm$candidateProfile4, _vm$candidateProfile5, _vm$candidateProfile6, _vm$candidateProfile$5, _vm$candidateProfile7, _vm$candidateProfile8, _vm$candidateProfile$6, _vm$candidateProfile9, _vm$candidateProfile10, _vm$candidateProfile$7, _vm$candidateProfile11, _vm$candidateProfile12;
        var hide = _ref.hide;
        return [_c('form', {
          attrs: {
            "action": "#",
            "method": "post"
          },
          on: {
            "submit": _vm.onSignOnSave
          }
        }, [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-download"
        }), _vm._v(" DOWNLOAD DOCUMENT CONTRACT")])], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_vm.currentStep === 0 ? _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2",
          staticStyle: {
            "font-size": "14pt"
          }
        }, [_vm._v("Informasi Crew")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form', [_c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "nama_crew",
            "for": "nama_crew"
          }
        }, [_c('strong', [_vm._v("Nama Crew")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_vm._v(" " + _vm._s((_vm$candidateProfile$ = (_vm$candidateProfile = _vm.candidateProfile) === null || _vm$candidateProfile === void 0 ? void 0 : (_vm$candidateProfile$2 = _vm$candidateProfile.personal_data) === null || _vm$candidateProfile$2 === void 0 ? void 0 : _vm$candidateProfile$2.full_name) !== null && _vm$candidateProfile$ !== void 0 ? _vm$candidateProfile$ : '-') + " ")])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "nik",
            "for": "nik"
          }
        }, [_c('strong', [_vm._v("NIK")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_vm._v(" " + _vm._s((_vm$candidateProfile$3 = (_vm$candidateProfile2 = _vm.candidateProfile) === null || _vm$candidateProfile2 === void 0 ? void 0 : (_vm$candidateProfile3 = _vm$candidateProfile2.personal_data) === null || _vm$candidateProfile3 === void 0 ? void 0 : _vm$candidateProfile3.nik_ktp) !== null && _vm$candidateProfile$3 !== void 0 ? _vm$candidateProfile$3 : '-') + " ")])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "jabatan",
            "for": "jabatan"
          }
        }, [_c('strong', [_vm._v("Jabatan")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_vm._v(" " + _vm._s((_vm$candidateProfile$4 = (_vm$candidateProfile4 = _vm.candidateProfile) === null || _vm$candidateProfile4 === void 0 ? void 0 : (_vm$candidateProfile5 = _vm$candidateProfile4.personal_data) === null || _vm$candidateProfile5 === void 0 ? void 0 : (_vm$candidateProfile6 = _vm$candidateProfile5.position) === null || _vm$candidateProfile6 === void 0 ? void 0 : _vm$candidateProfile6.name) !== null && _vm$candidateProfile$4 !== void 0 ? _vm$candidateProfile$4 : '-') + " ")])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "seaman_book",
            "for": "seaman_book"
          }
        }, [_c('strong', [_vm._v("Seaman Book")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_vm._v(" " + _vm._s((_vm$candidateProfile$5 = (_vm$candidateProfile7 = _vm.candidateProfile) === null || _vm$candidateProfile7 === void 0 ? void 0 : (_vm$candidateProfile8 = _vm$candidateProfile7.personal_data) === null || _vm$candidateProfile8 === void 0 ? void 0 : _vm$candidateProfile8.seaman_book_number) !== null && _vm$candidateProfile$5 !== void 0 ? _vm$candidateProfile$5 : '-') + " ")])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "seafarer_code",
            "for": "seafarer_code"
          }
        }, [_c('strong', [_vm._v("Seaferer Code")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_vm._v(" " + _vm._s((_vm$candidateProfile$6 = (_vm$candidateProfile9 = _vm.candidateProfile) === null || _vm$candidateProfile9 === void 0 ? void 0 : (_vm$candidateProfile10 = _vm$candidateProfile9.personal_data) === null || _vm$candidateProfile10 === void 0 ? void 0 : _vm$candidateProfile10.seaferer_code) !== null && _vm$candidateProfile$6 !== void 0 ? _vm$candidateProfile$6 : '-') + " ")])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "passport_number",
            "for": "passport_number"
          }
        }, [_c('strong', [_vm._v("Passport Number")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_vm._v(" " + _vm._s((_vm$candidateProfile$7 = (_vm$candidateProfile11 = _vm.candidateProfile) === null || _vm$candidateProfile11 === void 0 ? void 0 : (_vm$candidateProfile12 = _vm$candidateProfile11.personal_data) === null || _vm$candidateProfile12 === void 0 ? void 0 : _vm$candidateProfile12.passport_number) !== null && _vm$candidateProfile$7 !== void 0 ? _vm$candidateProfile$7 : '-') + " ")])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "file_contract",
            "for": "file_contract"
          }
        }, [_c('strong', [_vm._v("Attachment File")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-file', {
          attrs: {
            "plain": "",
            "id": "file-contract",
            "state": Boolean(_vm.form_signon.attachment)
          },
          model: {
            value: _vm.form_signon.attachment,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "attachment", $$v);
            },
            expression: "form_signon.attachment"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "file_contract",
            "for": "file_contract"
          }
        }, [_c('strong', [_vm._v("Notes")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form_signon.remarks,
            expression: "form_signon.remarks"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "join_onboard",
            "placeholder": "notes...",
            "required": ""
          },
          domProps: {
            "value": _vm.form_signon.remarks
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form_signon, "remarks", $event.target.value);
            }
          }
        })])])])], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "perusahaan",
            "for": "perusahaan"
          }
        }, [_c('strong', [_vm._v("Perusahaan")])]), _c('b-form-group', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsCompanies,
            "size": "lg",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Perusahaan")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.form_signon.company_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "company_id", $$v);
            },
            expression: "form_signon.company_id"
          }
        })], 1)], 1), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "perusahaan",
            "for": "perusahaan"
          }
        }, [_c('strong', [_vm._v("Department")])]), _c('b-form-group', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsDepartment,
            "size": "lg",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Department")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.form_signon.department_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "department_id", $$v);
            },
            expression: "form_signon.department_id"
          }
        })], 1)], 1), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "Jabatan",
            "for": "jabatan"
          }
        }, [_c('strong', [_vm._v("Jabatan")])]), _c('b-form-group', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsPosition,
            "size": "lg",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Jabatan")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.form_signon.position_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "position_id", $$v);
            },
            expression: "form_signon.position_id"
          }
        })], 1)], 1), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "nama_kapal",
            "for": "nama_kapal"
          }
        }, [_c('strong', [_vm._v("Nama Kapal")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsVehicles,
            "size": "lg",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Kapal")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.form_signon.vehicle_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "vehicle_id", $$v);
            },
            expression: "form_signon.vehicle_id"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "nama_kapal",
            "for": "nama_kapal"
          }
        }, [_c('strong', [_vm._v("Work Area")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsWorkArea,
            "size": "lg",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Work Area")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.form_signon.work_area_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "work_area_id", $$v);
            },
            expression: "form_signon.work_area_id"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "pelabuhan",
            "for": "pelabuhan"
          }
        }, [_c('strong', [_vm._v("Pelabuhan")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsPorts,
            "size": "lg",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Pelabuhan")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.form_signon.port_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "port_id", $$v);
            },
            expression: "form_signon.port_id"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "sign_on",
            "for": "sign_on"
          }
        }, [_c('strong', [_vm._v("Sign On")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "sign_on",
            "required": ""
          },
          model: {
            value: _vm.form_signon.sign_on,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "sign_on", $$v);
            },
            expression: "form_signon.sign_on"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "join_onboard",
            "for": "join_onboard"
          }
        }, [_c('strong', [_vm._v("Join Onboard")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "join_onboard",
            "required": ""
          },
          model: {
            value: _vm.form_signon.join_on_board,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "join_on_board", $$v);
            },
            expression: "form_signon.join_on_board"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "naik_kapal",
            "for": "naik_kapal"
          }
        }, [_c('strong', [_vm._v("Sign Off / Turun Kapal")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "naik_kapal",
            "required": ""
          },
          model: {
            value: _vm.form_signon.sign_off,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "sign_off", $$v);
            },
            expression: "form_signon.sign_off"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)])])], 1)], 1) : _vm._e(), _vm.currentStep === 1 ? _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2",
          staticStyle: {
            "font-size": "14pt"
          }
        }, [_vm._v("Payroll Component")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_c('strong', [_vm._v("Basic Salary")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("Rp.")])]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form_signon.basic_sallary,
            expression: "form_signon.basic_sallary"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "gaji_pokok",
            "required": ""
          },
          domProps: {
            "value": _vm.form_signon.basic_sallary
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form_signon, "basic_sallary", $event.target.value);
            }
          }
        })])])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_c('strong', [_vm._v("Overtime")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("Rp.")])]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form_signon.overtime,
            expression: "form_signon.overtime"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "gaji_pokok",
            "required": ""
          },
          domProps: {
            "value": _vm.form_signon.overtime
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form_signon, "overtime", $event.target.value);
            }
          }
        })])])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_c('strong', [_vm._v("Daily Fee")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("Rp.")])]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form_signon.daily_fee,
            expression: "form_signon.daily_fee"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "gaji_pokok",
            "required": ""
          },
          domProps: {
            "value": _vm.form_signon.daily_fee
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form_signon, "daily_fee", $event.target.value);
            }
          }
        })])])]), _vm._l(_vm.form_signon.allowance_fix, function (state, index) {
          return _c('div', {
            key: index,
            staticClass: "form-group row"
          }, [_c('label', {
            staticClass: "col-sm-6",
            attrs: {
              "label": "lembur",
              "for": "lembur"
            }
          }, [_c('strong', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.text))])]), _c('div', {
            staticClass: "col-sm-6"
          }, [_c('div', {
            staticClass: "input-group"
          }, [_c('div', {
            staticClass: "input-group-prepend"
          }, [_c('span', {
            staticClass: "input-group-text bg-primary",
            attrs: {
              "id": "rp"
            }
          }, [_vm._v("Rp.")])]), _c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: state.amount,
              expression: "state.amount"
            }],
            staticClass: "form-control",
            attrs: {
              "type": "number",
              "id": "uang_lembur",
              "required": ""
            },
            domProps: {
              "value": state.amount
            },
            on: {
              "input": function input($event) {
                if ($event.target.composing) return;
                _vm.$set(state, "amount", $event.target.value);
              }
            }
          })])])]);
        })], 2)], 1)], 1) : _vm._e(), _vm.currentStep === 2 ? _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2",
          staticStyle: {
            "font-size": "14pt"
          }
        }, [_vm._v("BPJS Setting")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center w-100"
        }, [_c('legend', {
          staticStyle: {
            "font-size": "14pt"
          }
        }, [_vm._v("BPJS Ketenagakerjaan")]), _c('b-form-checkbox', {
          attrs: {
            "id": "bpjstk_config",
            "value": 1,
            "unchecked-value": 0
          },
          model: {
            value: _vm.form_signon.bpjstk,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "bpjstk", $$v);
            },
            expression: "form_signon.bpjstk"
          }
        })], 1), _vm.form_signon.bpjstk === 1 ? _c('div', [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "col-sm-12 px-2",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_c('strong', [_vm._v("Basis Penggali")])]), _c('div', {
          staticClass: "px-2 row"
        }, [_c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsSetting,
            "size": "lg"
          },
          model: {
            value: _vm.form_signon.bpjstk_config,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "bpjstk_config", $$v);
            },
            expression: "form_signon.bpjstk_config"
          }
        })], 1), _vm.form_signon.bpjstk_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsGaji,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Gaji")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.form_signon.basispengali_bpjstk,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "basispengali_bpjstk", $$v);
            },
            expression: "form_signon.basispengali_bpjstk"
          }
        })], 1) : _vm._e()])]), _c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "col-sm-12 px-2",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_c('strong', [_vm._v("JHT Configuration")])]), _c('div', {
          staticClass: "px-2 row"
        }, [_c('div', {
          staticClass: "col-sm-12 mb-2"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsSetting,
            "size": "lg",
            "required": ""
          },
          model: {
            value: _vm.form_signon.jht_config,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "jht_config", $$v);
            },
            expression: "form_signon.jht_config"
          }
        })], 1), _vm.form_signon.jht_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("JHT (DITANGGUNG PERUSAHAAN)")]), _c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form_signon.jht_company_set_value,
            expression: "\n                                        form_signon.jht_company_set_value\n                                      "
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur"
          },
          domProps: {
            "value": _vm.form_signon.jht_company_set_value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form_signon, "jht_company_set_value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("%")])])])]) : _vm._e(), _vm.form_signon.jht_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("JHT (DITANGGUNG KARYAWAN)")]), _c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form_signon.jht_employee_set_value,
            expression: "\n                                        form_signon.jht_employee_set_value\n                                      "
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur"
          },
          domProps: {
            "value": _vm.form_signon.jht_employee_set_value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form_signon, "jht_employee_set_value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("%")])])])]) : _vm._e()])]), _c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "col-sm-12 px-2",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_c('strong', [_vm._v("JP Configuration")])]), _c('div', {
          staticClass: "px-2 row"
        }, [_c('div', {
          staticClass: "col-sm-12 mb-2"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsSetting,
            "size": "lg",
            "required": ""
          },
          model: {
            value: _vm.form_signon.jp_config,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "jp_config", $$v);
            },
            expression: "form_signon.jp_config"
          }
        })], 1), _vm.form_signon.jp_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("JP (DITANGGUNG PERUSAHAAN)")]), _c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form_signon.jp_company_set_value,
            expression: "\n                                        form_signon.jp_company_set_value\n                                      "
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur"
          },
          domProps: {
            "value": _vm.form_signon.jp_company_set_value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form_signon, "jp_company_set_value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("%")])])])]) : _vm._e(), _vm.form_signon.jp_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("JP (DITANGGUNG KARYAWAN)")]), _c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form_signon.jp_employee_set_value,
            expression: "\n                                        form_signon.jp_employee_set_value\n                                      "
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur"
          },
          domProps: {
            "value": _vm.form_signon.jp_employee_set_value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form_signon, "jp_employee_set_value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("%")])])])]) : _vm._e()]), _c('div', {
          staticClass: "col-sm-12 px-2 mb-2"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_c('strong', [_vm._v("NILAI MAKSIMAL PENGGALI JAMINAN PENSIUN (JP)")])]), _c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("Rp.")])]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form_signon.jp_max_gaji,
            expression: "form_signon.jp_max_gaji"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur"
          },
          domProps: {
            "value": _vm.form_signon.jp_max_gaji
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form_signon, "jp_max_gaji", $event.target.value);
            }
          }
        })])]), _c('div', {
          staticClass: "col-sm-12 px-2 mb-2"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_c('strong', [_vm._v("APAKAH UNTUK KARYAWAN BERKEWARGANEGARAAN ASING DIHITUNG JP ATAU TIDAK?")])]), _c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Option")]), _c('b-form-select-option', {
                attrs: {
                  "value": "YES"
                }
              }, [_vm._v("Ya")]), _c('b-form-select-option', {
                attrs: {
                  "value": "NO"
                }
              }, [_vm._v("Tidak")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.form_signon.jp_wna,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "jp_wna", $$v);
            },
            expression: "form_signon.jp_wna"
          }
        })], 1), _c('div', {
          staticClass: "col-sm-12 px-2 mb-2"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_c('strong', [_vm._v("MAX USIA YANG DICOVER BPJS KETENAGAKERJAAN")])]), _c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("Usia")])]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form_signon.jp_max_usia,
            expression: "form_signon.jp_max_usia"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur"
          },
          domProps: {
            "value": _vm.form_signon.jp_max_usia
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form_signon, "jp_max_usia", $event.target.value);
            }
          }
        })])]), _c('div', {
          staticClass: "col-sm-12 px-2 mb-2"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_c('strong', [_vm._v("APAKAH UNTUK KARYAWAN DENGAN USIA > 56 TAHUN DIHITUNG JP ATAU TIDAK?")])]), _c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Option ")]), _c('b-form-select-option', {
                attrs: {
                  "value": "YES"
                }
              }, [_vm._v("Ya")]), _c('b-form-select-option', {
                attrs: {
                  "value": "NO"
                }
              }, [_vm._v("Tidak")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.form_signon.jp_lebihusia_max,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "jp_lebihusia_max", $$v);
            },
            expression: "form_signon.jp_lebihusia_max"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "col-sm-12 px-2",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_c('strong', [_vm._v("JKM Configuration")])]), _c('div', {
          staticClass: "px-2 row"
        }, [_c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsSetting,
            "size": "lg"
          },
          model: {
            value: _vm.form_signon.jkm_config,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "jkm_config", $$v);
            },
            expression: "form_signon.jkm_config"
          }
        })], 1), _vm.form_signon.jkm_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form_signon.jkm_set_value,
            expression: "form_signon.jkm_set_value"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur"
          },
          domProps: {
            "value": _vm.form_signon.jkm_set_value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form_signon, "jkm_set_value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("%")])])])]) : _vm._e()])]), _c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "col-sm-12 px-2",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_c('strong', [_vm._v("JKK Configuration")])]), _c('div', {
          staticClass: "px-2 row"
        }, [_c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsSetting,
            "size": "lg"
          },
          model: {
            value: _vm.form_signon.jkk_config,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "jkk_config", $$v);
            },
            expression: "form_signon.jkk_config"
          }
        })], 1), _vm.form_signon.jkk_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form_signon.jkk_set_value,
            expression: "form_signon.jkk_set_value"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur"
          },
          domProps: {
            "value": _vm.form_signon.jkk_set_value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form_signon, "jkk_set_value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("%")])])])]) : _vm._e()])])]) : _vm._e()]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center w-100"
        }, [_c('legend', {
          staticStyle: {
            "font-size": "14pt"
          }
        }, [_vm._v("BPJS Kesehatan")]), _c('b-form-checkbox', {
          attrs: {
            "id": "bpjsksh_config",
            "value": 1,
            "unchecked-value": 0
          },
          model: {
            value: _vm.form_signon.bpjsksh,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "bpjsksh", $$v);
            },
            expression: "form_signon.bpjsksh"
          }
        })], 1), _vm.form_signon.bpjsksh === 1 ? _c('div', [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "col-sm-12 px-2",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_c('strong', [_vm._v("Basis Penggali")])]), _c('div', {
          staticClass: "px-2 row"
        }, [_c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsSetting,
            "size": "lg"
          },
          model: {
            value: _vm.form_signon.basispengali_bpjsksh_config,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "basispengali_bpjsksh_config", $$v);
            },
            expression: "\n                                      form_signon.basispengali_bpjsksh_config\n                                    "
          }
        })], 1), _vm.form_signon.basispengali_bpjsksh_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsGaji,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Gaji")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.form_signon.basispengali_bpjsksh,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "basispengali_bpjsksh", $$v);
            },
            expression: "form_signon.basispengali_bpjsksh"
          }
        })], 1) : _vm._e()])]), _c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "col-sm-12 px-2",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_c('strong', [_vm._v("BPJS Configuration")])]), _c('div', {
          staticClass: "px-2 row"
        }, [_c('div', {
          staticClass: "col-sm-12 mb-2"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.optionsSetting,
            "size": "lg",
            "required": ""
          },
          model: {
            value: _vm.form_signon.bpjsksh_config,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signon, "bpjsksh_config", $$v);
            },
            expression: "form_signon.bpjsksh_config"
          }
        })], 1), _vm.form_signon.bpjsksh_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("BPJS KSH (DITANGGUNG PERUSAHAAN)")]), _c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form_signon.bpjsksh_company_set_value,
            expression: "\n                                        form_signon.bpjsksh_company_set_value\n                                      "
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur"
          },
          domProps: {
            "value": _vm.form_signon.bpjsksh_company_set_value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form_signon, "bpjsksh_company_set_value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("%")])])])]) : _vm._e(), _vm.form_signon.bpjsksh_config === 1 ? _c('div', {
          staticClass: "col-sm-6"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_vm._v("BPJS KSH (DITANGGUNG KARYAWAN)")]), _c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form_signon.bpjsksh_employee_set_value,
            expression: "\n                                        form_signon.bpjsksh_employee_set_value\n                                      "
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur"
          },
          domProps: {
            "value": _vm.form_signon.bpjsksh_employee_set_value
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form_signon, "bpjsksh_employee_set_value", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("%")])])])]) : _vm._e()])]), _c('div', {
          staticClass: "form-group"
        }, [_c('div', {
          staticClass: "col-sm-12 px-2 mb-2"
        }, [_c('label', {
          staticClass: "col-xs-12",
          attrs: {
            "label": "gaji_pokok",
            "for": "gaji_pokok"
          }
        }, [_c('strong', [_vm._v("MAX GAJI BPJS Kesehatan")])]), _c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "rp"
          }
        }, [_vm._v("Rp.")])]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form_signon.max_gaji_bpjsksh,
            expression: "form_signon.max_gaji_bpjsksh"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur"
          },
          domProps: {
            "value": _vm.form_signon.max_gaji_bpjsksh
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form_signon, "max_gaji_bpjsksh", $event.target.value);
            }
          }
        })])])])]) : _vm._e()])], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "".concat(_vm.currentStep === 0 ? 'secondary' : 'primary'),
            "disabled": _vm.currentStep === 0,
            "block": "",
            "type": "button"
          },
          on: {
            "click": _vm.prevStep
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-left"
        }), _vm._v(" PREVIOUS FORM ")])], 1), _vm.currentStep !== 2 ? _c('b-col', {
          attrs: {
            "md": "8"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "block": "",
            "type": "submit"
          }
        }, [_vm._v(" NEXT FORM "), _c('i', {
          staticClass: "fa fa-chevron-right"
        })])], 1) : _vm._e(), _vm.currentStep === 2 ? _c('b-col', {
          attrs: {
            "md": "8"
          }
        }, [_c('b-button', {
          attrs: {
            "disabled": _vm.saving,
            "variant": "primary",
            "block": "",
            "type": "submit"
          }
        }, [_vm.saving ? _c('span', {
          staticClass: "spinner-border spinner-border-sm"
        }) : _vm._e(), !_vm.saving ? _c('i', {
          staticClass: "fa fa-file-signature"
        }) : _vm._e(), !_vm.saving ? _c('span', [_vm._v("SUBMIT")]) : _vm._e()])], 1) : _vm._e()], 1)], 1)], 1)], 1)];
      }
    }])
  }), _c('b-sidebar', {
    attrs: {
      "id": "sign-off",
      "title": "SIGN OFF CREW",
      "width": "50%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var _vm$crewProfile$fulln, _vm$crewProfile, _vm$crewProfile$no_kt, _vm$crewProfile2, _vm$crewProfile$posit, _vm$crewProfile3, _vm$crewProfile3$posi, _vm$crewProfile$perso, _vm$crewProfile4, _vm$crewProfile4$pers, _vm$crewProfile$perso2, _vm$crewProfile5, _vm$crewProfile5$pers, _vm$crewProfile$perso3, _vm$crewProfile6, _vm$crewProfile6$pers, _vm$crewProfile$vehic, _vm$crewProfile7, _vm$crewProfile7$vehi, _vm$crewProfile$port$, _vm$crewProfile8, _vm$crewProfile8$port, _vm$crewProfile$sign_, _vm$crewProfile9;
        var hide = _ref2.hide;
        return [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2",
          staticStyle: {
            "font-size": "14pt"
          }
        }, [_vm._v("Informasi Crew")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form', [_c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "nama_crew",
            "for": "nama_crew"
          }
        }, [_c('strong', [_vm._v("Nama Crew")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_vm._v(" " + _vm._s((_vm$crewProfile$fulln = (_vm$crewProfile = _vm.crewProfile) === null || _vm$crewProfile === void 0 ? void 0 : _vm$crewProfile.fullname) !== null && _vm$crewProfile$fulln !== void 0 ? _vm$crewProfile$fulln : '-') + " ")])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "nik",
            "for": "nik"
          }
        }, [_c('strong', [_vm._v("NIK")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_vm._v(" " + _vm._s((_vm$crewProfile$no_kt = (_vm$crewProfile2 = _vm.crewProfile) === null || _vm$crewProfile2 === void 0 ? void 0 : _vm$crewProfile2.no_ktp) !== null && _vm$crewProfile$no_kt !== void 0 ? _vm$crewProfile$no_kt : '-') + " ")])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "jabatan",
            "for": "jabatan"
          }
        }, [_c('strong', [_vm._v("Jabatan")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_vm._v(" " + _vm._s((_vm$crewProfile$posit = (_vm$crewProfile3 = _vm.crewProfile) === null || _vm$crewProfile3 === void 0 ? void 0 : (_vm$crewProfile3$posi = _vm$crewProfile3.position) === null || _vm$crewProfile3$posi === void 0 ? void 0 : _vm$crewProfile3$posi.name) !== null && _vm$crewProfile$posit !== void 0 ? _vm$crewProfile$posit : '-') + " ")])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "seaman_book",
            "for": "seaman_book"
          }
        }, [_c('strong', [_vm._v("Seaman Book")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_vm._v(" " + _vm._s((_vm$crewProfile$perso = (_vm$crewProfile4 = _vm.crewProfile) === null || _vm$crewProfile4 === void 0 ? void 0 : (_vm$crewProfile4$pers = _vm$crewProfile4.personal_data) === null || _vm$crewProfile4$pers === void 0 ? void 0 : _vm$crewProfile4$pers.seaman_book_number) !== null && _vm$crewProfile$perso !== void 0 ? _vm$crewProfile$perso : '-') + " ")])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "seafarer_code",
            "for": "seafarer_code"
          }
        }, [_c('strong', [_vm._v("Seafarer Code")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_vm._v(" " + _vm._s((_vm$crewProfile$perso2 = (_vm$crewProfile5 = _vm.crewProfile) === null || _vm$crewProfile5 === void 0 ? void 0 : (_vm$crewProfile5$pers = _vm$crewProfile5.personal_data) === null || _vm$crewProfile5$pers === void 0 ? void 0 : _vm$crewProfile5$pers.seaferer_code) !== null && _vm$crewProfile$perso2 !== void 0 ? _vm$crewProfile$perso2 : '-') + " ")])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "passport_number",
            "for": "passport_number"
          }
        }, [_c('strong', [_vm._v("Passport Number")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_vm._v(" " + _vm._s((_vm$crewProfile$perso3 = (_vm$crewProfile6 = _vm.crewProfile) === null || _vm$crewProfile6 === void 0 ? void 0 : (_vm$crewProfile6$pers = _vm$crewProfile6.personal_data) === null || _vm$crewProfile6$pers === void 0 ? void 0 : _vm$crewProfile6$pers.passport_number) !== null && _vm$crewProfile$perso3 !== void 0 ? _vm$crewProfile$perso3 : '-') + " ")])])])], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form', [_c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "perusahaan",
            "for": "perusahaan"
          }
        }, [_c('strong', [_vm._v("Perusahaan")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_vm._v(" N/A ")])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "nama_kapal",
            "for": "nama_kapal"
          }
        }, [_c('strong', [_vm._v("Nama Kapal")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_vm._v(" " + _vm._s((_vm$crewProfile$vehic = (_vm$crewProfile7 = _vm.crewProfile) === null || _vm$crewProfile7 === void 0 ? void 0 : (_vm$crewProfile7$vehi = _vm$crewProfile7.vehicle) === null || _vm$crewProfile7$vehi === void 0 ? void 0 : _vm$crewProfile7$vehi.name) !== null && _vm$crewProfile$vehic !== void 0 ? _vm$crewProfile$vehic : '-') + " ")])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "pelabuhan",
            "for": "pelabuhan"
          }
        }, [_c('strong', [_vm._v("Pelabuhan")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_vm._v(" " + _vm._s((_vm$crewProfile$port$ = (_vm$crewProfile8 = _vm.crewProfile) === null || _vm$crewProfile8 === void 0 ? void 0 : (_vm$crewProfile8$port = _vm$crewProfile8.port) === null || _vm$crewProfile8$port === void 0 ? void 0 : _vm$crewProfile8$port.port_name) !== null && _vm$crewProfile$port$ !== void 0 ? _vm$crewProfile$port$ : '-') + " ")])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "naik_kapal",
            "for": "naik_kapal"
          }
        }, [_c('strong', [_vm._v("Sign On / Naik Kapal")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_vm._v(" " + _vm._s((_vm$crewProfile$sign_ = (_vm$crewProfile9 = _vm.crewProfile) === null || _vm$crewProfile9 === void 0 ? void 0 : _vm$crewProfile9.sign_on) !== null && _vm$crewProfile$sign_ !== void 0 ? _vm$crewProfile$sign_ : '-') + " ")])]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "turun_kapal",
            "for": "turun_kapal"
          }
        }, [_c('strong', [_vm._v("Sign Off / Turun Kapal")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "signon",
            "required": ""
          },
          model: {
            value: _vm.form_signoff_crew.sign_off,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signoff_crew, "sign_off", $$v);
            },
            expression: "form_signoff_crew.sign_off"
          }
        })], 1)])])], 1)], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2",
          staticStyle: {
            "font-size": "14pt"
          }
        }, [_vm._v("Reason Sign Off")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', {
          staticClass: "text-center"
        }, [_c('th', {
          attrs: {
            "width": "90%"
          }
        }, [_vm._v("DESKRIPSI")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("STATUS")])])]), _c('tbody', _vm._l(_vm.master_signoff_reasons, function (state, index) {
          return _c('tr', {
            key: index
          }, [_c('th', [_vm._v(" " + _vm._s(state.name))]), _c('td', {
            staticClass: "text-center"
          }, [[_c('b-form-checkbox', {
            staticClass: "ml-4",
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": "",
              "disabled": state.disabled
            },
            on: {
              "change": function change($event) {
                return _vm.selectStatusSignOffCrew(state, index);
              }
            },
            model: {
              value: state.checked,
              callback: function callback($$v) {
                _vm.$set(state, "checked", $$v);
              },
              expression: "state.checked"
            }
          })]], 2)]);
        }), 0)]), _c('b-form-textarea', {
          attrs: {
            "placeholder": "Notes...",
            "id": "remarks"
          },
          model: {
            value: _vm.form_signoff_crew.remarks,
            callback: function callback($$v) {
              _vm.$set(_vm.form_signoff_crew, "remarks", $$v);
            },
            expression: "form_signoff_crew.remarks"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12"
          }
        }, [_c('span', [_c('b-button', {
          attrs: {
            "variant": "primary",
            "block": ""
          },
          on: {
            "click": _vm.onSaveSignOffCrew
          }
        }, [_c('i', {
          staticClass: "fa fa-file-signature"
        }), _vm._v(" SUBMIT ")])], 1)])], 1)], 1)];
      }
    }])
  }), _c('b-sidebar', {
    attrs: {
      "id": "blacklist-crew",
      "title": "BLACK LIST",
      "width": "50%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var _vm$crewProfile$fulln2, _vm$crewProfile10, _vm$crewProfile$fulln3, _vm$crewProfile11, _vm$crewProfile$posit2, _vm$crewProfile12, _vm$crewProfile12$pos, _vm$crewProfile$port$2, _vm$crewProfile13, _vm$crewProfile13$por, _vm$crewProfile$perso4, _vm$crewProfile14, _vm$crewProfile14$per, _vm$crewProfile$vehic2, _vm$crewProfile15, _vm$crewProfile15$veh, _vm$crewProfile$nip, _vm$crewProfile16, _vm$crewProfile$sign_2, _vm$crewProfile17, _vm$crewProfile$perso5, _vm$crewProfile18, _vm$crewProfile18$per, _vm$crewProfile$sign_3, _vm$crewProfile19, _vm$crewProfile$perso6, _vm$crewProfile20, _vm$crewProfile20$per, _vm$crewProfile$perso7, _vm$crewProfile21, _vm$crewProfile21$per, _vm$crewProfile$perso8, _vm$crewProfile22, _vm$crewProfile22$per, _vm$crewProfile$perso9, _vm$crewProfile23, _vm$crewProfile23$per, _vm$crewProfile$perso10, _vm$crewProfile24, _vm$crewProfile24$per, _vm$crewProfile$perso11, _vm$crewProfile25, _vm$crewProfile25$per, _vm$crewProfile$perso12, _vm$crewProfile26, _vm$crewProfile26$per, _vm$candidateProfile$8, _vm$candidateProfile13, _vm$candidateProfile14, _vm$candidateProfile$9, _vm$candidateProfile15, _vm$candidateProfile16, _vm$candidateProfile17, _vm$candidateProfile18, _vm$candidateProfile19, _vm$candidateProfile$10, _vm$candidateProfile20, _vm$candidateProfile21, _vm$candidateProfile$11, _vm$candidateProfile22, _vm$candidateProfile23, _vm$candidateProfile$12, _vm$candidateProfile24, _vm$candidateProfile$13, _vm$candidateProfile25, _vm$candidateProfile26, _vm$candidateProfile$14, _vm$candidateProfile27, _vm$candidateProfile28, _vm$candidateProfile$15, _vm$candidateProfile29, _vm$candidateProfile30, _vm$candidateProfile$16, _vm$candidateProfile31, _vm$candidateProfile32, _vm$candidateProfile$17, _vm$candidateProfile33, _vm$candidateProfile34, _vm$candidateProfile35;
        var hide = _ref3.hide;
        return [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_vm.form_crew.key === 'crew' ? _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Informasi Crew")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Nama Crew")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$fulln2 = (_vm$crewProfile10 = _vm.crewProfile) === null || _vm$crewProfile10 === void 0 ? void 0 : _vm$crewProfile10.fullname) !== null && _vm$crewProfile$fulln2 !== void 0 ? _vm$crewProfile$fulln2 : '-'))]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Perusahaan")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$fulln3 = (_vm$crewProfile11 = _vm.crewProfile) === null || _vm$crewProfile11 === void 0 ? void 0 : _vm$crewProfile11.fullname) !== null && _vm$crewProfile$fulln3 !== void 0 ? _vm$crewProfile$fulln3 : '-'))])]), _c('tr', [_c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Jabatan")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$posit2 = (_vm$crewProfile12 = _vm.crewProfile) === null || _vm$crewProfile12 === void 0 ? void 0 : (_vm$crewProfile12$pos = _vm$crewProfile12.position) === null || _vm$crewProfile12$pos === void 0 ? void 0 : _vm$crewProfile12$pos.name) !== null && _vm$crewProfile$posit2 !== void 0 ? _vm$crewProfile$posit2 : '-'))]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Pelabuhan")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$port$2 = (_vm$crewProfile13 = _vm.crewProfile) === null || _vm$crewProfile13 === void 0 ? void 0 : (_vm$crewProfile13$por = _vm$crewProfile13.port) === null || _vm$crewProfile13$por === void 0 ? void 0 : _vm$crewProfile13$por.port_name) !== null && _vm$crewProfile$port$2 !== void 0 ? _vm$crewProfile$port$2 : '-'))])]), _c('tr', [_c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("NIK")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$perso4 = (_vm$crewProfile14 = _vm.crewProfile) === null || _vm$crewProfile14 === void 0 ? void 0 : (_vm$crewProfile14$per = _vm$crewProfile14.personal_data) === null || _vm$crewProfile14$per === void 0 ? void 0 : _vm$crewProfile14$per.nik_ktp) !== null && _vm$crewProfile$perso4 !== void 0 ? _vm$crewProfile$perso4 : '-'))]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Nama Kapal")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$vehic2 = (_vm$crewProfile15 = _vm.crewProfile) === null || _vm$crewProfile15 === void 0 ? void 0 : (_vm$crewProfile15$veh = _vm$crewProfile15.vehicle) === null || _vm$crewProfile15$veh === void 0 ? void 0 : _vm$crewProfile15$veh.name) !== null && _vm$crewProfile$vehic2 !== void 0 ? _vm$crewProfile$vehic2 : '-'))])]), _c('tr', [_c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Nomor Pegawai")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$nip = (_vm$crewProfile16 = _vm.crewProfile) === null || _vm$crewProfile16 === void 0 ? void 0 : _vm$crewProfile16.nip) !== null && _vm$crewProfile$nip !== void 0 ? _vm$crewProfile$nip : '-'))]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Sign On")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$sign_2 = (_vm$crewProfile17 = _vm.crewProfile) === null || _vm$crewProfile17 === void 0 ? void 0 : _vm$crewProfile17.sign_on) !== null && _vm$crewProfile$sign_2 !== void 0 ? _vm$crewProfile$sign_2 : '-'))])]), _c('tr', [_c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Seaferer Code")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$perso5 = (_vm$crewProfile18 = _vm.crewProfile) === null || _vm$crewProfile18 === void 0 ? void 0 : (_vm$crewProfile18$per = _vm$crewProfile18.personal_data) === null || _vm$crewProfile18$per === void 0 ? void 0 : _vm$crewProfile18$per.seaferer_code) !== null && _vm$crewProfile$perso5 !== void 0 ? _vm$crewProfile$perso5 : '-'))]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Sign Off")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$sign_3 = (_vm$crewProfile19 = _vm.crewProfile) === null || _vm$crewProfile19 === void 0 ? void 0 : _vm$crewProfile19.sign_off) !== null && _vm$crewProfile$sign_3 !== void 0 ? _vm$crewProfile$sign_3 : '-'))])]), _c('tr', [_c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Nomor NPWP")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$perso6 = (_vm$crewProfile20 = _vm.crewProfile) === null || _vm$crewProfile20 === void 0 ? void 0 : (_vm$crewProfile20$per = _vm$crewProfile20.personal_data) === null || _vm$crewProfile20$per === void 0 ? void 0 : _vm$crewProfile20$per.npwp_number) !== null && _vm$crewProfile$perso6 !== void 0 ? _vm$crewProfile$perso6 : '-'))]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("No. Tlp / Hp")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$perso7 = (_vm$crewProfile21 = _vm.crewProfile) === null || _vm$crewProfile21 === void 0 ? void 0 : (_vm$crewProfile21$per = _vm$crewProfile21.personal_data) === null || _vm$crewProfile21$per === void 0 ? void 0 : _vm$crewProfile21$per.hp_number) !== null && _vm$crewProfile$perso7 !== void 0 ? _vm$crewProfile$perso7 : '-'))])]), _c('tr', [_c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Nomor Passport")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$perso8 = (_vm$crewProfile22 = _vm.crewProfile) === null || _vm$crewProfile22 === void 0 ? void 0 : (_vm$crewProfile22$per = _vm$crewProfile22.personal_data) === null || _vm$crewProfile22$per === void 0 ? void 0 : _vm$crewProfile22$per.passport_number) !== null && _vm$crewProfile$perso8 !== void 0 ? _vm$crewProfile$perso8 : '-'))]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Email")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$perso9 = (_vm$crewProfile23 = _vm.crewProfile) === null || _vm$crewProfile23 === void 0 ? void 0 : (_vm$crewProfile23$per = _vm$crewProfile23.personal_data) === null || _vm$crewProfile23$per === void 0 ? void 0 : _vm$crewProfile23$per.email) !== null && _vm$crewProfile$perso9 !== void 0 ? _vm$crewProfile$perso9 : '-'))])]), _c('tr', [_c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Nomor Seamanbook")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$perso10 = (_vm$crewProfile24 = _vm.crewProfile) === null || _vm$crewProfile24 === void 0 ? void 0 : (_vm$crewProfile24$per = _vm$crewProfile24.personal_data) === null || _vm$crewProfile24$per === void 0 ? void 0 : _vm$crewProfile24$per.seaman_book_number) !== null && _vm$crewProfile$perso10 !== void 0 ? _vm$crewProfile$perso10 : '-'))]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Kewarganegaraan")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$perso11 = (_vm$crewProfile25 = _vm.crewProfile) === null || _vm$crewProfile25 === void 0 ? void 0 : (_vm$crewProfile25$per = _vm$crewProfile25.personal_data) === null || _vm$crewProfile25$per === void 0 ? void 0 : _vm$crewProfile25$per.citizenship) !== null && _vm$crewProfile$perso11 !== void 0 ? _vm$crewProfile$perso11 : '-'))])]), _c('tr', [_c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Alamat")])]), _c('td', {
          attrs: {
            "colspan": "3"
          }
        }, [_vm._v(_vm._s((_vm$crewProfile$perso12 = (_vm$crewProfile26 = _vm.crewProfile) === null || _vm$crewProfile26 === void 0 ? void 0 : (_vm$crewProfile26$per = _vm$crewProfile26.personal_data) === null || _vm$crewProfile26$per === void 0 ? void 0 : _vm$crewProfile26$per.citizenship) !== null && _vm$crewProfile$perso12 !== void 0 ? _vm$crewProfile$perso12 : '-'))])])])])]) : _vm._e(), _vm.form_candidate.key === 'candidate' ? _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Informasi Candidate")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Nama Candidate")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$candidateProfile$8 = (_vm$candidateProfile13 = _vm.candidateProfile) === null || _vm$candidateProfile13 === void 0 ? void 0 : (_vm$candidateProfile14 = _vm$candidateProfile13.personal_data) === null || _vm$candidateProfile14 === void 0 ? void 0 : _vm$candidateProfile14.full_name) !== null && _vm$candidateProfile$8 !== void 0 ? _vm$candidateProfile$8 : '-'))]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Nomor Passport")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$candidateProfile$9 = (_vm$candidateProfile15 = _vm.candidateProfile) === null || _vm$candidateProfile15 === void 0 ? void 0 : (_vm$candidateProfile16 = _vm$candidateProfile15.personal_data) === null || _vm$candidateProfile16 === void 0 ? void 0 : _vm$candidateProfile16.passport_number) !== null && _vm$candidateProfile$9 !== void 0 ? _vm$candidateProfile$9 : '-'))])]), _c('tr', [_c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Jabatan")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$candidateProfile17 = _vm.candidateProfile) === null || _vm$candidateProfile17 === void 0 ? void 0 : (_vm$candidateProfile18 = _vm$candidateProfile17.personal_data) === null || _vm$candidateProfile18 === void 0 ? void 0 : (_vm$candidateProfile19 = _vm$candidateProfile18.position) === null || _vm$candidateProfile19 === void 0 ? void 0 : _vm$candidateProfile19.name))]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Nomor Seamanbook")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$candidateProfile$10 = (_vm$candidateProfile20 = _vm.candidateProfile) === null || _vm$candidateProfile20 === void 0 ? void 0 : (_vm$candidateProfile21 = _vm$candidateProfile20.personal_data) === null || _vm$candidateProfile21 === void 0 ? void 0 : _vm$candidateProfile21.seaman_book_number) !== null && _vm$candidateProfile$10 !== void 0 ? _vm$candidateProfile$10 : '-'))])]), _c('tr', [_c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("NIK")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$candidateProfile$11 = (_vm$candidateProfile22 = _vm.candidateProfile) === null || _vm$candidateProfile22 === void 0 ? void 0 : (_vm$candidateProfile23 = _vm$candidateProfile22.personal_data) === null || _vm$candidateProfile23 === void 0 ? void 0 : _vm$candidateProfile23.nik_ktp) !== null && _vm$candidateProfile$11 !== void 0 ? _vm$candidateProfile$11 : '-'))]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Apply Date")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$candidateProfile$12 = (_vm$candidateProfile24 = _vm.candidateProfile) === null || _vm$candidateProfile24 === void 0 ? void 0 : _vm$candidateProfile24.apply_date) !== null && _vm$candidateProfile$12 !== void 0 ? _vm$candidateProfile$12 : '-'))])]), _c('tr', [_c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Seaferer Code")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$candidateProfile$13 = (_vm$candidateProfile25 = _vm.candidateProfile) === null || _vm$candidateProfile25 === void 0 ? void 0 : (_vm$candidateProfile26 = _vm$candidateProfile25.personal_data) === null || _vm$candidateProfile26 === void 0 ? void 0 : _vm$candidateProfile26.seaferer_code) !== null && _vm$candidateProfile$13 !== void 0 ? _vm$candidateProfile$13 : '-'))]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("No. Tlp / Hp")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$candidateProfile$14 = (_vm$candidateProfile27 = _vm.candidateProfile) === null || _vm$candidateProfile27 === void 0 ? void 0 : (_vm$candidateProfile28 = _vm$candidateProfile27.personal_data) === null || _vm$candidateProfile28 === void 0 ? void 0 : _vm$candidateProfile28.hp_number) !== null && _vm$candidateProfile$14 !== void 0 ? _vm$candidateProfile$14 : '-'))])]), _c('tr', [_c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Nomor NPWP")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$candidateProfile$15 = (_vm$candidateProfile29 = _vm.candidateProfile) === null || _vm$candidateProfile29 === void 0 ? void 0 : (_vm$candidateProfile30 = _vm$candidateProfile29.personal_data) === null || _vm$candidateProfile30 === void 0 ? void 0 : _vm$candidateProfile30.npwp_number) !== null && _vm$candidateProfile$15 !== void 0 ? _vm$candidateProfile$15 : '-'))]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Email")])]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$candidateProfile$16 = (_vm$candidateProfile31 = _vm.candidateProfile) === null || _vm$candidateProfile31 === void 0 ? void 0 : (_vm$candidateProfile32 = _vm$candidateProfile31.personal_data) === null || _vm$candidateProfile32 === void 0 ? void 0 : _vm$candidateProfile32.email) !== null && _vm$candidateProfile$16 !== void 0 ? _vm$candidateProfile$16 : '-'))])]), _c('tr', [_c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('strong', [_vm._v("Alamat")])]), _c('td', {
          attrs: {
            "colspan": "3"
          }
        }, [_vm._v(_vm._s((_vm$candidateProfile$17 = (_vm$candidateProfile33 = _vm.candidateProfile) === null || _vm$candidateProfile33 === void 0 ? void 0 : (_vm$candidateProfile34 = _vm$candidateProfile33.alamat_ktp) === null || _vm$candidateProfile34 === void 0 ? void 0 : (_vm$candidateProfile35 = _vm$candidateProfile34[0]) === null || _vm$candidateProfile35 === void 0 ? void 0 : _vm$candidateProfile35.alamat) !== null && _vm$candidateProfile$17 !== void 0 ? _vm$candidateProfile$17 : '-'))])])])])]) : _vm._e(), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Reason Black List")]), _c('b-form-group', [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.reason,
            "size": "lg",
            "aria-placeholder": "Pilih Alasan"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Alasan")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.form.blacklist.reason_off,
            callback: function callback($$v) {
              _vm.$set(_vm.form.blacklist, "reason_off", $$v);
            },
            expression: "form.blacklist.reason_off"
          }
        })], 1), _c('b-form-textarea', {
          attrs: {
            "rows": "3",
            "value": "Notes",
            "required": "",
            "placeholder": "Remarks..."
          },
          model: {
            value: _vm.form.blacklist.remarks,
            callback: function callback($$v) {
              _vm.$set(_vm.form.blacklist, "remarks", $$v);
            },
            expression: "form.blacklist.remarks"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12"
          }
        }, [_c('span', [_c('b-button', {
          attrs: {
            "variant": "primary",
            "block": "",
            "disabled": _vm.loading
          },
          on: {
            "click": _vm.onSaveBlacklist
          }
        }, [_c('i', {
          staticClass: "fa fa-file-signature"
        }), _vm._v(" SUBMIT ")])], 1)])], 1)], 1)];
      }
    }])
  }), _c('b-sidebar', {
    attrs: {
      "id": "medical-checkup",
      "title": "MEDICAL CHECKUP",
      "width": "50%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var hide = _ref4.hide;
        return [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3 iq-bg-info"
        }, [_c('legend', {
          staticClass: "w-auto px-2",
          staticStyle: {
            "font-size": "14pt"
          }
        }, [_c('strong', [_vm._v("Medical Check Up")])]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Check Up Name",
            "label-for": "checkup_name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "id": "checkup_name",
            "placeholder": "Check Up Name"
          }
        })], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Expired Date",
            "label-for": "expired_date"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "expired_date"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "File Check Up",
            "label-for": "file_medical_checkup"
          }
        }, [_c('div', {
          staticClass: "custom-file"
        }, [_c('input', {
          staticClass: "custom-file-input",
          attrs: {
            "type": "file",
            "id": "validatedCustomFile",
            "required": ""
          }
        }), _c('label', {
          staticClass: "custom-file-label",
          attrs: {
            "for": "validatedCustomFile"
          }
        }, [_vm._v("Browse file...")])]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" File Type * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(".pdf / Maks 3 MB")])], 1)])], 1)], 1), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus pr-0"
        }), _vm._v(" Add Document")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-minus pr-0"
        }), _vm._v(" Delete Document")])], 1), _c('b-button', {
          attrs: {
            "variant": "primary",
            "block": ""
          }
        }, [_c('i', {
          staticClass: "fa fa-file-signature"
        }), _vm._v(" SUBMIT ")])], 1)], 1)];
      }
    }])
  })], 1)])])], 1), _c('b-modal', {
    attrs: {
      "id": "reject-modal",
      "size": "l",
      "title": "Reject (Navigation Equipment - Navigation Light)",
      "ok-title": "Save Changes",
      "cancel-title": "Close"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Reason *",
      "label-for": "reason"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "reason",
      "rows": "2"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }