import Vue from 'vue';
import moment from "moment";

const filters = {
    parseDate: (date, format = "YYYY-MM-DD") => {
        if (date) {
            const regex_db_format = /^\d{2}-\d{2}-\d{4}$/;
            let db_format = "DD-MM-YYYY";
            if(!date.match(regex_db_format)) db_format = "YYYY-MM-DD"
            
            return moment(date, db_format).format(format);
        }
        return date;
    },
    parseDateEndorsement: (date, format = "DD-MM-YYYY") => {
        if (date) {
            return moment(date).format(format);
        }
        return date;
    },
    addMonthsToDate(date, months, format = "DD-MM-YYYY") {
        if (date) {
            date = new Date(date);

            var d = date.getDate();
            date.setMonth(date.getMonth() + +months);
            if (date.getDate() != d) {
                date.setDate(0);
            }
            return moment(date).format(format);
        }
        return date;
    },
    addYearToDate(date, year, format = "DD-MM-YYYY") {
        if (date) {
            return moment(date).add(year, 'y').format(format);
        }
        return date;
    },
    getDateDifferenceFromToday(date) {
        var current = moment().startOf('day')
        var given = moment(date, "DD-MM-YYYY")

        //Difference in number of days
        return moment.duration(given.diff(current)).asDays()
    },
    getDateDifferenceInMonth(date, date_to_compare) {
        var date = moment(new Date(date))
        var date_to_compare = moment(new Date(date_to_compare))
        
        return date.diff(date_to_compare, 'months', false)
    },
    getDateDifferenceInMonthWithFormat(date, date_to_compare) {
        var date = moment(date, "DD-MM-YYYY")
        var date_to_compare = moment(date_to_compare, "DD-MM-YYYY")

        return date.diff(date_to_compare, 'month')
    },
    checkPhoto(photo, callback_photo) {
        let image_can_be_accessed = false;

        var image = new Image();
        image.src = photo;

        image.onload = () => image_can_be_accessed = true;
        image.onerror = () => image_can_be_accessed = false;

        if (!photo && !image_can_be_accessed) {
            return callback_photo;
        }
        
        return photo;
    },
    cleanObject(obj) {
        for (let propName in obj) {
            if (
            obj[propName] === null ||
            obj[propName] === undefined ||
            obj[propName] === ''
            ) {
            delete obj[propName]
            }
        }
        return obj
    },
}

Object.keys(filters).forEach(filter => {
    Vue.filter(filter, filters[filter]);
});