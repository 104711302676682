<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th
          class="text-center"
          v-for="(state, index) in tableData.head"
          :key="index"
          >{{ state }}</th
        >
      </tr>
    </thead>
    <tbody>
      <tr v-for="(data, index) in tableData.body" :key="index">
        <td class="text-center">{{
          currentPage > 1 ? currentPage + index + 1 : index + 1
        }}</td>
        <td
          ><router-link
            :to="{
              path: `profile-crew/${data.id}`,
              params: { id: data.id },
            }"
            >{{ data.fullname }}</router-link
          ><br /><small style="color: gray"
            >NIP : <b>{{ data.nip }}</b
            ><br />Seaferer Code : {{ data.seaferer_code }}</small
          ></td
        >
        <td class="text-center"
          >{{ data.vehicle }}<br /><small style="color: gray">{{
            data.company
          }}</small></td
        >
        <td class="text-center">{{ data.name_position }}</td>
        <td class="text-center">{{ data.sign_on }}</td>
        <td class="text-center">{{ data.sign_off }}</td>
        <td class="text-center">
          <b-badge
            v-if="data.status_screw === 'Active'"
            variant="success d-block"
            >{{ data.status_crew }}</b-badge
          >
          <b-badge
            v-else-if="data.status_screw === 'Finish'"
            variant="danger d-block"
            >{{ data.status_crew }}</b-badge
          >
          <b-badge v-else variant="primary d-block">{{
            data.status_crew
          }}</b-badge>
        </td>
        <td class="text-center">
          <b-dropdown
            size="lg"
            variant="link iq-bg-primary iq-border-radius-10"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <span
                class="dropdown-toggle p-0"
                id="dropdownMenuButton5"
                data-toggle="dropdown"
              >
                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
              </span>
            </template>
            <b-dropdown-item
              href="#"
              v-b-toggle.sign-off
              @click="() => setDataUser(data)"
              ><i class="fas fa-arrow-circle-down mr-2"></i>Sign
              Off</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              v-b-toggle.blacklist-crew
              @click="() => setDataUser(data)"
              ><i class="fa fa-ban mr-2"></i>Black List</b-dropdown-item
            >

            <b-dropdown-item>
              <router-link
                :class="'text-dark'"
                :to="{
                  path: `/crewing/condite/${data.id}`,
                  params: { id: data.id },
                }"
              >
                <i class="fas fa-arrow-circle-down mr-2"></i>Penilaian
                Condite</router-link
              >
            </b-dropdown-item>
            <b-dropdown-item>
              <router-link
                :class="'text-dark'"
                :to="{
                  path: `/crewing/conduct/${data.id}`,
                  params: { id: data.id },
                }"
              >
                <i class="fas fa-arrow-circle-down mr-2"></i>Penilaian
                Conduct</router-link
              >
            </b-dropdown-item>
          </b-dropdown>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { crewingActions } from '@src/Utils/helper'
export default {
  props: {
    currentPage: {
      type: Number,
      default: false,
    },
    tableData: {
      type: Object,
      default: false,
    },
    value: {
      type: Object,
      default: () => {
        return {
          id: null,
          key: '',
        }
      },
    },
  },
  methods: {
    ...crewingActions,
    setDataUser(state) {
      this.form.id = state.id
      this.form.key = 'crew'
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
      },
    },
  },
}
</script>
