<template>
  <b-container fluid>
    <form action="#" method="post" @submit="onSave">
      <b-row>
        <b-col lg="4">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">CREW BARU</h4>
            </template>
            <template v-slot:body>
              <b-form-group class="text-center">
                <div class="add-img-user profile-img-edit">
                  <b-img
                    class="profile-pic height-150 width-150"
                    fluid
                    :src="user.photo ?? user.default_photo"
                    alt="profile-pic"
                  />
                  <input type="hidden" v-model="user.photo" />
                  <div class="p-image">
                    <b-button
                      variant="none"
                      class="upload-button iq-bg-primary position-relative"
                    >
                      <input
                        type="file"
                        @change="previewImage"
                        class="h-100 position-absolute"
                        accept="image/*"
                        style="opacity: 0"
                        required
                      />
                      File Upload
                    </b-button>
                  </div>
                </div>
                <div class="img-extension mt-3">
                  <div class="d-inline-block align-items-center">
                    <span>Only</span>
                    <b-link href="javascript:void(0);">.jpg</b-link>
                    <b-link href="javascript:void(0);">.png</b-link>
                    <b-link href="javascript:void(0);">.jpeg</b-link>
                    <span>allowed</span>
                  </div>
                </div>
              </b-form-group>
              <b-alert :show="true" class="text-center text-white bg-primary">
                <div class="iq-alert-text">
                  <strong>DATA DIRI</strong>
                </div>
              </b-alert>
              <b-form-group label="Nama Lengkap" label-for="nama_lengkap">
                <b-form-input
                  id="nama_lengkap"
                  name="nama_lengkap"
                  v-model="user.full_name"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Nama Panggilan" label-for="nama_panggilan">
                <b-form-input
                  id="nama_panggilan"
                  name="nama_panggilan"
                  v-model="user.nick_name"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Nomor Induk Kependudukan (NIK)"
                label-for="nik"
              >
                <b-form-input
                  id="nik"
                  name="nik"
                  v-model="user.nik_ktp"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Seaferer Code" label-for="seafererCode">
                <b-form-input
                  id="seafererCode"
                  name="seafererCode"
                  v-model="user.seaferer_code"
                  required
                ></b-form-input>
                <b-form-text
                  id="seafererCode-live-help"
                  class="text-danger"
                  v-if="error?.seaferer_code"
                  >*{{ error?.seaferer_code }}</b-form-text
                >
              </b-form-group>
              <b-form-group
                label="Nomor Pokok Wajib Pajak (NPWP)"
                label-for="npwp"
              >
                <b-form-input
                  id="npwp"
                  name="npwp"
                  v-model="user.npwp_number"
                  required
                ></b-form-input>
              </b-form-group>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Jabatan / Ijazah"
                  label-for="jabatan"
                >
                  <b-form-select
                    plain
                    v-model="user.position_id"
                    :options="optionsJabatan"
                    required
                    size="sm"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Jabatan</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Nama Kapal"
                  label-for="kapal"
                >
                  <b-form-select
                    plain
                    v-model="user.vehicle_id"
                    :options="optionsVehicles"
                    required
                    size="sm"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Nama Kapal</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-row>
              <b-form-group label="Kewarganegaraan" label-for="kewarganegaraan">
                <b-form-select
                  plain
                  v-model="user.citizenship"
                  :options="optionsKewarganegaraan"
                  size="sm"
                  required
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null"
                      >Select Kewarganegaraan</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Tempat Lahir"
                  label-for="tempat_lahir"
                >
                  <b-form-input
                    id="tempat_lahir"
                    name="tempat_lahir"
                    v-model="user.place_of_birth"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Tanggal Lahir"
                  label-for="tanggal_lahir"
                >
                  <b-form-input
                    type="date"
                    id="tanggal_lahir"
                    v-model="user.date_of_birth"
                    required
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Golongan Darah"
                  label-for="golongan_darah"
                >
                  <b-form-select
                    plain
                    v-model="user.golongan_darah_id"
                    :options="optionsGolonganDarah"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Golongan Darah</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="col-md-6" label="Agama" label-for="Agama">
                  <b-form-select
                    plain
                    v-model="user.religion_id"
                    :options="optionsAgama"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Agama</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Status Pernikahan"
                  label-for="StatusPernikahan"
                >
                  <b-form-select
                    plain
                    v-model="user.marital_status_id"
                    :options="optionsStatusPernikahan"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Status</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Jumlah Anak"
                  label-for="jumlah_anak"
                >
                  <b-form-input
                    id="jumlah_anak"
                    name="jumlah_anak"
                    v-model="user.number_of_children"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="No. Tlp / Handphone"
                  label-for="no_handphone"
                >
                  <b-form-input
                    id="no_handphone"
                    name="no_handphone"
                    v-model="user.hp_number"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-md-6" label="Email" label-for="email">
                  <b-form-input
                    id="email"
                    name="email"
                    v-model="user.email"
                    required
                  ></b-form-input>
                  <b-form-text
                    id="email-live-help"
                    class="text-danger"
                    v-if="error?.email"
                    >*{{ error?.email }}</b-form-text
                  >
                </b-form-group>
              </b-row>
              <b-alert :show="true" class="text-center text-white bg-primary">
                <div class="iq-alert-text">
                  <strong>JOIN DATE</strong>
                </div>
              </b-alert>
              <b-form-group label="Nomor Pegawai" label-for="nip">
                <b-form-input id="nip" name="nip"></b-form-input>
              </b-form-group>
              <b-form-group label="Sign On" label-for="sign_on">
                <b-form-input
                  id="sign_on"
                  name="sign_on"
                  type="date"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Join Onboard" label-for="join_onboard">
                <b-form-input
                  id="join_onboard"
                  name="join_onboard"
                  type="date"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Sign Off" label-for="sign_off">
                <b-form-input
                  id="sign_off"
                  name="sign_off"
                  type="date"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group label="File Contract" label-for="file-contract">
                <b-form-file plain id="file-contract"></b-form-file>
              </b-form-group>
              <b-alert :show="true" class="text-center text-white bg-primary">
                <div class="iq-alert-text">
                  <strong>PAKAIAN & SEPATU</strong>
                </div>
              </b-alert>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Tinggi Badan"
                  label-for="tinggi_badan"
                >
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      id="tinggi_badan"
                      style="border: solid 1px gray"
                      v-model="user.height"
                      required
                    />
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text bg-primary"
                        id="inputGroupPrepend2"
                        >CM</span
                      >
                    </div>
                  </div>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Berat Badan"
                  label-for="berat_badan"
                >
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      id="berat_badan"
                      style="border: solid 1px gray"
                      v-model="user.weight"
                      required
                    />
                    <div class="input-group-prepend">
                      <span
                        class="input-group-text bg-primary"
                        id="inputGroupPrepend2"
                        >KG</span
                      >
                    </div>
                  </div>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Wearpack"
                  label-for="wearpack"
                >
                  <b-form-select
                    plain
                    v-model="user.wearpack"
                    :options="wearpackCrew"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Wearpack</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Sepatu Safety"
                  label-for="sepatu_safety"
                >
                  <b-form-input
                    type="number"
                    id="sepatu_safety"
                    name="sepatu_safety"
                    placeholder="E.g. 40"
                    required
                    v-model="user.size_of_shoes"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Helmet"
                  label-for="helmet"
                >
                  <b-form-input
                    type="number"
                    id="helmet"
                    name="helmet"
                  ></b-form-input>
                </b-form-group>
              </b-row>
              <b-alert :show="true" class="text-center text-white bg-primary">
                <div class="iq-alert-text">
                  <strong>BASIC SALLARY</strong>
                </div>
              </b-alert>
              <b-form-group label="Gaji Pokok" label-for="gaji_pokok">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text bg-primary"
                      id="inputGroupPrepend2"
                      >Rp.</span
                    >
                  </div>
                  <input type="number" class="form-control" id="gaji_pokok" />
                </div>
              </b-form-group>
              <b-form-group label="Uang Cuti" label-for="uang_cuti">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text bg-primary"
                      id="inputGroupPrepend2"
                      >Rp.</span
                    >
                  </div>
                  <input type="number" class="form-control" id="uang_cuti" />
                </div>
              </b-form-group>
              <b-form-group
                label="Lembur / Jam (Tetap)"
                label-for="uang_lembur"
              >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text bg-primary"
                      id="inputGroupPrepend2"
                      >Rp.</span
                    >
                  </div>
                  <input type="number" class="form-control" id="uang_lembur" />
                </div>
              </b-form-group>
              <b-form-group
                label="Tunjangan Jabatan"
                label-for="tunjangan_jabatan"
              >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text bg-primary"
                      id="inputGroupPrepend2"
                      >Rp.</span
                    >
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    id="tunjangan_jabatan"
                  />
                </div>
              </b-form-group>
              <b-form-group
                label="Uang Makan / Lain-lain"
                label-for="uang_makan"
              >
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span
                      class="input-group-text bg-primary"
                      id="inputGroupPrepend2"
                      >Rp.</span
                    >
                  </div>
                  <input type="number" class="form-control" id="uang_makan" />
                </div>
              </b-form-group>
              <b-form-group label="Fix Income" class="text-right">
                <h3 class="text-primary">Rp. 7.000.000,-</h3>
              </b-form-group>
              <b-alert :show="true" class="text-center text-white bg-primary">
                <div class="iq-alert-text">
                  <strong>AKUN BANK</strong>
                </div>
              </b-alert>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Atas Nama"
                  label-for="atas_nama"
                >
                  <b-form-input
                    id="atas_nama"
                    name="atas_nama"
                    placeholder="Atas Nama"
                    v-model="user.rekening_name"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Nama Bank"
                  label-for="nama_bank"
                >
                  <b-form-select
                    plain
                    v-model="user.bank_id"
                    :options="optionsBank"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Bank</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-row>
              <b-form-group label="Nomor Rekening" label-for="no_rek">
                <b-form-input
                  type="number"
                  id="no_rek"
                  name="no_rek"
                  placeholder="Nomor Rekening"
                  v-model="user.rekening_number"
                  required
                ></b-form-input>
              </b-form-group>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="8">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">FORM CREW BARU</h4>
            </template>
            <template v-slot:body>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >ALAMAT KTP</legend
                >
                <FormInputAddress v-model="user.ktp_address" />
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >ALAMAT DOMISILI</legend
                >
                <FormInputAddress
                  v-model="user.domicile_address"
                  :checked="user._checked"
                />
                <b-row>
                  <b-form-group class="col-md-12">
                    <b-form-checkbox
                      v-model="user._checked"
                      name="check-button"
                      switch
                    >
                      <small class="text-muted">
                        Alamat domisili sama dengan alamat ktp</small
                      >
                    </b-form-checkbox>
                  </b-form-group>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >KELUARGA YANG BISA DI HUBUNGI ( EMERGENCY CALL )</legend
                >
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Nama Lengkap" label-for="nama_lengkap">
                      <b-form-input
                        id="nama_lengkap"
                        name="nama_lengkap"
                        v-model="
                          user.family_emergency_call.family_emergency_name
                        "
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="No. Tlp/Handphone"
                      label-for="handphone"
                    >
                      <b-form-input
                        id="handphone"
                        name="handphone"
                        v-model="user.family_emergency_call.emergency_number"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Hubungan" label-for="hubungan">
                      <b-form-input
                        id="hubungan"
                        name="hubungan"
                        v-model="
                          user.family_emergency_call.family_emergency_status
                        "
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Alamat" label-for="alamat">
                      <b-form-textarea
                        id="alamat"
                        name="alamat"
                        rows="9"
                        v-model="
                          user.family_emergency_call.family_emergency_address
                        "
                        required
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >PENDIDIKAN</legend
                >
                <b-row
                  v-for="(state, index) in user.pendidikan"
                  v-bind:key="index"
                >
                  <div
                    v-if="user.pendidikan.length > 1 && index > 0"
                    class="d-flex w-100 px-3 my-1 justify-content-end cursor-pointer"
                    @click="deletePendidikanForm(index)"
                  >
                    <i class="fa fa-trash"></i>
                  </div>
                  <b-form-group
                    class="col-md-3"
                    label="Sekolah/Universitas"
                    label-for="instansiPendidikan"
                  >
                    <b-form-input
                      id="instansiPendidikan"
                      name="instansiPendidikan"
                      v-model="state.sekolah"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="col-md-3"
                    label="Jenjang"
                    label-for="jenjang"
                  >
                    <b-form-select
                      plain
                      v-model="state.jenjang"
                      :options="optionsJenjang"
                      size="sm"
                      required
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null"
                          >Select Jenjang</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group
                    class="col-md-3"
                    label="Jurusan"
                    label-for="jurusan"
                  >
                    <b-form-input
                      id="jurusan"
                      name="jurusan"
                      v-model="state.jurusan"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="col-md-3"
                    label="IPK/Nilai Akhir"
                    label-for="ipk"
                  >
                    <b-form-input
                      type="number"
                      id="ipk"
                      name="ipk"
                      step="0.0001"
                      min="0"
                      v-model="state.nilai"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="col-md-3"
                    label="Tahun Mulai"
                    label-for="tahunMulai"
                  >
                    <b-form-input
                      type="number"
                      id="tahunMulai"
                      name="tahunMulai"
                      v-model="state.tahun_mulai"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="col-md-3"
                    label="Tahun Selesai"
                    label-for="tahunSelesai"
                  >
                    <b-form-input
                      type="number"
                      id="tahunSelesai"
                      name="tahunSelesai"
                      v-model="state.tahun_selesai"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-3" label="Kota" label-for="kota">
                    <b-form-input
                      id="kota"
                      name="kota"
                      v-model="state.kota"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="col-md-3"
                    label="Negara"
                    label-for="negara"
                  >
                    <b-form-input
                      id="negara"
                      name="negara"
                      v-model="state.negara"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <div
                    v-if="user.pendidikan.length > 1"
                    class="d-flex w-100 px-2 my-1 border-bottom"
                  ></div>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="success"
                      class="mt-1"
                      @click="addPendidikanForm"
                    >
                      <i class="fa fa-plus"></i> ADD FORM
                    </b-button>
                  </b-col>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >DATA KELUARGA</legend
                >
                <div v-for="(state, index) in user.family" v-bind:key="index">
                  <div
                    v-if="user.family.length > 1 && index > 0"
                    class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                    @click="deleteFamilyForm(index)"
                  >
                    <i class="fa fa-trash"></i>
                  </div>
                  <b-row>
                    <b-form-group
                      class="col-md-6"
                      label="Nama Lengkap"
                      label-for="nama_lengkap"
                    >
                      <b-form-input
                        id="nama_lengkap"
                        name="nama_lengkap"
                        v-model="state.nama_lengkap"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Hubungan"
                      label-for="hubungan"
                    >
                      <b-form-select
                        plain
                        v-model="state.status"
                        :options="hubungan"
                        size="sm"
                        required
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >Select Hubungan</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group
                      class="col-md-3"
                      label="Jenis Kelamin"
                      label-for="jenis_kelamin"
                    >
                      <b-form-select
                        plain
                        v-model="state.jenis_kelamin"
                        :options="jenisKelaminFamily"
                        size="sm"
                        required
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >Select Jenis Kelamin</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Umur"
                      label-for="umur"
                    >
                      <b-form-input
                        type="number"
                        id="umur"
                        name="umur"
                        v-model="state.age_family"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Pekerjaan"
                      label-for="pekerjaan"
                    >
                      <b-form-input
                        id="pekerjaan"
                        name="pekerjaan"
                        v-model="state.pekerjaan"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="No. Tlp/Handphone"
                      label-for="handphone"
                    >
                      <b-form-input
                        type="number"
                        id="handphone"
                        name="handphone"
                        v-model="state.no_hp"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-row>
                  <div
                    v-if="user.family.length > 1"
                    class="d-flex w-100 px-2 my-1 border-bottom"
                  ></div>
                </div>
                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="success"
                      class="mt-1"
                      @click="addFamilyForm"
                    >
                      <i class="fa fa-plus"></i> ADD FORM
                    </b-button>
                  </b-col>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >TRAVEL DOCUMENT</legend
                >
                <div
                  v-for="(state, index) in user.travel_document"
                  v-bind:key="index"
                >
                  <div
                    v-if="user.travel_document.length > 1 && index > 0"
                    class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                    @click="deleteTravelDocument(index)"
                  >
                    <i class="fa fa-trash"></i>
                  </div>
                  <b-row>
                    <b-form-group
                      class="col-md-3"
                      label="Nama Dokumen"
                      label-for="nama_dokumen"
                    >
                      <b-form-input
                        id="nama_dokumen"
                        name="nama_dokumen"
                        v-model="state.document_name"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Kategori Dokumen"
                      label-for="kategori_dokumen"
                      v-model="state.document_name"
                    >
                      <b-form-select
                        plain
                        v-model="state.document_category"
                        :options="kategoriDokumen"
                        size="sm"
                        required
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >Select Kategori</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Nomor Dokumen"
                      label-for="nomor_dokumen"
                    >
                      <b-form-input
                        id="nomor_dokumen"
                        name="nomor_dokumen"
                        v-model="state.document_number"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group
                      class="col-md-6"
                      label="Date Issued"
                      label-for="date_issued"
                    >
                      <b-form-input
                        type="date"
                        id="date_issued"
                        v-model="state.date_issued"
                        required
                      ></b-form-input>
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Masa Berlaku"
                      label-for="masa_berlaku"
                    >
                      <b-form-input
                        type="date"
                        id="masa_berlaku"
                        v-model="state.validity_period"
                        required
                      ></b-form-input>
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group
                      class="col-md-6"
                      label="Place Issued"
                      label-for="place_issued"
                    >
                      <b-form-input
                        id="place_issued"
                        name="place_issued"
                        v-model="state.place_issued"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Attachment"
                      label-for="attachment"
                    >
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="validatedCustomFile"
                          required
                          @change="(event) => prefiewFiles(event, state)"
                        />
                        <label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          v-if="!state.file"
                          >Browse file...</label
                        >
                        <label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          v-else
                          >{{ state.file_label }}</label
                        >
                      </div>
                      <small class="text-muted">
                        File Type * :
                        <b-badge variant="info"
                          >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                          .pdf</b-badge
                        >
                        <br />
                        File CapaKota * :
                        <b-badge variant="info">3 MB / File</b-badge>
                      </small>
                    </b-form-group>
                  </b-row>
                  <div
                    v-if="user.travel_document.length > 1"
                    class="d-flex w-100 px-2 my-1 border-bottom"
                  ></div>
                </div>
                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="success"
                      class="mt-1"
                      @click="addTravelDocumentForm"
                    >
                      <i class="fa fa-plus"></i> ADD FORM
                    </b-button>
                  </b-col>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >CERTIFICATE OF COMPETENCY</legend
                >
                <div
                  v-for="(state, index) in user.certificate_of_competency"
                  v-bind:key="index"
                >
                  <div
                    v-if="
                      user.certificate_of_competency.length > 1 && index > 0
                    "
                    class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                    @click="deleteCertificateOfCompetencyForm(index)"
                  >
                    <i class="fa fa-trash"></i>
                  </div>
                  <b-row>
                    <b-form-group
                      class="col-md-6"
                      label="Ijazah & Endorse"
                      label-for="ijazah-endorse"
                    >
                      <b-form-input
                        id="ijazah-endorse"
                        name="ijazah-endorse"
                        v-model="state.document_name"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Nomor Sertifikat"
                      label-for="nomor_sertifikat"
                    >
                      <b-form-input
                        id="nomor_sertifikat"
                        name="nomor_sertifikat"
                        v-model="state.document_number"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group
                      class="col-md-6"
                      label="Date Issued"
                      label-for="date_issued"
                    >
                      <b-form-input
                        type="date"
                        id="date_issued"
                        v-model="state.date_issued"
                        required
                      ></b-form-input>
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Masa Berlaku"
                      label-for="masa_berlaku"
                    >
                      <b-form-input
                        type="date"
                        id="masa_berlaku"
                        v-model="state.validity_period"
                        required
                      ></b-form-input>
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group
                      class="col-md-6"
                      label="Place Issued"
                      label-for="place_issued"
                    >
                      <b-form-input
                        id="place_issued"
                        name="place_issued"
                        required
                        v-model="state.place_issued"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Attachment"
                      label-for="attachment"
                    >
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="validatedCustomFile"
                          required
                          @change="(event) => prefiewFiles(event, state)"
                        />
                        <label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          v-if="!state.file"
                          >Browse file...</label
                        >
                        <label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          v-else
                          >{{ state.file_label }}</label
                        >
                      </div>
                      <small class="text-muted">
                        File Type * :
                        <b-badge variant="info"
                          >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                          .pdf</b-badge
                        >
                        <br />
                        File CapaKota * :
                        <b-badge variant="info">3 MB / File</b-badge>
                      </small>
                    </b-form-group>
                  </b-row>
                  <div
                    v-if="user.certificate_of_competency.length > 1"
                    class="d-flex w-100 px-2 my-1 border-bottom"
                  ></div>
                </div>
                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="success"
                      class="mt-1"
                      @click="addCertificateOfCompetencyForm"
                    >
                      <i class="fa fa-plus"></i> ADD FORM
                    </b-button>
                  </b-col>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >CERTIFICATE OF PROFICIENCY</legend
                >
                <div
                  v-for="(state, index) in user.certificate_of_proficiency"
                  v-bind:key="index"
                >
                  <div
                    v-if="
                      user.certificate_of_proficiency.length > 1 && index > 0
                    "
                    class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                    @click="deleteCertificateOfProfiencyForm(index)"
                  >
                    <i class="fa fa-trash"></i>
                  </div>
                  <b-row>
                    <b-form-group
                      class="col-md-6"
                      label="Nama Sertifikat"
                      label-for="nama_sertifikat"
                    >
                      <b-form-input
                        id="nama_sertifikat"
                        name="nama_sertifikat"
                        v-model="state.document_name"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Nomor Dokumen"
                      label-for="nomor_dokumen"
                    >
                      <b-form-input
                        id="nomor_dokumen"
                        name="nomor_dokumen"
                        v-model="state.document_number"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group
                      class="col-md-6"
                      label="Date Issued"
                      label-for="date_issued"
                    >
                      <b-form-input
                        type="date"
                        id="date_issued"
                        v-model="state.date_issued"
                        required
                      ></b-form-input>
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Masa Berlaku"
                      label-for="masa_berlaku"
                    >
                      <b-form-input
                        type="date"
                        id="masa_berlaku"
                        v-model="state.validity_period"
                        required
                      ></b-form-input>
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group
                      class="col-md-6"
                      label="Place Issued"
                      label-for="place_issued"
                    >
                      <b-form-input
                        id="place_issued"
                        name="place_issued"
                        placeholder="E.g Jakarta"
                        v-model="state.place_issued"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-6"
                      label="Attachment"
                      label-for="attachment"
                    >
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="validatedCustomFile"
                          @change="(event) => prefiewFiles(event, state)"
                          required
                        />
                        <label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          v-if="!state.file"
                          >Browse file...</label
                        >
                        <label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          v-else
                          >{{ state.file_label }}</label
                        >
                      </div>
                      <small class="text-muted">
                        File Type * :
                        <b-badge variant="info"
                          >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                          .pdf</b-badge
                        >
                        <br />
                        File CapaKota * :
                        <b-badge variant="info">3 MB / File</b-badge>
                      </small>
                    </b-form-group>
                  </b-row>
                  <div
                    v-if="user.certificate_of_proficiency.length > 1"
                    class="d-flex w-100 px-2 my-1 border-bottom"
                  ></div>
                </div>
                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="success"
                      class="mt-1"
                      @click="addCertificateOfProfiencyForm"
                    >
                      <i class="fa fa-plus"></i> ADD FORM
                    </b-button>
                  </b-col>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >PENGALAMAN BERLAYAR, MINIMAL 5 PENGALAMAN TERAKHIR</legend
                >
                <div
                  v-for="(state, index) in user.sail_experience"
                  v-bind:key="index"
                >
                  <div
                    v-if="user.sail_experience.length > 1 && index > 0"
                    class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                    @click="deleteSailExperienceForm(index)"
                  >
                    <i class="fa fa-trash"></i>
                  </div>
                  <b-row>
                    <b-form-group
                      class="col-md-3"
                      label="Nama Kapal"
                      label-for="nama_kapal"
                    >
                      <b-form-input
                        id="nama_kapal"
                        name="nama_kapal"
                        v-model="state.ship_name"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Pemilik"
                      label-for="pemilik"
                    >
                      <b-form-input
                        id="pemilik"
                        name="pemilik"
                        v-model="state.owner"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Bendera"
                      label-for="bendera"
                    >
                      <b-form-input
                        id="bendera"
                        name="bendera"
                        v-model="state.flag"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Kawasan"
                      label-for="kawasan"
                    >
                      <b-form-input
                        id="kawasan"
                        name="kawasan"
                        v-model="state.area"
                        required
                      ></b-form-input>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group
                      class="col-md-3"
                      label="Jabatan"
                      label-for="jabatan"
                    >
                      <b-form-select
                        plain
                        v-model="state.position_id"
                        :options="optionsJabatan"
                        size="sm"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >Select Jabatan</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="No. Tlp Perusaahaan"
                      label-for="no_tlp"
                    >
                      <b-form-input
                        id="no_tlp"
                        name="no_tlp"
                        v-model="state.no_number_company"
                        required
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Tanggal Bergabung"
                      label-for="tanggal_bergabung"
                    >
                      <b-form-input
                        type="date"
                        id="tanggal_bergabung"
                        v-model="state.date_join"
                        required
                      ></b-form-input>
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-form-group>
                    <b-form-group
                      class="col-md-3"
                      label="Tanggal Behenti"
                      label-for="tanggal_berhenti"
                    >
                      <b-form-input
                        type="date"
                        id="tanggal_berhenti"
                        v-model="state.date_end"
                        required
                      ></b-form-input>
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-form-group>
                  </b-row>
                  <div
                    v-if="user.sail_experience.length > 1"
                    class="d-flex w-100 px-2 my-1 border-bottom"
                  ></div>
                </div>
                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="success"
                      class="mt-1"
                      @click="addSailExperienceForm"
                    >
                      <i class="fa fa-plus"></i> ADD FORM
                    </b-button>
                  </b-col>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >ORGANISASI</legend
                >
                <b-row
                  v-for="(state, index) in user.organisasi"
                  v-bind:key="index"
                >
                  <div
                    v-if="user.organisasi.length > 1 && index > 0"
                    class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                    @click="deleteOrganisasiForm(index)"
                  >
                    <i class="fa fa-trash"></i>
                  </div>
                  <b-col md="6">
                    <b-row>
                      <b-form-group
                        class="col-md-6"
                        label="Nama Organisasi"
                        label-for="namaOrganisasi"
                      >
                        <b-form-input
                          id="namaOrganisasi"
                          style="height: 35px"
                          name="namaOrganisasi"
                          required
                          v-model="state.nama_organisasi"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="col-md-6"
                        label="Jabatan"
                        label-for="jabatanOrganisasi"
                      >
                        <b-form-input
                          id="jabatanOrganisasi"
                          style="height: 35px"
                          name="jabatanOrganisasi"
                          v-model="state.posisi"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="col-md-6"
                        label="Tanggal Bergabung"
                        label-for="tanggal_bergabung"
                      >
                        <b-form-input
                          type="date"
                          id="tanggal_bergabung"
                          v-model="state.durasi_awal"
                          required
                        ></b-form-input>
                        <small style="float: right"
                          >click <i class="fa fa-calendar"></i> to show
                        </small>
                      </b-form-group>
                      <b-form-group
                        class="col-md-6"
                        label="Tanggal Selesai"
                        label-for="tanggal_selesai"
                      >
                        <b-form-input
                          type="date"
                          id="tanggal_selesai"
                          v-model="state.durasi_akhir"
                          required
                        ></b-form-input>
                        <small style="float: right"
                          >click <i class="fa fa-calendar"></i> to show
                        </small>
                      </b-form-group>
                    </b-row>
                  </b-col>
                  <b-col md="6">
                    <b-row>
                      <b-form-group
                        class="col-md-12"
                        label="Instansi"
                        label-for="instansi"
                      >
                        <b-form-input
                          id="instansi"
                          style="height: 35px"
                          name="instansi"
                          v-model="state.tempat"
                          required
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="col-md-12"
                        label="Deskripsi"
                        label-for="deskripsi"
                      >
                        <b-form-input
                          id="deskripsi"
                          style="height: 35px"
                          name="deskripsi"
                          v-model="state.deskripsi"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-row>
                  </b-col>
                  <div
                    v-if="user.organisasi.length > 1"
                    class="d-flex w-100 px-2 my-1 border-bottom"
                  ></div>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-button
                      variant="success"
                      class="mt-1"
                      @click="addOrganisasiForm"
                    >
                      <i class="fa fa-plus"></i> ADD FORM
                    </b-button>
                  </b-col>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >SUPPORTING DOCUMENT</legend
                >
                <b-row>
                  <b-col>
                    <b-form-group label="Nama Dokumen" label-for="nama_dokumen">
                      <input
                        type="text"
                        class="form-control"
                        id="nama_dokumen"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="File" label-for="file">
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="validatedCustomFile"
                          required
                        />
                        <label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          >Browse file...</label
                        >
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-button variant="success" class="mt-1"
                      ><i class="fa fa-plus"></i> ADD FORM</b-button
                    >
                  </b-col>
                </b-row>
              </fieldset>
              <b-button
                :disabled="user._saving"
                block
                variant="primary"
                type="submit"
                class="mt-3"
              >
                <b-spinner
                  v-if="user._saving"
                  variant="secondary"
                  label="Spinning"
                ></b-spinner>
                <span v-else>Submit Data</span>
              </b-button>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { crewingActions } from '@src/Utils/helper'
import FormInputAddress from '@src/components/crewing/FormInputAddress.vue'
import optionsData from '@src/core/mock/data'
export default {
  name: 'AddUser',
  mounted() {
    xray.index()
  },
  data() {
    return {
      ...optionsData,
      optionsVehicles: [],
      //   masih kurang
      selectedCategoryDokumen: null,
      error: {
        email: '',
        seaferer_code: '',
      },
      //   User Data
      user: {
        _saving: false,
        _checked: false,
        photo: require('../../assets/images/user/11.png'),
        photo_file: null,
        full_name: '',
        nick_name: '',
        nik_ktp: '',
        npwp_number: '',
        seaferer_code: '',
        citizenship: '',
        position_id: null,
        vehicle_id: null,
        place_of_birth: '',
        date_of_birth: '',
        golongan_darah_id: null,
        religion_id: null,
        marital_status_id: null,
        number_of_children: null,
        hp_number: null,
        email: '',
        address: 'jakarta',
        height: null,
        weight: null,
        wearpack: '',
        size_of_shoes: null,
        bank_id: null,
        rekening_name: '',
        rekening_number: null,
        // diganti
        domicile_address: {
          provinsi: null,
          kabupaten: null,
          kecamatan: null,
          kelurahan: null,
          kode_pos: 0,
          alamat: '',
        },
        ktp_address: {
          provinsi: null,
          kabupaten: null,
          kecamatan: null,
          kelurahan: null,
          kode_pos: 0,
          alamat: '',
        },
        family_emergency_call: {
          emergency_number: null,
          family_emergency_name: '',
          family_emergency_address: '',
        },
        pendidikan: [
          {
            sekolah: '',
            jenjang: null,
            jurusan: '',
            nilai: null,
            tahun_mulai: '',
            tahun_selesai: '',
            kota: '',
            negara: '',
          },
        ],
        family: [
          {
            status: null,
            nama_lengkap: '',
            jenis_kelamin: null,
            age_family: null,
            pekerjaan: '',
            no_hp: null,
          },
        ],
        travel_document: [
          {
            document_name: '',
            document_number: '',
            place_issued: '',
            date_issued: '',
            validity_period: '',
            file: null,
            file_label: '',
            // category_document:null
          },
        ],
        certificate_of_competency: [
          {
            document_name: '',
            document_number: '',
            place_issued: '',
            date_issued: '',
            validity_period: '',
            file: null,
            file_label: '',
          },
        ],
        certificate_of_proficiency: [
          {
            document_name: '',
            document_number: '',
            place_issued: '',
            date_issued: '',
            validity_period: '',
            file: null,
            file_label: '',
          },
        ],
        sail_experience: [
          {
            position_id: null,
            owner: '',
            ship_name: '',
            flag: '',
            area: '',
            no_number_company: 0,
            date_join: '',
            date_end: '',
          },
        ],
        pekerjaan: [
          {
            nama_kapal: 'TB. Bina Marine 65',
            perusahaan: 'Energy Trasnporter Indonesia',
            negara: 'Indonesia',
            lokasi: 'ADM. JAKARTA SELATAN',
            jabatan: 'Deck',
            no_tlp_perusahaan: 628612313121,
            tahun_masuk: '2019-01-01',
            tahun_berhenti: '2020-01-01',
          },
        ],
        organisasi: [
          {
            nama_organisasi: null,
            posisi: '',
            tempat: '',
            durasi_awal: '',
            durasi_akhir: '',
            deskripsi: '',
          },
        ],
      },
    }
  },
  watch: {
    'user._checked': {
      handler(value) {
        console.log(this.user.domicile_address, 'DOMISILI')
        console.log(this.user.ktp_address, 'KTP')
        if (value) {
          this.user.domicile_address.provinsi = this.user.ktp_address.provinsi
          this.user.domicile_address.kabupaten = this.user.ktp_address.kabupaten
          this.user.domicile_address.kecamatan = this.user.ktp_address.kecamatan
          this.user.domicile_address.kelurahan = this.user.ktp_address.kelurahan
          this.user.domicile_address.kode_pos = this.user.ktp_address.kode_pos
          this.user.domicile_address.alamat = this.user.ktp_address.alamat
        } else {
          this.user.domicile_address.provinsi = null
          this.user.domicile_address.kabupaten = null
          this.user.domicile_address.kecamatan = null
          this.user.domicile_address.kelurahan = null
          this.user.domicile_address.kode_pos = 0
          this.user.domicile_address.alamat = ''
        }
      },
    },
  },
  components: {
    FormInputAddress,
  },
  mounted() {
    this.fetchKapal()
  },
  methods: {
    ...crewingActions,
    previewImage: function (event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        this.user.photo_file = input.files[0]
        reader.onload = (e) => {
          this.user.photo = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    prefiewFiles: function (event, state) {
      const input = event.target
      if (input.files && input.files[0]) {
        state.file_label = input.files[0].name
        state.file = input.files[0]
      }
    },
    // Pendidikan Adding and Removing Forms
    addPendidikanForm() {
      this.user.pendidikan.push({
        sekolah: '',
        jenjang: null,
        jurusan: '',
        nilai: 0,
        tahun_mulai: '',
        tahun_selesai: '',
        kota: '',
        negara: '',
      })
    },
    deletePendidikanForm(index) {
      this.user.pendidikan.splice(index, 1)
    },
    // ----------------------------------
    // Pendidikan Adding and Removing Forms
    addFamilyForm() {
      this.user.family.push({
        status: null,
        nama_lengkap: '',
        jenis_kelamin: null,
        age_family: 0,
        pekerjaan: '',
        no_hp: 0,
      })
    },
    deleteFamilyForm(index) {
      this.user.family.splice(index, 1)
    },
    // ----------------------------------
    // Pendidikan Adding and Removing Forms
    addTravelDocumentForm() {
      this.user.travel_document.push({
        document_name: '',
        document_number: '',
        place_issued: '',
        date_issued: '',
        validity_period: '',
        file: null,
        file_label: '',
        category_document: null,
      })
    },
    deleteTravelDocument(index) {
      this.user.travel_document.splice(index, 1)
    },
    // ----------------------------------
    // CertificateCompetency Adding and Removing Forms
    addCertificateOfCompetencyForm() {
      this.user.certificate_of_competency.push({
        document_name: '',
        document_number: '',
        place_issued: '',
        date_issued: '',
        validity_period: '',
        file: null,
        file_label: '',
      })
    },
    deleteCertificateOfCompetencyForm(index) {
      this.user.certificate_of_competency.splice(index, 1)
    },
    // ----------------------------------
    // CertificateCompetency Adding and Removing Forms
    addCertificateOfProfiencyForm() {
      this.user.certificate_of_proficiency.push({
        document_name: '',
        document_number: '',
        place_issued: '',
        date_issued: '',
        validity_period: '',
        file: null,
        file_label: '',
      })
    },
    deleteCertificateOfProfiencyForm(index) {
      this.user.certificate_of_proficiency.splice(index, 1)
    },
    // ----------------------------------
    // Sail Experience Adding and Removing Forms
    addSailExperienceForm() {
      this.user.sail_experience.push({
        position_id: null,
        owner: '',
        ship_name: '',
        flag: '',
        area: '',
        no_number_company: 0,
        date_join: '',
        date_end: '',
      })
    },
    deleteSailExperienceForm(index) {
      this.user.sail_experience.splice(index, 1)
    },
    // Organisasi Adding and Removing Forms
    addOrganisasiForm() {
      this.user.organisasi.push({
        nama_organisasi: null,
        posisi: '',
        tempat: '',
        durasi_awal: '',
        durasi_akhir: '',
        deskripsi: '',
      })
    },
    deleteOrganisasiForm(index) {
      this.user.organisasi.splice(index, 1)
    },
    async fetchKapal() {
      this.loadingKapal = true
      let params = {
        page: 1,
        active: true,
        perPage: 100,
      }
      let res = await this.fetchVehicles(params)
      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.map((state) => {
            this.optionsVehicles.push({
              value: state.id,
              text: state.name,
            })
          })
          this._loading = false
        }
      }
      this.loadingKapal = false
    },
    async onSave(e) {
      e.preventDefault()
      this.user._saving = true
      let method = 'createCrew'
      console.log(this.$data.user, 'THIS IS DATA')
      let formData = new FormData()
      formData.append('full_name', this.user.full_name)
      formData.append('nik_ktp', this.user.nik_ktp)
      formData.append('nick_name', this.user.nick_name)
      formData.append('citizenship', this.user.citizenship)
      formData.append('seaferer_code', this.user.seaferer_code)
      formData.append('npwp_number', this.user.npwp_number)
      formData.append('position_id', this.user.position_id)
      formData.append('vehicle_id', this.user.vehicle_id)
      formData.append('place_of_birth', this.user.place_of_birth)
      formData.append('date_of_birth', this.user.date_of_birth)
      formData.append('golongan_darah_id', this.user.golongan_darah_id)
      formData.append('religion_id', this.user.religion_id)
      formData.append('marital_status_id', this.user.marital_status_id)
      formData.append('number_of_children', this.user.number_of_children)
      formData.append('hp_number', this.user.hp_number)
      formData.append('email', this.user.email)
      formData.append('address', this.user.address)
      formData.append('photo', this.user.photo_file)
      formData.append(
        'emergency_number',
        this.user.family_emergency_call.emergency_number
      )
      formData.append(
        'family_emergency_name',
        this.user.family_emergency_call.family_emergency_name
      )
      formData.append(
        'family_emergency_address',
        this.user.family_emergency_call.family_emergency_address
      )
      formData.append('height', this.user.height)
      formData.append('weight', this.user.weight)
      formData.append('wearpack', this.user.wearpack)
      formData.append('size_of_shoes', this.user.size_of_shoes)
      formData.append('bank_id', this.user.bank_id)
      formData.append('rekening_name', this.user.rekening_name)
      formData.append('rekening_number', this.user.rekening_number)
      formData.append('provinsi_ktp', this.user.ktp_address.provinsi)
      formData.append('kabupaten_ktp', this.user.ktp_address.kabupaten)
      formData.append('kecamatan_ktp', this.user.ktp_address.kecamatan)
      formData.append('kelurahan_ktp', this.user.ktp_address.kelurahan)
      formData.append('kode_pos_ktp', this.user.ktp_address.kode_pos)
      formData.append('alamat_ktp', this.user.ktp_address.alamat)
      formData.append('provinsi_domisili', this.user.domicile_address.provinsi)
      formData.append(
        'kabupaten_domisili',
        this.user.domicile_address.kabupaten
      )
      formData.append(
        'kecamatan_domisili',
        this.user.domicile_address.kecamatan
      )
      formData.append(
        'kelurahan_domisili',
        this.user.domicile_address.kelurahan
      )
      formData.append('kode_pos_domisili', this.user.domicile_address.kode_pos)
      formData.append('alamat_domisili', this.user.domicile_address.alamat)
      this.user.pendidikan.forEach((item, index) => {
        for (const property in item) {
          formData.append(`pendidikan[${index}][${property}]`, item[property])
        }
      })
      this.user.family.forEach((item, index) => {
        for (const property in item) {
          formData.append(`family[${index}][${property}]`, item[property])
        }
      })
      this.user.travel_document.forEach((item, index) => {
        for (const property in item) {
          if (property == 'file_label') return
          formData.append(
            `travel_document[${index}][${property}]`,
            item[property]
          )
        }
      })
      this.user.certificate_of_competency.forEach((item, index) => {
        for (const property in item) {
          if (property == 'file_label') return
          formData.append(
            `certificate_of_competency[${index}][${property}]`,
            item[property]
          )
        }
      })
      this.user.certificate_of_proficiency.forEach((item, index) => {
        for (const property in item) {
          if (property == 'file_label') return
          formData.append(
            `certificate_of_proficiency[${index}][${property}]`,
            item[property]
          )
        }
      })
      this.user.sail_experience.forEach((item, index) => {
        for (const property in item) {
          formData.append(
            `sail_experience[${index}][${property}]`,
            item[property]
          )
        }
      })
      this.user.pekerjaan.forEach((item, index) => {
        for (const property in item) {
          formData.append(`pekerjaan[${index}][${property}]`, item[property])
        }
      })
      this.user.organisasi.forEach((item, index) => {
        for (const property in item) {
          formData.append(`organisasi[${index}][${property}]`, item[property])
        }
      })
      const { status, data } = await this[method](formData)
      if (status !== 'success') {
        this.user._saving = false
        console.log(data)
        this.error.email = data?.email?.[0]
        this.error.seaferer_code = data?.seaferer_code?.[0]
        console.log(this.error)
        this.$swal(`Oops!`, `Email atau Seaferer code sudah ada.`, 'error')
        return
      }
      this.$swal(`Tersimpan`, 'Data Tersimpan', 'success')
      this.user._saving = false
      this.$router.push('/crewing/list-crew')
    },
  },
}
</script>
