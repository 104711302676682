var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Fontawesome Icon")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Use Class "), _c('code', [_vm._v("<i class=\"fa fa-address-book\"></i>")])]), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-address-book",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("address-book")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-envelope-open",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("envelope-open")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-id-card",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("id-card")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-telegram",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("telegram")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-user-circle",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("user-circle")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-area-chart",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("area-chart")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-asterisk",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("asterisk")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-car",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("car")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-bars",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("bars")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-battery-full",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("battery-full")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-bluetooth",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("bluetooth")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-book",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("book")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-bug",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("bug")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-building",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("building")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-calculator",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("calculator")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-calendar",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("calendar")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-camera",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("camera")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-commenting",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("commenting")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-crop",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("crop")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-download",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("download")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-folder",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("folder")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-gift",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("gift")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-users",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("users")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-hashtag",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("hashtag")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-home",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("home")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-lock",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("lock")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-graduation-cap",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("graduation-cap")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-paper-plane",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("paper-plane")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-star",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("star")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-tag",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("tag")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("trash")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-upload",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("upload")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-university",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("university")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-wifi",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("wifi")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-thumbs-up",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("thumbs-up")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-train",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("train")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-file",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("file")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-snapchat",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("snapchat")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-twitter",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("twitter")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-wordpress",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("wordpress")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-wordpress",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("wordpress")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "2",
            "sm": "2"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-wordpress",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v("wordpress")])], 1), _c('b-col', {
          staticClass: "text-center mt-3",
          attrs: {
            "sm": "12"
          }
        }, [_c('b-link', {
          staticClass: "btn btn-primary",
          attrs: {
            "href": "https://fontawesome.com/v4.7.0/",
            "target": "blank"
          }
        }, [_vm._v("View All Icon")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }