var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-primary rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "ri-user-fill"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("5600")])]), _c('h5', {}, [_vm._v("Doctors")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-warning rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "ri-women-fill"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("3450")])]), _c('h5', {}, [_vm._v("Nurses")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-danger rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-danger"
        }, [_c('i', {
          staticClass: "ri-group-fill"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("3500")])]), _c('h5', {}, [_vm._v("Patients")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-info rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-info"
        }, [_c('i', {
          staticClass: "ri-hospital-line"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("4500")])]), _c('h5', {}, [_vm._v("Pharmacists")])])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Hospital Survey")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-card-body pb-0 mt-3"
        }, [_c('div', {
          staticClass: "row text-center"
        }, [_c('div', {
          staticClass: "col-sm-3 col-6"
        }, [_c('h4', {
          staticClass: "margin-0"
        }, [_vm._v("$ 305 ")]), _c('p', {
          staticClass: "text-muted"
        }, [_vm._v(" Today's Income")])]), _c('div', {
          staticClass: "col-sm-3 col-6"
        }, [_c('h4', {
          staticClass: "margin-0"
        }, [_vm._v("$ 999 ")]), _c('p', {
          staticClass: "text-muted"
        }, [_vm._v("This Week's Income")])]), _c('div', {
          staticClass: "col-sm-3 col-6"
        }, [_c('h4', {
          staticClass: "margin-0"
        }, [_vm._v("$ 4999 ")]), _c('p', {
          staticClass: "text-muted"
        }, [_vm._v("This Month's Income")])]), _c('div', {
          staticClass: "col-sm-3 col-6"
        }, [_c('h4', {
          staticClass: "margin-0"
        }, [_vm._v("$ 90,000 ")]), _c('p', {
          staticClass: "text-muted"
        }, [_vm._v("This Year's Income")])])])]), _c('div', {
          attrs: {
            "id": "home-servey-chart"
          }
        }), _vm.$route.meta.dark ? _c('ApexChart', {
          attrs: {
            "element": "home-chart-09",
            "chartOption": _vm.homesurvey
          }
        }) : _c('ApexChart', {
          attrs: {
            "element": "home-chart-09",
            "chartOption": _vm.chart9
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Hospital Staff")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('Slick', {
          attrs: {
            "option": _vm.slickOptions,
            "id": "doster-list-slide"
          }
        }, _vm._l(_vm.doctorList, function (doctors) {
          return _c('div', {
            key: doctors.id,
            staticClass: "docter-list-item-inner rounded text-center"
          }, [_c('div', {
            staticClass: "donter-profile"
          }, [_c('img', {
            staticClass: "img-fluid rounded-circle",
            attrs: {
              "src": doctors.img,
              "alt": "user-img"
            }
          })]), _c('div', {
            staticClass: "doctor-detail mt-3"
          }, [_c('h5', [_vm._v(_vm._s(doctors.name))]), _c('h6', [_vm._v(_vm._s(doctors.position))])]), _c('hr'), _c('div', {
            staticClass: "doctor-description"
          }, [_c('p', {
            staticClass: "mb-0 text-center pl-2 pr-2"
          }, [_vm._v(_vm._s(doctors.worksAt))])])]);
        }), 0)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "8"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": ""
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Operations")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-card-header-toolbar d-flex align-items-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('i', {
                staticClass: "ri-more-fill"
              })];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-eye-fill mr-2"
        }), _vm._v("View")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Another action")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Something else here...")])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-borderless"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Patient")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Patient Name ")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Doctors Team")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Date Of Operation")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v(" Report")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Diseases")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_c('img', {
          staticClass: "rounded-circle img-fluid avatar-40",
          attrs: {
            "src": require("../../assets/images/user/01.jpg"),
            "alt": "profile"
          }
        })]), _c('td', [_vm._v("Petey Cruiser")]), _c('td', [_c('div', {
          staticClass: "iq-media-group"
        }, [_c('a', {
          staticClass: "iq-media",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "img-fluid avatar-40 rounded-circle",
          attrs: {
            "src": require("../../assets/images/user/05.jpg"),
            "alt": ""
          }
        })]), _c('a', {
          staticClass: "iq-media",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "img-fluid avatar-40 rounded-circle",
          attrs: {
            "src": require("../../assets/images/user/06.jpg"),
            "alt": ""
          }
        })]), _c('a', {
          staticClass: "iq-media",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "img-fluid avatar-40 rounded-circle",
          attrs: {
            "src": require("../../assets/images/user/07.jpg"),
            "alt": ""
          }
        })])])]), _c('td', [_vm._v("12-02-2020")]), _c('td', [_c('i', {
          staticClass: "ri-file-pdf-line font-size-16 text-danger"
        })]), _c('td', [_vm._v("Fracture")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_c('img', {
          staticClass: "rounded-circle img-fluid avatar-40",
          attrs: {
            "src": require("../../assets/images/user/02.jpg"),
            "alt": "profile"
          }
        })]), _c('td', [_vm._v("Anna Sthesia")]), _c('td', [_c('div', {
          staticClass: "iq-media-group"
        }, [_c('a', {
          staticClass: "iq-media",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "img-fluid avatar-40 rounded-circle",
          attrs: {
            "src": require("../../assets/images/user/05.jpg"),
            "alt": ""
          }
        })]), _c('a', {
          staticClass: "iq-media",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "img-fluid avatar-40 rounded-circle",
          attrs: {
            "src": require("../../assets/images/user/06.jpg"),
            "alt": ""
          }
        })]), _c('a', {
          staticClass: "iq-media",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "img-fluid avatar-40 rounded-circle",
          attrs: {
            "src": require("../../assets/images/user/07.jpg"),
            "alt": ""
          }
        })])])]), _c('td', [_vm._v("14-02-2020")]), _c('td', [_c('i', {
          staticClass: "ri-file-pdf-line font-size-16 text-danger"
        })]), _c('td', [_vm._v("Cataract surgery")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_c('img', {
          staticClass: "rounded-circle img-fluid avatar-40",
          attrs: {
            "src": require("../../assets/images/user/03.jpg"),
            "alt": "profile"
          }
        })]), _c('td', [_vm._v("Paul Molive")]), _c('td', [_c('div', {
          staticClass: "iq-media-group"
        }, [_c('a', {
          staticClass: "iq-media",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "img-fluid avatar-40 rounded-circle",
          attrs: {
            "src": require("../../assets/images/user/05.jpg"),
            "alt": ""
          }
        })]), _c('a', {
          staticClass: "iq-media",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "img-fluid avatar-40 rounded-circle",
          attrs: {
            "src": require("../../assets/images/user/06.jpg"),
            "alt": ""
          }
        })]), _c('a', {
          staticClass: "iq-media",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "img-fluid avatar-40 rounded-circle",
          attrs: {
            "src": require("../../assets/images/user/07.jpg"),
            "alt": ""
          }
        })])])]), _c('td', [_vm._v("14-02-2020")]), _c('td', [_c('i', {
          staticClass: "ri-file-pdf-line font-size-16 text-danger"
        })]), _c('td', [_vm._v("Cancer")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_c('img', {
          staticClass: "rounded-circle img-fluid avatar-40",
          attrs: {
            "src": require("../../assets/images/user/04.jpg"),
            "alt": "profile"
          }
        })]), _c('td', [_vm._v("Anna Mull")]), _c('td', [_c('div', {
          staticClass: "iq-media-group"
        }, [_c('a', {
          staticClass: "iq-media",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "img-fluid avatar-40 rounded-circle",
          attrs: {
            "src": require("../../assets/images/user/05.jpg"),
            "alt": ""
          }
        })]), _c('a', {
          staticClass: "iq-media",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "img-fluid avatar-40 rounded-circle",
          attrs: {
            "src": require("../../assets/images/user/06.jpg"),
            "alt": ""
          }
        })]), _c('a', {
          staticClass: "iq-media",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "img-fluid avatar-40 rounded-circle",
          attrs: {
            "src": require("../../assets/images/user/07.jpg"),
            "alt": ""
          }
        })])])]), _c('td', [_vm._v("16-02-2020")]), _c('td', [_c('i', {
          staticClass: "ri-file-pdf-line font-size-16 text-danger"
        })]), _c('td', [_vm._v("Hysterectomy")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_c('img', {
          staticClass: "rounded-circle img-fluid avatar-40",
          attrs: {
            "src": require("../../assets/images/user/05.jpg"),
            "alt": "profile"
          }
        })]), _c('td', [_vm._v("Ruby saul")]), _c('td', [_c('div', {
          staticClass: "iq-media-group"
        }, [_c('a', {
          staticClass: "iq-media",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "img-fluid avatar-40 rounded-circle",
          attrs: {
            "src": require("../../assets/images/user/05.jpg"),
            "alt": ""
          }
        })]), _c('a', {
          staticClass: "iq-media",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "img-fluid avatar-40 rounded-circle",
          attrs: {
            "src": require("../../assets/images/user/06.jpg"),
            "alt": ""
          }
        })]), _c('a', {
          staticClass: "iq-media",
          attrs: {
            "href": "#"
          }
        }, [_c('img', {
          staticClass: "img-fluid avatar-40 rounded-circle",
          attrs: {
            "src": require("../../assets/images/user/07.jpg"),
            "alt": ""
          }
        })])])]), _c('td', [_vm._v("18-02-2020")]), _c('td', [_c('i', {
          staticClass: "ri-file-pdf-line font-size-16 text-danger"
        })]), _c('td', [_vm._v("Cancer")])])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "6"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-info-box d-flex align-items-center p-3"
        }, [_c('div', {
          staticClass: "info-image mr-3"
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/page-img/30.png"),
            "alt": "image-box"
          }
        })]), _c('div', {
          staticClass: "info-text"
        }, [_c('h3', [_vm._v("120")]), _c('span', [_vm._v("Total Appointments")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-info-box d-flex align-items-center p-3"
        }, [_c('div', {
          staticClass: "info-image mr-3"
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/page-img/31.png"),
            "alt": "image-box"
          }
        })]), _c('div', {
          staticClass: "info-text"
        }, [_c('h3', [_vm._v("5000")]), _c('span', [_vm._v("Completed Appointments")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-info-box d-flex align-items-center p-3"
        }, [_c('div', {
          staticClass: "info-image mr-3"
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/page-img/32.png"),
            "alt": "image-box"
          }
        })]), _c('div', {
          staticClass: "info-text"
        }, [_c('h3', [_vm._v("1500")]), _c('span', [_vm._v("Cancelled Appointments")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-info-box d-flex align-items-center p-3"
        }, [_c('div', {
          staticClass: "info-image mr-3"
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/page-img/33.png"),
            "alt": "image-box"
          }
        })]), _c('div', {
          staticClass: "info-text"
        }, [_c('h3', [_vm._v("500")]), _c('span', [_vm._v("Followup Appointments")])])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Recent Activity")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('div', {
          staticClass: "dropdown"
        }, [_c('span', {
          staticClass: "dropdown-toggle text-primary",
          attrs: {
            "id": "dropdownMenuButton4",
            "data-toggle": "dropdown"
          }
        }, [_vm._v(" View All ")]), _c('div', {
          staticClass: "dropdown-menu dropdown-menu-right",
          attrs: {
            "aria-labelledby": "dropdownMenuButton4"
          }
        }, [_c('a', {
          staticClass: "dropdown-item",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-eye-fill mr-2"
        }), _vm._v("View")]), _c('a', {
          staticClass: "dropdown-item",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-delete-bin-6-fill mr-2"
        }), _vm._v("Delete")]), _c('a', {
          staticClass: "dropdown-item",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-pencil-fill mr-2"
        }), _vm._v("Edit")]), _c('a', {
          staticClass: "dropdown-item",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-printer-fill mr-2"
        }), _vm._v("Print")]), _c('a', {
          staticClass: "dropdown-item",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-file-download-fill mr-2"
        }), _vm._v("Download")])])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('ul', {
          staticClass: "iq-timeline"
        }, [_c('li', [_c('div', {
          staticClass: "timeline-dots-fill"
        }), _c('h6', {
          staticClass: "float-left mb-2 text-dark"
        }, [_c('i', {
          staticClass: "ri-user-fill"
        }), _vm._v(" 5 min ago")]), _c('small', {
          staticClass: "float-right mt-1"
        }, [_vm._v("Active")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque ")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots-fill bg-success"
        }), _c('h6', {
          staticClass: "float-left mb-2 text-dark"
        }, [_c('i', {
          staticClass: "ri-user-fill"
        }), _vm._v(" 6 min ago")]), _c('small', {
          staticClass: "float-right mt-1"
        }, [_vm._v("Away")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots-fill bg-info"
        }), _c('h6', {
          staticClass: "float-left mb-2 text-dark"
        }, [_c('i', {
          staticClass: "ri-user-fill"
        }), _vm._v(" 10 min ago")]), _c('small', {
          staticClass: "float-right mt-1"
        }, [_vm._v("Active")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots-fill bg-warning"
        }), _c('h6', {
          staticClass: "float-left mb-2 text-dark"
        }, [_c('i', {
          staticClass: "ri-user-fill"
        }), _vm._v(" 15 min ago")]), _c('small', {
          staticClass: "float-right mt-1"
        }, [_vm._v("Offline")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots-fill bg-danger"
        }), _c('h6', {
          staticClass: "float-left mb-2 text-dark"
        }, [_c('i', {
          staticClass: "ri-user-fill"
        }), _vm._v(" 18 min ago")]), _c('small', {
          staticClass: "float-right mt-1"
        }, [_vm._v("Away")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque")])])])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "4"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Total Accident Report")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "sm": "6"
          }
        }, [_c('h3', [_vm._v("$40K")])]), _c('ApexChart', {
          attrs: {
            "element": "home-wave-chart",
            "chartOption": _vm.chart7
          }
        }), _c('b-col', {
          staticClass: "row text-center mt-3"
        }, [_c('b-col', {
          attrs: {
            "sm": "6"
          }
        }, [_c('h6', {
          staticClass: "text-truncate d-block"
        }, [_vm._v("Last Month")]), _c('p', {
          staticClass: "m-0"
        }, [_vm._v("358")])]), _c('b-col', {
          attrs: {
            "sm": "6"
          }
        }, [_c('h6', {
          staticClass: "text-truncate d-block"
        }, [_vm._v("Current Month")]), _c('p', {
          staticClass: "m-0"
        }, [_vm._v("194")])])], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Total Death Report")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-sm-6"
        }, [_c('h3', [_vm._v("$20K")])])]), _c('ApexChart', {
          attrs: {
            "element": "home-wave-chart-8",
            "chartOption": _vm.chart8
          }
        }), _c('div', {
          staticClass: "row text-center mt-3"
        }, [_c('div', {
          staticClass: "col-sm-6"
        }, [_c('h6', {
          staticClass: "text-truncate d-block"
        }, [_vm._v("Last Month")]), _c('p', {
          staticClass: "m-0"
        }, [_vm._v("220")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('h6', {
          staticClass: "text-truncate d-block"
        }, [_vm._v("Current Month")]), _c('p', {
          staticClass: "m-0"
        }, [_vm._v("120")])])])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Overall Progress")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.$route.meta.dark ? _c('ApexChart', {
          attrs: {
            "element": "home-radial-chart",
            "chartOption": _vm.radialbar
          }
        }) : _c('ApexChart', {
          attrs: {
            "element": "home-radial-chart",
            "chartOption": _vm.chart10
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }