<template>
  <b-sidebar
    :id="`modal---${id}`"
    v-model="model"
    :title="modalTitle"
    width="100%"
    backdrop-variant="dark"
    no-close-on-backdrop
    no-close-on-esc
    bg-variant="white"
    right
    backdrop
    shadow>
    <template #default="{hide}">
      <div v-if="loading" class="text-center">
        Loading Content...
      </div>
      <b-row v-else class="m-3">
        <b-col cols="12" v-if="fleetDetail">
          <span class="mr-5"><h4>Fleet / Equipment : <b-badge variant="info">{{fleetDetail.name}}</b-badge></h4></span>
          <div class="table-responsive">
            <table class="table ">
              <tbody>
              <tr class="bg-primary">
                <td>
                  <ul class="text-left">
                    <li v-for="power in fleetDetail.powers">{{power.powerType ? power.powerType.name : '-'}} - {{power.powerSeries ? power.powerSeries.name : '-'}} {{power.powerSeries ? power.powerSeries.model : '-'}} <span class="pull-right">Actual RH : <b-badge variant="info"><strong>{{power.actualRunningHours}} RH</strong></b-badge></span></li>
                  </ul>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </b-col>
        <b-col cols="12">
          <fieldset class="form-group border p-3">
            <legend class="w-auto px-2">Select Docking Job</legend>
            <b-row>
              <b-col md="12" class="mb-3">
                <b-form-input id="job_name" v-model="jobKeyword" type="text" placeholder="Search Job Name"></b-form-input>
              </b-col>
              <b-col md="4">
                <v-jstree :data="filteredJobs" show-checkbox allow-batch whole-row multiple @item-click="itemClickInitComponent">
                  <template slot-scope="_">
                    <div style="display: inherit; width: 200px">
                      <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                      {{_.model.text}}
                    </div>
                  </template>
                </v-jstree>
              </b-col>
              <b-col md="8">
                <b-row>
                  <b-col md="12" class="mt-3">
                    <b-button v-if="['CREATE_EMERGENCY', 'CREATE_FROM_SCHEDULE', 'UPDATE', 'REVIEW', 'APPROVE'].includes(action)" @click="onAddJob" block variant="success" class="mt-3"><i class="fa fa-plus"></i> ADD DOCKING JOB</b-button>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <template v-if="selectedGroupOfJobs.length > 0 || !['CREATE_EMERGENCY', 'CREATE_FROM_SCHEDULE'].includes(action)">
                      <DockingScheduleForm
                        :action="action"
                        :docking-id="dockingId"
                        :vehicle-id="vehicleId"
                        :group-of-jobs="selectedGroupOfJobs"
                        @submit="onDockingActionSubmit"
                        @onFetch="onFetchDockingDetail"
                      />
                    </template>
                  </b-col>
                </b-row>

              </b-col>
            </b-row>
          </fieldset>
        </b-col>
      </b-row>
    </template>
  </b-sidebar>
</template>

<script>
import { dockingActions, fleetsActions } from '@src/Utils/helper'
import _ from 'lodash'
import DockingScheduleForm from '@components/Docking/DockingScheduleForm'

export default {
  name: 'ModalDocking',
  components: { DockingScheduleForm },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'docking'
    },
    vehicleId: {
      type: Number,
      default: null
    },
    action: {
      type: String,
      default: 'CREATE_EMERGENCY' // CREATE_EMERGENCY, CREATE_FROM_SCHEDULE, REVIEW, APPROVE, UPDATE, DETAIL
    },
    dockingId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      jobKeyword: '',
      loading: true,
      fleetDetail: null,
      groupOfJobs: [],
      selectedGroupOfJobs: []
    }
  },
  computed: {
    modalTitle () {
      if (this.action === 'CREATE_EMERGENCY') {
        return `Create Emergency Docking ${this.fleetDetail ? `-${this.fleetDetail.name}` : ''}`
      } else if (this.action === 'CREATE_FROM_SCHEDULE') {
        return `Create From Schedule Docking ${this.fleetDetail ? `-${this.fleetDetail.name}` : ''}`
      } else if (this.action === 'REVIEW') {
        return `Form Review Docking ${this.fleetDetail ? `-${this.fleetDetail.name}` : ''}`
      } else if (this.action === 'APPROVE') {
        return `Approve Docking ${this.fleetDetail ? `-${this.fleetDetail.name}` : ''}`
      } else if (this.action === 'UPDATE') {
        return `Update Docking ${this.fleetDetail ? `-${this.fleetDetail.name}` : ''}`
      } else if (this.action === 'DETAIL') {
        return `Detail Docking ${this.fleetDetail ? `-${this.fleetDetail.name}` : ''}`
      }

      return 'Create Docking'
    },
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    filteredJobs () {
      return this.groupOfJobs.filter(job => {
        return this.jobKeyword ? (
          job.text.includes(this.jobKeyword) ||
          job.children.filter(children => {
            return children.text.includes(this.jobKeyword)
          }).length > 0
        ) : true
      })
    }
  },
  watch: {
    model () {
      this.onResetStates()
      if (this.model === true) {
        if (this.vehicleId) {
          this.onInit()
        }
      }
    }
  },
  methods: {
    ...fleetsActions,
    ...dockingActions,

    onResetStates () {
      this.selectedGroupOfJobs = []
      this.jobKeyword = ''
      this.loading = false
      this.fleetDetail = null
    },
    async onInit () {
      this.loading = true
      await this.fetchFleetDetail()
      await this.fetchJobs()

      if (!this.fleetDetail) {
        this.$swal(
          `Oops!`,
          'Server sedang sibuk atau data docking tidak ada di database, silahkan muat ulang halaman',
          'error'
        )

        return
      }

      this.loading = false
    },
    async fetchFleetDetail () {
      const res = await this.getFleetById(this.vehicleId)

      if (res.status === 'success') {
        this.fleetDetail = res.data
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    itemClickInitComponent (node) {
    },
    async fetchJobs () {
      this.groupOfJobs = []
      const res = await this.getJobs({
        page: 1,
        perPage: 999999999,
        isActive: true
      })

      if (res.status === 'success' && res.data) {
        let rawData = res.data.data

        if (rawData && this.fleetDetail) {
          const groupedData = _.groupBy(rawData.filter(raw => raw.jobCategory.vehicleType.id === this.fleetDetail.vehicleType.id), (iterate) => {
            return iterate.jobCategory.id
          })

          if (groupedData) {
            Object.keys(groupedData).map(group => {
              if (groupedData[group][0]) {
                this.groupOfJobs.push({
                  'id': groupedData[group][0].jobCategory.id,
                  'text': groupedData[group][0].jobCategory.name,
                  'opened': true,
                  'selected': false,
                  'children': groupedData[group].map(groupChild => {
                    return {
                      'id': groupChild.id,
                      'text': groupChild.name,
                      'selected': false
                    }
                  })
                })
              }
            })
          }
        }
      }
    },
    onAddJob () {
      const self = this
      function attach () {
        self.selectedGroupOfJobs = self.groupOfJobs.filter(group => group.children.filter(children => children.selected).length > 0).map(group => {
          return {
            id: group.id,
            name: group.text,
            jobs: group.children.filter(children => children.selected).map(children => {
              return {
                id: children.id,
                name: children.text
              }
            })
          }
        })
      }
      this.selectedGroupOfJobs = []
      attach()
    },
    onDockingActionSubmit () {
      this.$emit('submit')
    },
    onFetchDockingDetail (data) {
      const categoryIds = data.map(d => d.dockingCategoryId)
      const jobIds = data.map(d => d.dockingTasks.map(t => t.dockingJobId)).flat()

      this.groupOfJobs = this.groupOfJobs.map(group => {
        if (categoryIds.includes(group.id)) {
          group.selected = true
        }

        group.children = group.children.map(job => {
          if (jobIds.includes(job.id)) {
            job.selected = true
          }

          return job
        })

        return group
      })
    },
    onDockingActionCancel () {

    }
  }
}
</script>

<style scoped>

</style>
