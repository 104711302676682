<template>
  <b-container fluid v-if="detailMi">
    <StepNavigation current-step="MANAGEMENT_SUMMARY" />
    <b-row>
<!--      <h4 class="card-title text-primary"
        ><strong>Summary Management for Claim</strong></h4
      >-->
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"
              ><strong>Summary Management for Claim</strong></h4
            >
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="1"></b-col>
              <b-col md="10">
                <b-row>
                  <b-col md="12" class="mb-4">
                    <img
                      src="@assets/images/summary-for-management.png"
                      width="100%"
                    />
                  </b-col>
                  <!-- FORM HERE -->
                  <form @submit.prevent="onSaveHeader">
                    <b-row class="mb-3">
                      <b-col md="12" class="text-right">
                        <b-button type="submit" variant="info" size="sm"
                          ><i class="ion-refresh"></i>Save</b-button
                        >
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" md="6" class="mb-3">
                        <h5 class="card-title text-success"
                          ><b>INFORMATION OF ACCIDENT/INCIDENT</b></h5
                        >
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label="Company *"
                          label-for="company"
                        >
                          <h4>{{ detailMi.company.company }}</h4>
                        </b-form-group>
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label="Fleet Name *"
                          label-for="fleets"
                        >
                          <h4>{{ detailMi.vehicle.name }}</h4>
                        </b-form-group>
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label="Date of Loss (DOL) *"
                          label-for="dol"
                        >
                          {{ detailMi.date_of_loss }}
                        </b-form-group>
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label="Place of Loss (POL) *"
                          label-for="pol"
                        >
                          {{ detailMi.place_of_loss }}
                        </b-form-group>
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label="Type of Coverage *"
                          label-for="coverage"
                        >
                          {{ detailMi.type_coverage.name }}
                        </b-form-group>
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label="Type of Loss *"
                          label-for="loss"
                        >
                          {{ detailMi.type_loss.name }}
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="6" class="mb-3">
                        <h5 class="card-title text-success"
                          ><b>COVERAGE INFORMATION OF THE VESSEL</b></h5
                        >
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label="Insurer"
                          label-for="insurer"
                        >
                          <template v-if="claimProposedInputMode">
                            <p class="form-control-static">{{
                              /*form.insurer_id
                                ? detailMi.insurers.find(
                                    (insurer) =>
                                      insurer.code === form.insurer_id
                                  ).insurer_club.name
                                : '-'*/
                              form.insurer_id || '-'
                            }}</p>
                          </template>
                          <!-- <v-select
                            v-else
                            v-model="form.insurer_id"
                            :get-option-label="
                              (option) => option.insurer_club.name
                            "
                            :options="detailMi.insurers"
                            :reduce="(task) => task.code"
                          >
                          </v-select>-->
                          <b-form-input
                            v-else
                            required
                            v-model="form.insurer_id"
                            type="text"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label="Policy Number"
                          label-for="name"
                        >
                          <template v-if="claimProposedInputMode">
                            <p class="form-control-static">{{
                              form.policy_number || '-'
                            }}</p>
                          </template>
                          <b-form-input
                            v-else
                            required
                            v-model="form.policy_number"
                            type="text"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label="Periode of Coverage"
                          label-for="position"
                        >
                          <p class="form-control-static">{{
                              form.coverage_period.join(' ') || '-'
                            }}</p>
                        </b-form-group>
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label="Surveyor"
                          label-for="insurer"
                        >
                          <template v-if="claimProposedInputMode">
                            <p class="form-control-static">{{
                              form.surveyor || '-'
                            }}</p>
                          </template>
                          <b-form-input
                            v-else
                            type="text"
                            required
                            v-model="form.surveyor"
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                          class="row"
                          label-cols-sm="3"
                          label="Adjuster"
                          label-for="insurer"
                        >
                          <template v-if="claimProposedInputMode">
                            <p class="form-control-static">{{
                              form.adjuster || '-'
                            }}</p>
                          </template>
                          <b-form-input
                            v-else
                            type="text"
                            required
                            v-model="form.adjuster"
                          ></b-form-input>
                        </b-form-group>
                        <div class="mt-4">Get From TSI Data</div>
                        <hr />
                        <b-form-group>
                          <b-row>
                            <label class="col-md-3">Total Sum Insured</label>
                            {{ form.sum_insured }}
                          </b-row>
                        </b-form-group>
                        <b-form-group>
                          <label>Deductible</label>
                          <b-row>
                            <b-col md="4">
                              <v-select
                                v-model="form.currency_deductible_id"
                                label="alias"
                                style="width: 100%"
                                :options="currencies"
                                :reduce="(currency) => currency.id"
                              >
                              </v-select>
                            </b-col>
                            <b-col md="4">
                              <b-form-input
                                type="number"
                                size="sm"
                                required
                                v-model="form.deductible"
                                placeholder="Input Currency"
                              ></b-form-input>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="12" class="mb-4">
                        <h5 class="card-title text-success"
                          ><b>CLAIM PROPOSED:</b></h5
                        >
                        <table class="table">
                          <thead class="thead-dark">
                            <tr>
                              <th scope="col">Actual or Riil Cost</th>
                              <th scope="col">Proposed Cost to Insurer</th>
                              <th scope="col"
                                >Estimate cost Approved by Insurer
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <template v-if="claimProposedInputMode">
                                  <p class="form-control-static"
                                    >{{
                                      form.currency_actual_id
                                        ? currencies.find(
                                            (currency) =>
                                              currency.id ===
                                              form.currency_actual_id
                                          ).alias
                                        : '-'
                                    }}
                                    {{ form.claim_proposed_actual || '-' }}</p
                                  >
                                </template>
                                <b-row v-else>
                                  <b-col md="3">
                                    <v-select
                                      v-model="form.currency_actual_id"
                                      label="alias"
                                      style="width: 100%"
                                      :options="currencies"
                                      :reduce="(currency) => currency.id"
                                    >
                                    </v-select>
                                  </b-col>
                                  <b-col md="7">
                                    <b-form-group>
                                      <b-form-input
                                        type="number"
                                        required
                                        v-model="form.claim_proposed_actual"
                                        placeholder="Input Currency"
                                      ></b-form-input>
                                    </b-form-group>
                                  </b-col>
                                </b-row>
                              </td>
                              <td>
                                <template v-if="claimProposedInputMode">
                                  <p class="form-control-static"
                                    >{{
                                      form.currency_insurer_id
                                        ? currencies.find(
                                            (currency) =>
                                              currency.id ===
                                              form.currency_insurer_id
                                          ).alias
                                        : '-'
                                    }}
                                    {{ form.claim_proposed_insurer || '-' }}</p
                                  >
                                </template>
                                <b-row v-else>
                                  <b-col md="3">
                                    <v-select
                                      v-model="form.currency_insurer_id"
                                      label="alias"
                                      style="width: 100%"
                                      :options="currencies"
                                      :reduce="(currency) => currency.id"
                                    >
                                    </v-select>
                                  </b-col>
                                  <b-col md="7">
                                    <b-form-group>
                                      <b-form-input
                                        type="number"
                                        required
                                        v-model="form.claim_proposed_insurer"
                                        placeholder="Input Currency"
                                      ></b-form-input>
                                    </b-form-group>
                                  </b-col>
                                </b-row>
                              </td>
                              <td>
                                <template v-if="claimProposedInputMode">
                                  <p class="form-control-static"
                                    >{{
                                      form.currency_approved_id
                                        ? currencies.find(
                                            (currency) =>
                                              currency.id ===
                                              form.currency_approved_id
                                          ).alias
                                        : '-'
                                    }}
                                    {{ form.claim_proposed_approved || '-' }}</p
                                  >
                                </template>
                                <b-row v-else>
                                  <b-col md="3">
                                    <v-select
                                      v-model="form.currency_approved_id"
                                      label="alias"
                                      style="width: 100%"
                                      :options="currencies"
                                      :reduce="(currency) => currency.id"
                                    >
                                    </v-select>
                                  </b-col>
                                  <b-col md="7">
                                    <b-form-group>
                                      <b-form-input
                                        type="number"
                                        required
                                        v-model="form.claim_proposed_approved"
                                        placeholder="Input Currency"
                                      ></b-form-input>
                                    </b-form-group>
                                  </b-col>
                                </b-row>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-col>
                      <b-col cols="12" md="12" class="mb-4">
                        <h5
                          class="card-title text-success d-flex justify-content-between"
                        >
                          <b>{{
                            claimProposedInputMode
                              ? 'ACTUAL CLAIM PROGRESS'
                              : 'RECORD CLAIM'
                          }}</b>
                          <b-button
                            variant="info"
                            v-if="
                              !claimProposedInputMode && role !== 'MANAGEMENT'
                            "
                            class="ml-3"
                            size="sm"
                            @click="claimProposedInputMode = true"
                            ><i class="ion-plus"></i>New Record</b-button
                          >
                        </h5>
                        <div class="table-responsive">
                          <table class="table" v-if="!claimProposedInputMode">
                            <thead class="thead-dark">
                              <tr>
                                <th scope="col" colspan="2">Update</th>
                                <th
                                  scope="col"
                                  rowspan="2"
                                  style="vertical-align: middle"
                                  >Update Claim</th
                                >
                                <th
                                  scope="col"
                                  rowspan="2"
                                  style="vertical-align: middle"
                                  >Barriers</th
                                >
                                <th
                                  scope="col"
                                  rowspan="2"
                                  style="vertical-align: middle"
                                  >Est. Settle Date</th
                                >
                                <th scope="col" colspan="2">Management</th>
                                <th scope="col" rowspan="2">Action</th>
                              </tr>
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">By User</th>
                                <th scope="col">Comment</th>
                                <th scope="col">User & Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              <!-- Content -->
                              <tr
                                v-for="(data, index) in claimPurposed"
                                :key="index"
                              >
                                <td>
                                  {{
                                    data.date
                                      ? moment(data.date).format('DD MMM YYYY')
                                      : '-'
                                  }}
                                </td>
                                <td>{{ data.creator_user || '-' }}</td>
                                <td>{{ data.update_claim || '-' }}</td>
                                <td>{{ data.barriers || '-' }}</td>
                                <td>
                                  {{
                                    data.date
                                      ? moment(data.date).format('DD MMM YYYY')
                                      : '-'
                                  }}
                                  <p>{{ data.follow_up_response || '-' }}</p>
                                </td>
                                <td>{{ data.management_comment || '-' }}</td>
                                <td>{{ data.management_user || '-' }}
                                <p>{{ data.management_date || '-' }}</p></td>
                                <td>
                                  <b-button
                                    variant="success"
                                    size="sm"
                                    class="mr-2"
                                    @click="onPopulateFormClaimPurposed(data)">
                                    <i class="fa fa-comment"></i>
                                  </b-button>
                                  <b-button
                                    variant="danger"
                                    v-if="role == 'SUPER ADMIN' || 'Super Admin' || 'Marine Insurance' || 'MARINE INSURANCE'"
                                    size="sm"
                                    @click="onDeleteClaimRecord(data.id)"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </b-button>
                                </td>
                              </tr>
                              <!-- End Content -->
                            </tbody>
                          </table>
                          <template v-else>
                            <table class="table">
                              <thead class="thead-dark">
                                <tr>
                                  <th scope="col">Update Claim</th>
                                  <th scope="col">Barries</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Estimate settled date </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <p
                                      class="form-control-static"
                                      v-if="role === 'MANAGEMENT'"
                                      >{{ formChild.update_claim || '-' }}</p
                                    >
                                    <b-form-textarea
                                      v-else
                                      v-model="formChild.update_claim"
                                      rows="5"
                                    ></b-form-textarea>
                                  </td>
                                  <td>
                                    <p
                                      class="form-control-static"
                                      v-if="role === 'MANAGEMENT'"
                                      >{{ formChild.barriers || '-' }}</p
                                    >
                                    <b-form-textarea
                                      v-else
                                      v-model="formChild.barriers"
                                      rows="5"
                                    ></b-form-textarea>
                                  </td>
                                  <td>
                                    <p
                                      class="form-control-static"
                                      v-if="role === 'MANAGEMENT'"
                                      >{{ formChild.follow_up_response || '-' }}</p
                                    >
                                    <b-form-select v-else v-model="formChild.follow_up_response" size="sm">
                                        <b-form-select-option value="settled">Settled</b-form-select-option>
                                        <b-form-select-option value="on_progress">On Going</b-form-select-option>
                                        <b-form-select-option value="withdrawal ">Withdrawal</b-form-select-option>
                                    </b-form-select>
                                  </td>
                                  <td>
                                    <p
                                      class="form-control-static"
                                      v-if="role === 'MANAGEMENT'"
                                      >{{ formChild.date || '-' }}</p
                                    >
                                    <date-picker
                                      v-else
                                      v-model="formChild.date"
                                      type="date"
                                      value-type="YYYY-MM-DD"
                                      placeholder="Select date"
                                    ></date-picker>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </template>
                        </div>
                      </b-col>
                      <b-col cols="12" md="12" class="mb-4">
                        <div class="table-responsive">
                          <table class="table">
                            <thead class="bg-warning">
                              <tr>
                                <th scope="col">FINAL ADJUSTMENT</th>
                                <th scope="col">DEDUCTIBLE</th>
                                <th scope="col">NET CLAIM LOST</th>
                                <th scope="col">TOTAL CLAIM DAYS</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <template v-if="claimProposedInputMode">
                                    <p class="form-control-static"
                                      >{{
                                        form.currency_final_adjustment_id
                                          ? currencies.find(
                                              (currency) =>
                                                currency.id ===
                                                form.currency_final_adjustment_id
                                            ).alias
                                          : '-'
                                      }}
                                      {{ form.final_adjusment || '-' }}</p>
                                  </template>
                                  <b-row v-else>
                                    <b-col md="5">
                                      <v-select
                                        v-model="
                                          form.currency_final_adjustment_id
                                        "
                                        label="alias"
                                        style="width: 100%"
                                        :options="currencies"
                                        :reduce="(currency) => currency.id"
                                      >
                                      </v-select>
                                    </b-col>
                                    <b-col md="7">
                                      <b-form-input
                                        type="number"
                                        required
                                        v-model="form.final_adjusment"
                                        placeholder="Input Currency"
                                      ></b-form-input>
                                    </b-col>
                                  </b-row>
                                </td>
                                <td>
                                  <template v-if="claimProposedInputMode">
                                    <p class="form-control-static"
                                      >{{
                                        form.currency_deductible_id
                                          ? currencies.find(
                                              (currency) =>
                                                currency.id ===
                                                form.currency_deductible_id
                                            ).alias
                                          : '-'
                                      }}
                                      {{ form.deductible || '-' }}</p
                                    >
                                  </template>
                                  <b-row v-else>
                                    <b-col md="5">
                                      <v-select
                                        v-model="form.currency_deductible_id"
                                        label="alias"
                                        style="width: 100%"
                                        :options="currencies"
                                        :reduce="(currency) => currency.id"
                                      >
                                      </v-select>
                                    </b-col>
                                    <b-col md="7">
                                      <b-form-input
                                        type="number"
                                        required
                                        v-model="form.deductible"
                                        placeholder="Input Currency"
                                      ></b-form-input>
                                    </b-col>
                                  </b-row>
                                </td>
                                <td>
                                  <template v-if="claimProposedInputMode">
                                    <p class="form-control-static"
                                      >{{
                                        form.currency_net_claim_id
                                          ? currencies.find(
                                              (currency) =>
                                                currency.id ===
                                                form.currency_net_claim_id
                                            ).alias
                                          : '-'
                                      }}
                                      {{ form.net_claim_cost || '-' }}</p
                                    >
                                  </template>
                                  <b-row v-else>
                                    <b-col md="5">
                                      <v-select
                                        v-model="form.currency_net_claim_id"
                                        label="alias"
                                        style="width: 100%"
                                        :options="currencies"
                                        :reduce="(currency) => currency.id"
                                      >
                                      </v-select>
                                    </b-col>
                                    <b-col md="7">
                                      <b-form-input
                                        type="number"
                                        required
                                        v-model="form.net_claim_cost"
                                        placeholder="Input Currency"
                                      ></b-form-input>
                                    </b-col>
                                  </b-row>
                                </td>
                                <td>
                                  <p class="form-control-static">{{
                                    form.total_claim_days || '-'
                                  }}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-col>
                      <b-col
                        cols="12"
                        md="12"
                        class="mb-4"
                        v-if="claimProposedInputMode && role === 'MANAGEMENT'"
                      >
                        <table class="table">
                          <thead class="thead-dark">
                            <tr>
                              <th scope="col">Management Comment</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <b-form-textarea
                                  v-model="formChild.management_comment"
                                  rows="5"
                                ></b-form-textarea>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-col>
                      <b-col
                        md="6"
                        class="mt-4"
                        v-if="claimProposedInputMode && role === 'MANAGEMENT'"
                      >
                        <hr />
                        <span class="mr-5"><strong>User</strong></span
                        ><span class="">
                          <b-form-input
                            v-model="formChild.management_user"
                            rows="5"
                          ></b-form-input>
                        </span>
                      </b-col>
                      <b-col
                        md="6"
                        class="mt-4"
                        v-if="claimProposedInputMode && role === 'MANAGEMENT'"
                      >
                        <hr />
                        <span class="mr-5"><strong>Date</strong></span
                        ><span class="">
                          <date-picker
                            v-model="formChild.management_date"
                            style="width: 100%"
                            type="date"
                            value-type="YYYY-MM-DD"
                            placeholder="Select date range"
                          ></date-picker>
                        </span>
                      </b-col>
                    </b-row>
                    <b-row>
                      <!-- FORM HERE -->
                      <!--                      <b-col md="4" class="mt-4">-->
                      <!--                        <hr>-->
                      <!--                        <span class="mr-5"><strong>Status</strong></span><span class="ml-5"><select  ><option>Withdrawal</option><option>On Progress</option><option>Settled</option></select></span>-->
                      <!--                      </b-col>-->
                      <b-col
                        cols="12"
                        md="12"
                        class="pull-right text-center mt-5 mb-5"
                      >
                        <div v-if="claimProposedInputMode">
                          <b-button
                            @click="onSaveClaimPurposed"
                            :disabled="
                              formChild.date === '' ||
                              formChild.update_claim === '' ||
                              formChild.barriers === '' ||
                              formChild.follow_up_response === ''
                            "
                            variant="primary"
                            >Submit</b-button
                          >
                          <b-button
                            @click="claimProposedInputMode = false"
                            variant="outline"
                            >Cancel</b-button
                          >
                        </div>
                      </b-col>
                    </b-row>
                  </form>
                </b-row>
              </b-col>
              <b-col md="1"></b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import ComponentClaim from '@src/views/MI/component/ComponentClaim'
import { miActions } from '@src/Utils/helper'
import StepNavigation from '@src/views/MI/component/StepNavigation'
import moment from 'moment'

export default {
  name: 'BlankPage',
  components: { StepNavigation },
  mounted () {
    xray.index()

    if (this.$route.query.formId) {
      this.fetchMi()
    }
  },
  methods: {
    ...miActions,
    moment (date) {
      return moment(date)
    },
    async fetchMi () {
      const { data, status } = await this.fetchDetailMi({
        id: this.$route.query.formId,
        with: 'vehicle,type_coverage,type_loss,company,documents,company_claimant,status,creator,histories,summary'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(`Oops!`, 'Ada Yang Salah', 'error')
        return
      }

      this.detailMi = data
      this.fetchFleetPrices()
      this.fetchInsurers()
      this.fetchCurrencies()
      this.fetchFormSummary()
    },
    async fetchFleetPrices (page = 1) {
      const params = {
        date: this.detailMi.date_of_loss,
        type: 'company',
        values: [this.detailMi.vehicle.company_id],
        page
      }

      const { status, data } = await this.getFleetPriceList(params)

      if (status === 'success') {
        if (data && data.data) {
          const flatData = data.data.map((d) => d.types).flat()
          const getType = flatData.find(
            (type) => type.id === this.detailMi.vehicle.vehicle_type_id
          )
          if (getType) {
            const getPriceFleet = getType.data.find(
              (d) => d.vehicle_id === this.detailMi.vehicle.id
            )
            if (getPriceFleet) {
              this.priceFleet = getPriceFleet
              this.form.coverage_period = [getPriceFleet.from, getPriceFleet.to]
              this.form.deductible = getPriceFleet.deductible_value
              this.form.net_claim_cost = getPriceFleet.deductible_value
              this.form.sum_insured = getPriceFleet.value
            }
          }
        }
      }
    },
    async fetchInsurers () {
      this.insurers = []
      let res = await this.getInsurers({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach((el) => {
            this.insurers.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    async fetchCurrencies () {
      const { data } = await this.getCurrencies()
      this.currencies = data
    },
    async fetchFormSummary () {
      const { data } = await this.getFormSummary({
        id: this.$route.query.formId,
        // with: 'insurers',
      })

      if (data) {
        this.form.insurer_id = data.insurer_id
        this.form.policy_number = data.policy_number
        this.form.coverage_period = [this.detailMi.coverage_period, this.detailMi.coverage_period_to]
        this.form.surveyor = data.surveyor
        this.form.adjuster = data.adjuster
        this.form.currency_actual_id = data.currency_actual_id
        this.form.currency_net_claim_id = data.currency_net_claim_id
        this.form.currency_approved_id = data.currency_approved_id
        this.form.currency_deductible_id = data.currency_deductible_id
        this.form.currency_final_adjustment_id = data.currency_final_adjustment_id
        this.form.currency_insurer_id = data.currency_insurer_id
        this.form.claim_proposed_insurer = data.claim_proposed_insurer
        this.form.claim_proposed_actual = data.claim_proposed_actual
        this.form.claim_estimate_settled = data.claim_estimate_settled
        this.form.claim_progress_barriers = data.claim_progress_barriers
        this.form.claim_progress_update = data.claim_progress_update
        this.form.claim_proposed_approved = data.claim_proposed_approved
        this.form.final_adjusment = data.final_adjusment
        this.form.deductible = data.deductible
        this.form.net_claim_cost = data.net_claim_cost
        this.form.sum_insured = data.sum_insured

        this.claimPurposed = data.data

        if (this.claimPurposed[0]) {
          this.form.total_claim_days = moment().diff(
            moment(this.claimPurposed[0].date),
            'days'
          )
        }
      }
    },
    async onSaveHeader () {
      let payload = { ...this.form }

      delete payload.coverage_period

      this.form.saving = true

      const { data, status } = await this.createSummaryManagement({
        formId: this.$route.query.formId,
        ...payload
      })

      if (status === 422) {
        this.$swal(`Oops!`, 'Semua Field Wajib Diisi', 'error')
        this.form.saving = false
        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(`Oops!`, 'API Ada Yang Salah', 'error')
        this.form.saving = false
        return
      }

      this.$swal(`Tersimpan`, 'Data Tersimpan', 'success')

      setTimeout(() => {
        this.$router.push('/mi/list-initial')
      }, 1500)

      this.form.saving = false
    },
    async onSaveClaimPurposed () {
      let payload = {...this.formChild}
      payload.creator_user = this.lsProfile.userDetail.name

      if (!this.detailMi.summary) {
        this.$swal(`Oops!`, 'Data Summary Tidak Ada', 'error')
        return
      }

      if (payload.id === '') {
        delete payload.id
      }

      const { data, status } = await this.createSummaryManagementClaimPurposed({
        summaryId: this.detailMi.summary.id,
        ...payload
      })

      if (status === 422) {
        this.$swal(`Oops!`, 'Semua Field Wajib Diisi', 'error')
        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(`Oops!`, 'API Ada Yang Salah', 'error')
        return
      }
      await this.fetchFormSummary()
      this.claimProposedInputMode = false
    },
    onResetFormClaimPurposed () {
      this.formChild = {
        id: '',
        date: '',
        creator_user: '',
        update_claim: '',
        barriers: '',
        follow_up_response: '',
        management_comment: '',
        management_date: '',
        management_user: ''
      }
    },
    onPopulateFormClaimPurposed (data) {
      this.formChild = {
        id: data.id,
        date: data.date,
        creator_user: data.creator_user,
        update_claim: data.update_claim,
        barriers: data.barriers,
        follow_up_response: data.follow_up_response,
        management_comment: data.management_comment,
        management_date: data.management_date,
        management_user: data.management_user
      }

      this.claimProposedInputMode = true
    },
    async onDeleteClaimRecord (id) {
      this.$swal({
        title: 'Hapus Claim Record?',
        text: `Aksi tidak dapat diurungkan`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteSummaryManagementClaimPurposed({
            id
          })
          this.$swal(`Record Terhapus!`, ``, 'success')
          await this.fetchFormSummary()
          this.claimProposedInputMode = false
        }
      })
    }
  },
  computed: {
    id () {
      return this.$route.query.id
    },
    role () {
      return this.lsProfile ? this.lsProfile.role.roles : ''
    }
  },
  data () {
    return {
      claimProposedInputMode: false,
      detailMi: null,
      priceFleet: null,
      insurers: [],
      currencies: [],
      statementTruth: false,
      currency: null,
      currencyOptions: [
        { value: 'idr', text: 'IDR' },
        { value: 'usd', text: 'USD' },
        { value: 'sgd', text: 'SGD' }
      ],
      claimPurposed: null,
      form: {
        saving: false,
        id: '',
        claim_proposed_actual: 0,
        creator_date: '',
        claim_proposed_insurer: '',
        claim_proposed_approved: '',
        claim_progress_update: '',
        claim_progress_barriers: '',
        claim_estimate_settled: '',
        final_adjusment: '',
        deductible: '',
        net_claim_cost: '',
        total_claim_days: '',
        creator_fullname: '',
        approver_fullname: '',
        approver_date: '',
        currency_actual_id: 3,
        currency_insurer_id: 3,
        currency_approved_id: 3,
        currency_deductible_id: 3,
        currency_net_claim_id: 3,
        currency_final_adjustment_id: 3,
        est_settled_date: '',
        act_settled_date: '',
        insurer_id: '',
        policy_number: '',
        surveyor: '',
        adjuster: '',
        coverage_period: [],
        sum_insured: ''
      },
      formChild: {
        id: '',
        date: '',
        creator_user: '',
        update_claim: '',
        barriers: '',
        follow_up_response: '',
        management_comment: '',
        management_date: '',
        management_user: ''
      },
      lsProfile: JSON.parse(localStorage.getItem('profile'))
    }
  }
}
</script>
