var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('footer', {
    staticClass: "bg-white iq-footer"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6"
  }, [_c('ul', {
    staticClass: "list-inline mb-0"
  }, [_vm._t("left")], 2)]), _c('div', {
    staticClass: "col-lg-6 text-right"
  }, [_vm._t("right")], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }