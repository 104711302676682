var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": true,
            "href": "#kanban",
            "title": "Kanban"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": false,
            "href": "#dragdrop",
            "title": "Drag & Drop"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "kanban"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('kanban-board', {
          attrs: {
            "stages": _vm.stages,
            "blocks": _vm.blocks
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "dragdrop"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col mx-2 px-2 py-3 bg-light border rounded"
        }, [_c('h6', [_vm._v("Idea")]), _c('draggable', {
          staticClass: "draggable-list",
          attrs: {
            "list": _vm.tasks.ideas,
            "group": "tasks"
          }
        }, _vm._l(_vm.tasks.ideas, function (idea, i) {
          return _c('div', {
            key: i
          }, [_c('div', {
            staticClass: "bg-white mt-3 p-2 shadow border rounded"
          }, [_c('p', [_vm._v(_vm._s(idea))])])]);
        }), 0)], 1), _c('div', {
          staticClass: "col mx-2 px-2 py-3 bg-light border rounded"
        }, [_c('h6', [_vm._v("Todo")]), _c('draggable', {
          staticClass: "draggable-list",
          attrs: {
            "list": _vm.tasks.todos,
            "group": "tasks"
          }
        }, _vm._l(_vm.tasks.todos, function (todo, i) {
          return _c('div', {
            key: i
          }, [_c('div', {
            staticClass: "bg-white mt-3 p-2 shadow border rounded"
          }, [_c('p', [_vm._v(_vm._s(todo))])])]);
        }), 0)], 1), _c('div', {
          staticClass: "col mx-2 px-2 py-3 bg-light border rounded"
        }, [_c('h6', [_vm._v("In Progress")]), _c('draggable', {
          staticClass: "draggable-list",
          attrs: {
            "list": _vm.tasks.inProgress,
            "group": "tasks"
          }
        }, _vm._l(_vm.tasks.inProgress, function (task, i) {
          return _c('div', {
            key: i
          }, [_c('div', {
            staticClass: "bg-white mt-3 p-2 shadow border rounded"
          }, [_c('p', [_vm._v(_vm._s(task))])])]);
        }), 0)], 1), _c('div', {
          staticClass: "col mx-2 px-2 py-3 bg-light border rounded"
        }, [_c('h6', [_vm._v("Ready to go")]), _c('draggable', {
          staticClass: "draggable-list",
          attrs: {
            "list": _vm.tasks.completed,
            "group": "tasks"
          }
        }, _vm._l(_vm.tasks.completed, function (task, i) {
          return _c('div', {
            key: i
          }, [_c('div', {
            staticClass: "bg-white mt-3 p-2 shadow border rounded"
          }, [_c('p', [_vm._v(_vm._s(task))])])]);
        }), 0)], 1)])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }