var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', _vm._l(_vm.doctors, function (doctor) {
    return _c('b-col', {
      key: doctor.message,
      attrs: {
        "sm": "12",
        "md": "3"
      }
    }, [_c('iq-card', {
      attrs: {
        "body-class": "text-center"
      },
      scopedSlots: _vm._u([{
        key: "body",
        fn: function fn() {
          return [_c('div', {
            staticClass: "doc-profile"
          }, [_c('img', {
            staticClass: "rounded-circle img-fluid avatar-80",
            attrs: {
              "src": doctor.image,
              "alt": "profile"
            }
          })]), _c('div', {
            staticClass: "iq-doc-info mt-3"
          }, [_c('h4', [_vm._v(" " + _vm._s(doctor.name))]), _c('p', {
            staticClass: "mb-0"
          }, [_vm._v(_vm._s(doctor.specialist))]), _c('a', {
            attrs: {
              "href": "javascript:void(0);"
            }
          }, [_vm._v(_vm._s(doctor.website))])]), _c('div', {
            staticClass: "iq-doc-description mt-2"
          }, [_c('p', {
            staticClass: "mb-0"
          }, [_vm._v(_vm._s(doctor.description))])]), _c('div', {
            staticClass: "iq-doc-social-info mt-3 mb-3"
          }, [_c('ul', {
            staticClass: "m-0 p-0 list-inline"
          }, [_c('li', [_c('a', {
            attrs: {
              "href": "#"
            }
          }, [_c('i', {
            staticClass: "ri-facebook-fill"
          })])]), _c('li', [_c('a', {
            attrs: {
              "href": "#"
            }
          }, [_c('i', {
            staticClass: "ri-twitter-fill"
          })])]), _c('li', [_c('a', {
            attrs: {
              "href": "#"
            }
          }, [_c('i', {
            staticClass: "ri-google-fill"
          })])])])]), _c('a', {
            staticClass: "btn btn-primary",
            attrs: {
              "href": "#"
            }
          }, [_vm._v("View Profile")])];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }