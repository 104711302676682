<template>
    <b-container fluid>
      <b-row>
        <b-col md="3">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-warning">
                <template v-slot:body >
                <div class="d-flex align-items-center justify-content-between">
                    <div class="rounded-circle iq-card-icon bg-warning"><i class="fas fa-file-invoice"></i></div>
                    <div class="text-right">
                    <router-link to="/templates/procurement-report-gl">
                        <h4 class="mb-0"><span class="counter">ALL JOURNAL</span></h4>
                    </router-link>
                    </div>
                </div>
                </template>
            </iq-card>
        </b-col>
        <b-col md="3">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-warning">
                <template v-slot:body >
                <div class="d-flex align-items-center justify-content-between">
                    <div class="rounded-circle iq-card-icon bg-warning"><i class="fas fa-file-invoice"></i></div>
                    <div class="text-right">
                    <h4 class="mb-0"><span class="counter">GENERAL LEDGER</span></h4>
                    </div>
                </div>
                </template>
            </iq-card>
        </b-col>
        <b-col md="3">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-warning">
                <template v-slot:body >
                <div class="d-flex align-items-center justify-content-between">
                    <div class="rounded-circle iq-card-icon bg-warning"><i class="fas fa-file-invoice"></i></div>
                    <div class="text-right">
                    <router-link to="/templates/procurement-report-arus-kas">
                        <h4 class="mb-0"><span class="counter">CASH FLOW</span></h4>
                    </router-link>
                    </div>
                </div>
                </template>
            </iq-card>
        </b-col>
        <b-col md="3">
            <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-warning">
                <template v-slot:body >
                <div class="d-flex align-items-center justify-content-between">
                    <div class="rounded-circle iq-card-icon bg-warning"><i class="fas fa-file-invoice"></i></div>
                    <div class="text-right">
                    <router-link to="/templates/procurement-report-laba-rugi">
                        <h4 class="mb-0"><span class="counter">PROFIT / LOSS</span></h4>
                    </router-link>
                    </div>
                </div>
                </template>
            </iq-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
                <h5 class="card-title text-primary"><strong>FILTER DATA</strong></h5>
            </template>
            <template v-slot:headerAction>
            <a class="text-primary float-right ml-3" v-b-toggle.filter role="button" style="cursor: pointer">
            <i class="fa fa-filter" />
            </a>
            </template>
            <template v-slot:body>
              <b-collapse visible id="filter" class="mb-4">
                <b-row>
                  <b-col md="3">
                      <b-form-group label="Fleet Category" label-for="fleet_category" style="heidght:35px">
                          <v-multiselect v-model="fleetCategorySelect" tag-placeholder="Category" placeholder="Fleet Category" label="text" track-by="value" :options="fleetCategory" :multiple="true" :taggable="true">
                          </v-multiselect>
                      </b-form-group>
                  </b-col>
                  <b-col md="3">
                      <b-form-group label="Fleet" label-for="fleet">
                        <b-form-select plain v-model="selectedFleet" :options="optionsFleet" size="lg">
                            <template v-slot:first>
                              <b-form-select-option :value="null">Select Fleet</b-form-select-option>
                            </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col md="3">
                      <b-form-group label="Ownership" label-for="ownership">
                          <b-form-select plain v-model="selectedOwnership" :options="optionsOwnership" size="lg">
                            <template v-slot:first>
                              <b-form-select-option :value="null">Select Ownership</b-form-select-option>
                            </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col md="3">
                      <b-form-group label="Company Name" label-for="companyName">
                          <b-form-select plain v-model="selectedCompany" :options="optionCompany" size="lg">
                            <template v-slot:first>
                              <b-form-select-option :value="null">Select Company</b-form-select-option>
                            </template>
                          </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col md="3">
                      <b-form-group label="Period" label-for="Period">
                          <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range"></date-picker>
                      </b-form-group>
                  </b-col>
                  <b-col md="3">
                  <b-form-group label="Filter" label-for="button_search">
                      <b-button variant="primary" class="mb-3 mr-1"><i class="fa fa-filter"></i> Filter Data</b-button>
                  </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="12">
            <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>SALES DASHBOARD</strong></h5>
                </template>
                <template v-slot:body>
                    <b-row>
                        <b-col md="4">
                            <HighChart :ref="salesChartCargo.type" :option="salesChartCargo.bodyData"/>
                        </b-col>
                        <b-col md="4">
                            <HighChart :ref="salesChartTrip.type" :option="salesChartTrip.bodyData"/>
                        </b-col>
                        <b-col md="4">
                            <HighChart :ref="salesChartReveneu.type" :option="salesChartReveneu.bodyData"/>
                        </b-col>
                        <b-col md="12">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th class="text-center">FLEET</th>
                                    <th class="text-center">CARGO</th>
                                    <th class="text-center">TRIP</th>
                                    <th class="text-center">AMOUNT</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td class="text-center">TB. 001</td>
                                    <td class="text-center">85.000 Tons</td>
                                    <td class="text-center">25 Trips</td>
                                    <td class="text-center">1.524..000.000</td>
                                </tr>
                                <tr>
                                    <td class="text-center">TB. 002</td>
                                    <td class="text-center">73.000 Tons</td>
                                    <td class="text-center">21 Trips</td>
                                    <td class="text-center">1.763.000.000</td>
                                </tr>
                                <tr>
                                    <td class="text-center">TB. 003</td>
                                    <td class="text-center">26.000 Tons</td>
                                    <td class="text-center">18 Trips</td>
                                    <td class="text-center">539.053.044</td>
                                </tr>
                                <tr>
                                    <td class="text-center">TB. 004</td>
                                    <td class="text-center">34.000 Tons</td>
                                    <td class="text-center">35 Trips</td>
                                    <td class="text-center">672.000.000</td>
                                </tr>
                                <tr>
                                    <td class="text-center">TB. 005</td>
                                    <td class="text-center">56.000 Tons</td>
                                    <td class="text-center">15 Trips</td>
                                    <td class="text-center">88.472.200</td>
                                </tr>
                                <tr>
                                    <th class="text-center">TOTAL</th>
                                    <th class="text-center">351.000 Tons</th>
                                    <th class="text-center">102 Trips</th>
                                    <th class="text-center">150.472.200.000</th>
                                </tr>
                                </tbody>
                            </table>
                            <div class="mt-3">
                                <b-pagination :value="1" :total-rows="50" align="center" />
                            </div>
                        </b-col>
                    </b-row>
                </template>
            </iq-card>

            <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>PURCHASE DASHBOARD</strong></h5>
                </template>
                <template v-slot:headerAction>
                <a class="text-primary float-right ml-3" v-b-toggle.filter role="button" style="cursor: pointer">
                <i class="fa fa-filter" />
                </a>
                </template>
                <template v-slot:body>
                    <b-row>
                        <b-col lg="12">
                            <HighChart :ref="purchaseChart.type" :option="purchaseChart.bodyData"/>
                        </b-col>
                        <b-col md="12" class="mt-5">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th class="text-center align-middle">FLEET</th>
                                    <th class="text-center align-middle">BUNGKER</th>
                                    <th class="text-center align-middle">HSE EQUIPMENT</th>
                                    <th class="text-center align-middle">RUNNING STORE</th>
                                    <th class="text-center align-middle">MAINTENANCE</th>
                                    <th class="text-center align-middle">SPAREPART</th>
                                    <th class="text-center align-middle">REPAIR</th>
                                    <th class="text-center align-middle">LUB OIL</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td class="text-center">TB. 001</td>
                                    <td class="text-center">1.541.612.785</td>
                                    <td class="text-center">11.875.000</td>
                                    <td class="text-center">1.200.000</td>
                                    <td class="text-center">1.355.000</td>
                                    <td class="text-center">6.900.000</td>
                                    <td class="text-center">10.990.000</td>
                                    <td class="text-center">5.432.000</td>
                                </tr>
                                <tr>
                                    <td class="text-center">TB. 002</td>
                                    <td class="text-center">516.000.000</td>
                                    <td class="text-center">1.885.000</td>
                                    <td class="text-center">13.000.000</td>
                                    <td class="text-center">180.500</td>
                                    <td class="text-center">4.917.200</td>
                                    <td class="text-center">14.094.200</td>
                                    <td class="text-center">13.000.000</td>
                                </tr>
                                <tr>
                                    <td class="text-center">TB. 003</td>
                                    <td class="text-center">707.777.600</td>
                                    <td class="text-center">6.602.500</td>
                                    <td class="text-center">1.202.700</td>
                                    <td class="text-center">617.500</td>
                                    <td class="text-center">11.942.853</td>
                                    <td class="text-center">60.320.250</td>
                                    <td class="text-center">8.260.000</td>
                                </tr>
                                <tr>
                                    <td class="text-center">TB. 004</td>
                                    <td class="text-center">88.472.200</td>
                                    <td class="text-center">14.725.000</td>
                                    <td class="text-center">2.898.000</td>
                                    <td class="text-center">180.500</td>
                                    <td class="text-center">53.614.650</td>
                                    <td class="text-center">14.402.000</td>
                                    <td class="text-center">532.000</td>
                                </tr>
                                <tr>
                                    <td class="text-center">TB. 005</td>
                                    <td class="text-center">88.472.200</td>
                                    <td class="text-center">14.725.000</td>
                                    <td class="text-center">2.898.000</td>
                                    <td class="text-center">180.500</td>
                                    <td class="text-center">53.614.650</td>
                                    <td class="text-center">14.402.000</td>
                                    <td class="text-center">532.000</td>
                                </tr>
                                <tr>
                                    <th class="text-center">ALL FLEET</th>
                                    <th class="text-center">36.837.810.000</th>
                                    <th class="text-center">771.014.725.000</th>
                                    <th class="text-center">212.000.898.000</th>
                                    <th class="text-center">180.029.000.500</th>
                                    <th class="text-center">102.053.614.650</th>
                                    <th class="text-center">14.000.402.000</th>
                                    <th class="text-center">532.000.000</th>
                                </tr>
                                </tbody>
                            </table>
                            <div class="mt-3">
                                <b-pagination :value="1" :total-rows="50" align="center" />
                            </div>
                        </b-col>
                    </b-row>
                </template>
            </iq-card>
        </b-col>
      </b-row>
    </b-container>
  </template>
  <script>
  import { xray } from '../../config/pluginInit'
  import HighCharts from 'highcharts'
  import More from 'highcharts/highcharts-more'
  More(HighCharts)
  export default {
    name: 'HighCharts',
    mounted () {
      xray.index()
    },
    data () {
      return {
        salesChartCargo: {
          title: 'Sales Report',
          bodyData: {
            chart: {
              renderTo: 'high-3d-chart',
              type: 'column',
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25
              }
            },
            title: {
              text: 'Cargo Report'
            },
            subtitle: {
              text: ''
            },
            plotOptions: {
              column: {
                depth: 25
              }
            },
            series: [{
              data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
              color: '#009999'
            }]
          }
        },
        salesChartTrip: {
          title: 'Trip Report',
          bodyData: {
            chart: {
              renderTo: 'high-3d-chart',
              type: 'column',
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25
              }
            },
            title: {
              text: 'Trip Report'
            },
            subtitle: {
              text: ''
            },
            plotOptions: {
              column: {
                depth: 25
              }
            },
            series: [{
              data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
              color: '#009999'
            }]
          }
        },
        salesChartReveneu: {
          title: 'Trip Report',
          bodyData: {
            chart: {
              renderTo: 'high-3d-chart',
              type: 'column',
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25
              }
            },
            title: {
              text: 'Reveneu Report'
            },
            subtitle: {
              text: ''
            },
            plotOptions: {
              column: {
                depth: 25
              }
            },
            series: [{
              data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4],
              color: '#009999'
            }]
          }
        },
        purchaseChart: {
            title: 'Pie Chart',
            bodyData: {
              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
              },
              colorAxis: {},
              title: {
                text: 'Purchase Report'
              },
              tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                  }
                }
              },
              series: [{
                name: 'Brands',
                colorByPoint: true,
                data: [
                    {
                    name: 'Bungker',
                    y: 11.84,
                    color: '#00ca00'
                    }, {
                    name: 'HSE Equipment',
                    y: 10.85,
                    color: '#e64141'
                    }, {
                    name: 'Running Store',
                    y: 4.67,
                    color: '#ffd400'
                    }, {
                    name: 'Maintenance',
                    y: 4.18,
                    color: '#00d0ff'
                    }, {
                    name: 'Sparepart',
                    y: 1.64,
                    color: '#374948'
                    }, {
                    name: 'Repair',
                    y: 1.6,
                    color: '#E9EDF4'
                    }, {
                    name: 'Lube Oil',
                    y: 1.2,
                    color: '#777D74'
                    }
                ]
              }]
            }
        },
        fleetCategorySelect:[],
        fleetCategory: [
            {
                text: 'Tug Boat',
                color: 'success',
                value: 'tugboat'
            },
            {
                text: 'Barge',
                color: 'warning',
                value: 'barge'
            }
        ],
        selectedFleet: 1,
        optionsFleet: [
            { value: '1', text: 'TB. 1001' },
            { value: '2', text: 'TB. 1002' },
            { value: '3', text: 'TB. 1003' },
            { value: '4', text: 'TB. 1004' },
            { value: '5', text: 'TB. 1005' },
        ],
        selectedOwnership: 1,
        optionsOwnership: [
            { value: '1', text: 'Company Owned' },
            { value: '2', text: 'Chartered' }
        ],
        selectedCompany: 1,
        optionCompany: [
            { value: '1', text: 'Perusahaan ABC' },
            { value: '2', text: 'Perusahaan EFG' },
            { value: '3', text: 'Perusahaan HIJ' }
        ],
      }
    }
  }
  </script>