var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', {
    staticClass: "mb-0"
  }, [_vm._v("Sign in")]), _c('p', [_vm._v("Enter your username and password to access system.")]), _c('sign-in1-form', {
    attrs: {
      "formType": "jwt",
      "username": "",
      "password": ""
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }