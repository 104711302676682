var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('VueFullCalendar', {
    attrs: {
      "defaultView": "dayGridMonth",
      "header": _vm.header,
      "plugins": _vm.calendarPlugins,
      "events": _vm.calendarEvents,
      "time-zone": "UTC"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }