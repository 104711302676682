var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-card', {
          staticClass: "mb-1",
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle",
            value: 'year',
            expression: "'year'"
          }],
          staticClass: "p-1",
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('h4', {
          staticClass: "p-3",
          staticStyle: {
            "cursor": "pointer",
            "font-weight": "bold"
          }
        }, [_vm._v("2021")])]), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "8"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Total Seluruh Premium"
          }
        }, [_c('h4', {
          staticStyle: {
            "cursor": "pointer",
            "font-weight": "bold"
          }
        }, [_vm._v(" IDR 9.809.729.019,56 ")])])], 1)], 1)], 1), _c('b-collapse', {
          attrs: {
            "id": "year",
            "role": "tabpanel"
          }
        }, [_c('b-row', {
          staticClass: "pl-4 mt-2"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-card-header', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle",
            value: 'budget-company',
            expression: "'budget-company'"
          }],
          staticClass: "p-1",
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('h4', {
          staticClass: "p-3",
          staticStyle: {
            "cursor": "pointer",
            "font-weight": "bold"
          }
        }, [_vm._v("PT.Transcoal Pacific")])]), _c('b-collapse', {
          attrs: {
            "id": "budget-company",
            "role": "tabpanel"
          }
        }, [_c('b-card-body', [_c('b-row', [_c('b-col', {
          staticClass: "mb-3",
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-sm table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Component")]), _c('th', [_vm._v("Value")]), _c('th', [_vm._v("Vehicle Type (Tug Boat), Oil Barge, Sea Truck")])]), _c('tr', [_c('td', {
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("TSI")]), _c('td', [_vm._v(" IDR 351.094.831.538,10 ")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("HM")]), _c('td', [_vm._v("75%")]), _c('td', [_vm._v(" IDR 263.321.123.653,58 ")])]), _c('tr', [_c('td', [_vm._v("IV")]), _c('td', [_vm._v("25%")]), _c('td', [_vm._v(" IDR 87.773.707.884,53 ")])]), _c('tr', [_c('td', [_vm._v("HM Rate")]), _c('td', [_vm._v("0.952%")]), _c('td', [_vm._v(" USD 280.831,44 ")])]), _c('tr', [_c('td', [_vm._v("IV Rate")]), _c('td', [_vm._v("0.150%")]), _c('td', [_vm._v(" USD 14.401,61 ")])])]), _c('tfoot', [_c('tr', [_c('td', {
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("Grand Total")]), _c('td', [_c('strong', [_vm._v(" IDR 4.339.925.943,83 ")])])])])])]), _c('b-col', {
          staticClass: "mb-3",
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-sm table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Component")]), _c('th', [_vm._v("Value")]), _c('th', [_vm._v("Vehicle Type (Barge)")])]), _c('tr', [_c('td', {
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("TSI")]), _c('td', [_vm._v(" SGD 3.250.000,00 ")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("HM")]), _c('td', [_vm._v("75%")]), _c('td', [_vm._v(" SGD 2.437.500,00 ")])]), _c('tr', [_c('td', [_vm._v("IV")]), _c('td', [_vm._v("25%")]), _c('td', [_vm._v(" SGD 812.500,00 ")])]), _c('tr', [_c('td', [_vm._v("HM Rate")]), _c('td', [_vm._v("0.952%")]), _c('td', [_vm._v(" SGD 17.818,13 ")])]), _c('tr', [_c('td', [_vm._v("IV Rate")]), _c('td', [_vm._v("0.150%")]), _c('td', [_vm._v(" SGD 1.218,75 ")])])]), _c('tfoot', [_c('tr', [_c('td', {
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("Grand Total")]), _c('td', [_c('strong', [_vm._v(" IDR 205.598.250,00 ")])])])])])])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('iq-card', {
    staticClass: "border border-light shadow-lg",
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Add Year Budget")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Year :",
            "label-for": "kind-fleet"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.yearOptions,
            "reduce": function reduce(type) {
              return type.value;
            }
          },
          model: {
            value: _vm.year,
            callback: function callback($$v) {
              _vm.year = $$v;
            },
            expression: "year"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "8"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Company :",
            "label-for": "kind-fleet"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "placeholder": "For Company",
            "label": "name",
            "track-by": "id",
            "options": _vm.companyOptions
          },
          model: {
            value: _vm.companySelected,
            callback: function callback($$v) {
              _vm.companySelected = $$v;
            },
            expression: "companySelected"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Company not found.")])])], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Vehicle Type",
            "label-for": "category_for"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "placeholder": "Search categories",
            "label": "name",
            "track-by": "id",
            "options": _vm.fleet_types,
            "multiple": true
          },
          model: {
            value: _vm.vehicleTypeSelected,
            callback: function callback($$v) {
              _vm.vehicleTypeSelected = $$v;
            },
            expression: "vehicleTypeSelected"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Category not found.")])])], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "TSI",
            "label-for": "category_for"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "prepend": "IDR"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          }
        })], 1)], 1)], 1), _c('b-col', {
          staticClass: "mt-2 mb-3",
          attrs: {
            "cols": "12"
          }
        }, [_c('hr'), _c('table', {
          staticClass: "table table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Component")]), _c('th', [_vm._v("Option Value")]), _c('th', [_vm._v("Value")]), _c('th', [_vm._v("#")])])]), _c('tbody', [_c('tr', [_c('td', [_c('b-form-input', {
          attrs: {
            "type": "text"
          }
        })], 1), _c('td', [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.optionValue,
            "reduce": function reduce(type) {
              return type.value;
            }
          },
          model: {
            value: _vm.componentOption,
            callback: function callback($$v) {
              _vm.componentOption = $$v;
            },
            expression: "componentOption"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number"
          }
        })], 1), _c('td', [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "warning",
            "size": "xs"
          }
        }, [_c('i', {
          staticClass: "ri-add-line"
        }), _vm._v(" Add")])], 1)])])]), _c('hr')]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('span', [_vm._v("Save")])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "submit",
            "variant": "none"
          }
        }, [_vm._v("Cancel")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }