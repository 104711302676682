var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Search & Filter")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Shipyard",
            "label-for": "ship-yard"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "shipyard",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.lists.params.name,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.params, "name", $$v);
            },
            expression: "lists.params.name"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.getShipyardsData();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Shipyard List")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', [_vm._v("No.")]), _c('th', [_vm._v("Nama")]), _c('th', [_vm._v("No Tlp.")]), _c('th', [_vm._v("Email")]), _c('th', [_vm._v("Action")])])]), _c('tbody', [_vm._l(_vm.lists.data, function (data, index) {
          return _c('tr', [_c('td', [_vm._v(_vm._s(index + 1) + ".")]), _c('td', [_vm._v(_vm._s(data.name))]), _c('td', [_vm._v(_vm._s(data.phone))]), _c('td', [_vm._v(_vm._s(data.email))]), _c('td', [_c('span', {
            staticClass: "text-info mr-1 cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.getShipyardDetailData(data.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('span', {
            staticClass: "text-danger cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.deleteShipyardData(data.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])])]);
        }), _vm.lists.data.length === 0 && !_vm.lists.isLoading ? _c('tr', [_c('td', {
          attrs: {
            "colspan": "5"
          }
        }, [_vm._v(" No Data ")])]) : _vm._e(), _vm.lists.isLoading ? _c('tr', [_c('td', {
          attrs: {
            "colspan": "5"
          }
        }, [_vm._v(" Loading ")])]) : _vm._e()], 2)])])]), _c('b-col', {
          staticClass: "mt-4"
        }, [_c('b-pagination', {
          attrs: {
            "value": _vm.lists.meta.currentPage,
            "total-rows": _vm.lists.meta.total,
            "per-page": _vm.lists.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getShipyardsData
          },
          model: {
            value: _vm.lists.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.meta, "currentPage", $$v);
            },
            expression: "lists.meta.currentPage"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.form.id
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.form.id ? 'Edit' : 'Add') + " Shipyard")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSave();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Shipyard Name",
            "label-for": "shipyard_name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "Power Name",
            "required": "",
            "type": "text",
            "placeholder": "Shipyard Name",
            "maxlength": "100"
          },
          model: {
            value: _vm.form.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "name", $$v);
            },
            expression: "form.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Email",
            "label-for": "email"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "email",
            "type": "email",
            "required": "",
            "placeholder": "User @",
            "maxlength": "100"
          },
          model: {
            value: _vm.form.email,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "email", $$v);
            },
            expression: "form.email"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Phone",
            "label-for": "phone"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "phone",
            "type": "number",
            "required": "",
            "maxlength": "50"
          },
          model: {
            value: _vm.form.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "phone", $$v);
            },
            expression: "form.phone"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label-for": "Address",
            "label": "Address"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "address",
            "rows": "3",
            "placeholder": "Address"
          },
          model: {
            value: _vm.form.address,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "address", $$v);
            },
            expression: "form.address"
          }
        })], 1), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "disabled": _vm.form.saving,
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('span', [_vm._v("Save")])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.resetForm();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }