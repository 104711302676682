var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', _vm._l(_vm.tableData.head, function (state, index) {
    return _c('th', {
      key: index,
      staticClass: "text-center"
    }, [_vm._v(_vm._s(state))]);
  }), 0)]), _c('tbody', _vm._l(_vm.tableData.body, function (data, index) {
    return _c('tr', {
      key: "data-".concat(index)
    }, [_c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm.currentPage > 1 ? _vm.currentPage + index + 1 : index + 1))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          path: "profile-candidate/".concat(data.id),
          params: {
            id: data.id
          }
        }
      }
    }, [_vm._v(_vm._s(data.full_name))]), _c('br'), _c('small', {
      staticStyle: {
        "color": "gray"
      }
    }, [_vm._v("Seaferer Code : " + _vm._s(data.seaferer_code))])], 1), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(data.name_position))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(data.apply_date ? _vm.moment(new Date(data.apply_date)).format('DD MMMM YYYY') : '-'))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(data.date_interview ? _vm.moment(new Date(data.date_interview)).format('DD MMMM YYYY HH:MM:ss') : '-'))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(data.expert || '-'))]), _c('td', {
      staticClass: "text-center"
    }, [_c('b-badge', {
      attrs: {
        "variant": "".concat(data.status === 'candidate' ? 'info' : data.status === 'expired_interview' ? 'danger' : data.status === 'waiting_result' ? 'success' : 'primary', " d-block")
      }
    }, [_vm._v(" " + _vm._s(data.status.split('_').join(' ')) + " ")])], 1), _c('td', {
      staticClass: "text-center"
    }, [_c('b-dropdown', {
      attrs: {
        "size": "lg",
        "variant": "link iq-bg-primary iq-border-radius-10",
        "toggle-class": "text-decoration-none",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton5",
              "data-toggle": "dropdown"
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square-o m-0 text-primary"
          })])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.verification_document",
        modifiers: {
          "verification_document": true
        }
      }],
      attrs: {
        "href": "#",
        "disabled": data.status === 'checklist' ? false : true
      },
      on: {
        "click": function click() {
          return _vm.setUserId(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-check mr-2"
    }), _vm._v("Verification ")]), _c('b-dropdown-item', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.setup-interview",
        modifiers: {
          "setup-interview": true
        }
      }],
      attrs: {
        "href": "#",
        "disabled": data.status === 'candidate' ? false : true
      },
      on: {
        "click": function click() {
          return _vm.setUserId(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-calendar mr-2"
    }), _vm._v("Set Interview ")]), _c('b-dropdown-item', {
      attrs: {
        "disabled": data.status === 'interview' ? false : true
      }
    }, [_c('router-link', {
      class: data.status === 'interview' ? 'text-dark' : 'text-secondary',
      attrs: {
        "to": {
          path: "/crewing/interview-review/".concat(data.id),
          params: {
            id: data.id
          }
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-address-card mr-2"
    }), _vm._v("Interview Review")])], 1), _c('b-dropdown-item', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.medical-checkup",
        modifiers: {
          "medical-checkup": true
        }
      }],
      attrs: {
        "href": "#",
        "disabled": data.status === 'mcu' ? false : true
      },
      on: {
        "click": function click() {
          return _vm.setUserId(data);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-stethoscope mr-2"
    }), _vm._v("Medical Checkup")]), _c('b-dropdown-item', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.sign-on",
        modifiers: {
          "sign-on": true
        }
      }],
      attrs: {
        "href": "#",
        "disabled": data.status === 'contract' ? false : true
      },
      on: {
        "click": function click() {
          return _vm.setUserId(data);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-handshake mr-2"
    }), _vm._v("Contract / Sign On")]), _c('b-dropdown-item', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.induction",
        modifiers: {
          "induction": true
        }
      }],
      attrs: {
        "href": "#",
        "disabled": data.status === 'induction' ? false : true
      },
      on: {
        "click": function click() {
          return _vm.setUserId(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-file mr-2"
    }), _vm._v("Induction")]), _c('b-dropdown-item', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.blacklist-crew",
        modifiers: {
          "blacklist-crew": true
        }
      }],
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-ban mr-2"
    }), _vm._v("Black List")])], 1)], 1)]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }