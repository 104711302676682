<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
          <iq-card>
              <template v-slot:headerTitle>
                  <h5 class="card-title text-primary"><strong>VENDOR</strong></h5>
              </template>
              <template v-slot:headerAction>
              <a class="text-primary float-right ml-3" v-b-toggle.filter role="button" style="cursor: pointer">
              <i class="fa fa-filter" />
              </a>
              </template>
              <template v-slot:body>
                <b-collapse visible id="filter" class="mb-4">
                  <b-row>
                    <b-col md="3">
                      <b-form-input id="search" style="height:35px" placeholder="Type Name Vendor"></b-form-input>
                    </b-col>
                    <b-col md="1">
                        <b-button variant="primary" size="sm"><i class="fa fa-search"></i></b-button>
                    </b-col>
                    <b-col md="8">
                        <b-button v-b-tooltip.top="'Add Data'" @click="slidePageFirst = !slidePageFirst;" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-plus"></i></b-button>
                        <b-button v-b-tooltip.top="'Refresh Data'" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-refresh"></i></b-button>

                        <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                            <template v-slot:button-content>
                                <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-save m-0"></i>
                                </span>
                            </template>
                            <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
                            <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
                        </b-dropdown>
                    </b-col>
                  </b-row>
                </b-collapse>
                <b-sidebar
                  v-model="slidePageFirst"
                  id="slidePageFirst"
                  title="NEW DATA"
                  width="60%"
                  backdrop-variant="dark"
                  bg-variant="white"
                  right
                  backdrop
                  shadow>
                  <template #default="{hide}">
                    <b-row class="m-3">
                        <b-col md="12">
                            <b-row>
                                <b-col md="12">
                                    <b-form-group label="Vendor Name" label-for="vendortName">
                                    <b-form-input id="vendortName" type="text"></b-form-input>
                                    </b-form-group>
                                </b-col>  
                                <b-col md="6">
                                    <template v-for="(item,index) in state">
                                    <b-form-radio inline v-model="stateActive[item[Object.keys(item)[0]]]" :name="item.name" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                                    </template>
                                    <div class="custom-file mt-3">
                                        <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                        <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                    </div>
                                </b-col>
                                <b-col md="6" class="text-center">
                                    <b-img rounded="circle" style="width:100px" :src="require('../../assets/images/ship.png')" alt="Responsive image"></b-img>
                                </b-col>
                            </b-row>
                            <hr>
                            <b-row>
                                <b-col md="6">
                                    <b-form>
                                        <b-form-group class="row" label-cols-md="4" label="Country" label-for="Country">
                                            <b-form-select plain v-model="selectCountry" :options="optionCountry" size="sm">
                                                <template v-slot:first>
                                                    <b-form-select-option :value="null">Select Country</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                        </b-form-group>
                                        <b-form-group class="row" label-cols-md="4" label="Provience" label-for="provience">
                                            <b-form-select plain v-model="selectProvience" :options="optionProvience" size="sm">
                                                <template v-slot:first>
                                                    <b-form-select-option :value="null">Select Provience</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                        </b-form-group>
                                        <b-form-group class="row" label-cols-md="4" label="City" label-for="city">
                                            <b-form-select plain v-model="selectCity" :options="optionCity" size="sm">
                                                <template v-slot:first>
                                                    <b-form-select-option :value="null">Select City</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                        </b-form-group>
                                        <b-form-group class="row" label-cols-md="4" label="Postal Code" label-for="postalCode">
                                            <b-form-select plain v-model="selectPostalCode" :options="optionPostalCode" size="sm">
                                                <template v-slot:first>
                                                    <b-form-select-option :value="null">Postal Code</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                        </b-form-group>
                                        <b-form-group class="row" label-cols-md="4" label="Address" label-for="address">
                                        <b-form-textarea id="address" rows="3"></b-form-textarea>
                                        </b-form-group>
                                    </b-form>
                                </b-col>
                                <b-col md="6">
                                    <b-form>
                                    <b-form-group class="row" label-cols-md="4" label="TAX ID" label-for="taxID">
                                            <b-form-input type="text" id="taxID" style="height:35px"></b-form-input>
                                        </b-form-group>
                                        <b-form-group class="row" label-cols-md="4" label="PKP ID" label-for="pkpID">
                                            <b-form-input type="text" id="pkpID" style="height:35px"></b-form-input>
                                        </b-form-group>
                                        <b-form-group class="row" label-cols-md="4" label="Phone Number" label-for="phoneNumber">
                                            <b-form-input type="text" id="phoneNumber" style="height:35px"></b-form-input>
                                        </b-form-group>
                                        <b-form-group class="row" label-cols-md="4" label="Ponsel Number" label-for="ponselNumber">
                                            <b-form-input type="number" id="ponselNumber" style="height:35px"></b-form-input>
                                        </b-form-group>
                                        <b-form-group class="row" label-cols-md="4" label="Email" label-for="email">
                                            <b-form-input type="number" id="email" style="height:35px"></b-form-input>
                                        </b-form-group>
                                        <b-form-group class="row" label-cols-md="4" label="Website" label-for="website">
                                            <b-form-input type="text" id="website" style="height:35px"></b-form-input>
                                        </b-form-group>
                                    </b-form>
                                </b-col>
                                <b-col md="12">
                                <hr>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="12" class="mt-3">
                                    <tab-nav :tabs="true">
                                        <tab-nav-items :active="true" id="first_tab" ariaControls="tab_first" role="tab" :ariaSelected="true" title="Additional Contact" />
                                        <tab-nav-items :active="false" id="second_tab" ariaControls="tab_second" role="tab" :ariaSelected="false" title="Sales & Purchase" />
                                        <tab-nav-items :active="false" id="third_tab" ariaControls="tab_third" role="tab" :ariaSelected="false" title="Accounting" />
                                        <tab-nav-items :active="false" id="fourth_tab" ariaControls="tab_fourth" role="tab" :ariaSelected="false" title="Internal Notes" />
                                    </tab-nav>
                                    <div id="myTabContent" class="tab-content">
                                        <tab-content-item :active="true" id="tab_first">
                                        <b-row>
                                            <b-col sm="12" md="3" v-for="doctor in doctors" :key="doctor.message">
                                                <iq-card body-class="text-center">
                                                    <template v-slot:body>
                                                    <div class="doc-profile">
                                                        <img class="rounded-circle img-fluid avatar-80" :src="doctor.image" alt="profile">
                                                    </div>
                                                    <div class="iq-doc-info mt-3">
                                                        <h4> {{doctor.name}}</h4>
                                                        <p class="mb-0">{{doctor.specialist}}</p>
                                                        <p class="mb-0">{{doctor.website}}</p>
                                                    </div>
                                                    </template>
                                                </iq-card>
                                            </b-col>
                                            <b-col md="12" class="text-center">
                                                <b-button variant="primary" size="sm" v-b-modal.additionalContact>
                                                    <i class="fa fa-users"></i>Add New Contact
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                        </tab-content-item>
                                        <tab-content-item :active="false" id="tab_second" aria-labelled-by="second_tab">
                                            <b-row>
                                                <b-col md="6">
                                                    <b class="text-primary">SALES</b>
                                                    <hr>
                                                    <b-form>
                                                        <b-form-group class="row" label-cols-md="4" label="Seller Name" label-for="Seller">
                                                            <b-form-select plain v-model="selectSeller" :options="optionSeller" size="sm">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null">Select Seller</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-form-group>
                                                        <b-form-group class="row" label-cols-md="4" label="Term Payment" label-for="termPayment">
                                                            <b-form-select plain v-model="selectTerm" :options="optionTerm" size="sm">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null">Term Payment</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-form-group>
                                                        <b-form-group class="row" label-cols-md="4" label="Fiscal Position" label-for="fiscalPosition">
                                                            <div class="custom-file">
                                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                                            </div>
                                                        </b-form-group>
                                                    </b-form>
                                                </b-col>
                                                <b-col md="6">
                                                    <b class="text-primary">PURCHASING</b>
                                                    <hr>
                                                    <b-form>
                                                        <b-form-group class="row" label-cols-md="4" label="Term Payment" label-for="termPayment">
                                                            <b-form-select plain v-model="selectTerm" :options="optionTerm" size="sm">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null">Term Payment</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-form-group>
                                                        <b-form-group class="row" label-cols-md="4" label="Receipt Reminder" label-for="receiptReminder">
                                                            <b-form-input type="text" id="receiptReminder" style="height:35px"></b-form-input>
                                                        </b-form-group>
                                                        <b-form-group class="row" label-cols-md="4" label="Company ID" label-for="companyID">
                                                            <b-form-input type="text" id="companyID" style="height:35px"></b-form-input>
                                                        </b-form-group>
                                                        <b-form-group class="row" label-cols-md="4" label="Company Reference" label-for="companyReference">
                                                            <b-form-input type="text" id="companyReference" style="height:35px"></b-form-input>
                                                        </b-form-group>
                                                    </b-form>
                                                </b-col>
                                            </b-row>
                                        </tab-content-item>
                                        <tab-content-item :active="false" id="tab_third" aria-labelled-by="third_tab">
                                            <b-row>
                                                <b-col md="6">
                                                    <b class="text-primary">REKENING BANK</b>
                                                    <hr>
                                                    <table class="table table-striped table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th class="text-center">Bank</th>
                                                                <th class="text-center">Rekening Number</th>
                                                                <th class="text-center">Payment</th>
                                                                <th class="text-center"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center">BCA</td>
                                                                <td class="text-center">3286 82737</td>
                                                                <td class="text-center">Yes</td>
                                                                <td class="text-center">
                                                                    <i class="fa fa-trash text-primary"></i>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="text-center">
                                                                    <b-form-input type="text" id="companyReference" style="height:35px"></b-form-input>
                                                                </td>
                                                                <td class="text-center">
                                                                    <b-form-input type="text" id="companyReference" style="height:35px"></b-form-input>
                                                                </td>
                                                                <td class="text-center">
                                                                    <template v-for="(item,index) in text">
                                                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-left" :key="index">
                                                                            <div class="custom-switch-inner mt-2">
                                                                                <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                                                <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </td>
                                                                <td class="text-center">
                                                                    <i class="fa fa-trash text-primary"></i>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="pl-3" colspan="4"><a href="">Add New Row</a></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </b-col>
                                                <b-col md="6">
                                                    <b class="text-primary">JOURNAL ACCOUNT</b>
                                                    <hr>
                                                    <b-form>
                                                        <b-form-group class="row" label-cols-md="4" label="Debit Account" label-for="account">
                                                            <b-form-select plain v-model="selectAccount" :options="optionAccount" size="sm">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null">Select Account</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-form-group>
                                                        <b-form-group class="row" label-cols-md="4" label="Debit Account" label-for="account">
                                                            <b-form-select plain v-model="selectAccount" :options="optionAccount" size="sm">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null">Select Account</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-form-group>
                                                    </b-form>
                                                </b-col>
                                            </b-row>
                                        </tab-content-item>
                                        <tab-content-item :active="false" id="tab_fourth" aria-labelled-by="fourth_tab">
                                            <b-row>
                                                <b-col md="6">
                                                    <b class="text-primary">NOTES VENDOR</b>
                                                    <hr>
                                                    <b-form>
                                                        <b-form-group class="row" label-cols-md="4" label="Alert Vendor" label-for="alertVendor">
                                                            <b-form-select plain v-model="selectAlertVendor" :options="optionAlertVendor" size="sm">
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="null">Select Alert Vendor</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </b-form-group>
                                                        <b-form-group class="row" label-cols-md="4" label="Remarks" label-for="remarks">
                                                            <b-form-textarea id="remarks" rows="3"></b-form-textarea>
                                                        </b-form-group>
                                                    </b-form>
                                                </b-col>
                                            </b-row>
                                        </tab-content-item>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                  </template>
                </b-sidebar>
                <b-modal id="additionalContact" size="lg" title="New Data" ok-title="Save" cancel-title="Close">
                    <b-row>
                        <b-col md="12">
                            <template v-for="(item,index) in radioAdditionalContact">
                                <b-form-radio inline v-model="radioAdditionalContactActive[item[Object.keys(item)[0]]]" :name="item.name" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                            </template>
                        </b-col>
                    </b-row>
                </b-modal>
                <b-row>
                  <b-col sm="3" class="text-center">
                    <b-card
                    :img-src="require('../../assets/images/page-img/company.jpg')"
                    img-alt="#"
                    img-top
                    class="iq-mb-3"
                    >
                    <a href="#" v-b-modal.detailVendor><h5 class="text-primary">Vehement Capital, PT</h5></a>
                    <p>Jakarta Pusat, Indonesia</p>
                    </b-card>
                  </b-col>
                  <b-col sm="3" class="text-center">
                    <b-card
                    :img-src="require('../../assets/images/page-img/company.jpg')"
                    img-alt="#"
                    img-top
                    class="iq-mb-3"
                    >
                    <h5 class="text-primary">Umbrella Corporation, PT </h5>
                    <p>Jakarta Selatan, Indonesia</p>
                    </b-card>
                  </b-col>
                  <b-col sm="3" class="text-center">
                    <b-card
                    :img-src="require('../../assets/images/page-img/company.jpg')"
                    img-alt="#"
                    img-top
                    class="iq-mb-3"
                    >
                    <h5 class="text-primary">The Acme Corporation, PT</h5>
                    <p>Kalimantan Timur, Indonesia</p>
                    </b-card>
                  </b-col>
                  <b-col sm="3" class="text-center">
                    <b-card
                    :img-src="require('../../assets/images/page-img/company.jpg')"
                    img-alt="#"
                    img-top
                    class="iq-mb-3"
                    >
                    <h5 class="text-primary">Massive Dynamic, PT</h5>
                    <p>Kendari, Indonesia</p>
                    </b-card>
                  </b-col>
                </b-row>
              </template>
          </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import { xray } from '../../config/pluginInit'
  export default {
    name: 'procurementProdcuts',
    mounted () {
      xray.index()
    },
    data () {
        return {
            // Sliding Page
            slidePageFirst: false,
            stateActive: {
                single: 'active'
            },
            state: [
                {
                name: 'single',
                label: 'Individual',
                value: 'inactive',
                disabled: false
                },
                {
                name: 'single',
                label: 'Company',
                value: 'active',
                disabled: false
                }
            ],
            radioAdditionalContactActive: {
                single: 'active'
            },
            radioAdditionalContact: [
                {
                    name: 'single',
                    label: 'Contact',
                    value: 'active',
                    disabled: false
                },
                {
                    name: 'single',
                    label: 'Factur',
                    value: 'inactive',
                    disabled: false
                },
                {
                    name: 'single',
                    label: 'Delivery',
                    value: 'inactive',
                    disabled: false
                },
                {
                    name: 'single',
                    label: 'Private',
                    value: 'inactive',
                    disabled: false
                },
                {
                name: 'single',
                label: 'Follow Up',
                value: 'inactive',
                disabled: false
                },
                {
                name: 'single',
                label: 'Others',
                value: 'inactive',
                disabled: false
                }
            ],
            selectCountry: null,
            optionCountry: [
                { value: '1', text: 'Indonesia' },
                { value: '2', text: 'Singapore' },
                { value: '3', text: 'Uited State' }
            ],
            selectProvience: null,
            optionProvience: [
                { value: '1', text: 'DKI Jakarta' },
                { value: '2', text: 'Bali' },
                { value: '3', text: 'Jawa Barat' }
            ],
            selectCity: null,
            optionCity: [
                { value: '1', text: 'Jakarta Selatan' },
                { value: '2', text: 'Jakarta Barat' },
                { value: '3', text: 'Jakarta Timur' }
            ],
            selectPostalCode: null,
            optionPostalCode: [
                { value: '1', text: '12310' },
                { value: '2', text: '12320' },
                { value: '3', text: '12330' }
            ],
            doctors: [
                { name: 'Agung Tri L', specialist: 'Marketing', image: require('../../assets/images/user/1.jpg'), website: '081223626382' },
                { name: 'Nurul Akbar', specialist: 'Sales', image: require('../../assets/images/user/01.jpg'), website: '081237652635' },
                { name: 'Tri Leksono', specialist: 'Marketing', image: require('../../assets/images/user/02.jpg'), website: '08123276325' },
                { name: 'Hanel Prillian', specialist: 'Marketing', image: require('../../assets/images/user/03.jpg'), website: '08126236253' }

            ],
            selectSeller: null,
            optionSeller: [
                { value: '1', text: 'Nurul Akbar' },
                { value: '2', text: 'Agung Tri Laksono' },
                { value: '3', text: 'Hanel Prillian' }
            ],
            selectTerm: null,
            optionTerm: [
                { value: '1', text: '7 Days' },
                { value: '2', text: '14 Days' },
                { value: '3', text: '1 Month' },
                { value: '4', text: '3 Months' },
                { value: '5', text: '6 Months' },
            ],
            text: [
                {
                activeLabel: 'Yes',
                inactiveLabel: 'No',
                label: 'Primary',
                class: 'primary',
                checked: true
                }
            ],
            selectAccount: null,
            optionAccount: [
                { value: '1', text: '[11120001] - Account Receive' },
                { value: '2', text: '[11120002] - Trade Receive' }
            ],
            selectAlertVendor: null,
            optionAlertVendor: [
                { value: '1', text: 'Blacklist' },
                { value: '2', text: 'Temporary Blacklist' }
            ]
        }
    }
  }
  </script>