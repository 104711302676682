<template>
  <b-modal :id="`modal---${id}`" v-model="model" size="xl" title="Select Fleet" header-bg-variant="light" header-text-variant="dark"
           footer-bg-variant="light" footer-text-variant="dark">
    <b-row class="mb-4">
      <b-col md="4" class="text-right">
        <b-form-input
          type="text"
          placeholder="Search fleets..."
          v-model="params.search"
          @input="fetchFleets"
        ></b-form-input>
      </b-col>
    </b-row>
    <div v-if="loading" class="text-center my-5">
      <b-spinner type="grow" label="Spinning"></b-spinner>
    </div>
    <template v-else>
      <b-row v-if="fleets.length > 0" class="container-fleets">
        <b-col
          md="3"
          class="text-center py-5 border-top"
          v-for="(fleet, i) in fleets"
          :key="`fleet-${i}`"
        >
          <div class="custom-control custom-checkbox image-checkbox">
            <input
              :id="`ck-fl-${i}`"
              :type="multiple ? 'checkbox' : 'radio'"
              class="custom-control-input"
              :value="fleet.id"
              v-model="checkedFleets"
            />
            <label class="custom-control-label" :for="`ck-fl-${i}`">
              <img
                :src="
                            fleet.vehicleType.icon.url
                              | checkPhoto(
                                require('@src/assets/images/fleet/PusherBarge.png')
                              )
                          "
                alt="#"
                class="img-fluid"
              />
            </label>
            <small
            ><strong>{{ fleet.name }}</strong></small
            >
          </div>
        </b-col>
      </b-row>
      <div v-else class="text-center my-5">
        <p class="mb-0 text-muted">No fleets data found.</p>
      </div>
    </template>
    <template #modal-footer="{ ok, cancel, hide }">
      <b-row>
        <b-col md="12" class="text-center">
          <b-button variant="none" class="iq-bg-danger text-right" @click="cancel()">Cancel</b-button>
          <b-button type="submit" variant="primary" @click="submit()" class="ml-2 text-right">Next <i class="fa fa-arrow-right"></i> </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { fleetsActions } from '@src/Utils/helper'

export default {
  name: 'ModalSelectFleets',
  mounted () {
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    id: {
      type: String,
      default: 'select-fleets'
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    model () {
      this.checkedFleets = []
      if (this.model === true) {
        this.fetchFleets()
      }
    }
  },
  data () {
    return {
      checkedFleets: [],
      loading: false,
      params: {
        page: 1,
        showAll: true,
        search: ''
      },
      fleets: []
    }
  },
  methods: {
    ...fleetsActions,
    cancel () {
      this.$emit('cancel')
    },
    submit () {
      this.$emit('submit', this.checkedFleets)
    },
    async fetchFleets () {
      this.loading = true
      let params = this.$options.filters.cleanObject(this.params)
      let res = await this.getFleets(params)

      if (!res.status) {
        this.loading = false
        this.fleets = []
      } else {
        this.loading = false
        this.fleets = res.data
      }
    }
  }
}
</script>

<style>
.container-fleets {
  overflow-y: scroll;
  height: 500px;
}
</style>
