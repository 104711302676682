<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-6 p-0" :active="true" href="#board" title="Maintenance Board"/>
                <tab-nav-items class="col-md-6 p-0" :active="false" href="#init" title="Maintenance Initiation"/>
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <!-- Board -->
      <tab-content-item :active="true" id="board">
        <MaintenanceSummaryBoard/>
      </tab-content-item>
      <!-- End Board -->
      <!-- Init -->
      <tab-content-item :active="false" id="init">
        <MaintenanceSummaryInit @submit="handleInitSubmit"/>
      </tab-content-item>
      <!-- End Init -->
    </div>
  </b-container>
</template>

<script>
import { xray } from '../../config/pluginInit'
import MaintenanceSummaryInit from '@src/views/Maintenance/MaintenanceSummaryInit'
import MaintenanceSummaryBoard from '@src/views/Maintenance/MaintenanceSummaryBoard'

export default {
  name: 'MaintenanceSummary',
  components: { MaintenanceSummaryBoard, MaintenanceSummaryInit },
  mounted () {
    xray.index()
  },
  data () {
    return {
      maintenanceDetailData: null,
      fleet_params: {
        page: 1,
        showAll: true,
        active: true,
        search: ''
      },
      fleets: [],
      menus: [],
      checkedFleets: [],
      treeInitMaintenance: [],
      tree: [
        {
          'text': 'Acocommodation & Hull',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Tanks',
              'selected': false
            }
          ]
        },
        {
          'text': 'Deck Equipment & Outfitting',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Mooring Equipment',
              'selected': false
            },
            {
              'text': 'Propeller',
              'selected': false
            },
            {
              'text': 'Winch & Windlass',
              'selected': false
            }
          ]
        },
        {
          'text': 'Electrical Components & Parts',
          'opened': true,
          'selected': false
        },
        {
          'text': 'Engine & Other Machinery',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Gear Box',
              'selected': false
            },
            {
              'text': 'Generator # Auxiliary Engine',
              'selected': true
            },
            {
              'text': 'Generator Emergency',
              'selected': false
            }
          ]
        },
        {
          'text': 'Machinery Equipment',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Main Engine',
              'selected': false
            }
          ]
        },
        {
          'text': 'Fire & Safety Equipment',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Safety Equipment',
              'selected': false
            }
          ]
        },
        {
          'text': 'Navigation & Communication Equipment',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Radio EQ',
              'selected': false
            }
          ]
        },
        {
          'text': 'Pipes & Fitting',
          'opened': true,
          'selected': false
        },
        {
          'text': 'Pumps',
          'opened': true,
          'selected': false
        }
      ],
      loadData: function (oriNode, resolve) {
        var id = oriNode.data.id ? oriNode.data.id : 0
        setTimeout(() => {
          let data = []
          if (id > 200) {
            data = []
          } else {
            data = [
              {
                'text': 'New Item 1...' + id, 'isLeaf': id > 100
              },
              {
                'text': 'New Item 2...' + id, 'isLeaf': id > 100
              }
            ]
          }
          resolve(data)
        }, 500)
      },
      itemEvents: {
        mouseover: function () {
          console.log('mouseover')
        },
        contextmenu: function () {
          console.log(arguments[2])
          arguments[2].preventDefault()
          console.log('contextmenu')
        }
      },
      jobOptions: [
        {
          label: 'Job Class ',
          color: 'primary',
          value: 'job_class'
        },
        {
          label: 'Job Type / Index',
          color: 'success',
          value: 'job_type'
        }
      ],
      dateRange: '',
      task: 'overdue',
      placement: 'top',
      typePowerCounter: true,
      typePowerCounter2: false,
      resetRunningHour: false,
      checkAllTypeofPower: false,
      taskList: [
        { value: 'towards', text: 'Towards Jobs' },
        { value: 'overdue', text: 'Overdue' },
        { value: 'postponed', text: 'Postponed' },
        { value: 'next_3_month', text: 'Next 3 Month' },
        { value: 'next_6_month', text: 'Next 6 Month' },
        { value: 'next_12_month', text: 'Next 12 Month' }
      ],
      actionSelect: 'maintenance',
      actionSelectList: [
        { value: 'review', text: 'Review' },
        { value: 'approve', text: 'Approve' },
        { value: 'maintenance', text: 'Maintenance' },
        { value: 'postponed', text: 'Postponed' }
      ],
      show_add_category: false,
      show_add_manufacturer: false,
      selectedBarge: null,
      selectedDischarge: null,
      selectedDestination: null,
      selectedCategoryOwnership: 'all',
      categoryOwnership: [
        { value: 'all', text: 'All Category Ownership' },
        { value: 'owned', text: 'Owned By Company' },
        { value: 'chartered', text: 'Chartered' }
      ],
      selectedRole: '1',
      fleet: [
        { value: '1', text: 'TB.TCP-202' },
        { value: '2', text: 'BG.SML-105' },
        { value: '3', text: 'BG.ETI-201' }
      ],
      role: [
        { value: '1', text: 'Luffing Pump' },
        { value: '2', text: 'Hull' },
        { value: '3', text: 'Load Line' },
        { value: '4', text: 'Basic Engine' },
        { value: '5', text: 'Fuel Oil (Tank)' },
        { value: '6', text: 'Lubrication Oil Tank' },
        { value: '7', text: 'Ballast Tank' },
        { value: '8', text: 'Void Tank' },
        { value: '9', text: 'Chain Locker' }
      ],
      vehicle: [
        {
          label: 'Tug Boat',
          checked: true,
          disabled: false
        },
        {
          label: 'Barge',
          checked: true,
          disabled: false
        },
        {
          label: 'Pusher Tug',
          checked: false,
          disabled: false
        },
        {
          label: 'Pusher Barge',
          checked: false,
          disabled: false
        },
        {
          label: 'Mother Vessel',
          checked: true,
          disabled: false
        },
        {
          label: 'Floating Crane',
          checked: true,
          disabled: false
        },
        {
          label: 'Heavy Equipment',
          checked: true,
          disabled: false
        }
      ],
      barge: [
        { value: '1', text: 'BG.TCP-202' },
        { value: '2', text: 'BG.SML-105' },
        { value: '3', text: 'BG.ETI-201' }
      ],
      discharge: [
        { value: '1', text: 'Asam-Asam' },
        { value: '2', text: 'Tanjung Awar-awar' },
        { value: '3', text: 'Kintap' }
      ],
      destination: [
        { value: '1', text: 'Asam-Asam' },
        { value: '2', text: 'Tanjung Awar-awar' },
        { value: '3', text: 'Kintap' }
      ],
      status_fleet: [
        { value: '1', text: 'All' },
        { value: '2', text: 'Mine Fleet' },
        { value: '3', text: 'Rent Fleet' }
      ],
      docking: [
        { value: '1', text: 'All' },
        { value: '2', text: 'Special Survey' },
        { value: '3', text: 'Intermediate Survey' }
      ],
      selectedFleetEquipment: [],
      fleetEquipmentList: [],
      selectedJobType: [],
      jobTypeList: [],
      selectedJobClass: [],
      jobClassList: [],
      jobs: [
        {
          fleet: 'TB.ETI 201',
          jobName: '10.000 H Maintenance Job',
          selected: false,
          componentNo: '813001',
          componentName: 'Fire Pump',
          current: '23.200 H',
          frequency: '10.000 H',
          lastMaintenance: '13 Januari 2021 / 11.000 H',
          pendingDate: '21.000 H',
          dueDate: '31.000 H',
          remarks: [{ date: '1 Feb 2021', reason: 'still sailing' }],
          status: [{ text: 'Over Main Engine 1', data: '2.000 H' }, { text: 'Over Main Engine 2', data: '2.000 H' }]
        },
        {
          fleet: 'TB.TCP 101',
          jobName: 'Oil Cooler Change',
          selected: true,
          componentNo: '43204',
          componentName: 'Hydraulic Pump Units FWD Port',
          current: '25 Maret 2021',
          frequency: '3 Month',
          lastMaintenance: '5 Oktober 2020',
          pendingDate: '5 Januari 2021',
          dueDate: '5 April 2021',
          remarks: [{ date: '5 Jan 2021', reason: 'Nanti aja belum ada budgetnya' }],
          status: [{ text: 'Over', data: '5 Days' }]
        }
      ],
      jobsHours: [
        {
          fleet: 'TB.ETI 201',
          jobName: '10.000 H Maintenance Job',
          componentName: 'Fire Pump',
          current: '21.200 H',
          frequency: '10.000 H',
          lastMaintenance: '13 Januari 2021 / 11.000 H',
          lastRunningMaintenance: '10.100 H',
          nextRunningHours: '23.000 H',
          status: '2.000 H Left'
        },
        {
          fleet: 'TB.TCP 101',
          jobName: '12.000 H Maintenance',
          componentName: 'A/c',
          current: '20.500 H',
          frequency: '12.000 H',
          lastMaintenance: '5 Oktober 2020',
          lastRunningMaintenance: '12.100 H',
          nextRunningHours: '25.000 H',
          status: '4.500 H Left'
        }
      ],
      selectedClasification: null,
      clasification: [
        { value: '1', text: 'Minor' },
        { value: '2', text: 'Medium' },
        { value: '3', text: 'Major' }
      ],
      selectedProblemCase: null,
      problemCase: [
        { value: '1', text: 'Human Error' },
        { value: '2', text: 'Engine Failure' },
        { value: '3', text: 'Material Fatigue' },
        { value: '4', text: 'Environment' }
      ],
      selectedKindTask: 1,
      kindTask: [
        { value: '1', text: 'Repair' },
        { value: '2', text: 'Renew' }
      ],
      selectedJobClass: 1,
      jobClass: [
        { value: '1', text: 'Corrective Maintenance' },
        { value: '2', text: 'Claim' },
        { value: '3', text: 'Preventive Maintenance' },
        { value: '4', text: 'Routines' },
        { value: '5', text: 'Short Service' }
      ],
      jobsFinished: [],
      jobsInit: [],
      equipmentValue: '',
      selectedTrigger: 'CALENDAR',
      triggers: [
        {
          label: 'Calendar Trigger ',
          color: 'warning',
          value: 'CALENDAR'
        },
        {
          label: 'Counter Trigger (Running Hours)',
          color: 'primary',
          value: 'COUNTER'
        }
      ],
      selectedPatternCalendar: 'month',
      patternCalendar: [
        {
          label: 'Day',
          color: 'success',
          value: 'DAY'
        },
        {
          label: 'Week',
          color: 'warning',
          value: 'WEEK'
        },
        {
          label: 'Month',
          color: 'primary',
          value: 'MONTH'
        }
      ],
      category: [
        { value: '1', text: 'Hull & Load Line' },
        { value: '2', text: 'Tanks' },
        { value: '3', text: 'Main Engine' }
      ],
    }
  },
  watch: {

  },
  created () {

  },
  methods: {
    handleInitSubmit () {
      location.reload()
    }
  }
}
</script>

<style scoped>
.icon-state-default {
  color: gray;
}
.icon-state-danger {
  color: red;
}
.icon-state-warning {
  color: yellow;
}
.icon-state-success {
  color: green;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
</style>
