var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "iq-card-body pl-0 pr-0 pt-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "docter-details-block py-4"
        }, [_c('div', {
          staticClass: "docter-profile text-center"
        }, [_c('b-img', _vm._b({
          attrs: {
            "src": _vm._f("checkPhoto")(_vm.user.userDetail.photo, require('@src/assets/images/user/01.jpg')),
            "alt": _vm.user.userDetail.name
          }
        }, 'b-img', {
          height: 144
        }, false))], 1), _c('div', {
          staticClass: "text-center mt-3 pl-3 pr-3"
        }, [_c('h4', [_c('b', [_vm._v(_vm._s(_vm.user.userDetail.name))])]), _c('p', [_vm._v(_vm._s(_vm.user.role.roles))]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(_vm.user.company.location) + " - " + _vm._s(_vm.user.company.company))]), _c('p', {
          staticClass: "mb-0"
        }, [_c('b-badge', {
          staticClass: "ml-2",
          attrs: {
            "variant": _vm.user.active ? 'success' : 'warning'
          }
        }, [_vm._v(_vm._s(_vm.user.active ? 'Active' : 'Not Active'))])], 1)])])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    attrs: {
      "body-class": "iq-card-body"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-card-header d-flex justify-content-between"
        }, [_c('div', {
          staticClass: "iq-header-title"
        }, [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Personal Information")])])]), _c('div', {
          staticClass: "iq-card-body"
        }, [_c('div', {
          staticClass: "about-info m-0 p-0"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-4"
        }, [_vm._v("Birthday:")]), _c('div', {
          staticClass: "col-8"
        }, [_vm._v(_vm._s(_vm._f("parseDate")(_vm.user.userDetail.birthday, 'DD MMMM YYYY')))]), _c('div', {
          staticClass: "col-4"
        }, [_vm._v("Age:")]), _c('div', {
          staticClass: "col-8"
        }, [_vm._v(_vm._s(_vm.user.userDetail.age))]), _c('div', {
          staticClass: "col-4"
        }, [_vm._v("Email:")]), _c('div', {
          staticClass: "col-8"
        }, [_c('a', {
          attrs: {
            "href": "mailto:".concat(_vm.user.email)
          }
        }, [_vm._v(" " + _vm._s(_vm.user.email) + " ")])]), _c('div', {
          staticClass: "col-4"
        }, [_vm._v("Phone:")]), _c('div', {
          staticClass: "col-8"
        }, [_c('a', {
          ref: "tel:".concat(_vm.user.userDetail.phone)
        }, [_vm._v(" " + _vm._s(_vm.user.userDetail.phone ? _vm.user.userDetail.phone : '-'))])]), _c('div', {
          staticClass: "col-4"
        }, [_vm._v("Address:")]), _c('div', {
          staticClass: "col-8"
        }, [_vm._v(_vm._s(_vm.user.userDetail.address ? _vm.user.userDetail.address : '-'))]), _c('div', {
          staticClass: "col-4 mt-4"
        }, [_c('router-link', {
          attrs: {
            "to": {
              name: 'setting.EditUser',
              params: {
                id: _vm.user.id
              },
              query: {
                menuObject: _vm.$route.query.menuObject
              }
            }
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "block": "",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "ri-edit-2-line"
        }), _vm._v("Edit")])], 1)], 1)])])])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    attrs: {
      "body-class": "iq-card-body"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-card-header d-flex justify-content-between"
        }, [_c('div', {
          staticClass: "iq-header-title"
        }, [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Fleet Responsible")])])]), _c('div', {
          staticClass: "iq-card-body"
        }, [_vm.user.vehicle.length > 0 ? _c('b-row', _vm._l(_vm.user.vehicle, function (fleet, i) {
          return _c('b-col', {
            key: "fleet-".concat(i),
            staticClass: "text-center",
            attrs: {
              "md": "4"
            }
          }, [_c('label', [_c('img', {
            staticClass: "img-fluid",
            attrs: {
              "src": _vm._f("checkPhoto")(fleet.vehicleType.icon.url, require('@src/assets/images/Km3FO09.png')),
              "alt": fleet.name
            }
          })]), _c('small', [_c('strong', [_vm._v(_vm._s(fleet.name))])])]);
        }), 1) : _c('p', [_vm._v("No fleet found.")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Log History")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.user.userLog.length > 0 ? _c('ul', {
          staticClass: "iq-timeline"
        }, _vm._l(_vm.user.userLog, function (log, i) {
          return _c('li', {
            key: "log-".concat(i)
          }, [_c('div', {
            staticClass: "timeline-dots border-success"
          }), _c('h6', {}, [_vm._v(_vm._s(log.activity))]), _c('small', {
            staticClass: "mt-1"
          }, [_vm._v(_vm._s(log.date))])]);
        }), 0) : _c('p', [_vm._v("No log history found.")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Notifications")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.user.userNotification.length > 0 ? _c('ul', {
          staticClass: "iq-timeline"
        }, _vm._l(_vm.user.userNotification, function (notif, i) {
          return _c('li', {
            key: "notif-".concat(i)
          }, [_c('div', {
            staticClass: "timeline-dots border-success"
          }), _c('h6', {}, [_vm._v(_vm._s(notif.title))]), _c('small', {
            staticClass: "mt-1"
          }, [_vm._v(_vm._s(notif.createAt))])]);
        }), 0) : _c('p', [_vm._v("There is no notification found.")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("User Privileges")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.loading_menu ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.menus.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("No.")]), _c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Data")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Add")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Update")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Delete")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Export")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Review")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Approval")])])]), _c('tbody', [_vm._l(_vm.menus, function (item, key) {
          return [_c('tr', {
            key: "menu-".concat(key)
          }, [_c('td', {
            staticClass: "text-center"
          }, [_c('b-form-checkbox', {
            attrs: {
              "name": "basic-checkbox-".concat(key),
              "disabled": ""
            },
            model: {
              value: _vm.checkedMenus[key].active,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "active", $$v);
              },
              expression: "checkedMenus[key].active"
            }
          })], 1), _c('td', [_vm._v(_vm._s(item.menu))]), _c('td', {
            staticClass: "text-center"
          }, [item.add ? [_c('b-form-checkbox', {
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": "",
              "disabled": ""
            },
            model: {
              value: _vm.checkedMenus[key].add,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "add", $$v);
              },
              expression: "checkedMenus[key].add"
            }
          })] : [_vm._v(" ")]], 2), _c('td', {
            staticClass: "text-center"
          }, [item.update ? [_c('b-form-checkbox', {
            key: key,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": "",
              "disabled": ""
            },
            model: {
              value: _vm.checkedMenus[key].update,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "update", $$v);
              },
              expression: "checkedMenus[key].update"
            }
          })] : [_vm._v(" ")]], 2), _c('td', {
            staticClass: "text-center"
          }, [item.delete ? [_c('b-form-checkbox', {
            key: key,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": "",
              "disabled": ""
            },
            model: {
              value: _vm.checkedMenus[key].delete,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "delete", $$v);
              },
              expression: "checkedMenus[key].delete"
            }
          })] : [_vm._v(" ")]], 2), _c('td', {
            staticClass: "text-center"
          }, [item.export ? [_c('b-form-checkbox', {
            key: key,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": "",
              "disabled": ""
            },
            model: {
              value: _vm.checkedMenus[key].export,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "export", $$v);
              },
              expression: "checkedMenus[key].export"
            }
          })] : [_vm._v(" ")]], 2), _c('td', {
            staticClass: "text-center"
          }, [item.review ? [_c('b-form-checkbox', {
            key: key,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": "",
              "disabled": ""
            },
            model: {
              value: _vm.checkedMenus[key].review,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "review", $$v);
              },
              expression: "checkedMenus[key].review"
            }
          })] : [_vm._v(" ")]], 2), _c('td', {
            staticClass: "text-center"
          }, [item.approval ? [_c('b-form-checkbox', {
            key: key,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": "",
              "disabled": ""
            },
            model: {
              value: _vm.checkedMenus[key].approval,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "approval", $$v);
              },
              expression: "checkedMenus[key].approval"
            }
          })] : [_vm._v(" ")]], 2)]), _vm._l(item.child, function (subMenuItem, keySub) {
            return [_c('tr', {
              key: "submenu-".concat(key).concat(keySub)
            }, [_c('td', {
              staticClass: "text-center"
            }, [_c('b-form-checkbox', {
              attrs: {
                "name": "submenu-ck-".concat(keySub),
                "disabled": ""
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].active,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "active", $$v);
                },
                expression: "checkedMenus[key].child[keySub].active"
              }
            })], 1), _c('td', [_c('span', {
              staticStyle: {
                "margin-right": "60px"
              }
            }), _vm._v(_vm._s(subMenuItem.menu))]), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.add ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": "",
                "disabled": ""
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].add,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "add", $$v);
                },
                expression: "checkedMenus[key].child[keySub].add"
              }
            })] : [_vm._v(" ")]], 2), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.update ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": "",
                "disabled": ""
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].update,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "update", $$v);
                },
                expression: "\n                                    checkedMenus[key].child[keySub].update\n                                  "
              }
            })] : [_vm._v(" ")]], 2), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.delete ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": "",
                "disabled": ""
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].delete,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "delete", $$v);
                },
                expression: "\n                                    checkedMenus[key].child[keySub].delete\n                                  "
              }
            })] : [_vm._v(" ")]], 2), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.export ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": "",
                "disabled": ""
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].export,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "export", $$v);
                },
                expression: "\n                                    checkedMenus[key].child[keySub].export\n                                  "
              }
            })] : [_vm._v(" ")]], 2), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.review ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": "",
                "disabled": ""
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].review,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "review", $$v);
                },
                expression: "\n                                    checkedMenus[key].child[keySub].review\n                                  "
              }
            })] : [_vm._v(" ")]], 2), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.approval ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": "",
                "disabled": ""
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].approval,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "approval", $$v);
                },
                expression: "\n                                    checkedMenus[key].child[keySub].approval\n                                  "
              }
            })] : [_vm._v(" ")]], 2)]), _vm._l(subMenuItem.child, function (tabMenuItem, keyTab) {
              return [_c('tr', {
                key: "keytab-".concat(key).concat(keySub).concat(keyTab)
              }, [_c('td', {
                staticClass: "text-center"
              }, [_c('b-form-checkbox', {
                attrs: {
                  "name": "tab-ck-".concat(keySub),
                  "disabled": ""
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].active,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "active", $$v);
                  },
                  expression: "\n                                    checkedMenus[key].child[keySub].child[\n                                      keyTab\n                                    ].active\n                                  "
                }
              })], 1), _c('td', [_c('span', {
                staticStyle: {
                  "margin-right": "100px"
                }
              }), _vm._v(_vm._s(tabMenuItem.menu))]), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.add ? [_c('b-form-checkbox', {
                key: keySub,
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": "",
                  "disabled": ""
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].add,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "add", $$v);
                  },
                  expression: "checkedMenus[key].child[keySub].child[\n                                        keyTab\n                                      ].add"
                }
              })] : [_vm._v(" ")]], 2), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.update ? [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": "",
                  "disabled": ""
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].update,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "update", $$v);
                  },
                  expression: "\n                                      checkedMenus[key].child[keySub].child[\n                                        keyTab\n                                      ].update\n                                    "
                }
              })] : [_vm._v(" ")]], 2), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.delete ? [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": "",
                  "disabled": ""
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].delete,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "delete", $$v);
                  },
                  expression: "\n                                      checkedMenus[key].child[keySub].child[\n                                        keyTab\n                                      ].delete\n                                    "
                }
              })] : [_vm._v(" ")]], 2), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.export ? [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": "",
                  "disabled": ""
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].export,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "export", $$v);
                  },
                  expression: "\n                                      checkedMenus[key].child[keySub].child[\n                                        keyTab\n                                      ].export\n                                    "
                }
              })] : [_vm._v(" ")]], 2), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.review ? [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": "",
                  "disabled": ""
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].review,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "review", $$v);
                  },
                  expression: "\n                                      checkedMenus[key].child[keySub].child[\n                                        keyTab\n                                      ].review\n                                    "
                }
              })] : [_vm._v(" ")]], 2), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.approval ? [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": "",
                  "disabled": ""
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].approval,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "approval", $$v);
                  },
                  expression: "\n                                      checkedMenus[key].child[keySub].child[\n                                        keyTab\n                                      ].approval\n                                    "
                }
              })] : [_vm._v(" ")]], 2)])];
            })];
          })];
        })], 2)])]) : _c('p', {
          staticClass: "mb-0 text-muted text-center"
        }, [_vm._v(" No privilege data data found. ")])]];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }