import axios from './auth'

const NOTIFICATION_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/user-notifications`;

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getNotifications({ commit }, params = {}) {
    const url = NOTIFICATION_BASE_URL;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let { data, ...pagination } = response.data;
      return {
        status: 'success',
        data,
        pagination
      };
    } catch (e) {
      if(e.response.status === 401) {
        return {
          status: 'unauthorized',
          data: e.response.data
        };
      }
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async readNotification({ commit }, notificationId) {
    const url = `${NOTIFICATION_BASE_URL}/${notificationId}/read`;

    const method = "post";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      data: {},
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
}
