var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List group")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-1",
            modifiers: {
              "collapse-1": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("The most basic list group is an unordered list with list items and the proper classes. Build upon it with the options that follow, or with your own CSS as needed.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-1"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "list-group"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-list-group>\n  <b-list-group-item>Cras justo odio</b-list-group-item>\n  <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>\n  <b-list-group-item>Morbi leo risus</b-list-group-item>\n  <b-list-group-item>Porta ac consectetur ac</b-list-group-item>\n  <b-list-group-item>Vestibulum at eros</b-list-group-item>\n</b-list-group>\n")])])])])]), _c('b-list-group', [_c('b-list-group-item', [_vm._v("Cras justo odio")]), _c('b-list-group-item', [_vm._v("Dapibus ac facilisis in")]), _c('b-list-group-item', [_vm._v("Morbi leo risus")]), _c('b-list-group-item', [_vm._v("Porta ac consectetur ac")]), _c('b-list-group-item', [_vm._v("Vestibulum at eros")])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Disabled items")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-2",
            modifiers: {
              "collapse-2": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Add "), _c('code', [_vm._v(".disabled")]), _vm._v(" to a "), _c('code', [_vm._v(".list-group-item")]), _vm._v(" to make it "), _c('em', [_vm._v("appear")]), _vm._v(" disabled. Note that some elements with "), _c('code', [_vm._v(".disabled")]), _vm._v(" will also require custom JavaScript to fully disable their click events (e.g., links).")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-2"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "disabled-items"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-list-group>\n  <b-list-group-item disabled>Cras justo odio</b-list-group-item>\n  <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>\n  <b-list-group-item>Morbi leo risus</b-list-group-item>\n  <b-list-group-item>Porta ac consectetur ac</b-list-group-item>\n  <b-list-group-item>Vestibulum at eros</b-list-group-item>\n</b-list-group>\n")])])])])]), _c('b-list-group', [_c('b-list-group-item', {
          attrs: {
            "disabled": ""
          }
        }, [_vm._v("Cras justo odio")]), _c('b-list-group-item', [_vm._v("Dapibus ac facilisis in")]), _c('b-list-group-item', [_vm._v("Morbi leo risus")]), _c('b-list-group-item', [_vm._v("Porta ac consectetur ac")]), _c('b-list-group-item', [_vm._v("Vestibulum at eros")])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Flush")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-3",
            modifiers: {
              "collapse-3": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Add "), _c('code', {
          staticClass: "highlighter-rouge"
        }, [_vm._v(".list-group-flush")]), _vm._v(" to remove some borders and rounded corners to render list group items edge-to-edge in a parent container (e.g., cards).")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-3"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "flush"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-list-group flush>\n  <b-list-group-item>Cras justo odio</b-list-group-item>\n  <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>\n  <b-list-group-item>Morbi leo risus</b-list-group-item>\n  <b-list-group-item>Porta ac consectetur ac</b-list-group-item>\n  <b-list-group-item>Vestibulum at eros</b-list-group-item>\n</b-list-group>\n")])])])])]), _c('b-list-group', {
          attrs: {
            "flush": ""
          }
        }, [_c('b-list-group-item', [_vm._v("Cras justo odio")]), _c('b-list-group-item', [_vm._v("Dapibus ac facilisis in")]), _c('b-list-group-item', [_vm._v("Morbi leo risus")]), _c('b-list-group-item', [_vm._v("Porta ac consectetur ac")]), _c('b-list-group-item', [_vm._v("Vestibulum at eros")])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Contextual classes")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-4",
            modifiers: {
              "collapse-4": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Use contextual classes to style list items with a stateful background and color.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-4"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "contextual-classes"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n <b-list-group>\n  <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>\n  <b-list-group-item variant=\"primary\">A simple primary list group item</b-list-group-item>\n  <b-list-group-item variant=\"secondary\">A simple secondary list group item</b-list-group-item>\n  <b-list-group-item variant=\"success\">A simple success list group item</b-list-group-item>\n  <b-list-group-item variant=\"danger\">A simple danger list group item</b-list-group-item>\n  <b-list-group-item variant=\"warning\">A simple warning list group item</b-list-group-item>\n  <b-list-group-item variant=\"info\">A simple info list group item</b-list-group-item>\n  <b-list-group-item variant=\"light\">A simple light list group item</b-list-group-item>\n  <b-list-group-item variant=\"dark\">A simple dark list group item</b-list-group-item>\n</b-list-group>\n")])])])])]), _c('b-list-group', [_c('b-list-group-item', [_vm._v("Dapibus ac facilisis in")]), _c('b-list-group-item', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("A simple primary list group item")]), _c('b-list-group-item', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("A simple secondary list group item")]), _c('b-list-group-item', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("A simple success list group item")]), _c('b-list-group-item', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("A simple danger list group item")]), _c('b-list-group-item', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("A simple warning list group item")]), _c('b-list-group-item', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("A simple info list group item")]), _c('b-list-group-item', {
          attrs: {
            "variant": "light"
          }
        }, [_vm._v("A simple light list group item")]), _c('b-list-group-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_vm._v("A simple dark list group item")])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("With badges")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-5",
            modifiers: {
              "collapse-5": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Add badges to any list group item to show unread counts, activity, and more with the help of some "), _c('a', {
          attrs: {
            "href": "/docs/4.3/utilities/flex/"
          }
        }, [_vm._v("utilities")]), _vm._v(".")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-5"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "with-badges-1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-list-group>\n  <b-list-group-item class=\"d-flex justify-content-between align-items-center\">\n    Cras justo odio\n    <b-badge variant=\"primary\" pill>14</b-badge>\n  </b-list-group-item>\n  <b-list-group-item class=\"d-flex justify-content-between align-items-center\">\n    Dapibus ac facilisis in\n    <b-badge variant=\"success\" pill>2</b-badge>\n  </b-list-group-item>\n  <b-list-group-item class=\"d-flex justify-content-between align-items-center\">\n    Morbi leo risus\n    <b-badge variant=\"danger\" pill>1</b-badge>\n  </b-list-group-item>\n</b-list-group>\n")])])])])]), _c('b-list-group', [_c('b-list-group-item', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_vm._v(" Cras justo odio "), _c('b-badge', {
          attrs: {
            "variant": "primary",
            "pill": ""
          }
        }, [_vm._v("14")])], 1), _c('b-list-group-item', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_vm._v(" Dapibus ac facilisis in "), _c('b-badge', {
          attrs: {
            "variant": "success",
            "pill": ""
          }
        }, [_vm._v("2")])], 1), _c('b-list-group-item', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_vm._v(" Morbi leo risus "), _c('b-badge', {
          attrs: {
            "variant": "danger",
            "pill": ""
          }
        }, [_vm._v("1")])], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("With badges")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-6",
            modifiers: {
              "collapse-6": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Add badges to any list group item to show unread counts, activity, and more with the help of some "), _c('a', {
          attrs: {
            "href": "/docs/4.3/utilities/flex/"
          }
        }, [_vm._v("utilities")]), _vm._v(".")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-6"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "with-badges-2"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-list-group>\n  <b-list-group-item class=\"d-flex justify-content-between align-items-center iq-bg-primary\">\n    Cras justo odio\n    <b-badge variant=\"primary\" pill>14</b-badge>\n  </b-list-group-item>\n  <b-list-group-item class=\"d-flex justify-content-between align-items-center iq-bg-success\">\n    Dapibus ac facilisis in\n    <b-badge variant=\"success\" pill>2</b-badge>\n  </b-list-group-item>\n  <b-list-group-item class=\"d-flex justify-content-between align-items-center iq-bg-danger\">\n    Morbi leo risus\n    <b-badge variant=\"danger\" pill>1</b-badge>\n  </b-list-group-item>\n</b-list-group>\n")])])])])]), _c('b-list-group', [_c('b-list-group-item', {
          staticClass: "d-flex justify-content-between align-items-center iq-bg-primary"
        }, [_vm._v(" Cras justo odio "), _c('b-badge', {
          attrs: {
            "variant": "primary",
            "pill": ""
          }
        }, [_vm._v("14")])], 1), _c('b-list-group-item', {
          staticClass: "d-flex justify-content-between align-items-center iq-bg-success"
        }, [_vm._v(" Dapibus ac facilisis in "), _c('b-badge', {
          attrs: {
            "variant": "success",
            "pill": ""
          }
        }, [_vm._v("2")])], 1), _c('b-list-group-item', {
          staticClass: "d-flex justify-content-between align-items-center iq-bg-danger"
        }, [_vm._v(" Morbi leo risus "), _c('b-badge', {
          attrs: {
            "variant": "danger",
            "pill": ""
          }
        }, [_vm._v("1")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Active")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-7",
            modifiers: {
              "collapse-7": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Add "), _c('code', [_vm._v(".active")]), _vm._v(" to a "), _c('code', [_vm._v(".list-group-item")]), _vm._v(" to indicate the current active selection.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-7"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "list-active"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-list-group>\n  <b-list-group-item active>Cras justo odio</b-list-group-item>\n  <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>\n  <b-list-group-item>Morbi leo risus</b-list-group-item>\n  <b-list-group-item>Porta ac consectetur ac</b-list-group-item>\n  <b-list-group-item>Vestibulum at eros</b-list-group-item>\n</b-list-group>\n")])])])])]), _c('b-list-group', [_c('b-list-group-item', {
          attrs: {
            "active": ""
          }
        }, [_vm._v("Cras justo odio")]), _c('b-list-group-item', [_vm._v("Dapibus ac facilisis in")]), _c('b-list-group-item', [_vm._v("Morbi leo risus")]), _c('b-list-group-item', [_vm._v("Porta ac consectetur ac")]), _c('b-list-group-item', [_vm._v("Vestibulum at eros")])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Links and buttons")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-8",
            modifiers: {
              "collapse-8": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Use "), _c('code', [_vm._v("<a>")]), _vm._v("s or "), _c('code', [_vm._v("<button>")]), _vm._v("s to create "), _c('em', [_vm._v("actionable")]), _vm._v(" list group items with hover, disabled, and active states by adding "), _c('code', [_vm._v(".list-group-item-action")]), _vm._v(". We separate these pseudo-classes to ensure list groups made of non-interactive elements (like "), _c('code', [_vm._v("<li>")]), _vm._v("s or "), _c('code', [_vm._v("<div>")]), _vm._v("s) don’t provide a click or tap affordance.")]), _c('p', [_vm._v("Be sure to "), _c('strong', [_vm._v("not use the standard "), _c('code', [_vm._v(".btn")]), _vm._v(" classes here")]), _vm._v(".")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-8"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "links-buttons"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-list-group>\n  <b-list-group-item href=\"#\" active>Cras justo odio</b-list-group-item>\n  <b-list-group-item href=\"#\">Dapibus ac facilisis in</b-list-group-item>\n  <b-list-group-item href=\"#\">Morbi leo risus</b-list-group-item>\n  <b-list-group-item href=\"#\">Porta ac consectetur ac</b-list-group-item>\n  <b-list-group-item href=\"#\">Vestibulum at eros</b-list-group-item>\n</b-list-group>\n")])])])])]), _c('b-list-group', [_c('b-list-group-item', {
          attrs: {
            "href": "#",
            "active": ""
          }
        }, [_vm._v("Cras justo odio")]), _c('b-list-group-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Dapibus ac facilisis in")]), _c('b-list-group-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Morbi leo risus")]), _c('b-list-group-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Porta ac consectetur ac")]), _c('b-list-group-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Vestibulum at eros")])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Horizontal")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-9",
            modifiers: {
              "collapse-9": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Add "), _c('code', [_vm._v(".list-group-horizontal")]), _vm._v(" to change the layout of list group items from vertical to horizontal across all breakpoints. Alternatively, choose a responsive variant "), _c('code', [_vm._v(".list-group-horizontal-{sm|md|lg|xl}")]), _vm._v(" to make a list group horizontal starting at that breakpoint’s "), _c('code', [_vm._v("min-width")]), _vm._v(". Currently "), _c('strong', [_vm._v("horizontal list groups cannot be combined with flush list groups.")])]), _c('p', [_c('strong', [_vm._v("ProTip:")]), _vm._v(" Want equal-width list group items when horizontal? Add "), _c('code', [_vm._v(".flex-fill")]), _vm._v(" to each list group item.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-9"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "horizontal"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-list-group horizontal>\n  <b-list-group-item>Cras justo odio</b-list-group-item>\n  <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>\n  <b-list-group-item>Morbi leo risus</b-list-group-item>\n</b-list-group>\n")])])])])]), _c('b-list-group', {
          attrs: {
            "horizontal": ""
          }
        }, [_c('b-list-group-item', [_vm._v("Cras justo odio")]), _c('b-list-group-item', [_vm._v("Dapibus ac facilisis in")]), _c('b-list-group-item', [_vm._v("Morbi leo risus")])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Contextual classes Action")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-10",
            modifiers: {
              "collapse-10": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Use contextual classes to style list items with a stateful background and color.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-10"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "contextual-action"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-list-group>\n  <b-list-group-item href=\"#\">Dapibus ac facilisis in</b-list-group-item>\n  <b-list-group-item href=\"#\" variant=\"primary\">A simple primary list group item</b-list-group-item>\n  <b-list-group-item href=\"#\" variant=\"secondary\">A simple secondary list group item</b-list-group-item>\n  <b-list-group-item href=\"#\" variant=\"success\">A simple success list group item</b-list-group-item>\n  <b-list-group-item href=\"#\" variant=\"danger\">A simple danger list group item</b-list-group-item>\n  <b-list-group-item href=\"#\" variant=\"warning\">A simple warning list group item</b-list-group-item>\n  <b-list-group-item href=\"#\" variant=\"info\">A simple info list group item</b-list-group-item>\n  <b-list-group-item href=\"#\" variant=\"light\">A simple light list group item</b-list-group-item>\n  <b-list-group-item href=\"#\" variant=\"dark\">A simple dark list group item</b-list-group-item>\n</b-list-group>\n")])])])])]), _c('b-list-group', [_c('b-list-group-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Dapibus ac facilisis in")]), _c('b-list-group-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_vm._v("A simple primary list group item")]), _c('b-list-group-item', {
          attrs: {
            "href": "#",
            "variant": "secondary"
          }
        }, [_vm._v("A simple secondary list group item")]), _c('b-list-group-item', {
          attrs: {
            "href": "#",
            "variant": "success"
          }
        }, [_vm._v("A simple success list group item")]), _c('b-list-group-item', {
          attrs: {
            "href": "#",
            "variant": "danger"
          }
        }, [_vm._v("A simple danger list group item")]), _c('b-list-group-item', {
          attrs: {
            "href": "#",
            "variant": "warning"
          }
        }, [_vm._v("A simple warning list group item")]), _c('b-list-group-item', {
          attrs: {
            "href": "#",
            "variant": "info"
          }
        }, [_vm._v("A simple info list group item")]), _c('b-list-group-item', {
          attrs: {
            "href": "#",
            "variant": "light"
          }
        }, [_vm._v("A simple light list group item")]), _c('b-list-group-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_vm._v("A simple dark list group item")])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Links and buttons")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-12",
            modifiers: {
              "collapse-12": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Add nearly any HTML within, even for linked list groups like the one below, with the help of "), _c('a', {
          attrs: {
            "href": "/docs/4.3/utilities/flex/"
          }
        }, [_vm._v("flexbox utilities")]), _vm._v(".")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-12"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "links-buttons"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-list-group>\n  <b-list-group-item href=\"#\" active class=\"flex-column align-items-start\">\n    <div class=\"d-flex w-100 justify-content-between\">\n      <h5 class=\"mb-1 text-white\">List group item heading</h5>\n      <small>3 days ago</small>\n    </div>\n    <p class=\"mb-1\">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>\n    <small>Donec id elit non mi porta.</small>\n  </b-list-group-item>\n  <b-list-group-item href=\"#\" class=\"flex-column align-items-start\">\n    <div class=\"d-flex w-100 justify-content-between\">\n      <h5 class=\"mb-1\">List group item heading</h5>\n      <small class=\"text-muted\">3 days ago</small>\n    </div>\n    <p class=\"mb-1\">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>\n    <small class=\"text-muted\">Donec id elit non mi porta.</small>\n  </b-list-group-item>\n  <b-list-group-item href=\"#\" class=\"flex-column align-items-start\">\n    <div class=\"d-flex w-100 justify-content-between\">\n      <h5 class=\"mb-1\">List group item heading</h5>\n      <small class=\"text-muted\">3 days ago</small>\n    </div>\n    <p class=\"mb-1\">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>\n    <small class=\"text-muted\">Donec id elit non mi porta.</small>\n  </b-list-group-item>\n</b-list-group>\n")])])])])]), _c('b-list-group', [_c('b-list-group-item', {
          staticClass: "flex-column align-items-start",
          attrs: {
            "href": "#",
            "active": ""
          }
        }, [_c('div', {
          staticClass: "d-flex w-100 justify-content-between"
        }, [_c('h5', {
          staticClass: "mb-1 text-white"
        }, [_vm._v("List group item heading")]), _c('small', [_vm._v("3 days ago")])]), _c('p', {
          staticClass: "mb-1"
        }, [_vm._v("Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.")]), _c('small', [_vm._v("Donec id elit non mi porta.")])]), _c('b-list-group-item', {
          staticClass: "flex-column align-items-start",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "d-flex w-100 justify-content-between"
        }, [_c('h5', {
          staticClass: "mb-1"
        }, [_vm._v("List group item heading")]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v("3 days ago")])]), _c('p', {
          staticClass: "mb-1"
        }, [_vm._v("Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.")]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v("Donec id elit non mi porta.")])]), _c('b-list-group-item', {
          staticClass: "flex-column align-items-start",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "d-flex w-100 justify-content-between"
        }, [_c('h5', {
          staticClass: "mb-1"
        }, [_vm._v("List group item heading")]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v("3 days ago")])]), _c('p', {
          staticClass: "mb-1"
        }, [_vm._v("Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.")]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v("Donec id elit non mi porta.")])])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }