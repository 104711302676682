var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Basic Checkbox")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _vm._l(_vm.state, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "checkbox d-inline-block mr-2"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: item.checked,
              expression: "item.checked"
            }],
            staticClass: "checkbox-input",
            attrs: {
              "type": "checkbox",
              "id": "basic-checkbox-".concat(index),
              "disabled": item.disabled
            },
            domProps: {
              "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
            },
            on: {
              "change": function change($event) {
                var $$a = item.checked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(item, "checked", $$c);
                }
              }
            }
          }), _c('label', {
            staticClass: "ml-2",
            attrs: {
              "for": "basic-checkbox-".concat(index)
            }
          }, [_vm._v(_vm._s(item.label))])]);
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Custom Checkbox")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _vm._l(_vm.state, function (item, index) {
          return [_c('b-checkbox', {
            key: index,
            staticClass: "custom-switch-color",
            attrs: {
              "color": item.color,
              "disabled": item.disabled,
              "name": "check-button",
              "inline": ""
            },
            model: {
              value: item.checked,
              callback: function callback($$v) {
                _vm.$set(item, "checked", $$v);
              },
              expression: "item.checked"
            }
          }, [_vm._v(" " + _vm._s(item.label) + " ")])];
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Custom Color")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _vm._l(_vm.color, function (item, index) {
          return [_c('b-checkbox', {
            key: index,
            staticClass: "custom-checkbox-color",
            attrs: {
              "color": item.color,
              "disabled": item.disabled,
              "name": "check-button",
              "inline": ""
            },
            model: {
              value: item.checked,
              callback: function callback($$v) {
                _vm.$set(item, "checked", $$v);
              },
              expression: "item.checked"
            }
          }, [_vm._v(" " + _vm._s(item.label) + " ")])];
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Color")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _vm._l(_vm.color, function (item, index) {
          return [_c('b-checkbox', {
            key: index,
            staticClass: "custom-checkbox-color-check",
            attrs: {
              "color": item.color,
              "disabled": item.disabled,
              "name": "check-button",
              "inline": ""
            },
            model: {
              value: item.checked,
              callback: function callback($$v) {
                _vm.$set(item, "checked", $$v);
              },
              expression: "item.checked"
            }
          }, [_vm._v(" " + _vm._s(item.label) + " ")])];
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Boolean Checkbox")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _vm._l(_vm.bool, function (item, index) {
          return [_c('b-checkbox', {
            key: index,
            attrs: {
              "disabled": item.disabled,
              "name": "check-button",
              "inline": ""
            },
            model: {
              value: item.checked,
              callback: function callback($$v) {
                _vm.$set(item, "checked", $$v);
              },
              expression: "item.checked"
            }
          }, [_vm._v(" " + _vm._s(item.label) + " ")])];
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Change Icon")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _vm._l(_vm.icon, function (item, index) {
          return [_c('div', {
            key: index,
            staticClass: "custom-control custom-checkbox checkbox-icon custom-control-inline"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: item.checked,
              expression: "item.checked"
            }],
            staticClass: "custom-control-input",
            attrs: {
              "type": "checkbox",
              "id": "customSwitch-1-".concat(index)
            },
            domProps: {
              "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
            },
            on: {
              "change": function change($event) {
                var $$a = item.checked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(item, "checked", $$c);
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": "customSwitch-1-".concat(index)
            },
            domProps: {
              "innerHTML": _vm._s(item.label)
            }
          })])];
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }