<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(doLogin)">
      <ValidationProvider
        vid="username"
        name="Username"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="username">Username</label>
          <input
            type="text"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="username"
            v-model="user.username"
            placeholder="Enter username"
            required
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider
        vid="password"
        name="Password"
        rules="required|min:6"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="passwordInput">Password</label>
          <router-link to="/reset-password" class="float-right">
            Forgot password?
          </router-link>
          <input
            :type="showPassword ? 'text' : 'password'"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="passwordInput"
            v-model="user.password"
            placeholder="Password"
            required
          />
          <a href="#" @click.prevent="showPassword = !showPassword">{{ showPassword ? 'Hide' : 'Show' }} password </a>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <button type="submit" class="btn btn-secondary float-right">
          <template v-if="!loading">
            Login
          </template>
          <template v-else>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </template>
        </button>
      </div>
      <div class="sign-info">
        <span class="dark-color d-inline-block line-height-2">
          Don't have an account?
          <router-link to="#" class="iq-waves-effect pr-4">
            Contact Your Administrator!
          </router-link>
        </span>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SocialLoginForm from "./SocialLoginForm";

export default {
  name: "LoginFormFleet",
  components: { SocialLoginForm },
  props: ["formType", "username", "password"],
  data: () => ({
    showPassword: false,
    user: {
      username: "",
      password: "",
    },
    loading: false
  }),
  created() {
    if (this.loggedIn) {
      this.$swal("Logged In", "You are already logged in.", "success");
      this.$router.push({ name: 'dashboard.home' });
    }
  },
  mounted() {
    this.user.username = this.$props.username;
    this.user.password = this.$props.password;
  },
  computed: {
    ...mapGetters('Auth', ['profile', 'loggedIn']),
  },
  methods: {
    ...mapActions("Auth", ["login", "getProfile"]),
    async doLogin() {
      this.loading = true;

      let res = await this.login(this.user);
      let res_prof = await this.getProfile();
      if (res.status == "success" && res_prof.status == "success") {
        this.loading = false;
        this.$router.go()
        this.$router.push({ name: 'dashboard.home' });
      } else {
        this.loading = false;
        if (res.data.message != null)
          this.$swal("Error", res.data.message, "error");
        else
          this.$swal("Error", "Something wrong with the server. Please try again later.", "error");
      }
    },
  },
};
</script>
