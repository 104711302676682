var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Add New User")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form-group', [_c('div', {
          staticClass: "add-img-user profile-img-edit"
        }, [_c('b-img', {
          staticClass: "profile-pic height-150 width-150",
          attrs: {
            "fluid": "",
            "src": _vm.user.profile_image,
            "alt": "profile-pic"
          }
        }), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.user.profile_image,
            expression: "user.profile_image"
          }],
          attrs: {
            "type": "hidden"
          },
          domProps: {
            "value": _vm.user.profile_image
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.user, "profile_image", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "p-image"
        }, [_c('b-button', {
          staticClass: "upload-button iq-bg-primary position-relative",
          attrs: {
            "variant": "none"
          }
        }, [_c('input', {
          staticClass: "h-100 position-absolute",
          staticStyle: {
            "opacity": "0"
          },
          attrs: {
            "type": "file",
            "accept": "image/*"
          },
          on: {
            "change": _vm.previewImage
          }
        }), _vm._v(" File Upload ")])], 1)], 1), _c('div', {
          staticClass: "img-extension mt-3"
        }, [_c('div', {
          staticClass: "d-inline-block align-items-center"
        }, [_c('span', [_vm._v("Only")]), _c('b-link', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_vm._v(".jpg")]), _c('b-link', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_vm._v(".png")]), _c('b-link', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_vm._v(".jpeg")]), _c('span', [_vm._v("allowed")])], 1)])]), _c('b-form-group', {
          attrs: {
            "label": "Facebook Url:",
            "label-for": "furl"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "furl",
            "name": "furl",
            "placeholder": "Facebook Url"
          },
          model: {
            value: _vm.user.facebook_url,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "facebook_url", $$v);
            },
            expression: "user.facebook_url"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Twitter Url:",
            "label-for": "turl"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "turl",
            "name": "turl",
            "placeholder": "Twitter Url"
          },
          model: {
            value: _vm.user.twitter_url,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "twitter_url", $$v);
            },
            expression: "user.twitter_url"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Instagram Url:",
            "label-for": "turl"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "instaurl",
            "name": "instaurl",
            "placeholder": "Instagram Url"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Linkedin Url:",
            "label-for": "lurl"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "lurl",
            "placeholder": "Linkedin Url"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "9"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("New User Information")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "new-user-info"
        }, [_c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "First Name:",
            "label-for": "fname"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "First name",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var errors = _ref.errors;
              return [_c('b-form-input', {
                class: errors.length > 0 ? ' is-invalid' : '',
                attrs: {
                  "type": "text",
                  "placeholder": "First Name"
                },
                model: {
                  value: _vm.user.fname,
                  callback: function callback($$v) {
                    _vm.$set(_vm.user, "fname", $$v);
                  },
                  expression: "user.fname"
                }
              }), _c('div', {
                staticClass: "invalid-feedback"
              }, [_c('span', [_vm._v(_vm._s(errors[0]))])])];
            }
          }])
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Last Name:",
            "label-for": "lname"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "Last name",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-form-input', {
                class: errors.length > 0 ? ' is-invalid' : '',
                attrs: {
                  "type": "text",
                  "placeholder": "Last Name"
                },
                model: {
                  value: _vm.user.lname,
                  callback: function callback($$v) {
                    _vm.$set(_vm.user, "lname", $$v);
                  },
                  expression: "user.lname"
                }
              }), _c('div', {
                staticClass: "invalid-feedback"
              }, [_c('span', [_vm._v(_vm._s(errors[0]))])])];
            }
          }])
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Street Address 1:",
            "label-for": "add1"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "name": "add1",
            "id": "add1",
            "placeholder": "Street Address 1"
          },
          model: {
            value: _vm.user.address1,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "address1", $$v);
            },
            expression: "user.address1"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Street Address 2:",
            "label-for": "add2"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "name": "add2",
            "id": "add2",
            "placeholder": "Street Address 2"
          },
          model: {
            value: _vm.user.address2,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "address2", $$v);
            },
            expression: "user.address2"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Company Name:",
            "label-for": "cname"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "name": "cname",
            "id": "cname",
            "placeholder": "Company Name"
          },
          model: {
            value: _vm.user.company_name,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "company_name", $$v);
            },
            expression: "user.company_name"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-sm-6",
          attrs: {
            "label-for": "selectcountry",
            "label": "Country:"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.countries,
            "id": "selectcountry"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Country")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.country,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "country", $$v);
            },
            expression: "user.country"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Town/City:",
            "label-for": "city"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "name": "city",
            "id": "city",
            "placeholder": "Town/City"
          },
          model: {
            value: _vm.user.city,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "city", $$v);
            },
            expression: "user.city"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Pin Code:",
            "label-for": "pno"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "name": "pno",
            "id": "pno",
            "placeholder": "Pin Code"
          },
          model: {
            value: _vm.user.pincode,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "pincode", $$v);
            },
            expression: "user.pincode"
          }
        })], 1)], 1), _c('hr'), _c('h5', {
          staticClass: "mb-3"
        }, [_vm._v("Security")]), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "User Name:",
            "label-for": "uname"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "User Name",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('b-form-input', {
                class: errors.length > 0 ? ' is-invalid' : '',
                attrs: {
                  "type": "text",
                  "placeholder": "User Name"
                },
                model: {
                  value: _vm.user.username,
                  callback: function callback($$v) {
                    _vm.$set(_vm.user, "username", $$v);
                  },
                  expression: "user.username"
                }
              }), _c('div', {
                staticClass: "invalid-feedback"
              }, [_c('span', [_vm._v(_vm._s(errors[0]))])])];
            }
          }])
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Email:",
            "label-for": "uname"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "Email",
            "rules": "required|email"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('b-form-input', {
                class: errors.length > 0 ? ' is-invalid' : '',
                attrs: {
                  "type": "text",
                  "placeholder": "Email"
                },
                model: {
                  value: _vm.user.email,
                  callback: function callback($$v) {
                    _vm.$set(_vm.user, "email", $$v);
                  },
                  expression: "user.email"
                }
              }), _c('div', {
                staticClass: "invalid-feedback"
              }, [_c('span', [_vm._v(_vm._s(errors[0]))])])];
            }
          }])
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "User Role:",
            "label-for": "selectuserrole"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.roles,
            "id": "selectuserrole"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.role,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "role", $$v);
            },
            expression: "user.role"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Mobile Number:",
            "label-for": "mobno"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "Mobile Number",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('b-form-input', {
                class: errors.length > 0 ? ' is-invalid' : '',
                attrs: {
                  "type": "text",
                  "placeholder": "Mobile Number"
                },
                model: {
                  value: _vm.user.mobile_no,
                  callback: function callback($$v) {
                    _vm.$set(_vm.user, "mobile_no", $$v);
                  },
                  expression: "user.mobile_no"
                }
              }), _c('div', {
                staticClass: "invalid-feedback"
              }, [_c('span', [_vm._v(_vm._s(errors[0]))])])];
            }
          }])
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Password:",
            "label-for": "pass"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "Password",
            "rules": "required|confirmed:repeat_password"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('b-form-input', {
                class: errors.length > 0 ? ' is-invalid' : '',
                attrs: {
                  "type": "password",
                  "placeholder": "Password"
                },
                model: {
                  value: _vm.user.password,
                  callback: function callback($$v) {
                    _vm.$set(_vm.user, "password", $$v);
                  },
                  expression: "user.password"
                }
              }), _c('div', {
                staticClass: "invalid-feedback"
              }, [_c('span', [_vm._v(_vm._s(errors[0]))])])];
            }
          }])
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Repeat Password:",
            "label-for": "rpass"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "vid": "repeat_password",
            "name": "Repeat Password",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var errors = _ref7.errors;
              return [_c('b-form-input', {
                class: errors.length > 0 ? ' is-invalid' : '',
                attrs: {
                  "type": "password",
                  "placeholder": "Repeat Password"
                },
                model: {
                  value: _vm.user.repeat_password,
                  callback: function callback($$v) {
                    _vm.$set(_vm.user, "repeat_password", $$v);
                  },
                  expression: "user.repeat_password"
                }
              }), _c('div', {
                staticClass: "invalid-feedback"
              }, [_c('span', [_vm._v(_vm._s(errors[0]))])])];
            }
          }])
        })], 1)], 1), _c('div', {
          staticClass: "checkbox"
        }, [_c('label', [_c('input', {
          staticClass: "mr-2",
          attrs: {
            "type": "checkbox"
          }
        }), _vm._v("I agree terms & conditions.")])]), _c('b-button', {
          attrs: {
            "variant": "primary",
            "type": "submit"
          }
        }, [_vm._v("Add New User")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }