import axios from './auth'

const BASE_PORTAL = `${process.env.VUE_APP_API_BASE_PORTAL}`

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async fetchCandidatePublic({ commit }, params = {}) {
    const url = `${BASE_PORTAL}/list-candidate-public`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async fetchInterviewReviewPublic({ commit }, params) {
    const url = `${BASE_PORTAL}/interview-review-public/${params}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async fetchPointListPublic({ commit }, params) {
    const url = `${BASE_PORTAL}/point-public`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async fetchInterviewCriteriaPublic({ commit }, params = {}) {
    const url = `${BASE_PORTAL}/interview-criteria-public`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async fetchProfileCandidatePublic({ commit }, params = {}) {
    const url = `${BASE_PORTAL}/profile-candidate-public/${params.candidate_id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async setFormInterviewPublic({ commit }, payload = {}) {
    const url = `${BASE_PORTAL}/form-interview-public/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
}
