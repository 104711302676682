var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      "id": "forecast-form",
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v(_vm._s(_vm.id ? !_vm.isPreview ? 'Edit Year' : 'Detail Year' : 'Add New Year') + " - Balancing Loss Ratio")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_vm._v("Select Vehicle Type")]), _c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.typeFleet,
            "reduce": function reduce(val) {
              return val.code;
            },
            "placeholder": "Select Vehicle Type",
            "clearable": false,
            "disabled": _vm.forecastAvailable
          },
          model: {
            value: _vm.checkPayload.group_id,
            callback: function callback($$v) {
              _vm.$set(_vm.checkPayload, "group_id", $$v);
            },
            expression: "checkPayload.group_id"
          }
        })], 1)]), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_vm._v("Select Year")]), _c('v-select', {
          attrs: {
            "options": _vm.yearOption,
            "placeholder": "Select Year",
            "clearable": false,
            "disabled": _vm.forecastAvailable
          },
          model: {
            value: _vm.checkPayload.year,
            callback: function callback($$v) {
              _vm.$set(_vm.checkPayload, "year", $$v);
            },
            expression: "checkPayload.year"
          }
        })], 1)]), !_vm.id ? _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [!_vm.forecastAvailable ? _c('b-button', {
          staticClass: "mt-4",
          staticStyle: {
            "margin-top": "37px!important"
          },
          attrs: {
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.checkForecast(_vm.checkPayload);
            }
          }
        }, [_vm._v(" Check ")]) : _c('b-button', {
          staticClass: "mt-4",
          staticStyle: {
            "margin-top": "37px!important"
          },
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.forecastAvailable = false;
            }
          }
        }, [_vm._v(" Change ")])], 1) : _vm._e()], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "10"
          }
        }, [_vm.vehicleTypes ? _c('p', [_vm._v(_vm._s(_vm.vehicleTypes))]) : _vm._e()])], 1)];
      },
      proxy: true
    }])
  })], 1), _vm.forecastAvailable ? [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerAction",
      fn: function fn() {
        return [_vm.isPreview ? _c('a', {
          staticClass: "btn btn-primary",
          attrs: {
            "id": "goto-forecast-edit",
            "href": "/mi/forecast-condition-input?id=".concat(_vm.id, "&preview=false")
          }
        }, [_vm._v(" Edit ")]) : _vm._e(), _c('b-button', {
          attrs: {
            "disabled": _vm.saving,
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.onSave();
            }
          }
        }, [_vm._v(" Simpan ")])];
      },
      proxy: true
    }], null, false, 2569228047)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_c('h4', {
          staticClass: "text-capitalize"
        }, [_vm._v("Forecast Premium "), _c('small', [_vm._v("(Dengan Beban)")])])]), _c('h3', [_vm._v(_vm._s(_vm.formatCurrency(_vm.budgetGrandTotalSum)))])])]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_c('h4', {
          staticClass: "text-capitalize"
        }, [_vm._v("Forecast Premium "), _c('small', [_vm._v("(Tanpa Beban)")])])]), _c('h3', [_vm._v(_vm._s(_vm.formatCurrency(_vm.forecastPremium)))])])]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_c('h4', {
          staticClass: "text-capitalize"
        }, [_vm._v("Actual Premium")])]), _c('b-input-group', {
          attrs: {
            "size": "md",
            "prepend": "IDR"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.actualPremium,
            callback: function callback($$v) {
              _vm.actualPremium = $$v;
            },
            expression: "actualPremium"
          }
        })], 1), _c('p', [_vm._v(_vm._s(_vm.formatCurrency(_vm.actualPremium)))])], 1)]), _c('b-col', {
          staticClass: "d-none",
          attrs: {
            "md": "3"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_c('h4', {
          staticClass: "text-capitalize"
        }, [_vm._v("Rate HM")])]), _c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "%"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "width": "50%"
          },
          model: {
            value: _vm.currentHm,
            callback: function callback($$v) {
              _vm.currentHm = $$v;
            },
            expression: "currentHm"
          }
        })], 1)], 1)])], 1)];
      },
      proxy: true
    }], null, false, 1681411923)
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "8"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_c('h4', {
          staticClass: "text-capitalize"
        }, [_vm._v("Loading Point Insurer")])]), _c('h3', [_vm._v(_vm._s(_vm.loadingPointInsurer.toFixed(2)) + "%")])])]), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_c('h4', {
          staticClass: "text-capitalize"
        }, [_vm._v("Additional Loading Point Insurer")])]), _c('h3', [_vm._v(_vm._s(_vm.additionalLoadingPointInsurer.toFixed(2)) + "%")])])]), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_c('h4', {
          staticClass: "text-capitalize"
        }, [_vm._v("Final Loading Point")])]), _c('h3', [_vm._v(_vm._s(_vm.finalLoadingPointInsurer.toFixed(2)) + "%")])])])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "mb-4"
        }, [_vm._v(" NILAI TAMBAH YANG DIBERIKAN OLEH ASURANSI KEPADA OWNER UNTUK MENAMBAH BEBAN RATE ")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Claim")]), _c('th', [_vm._v("Percentage (%)")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("0 - IDR 1.000.000.000")]), _c('td', [_vm._v("2.5%")])]), _c('tr', [_c('td', [_vm._v("IDR 1.100.000.000 - IDR 2.000.000.000")]), _c('td', [_vm._v("5.0%")])]), _c('tr', [_c('td', [_vm._v("IDR 2.100.000.000 - IDR 3.000.000.000")]), _c('td', [_vm._v("7.5%")])]), _c('tr', [_c('td', [_vm._v("IDR 3.100.000.000 - IDR 4.000.000.000")]), _c('td', [_vm._v("10.0%")])]), _c('tr', [_c('td', [_vm._v("IDR 4.100.000.000 - IDR 5.000.000.000")]), _c('td', [_vm._v("12.5%")])]), _c('tr', [_c('td', [_vm._v("> IDR 5.100.000.000")]), _c('td', [_vm._v("15.0%")])])])])])], 1)];
      },
      proxy: true
    }], null, false, 2472970667)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-capitalize text-black"
        }, [_c('strong', [_vm._v("Step 1 - Pure Risk Component")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-forecast-step-1",
            modifiers: {
              "collapse-forecast-step-1": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-window-minimize"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-forecast-step-1",
            "visible": !_vm.isPreview
          }
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6"
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Details")]), _c('th', [_vm._v("Percentage")])])]), _c('tbody', _vm._l(_vm.pureRiskItems, function (item, index) {
          return _c('tr', {
            key: item.id
          }, [_c('td', [_vm._v(_vm._s(index + 1) + ".")]), _c('td', [_vm._v(_vm._s(item.name.toUpperCase()))]), _c('td', [_c('b-input-group', {
            attrs: {
              "size": "md",
              "append": "%"
            }
          }, [_c('b-form-input', {
            staticStyle: {
              "max-width": "50%"
            },
            attrs: {
              "type": "number"
            },
            model: {
              value: item.value,
              callback: function callback($$v) {
                _vm.$set(item, "value", $$v);
              },
              expression: "item.value"
            }
          })], 1)], 1)]);
        }), 0), _c('tfoot', [_c('tr', [_c('td', {
          staticClass: "text-center bg-success",
          attrs: {
            "colspan": "2"
          }
        }, [_c('strong', [_vm._v("Total")])]), _c('td', {
          staticClass: "text-center bg-success"
        }, [_c('strong', [_vm._v(_vm._s(_vm.pureRiskItemTotal) + "%")])])]), _c('tr', [_c('td', {
          staticClass: "text-center bg-info",
          attrs: {
            "colspan": "2"
          }
        }, [_c('strong', [_vm._v("Pure Risk Cost")])]), _c('td', {
          staticClass: "text-center bg-info"
        }, [_c('strong', [_vm._v(_vm._s(_vm.pureRiskItemCost) + "%")])])])])])])]), _c('div', {
          staticClass: "col-md-6"
        }, [_c('img', {
          staticClass: "mt-5",
          attrs: {
            "src": require("@assets/images/financial-pure-risk-component.jpg"),
            "width": "100%"
          }
        })])])])];
      },
      proxy: true
    }], null, false, 50578444)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-capitalize text-black"
        }, [_c('strong', [_vm._v("Step 2 - Outstanding & Future Claim")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-forecast-step-2",
            modifiers: {
              "collapse-forecast-step-2": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-window-minimize"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-forecast-step-2",
            "visible": !_vm.isPreview
          }
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-12 mb-3"
        }, [_c('h4', {
          staticClass: "mb-4"
        }, [_vm._v("Outstanding (From Claim Form)")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Vessel / Form Claim Linked")]), _c('th', [_vm._v("EST Cost")]), _c('th', [_vm._v("Nett Lost")]), _c('th', [_vm._v("Additional THUMB RULES (1%)")]), _c('th', [_vm._v("Est Closed File")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Period Coverage")]), _c('th', [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.outstanding, function (data, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index + 1) + ".")]), _c('td', [!data.premiumFromBudget.checked ? _c('div', [data.vehicle || data.cause_of_loss ? [_vm._v(" " + _vm._s(data.vehicle ? data.vehicle.name : '') + " - " + _vm._s(data.cause_of_loss ? data.cause_of_loss.name : '') + " ")] : [_vm._v(" " + _vm._s(data.form_free_text) + " ")]], 2) : _c('b-form-input', {
            attrs: {
              "type": "text"
            },
            model: {
              value: data.form_free_text,
              callback: function callback($$v) {
                _vm.$set(data, "form_free_text", $$v);
              },
              expression: "data.form_free_text"
            }
          })], 1), _c('td', [_vm.isPreview ? _c('h5', [_vm._v(_vm._s(_vm.formatCurrency(data.cost)))]) : _c('b-form-input', {
            attrs: {
              "type": "text"
            },
            model: {
              value: data.cost,
              callback: function callback($$v) {
                _vm.$set(data, "cost", $$v);
              },
              expression: "data.cost"
            }
          })], 1), _c('td', [_vm.isPreview ? _c('h5', [_vm._v(_vm._s(_vm.formatCurrency(data.net_claim_loss)))]) : _c('b-form-input', {
            attrs: {
              "type": "text"
            },
            model: {
              value: data.net_claim_loss,
              callback: function callback($$v) {
                _vm.$set(data, "net_claim_loss", $$v);
              },
              expression: "data.net_claim_loss"
            }
          })], 1), _c('td', [_c('h5', [_vm._v(_vm._s(_vm.formatCurrency(data.thumb_rules_cost = Number(data.cost) * (1 / 100) + Number(data.cost))))])]), _c('td', [!data.premiumFromBudget.checked ? _c('div', [data.summary ? [_vm._v(" " + _vm._s(data.follow_up_response === 'settled' ? data.summary.act_settled_date : data.summary.est_settled_date) + " ")] : _vm._e()], 2) : _c('div', [data.follow_up_response === 'settled' ? _c('date-picker', {
            staticStyle: {
              "width": "100%"
            },
            attrs: {
              "type": "date",
              "value-type": "YYYY-MM-DD",
              "placeholder": "Select Date"
            },
            model: {
              value: data.summary.act_settled_date,
              callback: function callback($$v) {
                _vm.$set(data.summary, "act_settled_date", $$v);
              },
              expression: "data.summary.act_settled_date"
            }
          }) : _c('date-picker', {
            staticStyle: {
              "width": "100%"
            },
            attrs: {
              "type": "date",
              "value-type": "YYYY-MM-DD",
              "placeholder": "Select Date"
            },
            model: {
              value: data.summary.est_settled_date,
              callback: function callback($$v) {
                _vm.$set(data.summary, "est_settled_date", $$v);
              },
              expression: "data.summary.est_settled_date"
            }
          })], 1)]), _c('td', [!data.premiumFromBudget.checked ? _c('div', [_vm._v(" " + _vm._s(data.follow_up_response.split('_').join(' ')) + " ")]) : _c('v-select', {
            attrs: {
              "options": ['on_progress', 'settled', 'withdrawal'],
              "reduce": function reduce(val) {
                return val.split(' ').join('_');
              },
              "clearable": false
            },
            model: {
              value: data.follow_up_response,
              callback: function callback($$v) {
                _vm.$set(data, "follow_up_response", $$v);
              },
              expression: "data.follow_up_response"
            }
          })], 1), _c('td', [_c('date-picker', {
            staticStyle: {
              "width": "100%"
            },
            attrs: {
              "type": "year",
              "value-type": "YYYY",
              "range": "",
              "placeholder": "Select year"
            },
            on: {
              "change": function change() {
                data.coverage_period = data.coverage_date_range ? _vm.moment(data.coverage_date_range[0]).format('YYYY') : null;
                data.coverage_period_to = data.coverage_date_range ? _vm.moment(data.coverage_date_range[1]).format('YYYY') : null;
              }
            },
            model: {
              value: data.coverage_date_range,
              callback: function callback($$v) {
                _vm.$set(data, "coverage_date_range", $$v);
              },
              expression: "data.coverage_date_range"
            }
          })], 1), _c('td', [_c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.deleteOutstanding(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])], 1)]);
        }), 0)]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "warning",
            "block": ""
          },
          on: {
            "click": _vm.addOutstanding
          }
        }, [_c('i', {
          staticClass: "ri-add-line"
        }), _vm._v(" Add More Data Outstanding")])], 1)], 1), _c('div', {
          staticClass: "col-md-12"
        }, [_c('hr')]), _c('div', {
          staticClass: "col-md-12 mb-3"
        }, [_c('h4', {
          staticClass: "mb-4"
        }, [_vm._v("Future Claim")]), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Items")]), _c('th', [_vm._v("Content")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Est Increase Loss Ratio")]), _c('td', [_c('b-textarea', {
          attrs: {
            "rows": "2"
          },
          model: {
            value: _vm.futureClaim.est_inc_loss_ratio,
            callback: function callback($$v) {
              _vm.$set(_vm.futureClaim, "est_inc_loss_ratio", $$v);
            },
            expression: "futureClaim.est_inc_loss_ratio"
          }
        })], 1)]), _c('tr', [_c('td', [_vm._v("Condition")]), _c('td', [_c('b-textarea', {
          attrs: {
            "rows": "2"
          },
          model: {
            value: _vm.futureClaim.condition,
            callback: function callback($$v) {
              _vm.$set(_vm.futureClaim, "condition", $$v);
            },
            expression: "futureClaim.condition"
          }
        })], 1)]), _c('tr', [_c('td', [_vm._v("Amount /Years")]), _c('td', [_c('b-input-group', {
          attrs: {
            "size": "md",
            "prepend": "IDR"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "max-width": "50%"
          },
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.futureClaim.amount_of_years,
            callback: function callback($$v) {
              _vm.$set(_vm.futureClaim, "amount_of_years", $$v);
            },
            expression: "futureClaim.amount_of_years"
          }
        })], 1), _c('p', [_vm._v(_vm._s(_vm.formatCurrency(_vm.futureClaim.amount_of_years)))])], 1)]), _c('tr', [_c('td', [_vm._v("Estimate Loss Future Until Renewal")]), _c('td', [_c('b-input-group', {
          attrs: {
            "size": "md",
            "prepend": "IDR"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "max-width": "50%"
          },
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.futureClaim.estimate_loss_future,
            callback: function callback($$v) {
              _vm.$set(_vm.futureClaim, "estimate_loss_future", $$v);
            },
            expression: "futureClaim.estimate_loss_future"
          }
        })], 1), _c('p', [_vm._v(_vm._s(_vm.formatCurrency(_vm.futureClaim.estimate_loss_future)))])], 1)])])])])])])])];
      },
      proxy: true
    }], null, false, 2302485330)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-capitalize text-black"
        }, [_c('strong', [_vm._v("Step 3 - Calculation & Forecast Condition")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-forecast-step-3",
            modifiers: {
              "collapse-forecast-step-3": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-window-minimize"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-forecast-step-3",
            "visible": !_vm.isPreview
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-col', {
          staticClass: "text-center mb-3",
          attrs: {
            "cols": "12"
          }
        }, [_vm._l(_vm.typeOfCalculation, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            staticClass: "custom-radio-color-checked",
            attrs: {
              "inline": "",
              "color": item.color,
              "name": "color",
              "value": item.value,
              "disabled": item.disabled
            },
            model: {
              value: _vm.typeCalculation,
              callback: function callback($$v) {
                _vm.typeCalculation = $$v;
              },
              expression: "typeCalculation"
            }
          }, [_c('strong', [_c('h5', [_vm._v(_vm._s(item.label))])])])];
        })], 2)], 1), _c('b-col', {
          staticClass: "mb-4",
          attrs: {
            "md": "6"
          }
        }, [_vm.typeCalculation === 'data' ? _c('b-input-group', {
          attrs: {
            "size": "md",
            "prepend": "Input Your Max Number of Year for Calculation"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.initialCalculationPayload.decrement,
            callback: function callback($$v) {
              _vm.$set(_vm.initialCalculationPayload, "decrement", $$v);
            },
            expression: "initialCalculationPayload.decrement"
          }
        }), _c('b-button', {
          attrs: {
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.getInitialCalculation();
            }
          }
        }, [_vm._v("Check")])], 1) : _vm._e()], 1), _c('b-col', {
          staticClass: "mb-5",
          attrs: {
            "md": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "secondary"
          }
        }, [_c('div', {
          staticClass: "iq-alert-text text-center"
        }, [_vm._v("LOSS RECORD")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped table-warning"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Year")]), _c('th', [_vm._v("Premium")]), _c('th', [_vm._v("Claim Loss")]), _c('th', [_vm._v("Loss Ratio")]), _c('th', [_vm._v("Pure Risk")]), _c('th', [_vm._v("Action")])])]), _c('tbody', [_vm._l(_vm.individualRecords, function (data, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_c('strong', [_vm._v(_vm._s(data.year))])]), _c('td', [_vm._v(_vm._s(_vm.formatCurrency(data.actual_premium)))]), _c('td', [_vm._v(_vm._s(_vm.formatCurrency(data.claim_loss)))]), _c('td', [_vm._v(_vm._s(parseFloat(data.loss_ratio).toFixed(3)) + "%")]), _c('td', [_vm._v(_vm._s(parseFloat(data.pure_risk).toFixed(2)) + "%")]), _c('td', [data.manual ? _c('b-button', {
            staticClass: "mb-3 mr-1",
            attrs: {
              "size": "sm",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                return _vm.deleteIndividualRecord(index);
              }
            }
          }, [_c('i', {
            staticClass: "ri-delete-bin-line"
          })]) : _vm._e()], 1)]);
        }), _c('tr', [_c('td', [_c('strong', [_vm._v(_vm._s(_vm.initialIndividualRecords.year))])]), _c('td', [_vm._v(_vm._s(_vm.formatCurrency(_vm.initialIndividualRecords.actual_premium)))]), _c('td', [_vm._v(_vm._s(_vm.formatCurrency(_vm.initialIndividualRecords.claim_loss)))]), _c('td', [_vm._v(_vm._s(parseFloat(_vm.initialIndividualRecords.loss_ratio).toFixed(3)) + "%")]), _c('td', [_vm._v(_vm._s(isNaN(_vm.initialIndividualRecords.pure_risk) ? 0 : Number(_vm.initialIndividualRecords.pure_risk).toFixed(2)) + "%")])]), _vm.typeCalculation === 'manual' ? _c('tr', {
          staticClass: "bg-white"
        }, [_c('td', [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.yearCalculationOption,
            "placeholder": "Select Year",
            "clearable": false
          },
          model: {
            value: _vm.calculation.year,
            callback: function callback($$v) {
              _vm.$set(_vm.calculation, "year", $$v);
            },
            expression: "calculation.year"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Input Premium"
          },
          on: {
            "input": _vm.setCalculationLossRatio
          },
          model: {
            value: _vm.calculation.actual_premium,
            callback: function callback($$v) {
              _vm.$set(_vm.calculation, "actual_premium", $$v);
            },
            expression: "calculation.actual_premium"
          }
        }), _c('p', [_vm._v(_vm._s(_vm.formatCurrency(_vm.calculation.actual_premium)))])], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Input Claim Loss"
          },
          on: {
            "input": _vm.setCalculationLossRatio
          },
          model: {
            value: _vm.calculation.claim_loss,
            callback: function callback($$v) {
              _vm.$set(_vm.calculation, "claim_loss", $$v);
            },
            expression: "calculation.claim_loss"
          }
        }), _c('p', [_vm._v(_vm._s(_vm.formatCurrency(_vm.calculation.claim_loss)))])], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Input Loss Ratio",
            "readonly": ""
          },
          model: {
            value: _vm.calculation.loss_ratio,
            callback: function callback($$v) {
              _vm.$set(_vm.calculation, "loss_ratio", $$v);
            },
            expression: "calculation.loss_ratio"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Input Pure Risk"
          },
          model: {
            value: _vm.calculation.pure_risk,
            callback: function callback($$v) {
              _vm.$set(_vm.calculation, "pure_risk", $$v);
            },
            expression: "calculation.pure_risk"
          }
        })], 1), _c('td', [_vm.typeCalculation === 'manual' ? _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "warning",
            "block": ""
          },
          on: {
            "click": function click($event) {
              return _vm.insertIndividualRecord(_vm.calculation);
            }
          }
        }, [_vm._v("Submit ")]) : _vm._e()], 1)]) : _vm._e()], 2)])])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "secondary"
          }
        }, [_c('div', {
          staticClass: "iq-alert-text text-center"
        }, [_vm._v("LOADING POINT CALCULATION")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped table-success"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Year")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Acc.Premium")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Acc.Loss")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Acc.Loss Ratio")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Pure Risk")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Insurer Recovery (Month)")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Insurer Recovery")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("Crosscheck")])]), _c('tr', [_c('th', [_vm._v("Kerugian/Keuntungan yang diterima Asuransi")]), _c('th', [_vm._v("%")])])]), _c('tbody', _vm._l(_vm.accumulativeRecords, function (data, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_c('strong', [_vm._v(_vm._s(data.year))])]), _c('td', [_vm._v(_vm._s(_vm.formatCurrency(data.acc_premium)))]), _c('td', [_vm._v(_vm._s(_vm.formatCurrency(data.acc_loss)))]), _c('td', [_vm._v(_vm._s(parseFloat(data.acc_loss_ratio).toFixed(3)) + "%")]), _c('td', [_vm._v(_vm._s(parseFloat(data.pure_risk).toFixed(2)) + "%")]), _c('td', [_vm._v(_vm._s(parseFloat(data.insurer_rec_month).toFixed(2)))]), _c('td', [_vm._v(_vm._s(parseFloat(data.insurer_recovery).toFixed(3)) + "%")]), _c('td', [_vm._v(_vm._s(_vm.formatCurrency(data.keuntungan_kerugian)))]), _c('td', [_vm._v(_vm._s(parseFloat(data.insurer_recovery).toFixed(3)) + "%")])]);
        }), 0)])])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }], null, false, 1789699151)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-capitalize text-black"
        }, [_c('strong', [_vm._v("Step 4 - Final Calculation")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-forecast-step-4",
            modifiers: {
              "collapse-forecast-step-4": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-window-minimize"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-forecast-step-4",
            "visible": !_vm.isPreview
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "mb-5",
          attrs: {
            "md": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "secondary"
          }
        }, [_c('div', {
          staticClass: "iq-alert-text text-center"
        }, [_vm._v("forecasting premium by simple linear calculation")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_vm._v(_vm._s(_vm.textReducePremium)), _c('br'), _c('small', {
          staticClass: "text-success"
        }, [_vm._v("Premium (Current) – Forecast")])]), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('h4', [_vm._v(_vm._s(_vm.formatCurrency(_vm.reducePremium)))])]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_vm._v(_vm._s(_vm.textReducePremiumPercent) + " "), _c('br'), _c('small', {
          staticClass: "text-success"
        }, [_vm._v(_vm._s(_vm.textReducePremium) + " / Premium (Current) x 100%")])]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('h4', [_vm._v(_vm._s(Number(_vm.reducePremiumPercent).toFixed(3)) + "%")])])], 1), _c('b-row', {
          staticClass: "pl-4"
        }, [_c('b-col', {
          staticClass: "mt-5",
          attrs: {
            "md": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "info"
          }
        }, [_c('div', {
          staticClass: "iq-alert-text text-center"
        }, [_vm._v("PESIMISTIC CALCULATION")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Select Company")]), _c('th', [_vm._v("Current Rate")]), _c('th', [_vm._v(_vm._s(_vm.textReducePremium === 'Reduce Premium' ? 'Decrease' : 'Increase')), _c('br')]), _c('th', [_c('strong', [_vm._v("Result")])]), _c('th', [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.pesimisticCalculation, function (data, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [_c('v-select', {
            attrs: {
              "label": "company",
              "options": _vm.companies,
              "placeholder": "Company Name",
              "reduce": function reduce(c) {
                return c.id;
              },
              "multiple": true,
              "selectable": function selectable(c) {
                return _vm.companiesSelected.indexOf(c.id) === -1;
              }
            },
            on: {
              "input": _vm.setCompanyOptions
            },
            model: {
              value: data.companies,
              callback: function callback($$v) {
                _vm.$set(data, "companies", $$v);
              },
              expression: "data.companies"
            }
          })], 1), _c('td', [_c('div', [_vm._l(_vm.pesimisticRateOptions, function (item, index) {
            return [_c('b-form-radio', {
              key: "".concat(i, "-").concat(index),
              staticClass: "custom-radio-color-checked",
              attrs: {
                "inline": "",
                "color": item.color,
                "name": "pesimistic-rate-options-".concat(i, "-").concat(index),
                "id": "".concat(i, "-").concat(index),
                "value": item.value,
                "disabled": item.disabled
              },
              on: {
                "change": function change($event) {
                  return _vm.changeCurrentRate(i);
                }
              },
              model: {
                value: data.pesimisticRate,
                callback: function callback($$v) {
                  _vm.$set(data, "pesimisticRate", $$v);
                },
                expression: "data.pesimisticRate"
              }
            }, [_c('strong', [_c('h5', [_vm._v(_vm._s(item.label))])])])];
          })], 2), _c('div', [_c('b-input-group', {
            attrs: {
              "size": "md",
              "append": "%"
            }
          }, [_c('b-form-input', {
            staticStyle: {
              "max-width": "50%"
            },
            attrs: {
              "type": "number"
            },
            on: {
              "input": function input($event) {
                return _vm.setDecreaseAndResult(data.current_rate, i);
              }
            },
            model: {
              value: data.current_rate,
              callback: function callback($$v) {
                _vm.$set(data, "current_rate", $$v);
              },
              expression: "data.current_rate"
            }
          })], 1)], 1)]), _c('td', [_c('small', [_vm._v(_vm._s(Number(data.decrease).toFixed(3)) + "%")])]), _c('td', [_c('small', [_vm._v(_vm._s(Number(data.result).toFixed(3)) + "%")])]), _c('td', [_c('b-button', {
            staticClass: "mb-3 mr-1",
            attrs: {
              "size": "sm",
              "variant": "danger"
            }
          }, [_c('i', {
            staticClass: "ri-delete-bin-line"
          })])], 1)]);
        }), 0)])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "info",
            "block": ""
          },
          on: {
            "click": _vm.addPesimisticCalculation
          }
        }, [_c('i', {
          staticClass: "ri-add-line"
        }), _vm._v(" Add Data")])], 1)], 1)], 1), _c('b-col', {
          staticClass: "mt-4",
          attrs: {
            "md": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "success"
          }
        }, [_c('div', {
          staticClass: "iq-alert-text text-center"
        }, [_vm._v("OPTIMISTIC CALCULATION")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Company")]), _c('th', [_vm._v("Pesimistic Rate")]), _c('th', [_vm._v("Additional %")]), _c('th', [_c('strong', [_vm._v("Result")])])])]), _c('tbody', _vm._l(_vm.optimisticCalculation, function (data, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._l(data.companies, function (company) {
            return _c('b-badge', {
              key: company.id,
              attrs: {
                "variant": "warning"
              }
            }, [_vm._v(_vm._s(company.name))]);
          }), _vm._v("   ")], 2), _c('td', [_c('i', [_c('strong', [_vm._v(_vm._s(Number(data.rate).toFixed(3)) + "%")])])]), _c('td', [_c('i', [_c('strong', [_vm._v(_vm._s(Number(data.additional).toFixed(3)) + "%")])])]), _c('td', [_c('i', [_c('strong', [_vm._v(_vm._s(Number(data.result).toFixed(3)) + "%")])])])]);
        }), 0)])])], 1)], 1), _c('b-col', {
          staticClass: "mt-5",
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-bordered table-striped shadow"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "width": "50%",
            "colspan": "2",
            "rowspan": "2"
          }
        }, [_vm._v("FINAL RESULT ")]), _c('th', {
          attrs: {
            "colspan": "3"
          }
        }, [_vm._v("FINAL RATE FOR RENEWAL ")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_c('strong', [_vm._v("HM")])]), _c('td', {
          staticClass: "text-center"
        }, [_c('strong', [_vm._v("IV")])]), _c('td', {
          staticClass: "text-center"
        }, [_c('strong', [_vm._v("WAR")])])])]), _c('tbody', _vm._l(_vm.optimisticCalculation, function (data, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._l(data.companies, function (company) {
            return _c('b-badge', {
              key: company.id,
              attrs: {
                "variant": "warning"
              }
            }, [_vm._v(_vm._s(company.name))]);
          }), _vm._v("   ")], 2), _c('td', [_c('strong', [_vm._v(_vm._s(Number(data.result).toFixed(3)) + "%")])]), _c('td', [_c('strong', [_vm._v(_vm._s(Number(data.result).toFixed(3)) + "%")])]), _c('td', [_c('b-input-group', {
            attrs: {
              "size": "md",
              "append": "%"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number",
              "width": "50%"
            },
            on: {
              "change": function change($event) {
                return _vm.handleChangePesimisticCalculationIv(index, $event);
              }
            },
            model: {
              value: data.iv,
              callback: function callback($$v) {
                _vm.$set(data, "iv", $$v);
              },
              expression: "data.iv"
            }
          })], 1)], 1), _c('td', [_c('b-input-group', {
            attrs: {
              "size": "md",
              "append": "%"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number",
              "width": "50%"
            },
            on: {
              "change": function change($event) {
                return _vm.handleChangePesimisticCalculationWar(index, $event);
              }
            },
            model: {
              value: data.war,
              callback: function callback($$v) {
                _vm.$set(data, "war", $$v);
              },
              expression: "data.war"
            }
          })], 1)], 1)]);
        }), 0)])]), _c('b-col', {
          staticClass: "mt-5",
          attrs: {
            "md": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "danger"
          }
        }, [_c('div', {
          staticClass: "iq-alert-text text-center"
        }, [_vm._v("Kurs Manual Input")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_c('b-card', {
          staticClass: "mb-5",
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.kurs",
            modifiers: {
              "kurs": true
            }
          }],
          staticClass: "p-1",
          attrs: {
            "variant": "danger",
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('h4', {
          staticClass: "p-3",
          staticStyle: {
            "cursor": "pointer"
          }
        }, [_vm._v(" Select Your Kurs Data ")])]), _c('b-collapse', {
          attrs: {
            "id": "kurs",
            "visible": true,
            "role": "tabpanel"
          }
        }, [_c('b-card-body', [_c('b-card-text', [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("#")]), _vm._l(_vm.currencies, function (currencyMaster) {
          return currencyMaster.alias !== 'IDR' ? _c('th', {
            key: currencyMaster.id,
            attrs: {
              "scope": "col"
            }
          }, [_vm._v(_vm._s(currencyMaster.alias))]) : _vm._e();
        }), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Update Kurs")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Action")])], 2)]), _c('tbody', [_vm._l(_vm.currenciesMapping, function (currency, index) {
          return _c('tr', {
            key: index,
            on: {
              "click": function click($event) {
                _vm.currencyMappingId = currency.id;
              }
            }
          }, [_c('td', {
            staticClass: "text-center"
          }, [_c('b-form-radio', {
            attrs: {
              "name": "some-radios",
              "value": currency.id
            },
            model: {
              value: _vm.currencyMappingId,
              callback: function callback($$v) {
                _vm.currencyMappingId = $$v;
              },
              expression: "currencyMappingId"
            }
          })], 1), _vm._l(_vm.currencies, function (currencyMaster) {
            return currencyMaster.alias !== 'IDR' ? _c('td', {
              key: currencyMaster.id
            }, [_c('h5', [_vm._v("IDR " + _vm._s(currency.childs.find(function (child) {
              return child.currency_id === currencyMaster.id;
            }) ? currency.childs.find(function (child) {
              return child.currency_id === currencyMaster.id;
            }).value : '-') + ",-")])]) : _vm._e();
          }), _c('td', [_vm._v(" " + _vm._s(currency.date) + " ")]), _c('td')], 2);
        }), _c('tr', [_c('td', {
          staticClass: "text-center"
        }), _vm._l(_vm.formCurrency.contents, function (child) {
          return _c('td', {
            attrs: {
              "scope": "col"
            }
          }, [_c('b-input-group', {
            attrs: {
              "size": "md",
              "prepend": child.currency
            }
          }, [_c('b-form-input', {
            staticStyle: {
              "max-width": "50%"
            },
            attrs: {
              "type": "number"
            },
            model: {
              value: child.value,
              callback: function callback($$v) {
                _vm.$set(child, "value", $$v);
              },
              expression: "child.value"
            }
          })], 1)], 1);
        }), _c('td', [_c('date-picker', {
          attrs: {
            "type": "date",
            "value-type": "YYYY-MM-DD",
            "placeholder": "Select date"
          },
          model: {
            value: _vm.formCurrency.date,
            callback: function callback($$v) {
              _vm.$set(_vm.formCurrency, "date", $$v);
            },
            expression: "formCurrency.date"
          }
        })], 1), _c('td', [_c('b-button', {
          attrs: {
            "disabled": _vm.formCurrency.date === '' || _vm.formCurrency.contents.filter(function (content) {
              return content.value === '';
            }).length > 0,
            "variant": "primary"
          },
          on: {
            "click": _vm.handleSaveCurrencyMapping
          }
        }, [_c('i', {
          staticClass: "fa fa-save"
        }), _vm._v(" Submit ")])], 1)], 2)], 2)])])])], 1)], 1)], 1)], 1)]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-bordered table-striped shadow"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Currency "), _c('small', [_vm._v("!= IDR")])]), _c('th', [_vm._v("Input Kurs to IDR")])])]), _c('tbody', [_vm._l(_vm.currenciesMapping.filter(function (currency) {
          return currency.id === _vm.currencyMappingId;
        }), function (currency, index) {
          return _vm._l(currency.childs, function (type, indexType) {
            return _c('tr', {
              key: indexType
            }, [_c('td', [_c('strong', [_vm._v(_vm._s(type.currency.name))])]), _c('td', [_c('h5', [_c('strong', [_vm._v("IDR " + _vm._s(type.value) + "-")])])])]);
          });
        })], 2)])])], 1)], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 1422955093)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-capitalize text-black"
        }, [_c('strong', [_vm._v("Step 5 - Result Budget Periode "), _c('strong', [_vm._v(_vm._s(_vm.checkPayload.year))])])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('strong', [_vm._v("All Data in TSI (price-fleet) Group by Company & Vehicle Type")]), _c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-forecast-step-5",
            modifiers: {
              "collapse-forecast-step-5": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-window-minimize"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-forecast-step-5",
            "visible": !_vm.isPreview
          }
        }, [_c('b-row', _vm._l(_vm.budgets, function (budget, budgetIndex) {
          return _c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('b-alert', {
            attrs: {
              "show": true,
              "variant": "secondary"
            }
          }, [_c('div', {
            staticClass: "iq-alert-text text-center"
          }, [_vm._v(_vm._s(budget.company))])]), _c('div', {
            staticClass: "table-responsive"
          }, [_c('table', {
            staticClass: "table table-bordered table-striped shadow"
          }, [_c('thead', [_c('tr', {
            staticClass: "text-bold text-center"
          }, [_c('td', {
            attrs: {
              "rowspan": "2"
            }
          }, [_c('strong', [_vm._v("Vehicle Type In TSI")])]), _c('td', {
            staticStyle: {
              "vertical-align": "middle"
            },
            attrs: {
              "colspan": "6"
            }
          }, [_c('strong', [_vm._v("Details")])])]), _c('tr', [_c('td', [_c('strong', [_vm._v("TSI "), _c('small', [_vm._v("(Total in each vehicle type)")])])]), _c('td', [_c('strong', [_vm._v("HM (75%)")])]), _c('td', [_c('strong', [_vm._v("IV (25%)")])]), _c('td', [_c('strong', [_vm._v("WAR")])]), _c('td', [_c('strong', [_vm._v("HM Rate "), _c('small', [_vm._v("{HM 75% x Final Rate (match company)")])])]), _c('td', [_c('strong', [_vm._v("IV Rate "), _c('small', [_vm._v("{IV 25% x Final Rate (match company)")])])]), _c('td', [_c('strong', [_vm._v("WAR Rate")])]), _c('td', [_c('strong', [_vm._v("Sub Total")])])])]), _c('tbody', _vm._l(budget.types, function (type, typeIndex) {
            return _c('tr', [_c('td', [_vm._v(_vm._s(type.name))]), _c('td', [_vm._v(" " + _vm._s(_vm.formatCurrency(type.data.tsi)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatCurrency(type.data.hm)) + " ")]), _c('td', [_vm._v("` " + _vm._s(_vm.formatCurrency(type.data.iv)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatCurrency(type.data.war)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatCurrency(type.data.hm_rate = _vm.budgetHmRateCalculation(budgetIndex, typeIndex, budget.id))) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatCurrency(type.data.iv_rate = _vm.budgetIvRateCalculation(budgetIndex, typeIndex, budget.id))) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatCurrency(type.data.war_rate = _vm.budgetWarRateCalculation(budgetIndex, typeIndex, budget.id))) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatCurrency(type.data.sub_total = Number(type.data.hm_rate) + Number(type.data.iv_rate) + Number(type.data.war_rate))) + " ")])]);
          }), 0), _c('tfoot', [_c('tr', [_c('td', {
            staticClass: "text-right",
            attrs: {
              "colspan": "8"
            }
          }, [_c('strong', [_vm._v("Grand Total (IDR)")])]), _c('td', [_vm._v(_vm._s(_vm.formatCurrency(budget.grand_total = _vm.budgetSubtotalSum(budget.id))))])])])])])], 1);
        }), 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "bg-success text-white p-3"
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_vm._v(" TOTAL SELURUH PREMIUM PERIODE BUDGET TAHUN "), _c('strong', [_vm._v(_vm._s(_vm.checkPayload.year))]), _c('br'), _c('small', [_vm._v("from grand total each company")])]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('h3', {
          staticClass: "text-white"
        }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.budgetGrandTotalSum)))])]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_vm._v("DIFFERENCE "), _c('br'), _vm._v(" "), _c('small', [_vm._v("(-(100%-(Total Seluruh Budget/Premium / Forecast Tanpa Beban)))")])]), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('h3', {
          staticClass: "text-white"
        }, [_vm._v(_vm._s(_vm.forecastPremium > 0 ? (100 - _vm.budgetGrandTotalSum / _vm.forecastPremium).toFixed(2) : 0) + "%")])])], 1)];
      },
      proxy: true
    }], null, false, 2595761846)
  })], 1), _c('b-sidebar', {
    attrs: {
      "id": "show-budget",
      "title": "LIST OF BUDGET",
      "width": "1500px",
      "backdrop-variant": "dark",
      "right": "",
      "backdrop": "",
      "shadow": ""
    }
  }, [_c('b-row', {
    staticClass: "pt-2 pl-3 pr-3 pb-2"
  })], 1)] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }