var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "summary-management-detail-accident-list"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-input', {
    attrs: {
      "placeholder": "Search..."
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  })], 1), _c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height mt-3",
      "body-class": ""
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Detail Accident")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-detail-accident",
            modifiers: {
              "collapse-detail-accident": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-detail-accident",
            "visible": ""
          }
        }, [_c('table', {
          staticClass: "table table-striped mt-3"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("No.")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Name of Vessel")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Type")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Type of Coverage")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Type of Loss")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Cost")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Remarks")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Date of Loss / Created")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Cause of Loss")])])]), _c('tbody', _vm._l(_vm.filterableForms, function (data, index) {
          return _c('tr', {
            key: index
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(index + 1) + ".")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.vehicle.name))]), _c('td', {
            staticClass: "text-center"
          }, [data.type_form === 'claim' ? _c('b-button', {
            ref: "button",
            refInFor: true,
            attrs: {
              "variant": "info"
            }
          }, [_vm._v("Claim")]) : _vm._e(), data.type_form === 'non_claim' ? _c('b-button', {
            ref: "button",
            refInFor: true,
            attrs: {
              "variant": "warning"
            }
          }, [_vm._v("Non Claim ")]) : _vm._e()], 1), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.type_coverage.name))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.type_loss.name))]), _c('td', {
            staticClass: "text-right"
          }, [_c('div', [_c('span', [_vm._v("Estimate Cost: ")]), _c('span', [_vm._v(_vm._s(_vm.formatCurrency(data.cost)))])]), _c('div', [_c('span', [_vm._v("Recovery ")]), _c('span', [_vm._v(_vm._s(_vm.formatCurrency(data.recovery)))])])]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.remarks || '-'))]), _c('td', {
            staticClass: "text-center"
          }, [_c('b-button', {
            ref: "button",
            refInFor: true,
            attrs: {
              "id": "popover-status-".concat(index),
              "variant": "primary"
            }
          }, [_vm._v(_vm._s(data.status ? data.status.name : '-'))])], 1), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(data.date_of_loss) + " "), _c('br'), _c('strong', [_vm._v("PIC: " + _vm._s(data.creator ? data.creator.username : '-'))])]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.cause_of_loss.name))])]);
        }), 0)])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }