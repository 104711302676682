var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Basic Treeview")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-1",
            modifiers: {
              "collapse-1": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-1"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "basic-treeview"
          }
        }, [_c('code', [_vm._v("\n<template>\n    <TreeView\n      :item=\"items\"\n    >\n    </TreeView>\n</template>\n<script>\nimport TreeView from '@components/xray/treeview/TreeView'\nexport default {\ncomponents: { TreeView },\ndata(){\n  return {\n      items: {\n        name: 'Vue',\n        children: [\n          {\n            name: 'public',\n            children: [\n              { name: 'index.html' }\n            ]\n          },\n          {\n            name: 'src',\n            children: [\n              { name: 'assets' },\n              { name: 'views' }\n            ]\n          },\n          { name: '.vue.config.js' }\n        ]\n      }\n    }\n   }\n  }\n</script>\n")])])])])]), _c('ul', {
          staticClass: "p-0"
        }, [_c('TreeView', {
          attrs: {
            "isOpened": true,
            "item": _vm.treeData
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Customize Treeview")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-2",
            modifiers: {
              "collapse-2": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-2"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "customize-treeview"
          }
        }, [_c('code', [_vm._v("\n<template>\n    <TreeView\n      :item=\"items\"\n    >\n      <template v-slot:groupOpened>\n        <img :src=\"require('./assets/images/treeview/t-open-folder.png')\" alt=\"\" class=\"img-fluid height-25 mb-1\">\n      </template>\n      <template v-slot:groupClosed>\n        <img :src=\"require('./assets/images/treeview/t-close-folder.png')\" alt=\"\" class=\"img-fluid height-25 mb-1\">\n      </template>\n      <template v-slot:item>\n        <img :src=\"require('./assets/images/treeview/t-files.png')\" alt=\"\" class=\"img-fluid height-25 mb-1\">\n      </template>\n    </TreeView>\n</template>\n<script>\nimport TreeView from '@components/xray/treeview/TreeView'\nexport default {\ncomponents: { TreeView },\ndata(){\n  return {\n      items: {\n        name: 'Vue',\n        children: [\n          {\n            name: 'public',\n            children: [\n              { name: 'index.html' }\n            ]\n          },\n          {\n            name: 'src',\n            children: [\n              { name: 'assets' },\n              { name: 'views' }\n            ]\n          },\n          { name: '.vue.config.js' }\n        ]\n      }\n    }\n   }\n  }\n</script>\n")])])])])]), _c('ul', {
          staticClass: "p-0"
        }, [_c('TreeView', {
          attrs: {
            "item": _vm.treeData,
            "isOpened": true
          },
          scopedSlots: _vm._u([{
            key: "groupOpened",
            fn: function fn() {
              return [_c('img', {
                staticClass: "img-fluid height-25 mb-1",
                attrs: {
                  "src": require('../../assets/images/treeview/t-open-folder.png'),
                  "alt": ""
                }
              })];
            },
            proxy: true
          }, {
            key: "groupClosed",
            fn: function fn() {
              return [_c('img', {
                staticClass: "img-fluid height-25 mb-1",
                attrs: {
                  "src": require('../../assets/images/treeview/t-close-folder.png'),
                  "alt": ""
                }
              })];
            },
            proxy: true
          }, {
            key: "item",
            fn: function fn() {
              return [_c('img', {
                staticClass: "img-fluid height-25 mb-1",
                attrs: {
                  "src": require('../../assets/images/treeview/t-files.png'),
                  "alt": ""
                }
              })];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }