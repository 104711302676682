<template>
    <b-container fluid>
      <b-row>
        <b-col lg="12">
            <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>PRODUCTS</strong></h5>
                </template>
                <template v-slot:headerAction>
                <a class="text-primary float-right ml-3" v-b-toggle.filter role="button" style="cursor: pointer">
                <i class="fa fa-filter" />
                </a>
                </template>
                <template v-slot:body>
                  <b-collapse visible id="filter" class="mb-4">
                    <b-row>
                      <b-col md="3">
                        <b-form-input id="search" style="height:35px" placeholder="Type Product"></b-form-input>
                      </b-col>
                      <b-col md="1">
                          <b-button variant="primary" size="sm"><i class="fa fa-search"></i></b-button>
                      </b-col>
                      <b-col md="8">
                          <b-button v-b-tooltip.top="'Add Data'" @click="slideAddProduct = !slideAddProduct;" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-plus"></i></b-button>
                          <b-button v-b-tooltip.top="'Refresh Data'" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-refresh"></i></b-button>

                          <b-dropdown v-b-tooltip.top="'Export Data'"  variant="primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                              <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                  <i class="fa fa-save m-0"></i>
                                  </span>
                              </template>
                              <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
                              <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
                          </b-dropdown>
                      </b-col>
                    </b-row>
                  </b-collapse>
                  <b-sidebar
                    v-model="slideAddProduct"
                    id="slideAddProduct"
                    title="NEW DATA"
                    width="60%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="12">
                            <b-form>
                                <b-form-group label="Product Name" label-for="productName">
                                    <b-form-input id="productName" type="text"></b-form-input>
                                </b-form-group>
                                <template v-for="(item,index) in state">
                                    <b-form-radio inline v-model="stateActive[item[Object.keys(item)[0]]]" :name="item.name" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                                </template>
                            </b-form>
                            <hr>
                            <tab-nav :tabs="true" class="text-primary">
                                <tab-nav-items :active="true" id="first-tab" ariaControls="tab-first" role="tab" :ariaSelected="true" title="General Information" />
                                <tab-nav-items :active="false" id="second-tab" ariaControls="tab-second" role="tab" :ariaSelected="false" title="Sales" />
                                <tab-nav-items :active="false" id="third-tab" ariaControls="tab-third" role="tab" :ariaSelected="false" title="Purchase" />
                                <tab-nav-items :active="false" id="fourth-tab" ariaControls="tab-fourth" role="tab" :ariaSelected="false" title="Inventory Stock" />
                                <tab-nav-items :active="false" id="fifth-tab" ariaControls="tab-fifth" role="tab" :ariaSelected="false" title="Accounting" />
                            </tab-nav>
                            <div id="myTabContent" class="tab-content">
                                <tab-content-item :active="true" id="tab-first">
                                    <b-row>
                                        <b-col md="6">
                                            <b-form>
                                                <b-form-group class="row" label-cols-md="4" label="Product Type" label-for="productType">
                                                    <b-form-select plain v-model="selectProductType" :options="optionProductType" size="sm">
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Product Type</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                </b-form-group>
                                                <b-form-group class="row" label-cols-md="4" label="Billing Policy" label-for="billingPolicy">
                                                    <b-form-select plain v-model="selectBillingPolicy" :options="optionBillingPolicy" size="sm">
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Billing Policy</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                </b-form-group>
                                                <b-form-group class="row" label-cols-md="4" label="Product Category" label-for="productCategory">
                                                    <b-form-select plain v-model="selectProductCat" :options="optionProductCat" size="sm">
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Product Category</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                </b-form-group>
                                                <b-form-group class="row" label-cols-md="4" label="Photo Product" label-for="photoProduct">
                                                    <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                        <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                                    </div>
                                                    <b-img thumbnail class="w-50 mt-2" :src="require('../../assets/images/page-img/material.jpg')" alt="Responsive image"></b-img>
                                                </b-form-group>
                                            </b-form>
                                        </b-col>
                                        <b-col md="6">
                                            <b-form>
                                                <b-form-group class="row" label-cols-md="4" label="Item Price" label-for="price">
                                                    <b-form-input type="text" id="price" style="height:35px"></b-form-input>
                                                </b-form-group>
                                                <b-form-group class="row" label-cols-md="4" label="Customer Tax" label-for="customerTax">
                                                    <b-form-input type="number" id="customerTax" style="height:35px"></b-form-input>
                                                </b-form-group>
                                                <b-form-group class="row" label-cols-md="4" label="Cost Production" label-for="costProduction">
                                                    <b-form-input type="number" id="costProduction" style="height:35px"></b-form-input>
                                                </b-form-group>
                                                <b-form-group class="row" label-cols-md="4" label="Reference" label-for="reference">
                                                    <b-form-input type="text" id="reference" style="height:35px"></b-form-input>
                                                </b-form-group>
                                                <b-form-group class="row" label-cols-md="4" label="Barcode" label-for="barcode">
                                                    <b-form-input type="text" id="barcode" style="height:35px"></b-form-input>
                                                </b-form-group>
                                            </b-form>
                                        </b-col>
                                        <b-col md="12">
                                            <b-form-group label="Remarks" label-for="remarks">
                                                <b-form-textarea id="remarks" rows="3"></b-form-textarea>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </tab-content-item>
                                <tab-content-item :active="false" id="tab-second" aria-labelled-by="second-tab">
                                    <b-col md="6">
                                        <b-form>
                                            <b-form-group class="row" label-cols-md="4" label="Product Relate" label-for="productRelate">
                                                <b-form-select plain v-model="selectInventory" :options="optionInventory" size="sm">
                                                    <template v-slot:first>
                                                    <b-form-select-option :value="null">Select Other Products</b-form-select-option>
                                                    </template>
                                                </b-form-select>
                                            </b-form-group>
                                            <b-form-group class="row" label-cols-md="4" label="Description" label-for="taxID">
                                                <b-form-textarea id="address" rows="2"></b-form-textarea>
                                            </b-form-group>
                                        </b-form>
                                    </b-col>
                                </tab-content-item>
                                <tab-content-item :active="false" id="tab-third" aria-labelled-by="third-tab">
                                    <b class="text-primary">OTHER VENDOR</b>
                                    <b-row class="mt-3">
                                        <b-col md="12">
                                            <table class="table table-sm">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center">Vendor Name</th>
                                                        <th class="text-center">Product ID</th>
                                                        <th class="text-center">Product Name</th>
                                                        <th class="text-center">Price</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="pl-3">
                                                            <b-form-input type="text" id="vendorName" style="height:35px"></b-form-input>
                                                        </td>
                                                        <td class="pl-3">
                                                            <b-form-select plain v-model="selectInventory" :options="optionInventory" size="sm">
                                                                <template v-slot:first>
                                                                <b-form-select-option :value="null">Select Other Products</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </td>
                                                        <td class="pl-3">
                                                            <b-form-input type="text" id="vendorName" style="height:35px"></b-form-input>
                                                        </td>
                                                        <td class="pl-3">
                                                            <b-form-input type="number" id="price" style="height:35px"></b-form-input>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="pl-3">
                                                            <b-form-input type="text" id="vendorName" style="height:35px"></b-form-input>
                                                        </td>
                                                        <td class="pl-3">
                                                            <b-form-select plain v-model="selectInventory" :options="optionInventory" size="sm">
                                                                <template v-slot:first>
                                                                <b-form-select-option :value="null">Select Other Products</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                        </td>
                                                        <td class="pl-3">
                                                            <b-form-input type="text" id="vendorName" style="height:35px"></b-form-input>
                                                        </td>
                                                        <td class="pl-3">
                                                            <b-form-input type="number" id="price" style="height:35px"></b-form-input>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <b-button variant="primary" class="float-right" size="sm"><i class="fa fa-plus"></i>Add Row Data</b-button>
                                            <b-button variant="danger" class="float-right mr-1" size="sm"><i class="fa fa-trash"></i>Delete Row Data</b-button>
                                        </b-col>
                                        <b-col md="12"><hr></b-col>
                                        <b-col md="6">
                                            <b-form>
                                                <b-form-group label="TAX" label-cols-md="4" label-for="tax">
                                                    <b-form-input type="text" id="tax" style="height:35px"></b-form-input>
                                                </b-form-group>
                                                <b-form-group label="Term Payment" label-cols-md="4" label-for="Term Payment">
                                                    <template v-for="(item,index) in term">
                                                        <b-form-radio inline v-model="stateActive[item[Object.keys(item)[0]]]" :name="item.name" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                                                    </template>
                                                </b-form-group>
                                                <b-form-group label="Description" label-cols-md="4" label-for="description">
                                                    <b-form-textarea id="description" rows="2"></b-form-textarea>
                                                </b-form-group>
                                            </b-form>
                                        </b-col>
                                    </b-row>
                                </tab-content-item>
                                <tab-content-item :active="false" id="tab-fourth" aria-labelled-by="fourth-tab">
                                    <b-row>
                                        <b-col md="6">
                                            <b class="text-primary">OPERATIONS</b>
                                            <hr>
                                            <b-form-group class="row" label-cols-md="4" label="Item Price" label-for="price">
                                                <template v-for="(item,index) in invent">
                                                    <b-checkbox class="custom-switch-color" :color="item.color" v-model="item.checked" :disabled="item.disabled" name="check-button" inline :key="index">
                                                        {{ item.label }}
                                                    </b-checkbox>
                                                </template>
                                            </b-form-group>
                                            <b-form>
                                                <b-form-group class="row" label-cols-md="4" label="Description Receipt" label-for="descReceipt">
                                                    <b-form-textarea id="descReceipt" rows="2"></b-form-textarea>
                                                </b-form-group>
                                            </b-form>
                                        </b-col>
                                        <b-col md="6">
                                            <b class="text-primary">LOGISTIC</b>
                                            <hr>
                                            <b-form-group class="row" label-cols-md="4" label="PIC" label-for="pic">
                                                <div class="input-group">
                                                    <input type="number" class="form-control" id="pic" style="height:35px">
                                                </div>
                                            </b-form-group>
                                            <b-form-group class="row" label-cols-md="4" label="Weight" label-for="weight">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text bg-primary" id="inputGroupPrepend2">KG</span>
                                                    </div>
                                                    <input type="number" class="form-control" id="weight" style="height:35px">
                                                </div>
                                            </b-form-group>
                                            <b-form-group class="row" label-cols-md="4" label="Volume" label-for="volume">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text bg-primary" id="inputGroupPrepend2">M2</span>
                                                    </div>
                                                    <input type="number" class="form-control" id="volume" style="height:35px">
                                                </div>
                                            </b-form-group>
                                            <b-form-group class="row" label-cols-md="4" label="Production Days" label-for="daysProduction">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text bg-primary" id="inputGroupPrepend2">Day</span>
                                                    </div>
                                                    <input type="number" class="form-control" id="volume" style="height:35px">
                                                </div>
                                            </b-form-group> 
                                            <b-form>
                                                <b-form-group class="row" label-cols-md="4" label="Description Logistic" label-for="descLogistic">
                                                    <b-form-textarea id="descLogistic" rows="2"></b-form-textarea>
                                                </b-form-group>
                                            </b-form>
                                        </b-col>
                                    </b-row>
                                </tab-content-item>
                                <tab-content-item :active="false" id="tab-fifth" aria-labelled-by="fourth-tab">
                                    <b-row>
                                        <b-col md="6">
                                            <b class="text-primary">KREDIT ACCOUNT</b>
                                            <hr>
                                            <b-form-select plain v-model="selectBankAccount" :options="optionBankAccount" size="sm">
                                                <template v-slot:first>
                                                    <b-form-select-option :value="null">Select Bank Account</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                        </b-col>
                                        <b-col md="6">
                                            <b class="text-primary">DEBIT ACCOUNT</b>
                                            <hr>
                                            <b-form-select plain v-model="selectBankAccount" :options="optionBankAccount" size="sm">
                                                <template v-slot:first>
                                                    <b-form-select-option :value="null">Select Bank Account</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                        </b-col>
                                    </b-row>
                                </tab-content-item>
                            </div>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <b-row>
                    <b-col sm="3" class="text-center">
                        <b-card
                        :img-src="require('../../assets/images/page-img/material.jpg')"
                        img-alt="#"
                        img-top>
                        <a href="#" v-b-modal.detailVendor><h5 class="text-primary">Engine Oil Filter</h5></a>
                        <p>
                            <strong>Product Type : </strong> Material<br>
                            <strong>Harga : </strong> Rp. 0,00<br>
                            <strong>Stock : </strong> 0,00 pcs<br>
                        </p>
                        </b-card>
                    </b-col>
                    <b-col sm="3" class="text-center">
                        <b-card
                        :img-src="require('../../assets/images/page-img/material.jpg')"
                        img-alt="#"
                        img-top>
                        <a href="#" v-b-modal.detailVendor><h5 class="text-primary">Hydraulic Oil Filter</h5></a>
                        <p>
                            <strong>Product Type : </strong> Material<br>
                            <strong>Harga : </strong> Rp. 0,00<br>
                            <strong>Stock : </strong> 0,00 pcs<br>
                        </p>
                        </b-card>
                    </b-col>
                    <b-col sm="3" class="text-center">
                        <b-card
                        :img-src="require('../../assets/images/page-img/material.jpg')"
                        img-alt="#"
                        img-top>
                        <a href="#" v-b-modal.detailVendor><h5 class="text-primary">V-BELT MITSHUBISHI</h5></a>
                        <p>
                            <strong>Product Type : </strong> Material<br>
                            <strong>Harga : </strong> Rp. 0,00<br>
                            <strong>Stock : </strong> 0,00 pcs<br>
                        </p>
                        </b-card>
                    </b-col>
                    <b-col sm="3" class="text-center">
                        <b-card
                        :img-src="require('../../assets/images/page-img/material.jpg')"
                        img-alt="#"
                        img-top>
                        <a href="#" v-b-modal.detailVendor><h5 class="text-primary">FO FILTER ELEMENT ASSY</h5></a>
                        <p>
                            <strong>Product Type : </strong> Material<br>
                            <strong>Harga : </strong> Rp. 0,00<br>
                            <strong>Stock : </strong> 0,00 pcs<br>
                        </p>
                        </b-card>
                    </b-col>
                    <b-col md="12">
                        <div class="mt-3">
                            <b-pagination :value="1" :total-rows="50" align="center" />
                        </div>
                    </b-col>
                  </b-row>
                </template>
            </iq-card>
        </b-col>
      </b-row>
    </b-container>
  </template>
  <script>
  import { xray } from '../../config/pluginInit'
  export default {
    name: 'procurementProdcuts',
    mounted () {
      xray.index()
    },
    data () {
        return {
            // Sliding Page
            slideAddProduct: false,
            stateActive: {
                single: 'active'
            },
            state: [
                {
                name: 'single',
                label: 'Can be Sell',
                value: 'inactive'
                },
                {
                name: 'single',
                label: 'Can be Buy',
                value: 'active'
                }
            ],
            term: [
                {
                name: 'single',
                label: 'Qty Order',
                value: 'inactive',
                disabled: false
                },
                {
                name: 'single',
                label: 'Qty Receipt',
                value: 'active',
                disabled: false
                }
            ],
            invent: [
                {
                name: 'buy',
                label: 'Buy',
                value: 'buy',
                disabled: false
                },
                {
                name: 'production',
                label: 'Production',
                value: 'production',
                disabled: false
                }
            ],
            selectProductType: null,
            optionProductType: [
                { value: '1', text: 'Consumable' },
                { value: '2', text: 'Service' },
                { value: '3', text: 'Storeable' }
            ],
            selectProductCat: null,
            optionProductCat: [
                { value: '1', text: 'All' },
                { value: '2', text: 'All / Expenses' },
                { value: '3', text: 'All / Saleable' }
            ],
            selectBillingPolicy: null,
            optionBillingPolicy: [
                { value: '1', text: 'Total Deliver' },
                { value: '2', text: 'Total Order' }
            ],
            selectInventory: null,
            optionInventory: [
                { value: '1', text: '[M001] - Engine Oil Filter' },
                { value: '2', text: '[M002] - V Belt Altenator' },
                { value: '3', text: '[M003] - Nozle Injector' }
            ],
            selectBankAccount: null,
            optionBankAccount: [
                { value: '1', text: '[110001] - Akun Dummy 1' },
                { value: '2', text: '[110002] - Akun Dummy 2' },
                { value: '3', text: '[110003] - Akun Dummy 3' }
            ],
        }
    }
  }
  </script>