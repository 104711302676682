var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("HISTORY SERVICE REPAIR")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$list_table_finish, _vm$list_table_finish2, _vm$list_table_finish3;
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("FLEET NAME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL COST")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL REPAIR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("MINOR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("MEDIUM")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("MAJOR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', _vm._l((_vm$list_table_finish = _vm.list_table_finished) === null || _vm$list_table_finish === void 0 ? void 0 : _vm$list_table_finish.data, function (state, index) {
          var _state$vehicle, _state$status, _state$status2, _state$status3, _state$classfication, _state$classfication2, _state$classfication3;
          return _c('tr', {
            key: 'finish-' + index
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(index + 1))]), _c('td', {
            staticClass: "text-left"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$vehicle = state.vehicle) === null || _state$vehicle === void 0 ? void 0 : _state$vehicle.name))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.formatCurrency(state === null || state === void 0 ? void 0 : state.total_cost_real)))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.total_repair) + " Jobs ")]), _c('td', {
            staticClass: "text-center"
          }, [_c('b-badge', {
            staticClass: "mb-1",
            attrs: {
              "variant": "info"
            }
          }, [_vm._v("Earlier : " + _vm._s(state === null || state === void 0 ? void 0 : (_state$status = state.status) === null || _state$status === void 0 ? void 0 : _state$status.earlier) + " Jobs")]), _vm._v(" "), _c('br'), _c('b-badge', {
            staticClass: "mb-1",
            attrs: {
              "variant": "success"
            }
          }, [_vm._v("On Time : " + _vm._s(state === null || state === void 0 ? void 0 : (_state$status2 = state.status) === null || _state$status2 === void 0 ? void 0 : _state$status2.onTime) + " Jobs")]), _c('br'), _c('b-badge', {
            attrs: {
              "variant": "danger"
            }
          }, [_vm._v("Delay : " + _vm._s(state === null || state === void 0 ? void 0 : (_state$status3 = state.status) === null || _state$status3 === void 0 ? void 0 : _state$status3.late) + " Jobs")])], 1), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$classfication = state.classfication) === null || _state$classfication === void 0 ? void 0 : _state$classfication.minor) + " Jobs")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$classfication2 = state.classfication) === null || _state$classfication2 === void 0 ? void 0 : _state$classfication2.medium) + " Jobs")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$classfication3 = state.classfication) === null || _state$classfication3 === void 0 ? void 0 : _state$classfication3.major) + " Jobs")]), _c('td', {
            staticClass: "text-center"
          }, [_c('b-dropdown', {
            attrs: {
              "size": "sm",
              "variant": "link iq-bg-primary iq-border-radius-10",
              "toggle-class": "text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton5",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-pencil-square-o m-0 text-primary"
                })])];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            attrs: {
              "href": "#"
            }
          }, [_c('router-link', {
            attrs: {
              "to": {
                path: "/sr/history",
                query: {
                  vehicleId: state === null || state === void 0 ? void 0 : state.vehicle_id
                }
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-ship mr-2"
          }), _vm._v("Detail History ")])], 1)], 1)], 1)]);
        }), 0)]), ((_vm$list_table_finish2 = _vm.list_table_finished) === null || _vm$list_table_finish2 === void 0 ? void 0 : (_vm$list_table_finish3 = _vm$list_table_finish2.data) === null || _vm$list_table_finish3 === void 0 ? void 0 : _vm$list_table_finish3.length) > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.list_table_finished.meta.total_item,
            "per-page": _vm.list_table_finished.meta.per_page,
            "aria-controls": "my-table",
            "align": "center",
            "first-number": ""
          },
          on: {
            "change": _vm.getListFinishedTableServiceAndRepair
          },
          model: {
            value: _vm.list_table_finished.meta.page,
            callback: function callback($$v) {
              _vm.$set(_vm.list_table_finished.meta, "page", $$v);
            },
            expression: "list_table_finished.meta.page"
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }