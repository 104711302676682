var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Add New User")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form-group', [_c('div', {
          staticClass: "add-img-user profile-img-edit"
        }, [_c('b-img', {
          staticClass: "profile-pic height-150 width-150",
          attrs: {
            "fluid": "",
            "src": _vm._f("checkPhoto")(_vm.user.photo, require('@src/assets/images/user/01.jpg')),
            "alt": "Profile Picture"
          }
        }), _c('div', {
          staticClass: "p-image"
        }, [_c('b-button', {
          staticClass: "upload-button iq-bg-primary position-relative",
          attrs: {
            "variant": "none",
            "disabled": _vm.loading_upload
          }
        }, [_c('input', {
          staticClass: "h-100 position-absolute",
          staticStyle: {
            "opacity": "0"
          },
          attrs: {
            "type": "file",
            "accept": "image/png, image/jpeg"
          },
          on: {
            "change": _vm.onChangePhoto
          }
        }), _vm._v(" " + _vm._s(_vm.loading_upload ? 'Uploading...' : 'Upload Photo') + " ")])], 1)], 1), _c('div', {
          staticClass: "img-extension my-3"
        }, [_c('div', {
          staticClass: "d-inline-block align-items-center"
        }, [_c('span', [_vm._v("Only")]), _c('b-link', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_vm._v(".jpg")]), _c('b-link', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_vm._v(".png")]), _c('b-link', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_vm._v(".jpeg")]), _c('span', [_vm._v("allowed")])], 1)])]), _c('b-form-group', {
          attrs: {
            "label": "*Username:",
            "label-for": "uname"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "Username",
            "rules": "required|max:30"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var errors = _ref.errors;
              return [_c('b-form-input', {
                class: errors.length > 0 ? ' is-invalid' : '',
                attrs: {
                  "type": "text",
                  "placeholder": "Username"
                },
                model: {
                  value: _vm.user.username,
                  callback: function callback($$v) {
                    _vm.$set(_vm.user, "username", $$v);
                  },
                  expression: "user.username"
                }
              }), _c('div', {
                staticClass: "invalid-feedback"
              }, [_c('span', [_vm._v(_vm._s(errors[0]))])])];
            }
          }])
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "*Email:",
            "label-for": "email"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "Email",
            "rules": "required|email|max:100"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('b-form-input', {
                class: errors.length > 0 ? ' is-invalid' : '',
                attrs: {
                  "type": "text",
                  "placeholder": "Email"
                },
                model: {
                  value: _vm.user.email,
                  callback: function callback($$v) {
                    _vm.$set(_vm.user, "email", $$v);
                  },
                  expression: "user.email"
                }
              }), _c('div', {
                staticClass: "invalid-feedback"
              }, [_c('span', [_vm._v(_vm._s(errors[0]))])])];
            }
          }])
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "*Password:",
            "label-for": "pass"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "Password",
            "rules": "required|min:6"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('b-form-input', {
                class: errors.length > 0 ? ' is-invalid' : '',
                attrs: {
                  "type": "password",
                  "placeholder": "Password"
                },
                model: {
                  value: _vm.user.password,
                  callback: function callback($$v) {
                    _vm.$set(_vm.user, "password", $$v);
                  },
                  expression: "user.password"
                }
              }), _c('div', {
                staticClass: "invalid-feedback"
              }, [_c('span', [_vm._v(_vm._s(errors[0]))])])];
            }
          }])
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "*Repeat Password:",
            "label-for": "rpass"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "vid": "repeat_password",
            "name": "Repeat Password",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('b-form-input', {
                class: errors.length > 0 ? ' is-invalid' : '',
                attrs: {
                  "type": "password",
                  "placeholder": "Repeat Password"
                },
                model: {
                  value: _vm.confirm_password,
                  callback: function callback($$v) {
                    _vm.confirm_password = $$v;
                  },
                  expression: "confirm_password"
                }
              }), _c('div', {
                staticClass: "invalid-feedback"
              }, [_c('span', [_vm._v(_vm._s(errors[0]))])])];
            }
          }])
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "9"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("New User Information")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "*Name:",
            "label-for": "fname"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "name",
            "rules": "required|max:100"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var errors = _ref5.errors;
              return [_c('b-form-input', {
                class: errors.length > 0 ? ' is-invalid' : '',
                attrs: {
                  "type": "text",
                  "placeholder": "Name"
                },
                model: {
                  value: _vm.user.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.user, "name", $$v);
                  },
                  expression: "user.name"
                }
              }), _c('div', {
                staticClass: "invalid-feedback"
              }, [_c('span', [_vm._v(_vm._s(errors[0]))])])];
            }
          }])
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "*Mobile Number:",
            "label-for": "mobno"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": "Mobile Number",
            "rules": "required|max:30"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('b-form-input', {
                class: errors.length > 0 ? ' is-invalid' : '',
                attrs: {
                  "type": "number",
                  "placeholder": "Mobile Number"
                },
                model: {
                  value: _vm.user.phone,
                  callback: function callback($$v) {
                    _vm.$set(_vm.user, "phone", $$v);
                  },
                  expression: "user.phone"
                }
              }), _c('div', {
                staticClass: "invalid-feedback"
              }, [_c('span', [_vm._v(_vm._s(errors[0]))])])];
            }
          }])
        })], 1), _c('b-form-group', {
          staticClass: "col-sm-6",
          attrs: {
            "label-for": "gender",
            "label": "*Gender:"
          }
        }, [_c('b-form-select', {
          attrs: {
            "id": "gender",
            "plain": "",
            "options": _vm.genders
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Gender")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.gender,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "gender", $$v);
            },
            expression: "user.gender"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "*Birthday:",
            "label-for": "birthday"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "birthday",
            "type": "date"
          },
          model: {
            value: _vm.user.birthday,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "birthday", $$v);
            },
            expression: "user.birthday"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1), _c('b-form-group', {
          staticClass: "col-md-12",
          attrs: {
            "label": "*Street Address:",
            "label-for": "add1"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "add1",
            "name": "add1",
            "placeholder": "Street Address"
          },
          model: {
            value: _vm.user.address,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "address", $$v);
            },
            expression: "user.address"
          }
        })], 1)], 1), _c('hr'), _c('h5', {
          staticClass: "mb-3"
        }, [_vm._v("Role & Privilege")]), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "*User Role:",
            "label-for": "selectuserrole"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "roles",
            "options": _vm.roles,
            "reduce": function reduce(roles) {
              return roles.id;
            }
          },
          on: {
            "input": _vm.fetchMenus
          },
          model: {
            value: _vm.user.roleId,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "roleId", $$v);
            },
            expression: "user.roleId"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "*Company Name:",
            "label-for": "cname"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "company",
            "options": _vm.companies,
            "placeholder": "Company Name",
            "reduce": function reduce(c) {
              return c.id;
            }
          },
          model: {
            value: _vm.user.companyId,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "companyId", $$v);
            },
            expression: "user.companyId"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "*Department Name:",
            "label-for": "cname"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.departments,
            "placeholder": "Department Name",
            "reduce": function reduce(d) {
              return d.id;
            }
          },
          model: {
            value: _vm.user.departmentId,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "departmentId", $$v);
            },
            expression: "user.departmentId"
          }
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_vm.loading_menu ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.menus.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("No.")]), _c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Data")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Add")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Update")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Delete")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Export")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Review")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Approval")])])]), _c('tbody', [_vm._l(_vm.menus, function (item, key) {
          return [_c('tr', {
            key: "menu-".concat(key)
          }, [_c('td', {
            staticClass: "text-center"
          }, [_c('b-form-checkbox', {
            attrs: {
              "name": "basic-checkbox-".concat(key)
            },
            on: {
              "change": function change($event) {
                return _vm.selectMenu(key);
              }
            },
            model: {
              value: _vm.checkedMenus[key].active,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "active", $$v);
              },
              expression: "checkedMenus[key].active"
            }
          })], 1), _c('td', [_vm._v(_vm._s(item.menu))]), _c('td', {
            staticClass: "text-center"
          }, [item.add ? [_c('b-form-checkbox', {
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change($event) {
                return _vm.handleParentActive(key);
              }
            },
            model: {
              value: _vm.checkedMenus[key].add,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "add", $$v);
              },
              expression: "checkedMenus[key].add"
            }
          })] : [_vm._v(" ")]], 2), _c('td', {
            staticClass: "text-center"
          }, [item.update ? [_c('b-form-checkbox', {
            key: key,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change($event) {
                return _vm.handleParentActive(key);
              }
            },
            model: {
              value: _vm.checkedMenus[key].update,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "update", $$v);
              },
              expression: "checkedMenus[key].update"
            }
          })] : [_vm._v(" ")]], 2), _c('td', {
            staticClass: "text-center"
          }, [item.delete ? [_c('b-form-checkbox', {
            key: key,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change($event) {
                return _vm.handleParentActive(key);
              }
            },
            model: {
              value: _vm.checkedMenus[key].delete,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "delete", $$v);
              },
              expression: "checkedMenus[key].delete"
            }
          })] : [_vm._v(" ")]], 2), _c('td', {
            staticClass: "text-center"
          }, [item.export ? [_c('b-form-checkbox', {
            key: key,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change($event) {
                return _vm.handleParentActive(key);
              }
            },
            model: {
              value: _vm.checkedMenus[key].export,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "export", $$v);
              },
              expression: "checkedMenus[key].export"
            }
          })] : [_vm._v(" ")]], 2), _c('td', {
            staticClass: "text-center"
          }, [item.review ? [_c('b-form-checkbox', {
            key: key,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change($event) {
                return _vm.handleParentActive(key);
              }
            },
            model: {
              value: _vm.checkedMenus[key].review,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "review", $$v);
              },
              expression: "checkedMenus[key].review"
            }
          })] : [_vm._v(" ")]], 2), _c('td', {
            staticClass: "text-center"
          }, [item.approval ? [_c('b-form-checkbox', {
            key: key,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            on: {
              "change": function change($event) {
                return _vm.handleParentActive(key);
              }
            },
            model: {
              value: _vm.checkedMenus[key].approval,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "approval", $$v);
              },
              expression: "checkedMenus[key].approval"
            }
          })] : [_vm._v(" ")]], 2)]), _vm._l(item.child, function (subMenuItem, keySub) {
            return [_c('tr', {
              key: "submenu-".concat(key).concat(keySub)
            }, [_c('td', {
              staticClass: "text-center"
            }, [_c('b-form-checkbox', {
              attrs: {
                "name": "submenu-ck-".concat(keySub)
              },
              on: {
                "change": function change($event) {
                  return _vm.selectMenu(key, keySub, false);
                }
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].active,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "active", $$v);
                },
                expression: "\n                                    checkedMenus[key].child[keySub].active\n                                  "
              }
            })], 1), _c('td', [_c('span', {
              staticStyle: {
                "margin-right": "60px"
              }
            }), _vm._v(_vm._s(subMenuItem.menu))]), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.add ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": ""
              },
              on: {
                "change": function change($event) {
                  return _vm.handleParentActive(key, keySub, false);
                }
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].add,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "add", $$v);
                },
                expression: "\n                                      checkedMenus[key].child[keySub].add\n                                    "
              }
            })] : [_vm._v(" ")]], 2), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.update ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": ""
              },
              on: {
                "change": function change($event) {
                  return _vm.handleParentActive(key, keySub, false);
                }
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].update,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "update", $$v);
                },
                expression: "\n                                      checkedMenus[key].child[keySub].update\n                                    "
              }
            })] : [_vm._v(" ")]], 2), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.delete ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": ""
              },
              on: {
                "change": function change($event) {
                  return _vm.handleParentActive(key, keySub, false);
                }
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].delete,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "delete", $$v);
                },
                expression: "\n                                      checkedMenus[key].child[keySub].delete\n                                    "
              }
            })] : [_vm._v(" ")]], 2), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.export ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": ""
              },
              on: {
                "change": function change($event) {
                  return _vm.handleParentActive(key, keySub, false);
                }
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].export,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "export", $$v);
                },
                expression: "\n                                      checkedMenus[key].child[keySub].export\n                                    "
              }
            })] : [_vm._v(" ")]], 2), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.review ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": ""
              },
              on: {
                "change": function change($event) {
                  return _vm.handleParentActive(key, keySub, false);
                }
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].review,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "review", $$v);
                },
                expression: "\n                                      checkedMenus[key].child[keySub].review\n                                    "
              }
            })] : [_vm._v(" ")]], 2), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.approval ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": ""
              },
              on: {
                "change": function change($event) {
                  return _vm.handleParentActive(key, keySub, false);
                }
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].approval,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "approval", $$v);
                },
                expression: "\n                                      checkedMenus[key].child[keySub].approval\n                                    "
              }
            })] : [_vm._v(" ")]], 2)]), _vm._l(subMenuItem.child, function (tabMenuItem, keyTab) {
              return [_c('tr', {
                key: "keytab-".concat(key).concat(keySub).concat(keyTab)
              }, [_c('td', {
                staticClass: "text-center"
              }, [_c('b-form-checkbox', {
                attrs: {
                  "name": "tab-ck-".concat(keySub)
                },
                on: {
                  "change": function change($event) {
                    return _vm.selectMenu(key, keySub, false, true, keyTab);
                  }
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].active,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "active", $$v);
                  },
                  expression: "\n                                      checkedMenus[key].child[keySub].child[\n                                        keyTab\n                                      ].active\n                                    "
                }
              })], 1), _c('td', [_c('span', {
                staticStyle: {
                  "margin-right": "100px"
                }
              }), _vm._v(_vm._s(tabMenuItem.menu))]), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.add ? [_c('b-form-checkbox', {
                key: keySub,
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": ""
                },
                on: {
                  "change": function change($event) {
                    return _vm.handleParentActive(key, keySub, false, true, keyTab);
                  }
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].add,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "add", $$v);
                  },
                  expression: "\n                                        checkedMenus[key].child[keySub].child[\n                                          keyTab\n                                        ].add\n                                      "
                }
              })] : [_vm._v(" ")]], 2), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.update ? [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": ""
                },
                on: {
                  "change": function change($event) {
                    return _vm.handleParentActive(key, keySub, false, true, keyTab);
                  }
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].update,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "update", $$v);
                  },
                  expression: "\n                                        checkedMenus[key].child[keySub].child[\n                                          keyTab\n                                        ].update\n                                      "
                }
              })] : [_vm._v(" ")]], 2), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.delete ? [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": ""
                },
                on: {
                  "change": function change($event) {
                    return _vm.handleParentActive(key, keySub, false, true, keyTab);
                  }
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].delete,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "delete", $$v);
                  },
                  expression: "\n                                        checkedMenus[key].child[keySub].child[\n                                          keyTab\n                                        ].delete\n                                      "
                }
              })] : [_vm._v(" ")]], 2), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.export ? [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": ""
                },
                on: {
                  "change": function change($event) {
                    return _vm.handleParentActive(key, keySub, false, true, keyTab);
                  }
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].export,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "export", $$v);
                  },
                  expression: "\n                                        checkedMenus[key].child[keySub].child[\n                                          keyTab\n                                        ].export\n                                      "
                }
              })] : [_vm._v(" ")]], 2), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.review ? [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": ""
                },
                on: {
                  "change": function change($event) {
                    return _vm.handleParentActive(key, keySub, false, true, keyTab);
                  }
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].review,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "review", $$v);
                  },
                  expression: "\n                                        checkedMenus[key].child[keySub].child[\n                                          keyTab\n                                        ].review\n                                      "
                }
              })] : [_vm._v(" ")]], 2), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.approval ? [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": ""
                },
                on: {
                  "change": function change($event) {
                    return _vm.handleParentActive(key, keySub, false, true, keyTab);
                  }
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].approval,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "approval", $$v);
                  },
                  expression: "\n                                        checkedMenus[key].child[keySub].child[\n                                          keyTab\n                                        ].approval\n                                      "
                }
              })] : [_vm._v(" ")]], 2)])];
            })];
          })];
        })], 2)])]) : _c('div', {
          staticClass: "text-center my-5"
        }, [_c('p', {
          staticClass: "mb-0 text-muted"
        }, [_vm._v("No privilege data found on selected role.")])])]], 2)], 1), _c('hr'), _c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('h5', {
          staticClass: "mb-3"
        }, [_vm._v("Fleet Responsible")])]), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Search fleets..."
          },
          on: {
            "input": _vm.fetchFleets
          },
          model: {
            value: _vm.fleet_params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.fleet_params, "search", $$v);
            },
            expression: "fleet_params.search"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.select_all_fleets,
            callback: function callback($$v) {
              _vm.select_all_fleets = $$v;
            },
            expression: "select_all_fleets"
          }
        }, [_vm._v(" Select All ")])], 1)], 1), _vm.loading_fleet ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_c('small', {
          staticClass: "text-muted"
        }, [_vm._v("click images for checked / unchecked")]), _vm.fleets.length > 0 ? _c('b-row', _vm._l(_vm.fleets, function (fleet, i) {
          return _c('b-col', {
            key: "fleet-".concat(i),
            staticClass: "text-center py-5 border-top",
            attrs: {
              "md": "3"
            }
          }, [_c('div', {
            staticClass: "custom-control custom-checkbox image-checkbox"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.checkedFleets,
              expression: "checkedFleets"
            }],
            staticClass: "custom-control-input",
            attrs: {
              "id": "ck-fl-".concat(i),
              "type": "checkbox"
            },
            domProps: {
              "value": fleet.id,
              "checked": Array.isArray(_vm.checkedFleets) ? _vm._i(_vm.checkedFleets, fleet.id) > -1 : _vm.checkedFleets
            },
            on: {
              "change": function change($event) {
                var $$a = _vm.checkedFleets,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = fleet.id,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && (_vm.checkedFleets = $$a.concat([$$v]));
                  } else {
                    $$i > -1 && (_vm.checkedFleets = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.checkedFleets = $$c;
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": "ck-fl-".concat(i)
            }
          }, [_c('img', {
            staticClass: "img-fluid",
            attrs: {
              "src": _vm._f("checkPhoto")(fleet.vehicleType.icon.url, require('@src/assets/images/fleet/PusherBarge.png')),
              "alt": "#"
            }
          })]), _c('small', [_c('strong', [_vm._v(_vm._s(fleet.name))])])])]);
        }), 1) : _c('div', {
          staticClass: "text-center my-5"
        }, [_c('p', {
          staticClass: "mb-0 text-muted"
        }, [_vm._v("No fleets data found.")])])], _c('div', {
          staticClass: "text-right"
        }, [_c('router-link', {
          attrs: {
            "to": {
              name: 'setting.users',
              query: {
                menuObject: _vm.$route.query.menuObject
              }
            }
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-2",
          attrs: {
            "variant": "light"
          }
        }, [_vm._v("Cancel")])], 1), _c('b-button', {
          staticClass: "mb-3",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.doRegister
          }
        }, [!_vm.loading ? [_vm._v(" Register User ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("Loading...")])]], 2), _c('b-card-text', [_c('small', {
          staticClass: "text-muted"
        }, [_vm._v("Sign * : "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("Mandatory (Must be Input)")])], 1)])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }