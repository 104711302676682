var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": true,
            "href": "#power",
            "title": "MASTER DATA POWER"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": false,
            "href": "#rob",
            "title": "MASTER DATA ROB TYPE"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "power"
    }
  }, [_c('tab-nav', {
    attrs: {
      "tabs": true,
      "align": "center"
    }
  }, [_c('tab-nav-items', {
    attrs: {
      "active": true,
      "href": "#powerList",
      "title": "Master Data Type of Power"
    }
  }), _c('tab-nav-items', {
    attrs: {
      "active": false,
      "href": "#series",
      "title": "Master Data Series of Power"
    }
  })], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "myTabContent"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "powerList"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER DATA POWER")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.getTypePowerList();
            }
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Master Data Power:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.powersTypeList.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.powersTypeList.params, "search", $$v);
            },
            expression: "powersTypeList.params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Master Data Type of Power")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.powersTypeList._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.powersTypeList.data && _vm.powersTypeList.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("List of Power")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.powersTypeList.data, function (p, i) {
          return _c('tr', {
            key: "p-".concat(i)
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.powersTypeList.meta.currentPage > 1 ? _vm.powersTypeList.meta.perPage + i + 1 : i + 1))]), _c('td', [_vm._v(_vm._s(p.name))]), _c('td', [_vm._v(_vm._s(p.active ? 'Active' : 'Inactive'))]), _c('td', [_c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.toEditPower(p, 'types');
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeletePowerType(p);
              }
            }
          }, [!_vm.loading_delete_power ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v("Deleting... ")]], 2)], 1)]);
        }), 0)])]) : _vm._e(), _vm.powersTypeList.data && _vm.powersTypeList.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.powersTypeList.meta.total,
            "per-page": _vm.powersTypeList.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getTypePowerList
          },
          model: {
            value: _vm.powersTypeList.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.powersTypeList.meta, "currentPage", $$v);
            },
            expression: "powersTypeList.meta.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No powers data found.")])]];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.form_power_edit_mode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.form_power_edit_mode ? 'Edit' : 'Add') + " Power")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', [_c('b-form-group', {
          attrs: {
            "label": "Power Name",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "Power Name",
            "type": "text",
            "placeholder": "Power Name",
            "maxlength": "30"
          },
          model: {
            value: _vm.formPowerType.name,
            callback: function callback($$v) {
              _vm.$set(_vm.formPowerType, "name", $$v);
            },
            expression: "formPowerType.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "sub_sub_project"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.formPowerType.active,
            callback: function callback($$v) {
              _vm.$set(_vm.formPowerType, "active", $$v);
            },
            expression: "formPowerType.active"
          }
        }, [_vm._v("Active / Inactive ")])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [!_vm.form_power_edit_mode ? _c('b-button', {
          attrs: {
            "disabled": _vm.formPowerType.name === '' || _vm.formPowerType.name === null,
            "type": "submit",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.createPowerTypes();
            }
          }
        }, [_c('span', [_vm._v("Add")])]) : _c('b-button', {
          attrs: {
            "disabled": _vm.formPowerType.name === '' || _vm.formPowerType.name === null,
            "type": "submit",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.updatePowerTypeData();
            }
          }
        }, [_c('span', [_vm._v("Save Edit")])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.initFormPowerType();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "series"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER DATA SERIES")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.getSeriesPowerList();
            }
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Master Data Series:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.powersSeriesList.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.powersSeriesList.params, "search", $$v);
            },
            expression: "powersSeriesList.params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Master Data Series of Power")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.powersSeriesList._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.powersSeriesList.data && _vm.powersSeriesList.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Series - Model")]), _c('th', [_vm._v("Rated power (kW)")]), _c('th', [_vm._v("No. of cylinders")]), _c('th', [_vm._v("Bore x stroke (mm)")]), _c('th', [_vm._v("Displacement (L)")]), _c('th', [_vm._v("Maximum RPM")]), _c('th', [_vm._v("Fuel")]), _c('th', [_vm._v("Status")]), _c('th', {
          attrs: {
            "width": "12%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.powersSeriesList.data, function (p, i) {
          return _c('tr', {
            key: "p-".concat(i)
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.powersSeriesList.meta.currentPage > 1 ? _vm.powersSeriesList.meta.perPage + i + 1 : i + 1))]), _c('td', [_vm._v(_vm._s(p.name) + " - " + _vm._s(p.model))]), _c('td', [_vm._v(_vm._s(p.ratedPower))]), _c('td', [_vm._v(_vm._s(p.noOfCylinders))]), _c('td', [_vm._v(_vm._s(p.boreXStroke))]), _c('td', [_vm._v(_vm._s(p.displacement))]), _c('td', [_vm._v(_vm._s(p.ratedSpeed))]), _c('td', [_vm._v(_vm._s(p.fuel))]), _c('td', [_vm._v(_vm._s(p.active ? 'Active' : 'Inactive'))]), _c('td', [_c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.toEditPower(p, 'series');
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeletePowerSeries(p);
              }
            }
          }, [!_vm.loading_delete_power ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v("Deleting... ")]], 2)], 1)]);
        }), 0)])]) : _vm._e(), _vm.powersSeriesList.data && _vm.powersSeriesList.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.powersSeriesList.meta.total,
            "per-page": _vm.powersSeriesList.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getSeriesPowerList
          },
          model: {
            value: _vm.powersSeriesList.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.powersSeriesList.meta, "currentPage", $$v);
            },
            expression: "powersSeriesList.meta.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No powers data found.")])]];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.form_power_edit_mode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.form_power_edit_mode ? 'Edit' : 'Add') + " Series")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.createPowerSeries();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Series Name",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "SeriesName",
            "type": "text",
            "placeholder": "Series Name",
            "maxlength": "30"
          },
          model: {
            value: _vm.formPowerSeries.name,
            callback: function callback($$v) {
              _vm.$set(_vm.formPowerSeries, "name", $$v);
            },
            expression: "formPowerSeries.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Model Name",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "SeriesName",
            "type": "text",
            "placeholder": "Model Name",
            "maxlength": "30"
          },
          model: {
            value: _vm.formPowerSeries.model,
            callback: function callback($$v) {
              _vm.$set(_vm.formPowerSeries, "model", $$v);
            },
            expression: "formPowerSeries.model"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Rated power (kW)",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "SeriesName",
            "type": "text",
            "placeholder": "Rated power (kW)",
            "maxlength": "30"
          },
          model: {
            value: _vm.formPowerSeries.ratedPower,
            callback: function callback($$v) {
              _vm.$set(_vm.formPowerSeries, "ratedPower", $$v);
            },
            expression: "formPowerSeries.ratedPower"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "No. of cylinders",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "SeriesName",
            "type": "number",
            "placeholder": "No. of cylinders",
            "maxlength": "30"
          },
          model: {
            value: _vm.formPowerSeries.noOfCylinders,
            callback: function callback($$v) {
              _vm.$set(_vm.formPowerSeries, "noOfCylinders", $$v);
            },
            expression: "formPowerSeries.noOfCylinders"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Bore x stroke (mm)",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "SeriesName",
            "type": "text",
            "placeholder": "Bore x stroke (mm)",
            "maxlength": "30"
          },
          model: {
            value: _vm.formPowerSeries.boreXStroke,
            callback: function callback($$v) {
              _vm.$set(_vm.formPowerSeries, "boreXStroke", $$v);
            },
            expression: "formPowerSeries.boreXStroke"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Displacement (L)",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "SeriesName",
            "type": "text",
            "placeholder": "Displacement (L)",
            "maxlength": "30"
          },
          model: {
            value: _vm.formPowerSeries.displacement,
            callback: function callback($$v) {
              _vm.$set(_vm.formPowerSeries, "displacement", $$v);
            },
            expression: "formPowerSeries.displacement"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Maximum RPM",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "SeriesName",
            "type": "number",
            "placeholder": "Maximum RPM",
            "maxlength": "30"
          },
          model: {
            value: _vm.formPowerSeries.ratedSpeed,
            callback: function callback($$v) {
              _vm.$set(_vm.formPowerSeries, "ratedSpeed", $$v);
            },
            expression: "formPowerSeries.ratedSpeed"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Fuel",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "SeriesName",
            "type": "text",
            "placeholder": "Fuel",
            "maxlength": "80"
          },
          model: {
            value: _vm.formPowerSeries.fuel,
            callback: function callback($$v) {
              _vm.$set(_vm.formPowerSeries, "fuel", $$v);
            },
            expression: "formPowerSeries.fuel"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "sub_sub_project"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.formPowerSeries.active,
            callback: function callback($$v) {
              _vm.$set(_vm.formPowerSeries, "active", $$v);
            },
            expression: "formPowerSeries.active"
          }
        }, [_vm._v("Active / Inactive ")])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.form_power_edit_mode ? _c('span', [_vm._v("Add")]) : _c('span', [_vm._v("Save Edit")])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.initFormPowerSeries();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "rob"
    }
  }, [_c('tab-nav', {
    attrs: {
      "tabs": true,
      "align": "center"
    }
  }, [_c('tab-nav-items', {
    attrs: {
      "active": true,
      "href": "#robList",
      "title": "Master Data Type of ROB"
    }
  }), _c('tab-nav-items', {
    attrs: {
      "active": false,
      "href": "#seriesROB",
      "title": "Master Data Series of ROB"
    }
  })], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "myTabContent2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "robList"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER DATA TYPE OF ROB")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.getRobPowerList();
            }
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Master Data ROB:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.robs.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.robs.params, "search", $$v);
            },
            expression: "robs.params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Master Data Type of ROB")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.robs._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.robs.data && _vm.robs.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("List of ROB")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.robs.data, function (p, i) {
          return _c('tr', {
            key: "p-".concat(i)
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(p.name))]), _c('td', [_vm._v(_vm._s(p.active ? 'Active' : 'Inactive'))]), _c('td', [_c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.toEditRob(p, 'types');
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteRobType(p);
              }
            }
          }, [!_vm.loading_delete_power ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v("Deleting... ")]], 2)], 1)]);
        }), 0)])]) : _vm._e(), _vm.robs.data && _vm.robs.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.robs.meta.total,
            "per-page": _vm.robs.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getRobPowerList
          },
          model: {
            value: _vm.robs.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.robs.meta, "currentPage", $$v);
            },
            expression: "robs.meta.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No ROB data found.")])]];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.form_rob_edit_mode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.form_rob_edit_mode ? 'Edit' : 'Add') + " Power")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.createRobTypes();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Type of ROB Name",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "Type of ROB Name",
            "type": "text",
            "placeholder": "Type of ROB Name",
            "maxlength": "30"
          },
          model: {
            value: _vm.formRobType.name,
            callback: function callback($$v) {
              _vm.$set(_vm.formRobType, "name", $$v);
            },
            expression: "formRobType.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "sub_sub_project"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.formRobType.active,
            callback: function callback($$v) {
              _vm.$set(_vm.formRobType, "active", $$v);
            },
            expression: "formRobType.active"
          }
        }, [_vm._v("Active / Inactive ")])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.form_rob_edit_mode ? _c('span', [_vm._v("Add")]) : _c('span', [_vm._v("Save Edit")])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.initFormRobType();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "seriesROB"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER DATA SERIES ROB")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.getRobSeriesList();
            }
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Master Data Series ROB:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.seriesROB.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.seriesROB.params, "search", $$v);
            },
            expression: "seriesROB.params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Master Data Series of ROB")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.seriesROB._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.seriesROB.data && _vm.seriesROB.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Series")]), _c('th', [_vm._v("Manufacturer")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.seriesROB.data, function (p, i) {
          return _c('tr', {
            key: "p-".concat(i)
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(p.series))]), _c('td', [_vm._v(_vm._s(p.manufacturer))]), _c('td', [_vm._v(_vm._s(p.active ? 'Active' : 'Inactive'))]), _c('td', [_c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            }
          }, [_c('i', {
            staticClass: "fa fa-edit",
            on: {
              "click": function click($event) {
                return _vm.toEditRob(p, 'series');
              }
            }
          })]), _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteRobSeries(p);
              }
            }
          }, [!_vm.loading_delete_power ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v("Deleting... ")]], 2)], 1)]);
        }), 0)])]) : _vm._e(), _vm.seriesROB.data && _vm.seriesROB.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.seriesROB.meta.total,
            "per-page": _vm.seriesROB.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getRobSeriesList
          },
          model: {
            value: _vm.seriesROB.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.seriesROB.meta, "currentPage", $$v);
            },
            expression: "seriesROB.meta.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No Series data found.")])]];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.form_rob_edit_mode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.form_rob_edit_mode ? 'Edit' : 'Add') + " Series")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.createRobSeries();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Series ROB",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "SeriesName",
            "type": "text",
            "placeholder": "Series ROB",
            "maxlength": "30"
          },
          model: {
            value: _vm.formRobSeries.series,
            callback: function callback($$v) {
              _vm.$set(_vm.formRobSeries, "series", $$v);
            },
            expression: "formRobSeries.series"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Manufacturer",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "SeriesName",
            "type": "text",
            "placeholder": "Manufacturer",
            "maxlength": "30"
          },
          model: {
            value: _vm.formRobSeries.manufacturer,
            callback: function callback($$v) {
              _vm.$set(_vm.formRobSeries, "manufacturer", $$v);
            },
            expression: "formRobSeries.manufacturer"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "sub_sub_project"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.formRobSeries.active,
            callback: function callback($$v) {
              _vm.$set(_vm.formRobSeries, "active", $$v);
            },
            expression: "formRobSeries.active"
          }
        }, [_vm._v("Active / Inactive ")])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.form_rob_edit_mode ? _c('span', [_vm._v("Add")]) : _c('span', [_vm._v("Save Edit")])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.initFormRobSeries();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }