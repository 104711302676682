import {
  hubungan,
  jenisKelamin,
  jenisKelaminFamily,
  kategoriDokumen,
  optionsAgama,
  optionsGolonganDarah,
  optionsJabatan,
  optionsJabatanPekerjaan,
  optionsJenjang,
  optionsKecamatan,
  optionsKelurahan,
  optionsKewarganegaraan,
  optionsKota,
  optionsProvinsi,
  optionsStatusPernikahan,
  wearpack,
  wearpackCrew,
  optionsBank,
} from './optionsData'

export default {
  jenisKelamin,
  hubungan,
  jenisKelaminFamily,
  kategoriDokumen,
  optionsAgama,
  optionsGolonganDarah,
  optionsJabatan,
  optionsJabatanPekerjaan,
  optionsJenjang,
  optionsKecamatan,
  optionsKelurahan,
  optionsKewarganegaraan,
  optionsKota,
  optionsProvinsi,
  optionsStatusPernikahan,
  wearpack,
  wearpackCrew,
  optionsBank,
}
