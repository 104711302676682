<template>
  <b-container fluid>
    <StepNavigation
      current-step="RCA_FINAL"
    />
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"
            ><strong>Final Report Root Cause Analysis</strong></h4
            >
          </template>
          <template v-slot:headerAction>
            <b-form-checkbox v-if="canPreview" name="check-button" v-model="isPreview" switch inline>
              <template>Preview</template>
            </b-form-checkbox>
          </template>
          <template v-slot:body>
            <FinalReportRootCauseAnalysisPreview v-if="isPreview"/>
            <FinalReportRootCauseAnalysisForm v-else/>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import FinalReportRootCauseAnalysisForm from '@src/views/MI/FinalReportRootCauseAnalysisForm'
import FinalReportRootCauseAnalysisPreview from '@src/views/MI/FinalReportRootCauseAnalysisPreview'
import StepNavigation from '@src/views/MI/component/StepNavigation'

export default {
  name: 'FinalReportRootCauseAnalysis',
  components: { StepNavigation, FinalReportRootCauseAnalysisPreview, FinalReportRootCauseAnalysisForm },
  mounted () {
    if (this.$route.query.preview === 'true' || this.$route.query.preview === true) {
      this.isPreview = true
    }
    if (this.$route.query.id && this.$route.query.formId) {
      this.canPreview = true
    }
    xray.index()
  },
  data () {
    return {
      isPreview: false,
      canPreview: false,
    }
  }
}
</script>
