var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "iq-social-media"
  }, _vm._l(_vm.socialProviders, function (socialProvider, index) {
    return _c('li', {
      key: index
    }, [_c('a', {
      attrs: {
        "href": "javascript:void(0)"
      },
      on: {
        "click": function click($event) {
          return _vm.socialLogin(socialProvider.type);
        }
      }
    }, [_c('i', {
      class: socialProvider.icon
    })])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }