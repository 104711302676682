var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('img', {
    attrs: {
      "src": "https://coderthemes.com/hyper/saas/assets/images/mail_sent.svg",
      "width": "80",
      "alt": ""
    }
  }), _c('h1', {
    staticClass: "mt-3 mb-0"
  }, [_vm._v("Reset Password.")]), _c('form', {
    staticClass: "mt-4",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.doChangePassword.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("New Password")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    staticClass: "form-control mb-0",
    attrs: {
      "type": "password",
      "aria-describedby": "emailHelp",
      "placeholder": "Enter New Password"
    },
    domProps: {
      "value": _vm.password
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.password = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("New Password Confirmation")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.confirm_password,
      expression: "confirm_password"
    }],
    staticClass: "form-control mb-0",
    attrs: {
      "type": "password",
      "aria-describedby": "emailHelp",
      "placeholder": "Enter New Password Confirmation"
    },
    domProps: {
      "value": _vm.confirm_password
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.confirm_password = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('button', {
    staticClass: "btn btn-primary float-right",
    attrs: {
      "type": "submit"
    }
  }, [!_vm.loading ? [_vm._v(" Reset Password ")] : [_c('span', {
    staticClass: "spinner-border spinner-border-sm",
    attrs: {
      "role": "status",
      "aria-hidden": "true"
    }
  }), _c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading...")])]], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }