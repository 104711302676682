import axios from './auth'

const VEHICLES_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/vehicles`

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getVehicles ({ commit }, params) {
    let url = VEHICLES_BASE_URL

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getVehicle ({ commit }, id) {
    let url = `${VEHICLES_BASE_URL}/${id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  }
}
