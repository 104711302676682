var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.lists, function (data, index) {
    return _c('b-row', {
      key: index
    }, [_c('b-col', {
      attrs: {
        "md": "8"
      }
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "md": "3"
      }
    }, [_c('strong', [_vm._v("Vessel:")]), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(data.vehicle.name))])]), _c('b-col', {
      attrs: {
        "md": "3"
      }
    }, [_c('strong', [_vm._v("DOL:")]), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(data.date_of_loss))])]), _c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('strong', [_vm._v("Cause of Loss:")]), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(data.classification_of_loss.name))])]), _c('b-col', {
      staticClass: "mt-3",
      attrs: {
        "md": "12"
      }
    }, [_c('table', {
      staticClass: "table table-bordered"
    }, [_c('thead', [_c('tr', {
      staticClass: "bg-warning"
    }, [_c('th', {}, [_vm._v("Insurer")]), _c('th', {}, [_vm._v("Share")]), _c('th', {}, [_vm._v("Amount")]), _c('th', {}, [_vm._v("Paid Date")])])]), _c('tbody', [data.insurers.length === 0 ? _c('tr', [_c('td', {
      attrs: {
        "colspan": "4"
      }
    }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(data.insurers, function (insurer, insurerIndex) {
      return _c('tr', {
        key: insurerIndex
      }, [_c('td', [_vm._v(" " + _vm._s(insurer.insurer_club ? insurer.insurer_club.name : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(insurer.share) + " ")]), _c('td', [_vm._v(" " + _vm._s(insurer.currency.alias) + " " + _vm._s(_vm.numberFormat(insurer.amount)) + " ")]), _c('td', [_vm._v(" " + _vm._s(insurer.paid_at) + " ")])]);
    })], 2)])])], 1)], 1), _c('b-col', {
      attrs: {
        "md": "4"
      }
    }, _vm._l(data.summaries, function (summary, summaryIndex) {
      return _c('table', {
        key: summaryIndex,
        staticClass: "table table-bordered"
      }, [_c('thead', [_c('tr', {
        staticClass: "bg-success"
      }, [_c('th', {}, [_vm._v("Details")]), _c('th', {}, [_vm._v("Amount")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v(" Actual/Rill Cost ")]), _c('td', [_vm._v(" " + _vm._s(_vm.numberFormat(summary.claim_proposed_actual)) + " ")])]), _c('tr', [_c('td', [_vm._v(" Claim Proposed ")]), _c('td', [_vm._v(" " + _vm._s(_vm.numberFormat(summary.claim_proposed_insurer)) + " ")])]), _c('tr', [_c('td', [_vm._v(" Final Adjustment ")]), _c('td', [_vm._v(" " + _vm._s(_vm.numberFormat(summary.final_adjusment)) + " ")])]), _c('tr', [_c('td', [_vm._v(" Deductible ")]), _c('td', [_vm._v(" " + _vm._s(_vm.numberFormat(summary.deductible)) + " ")])]), _c('tr', [_c('td', [_vm._v(" Net Claim ")]), _c('td', [_vm._v(" " + _vm._s(_vm.numberFormat(summary.net_claim_cost)) + " ")])]), _c('tr', [_c('td', [_vm._v(" Total Claim Days ")]), _c('td', [_vm._v(" " + _vm._s(summary.total_claim_days) + " ")])])])]);
    }), 0)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }