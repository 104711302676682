<template>
  <div v-if="detailSurvey" style="background-color: #fff">
    <div class="no-print pull-right">
      <b-button-group class="mr-2">
        <button class="btn btn-sm btn-primary" @click.prevent="print()"><i class="fa fa-save"></i>
          Save</button>
      </b-button-group>
    </div>

    <div class="row">
      <div class="col-md-12 text-left">
        <img :src="loadImg('energy_transporter')" class="admin_img" alt="energy_transporter">
        <img :src="loadImg('sentra_makmur')" class="admin_img" alt="sentra_makmur">
        <img :src="loadImg('renjani_maritim')" class="admin_img" alt="renjani_maritim">
        <img :src="loadImg('trans_energi_logistik')" class="admin_img" alt="trans_energi_logistik">
        <img :src="loadImg('marine_insurance')" class="admin_img" alt="marine_insurance">
      </div>
    </div>
    <div class="row" style="margin-top:-20px">
      <div class="col-lg-12 text-center">
        <h4 class="mt-5" style="text-decoration: underline;">INTIAL CLAIM ANALYSIS <br />MARINE INSURANCE GROUP TRANS
          ENERGI LOGISTIK</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3">
        <table border="0" width="100%">
          <tbody>
            <tr>
              <td>TB/BG</td>
              <td>: {{detailMi.vehicle.name}}</td>
            </tr>
            <tr>
              <td>Cause Of Loss</td>
              <td>: {{detailMi.type_loss.name}}</td>
            </tr>
            <tr>
              <td>DOL</td>
              <td>: {{detailMi.date_of_loss}}</td>
            </tr>
            <tr>
              <td>Policy Number</td>
              <td>: {{form.police_number}}</td>
            </tr>
            <tr>
              <td>Periode Of Cover</td>
              <td>: {{form.coverage_period}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-6"></div>
      <div class="col-lg-3">
        <table border="0" width="100%" class="border border-dark">
          <tbody>
            <tr>
              <td>80 - 100</td>
              <td>= Recommended (direkomendasikan).</td>
            </tr>
            <tr>
              <td>65 - 75</td>
              <td>= with consideration (dengan pertimbangan)</td>
            </tr>
            <tr>
              <td>55 - 60</td>
              <td>= It needs special attention (perlu perhatian khusus )</td>
            </tr>
            <tr>
              <td>0 - 50</td>
              <td>= Not recommended (tidak disarankan)</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>&nbsp;</div>
    <div class="row">
      <div class="col-lg-12">
        <table border="1" width="100%">
          <thead>
            <tr>
              <th class="text-center">No.</th>
              <th v-for="(header, headerIndex) in detailSurvey.headers" :key="headerIndex" class="text-center">
                {{header.label_name}}</th>
            </tr>
          </thead>
          <tbody v-if="detailSurvey.headers[0]">
            <tr v-for="number in detailSurvey.headers[0].contents.length" :key="number">
              <td class="text-center">{{number}}</td>
              <td v-for="(header, headerIndex) in detailSurvey.headers" :key="headerIndex">
                <template v-if="header.contents[number-1]">
                  <template v-if="header.contents[number-1].text">
                    {{header.contents[number-1].text}}
                  </template>
                  <template v-else-if="header.contents[number-1].input">
                    <b-form-input type="number" v-if="header.contents[number-1].input.alias === 'max100'" :maxlength="3"
                      :min="1" :max="100" v-model="header.contents[number-1].input.value" class="text-center">
                    </b-form-input>
                    <p v-else>{{header.contents[number-1].input.value}}</p>
                  </template>
                </template>
              </td>
            </tr>
            <tr style="background-color: yellow;">
              <td colspan="3" class="text-right">
                <h5><b>Nilai Seluruhnya</b></h5>
              </td>
              <td class="text-center">
                <h5><b>{{ totalAll }}</b></h5>
              </td>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>&nbsp;</div>
    <div class="row">
      <div class="col-lg-3">
        <table border="0" width="100%">
          <tbody>
            <tr>
              <td>Conclusion (Kesimpulan) </td>
              <td width="5%">:</td>
              <td v-html="form.conclusion || '-'"></td>
            </tr>
            <tr>
              <td>Comments or Suggestions <br>Komentar atau Saran-saran </td>
              <td width="5%">:</td>
              <td v-html="form.comments || '-'"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { fleetsActions, miActions } from '@/src/Utils/helper'

export default {
  name: 'ClaimAnalysisPreview',
  data () {
    return {
      detailMi: null,
      detailSurvey: null,
      detailFillSurvey: null,
      form: {
        police_number: null,
        coverage_period: null,
        conclusion: null,
        comments: null
      }
    }
  },
  computed: {
    totalAll () {
      let ret = 0

      if (this.detailSurvey) {
        let header = this.detailSurvey.headers.find(header => header.type === 'user_input' && header.contents[0] && header.contents[0].input && header.contents[0].input.alias === 'max100')

        if (header) {
          let filteredContents = header.contents.filter(content => content.input.value)

          filteredContents.forEach(content => {
            ret += parseInt(content.input.value)
          })

          if (filteredContents.length > 0) {
            ret = ret / filteredContents.length
          }
        }
      }

      return Math.round(ret)
    }
  },
  async mounted () {
    await this.fetchMi()
    await this.fetchFormSurvey()
    await this.fetchSurvey()
  },
  methods: {
    ...fleetsActions,
    ...miActions,
    loadImg (name) {
      return require(`@assets/images/logo/${name}.png`)
    },
    print () {
      window.print()
    },
    async fetchMi () {
      const { data, status } = await this.fetchDetailMi({
        id: this.$route.params.id,
        with: 'vehicle,type_coverage,type_loss,company,documents,company_claimant,status,creator,histories'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        this.form._saving = false
        return
      }

      this.detailMi = data
    },
    async fetchSurvey () {
      const { data, status } = await this.getClaimAnalysisByFormId({
        id: this.$route.params.id,
        survey: 'init_claim'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        this.form._saving = false
        return
      }

      this.detailSurvey = data

      if (this.detailSurvey.form) {
        this.form.coverage_period = this.detailSurvey.form.coverage_period ? this.detailSurvey.form.coverage_period.toString() : ''
        this.form.conclusion = this.detailSurvey.form.conclusion
        this.form.comments = this.detailSurvey.form.comments
      }

      if (this.detailSurvey.headers) {
        this.detailSurvey.headers.map((header, headerIndex) => {
          if (this.detailFillSurvey.headers[headerIndex] && header.contents) {
            header.contents.map((content, contentIndex) => {
              if (this.detailFillSurvey.headers[headerIndex].contents[contentIndex] &&
                this.detailFillSurvey.headers[headerIndex].contents[contentIndex].value &&
                content.input) {
                content.input.value = this.detailFillSurvey.headers[headerIndex].contents[contentIndex].value.value_anything
              }
            })
          }
        })
      }
    },
    async fetchFormSurvey () {
      const { data, status } = await this.getFormClaimAnalysisByFormId({
        id: this.$route.params.id,
        survey: 'init_claim'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        this.form._saving = false
        return
      }

      this.detailFillSurvey = data

      if (this.detailFillSurvey.claim_analysis) {
        this.form.conclusion = this.detailFillSurvey.claim_analysis.conclusion
        this.form.police_number = this.detailFillSurvey.claim_analysis.police_number
      }
    }
  }
}
</script>

<style scoped>
.admin_img {
  background-color: white !important;
}
</style>
