var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Matrix Report")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('router-link', {
          attrs: {
            "to": "#"
          }
        }, [_c('button', {
          staticClass: "btn btn-dark mt-1 mr-4"
        }, [_c('i', {
          staticClass: "fa fa-file-pdf"
        }), _vm._v(" Generate Report")])]), _c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-filter2",
            modifiers: {
              "collapse-filter2": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          attrs: {
            "id": "collapse-filter2",
            "visible": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Select Report"
          }
        }, [_c('date-picker', {
          staticClass: "w-100",
          attrs: {
            "type": "date",
            "range": "",
            "value-type": "YYYY-MM-DD",
            "placeholder": "Select date range"
          },
          on: {
            "change": _vm.handleCHangeReportDateRange
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Status"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.statusOptions,
            "reduce": function reduce(statusOptions) {
              return statusOptions.value;
            }
          },
          model: {
            value: _vm.report.params.status_id,
            callback: function callback($$v) {
              _vm.$set(_vm.report.params, "status_id", $$v);
            },
            expression: "report.params.status_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.handleFetchReport
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _vm.loading ? _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(" Loading... ")]) : [_vm.report.data !== null ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Summary Cases Period " + _vm._s(_vm.report.params.from) + " s/d " + _vm._s(_vm.report.params.to))])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "7"
          }
        }, [_c('table', {
          staticClass: "table mb-0 table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2",
            "width": "5%"
          }
        }, [_vm._v("No.")]), _c('th', {
          staticClass: "text-center",
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2",
            "width": "40%"
          }
        }, [_vm._v("Period")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("Non Claim")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("Claim")]), _c('th', {
          staticClass: "text-center",
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Total Accident")])]), _c('tr', [_c('th', [_vm._v("Major")]), _c('th', [_vm._v("Minor")]), _c('th', [_vm._v("Major")]), _c('th', [_vm._v("Minor")])])]), _c('tbody', _vm._l(_vm.report.data.form_data, function (data, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(data.period))]), _c('td', [_vm._v(_vm._s(data.nonclaim_major))]), _c('td', [_vm._v(_vm._s(data.nonclaim_minor))]), _c('td', [_vm._v(_vm._s(data.claim_major))]), _c('td', [_vm._v(_vm._s(data.claim_minor))]), _c('td', [_vm._v(_vm._s(data.total))])]);
        }), 0), _c('tfoot', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("Total")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "5"
          }
        }, [_vm._v(_vm._s(_vm.report.data.form_total))])])])])]), _c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('div', {
          attrs: {
            "id": "summary-chart"
          }
        }, [_c('ApexChart', {
          attrs: {
            "element": _vm.summaryCharts.type,
            "chartOption": _vm.summaryCharts.bodyData
          }
        })], 1)])], 1)];
      },
      proxy: true
    }], null, false, 1791922893)
  })], 1)], 1) : _vm._e(), _vm.report.data !== null ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Classification of Loss Period " + _vm._s(_vm.report.params.from) + " s/d " + _vm._s(_vm.report.params.to))])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('table', {
          staticClass: "table mb-0 table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2",
            "width": "70%"
          }
        }, [_vm._v("Classification of Loss")]), _c('th', {
          staticClass: "text-center",
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Total")])])]), _c('tbody', _vm._l(_vm.report.data.classification_of_loss_data, function (data, index) {
          return _c('tr', {
            key: index
          }, [data.value === null ? _c('td', {
            staticClass: "text-center",
            attrs: {
              "colspan": "2"
            }
          }, [_c('strong', [_vm._v(_vm._s(data.label))])]) : _c('td', [_vm._v(_vm._s(data.label))]), data.value !== null ? _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.value))]) : _vm._e()]);
        }), 0), _c('tfoot', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("Total")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v(_vm._s(_vm.report.data.classification_of_loss_total))])])])])]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('div', {
          attrs: {
            "id": "classification-chart"
          }
        }, [_c('ApexChart', {
          attrs: {
            "element": _vm.classificationCharts.type,
            "chartOption": _vm.classificationCharts.bodyData
          }
        })], 1)])], 1)];
      },
      proxy: true
    }], null, false, 1347508198)
  })], 1)], 1) : _vm._e(), _vm.report.data !== null ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Cause of Loss Period " + _vm._s(_vm.report.params.from) + " s/d " + _vm._s(_vm.report.params.to))])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('table', {
          staticClass: "table mb-0 table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2",
            "width": "70%"
          }
        }, [_vm._v("Cause of Loss ")]), _c('th', {
          staticClass: "text-center",
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Total Causes")])])]), _c('tbody', _vm._l(_vm.report.data.cause_of_loss_data, function (data, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(data.label))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.value))])]);
        }), 0), _c('tfoot', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("Total")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v(_vm._s(_vm.report.data.cause_of_loss_total))])])])])]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('div', {
          attrs: {
            "id": "cause-chart"
          }
        }, [_c('ApexChart', {
          attrs: {
            "element": _vm.causeCharts.type,
            "chartOption": _vm.causeCharts.bodyData
          }
        })], 1)])], 1)];
      },
      proxy: true
    }], null, false, 1868078355)
  })], 1)], 1) : _vm._e()]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }