var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "id": "modal---".concat(_vm.id),
      "title": _vm.modalTitle,
      "width": "50%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_vm.loading ? _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" Loading Content... ")]) : _c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('tab-content-item', {
          attrs: {
            "active": true,
            "id": "power"
          }
        }, [_c('tab-nav', {
          attrs: {
            "tabs": true
          }
        }, [_c('tab-nav-items', {
          attrs: {
            "active": true,
            "href": "#activity",
            "title": "Activity"
          }
        })], 1), _c('div', {
          staticClass: "tab-content",
          attrs: {
            "id": "myTabContent"
          }
        }, [_c('tab-content-item', {
          attrs: {
            "active": true,
            "id": "activity"
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "mb-5",
          attrs: {
            "md": "12"
          }
        }, [_c('form', {
          attrs: {
            "action": ""
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submit.apply(null, arguments);
            }
          }
        }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Select Docking Job *",
            "label-for": "docking_job"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "displayName",
            "options": _vm.tasks,
            "reduce": function reduce(task) {
              return task.id;
            }
          },
          on: {
            "input": _vm.handleTaskDetail
          },
          model: {
            value: _vm.selectedTaskId,
            callback: function callback($$v) {
              _vm.selectedTaskId = $$v;
            },
            expression: "selectedTaskId"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label-for": "progress_bar"
          }
        }, [_c('h4', {
          staticClass: "mb-2"
        }, [_vm._v("Progrees : "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.progressBar) + "%")])], 1), _c('b-progress', {
          attrs: {
            "value": _vm.form.progressBar,
            "variant": "success",
            "max": "100"
          }
        })], 1)], 1)], 1), _vm.form.progressBar < 100 ? [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Start *",
            "label-for": "start"
          }
        }, [_c('date-picker', {
          attrs: {
            "required": "",
            "value-type": "DD-MM-YYYY",
            "type": "date",
            "placeholder": "Select date",
            "format": "DD MMM YYYY"
          },
          model: {
            value: _vm.form.date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "date", $$v);
            },
            expression: "form.date"
          }
        })], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Progress Pekerjaan *",
            "label-for": "progress_pekerjaan"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "progress_pekerjaan"
          }
        }, [_vm._v("%")])]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.progress,
            expression: "form.progress"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "min": _vm.form.progressBar,
            "required": "",
            "id": "progress_pekerjaan",
            "placeholder": "80"
          },
          domProps: {
            "value": _vm.form.progress
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "progress", $event.target.value);
            }
          }
        })])])], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Report Progress",
            "label-for": "report_progress"
          }
        }, [_vm.form.fileName ? _c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('a', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-primary",
          attrs: {
            "href": _vm.form.fileUrl,
            "title": "Download File",
            "download": ""
          }
        }, [_c('i', {
          staticClass: "fa fa-download"
        }), _vm._v(" Download")]), _c('a', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-danger",
          attrs: {
            "href": "#",
            "title": "Hapus",
            "download": ""
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return function () {
                _vm.form.fileUrl = '';
                _vm.form.fileName = '';
              }.apply(null, arguments);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-trash"
        })])]) : [_c('div', {
          staticClass: "custom-file"
        }, [_c('input', {
          staticClass: "custom-file-input",
          attrs: {
            "type": "file",
            "accept": "image/png,image/jpg,image/jpeg,.pdf,.xls,.xlsx,.doc,.docx",
            "id": "attachment-shipyard"
          },
          on: {
            "change": _vm.onUploadFile
          }
        }), _c('label', {
          staticClass: "custom-file-label",
          attrs: {
            "for": "attachment-shipyard"
          }
        }, [_vm._v("Browse file...")])]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" File Type * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("3 MB / File")])], 1)]], 2)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Notes",
            "label-for": "notes"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "3",
            "placeholder": "Notes"
          },
          model: {
            value: _vm.form.notes,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "notes", $$v);
            },
            expression: "form.notes"
          }
        })], 1)], 1)], 1), _c('b-button', {
          attrs: {
            "type": "submit",
            "block": "",
            "variant": "success"
          }
        }, [_vm._v("UPDATE ACTIVITY")])] : _vm._e()], 2)]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('ul', {
          staticClass: "iq-timeline",
          staticStyle: {
            "height": "100%"
          }
        }, _vm._l(_vm.activities, function (activity) {
          return _c('li', [_c('div', {
            staticClass: "timeline-dots border-success"
          }), !_vm.selectedTask ? _c('h6', {
            staticClass: "float-left mb-1"
          }, [_vm._v(_vm._s(activity.taskName))]) : _vm._e(), _c('br'), _c('small', [_vm._v(_vm._s(activity.startActivity))]), _c('div', {
            staticClass: "d-inline-block w-100"
          }, [_c('p', [_vm._v(_vm._s(activity.notes))])])]);
        }), 0)])], 1)], 1)], 1)], 1)], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }