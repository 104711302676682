<template>
  <div class="iq-sidebar bg-dark">
    <div class="iq-sidebar-logo d-flex justify-content-between p-4 mb-5">
      <img :src="logo" class="img-fluid w-100 mr-2" alt="logo"  />

      <div class="iq-menu-bt-sidebar" v-if="toggleButton">
        <div class="iq-menu-bt align-self-center">
          <div
            class="wrapper-menu"
            :class="{ open: isMini }"
            @click="miniSidebar"
          >
            <div class="main-circle"><i class="ri-more-2-fill"></i></div>
            <div class="hover-circle"><i class="ri-more-fill"></i></div>
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu" :class="horizontal ? 'd-xl-none' : ''">
        <List :items="items" :open="true" :horizontal="horizontal" />
      </nav>
      <div class="p-3"></div>
    </div>
  </div>
  <!-- TOP Nav Bar -->
</template>

<script>
import List from '../menus/ListStyle1'
export default {
  name: 'SideBarStyle1',
  props: {
    homeURL: { type: Object, default: () => ({ name: 'dashboard.home-1' }) },
    items: { type: Array },
    logo: {
      type: String,
      default: require('../../../assets/images/logo-dls.png'),
    },
    horizontal: { type: Boolean },
    toggleButton: { type: Boolean, default: true },
  },
  components: {
    List,
  },
  computed: {
    isMini() {
      return this.$store.getters['Setting/miniSidebarState']
    },
  },
  methods: {
    miniSidebar() {
      this.$emit('toggle')
    },
  },
  data() {
    return {}
  },
}
</script>
