import CryptoJS from "crypto-js";
import ax from 'axios';
import axios from 'axios'

const CRYPTO_SECRET_KEY = process.env.VUE_APP_CRYPTO_SECRET_KEY;
const AUTH_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/auth`;
const USERS_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/users`;

// ===
// Private helpers
// ===
function decrypt(cipherText) {
	const bytes = CryptoJS.AES.decrypt(cipherText, CRYPTO_SECRET_KEY)
	return bytes.toString(CryptoJS.enc.Utf8);
}

const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch, commit }) {
    dispatch('validate') // refresh token
    commit("SET_AXIOS_TOKEN", state.token);
  },

  async login({ dispatch, commit }, payload) {
    const url = `${AUTH_BASE_URL}/login`;

    const method = "POST";
    const data = payload;
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      headers,
      data
    };

    try {
      const response = await axios(config);
      const data = response.data.data;

      commit("SET_TOKEN", data.token);
      commit("SET_REFRESH_TOKEN", data.refreshToken);

      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },

  async getProfile({ commit }) {
    const url = `${USERS_BASE_URL}/profile/me`;

    const method = "get";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      commit("SET_PROFILE", data);
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },

  logout({ commit }) {
    commit('SET_PROFILE', null)
    commit('SET_TOKEN', null)
    commit('SET_REFRESH_TOKEN', null)
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  async validate({ commit, state }) {
    if (!state.profile) return Promise.resolve(null);

    var urlencoded = new URLSearchParams();
    urlencoded.append("refreshToken", decrypt(state.refresh_token));

    const url = `${AUTH_BASE_URL}/refresh-token`;

    const method = "POST";
    const data = urlencoded;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const config = {
      url,
      method,
      headers,
      data
    };

    try {
      const response = await axios(config);
      const data = response.data.data;
      commit("SET_TOKEN", data.token);
      commit("SET_REFRESH_TOKEN", data.refreshToken);

      return data;
    } catch (e) {
      if (e.response && e.response.status === 401) {
        commit('SET_PROFILE', null)
      }
      return null
    }
  },

  async updateProfile({ commit }, payload) {
    const url = `${USERS_BASE_URL}/profile`;

    const method = "put";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json",
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },

  async updatePhoto({ commit }, payload) {
    const url = `${USERS_BASE_URL}/profile/photo`;

    const method = "post";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },

  async updateUsername({ commit }, payload) {
    const url = `${USERS_BASE_URL}/profile/username`;
    const method = "put";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },

  async updatePassword({ commit }, payload) {
    const url = `${USERS_BASE_URL}/profile/password`;

    const method = "put";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },

  async forgotPassword({ commit }, payload) {
    const url = `${AUTH_BASE_URL}/forgot-password`;

    const method = "post";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },

  validateForgotToken({ commit }, payload) {
    const url = `${AUTH_BASE_URL}/token/validate`;

    const method = "get";
    const headers = {
      "Authorization": `Bearer ${payload}`,
    };
    const config = {
      url,
      method,
      headers
    };

    return ax(config)
    .then(res => {
      return { status: 'success' };
    }).catch(err => {
      return { status: 'error' };
    });
  },

  refreshToken({ commit, state }) {
    const url = `${AUTH_BASE_URL}/refresh-token`;

    const method = "post";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    }

    var urlencoded = new URLSearchParams();
    urlencoded.append("refreshToken", decrypt(state.refresh_token));

    const config = {
      url,
      method,
      data: urlencoded,
      headers
    };

    delete axios.defaults.headers.common["Authorization"]

    return axios(config)
      .then(res => {
        let data = res.data
        commit("SET_TOKEN", data.token);
        commit("SET_REFRESH_TOKEN", data.refreshToken);
        return { status: 'success' };
      }).catch(err => {
        return { status: 'error' };
      });
  },

  async changePassword({ commit }, payload) {
    const url = `${USERS_BASE_URL}/password`;
    const method = "post";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": `Bearer ${payload.token}`,
    };
    const config = {
      url,
      method,
      data: payload.data,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
};

export default actions;
