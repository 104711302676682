<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h5 class="card-title text-primary"><strong>DOCKING HISTORY - TB. ETI 201</strong></h5>
          </template>
          <template v-slot:headerAction>
            <a class="text-primary float-right ml-3" v-b-toggle.docking_progress_card role="button" style="cursor: pointer">
            <i class="fa fa-filter" />
            </a> 
          </template>
          <template v-slot:body>
            <b-collapse visible id="docking_progress_card" class="mb-2">
              <!-- FILTER DOCKING PROGRESS-->
              <b-row>
                  <b-col md="4">
                    <b-form-group label="Fleet Category" label-for="fleet_category">
                      <v-multiselect v-model="fleetCategorySelect" tag-placeholder="Category" placeholder="Fleet Category" label="text" track-by="value" :options="fleetCategory" :multiple="true" :taggable="true">
                      </v-multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Docking Type" label-for="docking_type">
                        <b-form-select plain v-model="selectedDockingType" :options="docking_type" size="lg">
                          <template v-slot:first>
                          <b-form-select-option :value="null">Select Docking Type</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Status" label-for="status">
                        <b-form-select plain v-model="selectedDockingStatusFinish" :options="dockingStatusFinish" size="lg">
                          <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Period Docking (Arrival)" label-for="Period">
                      <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range"></date-picker>
                    </b-form-group>
                  </b-col>
                  <b-col md="1">
                    <b-form-group label="Filter" label-for="button_search">
                      <b-button variant="primary" class="mb-3 mr-1"><i class="fa fa-filter"></i></b-button>
                    </b-form-group>
                  </b-col>
              </b-row>
              <!-- FILTER DOCKING PROGRESS END -->
            </b-collapse>
              
            <!-- DOCKING PROGRESS -->
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="text-center">NO</th>
                  <th class="text-center">DOCKING TYPE</th>
                  <th class="text-center">SHIPYARD</th>
                  <th class="text-center">ARRIVAL</th>
                  <th class="text-center">UPLSIP</th>
                  <th class="text-center">DELIVERY</th>
                  <th class="text-center">JOB</th>
                  <th class="text-center">STATUS</th>
                  <th class="text-center">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th class="text-center">1</th>
                  <td class="text-center">INTERMEDIATE SURVEY</td>
                  <td class="text-center">PT. CAHAYA BAHARI SHIPYARD</td>
                  <td class="text-center">2022-05-10</td>
                  <td class="text-center">2022-05-16</td>
                  <td class="text-center">2022-06-18</td>
                  <td class="text-center">12</td>
                  <td class="text-center"><b-badge variant="success d-block">ON TIME</b-badge></td>
                  <td class="text-center">
                    <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                          <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                        </span>
                      </template>
                      <b-dropdown-item href="#" @click="slideDockingDetail = !slideDockingDetail;" variant="primary]"><i class="fa fa-history mr-2"></i><router-link to="docking-history">DOCKING DETAIL</router-link></b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
                <tr>
                  <th class="text-center">2</th>
                  <td class="text-center">SPECIAL SURVEY</td>
                  <td class="text-center">PT. GALANGAN CISWANTO</td>
                  <td class="text-center">2022-01-20</td>
                  <td class="text-center">2022-01-21</td>
                  <td class="text-center">2022-02-28</td>
                  <td class="text-center">22</td>
                  <td class="text-center"><b-badge variant="danger d-block">DELAY</b-badge></td>
                  <td class="text-center">
                    <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                          <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                        </span>
                      </template>
                      <b-dropdown-item href="#" variant="primary]"><i class="fa fa-history mr-2"></i><router-link to="docking-history">DOCKING DETAIL</router-link></b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
                <tr>
                  <th class="text-center">3</th>
                  <td class="text-center">EMERGENCY DOCKING</td>
                  <td class="text-center">PT. BUMI AYU</td>
                  <td class="text-center">2021-09-28</td>
                  <td class="text-center">2021-09-30</td>
                  <td class="text-center">2021-11-05</td>
                  <td class="text-center">19</td>
                  <td class="text-center"><b-badge variant="success d-block">ON TIME</b-badge></td>
                  <td class="text-center">
                    <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                          <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                        </span>
                      </template>
                      <b-dropdown-item href="#" variant="primary]"><i class="fa fa-history mr-2"></i><router-link to="docking-history">DOCKING DETAIL</router-link></b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
                <tr>
                  <th class="text-center">4</th>
                  <td class="text-center">INTERMEDIATE SURVEY</td>
                  <td class="text-center">PT. CAHAYA BAHARI SHIPYARD</td>
                  <td class="text-center">2021-04-12</td>
                  <td class="text-center">2021-04-13</td>
                  <td class="text-center">2021-06-03</td>
                  <td class="text-center">32</td>
                  <td class="text-center"><b-badge variant="success d-block">ON TIME</b-badge></td>
                  <td class="text-center">
                    <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                          <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                        </span>
                      </template>
                      <b-dropdown-item href="#" variant="primary]"><i class="fa fa-history mr-2"></i><router-link to="docking-history">DOCKING DETAIL</router-link></b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="mt-3">
              <b-pagination :value="1" :total-rows="50" align="center" />
            </div>
            <!-- DOCKING PROGRESS END -->


            <!-- SLIDE - FORM DETAIL DOCKING -->
            <b-sidebar
              v-model="slideDockingDetail"
              id="docking_detail"
              title="DOCKING DETAIL - TB. ETI 201"
              width="60%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow>
              <template #default="{hide}">
                <b-row class="m-3">
                  <b-col cols="12">
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2">Select Docking Job</legend>
                      <b-row>
                        <b-col md="10">
                          <b-form-input id="job_name" type="text" placeholder="Search Job Name"></b-form-input>
                        </b-col>
                        <b-col md="2">
                          <b-button variant="success" block>SEARCH</b-button>
                        </b-col>
                        <b-col md="4">
                          <v-jstree :data="tree" show-checkbox allow-batch whole-row :item-events="itemEvents" @item-click="itemClick" class="mt-3">
                            <template slot-scope="_">
                              <div style="display: inherit; width: 200px" @click.ctrl="customItemClickWithCtrl">
                                <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                                {{_.model.text}}
                              </div>
                            </template>
                          </v-jstree>
                        </b-col>
                        <b-col md="8" class="mt-3">
                          <table class="table table-striped">
                            <tbody>
                              <tr>
                                <th width="20%">Arrival Shipyard</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">
                                  <b-form-input type="date" id="arrival_shipyard"></b-form-input>
                                </td>
                                <th width="20%">Up Slip</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">
                                  <b-form-input type="date" id="upslip"></b-form-input>
                                </td>
                              </tr>
                              <tr>
                                <th width="20%">Waiting Dock Space</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">5 Hari</td>
                                <th width="20%">Down Slip</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">
                                  <b-form-input type="date" id="downslip"></b-form-input>
                                </td>
                              </tr>
                              <tr>
                                <th width="20%">Complete Date</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">
                                  <b-form-input type="date" id="delivery_date"></b-form-input>
                                </td>
                                <th width="20%">Budget</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">Rp. 385.000.000,-</td>
                              </tr>
                              <tr>
                                <th width="20%">Shipyard</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">PT. CAHAYA BAHARI SHIPYARD</td>
                                <th width="20%">Penawaran</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                    </div>
                                    <input type="number" class="form-control" id="real_budget" placeholder="324.000.000">
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <th width="20%">PIC</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">NURUL AKBAR</td>
                                <th width="20%">Document Shipyard</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">
                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                  </ul>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          
                          <!-- JOB CATEGORY PERCENT -->
                          <b-row>
                            <b-col md="12">
                              <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary rounded">
                                <template v-slot:body >
                                  <div class="d-flex align-items-center justify-content-between">
                                    <div class="rounded-circle iq-card-icon bg-primary"><i class="fa fa-percent"></i></div>
                                    <div class="text-right">
                                      <h2 class="mb-0"><span class="counter">100%</span></h2>
                                      <h5 class="">Persentase Job Category</h5>
                                    </div>
                                  </div>
                                </template>
                              </iq-card>
                            </b-col>
                          </b-row>
                          <!-- JOB CATEGORY PERCENT END -->

                          <!-- DOCKING JOB -->
                          <div class="accordion" role="tablist">
                            <b-card no-body >
                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle.jobCat-1 variant="primary" class="text-left"><i class="fa fa-chevron-circle-down"></i>DOCKING / UNDOCKING <strong class="pull-right">BOBOT PEKERJAAN : 30%</strong></b-button>
                              </b-card-header>

                              <b-collapse id="jobCat-1" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                  <!-- BOBOT DOCKING JOB -->
                                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                                    <template v-slot:body >
                                      <div class="d-flex align-items-center justify-content-between">
                                        <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                                        <div class="text-right">
                                          <h2 class="mb-0"><span class="counter">100%</span></h2>
                                          <h5 class="">Persentase Docking Job</h5>
                                        </div>
                                      </div>
                                    </template>
                                  </iq-card>

                                  <b-modal id="reason_reject" size="md" title="Reject - Docking Up Slip" ok-title="Save" cancel-title="Close">
                                    <b-row>
                                      <b-col md="12">
                                          <b-form-group label="Reason Reject" label-for="reason_reject">
                                            <b-form-textarea id="reason_reject" rows="3"></b-form-textarea>
                                          </b-form-group>
                                        <b-button block variant="primary"><i class="fa fa-close mr-2"></i>Reject Docking Job</b-button>
                                      </b-col>
                                    </b-row>
                                  </b-modal>

                                  <div class="accordion" role="tablist">
                                    <b-card no-body >
                                      <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                        <b-button block v-b-toggle.job-1-1 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Up Slip <strong class="pull-right">BOBOT PEKERJAAN : 50%</strong></b-button>
                                      </b-card-header>
                                      <b-collapse id="job-1-1" accordion="my-accordion-sub" role="tabpanel">
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Check water tubes/plates and clean/free from clogged.<br>
                                                Check  from any corrosion <br>
                                                Check  zink anodes,  replace when necessary<br>
                                                Check  leakage after assembly
                                              </td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Internal Man Power</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <b-alert :show="true" variant="success">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-thumbs-up"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                        </b-alert>
                                        <b-alert :show="true" variant="danger">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-close"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                        </b-alert>
                                        <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                        <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                      </b-collapse>
                                    </b-card>
                                    <b-card no-body >
                                      <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                        <b-button block v-b-toggle.job-1-2 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Down Slip <strong class="pull-right">BOBOT PEKERJAAN : 50%</strong></b-button>
                                      </b-card-header>
                                      <b-collapse id="job-1-2" accordion="my-accordion-sub" role="tabpanel">
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Clean motor housing including stator and rotor<br>
                                                Check  bearing,  replace when  necessary<br>
                                                Check  cable installations and connections<br>
                                                Check  foundation and tighten all bolts
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <fieldset class="form-group border p-3">
                                          <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                          <table class="table table-striped">
                                            <tbody>
                                              <tr>
                                                <th width="20%">Nama Kontraktor </th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">PT. CAHAYA PELAYARAN</td>
                                                <th width="20%">Executive Engineer</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Penawaran</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">328.000.000</td>
                                                <th width="20%">Penawaran Nego</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">325.000.000</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Notes</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                                <th width="20%">Document</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  </ul>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </fieldset>
                                        <b-alert :show="true" variant="success">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-thumbs-up"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                        </b-alert>
                                        <b-alert :show="true" variant="danger">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-close"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                        </b-alert>
                                        <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                        <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                      </b-collapse>
                                    </b-card>
                                  </div>
                                </b-card-body>
                              </b-collapse>
                            </b-card>
                          </div>
                          <!-- DOCKING JOB END -->
                          
                          <!-- DOCKING JOB -->
                          <div class="accordion" role="tablist">
                            <b-card no-body >
                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle.jobCat-2 variant="primary" class="text-left"><i class="fa fa-chevron-circle-down"></i>SAND BLASTING<strong class="pull-right">BOBOT PEKERJAAN : 70%</strong></b-button>
                              </b-card-header>

                              <b-collapse id="jobCat-2" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                  <!-- BOBOT DOCKING JOB -->
                                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                                    <template v-slot:body >
                                      <div class="d-flex align-items-center justify-content-between">
                                        <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                                        <div class="text-right">
                                          <h2 class="mb-0"><span class="counter">100%</span></h2>
                                          <h5 class="">Persentase Docking Job</h5>
                                        </div>
                                      </div>
                                    </template>
                                  </iq-card>
                                  <!-- BOBOT DOCKING JOB END -->
                                  <div class="accordion" role="tablist">
                                    <b-card no-body >
                                      <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                        <b-button block v-b-toggle.job-2-1 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Scraping teritip di lambung dan lunas<strong class="pull-right">BOBOT PEKERJAAN : 30%</strong></b-button>
                                      </b-card-header>
                                      <b-collapse id="job-2-1" accordion="my-accordion-sub" role="tabpanel">
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Clean motor housing including stator and rotor<br>
                                                Check  bearing,  replace when  necessary<br>
                                                Check  cable installations and connections<br>
                                                Check  foundation and tighten all bolts
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <b-alert :show="true" variant="success">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-thumbs-up"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                        </b-alert>
                                        <b-alert :show="true" variant="danger">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-close"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                        </b-alert>
                                        <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                        <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                      </b-collapse>
                                    </b-card>
                                    <b-card no-body >
                                      <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                        <b-button block v-b-toggle.job-2-2 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Pencucian lambung dengan air tawar<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                      </b-card-header>
                                      <b-collapse id="job-2-2" accordion="my-accordion-sub" role="tabpanel">
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Clean motor housing including stator and rotor<br>
                                                Check  bearing,  replace when  necessary<br>
                                                Check  cable installations and connections<br>
                                                Check  foundation and tighten all bolts
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <b-alert :show="true" variant="success">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-thumbs-up"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                        </b-alert>
                                        <b-alert :show="true" variant="danger">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-close"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                        </b-alert>
                                        <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                        <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                      </b-collapse>
                                    </b-card>
                                    <b-card no-body >
                                      <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                        <b-button block v-b-toggle.job-2-3 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Sand blasting lunas<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                      </b-card-header>
                                      <b-collapse id="job-2-3" accordion="my-accordion-sub" role="tabpanel">
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Clean motor housing including stator and rotor<br>
                                                Check  bearing,  replace when  necessary<br>
                                                Check  cable installations and connections<br>
                                                Check  foundation and tighten all bolts
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <b-alert :show="true" variant="success">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-thumbs-up"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                        </b-alert>
                                        <b-alert :show="true" variant="danger">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-close"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                        </b-alert>
                                        <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                        <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                      </b-collapse>
                                    </b-card>
                                    <b-card no-body >
                                      <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                        <b-button block v-b-toggle.job-2-4 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Sand blasting lambung<strong class="pull-right">BOBOT PEKERJAAN : 40%</strong></b-button>
                                      </b-card-header>
                                      <b-collapse id="job-2-4" accordion="my-accordion-sub" role="tabpanel">
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Clean motor housing including stator and rotor<br>
                                                Check  bearing,  replace when  necessary<br>
                                                Check  cable installations and connections<br>
                                                Check  foundation and tighten all bolts
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <b-alert :show="true" variant="success">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-thumbs-up"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                        </b-alert>
                                        <b-alert :show="true" variant="danger">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-close"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                        </b-alert>
                                        <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                        <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                      </b-collapse>
                                    </b-card>
                                    <b-card no-body >
                                      <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                        <b-button block v-b-toggle.job-2-5 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Sand blasting deck dan bulwark<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                      </b-card-header>
                                      <b-collapse id="job-2-5" accordion="my-accordion-sub" role="tabpanel">
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Clean motor housing including stator and rotor<br>
                                                Check  bearing,  replace when  necessary<br>
                                                Check  cable installations and connections<br>
                                                Check  foundation and tighten all bolts
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <b-alert :show="true" variant="success">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-thumbs-up"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                        </b-alert>
                                        <b-alert :show="true" variant="danger">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-close"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                        </b-alert>
                                        <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                        <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                      </b-collapse>
                                    </b-card>
                                  </div>
                                </b-card-body>
                              </b-collapse>
                            </b-card>
                          </div>
                          <!-- DOCKING JOB END -->

                          <!-- DOCKING JOB -->
                          <div class="accordion" role="tablist">
                            <b-card no-body >
                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle.jobCat-3 variant="primary" class="text-left"><i class="fa fa-chevron-circle-down"></i>MACHINERY EQUIPMENT<strong class="pull-right">BOBOT PEKERJAAN : 30%</strong></b-button>
                              </b-card-header>

                              <b-collapse id="jobCat-3" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                  <!-- BOBOT DOCKING JOB -->
                                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                                    <template v-slot:body >
                                      <div class="d-flex align-items-center justify-content-between">
                                        <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                                        <div class="text-right">
                                          <h2 class="mb-0"><span class="counter">100%</span></h2>
                                          <h5 class="">Persentase Docking Job</h5>
                                        </div>
                                      </div>
                                    </template>
                                  </iq-card>
                                  <!-- BOBOT DOCKING JOB END -->
                                  <div class="accordion" role="tablist">
                                    <b-card no-body >
                                      <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                        <b-button block v-b-toggle.job-3-1 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> General Overhaul ME (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 30%</strong></b-button>
                                      </b-card-header>
                                      <b-collapse id="job-3-1" accordion="my-accordion-sub" role="tabpanel">
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Clean motor housing including stator and rotor<br>
                                                Check  bearing,  replace when  necessary<br>
                                                Check  cable installations and connections<br>
                                                Check  foundation and tighten all bolts
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <b-alert :show="true" variant="success">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-thumbs-up"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                        </b-alert>
                                        <b-alert :show="true" variant="danger">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-close"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                        </b-alert>
                                        <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                        <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                      </b-collapse>
                                    </b-card>
                                    <b-card no-body >
                                      <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                        <b-button block v-b-toggle.job-3-2 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Kalibrasi injector (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                      </b-card-header>
                                      <b-collapse id="job-3-2" accordion="my-accordion-sub" role="tabpanel">
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Clean motor housing including stator and rotor<br>
                                                Check  bearing,  replace when  necessary<br>
                                                Check  cable installations and connections<br>
                                                Check  foundation and tighten all bolts
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <b-alert :show="true" variant="success">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-thumbs-up"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                        </b-alert>
                                        <b-alert :show="true" variant="danger">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-close"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                        </b-alert>
                                        <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                        <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                      </b-collapse>
                                    </b-card>
                                    <b-card no-body >
                                      <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                        <b-button block v-b-toggle.job-3-3 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Service/ganti baru dynamo starter (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                      </b-card-header>
                                      <b-collapse id="job-3-3" accordion="my-accordion-sub" role="tabpanel">
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Clean motor housing including stator and rotor<br>
                                                Check  bearing,  replace when  necessary<br>
                                                Check  cable installations and connections<br>
                                                Check  foundation and tighten all bolts
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <b-alert :show="true" variant="success">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-thumbs-up"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                        </b-alert>
                                        <b-alert :show="true" variant="danger">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-close"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                        </b-alert>
                                        <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                        <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                      </b-collapse>
                                    </b-card>
                                    <b-card no-body >
                                      <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                        <b-button block v-b-toggle.job-3-4 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Service/ganti baru alternator (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 40%</strong></b-button>
                                      </b-card-header>
                                      <b-collapse id="job-3-4" accordion="my-accordion-sub" role="tabpanel">
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Clean motor housing including stator and rotor<br>
                                                Check  bearing,  replace when  necessary<br>
                                                Check  cable installations and connections<br>
                                                Check  foundation and tighten all bolts
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <b-alert :show="true" variant="success">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-thumbs-up"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                        </b-alert>
                                        <b-alert :show="true" variant="danger">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-close"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                        </b-alert>
                                        <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                        <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                      </b-collapse>
                                    </b-card>
                                    <b-card no-body >
                                      <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                        <b-button block v-b-toggle.job-3-5 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Service turbocharger<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                      </b-card-header>
                                      <b-collapse id="job-3-5" accordion="my-accordion-sub" role="tabpanel">
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Clean motor housing including stator and rotor<br>
                                                Check  bearing,  replace when  necessary<br>
                                                Check  cable installations and connections<br>
                                                Check  foundation and tighten all bolts
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <b-alert :show="true" variant="success">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-thumbs-up"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                        </b-alert>
                                        <b-alert :show="true" variant="danger">
                                          <div class="iq-alert-icon">
                                            <i class="fa fa-close"></i>
                                          </div>
                                          <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                        </b-alert>
                                        <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                        <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                      </b-collapse>
                                    </b-card>
                                  </div>
                                </b-card-body>
                              </b-collapse>
                            </b-card>
                          </div>
                          <!-- DOCKING JOB END -->
                          <b-row>
                            <b-col md="12" class="mt-3">
                              <b-card-text class="pull-right"><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
                              <br><br>
                              <b-button type="submit" variant="success" class="pull-right mr-1"><i class="fa fa-save"></i> Save</b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                </b-row>
              </template>
            </b-sidebar>
            <!-- SLIDE - FORM DETAIL DOCKING END -->
            
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
export default {
  name: 'DockingHistory',
  data () {
    return {
      slideDockingDetail: false,
    }
  },
  mounted () {
    xray.index()
  },
  computed: {
  },
  methods: {
  }
}
</script>