var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Activity Statistic")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.$route.meta.dark ? _c('ApexChart', {
          attrs: {
            "element": "home-chart-05",
            "chartOption": _vm.chart5
          }
        }) : _c('ApexChart', {
          attrs: {
            "element": "home-chart-05",
            "chartOption": _vm.chart5
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('img', {
          staticClass: "img-fluid rounded",
          attrs: {
            "src": require("../../assets/images/page-img/39.png"),
            "alt": "banner-img"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Patient Distribution")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('AmChart', {
          attrs: {
            "element": "doc-chart-01",
            "type": "dash-3",
            "option": [],
            "height": 415
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Patients In")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.$route.meta.dark ? _c('ApexChart', {
          attrs: {
            "element": "home-chart-06",
            "chartOption": _vm.chart6
          }
        }) : _c('ApexChart', {
          attrs: {
            "element": "home-chart-06",
            "chartOption": _vm.chart6
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Patients Satisfactions")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('h2', [_vm._v("3,897"), _c('span', {
          staticClass: "text-success font-size-14 ml-3 mr-3"
        }, [_c('i', {
          staticClass: "ri-arrow-up-fill mr-2"
        }), _vm._v("+3.3%")]), _c('small', {
          staticClass: "text-secondary font-size-14"
        }, [_vm._v("Generated by clients")])]), _c('div', {
          staticClass: "progress mt-3"
        }, [_c('div', {
          staticClass: "progress-bar bg-primary",
          staticStyle: {
            "width": "40%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "40",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }), _c('div', {
          staticClass: "progress-bar bg-warning",
          staticStyle: {
            "width": "20%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "20",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }), _c('div', {
          staticClass: "progress-bar bg-info",
          staticStyle: {
            "width": "10%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "10",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }), _c('div', {
          staticClass: "progress-bar bg-danger",
          staticStyle: {
            "width": "40%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "40",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }), _c('div', {
          staticClass: "progress-bar bg-success",
          staticStyle: {
            "width": "20%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "20",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }), _c('div', {
          staticClass: "progress-bar bg-secondary",
          staticStyle: {
            "width": "10%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "10",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        })]), _c('div', {
          staticClass: "table-responsive mt-4"
        }, [_c('table', {
          staticClass: "table mb-0 table-borderless"
        }, [_c('tbody', [_c('tr', [_c('td', [_c('div', {
          staticClass: "iq-profile-avatar status-online mt-4"
        })]), _c('td', [_c('h4', [_vm._v("Excellent")])]), _c('td', [_c('span', {
          staticClass: "text-muted"
        }, [_vm._v("2400")])]), _c('td', [_vm._v("60%")])]), _c('tr', [_c('td', [_c('div', {
          staticClass: "iq-profile-avatar status-blue mt-4"
        })]), _c('td', [_c('h4', [_vm._v("Very Good")])]), _c('td', [_c('span', {
          staticClass: "text-muted"
        }, [_vm._v("1200")])]), _c('td', [_vm._v("30%")])]), _c('tr', [_c('td', [_c('div', {
          staticClass: "iq-profile-avatar status-primary mt-4"
        })]), _c('td', [_c('h4', [_vm._v("Good")])]), _c('td', [_c('span', {
          staticClass: "text-muted"
        }, [_vm._v("240")])]), _c('td', [_vm._v("6%")])]), _c('tr', [_c('td', [_c('div', {
          staticClass: "iq-profile-avatar status-info mt-4"
        })]), _c('td', [_c('h4', [_vm._v("Fair")])]), _c('td', [_c('span', {
          staticClass: "text-muted"
        }, [_vm._v("80")])]), _c('td', [_vm._v("2%")])]), _c('tr', [_c('td', [_c('div', {
          staticClass: "iq-profile-avatar status-away mt-4"
        })]), _c('td', [_c('h4', [_vm._v("Poor")])]), _c('td', [_c('span', {
          staticClass: "text-muted"
        }, [_vm._v("40")])]), _c('td', [_vm._v("1%")])]), _c('tr', [_c('td', [_c('div', {
          staticClass: "iq-profile-avatar status-danger mt-4"
        })]), _c('td', [_c('h4', [_vm._v("Very Poor")])]), _c('td', [_c('span', {
          staticClass: "text-muted"
        }, [_vm._v("40")])]), _c('td', [_vm._v("1%")])])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Tasks")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('ul', {
          staticClass: "nav nav-pills",
          attrs: {
            "id": "myTab",
            "role": "tablist"
          }
        }, [_c('li', {
          staticClass: "nav-item"
        }, [_c('a', {
          staticClass: "nav-link active",
          attrs: {
            "id": "home-tab",
            "data-toggle": "tab",
            "href": "#home",
            "role": "tab",
            "aria-controls": "home",
            "aria-selected": "true"
          }
        }, [_vm._v("Home")])]), _c('li', {
          staticClass: "nav-item"
        }, [_c('a', {
          staticClass: "nav-link",
          attrs: {
            "id": "profile-tab",
            "data-toggle": "tab",
            "href": "#profile",
            "role": "tab",
            "aria-controls": "profile",
            "aria-selected": "false"
          }
        }, [_vm._v("Profile")])]), _c('li', {
          staticClass: "nav-item"
        }, [_c('a', {
          staticClass: "nav-link",
          attrs: {
            "id": "contact-tab",
            "data-toggle": "tab",
            "href": "#contact",
            "role": "tab",
            "aria-controls": "contact",
            "aria-selected": "false"
          }
        }, [_vm._v("Contact")])])])])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "tab-content",
          attrs: {
            "id": "myTabContent"
          }
        }, [_c('div', {
          staticClass: "tab-pane fade show active",
          attrs: {
            "id": "home",
            "role": "tabpanel",
            "aria-labelledby": "home-tab"
          }
        }, [_c('div', {
          staticClass: "d-flex tasks-card",
          attrs: {
            "role": "alert"
          }
        }, [_c('div', {
          staticClass: "custom-control custom-checkbox"
        }, [_c('input', {
          staticClass: "custom-control-input",
          attrs: {
            "type": "checkbox",
            "id": "customCheck11"
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": "customCheck11"
          }
        }, [_vm._v("You should check in some of below.")])]), _c('button', {
          staticClass: "close ml-auto",
          attrs: {
            "type": "button",
            "data-dismiss": "alert",
            "aria-label": "Close"
          }
        }, [_c('i', {
          staticClass: "ri-close-line"
        })])]), _c('div', {
          staticClass: "d-flex tasks-card",
          attrs: {
            "role": "alert"
          }
        }, [_c('div', {
          staticClass: "custom-control custom-checkbox"
        }, [_c('input', {
          staticClass: "custom-control-input",
          attrs: {
            "type": "checkbox",
            "id": "customCheck10"
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": "customCheck10"
          }
        }, [_vm._v("Get the address of customer")])]), _c('button', {
          staticClass: "close ml-auto",
          attrs: {
            "type": "button",
            "data-dismiss": "alert",
            "aria-label": "Close"
          }
        }, [_c('i', {
          staticClass: "ri-close-line"
        })])]), _c('div', {
          staticClass: "d-flex tasks-card",
          attrs: {
            "role": "alert"
          }
        }, [_c('div', {
          staticClass: "custom-control custom-checkbox"
        }, [_c('input', {
          staticClass: "custom-control-input",
          attrs: {
            "type": "checkbox",
            "id": "customCheck12"
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": "customCheck12"
          }
        }, [_vm._v("Contact Vendor for parcel")])]), _c('button', {
          staticClass: "close ml-auto",
          attrs: {
            "type": "button",
            "data-dismiss": "alert",
            "aria-label": "Close"
          }
        }, [_c('i', {
          staticClass: "ri-close-line"
        })])]), _c('div', {
          staticClass: "d-flex tasks-card",
          attrs: {
            "role": "alert"
          }
        }, [_c('div', {
          staticClass: "custom-control custom-checkbox"
        }, [_c('input', {
          staticClass: "custom-control-input",
          attrs: {
            "type": "checkbox",
            "id": "customCheck13"
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": "customCheck13"
          }
        }, [_vm._v("Refule delivery truck")])]), _c('button', {
          staticClass: "close ml-auto",
          attrs: {
            "type": "button",
            "data-dismiss": "alert",
            "aria-label": "Close"
          }
        }, [_c('i', {
          staticClass: "ri-close-line"
        })])]), _c('div', {
          staticClass: "d-flex tasks-card",
          attrs: {
            "role": "alert"
          }
        }, [_c('div', {
          staticClass: "custom-control custom-checkbox"
        }, [_c('input', {
          staticClass: "custom-control-input",
          attrs: {
            "type": "checkbox",
            "id": "customCheck14"
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": "customCheck14"
          }
        }, [_vm._v("Pick up for order no. 334")])]), _c('button', {
          staticClass: "close ml-auto",
          attrs: {
            "type": "button",
            "data-dismiss": "alert",
            "aria-label": "Close"
          }
        }, [_c('i', {
          staticClass: "ri-close-line"
        })])]), _c('div', {
          staticClass: "d-flex tasks-card",
          attrs: {
            "role": "alert"
          }
        }, [_c('div', {
          staticClass: "custom-control custom-checkbox"
        }, [_c('input', {
          staticClass: "custom-control-input",
          attrs: {
            "type": "checkbox",
            "id": "customCheck15"
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": "customCheck15"
          }
        }, [_vm._v("Pay taxes for every bill")])]), _c('button', {
          staticClass: "close ml-auto",
          attrs: {
            "type": "button",
            "data-dismiss": "alert",
            "aria-label": "Close"
          }
        }, [_c('i', {
          staticClass: "ri-close-line"
        })])]), _c('div', {
          staticClass: "d-flex tasks-card",
          attrs: {
            "role": "alert"
          }
        }, [_c('div', {
          staticClass: "custom-control custom-checkbox"
        }, [_c('input', {
          staticClass: "custom-control-input",
          attrs: {
            "type": "checkbox",
            "id": "customCheck16"
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": "customCheck16"
          }
        }, [_vm._v("I am designers & I have no life")])]), _c('button', {
          staticClass: "close ml-auto",
          attrs: {
            "type": "button",
            "data-dismiss": "alert",
            "aria-label": "Close"
          }
        }, [_c('i', {
          staticClass: "ri-close-line"
        })])]), _c('div', {
          staticClass: "d-flex tasks-card",
          attrs: {
            "role": "alert"
          }
        }, [_c('div', {
          staticClass: "custom-control custom-checkbox"
        }, [_c('input', {
          staticClass: "custom-control-input",
          attrs: {
            "type": "checkbox",
            "id": "customCheck17"
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": "customCheck17"
          }
        }, [_vm._v("This is a good product. Buy it :) ")])]), _c('button', {
          staticClass: "close ml-auto",
          attrs: {
            "type": "button",
            "data-dismiss": "alert",
            "aria-label": "Close"
          }
        }, [_c('i', {
          staticClass: "ri-close-line"
        })])])]), _c('div', {
          staticClass: "tab-pane fade",
          attrs: {
            "id": "profile",
            "role": "tabpanel",
            "aria-labelledby": "profile-tab"
          }
        }, [_c('div', {
          staticClass: "d-flex tasks-card",
          attrs: {
            "role": "alert"
          }
        }, [_c('div', {
          staticClass: "custom-control custom-checkbox"
        }, [_c('input', {
          staticClass: "custom-control-input",
          attrs: {
            "type": "checkbox",
            "id": "customCheck18"
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": "customCheck18"
          }
        }, [_vm._v("You should check in on some of below.")])]), _c('button', {
          staticClass: "close ml-auto",
          attrs: {
            "type": "button",
            "data-dismiss": "alert",
            "aria-label": "Close"
          }
        }, [_c('i', {
          staticClass: "ri-close-line"
        })])]), _c('div', {
          staticClass: "d-flex tasks-card",
          attrs: {
            "role": "alert"
          }
        }, [_c('div', {
          staticClass: "custom-control custom-checkbox"
        }, [_c('input', {
          staticClass: "custom-control-input",
          attrs: {
            "type": "checkbox",
            "id": "customCheck19"
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": "customCheck19"
          }
        }, [_vm._v("You should check in on some of below.")])]), _c('button', {
          staticClass: "close ml-auto",
          attrs: {
            "type": "button",
            "data-dismiss": "alert",
            "aria-label": "Close"
          }
        }, [_c('i', {
          staticClass: "ri-close-line"
        })])])]), _c('div', {
          staticClass: "tab-pane fade",
          attrs: {
            "id": "contact",
            "role": "tabpanel",
            "aria-labelledby": "contact-tab"
          }
        }, [_c('div', {
          staticClass: "d-flex tasks-card",
          attrs: {
            "role": "alert"
          }
        }, [_c('div', {
          staticClass: "custom-control custom-checkbox"
        }, [_c('input', {
          staticClass: "custom-control-input",
          attrs: {
            "type": "checkbox",
            "id": "customCheck110"
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": "customCheck110"
          }
        }, [_vm._v("You should check in on some of below.")])]), _c('button', {
          staticClass: "close ml-auto",
          attrs: {
            "type": "button",
            "data-dismiss": "alert",
            "aria-label": "Close"
          }
        }, [_c('i', {
          staticClass: "ri-close-line"
        })])]), _c('div', {
          staticClass: "d-flex tasks-card",
          attrs: {
            "role": "alert"
          }
        }, [_c('div', {
          staticClass: "custom-control custom-checkbox"
        }, [_c('input', {
          staticClass: "custom-control-input",
          attrs: {
            "type": "checkbox",
            "id": "customCheck111"
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": "customCheck111"
          }
        }, [_vm._v("You should check in on some of below.")])]), _c('button', {
          staticClass: "close ml-auto",
          attrs: {
            "type": "button",
            "data-dismiss": "alert",
            "aria-label": "Close"
          }
        }, [_c('i', {
          staticClass: "ri-close-line"
        })])]), _c('div', {
          staticClass: "d-flex tasks-card",
          attrs: {
            "role": "alert"
          }
        }, [_c('div', {
          staticClass: "custom-control custom-checkbox"
        }, [_c('input', {
          staticClass: "custom-control-input",
          attrs: {
            "type": "checkbox",
            "id": "customCheck112"
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": "customCheck112"
          }
        }, [_vm._v("You should check in on some of below.")])]), _c('button', {
          staticClass: "close ml-auto",
          attrs: {
            "type": "button",
            "data-dismiss": "alert",
            "aria-label": "Close"
          }
        }, [_c('i', {
          staticClass: "ri-close-line"
        })])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Patient Timeline")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle text-primary",
                attrs: {
                  "id": "dropdownMenuButton4",
                  "data-toggle": "dropdown"
                }
              }, [_vm._v(" View All ")])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-eye-fill mr-2"
        }), _vm._v("View")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-delete-bin-6-fill mr-2"
        }), _vm._v("Delete")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-pencil-fill mr-2"
        }), _vm._v("Edit")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-printer-fill mr-2"
        }), _vm._v("Print")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-file-download-fill mr-2"
        }), _vm._v("Download")])], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('ul', {
          staticClass: "iq-timeline"
        }, [_c('li', [_c('div', {
          staticClass: "timeline-dots"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Patient Checkup")]), _c('small', {
          staticClass: "float-right mt-1"
        }, [_vm._v("23 November 2019")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque ")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Patient Admit")]), _c('small', {
          staticClass: "float-right mt-1"
        }, [_vm._v("24 November 2019")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-primary"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Treatment Starts")]), _c('small', {
          staticClass: "float-right mt-1"
        }, [_vm._v("24 November 2019")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-warning"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Patient Discharge")]), _c('small', {
          staticClass: "float-right mt-1"
        }, [_vm._v("30 November 2019")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque scelerisque")])])])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Patients Lists ")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle text-primary",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "ri-more-fill"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-eye-fill mr-2"
        }), _vm._v("View")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-delete-bin-6-fill mr-2"
        }), _vm._v("Delete")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-pencil-fill mr-2"
        }), _vm._v("Edit")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-printer-fill mr-2"
        }), _vm._v("Print")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-file-download-fill mr-2"
        }), _vm._v("Download")])], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-borderless"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Patient")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("E-mail Id ")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Contact")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Disease")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Petey Cruiser")]), _c('td', [_vm._v("peteycruiser01@gmail.com")]), _c('td', [_vm._v("+1-202-555-0146")]), _c('td', [_vm._v("Fever")])]), _c('tr', [_c('td', [_vm._v("Anna Sthesia")]), _c('td', [_vm._v("annasthesia121@gmail.com")]), _c('td', [_vm._v("+1-202-555-0164")]), _c('td', [_vm._v("Cancer")])]), _c('tr', [_c('td', [_vm._v("Paul Molive")]), _c('td', [_vm._v("paulmolive30@gmail.com")]), _c('td', [_vm._v("+1-202-555-0153")]), _c('td', [_vm._v("Diabetes")])]), _c('tr', [_c('td', [_vm._v("Anna Mull")]), _c('td', [_vm._v("annamull07@gmail.com")]), _c('td', [_vm._v("+1-202-555-0154")]), _c('td', [_vm._v("eye")])])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Patients Reports")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('ul', {
          staticClass: "report-lists m-0 p-0"
        }, [_c('li', {
          staticClass: "d-flex mb-4 align-items-center"
        }, [_c('div', {
          staticClass: "media-support-info"
        }, [_c('h6', [_vm._v("X-ray.pdf")]), _c('a', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("View report")])]), _c('button', {
          staticClass: "btn btn-success",
          attrs: {
            "type": "button",
            "name": "button"
          }
        }, [_vm._v("Download ")])]), _c('li', {
          staticClass: "d-flex mb-4 align-items-center"
        }, [_c('div', {
          staticClass: "media-support-info"
        }, [_c('h6', [_vm._v("pathologyreport.pdf")]), _c('a', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("View report")])]), _c('button', {
          staticClass: "btn btn-success",
          attrs: {
            "type": "button",
            "name": "button"
          }
        }, [_vm._v("Download ")])]), _c('li', {
          staticClass: "d-flex mb-4 align-items-center"
        }, [_c('div', {
          staticClass: "media-support-info"
        }, [_c('h6', [_vm._v("laboratoryreports.pdf")]), _c('a', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("View report")])]), _c('button', {
          staticClass: "btn btn-danger",
          attrs: {
            "type": "button",
            "name": "button"
          }
        }, [_vm._v("On Hold ")])]), _c('li', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "media-support-info"
        }, [_c('h6', [_vm._v("operativereport.pdf")]), _c('a', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("View report")])]), _c('button', {
          staticClass: "btn btn-success",
          attrs: {
            "type": "button",
            "name": "button"
          }
        }, [_vm._v("Download ")])])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }