var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    class: _vm.liClass
  }, [_c('a', {
    class: 'nav-link ' + _vm.className,
    attrs: {
      "id": _vm.id,
      "data-toggle": _vm.dataToggle,
      "href": _vm.url,
      "role": _vm.role,
      "aria-controls": _vm.ariaControls,
      "aria-selected": _vm.ariaSelected
    }
  }, [_vm.hasTitleSlot ? _vm._t("title") : [_vm._v(_vm._s(_vm.title))]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }