<template>
    <b-container fluid>
      <b-row>
        <b-col lg="12">
            <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>REPORT - CASH FLOW</strong></h5>
                </template>
                <template v-slot:headerAction>
                  <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                    <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                        <i class="fa fa-save m-0"></i>
                        </span>
                    </template>
                    <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
                    <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
                  </b-dropdown>
                </template>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12" class="text-center">
                      <h4>PT. CAHAYA MARITIM</h4>
                      <h3>CASH FLOW (INDIRECT)</h3>
                      <p>Form Periode January - December 2017</p>
                    </b-col>
                    <b-col md="12" class="pl-5 pr-5">
                        <table class="table table-borderless table-sm">
                            <thead>
                            <tr>
                                <th width="80%" class="pl-5">DESCRIPTION</th>
                                <th width="20%" class="text-right pr-5">AMOUNT</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th colspan="2" class="pl-5">Operational Activity</th>
                                </tr>
                                <tr>
                                    <td style="padding-left:80px">Profit/Loss</td>
                                    <td class="text-right pr-5">0</td>
                                </tr>
                                <tr>
                                    <td style="padding-left:80px">Add Accumulated Depreciation</td>
                                    <td class="text-right pr-5">77.160.000</td>
                                </tr>
                                <tr>
                                    <td style="padding-left:80px">Add Payable</td>
                                    <td class="text-right pr-5">30.495.000</td>
                                </tr>
                                <tr>
                                    <td style="padding-left:80px">Add Other Current Liability</td>
                                    <td class="text-right pr-5">12.500.000</td>
                                </tr>
                                <tr>
                                    <td style="padding-left:80px">Less Receivable</td>
                                    <td class="text-right pr-5">-30.695.000</td>
                                </tr>
                                <tr>
                                    <td style="padding-left:80px">Less Inventory</td>
                                    <td class="text-right pr-5">-19.410.000</td>
                                </tr>
                                <tr>
                                    <td style="padding-left:80px">Less Other Current Asset</td>
                                    <td class="text-right pr-5" style="border-bottom:solid 1px gray">-48.000.000</td>
                                </tr>
                                <tr>
                                    <th style="padding-left:80px">Total Operational Activity</th>
                                    <th class="text-right pr-5">22.050.000</th>
                                </tr>

                                <tr>
                                    <th colspan="2" class="pl-5">Investment</th>
                                </tr>
                                <tr>
                                    <td style="padding-left:80px">Less Fixed Asset</td>
                                    <td class="text-right pr-5">0</td>
                                </tr>
                                <tr>
                                    <td style="padding-left:80px">Less Other Asset</td>
                                    <td class="text-right pr-5" style="border-bottom:solid 1px gray">0</td>
                                </tr>
                                <tr>
                                    <th style="padding-left:80px">Total Investment</th>
                                    <th class="text-right pr-5">0</th>
                                </tr>

                                <tr>
                                    <th colspan="2" class="pl-5">Funding</th>
                                </tr>
                                <tr>
                                    <td style="padding-left:80px">Add Long Term Liability</td>
                                    <td class="text-right pr-5">185.000.000	</td>
                                </tr>
                                <tr>
                                    <td style="padding-left:80px">Add Equity</td>
                                    <td class="text-right pr-5" style="border-bottom:solid 1px gray">302.056.000</td>
                                </tr>
                                <tr>
                                    <th style="padding-left:80px">Total Funding</th>
                                    <th class="text-right pr-5" style="border-bottom:solid 1px gray">487.056.000</th>
                                </tr>
                                <tr>
                                    <th style="padding-left:80px">Total of Current cash flow (used) in this period</th>
                                    <th class="text-right pr-5" style="border-bottom:solid 1px gray">509.106.000</th>
                                </tr>
                                <tr>
                                    <th style="padding-left:80px">Cash & Cash equivalent on Opening period</th>
                                    <th class="text-right pr-5" style="border-bottom:solid 1px gray">0</th>
                                </tr>
                                <tr>
                                    <th style="padding-left:80px">Cash & Cash equivalent on End period</th>
                                    <th class="text-right pr-5">509.106.000</th>
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                    <b-col md="12"><hr></b-col>
                  </b-row>
                </template>
            </iq-card>
        </b-col>
      </b-row>
    </b-container>
  </template>
  <script>
  import { xray } from '../../config/pluginInit'
  export default {
    name: 'procurementReportArusKas',
    mounted () {
      xray.index()
    }
  }
  </script>
  