var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h4', {
    staticClass: "text-primary mb-2"
  }, [_vm._v("All Request :")])]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-primary"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "fas fa-database"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/"
          }
        }, [_c('h3', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("0")])]), _c('p', [_vm._v("To Send")])])], 1)])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-primary"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "fas fa-database"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/"
          }
        }, [_c('h3', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("0")])]), _c('p', [_vm._v("Waiting")])])], 1)])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-primary"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "fas fa-database"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/"
          }
        }, [_c('h3', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("0")])]), _c('p', [_vm._v("Late")])])], 1)])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h4', {
    staticClass: "text-primary mb-2"
  }, [_vm._v("My Request :")])]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-primary"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "fas fa-database"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/"
          }
        }, [_c('h3', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("0")])]), _c('p', [_vm._v("To Send")])])], 1)])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-primary"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "fas fa-database"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/"
          }
        }, [_c('h3', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("0")])]), _c('p', [_vm._v("Waiting")])])], 1)])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-primary"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "fas fa-database"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/"
          }
        }, [_c('h3', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("0")])]), _c('p', [_vm._v("Late")])])], 1)])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("REQUISITION")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.filter",
            modifiers: {
              "filter": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-4",
          attrs: {
            "visible": "",
            "id": "filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionRequestClass,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Request Class")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectRequestClass,
            callback: function callback($$v) {
              _vm.selectRequestClass = $$v;
            },
            expression: "selectRequestClass"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionStatusPurchaseRequest,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Status")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectStatusPurchaseRequest,
            callback: function callback($$v) {
              _vm.selectStatusPurchaseRequest = $$v;
            },
            expression: "selectStatusPurchaseRequest"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionPriority,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Priority")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectPriority,
            callback: function callback($$v) {
              _vm.selectPriority = $$v;
            },
            expression: "selectPriority"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionCompany,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Company")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectCompany,
            callback: function callback($$v) {
              _vm.selectCompany = $$v;
            },
            expression: "selectCompany"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Add Data',
            expression: "'Add Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              _vm.addPurchaseRequest = !_vm.addPurchaseRequest;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        })]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Update Data',
            expression: "'Update Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        })]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Delete Data',
            expression: "'Delete Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash"
        })]), _c('b-dropdown', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Export Data',
            expression: "'Export Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "link iq-bg-primary",
            "toggle-class": "text-decoration-none",
            "no-caret": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-save m-0"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _vm._v("Export Excel")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf mr-2"
        }), _vm._v("Export PDF")])], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "align-middle text-center"
        }, [_vm._l(_vm.state, function (item, index) {
          return [_c('b-checkbox', {
            key: index,
            staticClass: "custom-switch-color",
            attrs: {
              "color": _vm.primary,
              "disabled": item.disabled,
              "name": "check-button",
              "inline": ""
            },
            model: {
              value: item.checked,
              callback: function callback($$v) {
                _vm.$set(item, "checked", $$v);
              },
              expression: "item.checked"
            }
          })];
        })], 2), _c('th', {
          staticClass: "align-middle text-center"
        }, [_vm._v("REQUEST ID")]), _c('th', {
          staticClass: "align-middle text-center"
        }, [_vm._v("CLASSIFICATION")]), _c('th', {
          staticClass: "align-middle text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "align-middle text-center"
        }, [_vm._v("DATE REQUEST")]), _c('th', {
          staticClass: "align-middle text-center"
        }, [_vm._v("FLEET")]), _c('th', {
          staticClass: "align-middle text-center"
        }, [_vm._v("COMPANY")]), _c('th', {
          staticClass: "align-middle text-center"
        }, [_vm._v("DEPARTMENT")]), _c('th', {
          staticClass: "align-middle text-center"
        }, [_vm._v("REQUEST BY")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "align-middle text-center"
        }, [_vm._l(_vm.state, function (item, index) {
          return [_c('b-checkbox', {
            key: index,
            staticClass: "custom-switch-color",
            attrs: {
              "color": _vm.primary,
              "disabled": item.disabled,
              "name": "check-button",
              "inline": ""
            },
            model: {
              value: item.checked,
              callback: function callback($$v) {
                _vm.$set(item, "checked", $$v);
              },
              expression: "item.checked"
            }
          })];
        })], 2), _c('th', {
          staticClass: "align-middle text-center"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              _vm.slidePageFirst = !_vm.slidePageFirst;
            }
          }
        }, [_vm._v("REQ0001")])]), _c('td', {
          staticClass: "align-middle text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Material")])], 1), _c('td', {
          staticClass: "align-middle text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Open")])], 1), _c('td', {
          staticClass: "align-middle text-center"
        }, [_vm._v("11 Nov 2022")]), _c('td', {
          staticClass: "align-middle text-center"
        }, [_vm._v("TB. A001")]), _c('td', {
          staticClass: "align-middle text-center"
        }, [_vm._v("PT. Lintas Prima Perkasa")]), _c('td', {
          staticClass: "align-middle text-center"
        }, [_vm._v("Technical")]), _c('td', {
          staticClass: "align-middle text-center"
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('td', {
          staticClass: "align-middle text-center"
        }, [_vm._l(_vm.state, function (item, index) {
          return [_c('b-checkbox', {
            key: index,
            staticClass: "custom-switch-color",
            attrs: {
              "color": _vm.primary,
              "disabled": item.disabled,
              "name": "check-button",
              "inline": ""
            },
            model: {
              value: item.checked,
              callback: function callback($$v) {
                _vm.$set(item, "checked", $$v);
              },
              expression: "item.checked"
            }
          })];
        })], 2), _c('th', {
          staticClass: "align-middle text-center"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              _vm.slidePageFirst = !_vm.slidePageFirst;
            }
          }
        }, [_vm._v("REQ0002")])]), _c('td', {
          staticClass: "align-middle text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Material")])], 1), _c('td', {
          staticClass: "align-middle text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("Approve")])], 1), _c('td', {
          staticClass: "align-middle text-center"
        }, [_vm._v("23 Nov 2022")]), _c('td', {
          staticClass: "align-middle text-center"
        }, [_vm._v("TB. A002")]), _c('td', {
          staticClass: "align-middle text-center"
        }, [_vm._v("PT. Lintas Prima Perkasa")]), _c('td', {
          staticClass: "align-middle text-center"
        }, [_vm._v("Operational")]), _c('td', {
          staticClass: "align-middle text-center"
        }, [_vm._v("Nurul Akbar")])])])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('b-sidebar', {
          attrs: {
            "id": "detail_vendor",
            "title": "DETAIL - REQ0001",
            "width": "60%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var hide = _ref.hide;
              return [_c('b-row', {
                staticClass: "p-5"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Request Number")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("REQ001")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Company")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Vehement Capital")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Request Class")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("PURCHASE")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Department")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Operational")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Priority")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Normal")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Fleet")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("TUGBOAT 1001")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Request Date")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("01/11/2022")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Project")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Oil Project")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Request By")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Sub Project")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_c('b-badge', {
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("Long Hauling")])], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Description")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Sparepart TUGBOAT 1001")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Location")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Sangatta")])])])])]), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('tab-nav', {
                attrs: {
                  "tabs": true
                }
              }, [_c('tab-nav-items', {
                attrs: {
                  "active": true,
                  "id": "first_tab2",
                  "ariaControls": "tab_first2",
                  "role": "tab",
                  "ariaSelected": true,
                  "title": "Details"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "second_tab2",
                  "ariaControls": "tab_second2",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Shipping"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "third_tab2",
                  "ariaControls": "tab_third2",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Approval"
                }
              })], 1), _c('div', {
                staticClass: "tab-content",
                attrs: {
                  "id": "myTabContent"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "tab_first2"
                }
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("INVENTTORY ID")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("DESCRIPTION ITEM")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("QTY")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("UOM")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("EXPECT DATE")])])]), _c('tbody', [_c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("[M001] - Engine Oil Filter")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Engine Oil Filter")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("10")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("pcs")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("11 Nov 2022")])])])])]), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "tab_second2",
                  "aria-labelled-by": "second_tab"
                }
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Office Type")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Head Office")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("PIC")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Nurul Akbar")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Office")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("HO - Jakarta")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Provience")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("DKI Jakarta")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Company Name")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("PT. Lintas Prima Perkasa")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("City")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("North Jakarta")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Phone Number")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("021 984374")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Postal Code")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("12359")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Ponsel Number")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("0812 1214 3758")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Address")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Pantai indah kapuk ST. Gold Coast Office Tower, Lantai 21 Unit G-J, Penjaringan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Email")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("info@litasprimaperkasa.com")])])])])]), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "tab_third2",
                  "aria-labelled-by": "third_tab"
                }
              }, [_c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "align-middle text-center"
              }, [_vm._v("ASSIGN TO")]), _c('th', {
                staticClass: "align-middle text-center"
              }, [_vm._v("APPROVE BY")]), _c('th', {
                staticClass: "align-middle text-center"
              }, [_vm._v("APPROVE DATE")]), _c('th', {
                staticClass: "align-middle text-center"
              }, [_vm._v("STATUS")]), _c('th', {
                staticClass: "align-middle text-center"
              }, [_vm._v("REASON")])])]), _c('tbody', [_c('tr', [_c('td', {
                staticClass: "align-middle text-center"
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                staticClass: "align-middle text-center"
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                staticClass: "align-middle text-center"
              }, [_vm._v("25 Nov 2022")]), _c('td', {
                staticClass: "align-middle text-center"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("APPROVE")])], 1), _c('td', {
                staticClass: "align-middle text-center"
              }, [_vm._v("No Comment")])])])])])], 1)], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.slidePageFirst,
            callback: function callback($$v) {
              _vm.slidePageFirst = $$v;
            },
            expression: "slidePageFirst"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "addPurchaseRequest",
            "title": "NEW DATA",
            "width": "60%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hide = _ref2.hide;
              return [_c('b-row', {
                staticClass: "p-5"
              }, [_c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Request Number",
                  "label-for": "requestNumber"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "requestNumber",
                  "placeholder": "<< GENERATE NUMBER >>"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Request Class",
                  "label-for": "requestClass"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionRequestClass,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Class")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectRequestClass,
                  callback: function callback($$v) {
                    _vm.selectRequestClass = $$v;
                  },
                  expression: "selectRequestClass"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Priority",
                  "label-for": "requestPriority"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionPriority,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Status")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectPriority,
                  callback: function callback($$v) {
                    _vm.selectPriority = $$v;
                  },
                  expression: "selectPriority"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Request Date",
                  "label-for": "requestDate"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "date",
                  "id": "requestDate"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Request By",
                  "label-for": "requestBy"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionRequestBy,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Requester")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectRequestBy,
                  callback: function callback($$v) {
                    _vm.selectRequestBy = $$v;
                  },
                  expression: "selectRequestBy"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Description",
                  "label-for": "description"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "description"
                }
              })], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Company",
                  "label-for": "requestCompany"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionCompany,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Company")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectCompany,
                  callback: function callback($$v) {
                    _vm.selectCompany = $$v;
                  },
                  expression: "selectCompany"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Department",
                  "label-for": "requestDepartment"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionDepartment,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Department")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectDepartment,
                  callback: function callback($$v) {
                    _vm.selectDepartment = $$v;
                  },
                  expression: "selectDepartment"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Fleet",
                  "label-for": "requestFleet"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionFleet,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Fleet")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectFleet,
                  callback: function callback($$v) {
                    _vm.selectFleet = $$v;
                  },
                  expression: "selectFleet"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Project",
                  "label-for": "Project"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionProject,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Project")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectProject,
                  callback: function callback($$v) {
                    _vm.selectProject = $$v;
                  },
                  expression: "selectProject"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Sub Project",
                  "label-for": "requestSubProject"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionSubProject,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Sub Project")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectSubProject,
                  callback: function callback($$v) {
                    _vm.selectSubProject = $$v;
                  },
                  expression: "selectSubProject"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Location",
                  "label-for": "requestLocation"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionLocation,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Location")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectLocation,
                  callback: function callback($$v) {
                    _vm.selectLocation = $$v;
                  },
                  expression: "selectLocation"
                }
              })], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('hr')]), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('tab-nav', {
                attrs: {
                  "tabs": true
                }
              }, [_c('tab-nav-items', {
                attrs: {
                  "active": true,
                  "id": "first_tab",
                  "ariaControls": "tab_first",
                  "role": "tab",
                  "ariaSelected": true,
                  "title": "Details"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "second_tab",
                  "ariaControls": "tab_second",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Shipping"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "third_tab",
                  "ariaControls": "tab_third",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Approval"
                }
              })], 1), _c('div', {
                staticClass: "tab-content",
                attrs: {
                  "id": "myTabContent"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "tab_first"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.top",
                  value: 'Add Data',
                  expression: "'Add Data'",
                  modifiers: {
                    "top": true
                  }
                }],
                staticClass: "float-right mr-1 mb-3",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                },
                on: {
                  "click": function click($event) {
                    _vm.addPurchaseRequest = !_vm.addPurchaseRequest;
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              })]), _c('b-button', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.top",
                  value: 'Edit Data',
                  expression: "'Edit Data'",
                  modifiers: {
                    "top": true
                  }
                }],
                staticClass: "float-right mr-1 mb-3",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                }
              }, [_c('i', {
                staticClass: "fa fa-edit"
              })]), _c('b-button', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.top",
                  value: 'Delete Data',
                  expression: "'Delete Data'",
                  modifiers: {
                    "top": true
                  }
                }],
                staticClass: "float-right mr-1 mb-3",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash"
              })]), _c('b-button', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.top",
                  value: 'Refresh Data',
                  expression: "'Refresh Data'",
                  modifiers: {
                    "top": true
                  }
                }],
                staticClass: "float-right mr-1 mb-3",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                }
              }, [_c('i', {
                staticClass: "fa fa-refresh"
              })]), _c('table', {
                staticClass: "table table-borderless table-sm"
              }, [_c('thead', [_c('tr', {
                staticClass: "bg-info"
              }, [_c('th', {
                staticClass: "align-middle text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._l(_vm.state, function (item, index) {
                return [_c('b-checkbox', {
                  key: index,
                  staticClass: "custom-switch-color",
                  attrs: {
                    "color": _vm.primary,
                    "disabled": item.disabled,
                    "name": "check-button",
                    "inline": ""
                  },
                  model: {
                    value: item.checked,
                    callback: function callback($$v) {
                      _vm.$set(item, "checked", $$v);
                    },
                    expression: "item.checked"
                  }
                })];
              })], 2), _c('th', {
                staticClass: "align-middle text-center",
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("INVENORY ID")]), _c('th', {
                staticClass: "align-middle text-center",
                attrs: {
                  "width": "35x%"
                }
              }, [_vm._v("DESCRIPTION")]), _c('th', {
                staticClass: "align-middle text-center",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v("QTY")]), _c('th', {
                staticClass: "align-middle text-center",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v("UOM")]), _c('th', {
                staticClass: "align-middle text-center",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v("EXPECT DATE")])])]), _c('tbody', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._l(_vm.state, function (item, index) {
                return [_c('b-checkbox', {
                  key: index,
                  staticClass: "custom-switch-color",
                  attrs: {
                    "color": _vm.primary,
                    "disabled": item.disabled,
                    "name": "check-button",
                    "inline": ""
                  },
                  model: {
                    value: item.checked,
                    callback: function callback($$v) {
                      _vm.$set(item, "checked", $$v);
                    },
                    expression: "item.checked"
                  }
                })];
              })], 2), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionInventoryID
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Item ID")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectInventoryID,
                  callback: function callback($$v) {
                    _vm.selectInventoryID = $$v;
                  },
                  expression: "selectInventoryID"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Engine Oil Filter")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-right",
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty",
                  "placeholder": "10"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-select', {
                attrs: {
                  "plain": ""
                }
              }, [_c('b-form-select-option', [_vm._v("Pcs")]), _c('b-form-select-option', [_vm._v("Meter")]), _c('b-form-select-option', [_vm._v("Box")]), _c('b-form-select-option', [_vm._v("Lusin")])], 1)], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "date",
                  "id": "requestDate"
                }
              })], 1)]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._l(_vm.state, function (item, index) {
                return [_c('b-checkbox', {
                  key: index,
                  staticClass: "custom-switch-color",
                  attrs: {
                    "color": _vm.primary,
                    "disabled": item.disabled,
                    "name": "check-button",
                    "inline": ""
                  },
                  model: {
                    value: item.checked,
                    callback: function callback($$v) {
                      _vm.$set(item, "checked", $$v);
                    },
                    expression: "item.checked"
                  }
                })];
              })], 2), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionInventoryID
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Item ID")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectInventoryID,
                  callback: function callback($$v) {
                    _vm.selectInventoryID = $$v;
                  },
                  expression: "selectInventoryID"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Engine Oil Filter")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-right",
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty",
                  "placeholder": "10"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-select', {
                attrs: {
                  "plain": ""
                }
              }, [_c('b-form-select-option', [_vm._v("Pcs")]), _c('b-form-select-option', [_vm._v("Meter")]), _c('b-form-select-option', [_vm._v("Box")]), _c('b-form-select-option', [_vm._v("Lusin")])], 1)], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "date",
                  "id": "requestDate"
                }
              })], 1)])])])], 1), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "tab_second",
                  "aria-labelled-by": "second_tab"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b', {
                staticClass: "text-primary"
              }, [_vm._v("SHIPPING TO")]), _c('hr')]), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Office Type",
                  "label-for": "OfficeType"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionOfficeType,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Office Type")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectOfficeType,
                  callback: function callback($$v) {
                    _vm.selectOfficeType = $$v;
                  },
                  expression: "selectOfficeType"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Office",
                  "label-for": "Office"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionOffice,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Office")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectOffice,
                  callback: function callback($$v) {
                    _vm.selectOffice = $$v;
                  },
                  expression: "selectOffice"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Compnay Name",
                  "label-for": "companyName"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "text",
                  "id": "companyName",
                  "placeholder": "PT. Lintas Prima Perkasa"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Phone Number",
                  "label-for": "phoneNumber"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "text",
                  "id": "phoneNumber"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Ponsel Number",
                  "label-for": "ponselNumber"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "number",
                  "id": "ponselNumber"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Email",
                  "label-for": "email"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "number",
                  "id": "email"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "PIC",
                  "label-for": "pic"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "text",
                  "id": "pic"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Provience",
                  "label-for": "provience"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionProvience,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Provience")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectProvience,
                  callback: function callback($$v) {
                    _vm.selectProvience = $$v;
                  },
                  expression: "selectProvience"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "City",
                  "label-for": "city"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionCity,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select City")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectCity,
                  callback: function callback($$v) {
                    _vm.selectCity = $$v;
                  },
                  expression: "selectCity"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Postal Code",
                  "label-for": "postalCode"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionPostalCode,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Postal Code")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectPostalCode,
                  callback: function callback($$v) {
                    _vm.selectPostalCode = $$v;
                  },
                  expression: "selectPostalCode"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Address",
                  "label-for": "address"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "address",
                  "rows": "3"
                }
              })], 1)], 1)], 1)], 1), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "tab_third",
                  "aria-labelled-by": "third_tab"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b', {
                staticClass: "text-primary"
              }, [_vm._v("APPROVAL")]), _c('hr')]), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Assigned ID",
                  "label-for": "AssignedID"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionAssignedID,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Assigned")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectAssignedID,
                  callback: function callback($$v) {
                    _vm.selectAssignedID = $$v;
                  },
                  expression: "selectAssignedID"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Assigned To",
                  "label-for": "assignedTo"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "text",
                  "id": "assignedTo",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Approve By",
                  "label-for": "ApproveBy"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "text",
                  "id": "ApproveBy",
                  "readonly": ""
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Approve Date",
                  "label-for": "approveDate"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "text",
                  "id": "approveDate",
                  "readonly": ""
                }
              })], 1)], 1)], 1)], 1), _c('b-button', {
                staticClass: "pull-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save")]), _c('b-button', {
                staticClass: "pull-right mr-1",
                attrs: {
                  "type": "submit",
                  "variant": "danger"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" Reset")]), _c('br'), _c('br'), _c('b-card-text', {
                staticClass: "pull-right"
              }, [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)])], 1)], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.addPurchaseRequest,
            callback: function callback($$v) {
              _vm.addPurchaseRequest = $$v;
            },
            expression: "addPurchaseRequest"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }