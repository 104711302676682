<template>
    <b-row>
        <b-col sm="12">
            <b-row>
                <b-col md="6" lg="3">
                <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary rounded">
                    <template v-slot:body>
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="rounded-circle iq-card-icon bg-primary"><i class="fa fa-ship"></i></div>
                        <div class="text-right">
                        <h5 class="mb-0 font-weight-bold"><span class="counter">{{ report_dashboard?.dashboard?.fleets_repair ?? 0 }}</span></h5>
                        <h5 class="">Fleets on Repairing</h5>
                        </div>
                    </div>
                    </template>
                </iq-card>
                </b-col>
                <b-col md="6" lg="3">
                <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-warning rounded">
                    <template v-slot:body >
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="rounded-circle iq-card-icon bg-warning"><i class="fas fa-dollar-sign"></i></div>
                        <div class="text-right">
                        <h5 class="mb-0 font-weight-bold"><span class="counter">{{ formatCurrency(report_dashboard?.dashboard?.total_all_cost ?? 0) }}</span></h5>
                        <h5 class="">Cost Repair All Fleet</h5>
                        </div>
                    </div>
                    </template>
                </iq-card>
                </b-col>
                <b-col md="6" lg="3">
                <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-danger rounded">
                    <template v-slot:body >
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="rounded-circle iq-card-icon bg-danger"><i class="fa fa-tools"></i></div>
                        <div class="text-right">
                        <h5 class="mb-0 font-weight-bold"><span class="counter">{{ report_dashboard?.dashboard?.all_repair ?? 0 }}</span></h5>
                        <h5 class="">Repairs Job All Fleet</h5>
                        </div>
                    </div>
                    </template>
                </iq-card>
                </b-col>
                <b-col md="6" lg="3">
                <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                    <template v-slot:body >
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-spinner"></i></div>
                        <div class="text-right">
                        <h5 class="mb-0 font-weight-bold"><span class="counter">{{ report_dashboard?.dashboard?.all_repair_progress ?? 0 }}</span></h5>
                        <h5 class="">On Progress Repairs Job</h5>
                        </div>
                    </div>
                    </template>
                </iq-card>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6" v-for="(item,index) in classification" :key="index">
                <iq-card>
                    <template v-slot:headerTitle>
                    <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                    <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                </iq-card>
                </b-col>
                <b-col lg="6" v-for="(item,index) in cause" :key="index">
                <iq-card>
                    <template v-slot:headerTitle>
                    <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                    <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                </iq-card>
                </b-col>
                <b-col lg="6" v-for="(item,index) in typeRM" :key="index">
                <iq-card>
                    <template v-slot:headerTitle>
                    <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                    <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                </iq-card>
                </b-col>
                <b-col lg="6" v-for="(item,index) in costRM" :key="index">
                <iq-card>
                    <template v-slot:headerTitle>
                    <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                    <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                </iq-card>
                </b-col>
                <!-- <b-col lg="6" v-for="(item,index) in equipmentRM" :key="index">
                <iq-card>
                    <template v-slot:headerTitle>
                    <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                    <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                </iq-card>
                </b-col> -->
                <b-col lg="6" v-for="(item,index) in categoryRunningRM" :key="index">
                <iq-card>
                    <template v-slot:headerTitle>
                    <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                    <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                </iq-card>
                </b-col>
                <b-col lg="6" v-for="(item,index) in categoryDelayRM" :key="index">
                <iq-card>
                    <template v-slot:headerTitle>
                    <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                    <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                </iq-card>
                </b-col>
                <b-col sm="12">
                  <iq-card>
                      <template v-slot:headerTitle>
                      <h5 class="card-title text-primary">
                          <strong>Matriks Realisasi R & M</strong>
                      </h5>
                      </template>
                      <template v-slot:body>
                      <table class="table table-striped">
                          <thead>
                          <tr>
                              <th class="text-center">Group</th>
                              <th class="text-center">Rencana R & M (jam)</th>
                              <th class="text-center">Aktual R&M (jam)</th>
                              <th class="text-center">Realisasi (%)</th>
                          </tr>
                          </thead>

                          <tbody>
                          <tr v-for="(state,index) in report_dashboard?.dashboard_tables?.realisasi_rnm?.data" :key="'realisasi-' + index">
                              <th>{{ state?.group_name ?? '-' }}</th>
                              <td class="text-center">{{ state?.total_plan }} Jam</td>
                              <td class="text-center">{{ state?.aktual }} Jam</td>
                              <td class="text-center">{{ state?.realisasi }}%</td>
                          </tr>
                          <tr>
                              <th>TOTAL</th>
                              <th class="text-center">{{ report_dashboard?.dashboard_tables?.realisasi_rnm?.total?.total_plan }} Jam</th>
                              <th class="text-center">{{ report_dashboard?.dashboard_tables?.realisasi_rnm?.total?.aktual  }} Jam</th>
                              <th class="text-center">{{ report_dashboard?.dashboard_tables?.realisasi_rnm?.total?.realisasi  }}%</th>
                          </tr>
                          </tbody>
                      </table>
                      </template>
                  </iq-card>
                </b-col>
                <!-- <b-col sm="6">
                  <iq-card>
                      <template v-slot:headerTitle>
                      <h5 class="card-title text-primary">
                          <strong>Matriks Presentase R & M</strong>
                      </h5>
                      </template>
                      <template v-slot:body>
                      <table class="table table-striped">
                          <thead>
                            <tr>
                              <th class="text-center">Group</th>
                              <th class="text-center">Armada</th>
                              <th class="text-center">Jam Operasi</th>
                              <th class="text-center">Jam R & M</th>
                              <th class="text-center">Presentase R & M</th>
                            </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(state,index) in report_dashboard?.dashboard_tables?.realisasi_rnm?.data" :key="'realisasi-' + index">
                              <th>{{ state?.group_name ?? '-' }}</th>
                              <td class="text-center">{{ state?.total_plan }} Jam</td>
                              <td class="text-center">{{ state?.aktual }} Jam</td>
                              <td class="text-center">{{ state?.realisasi }}%</td>
                          </tr>
                          </tbody>
                      </table>
                      </template>
                  </iq-card>
                </b-col> -->
                <b-col sm="6">
                <iq-card>
                    <template v-slot:headerTitle>
                    <h5 class="card-title text-primary">
                        <strong>Type R & M</strong>
                    </h5>
                    </template>
                    <template v-slot:body>
                    <table class="table table-striped table-sm">
                        <thead>
                        <tr>
                            <th class="text-center" v-for="(state, index) in report_dashboard?.dashboard_tables?.type_rnm" :key="index"
                            >{{ state.label }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="text-center" v-for="(state, index) in report_dashboard?.dashboard_tables?.type_rnm" :key="index"
                            >{{ state.value }} Jobs</td>
                        </tr>
                        </tbody>
                    </table>
                    </template>
                </iq-card>
                </b-col>
                <b-col sm="6">
                <iq-card>
                    <template v-slot:headerTitle>
                    <h5 class="card-title text-primary">
                        <strong>Status R & M</strong>
                    </h5>
                    </template>
                    <template v-slot:body>
                    <table class="table table-striped table-sm">
                        <tbody>
                        <tr v-for="(state, index) in report_dashboard?.dashboard_tables?.status_rnm" :key="index">
                            <th>{{ state.label }}</th>
                            <td class="text-center">{{ state.value }} Jobs</td>
                        </tr>
                        </tbody>
                    </table>
                    </template>
                </iq-card>
                </b-col>
                <b-col sm="12">
                <iq-card>
                    <template v-slot:headerTitle>
                    <h5 class="card-title text-primary">
                        <strong>Vessel Type R & M</strong>
                    </h5>
                    </template>
                    <template v-slot:body>
                    <table class="table table-striped">
                        <tbody>
                        <tr>
                            <th class="text-center" v-for="(state, index) in report_dashboard?.dashboard_tables?.vessel_type_rnm" :key="index">
                            {{ state.label }}</th>
                        </tr>
                        <tr>
                            <td class="text-center" v-for="(state, index) in report_dashboard?.dashboard_tables?.vessel_type_rnm" :key="index">
                            {{ state.value }} Jobs</td>
                        </tr>
                        </tbody>
                    </table>
                    </template>
                </iq-card>
                </b-col>
              
            </b-row>
        </b-col>
    </b-row>
</template>


<script>
import { xray } from '../../config/pluginInit'
import _ from 'lodash'
import {
  fleetsActions,
  crewingActions,
  srActions
} from '@src/Utils/helper'
import { createPopper } from '@popperjs/core'
import moment from 'moment'
import optionsData from '@src/core/mock/data'

export default {
  name: 'TabReport',
  props: {
    query: {
      type: Object,
      default: () => {
        return {
            fleetCategory: [],
            fleet:null,
            status:null,
            dateRange:null
        }
      },
    },
    readOnly: {
        type: Boolean,
    },
    fleetId:{
        type:Number
    },
    filtering:{
        type:Boolean
    }
  },
  data() {
    return {
      // fleet
      ...optionsData,
      showInitModal: false,
      showInitModalNext: false,
      isModalAddJob: false,
      nextLoading: false,
      is_vehicle_exist: null,
      select_all_fleets: false,
      loading_fleet: false,
      reject_loading: false,
      editingNode: null,
      // CHART
      classification:
      [
        {
          title: 'GRAPHIC CLASSIFICATION',
          bodyData: {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie'
            },
            colorAxis: {},
            title: {
              text: 'Repair & Maintenance Group By Data Classification'
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
              }
            },
            series: [{
              name: 'Brands',
              colorByPoint: true,
              data: []
            }]
          }
        }
      ],
      cause:
      [
        {
          title: 'GRAPHIC CAUSE',
          bodyData: {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie'
            },
            colorAxis: {},
            title: {
              text: 'Repair & Maintenance Group By Data Cause'
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
              }
            },
            series: [{
              name: 'Brands',
              colorByPoint: true,
              data: []
            }]
          }
        }
      ],
      typeRM:
      [
        {
          title: 'GRAPHIC TYPE REPAIR & MAINTENANCE',
          bodyData: {
            title: {
              text: 'Repair & Maintenance Group By Data Type RM'
            },
            yAxis: {
              title: {
                text: 'Number of Job'
              }
            },
            xAxis: {
                categories: [],
                crosshair: true
            },
            series:[]
          }
        }
      ],
      costRM:
      [
        {
          title: 'GRAPHIC COST REPAIR & MAINTENANCE',
          bodyData: {
            chart: {
              renderTo: 'high-3d-chart',
              type: 'column',
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25
              }
            },
            title: {
              text: 'Repair & Maintenance Cost'
            },
            xAxis: {
                categories: [],
                crosshair: true
            },
            series:[]
          }
        },
      ],
      equipmentRM:
      [
        {
          title: 'GRAPHIC EQUIPMENT REPAIR & MAINTENANCE',
          bodyData: {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie'
            },
            colorAxis: {},
            title: {
              text: 'Repair & Maintenance Group By Equipment'
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
              }
            },
            series: [{
              name: 'Brands',
              colorByPoint: true,
              data: [
                {
                  name: 'ACCOMMODATION',
                  y: 21.41,
                  color: '#0084ff'
                },
                {
                  name: 'HULL & LOAD LINE',
                  y: 11.84,
                  color: '#00ca00'
                },
                {
                  name: 'TANK',
                  y: 10.85,
                  color: '#e64141'
                },
                {
                  name: 'MOORING & ANCHOR EQUIPMENT',
                  y: 4.67,
                  color: '#ffd400'
                },
                {
                  name: 'DECK & OUT FITTING EQUIPMENT',
                  y: 4.18,
                  color: '#00d0ff'
                },
                {
                  name: 'FIRE FIGHTING APPLIANCE',
                  y: 1.64,
                  color: '#374948'
                },
                {
                  name: 'LIFE SAVING APPLIANCE',
                  y: 1.6,
                  color: '#E9EDF4'
                },
                {
                  name: 'NAVIGATION EQUIPMENT',
                  y: 1.2,
                  color: '#777D74'
                },
                {
                  name: 'RADIO EQUIPMENT',
                  y: 2.61,
                  color: '#ffcc00'
                },
                {
                  name: 'AUXILARY ENGINE',
                  y: 18,
                  color: '#0099ff'
                },
                {
                  name: 'MAIN ENGINE',
                  y: 12,
                  color: '#cc9966'
                },
              ]
            }]
          }
        }
      ],
      categoryRM:
      [
        {
          title: 'GRAPHIC CATEGORY R &M',
          bodyData: {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie'
            },
            colorAxis: {},
            title: {
              text: 'Repair & Maintenance Group By Data Category R & M'
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
              }
            },
            series: [{
              name: 'Brands',
              colorByPoint: true,
              data: [
                {
                  name: 'Welding',
                  y: 25,
                  color: '#00ca00'
                },
                {
                  name: 'Mechanical',
                  y: 35,
                  color: '#0084ff'
                },
                {
                  name: 'Electrical',
                  y: 21,
                  color: '#e64141'
                },
                {
                  name: 'General Service',
                  y: 19,
                  color: '#ff9933'
                }
              ]
            }]
          }
        }
      ],
      categoryRunningRM:
      [
        {
          title: 'GRAPHIC RUNNING FACTOR R &M',
          bodyData: {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie'
            },
            colorAxis: {},
            title: {
              text: 'Repair & Maintenance Group By Data Delay Factor R & M'
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
              }
            },
            series: [{
              name: 'Brands',
              colorByPoint: true,
              data: []
            }]
          }
        }
      ],
      categoryDelayRM:
      [
        {
          title: 'GRAPHIC DELAY FACTOR R &M',
          bodyData: {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie'
            },
            colorAxis: {},
            title: {
              text: 'Repair & Maintenance Group By Data Delay Factor R & M'
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
              }
            },
            series: [{
              name: 'Brands',
              colorByPoint: true,
              data: []
            }]
          }
        }
      ],
      fleet_params: {
        page: 1,
        showAll: true,
        search: ''
      },
      fleetCategorySelect: [],
      fleetCategory:
      [
        {text: 'Tug Boat',color: 'success',value: 'tugboat'}, 
        {text: 'Barge',color: 'warning',value: 'barge'},
        {text: 'Mother Vessel',color: 'primary',value: 'mother_vessel'},
        {text: 'Heavy Equipment',color: 'primary',value: 'heavy_equipment'},
        {text: 'Pusher Tug',color: 'primary',value: 'pusher_tug'},
        {text: 'Pusher Barge',color: 'primary',value: 'pusher_barge'}
      ],
      menus: [],
      checkedFleets: null,
      fleetNameChecked: '',
      selectedFleets: null,
      optionsFleets:[],
      optionsDepartment: [],
      optionsActivity: [],
      optionsCategory:[],
      optionsCause: [],
      optionsClassification: [],
      optionsStatus: [],
      optionsTaskType: [],
      optionsKindTask:[],
      optionsReject:[],
      optionsVehicleType:[],
      optionsRepairMaintenance: [
        {value: '1', text: 'Auxuliary Engine - Repair block engine AE kanan'}, 
        {value: '2', text: 'Main Engine - Resurface block engine'},
        {value: '3', text: 'Electrical - Repair jalur pendingin'},
        {value: '4', text: 'Main Engine - Pengukuran cylinder liner'},
        {value: '5', text: 'Main Engine - Pengukuran clearance main bearing'},
        {value: '6', text: 'Main Engine - Polish Crankshaft'},
        {value: '7', text: 'Main Engine - Cek & repair camshaft'}
      ],
      selectedActivity: null,
      selectedStatus: null,

      tree:
      [
        {'text': 'ACCOMMODATION', 'opened': true, 'selected': false, 
        'children':
          [
            { 'text': 'WHEEL HOUSE', 'selected': false},
            { 'text': 'ENGINE ROOM', 'selected': false},
            { 'text': 'ACCOMODATION ROOM', 'selected': false},
          ]
        },
        {'text': 'HULL & LOAD LINE', 'opened': true, 'selected': false, 'children': 
          [
            {'text': 'HULL', 'selected': false},
            {'text': 'LOAD LINE', 'selected': false}
          ]
        },
        {'text': 'TANK', 'opened': true, 'selected': false, 'children': 
          [
            {'text': 'FUEL OIL TANK', 'selected': false},
            {'text': 'FUEL OIL TANK DAILY', 'selected': false},
            {'text': 'LUBRICATION OIL TANK', 'selected': false},
            {'text': 'HYDRAULIC OIL TANK', 'selected': false},
            {'text': 'FRESH WATER TANK', 'selected': false},
            {'text': 'BALLAST TANK', 'selected': false},
            {'text': 'DIRTY OIL TANK', 'selected': false},
            {'text': 'VOID TANK', 'selected': false},
            {'text': 'COFFERDAM', 'selected': false},
            {'text': 'CHAIN LOCKER', 'selected': false},
            {'text': 'CARGO TANK', 'selected': false}
          ]
        },
        {'text': 'MOORING & ANCHOR EQUIPMENT', 'opened': true, 'selected': false, 'children': 
          [
            {'text': 'TOWING HOOK & EQUIPMENT', 'selected': false},
            {'text': 'ANCHOR', 'selected': false},
            {'text': 'ANCHOR CHAIN CABLE', 'selected': false},
            {'text': 'ANCHOR WINDLASS', 'selected': false},
            {'text': 'MOORING ROPE CIR 8"', 'selected': false},
            {'text': 'MOORING ROPE CIR 10"', 'selected': false},
            {'text': 'MOORING ROPE CIR 12"', 'selected': false}
          ]
        },
        {'text': 'DECK & OUT FITTING EQUIPMENT', 'opened': true, 'selected': false, 'children': 
          [
            {'text': 'WATER TIGHT DOOR', 'selected': false},
            {'text': 'WATER TIGHT HATCH/ EMERGENCY HATCH', 'selected': false},
            {'text': 'WINDOWS', 'selected': false},
            {'text': 'ANCHOR WINDLASS', 'selected': false},
            {'text': 'MAST"', 'selected': false},
            {'text': 'SAFETY HAND RAILING"', 'selected': false},
            {'text': 'FENDER"', 'selected': false},
            {'text': 'BOLLARD"', 'selected': false}
          ]
        },
        {'text': 'FIRE FIGHTING APPLIANCE', 'opened': true, 'selected': false, 'children': 
          [
            {'text': 'BREATHING APPARATUS', 'selected': false},
            {'text': 'FIRE PROTECTION SUITS', 'selected': false},
            {'text': 'CO2 FIRE EXTINGUISHER', 'selected': false},
            {'text': 'DRY POWDER FIRE EXTINGUISHER', 'selected': false},
            {'text': 'FOAM FIRE EXTINGUISHER"', 'selected': false},
            {'text': 'FIRE HYDRANT"', 'selected': false},
            {'text': 'FIRE HOSE & NOZZLE"', 'selected': false},
            {'text': 'FIRE MONITOR"', 'selected': false},
            {'text': 'SMOKE / HEAT DETECTOR"', 'selected': false},
          ]
        },
      ],

      logs:null,

      request_loading:false,
      finish_loading:false,
      report_loading:false,
      pagination:{
        currentPage: 1,
        perPage: 10,
      },
      list_table:null,
      list_table_finished:null,
      repair_data:null,
      review_loading:false,

      job_data:null,
      job_loading:false,

      update_jobs_data:null,
      update_jobs_loading:null,

      approve_sr_loading:false,
      approve_sr_data:null,

      detail_sr_data:null,

      logs_data:null,
      activity_repair_data:null,
      activity_repair_loading:null,
      activity_form:{
        id:null,
        form_repair_id:null,
        activity_id:null,
        date_of_activity:null,
        notes:'',
        is_finished:false,
        file:[]
      },
      list_repair_service:null,

      report_dashboard:{},

      showRejectModal:null,
      selected_reject_id:null,
      form_reject: {
        reject_id:null,
        reject_by: JSON.parse(localStorage.getItem('profile')).id,
        remarks:'',
      }
    }
  },
  watch: {
    select_all_fleets: {
      deep: true,
      handler: function(checked) {
        this.checkedFleets = null
        this.fleetNameChecked = null
        if (checked) {
          this.fleets.map((f) => this.checkedFleets = f.id)
        }
      }
    },
    'checkedFleets': {
      handler(value) {
        if (value) {
          let nameFleet = this.fleets.find((f) => this.checkedFleets === f.id)
          this.fleetNameChecked = nameFleet.name
        }
      },
    },
    'activity_form.form_repair_id': {
      async handler() {
        this.activity_repair_data = null
        this.activity_repair_loading = true

        await this.getSingleRepairData()

        this.activity_repair_loading = false
      },
      deep: true,
    },
    'filtering': {
      handler(n, o) {
        this.fetchDashboardReportData()
      },
      immediate: true,
    },
  },
  created() {
    this.fetchFleets = _.debounce(this.fetchFleets, 1000)
  },
  async mounted() {
    xray.index()

    await this.fetchDashboardReportData()
    await this.fetchFleets()
  },
  methods: {
    ...fleetsActions,
    ...srActions,
    ...crewingActions,

    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
  
    async fetchDashboardReportData(){
      let params = {
          start_date: moment(this.query?.dateRange?.[0]).format('YYYY-MM-DD'),
          end_date: moment(this.query?.dateRange?.[1]).format('YYYY-MM-DD'),
          fleet_id: this.readOnly ? this.fleetId : this.query?.fleet?.value ?? null,
          fleet_category: this.query?.fleetCategory?.map(val => val?.value),
      }

      if(!params.fleet_id) delete params.fleet_id
      if(params.fleet_category.length <= 0) delete params.fleet_category

      const res_dashboard = await this.fetchReportDashboard(params)
      const res_dashboard_graphics = await this.fetchReportDashboardGraphics(params)
      const res_dashboard_pies = await this.fetchReportDashboardPies(params)
      const res_dashboard_tables = await this.fetchReportDashboardTables(params)

      this.report_dashboard = {
          dashboard: res_dashboard?.data,
          dashboard_tables: res_dashboard_tables?.data,
      }
      this.classification[0].bodyData.series[0].data = res_dashboard_pies?.data?.pie_classification
      this.cause[0].bodyData.series[0].data = res_dashboard_pies?.data?.pie_cause
      this.categoryRunningRM[0].bodyData.series[0].data = res_dashboard_pies?.data?.pie_running
      this.categoryDelayRM[0].bodyData.series[0].data = res_dashboard_pies?.data?.pie_delay

      this.typeRM[0].bodyData.xAxis.categories = res_dashboard_graphics?.data?.months
      this.typeRM[0].bodyData.series = res_dashboard_graphics?.data?.type_rnm?.data

      this.costRM[0].bodyData.xAxis.categories = res_dashboard_graphics?.data?.months
      this.costRM[0].bodyData.series = res_dashboard_graphics?.data?.cost_repair?.data
    },
    async fetchFleets() {
      this.loading_fleet = true
      let params = this.$options.filters.cleanObject(this.fleet_params)
      let res = await this.getFleets(params)
      if (!res.status) {
        this.loading_fleet = false
        this.fleets = []
        this.optionsFleets = []
      } else {
        this.loading_fleet = false
        this.fleets = res.data
        res.data.map(val => {
          this.optionsFleets.push({
            text:val.name,
            value:val.id,
          })
        })
      }
    },
    withPopper(dropdownList, component, {
      width
    }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [{
          name: 'offset',
          options: {
            offset: [0, -1]
          }
        }, {
          name: 'toggleClass',
          enabled: true,
          phase: 'write',
          fn({
            state
          }) {
            component.$el.classList.toggle('drop-up', state.placement === 'top')
          }
        }]
      })
      return () => popper.destroy()
    },
    itemClick(node) {
      console.log(node.model.text + ' clicked !')
    },
    customItemClick: function(node, item, e) {
      e.stopPropagation()
      var index = node.parentItem.indexOf(item)
      node.parentItem.splice(index, 1)
    },
    customItemClickWithCtrl: function() {
      console.log('click + ctrl')
    },
  }
}
</script>