<template >
  <b-container fluid>
    <!-- Top Section !-->
    <b-row>
      <b-col lg="12">
        <b-breadcrumb :items="breadCrumbItems" />
      </b-col>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
              <span><h4 class="card-title"><i class="ri-ship-2-fill" style="margin-right: 10px;"></i> TB.ETI 301
                <b-button  v-b-tooltip.top="'Reject This Voyage'" size="sm" variant="danger" class="mb-3 mr-1" style="margin: 12px 0 0 10px"><i class="ri-close-fill"></i> Reject</b-button>
                <b-button  v-b-tooltip.top="'Accept This Voyage'" size="sm" variant="success" class="mb-3 mr-1" style="margin: 12px 0 0 10px"><i class="ri-check-line"></i> Accept</b-button>
              </h4>
            </span>
          </template>
          <template v-slot:headerAction>
            <!--<small>Right Header</small>-->
            <b-button variant="info" class="mb-3 mr-1" style="margin: 15px 0 0 5px"><i class="ri-save-fill"></i> Save</b-button>
          </template>
          <template v-slot:body>

          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Top Section !-->
    <!-- Content Section !-->
    <b-row>
      <!-- Card Fleet -->
      <b-col sm="12">
        <form-wizard step-size="lg" @on-complete="onComplete"
                     v-bind:title="title"
                     v-bind:subtitle="subtitle"
                     back-button-text="Go back!"
                     next-button-text="Save!"
                     finish-button-text="We're there">
          <!-- Step 1 -->
          <tab-content title="History, Port Plan, Loading Port - In Pos Jetty"
                       icon="ri-anchor-line">
            <!-- History -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Voyage History</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col md="12">
                    <table class="table table-dark table-striped">
                      <thead>
                        <tr>
                          <th>Barge</th>
                          <th>Last Port</th>
                          <th>OTW / Departure Time</th>
                          <th>Check</th>
                          <th>ROB Fuel Oil</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-info">BG ETI 202</td>
                          <td>Suralaya</td>
                          <td>15 September 2020 20:00</td>
                          <td>9 September 2020 13:05</td>
                          <td>476.264 Liter</td>
                          <td>-</td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
            <!-- End History -->
            <!-- Port Plan -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Port Planing</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col md="6">
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th>Current Port</th>
                        <th>Arrival / Drop Anchor(Bouy) (Engine Off)</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="iq-bg-primary mb-1 mr-1">Sangatta</td>
                        <td>15 September 2020 20:00</td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label="Number of Voyage *" label-for="numberOfVoyage">
                      <b-form-select plain v-model="selectedNumberOfVoyage" :options="numberOfVoyage" size="lg">
                        <template v-slot:first>
                          <b-form-select-option :value="null">Select number of voyage</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                    <b-form-group label="Destination Port *" label-for="destination">
                      <v-select v-model="destinationLoading" label="text" :options="destination" placeholder="Select Destination"></v-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="3"></b-col>
                  <b-col md="6"></b-col>
                  <b-col md="3">
                    <b-form-group label="Barge ? [Yes / No] *" label-for="bargeYesNo">
                      <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                        <div class="custom-switch-inner">
                          <input type="checkbox" class="custom-control-input" :class="'bg-'+bargeYesNo.class" :id="'bargeYesNo'" v-model="bargeYesNo.checked">
                          <label class="custom-control-label" :for="'bargeYesNo'" :data-on-label="bargeYesNo.activeLabel" :data-off-label="bargeYesNo.inactiveLabel">
                          </label>
                        </div>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <template v-if="bargeYesNo.checked == true">
                      <b-form-group label="Barge *" label-for="barge">
                        <v-select v-model="bargeLoading" label="text" :options="barge" placeholder="Select Barge"></v-select>
                      </b-form-group>
                    </template>
                  </b-col>
                  <b-col md="6"></b-col>
                  <b-col md="3">
                    <b-form-group label="Bunker ? [Yes / No] *" label-for="bunkerYesNo">
                      <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                        <div class="custom-switch-inner">
                          <input type="checkbox" class="custom-control-input" :class="'bg-'+bunkerYesNo.class" :id="'bunkerYesNo'" v-model="bunkerYesNo.checked">
                          <label class="custom-control-label" :for="'bunkerYesNo'" :data-on-label="bunkerYesNo.activeLabel" :data-off-label="bunkerYesNo.inactiveLabel">
                          </label>
                        </div>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <template v-if="bunkerYesNo.checked == true">
                      <b-form-group label="Date *" label-for="Date">
                        <b-form-input id="dateBunker1" type="datetime-local" value="2019-12-19T13:45:00" v-model="bunkerDate"></b-form-input>
                        <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                      </b-form-group>
                      <div class="input-group" v-if="visibleROB === true">
                        <b-form-input id="rob-fuel-oil" type="number" value="2356" v-model="amountROB" @blur="onBlurNumberROB"></b-form-input>
                        <div class="input-group-prepend">
                          <span class="input-group-text">Liter</span>
                        </div>
                      </div>
                      <div class="input-group" v-if="visibleROB === false">
                        <b-form-input id="rob-fuel-oil" type="text" value="2356" v-model="amountROB" @focus="onFocusTextROB"></b-form-input>
                        <div class="input-group-prepend">
                          <span class="input-group-text">Liter</span>
                        </div>
                      </div>
                    </template>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
            <!-- End Port Plan -->
            <!-- Activity in Loading Port -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Activity in Loading Port</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col md="6">
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th>Arrival / Drop Anchor(Bouy) (Engine Off)</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>15 September 2020 20:00</td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Anchor Up / Standby Engine *" label-for="anchorUpLoading">
                      <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="anchorUpLoading"></b-form-input>
                      <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" class="text-right">
                    <b-button variant="warning" class="mb-3 mr-1" @click.prevent="addIdleLoading()"><i class="ri-add-line"></i> Add Idle</b-button>
                  </b-col>
                  <b-col md="12" v-if="formNoteIdleLoading.noteIdle.length > 0">
                    <table class="table table-striped table-warning">
                      <thead>
                      <tr>
                        <th width="35%">Note Idle</th>
                        <th width="20%">Start Idle</th>
                        <th width="20%">End Idle</th>
                        <th width="15%">Using ME?</th>
                        <th width="10%">#</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(idle, index) in formNoteIdleLoading.noteIdle" :key="index">
                        <td>
                          <b-form-group label="" label-for="">
                            <v-select v-model="idle.note" :disabled="formNoteIdleLoading._saving" label="text" :options="noteIdle" placeholder="Select Note Idle"></v-select>
                          </b-form-group>
                        </td>
                        <td>
                          <b-form-group label="" label-for="anchorUpLoading">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="idle.startIdle"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td>
                          <b-form-group label="" label-for="anchorUpLoading">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="idle.endIdle"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td>
                          <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                            <div class="custom-switch-inner">
                              <input type="checkbox" class="custom-control-input" :class="'bg-'+idle.me.class" :id="'idle.me'+index" v-model="idle.me.checked">
                              <label class="custom-control-label" :for="'idle.me'+index" :data-on-label="idle.me.activeLabel" :data-off-label="idle.me.inactiveLabel">
                              </label>
                            </div>
                          </div>
                        </td>
                        <td>
                          <b-button @click.prevent="removeIdleLoading(index)" size="sm" variant="danger" class="mb-3 mr-1"><i class="ri-delete-bin-line"></i></b-button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
            <!-- End Activity in Loading Port -->
            <!-- Change Loading Port -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Change Loading Port</h4>
              </template>
              <template v-slot:headerAction>
                <small style="margin-right: 5px;">Save first, if you want change loading port! </small>
                <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                  <div class="custom-switch-inner">
                    <input type="checkbox" class="custom-control-input" :class="'bg-'+changePort.class" :id="'changePort'" v-model="changePort.checked">
                    <label class="custom-control-label" :for="'changePort'" :data-on-label="changePort.activeLabel" :data-off-label="changePort.inactiveLabel">
                    </label>
                  </div>
                </div>
                <b-button v-if="changePort.checked == true" size="sm" variant="success" class="mb-3 mr-1" @click.prevent="addChangePort()"><i class="ri-add-line"></i> Add Destination</b-button>
              </template>
              <template v-slot:body>
                <template v-if="changePort.checked == true">
                  <b-row v-for="(port, index) in formChangePort.port" :key="index">
                    <b-col md="8"><h4>Change Port #{{index+1}}</h4></b-col>
                    <b-col md="4" class="text-right"><b-button @click.prevent="removeChangePort(index)" size="sm" variant="danger" class="mb-3 mr-1"><i class="ri-close-line"></i> Delete</b-button></b-col>
                    <b-col md="12">
                      <table class="table table-light table-striped">
                        <thead>
                        <tr>
                          <th width="30%">Destination Port *</th>
                          <th width="20%">Barge *</th>
                          <th width="25%">Arrival / Drop Anchor(Bouy) (Engine Off) *</th>
                          <th width="25%">Anchor Up / Start Engine *</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>
                            <v-select v-model="port.destination" label="text" :options="destination" placeholder="Select Destination"></v-select>
                          </td>
                          <td>
                            <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                              <div class="custom-switch-inner">
                                <input type="checkbox" class="custom-control-input" :class="'bg-'+port.bargeYesNo.class" :id="'port.bargeYesNo'+index" v-model="port.bargeYesNo.checked">
                                <label class="custom-control-label" :for="'port.bargeYesNo'+index" :data-on-label="port.bargeYesNo.activeLabel" :data-off-label="port.bargeYesNo.inactiveLabel">
                                </label>
                              </div>
                            </div>
                            <template v-if="port.bargeYesNo.checked == true">
                              <v-select v-model="port.barge" label="text" :options="barge" placeholder="Select Barge"></v-select>
                            </template>
                          </td>
                          <td>
                            <b-form-group label="" label-for="anchorUpLoading">
                              <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="port.dropAnchor"></b-form-input>
                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                            </b-form-group>
                          </td>
                          <td>
                            <b-form-group label="" label-for="anchorUpLoading">
                              <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="port.dropAnchor"></b-form-input>
                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                            </b-form-group>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <b-col md="12" v-if="port.idle.length > 0">
                      <table class="table table-striped table-warning">
                        <thead>
                        <tr>
                          <th width="35%">Note Idle</th>
                          <th width="20%">Start Idle</th>
                          <th width="20%">End Idle</th>
                          <th width="15%">Using ME?</th>
                          <th width="10%">#</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(idle, indexIdle) in port.idle" :key="indexIdle">
                          <td>
                            <b-form-group label="" label-for="">
                              <v-select v-model="idle.note" label="text" :options="noteIdle" placeholder="Select Note Idle"></v-select>
                            </b-form-group>
                          </td>
                          <td>
                            <b-form-group label="" label-for="anchorUpLoading">
                              <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="idle.startIdle"></b-form-input>
                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                            </b-form-group>
                          </td>
                          <td>
                            <b-form-group label="" label-for="anchorUpLoading">
                              <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="idle.endIdle"></b-form-input>
                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                            </b-form-group>
                          </td>
                          <td>
                            <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                              <div class="custom-switch-inner">
                                <input type="checkbox" class="custom-control-input" :class="'bg-'+idle.me.class" :id="'idle.me'+index+indexIdle" v-model="idle.me.checked">
                                <label class="custom-control-label" :for="'idle.me'+index+indexIdle" :data-on-label="idle.me.activeLabel" :data-off-label="idle.me.inactiveLabel">
                                </label>
                              </div>
                            </div>
                          </td>
                          <td>
                            <b-button @click.prevent="removeIdleChangePort(index, indexIdle)" size="sm" variant="danger" class="mb-3 mr-1"><i class="ri-delete-bin-line"></i></b-button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <b-col md="12">
                      <b-button block size="sm" variant="warning" class="mb-3 mr-1" @click.prevent="addIdleChangePort(index)"><i class="ri-add-line"></i> Add Idle</b-button>
                    </b-col>
                  </b-row>
                </template>
              </template>
            </iq-card>
            <!-- End Change Loading Port -->
            <!-- Remark -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Remark</h4>
              </template>
              <template v-slot:body>
                <b-form-group label="Input Remark in Loading Port" label-for="remarkLoading">
                  <b-form-textarea id="remarkLoading" rows="1" v-model="remarkLoading"></b-form-textarea>
                </b-form-group>
              </template>
            </iq-card>
            <small>field with * is required!</small>
            <!-- End Remark -->
          </tab-content>
          <!-- End Step 1 -->
          <!-- Step 2 -->
          <tab-content title="Completed, Departure Time"
                       icon="ri-ship-line">
            <!-- Completed, In Pos Jetty -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Completed Cast Off</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col md="12">
                    <table class="table table-light table-striped">
                      <thead>
                      <tr>
                        <th>Anchor Up / Start Engine</th>
                        <th>In Pos Jetty / Made All Fast</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="iq-bg-primary mb-1 mr-1">23 September 2020 12:00</td>
                        <td>
                          <b-form-group label="" label-for="inPosJetty">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep2.inPosJetty"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12">
                    <table class="table table-light table-striped">
                      <thead>
                      <tr>
                        <th>Comm Loading</th>
                        <th>Completed Loading</th>
                        <th>Cast Off / Unberthing</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          <b-form-group label="" label-for="commLoading">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep2.commLoading"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td>
                          <b-form-group label="" label-for="completedLoading">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep2.completedLoading"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td>
                          <b-form-group label="" label-for="castOff">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep2.castOff"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12">
                    <table class="table table-dark table-striped">
                      <thead>
                      <tr>
                        <th>Cargo Type</th>
                        <th>Cargo Loaded</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          <b-form-group label="" label-for="cargoType">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep2.cargoType"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td>
                          <div class="input-group" v-if="visibleCargoLoaded === true">
                            <b-form-input id="rob-fuel-oil" type="number" value="2356" v-model="formStep2.cargoLoaded" @blur="onBlurNumberCargoLoaded"></b-form-input>
                            <div class="input-group-prepend">
                              <span class="input-group-text">Ton</span>
                            </div>
                          </div>
                          <div class="input-group" v-if="visibleCargoLoaded === false">
                            <b-form-input id="rob-fuel-oil" type="text" value="2356" v-model="formStep2.cargoLoaded" @focus="onFocusTextCargoLoaded"></b-form-input>
                            <div class="input-group-prepend">
                              <span class="input-group-text">Ton</span>
                            </div>
                          </div>
                          <small style="float: right"> Input Specific Number for Cargo Loaded!</small>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
            <!-- End Completed, In Pos Jetty -->
            <!-- Departure Time -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Departure Time</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col md="12">
                    <table class="table table-light table-striped">
                      <thead>
                      <tr>
                        <th>Document On Board</th>
                        <th>Drop Anchor(Bouy) (Engine Off)</th>
                        <th>Anchor Up / Start Engine / Departure</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          <b-form-group label="" label-for="documentOnBoard">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep2.documentOnBoard"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td class="iq-bg-info mb-1 mr-1">
                          <b-form-group label="" label-for="dropAnchorBouy">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep2.dropAnchorBouy"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td class="iq-bg-info mb-1 mr-1">
                          <b-form-group label="" label-for="anchorUp">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep2.anchorUp"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <!-- Idle Step 2 -->
                  <b-col md="6">
                    <p>Other Activity before Departure to Discharge Port</p>
                  </b-col>
                  <b-col md="6 text-right">
                    <b-button size="sm" variant="warning" class="mb-3 mr-1" @click.prevent="addIdleStep2()"><i class="ri-add-line"></i> Add Idle</b-button>
                  </b-col>
                  <b-col md="12" v-if="formStep2.idle.length > 0">
                    <table class="table table-striped table-warning">
                      <thead>
                      <tr>
                        <th width="35%">Note Idle</th>
                        <th width="20%">Start Idle</th>
                        <th width="20%">End Idle</th>
                        <th width="15%">Using ME?</th>
                        <th width="10%">#</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(idle, index) in formStep2.idle" :key="index">
                        <td>
                          <b-form-group label="" label-for="">
                            <v-select v-model="idle.note" :disabled="formStep2._saving" label="text" :options="noteIdle" placeholder="Select Note Idle"></v-select>
                          </b-form-group>
                        </td>
                        <td>
                          <b-form-group label="" label-for="anchorUpLoading">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="idle.startIdle"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td>
                          <b-form-group label="" label-for="anchorUpLoading">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="idle.endIdle"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td>
                          <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                            <div class="custom-switch-inner">
                              <input type="checkbox" class="custom-control-input" :class="'bg-'+idle.me.class" :id="'idle.me'+index" v-model="idle.me.checked">
                              <label class="custom-control-label" :for="'idle.me'+index" :data-on-label="idle.me.activeLabel" :data-off-label="idle.me.inactiveLabel">
                              </label>
                            </div>
                          </div>
                        </td>
                        <td>
                          <b-button @click.prevent="removeIdleStep2(index)" size="sm" variant="danger" class="mb-3 mr-1"><i class="ri-delete-bin-line"></i></b-button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <!-- Idle Step 2 -->
                </b-row>
              </template>
            </iq-card>
            <!-- End Departure Time -->
            <!-- Remark -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Remark</h4>
              </template>
              <template v-slot:body>
                <b-form-group label="Input Remark in Loading" label-for="formStep2.remark">
                  <b-form-textarea id="formStep2.remark" rows="1" v-model="formStep2.remark"></b-form-textarea>
                </b-form-group>
              </template>
            </iq-card>
            <small>field with * is required!</small>
            <!-- End Remark -->
          </tab-content>
          <!-- End Step 2 -->
          <!-- Step 3 -->
          <tab-content title="Discharge Port, In Pos Jetty"
                       icon="ri-map-pin-time-fill">
            <!-- Obstacles and Bunker -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Voyage Obstacles go to Discharge Port</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <!--  Step 3 Voyage Obstacles --->
                  <b-col md="12">
                    <table class="table table-light table-striped">
                      <thead>
                      <tr>
                        <th width="50%">Anchor Up / Start Engine (Loading Port [In Pos Jetty] )</th>
                        <th>Voyage Obstacles ?</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="iq-bg-primary mb-1 mr-1">23 September 2020 12:00</td>
                        <td>
                          <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                            <div class="custom-switch-inner">
                              <input type="checkbox" class="custom-control-input" :class="'bg-'+formStep3.voyageObstaclesYesNo.class" :id="'formStep3.voyageObstaclesYesNo'" v-model="formStep3.voyageObstaclesYesNo.checked">
                              <label class="custom-control-label" :for="'formStep3.voyageObstaclesYesNo'" :data-on-label="formStep3.voyageObstaclesYesNo.activeLabel" :data-off-label="formStep3.voyageObstaclesYesNo.inactiveLabel">
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <!-- End Step 3 Voyage Obstacles --->
                  <!-- Step 3 Shelter -->
                  <b-col md="12">
                    <table class="table table-light table-striped" v-if="formStep3.voyageObstaclesYesNo.checked == true">
                      <thead>
                        <tr>
                          <td class="text-right"><strong>Shelter ? </strong></td>
                          <td class="text-left">
                            <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                              <div class="custom-switch-inner">
                                <input type="checkbox" class="custom-control-input" :class="'bg-'+formStep3.voyageObstacles.shelterYesNo.class" :id="'formStep3.shelterYesNo'" v-model="formStep3.voyageObstacles.shelterYesNo.checked">
                                <label class="custom-control-label" :for="'formStep3.shelterYesNo'" :data-on-label="formStep3.voyageObstacles.shelterYesNo.activeLabel" :data-off-label="formStep3.voyageObstacles.shelterYesNo.inactiveLabel">
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </thead>
                      <tbody class="iq-bg-danger">
                        <template v-if="formStep3.voyageObstacles.shelterYesNo.checked == true">
                          <tr>
                            <td colspan="2">
                              <b-button block size="sm" variant="danger" class="mb-3 mr-1" @click.prevent="addShelter()"><i class="ri-add-line"></i> Add Shelter</b-button>
                            </td>
                          </tr>
                          <template v-for="(shelter, index) in formStep3.voyageObstacles.shelter">
                            <tr>
                              <td><h4>Shelter #{{index+1}}</h4></td>
                              <td class="text-right"><b-button @click.prevent="removeShelter(index)" size="sm" variant="danger" class="mb-3 mr-1"><i class="ri-close-line"></i> Remove</b-button></td>
                            </tr>
                            <tr>
                              <td width="50%">
                                <b-form-group label="Shelter Location *" label-for="shelter.shelterLocation">
                                  <v-select v-model="shelter.shelterLocation" label="text" :options="destination" placeholder="Select Shelter Location"></v-select>
                                </b-form-group>
                              </td>
                              <td width="50%">
                                <b-form-group label="Shelter Reason *" label-for="shelter.shelterReason">
                                  <v-select v-model="shelter.shelterReason" label="text" :options="noteIdle" placeholder="Select Shelter Reason"></v-select>
                                </b-form-group>
                              </td>
                            </tr>
                            <tr>
                              <td width="50%">
                                <b-form-group label="Bunker ? [Yes / No] *" label-for="shelter.fuelOilYesNo">
                                  <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                                    <div class="custom-switch-inner">
                                      <input type="checkbox" class="custom-control-input" :class="'bg-'+shelter.fuelOilYesNo.class" :id="'shelter.fuelOilYesNo'+index" v-model="shelter.fuelOilYesNo.checked">
                                      <label class="custom-control-label" :for="'shelter.fuelOilYesNo'+index" :data-on-label="shelter.fuelOilYesNo.activeLabel" :data-off-label="shelter.fuelOilYesNo.inactiveLabel">
                                      </label>
                                    </div>
                                  </div>
                                </b-form-group>
                              </td>
                              <td width="50%">
                                <template v-if="shelter.fuelOilYesNo.checked == true">
                                  <b-form-group label="Amount *" label-for="shelter.fuelOilAmount">
                                    <div class="input-group" v-if="visibleFuelOilAmountShelterStep3 === true">
                                      <b-form-input id="shelter.fuelOilAmount" type="number" value="2356" v-model="shelter.fuelOilAmount" @blur="onBlurNumberROBShelter"></b-form-input>
                                      <div class="input-group-prepend">
                                        <span class="input-group-text">Liter</span>
                                      </div>
                                    </div>
                                    <div class="input-group" v-if="visibleFuelOilAmountShelterStep3 === false">
                                      <b-form-input id="shelter.fuelOilAmount" type="text" value="2356" v-model="shelter.fuelOilAmount" @focus="onFocusTextROBShelter"></b-form-input>
                                      <div class="input-group-prepend">
                                        <span class="input-group-text">Liter</span>
                                      </div>
                                    </div>
                                  </b-form-group>
                                </template>
                              </td>
                            </tr>
                            <tr>
                              <td width="50%">
                                <b-form-group label="Drop Anchor(Bouy) (Engine Off)" label-for="shelter.dropAnchor">
                                  <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="shelter.dropAnchor"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                </b-form-group>
                              </td>
                              <td width="50%">
                                <b-form-group label="Anchor Up / Start Engine" label-for="shelter.anchorUp">
                                  <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="shelter.anchorUp"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                </b-form-group>
                              </td>
                            </tr>
                          </template>
                        </template>
                        <template v-else>
                          <tr>
                            <td>
                              <b-form-group label="Drop Anchor(Bouy) (Engine Off)" label-for="formStep3.voyageObstacles.engineOff">
                                <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep3.voyageObstacles.engineOff"></b-form-input>
                                <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                              </b-form-group>
                            </td>
                            <td>
                              <b-form-group label="Anchor Up / Start Engine" label-for="formStep3.voyageObstacles.startEngine">
                                <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep3.voyageObstacles.startEngine"></b-form-input>
                                <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <b-form-group label="Input Reason of Voyage Obstacles" label-for="formStep3.voyageObstacles.reason">
                                <b-form-textarea id="formStep2.remark" rows="1" v-model="formStep3.voyageObstacles.reason"></b-form-textarea>
                              </b-form-group>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </b-col>
                  <!-- End Step 3 Shelter -->
                  <!-- Step 3 Bunker -->
                  <b-col md="12">
                    <table class="table table-light table-striped">
                      <thead>
                      <tr>
                        <th width="35%"></th>
                        <th width="35%"></th>
                        <th width="30%"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          <b-form-group label="Bunker ?" label-for="formStep3.fuelOilYesNo">
                            <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                              <div class="custom-switch-inner">
                                <input type="checkbox" class="custom-control-input" :class="'bg-'+formStep3.fuelOilYesNo.class" :id="'formStep3.fuelOilYesNo'" v-model="formStep3.fuelOilYesNo.checked">
                                <label class="custom-control-label" :for="'formStep3.fuelOilYesNo'" :data-on-label="formStep3.fuelOilYesNo.activeLabel" :data-off-label="formStep3.fuelOilYesNo.inactiveLabel">
                                </label>
                              </div>
                            </div>
                          </b-form-group>
                        </td>
                        <td>
                          <template v-if="formStep3.fuelOilYesNo.checked == true">
                            <b-form-group label="Bunker Date" label-for="formStep3.fuelOilDate">
                              <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep3.fuelOilDate"></b-form-input>
                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                            </b-form-group>
                          </template>
                        </td>
                        <td>
                          <template v-if="formStep3.fuelOilYesNo.checked == true">
                            <b-form-group label="Bunker Amount" label-for="formStep3.fuelOilAmount">
                              <div class="input-group" v-if="visibleFuelOilAmountStep3 === true">
                                <b-form-input id="formStep3.fuelOilAmount" type="number" value="2356" v-model="formStep3.fuelOilAmount" @blur="onBlurNumberROBStep3"></b-form-input>
                                <div class="input-group-prepend">
                                  <span class="input-group-text">Liter</span>
                                </div>
                              </div>
                              <div class="input-group" v-if="visibleFuelOilAmountStep3 === false">
                                <b-form-input id="formStep3.fuelOilAmount" type="text" value="2356" v-model="formStep3.fuelOilAmount" @focus="onFocusTextROBStep3"></b-form-input>
                                <div class="input-group-prepend">
                                  <span class="input-group-text">Liter</span>
                                </div>
                              </div>
                            </b-form-group>
                          </template>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <!-- End Step 3 Bunker -->
                </b-row>
              </template>
            </iq-card>
            <!-- End Obstacles and Bunker -->
            <!-- Discharge Port -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Discharge Port - In Pos Jetty</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <!-- Step 3 Drop / Up Anchor -->
                  <b-col md="12">
                    <table class="table table-light table-striped">
                      <thead>
                      <tr>
                        <th width="30%">Destination (Discharge) Port</th>
                        <th width="35%">Arrival / Drop Anchor(Bouy) (Engine Off)</th>
                        <th width="35%">Anchor Up / Start Engine / Departure</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          <b-form-group label="" label-for="destination">
                            <v-select v-model="formStep3.destinationPort" label="text" :options="destination" placeholder="Select Destination"></v-select>
                          </b-form-group>
                        </td>
                        <td>
                          <b-form-group label="" label-for="formStep3.arrivalDropAnchor">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep3.arrivalDropAnchor"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td>
                          <b-form-group label="" label-for="formStep3.departureAnchorUp">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep3.departureAnchorUp"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <!-- Step 3 End Drop / Up Anchor -->
                  <!-- Step 3 Idle -->
                  <b-col md="6">
                    <p>Other Activity before Comm Disch</p>
                  </b-col>
                  <b-col md="6 text-right">
                    <b-button size="sm" variant="warning" class="mb-3 mr-1" @click.prevent="addIdleStep3()"><i class="ri-add-line"></i> Add Idle</b-button>
                  </b-col>
                  <b-col md="12" v-if="formStep3.idle.length > 0">
                    <table class="table table-striped table-warning">
                      <thead>
                      <tr>
                        <th width="35%">Note Idle</th>
                        <th width="20%">Start Idle</th>
                        <th width="20%">End Idle</th>
                        <th width="15%">Using ME?</th>
                        <th width="10%">#</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(idle, index) in formStep3.idle" :key="index">
                        <td>
                          <b-form-group label="" label-for="">
                            <v-select v-model="idle.note" :disabled="formStep3._saving" label="text" :options="noteIdle" placeholder="Select Note Idle"></v-select>
                          </b-form-group>
                        </td>
                        <td>
                          <b-form-group label="" label-for="anchorUpLoading">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="idle.startIdle"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td>
                          <b-form-group label="" label-for="anchorUpLoading">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="idle.endIdle"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td>
                          <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                            <div class="custom-switch-inner">
                              <input type="checkbox" class="custom-control-input" :class="'bg-'+idle.me.class" :id="'idle.me'+index" v-model="idle.me.checked">
                              <label class="custom-control-label" :for="'idle.me'+index" :data-on-label="idle.me.activeLabel" :data-off-label="idle.me.inactiveLabel">
                              </label>
                            </div>
                          </div>
                        </td>
                        <td>
                          <b-button @click.prevent="removeIdleStep3(index)" size="sm" variant="danger" class="mb-3 mr-1"><i class="ri-delete-bin-line"></i></b-button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <!-- End Step 3 Idle -->
                </b-row>
              </template>
            </iq-card>
            <!-- End Discharge Port -->
            <small>field with * is required!</small>
          </tab-content>
          <!-- End Step 3 -->
          <!-- Step 4 -->
          <tab-content title="Completed Disch / Cash-Off - Loading Port"
                       icon="ri-compass-3-fill">
            <!-- Completed Cast Off -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Completed Cast Off</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <!--  Step 4 In Pos Jetty --->
                  <b-col md="12">
                    <table class="table table-light table-striped">
                      <thead>
                      <tr>
                        <th width="50%">Anchor Up / Start Engine (Discharge Port)</th>
                        <th>In Pos Jetty / Made All Fast</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="iq-bg-primary mb-1 mr-1">23 September 2020 12:00</td>
                        <td>
                          <b-form-group label="" label-for="formStep4.inPosJetty">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep4.inPosJetty"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <!-- End Step 4 In Pos Jetty --->
                  <!--  Step 4 Comm Discharge --->
                  <b-col md="12">
                    <table class="table table-light table-striped">
                      <thead>
                      <tr>
                        <th>Comm Discharge</th>
                        <th>Completed Discharge</th>
                        <th>Cast Off / Unberthing</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          <b-form-group label="" label-for="commDischarge">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep4.commDischarge"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td>
                          <b-form-group label="" label-for="completedDischarge">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep4.completedDischarge"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td>
                          <b-form-group label="" label-for="castOff">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep4.castOff"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <!--  End Step 4 Comm Discharge --->
                  <!--  Step 4 Cargo Discharge --->
                  <b-col md="12">
                    <table class="table table-dark table-striped">
                      <thead>
                      <tr>
                        <th width="50%">Cargo Loaded</th>
                        <th width="50%">Cargo Discharge</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="iq-bg-warning">
                          7.234,42 Ton
                        </td>
                        <td>
                          <div class="input-group" v-if="visibleCargoDischarge === true">
                            <b-form-input id="rob-fuel-oil" type="number" value="2356" v-model="formStep4.cargoDischarge" @blur="onBlurNumberCargoDischarge"></b-form-input>
                            <div class="input-group-prepend">
                              <span class="input-group-text">Ton</span>
                            </div>
                          </div>
                          <div class="input-group" v-if="visibleCargoDischarge === false">
                            <b-form-input id="rob-fuel-oil" type="text" value="2356" v-model="formStep4.cargoDischarge" @focus="onFocusTextCargoDischarge"></b-form-input>
                            <div class="input-group-prepend">
                              <span class="input-group-text">Ton</span>
                            </div>
                          </div>
                          <small style="float: right"> Input Specific Number for Cargo Discharge!</small>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
            <!-- End Completed Cast Off -->
            <!-- Agent, Bunker Supplier, Remarks -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Agent, Bunker Supplier, Remarks</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Name Of Agent" label-for="formStep4.nameOfAgent">
                      <b-form-input type="text" id="formStep4.nameOfAgent" rows="1" v-model="formStep4.nameOfAgent"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Bunker Supplier" label-for="formStep4.bunkerSupplier">
                      <b-form-input type="text" id="formStep4.bunkerSupplier" rows="1" v-model="formStep4.bunkerSupplier"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Input Remark in Discharge" label-for="formStep4.remark">
                      <b-form-textarea id="formStep4.remark" rows="1" v-model="formStep4.remark"></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
            <!-- End Agent, Bunker Supplier, Remarks -->
            <!-- Arrival, Departure Time -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Departure Time</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col md="12">
                    <table class="table table-light table-striped">
                      <thead>
                      <tr>
                        <th width="35%">Drop Anchor(Bouy) (Engine Off)</th>
                        <th width="35%">Anchor Up / Start Engine / Departure</th>
                        <th width="30%">Destination Port</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="iq-bg-info mb-1 mr-1">
                          <b-form-group label="" label-for="dropAnchorBouy">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep4.dropAnchorBouy"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td class="iq-bg-info mb-1 mr-1">
                          <b-form-group label="" label-for="anchorUp">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep4.anchorUp"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td>
                          <b-form-group label="" label-for="destination">
                            <v-select v-model="formStep4.destinationPort" label="text" :options="destination" placeholder="Select Destination"></v-select>
                          </b-form-group>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <!-- Idle Step 4 -->
                  <b-col md="6">
                    <p>Other Activity before Departure to Loading Port</p>
                  </b-col>
                  <b-col md="6 text-right">
                    <b-button size="sm" variant="warning" class="mb-3 mr-1" @click.prevent="addIdleStep4()"><i class="ri-add-line"></i> Add Idle</b-button>
                  </b-col>
                  <b-col md="12" v-if="formStep4.idle.length > 0">
                    <table class="table table-striped table-warning">
                      <thead>
                      <tr>
                        <th width="35%">Note Idle</th>
                        <th width="20%">Start Idle</th>
                        <th width="20%">End Idle</th>
                        <th width="15%">Using ME?</th>
                        <th width="10%">#</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(idle, index) in formStep4.idle" :key="index">
                        <td>
                          <b-form-group label="" label-for="">
                            <v-select v-model="idle.note" :disabled="formStep4._saving" label="text" :options="noteIdle" placeholder="Select Note Idle"></v-select>
                          </b-form-group>
                        </td>
                        <td>
                          <b-form-group label="" label-for="anchorUpLoading">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="idle.startIdle"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td>
                          <b-form-group label="" label-for="anchorUpLoading">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="idle.endIdle"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td>
                          <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                            <div class="custom-switch-inner">
                              <input type="checkbox" class="custom-control-input" :class="'bg-'+idle.me.class" :id="'idle.me'+index" v-model="idle.me.checked">
                              <label class="custom-control-label" :for="'idle.me'+index" :data-on-label="idle.me.activeLabel" :data-off-label="idle.me.inactiveLabel">
                              </label>
                            </div>
                          </div>
                        </td>
                        <td>
                          <b-button @click.prevent="removeIdleStep4(index)" size="sm" variant="danger" class="mb-3 mr-1"><i class="ri-delete-bin-line"></i></b-button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <!-- Idle Step 4 -->
                </b-row>
              </template>
            </iq-card>
            <!-- End Arrival, Departure Time -->
            <!--  Step 4 Arrival Port, Arrival Condition --->
            <iq-card class="iq-bg-success">
              <template v-slot:headerTitle>
                <h4 class="card-title">Arrival Port (Destination/Loading) - Arrival Condition</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <!--  Step 4 Arrival Voyage Obstacles --->
                  <b-col md="12">
                    <table class="table table-light table-striped">
                      <thead>
                        <tr>
                          <th>Voyage Obstacles ?</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                              <div class="custom-switch-inner">
                                <input type="checkbox" class="custom-control-input" :class="'bg-'+formStep4.arrivalPort.voyageObstaclesYesNo.class" :id="'formStep4.arrivalPort.voyageObstaclesYesNo'" v-model="formStep4.arrivalPort.voyageObstaclesYesNo.checked">
                                <label class="custom-control-label" :for="'formStep4.arrivalPort.voyageObstaclesYesNo'" :data-on-label="formStep4.arrivalPort.voyageObstaclesYesNo.activeLabel" :data-off-label="formStep4.arrivalPort.voyageObstaclesYesNo.inactiveLabel">
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <!-- End Step 4 Arrival Voyage Obstacles --->
                  <!-- Step 4 Arrival Shelter -->
                  <b-col md="12">
                    <table class="table table-light table-striped" v-if="formStep4.arrivalPort.voyageObstaclesYesNo.checked == true">
                      <thead>
                      <tr>
                        <td class="text-right"><strong>Shelter ? </strong></td>
                        <td class="text-left">
                          <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                            <div class="custom-switch-inner">
                              <input type="checkbox" class="custom-control-input" :class="'bg-'+formStep4.arrivalPort.voyageObstacles.shelterYesNo.class" :id="'formStep4.arrivalPort.voyageObstacles.shelterYesNo'" v-model="formStep4.arrivalPort.voyageObstacles.shelterYesNo.checked">
                              <label class="custom-control-label" :for="'formStep4.arrivalPort.voyageObstacles.shelterYesNo'" :data-on-label="formStep4.arrivalPort.voyageObstacles.shelterYesNo.activeLabel" :data-off-label="formStep4.arrivalPort.voyageObstacles.shelterYesNo.inactiveLabel">
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </thead>
                      <tbody class="iq-bg-danger">
                      <template v-if="formStep4.arrivalPort.voyageObstacles.shelterYesNo.checked == true">
                        <tr>
                          <td colspan="2">
                            <b-button block size="sm" variant="danger" class="mb-3 mr-1" @click.prevent="addShelterArrival()"><i class="ri-add-line"></i> Add Shelter</b-button>
                          </td>
                        </tr>
                        <template v-for="(shelter, index) in formStep4.arrivalPort.voyageObstacles.shelter">
                          <tr>
                            <td><h4>Shelter #{{index+1}}</h4></td>
                            <td class="text-right"><b-button @click.prevent="removeShelterArrival(index)" size="sm" variant="danger" class="mb-3 mr-1"><i class="ri-close-line"></i> Remove</b-button></td>
                          </tr>
                          <tr>
                            <td width="50%">
                              <b-form-group label="Shelter Location *" label-for="shelter.shelterLocation">
                                <v-select v-model="shelter.shelterLocation" label="text" :options="destination" placeholder="Select Shelter Location"></v-select>
                              </b-form-group>
                            </td>
                            <td width="50%">
                              <b-form-group label="Shelter Reason *" label-for="shelter.shelterReason">
                                <v-select v-model="shelter.shelterReason" label="text" :options="noteIdle" placeholder="Select Shelter Reason"></v-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td width="50%">
                              <b-form-group label="Bunker ? [Yes / No] *" label-for="shelter.fuelOilYesNo">
                                <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                                  <div class="custom-switch-inner">
                                    <input type="checkbox" class="custom-control-input" :class="'bg-'+shelter.fuelOilYesNo.class" :id="'shelter.fuelOilYesNo'+index" v-model="shelter.fuelOilYesNo.checked">
                                    <label class="custom-control-label" :for="'shelter.fuelOilYesNo'+index" :data-on-label="shelter.fuelOilYesNo.activeLabel" :data-off-label="shelter.fuelOilYesNo.inactiveLabel">
                                    </label>
                                  </div>
                                </div>
                              </b-form-group>
                            </td>
                            <td width="50%">
                              <template v-if="shelter.fuelOilYesNo.checked == true">
                                <b-form-group label="Amount *" label-for="shelter.fuelOilAmount">
                                  <div class="input-group" v-if="visibleFuelOilAmountShelterStep4 === true">
                                    <b-form-input id="shelter.fuelOilAmount" type="number" value="2356" v-model="shelter.fuelOilAmount" @blur="onBlurNumberROBShelterStep4"></b-form-input>
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Liter</span>
                                    </div>
                                  </div>
                                  <div class="input-group" v-if="visibleFuelOilAmountShelterStep4 === false">
                                    <b-form-input id="shelter.fuelOilAmount" type="text" value="2356" v-model="shelter.fuelOilAmount" @focus="onFocusTextROBShelterStep4"></b-form-input>
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Liter</span>
                                    </div>
                                  </div>
                                </b-form-group>
                              </template>
                            </td>
                          </tr>
                          <tr>
                            <td width="50%">
                              <b-form-group label="Fresh Water ? [Yes / No] *" label-for="shelter.freshWaterYesNo">
                                <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                                  <div class="custom-switch-inner">
                                    <input type="checkbox" class="custom-control-input" :class="'bg-'+shelter.freshWaterYesNo.class" :id="'shelter.freshWaterYesNo'+index" v-model="shelter.freshWaterYesNo.checked">
                                    <label class="custom-control-label" :for="'shelter.freshWaterYesNo'+index" :data-on-label="shelter.freshWaterYesNo.activeLabel" :data-off-label="shelter.freshWaterYesNo.inactiveLabel">
                                    </label>
                                  </div>
                                </div>
                              </b-form-group>
                            </td>
                            <td width="50%">
                              <template v-if="shelter.freshWaterYesNo.checked == true">
                                <b-form-group label="Amount *" label-for="shelter.freshWater">
                                  <div class="input-group" v-if="visibleFreshWaterAmountShelterStep4 === true">
                                    <b-form-input id="shelter.freshWaterAmount" type="number" value="2356" v-model="shelter.freshWaterAmount" @blur="onBlurNumberFWShelterStep4"></b-form-input>
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Liter</span>
                                    </div>
                                  </div>
                                  <div class="input-group" v-if="visibleFreshWaterAmountShelterStep4 === false">
                                    <b-form-input id="shelter.freshWaterAmount" type="text" value="2356" v-model="shelter.freshWaterAmount" @focus="onFocusTextFWShelterStep4"></b-form-input>
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Liter</span>
                                    </div>
                                  </div>
                                </b-form-group>
                              </template>
                            </td>
                          </tr>
                          <tr>
                            <td width="50%">
                              <b-form-group label="Drop Anchor(Bouy) (Engine Off)" label-for="shelter.dropAnchor">
                                <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="shelter.dropAnchor"></b-form-input>
                                <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                              </b-form-group>
                            </td>
                            <td width="50%">
                              <b-form-group label="Anchor Up / Start Engine" label-for="shelter.anchorUp">
                                <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="shelter.anchorUp"></b-form-input>
                                <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                              </b-form-group>
                            </td>
                          </tr>
                        </template>
                      </template>
                      <template v-else>
                        <tr>
                          <td>
                            <b-form-group label="Drop Anchor(Bouy) (Engine Off)" label-for="formStep3.voyageObstacles.engineOff">
                              <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep4.arrivalPort.voyageObstacles.engineOff"></b-form-input>
                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                            </b-form-group>
                          </td>
                          <td>
                            <b-form-group label="Anchor Up / Start Engine" label-for="formStep3.voyageObstacles.startEngine">
                              <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep4.arrivalPort.voyageObstacles.startEngine"></b-form-input>
                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                            </b-form-group>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <b-form-group label="Input Reason of Voyage Obstacles" label-for="formStep3.voyageObstacles.reason">
                              <b-form-textarea id="formStep2.remark" rows="1" v-model="formStep4.arrivalPort.voyageObstacles.reason"></b-form-textarea>
                            </b-form-group>
                          </td>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                  </b-col>
                  <!-- End Step 4 Arrival Shelter -->
                  <!--  Step 4 Arrival Condition --->
                  <b-col md="12">
                    <table class="table table-dark table-striped">
                      <thead>
                      <tr>
                        <th width="50%">Arrival / Drop Anchor(Bouy) / Engine Off</th>
                        <th>ROB Fuel Oil</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td width="50%">
                          <b-form-group label="" label-for="formStep4.arrivalPort.arrivalDropAnchor">
                            <b-form-input type="datetime-local" value="2019-12-19T13:45:00" v-model="formStep4.arrivalPort.arrivalDropAnchor"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                        </td>
                        <td width="50%">
                          <div class="input-group" v-if="visibleFuelOilAmountStep4 === true">
                            <b-form-input id="formStep4.arrivalPort.ROBFuelOil" type="number" value="2356" v-model="formStep4.arrivalPort.ROBFuelOil" @blur="onBlurNumberROBStep4"></b-form-input>
                            <div class="input-group-prepend">
                              <span class="input-group-text">Liter</span>
                            </div>
                          </div>
                          <div class="input-group" v-if="visibleFuelOilAmountStep4 === false">
                            <b-form-input id="formStep4.arrivalPort.ROBFuelOil" type="text" value="2356" v-model="formStep4.arrivalPort.ROBFuelOil" @focus="onFocusTextROBStep4"></b-form-input>
                            <div class="input-group-prepend">
                              <span class="input-group-text">Liter</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <!--  End Step 4 Arrival Condition --->
                </b-row>
              </template>
            </iq-card>
            <!--  End Step 4 Arrival Port, Arrival Condition --->
            <small>field with * is required!</small>
          </tab-content>
          <!-- End Step 4 -->
          <!-- Step 5 -->
          <tab-content title="Final Review"
                       icon="ri-road-map-line">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Voyage Parameter</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <!--  Step 5 Voyage Review --->
                  <b-col md="12">
                    <table class="table table-light table-striped">
                      <thead>
                      <tr>
                        <th width="50%">Port</th>
                        <th>Type</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td class="iq-bg-primary mb-1 mr-1">Sangatta</td>
                        <td class="iq-bg-primary mb-1 mr-1">Laden Sea</td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <!--  End Step 5 Voyage Review --->
                  <!--  Step 5 Voyage Parameter --->
                  <b-col md="12">
                    <table class="table table-light table-striped">
                      <thead>
                      <tr>
                        <th width="30%">Distance</th>
                        <th width="35%">Parameter Average Speed</th>
                        <th width="35%">Parameter Liter per Hour</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          <div class="input-group" v-if="visibleDistance === true">
                            <b-form-input id="formStep5.distance" type="number" value="2356" v-model="formStep5.distance" @blur="onBlurNumberDistance"></b-form-input>
                          </div>
                          <div class="input-group" v-if="visibleDistance === false">
                            <b-form-input id="formStep5.distance" type="text" value="2356" v-model="formStep5.distance" @focus="onFocusTextDistance"></b-form-input>
                          </div>
                        </td>
                        <td>
                          <div class="input-group" v-if="visibleParameterAverageSpeed === true">
                            <b-form-input id="formStep5.parameterAverageSpeed" type="number" value="2356" v-model="formStep5.parameterAverageSpeed" @blur="onBlurNumberParameterAverageSpeed"></b-form-input>
                          </div>
                          <div class="input-group" v-if="visibleParameterAverageSpeed === false">
                            <b-form-input id="formStep5.parameterAverageSpeed" type="text" value="2356" v-model="formStep5.parameterAverageSpeed" @focus="onFocusTextParameterAverageSpeed"></b-form-input>
                          </div>
                        </td>
                        <td>
                          <div class="input-group" v-if="visibleParameterLiterPerHour === true">
                            <b-form-input id="formStep5.parameterLiterPerHour" type="number" value="2356" v-model="formStep5.parameterLiterPerHour" @blur="onBlurNumberParameterLiterPerHour"></b-form-input>
                          </div>
                          <div class="input-group" v-if="visibleParameterLiterPerHour === false">
                            <b-form-input id="formStep5.parameterLiterPerHour" type="text" value="2356" v-model="formStep5.parameterLiterPerHour" @focus="onFocusTextParameterLiterPerHour"></b-form-input>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <!--  End Step 5 Voyage Parameter --->
                </b-row>
              </template>
            </iq-card>
            <!-- Parameter AE, Premi -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Parameter AE, ME Premi</h4>
              </template>
              <template v-slot:body>
                <!--  Step 5 Parameter ME, Manuvering, Deduction --->
                <b-col md="12">
                  <table class="table table-light table-striped">
                    <thead>
                    <tr>
                      <th width="30%">Parameter Auxiliary Engine</th>
                      <th width="35%">Parameter Maneuvering, Idle, Running Free</th>
                      <th width="35%">Deduction</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        <div class="input-group" v-if="visibleParameterAE === true">
                          <b-form-input id="formStep5.parameterAE" type="number" value="2356" v-model="formStep5.parameterAE" @blur="onBlurNumberParameterAE"></b-form-input>
                        </div>
                        <div class="input-group" v-if="visibleParameterAE === false">
                          <b-form-input id="formStep5.parameterAE" type="text" value="2356" v-model="formStep5.parameterAE" @focus="onFocusTextParameterAE"></b-form-input>
                        </div>
                      </td>
                      <td>
                        <div class="input-group" v-if="visibleParameterManuvering === true">
                          <b-form-input id="formStep5.parameterManuvering" type="number" value="2356" v-model="formStep5.parameterManuvering" @blur="onBlurNumberParameterManuvering"></b-form-input>
                        </div>
                        <div class="input-group" v-if="visibleParameterManuvering === false">
                          <b-form-input id="formStep5.parameterManuvering" type="text" value="2356" v-model="formStep5.parameterManuvering" @focus="onFocusTextParameterManuvering"></b-form-input>
                        </div>
                      </td>
                      <td>
                        <div class="input-group" v-if="visibleDeduction === true">
                          <b-form-input id="formStep5.deduction" type="number" value="2356" v-model="formStep5.deduction" @blur="onBlurNumberDeduction"></b-form-input>
                        </div>
                        <div class="input-group" v-if="visibleDeduction === false">
                          <b-form-input id="formStep5.deduction" type="text" value="2356" v-model="formStep5.deduction" @focus="onFocusTextDeduction"></b-form-input>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
                <!--  End Step 5 Parameter ME, Manuvering, Deduction --->
              </template>
            </iq-card>
            <!-- End Parameter AE, Premi -->
            <!-- Remark -->
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Remark</h4>
              </template>
              <template v-slot:body>
                <b-form-group label="Input Remark in This Voyage" label-for="formStep5.remark">
                  <b-form-textarea id="formStep5.remark" rows="1" v-model="formStep5.remark"></b-form-textarea>
                </b-form-group>
              </template>
            </iq-card>
            <small>field with * is required!</small>
          </tab-content>
          <!-- End Step 5 -->
        </form-wizard>
      </b-col>
      <!-- End Card Fleet -->
    </b-row>
    <!-- End Content Section !-->
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'

export default {
  name: 'InputVoyage',
  mounted () {
    xray.index()
  },
  data () {
    return {
      title: 'Input Voyage',
      subtitle: 'Make sure your data is real!',
      selectedNumberOfVoyage: null,
      destinationLoading: null,
      bargeLoading: null,
      anchorUpLoading: null,
      bunkerDate: null,
      amountROB: null,
      tempROB: null,
      tempCargoLoaded: null,
      tempCargoDischarge: null,
      tempFuelOilAmountStep3: null,
      tempShelterFuelOilAmountStep3: null,
      tempFuelOilAmountStep4: null,
      tempShelterFuelOilAmountStep4: null,
      tempShelterFreshWaterAmountStep4: null,
      tempDistance: null,
      tempParameterAverageSpeed: null,
      tempParameterLiterPerHour: null,
      tempParameterAE: null,
      tempParameterManuvering: null,
      tempDeduction: null,
      visibleCargoLoaded: true,
      visibleCargoDischarge: true,
      visibleFuelOilAmountStep3: true,
      visibleFuelOilAmountShelterStep3: true,
      visibleFuelOilAmountStep4: true,
      visibleFuelOilAmountShelterStep4: true,
      visibleFreshWaterAmountShelterStep4: true,
      visibleDistance: true,
      visibleParameterAverageSpeed: true,
      visibleParameterLiterPerHour: true,
      visibleParameterAE: true,
      visibleParameterManuvering: true,
      visibleDeduction: true,
      formNoteIdleLoading: {
        _saving: false,
        noteIdle: []
      },
      formChangePort: {
        _saving: false,
        port: [{
          destination: '',
          bargeYesNo: {
            activeLabel: 'Yes',
            inactiveLabel: 'No',
            label: '',
            class: 'success',
            checked: false
          },
          barge: '',
          dropAnchor: '',
          anchorUp: '',
          idle: []
        }]
      },
      remarkLoading: null,
      // Step 2
      formStep2: {
        _saving: false,
        inPosJetty: '',
        commLoading: '',
        completedLoading: '',
        castOff: '',
        cargoType: '',
        cargoLoaded: '',
        dropAnchorBouy: '',
        documentOnBoard: '',
        anchorUp: '',
        idle: [],
        remark: ''
      },
      // Step 3
      formStep3: {
        _saving: false,
        arrivalDropAnchor: '',
        destinationPort: '',
        voyageObstaclesYesNo: {
          activeLabel: 'Yes',
          inactiveLabel: 'No',
          label: '',
          class: 'success',
          checked: false
        },
        voyageObstacles: {
          engineOff: '',
          startEngine: '',
          reason: '',
          shelterYesNo: {
            activeLabel: 'Yes',
            inactiveLabel: 'No',
            label: '',
            class: 'danger',
            checked: false
          },
          shelter: [
            {
              shelterLocation: '',
              shelterReason: '',
              dropAnchor: '',
              anchorUp: '',
              fuelOilYesNo: {
                activeLabel: 'Yes',
                inactiveLabel: 'No',
                label: 'Bunker ?',
                class: 'success',
                checked: false
              },
              fuelOilAmount: ''
            }
          ]
        },
        fuelOilYesNo: {
          activeLabel: 'Yes',
          inactiveLabel: 'No',
          label: '',
          class: 'success',
          checked: false
        },
        fuelOilDate: '',
        fuelOilAmount: '',
        departureAnchorUp: '',
        idle: [],
        remark: ''
      },
      // Step 4
      formStep4: {
        _saving: false,
        inPosJetty: '',
        commDischarge: '',
        completedDischarge: '',
        castOff: '',
        cargoDischarge: '',
        dropAnchorBouy: '',
        anchorUp: '',
        destinationPort: '',
        idle: [],
        nameOfAgent: '',
        bunkerSupplier: '',
        remark: '',
        arrivalPort: {
          voyageObstaclesYesNo: {
            activeLabel: 'Yes',
            inactiveLabel: 'No',
            label: '',
            class: 'success',
            checked: false
          },
          voyageObstacles: {
            engineOff: '',
            startEngine: '',
            reason: '',
            shelterYesNo: {
              activeLabel: 'Yes',
              inactiveLabel: 'No',
              label: '',
              class: 'danger',
              checked: false
            },
            shelter: [
              {
                shelterLocation: '',
                shelterReason: '',
                dropAnchor: '',
                anchorUp: '',
                fuelOilYesNo: {
                  activeLabel: 'Yes',
                  inactiveLabel: 'No',
                  label: 'Bunker ?',
                  class: 'success',
                  checked: false
                },
                fuelOilAmount: '',
                freshWaterYesNo: {
                  activeLabel: 'Yes',
                  inactiveLabel: 'No',
                  label: 'Fresh Water ?',
                  class: 'success',
                  checked: false
                },
                freshWaterAmount: ''
              }
            ]
          },
          arrivalDropAnchor: '',
          ROBFuelOil: ''
        }
      },
      // Step 5
      formStep5: {
        _saving: false,
        distance: 0,
        parameterAverageSpeed: 0,
        parameterLiterPerHour: 0,
        parameterAE: 0,
        parameterManuvering: 0,
        deduction: 0,
        remark: ''
      },
      breadCrumbItems: [
        {
          html: '<i class="ri-ship-fill mr-1 float-left"></i>PMS',
          href: '/pms'
        },
        {
          text: 'Input Voyage',
          active: true
        }
      ],
      barge: [
        { value: '1', text: 'BG.TCP-202' },
        { value: '2', text: 'BG.SML-105' },
        { value: '3', text: 'BG.ETI-201' }
      ],
      destination: [
        { value: '1', text: 'Asam-Asam' },
        { value: '2', text: 'Tanjung Awar-awar' },
        { value: '3', text: 'Kintap' }
      ],
      noteIdle: [
        { value: 'Tunggu Antrian Cargo', text: 'Tunggu Antrian Cargo' },
        { value: 'Tunggu Antrian Bongkar', text: 'Tunggu Antrian Bongkar' },
        { value: 'Tunggu Dokumen', text: 'Tunggu Dokumen' },
        { value: 'Cuaca Buruk', text: 'Cuaca Buruk' },
        { value: 'Tunggu Antrian Bunker FO', text: 'Tunggu Antrian Bunker FO' },
        { value: 'Shifting', text: 'Shifting' },
        { value: 'Kargo Terbakar', text: 'Kargo Terbakar' },
        { value: 'Pergantian Crew', text: 'Pergantian Crew' },
        { value: 'Tunggu Spare Part', text: 'Tunggu Spare Part' },
        { value: 'Tunggu Perbaikan', text: 'Tunggu Perbaikan' },
        { value: 'Assist Tug Boat', text: 'Assist Tug Boat' },
        { value: 'Assist Barge', text: 'Assist Barge' },
        { value: 'Ikat Bouy', text: 'Ikat Bouy' },
        { value: 'Docking', text: 'Docking' },
        { value: 'Tunggu Antrian Bunker FW', text: 'Tunggu Antrian Bunker FW' },
        { value: 'Lain-Lain', text: 'Lain-Lain' }
      ],
      numberOfVoyage: [
        { value: '1', text: '1' },
        { value: '2', text: '2' },
        { value: '3', text: '3' }
      ],
      bargeYesNo: {
        activeLabel: 'Yes',
        inactiveLabel: 'No',
        label: '',
        class: 'primary',
        checked: true
      },
      bunkerYesNo: {
        activeLabel: 'Yes',
        inactiveLabel: 'No',
        label: '',
        class: 'primary',
        checked: true
      },
      changePort: {
        activeLabel: 'Yes',
        inactiveLabel: 'No',
        label: '',
        class: 'success',
        checked: false
      }
    }
  },
  methods: {
    onComplete: function () {
      alert('Yay. Done!')
    },
    onBlurNumberROB (e) {
      this.visibleROB = false
      this.tempROB = this.amountROB
      this.amountROB = this.thousandSeparator(this.amountROB)
    },
    onFocusTextROB () {
      this.visibleROB = true
      this.amountROB = this.tempROB
    },
    onBlurNumberROBShelter (e) {
      this.visibleFuelOilAmountShelterStep3 = false
      this.tempShelterFuelOilAmountStep3 = this.formStep3.voyageObstacles.shelter.fuelOilAmount
      this.formStep3.voyageObstacles.shelter.fuelOilAmount = this.thousandSeparator(this.formStep3.voyageObstacles.shelter.fuelOilAmount)
    },
    onFocusTextROBShelter () {
      this.visibleFuelOilAmountShelterStep3 = true
      this.formStep3.voyageObstacles.shelter.fuelOilAmount = this.tempShelterFuelOilAmountStep3
    },
    onBlurNumberROBStep3 (e) {
      this.visibleFuelOilAmountStep3 = false
      this.tempFuelOilAmountStep3 = this.formStep3.fuelOilAmount
      this.formStep3.fuelOilAmount = this.thousandSeparator(this.formStep3.fuelOilAmount)
    },
    onFocusTextROBStep3 () {
      this.visibleFuelOilAmountStep3 = true
      this.formStep3.fuelOilAmount = this.tempFuelOilAmountStep3
    },
    onBlurNumberROBStep4 (e) {
      this.visibleFuelOilAmountStep4 = false
      this.tempFuelOilAmountStep4 = this.formStep4.arrivalPort.ROBFuelOil
      this.formStep4.arrivalPort.ROBFuelOil = this.thousandSeparator(this.formStep4.arrivalPort.ROBFuelOil)
    },
    onFocusTextROBStep4 () {
      this.visibleFuelOilAmountStep4 = true
      this.formStep4.arrivalPort.ROBFuelOil = this.tempFuelOilAmountStep4
    },
    onBlurNumberROBShelterStep4 (e) {
      this.visibleFuelOilAmountShelterStep4 = false
      this.tempShelterFuelOilAmountStep4 = this.formStep4.arrivalPort.voyageObstacles.shelter.fuelOilAmount
      this.formStep4.arrivalPort.voyageObstacles.shelter.fuelOilAmount = this.thousandSeparator(this.formStep4.arrivalPort.voyageObstacles.shelter.fuelOilAmount)
    },
    onFocusTextROBShelterStep4 () {
      this.visibleFuelOilAmountShelterStep4 = true
      this.formStep4.arrivalPort.voyageObstacles.shelter.fuelOilAmount = this.tempShelterFuelOilAmountStep4
    },
    onBlurNumberFWShelterStep4 (e) {
      this.visibleFreshWaterAmountShelterStep4 = false
      this.tempShelterFreshWaterAmountStep4 = this.formStep4.arrivalPort.voyageObstacles.shelter.freshWaterAmount
      this.formStep4.arrivalPort.voyageObstacles.shelter.freshWaterAmount = this.thousandSeparator(this.formStep4.arrivalPort.voyageObstacles.shelter.freshWaterAmount)
    },
    onFocusTextFWShelterStep4 () {
      this.visibleFreshWaterAmountShelterStep4 = true
      this.formStep4.arrivalPort.voyageObstacles.shelter.freshWaterAmount = this.tempShelterFreshWaterAmountStep4
    },
    onBlurNumberCargoLoaded (e) {
      this.visibleCargoLoaded = false
      this.tempCargoLoaded = this.formStep2.cargoLoaded
      this.formStep2.cargoLoaded = this.thousandSeparator(this.formStep2.cargoLoaded)
    },
    onFocusTextCargoLoaded () {
      this.visibleCargoLoaded = true
      this.formStep2.cargoLoaded = this.tempCargoLoaded
    },
    onBlurNumberCargoDischarge (e) {
      this.visibleCargoDischarge = false
      this.tempCargoDischarge = this.formStep4.cargoDischarge
      this.formStep4.cargoDischarge = this.thousandSeparator(this.formStep4.cargoDischarge)
    },
    onFocusTextCargoDischarge () {
      this.visibleCargoDischarge = true
      this.formStep4.cargoDischarge = this.tempCargoDischarge
    },
    onBlurNumberDistance (e) {
      this.visibleDistance = false
      this.tempDistance = this.formStep5.distance
      this.formStep5.distance = this.thousandSeparator(this.formStep5.distance)
    },
    onFocusTextDistance () {
      this.visibleDistance = true
      this.formStep5.distance = this.tempDistance
    },
    onBlurNumberParameterAverageSpeed (e) {
      this.visibleParameterAverageSpeed = false
      this.tempParameterAverageSpeed = this.formStep5.parameterAverageSpeed
      this.formStep5.parameterAverageSpeed = this.thousandSeparator(this.formStep5.parameterAverageSpeed)
    },
    onFocusTextParameterAverageSpeed () {
      this.visibleParameterAverageSpeed = true
      this.formStep5.parameterAverageSpeed = this.tempParameterAverageSpeed
    },
    onBlurNumberParameterLiterPerHour (e) {
      this.visibleParameterLiterPerHour = false
      this.tempParameterLiterPerHour = this.formStep5.parameterLiterPerHour
      this.formStep5.parameterLiterPerHour = this.thousandSeparator(this.formStep5.parameterLiterPerHour)
    },
    onFocusTextParameterLiterPerHour () {
      this.visibleParameterLiterPerHour = true
      this.formStep5.parameterLiterPerHour = this.tempParameterLiterPerHour
    },
    onBlurNumberParameterAE (e) {
      this.visibleParameterAE = false
      this.tempParameterAE = this.formStep5.parameterAE
      this.formStep5.parameterAE = this.thousandSeparator(this.formStep5.parameterAE)
    },
    onFocusTextParameterAE () {
      this.visibleParameterAE = true
      this.formStep5.parameterAE = this.tempParameterAE
    },
    onBlurNumberParameterManuvering (e) {
      this.visibleParameterManuvering = false
      this.tempParameterManuvering = this.formStep5.parameterManuvering
      this.formStep5.parameterManuvering = this.thousandSeparator(this.formStep5.parameterManuvering)
    },
    onFocusTextParameterManuvering () {
      this.visibleParameterManuvering = true
      this.formStep5.parameterManuvering = this.tempParameterManuvering
    },
    onBlurNumberDeduction (e) {
      this.visibleDeduction = false
      this.tempDeduction = this.formStep5.Deduction
      this.formStep5.Deduction = this.thousandSeparator(this.formStep5.Deduction)
    },
    onFocusTextDeduction () {
      this.visibleDeduction = true
      this.formStep5.deduction = this.tempDeduction
    },
    thousandSeparator (amount) {
      if (amount !== '' || amount !== undefined || amount !== 0 || amount !== '0' || amount !== null) {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        return amount
      }
    },
    addIdleLoading () {
      this.formNoteIdleLoading.noteIdle.push(
        {
          note: '',
          startIdle: '',
          endIdle: '',
          me: {
            activeLabel: 'Yes',
            inactiveLabel: 'No',
            label: '',
            class: 'warning',
            checked: false
          }
        }
      )
    },
    removeIdleLoading (index) {
      this.formNoteIdleLoading.noteIdle.splice(index, 1)
    },
    addChangePort () {
      const self = this
      if (this.formChangePort.port.length == 2) {
        self.$notify.danger('Maximum 2 for Change of Loading Port')
      } else {
        this.formChangePort.port.push(
          {
            destination: '',
            bargeYesNo: {
              activeLabel: 'Yes',
              inactiveLabel: 'No',
              label: '',
              class: 'success',
              checked: false
            },
            dropAnchor: '',
            anchorUp: '',
            idle: []
          }
        )
      }
    },
    removeChangePort (index) {
      this.$swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.formChangePort.port.splice(index, 1)
          this.$swal.fire(
            'Deleted!',
            'Your data has been deleted.',
            'success'
          )
        }
      })
    },
    addIdleChangePort (portIndex, data) {
      this.formChangePort.port[portIndex].idle.push(
        {
          note: '',
          startIdle: '',
          endIdle: '',
          me: {
            activeLabel: 'Yes',
            inactiveLabel: 'No',
            label: '',
            class: 'warning',
            checked: false
          }
        }
      )
    },
    removeIdleChangePort (portIndex, index) {
      this.formChangePort.port[portIndex].idle.splice(index, 1)
    },
    addIdleStep2 () {
      this.formStep2.idle.push(
        {
          note: '',
          startIdle: '',
          endIdle: '',
          me: {
            activeLabel: 'Yes',
            inactiveLabel: 'No',
            label: '',
            class: 'warning',
            checked: false
          }
        }
      )
    },
    removeIdleStep2 (index) {
      this.formStep2.idle.splice(index, 1)
    },
    addIdleStep3 () {
      this.formStep3.idle.push(
        {
          note: '',
          startIdle: '',
          endIdle: '',
          me: {
            activeLabel: 'Yes',
            inactiveLabel: 'No',
            label: '',
            class: 'warning',
            checked: false
          }
        }
      )
    },
    removeIdleStep3 (index) {
      this.formStep3.idle.splice(index, 1)
    },
    addIdleStep4 () {
      this.formStep4.idle.push(
        {
          note: '',
          startIdle: '',
          endIdle: '',
          me: {
            activeLabel: 'Yes',
            inactiveLabel: 'No',
            label: '',
            class: 'warning',
            checked: false
          }
        }
      )
    },
    removeIdleStep4 (index) {
      this.formStep4.idle.splice(index, 1)
    },
    addShelter () {
      this.formStep3.voyageObstacles.shelter.push(
        {
          shelterLocation: '',
          shelterReason: '',
          dropAnchor: '',
          anchorUp: '',
          fuelOilYesNo: {
            activeLabel: 'Yes',
            inactiveLabel: 'No',
            label: '',
            class: 'success',
            checked: false
          },
          fuelOilAmount: ''
        }
      )
    },
    removeShelter (index) {
      this.formStep3.voyageObstacles.shelter.splice(index, 1)
    },
    addShelterArrival () {
      this.formStep4.arrivalPort.voyageObstacles.shelter.push(
        {
          shelterLocation: '',
          shelterReason: '',
          dropAnchor: '',
          anchorUp: '',
          fuelOilYesNo: {
            activeLabel: 'Yes',
            inactiveLabel: 'No',
            label: '',
            class: 'success',
            checked: false
          },
          fuelOilAmount: ''
        }
      )
    },
    removeShelterArrival (index) {
      this.formStep4.arrivalPort.voyageObstacles.shelter.splice(index, 1)
    },
    testNotify () {
      this.$notify.danger('Hello')
    }
  }
}
</script>
