var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "forecast-final-result"
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Forecast Final Independent")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.lists.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "8%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Vehicle Type Group")]), _c('th', {
          attrs: {
            "width": "12%"
          }
        }, [_vm._v("Year")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Final Rate HM")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Final Rate IV")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Final Rate WAR")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Companies")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.lists.data, function (c, i) {
          return _c('tr', {
            key: "c-".concat(i)
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(_vm.typeFleet.find(function (type) {
            return type.code === c.vehicle_type_group_id;
          }) ? _vm.typeFleet.find(function (type) {
            return type.code === c.vehicle_type_group_id;
          }).name : '-'))]), _c('td', [_vm._v(" " + _vm._s(c.year) + " ")]), _c('td', [_vm._v(" " + _vm._s(c.final_rate_hm) + " ")]), _c('td', [_vm._v(" " + _vm._s(c.final_rate_iv) + " ")]), _c('td', [_vm._v(" " + _vm._s(c.final_rate_war) + " ")]), _c('td', _vm._l(c.company.filter(function (company) {
            return _vm.companies.find(function (com) {
              return com.id === company;
            });
          }).map(function (company) {
            return _vm.companies.find(function (com) {
              return com.id === company;
            }).company;
          }), function (company) {
            return _c('b-badge', {
              attrs: {
                "variant": "primary"
              }
            }, [_vm._v(" " + _vm._s(company) + " ")]);
          }), 1), _c('td', [_c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.handeEditList(c);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.handleDeleteList(c);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])], 1)]);
        }), 0)])]) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No data found.")])]];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.editMode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.editMode ? 'Edit' : 'Add') + " Data")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.handleSubmitForm.apply(null, arguments);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Year *"
          }
        }, [_c('v-select', {
          attrs: {
            "options": _vm.yearOption,
            "placeholder": "Select Year",
            "clearable": false
          },
          model: {
            value: _vm.form.year,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "year", $$v);
            },
            expression: "form.year"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Vehicle Type Group *"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.typeFleet,
            "reduce": function reduce(type) {
              return type.code;
            }
          },
          model: {
            value: _vm.form.vehicle_type_group_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "vehicle_type_group_id", $$v);
            },
            expression: "form.vehicle_type_group_id"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Companies *"
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "label": "company",
            "options": _vm.companies,
            "reduce": function reduce(type) {
              return type.id;
            }
          },
          model: {
            value: _vm.form.company,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "company", $$v);
            },
            expression: "form.company"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Final Rate HM *"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "step": ".001",
            "required": ""
          },
          model: {
            value: _vm.form.final_rate_hm,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "final_rate_hm", $$v);
            },
            expression: "form.final_rate_hm"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Final Rate IV *"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "step": ".001",
            "required": ""
          },
          model: {
            value: _vm.form.final_rate_iv,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "final_rate_iv", $$v);
            },
            expression: "form.final_rate_iv"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Final Rate War"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "step": ".001"
          },
          model: {
            value: _vm.form.final_rate_war,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "final_rate_war", $$v);
            },
            expression: "form.final_rate_war"
          }
        })], 1), _c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_vm._v("Save")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }