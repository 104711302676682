import axios from './auth'

// const SR_BASE_URL = `http://localhost:8004/api`
const SR_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/sr`

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  // GET
  async fetchListDepartment({ commit }, params) {
    const url = `${SR_BASE_URL}/list-department`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },   
  
  async fetchListServiceAndRepair({ commit }, params) {
    const url = `${SR_BASE_URL}/service-list`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchListVehicleType({ commit }, params) {
    const url = `${SR_BASE_URL}/vehicle-list-type`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchListTableServiceAndRepair({ commit }, params) {
    const url = `${SR_BASE_URL}/service-list-table`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  }, 

  async fetchListProgressTableServiceAndRepair({ commit }, params) {
    const url = `${SR_BASE_URL}/service-list-table-progress`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  }, 

  async fetchListTableFinishedServiceAndRepair({ commit }, params) {
    const url = `${SR_BASE_URL}/service-list-table-finished`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchListTableFinishedServiceAndRepairById({ commit }, params) {
    const url = `${SR_BASE_URL}/service-list-table-finished/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: params.params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },  
  async fetchDetailRepairServiceAndRepair({ commit }, payload) {
    const url = `${SR_BASE_URL}/service-detail-repair-finished/${payload.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params:payload.params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchSingleServiceAndRepair({ commit }, params = {}) {
    const url = `${SR_BASE_URL}/service-list/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params:params.params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchSingleRepair({ commit }, params = {}) {
    const url = `${SR_BASE_URL}/view-job/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: params.params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchListRepairService({ commit }, params) {
    const url = `${SR_BASE_URL}/options-jobs/${params}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  }, 
  async fetchLogs({ commit }, params) {
    const url = `${SR_BASE_URL}/logs/${params}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchListVehicles({ commit }, params){
    const url = `${SR_BASE_URL}/vehicle-list`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  // GET MASTERS
  async fetchMasterActivity({ commit }, params){
    const url = `${SR_BASE_URL}/master-activity`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchMasterCategory({ commit }, params){
    const url = `${SR_BASE_URL}/master-category`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchMasterCause({ commit }, params){
    const url = `${SR_BASE_URL}/master-cause`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchMasterClassification({ commit }, params){
    const url = `${SR_BASE_URL}/master-classification`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchMasterKindTask({ commit }, params){
    const url = `${SR_BASE_URL}/master-kind-task`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchMasterStatus({ commit }, params){
    const url = `${SR_BASE_URL}/master-status`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchMasterTaskType({ commit }, params){
    const url = `${SR_BASE_URL}/master-task-type`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchMasterReasonProject({ commit }, params){
    const url = `${SR_BASE_URL}/master-reject-reason`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  // CRUD MASTERS
  async getMasterCategory({ commit }, params){
    const url = `${SR_BASE_URL}/master-category`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async createMasterCategory({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-category`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updateMasterCategory({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-category/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deleteMasterCategory({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-category/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async getMasterActivity({ commit }, params){
    const url = `${SR_BASE_URL}/master-activity`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async createMasterActivity({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-activity`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updateMasterActivity({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-activity/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deleteMasterActivity({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-activity/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async getMasterCause({ commit }, params){
    const url = `${SR_BASE_URL}/master-cause`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async createMasterCause({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-cause`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updateMasterCause({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-cause/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deleteMasterCause({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-cause/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async getMasterClassification({ commit }, params){
    const url = `${SR_BASE_URL}/master-classification`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async createMasterClassification({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-classification`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updateMasterClassification({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-classification/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deleteMasterClassification({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-classification/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async getMasterKindTask({ commit }, params){
    const url = `${SR_BASE_URL}/master-kind-task`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async createMasterKindTask({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-kind-task`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updateMasterKindTask({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-kind-task/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deleteMasterKindTask({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-kind-task/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  
  async getMasterTaskType({ commit }, params){
    const url = `${SR_BASE_URL}/master-task-type`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async createMasterTaskType({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-task-type`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updateMasterTaskType({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-task-type/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deleteMasterTaskType({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-task-type/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async getMasterRejectReason({ commit }, params){
    const url = `${SR_BASE_URL}/master-reject`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async createMasterRejectReason({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-reject`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async updateMasterRejectReason({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-reject/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deleteMasterRejectReason({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/master-reject/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  // GET REPORT 
  async fetchReportDashboard({ commit }, params){
    const url = `${SR_BASE_URL}/report-dashboard`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchReportDashboardGraphics({ commit }, params){
    const url = `${SR_BASE_URL}/report-dashboard-graphics`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchReportDashboardPies({ commit }, params){
    const url = `${SR_BASE_URL}/report-dashboard-pies`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchReportDashboardTables({ commit }, params){
    const url = `${SR_BASE_URL}/report-dashboard-tables`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  // POST 
  async createServiceAndRepair({ commit }, payload) {
    const url = `${SR_BASE_URL}/service`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  }, 
  async createJobs({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/create-jobs/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.formData,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  }, 
  async updateJobs({ commit }, formData) {
    const url = `${SR_BASE_URL}/update-jobs`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: formData,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  }, 
  async setReviewRepair({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/review-repair/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.formData,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setActivityRepair({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/activity-repair/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.formData,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data,
      }
    }
  }, 
  async setRejectService({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/reject-service/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data:payload.data
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async checkVehicleExist({ commit }, payload) {
    const url = `${SR_BASE_URL}/check-vehicle/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  // PUT
  async approveSr({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/approve-sr/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  }, 

  // DELETE
  async deleteService({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/delete-service/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async deleteRepair({ commit }, payload = {}) {
    const url = `${SR_BASE_URL}/delete-repair/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
}
