import axios from './auth'

const ROLE_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/roles`;

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getRoles({ commit }, params = {}) {
    const url = ROLE_BASE_URL;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async addRole({ commit }, payload) {
    const url = ROLE_BASE_URL;
    let urlencoded = new URLSearchParams()
    urlencoded.append('name', payload)

    const method = "post";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const config = {
      url,
      method,
      data: urlencoded,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async updateRole({ commit }, payload) {
    const url = `${ROLE_BASE_URL}/${payload.id}`;
    let urlencoded = new URLSearchParams()
    urlencoded.append('name', payload.roles)

    const method = "put";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const config = {
      url,
      method,
      data: urlencoded,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async deleteRole({ commit }, payload) {
    const url = `${ROLE_BASE_URL}/${payload}`;

    const method = "delete";
    const headers = {};
    const config = {
      url,
      method,
      headers
    };

    try {
      await axios(config);
      return { status: 'success' };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
}
