<template>
  <div>
    <!-- Top Section !-->
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>Matrix Report</strong></h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="#">
              <button class="btn btn-dark mt-1 mr-4"><i class="fa fa-file-pdf"></i> Generate Report</button>
            </router-link>
            <a class="text-primary float-right" v-b-toggle.collapse-filter2 role="button" style="cursor: pointer;">
              <i class="fa fa-filter" />
            </a>
          </template>
          <template v-slot:body>
            <b-collapse id="collapse-filter2" visible>
              <b-row>
                <b-col md="3">
                  <b-form-group label-for="dateIssued" label="Select Report">
                    <date-picker class="w-100" @change="handleCHangeReportDateRange" v-model="dateRange" type="date" range value-type="YYYY-MM-DD"
                                 placeholder="Select date range"></date-picker>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label-for="dateIssued" label="Status">
                    <v-select v-model="report.params.status_id" label="text" :options="statusOptions"
                              :reduce="statusOptions => statusOptions.value"></v-select>
                  </b-form-group>
                </b-col>
                <b-col md="1">
                  <b-form-group label="Filter" label-for="button_search">
                    <b-button @click="handleFetchReport" variant="primary" class="mb-3 mr-1"><i class="fa fa-filter"></i>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-collapse>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Top Section !-->

    <div class="alert alert-info" v-if="loading">
      Loading...
    </div>
    <template v-else>
      <!-- Content Section !-->
      <b-row v-if="report.data !== null">
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>Summary Cases Period {{ report.params.from }} s/d
                {{ report.params.to }}</strong></h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="7">
                  <table class="table mb-0 table-striped">
                    <thead>
                    <tr>
                      <th rowspan="2" class="text-center" style="vertical-align: middle;" width="5%">No.</th>
                      <th rowspan="2" class="text-center" style="vertical-align: middle;" width="40%">Period</th>
                      <th colspan="2" class="text-center">Non Claim</th>
                      <th colspan="2" class="text-center">Claim</th>
                      <th rowspan="2" class="text-center" style="vertical-align: middle;">Total Accident</th>
                    </tr>
                    <tr>
                      <th>Major</th>
                      <th>Minor</th>
                      <th>Major</th>
                      <th>Minor</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(data, index) in report.data.form_data" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ data.period }}</td>
                      <td>{{ data.nonclaim_major }}</td>
                      <td>{{ data.nonclaim_minor }}</td>
                      <td>{{ data.claim_major }}</td>
                      <td>{{ data.claim_minor }}</td>
                      <td>{{ data.total }}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                      <th class="text-center" colspan="2">Total</th>
                      <th class="text-center" colspan="5">{{ report.data.form_total }}</th>
                    </tr>
                    </tfoot>
                  </table>
                </b-col>
                <b-col md="5">
                  <div id="summary-chart">
                    <ApexChart :element="summaryCharts.type" :chartOption="summaryCharts.bodyData" />
                  </div>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>

      <b-row v-if="report.data !== null">
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>Classification of Loss Period {{ report.params.from }} s/d
                {{ report.params.to }}</strong></h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="6">
                  <table class="table mb-0 table-striped">
                    <thead>
                    <tr>
                      <th rowspan="2" class="text-center" style="vertical-align: middle;" width="70%">Classification
                        of Loss</th>
                      <th rowspan="2" class="text-center" style="vertical-align: middle;">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(data, index) in report.data.classification_of_loss_data" :key="index">
                      <td colspan="2" class="text-center" v-if="data.value === null"><strong>{{ data.label }}</strong>
                      </td>
                      <td v-else>{{ data.label }}</td>
                      <td class="text-center" v-if="data.value !== null">{{ data.value }}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                      <th class="text-center">Total</th>
                      <th class="text-center">{{ report.data.classification_of_loss_total }}</th>
                    </tr>
                    </tfoot>
                  </table>
                </b-col>
                <b-col md="6">
                  <div id="classification-chart">
                    <ApexChart :element="classificationCharts.type" :chartOption="classificationCharts.bodyData" />
                  </div>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>

      <b-row v-if="report.data !== null">
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>Cause of Loss Period {{ report.params.from }} s/d
                {{ report.params.to }}</strong></h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="6">
                  <table class="table mb-0 table-striped">
                    <thead>
                    <tr>
                      <th rowspan="2" class="text-center" style="vertical-align: middle;" width="70%">Cause of Loss
                      </th>
                      <th rowspan="2" class="text-center" style="vertical-align: middle;">Total Causes</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(data, index) in report.data.cause_of_loss_data" :key="index">
                      <td>{{ data.label }}</td>
                      <td class="text-center">{{ data.value }}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                      <th class="text-center">Total</th>
                      <th class="text-center">{{ report.data.cause_of_loss_total }}</th>
                    </tr>
                    </tfoot>
                  </table>
                </b-col>
                <b-col md="6">
                  <div id="cause-chart">
                    <ApexChart :element="causeCharts.type" :chartOption="causeCharts.bodyData" />
                  </div>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <!-- End Content Section !-->
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import { companiesActions, fleetsActions, miActions } from '@src/Utils/helper'

export default {
  name: 'ListInitialReport',
  data () {
    return {
      loading: false,
      summaryCharts:
        {
          title: 'Line Chart',
          type: 'line',
          bodyData: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            series: [{
              name: 'Total Accidents',
              data: []
            }],
            dataLabels: {
              enabled: true
            },
            stroke: {
              curve: 'straight'
            },
            title: {
              text: 'Summary Case',
              align: 'left'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              }
            },
            xaxis: {
              categories: []
            }
          }
        },
      classificationCharts:
        {
          title: 'Line Chart 2',
          type: 'line2',
          bodyData: {
            chart: {
              height: 400,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            series: [{
              name: 'Total Classification',
              data: []
            }],
            dataLabels: {
              enabled: true
            },
            stroke: {
              curve: 'straight'
            },
            title: {
              text: 'Classification Case',
              align: 'left'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              }
            },
            xaxis: {
              categories: []
            }
          }
        },
      causeCharts:
        {
          title: 'Line Chart 3',
          type: 'line3',
          bodyData: {
            chart: {
              height: 400,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            series: [{
              name: 'Total Cause',
              data: []
            }],
            dataLabels: {
              enabled: true
            },
            stroke: {
              curve: 'straight'
            },
            title: {
              text: 'Cause Case',
              align: 'left'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              }
            },
            xaxis: {
              categories: []
            }
          }
        },
      statusOptions: [],
      dateRange: [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      report: {
        data: null,
        params: {
          from: null,
          to: null,
          status_id: null
        }
      }
    }
  },
  mounted () {
    this.handleCHangeReportDateRange()
    this.handleFetchStatusList()
    this.handleFetchReport()
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    ...miActions,

    handleCHangeReportDateRange () {
      this.report.params.from = this.dateRange ? moment(this.dateRange[0]).format('YYYY-MM-DD') : null
      this.report.params.to = this.dateRange ? moment(this.dateRange[1]).format('YYYY-MM-DD') : null
    },
    async handleFetchStatusList () {
      let res = await this.getStatuses({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          this.statusOptions = res.data.map((val) => ({
            value: val.id,
            text: val.name
          }))
        }
      }
    },
    async handleFetchReport () {
      this.loading = true
      const { data } = await this.getReport(this.report.params)
      if (data) {
        this.report.data = data
        this.summaryCharts.bodyData.xaxis.categories = data.form_chart.map(val => val.label)
        this.summaryCharts.bodyData.series[0].data = data.form_chart.map(val => val.value)

        const filteredClassificationData = data.classification_of_loss_data.filter(val => val.value !== null)
        this.classificationCharts.bodyData.xaxis.categories = filteredClassificationData.map(val => val.label)
        this.classificationCharts.bodyData.series[0].data = filteredClassificationData.map(val => val.value)

        this.causeCharts.bodyData.xaxis.categories = data.cause_of_loss_data.map(val => val.label)
        this.causeCharts.bodyData.series[0].data = data.cause_of_loss_data.map(val => val.value)
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
