import axios from './auth'

const DASHBOARD_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/dashboards`;

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getStatsCertificate({ commit }, params = {}) {
    const url = `${DASHBOARD_BASE_URL}/certificate`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getDailyOps({ commit }, params = {}) {
    const url = `${DASHBOARD_BASE_URL}/voyages/daily`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getDailyOpsExport({ commit }, params = {}) {
    const url = `${DASHBOARD_BASE_URL}/voyages/daily/export`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      responseType: 'arraybuffer',
      params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getMonthlyOps({ commit }, params = {}) {
    const url = `${DASHBOARD_BASE_URL}/voyages/monthly`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getMonthlyOpsExport({ commit }, params = {}) {
    const url = `${DASHBOARD_BASE_URL}/voyages/monthly/export`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      responseType: 'arraybuffer',
      params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
}
