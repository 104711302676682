var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('StepNavigation', {
    attrs: {
      "current-step": "MANAGEMENT_APPROVAL"
    }
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Management Approval")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.incident ? _c('b-row', [_c('b-col', {
          attrs: {
            "md": "1"
          }
        }), _c('b-col', {
          attrs: {
            "md": "10"
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "mb-5",
          attrs: {
            "md": "12"
          }
        }, [_c('img', {
          attrs: {
            "src": require("@assets/images/management-approval.png"),
            "width": "100%"
          }
        })]), _c('ComponentClaim', {
          attrs: {
            "incident": _vm.incident,
            "readonly": "",
            "companies": _vm.companies,
            "vehicles": [{
              value: _vm.incident.vehicle.id,
              label: _vm.incident.vehicle.name
            }],
            "departments": [{
              value: _vm.incident.department.id,
              label: _vm.incident.department.name
            }],
            "coverages": [{
              value: _vm.incident.type_coverage.id,
              label: _vm.incident.type_coverage.name
            }],
            "losses": [{
              value: _vm.incident.type_loss.id,
              label: _vm.incident.type_loss.name
            }]
          }
        }), _c('b-col', {
          staticClass: "mt-4",
          attrs: {
            "md": "6"
          }
        }, [_c('hr'), _c('b-form-input', {
          attrs: {
            "type": "text",
            "readonly": _vm.$route.query.state === 'view',
            "placeholder": "Input Name"
          },
          model: {
            value: _vm.incident.creator_fullname,
            callback: function callback($$v) {
              _vm.$set(_vm.incident, "creator_fullname", $$v);
            },
            expression: "incident.creator_fullname"
          }
        })], 1), _c('b-col', {
          staticClass: "mt-4",
          attrs: {
            "md": "6"
          }
        }, [_c('hr'), _c('span', {
          staticClass: "mr-5"
        }, [_c('strong', [_vm._v("Date")])]), _c('span', {
          staticClass: "ml-5"
        }, [_c('date-picker', {
          attrs: {
            "type": "date",
            "readonly": _vm.$route.query.state === 'view',
            "value-type": "YYYY-MM-DD",
            "format": "DD MMM YYYY",
            "placeholder": "Select date"
          },
          model: {
            value: _vm.incident.creator_date,
            callback: function callback($$v) {
              _vm.$set(_vm.incident, "creator_date", $$v);
            },
            expression: "incident.creator_date"
          }
        })], 1)]), ['MANAGEMENT', 'management'].includes(_vm.role) ? [_c('b-col', {
          staticClass: "mt-4",
          attrs: {
            "md": "12"
          }
        }, [_c('h5', {
          staticClass: "card-title text-success"
        }, [_c('b', [_vm._v("MANAGEMENT APPROVAL AND COMMENT")])]), _c('b-form-group', {
          attrs: {
            "label": "Decribe of Approval"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "exampleFormControlTextarea1",
            "readonly": _vm.$route.query.state === 'view',
            "rows": "3"
          },
          model: {
            value: _vm.incident.approver_description,
            callback: function callback($$v) {
              _vm.$set(_vm.incident, "approver_description", $$v);
            },
            expression: "incident.approver_description"
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "mt-4",
          attrs: {
            "md": "6"
          }
        }, [_c('hr'), _c('b-form-input', {
          attrs: {
            "type": "text",
            "readonly": _vm.$route.query.state === 'view',
            "placeholder": "Input Name"
          },
          model: {
            value: _vm.incident.approver_fullname,
            callback: function callback($$v) {
              _vm.$set(_vm.incident, "approver_fullname", $$v);
            },
            expression: "incident.approver_fullname"
          }
        })], 1), _c('b-col', {
          staticClass: "mt-4",
          attrs: {
            "md": "6"
          }
        }, [_c('hr'), _c('span', {
          staticClass: "mr-5"
        }, [_c('strong', [_vm._v("Date")])]), _c('span', {
          staticClass: "ml-5"
        }, [_c('date-picker', {
          attrs: {
            "type": "date",
            "readonly": _vm.$route.query.state === 'view',
            "value-type": "YYYY-MM-DD",
            "format": "DD MMM YYYY",
            "placeholder": "Select date"
          },
          model: {
            value: _vm.incident.approver_date,
            callback: function callback($$v) {
              _vm.$set(_vm.incident, "approver_date", $$v);
            },
            expression: "incident.approver_date"
          }
        })], 1)])] : _vm._e(), _vm.$route.query.state !== 'view' ? _c('b-col', {
          staticClass: "pull-right text-center mt-5",
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('b-form-checkbox', {
          staticClass: "mb-3",
          model: {
            value: _vm.statementTruth,
            callback: function callback($$v) {
              _vm.statementTruth = $$v;
            },
            expression: "statementTruth"
          }
        }, [_vm._v(" I already read all and I approve this case ")])], 1) : _vm._e(), _vm.$route.query.state !== 'view' ? _c('b-col', {
          staticClass: "pull-right text-center mt-2",
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "disabled": !_vm.statementTruth,
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onSave(_vm.incident.creator_fullname, _vm.incident.creator_date, _vm.incident.approver_description, _vm.incident.approver_fullname, _vm.incident.approver_date);
            }
          }
        }, [_vm._v("Submit")]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push({
                name: 'mi.listInitial'
              });
            }
          }
        }, [_vm._v("Cancel")])], 1) : _vm._e(), _c('b-col', {
          staticClass: "mb-5",
          attrs: {
            "cols": "12",
            "md": "12"
          }
        })], 2)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }