var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_vm._l(_vm.cards, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "3"
      }
    }, [_c('iq-card', {
      class: item.active ? 'bg-primary text-white' : '',
      attrs: {
        "body-class": "text-center"
      },
      scopedSlots: _vm._u([{
        key: "body",
        fn: function fn() {
          return [item.plan !== '' ? _c('span', {
            staticClass: "font-size-16 text-uppercase"
          }, [_vm._v(_vm._s(item.plan))]) : _vm._e(), _c('h2', {
            staticClass: "mb-4 display-3 font-weight-bolder",
            class: item.active ? 'text-white' : ''
          }, [_vm._v(" " + _vm._s(item.amount) + " "), _c('small', {
            staticClass: "font-size-14",
            class: item.active ? 'text-white' : 'text-muted'
          }, [_vm._v(_vm._s(item.duration))])]), _c('ul', {
            staticClass: "list-unstyled line-height-4 mb-0"
          }, [_c('li', [_vm._v(_vm._s(item.description))])]), _c('b-button', {
            staticClass: "mt-5",
            class: item.buttonClass,
            attrs: {
              "variant": item.active ? 'light' : 'primary'
            }
          }, [_vm._v(_vm._s(item.button))])];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._l(_vm.bgCards, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "3"
      }
    }, [_c('b-card', {
      staticClass: "text-white text-center iq-mb-3",
      attrs: {
        "overlay": "",
        "img-src": item.bgImage,
        "img-alt": "Card Image",
        "text-variant": "white",
        "bg-variant": "dark"
      }
    }, [[_c('h2', {
      staticClass: "mb-4 display-3 font-weight-bolder text-white"
    }, [_vm._v(" " + _vm._s(item.amount) + " "), _c('small', {
      staticClass: "font-size-14 text-white"
    }, [_vm._v(_vm._s(item.duration))])]), _c('ul', {
      staticClass: "list-unstyled line-height-4 mb-0"
    }, [_c('li', [_vm._v(_vm._s(item.description))])]), _c('b-button', {
      staticClass: "mt-5",
      class: item.buttonClass,
      attrs: {
        "variant": item.active ? 'light' : 'primary'
      }
    }, [_vm._v(_vm._s(item.button))])]], 2)], 1);
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }