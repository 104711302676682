var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Master Goods")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("No")]), _c('th', [_vm._v("Goods Code")]), _c('th', [_vm._v("Goods Name")]), _c('th', [_vm._v("Images")]), _c('th', [_vm._v("Category")]), _c('th', [_vm._v("Units")]), _c('th', [_vm._v("Action")])])]), _c('tbody')])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Add Master Goods")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category",
            "label-for": "serial"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.category,
            "reduce": function reduce(type) {
              return type.value;
            },
            "placeholder": "select category"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Images / File"
          }
        }, [_c('b-form-file', {
          attrs: {
            "id": "fileSupport",
            "placeholder": "Choose a file",
            "accept": "application/pdf"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Goods Code"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Goods Code..."
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Goods Name"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Goods Name..."
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Units"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.units,
            "reduce": function reduce(type) {
              return type.value;
            },
            "placeholder": "select units"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Description"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "exampleFormControlTextarea1",
            "rows": "3"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Assign to All Fleet"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "assign-fleet",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.assignFleet,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "assignFleet", $$v);
            },
            expression: "form.assignFleet"
          }
        }, [_vm.form.assignFleet === true || _vm.form.assignFleet === 'true' ? [_vm._v("Yes")] : [_vm._v("No")]], 2)], 1), _vm.form.assignFleet === true || _vm.form.assignFleet === 'true' ? _c('b-form-group', {
          staticClass: "bg-light p-3",
          attrs: {
            "label": "Choose Vehicle Type",
            "label-for": "category_for"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "placeholder": "Search categories",
            "label": "name",
            "track-by": "id",
            "options": _vm.fleet_types,
            "multiple": true
          },
          model: {
            value: _vm.form.vehicleType,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "vehicleType", $$v);
            },
            expression: "form.vehicleType"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Category not found.")])])], 1) : _vm._e(), _c('b-form-group', {
          attrs: {
            "label": "Status"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.status,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status", $$v);
            },
            expression: "form.status"
          }
        }, [_vm._v(" Active ")])], 1)], 1), _c('b-col', {
          staticClass: "mt-4 text-right",
          attrs: {
            "md": "12"
          }
        }, [_c('hr'), _c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_vm._v("Save")]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "submit",
            "variant": "none"
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }