var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.type === 'donut' ? _c('donut-chart', {
    attrs: {
      "id": _vm.element,
      "data": _vm.data,
      "colors": _vm.colors,
      "resize": _vm.resize
    }
  }) : _vm._e(), _vm.type === 'bar' ? _c('bar-chart', {
    attrs: {
      "id": _vm.element,
      "data": _vm.data,
      "xkey": _vm.xKeys,
      "ykeys": _vm.yKeys,
      "resize": "true",
      "labels": _vm.labels,
      "bar-colors": _vm.colors,
      "grid": "true",
      "grid-text-weight": "bold"
    }
  }) : _vm._e(), _vm.type === 'line' ? _c('line-chart', {
    attrs: {
      "id": _vm.element,
      "data": _vm.data,
      "xkey": _vm.xKeys,
      "ykeys": _vm.yKeys,
      "line-colors": _vm.colors,
      "labels": _vm.labels,
      "grid": "true",
      "grid-text-weight": "bold",
      "hide-hover": "auto"
    }
  }) : _vm._e(), _vm.type === 'area' ? _c('area-chart', {
    attrs: {
      "id": _vm.element,
      "data": _vm.data,
      "line-colors": _vm.colors,
      "resize": _vm.resize,
      "labels": _vm.labels,
      "xkey": _vm.xKeys,
      "ykeys": _vm.yKeys,
      "hide-hover": "always",
      "grid": "true",
      "grid-text-weight": "bold"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }