import Vue from 'vue'
import VCalendar from 'v-calendar';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
    locales: {
        'pt-PT': {
            firstDayOfWeek: 1,
            masks: {
                L: 'YYYY-MM-DD', // Default for formatting/parsing dates
            },
        },
        // Added for GitHub Issue #330
        hu: {
            firstDayOfWeek: 2,
            masks: {
                L: 'YYYY.MM.DD',
                title: 'YYYY MMMM', // <- this doesn't work
            },
        },
    },
});
