<template>
  <b-container fluid>
    <!-- Grafik -->
    <b-row class="mt-1">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"
              ><strong>Summary Management</strong></h4
            >
          </template>
          <template v-slot:headerAction>
            <a
              class="text-primary float-right ml-3"
              v-b-toggle.filter
              role="button"
              style="cursor: pointer"
            >
              <i class="fa fa-filter" />
            </a>
          </template>
          <template v-slot:body>
            <b-collapse visible id="filter">
              <b-row>
                <b-col md="3">
                  <date-picker
                    v-model="summary.params.year"
                    type="year"
                    format="YYYY"
                    value-type="format"
                    placeholder="Select year"
                  ></date-picker>
                </b-col>
                <b-col md="3">
                  <v-select
                    label="text"
                    v-model="summary.params.type"
                    :options="typeOptions"
                    :reduce="(type) => type.value"
                    placeholder="Select type"
                    :clearable="false"
                  />
                </b-col>
                <b-col md="3">
                  <v-select
                    label="text"
                    v-model="summary.params.vehicle_ids"
                    :options="kapal"
                    :reduce="(type) => type.value"
                    placeholder="select Fleet"
                    :multiple="true"
                  ></v-select>
                </b-col>
                <b-col md="2">
                  <b-button
                    @click="fetchSummary"
                    variant="primary"
                    class="mb-3 mr-1"
                    ><i class="fa fa-search"></i>
                  </b-button>
                </b-col>
              </b-row>
            </b-collapse>
            <hr />
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <div v-if="isLoading" class="alert alert-info">
      Loading...
    </div>
    <template v-else>
      <!-- COUNTING -->
      <b-row v-if="summary.data !== null">
        <b-col md="6" lg="3">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary rounded">
            <template v-slot:body>
              <a href="#" @click.prevent="handleOpenTotalCase()">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-primary"><i class="ri-ship-fill"></i></div>
                  <div class="text-right">
                    <h2 class="mb-0"><span class="counter">{{ summary.data.total_case }}</span></h2>
                    <h5 class="">Total Case</h5>
                  </div>
                </div>
              </a>
            </template>
          </iq-card>
        </b-col>
        <b-col md="6" lg="3">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
            <template v-slot:body >
              <a href="#" @click.prevent="handleOpenNonClaim()">
                <div class="d-flex align-items-center justify-content-between">
                <div class="rounded-circle iq-card-icon bg-blue text-white"><i class="ri-ship-2-line"></i></div>
                <div class="text-right">
                  <h2 class="mb-0"><span class="counter">{{ summary.data.total_nonclaim }}</span></h2>
                  <h5 class="">Non Claim</h5>
                </div>
              </div>
              </a>
            </template>
          </iq-card>
        </b-col>
        <b-col md="6" lg="3">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-danger rounded">
            <template v-slot:body >
              <a href="#" @click.prevent="handleOpenClaim()">
                <div class="d-flex align-items-center justify-content-between">
                <div class="rounded-circle iq-card-icon bg-danger"><i class="ri-ship-2-fill"></i></div>
                <div class="text-right">
                  <h2 class="mb-0"><span class="counter">{{ summary.data.total_claim }}</span></h2>
                  <h5 class="">Claim</h5>
                </div>
              </div>
              </a>
            </template>
          </iq-card>
        </b-col>
        <b-col md="6" lg="3">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-success rounded">
            <template v-slot:body >
              <a href="#" @click.prevent="handleOpenImprovement()">
              <div class="d-flex align-items-center justify-content-between">
                <div class="rounded-circle iq-card-icon bg-success"><i class="ri-hospital-line"></i></div>
                <div class="text-right">
<!--                  <h2 class="mb-0"><span class="counter">4500</span></h2>-->
                  <h5 class="">Continual Improvement</h5>
                </div>
              </div>
              </a>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <!-- END COUNTING -->
      <b-row v-if="summary.data !== null">
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>Case Condition</strong></h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="12">
                  <ApexChart element="chart-case-condition" :chartOption="chartCaseCondition" />
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>Claim - Recovery</strong></h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="12">
                  <ApexChart element="chart-claim-recovery" :chartOption="chartClaimRecovery" />
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>Non Claim - Cost of Loss</strong></h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="12">
                  <ApexChart element="chart-nonclaim-loss" :chartOption="chartNonClaimLoss" />
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <b-col md="6">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"
              ><strong>Cost of Loss</strong></h4
              >
            </template>
            <template v-slot:body>
              <table
                class="table table-bordered"
                v-for="(data, index) in summary.data.cost_of_loss"
                :key="index"
              >
                <thead>
                <tr>
                  <th class="bg-danger text-center">{{ data.name }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-center">
                    <h3>{{ formatCurrency(data.total) }}</h3>
                  </td>
                </tr>
                </tbody>
              </table>
            </template>
          </iq-card>
        </b-col>
        <b-col md="6">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>Recovery</strong></h4>
            </template>
            <template v-slot:body>
              <table
                class="table table-bordered"
                v-for="(data, index) in summary.data.recovery"
                :key="index"
              >
                <thead>
                <tr>
                  <th class="bg-success text-center">{{ data.name }}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-center">
                    <h3>{{ formatCurrency(data.total) }}</h3>
                  </td>
                </tr>
                </tbody>
              </table>
            </template>
          </iq-card>
        </b-col>
        <!-- Graphic Rate Record -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>Rate Record</strong></h4>
            </template>
            <template v-slot:body>
              <b-row v-for="(chart, index) in chartRateRecords" :key="index">
                <b-col md="12">
                  <h3>{{chart.name}}</h3>
                  <ApexChart :element="`chart-rate-record-${chart.alias}`" :chartOption="chart" />
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <!-- End Graphic Rate Record -->
        <!-- Graphic Loss Ratio -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>Loss Ratio</strong></h4>
            </template>
            <template v-slot:body>
              <b-row v-for="(chart, index) in chartLossRatio" :key="index">
                <b-col md="12">
                  <h3>{{chart.name}}</h3>
                  <ApexChart :element="`chart-loss-ratio-${chart.alias}`" :chartOption="chart" />
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <!-- EndGraphic Loss Ratio -->
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>Details classification of case & cause of loss</strong></h4>
            </template>
            <template v-slot:body>
              <b-row class="overflow-auto" style="height: 300px">
                <b-col md="6">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th class="text-center">Classification of Case</th>
                      <th class="text-center">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="(data, index) in summary.data.matrix_coverage"
                      style="cursor: pointer"
                      :key="index"
                      @click="
                        data.value !== null
                          ? onShowAccidentForm(data.forms)
                          : () => {}
                      "
                    >
                      <th
                        v-if="data.value === null"
                        colspan="2"
                        class="bg-warning text-center"
                      >{{ data.label }}</th
                      >
                      <td v-if="data.value !== null">{{ data.label }}</td>
                      <td v-if="data.value !== null" class="text-center"
                      ><strong>{{ data.value }}</strong></td
                      >
                    </tr>
                    <!-- <tr class="bg-danger">
                      <td>Dead</td>
                      <td class="text-center"><strong>0</strong></td>
                    </tr> -->
                    <tr
                      class="bg-info"
                      style="cursor: pointer"
                      @click="onShowMatrixClassificationOfLossTable()"
                    >
                      <td><strong>Total</strong></td>
                      <td class="text-center"
                      ><strong>{{
                          summary.data.matrix_coverage_total
                        }}</strong></td
                      >
                    </tr>
                    </tbody>
                  </table>
                </b-col>
                <b-col md="6" class="iq-bg-success">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th class="text-center">Cause of Loss</th>
                      <th class="text-center">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      v-for="(data, index) in summary.data.matrix_cause_of_loss"
                      :key="index"
                      style="cursor: pointer"
                      @click="onShowAccidentForm(data.forms)"
                    >
                      <td>{{ data.label }}</td>
                      <td class="text-center"
                      ><strong>{{ data.value }}</strong></td
                      >
                    </tr>
                    <tr
                      class="bg-info"
                      style="cursor: pointer"
                      @click="onShowMatrixCauseOfLossTable()"
                    >
                      <td><strong>Total</strong></td>
                      <td class="text-center"
                      ><strong>{{
                          summary.data.matrix_cause_of_loss.reduce((a, b) => {
                            return a + b.value
                          }, 0)
                        }}</strong></td
                      >
                    </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <!-- Grafik End -->

      <!-- Detail Accident -->
      <b-row class="mt-1 d-none">
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"
              ><strong>Detail Accident</strong></h4
              >
            </template>
            <template v-slot:headerAction>
              <span>
                <small>click this button for show and collapse data! &rarr;</small>
              </span>
              <span class="ml-3">
                <a class="text-primary float-right" v-b-toggle.collapse-detail-accident role="button" style="cursor: pointer"><i class="fa fa-circle" /></a>
              </span>
            </template>
            <template v-slot:body>
              <b-collapse visible id="collapse-detail-accident" class="mb-2">
                <SummaryManagementDetailAccidentList v-if="summary.data" :data="summary.data.forms" />
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <!-- Detail Accident End -->

      <iq-card v-if="summary.data !== null" :class="`d-none`">
        <template v-slot:headerTitle>
          <b-form-checkbox
            name="check-button"
            v-model="showSettledBox"
            switch
            inline
          >
            Show Settled
          </b-form-checkbox>
          <b-form-checkbox
            name="check-button"
            v-model="showOnGoingBox"
            switch
            inline
          >
            Show On Going
          </b-form-checkbox>
        </template>
      </iq-card>
      <!-- Settled -->
      <b-col md="12 d-none" v-if="summary.data !== null && showSettledBox">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>Settled</strong></h4>
          </template>
          <template v-slot:body>
            <template v-if="summary.data.claim_status_report">
              <SummaryManagementReportSettled :lists="summary.data.claim_status_report.settled"/>
            </template>
          </template>
        </iq-card>
      </b-col>
      <!-- Settled End-->

      <!-- On Going -->
      <b-col md="12 d-none" v-if="summary.data !== null && showOnGoingBox">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>On Going</strong></h4>
          </template>
          <template v-slot:body>
            <template v-if="summary.data.claim_status_report">
              <SummaryManagementReportUnSettled :lists="summary.data.claim_status_report.unsettled"/>
            </template>
          </template>
        </iq-card>
      </b-col>
      <!-- On Going End-->

      <!-- Popup Accident -->
      <b-modal
        v-model="showAccidentPopup"
        id="modal-accident"
        hide-header
        size="xl"
        hide-footer
      >
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"
            ><strong>Detail Accident</strong></h4
            >
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="mt-2 m-auto">
                <SummaryManagementDetailAccidentList v-if="filteredForms" :data="filteredForms" />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="text-right mt-4">
                <b-button
                  @click="showAccidentPopup = false"
                  variant="none"
                  class="iq-bg-danger ml-3"
                >Close
                </b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-modal>
      <!-- Popup Accident End -->

      <!-- Popup Cause of Loss -->
      <b-modal
        v-model="showMatrixCauseOfLossTable"
        id="modal-cause-of-loss-table"
        hide-header
        size="xl"
        hide-footer
      >
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"
            ><strong>Cause of Loss</strong></h4
            >
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="mt-2 m-auto">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th width="15%" class="text-center">Date.</th>
                    <th width="20%" class="text-center">No.</th>
                    <th width="20%" class="text-center">Name of Vessel</th>
                    <th
                      width="20%"
                      v-for="(data, index) in summary.data.matrix_cause_of_loss"
                      class="text-center"
                    >{{ data.label }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(data, index) in matrixCauseOfLossTableData">
                    <tr
                      :key="index"
                    >
                      <td class="text-center" :rowspan="data.lists.length + 1">{{ data.month_year }}</td>
                    </tr>
                    <tr v-for="(list, listIndex) in data.lists" :key="listIndex">
                      <td class="text-center">{{ listIndex + 1 }}</td>
                      <td class="text-center">{{ list.vehicle }}</td>
                      <template v-for="data2 in summary.data.matrix_cause_of_loss">
                        <td width="20%" :class="list.check_id === data2.id ? 'text-center text-white bg-danger' : 'text-center'">{{ list.check_id === data2.id ? 'V' : '-' }}</td>
                      </template>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="text-right mt-4">
                <b-button
                  @click="showMatrixCauseOfLossTable = false"
                  variant="none"
                  class="iq-bg-danger ml-3"
                >Close
                </b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-modal>
      <!-- Popup Cause of Loss End -->

      <!-- Popup Classification of Loss -->
      <b-modal
        v-model="showMatrixClassificationOfLossTable"
        id="modal-classification-of-loss-table"
        hide-header
        size="xl"
        hide-footer
      >
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"
            ><strong>Classification of Loss</strong></h4
            >
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="mt-2 m-auto overflow-auto">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th width="15%" class="text-center">Date.</th>
                    <th width="20%" class="text-center">No.</th>
                    <th width="20%" class="text-center">Name of Vessel</th>
                    <template
                      v-for="(data, index) in summary.data.matrix_coverage"
                    >
                      <th
                        width="20%"
                        v-if="data.value !== null"
                        class="text-center"
                      >{{ data.label }}</th
                      >
                    </template>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(data, index) in matrixCoverageTableData">
                    <tr
                      :key="index"
                    >
                      <td class="text-center" :rowspan="data.lists.length + 1">{{ data.month_year }}</td>
                    </tr>
                    <tr v-for="(list, listIndex) in data.lists" :key="listIndex">
                      <td class="text-center">{{ listIndex + 1 }}</td>
                      <td class="text-center">{{ list.vehicle }}</td>
                      <template v-for="data2 in summary.data.matrix_coverage">
                        <td width="20%" v-if="data2.value !== null" :class="list.check_id === data2.id ? 'text-center bg-danger text-white' : 'text-center'">{{ list.check_id === data2.id ? 'V' : '-' }}
                        </td>
                      </template>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="text-right mt-4">
                <b-button
                  @click="showMatrixClassificationOfLossTable = false"
                  variant="none"
                  class="iq-bg-danger ml-3"
                >
                  Close
                </b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-modal>
      <!-- Popup Classification of Loss End -->
    </template>
    <!-- Status Report -->
    <!-- <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>Claim Status Report</strong></h4>
          </template>
          <template v-slot:headerAction>
            <a class="text-primary float-right ml-3" v-b-toggle.filter_status_report role="button"
              style="cursor: pointer">
              <i class="fa fa-filter" />
            </a>
          </template>
          <template v-slot:body>
            <b-collapse visible id="filter_status_report">
              <b-row>
                <b-col md="3">
                  <b-form-input class="w-100" type="text" placeholder="Claim Status Report">
                  </b-form-input>
                </b-col>
                <b-col md="2">
                  <b-form-select plain v-model="selectedFleets" :options="kapal" size="lg">
                    <template v-slot:first>
                      <b-form-select-option :value="null">Select Fleet</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-col>
                <b-col md="3">
                  <template v-for="(item, index) in custom">
                    <b-form-radio inline v-model="stateActive[item[Object.keys(item)[0]]]" :name="item.name"
                      :key="index" :value="item.value" :disabled="item.disabled" class="mt-2">{{ item.label }}
                    </b-form-radio>
                  </template>
                </b-col>
                <b-col md="3">
                  <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range">
                  </date-picker>
                </b-col>
                <b-col md="1">
                  <b-button variant="primary" class="mr-1"><i class="fa fa-search"></i></b-button>
                </b-col>
              </b-row>
            </b-collapse>
            <hr>
            <b-row>
              <b-col md="8">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="text-center">Insurer</th>
                      <th class="text-center">Share</th>
                      <th class="text-center">Amount</th>
                      <th class="text-center">Paid Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Askrindo</td>
                      <td class="text-center">40%</td>
                      <td class="text-right">USD. 150.000,-</td>
                      <td class="text-center">22 June 2022</td>
                    </tr>
                    <tr>
                      <td>Jasa Raharja Putera</td>
                      <td class="text-center">30%</td>
                      <td class="text-right">USD. 75.000,-</td>
                      <td class="text-center">25 June 2022</td>
                    </tr>
                    <tr>
                      <td>Tripakarta</td>
                      <td class="text-center">10%</td>
                      <td class="text-right">USD. 50.000,-</td>
                      <td class="text-center">01 July 2022</td>
                    </tr>
                    <tr>
                      <td>AJS</td>
                      <td class="text-center">5%</td>
                      <td class="text-right">USD. 5.000,-</td>
                      <td class="text-center">30 July 2022</td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="4" class="iq-bg-success">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="text-center">Details</th>
                      <th class="text-center">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Actual/Rill Cost</td>
                      <td class="text-center">Rp. 500.000.000,-</td>
                    </tr>
                    <tr>
                      <td>Claim Proposed</td>
                      <td class="text-center">Rp. 800.000.000,-</td>
                    </tr>
                    <tr>
                      <td>Final Adjustment</td>
                      <td class="text-center">Rp. 700.000.000,-</td>
                    </tr>
                    <tr>
                      <td>Deductible</td>
                      <td class="text-center">Rp. 50.000.000,-</td>
                    </tr>
                    <tr>
                      <td>Net Claim</td>
                      <td class="text-center">Rp. 650.000.000,-</td>
                    </tr>
                    <tr>
                      <td>Total Claim Days</td>
                      <td class="text-center">160</td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="12">
                <table class="table table-striped mt-5">
                  <thead>
                    <tr>
                      <th class="text-center">Claim Status</th>
                      <th class="text-center">Status</th>
                      <th class="text-center">Remarks</th>
                      <th class="text-center">Taken Days</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Submit Claim</td>
                      <td class="text-center">
                        <b-badge variant="success">DONE</b-badge>
                      </td>
                      <td>-</td>
                      <td class="text-center">1</td>
                    </tr>
                    <tr>
                      <td>Investigation Process</td>
                      <td class="text-center">
                        <b-badge variant="success">DONE</b-badge>
                      </td>
                      <td>-</td>
                      <td class="text-center">4</td>
                    </tr>
                    <tr>
                      <td>Collecting General Document</td>
                      <td class="text-center">
                        <b-badge variant="success">DONE</b-badge>
                      </td>
                      <td>-</td>
                      <td class="text-center">2</td>
                    </tr>
                    <tr>
                      <td>Join Survey with Class</td>
                      <td class="text-center">
                        <b-badge variant="success">DONE</b-badge>
                      </td>
                      <td>-</td>
                      <td class="text-center">1</td>
                    </tr>
                    <tr>
                      <td>Joiun Survey with Surveyor</td>
                      <td class="text-center">
                        <b-badge variant="success">DONE</b-badge>
                      </td>
                      <td>-</td>
                      <td class="text-center">3</td>
                    </tr>
                    <tr>
                      <td>Collectiong Certificate Statutory</td>
                      <td class="text-center">
                        <b-badge variant="success">DONE</b-badge>
                      </td>
                      <td>-</td>
                      <td class="text-center">2</td>
                    </tr>
                    <tr>
                      <td>Collectiong Certificate Class</td>
                      <td class="text-center">
                        <b-badge variant="success">DONE</b-badge>
                      </td>
                      <td>-</td>
                      <td class="text-center">2</td>
                    </tr>
                    <tr>
                      <td>Recapitulation Process</td>
                      <td class="text-center">
                        <b-badge variant="danger">ON HOLD</b-badge>
                      </td>
                      <td>Waiting cost detail from purchasing</td>
                      <td class="text-center">-</td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>

          </template>
        </iq-card>
      </b-col>
    </b-row> -->
    <!-- Status Report End -->

    <!-- SIDEBAR TOTAL CASE -->
    <b-sidebar id="sidebar-total-case" title="TOTAL CASE DETAIL" width="1300px"
               backdrop-variant="dark" right backdrop shadow>
      <b-row class="pt-2 pl-3 pr-3 pb-2">
        <b-col md="12" class="mb-3">
          <hr>
          <SummaryManagementDetailAccidentList v-if="summary.data" :data="summary.data.forms" />
        </b-col>
      </b-row>
    </b-sidebar>
    <!-- END SIDEBAR TOTAL CASE -->

    <!-- SIDEBAR CLAIM -->
    <b-sidebar id="sidebar-claim" title="CLAIM DATA" width="1300px"
               backdrop-variant="dark" right backdrop shadow>
      <b-row class="pt-2 pl-3 pr-3 pb-2">
        <b-col md="12" class="mb-3">
          <hr>
        </b-col>
        <!-- TAB -->
        <b-col lg="12">
          <iq-card body-class="p-0">
            <template v-slot:body>
              <div class="iq-edit-list">
                <tab-nav :pills="true" class="iq-edit-profile d-flex">
                  <tab-nav-items class="col-md-6 p-0" :active="true" href="#on-going" title="On Going" />
                  <tab-nav-items class="col-md-6 p-0" :active="false" href="#settled" title="Settled" />
                </tab-nav>
              </div>
            </template>
          </iq-card>
        </b-col>
        <!-- END TAB -->
        <b-col lg="12">
          <div id="pills-tabContent-2" class="tab-content">
            <!-- Operational -->
            <tab-content-item :active="true" id="on-going">
              <SummaryManagementReportUnSettled v-if="summary.data && summary.data.claim_status_report" :lists="summary.data.claim_status_report.unsettled"/>
            </tab-content-item>
            <tab-content-item :active="false" id="settled">
              <SummaryManagementReportSettled v-if="summary.data && summary.data.claim_status_report" :lists="summary.data.claim_status_report.settled"/>
            </tab-content-item>
          </div>
        </b-col>
      </b-row>
    </b-sidebar>
    <!-- END SIDEBAR CLAIM -->

    <!-- SIDEBAR NON CLAIM -->
    <b-sidebar id="sidebar-non-claim" title="NON CLAIM DATA" width="1300px"
               backdrop-variant="dark" right backdrop shadow>
      <b-row class="pt-2 pl-3 pr-3 pb-2">
        <b-col md="12" class="mb-3">
          <hr>
          <SummaryManagementNonClaim v-if="summary.data" :lists="summary.data.nonclaim_ongoing" />
        </b-col>
      </b-row>
    </b-sidebar>
    <!-- END SIDEBAR NON CLAIM -->

    <!-- SIDEBAR IMPROVEMENT -->
    <b-sidebar id="sidebar-improvement" title="CONTINUAL IMPROVEMENT" width="100%"
               backdrop-variant="dark" right backdrop shadow>
      <b-row class="pt-2 pl-3 pr-3 pb-2">
        <b-col md="12" class="mb-3">
          <hr>
        </b-col>
        <!-- TAB -->
        <b-col lg="12">
          <iq-card body-class="p-0">
            <template v-slot:body>
              <div class="iq-edit-list">
                <tab-nav :pills="true" class="iq-edit-profile d-flex">
                  <tab-nav-items class="col-md-4 p-0" :active="true" href="#recommendation" title="Recommendation" />
                  <tab-nav-items class="col-md-4 p-0" :active="false" href="#evaluation" title="Evaluation" />
                  <tab-nav-items class="col-md-4 p-0" :active="false" href="#erm" title="Enterprise Risk Management" />
                </tab-nav>
              </div>
            </template>
          </iq-card>
        </b-col>
        <!-- END TAB -->
        <b-col lg="12">
          <div id="pills-tabContent-3" class="tab-content">
            <!-- Operational -->
            <tab-content-item :active="true" id="recommendation">
              <SummaryManagementRecommendations v-if="summary.data" :show-action-buttons="false" :lists="summary.data.form_with_recommendation" />
            </tab-content-item>
            <tab-content-item :active="false" id="evaluation">
              <SummaryManagementEvaluations v-if="summary.data" :lists="summary.data.form_with_evaluation" />
            </tab-content-item>
            <tab-content-item :active="false" id="erm">
              <SummaryManagementERM v-if="summary.data" :lists="summary.data.form_with_erm"/>
            </tab-content-item>
          </div>
        </b-col>
      </b-row>
    </b-sidebar>
    <!-- END SIDEBAR IMPROVEMENT -->
  </b-container>
</template>
<script>
import { miActions, vehiclesActions } from '@/src/Utils/helper'
import { xray } from '../../config/pluginInit'
import { numberFormat } from '@src/plugins/helpers'
import _ from 'lodash'
import moment from 'moment'
import SummaryManagementDetailAccidentList from '@src/views/MI/SummaryManagementDetailAccidentList'
import SummaryManagementReportSettled from '@src/views/MI/SummaryManagementReportSettled'
import SummaryManagementReportUnSettled from '@src/views/MI/SummaryManagementReportUnSettled'
import SummaryManagementNonClaim from '@src/views/MI/SummaryManagementNonClaim'
import SummaryManagementRecommendations from '@src/views/MI/SummaryManagementRecommendations'
import SummaryManagementEvaluations from '@src/views/MI/SummaryManagementEvaluations'
import SummaryManagementERM from '@src/views/MI/SummaryManagementERM'

export default {
  name: 'SummaryManagement',
  components: { SummaryManagementERM, SummaryManagementEvaluations, SummaryManagementRecommendations, SummaryManagementNonClaim, SummaryManagementReportUnSettled, SummaryManagementReportSettled, SummaryManagementDetailAccidentList },
  async mounted () {
    xray.index()
    await this.getVehicleList()
    await this.fetchSummary()
  },
  data () {
    return {
      isLoading: true,
      selectedFleets: null,
      showSettledBox: true,
      showOnGoingBox: true,
      stateActive: {
        single: 'active',
        disable: 'active',
        number: 'settled',
        colorDisabled: 'five',
        color: 'danger'
      },
      chartCaseCondition: {
        chart: {
          height: 350,
          type: 'area'
        },
        colors: ['#E1534F', '#017BFE'],
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'smooth'
        },
        series: [],
        markers: {
          size: 4
        },
        xaxis: {
          categories: []
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        }
      },
      chartClaimRecovery: {
        chart: {
          height: 350,
          type: 'bar'
        },
        colors: ['#E1534F'],
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return 'IDR ' + numberFormat(value)
          },
          position: 'top'
        },
        stroke: {
          curve: 'smooth'
        },
        series: [],
        markers: {
          size: 4
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return 'IDR ' + numberFormat(value)
            }
          }
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
      },
      chartNonClaimLoss: {
        chart: {
          height: 350,
          type: 'bar'
        },
        colors: ['#017BFE'],
        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return 'IDR ' + numberFormat(value)
          }
        },
        stroke: {
          curve: 'smooth'
        },
        series: [],
        markers: {
          size: 4
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return 'IDR ' + numberFormat(value)
            }
          }
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
      },
      chartRateRecords: [],
      chartLossRatio: [],
      custom: [
        {
          name: 'number',
          label: 'Settled',
          value: 'settled',
          disabled: false
        },
        {
          name: 'number',
          label: 'On Going',
          value: 'on_going',
          disabled: false
        }
      ],
      kapal: [],
      typeOptions: [
        {
          text: 'all',
          value: 'all'
        },
        {
          text: 'claim',
          value: 'claim'
        },
        {
          text: 'non claim',
          value: 'non_claim'
        }
      ],
      summary: {
        params: {
          year: new Date().getFullYear().toString(),
          type: 'all',
          vehicle_ids: []
        },
        data: null
      },
      filteredForms: [],
      showAccidentPopup: false,
      showMatrixCauseOfLossTable: false,
      showMatrixClassificationOfLossTable: false
    }
  },
  computed: {
    matrixCoverageTableData () {
      if (!this.summary.data) {
        return []
      }

      return Object.entries(_.groupBy(this.summary.data.matrix_coverage_table, ({ date_of_loss }) => new Date(date_of_loss).getMonth())).map(group => {
        return {
          month_year: group[1] && group[1][0] ? moment(group[1][0].date_of_loss).format('MMM YYYY') : null,
          lists: group[1]
        }
      })
    },
    matrixCauseOfLossTableData () {
      if (!this.summary.data) {
        return []
      }

      return Object.entries(_.groupBy(this.summary.data.matrix_cause_of_loss_table, ({ date_of_loss }) => new Date(date_of_loss).getMonth())).map(group => {
        return {
          month_year: group[1] && group[1][0] ? moment(group[1][0].date_of_loss).format('MMM YYYY') : null,
          lists: group[1]
        }
      })
    }
  },
  methods: {
    ...miActions,
    ...vehiclesActions,
    formatCurrency (number) {
      const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 2
      })
      return formatter.format(Number.isNaN(number) ? 0 : number)
    },
    numberFormat (number) {
      return numberFormat(number)
    },
    async fetchSummary () {
      this.isLoading = true
      const params = { ...this.summary.params }
      this.chartCaseCondition.series = []
      this.chartCaseCondition.xaxis.categories = []
      this.chartClaimRecovery.series = []
      this.chartClaimRecovery.xaxis.categories = []
      this.chartNonClaimLoss.series = []
      this.chartNonClaimLoss.xaxis.categories = []

      this.chartRateRecords = []
      this.chartLossRatio = []
      this.summary.data = null

      if (params.vehicle_ids.length === 0) {
        delete params.vehicle_ids
      }
      const { data, status } = await this.getSummaryManagement(
        params
      )
      if (status === 'success') {
        this.summary.data = data

        this.chartClaimRecovery.series.push({
          name: 'claim',
          data: data.claim_chart_recovery.map((val) => val.value)
        })
        this.chartClaimRecovery.xaxis.categories = data.claim_chart_recovery.map(
          (val) => moment(val.label).format('MMM YYYY')
        )

        this.chartNonClaimLoss.series.push({
          name: 'Non Claim',
          data: data.nonclaim_chart_cost_loss.map((val) => val.value)
        })
        this.chartNonClaimLoss.xaxis.categories = data.nonclaim_chart_cost_loss.map(
          (val) => moment(val.label).format('MMM YYYY')
        )

        if (
          params.type === 'all' ||
          params.type === 'claim'
        ) {
          this.chartCaseCondition.series.push({
            name: 'claim',
            data: data.chart.claim.map((val) => val.value)
          })
        }
        if (
          params.type === 'all' ||
          params.type === 'non_claim'
        ) {
          this.chartCaseCondition.series.push({
            name: 'non claim',
            data: data.chart.non_claim.map((val) => val.value)
          })
        }
        if (
          params.type === 'all' ||
          params.type === 'claim'
        ) {
          this.chartCaseCondition.xaxis.categories = data.chart.claim.map(
            (val) => moment(val.label).format('MMM YYYY')
          )
        } else {
          this.chartCaseCondition.xaxis.categories = data.chart.non_claim.map(
            (val) => moment(val.label).format('MMM YYYY')
          )
        }

        if (data.chart_rate_record) {
          data.chart_rate_record.forEach(chart => {
            if (chart.data) {
              const series = []
              const groups = chart.data.map((val) => {
                return { title: val.label, cols: val.data ? val.data.length : 0 }
              })
                // .filter(val => val.cols.length > 0)

              series.push({
                name: 'Rate I&V',
                data: chart.data ? chart.data.map((val) => {
                  const cols = []

                  if (val.data) {
                    val.data.forEach(valData => {
                      if (valData.companies) {
                        valData.companies.forEach(company => {
                          cols.push({
                            x: company.company,
                            y: valData.final_rate_iv
                          })
                        })
                      }
                    })
                  }

                  return cols
                }).flat() : []
              })
              series.push({
                name: 'Rate H&M',
                data: chart.data ? chart.data.map((val) => {
                  const cols = []

                  if (val.data) {
                    val.data.forEach(valData => {
                      if (valData.companies) {
                        valData.companies.forEach(company => {
                          cols.push({
                            x: company.company,
                            y: valData.final_rate_hm
                          })
                        })
                      }
                    })
                  }

                  return cols
                }).flat() : []
              })
              series.push({
                name: 'Rate WAR',
                data: chart.data ? chart.data.map((val) => {
                  const cols = []

                  if (val.data) {
                    val.data.forEach(valData => {
                      if (valData.companies) {
                        valData.companies.forEach(company => {
                          cols.push({
                            x: company.company,
                            y: valData.final_rate_war
                          })
                        })
                      }
                    })
                  }

                  return cols
                }).flat() : []
              })

              this.chartRateRecords.push({
                name: chart.name,
                alias: chart.alias,
                chart: {
                  height: 350,
                  type: 'bar'
                },
                colors: ['#E1534F', '#017BFE', '#0E5E6F'],
                dataLabels: {
                  enabled: true,
                  formatter: function (value) {
                    return `${numberFormat(value)}%`
                  },
                  style: {
                    colors: ['#333']
                  },
                  position: 'top'
                },
                stroke: {
                  curve: 'smooth'
                },
                series,
                markers: {
                  size: 4
                },
                yaxis: {
                  labels: {
                    formatter: function (value) {
                      return `${numberFormat(value)}%`
                    }
                  }
                },
                xaxis: {
                  type: 'category',
                  labels: {
                    formatter: function (val) {
                      return val
                    }
                  },
                  group: {
                    style: {
                      fontSize: '10px',
                      fontWeight: 700
                    },
                    groups
                  }
                },
                grid: {
                  row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                  }
                }
              })
            }
          })
        }
        if (data.chart_rate_lossratio) {
          data.chart_rate_lossratio.forEach(chart => {
            if (chart.data) {
              const series = []
              const categories = chart.data.map((val) => val.label)

              series.push({
                name: 'Individual',
                data: chart.data ? chart.data.map((val) => val.individual) : []
              })
              series.push({
                name: 'Accumulative',
                data: chart.data ? chart.data.map((val) => val.accumulative) : []
              })

              this.chartLossRatio.push({
                name: chart.name,
                alias: chart.alias,
                chart: {
                  height: 350,
                  type: 'line'
                },
                colors: ['#E1534F', '#017BFE', '#01fe23'],
                dataLabels: {
                  enabled: true
                },
                stroke: {
                  curve: 'smooth'
                },
                series,
                markers: {
                  size: 4
                },
                xaxis: {
                  categories
                },
                grid: {
                  row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                  }
                }
              })
            }
          })
        }
      }

      this.isLoading = false
    },
    async getVehicleList () {
      const { data } = await this.getVehicles({ showAll: true, page: 1 })
      this.kapal = data.data.map((val) => ({ value: val.id, text: val.name }))
    },
    onShowAccidentForm (ids) {
      this.filteredForms = ids.map((val) =>
        this.summary.data.forms.find((data) => data.id === val)
      )
      this.showAccidentPopup = true
    },
    onShowMatrixCauseOfLossTable () {
      this.showMatrixCauseOfLossTable = true
    },
    onShowMatrixClassificationOfLossTable () {
      this.showMatrixClassificationOfLossTable = true
    },
    handleOpenTotalCase () {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-total-case')
    },
    handleOpenNonClaim () {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-non-claim')
    },
    handleOpenClaim () {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-claim')
    },
    handleOpenImprovement () {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-improvement')
    }
  }
}
</script>
