<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
          <iq-card>
              <template v-slot:headerTitle>
                  <h5 class="card-title text-primary"><strong>PURCHASE RECEIPT</strong></h5>
              </template>
              <template v-slot:headerAction>
              <a class="text-primary float-right ml-3" v-b-toggle.filter role="button" style="cursor: pointer">
              <i class="fa fa-filter" />
              </a>
              </template>
              <template v-slot:body>
                <b-collapse visible id="filter" class="mb-4">
                    <b-row>
                      <b-col md="2">
                          <b-form-select plain v-model="selectStatusPurchaseRequest" :options="optionStatusPurchaseRequest" size="sm">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Status</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-col>
                      <b-col md="2">
                          <b-form-select plain v-model="selectPriority" :options="optionPriority" size="sm">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Priority</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-col>
                      <b-col md="2">
                          <b-form-select plain v-model="selectCompany" :options="optionCompany" size="sm">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Company</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-col>
                      <b-col md="1">
                          <b-button variant="primary" size="sm"><i class="fa fa-search"></i></b-button>
                      </b-col>
                      <b-col md="5">
                          <b-button v-b-tooltip.top="'Add Data'" @click="addPurchaseOrder = !addPurchaseOrder;" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-plus"></i></b-button>
                          <b-button v-b-tooltip.top="'Refresh Data'" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-refresh"></i></b-button>
                          <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                              <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                  <i class="fa fa-save m-0"></i>
                                  </span>
                              </template>
                              <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
                              <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
                          </b-dropdown>
                      </b-col>
                    </b-row>
                  </b-collapse>
                  <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="text-center">RECEIPT NUMBER</th>
                            <th class="text-center">STATUS</th>
                            <th class="text-center">DATE</th>
                            <th class="text-center">DEPARTMENT</th>
                            <th class="text-center">FROM</th>
                            <th class="text-center">TO</th>
                            <th class="text-center">ORDER TOTAL</th>
                            <th class="text-center">SOUCRE</th>
                            <th class="text-center">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th class="text-center"><a href="#" @click="detailPurchaseItem = !detailPurchaseItem;">REC0001</a></th>
                            <td class="text-center"><b-badge variant="primary w-100">Progress</b-badge></td>
                            <td class="text-center">10/28/2022</td>
                            <td class="text-center">Technical</td>
                            <td class="text-center">VDR001 - Global Marine, PT</td>
                            <td class="text-center">WAREHOUSE JAKARTA</td>
                            <td class="text-center">1.524.000</td>
                            <th class="text-center"><a href="#" @click="detailPurchaseItem = !detailPurchaseItem;">PO0001</a></th>
                            <td class="text-center">
                                <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-list-alt m-0 text-primary"></i>
                                    </span>
                                </template>
                                <b-dropdown-item href="#" variant="dark"><i class="fa fa-edit mr-2"></i>Update</b-dropdown-item>
                                <b-dropdown-item href="#" variant="dark"><i class="fa fa-trash mr-2"></i>Delete</b-dropdown-item>
                                </b-dropdown>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                  <div class="mt-3">
                      <b-pagination :value="1" :total-rows="50" align="center" />
                  </div>

                  <!-- Detail Purchase -->
                  <b-sidebar
                    v-model="detailPurchaseItem"
                    id="detail_vendor"
                    title="REQ-2210001 - TB. ETI 201"
                    width="80%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h5 text-primary">General Information</legend>
                            <table class="table table-striped mt-2">
                              <tbody>
                                <tr>
                                  <th width="15%">Request Number</th>
                                  <td width="4%">:</td>
                                  <td width="15%">REQ-2210001</td>
                                  
                                  <th width="15%">Company</th>
                                  <td width="3%">:</td>
                                  <td width="15%">Transcoal Pacific</td>
                                  
                                  <th width="15%">Priority</th>
                                  <td width="3%">:</td>
                                  <td width="15%"><b-badge variant="primary">Normal</b-badge></td>
                                </tr>
                                <tr>
                                  <th width="15%">Request Class</th>
                                  <td width="4%">:</td>
                                  <td width="15%">PURCHASE</td>
                                  
                                  <th width="15%">Fleet</th>
                                  <td width="3%">:</td>
                                  <td width="15%">TB. ETI 301</td>
                                  
                                  <th width="15%">Currenct</th>
                                  <td width="3%">:</td>
                                  <td width="15%">IDR</td>
                                </tr>
                                <tr>
                                  <th width="15%">Request By</th>
                                  <td width="4%">:</td>
                                  <td width="15%">Agung Tri Laksono</td>
                                  
                                  <th width="15%">Project</th>
                                  <td width="3%">:</td>
                                  <td width="15%">KPC Project</td>
                                  
                                  <th width="15%">Est. Cost</th>
                                  <td width="3%">:</td>
                                  <td width="15%">Rp. 2.500.000</td>
                                </tr>
                                <tr>
                                  <th width="15%">Department</th>
                                  <td width="4%">:</td>
                                  <td width="15%">Operational</td>
                                  
                                  <th width="15%">Sub Project</th>
                                  <td width="3%">:</td>
                                  <td width="15%">Long Hauling</td>
                                  
                                  <th width="15%">Open Qty</th>
                                  <td width="3%">:</td>
                                  <td width="15%">25</td>
                                </tr>
                                <tr>
                                  <th width="15%">Date</th>
                                  <td width="4%">:</td>
                                  <td width="15%">10/28/2022</td>
                                  
                                  <th width="15%">Location</th>
                                  <td width="3%">:</td>
                                  <td width="15%">Sangatta</td>
                                  
                                  <th width="15%">Description</th>
                                  <td width="3%">:</td>
                                  <td width="15%">Request Sparepart</td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <tab-nav :tabs="true">
                            <tab-nav-items :active="true" id="item-tab" ariaControls="item" role="tab" :ariaSelected="true" title="Item Detail" />
                            <tab-nav-items :active="false" id="vendor-tab" ariaControls="vendor" role="tab" :ariaSelected="false" title="Vendor Info" />
                          </tab-nav>
                          <div id="myTabContent" class="tab-content">
                            <tab-content-item :active="true" id="item">
                              <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                      <tr>
                                        <th class="text-center">INVENTORY ID</th>
                                        <th class="text-center">DESCRIPTION ITEM</th>
                                        <th class="text-center">QTY</th>
                                        <th class="text-center">UOM</th>
                                        <th class="text-center">PRICE</th>
                                        <th class="text-center">SUB TOTAL</th>
                                        <th class="text-center">VENDOR INFO</th>
                                        <th class="text-center">VENDOR CONTACT</th>
                                        <th class="text-center">VENDOR LOCATION</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td class="text-center">INV-02101001</td>
                                        <td class="text-center">Engine Oil Filter</td>
                                        <td class="text-center">10</td>
                                        <td class="text-center">PCS</td>
                                        <td class="text-center">225.000</td>
                                        <td class="text-center">2.250.000</td>
                                        <td class="text-center">Utama Production, CV</td>
                                        <td class="text-center">Agung Tri Laksono</td>
                                        <td class="text-center">Kalimantan Selatan</td>
                                      </tr>
                                      
                                      <tr>
                                        <td class="text-center">INV-02101002</td>
                                        <td class="text-center">Hyd Oil Filter Element</td>
                                        <td class="text-center">20</td>
                                        <td class="text-center">PCS</td>
                                        <td class="text-center">225.000</td>
                                        <td class="text-center">2.250.000</td>
                                        <td class="text-center">Utama Production, CV</td>
                                        <td class="text-center">Agung Tri Laksono</td>
                                        <td class="text-center">Kalimantan Selatan</td>
                                      </tr>
                                    </tbody>
                                </table>
                              </div>
                            </tab-content-item>
                            <tab-content-item :active="false" id="vendor" aria-labelled-by="vendor-tab">
                              <b-row>
                                <b-col md="12">
                                  <h4>New Vendor ?</h4>
                                  <template v-for="(item,index) in text">
                                    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-left" :key="index">
                                      <div class="custom-switch-inner">
                                        <p class="mb-0"> {{ item.label }} </p>
                                        <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                        <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                        </label>
                                      </div>
                                    </div>
                                  </template>
                                </b-col>
                                <b-col md="6" class="mt-3">
                                  <fieldset class="form-group border p-3">
                                    <legend class="w-auto px-2 h6 text-primary">Vendor Information</legend>
                                    <b-form>
                                      <b-row>
                                        <b-col>
                                          <b-form-group label="Company ID" label-for="companyID">
                                            <b-form-input id="CompanyID" style="height:40px"></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Company Name" label-for="companyName">
                                            <b-form-input id="vendorName" style="height:40px"></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col>
                                          <b-form-group label="Phone" label-for="phone">
                                            <b-form-input id="phone" style="height:40px"></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="WhatsApp Number" label-for="whatsappNumber">
                                            <b-form-input id="whatsappNumber" style="height:40px"></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col>
                                          <b-form-group label="Email" label-for="email">
                                            <b-form-input type="email" id="email" style="height:40px"></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Website" label-for="website">
                                            <b-form-input id="website" style="height:40px"></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <b-row>
                                        <b-col>
                                          <b-form-group label="Address" label-for="address">
                                            <b-form-textarea id="address" rows="3"></b-form-textarea>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </b-form>
                                      
                                      <b-form>
                                        
                                      </b-form>
                                  </fieldset>
                                </b-col>
                              </b-row>
                            </tab-content-item>
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>

                  <b-sidebar
                    v-model="addPurchaseOrder"
                    id="addPurchaseOrder"
                    title="ADD PURCHASE RECEIPT"
                    width="80%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h5 text-primary">General Information</legend>
                            <b-alert :show="true" variant="primary">
                              <b-row>
                                <b-col md="12">
                                  <div class="iq-alert-text">Please Select Your <b>Type Receipt</b> From </div>
                                </b-col>
                                <b-col md="6">
                                  <b-form-select plain v-model="selectReceiptType" :options="optionReceiptType" size="sm" style="border: solid 1px gray;">
                                    <template v-slot:first>
                                      <b-form-select-option :value="null">Select Order Type</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-col>
                                <b-col md="6">
                                  <b-form-select plain v-model="selectOrderNumber" :options="optionOrderNumber" size="sm" style="border: solid 1px gray;">
                                    <template v-slot:first>
                                    <b-form-select-option :value="null">Select Order Number</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-col>
                              </b-row>
                            </b-alert>
                            <b-row class="pl-3 pr-3">
                              <b-col md="4">
                                <b-form>
                                  <b-form-group class="row" label-cols-md="4" label="Receipt Number" label-for="receiptNumber">
                                    <b-form-input id="receiptNumber" style="height:40px" placeholder="< GENERATE RECEIPT NUMBER >"></b-form-input>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Date" label-for="Date">
                                    <b-form-input type="date" id="Date" style="height:40px"></b-form-input>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Complete Date" label-for="deliveryDate">
                                    <b-form-input type="date" id="deliveryDate" style="height:40px"></b-form-input>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Request By" label-for="requestBy">
                                    <b-form-select plain v-model="selectRequestBy" :options="optionRequestBy" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Requester</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Department" label-for="requestDepartment">
                                    <b-form-select plain v-model="selectDepartment" :options="optionDepartment" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Department</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </b-form>
                              </b-col>

                              <b-col md="4">
                                <b-form>
                                  <b-form-group class="row" label-cols-md="4" label="Company" label-for="requestCompany">
                                    <b-form-select plain v-model="selectCompany" :options="optionCompany" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Company</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Fleet" label-for="requestFleet">
                                    <b-form-select plain v-model="selectFleet" :options="optionFleet" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Fleet</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Project" label-for="Project">
                                    <b-form-select plain v-model="selectProject" :options="optionProject" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Project</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Sub Project" label-for="requestSubProject">
                                    <b-form-select plain v-model="selectSubProject" :options="optionSubProject" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Sub Project</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Location" label-for="requestLocation">
                                    <b-form-select plain v-model="selectLocation" :options="optionLocation" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Location</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </b-form>
                              </b-col>

                              <b-col md="4">
                                <b-form>
                                  <b-form-group class="row" label-cols-md="4" label="Owner Data" label-for="ownerData">
                                    <b-form-select plain v-model="selectOwnerData" :options="optionOwnerData" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Owner Data</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Priority" label-for="requestPriority">
                                    <b-form-select plain v-model="selectPriority" :options="optionPriority" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Status</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Total Qty" label-for="TotalQty">
                                    <b-form-input type="number" id="TotalQty" style="height:40px" placeholder="45 pcs" readonly></b-form-input>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Total Cost" label-for="totalCost">
                                    <b-form-input type="number" id="totalCost" style="height:40px" placeholder="Rp. 174.000.000" readonly></b-form-input>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Description" label-for="description">
                                    <b-form-input type="number" id="description" style="height:40px"></b-form-input>
                                  </b-form-group>
                                </b-form>
                              </b-col>
                            </b-row>
                          </fieldset>
                          <tab-nav :tabs="true">
                            <tab-nav-items :active="true" id="add_pr_item-tab" ariaControls="add_pr_item" role="tab" :ariaSelected="true" title="Item Detail" />
                            <tab-nav-items :active="false" id="add_pr_vendor-tab" ariaControls="add_pr_vendor" role="tab" :ariaSelected="false" title="Vendor Info" />
                          </tab-nav>
                          <div id="myTabContent" class="tab-content">
                            <tab-content-item :active="true" id="add_pr_item">
                              <div class="table-responsive">
                                <table class="table">
                                  <thead>
                                      <tr>
                                        <th class="text-center">PO NUMBER</th>
                                        <th class="text-center">INVENTORY ID</th>
                                        <th class="text-center">DESCRIPTION ITEM</th>
                                        <th class="text-center">OPEN QTY</th>
                                        <th class="text-center">ORDER QTY</th>
                                        <th class="text-center">RECEIPT QTY</th>
                                        <th class="text-center">RETURN QTY</th>
                                        <th class="text-center">UOM</th>
                                        <th class="text-center">WAREHOUSE</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td class="text-center">PO-22100001</td>
                                        <td class="text-center">INV-02101001</td>
                                        <td class="text-center">Engine Oil Filter</td>
                                        <td class="text-center">10</td>
                                        <td class="text-center">10</td>
                                        <td class="text-center">10</td>
                                        <td class="text-center">0</td>
                                        <td class="text-center">Pcs</td>
                                        <td class="text-center">Jakarta</td>
                                      </tr>
                                    </tbody>
                                </table>
                              </div>
                            </tab-content-item>
                            <tab-content-item :active="false" id="add_pr_vendor" aria-labelled-by="add_pr_vendor-tab">
                              <b-row>
                                <b-col md="12" class="mt-3">
                                  <fieldset class="form-group border p-3">
                                    <legend class="w-auto px-2 h6 text-primary">Vendor</legend>
                                    <b-alert :show="true" variant="primary">
                                      <b-row>
                                        <b-col md="12">
                                          <div class="iq-alert-text">New vendor ? (<b>Yes</b> = New Vendor / <b>No</b> : Vendor Existing)</div>
                                        </b-col>
                                        <b-col md="12">
                                          <template v-for="(item,index) in text">
                                            <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-left" :key="index">
                                              <div class="custom-switch-inner">
                                                <p class="mb-0"> {{ item.label }} </p>
                                                <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                </label>
                                              </div>
                                            </div>
                                          </template>
                                        </b-col>
                                        <b-col md="6" class="mt-2">
                                          <b-form-select plain v-model="selectVendor" :options="optionVendor" size="sm" style="border: solid 1px gray;">
                                            <template v-slot:first>
                                              <b-form-select-option :value="null">Select Vendor</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </b-col>
                                      </b-row>
                                    </b-alert>
                                    <b-form>
                                      <b-row>
                                        <b-col md="6">
                                          <b-alert :show="true" class="text-white bg-primary">
                                            <div class="iq-alert-text"><b>Company Info</b></div>
                                          </b-alert>
                                          <b-row>
                                            <b-col>
                                              <b-form-group label="Company ID" label-for="companyID">
                                                <b-form-input id="CompanyID" style="height:40px" placeholder="<< GENERATE COMPANY ID >>"></b-form-input>
                                              </b-form-group>
                                            </b-col>
                                            <b-col>
                                              <b-form-group label="Company Name" label-for="companyName">
                                                <b-form-input id="vendorName" style="height:40px"></b-form-input>
                                              </b-form-group>
                                            </b-col>
                                          </b-row>
                                          <b-row>
                                            <b-col>
                                              <b-form-group label="Phone" label-for="phone">
                                                <b-form-input id="phone" style="height:40px"></b-form-input>
                                              </b-form-group>
                                            </b-col>
                                            <b-col>
                                              <b-form-group label="WhatsApp Number" label-for="whatsappNumber">
                                                <b-form-input id="whatsappNumber" style="height:40px"></b-form-input>
                                              </b-form-group>
                                            </b-col>
                                          </b-row>
                                          <b-row>
                                            <b-col>
                                              <b-form-group label="Email" label-for="email">
                                                <b-form-input type="email" id="email" style="height:40px"></b-form-input>
                                              </b-form-group>
                                            </b-col>
                                            <b-col>
                                              <b-form-group label="Website" label-for="website">
                                                <b-form-input id="website" style="height:40px"></b-form-input>
                                              </b-form-group>
                                            </b-col>
                                          </b-row>
                                          <b-row>
                                            <b-col>
                                              <b-form-group label="Address" label-for="address">
                                                <b-form-textarea id="address" rows="3"></b-form-textarea>
                                              </b-form-group>
                                            </b-col>
                                          </b-row>
                                        </b-col>
                                        <b-col md="6">
                                          <b-alert :show="true" class="text-white bg-primary">
                                            <div class="iq-alert-text"><b>Shipping To</b></div>
                                          </b-alert>
                                          <b-row>
                                            <b-col>
                                              <b-form-group label="Company Name" label-for="companyName">
                                                <b-form-input id="CompanyName" style="height:40px"></b-form-input>
                                              </b-form-group>
                                            </b-col>
                                            <b-col>
                                              <b-form-group label="Phone" label-for="phone">
                                                <b-form-input id="phone" style="height:40px"></b-form-input>
                                              </b-form-group>
                                            </b-col>
                                          </b-row>
                                          <b-row>
                                            <b-col>
                                              <b-form-group label="Email" label-for="email">
                                                <b-form-input type="email" id="email" style="height:40px"></b-form-input>
                                              </b-form-group>
                                            </b-col>
                                            <b-col>
                                              <b-form-group label="Website" label-for="website">
                                                <b-form-input id="website" style="height:40px"></b-form-input>
                                              </b-form-group>
                                            </b-col>
                                          </b-row>
                                          <b-row>
                                            <b-col>
                                              <b-row>
                                                <b-col>
                                                  <b-form-group label="Currency" label-for="currency">
                                                    <b-form-select plain v-model="selectCurrency" :options="optionCurrency" size="sm">
                                                      <template v-slot:first>
                                                      <b-form-select-option :value="null">Currency</b-form-select-option>
                                                      </template>
                                                    </b-form-select>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col>
                                                  <b-form-group label="Tax" label-for="tax">
                                                    <template v-for="(item,index) in text">
                                                      <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-left" :key="index">
                                                        <div class="custom-switch-inner">
                                                          <p class="mb-0"> {{ item.label }} </p>
                                                          <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                          <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </template>
                                                  </b-form-group>
                                                </b-col>
                                              </b-row>
                                            </b-col>
                                            <b-col>
                                              <b-form-group label="Payment Condition" label-for="website">
                                                <b-form-select plain>
                                                  <b-form-select-option>CASH</b-form-select-option>
                                                  <b-form-select-option>COD</b-form-select-option>
                                                  <b-form-select-option>NET 7</b-form-select-option>
                                                  <b-form-select-option>NET 15</b-form-select-option>
                                                  <b-form-select-option>NET 30</b-form-select-option>
                                                  <b-form-select-option>NET 60</b-form-select-option>
                                                </b-form-select>
                                              </b-form-group>
                                            </b-col>
                                          </b-row>
                                          <b-row>
                                            <b-col>
                                              <b-form-group label="Address" label-for="address">
                                                <b-form-textarea id="address" rows="3"></b-form-textarea>
                                              </b-form-group>
                                            </b-col>
                                          </b-row>
                                        </b-col>
                                      </b-row>
                                    </b-form>
                                  </fieldset>
                                </b-col>
                              </b-row>
                            </tab-content-item>
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
              </template>
          </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
export default {
  name: 'procurementPurchaseRequest',
  mounted () {
    xray.index()
  },
  data () {
      return {
        // Sliding Page
        detailPurchaseItem: false,
        addPurchaseOrder: false,

        // Form Option
        selectReceiptType: 2,
        optionReceiptType: [
            { value: '1', text: 'Normal' },
            { value: '2', text: 'Purchase Order' }
        ],
        selectStatusPurchaseRequest: null,
        optionStatusPurchaseRequest: [
            { value: '1', text: 'Request' },
            { value: '2', text: 'Progress' },
            { value: '3', text: 'Close' },
            { value: '4', text: 'Hold' },
        ],
        selectPriority: null,
        optionPriority: [
            { value: '1', text: 'Normal' },
            { value: '2', text: 'Urgent' },
            { value: '3', text: 'Critical' }
        ],
        selectCompany: null,
        optionCompany: [
            { value: '1', text: 'Transcoal Pacific' },
            { value: '2', text: 'Energi Transporter' },
            { value: '3', text: 'Sentra Makmur Lines' }
        ],
        selectRequestBy: null,
        optionRequestBy: [
            { value: '1', text: 'Nurul Akbar' },
            { value: '2', text: 'Agung Tri Laksono' },
            { value: '3', text: 'Ananda Pratama' },
            { value: '4', text: 'Dicky Setiawan'}
        ],
        selectOwnerData: null,
        optionOwnerData: [
            { value: '1', text: 'Nurul Akbar' },
            { value: '2', text: 'Agung Tri Laksono' },
            { value: '3', text: 'Ananda Pratama' },
            { value: '4', text: 'Dicky Setiawan'}
        ],
        selectFleet: null,
        optionFleet: [
            { value: '1', text: 'TB. ETI 201' },
            { value: '2', text: 'TB. TCP 311' },
            { value: '3', text: 'BG. ETI 3001' },
            { value: '4', text: 'PT. TCP 207'}
        ],
        selectProject: null,
        optionProject: [
            { value: '1', text: 'KPC PROJECT' },
            { value: '2', text: 'AI PROJECT' },
            { value: '3', text: 'KENDARI PROJECT' },
            { value: '4', text: 'NICKEL PROJECT'}
        ],
        selectSubProject: null,
        optionSubProject: [
            { value: '1', text: 'LONG HAULING' },
            { value: '2', text: 'TRANSHIPMENT' },
            { value: '3', text: 'ASSIST TUG' }
        ],
        selectLocation: null,
        optionLocation: [
            { value: '1', text: 'Asam-Asam' },
            { value: '2', text: 'Sangatta' },
            { value: '3', text: 'Bengalon' },
            { value: '4', text: 'Paiton'}
        ],
        selectDepartment: null,
        optionDepartment: [
            { value: '1', text: 'Operational' },
            { value: '2', text: 'Technical' },
            { value: '3', text: 'Crewing' }
        ],
        selectCurrency: null,
        optionCurrency: [
            { value: '1', text: 'IDR' },
            { value: '2', text: 'USD' },
            { value: '3', text: 'SGD' }
        ],
        selectInventoryID: null,
        optionInventoryID: [
            { value: '1', text: 'TEC-0001 - FUEL OIL' },
            { value: '2', text: 'TEC-0002 - LUBE OIL' },
            { value: '3', text: 'TEC-0002 - V-BELT' }
        ],
        selectOrderNumber: null,
        optionOrderNumber: [
            { value: '1', text: 'PO-2210001 - PERBAIKAN KAPAL 301' },
            { value: '2', text: 'PO-2210002 - PERBAIKAN ME TB. ETI 2201' },
            { value: '3', text: 'PO-2210003 - PEMBELIAN STOCK' }
        ],
        selectVendor: null,
        optionVendor: [
            { value: '1', text: 'Solusi Cakra Tangguh, PT' },
            { value: '2', text: 'Chemical Indo Pratama, PT' },
            { value: '3', text: 'Energi Cipta Karya' }
        ],
        // Checklist
        text: [
          {
            activeLabel: 'Yes',
            inactiveLabel: 'No',
            class: 'primary',
            checked: true
          }
        ],
        state: [
          {
            label: 'False/Inactive',
            checked: false,
            disabled: false
          }
        ],
      }
  }
}
</script>
