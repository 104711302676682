<template>
    <b-row>
        <b-col sm="12">
        <iq-card>
            <template v-slot:headerTitle>
            <h5 class="card-title text-primary">
                <strong>HISTORY SERVICE REPAIR</strong>
            </h5>
            </template>
            <template v-slot:body>
            <table class="table table-striped">
                <thead>
                <tr>
                    <th class="text-center">NO</th>
                    <th class="text-left">FLEET NAME</th>
                    <th class="text-center">TOTAL COST</th>
                    <th class="text-center">TOTAL REPAIR</th>
                    <th class="text-center">STATUS</th>
                    <th class="text-center">MINOR</th>
                    <th class="text-center">MEDIUM</th>
                    <th class="text-center">MAJOR</th>
                    <th class="text-center">ACTION</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(state, index) in list_table_finished?.data" :key="'finish-' + index">
                    <td class="text-center">{{ index + 1 }}</td>
                    <td class="text-left">{{  state?.vehicle?.name }}</td>
                    <td class="text-center">{{ formatCurrency(state?.total_cost_real) }}</td>
                    <td class="text-center">{{ state?.total_repair }} Jobs
                    </td>
                    <td class="text-center">
                    <b-badge variant="info" class="mb-1">Earlier : {{ state?.status?.earlier }} Jobs</b-badge> <br/>
                    <b-badge variant="success" class="mb-1">On Time : {{ state?.status?.onTime }} Jobs</b-badge><br>
                    <b-badge variant="danger">Delay : {{ state?.status?.late }} Jobs</b-badge>
                    </td>
                    <td class="text-center">{{ state?.classfication?.minor }} Jobs</td>
                    <td class="text-center">{{ state?.classfication?.medium }} Jobs</td>
                    <td class="text-center">{{ state?.classfication?.major }} Jobs</td>
                    <td class="text-center">
                    <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                        <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                            <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                        </span>
                        </template>
                        <b-dropdown-item href="#">
                        <router-link
                            :to="{
                            path: `/sr/history`,
                            query: { 
                                vehicleId: state?.vehicle_id,
                            },
                            }"
                        >
                            <i class="fa fa-ship mr-2"></i>Detail History
                        </router-link>
                        </b-dropdown-item>
                    </b-dropdown>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="mt-3" v-if="list_table_finished?.data?.length > 0">
                <b-pagination
                  v-model="list_table_finished.meta.page"
                  :total-rows="list_table_finished.meta.total_item"
                  :per-page="list_table_finished.meta.per_page"
                  aria-controls="my-table"
                  @change="getListFinishedTableServiceAndRepair"
                  align="center"
                  first-number
                />
            </div>
            </template>
        </iq-card>
        </b-col>
    </b-row>
</template>

<script>
import { xray } from '../../config/pluginInit'
import _ from 'lodash'
import {
  fleetsActions,
  crewingActions,
  srActions
} from '@src/Utils/helper'
import { createPopper } from '@popperjs/core'
import moment from 'moment'
import optionsData from '@src/core/mock/data'

export default {
  name: 'TabFinish',
  props: {
    query: {
      type: Object,
      default: () => {
        return {
            fleetCategory: [],
            fleet:null,
            status:null
        }
      },
    },
    readOnly: {
        type: Boolean,
    },
    fleetId:{
        type:Number
    },
    filtering:{
        type:Boolean
    }
  },
  data() {
    return {
      // fleet
      ...optionsData,
      finish_loading:false,
      list_table_finished:{
        data:[],
        meta:null
      },
    }
  },
  async mounted() {
    xray.index()
    await this.getListFinishedTableServiceAndRepair()
  },
  created() {
    this.getListFinishedTableServiceAndRepair = _.debounce(this.getListFinishedTableServiceAndRepair, 500)
  },
  watch:{
    'filtering': {
      handler(n, o) {
        this.getListFinishedTableServiceAndRepair()
      },
      immediate: true,
    },
  },
  methods: {
    ...fleetsActions,
    ...srActions,
    ...crewingActions,

    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    async getListFinishedTableServiceAndRepair(page){
        this.request_loading = true
      
        let params = {
            start_date: moment(this.query?.dateRange?.[0]).format('YYYY-MM-DD'),
            end_date: moment(this.query?.dateRange?.[1]).format('YYYY-MM-DD'),
            fleet_id: this.readOnly ? this.fleetId : this.query?.fleet?.value ?? null,
            fleet_category: this.query?.fleetCategory?.map(val => val?.value),
            page: page || 1,
        }

        if(!params.fleet_id) delete params.fleet_id
        if(params.fleet_category.length <= 0) delete params.fleet_category
      
        let res = await this.fetchListTableFinishedServiceAndRepair(params)
        if (res.status === 'error') {
            this.list_table_finished.data = []
            this.list_table_finished.meta = null
            this.request_loading = false
        } else {
            this.list_table_finished.data = res.data.data
            this.list_table_finished.meta = res.data.meta  
            this.request_loading = false
        }
    }, 
  }
}
</script>