import CryptoJS from "crypto-js"

const CRYPTO_SECRET_KEY = process.env.VUE_APP_CRYPTO_SECRET_KEY

// ===
// Private helpers
// ===
function encrypt(text) {
	return CryptoJS.AES.encrypt(text, CRYPTO_SECRET_KEY).toString()
}

function decrypt(cipherText) {
    const bytes = CryptoJS.AES.decrypt(cipherText, CRYPTO_SECRET_KEY)
    return bytes.toString(CryptoJS.enc.Utf8)
}

function saveState(key, value, token=false) {
	if (token) window.localStorage.setItem(key, value)
	else window.localStorage.setItem(key, JSON.stringify(value))
}

function setDefaultAuthHeaders(state) {
	axios.defaults.headers.common.Authorization = state.token
	  ? `Bearer ${decrypt(state.token)}`
	  : ''
  }

const mutations = {
	SET_PROFILE(state, newValue) {
		state.profile = newValue
		saveState('profile', newValue)
	},
	SET_TOKEN(state, newValue) {
		const encrypted_token = newValue ? encrypt(newValue) : null
		state.token = encrypted_token
		saveState('token', encrypted_token, true)
		setDefaultAuthHeaders(state)
	},
	SET_REFRESH_TOKEN(state, newValue) {
		const encrypted_token = newValue ? encrypt(newValue) : null
		state.refresh_token = encrypted_token
		saveState('refresh_token', encrypted_token, true)
		setDefaultAuthHeaders(state)
	},
	SET_AXIOS_TOKEN(state, newValue) {
		setDefaultAuthHeaders(state)
	}
}

export default mutations
