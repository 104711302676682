<template>
  <b-sidebar
    :id="`modal---${id}`"
    v-model="model"
    :title="modalTitle"
    width="50%"
    backdrop-variant="dark"
    bg-variant="white"
    right
    backdrop
    shadow>
    <template #default="{hide}">
      <div v-if="loading" class="text-center">
        Loading Content...
      </div>
      <b-row v-else class="m-3">
        <b-col md="12">
          <tab-content-item :active="true" id="power">
            <tab-nav :tabs="true">
              <tab-nav-items :active="true" href="#activity" title="Activity" />
            </tab-nav>
            <div id="myTabContent" class="tab-content">
              <tab-content-item :active="true" id="activity">
                <b-row>
                  <b-col md="12" class="mb-5">
                    <form action="" @submit.prevent="submit">
                      <b-form-row>
                        <b-col>
                          <b-form-group label="Select Docking Job *" label-for="docking_job">
                            <v-select v-model="selectedTaskId" label="displayName" @input="handleTaskDetail"
                                      :options="tasks" :reduce="task => task.id">
                            </v-select>
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                      <b-form-row>
                        <b-col>
                          <b-form-group label-for="progress_bar"><h4 class="mb-2">Progrees : <b-badge variant="warning">
                            {{ form.progressBar }}%</b-badge></h4>
                            <b-progress :value="form.progressBar" variant="success" max="100"></b-progress>
                          </b-form-group>
                        </b-col>
                      </b-form-row>
                      <template v-if="form.progressBar < 100">
                        <b-form-row>
                          <b-col>
                            <b-form-group label="Start *" label-for="start">
                              <date-picker required v-model="form.date" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group label="Progress Pekerjaan *" label-for="progress_pekerjaan">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text bg-primary" id="progress_pekerjaan">%</span>
                                </div>
                                <input type="number" v-model="form.progress" :min="form.progressBar" required class="form-control" id="progress_pekerjaan" placeholder="80">
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group label="Report Progress" label-for="report_progress">
                              <div v-if="form.fileName" class="d-flex justify-content-between">
                                <a :href="form.fileUrl" class="text-primary" v-b-tooltip.hover title="Download File" download><i class="fa fa-download"></i> Download</a>
                                <a href="#" @click.prevent="() => {
                  form.fileUrl = ''
                  form.fileName = ''
                   }" class="text-danger" v-b-tooltip.hover title="Hapus" download><i class="fa fa-trash"></i></a>
                              </div>
                              <template v-else>
                                <div class="custom-file">
                                  <input type="file" @change="onUploadFile"
                                         accept="image/png,image/jpg,image/jpeg,.pdf,.xls,.xlsx,.doc,.docx"
                                         class="custom-file-input" id="attachment-shipyard">
                                  <label class="custom-file-label" for="attachment-shipyard">Browse file...</label>
                                </div>
                                <small class="text-muted">
                                  File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                  File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                </small>
                              </template>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row>
                          <b-col>
                            <b-form-group label="Notes" label-for="notes">
                              <b-form-textarea id="description" v-model="form.notes" rows="3" placeholder="Notes"></b-form-textarea>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-button type="submit" block variant="success">UPDATE ACTIVITY</b-button>
                      </template>
                    </form>
                  </b-col>
                  <b-col md="12">
                    <ul class="iq-timeline" style="height:100%">
                      <li v-for="activity in activities">
                        <div class="timeline-dots border-success"></div>
                        <h6 v-if="!selectedTask" class="float-left mb-1">{{ activity.taskName }}</h6><br/>
                        <small>{{ activity.startActivity }}</small>
                        <div class="d-inline-block w-100">
                          <p>{{activity.notes}}</p>
                        </div>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
              </tab-content-item>
            </div>
          </tab-content-item>
        </b-col>
      </b-row>
    </template>
  </b-sidebar>
</template>

<script>
import { dockingActions, filesActions, fleetsActions } from '@src/Utils/helper'
import _ from 'lodash'

export default {
  name: 'ModalDockingActivity',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'docking-activity'
    },
    dockingId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      tasks: [],
      activities: [],
      selectedTaskId: null,
      form: {
        saving: false,
        date: '',
        notes: '',
        progress: 0,
        progressBar: 0,
        fileName: '',
        fileUrl: ''
      }
    }
  },
  computed: {
    modalTitle () {
      return 'Update Activity'
    },
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    selectedTask () {
      if (!this.selectedTaskId) {
        return null
      }

      return this.tasks.find(task => task.id === this.selectedTaskId)
    }
  },
  watch: {
    model () {
      this.onResetStates()
      if (this.model === true) {
        if (this.dockingId) {
          this.onInit()
        }
      }
    }
  },
  methods: {
    ...fleetsActions,
    ...dockingActions,
    ...filesActions,

    onResetStates () {
      this.resetForm()
      this.loading = false
      this.tasks = []
      this.selectedTaskId = null
      this.activities = []
    },
    resetForm () {
      this.form = {
        saving: false,
        date: '',
        notes: '',
        progress: 0,
        progressBar: 0,
        fileName: '',
        fileUrl: ''
      }
    },
    cancel () {
      this.resetForm()
      this.$emit('cancel')
    },
    async onInit () {
      this.loading = true
      await Promise.all([
        this.fetchTasks()
      ])
      this.loading = false
    },
    async submit () {
      this.form.saving = true
      const payload = {
        'date': this.form.date,
        'progress': this.form.progress,
        'notes': this.form.notes,
        'fileName': [
          this.form.fileName
        ],
        dockingId: this.dockingId,
        taskId: this.selectedTaskId }

      const { status } = await this.createDockingTaskActivity(payload)

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        return
      }

      if ([500, 404, 403, 400].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      this.form.saving = false
      this.$emit('submit')
    },
    async handleTaskDetail () {
      if (this.selectedTask) {
        this.form.progressBar = this.selectedTask.progress
        // this.form.progress = this.selectedTask.jobWorkloadPercentage
        // this.form.date = this.selectedTask.jobStart
        // this.form.notes = this.selectedTask.notes
        // this.form.fileName = this.selectedTask.jobDoc ? this.selectedTask.jobDoc.fileName : ''
        // this.form.fileUrl = this.selectedTask.jobDoc ? this.selectedTask.jobDoc.url : ''
        this.activities = []
        if (this.selectedTask.taskActivity) {
          this.selectedTask.taskActivity.forEach(activity => {
            if (activity.startActivity) {
              this.activities.push({ ...activity, taskName: this.selectedTask.name })
            }
          })
        }
      }
    },
    async onUploadFile (event) {
      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)
      if (res.status === 'success') {
        this.form.fileName = res.data.fileName
        this.form.fileUrl = res.data.url
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    async fetchTasks () {
      this.tasks = []
      this.activities = []

      const res = await this.getDockingTasks({
        dockingId: this.dockingId
      })
      if (res.status === 'success' && res.data) {
        let rawData = res.data
        if (rawData) {
          this.tasks = rawData.map(d => {
            return {
              displayName: `${d.dockingJob.name} ${d.dockingJob.jobCategory ? `- ${d.dockingJob.jobCategory.name}` : ''}`,
              name: d.dockingJob.name,
              id: d.id,
              ...d
            }
          })

          this.tasks.forEach(task => {
            if (task.taskActivity) {
              task.taskActivity.forEach(activity => {
                if (activity.startActivity) {
                  this.activities.push({ ...activity, taskName: task.name })
                }
              })
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
