var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": true,
            "href": "#pureRiskComponent",
            "title": "Pure Risk Component (Year)"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": false,
            "href": "#pureRiskDetail",
            "title": "Pure Risk Detail"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "pureRiskComponent"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER PURE RISK COMPONENT (YEAR)")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.doExportProjects
          }
        }, [!_vm.loading_export_project ? [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("   Export ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Exporting... ")]], 2)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search :",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Year :",
            "label-for": "kind-fleet"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.yearOptions,
            "reduce": function reduce(type) {
              return type.value;
            }
          },
          model: {
            value: _vm.year,
            callback: function callback($$v) {
              _vm.year = $$v;
            },
            expression: "year"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-table-simple', [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("NO")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Year")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Pure Risk Cost")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Details %")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.pureRisks.data, function (data, index) {
          return _c('tr', [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(data.year))]), _c('td', [_vm._v(_vm._s(data.pure_risk_cost) + "%")]), _c('td', [_c('ol', {
            staticClass: "m-0 pl-3"
          }, _vm._l(data.items, function (item) {
            return _c('li', [_vm._v(_vm._s(item.item.name) + ": " + _vm._s(item.value) + "%")]);
          }), 0)]), _c('td', [_c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onEditPureRisk(data.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeletePureRisk(data.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])], 1)]);
        }), 0)])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.pureRiskForm.id ? "Edit" : "Add") + " Pure Risk Component (Year)")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSavePureRisk.apply(null, arguments);
            }
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Year of Budget:",
            "label-for": "serial"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.yearOptions,
            "reduce": function reduce(type) {
              return type.value;
            }
          },
          model: {
            value: _vm.pureRiskForm.year,
            callback: function callback($$v) {
              _vm.$set(_vm.pureRiskForm, "year", $$v);
            },
            expression: "pureRiskForm.year"
          }
        })], 1), _c('p', {
          staticClass: "text-center"
        }), _c('h4', [_vm._v("PERCENTAGE SHARING")]), _c('hr'), _c('p'), _c('table', {
          staticClass: "table"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "colspan": "2"
          }
        }, [_c('h5', [_vm._v("#DETAILS")])])])]), _c('tbody', [_vm._l(_vm.pureRiskForm.items, function (item, index) {
          return _c('tr', [_c('td', {
            attrs: {
              "width": "40%"
            }
          }, [_vm._v(_vm._s(item.name))]), _c('td', {
            attrs: {
              "width": "60%"
            }
          }, [_c('b-input-group', {
            attrs: {
              "size": "md",
              "append": "%"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number",
              "min": "1"
            },
            model: {
              value: item.value,
              callback: function callback($$v) {
                _vm.$set(item, "value", $$v);
              },
              expression: "item.value"
            }
          })], 1)], 1)]);
        }), _c('tr', [_c('td', {
          attrs: {
            "width": "40%"
          }
        }, [_vm._v("SUM")]), _c('td', {
          attrs: {
            "width": "60%"
          }
        }, [_c('h5', {
          class: {
            'text-danger': _vm.isSumPureRiskItemsMoreThan100
          }
        }, [_c('strong', [_vm._v(_vm._s(_vm.sumPureRiskItems) + "%")])]), _vm.isSumPureRiskItemsMoreThan100 ? _c('small', {
          staticClass: "text-danger"
        }, [_c('strong', [_vm._v("Jangan Melebihi 100%")])]) : _vm._e()])]), _c('tr', [_c('td', {
          attrs: {
            "width": "40%"
          }
        }, [_vm._v("PURE RISK COST")]), _c('td', {
          attrs: {
            "width": "60%"
          }
        }, [_vm.isSumPureRiskItemsMoreThan100 ? _c('small', {
          staticClass: "text-danger"
        }, [_c('strong', [_vm._v("Jangan Melebihi 100%")])]) : _c('h5', [_c('strong', [_vm._v(_vm._s(_vm.pureRiskItemsCost) + "%")])])])])], 2)])], 1), _c('b-col', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_vm._v("Save")]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              return _vm.resetFormPureRisk();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "pureRiskDetail"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER PURE RISK DETAIL")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.doExportProjects
          }
        }, [!_vm.loading_export_project ? [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("   Export ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Exporting... ")]], 2)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Master Data Pure Risk:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-table-simple', [_c('thead', [_c('tr', {
          attrs: {
            "width": "100%"
          }
        }, [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("NO")]), _c('th', {
          attrs: {
            "width": "65%"
          }
        }, [_vm._v("DESCRIPTION / ITEM")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Status")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.pureRiskItems.data, function (data, index) {
          return _c('tr', [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(data.name))]), _c('td', [data.active ? _c('b-badge', {
            attrs: {
              "variant": "success"
            }
          }, [_vm._v("Active")]) : _c('b-badge', {
            attrs: {
              "variant": "danger"
            }
          }, [_vm._v("Inactive")])], 1), _c('td', [_c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onEditPureRiskItem(data.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeletePureRiskItem(data.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])], 1)]);
        }), 0)])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    staticClass: "border border-light shadow-lg",
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.pureRiskItemForm.id ? "Edit" : "Add") + " Pure Risk Detail")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSavePureRiskItems.apply(null, arguments);
            }
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Details:",
            "label-for": "serial"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "required": ""
          },
          model: {
            value: _vm.pureRiskItemForm.name,
            callback: function callback($$v) {
              _vm.$set(_vm.pureRiskItemForm, "name", $$v);
            },
            expression: "pureRiskItemForm.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "sub_sub_project"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "value": 1,
            "unchecked-value": 0,
            "name": "check-button",
            "required": "",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.pureRiskItemForm.active,
            callback: function callback($$v) {
              _vm.$set(_vm.pureRiskItemForm, "active", $$v);
            },
            expression: "pureRiskItemForm.active"
          }
        }, [_vm._v(" Active ")])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_vm._v("Save")]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              return _vm.resetFormPureRiskItems();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }