var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "iq-card-body iq-global-search"
  }, [_c('h5', {
    staticClass: "card-title"
  }, [_vm._v("Page")]), _c('ul', {
    staticClass: "pl-1 mb-0"
  }, [_vm.filteredList.length > 0 ? _vm._l(_vm.filteredList, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "iq-bg-primary-hover"
    }, [!item.is_heading && !item.children ? [!item.is_heading ? _c('router-link', {
      staticClass: "iq-waves-effect pr-4 list-group-item bg-transparent border-0 iq-bg-primary-hover",
      attrs: {
        "to": item.link
      }
    }, [item.is_icon_class ? _c('i', {
      class: item.icon
    }) : void 0, _c('span', [_vm._v(_vm._s(item.title))]), item.children ? _c('i', {
      staticClass: "ri-arrow-right-s-line iq-arrow-right"
    }) : _vm._e()], 2) : _vm._e()] : _vm._e()], 2);
  }) : [_c('li', {
    staticClass: "nav-link"
  }, [_vm._v("Search Not Found")])]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }