var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("SALES ORDER")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.filter",
            modifiers: {
              "filter": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-4",
          attrs: {
            "visible": "",
            "id": "filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionStatusPurchaseRequest,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Status")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectStatusPurchaseRequest,
            callback: function callback($$v) {
              _vm.selectStatusPurchaseRequest = $$v;
            },
            expression: "selectStatusPurchaseRequest"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionPriority,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Priority")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectPriority,
            callback: function callback($$v) {
              _vm.selectPriority = $$v;
            },
            expression: "selectPriority"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionCompany,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Company")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectCompany,
            callback: function callback($$v) {
              _vm.selectCompany = $$v;
            },
            expression: "selectCompany"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1), _c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Add Data',
            expression: "'Add Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              _vm.addPurchaseRequest = !_vm.addPurchaseRequest;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        })]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Refresh Data',
            expression: "'Refresh Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-refresh"
        })]), _c('b-dropdown', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Export Data',
            expression: "'Export Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "link iq-bg-primary",
            "toggle-class": "text-decoration-none",
            "no-caret": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-save m-0"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _vm._v("Export Excel")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf mr-2"
        }), _vm._v("Export PDF")])], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("ORDER NUMBER")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DATE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("CUSTOMER")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("SHIPMENT")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ORDER QTY")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("UOM")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              _vm.pageDetailSalesOrder = !_vm.pageDetailSalesOrder;
            }
          }
        }, [_vm._v("SO-2210001")])]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary w-100"
          }
        }, [_vm._v("Order")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("10/28/2022")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Jaya Abadi, PT")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("EXPEDITION")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("100")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Pcs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-list-alt m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.addVendor",
            modifiers: {
              "addVendor": true
            }
          }],
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-edit mr-2"
        }), _vm._v("Approval")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-edit mr-2"
        }), _vm._v("Update")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("Delete")])], 1)], 1)])])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('b-modal', {
          attrs: {
            "id": "addVendor",
            "size": "lg",
            "title": "Detail",
            "ok-title": "Save",
            "cancel-title": "Close"
          }
        }, [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 h5 text-primary"
        }, [_vm._v("General Information")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Request Number")]), _c('td', {
          attrs: {
            "width": "4%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("REQ-2210001")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Company")]), _c('td', {
          attrs: {
            "width": "3%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Transcoal Pacific")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Priority")]), _c('td', {
          attrs: {
            "width": "3%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "15%"
          }
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Normal")])], 1)]), _c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Request Class")]), _c('td', {
          attrs: {
            "width": "4%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("PURCHASE")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Fleet")]), _c('td', {
          attrs: {
            "width": "3%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("TB. ETI 301")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Currenct")]), _c('td', {
          attrs: {
            "width": "3%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("IDR")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Request By")]), _c('td', {
          attrs: {
            "width": "4%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Agung Tri Laksono")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Project")]), _c('td', {
          attrs: {
            "width": "3%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("KPC Project")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Est. Cost")]), _c('td', {
          attrs: {
            "width": "3%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Rp. 2.500.000")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Department")]), _c('td', {
          attrs: {
            "width": "4%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Operational")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Sub Project")]), _c('td', {
          attrs: {
            "width": "3%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Long Hauling")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Open Qty")]), _c('td', {
          attrs: {
            "width": "3%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("25")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Date")]), _c('td', {
          attrs: {
            "width": "4%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("10/28/2022")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Location")]), _c('td', {
          attrs: {
            "width": "3%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Sangatta")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Description")]), _c('td', {
          attrs: {
            "width": "3%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Request Sparepart")])])])])]), _c('tab-nav', {
          attrs: {
            "tabs": true
          }
        }, [_c('tab-nav-items', {
          attrs: {
            "active": true,
            "id": "item-tab",
            "ariaControls": "item",
            "role": "tab",
            "ariaSelected": true,
            "title": "Item Detail"
          }
        }), _c('tab-nav-items', {
          attrs: {
            "active": false,
            "id": "vendor-tab",
            "ariaControls": "vendor",
            "role": "tab",
            "ariaSelected": false,
            "title": "Vendor Info"
          }
        })], 1), _c('div', {
          staticClass: "tab-content",
          attrs: {
            "id": "myTabContent"
          }
        }, [_c('tab-content-item', {
          attrs: {
            "active": true,
            "id": "item"
          }
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("INVENTORY ID")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET NAME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DESCRIPTION")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TONASE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("EST. PRICE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TAX")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("AMOUNT")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ATTACHMENT")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v(" [M000] - COAL ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" TB. 0001 ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" POL - POD\" ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" 7500 ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" 1.450.000.000 ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" 11% ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" 1.450.000.000 ")]), _c('td', {
          staticClass: "text-center"
        }, [_c('div', {
          staticClass: "custom-file mt-3"
        }, [_c('input', {
          staticClass: "custom-file-input",
          attrs: {
            "type": "file",
            "id": "validatedCustomFile",
            "required": ""
          }
        }), _c('label', {
          staticClass: "custom-file-label",
          attrs: {
            "for": "validatedCustomFile"
          }
        }, [_vm._v("Browse file...")])])])])])])])]), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "vendor",
            "aria-labelled-by": "vendor-tab"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('h4', [_vm._v("New Vendor ?")]), _vm._l(_vm.text, function (item, index) {
          return [_c('div', {
            key: index,
            staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-left"
          }, [_c('div', {
            staticClass: "custom-switch-inner"
          }, [_c('p', {
            staticClass: "mb-0"
          }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: item.checked,
              expression: "item.checked"
            }],
            staticClass: "custom-control-input",
            class: 'bg-' + item.class,
            attrs: {
              "type": "checkbox",
              "id": 'customSwitch-11' + index
            },
            domProps: {
              "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
            },
            on: {
              "change": function change($event) {
                var $$a = item.checked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(item, "checked", $$c);
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": 'customSwitch-11' + index,
              "data-on-label": item.activeLabel,
              "data-off-label": item.inactiveLabel
            }
          })])])];
        })], 2), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "6"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 h6 text-primary"
        }, [_vm._v("Vendor Information")]), _c('b-form', [_c('b-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Company ID",
            "label-for": "companyID"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "height": "40px"
          },
          attrs: {
            "id": "CompanyID"
          }
        })], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Company Name",
            "label-for": "companyName"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "height": "40px"
          },
          attrs: {
            "id": "vendorName"
          }
        })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Telepon",
            "label-for": "telepon"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "height": "40px"
          },
          attrs: {
            "id": "telepon"
          }
        })], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "WhatsApp Number",
            "label-for": "whatsappNumber"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "height": "40px"
          },
          attrs: {
            "id": "whatsappNumber"
          }
        })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Email",
            "label-for": "email"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "height": "40px"
          },
          attrs: {
            "type": "email",
            "id": "email"
          }
        })], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Website",
            "label-for": "website"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "height": "40px"
          },
          attrs: {
            "id": "website"
          }
        })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Address",
            "label-for": "address"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "address",
            "rows": "3"
          }
        })], 1)], 1)], 1)], 1), _c('b-form')], 1)])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-sidebar', {
          attrs: {
            "id": "detail_vendor",
            "title": "REQ-2210001 - TB. ETI 201",
            "width": "80%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var hide = _ref.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("General Information")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Request Number")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("REQ-2210001")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Company")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Transcoal Pacific")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Priority")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_c('b-badge', {
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("Normal")])], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Request Class")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("PURCHASE")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Fleet")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("TB. ETI 301")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Currenct")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("IDR")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Request By")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Project")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("KPC Project")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Est. Cost")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Rp. 2.500.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Department")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Operational")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Sub Project")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Long Hauling")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Open Qty")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("25")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Date")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("10/28/2022")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Location")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Sangatta")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Description")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Request Sparepart")])])])])]), _c('tab-nav', {
                attrs: {
                  "tabs": true
                }
              }, [_c('tab-nav-items', {
                attrs: {
                  "active": true,
                  "id": "item-tab",
                  "ariaControls": "item",
                  "role": "tab",
                  "ariaSelected": true,
                  "title": "Item Detail"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "vendor-tab",
                  "ariaControls": "vendor",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Vendor Info"
                }
              })], 1), _c('div', {
                staticClass: "tab-content",
                attrs: {
                  "id": "myTabContent"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "item"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("INVENTORY ID")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("DESCRIPTION ITEM")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("QTY")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("UOM")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("PRICE")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("SUB TOTAL")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("VENDOR INFO")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("VENDOR CONTACT")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("VENDOR LOCATION")])])]), _c('tbody', [_c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("INV-02101001")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Engine Oil Filter")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("10")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("PCS")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("225.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("2.250.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Utama Production, CV")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Kalimantan Selatan")])]), _c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("INV-02101002")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Hyd Oil Filter Element")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("20")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("PCS")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("225.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("2.250.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Utama Production, CV")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Kalimantan Selatan")])])])])])]), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "vendor",
                  "aria-labelled-by": "vendor-tab"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('h4', [_vm._v("New Vendor ?")]), _vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-left"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2), _c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "6"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h6 text-primary"
              }, [_vm._v("Vendor Information")]), _c('b-form', [_c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Company ID",
                  "label-for": "companyID"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "CompanyID"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Company Name",
                  "label-for": "companyName"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "vendorName"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Telepon",
                  "label-for": "telepon"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "telepon"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "WhatsApp Number",
                  "label-for": "whatsappNumber"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "whatsappNumber"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Email",
                  "label-for": "email"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "email",
                  "id": "email"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Website",
                  "label-for": "website"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "website"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Address",
                  "label-for": "address"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "address",
                  "rows": "3"
                }
              })], 1)], 1)], 1)], 1), _c('b-form')], 1)])], 1)], 1)], 1)], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.pageDetailSalesOrder,
            callback: function callback($$v) {
              _vm.pageDetailSalesOrder = $$v;
            },
            expression: "pageDetailSalesOrder"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "addPurchaseRequest",
            "title": "ADD SALES ORDER",
            "width": "80%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hide = _ref2.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("General Information")]), _c('b-row', {
                staticClass: "pl-3 pr-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Order Number",
                  "label-for": "orderNumber"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "orderNumber",
                  "placeholder": "<< CREATE ORDER NUMBER >>"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Date",
                  "label-for": "dateRequest"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "date",
                  "id": "dateRequest"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Shipment Date",
                  "label-for": "deliveryDate"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "date",
                  "id": "deliveryDate"
                }
              })], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Customer",
                  "label-for": "customer"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionCustomer,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Company")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectCustomer,
                  callback: function callback($$v) {
                    _vm.selectCustomer = $$v;
                  },
                  expression: "selectCustomer"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Customer Name",
                  "label-for": "customerName"
                }
              }, [_c('b-form-input', {
                staticClass: "text-right",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "text",
                  "id": "qty"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Location",
                  "label-for": "requestLocation"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionLocation,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Location")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectLocation,
                  callback: function callback($$v) {
                    _vm.selectLocation = $$v;
                  },
                  expression: "selectLocation"
                }
              })], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Priority",
                  "label-for": "requestPriority"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionPriority,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Status")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectPriority,
                  callback: function callback($$v) {
                    _vm.selectPriority = $$v;
                  },
                  expression: "selectPriority"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Currency",
                  "label-for": "currency"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "8"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionCurrency,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Currency")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectCurrency,
                  callback: function callback($$v) {
                    _vm.selectCurrency = $$v;
                  },
                  expression: "selectCurrency"
                }
              })], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-button', {
                staticClass: "mb-3 mr-1 w-100",
                attrs: {
                  "variant": "outline-primary",
                  "size": "sm"
                }
              }, [_vm._v("Data")])], 1)], 1)], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Description",
                  "label-for": "description"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "description"
                }
              })], 1)], 1)], 1)], 1)], 1), _c('tab-nav', {
                attrs: {
                  "tabs": true
                }
              }, [_c('tab-nav-items', {
                attrs: {
                  "active": true,
                  "id": "item-tab",
                  "ariaControls": "tabItemAddSalesOrder",
                  "role": "tab",
                  "ariaSelected": true,
                  "title": "Item Detail"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "shippingTabSalesOrder",
                  "ariaControls": "tabShippingAddSalesOrder",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Shipping Method"
                }
              })], 1), _c('div', {
                staticClass: "tab-content",
                attrs: {
                  "id": "myTabContent"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "tabItemAddSalesOrder"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.top",
                  value: 'Add Data',
                  expression: "'Add Data'",
                  modifiers: {
                    "top": true
                  }
                }],
                staticClass: "float-right mr-1 mb-3",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                },
                on: {
                  "click": function click($event) {
                    _vm.addPurchaseRequest = !_vm.addPurchaseRequest;
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              })]), _c('b-button', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.top",
                  value: 'Edit Data',
                  expression: "'Edit Data'",
                  modifiers: {
                    "top": true
                  }
                }],
                staticClass: "float-right mr-1 mb-3",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                }
              }, [_c('i', {
                staticClass: "fa fa-edit"
              })]), _c('b-button', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.top",
                  value: 'Delete Data',
                  expression: "'Delete Data'",
                  modifiers: {
                    "top": true
                  }
                }],
                staticClass: "float-right mr-1 mb-3",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash"
              })]), _c('b-button', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.top",
                  value: 'Refresh Data',
                  expression: "'Refresh Data'",
                  modifiers: {
                    "top": true
                  }
                }],
                staticClass: "float-right mr-1 mb-3",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                }
              }, [_c('i', {
                staticClass: "fa fa-refresh"
              })]), _c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("INVENTORY ID")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("FLEET NAME")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("DESCRIPTION")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("TONASE")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("EST. PRICE")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("TAX")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("AMOUNT")])])]), _c('tbody', [_c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionInventoryID,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Inventory ID")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectInventoryID,
                  callback: function callback($$v) {
                    _vm.selectInventoryID = $$v;
                  },
                  expression: "selectInventoryID"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-right",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "text",
                  "id": "qty",
                  "placeholder": "TB. 0001"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-right",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "text",
                  "id": "qty",
                  "placeholder": "POL - POD"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-right",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty",
                  "placeholder": "7500"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-right",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty",
                  "placeholder": ""
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-right",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty",
                  "placeholder": ""
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-right",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty",
                  "placeholder": ""
                }
              })], 1)])])])])], 1), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "tabShippingAddSalesOrder",
                  "aria-labelled-by": "shippingTabSalesOrder"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('h4', [_vm._v("New Vendor ?")]), _vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-left"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2), _c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "6"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h6 text-primary"
              }, [_vm._v("Vendor Information")]), _c('b-form', [_c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Company ID",
                  "label-for": "companyID"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "CompanyID"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Company Name",
                  "label-for": "companyName"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "vendorName"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Telepon",
                  "label-for": "telepon"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "telepon"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "WhatsApp Number",
                  "label-for": "whatsappNumber"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "whatsappNumber"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Email",
                  "label-for": "email"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "email",
                  "id": "email"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Website",
                  "label-for": "website"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "website"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Address",
                  "label-for": "address"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "address",
                  "rows": "3"
                }
              })], 1)], 1)], 1)], 1), _c('b-form')], 1)])], 1)], 1)], 1)], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.addPurchaseRequest,
            callback: function callback($$v) {
              _vm.addPurchaseRequest = $$v;
            },
            expression: "addPurchaseRequest"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }