import axios from 'axios'
import CryptoJS from "crypto-js"

const CRYPTO_SECRET_KEY = process.env.VUE_APP_CRYPTO_SECRET_KEY
const token = window.localStorage.getItem('token');

function decrypt(cipherText) {
    const bytes = CryptoJS.AES.decrypt(cipherText, CRYPTO_SECRET_KEY)
    return bytes.toString(CryptoJS.enc.Utf8)
}

axios.defaults.headers.common.Authorization = token
		? `Bearer ${decrypt(token)}`
		: ''

export default axios