var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title pt-3"
        }, [_c('b', [_vm._v("Dropdown Options")])]), _c('p', [_vm._v("vue-select accepts arrays of strings or objects to use as options through the options prop")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('iq-card', {
          staticClass: "shadow-none border",
          scopedSlots: _vm._u([{
            key: "headerTitle",
            fn: function fn() {
              return [_c('h4', {
                staticClass: "card-title"
              }, [_c('b', [_vm._v("Basic")])])];
            },
            proxy: true
          }, {
            key: "headerAction",
            fn: function fn() {
              return [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.collapse-1",
                  modifiers: {
                    "collapse-1": true
                  }
                }],
                staticClass: "text-primary float-right",
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "ri-code-s-slash-line"
              })])];
            },
            proxy: true
          }, {
            key: "body",
            fn: function fn() {
              return [_c('b-collapse', {
                staticClass: "mb-2",
                attrs: {
                  "id": "collapse-1"
                }
              }, [_c('div', {
                staticClass: "card"
              }, [_c('kbd', {
                staticClass: "bg-dark"
              }, [_c('pre', {
                staticClass: "text-white",
                attrs: {
                  "id": "basic-dropdown"
                }
              }, [_c('code', [_vm._v("\n<template>\n  <v-select name=\"user_id\"  :options=\"[ 'Name ','name']\"/>\n  <v-select name=\"user_id\" :options=\"[{label: 'Name ',value: 'name'}]\" />\n</template>\n<script>\nexport default {\n name: 'SelectDemo',\ndata(){\n  return {}\n   }\n  }\n</script>\n")])])])])]), _c('v-select', {
                attrs: {
                  "name": "user_id",
                  "options": ['Name ', 'name']
                }
              }), _c('p', [_vm._v("When provided an array of objects, vue-select will display a single value of the object. By default, vue-select will look for a key named label on the object to use as display text")]), _c('v-select', {
                attrs: {
                  "name": "user_id",
                  "options": [{
                    label: 'Name ',
                    value: 'name'
                  }]
                }
              })];
            },
            proxy: true
          }])
        })], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('iq-card', {
          staticClass: "shadow-none border",
          scopedSlots: _vm._u([{
            key: "headerTitle",
            fn: function fn() {
              return [_c('h4', {
                staticClass: "card-title"
              }, [_c('b', [_vm._v("Option label")])])];
            },
            proxy: true
          }, {
            key: "headerAction",
            fn: function fn() {
              return [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.collapse-2",
                  modifiers: {
                    "collapse-2": true
                  }
                }],
                staticClass: "text-primary float-right",
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "ri-code-s-slash-line"
              })])];
            },
            proxy: true
          }, {
            key: "body",
            fn: function fn() {
              return [_c('b-collapse', {
                staticClass: "mb-2",
                attrs: {
                  "id": "collapse-2"
                }
              }, [_c('div', {
                staticClass: "card"
              }, [_c('kbd', {
                staticClass: "bg-dark"
              }, [_c('pre', {
                staticClass: "text-white",
                attrs: {
                  "id": "Option-label-dropdown"
                }
              }, [_c('code', [_vm._v("\n<template>\n  <v-select name=\"user_id\" label=\"countryName\" :options= countries />\n</template>\n<script>\nexport default {\n name: 'SelectDemo',\ndata(){\n  return {\n      countries: [\n        {\n          countryCode: 'GE',\n          countryName: 'Germany'\n        },\n        {\n          countryCode: 'AUS',\n          countryName: 'Australia'\n        },\n        {\n          countryCode: 'CA',\n          countryName: 'Canada'\n        }\n      ]\n    }\n   }\n  }\n</script>\n")])])])])]), _c('p', [_vm._v("When the options array contains objects, vue-select looks for the label key to display by default. You can set your own label to match your source data using the label prop")]), _c('v-select', {
                attrs: {
                  "name": "user_id",
                  "label": "countryName",
                  "options": _vm.countries
                }
              })];
            },
            proxy: true
          }])
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_c('b', [_vm._v("Selecting values")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('iq-card', {
          staticClass: "shadow-none border",
          scopedSlots: _vm._u([{
            key: "headerTitle",
            fn: function fn() {
              return [_c('h4', {
                staticClass: "card-title"
              }, [_c('b', [_vm._v("Default")])]), _c('p', [_vm._v("The most common use case for vue-select is to have the chosen value synced with a parent component. vue-select takes advantage of the v-model syntax to sync values with a parent")])];
            },
            proxy: true
          }, {
            key: "headerAction",
            fn: function fn() {
              return [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.collapse-3",
                  modifiers: {
                    "collapse-3": true
                  }
                }],
                staticClass: "text-primary float-right",
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "ri-code-s-slash-line"
              })])];
            },
            proxy: true
          }, {
            key: "body",
            fn: function fn() {
              return [_c('b-collapse', {
                staticClass: "mb-2",
                attrs: {
                  "id": "collapse-3"
                }
              }, [_c('div', {
                staticClass: "card"
              }, [_c('kbd', {
                staticClass: "bg-dark"
              }, [_c('pre', {
                staticClass: "text-white",
                attrs: {
                  "id": "default-dropdown"
                }
              }, [_c('code', [_vm._v("\n<template>\n <v-select name=\"user_id\" v-model=\"selected\" :options=\"options\" />\n</template>\n<script>\nexport default {\n name: 'SelectDemo',\ndata(){\n  return {\n      options: [\n        { id: 1, label: 'foo' },\n        { id: 3, label: 'bar' },\n        { id: 2, label: 'baz' }\n      ],\n      selected: { id: 3, label: 'bar' }\n   }\n  }\n}\n</script>\n")])])])])]), _c('v-select', {
                attrs: {
                  "name": "user_id",
                  "options": _vm.options
                },
                model: {
                  value: _vm.selected,
                  callback: function callback($$v) {
                    _vm.selected = $$v;
                  },
                  expression: "selected"
                }
              })];
            },
            proxy: true
          }])
        })], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('iq-card', {
          staticClass: "shadow-none border",
          scopedSlots: _vm._u([{
            key: "headerTitle",
            fn: function fn() {
              return [_c('h4', {
                staticClass: "card-title"
              }, [_c('b', [_vm._v("Single/Multiple Selection")])]), _c('p', [_vm._v("By default, vue-select supports choosing a single value. If you need multiple values, use the multiple prop")])];
            },
            proxy: true
          }, {
            key: "headerAction",
            fn: function fn() {
              return [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.collapse-4",
                  modifiers: {
                    "collapse-4": true
                  }
                }],
                staticClass: "text-primary float-right",
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "ri-code-s-slash-line"
              })])];
            },
            proxy: true
          }, {
            key: "body",
            fn: function fn() {
              return [_c('b-collapse', {
                staticClass: "mb-2",
                attrs: {
                  "id": "collapse-4"
                }
              }, [_c('div', {
                staticClass: "card"
              }, [_c('kbd', {
                staticClass: "bg-dark"
              }, [_c('pre', {
                staticClass: "text-white",
                attrs: {
                  "id": "single-multiple-dropdown"
                }
              }, [_c('code', [_vm._v("\n<template>\n  <v-select multiple :closeOnSelect=\"false\" v-model=\"selectedselection\" :options=\"optionsselection\"></v-select>\n</template>\n<script>\nexport default {\n name: 'SelectDemo',\ndata(){\n  return {\n      selectedselection: ['foo', 'bar'],\n      optionsselection: ['foo', 'bar', 'baz']\n   }\n  }\n}\n</script>\n")])])])])]), _c('v-select', {
                attrs: {
                  "multiple": "",
                  "closeOnSelect": false,
                  "options": _vm.optionsselection
                },
                model: {
                  value: _vm.selectedselection,
                  callback: function callback($$v) {
                    _vm.selectedselection = $$v;
                  },
                  expression: "selectedselection"
                }
              })];
            },
            proxy: true
          }])
        })], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('iq-card', {
          staticClass: "shadow-none border",
          scopedSlots: _vm._u([{
            key: "headerTitle",
            fn: function fn() {
              return [_c('h4', {
                staticClass: "card-title"
              }, [_c('b', [_vm._v("Tagging")])]), _c('p', [_vm._v("To allow input that's not present within the options, set the taggable prop to true. If you want new tags to be pushed to the options list, set push-tags to true")])];
            },
            proxy: true
          }, {
            key: "headerAction",
            fn: function fn() {
              return [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.collapse-5",
                  modifiers: {
                    "collapse-5": true
                  }
                }],
                staticClass: "text-primary float-right",
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "ri-code-s-slash-line"
              })])];
            },
            proxy: true
          }, {
            key: "body",
            fn: function fn() {
              return [_c('b-collapse', {
                staticClass: "mb-2",
                attrs: {
                  "id": "collapse-5"
                }
              }, [_c('div', {
                staticClass: "card"
              }, [_c('kbd', {
                staticClass: "bg-dark"
              }, [_c('pre', {
                staticClass: "text-white",
                attrs: {
                  "id": "tagging-dropdown"
                }
              }, [_c('code', [_vm._v("\n<template>\n  <h6>Vue Select - Taggable</h6>\n    <v-select taggable /><br>\n  <h6>Vue Select - Taggable &amp; Push-Tags</h6>\n    <v-select taggable push-tags/>\n</template>\n<script>\nexport default {\n name: 'SelectDemo',\ndata(){\n  return {\n     taggable: {\n        type: Boolean,\n        default: false\n      },\n      pushTags: {\n        type: Boolean,\n        default: false\n      }\n   }\n  }\n}\n</script>\n")])])])])]), _c('h6', [_vm._v("Vue Select - Taggable")]), _c('v-select', {
                attrs: {
                  "taggable": ""
                }
              }), _c('br'), _c('h6', [_vm._v("Vue Select - Taggable & Push-Tags")]), _c('v-select', {
                attrs: {
                  "taggable": "",
                  "push-tags": ""
                }
              })];
            },
            proxy: true
          }])
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }