<template>
    <b-container fluid>
        <form>
            <b-row>
                <b-col lg="4">
                    <iq-card class-name="iq-user-profile-block1">
                        <template v-slot:body>
                            <div class="user-details-block">
                                <div class="user-profile text-center">
                                <img src="../../assets/images/ship.png" alt="profile-img" class="avatar-130 img-fluid">
                                </div>
                                <div class="text-center mt-3">
                                <h4>
                                    <b>TB. ETI 201</b>
                                </h4>
                                <b-badge variant="info"></b-badge>
                                </div>
                            </div>
                            <table class="table table-striped mt-3">
                                <tbody>
                                    <tr class="bg-primary">
                                        <td colspan="2" class="text-center">
                                        <strong>SHIP PARTICULAR</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <strong>Type Kapal</strong>
                                        </td>
                                        <td>TUG BOAT</td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <strong>Bendera</strong>
                                        </td>
                                        <td>Indonesia</td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <strong>Klasifikasi</strong>
                                        </td>
                                        <td>BKI Class</td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <strong>Tahun Pembuatan</strong>
                                        </td>
                                        <td>2021</td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <strong>Tempat Pembangunan</strong>
                                        </td>
                                        <td>Samarinda</td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <strong>Tempat Pendaftaran</strong>
                                        </td>
                                        <td>Jakarta</td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <strong>Ukuran Kapal</strong>
                                        </td>
                                        <td>200 X</td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <strong>Main Engine</strong>
                                        </td>
                                        <td>
                                        <span>Starboard RH : <b-badge variant="info">
                                            <strong>1.250</strong>
                                            </b-badge>
                                        </span>
                                        <br>
                                        <span>Portside RH : <b-badge variant="info">
                                            <strong>1.250</strong>
                                            </b-badge>
                                        </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <strong>Auxuliary Engine</strong>
                                        </td>
                                        <td>
                                        <span>Starboard RH : <b-badge variant="info">
                                            <strong>1.250</strong>
                                            </b-badge>
                                        </span>
                                        <br>
                                        <span>Portside RH : <b-badge variant="info">
                                            <strong>1.250</strong>
                                            </b-badge>
                                        </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                    </iq-card>
                </b-col>
                <b-col lg="8">
                    <iq-card>
                        <template v-slot:headerTitle><h4 class="card-title text-primary">Serah Terima</h4></template>
                        <template v-slot:body>
                            <b-row>
                                <b-col md="12">
                                    <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2 h6 text-primary">Docking Information</legend>
                                        <table class="table table-striped">
                                            <tbody>
                                                <tr>
                                                    <th width="20%">Arrival Shipyard</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">2022-06-10</td>
                                                    <th width="20%">Up Slip</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">2022-06-15</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Waiting Dock</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">5 Hari</td>
                                                    <th width="20%">Down Slip</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">2022-06-15</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Complete Date</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">2022-06-10</td>
                                                    <th width="20%">Budget</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">Rp. 385.000.000,-</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Shipyard</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">PT. CAHAYA BAHARI SHIPYARD</td>
                                                    <th width="20%">Real Badget</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">Rp. 350.000.000,-</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">PIC</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">NURUL AKBAR</td>
                                                    <th width="20%">Doc. Shipyard</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">
                                                    <i class="fa fa-file-pdf text-primary h3 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h3 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h3 mr-2"></i>
                                                    </td>
                                                </tr>
                                                </tbody>
                                        </table>
                                        <table class="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <th width="20%">Start Enegine</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">
                                                        <b-form-input type="date" id="startEngine"></b-form-input>
                                                    </td>
                                                    <th width="20%">Finish Engine</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">
                                                        <b-form-input type="date" id="finishEngine"></b-form-input>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Start ME</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">
                                                        <b-form-input type="date" id="startME"></b-form-input>
                                                    </td>
                                                    <th width="20%">Finish ME</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">
                                                        <b-form-input type="date" id="finishME"></b-form-input>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Start AE</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">
                                                        <b-form-input type="date" id="startAE"></b-form-input>
                                                    </td>
                                                    <th width="20%">Finish AE</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">
                                                        <b-form-input type="date" id="finishAE"></b-form-input>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Engine Test</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">
                                                        <b-form-input type="date" id="startAE"></b-form-input>
                                                    </td>
                                                    <th width="20%">Ready Sail</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">
                                                        <b-form-input type="date" id="finishAE"></b-form-input>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </fieldset>
                                    <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2 h6 text-primary">Satistification Notes</legend>
                                        <table class="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <th width="20%">Date Certificate</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">
                                                        <b-form-input type="date" id="startME"></b-form-input>
                                                    </td>
                                                    <th width="20%">Doc. S-Note</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">
                                                        <input type="file" class="form-control-file" id="attachmentSNote">
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Estimasi Budget</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">Rp. 3.500.000.000</td>
                                                    <th width="20%">Real Budget</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                            </div>
                                                            <input type="number" class="form-control" id="est_budget">
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Estimasi Tonase</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">10 Ton</td>
                                                    <th width="20%">Actual Tonase</th>
                                                    <td width="1%" class="text-center">:</td>
                                                    <td width="29%">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text bg-primary" id="inputGroupPrepend2">Ton</span>
                                                            </div>
                                                            <input type="number" class="form-control" id="est_tonase">
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </fieldset>
                                </b-col>
                            </b-row>
                        </template>
                    </iq-card>
                    <iq-card>
                        <template v-slot:headerTitle><h4 class="card-title text-primary">Summary Job</h4></template>
                        <template v-slot:body>
                            <!-- JOB CATEGORY PERCENT -->
                            <b-row>
                                <b-col md="12">
                                <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary rounded">
                                    <template v-slot:body >
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="rounded-circle iq-card-icon bg-primary"><i class="fa fa-percent"></i></div>
                                        <div class="text-right">
                                        <h2 class="mb-0"><span class="counter">100%</span></h2>
                                        <h5 class="">Persentase Job Category</h5>
                                        </div>
                                    </div>
                                    </template>
                                </iq-card>
                                </b-col>
                            </b-row>
                            <!-- JOB CATEGORY PERCENT END -->

                            <!-- DOCKING JOB -->
                            <div class="accordion" role="tablist">
                                <b-card no-body>
                                <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-success">
                                    <template v-slot:body >
                                    <div class="d-flex align-items-center justify-content-between">
                                        <b-button variant="primary" class="mr-1" v-b-toggle.jobCat-1><i class="fa fa-chevron-circle-down"></i>Click, Job Detail</b-button>
                                        <h5 class="mb-0">DOCKING / UNDOCKING - REVIEW</h5>
                                        <div class="text-right"><strong class="pull-right">BOBOT PEKERJAAN : 50%</strong></div>
                                    </div>
                                    </template>
                                </iq-card>

                                <b-collapse id="jobCat-1" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                    <!-- BOBOT DOCKING JOB -->
                                    <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                                        <template v-slot:body >
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                                            <div class="text-right">
                                            <h2 class="mb-0"><span class="counter">100%</span></h2>
                                            <h5 class="">Persentase Docking Job</h5>
                                            </div>
                                        </div>
                                        </template>
                                    </iq-card>
                                    <!-- BOBOT DOCKING JOB END -->
                                    <div class="accordion" role="tablist">
                                        <b-card no-body >
                                        <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-1-1 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Up Slip <strong class="pull-right">BOBOT PEKERJAAN : 50%</strong></b-button>
                                        </b-card-header>
                                        <b-collapse id="job-1-1" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                            <tbody>
                                                <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">50%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                </td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                                </tr>
                                                <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                    Check water tubes/plates and clean/free from clogged.<br>
                                                    Check  from any corrosion <br>
                                                    Check  zink anodes,  replace when necessary<br>
                                                    Check  leakage after assembly
                                                </td>
                                                </tr>
                                                <tr>
                                                <th class="align-top">Internal Man Power</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                    <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                    <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                    <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                                </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                            <fieldset class="form-group border p-3">
                                            <legend class="w-auto px-2 h6 text-primary">Kontraktor 1</legend>
                                            <table class="table table-striped mt-2">
                                                <tbody>
                                                <tr>
                                                    <th width="20%">Nama Kontraktor</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">PT. Tavia Digital Solusi</td>
                                                    <th width="20%">Executive Engineer</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Penawaran</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Rp.300.000.000,-</td>
                                                    <th width="20%">Penawaran Final</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Rp. 28.000.000,-</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Note</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Note Here</td>
                                                    <th width="20%">Attachment</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    </td>                                                          
                                                </tr>
                                                </tbody>
                                            </table>
                                            </fieldset>
                                            <b-alert :show="true" variant="success">
                                            <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                            </div>
                                            <div class="iq-alert-text">General Overhaul ME (P/S) <b>FINISH</b></div>
                                            </b-alert>
                                        </b-collapse>
                                        </b-card>
                                        <b-card no-body >
                                        <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-1-2 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Down Slip <strong class="pull-right">BOBOT PEKERJAAN : 50%</strong></b-button>
                                        </b-card-header>
                                        <b-collapse id="job-1-2" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                            <tbody>
                                                <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">50%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                </td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                                </tr>
                                                <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                    Check water tubes/plates and clean/free from clogged.<br>
                                                    Check  from any corrosion <br>
                                                    Check  zink anodes,  replace when necessary<br>
                                                    Check  leakage after assembly
                                                </td>
                                                </tr>
                                                <tr>
                                                <th class="align-top">Internal Man Power</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                    <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                    <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                    <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                                </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                            <fieldset class="form-group border p-3">
                                                <legend class="w-auto px-2 h6 text-primary">Kontraktor 1</legend>
                                                <table class="table table-striped mt-2">
                                                <tbody>
                                                    <tr>
                                                    <th width="20%">Nama Kontraktor</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">PT. Tavia Digital Solusi</td>
                                                    <th width="20%">Executive Engineer</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                    </tr>
                                                    <tr>
                                                    <th width="20%">Penawaran</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Rp.300.000.000,-</td>
                                                    <th width="20%">Penawaran Final</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Rp. 28.000.000,-</td>
                                                    </tr>
                                                    <tr>
                                                    <th width="20%">Note</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Note Here</td>
                                                    <th width="20%">Attachment</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                        <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                        <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                        <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </fieldset>
                                            <b-alert :show="true" variant="success">
                                            <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                            </div>
                                            <div class="iq-alert-text">General Overhaul ME (P/S) <b>FINISH</b></div>
                                            </b-alert>
                                        </b-collapse>
                                        </b-card>
                                    </div>
                                    </b-card-body>
                                </b-collapse>
                                </b-card>
                            </div>
                            <!-- DOCKING JOB END -->
                            <!-- DOCKING JOB -->
                            <div class="accordion" role="tablist">
                                <b-card no-body>
                                <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-success">
                                    <template v-slot:body >
                                    <div class="d-flex align-items-center justify-content-between">
                                        <b-button variant="primary" class="mr-1" v-b-toggle.jobCat-2><i class="fa fa-chevron-circle-down"></i>Click, Job Detail</b-button>
                                        <h5 class="mb-0">SAND BLASTING - ON PROGRESS</h5>
                                        <div class="text-right"><strong class="pull-right">BOBOT PEKERJAAN : 50%</strong></div>
                                    </div>
                                    </template>
                                </iq-card>

                                <b-collapse id="jobCat-2" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                    <!-- BOBOT DOCKING JOB -->
                                    <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                                        <template v-slot:body >
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                                            <div class="text-right">
                                            <h2 class="mb-0"><span class="counter">100%</span></h2>
                                            <h5 class="">Persentase Docking Job</h5>
                                            </div>
                                        </div>
                                        </template>
                                    </iq-card>
                                    <!-- BOBOT DOCKING JOB END -->
                                    <div class="accordion" role="tablist">
                                        <b-card no-body >
                                        <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-2-1 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Sand blasting lunas<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                        </b-card-header>
                                        <b-collapse id="job-2-1" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                            <tbody>
                                                <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">50%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                </td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                                </tr>
                                                <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                    Check water tubes/plates and clean/free from clogged.<br>
                                                    Check  from any corrosion <br>
                                                    Check  zink anodes,  replace when necessary<br>
                                                    Check  leakage after assembly
                                                </td>
                                                </tr>
                                                <tr>
                                                <th class="align-top">Internal Man Power</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                    <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                    <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                    <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                                </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                            <fieldset class="form-group border p-3">
                                            <legend class="w-auto px-2 h6 text-primary">Kontraktor 1</legend>
                                            <table class="table table-striped mt-2">
                                                <tbody>
                                                <tr>
                                                    <th width="20%">Nama Kontraktor</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">PT. Tavia Digital Solusi</td>
                                                    <th width="20%">Executive Engineer</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Penawaran</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Rp.300.000.000,-</td>
                                                    <th width="20%">Penawaran Final</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Rp. 28.000.000,-</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Note</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Note Here</td>
                                                    <th width="20%">Attachment</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </fieldset>
                                            <b-alert :show="true" variant="success">
                                            <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                            </div>
                                            <div class="iq-alert-text">General Overhaul ME (P/S) <b>FINISH</b></div>
                                            </b-alert>
                                        </b-collapse>
                                        </b-card>
                                        <b-card no-body >
                                        <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-2-2 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Sand blasting deck dan bulwark<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                        </b-card-header>
                                        <b-collapse id="job-2-2" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                            <tbody>
                                                <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">50%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                </td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                                </tr>
                                                <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                    Check water tubes/plates and clean/free from clogged.<br>
                                                    Check from any corrosion <br>
                                                    Check zink anodes,  replace when necessary<br>
                                                    Check leakage after assembly
                                                </td>
                                                </tr>
                                                <tr>
                                                <th class="align-top">Internal Man Power</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                    <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                    <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                    <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                                </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                            <fieldset class="form-group border p-3">
                                            <legend class="w-auto px-2 h6 text-primary">Kontraktor 1</legend>
                                            <table class="table table-striped mt-2">
                                                <tbody>
                                                <tr>
                                                    <th width="20%">Nama Kontraktor</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">PT. Tavia Digital Solusi</td>
                                                    <th width="20%">Executive Engineer</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Penawaran</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Rp.300.000.000,-</td>
                                                    <th width="20%">Penawaran Final</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Rp. 28.000.000,-</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Note</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Note Here</td>
                                                    <th width="20%">Attachment</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </fieldset>
                                            <b-alert :show="true" variant="success">
                                            <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                            </div>
                                            <div class="iq-alert-text">General Overhaul ME (P/S) <b>FINISH</b></div>
                                            </b-alert>
                                        </b-collapse>
                                        </b-card>
                                    </div>
                                    </b-card-body>
                                </b-collapse>
                                </b-card>
                            </div>
                            <!-- DOCKING JOB END -->
                            <!-- DOCKING JOB -->
                            <div class="accordion-jobCat-3" role="tablist">
                                <b-card no-body>
                                <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-success">
                                    <template v-slot:body >
                                    <div class="d-flex align-items-center justify-content-between">
                                        <b-button variant="primary" class="mr-1" v-b-toggle.jobCat-3><i class="fa fa-chevron-circle-down"></i>Click, Job Detail</b-button>
                                        <h5 class="mb-0">MACHINERY EQUIPMENT</h5>
                                        <div class="text-right"><strong class="pull-right">BOBOT PEKERJAAN : 50%</strong></div>
                                    </div>
                                    </template>
                                </iq-card>

                                <b-collapse id="jobCat-3" accordion="my-accordion-jobCat-3" role="tabpanel">
                                    <b-card-body>
                                    <!-- BOBOT DOCKING JOB -->
                                    <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                                        <template v-slot:body >
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                                            <div class="text-right">
                                            <h2 class="mb-0"><span class="counter">100%</span></h2>
                                            <h5 class="">Persentase Docking Job</h5>
                                            </div>
                                        </div>
                                        </template>
                                    </iq-card>
                                    <!-- BOBOT DOCKING JOB END -->
                                    <div class="accordion" role="tablist">
                                        <b-card no-body >
                                        <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-3-1 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> General Overhaul ME (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 30%</strong></b-button>
                                        </b-card-header>
                                        <b-collapse id="job-3-1" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                            <tbody>
                                                <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">30%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                </td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                                </tr>
                                                <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                    Check water tubes/plates and clean/free from clogged.<br>
                                                    Check  from any corrosion <br>
                                                    Check  zink anodes,  replace when necessary<br>
                                                    Check  leakage after assembly
                                                </td>
                                                </tr>
                                                <tr>
                                                <th class="align-top">Internal Man Power</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                    <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                    <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                    <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                                </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                            <fieldset class="form-group border p-3">
                                            <legend class="w-auto px-2 h6 text-primary">Kontraktor 1</legend>
                                            <table class="table table-striped mt-2">
                                                <tbody>
                                                <tr>
                                                    <th width="20%">Nama Kontraktor</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">PT. Tavia Digital Solusi</td>
                                                    <th width="20%">Executive Engineer</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Penawaran</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Rp.300.000.000,-</td>
                                                    <th width="20%">Penawaran Final</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Rp. 28.000.000,-</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Note</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Note Here</td>
                                                    <th width="20%">Attachment</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </fieldset>
                                            <fieldset class="form-group border p-3">
                                            <legend class="w-auto px-2 h6 text-primary">Daily Progress</legend>
                                            <ul class="iq-timeline" style="height:500px">
                                                <li>
                                                    <div class="timeline-dots border-success"></div>
                                                    <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                                    <small>24 November 2019 15:45</small>
                                                    <div class="d-inline-block w-100">
                                                    <p>Melakukan inspeksi pada kerusakan</p>
                                                    <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                                    </ul>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="timeline-dots border-success"></div>
                                                    <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                                    <small>23 November 2019 21:25</small>
                                                    <div class="d-inline-block w-100">
                                                    <p>Barang tiba di galangan</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="timeline-dots border-success"></div>
                                                    <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                                    <small>22 November 2019 10:12</small>
                                                    <div class="d-inline-block w-100">
                                                    <p>Menunggu sparepart tiba</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="timeline-dots border-success"></div>
                                                    <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                                    <small>21 November 2019 11:21</small>
                                                    <div class="d-inline-block w-100">
                                                    <p>Melengkapi dokumen perbaikan</p>
                                                    <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                            </fieldset>
                                            <b-alert :show="true" variant="success">
                                            <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                            </div>
                                            <div class="iq-alert-text">General Overhaul ME (P/S) <b>FINISH</b></div>
                                            </b-alert>
                                        </b-collapse>
                                        </b-card>
                                        <b-card no-body >
                                        <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-3-2 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Kalibrasi injector (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                        </b-card-header>
                                        <b-collapse id="job-3-2" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                            <tbody>
                                                <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">10%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                </td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                                </tr>
                                                <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                    Check water tubes/plates and clean/free from clogged.<br>
                                                    Check  from any corrosion <br>
                                                    Check  zink anodes,  replace when necessary<br>
                                                    Check  leakage after assembly
                                                </td>
                                                </tr>
                                                <tr>
                                                <th class="align-top">Internal Man Power</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                    <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                    <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                    <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                                </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                            <fieldset class="form-group border p-3">
                                            <legend class="w-auto px-2 h6 text-primary">Daily Progress</legend>
                                            <ul class="iq-timeline" style="height:500px">
                                                <li>
                                                    <div class="timeline-dots border-success"></div>
                                                    <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                                    <small>24 November 2019 15:45</small>
                                                    <div class="d-inline-block w-100">
                                                    <p>Melakukan inspeksi pada kerusakan</p>
                                                    <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                                    </ul>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="timeline-dots border-success"></div>
                                                    <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                                    <small>23 November 2019 21:25</small>
                                                    <div class="d-inline-block w-100">
                                                    <p>Barang tiba di galangan</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="timeline-dots border-success"></div>
                                                    <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                                    <small>22 November 2019 10:12</small>
                                                    <div class="d-inline-block w-100">
                                                    <p>Menunggu sparepart tiba</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="timeline-dots border-success"></div>
                                                    <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                                    <small>21 November 2019 11:21</small>
                                                    <div class="d-inline-block w-100">
                                                    <p>Melengkapi dokumen perbaikan</p>
                                                    <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                            </fieldset>
                                            <b-alert :show="true" variant="success" class="mt-3">
                                            <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                            </div>
                                            <div class="iq-alert-text">General Overhaul ME (P/S) <b>FINISH</b></div>
                                            </b-alert>
                                        </b-collapse>
                                        </b-card>
                                        <b-card no-body >
                                        <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-3-3 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Service/ganti baru dynamo starter (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                        </b-card-header>
                                        <b-collapse id="job-3-3" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                            <tbody>
                                                <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">10%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                </td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                                </tr>
                                                <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                    Check water tubes/plates and clean/free from clogged.<br>
                                                    Check  from any corrosion <br>
                                                    Check  zink anodes,  replace when necessary<br>
                                                    Check  leakage after assembly
                                                </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                            <fieldset class="form-group border p-3">
                                            <legend class="w-auto px-2 h6 text-primary">Kontraktor 1</legend>
                                            <table class="table table-striped mt-2">
                                                <tbody>
                                                <tr>
                                                    <th width="20%">Nama Kontraktor</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">PT. Tavia Digital Solusi</td>
                                                    <th width="20%">Executive Engineer</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Penawaran</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Rp.300.000.000,-</td>
                                                    <th width="20%">Penawaran Final</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Rp. 28.000.000,-</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Note</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Note Here</td>
                                                    <th width="20%">Attachment</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </fieldset>
                                            <fieldset class="form-group border p-3">
                                            <legend class="w-auto px-2 h6 text-primary">Daily Progress</legend>
                                            <ul class="iq-timeline" style="height:500px">
                                                <li>
                                                    <div class="timeline-dots border-success"></div>
                                                    <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                                    <small>24 November 2019 15:45</small>
                                                    <div class="d-inline-block w-100">
                                                    <p>Melakukan inspeksi pada kerusakan</p>
                                                    <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                                    </ul>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="timeline-dots border-success"></div>
                                                    <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                                    <small>23 November 2019 21:25</small>
                                                    <div class="d-inline-block w-100">
                                                    <p>Barang tiba di galangan</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="timeline-dots border-success"></div>
                                                    <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                                    <small>22 November 2019 10:12</small>
                                                    <div class="d-inline-block w-100">
                                                    <p>Menunggu sparepart tiba</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="timeline-dots border-success"></div>
                                                    <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                                    <small>21 November 2019 11:21</small>
                                                    <div class="d-inline-block w-100">
                                                    <p>Melengkapi dokumen perbaikan</p>
                                                    <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                            </fieldset>
                                            <b-alert :show="true" variant="success" class="mt-3">
                                            <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                            </div>
                                            <div class="iq-alert-text">General Overhaul ME (P/S) <b>FINISH</b></div>
                                            </b-alert>
                                        </b-collapse>
                                        </b-card>
                                        <b-card no-body >
                                        <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-3-4 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Service/ganti baru alternator (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                        </b-card-header>
                                        <b-collapse id="job-3-4" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                            <tbody>
                                                <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">10%<td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                </td>
                                                </tr>
                                                <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                                </tr>
                                                <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                    Check water tubes/plates and clean/free from clogged.<br>
                                                    Check  from any corrosion <br>
                                                    Check  zink anodes,  replace when necessary<br>
                                                    Check  leakage after assembly
                                                </td>
                                                </tr>
                                            </tbody>
                                            </table>
                                            <table class="table table-striped mt-2">
                                            <tbody>
                                                <fieldset class="form-group border p-3">
                                                    <legend class="w-auto px-2 h6 text-primary">Kontraktor 1</legend>
                                                    <tr>
                                                    <th width="20%">Nama Kontraktor</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">PT. Tavia Digital Solusi</td>
                                                    <th width="20%">Executive Engineer</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                    </tr>
                                                    <tr>
                                                    <th width="20%">Penawaran</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Rp.300.000.000,-</td>
                                                    <th width="20%">Penawaran Final</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Rp. 28.000.000,-</td>
                                                    </tr>
                                                    <tr>
                                                    <th width="20%">Note</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Note Here</td>
                                                    <th width="20%">Attachment</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        </ul>
                                                    </td>
                                                    </tr>
                                                </fieldset>
                                                </tbody>
                                            </table>
                                            <fieldset class="form-group border p-3">
                                            <legend class="w-auto px-2 h6 text-primary">Daily Progress</legend>
                                            <ul class="iq-timeline" style="height:500px">
                                                <li>
                                                    <div class="timeline-dots border-success"></div>
                                                    <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                                    <small>24 November 2019 15:45</small>
                                                    <div class="d-inline-block w-100">
                                                    <p>Melakukan inspeksi pada kerusakan</p>
                                                    <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                                    </ul>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="timeline-dots border-success"></div>
                                                    <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                                    <small>23 November 2019 21:25</small>
                                                    <div class="d-inline-block w-100">
                                                    <p>Barang tiba di galangan</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="timeline-dots border-success"></div>
                                                    <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                                    <small>22 November 2019 10:12</small>
                                                    <div class="d-inline-block w-100">
                                                    <p>Menunggu sparepart tiba</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="timeline-dots border-success"></div>
                                                    <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                                    <small>21 November 2019 11:21</small>
                                                    <div class="d-inline-block w-100">
                                                    <p>Melengkapi dokumen perbaikan</p>
                                                    <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                            </fieldset>
                                            <b-alert :show="true" variant="success" class="mt-3">
                                            <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                            </div>
                                            <div class="iq-alert-text">General Overhaul ME (P/S) <b>FINISH</b></div>
                                            </b-alert>
                                        </b-collapse>
                                        </b-card>
                                    </div>
                                    </b-card-body>
                                </b-collapse>
                                </b-card>
                            </div>
                            <!-- DOCKING JOB END -->
                        </template>
                    </iq-card>
                </b-col>
            </b-row>
        </form>
    </b-container>
</template>
  <script>
  import { xray } from '../../config/pluginInit'
  export default {
    name: 'Sea Trial',
    mounted () {
      xray.index()
    },
    data () {
      return {
        selectedMerkEngine: null,
        optionsMerkEngine: [
          { value: '1', text: 'YANMAR 4TNV 106T GGE' },
          { value: '2', text: 'MITSUBISHI S6R2 MPTK2/3L' },
          { value: '3', text: 'YANMAR 12LAK STE2' }
        ],
      }
    }
  }
  </script>