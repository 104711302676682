<template>
  <b-container fluid>
    <b-row>
      <b-col md="8">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Master Goods</h4>
          </template>
          <template v-slot:body>
            <table class="table table-striped">
              <thead>
              <tr>
                <th width="5%">No</th>
                <th>Goods Code</th>
                <th>Goods Name</th>
                <th>Images</th>
                <th>Category</th>
                <th>Units</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </template>
        </iq-card>
      </b-col>
      <b-col md="4">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Add Master Goods</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12">
                <b-form-group label="Category" label-for="serial">
                  <v-select label="text" :options="category" :reduce="(type) => type.value" placeholder="select category"></v-select>
                </b-form-group>
                <b-form-group label="Images / File">
                  <b-form-file id="fileSupport" placeholder="Choose a file" accept="application/pdf"></b-form-file>
                </b-form-group>
                <b-form-group label="Goods Code">
                  <b-form-input id="fleet_name" class="w-100" type="text" placeholder="Goods Code..."></b-form-input>
                </b-form-group>
                <b-form-group label="Goods Name">
                  <b-form-input id="fleet_name" class="w-100" type="text" placeholder="Goods Name..."></b-form-input>
                </b-form-group>
                <b-form-group label="Units">
                  <v-select label="text" :options="units" :reduce="(type) => type.value" placeholder="select units"></v-select>
                </b-form-group>
                <b-form-group label="Description">
                  <b-form-textarea id="exampleFormControlTextarea1" rows="3"></b-form-textarea>
                </b-form-group>
                <b-form-group label="Assign to All Fleet">
                  <b-form-checkbox name="assign-fleet" v-model="form.assignFleet" switch inline>
                    <template v-if="form.assignFleet === true || form.assignFleet === 'true'">Yes</template>
                    <template v-else>No</template>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group label="Choose Vehicle Type" label-for="category_for" class="bg-light p-3" v-if="form.assignFleet === true || form.assignFleet === 'true'">
                  <v-multiselect
                    v-model="form.vehicleType"
                    placeholder="Search categories"
                    label="name"
                    track-by="id"
                    :options="fleet_types"
                    :multiple="true"
                  >
                    <span slot="noResult">Category not found.</span>
                  </v-multiselect>
                </b-form-group>
                <b-form-group label="Status">
                  <b-form-checkbox name="check-button" v-model="form.status" switch inline>
                    Active
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="12" class="mt-4 text-right">
                <hr/>
                <b-button type="submit" variant="primary">Save</b-button>
                <b-button type="submit" variant="none" class="iq-bg-danger ml-3">Cancel</b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import {
  fleetsActions
} from '@src/Utils/helper'

export default {
  name: 'ComponentGoods',
  mounted () {
    xray.index()
    this.fetchFleetTypes()
  },
  data () {
    return {
      fleet_types: [],
      category: [
        { value: '-', text: 'Oil Machine' }
      ],
      units: [
        { value: 'pcs', text: 'Pcs' },
        { value: 'ltr', text: 'Liters' },
        { value: 'box', text: 'Box' }
      ],
      form: {
        images: '',
        category: '',
        go0dsCode: '',
        goodsName: '',
        units: '',
        description: '',
        status: true,
        assignFleet: true,
        vehicleType: []
      }
    }
  },
  methods: {
    ...fleetsActions,
    async fetchFleetTypes () {
      let res = await this.getFleetTypes()
      this.fleet_types = res.data
    }
  }
}
</script>
