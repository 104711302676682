var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', {
    staticClass: "mb-0"
  }, [_vm._v("Reset Password")]), _c('p', [_vm._v(" Enter your email address and we'll send you an email with instructions to reset your password. ")]), _c('form', {
    staticClass: "mt-5",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.doResetPassword();
      }
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "exampleInputEmail1"
    }
  }, [_vm._v("Email address")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.email,
      expression: "email"
    }],
    staticClass: "form-control mb-0",
    attrs: {
      "type": "email",
      "id": "exampleInputEmail1",
      "aria-describedby": "emailHelp",
      "placeholder": "Enter email"
    },
    domProps: {
      "value": _vm.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.email = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('button', {
    staticClass: "btn btn-primary float-right",
    attrs: {
      "type": "submit"
    }
  }, [!_vm.loading ? [_vm._v(" Reset Password ")] : [_c('span', {
    staticClass: "spinner-border spinner-border-sm",
    attrs: {
      "role": "status",
      "aria-hidden": "true"
    }
  }), _c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading...")])]], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }