var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Grid options")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("See how aspects of the Bootstrap grid system work across multiple devices with a handy table..")]), _c('table', {
          staticClass: "table table-bordered table-striped"
        }, [_c('thead', [_c('tr', [_c('th'), _c('th', {
          staticClass: "text-center"
        }, [_vm._v(" Extra small"), _c('br'), _c('small', [_vm._v("<576px")])]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v(" Small"), _c('br'), _c('small', [_vm._v("≥576px")])]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v(" Medium"), _c('br'), _c('small', [_vm._v("≥768px")])]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v(" Large"), _c('br'), _c('small', [_vm._v("≥992px")])]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v(" Extra large"), _c('br'), _c('small', [_vm._v("≥1200px")])])])]), _c('tbody', [_c('tr', [_c('th', {
          staticClass: "text-nowrap text-left",
          attrs: {
            "scope": "row"
          }
        }, [_vm._v("Max container width")]), _c('td', [_vm._v("None (auto)")]), _c('td', [_vm._v("540px")]), _c('td', [_vm._v("720px")]), _c('td', [_vm._v("960px")]), _c('td', [_vm._v("1140px")])]), _c('tr', [_c('th', {
          staticClass: "text-nowrap text-left",
          attrs: {
            "scope": "row"
          }
        }, [_vm._v("Prop")]), _c('td', [_c('code', [_vm._v("cols=\"*\"")])]), _c('td', [_c('code', [_vm._v("sm=\"*\"")])]), _c('td', [_c('code', [_vm._v("md=\"*\"")])]), _c('td', [_c('code', [_vm._v("lg=\"*\"")])]), _c('td', [_c('code', [_vm._v("xl=\"*\"")])])]), _c('tr', [_c('th', {
          staticClass: "text-nowrap text-left",
          attrs: {
            "scope": "row"
          }
        }, [_vm._v("# of columns")]), _c('td', {
          attrs: {
            "colspan": "5"
          }
        }, [_vm._v("12")])]), _c('tr', [_c('th', {
          staticClass: "text-nowrap text-left",
          attrs: {
            "scope": "row"
          }
        }, [_vm._v("Gutter width")]), _c('td', {
          attrs: {
            "colspan": "5"
          }
        }, [_vm._v("30px (15px on each side of a column)")])]), _c('tr', [_c('th', {
          staticClass: "text-nowrap text-left",
          attrs: {
            "scope": "row"
          }
        }, [_vm._v("Nestable")]), _c('td', {
          attrs: {
            "colspan": "5"
          }
        }, [_vm._v("Yes")])]), _c('tr', [_c('th', {
          staticClass: "text-nowrap text-left",
          attrs: {
            "scope": "row"
          }
        }, [_vm._v("Offset")]), _c('td', [_c('code', [_vm._v("offset=\"*\"")])]), _c('td', [_c('code', [_vm._v("offset-sm=\"*\"")])]), _c('td', [_c('code', [_vm._v("offset-md=\"*\"")])]), _c('td', [_c('code', [_vm._v("offset-lg=\"*\"")])]), _c('td', [_c('code', [_vm._v("offset-xl=\"*\"")])])]), _c('tr', [_c('th', {
          staticClass: "text-nowrap text-left",
          attrs: {
            "scope": "row"
          }
        }, [_vm._v("Column ordering")]), _c('td', [_c('code', [_vm._v("order=\"*\"")])]), _c('td', [_c('code', [_vm._v("order-sm=\"*\"")])]), _c('td', [_c('code', [_vm._v("order-md=\"*\"")])]), _c('td', [_c('code', [_vm._v("order-lg=\"*\"")])]), _c('td', [_c('code', [_vm._v("order-xl=\"*\"")])])])])])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Equal-width")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-2",
            modifiers: {
              "collapse-2": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("For example, here are two grid layouts that apply to every device and viewport, from "), _c('code', [_vm._v("xs")]), _vm._v(" to "), _c('code', [_vm._v("xl")]), _vm._v(". Add any number of unit-less classes for each breakpoint you need and every column will be the same width.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-2"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "grid-2"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<div class=\"iq-example-row\">\n  <b-container fluid>\n    <!-- Stack the columns on mobile by making one full-width and the other half-width -->\n    <b-row class=\"mb-3\">\n      <b-col cols=\"12\" md=\"8\"> cols=\"12\" md=\"8\"</b-col>\n      <b-col cols=\"6\" md=\"4\"> cols=\"6\" md=\"4\"</b-col>\n    </b-row>\n    <!-- Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop -->\n    <b-row class=\"mb-3\">\n      <b-col cols=\"6\" md=\"4\"> cols=\"6\" md=\"4\"</b-col>\n      <b-col cols=\"6\" md=\"4\"> cols=\"6\" md=\"4\"</b-col>\n      <b-col cols=\"6\" md=\"4\"> cols=\"6\" md=\"4\"</b-col>\n    </b-row>\n    <!-- Columns are always 50% wide, on mobile and desktop -->\n    <b-row>\n      <b-col cols=\"6\"> cols=\"6\"</b-col>\n      <b-col cols=\"6\"> cols=\"6\"</b-col>\n    </b-row>\n  </b-container>\n</div>\n")])])])])]), _c('div', {
          staticClass: "iq-example-row"
        }, [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "8"
          }
        }, [_vm._v(" cols=\"12\" md=\"8\"")]), _c('b-col', {
          attrs: {
            "cols": "6",
            "md": "4"
          }
        }, [_vm._v(" cols=\"6\" md=\"4\"")])], 1), _c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "6",
            "md": "4"
          }
        }, [_vm._v(" cols=\"6\" md=\"4\"")]), _c('b-col', {
          attrs: {
            "cols": "6",
            "md": "4"
          }
        }, [_vm._v(" cols=\"6\" md=\"4\"")]), _c('b-col', {
          attrs: {
            "cols": "6",
            "md": "4"
          }
        }, [_vm._v(" cols=\"6\" md=\"4\"")])], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_vm._v(" cols=\"6\"")]), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_vm._v(" cols=\"6\"")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Setting one column width")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-3",
            modifiers: {
              "collapse-3": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Auto-layout for flexbox grid columns also means you can set the width of one column and have the sibling columns automatically resize around it. You may use predefined grid classes (as shown below), grid mixins, or inline widths. Note that the other columns will resize no matter the width of the center column.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-3"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "grid-3"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<div class=\"iq-example-row\">\n  <b-container fluid>\n    <b-row class=\"mb-3\">\n      <b-col>1 of 3</b-col>\n      <b-col cols=\"6\">2 of 3 (wider)</b-col>\n      <b-col>3 of 3</b-col>\n    </b-row>\n    <b-row>\n      <b-col>1 of 3</b-col>\n      <b-col cols=\"5\">2 of 3 (wider)</b-col>\n      <b-col>3 of 3</b-col>\n    </b-row>\n  </b-container>\n</div>\n")])])])])]), _c('div', {
          staticClass: "iq-example-row"
        }, [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', [_vm._v("1 of 3")]), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_vm._v("2 of 3 (wider)")]), _c('b-col', [_vm._v("3 of 3")])], 1), _c('b-row', [_c('b-col', [_vm._v("1 of 3")]), _c('b-col', {
          attrs: {
            "cols": "5"
          }
        }, [_vm._v("2 of 3 (wider)")]), _c('b-col', [_vm._v("3 of 3")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Variable width content")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-4",
            modifiers: {
              "collapse-4": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Use "), _c('code', [_vm._v("col-{breakpoint}-auto")]), _vm._v(" classes to size columns based on the natural width of their content.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-4"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "grid-4"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<div class=\"iq-example-row\">\n  <b-container fluid>\n    <b-row class=\"justify-content-md-center mb-3\">\n      <b-col col lg=\"2\">1 of 3</b-col>\n      <b-col md=\"auto\">Variable width content</b-col>\n      <b-col col lg=\"2\">3 of 3</b-col>\n    </b-row>\n    <b-row>\n      <b-col>1 of 3</b-col>\n      <b-col md=\"auto\">Variable width content</b-col>\n      <b-col col lg=\"2\">3 of 3</b-col>\n    </b-row>\n  </b-container>\n</div>\n")])])])])]), _c('div', {
          staticClass: "iq-example-row"
        }, [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', {
          staticClass: "justify-content-md-center mb-3"
        }, [_c('b-col', {
          attrs: {
            "col": "",
            "lg": "2"
          }
        }, [_vm._v("1 of 3")]), _c('b-col', {
          attrs: {
            "md": "auto"
          }
        }, [_vm._v("Variable width content")]), _c('b-col', {
          attrs: {
            "col": "",
            "lg": "2"
          }
        }, [_vm._v("3 of 3")])], 1), _c('b-row', [_c('b-col', [_vm._v("1 of 3")]), _c('b-col', {
          attrs: {
            "md": "auto"
          }
        }, [_vm._v("Variable width content")]), _c('b-col', {
          attrs: {
            "col": "",
            "lg": "2"
          }
        }, [_vm._v("3 of 3")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Equal-width multi-row")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-5",
            modifiers: {
              "collapse-5": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Create equal-width columns that span multiple rows by inserting a "), _c('code', [_vm._v(".w-100")]), _vm._v(" where you want the columns to break to a new line. Make the breaks responsive by mixing the "), _c('code', [_vm._v(".w-100")]), _vm._v(" with some "), _c('a', {
          attrs: {
            "href": "/docs/4.3/utilities/display/"
          }
        }, [_vm._v("responsive display utilities")]), _vm._v(".")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-5"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "grid-5"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<div class=\"iq-example-row\">\n  <b-container fluid>\n    <b-row>\n      <b-col>col</b-col>\n      <b-col>col</b-col>\n      <div class=\"w-100\"></div>\n      <b-col>col</b-col>\n      <b-col>col</b-col>\n    </b-row>\n  </b-container>\n</div>\n")])])])])]), _c('div', {
          staticClass: "iq-example-row"
        }, [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', [_c('b-col', [_vm._v("col")]), _c('b-col', [_vm._v("col")]), _c('div', {
          staticClass: "w-100"
        }), _c('b-col', [_vm._v("col")]), _c('b-col', [_vm._v("col")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Responsive classes")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-6",
            modifiers: {
              "collapse-6": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Bootstrap’s grid includes five tiers of predefined classes for building complex responsive layouts. Customize the size of your columns on extra small, small, medium, large, or extra large devices however you see fit.")]), _c('h4', {
          staticClass: "card-title"
        }, [_vm._v("All breakpoints")]), _c('p', [_vm._v("For grids that are the same from the smallest of devices to the largest, use the "), _c('code', [_vm._v(".col")]), _vm._v(" and "), _c('code', [_vm._v(".col-*")]), _vm._v(" classes. Specify a numbered class when you need a particularly sized column; otherwise, feel free to stick to "), _c('code', [_vm._v(".col")]), _vm._v(".")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-6"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "grid-6"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<div class=\"iq-example-row\">\n  <b-container fluid>\n    <b-row class=\"mb-3\">\n      <b-col>col</b-col>\n      <b-col>col</b-col>\n      <b-col>col</b-col>\n      <b-col>col</b-col>\n    </b-row>\n    <b-row>\n      <b-col cols=\"8\">cols=\"8\"</b-col>\n      <b-col cols=\"4\">cols=\"4\"</b-col>\n    </b-row>\n  </b-container>\n</div>\n")])])])])]), _c('div', {
          staticClass: "iq-example-row"
        }, [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', [_vm._v("col")]), _c('b-col', [_vm._v("col")]), _c('b-col', [_vm._v("col")]), _c('b-col', [_vm._v("col")])], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "8"
          }
        }, [_vm._v("cols=\"8\"")]), _c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_vm._v("cols=\"4\"")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Stacked to horizontal")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-7",
            modifiers: {
              "collapse-7": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Using a single set of "), _c('code', [_vm._v(".col-sm-*")]), _vm._v(" classes, you can create a basic grid system that starts out stacked and becomes horizontal at the small breakpoint ("), _c('code', [_vm._v("sm")]), _vm._v(").")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-7"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "grid-7"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<div class=\"iq-example-row\">\n  <b-container fluid>\n    <b-row class=\"mb-3\">\n      <b-col sm=\"8\">sm=\"8\"</b-col>\n      <b-col sm=\"4\">sm=\"4\"</b-col>\n    </b-row>\n    <b-row>\n      <b-col sm>sm</b-col>\n      <b-col sm>sm</b-col>\n      <b-col sm>sm</b-col>\n    </b-row>\n  </b-container>\n</div>\n")])])])])]), _c('div', {
          staticClass: "iq-example-row"
        }, [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "sm": "8"
          }
        }, [_vm._v("sm=\"8\"")]), _c('b-col', {
          attrs: {
            "sm": "4"
          }
        }, [_vm._v("sm=\"4\"")])], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "sm": ""
          }
        }, [_vm._v("sm")]), _c('b-col', {
          attrs: {
            "sm": ""
          }
        }, [_vm._v("sm")]), _c('b-col', {
          attrs: {
            "sm": ""
          }
        }, [_vm._v("sm")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Mix and match")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-8",
            modifiers: {
              "collapse-8": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Don’t want your columns to simply stack in some grid tiers? Use a combination of different classes for each tier as needed. See the example below for a better idea of how it all works.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-8"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "grid-8"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<div class=\"iq-example-row\">\n  <b-container fluid>\n    <!-- Stack the columns on mobile by making one full-width and the other half-width -->\n    <b-row class=\"mb-3\">\n      <b-col cols=\"12\" md=\"8\">cols=\"12\" md=\"8\"</b-col>\n      <b-col cols=\"6\" md=\"4\">cols=\"6\" md=\"4\"</b-col>\n    </b-row>\n    <!-- Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop -->\n    <b-row class=\"mb-3\">\n      <b-col cols=\"6\" md=\"4\">cols=\"6\" md=\"4\"</b-col>\n      <b-col cols=\"6\" md=\"4\">cols=\"6\" md=\"4\"</b-col>\n      <b-col cols=\"6\" md=\"4\">cols=\"6\" md=\"4\"</b-col>\n    </b-row>\n    <!-- Columns are always 50% wide, on mobile and desktop -->\n    <b-row>\n      <b-col cols=\"6\">cols=\"6\"</b-col>\n      <b-col cols=\"6\">cols=\"6\"</b-col>\n    </b-row>\n  </b-container>\n</div>\n")])])])])]), _c('div', {
          staticClass: "iq-example-row"
        }, [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "8"
          }
        }, [_vm._v("cols=\"12\" md=\"8\"")]), _c('b-col', {
          attrs: {
            "cols": "6",
            "md": "4"
          }
        }, [_vm._v("cols=\"6\" md=\"4\"")])], 1), _c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "6",
            "md": "4"
          }
        }, [_vm._v("cols=\"6\" md=\"4\"")]), _c('b-col', {
          attrs: {
            "cols": "6",
            "md": "4"
          }
        }, [_vm._v("cols=\"6\" md=\"4\"")]), _c('b-col', {
          attrs: {
            "cols": "6",
            "md": "4"
          }
        }, [_vm._v("cols=\"6\" md=\"4\"")])], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_vm._v("cols=\"6\"")]), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_vm._v("cols=\"6\"")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Gutters")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-9",
            modifiers: {
              "collapse-9": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Gutters can be responsively adjusted by breakpoint-specific padding and negative margin utility classes. To change the gutters in a given row, pair a negative margin utility on the "), _c('code', [_vm._v(".row")]), _vm._v(" and matching padding utilities on the "), _c('code', [_vm._v(".col")]), _vm._v("s. The "), _c('code', [_vm._v(".container")]), _vm._v(" or "), _c('code', [_vm._v(".container-fluid")]), _vm._v(" parent may need to be adjusted too to avoid unwanted overflow, using again matching padding utility.")]), _c('p', [_vm._v("Here’s an example of customizing the Bootstrap grid at the large ("), _c('code', [_vm._v("lg")]), _vm._v(") breakpoint and above. We’ve increased the "), _c('code', [_vm._v(".col")]), _vm._v(" padding with "), _c('code', [_vm._v(".px-lg-5")]), _vm._v(", counteracted that with "), _c('code', [_vm._v(".mx-lg-n5")]), _vm._v(" on the parent "), _c('code', [_vm._v(".row")]), _vm._v(" and then adjusted the "), _c('code', [_vm._v(".container")]), _vm._v(" wrapper with "), _c('code', [_vm._v(".px-lg-5")]), _vm._v(".")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-9"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "grid-9"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<div class=\"iq-example-row\">\n  <b-container fluid class=\"px-lg-5\">\n    <b-row class=\"mx-lg-n5\">\n      <b-col class=\"py-3 px-lg-5 border bg-light\">Custom column padding</b-col>\n      <b-col class=\"py-3 px-lg-5 border bg-light\">Custom column padding</b-col>\n    </b-row>\n  </b-container>\n</div>\n")])])])])]), _c('div', {
          staticClass: "iq-example-row"
        }, [_c('b-container', {
          staticClass: "px-lg-5",
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', {
          staticClass: "mx-lg-n5"
        }, [_c('b-col', {
          staticClass: "py-3 px-lg-5 border bg-light"
        }, [_vm._v("Custom column padding")]), _c('b-col', {
          staticClass: "py-3 px-lg-5 border bg-light"
        }, [_vm._v("Custom column padding")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Alignment")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-10",
            modifiers: {
              "collapse-10": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Use flexbox alignment utilities to vertically and horizontally align columns. "), _c('strong', [_vm._v("Internet Explorer 10-11 do not support vertical alignment of flex items when the flex container has a "), _c('code', [_vm._v("min-height")]), _vm._v(" as shown below.")]), _vm._v(" "), _c('a', {
          attrs: {
            "href": "https://github.com/philipwalton/flexbugs#flexbug-3"
          }
        }, [_vm._v("See Flexbugs #3 for more details.")])]), _c('h4', {
          staticClass: "mb-3"
        }, [_vm._v("Vertical alignment")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-10"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "grid-10"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<div class=\"iq-example-row iq-example-row-flex-cols\">\n  <b-container fluid>\n    <b-row align-v=\"start\">\n      <b-col>One of three columns</b-col>\n      <b-col>One of three columns</b-col>\n      <b-col>One of three columns</b-col>\n    </b-row>\n    <b-row align-v=\"center\">\n      <b-col>One of three columns</b-col>\n      <b-col>One of three columns</b-col>\n      <b-col>One of three columns</b-col>\n    </b-row>\n    <b-row align-v=\"end\">\n      <b-col>One of three columns</b-col>\n      <b-col>One of three columns</b-col>\n      <b-col>One of three columns</b-col>\n    </b-row>\n  </b-container>\n  <b-container fluid>\n    <b-row>\n      <b-col align-self=\"start\">One of three columns</b-col>\n      <b-col align-self=\"center\">One of three columns</b-col>\n      <b-col align-self=\"end\">One of three columns</b-col>\n    </b-row>\n  </b-container>\n</div>\n")])])])])]), _c('div', {
          staticClass: "iq-example-row iq-example-row-flex-cols"
        }, [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', {
          attrs: {
            "align-v": "start"
          }
        }, [_c('b-col', [_vm._v("One of three columns")]), _c('b-col', [_vm._v("One of three columns")]), _c('b-col', [_vm._v("One of three columns")])], 1), _c('b-row', {
          attrs: {
            "align-v": "center"
          }
        }, [_c('b-col', [_vm._v("One of three columns")]), _c('b-col', [_vm._v("One of three columns")]), _c('b-col', [_vm._v("One of three columns")])], 1), _c('b-row', {
          attrs: {
            "align-v": "end"
          }
        }, [_c('b-col', [_vm._v("One of three columns")]), _c('b-col', [_vm._v("One of three columns")]), _c('b-col', [_vm._v("One of three columns")])], 1)], 1), _c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "align-self": "start"
          }
        }, [_vm._v("One of three columns")]), _c('b-col', {
          attrs: {
            "align-self": "center"
          }
        }, [_vm._v("One of three columns")]), _c('b-col', {
          attrs: {
            "align-self": "end"
          }
        }, [_vm._v("One of three columns")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Horizontal alignment")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-11",
            modifiers: {
              "collapse-11": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Create equal-width columns that span multiple rows by inserting a "), _c('code', [_vm._v(".w-100")]), _vm._v(" where you want the columns to break to a new line. Make the breaks responsive by mixing the "), _c('code', [_vm._v(".w-100")]), _vm._v(" with some "), _c('a', {
          attrs: {
            "href": "/docs/4.3/utilities/display/"
          }
        }, [_vm._v("responsive display utilities")]), _vm._v(".")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-11"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "grid-11"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<div class=\"iq-example-row\">\n  <b-container fluid>\n    <b-row align-h=\"start\" class=\"mb-3\">\n      <b-col cols=\"4\">One of two columns</b-col>\n      <b-col cols=\"4\">One of two columns</b-col>\n    </b-row>\n    <b-row align-h=\"center\" class=\"mb-3\">\n      <b-col cols=\"4\">One of two columns</b-col>\n      <b-col cols=\"4\">One of two columns</b-col>\n    </b-row>\n    <b-row align-h=\"end\" class=\"mb-3\">\n      <b-col cols=\"4\">One of two columns</b-col>\n      <b-col cols=\"4\">One of two columns</b-col>\n    </b-row>\n    <b-row align-h=\"around\" class=\"mb-3\">\n      <b-col cols=\"4\">One of two columns</b-col>\n      <b-col cols=\"4\">One of two columns</b-col>\n    </b-row>\n    <b-row align-h=\"between\" class=\"mb-3\">\n      <b-col cols=\"4\">One of two columns</b-col>\n      <b-col cols=\"4\">One of two columns</b-col>\n    </b-row>\n  </b-container>\n</div>\n")])])])])]), _c('div', {
          staticClass: "iq-example-row"
        }, [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', {
          staticClass: "mb-3",
          attrs: {
            "align-h": "start"
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_vm._v("One of two columns")]), _c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_vm._v("One of two columns")])], 1), _c('b-row', {
          staticClass: "mb-3",
          attrs: {
            "align-h": "center"
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_vm._v("One of two columns")]), _c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_vm._v("One of two columns")])], 1), _c('b-row', {
          staticClass: "mb-3",
          attrs: {
            "align-h": "end"
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_vm._v("One of two columns")]), _c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_vm._v("One of two columns")])], 1), _c('b-row', {
          staticClass: "mb-3",
          attrs: {
            "align-h": "around"
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_vm._v("One of two columns")]), _c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_vm._v("One of two columns")])], 1), _c('b-row', {
          staticClass: "mb-3",
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_vm._v("One of two columns")]), _c('b-col', {
          attrs: {
            "cols": "4"
          }
        }, [_vm._v("One of two columns")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }