var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Goods In & Out")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Selecte Date Range",
            "label-for": "serial"
          }
        }, [_c('date-picker', {
          attrs: {
            "type": "datetime",
            "range": "",
            "placeholder": "Select date range",
            "format": "DD-MM-YYYY HH:mm"
          },
          model: {
            value: _vm.historiesParamsDate,
            callback: function callback($$v) {
              _vm.historiesParamsDate = $$v;
            },
            expression: "historiesParamsDate"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Choose Type of Stock",
            "label-for": "serial"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.category,
            "reduce": function reduce(type) {
              return type.value;
            },
            "placeholder": "select type of stock",
            "multiple": true
          },
          model: {
            value: _vm.typeStockSelected,
            callback: function callback($$v) {
              _vm.typeStockSelected = $$v;
            },
            expression: "typeStockSelected"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Goods",
            "label-for": "serial"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.categoryGoods,
            "reduce": function reduce(type) {
              return type.value;
            },
            "placeholder": "select category"
          },
          model: {
            value: _vm.goodsSelected,
            callback: function callback($$v) {
              _vm.goodsSelected = $$v;
            },
            expression: "goodsSelected"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-button', {
          staticClass: "mt-4",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        }), _vm._v(" Filter")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Data")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "3%"
          }
        }, [_vm._v("No")]), _c('th', [_vm._v("Letter Number")]), _c('th', [_vm._v("Date")]), _c('th', [_vm._v("Goods (Code - Name)")]), _c('th', [_vm._v("Qty")]), _c('th', [_vm._v("In Stock")]), _c('th', [_vm._v("Notes")]), _c('th', [_vm._v("System Log")]), _c('th', [_vm._v("Action")])])])])])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Add Data")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Choose Type of Stock",
            "label-for": "serial"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.category,
            "reduce": function reduce(type) {
              return type.value;
            },
            "placeholder": "select type of stock",
            "multiple": true
          },
          model: {
            value: _vm.typeStockSelected,
            callback: function callback($$v) {
              _vm.typeStockSelected = $$v;
            },
            expression: "typeStockSelected"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Letter Number"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Letter Number..."
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Date"
          }
        }, [_c('date-picker', {
          attrs: {
            "type": "date",
            "placeholder": "Select date"
          }
        })], 1), _c('hr'), _c('b-form-group', {
          attrs: {
            "label": "Goods",
            "label-for": "serial"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.categoryGoods,
            "reduce": function reduce(type) {
              return type.value;
            },
            "placeholder": "select category"
          },
          model: {
            value: _vm.goodsSelected,
            callback: function callback($$v) {
              _vm.goodsSelected = $$v;
            },
            expression: "goodsSelected"
          }
        })], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Qty",
            "label-for": "serial"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "number",
            "placeholder": "Qty..."
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "In Stock",
            "label-for": "serial"
          }
        }, [_c('strong', [_vm._v("50")])])], 1)], 1), _c('b-form-group', {
          attrs: {
            "label": "Notes",
            "label-for": "serial"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "2"
          }
        }), _c('small', {
          staticStyle: {
            "float": "left"
          }
        }, [_c('i', {
          staticClass: "fa fa-warning"
        }), _vm._v(" Max 500 Character")])], 1)], 1), _c('b-col', {
          staticClass: "mt-4 text-right",
          attrs: {
            "md": "12"
          }
        }, [_c('hr'), _c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_vm._v("Save")]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "submit",
            "variant": "none"
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }