var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "iq-top-navbar bg-dark"
  }, [_c('div', {
    staticClass: "iq-navbar-custom",
    class: _vm.horizontal ? 'd-flex align-items-center justify-content-between' : ''
  }, [_c('div', {
    staticClass: "iq-sidebar-logo"
  }, [_c('div', {
    staticClass: "top-logo"
  }, [_c('router-link', {
    attrs: {
      "to": _vm.homeURL
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": _vm.logo,
      "alt": "logo"
    }
  })])], 1)]), _vm.horizontal ? _c('div', {
    staticClass: "iq-menu-horizontal"
  }, [_c('div', {
    staticClass: "iq-sidebar-menu"
  }, [_c('List', {
    staticClass: "d-flex",
    attrs: {
      "items": _vm.items
    }
  })], 1)]) : _vm._e(), _c('nav', {
    staticClass: "navbar navbar-expand-lg navbar-light p-0"
  }, [_vm._m(0), _c('b-navbar-toggle', {
    attrs: {
      "target": "nav-collapse"
    }
  }, [_c('i', {
    staticClass: "ri-menu-3-line"
  })]), _c('div', {
    staticClass: "iq-menu-bt align-self-center"
  }, [_c('div', {
    staticClass: "wrapper-menu",
    class: {
      'open': _vm.isMini
    },
    on: {
      "click": _vm.miniSidebar
    }
  }, [_vm._m(1), _vm._m(2)])]), _c('b-collapse', {
    attrs: {
      "id": "nav-collapse",
      "is-nav": ""
    }
  }, [_vm._t("responsiveRight")], 2), _vm._t("right")], 2)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "iq-search-bar"
  }, [_c('form', {
    staticClass: "searchbox",
    attrs: {
      "action": "#"
    }
  }, [_c('input', {
    staticClass: "text search-input bg-light",
    attrs: {
      "type": "text",
      "placeholder": "Type here to search..."
    }
  }), _c('a', {
    staticClass: "search-link",
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-search-line"
  })])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "main-circle"
  }, [_c('i', {
    staticClass: "ri-more-fill"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hover-circle"
  }, [_c('i', {
    staticClass: "ri-more-2-fill"
  })]);

}]

export { render, staticRenderFns }