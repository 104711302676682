var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: 'iq-card ' + _vm.className
  }, [_vm._t("cardImage"), _vm.hasHeaderTitleSlot || _vm.hasHeaderActionSlot ? _c('div', {
    class: 'iq-card-header d-flex justify-content-between ' + _vm.headerClass
  }, [_c('div', {
    staticClass: "iq-header-title"
  }, [_vm._t("headerTitle")], 2), _c('div', {
    staticClass: "iq-card-header-toolbar d-flex align-items-center"
  }, [_vm._t("headerAction")], 2)]) : _vm._e(), _vm.hasBodySlot ? _c('b-card-body', {
    attrs: {
      "body-class": 'iq-card-body ' + _vm.bodyClass
    }
  }, [_vm._t("body")], 2) : _vm._e(), _vm._t("default"), _vm.hasFooterSlot ? _c('div', {
    class: 'card-footer' + _vm.footerClass
  }, [_vm._t("footer")], 2) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }