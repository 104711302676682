<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="true"
                  href="#project"
                  title="MASTER DATA PROJECT"
                />
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="false"
                  href="#idle"
                  title="MASTER DATA REASON"
                />
<!--                <tab-nav-items
                  class="col-md-4 p-0"
                  :active="false"
                  href="#cargo"
                  title="MASTER DATA TYPE OF CARGO"
                />-->
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <tab-content-item :active="true" id="project">
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                  ><strong>MASTER DATA PROJECT</strong></h4
                >
              </template>
              <template v-slot:headerAction
                v-if="menuPermission('export')">
                <b-button variant="primary" @click="doExportProjects">
                  <template v-if="!loading_export_project">
                    <i class="fa fa-file-excel"></i> &nbsp; Export
                  </template>
                  <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Exporting...
                  </template>
                </b-button>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col cols="12" md="3">
                    <b-form-group
                      label="Search Master Data Project:"
                      label-for="kind-fleet"
                    >
                      <b-form-input
                        id="fleet_name"
                        class="w-100"
                        type="text"
                        placeholder="Search..."
                        v-model="projects.params.search"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="2">
                    <b-form-group label="Search" label-for="button_search">
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="fetchProjects(1)"
                        ><i class="fa fa-search"></i
                      ></b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
          <b-col :sm="!menuPermission('update') && !menuPermission('add') ? '12' : '8'">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">List Master Data Project</h4>
              </template>
              <template v-slot:body>
                <div v-if="projects._loading" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div class="table-responsive" v-if="projects.data && projects.data.length > 0">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th width="10%">No</th>
                          <th width="25%">Name Project</th>
                          <th width="25%">Sub Project</th>
                          <th width="15%">Location</th>
                          <th width="10%">Status</th>
                          <th width="15%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(p, i) in projects.data" :key="`p-${i}`">
                          <td class="text-center">{{ projects.meta.currentPage > 1 ? projects.meta.perPage + i + 1 : i + 1 }}</td>
                          <td>{{ p.projectName }}</td>
                          <td>
                            <ol class="m-0 pl-3" v-if="p.subProject.length > 0">
                              <li
                                v-for="(sp, i_sp) in p.subProject"
                                :key="`sp-${i_sp}`"
                              >
                                {{ sp.name }}
                              </li>
                            </ol>
                            <template v-else>-</template>
                          </td>
                          <td>{{ p.location ? p.location : '-' }}</td>
                          <td>{{ p.active ? 'Active' : 'Inactive' }}</td>
                          <td>
                            <b-button
                              v-if="menuPermission('update')"
                              variant="success"
                              size="sm"
                              class="mr-2"
                              @click="onEditProject(p)"
                              ><i class="fa fa-edit"></i
                            ></b-button>
                            <b-button
                              v-if="menuPermission('delete')"
                              variant="danger"
                              size="sm"
                              @click="onDeleteProject(p)"
                            >
                              <i
                                v-if="!loading_delete_project"
                                class="fa fa-trash"
                              ></i>
                              <template v-else>
                                <span
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Deleting...
                              </template>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="projects.data && projects.data.length > 0">
                    <b-pagination
                      v-model="projects.meta.currentPage"
                      :total-rows="projects.meta.total"
                      @change="fetchProjects"
                      :per-page="projects.meta.perPage"
                      first-number
                      align="center"
                    ></b-pagination>
                  </div>
                  <p v-else class="text-center my-4">No projects data found.</p>
                </template>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="4"
            v-if="menuPermission('update') || menuPermission('add')">
            <iq-card
              :class="{
                'border border-danger shadow-lg': form_project_edit_mode,
              }"
            >
              <template v-slot:headerTitle>
                <h4 class="card-title"
                  >{{ form_project_edit_mode ? 'Edit' : 'Add' }} Project</h4
                >
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="submitFormProject">
                  <b-form-group label="Project Name" label-for="project_name">
                    <v-multiselect
                      v-model="form.selected_project"
                      placeholder="Search or add new project"
                      label="projectName"
                      track-by="id"
                      :close-on-select="true"
                      :clear-on-select="true"
                      :options="projects.data"
                      @search-change="searchProjects"
                    >
                      <span slot="noResult" @click="doAddProject">
                        Not Found. Click to add
                        <strong>{{ form.projectName }}</strong>
                      </span>
                    </v-multiselect>
                  </b-form-group>

                  <b-form-group label="Category For" label-for="category_for">
                    <v-multiselect
                      v-model="form.project.vehicleType"
                      placeholder="Search categories"
                      label="name"
                      track-by="id"
                      :options="fleet_types"
                      :multiple="true"
                    >
                      <span slot="noResult">Category not found.</span>
                    </v-multiselect>
                  </b-form-group>

                  <b-form-group
                    label="Project Location"
                    label-for="project_location"
                  >
                    <b-form-input
                      id="port_name"
                      type="text"
                      placeholder="Project Location"
                      maxlength="30"
                      v-model="form.project.location"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Sub Project"
                    label-for="sub_project_name"
                  >
                    <b-row>
                      <b-col>
                        <b-form-input
                          id="port_name"
                          type="text"
                          placeholder="Sub Project Name"
                          class="d-inline-block"
                          maxlength="30"
                          v-model="form.project.subProject[0].name"
                        ></b-form-input>
                      </b-col>

                      <b-button
                        variant="outline-primary"
                        class="mr-2"
                        v-b-modal.edit-sub-project
                        v-b-tooltip.hover
                        title="Setting Sub Project"
                        ><i class="fa fa-cog"></i
                      ></b-button>

                      <b-modal
                        id="edit-sub-project"
                        size="md"
                        :title="`Edit Sub Projects`"
                        ok-title="Save"
                        cancel-title="Cancel"
                        centered
                      >
                        <template v-for="(sp, i) in form.project.subProject">
                          <b-input-group
                            :key="`spf-${i}`"
                            :prepend="(i + 1).toString()"
                            class="mb-3"
                          >
                            <b-form-input
                              v-model="sp.name"
                              :placeholder="`Sub Project ${i + 1} Name`"
                              type="text"
                              maxlength="30"
                            ></b-form-input>

                            <b-input-group-append>
                              <b-button
                                variant="outline-danger"
                                @click="deleteSubProject(i)"
                              >
                                <i class="fa fa-times"></i>
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </template>
                        <div class="mt-3">
                          <a href="" @click.prevent="addSubProject"
                            >Add New Sub Project</a
                          >
                        </div>
                      </b-modal>
                    </b-row>
                  </b-form-group>

                  <b-form-group label="Status" label-for="sub_sub_project">
                    <b-form-checkbox
                      v-model="form.project.active"
                      name="check-button"
                      switch
                      inline
                    >
                      {{ form.project.active ? 'Active' : 'Inactive' }}
                    </b-form-checkbox>
                  </b-form-group>
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span v-if="!loading_form_project">{{
                        form_project_edit_mode || !menuPermission('add') ? 'Save' : 'Add'
                      }}</span>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </template>
                    </b-button>
                    <b-button
                      type="button"
                      variant="danger"
                      class="iq-bg-danger ml-3"
                      @click.prevent="resetFormProject"
                      >Cancel</b-button
                    >
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
      <tab-content-item :active="false" id="idle">
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                  ><strong>MASTER DATA REASON (IDLE)</strong></h4
                >
              </template>
              <template v-slot:headerAction
                v-if="menuPermission('export')">
                <b-button @click="doExportReasons" variant="primary">
                  <template v-if="!loading_export_reason">
                    <i class="fa fa-file-excel"></i> &nbsp; Export
                  </template>
                  <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Exporting...
                  </template>
                </b-button>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col cols="12" md="3">
                    <b-form-group
                      label="Search Data Reason:"
                      label-for="kind-fleet"
                    >
                      <b-form-input
                        id="fleet_name"
                        class="w-100"
                        type="text"
                        placeholder="Search..."
                        v-model="reasons.params.search"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="2">
                    <b-form-group label="Search" label-for="button_search">
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="fetchReasons(1)"
                        ><i class="fa fa-search"></i
                      ></b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
          <b-col :sm="!menuPermission('update') && !menuPermission('add') ? '12' : '8'">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">List Master Data Reason (Idle)</h4>
              </template>
              <template v-slot:body>
                <div v-if="reasons._loading" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div class="table-responsive" v-if="reasons.data && reasons.data.length > 0">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th width="10%">No</th>
                          <th width="25%">Reason</th>
                          <th width="25%">Fleet Category</th>
                          <th width="10%">Status</th>
                          <th width="15%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(r, i) in reasons.data" :key="`r-${i}`">
                          <td class="text-center">{{ reasons.meta.currentPage > 1 ? reasons.meta.perPage + i + 1 : i + 1 }}</td>
                          <td>{{ r.name }}</td>
                          <td>
                            <span
                              v-for="(vehicle, index) in r.vehicleType"
                              :key="`vh-${index}`"
                            >
                              {{ vehicle.name }}
                              <span v-if="index != r.vehicleType.length - 1"
                                >,
                              </span>
                            </span>
                          </td>
                          <td>{{ r.active ? 'Active' : 'Inactive' }}</td>
                          <td>
                            <b-button
                              v-if="menuPermission('update')"
                              variant="success"
                              size="sm"
                              class="mr-2"
                              @click="onEditReason(r)"
                              ><i class="fa fa-edit"></i
                            ></b-button>
                            <b-button
                              v-if="menuPermission('delete')"
                              variant="danger"
                              size="sm"
                              @click="onDeleteReason(r)"
                              ><i class="fa fa-trash"></i
                            ></b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="reasons.data && reasons.data.length > 0">
                    <b-pagination
                      v-model="reasons.meta.currentPage"
                      :total-rows="reasons.meta.total"
                      @change="fetchReasons"
                      :per-page="reasons.meta.perPage"
                      first-number
                      align="center"
                    ></b-pagination>
                  </div>
                  <p v-else class="text-center my-4">No reasons data found.</p>
                </template>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="4"
            v-if="menuPermission('update') || menuPermission('add')">
            <iq-card
              :class="{
                'border border-danger shadow-lg': form_reason_edit_mode,
              }"
            >
              <template v-slot:headerTitle>
                <h4 class="card-title"
                  >{{ form_reason_edit_mode ? 'Edit' : 'Add' }} Reason
                  (Idle)</h4
                >
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="submitFormReason">
                  <b-form-group label="Reason Name" label-for="reason_name">
                    <b-form-input
                      id="port_name"
                      type="text"
                      placeholder="Reason Name"
                      v-model="form_reason.name"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Category For" label-for="category_for">
                    <v-multiselect
                      v-model="form_reason.vehicleType"
                      placeholder="Search categories"
                      label="name"
                      track-by="id"
                      :options="fleet_types"
                      :multiple="true"
                    >
                      <span slot="noResult">Category not found.</span>
                    </v-multiselect>
                  </b-form-group>

                  <b-form-group label="Status" label-for="status">
                    <b-form-checkbox
                      v-model="form_reason.active"
                      name="check-button"
                      switch
                      inline
                    >
                      {{ form_reason.active ? 'Active' : 'Inactive' }}
                    </b-form-checkbox>
                  </b-form-group>
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span v-if="!loading_form_reason">{{
                        form_reason_edit_mode || !menuPermission('add') ? 'Save' : 'Add'
                      }}</span>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </template>
                    </b-button>
                    <b-button
                      type="submit"
                      variant="none"
                      class="iq-bg-danger ml-3"
                      @click.prevent="resetFormReason"
                      >Cancel</b-button
                    >
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
      <tab-content-item :active="false" id="cargo">
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                ><strong>MASTER DATA TYPE OF CARGO</strong></h4
                >
              </template>
              <template v-slot:headerAction
                v-if="menuPermission('export')">
                <b-button variant="primary">
                  <template v-if="!loading_export_reason">
                    <i class="fa fa-file-excel"></i> &nbsp; Export
                  </template>
                  <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Exporting...
                  </template>
                </b-button>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col cols="12" md="3">
                    <b-form-group
                      label="Search Data Reason:"
                      label-for="kind-fleet"
                    >
                      <b-form-input
                        id="fleet_name"
                        class="w-100"
                        type="text"
                        placeholder="Search..."
                        v-model="reason_params.search"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="2">
                    <b-form-group label="Search" label-for="button_search">
                      <b-button
                        variant="primary"
                        class="ml-2"
                      ><i class="fa fa-search"></i
                      ></b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
          <b-col :sm="!menuPermission('add') && !menuPermission('update') ? '12' : '8'">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">List Master Data Type of Cargo</h4>
              </template>
              <template v-slot:body>
                <div v-if="loading_reason" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div class="table-responsive" v-if="reasons.length > 0">
                    <table class="table table-striped">
                      <thead>
                      <tr>
                        <th width="10%">No</th>
                        <th width="25%">Type of Cargo</th>
                        <th width="25%">Description</th>
                        <th width="10%">Status</th>
                        <th width="15%">Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(r, i) in reasons" :key="`r-${i}`">
                        <td>{{ i + 1 }}</td>
                        <td>{{ r.name }}</td>
                        <td>
                            <span
                              v-for="(vehicle, index) in r.vehicleType"
                              :key="`vh-${index}`"
                            >
                              {{ vehicle.name }}
                              <span v-if="index != r.vehicleType.length - 1"
                              >,
                              </span>
                            </span>
                        </td>
                        <td>{{ r.active ? 'Active' : 'Inactive' }}</td>
                        <td>
                          <b-button
                            v-if="menuPermission('update')"
                            variant="success"
                            size="sm"
                            class="mr-2"
                          ><i class="fa fa-edit"></i
                          ></b-button>
                          <b-button
                            v-if="menuPermission('delete')"
                            variant="danger"
                            size="sm"
                          ><i class="fa fa-trash"></i
                          ></b-button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <p v-else class="text-center my-4">No reasons data found.</p>
                </template>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="4"
            v-if="menuPermission('add') || menuPermission('update')">
            <iq-card
              :class="{
                'border border-danger shadow-lg': form_reason_edit_mode,
              }"
            >
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ form_reason_edit_mode ? 'Edit' : 'Add' }} Type of Cargo</h4>
              </template>
              <template v-slot:body>
                <b-form>
                  <b-form-group label="Type of Cargo" label-for="type_of_cargo">
                    <b-form-input
                      id="port_name"
                      type="text"
                      placeholder="Type of Cargo"
                      v-model="form_reason.name"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Description" label-for="description">
                    <b-form-textarea id="description" rows="2"></b-form-textarea>
                  </b-form-group>

                  <b-form-group label="Status" label-for="status">
                    <b-form-checkbox
                      v-model="form_reason.active"
                      name="check-button"
                      switch
                      inline
                    >
                      {{ form_reason.active ? 'Active' : 'Inactive' }}
                    </b-form-checkbox>
                  </b-form-group>
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="button" variant="primary">
                      <span v-if="!loading_form_reason">{{
                          form_reason_edit_mode || !menuPermission('add') ? 'Save' : 'Add'
                        }}</span>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </template>
                    </b-button>
                    <b-button
                      type="button"
                      variant="none"
                      class="iq-bg-danger ml-3"
                      @click.prevent="resetFormReason"
                    >Cancel</b-button
                    >
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
    </div>
  </b-container>
</template>

<script>
import { saveAs } from 'file-saver'
import {
  projectsActions,
  fleetsActions,
  reasonsActions
} from '@src/Utils/helper'
import _ from 'lodash'

export default {
  name: 'MasterProjectIdle',
  data () {
    return {
      fleet_types: [],
      projects: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          name: '',
          active: true,
          perPage: 50,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      reasons: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          name: '',
          active: true,
          perPage: 50,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      projects_form: [],
      form_project_edit_mode: false,
      loading_export_project: false,
      loading_delete_project: false,
      loading_form_project: false,
      form_reason_edit_mode: false,
      loading_export_reason: false,
      loading_delete_reason: false,
      loading_form_reason: false,
      form: {
        selected_project: '',
        projectName: '',
        project: {
          location: '',
          active: true,
          vehicleType: [],
          subProject: [{ name: '' }]
        }
      },
      form_reason: {
        name: '',
        active: true,
        vehicleType: []
      },
      selected_reason: {}
    }
  },
  created () {
    this.fetchProjects = _.debounce(this.fetchProjects, 500)
    this.fetchReasons = _.debounce(this.fetchReasons, 500)
  },
  mounted () {
    this.fetchProjects()
    this.fetchFleetTypes()
    this.fetchReasons()
  },
  methods: {
    ...projectsActions,
    ...fleetsActions,
    ...reasonsActions,
    async fetchProjects (page) {
      this.projects.params.page = page || 1
      this.projects._loading = true
      this.projects.data = []
      let params = this.$options.filters.cleanObject(this.projects.params)

      let res = await this.getProjects(params)
      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.projects.data = res.data.data
          this.projects.meta.perPage = res.data.perPage
          this.projects.meta.currentPage = res.data.currentPage
          this.projects.meta.total = res.data.total
          this.projects.meta.totalPage = res.data.totalPage
        }
        this.projects._loading = false
      } else {
        this.projects._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async fetchFleetTypes () {
      let res = await this.getFleetTypes()
      this.fleet_types = res.data
    },
    async fetchReasons (page) {
      this.reasons.params.page = page || 1
      this.reasons._loading = true
      this.reasons.data = []
      let params = this.$options.filters.cleanObject(this.reasons.params)

      let res = await this.getReasons(params)
      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.reasons.data = res.data.data
          this.reasons.meta.perPage = res.data.perPage
          this.reasons.meta.currentPage = res.data.currentPage
          this.reasons.meta.total = res.data.total
          this.reasons.meta.totalPage = res.data.totalPage
        }
        this.reasons._loading = false
      } else {
        this.reasons._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async doExportProjects () {
      this.loading_export_project = true
      let res = await this.exportProjects()
      if (res.status == 'success') {
        this.loading_export_project = false
        saveAs(res.data, 'Project List.xlsx')
      } else {
        this.loading_export_project = false
        if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    async doExportReasons () {
      this.loading_export_reason = true
      let res = await this.exportReasons()
      if (res.status == 'success') {
        this.loading_export_reason = false
        saveAs(res.data, 'Reasons List.xlsx')
      } else {
        this.loading_export_reason = false
        if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    async doAddProject () {
      let res = await this.addProject(this.form.projectName)
      if (res.status == 'success') {
        this.form.selected_project = res.data
        this.fetchProjects()
      } else {
        if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    async searchProjects (query) {
      this.form.projectName = query
      let params = {
        active: true,
        search: query
      }
      let res = await this.getProjects(params)
      this.projects_form = res.data
    },
    async submitFormProject () {
      this.loading_form_project = true

      let payload = {
        id: this.form.selected_project.id,
        data: this.form.project
      }

      let form_has_empty_value = false
      for (var key in payload.data) {
        if (payload.data[key] === '') form_has_empty_value = true
        else if (Array.isArray(payload.data[key]) && !payload.data[key].length) { form_has_empty_value = true }
      }

      if (form_has_empty_value) {
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )
      } else {
        if (!this.form_project_edit_mode) {
          let res = await this.addProjectDetail(payload)
          if (res.status == 'success') {
            this.resetFormProject()
            this.fetchProjects()
            this.$swal(
              'Project added!',
              'Project added successfully.',
              'success'
            )
          } else {
            if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
            }
          }
        } else {
          payload.data.projectName = this.form.projectName

          let res = await this.updateProjectDetail(payload)
          if (res.status == 'success') {
            this.resetFormProject()
            this.fetchProjects()
            this.$swal(
              'Project updated!',
              'Project updated successfully.',
              'success'
            )
          } else {
            if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
            }
          }
        }
      }
      this.loading_form_project = false
    },
    async submitFormReason () {
      this.loading_form_reason = true

      let payload = this.form_reason

      let form_has_empty_value = false
      for (var key in payload) {
        if (payload[key] === '') form_has_empty_value = true
        else if (Array.isArray(payload[key]) && !payload[key].length) { form_has_empty_value = true }
      }

      if (form_has_empty_value) {
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )
      } else {
        if (!this.form_reason_edit_mode) {
          let res = await this.addReason(payload)
          if (res.status == 'success') {
            this.resetFormReason()
            this.fetchReasons()
            this.$swal('Reason added!', 'Reason added successfully.', 'success')
          } else {
            if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
            }
          }
        } else {
          let res = await this.updateReason({
            id: this.selected_reason.id,
            data: payload
          })
          if (res.status == 'success') {
            this.resetFormReason()
            this.fetchReasons()
            this.$swal(
              'Reason updated!',
              'Reason updated successfully.',
              'success'
            )
          } else {
            if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
            }
          }
        }
      }
      this.loading_form_reason = false
    },
    onEditProject (project) {
      this.form_project_edit_mode = true
      this.form.selected_project = project
      this.form.projectName = project.projectName
      this.form.project = {
        location: project.location,
        active: project.active,
        vehicleType: project.vehicleType.length > 0 ? project.vehicleType : [],
        subProject:
          project.subProject.length > 0 ? project.subProject : [{ name: '' }]
      }
    },
    onEditReason (reason) {
      this.form_reason_edit_mode = true
      this.selected_reason = reason
      this.form_reason = {
        name: reason.name,
        active: reason.active,
        vehicleType: reason.vehicleType.length > 0 ? reason.vehicleType : []
      }
    },
    onDeleteProject (project) {
      this.$swal({
        title: 'Delete project?',
        text: `Project ${project.projectName} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_project = true
          let res = await this.deleteProject(project.id)
          if (res.status != 'success') {
            this.loading_delete_project = false
            if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.loading_delete_project = false
            this.fetchProjects()
            this.$swal(
              `Project deleted!`,
              `Project ${project.projectName} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    onDeleteReason (reason) {
      this.$swal({
        title: 'Delete reason?',
        text: `Reason ${reason.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_reason = true
          let res = await this.deleteReason(reason.id)
          if (res.status != 'success') {
            this.loading_delete_reason = false
            if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.loading_delete_reason = false
            this.fetchReasons()
            this.$swal(
              `Reason deleted!`,
              `Reason ${reason.name} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    addSubProject () {
      this.form.project.subProject.push({ name: '' })
    },
    deleteSubProject (index) {
      this.form.project.subProject.splice(index, 1)
    },
    resetFormProject () {
      this.form_project_edit_mode = false
      this.form = {
        selected_project: '',
        projectName: '',
        project: {
          location: '',
          active: true,
          vehicleType: [],
          subProject: [{ name: '' }]
        }
      }
    },
    resetFormReason () {
      this.form_reason_edit_mode = false
      this.form_reason = {
        name: '',
        active: true,
        vehicleType: []
      }
    },
    menuPermission (output) {
      const { menuObject } = this.$route.query

      if (!menuObject) {
        return true
      }

      return JSON.parse(window.atob(menuObject))[output]
    }
  }
}
</script>

<style scoped>
</style>
