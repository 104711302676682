var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Examples")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-1",
            modifiers: {
              "collapse-1": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Progress components are built with two HTML elements, some CSS to set the width, and a few attributes. We don’t use "), _c('a', {
          attrs: {
            "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/progress"
          }
        }, [_vm._v("the HTML5 "), _c('code', [_vm._v("<progress>")]), _vm._v(" element")]), _vm._v(", ensuring you can stack progress bars, animate them, and place text labels over them.")]), _c('ul', [_c('li', [_vm._v("We use the "), _c('code', [_vm._v(".progress")]), _vm._v(" as a wrapper to indicate the max value of the progress bar.")]), _c('li', [_vm._v("We use the inner "), _c('code', [_vm._v(".progress-bar")]), _vm._v(" to indicate the progress so far.")]), _c('li', [_vm._v("The "), _c('code', [_vm._v(".progress-bar")]), _vm._v(" requires an inline style, utility class, or custom CSS to set their width.")]), _c('li', [_vm._v("The "), _c('code', [_vm._v(".progress-bar")]), _vm._v(" also requires some "), _c('code', [_vm._v("role")]), _vm._v(" and "), _c('code', [_vm._v("aria")]), _vm._v(" attributes to make it accessible.")])]), _c('p', [_vm._v("Put that all together, and you have the following examples.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-1"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "examples-1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-progress :value=\"0\" max=\"100\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"25\" max=\"100\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"50\" max=\"100\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"75\" max=\"100\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"100\" max=\"100\" class=\"mb-3\"></b-progress>\n")])])])])]), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 0,
            "max": "100"
          }
        }), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 25,
            "max": "100"
          }
        }), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 50,
            "max": "100"
          }
        }), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 75,
            "max": "100"
          }
        }), _c('b-progress', {
          attrs: {
            "value": 100,
            "max": "100"
          }
        })];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Heights")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-2",
            modifiers: {
              "collapse-2": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("We only set a "), _c('code', [_vm._v("height")]), _vm._v(" value on the "), _c('code', [_vm._v(".progress")]), _vm._v(", so if you change that value the inner "), _c('code', [_vm._v(".progress-bar")]), _vm._v(" will automatically resize accordingly.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-2"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "heights-1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-progress :value=\"25\" show-progress max=\"100\" height=\"3px\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"50\" show-progress max=\"100\" height=\"10px\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"75\" show-progress max=\"100\" height=\"15px\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"100\" show-progress max=\"100\" height=\"20px\" ></b-progress>\n")])])])])]), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 25,
            "show-progress": "",
            "max": "100",
            "height": "3px"
          }
        }), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 50,
            "show-progress": "",
            "max": "100",
            "height": "10px"
          }
        }), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 75,
            "show-progress": "",
            "max": "100",
            "height": "15px"
          }
        }), _c('b-progress', {
          attrs: {
            "value": 99,
            "show-progress": "",
            "max": "100",
            "height": "20px"
          }
        })];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Animated stripes")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-3",
            modifiers: {
              "collapse-3": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("The striped gradient can also be animated. Add "), _c('code', [_vm._v(".progress-bar-animated")]), _vm._v(" to "), _c('code', [_vm._v(".progress-bar")]), _vm._v(" to animate the stripes right to left via CSS3 animations.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-3"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "animated-stripe-1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-progress :value=\"75\" animated max=\"100\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"75\" variant=\"success\" animated max=\"100\"></b-progress>\n")])])])])]), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 75,
            "animated": "",
            "max": "100"
          }
        }), _c('b-progress', {
          attrs: {
            "value": 75,
            "variant": "success",
            "animated": "",
            "max": "100"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Labels")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-4",
            modifiers: {
              "collapse-4": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Add labels to your progress bars by placing text within the "), _c('code', [_vm._v(".progress-bar")]), _vm._v(".")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-4"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "labels-1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-progress :value=\"25\" show-progress max=\"100\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"50\" show-progress max=\"100\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"75\" show-progress max=\"100\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"100\" show-progress max=\"100\"></b-progress>\n")])])])])]), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 25,
            "show-progress": "",
            "max": "100"
          }
        }), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 50,
            "show-progress": "",
            "max": "100"
          }
        }), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 75,
            "show-progress": "",
            "max": "100"
          }
        }), _c('b-progress', {
          attrs: {
            "value": 99,
            "show-progress": "",
            "max": "100"
          }
        })];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Backgrounds")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-5",
            modifiers: {
              "collapse-5": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Use background utility classes to change the appearance of individual progress bars.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-5"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "backgrounds-1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-progress :value=\"25\" variant=\"success\" max=\"100\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"50\" variant=\"info\" max=\"100\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"75\" variant=\"warning\" max=\"100\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"100\" variant=\"danger\" max=\"100\"></b-progress>\n")])])])])]), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 25,
            "variant": "success",
            "max": "100"
          }
        }), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 50,
            "variant": "info",
            "max": "100"
          }
        }), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 75,
            "variant": "warning",
            "max": "100"
          }
        }), _c('b-progress', {
          attrs: {
            "value": 100,
            "variant": "danger",
            "max": "100"
          }
        })];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Multiple bars")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-6",
            modifiers: {
              "collapse-6": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Include multiple progress bars in a progress component if you need.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-6"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "multiple-bars-1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-progress max=\"100\">\n  <b-progress-bar variant=\"primary\" :value=\"15\"></b-progress-bar>\n  <b-progress-bar variant=\"success\" :value=\"30\"></b-progress-bar>\n  <b-progress-bar variant=\"info\" :value=\"20\"></b-progress-bar>\n</b-progress>\n")])])])])]), _c('b-progress', {
          attrs: {
            "max": "100"
          }
        }, [_c('b-progress-bar', {
          attrs: {
            "variant": "primary",
            "value": 15
          }
        }), _c('b-progress-bar', {
          attrs: {
            "variant": "success",
            "value": 30
          }
        }), _c('b-progress-bar', {
          attrs: {
            "variant": "info",
            "value": 20
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Striped")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-7",
            modifiers: {
              "collapse-7": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Add "), _c('code', [_vm._v(".progress-bar-striped")]), _vm._v(" to any "), _c('code', [_vm._v(".progress-bar")]), _vm._v(" to apply a stripe via CSS gradient over the progress bar’s background color.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-7"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "striped-1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-progress :value=\"10\" striped max=\"100\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"25\" striped variant=\"success\" max=\"100\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"50\" striped variant=\"info\" max=\"100\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"75\" striped variant=\"warning\" max=\"100\" class=\"mb-3\"></b-progress>\n<b-progress :value=\"100\" striped variant=\"danger\" max=\"100\"></b-progress>\n")])])])])]), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 10,
            "striped": "",
            "max": "100"
          }
        }), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 25,
            "striped": "",
            "variant": "success",
            "max": "100"
          }
        }), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 50,
            "striped": "",
            "variant": "info",
            "max": "100"
          }
        }), _c('b-progress', {
          staticClass: "mb-3",
          attrs: {
            "value": 75,
            "striped": "",
            "variant": "warning",
            "max": "100"
          }
        }), _c('b-progress', {
          attrs: {
            "value": 100,
            "striped": "",
            "variant": "danger",
            "max": "100"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }