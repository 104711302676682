var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "iq-card-body pl-0 pr-0 pt-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "docter-details-block"
        }, [_c('div', {
          staticClass: "doc-profile-bg bg-primary",
          staticStyle: {
            "height": "150px"
          }
        }), _c('div', {
          staticClass: "docter-profile text-center"
        }, [_c('b-img', {
          staticClass: "avatar-130 img-fluid",
          attrs: {
            "src": _vm.doctor.profile,
            "alt": "profile-img"
          }
        })], 1), _c('div', {
          staticClass: "text-center mt-3 pl-3 pr-3"
        }, [_c('h4', [_c('b', [_vm._v("Bini Jets")])]), _c('p', [_vm._v("Doctor")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus repudiandae eveniet harum.")])]), _c('hr'), _c('ul', {
          staticClass: "doctoe-sedual d-flex align-items-center justify-content-between p-0 m-0"
        }, [_c('li', {
          staticClass: "text-center"
        }, [_c('h3', {
          staticClass: "counter"
        }, [_vm._v("4500")]), _c('span', [_vm._v("Operations")])]), _c('li', {
          staticClass: "text-center"
        }, [_c('h3', {
          staticClass: "counter"
        }, [_vm._v("100")]), _c('span', [_vm._v("Hospital")])]), _c('li', {
          staticClass: "text-center"
        }, [_c('h3', {
          staticClass: "counter"
        }, [_vm._v("10000")]), _c('span', [_vm._v("Patients")])])])])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    attrs: {
      "body-class": "iq-card-body"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-card-header d-flex justify-content-between"
        }, [_c('div', {
          staticClass: "iq-header-title"
        }, [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Personal Information")])])]), _c('div', {
          staticClass: "iq-card-body"
        }, [_c('div', {
          staticClass: "about-info m-0 p-0"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-4"
        }, [_vm._v("First Name:")]), _c('div', {
          staticClass: "col-8"
        }, [_vm._v("Bini")]), _c('div', {
          staticClass: "col-4"
        }, [_vm._v("Last Name:")]), _c('div', {
          staticClass: "col-8"
        }, [_vm._v("Jets")]), _c('div', {
          staticClass: "col-4"
        }, [_vm._v("Age:")]), _c('div', {
          staticClass: "col-8"
        }, [_vm._v("27")]), _c('div', {
          staticClass: "col-4"
        }, [_vm._v("Position:")]), _c('div', {
          staticClass: "col-8"
        }, [_vm._v("Senior Docter")]), _c('div', {
          staticClass: "col-4"
        }, [_vm._v("Email:")]), _c('div', {
          staticClass: "col-8"
        }, [_c('a', {
          attrs: {
            "href": "mailto:biniJets24@demo.com"
          }
        }, [_vm._v(" biniJets24@demo.com ")])]), _c('div', {
          staticClass: "col-4"
        }, [_vm._v("Phone:")]), _c('div', {
          staticClass: "col-8"
        }, [_c('a', {
          attrs: {
            "href": "tel:001-2351-25612"
          }
        }, [_vm._v("001 2351 256 12")])]), _c('div', {
          staticClass: "col-4"
        }, [_vm._v("Location:")]), _c('div', {
          staticClass: "col-8"
        }, [_vm._v("USA")])])])])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    attrs: {
      "body-class": "iq-card-body"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-card-header d-flex justify-content-between"
        }, [_c('div', {
          staticClass: "iq-header-title"
        }, [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Photos")])])]), _c('div', {
          staticClass: "iq-card-body"
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-4 col-6 pb-3"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/login/1.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-4 col-6 pb-3"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/login/2.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-4 col-6 pb-3"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/login/3.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-4 col-6 pb-3"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/login/1.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-4 col-6 pb-3"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/login/2.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-4 col-6 pb-3"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/login/3.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-4 col-6 pb-0"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/login/1.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-4 col-6 pb-0"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/login/2.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-4 col-6 pb-0"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/login/3.png"),
            "alt": "gallary-image"
          }
        })])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Speciality")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('ul', {
          staticClass: "speciality-list m-0 p-0"
        }, [_c('li', {
          staticClass: "d-flex mb-4 align-items-center"
        }, [_c('div', {
          staticClass: "user-img img-fluid"
        }, [_c('a', {
          staticClass: "iq-bg-primary",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-award-fill"
        })])]), _c('div', {
          staticClass: "media-support-info ml-3"
        }, [_c('h6', [_vm._v("professional")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Certified Skin Treatment")])])]), _c('li', {
          staticClass: "d-flex mb-4 align-items-center"
        }, [_c('div', {
          staticClass: "user-img img-fluid"
        }, [_c('a', {
          staticClass: "iq-bg-warning",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-award-fill"
        })])]), _c('div', {
          staticClass: "media-support-info ml-3"
        }, [_c('h6', [_vm._v("Certified")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Cold Laser Operation")])])]), _c('li', {
          staticClass: "d-flex mb-4 align-items-center"
        }, [_c('div', {
          staticClass: "user-img img-fluid"
        }, [_c('a', {
          staticClass: "iq-bg-info",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-award-fill"
        })])]), _c('div', {
          staticClass: "media-support-info ml-3"
        }, [_c('h6', [_vm._v("Medication Laser")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Hair Lose Product")])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Notifications")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('ul', {
          staticClass: "iq-timeline"
        }, [_c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {}, [_vm._v("Dr. Joy Send you Photo")]), _c('small', {
          staticClass: "mt-1"
        }, [_vm._v("23 November 2019")])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-danger"
        }), _c('h6', {}, [_vm._v("Reminder : Opertion Time!")]), _c('small', {
          staticClass: "mt-1"
        }, [_vm._v("20 November 2019")])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-primary"
        }), _c('h6', {
          staticClass: "mb-1"
        }, [_vm._v("Patient Call")]), _c('small', {
          staticClass: "mt-1"
        }, [_vm._v("19 November 2019")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Schedule")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('ul', {
          staticClass: "list-inline m-0 p-0"
        }, [_c('li', [_c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Ruby saul (Blood Check)")]), _c('small', {
          staticClass: "float-right mt-1"
        }, [_vm._v("Today")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', {
          staticClass: "badge badge-primary"
        }, [_vm._v("09:00 AM ")])])]), _c('li', [_c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v(" Anna Mull (Fever)")]), _c('small', {
          staticClass: "float-right mt-1"
        }, [_vm._v("Today")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', {
          staticClass: "badge badge-danger"
        }, [_vm._v("09:15 AM ")])])]), _c('li', [_c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Petey Cruiser (X-ray)")]), _c('small', {
          staticClass: "float-right mt-1"
        }, [_vm._v("Today")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', {
          staticClass: "badge badge-warning"
        }, [_vm._v("10:00 AM ")])])]), _c('li', [_c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Anna Sthesia (Full body Check up)")]), _c('small', {
          staticClass: "float-right mt-1"
        }, [_vm._v("Today")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', {
          staticClass: "badge badge-info"
        }, [_vm._v("01:00 PM ")])])]), _c('li', [_c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Paul Molive (Operation)")]), _c('small', {
          staticClass: "float-right mt-1"
        }, [_vm._v("Tomorrow")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', {
          staticClass: "badge badge-success"
        }, [_vm._v("09:00 AM ")])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Patients Notes")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('ul', {
          staticClass: "list-inline m-0 p-0"
        }, [_c('li', {
          staticClass: "d-flex align-items-center justify-content-between mb-3"
        }, [_c('div', [_c('h6', [_vm._v("Treatment was good!")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Eye Test ")])]), _c('div', [_c('a', {
          staticClass: "btn iq-bg-primary",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Open")])])]), _c('li', {
          staticClass: "d-flex align-items-center justify-content-between mb-3"
        }, [_c('div', [_c('h6', [_vm._v("My Helth in better Now")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Fever Test")])]), _c('div', [_c('a', {
          staticClass: "btn iq-bg-primary",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Open")])])]), _c('li', {
          staticClass: "d-flex align-items-center justify-content-between mb-3"
        }, [_c('div', [_c('h6', [_vm._v("No Effacted")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Thyroid Test")])]), _c('div', [_c('a', {
          staticClass: "btn iq-bg-danger",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Close")])])]), _c('li', {
          staticClass: "d-flex align-items-center justify-content-between mb-3"
        }, [_c('div', [_c('h6', [_vm._v("Operation Successfull")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Orthopaedic")])]), _c('div', [_c('a', {
          staticClass: "btn iq-bg-primary",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Open")])])]), _c('li', {
          staticClass: "d-flex align-items-center justify-content-between mb-3"
        }, [_c('div', [_c('h6', [_vm._v("Mediacal Care is just a click away")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Join Pain ")])]), _c('div', [_c('a', {
          staticClass: "btn iq-bg-danger",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Close")])])]), _c('li', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', [_c('h6', [_vm._v("Treatment is good")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Skin Treatment ")])]), _c('div', [_c('a', {
          staticClass: "btn iq-bg-primary",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Open")])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Education")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table mb-0 table-borderless"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Year")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Degree")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Institute")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Result")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("2010")]), _c('td', [_vm._v("MBBS, M.D")]), _c('td', [_vm._v("University of Wyoming")]), _c('td', [_c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v("Distinction")])])]), _c('tr', [_c('td', [_vm._v("2014")]), _c('td', [_vm._v("M.D. of Medicine")]), _c('td', [_vm._v("Netherland Medical College")]), _c('td', [_c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v("Distinction")])])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Experience")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table mb-0 table-borderless"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Year")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Department")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Position")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Hospital")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Feedback")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("2014 - 2018")]), _c('td', [_vm._v("MBBS, M.D")]), _c('td', [_vm._v("Senior Docter")]), _c('td', [_vm._v("Midtown Medical Clinic")]), _c('td', [_c('span', {
          staticClass: "badge badge-primary"
        }, [_vm._v("Good")])])]), _c('tr', [_c('td', [_vm._v("2018 - 2020")]), _c('td', [_vm._v("M.D. of Medicine")]), _c('td', [_vm._v("Associate Prof.")]), _c('td', [_vm._v("Netherland Medical College")]), _c('td', [_c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v("excellence")])])])])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }