<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Stock Opname</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="text-right mb-5">
                <b-button variant="dark"><i class="fa fa-plus-circle"></i> Add New Opname </b-button>
              </b-col>
              <b-col md="12">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th width="3%">No</th>
                    <th width="15%">Name</th>
                    <th width="5%">Date</th>
                    <th>Fleets</th>
                    <th>Status</th>
                    <th>Different</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tr>
                    <td></td>
                    <td>
                      <b-form-input type="text" placeholder="search..."></b-form-input>
                    </td>
                    <td>
                      <date-picker type="date" range placeholder="Select Date" format="DD-MM-YYYY"></date-picker>
                    </td>
                    <td>
                      <v-multiselect v-model="fleetSelected" tag-placeholder="Category" placeholder="Search or add a fleet" label="name" track-by="code" :options="fleetEquipments">
                      </v-multiselect>
                    </td>
                    <td>
                        <v-select label="text" v-model="statusSelected" :options="statusGoods" :reduce="(type) => type.value" placeholder="select category"></v-select>
                    </td>
                    <td></td>
                    <td>
                    </td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Stock Opname Bulan Januari</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <span><b-button variant="warning"><i class="fa fa-edit"></i> </b-button></span>
                      <span><b-button variant="danger"><i class="fa fa-trash"></i> </b-button></span>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { fleetsActions } from '@src/Utils/helper'

export default {
  name: 'StockOpname',
  data () {
    return {
      fleetEquipments: [],
      fleetSelected: [],
      fleetParams: {
        page: 1,
        showAll: true,
        active: true,
        search: ''
      },
      historiesParamsDate: [],
      statusSelected: [],
      typeStockSelected: [
        { value: 'stock-in', text: 'Stock In' },
        { value: 'stock-out', text: 'Stock Out' }
      ],
      category: [
        { value: 'stock-in', text: 'Stock In' },
        { value: 'stock-out', text: 'Stock Out' }
      ],
      statusGoods: [
        { value: 'all', text: 'All Status' },
        { value: 'available', text: 'Available Stock' },
        { value: 'towards-runs-out', text: 'Stock Runs Out Soon' },
        { value: 'out-of-stock', text: 'Out of Stock' }
      ]
    }
  },
  async mounted () {
    xray.index()
    await this.fetchFleets()
  },
  methods: {
    ...fleetsActions,
    async fetchFleets () {
      this.fleetsLoading = true
      this.fleets = []

      let params = this.$options.filters.cleanObject(this.fleetParams)
      let res = await this.getFleets(params)

      if (res.status) {
        this.fleetsLoading = false
        if (res.data && res.data.length > 0) {
          res.data.forEach(el => {
            el.componentSetup = []
            this.fleets.push(el)
            this.fleetEquipments.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
  }
}
</script>
