<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
import Loader from '../../components/xray/loader/Loader'
import logo from '../../assets/images/logo.png'
import bgImage from '../../assets/images/login/2.png'
export default {
  name: 'AuthLayout2',
  components: {
    Loader
  },
  data () {
    return {
      slickOptions: {
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 2000,
        fade: true,
        slidesToShow: 1,
        arrows: false,
        dots: true
      },
      logo: logo,
      bgImageURL: bgImage
    }
  }
}
</script>
