var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("PURCHASE ORDER")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.filter",
            modifiers: {
              "filter": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-4",
          attrs: {
            "visible": "",
            "id": "filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionRequestClass,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Request Class")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectRequestClass,
            callback: function callback($$v) {
              _vm.selectRequestClass = $$v;
            },
            expression: "selectRequestClass"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionStatusPurchaseRequest,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Status")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectStatusPurchaseRequest,
            callback: function callback($$v) {
              _vm.selectStatusPurchaseRequest = $$v;
            },
            expression: "selectStatusPurchaseRequest"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionPriority,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Priority")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectPriority,
            callback: function callback($$v) {
              _vm.selectPriority = $$v;
            },
            expression: "selectPriority"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionCompany,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Company")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectCompany,
            callback: function callback($$v) {
              _vm.selectCompany = $$v;
            },
            expression: "selectCompany"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Add Data',
            expression: "'Add Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              _vm.addPurchaseOrder = !_vm.addPurchaseOrder;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        })]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Refresh Data',
            expression: "'Refresh Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-refresh"
        })]), _c('b-dropdown', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Export Data',
            expression: "'Export Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "link iq-bg-primary",
            "toggle-class": "text-decoration-none",
            "no-caret": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-save m-0"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _vm._v("Export Excel")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf mr-2"
        }), _vm._v("Export PDF")])], 1)], 1)], 1)], 1), _c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("ORDER NUMBER")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ORDER TYPE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DATE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("VENDOR NAME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("QTY")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("REQ. NUMBER")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              _vm.detailPurchaseItem = !_vm.detailPurchaseItem;
            }
          }
        }, [_vm._v("PO0001")])]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary w-100"
          }
        }, [_vm._v("Request")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "success w-100"
          }
        }, [_vm._v("Open")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("10/28/2022")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Vehement Capital Maritim")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("10 Pcs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1.524.000")]), _c('td', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              _vm.detailPurchaseItem = !_vm.detailPurchaseItem;
            }
          }
        }, [_vm._v("REQ0001")])]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-list-alt m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-envelope mr-2"
        }), _vm._v("Send Email")]), _c('b-dropdown-item', {
          attrs: {
            "href": "../../assets/images/PURCHASE-DOC.pdf",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf mr-2"
        }), _vm._v("Export PDF")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-edit mr-2"
        }), _vm._v("Update")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("Delete")])], 1)], 1)])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('b-sidebar', {
          attrs: {
            "id": "detail_vendor",
            "title": "PO001 - TUGBOAT 1001",
            "width": "80%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var hide = _ref.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h6 text-primary"
              }, [_vm._v("General Information")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Order Number")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("REQ10001")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Company")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Vehement Capital")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Priority")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_c('b-badge', {
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("Normal")])], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Request Class")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("PURCHASE")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Department")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Technical Sangatta")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Location")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Sangatta")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Vendor")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Utama Production, CV")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Fleet")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("TUGBOAT 1001")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Request By")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Nurul Akbar")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Order Date")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("03/11/2022")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Project")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("COAL PROJECT")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Order By")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Promise Date")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("04/11/2022")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Sub Project")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Long Hauling")]), _c('th', {
                attrs: {
                  "width": "12%"
                }
              }, [_vm._v("Description")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Request Sparepart for TUGBOAT 1001")])])])])]), _c('tab-nav', {
                attrs: {
                  "tabs": true
                }
              }, [_c('tab-nav-items', {
                attrs: {
                  "active": true,
                  "id": "item-tab",
                  "ariaControls": "item",
                  "role": "tab",
                  "ariaSelected": true,
                  "title": "Item Detail"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "vendor-tab",
                  "ariaControls": "vendor",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Vendor Info"
                }
              })], 1), _c('div', {
                staticClass: "tab-content",
                attrs: {
                  "id": "myTabContent"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "item"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("INVENTORY ID")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("DESCRIPTION ITEM")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("QTY")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("UOM")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("PRICE")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("SUB TOTAL")])])]), _c('tbody', [_c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("[M001] - Engine Oil Filter")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Engine Oil Filter")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("10")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("PCS")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("225.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("2.250.000")])])])])])]), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "vendor",
                  "aria-labelled-by": "vendor-tab"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h6 text-primary"
              }, [_vm._v("Utama Maritim, CV")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "8"
                }
              }, [_c('table', {
                staticClass: "table table-borderless table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', [_vm._v("Address")]), _c('td', [_vm._v("Jalan H. Fachrudin No.36 ")]), _c('th', [_vm._v("Phone")]), _c('td', [_vm._v("021 – 326 632")])]), _c('tr', [_c('th', [_vm._v(" ")]), _c('td', [_vm._v("Jakarta Pusat, Indonesia")]), _c('th', [_vm._v("Ponsel / WA")]), _c('td', [_vm._v("081212143758")])]), _c('tr', [_c('th', [_vm._v("Post Code")]), _c('td', [_vm._v("12310")]), _c('th', [_vm._v("Email")]), _c('td', [_vm._v("info@gmail.com")])]), _c('tr', [_c('th', [_vm._v("Tax ID")]), _c('td', [_vm._v("31391734")]), _c('th', [_vm._v("Website")]), _c('td', [_vm._v("www.utamamaritim.com")])]), _c('tr', [_c('th', [_vm._v("PKP ID")]), _c('td', [_vm._v("427424268")]), _c('th', [_vm._v(" ")]), _c('td', [_vm._v(" ")])])])])]), _c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "12"
                }
              }, [_c('tab-nav', {
                attrs: {
                  "tabs": true
                }
              }, [_c('tab-nav-items', {
                attrs: {
                  "active": true,
                  "id": "first_tab",
                  "ariaControls": "tab_first",
                  "role": "tab",
                  "ariaSelected": true,
                  "title": "Additional Contact"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "second_tab",
                  "ariaControls": "tab_second",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Sales & Purchase"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "third_tab",
                  "ariaControls": "tab_third",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Accounting"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "fourth_tab",
                  "ariaControls": "tab_fourth",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Internal Notes"
                }
              })], 1), _c('div', {
                staticClass: "tab-content",
                attrs: {
                  "id": "myTabContent"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "tab_first"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "8"
                }
              }, [_c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3"
              }, [_vm._v("Nama")]), _c('td', [_vm._v("Nurul Akbar")]), _c('th', [_vm._v("Phone")]), _c('td', [_vm._v("021 – 326 632")])]), _c('tr', [_c('th', {
                staticClass: "pl-3"
              }, [_vm._v("Address")]), _c('td', [_vm._v("Jalan H. Fachrudin No.36 ")]), _c('th', [_vm._v("Ponsel")]), _c('td', [_vm._v("081212143758")])]), _c('tr', [_c('th', {
                staticClass: "pl-3"
              }, [_vm._v(" ")]), _c('td', [_vm._v(" ")]), _c('th', [_vm._v("Email")]), _c('td', [_vm._v("nurulakbar93@gmail.com")])])])])])], 1)], 1), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "tab_second",
                  "aria-labelled-by": "second_tab"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h6 text-primary"
              }, [_vm._v("Sales")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "40%"
                }
              }, [_vm._v("Seller")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "40%"
                }
              }, [_vm._v("Term Payment")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("7 Days")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "40%"
                }
              }, [_vm._v("Fiscal Postion")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("None")])])])])])]), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h6 text-primary"
              }, [_vm._v("Purchase")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "40%"
                }
              }, [_vm._v("Term Payment")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("7 Days")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "40%"
                }
              }, [_vm._v("Receipt Reminder")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Yes")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "40%"
                }
              }, [_vm._v("Company ID")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("EM001")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "40%"
                }
              }, [_vm._v("Industry")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Manufacture")])])])])])])], 1)], 1), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "tab_third",
                  "aria-labelled-by": "third_tab"
                }
              }, [_c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "40%"
                }
              }, [_vm._v("Bank")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Bank Centra Asia")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "40%"
                }
              }, [_vm._v("Rekening Number")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Yes")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "40%"
                }
              }, [_vm._v("Receivable")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("[112100001] - Account Receivable")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "40%"
                }
              }, [_vm._v("Trade Receivable")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("[113100002] - Trade Receivable")])])])])]), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "tab_fourth",
                  "aria-labelled-by": "fourth_tab"
                }
              }, [_c('p', {
                staticClass: "pl-3"
              }, [_vm._v("Utama Maritim, CV - Jalan H. Fachrudin No.36")])])], 1)], 1)], 1)], 1)])], 1)], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.detailPurchaseItem,
            callback: function callback($$v) {
              _vm.detailPurchaseItem = $$v;
            },
            expression: "detailPurchaseItem"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "addPurchaseOrder",
            "title": "ADD PURCHASE ORDER",
            "width": "80%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hide = _ref2.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h6 text-primary"
              }, [_vm._v("General Information")]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "primary"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Please Select Your "), _c('b', [_vm._v("Type Request Order")]), _vm._v(" From ")])]), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "options": _vm.optionReqType,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Order Type")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectReqType,
                  callback: function callback($$v) {
                    _vm.selectReqType = $$v;
                  },
                  expression: "selectReqType"
                }
              })], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "options": _vm.optionReqNumber,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Request Number")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectReqNumber,
                  callback: function callback($$v) {
                    _vm.selectReqNumber = $$v;
                  },
                  expression: "selectReqNumber"
                }
              })], 1)], 1)], 1), _c('b-row', {
                staticClass: "pl-3 pr-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Order Number",
                  "label-for": "orderNumber"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "orderNumber",
                  "placeholder": "<< GENERATE ORDER NUMBER >>"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Request Class",
                  "label-for": "requestClass"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionRequestClass,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Class")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectRequestClass,
                  callback: function callback($$v) {
                    _vm.selectRequestClass = $$v;
                  },
                  expression: "selectRequestClass"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Vendor",
                  "label-for": "vendor"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionVendor,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Vendor")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectVendor,
                  callback: function callback($$v) {
                    _vm.selectVendor = $$v;
                  },
                  expression: "selectVendor"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Order Date",
                  "label-for": "orderDate"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "date",
                  "id": "orderDate"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Promise Date",
                  "label-for": "promiseDate"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "date",
                  "id": "promiseDate"
                }
              })], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Company",
                  "label-for": "requestCompany"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionCompany,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Company")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectCompany,
                  callback: function callback($$v) {
                    _vm.selectCompany = $$v;
                  },
                  expression: "selectCompany"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Department",
                  "label-for": "requestDepartment"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionDepartment,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Department")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectDepartment,
                  callback: function callback($$v) {
                    _vm.selectDepartment = $$v;
                  },
                  expression: "selectDepartment"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Fleet",
                  "label-for": "requestFleet"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionFleet,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Fleet")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectFleet,
                  callback: function callback($$v) {
                    _vm.selectFleet = $$v;
                  },
                  expression: "selectFleet"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Project",
                  "label-for": "Project"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionProject,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Project")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectProject,
                  callback: function callback($$v) {
                    _vm.selectProject = $$v;
                  },
                  expression: "selectProject"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Sub Project",
                  "label-for": "requestSubProject"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionSubProject,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Sub Project")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectSubProject,
                  callback: function callback($$v) {
                    _vm.selectSubProject = $$v;
                  },
                  expression: "selectSubProject"
                }
              })], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Priority",
                  "label-for": "requestPriority"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionPriority,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Status")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectPriority,
                  callback: function callback($$v) {
                    _vm.selectPriority = $$v;
                  },
                  expression: "selectPriority"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Location",
                  "label-for": "requestLocation"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionLocation,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Location")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectLocation,
                  callback: function callback($$v) {
                    _vm.selectLocation = $$v;
                  },
                  expression: "selectLocation"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Request By",
                  "label-for": "requestBy"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionRequestBy,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Person")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectRequestBy,
                  callback: function callback($$v) {
                    _vm.selectRequestBy = $$v;
                  },
                  expression: "selectRequestBy"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Order By",
                  "label-for": "OrderBy"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionOrderBy,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Person")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectOrderBy,
                  callback: function callback($$v) {
                    _vm.selectOrderBy = $$v;
                  },
                  expression: "selectOrderBy"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Description",
                  "label-for": "description"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "description"
                }
              })], 1)], 1)], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h6 text-primary"
              }, [_vm._v("Item List")]), _c('b-button', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.top",
                  value: 'Add Data',
                  expression: "'Add Data'",
                  modifiers: {
                    "top": true
                  }
                }],
                staticClass: "float-right mr-1 mb-3",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                },
                on: {
                  "click": function click($event) {
                    _vm.addPurchaseOrder = !_vm.addPurchaseOrder;
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              })]), _c('b-button', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.top",
                  value: 'Edit Data',
                  expression: "'Edit Data'",
                  modifiers: {
                    "top": true
                  }
                }],
                staticClass: "float-right mr-1 mb-3",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                }
              }, [_c('i', {
                staticClass: "fa fa-edit"
              })]), _c('b-button', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.top",
                  value: 'Delete Data',
                  expression: "'Delete Data'",
                  modifiers: {
                    "top": true
                  }
                }],
                staticClass: "float-right mr-1 mb-3",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash"
              })]), _c('b-button', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.top",
                  value: 'Refresh Data',
                  expression: "'Refresh Data'",
                  modifiers: {
                    "top": true
                  }
                }],
                staticClass: "float-right mr-1 mb-3",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                }
              }, [_c('i', {
                staticClass: "fa fa-refresh"
              })]), _c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table"
              }, [_c('thead', [_c('tr', {
                staticClass: "table-success"
              }, [_c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._l(_vm.state, function (item, index) {
                return [_c('b-checkbox', {
                  key: index,
                  staticClass: "custom-switch-color",
                  attrs: {
                    "color": _vm.primary,
                    "disabled": item.disabled,
                    "name": "check-button",
                    "inline": ""
                  },
                  model: {
                    value: item.checked,
                    callback: function callback($$v) {
                      _vm.$set(item, "checked", $$v);
                    },
                    expression: "item.checked"
                  }
                })];
              })], 2), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("ITEM ID")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("DESCRIPTION ITEM")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v("QTY")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v("UOM")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("@PRICE")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v("DISCOUNT")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("TOTAL")])])]), _c('tbody', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._l(_vm.state, function (item, index) {
                return [_c('b-checkbox', {
                  key: index,
                  staticClass: "custom-switch-color",
                  attrs: {
                    "color": _vm.primary,
                    "disabled": item.disabled,
                    "name": "check-button",
                    "inline": ""
                  },
                  model: {
                    value: item.checked,
                    callback: function callback($$v) {
                      _vm.$set(item, "checked", $$v);
                    },
                    expression: "item.checked"
                  }
                })];
              })], 2), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionInventoryID
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Item ID")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectInventoryID,
                  callback: function callback($$v) {
                    _vm.selectInventoryID = $$v;
                  },
                  expression: "selectInventoryID"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('a', {
                attrs: {
                  "href": ""
                }
              }, [_vm._v("[M001] - Engine Oil Filter")])]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-right",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty",
                  "placeholder": "10"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-select', {
                attrs: {
                  "plain": ""
                }
              }, [_c('b-form-select-option', [_vm._v("Pcs")]), _c('b-form-select-option', [_vm._v("Meter")]), _c('b-form-select-option', [_vm._v("Box")]), _c('b-form-select-option', [_vm._v("Lusin")])], 1)], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "price"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "discount"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "total"
                }
              })], 1)])])])])], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.addPurchaseOrder,
            callback: function callback($$v) {
              _vm.addPurchaseOrder = $$v;
            },
            expression: "addPurchaseOrder"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }