var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.detailMi ? _c('div', [_c('div', {
    staticClass: "accessor-of-the-incident mb-5"
  }, [_c('b-row', {
    staticClass: "mb-4 position-relative"
  }, [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("FINAL REPORT ROOT CAUSE ANALYSIS")])])], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "7"
    }
  }, [_c('table', {
    staticClass: "w-100 mb-3",
    attrs: {
      "border": "1"
    }
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "color": "#ffe89a"
    },
    attrs: {
      "width": "60%",
      "bgcolor": "#1e90ff"
    }
  }, [_vm._v("Assessor of the Accident")]), _c('td', {
    attrs: {
      "rowspan": "2"
    }
  }, [_c('table', {
    staticClass: "w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Report Number")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v("MI-03/III/2021/HM")])]), _c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Vessel")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.detailMi.vehicle.name))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Date of Loss")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.rootCauseAnalysis.date_of_loss))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Place of Loss")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.detailMi.place_of_loss))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Type of Loss")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.detailMi.type_loss.name))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Type of Coverage")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.detailMi.type_coverage.name))])])])])])]), _c('tr', [_c('td', [_c('table', {
    staticClass: "w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Name")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.rootCauseAnalysis.assessor_fullname))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Company")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.company ? _vm.company.text : '-'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Current Position")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v("Junior Manager")])])])])])]), _c('tr', [_c('th', {
    staticClass: "text-center",
    staticStyle: {
      "color": "#ffe89a"
    },
    attrs: {
      "bgcolor": "#1e90ff",
      "colspan": "3"
    }
  }, [_vm._v("all data belongs to Marine Insurance Departement Group Trans Energi Logistik ")])])]), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.form.images, function (img) {
    return _c('img', {
      staticStyle: {
        "width": "auto",
        "max-width": "100%"
      },
      attrs: {
        "src": img,
        "alt": ""
      }
    });
  }), 0), _c('table', {
    staticClass: "w-100 mb-3",
    attrs: {
      "border": "1"
    }
  }, [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "100%",
      "bgcolor": "aqua"
    }
  }, [_vm._v(" Never Judge the case without knowing the whole Story, Only God Can Judge Me and How this case Ended ")])])]), _c('table', {
    staticClass: "w-100 mb-3"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" REFERENCE & CHECKLIST : ")])]), _c('tr', [_c('td', {
    attrs: {
      "width": "80%"
    }
  }, [_vm._v(" THE DEFINE WORIKSHEET : PROBLEM STATEMENT WORKSHEET ")]), _c('td', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v(" V ")])]), _c('tr', [_c('td', {
    attrs: {
      "width": "80%"
    }
  }, [_vm._v(" COLLECTING IMFORMATION : THE 5-WHYs WORKSHEET & COMPARATIVE ANALYSIS WORKSHEET ")]), _c('td', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v(" V ")])]), _c('tr', [_c('td', {
    attrs: {
      "width": "80%"
    }
  }, [_vm._v(" INDETIFY THE ROOT CAUSE : FAILURE MODE AND EFFECT ANALYSIS (FMEA) ")]), _c('td', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v(" V ")])]), _c('tr', [_c('td', {
    attrs: {
      "width": "80%"
    }
  }, [_vm._v(" IMPLEMENT CORRECTIVE ACTIONS ")]), _c('td', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v(" V ")])]), _c('tr', [_c('td', {
    attrs: {
      "width": "80%"
    }
  }, [_vm._v(" CAUSE AND EFFECT DIAGRAM (FISH BONE) ")]), _c('td', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v(" V ")])]), _c('tr', [_c('td', {
    staticClass: "pt-5"
  }, [_c('table', {
    staticClass: "w-50"
  }, [_c('tr', [_c('td', {
    attrs: {
      "width": "60%"
    }
  }, [_vm._v("Starting Date")]), _c('td', [_vm._v(_vm._s(_vm.form.start_date || '-'))])]), _c('tr', [_c('td', [_vm._v("Closing Date")]), _c('td', [_vm._v(_vm._s(_vm.form.closing_date || '-'))])])])]), _c('td', [_c('table', {
    staticClass: "w-25"
  }, [_c('tr', [_c('td', {
    staticClass: "confidential"
  }, [_vm._v("CONFIDENTIAL")])])])])]), _c('tr', {
    staticClass: "pt-5 pb-5"
  }, [_c('td', {
    staticClass: "without-prejudice text-center",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" \"WITHOUT PREJUDICE\" ")])]), _c('tr', {
    staticClass: "pt-5"
  }, [_c('td', {
    staticClass: "footer-info text-center",
    attrs: {
      "colspan": "2"
    }
  }, [_c('h5', [_vm._v("MARINE INSURANCE DEPARTEMENT OF GROUP TRANS ENERGI LOGISTIK")]), _c('div', [_vm._v(" Bakrie Tower, 9th Floor, Complex Rasuna Said, Jln H.R RASUNA SAID, Kuningan, Jakarta Selatan, Indonesia, 12940 ")])])])])])], 1)], 1), _c('div', {
    staticClass: "daftar-isi d-none mb-5"
  }, [_c('b-row', {
    staticClass: "mb-4 position-relative"
  }, [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("DAFTAR ISI")])])], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "7"
    }
  }, [_c('table', {
    staticClass: "w-100"
  }, [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("A")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("INVESTIGATION DETAILS")]), _c('td', {
    attrs: {
      "colspan": "2"
    }
  })]), _c('tr', [_c('td', [_vm._v("A.1")]), _c('td', [_vm._v("Investigation Team")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("3")])]), _c('tr', [_c('td', [_vm._v("A.2")]), _c('td', [_vm._v("Direct Witness")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("3")])]), _c('tr', [_c('td', [_vm._v("A.3")]), _c('td', [_vm._v("Indirect Witness")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("3")])]), _c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("B")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("SUPPORTING DOCUMENT")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("3")])]), _c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("C")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("DETAILS OF INVESTIGATION")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("4")])]), _c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("D")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("SUMMARY OF ACCIDENT")]), _c('td', {
    attrs: {
      "colspan": "2"
    }
  })]), _c('tr', [_c('td', [_vm._v("D.1")]), _c('td', [_vm._v("The Problem")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("5")])]), _c('tr', [_c('td', [_vm._v("D.2")]), _c('td', [_vm._v("Cause of Loss")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("5")])]), _c('tr', [_c('td', [_vm._v("D.3")]), _c('td', [_vm._v("Impact of Damage")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("5")])]), _c('tr', [_c('td', [_vm._v("D.4")]), _c('td', [_vm._v("Cost of Damage")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("5")])]), _c('tr', [_c('td', [_vm._v("D.5")]), _c('td', [_c('strong', [_vm._v("Cause & Effect (Findings)")])]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("5")])]), _c('tr', [_c('td', [_vm._v("D.5.1")]), _c('td', [_vm._v("Human")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("6")])]), _c('tr', [_c('td', [_vm._v("D.5.2")]), _c('td', [_vm._v("Machinery/Equipment etc.")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("6")])]), _c('tr', [_c('td', [_vm._v("D.5.3")]), _c('td', [_vm._v("Control, System, Method,Process …")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("6")])]), _c('tr', [_c('td', [_vm._v("D.5.4")]), _c('td', [_vm._v("Other Factor")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("7")])]), _c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("E")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("CLAIMS OPPORTUNITY")]), _c('td', {
    attrs: {
      "colspan": "2"
    }
  })]), _c('tr', [_c('td', [_vm._v("E.1")]), _c('td', [_vm._v("Initial Claim Analysis")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("8")])]), _c('tr', [_c('td', [_vm._v("E.2")]), _c('td', [_vm._v("Document Control ")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("8")])]), _c('tr', [_c('td', [_vm._v("E.3")]), _c('td', [_vm._v("Cost of Loss ")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("9")])]), _c('tr', [_c('td', [_vm._v("E.4")]), _c('td', [_vm._v("Impact to Loss Ratio ")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("9")])]), _c('tr', [_c('td', [_vm._v("E.5")]), _c('td', [_vm._v(" Percentage of Claims Handling ")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("9")])]), _c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("F")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("CONCLUSION")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("9")])]), _c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("G")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("RECOMMENDATION")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, _vm._l(180, function (i) {
    return _c('span', [_vm._v(".")]);
  }), 0), _c('td', {
    staticClass: "text-right",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("10")])])])])], 1)], 1), _c('div', {
    staticClass: "investigation-details mb-5"
  }, [_c('b-row', {
    staticClass: "mb-4 position-relative"
  }, [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("A. INVESTIGATION DETAILS")])])], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("A.1 Investigation Team")])]), _c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Team List")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Name")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Position")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Remarks")])])]), _c('tbody', _vm._l(_vm.form.investigation_teams, function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(data.team || '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.name || '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.position || '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.remarks || '-') + " ")])]);
  }), 0)])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("A.2 Direct Witness")])]), _c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Name")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Position")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Remarks")])])]), _c('tbody', _vm._l(_vm.form.direct_witnesses, function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.name : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.position : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.remarks : '-') + " ")])]);
  }), 0)])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("A.3 Indirect Witness")])]), _c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Name")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Position")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Remarks")])])]), _c('tbody', _vm._l(_vm.form.indirect_witnesses, function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.name : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.position : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.remarks : '-') + " ")])]);
  }), 0)])])])], 1)], 1), _c('div', {
    staticClass: "supporting-document mb-5"
  }, [_c('b-row', {
    staticClass: "mb-4 position-relative"
  }, [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("B. SUPPORTING DOCUMENT")])])], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "7"
    }
  }, [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Document")]), _c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("Original")]), _c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("Copy")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("PIC")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Remarks")])])]), _c('tbody', _vm._l(_vm.form.supporting_documents, function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm._v(" " + _vm._s(data.document) + " ")]), _c('td', [_vm._v(" " + _vm._s(data.original || '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.copy || '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.pic || '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.remarks || '-') + " ")])]);
  }), 0)])])], 1)], 1), _c('div', {
    staticClass: "detailf-of-investigation mb-5"
  }, [_c('b-row', {
    staticClass: "mb-4 position-relative"
  }, [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("C. DETAILS OF INVESTIGATION")])])], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.form.images, function (img) {
    return _c('img', {
      staticStyle: {
        "width": "auto",
        "max-width": "100%"
      },
      attrs: {
        "src": img,
        "alt": ""
      }
    });
  }), 0)])], 1)], 1), _c('div', {
    staticClass: "summary-of-accident mb-5"
  }, [_c('b-row', {
    staticClass: "mb-4 position-relative"
  }, [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("D. SUMMARY OF ACCIDENT")])])], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("D.1 The Problem")])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('table', {
    staticClass: "table w-100 table-bordered"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "25%",
      "bgcolor": "#ffd700"
    }
  }, [_vm._v("THE PROBLEM ")]), _c('td', {
    attrs: {
      "width": "75%"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.problem) + " ")])])])])])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("D.2 Cause of Loss")])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('table', {
    staticClass: "table w-100 table-bordered"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "25%",
      "bgcolor": "#ffd700"
    }
  }, [_vm._v("CAUSE OF LOSS ")]), _c('td', {
    attrs: {
      "width": "75%"
    }
  })]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.cause_of_loss) + " ")])])])])])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("D.3 Impact of Damage")])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('table', {
    staticClass: "table w-100 table-bordered"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "25%",
      "bgcolor": "#ffd700"
    }
  }, [_vm._v("IMPACT OF DAMAGE")]), _c('td', {
    attrs: {
      "width": "75%"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.impact) + " ")])])])])])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("D.4 Cost of Damage")])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('table', {
    staticClass: "w-100 mb-3"
  }, [_c('tr', [_c('td', [_c('strong', [_vm._v("ESTIMATION DAMAGE AND LOSS VALUE")])]), _c('td', {
    staticClass: "text-right"
  })]), _c('tr', [_c('td', [_c('strong', [_vm._v("LIST OF MATERIAL AND COST ")])]), _c('td', {
    staticClass: "text-right"
  }, [_vm._v("VESSEL: TB ETI 309")])])]), _c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Description")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Cost")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Total")]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Remarks")])])]), _c('tbody', _vm._l(_vm.form.damage_costs, function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm._v(" " + _vm._s(data.description || '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.cost ? "Rp".concat(_vm.numberFormat(data.cost)) : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.total ? "Rp".concat(_vm.numberFormat(data.total)) : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.remarks || '-') + " ")])]);
  }), 0), _c('tfoot', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#ffd700",
      "colspan": "2"
    }
  }, [_vm._v(" TOTAL ")]), _c('th', {
    attrs: {
      "bgcolor": "#ffd700"
    }
  }, [_vm._v(" Rp." + _vm._s(_vm.numberFormat(_vm.costOfDamagesSumCost)) + " ")]), _c('th', {
    attrs: {
      "bgcolor": "#ffd700"
    }
  }, [_vm._v(" Rp." + _vm._s(_vm.numberFormat(_vm.costOfDamagesSumTotal)) + " ")]), _c('th', {
    attrs: {
      "bgcolor": "#ffd700"
    }
  })])])])])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("D.5 Cause & Effect (Findings) - For Details please see attachment of Fishbone Diagram for each Witness ")])])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("D.5.1 Human ")])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('table', {
    staticClass: "w-100"
  }, [_c('tr', [_c('td', {
    attrs: {
      "width": "80%"
    }
  }, [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v("Findings")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Proximity Level")])])]), _c('tbody', _vm._l(_vm.findingsByPrefix(1), function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(data.value) + " ")]), _c('td', [_vm._v(" " + _vm._s(data.text) + " ")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(data.proximity) + " ")])]);
  }), 0)])]), _c('td', {
    staticStyle: {
      "vertical-align": "baseline"
    },
    attrs: {
      "width": "20%"
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/level-priority.png",
      "alt": ""
    }
  })])])])])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("D.5.2 Machinery/Equipment etc. ")])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('table', {
    staticClass: "w-100"
  }, [_c('tr', [_c('td', {
    attrs: {
      "width": "80%"
    }
  }, [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v("Findings")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Proximity Level")])])]), _c('tbody', _vm._l(_vm.findingsByPrefix(2), function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(data.value) + " ")]), _c('td', [_vm._v(" " + _vm._s(data.text) + " ")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(data.proximity) + " ")])]);
  }), 0)])]), _c('td', {
    staticStyle: {
      "vertical-align": "baseline"
    },
    attrs: {
      "width": "20%"
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/level-priority.png",
      "alt": ""
    }
  })])])])])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("D.5.3 Control, System, Method,Process ")])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('table', {
    staticClass: "w-100"
  }, [_c('tr', [_c('td', {
    attrs: {
      "width": "80%"
    }
  }, [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v("Findings")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Proximity Level")])])]), _c('tbody', _vm._l(_vm.findingsByPrefix(3), function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(data.value) + " ")]), _c('td', [_vm._v(" " + _vm._s(data.text) + " ")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(data.proximity) + " ")])]);
  }), 0)])]), _c('td', {
    staticStyle: {
      "vertical-align": "baseline"
    },
    attrs: {
      "width": "20%"
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/level-priority.png",
      "alt": ""
    }
  })])])])])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("D.5.4 Other Factor ")])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('table', {
    staticClass: "w-100"
  }, [_c('tr', [_c('td', {
    attrs: {
      "width": "80%"
    }
  }, [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v("Findings")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Proximity Level")])])]), _c('tbody', _vm._l(_vm.findingsByPrefix(4), function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(data.value) + " ")]), _c('td', [_vm._v(" " + _vm._s(data.text) + " ")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(data.proximity) + " ")])]);
  }), 0)])]), _c('td', {
    staticStyle: {
      "vertical-align": "baseline"
    },
    attrs: {
      "width": "20%"
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/level-priority.png",
      "alt": ""
    }
  })])])])])])])], 1)], 1), _c('div', {
    staticClass: "claims-opportunity mb-5"
  }, [_c('b-row', {
    staticClass: "mb-4 position-relative"
  }, [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("E. CLAIMS OPPORTUNITY")])])], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("E.1 Initial Claim Analysis")])]), _vm.detailSurvey ? _c('div', [_c('b-table-simple', {
    attrs: {
      "responsive": "",
      "width": "100%",
      "striped": ""
    }
  }, [_c('b-thead', [_c('b-th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("NO")]), _vm._l(_vm.detailSurvey.headers, function (header, headerIndex) {
    return _c('b-th', [_vm._v(_vm._s(header.label_name))]);
  })], 2), _vm.detailSurvey.headers[0] ? _c('b-tbody', [_vm._l(_vm.detailSurvey.headers[0].contents.length, function (number) {
    return _c('b-tr', [_c('b-td', [_vm._v(_vm._s(number))]), _vm._l(_vm.detailSurvey.headers, function (header, headerIndex) {
      return _c('b-td', [header.contents[number - 1] ? [header.contents[number - 1].text ? [_vm._v(" " + _vm._s(header.contents[number - 1].text) + " ")] : header.contents[number - 1].input ? [_vm._v(" " + _vm._s(header.contents[number - 1].input.value) + " ")] : [_vm._v(" - ")]] : _vm._e()], 2);
    })], 2);
  }), _c('b-tr', {
    staticClass: "iq-bg-primary"
  }, [_c('b-td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "3"
    }
  }, [_c('h5', [_c('b', [_vm._v("Nilai Seluruhnya")])])]), _c('b-td', {
    staticClass: "text-center"
  }, [_c('h5', [_c('b', [_vm._v(_vm._s(_vm.totalAll))])])]), _c('b-td', [_vm._v(" ")])], 1)], 2) : _vm._e()], 1)], 1) : _vm._e()]), _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("E.2 Document Control")])]), _c('div', {
    staticClass: "mb-3"
  }, [_vm._v(" Saat dilakukan joint survey seluruh dokumen sudah dicek dan clear, sehingga tidak ada \" hot issue\" dalam calaim ini ")]), _c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Document")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Remarks")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Status")])])]), _c('tbody', _vm._l(_vm.form.document_controls, function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm._v(" " + _vm._s(data.document) + " ")]), _c('td', [_vm._v(" " + _vm._s(data.remarks) + " ")]), _c('td', [_c('strong', [_vm._v(_vm._s(data.status))])])]);
  }), 0)])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("E.3 Cost of Loss")])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Description")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Cost")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Total")]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Remarks")])])]), _c('tbody', _vm._l(_vm.form.loss_costs, function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm._v(" " + _vm._s(data.description || '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.cost ? "Rp".concat(_vm.numberFormat(data.cost)) : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.total ? "Rp".concat(_vm.numberFormat(data.total)) : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.remarks || '-') + " ")])]);
  }), 0), _c('tfoot', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#ffd700",
      "colspan": "2"
    }
  }, [_vm._v(" TOTAL ")]), _c('th', {
    attrs: {
      "bgcolor": "#ffd700"
    }
  }, [_vm._v(" Rp." + _vm._s(_vm.numberFormat(_vm.costOfLossSumCost)) + " ")]), _c('th', {
    attrs: {
      "bgcolor": "#ffd700"
    }
  }, [_vm._v(" Rp." + _vm._s(_vm.numberFormat(_vm.costOfLossSumTotal)) + " ")]), _c('th', {
    attrs: {
      "bgcolor": "#ffd700"
    }
  })])])])])]), _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("E.4 Impact to Loss Ratio")])]), _c('div', {
    staticClass: "mb-3"
  })]), _c('div', {
    staticClass: "mb-3"
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('h5', [_vm._v("E.5 Percentage of Claims Handling ")])]), _c('div', {
    staticClass: "mb-3"
  })])])], 1)], 1), _c('div', {
    staticClass: "conclusion mb-5"
  }, [_c('b-row', {
    staticClass: "mb-4 position-relative"
  }, [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("F. CONCLUSION")])])], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "11"
    }
  }, [_c('table', {
    staticClass: "w-100 table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      "rowspan": "2",
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      "rowspan": "2",
      "width": "25%"
    }
  }, [_vm._v("Damage")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4",
      "width": "40%"
    }
  }, [_vm._v("Root Cause & Effect (Findings) ")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      "rowspan": "2",
      "width": "10%"
    }
  }, [_vm._v("Status")])]), _c('tr', [_c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Human")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Machinery/ Equipment ")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(" Control, System, Method,Process ")]), _c('th', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(" Other Factor ")])])]), _c('tbody', [_vm._l(_vm.form.conclusions, function (data, index) {
    return _vm._l(data.rows, function (row, indexRow) {
      return _c('tr', [indexRow === 0 ? _c('td', {
        staticClass: "text-center",
        attrs: {
          "rowspan": data.rows.length
        }
      }, [_vm._v(" " + _vm._s(index + 1) + " ")]) : _vm._e(), indexRow === 0 ? _c('td', {
        attrs: {
          "rowspan": data.rows.length
        }
      }, [_vm._v(" " + _vm._s(data.damage) + " ")]) : _vm._e(), _c('td', {
        staticClass: "text-center"
      }, [_vm._v(" " + _vm._s(row.human) + " ")]), _c('td', {
        staticClass: "text-center"
      }, [_vm._v(" " + _vm._s(row.equipment) + " ")]), _c('td', {
        staticClass: "text-center"
      }, [_vm._v(" " + _vm._s(row.csmp) + " ")]), _c('td', {
        staticClass: "text-center"
      }, [_vm._v(" " + _vm._s(row.other) + " ")]), _c('td', {
        staticClass: "text-center",
        class: {
          'bg-danger text-white': row.status === 'fatal',
          'bg-warning text-white': row.status === 'danger',
          'bg-info text-white': row.status === 'alert',
          'bg-success text-white': row.status === 'normal'
        }
      }, [_vm._v(" " + _vm._s(row.status) + " ")])]);
    });
  })], 2)])])], 1)], 1), _c('div', {
    staticClass: "recommendation mb-5"
  }, [_c('b-row', {
    staticClass: "mb-4 position-relative"
  }, [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("G. RECOMMENDATION")])])], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "7"
    }
  }, [_c('table', {
    staticClass: "w-100 table"
  }, [_c('thead', [_c('tr', [_c('td', {
    attrs: {
      "width": "10%"
    }
  }), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "30%",
      "bgcolor": "#ffd700"
    }
  }, [_vm._v("HELPFUL")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "30%",
      "bgcolor": "#ffd700"
    }
  }, [_vm._v("HARMFUL")])])]), _c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center text-white",
    attrs: {
      "bgcolor": "#a9a9a9"
    }
  }, [_vm._v(" INTERNAL ")]), _c('td', {
    staticStyle: {
      "vertical-align": "top"
    }
  }, [_c('div', [_c('strong', [_vm._v("STRENGTH")])]), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.form.recommendation.strength)
    }
  })]), _c('td', {
    staticStyle: {
      "vertical-align": "top"
    }
  }, [_c('div', [_c('strong', [_vm._v("WEAKNESS")])]), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.form.recommendation.weakness)
    }
  })])]), _c('tr', [_c('th', {
    staticClass: "text-center text-white",
    attrs: {
      "bgcolor": "#8b4513"
    }
  }, [_vm._v(" EXTERNAL ")]), _c('td', {
    staticStyle: {
      "vertical-align": "top"
    }
  }, [_c('div', [_c('strong', [_vm._v("OPPORTUNITY")])]), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.form.recommendation.opportunity)
    }
  })]), _c('td', {
    staticStyle: {
      "vertical-align": "top"
    }
  }, [_c('div', [_c('strong', [_vm._v("THREAT")])]), _c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.form.recommendation.threat)
    }
  })])])])])])], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }