<template>
  <div>
    <h1 class="mb-0">Reset Password</h1>
    <p>
      Enter your email address and we'll send you an email with instructions to
      reset your password.
    </p>
    <form class="mt-5" @submit.prevent="doResetPassword()">
      <div class="form-group">
        <label for="exampleInputEmail1">Email address</label>
        <input
          type="email"
          class="form-control mb-0"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Enter email"
          v-model="email"
        />
      </div>

      <div class="d-inline-block w-100">
        <button type="submit" class="btn btn-primary float-right">
          <template v-if="!loading"> Reset Password </template>
          <template v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Loading...</span>
          </template>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "RecoverPassword1",
  data() {
    return {
      email: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions("Auth", ["forgotPassword"]),
    async doResetPassword() {
      this.loading = true;

      if (!this.email) {
        this.loading = false;
        this.$swal(
          "Email is required",
          "Please fill the email, so we can send your reset password link.",
          "error"
        );
      } else {
        var urlencoded = new URLSearchParams();
        urlencoded.append("email", this.email);

        let res = await this.forgotPassword(urlencoded);
        if (res.status == "success") {
          this.loading = false;
          this.$swal(
            "Link sent successfully!",
            "Check your email for further instruction to reset password",
            "success"
          );
        } else {
          this.loading = false;
          if (res.data.message != null)
            this.$swal("Error", res.data.message, "error");
          else
            this.$swal(
              "Error",
              "Something wrong with the server. Please try again later.",
              "error"
            );
        }
      }
    },
  },
};
</script>
