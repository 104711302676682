var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Tooltips")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-1",
            modifiers: {
              "collapse-1": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Hover over the buttons below to see the four tooltips directions: top, right, bottom, and left. The data-placement attribute specifies the tooltip position.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-1"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "tooltips-1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button v-b-tooltip.top=\"'Tooltip on top'\" variant=\"secondary\" class=\"mt-3 mr-1\">Tooltip on top</b-button>\n<b-button v-b-tooltip.right=\"'Tooltip on right'\" variant=\"secondary\" class=\"mt-3 mr-1\">Tooltip on right</b-button>\n<b-button v-b-tooltip.bottom=\"'Tooltip on bottom'\" variant=\"secondary\" class=\"mt-3 mr-1\">Tooltip on bottom</b-button>\n<b-button v-b-tooltip.left=\"'Tooltip on left'\" variant=\"secondary\" class=\"mt-3 mr-1\">Tooltip on left</b-button>\n")])])])])]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Tooltip on top',
            expression: "'Tooltip on top'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "mt-3 mr-1",
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("Tooltip on top")]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.right",
            value: 'Tooltip on right',
            expression: "'Tooltip on right'",
            modifiers: {
              "right": true
            }
          }],
          staticClass: "mt-3 mr-1",
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("Tooltip on right")]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.bottom",
            value: 'Tooltip on bottom',
            expression: "'Tooltip on bottom'",
            modifiers: {
              "bottom": true
            }
          }],
          staticClass: "mt-3 mr-1",
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("Tooltip on bottom")]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.left",
            value: 'Tooltip on left',
            expression: "'Tooltip on left'",
            modifiers: {
              "left": true
            }
          }],
          staticClass: "mt-3 mr-1",
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("Tooltip on left")])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Disabled elements")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-2",
            modifiers: {
              "collapse-2": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Elements with the "), _c('code', [_vm._v("disabled")]), _vm._v(" attribute aren’t interactive, meaning users cannot focus, hover, or click them to trigger a tooltip (or popover). As a workaround, you’ll want to trigger the tooltip from a wrapper "), _c('code', [_vm._v("<div>")]), _vm._v(" or "), _c('code', [_vm._v("<span>")]), _vm._v(", ideally made keyboard-focusable using "), _c('code', [_vm._v("tabindex=\"0\"")]), _vm._v(", and override the "), _c('code', [_vm._v("pointer-events")]), _vm._v(" on the disabled element.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-2"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "disabled-1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<span class=\"d-inline-block\" tabindex=\"0\" v-b-tooltip.top title=\"Disabled tooltip\">\n  <b-button variant=\"primary\" style=\"pointer-events: none;\" disabled>Disabled button</b-button>\n</span>\n")])])])])]), _c('span', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "d-inline-block",
          attrs: {
            "tabindex": "0",
            "title": "Disabled tooltip"
          }
        }, [_c('b-button', {
          staticStyle: {
            "pointer-events": "none"
          },
          attrs: {
            "variant": "primary",
            "disabled": ""
          }
        }, [_vm._v("Disabled button")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Tooltips")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-3",
            modifiers: {
              "collapse-3": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Hover over the buttons below to see the four tooltips directions: top, right, bottom, and left Using background colors")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-3"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "tooltips-2"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n <b-button v-b-tooltip.top=\"'Tooltip on top'\" variant=\"primary\" class=\"mt-3 mr-1\">Tooltip on top</b-button>\n<b-button v-b-tooltip.right=\"'Tooltip on right'\" variant=\"success\" class=\"mt-3 mr-1\">Tooltip on right</b-button>\n<b-button v-b-tooltip.bottom=\"'Tooltip on bottom'\" variant=\"danger\" class=\"mt-3 mr-1\">Tooltip on bottom</b-button>\n<b-button v-b-tooltip.left=\"'Tooltip on left'\" variant=\"info\" class=\"mt-3 mr-1\">Tooltip on left</b-button>\n")])])])])]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Tooltip on top',
            expression: "'Tooltip on top'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "mt-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Tooltip on top")]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.right",
            value: 'Tooltip on right',
            expression: "'Tooltip on right'",
            modifiers: {
              "right": true
            }
          }],
          staticClass: "mt-3 mr-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("Tooltip on right")]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.bottom",
            value: 'Tooltip on bottom',
            expression: "'Tooltip on bottom'",
            modifiers: {
              "bottom": true
            }
          }],
          staticClass: "mt-3 mr-1",
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Tooltip on bottom")]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.left",
            value: 'Tooltip on left',
            expression: "'Tooltip on left'",
            modifiers: {
              "left": true
            }
          }],
          staticClass: "mt-3 mr-1",
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Tooltip on left")])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Tooltips")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-4",
            modifiers: {
              "collapse-4": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Hover over the buttons below to see the four tooltips directions: top, right, bottom, and left Using Light background colors")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-4"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "tooltips-2"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button v-b-tooltip.top=\"'Tooltip on top'\" variant=\"none\" class=\"iq-bg-primary mt-3\">Tooltip on top</b-button>\n<b-button v-b-tooltip.right=\"'Tooltip on right'\" variant=\"none\" class=\"iq-bg-success mt-3\">Tooltip on right</b-button>\n<b-button v-b-tooltip.bottom=\"'Tooltip on bottom'\" variant=\"none\" class=\"iq-bg-danger mt-3\">Tooltip on bottom</b-button>\n<b-button v-b-tooltip.left=\"'Tooltip on left'\" variant=\"none\" class=\"iq-bg-info mt-3\">Tooltip on left</b-button>\n")])])])])]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Tooltip on top',
            expression: "'Tooltip on top'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "iq-bg-primary mt-3 mr-1",
          attrs: {
            "variant": "none"
          }
        }, [_vm._v("Tooltip on top")]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.right",
            value: 'Tooltip on right',
            expression: "'Tooltip on right'",
            modifiers: {
              "right": true
            }
          }],
          staticClass: "iq-bg-success mt-3 mr-1",
          attrs: {
            "variant": "none"
          }
        }, [_vm._v("Tooltip on right")]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.bottom",
            value: 'Tooltip on bottom',
            expression: "'Tooltip on bottom'",
            modifiers: {
              "bottom": true
            }
          }],
          staticClass: "iq-bg-danger mt-3 mr-1",
          attrs: {
            "variant": "none"
          }
        }, [_vm._v("Tooltip on bottom")]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.left",
            value: 'Tooltip on left',
            expression: "'Tooltip on left'",
            modifiers: {
              "left": true
            }
          }],
          staticClass: "iq-bg-info mt-3 mr-1",
          attrs: {
            "variant": "none"
          }
        }, [_vm._v("Tooltip on left")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Hover elements")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-5",
            modifiers: {
              "collapse-5": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Hover over the buttons below to see the tooltip.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-5"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "tooltips-5"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button href=\"#\" v-b-tooltip.hover=\"'Some tooltip text!'\" variant=\"link\" >Hover over me</b-button>\n")])])])])]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Some tooltip text!',
            expression: "'Some tooltip text!'",
            modifiers: {
              "hover": true
            }
          }],
          attrs: {
            "href": "#",
            "variant": "link"
          }
        }, [_vm._v("Hover over me")])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }