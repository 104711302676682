<template>
  <b-container fluid v-if="dockingDetail && fleetDetail">
    <form @submit.prevent="submit">
      <b-row>
        <b-col lg="4">
          <iq-card class-name="iq-user-profile-block1">
            <template v-slot:body>
              <div class="user-details-block">
                <div class="user-profile text-center">
                  <img src="../../assets/images/ship.png" alt="profile-img" class="avatar-130 img-fluid">
                </div>
                <div class="text-center mt-3">
                  <h4><b>{{ fleetDetail.name }}</b></h4>
                  <b-badge variant="info">{{ fleetDetail.company.company }}</b-badge>
                </div>
              </div>
              <table class="table table-borderless mt-3">
                <tbody>
                <tr class="bg-primary">
                  <td colspan="2" class="text-center"><strong>SHIP PARTICULAR</strong></td>
                </tr>
                <tr>
                  <td><strong>Type Kapal</strong></td>
                  <td>{{ fleetDetail.vehicleType.name }}</td>
                </tr>
                <tr>
                  <td><strong>Bendera</strong></td>
                  <td>{{ fleetDetail.flag }}</td>
                </tr>
                <tr>
                  <td><strong>Klasifikasi</strong></td>
                  <td>{{ fleetDetail.classification }}</td>
                </tr>
                <tr>
                  <td><strong>Tahun Pembuatan</strong></td>
                  <td>-</td>
                </tr>
                <tr>
                  <td><strong>Tempat Pembangunan</strong></td>
                  <td>{{ fleetDetail.yearBuildCountry }}</td>
                </tr>
                <tr>
                  <td><strong>Tempat Pendaftaran</strong></td>
                  <td>-</td>
                </tr>
                <tr>
                  <td><strong>Ukuran Kapal</strong></td>
                  <td>{{ fleetDetail.dimension.width.meter }} x {{ fleetDetail.dimension.length.meter }} x {{ fleetDetail.dimension.depth.meter }} meter</td>
                </tr>
                <tr>
                  <td><strong>Main Engine</strong></td>
                  <td>
                    <ul class="text-left">
                      <li v-for="power in fleetDetail.powers">{{power.powerType ? power.powerType.name : '-'}} - {{power.powerSeries ? power.powerSeries.name : '-'}} {{power.powerSeries ? power.powerSeries.model : '-'}} <span class="pull-right">Actual RH : <b-badge variant="info"><strong>{{power.actualRunningHours}} RH</strong></b-badge></span></li>
                    </ul>
                  </td>
                </tr>
                </tbody>
              </table>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="8">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">SERAH TERIMA</h4>
            </template>
            <template v-slot:body>
              <div style="overflow-x: scroll">
                <table class="table table-striped">
                  <tbody>
                  <tr>
                    <th width="20%">Arrival Shipyard</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">{{ dockingDetail.arrival }}</td>
                    <th width="20%">Up Slip</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">{{ dockingDetail.upSlip }}</td>
                  </tr>
                  <tr>
                    <th width="20%">Down Slip</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">
                      <date-picker required v-model="form.downSlip" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
                    </td>
                    <th width="20%">Complete Date</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">
                      <date-picker required v-model="form.deliveryDate" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
                    </td>
                  </tr>
                  <tr>
                    <th width="20%">Budget</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">Rp{{ numberFormat(dockingDetail.budgetEstimation)}}</td>
                    <th width="20%">Penawaran</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">Rp{{ numberFormat(dockingDetail.budgetReal)}}</td>
                  </tr>
                  <tr>
                    <th width="20%">Shipyard</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">{{ dockingDetail.shipyard.name}}</td>
                    <th width="20%">PIC</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">{{ dockingDetail.user.name}}</td>
                  </tr>
                  <tr>
                    <th width="20%">Document Shipyard</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">
                      <ul class="d-flex flex-wrap p-0 m-0" v-if="dockingDetail.docShipyard">
                        <li class="pb-1" style="width: 30px"><a :href="dockingDetail.docShipyard.url" target="_blank"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                      </ul>
                    </td>
                    <th width="20%">Tonage Docking</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-primary" id="inputGroupPrepend2">Tons</span>
                        </div>
                        <input type="number" v-model="form.tonageDocking" class="form-control" id="tonage_docking" placeholder="5">
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th width="20%">Start Engine</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">
                      <date-picker required v-model="form.startEngine" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
                    </td>
                    <th width="20%">Finish Engine</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">
                      <date-picker required v-model="form.finishEngine" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
                    </td>
                  </tr>
                  <tr>
                    <th width="20%">Start ME</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">
                      <date-picker required v-model="form.startMe" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
                    </td>
                    <th width="20%">Finish ME</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">
                      <date-picker required v-model="form.finishMe" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
                    </td>
                  </tr>
                  <tr>
                    <th width="20%">Start AE</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">
                      <date-picker required v-model="form.startAe" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
                    </td>
                    <th width="20%">Finish AE</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">
                      <date-picker required v-model="form.finishAe" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
                    </td>
                  </tr>
                  <tr>
                    <th width="20%">Engine Test</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">
                      <date-picker required v-model="form.engineTest" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
                    </td>
                    <th width="20%">Ready Sail</th>
                    <td width="5%" class="text-center">:</td>
                    <td width="25%">
                      <date-picker required v-model="form.readySail" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" class="align-top">
                      <strong>Remarks</strong>
                      <div class="mt-2">
                        <wysiwyg v-model="form.remark"/>
                      </div>
                    </td>
                    <td colspan="3" class="align-top">
                      <strong>Attachment Serah Terima</strong>
                      <div class="custom-file mt-2">
                        <input type="file" @change="onUploadAttachment" class="custom-file-input" id="validatedCustomFile" required>
                        <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                      </div>
                      <small class="text-muted">
                        File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                        File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                      </small>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <!-- JOB CATEGORY PERCENT -->
                <b-row>
                  <b-col md="12">
                    <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary rounded">
                      <template v-slot:body >
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="rounded-circle iq-card-icon bg-primary"><i class="fa fa-percent"></i></div>
                          <div class="text-right">
                            <h2 class="mb-0"><span class="counter">100%</span></h2>
                            <h5 class="">Persentase Job Category</h5>
                          </div>
                        </div>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
                <!-- JOB CATEGORY PERCENT END -->
                <!-- DOCKING JOB -->
                <div class="accordion" role="tablist">
                  <b-card no-body>
                    <template v-for="(jobCategory, indexJobCategory) in dockingDetail.dockingTaskCategory">
                      <b-card-header :key="indexJobCategory" header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                        <b-button block v-b-toggle="`jobCat-${indexJobCategory}`" variant="primary" class="text-left"><i class="fa fa-chevron-circle-down"></i>{{jobCategory.dockingJobCategory.name}} <strong class="pull-right">BOBOT PEKERJAAN :
                          {{ jobCategory.wokloadPercentage }}%</strong></b-button>
                      </b-card-header>

                      <b-collapse :key="indexJobCategory" :id="`jobCat-${indexJobCategory}`" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                          <!-- BOBOT DOCKING JOB -->
                          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                            <template v-slot:body >
                              <div class="d-flex align-items-center justify-content-between">
                                <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                                <div class="text-right">
                                  <h2 class="mb-0"><span class="counter">100%</span></h2>
                                  <h5 class="">Persentase Docking Job</h5>
                                </div>
                              </div>
                            </template>
                          </iq-card>

                          <div class="accordion" role="tablist">
                            <b-card no-body v-for="(job, indexJob) in jobCategory.dockingTask" :key="`${indexJob}-${indexJobCategory}`">
                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                <b-button block v-b-toggle="`task-${indexJob}-${indexJobCategory}`" variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i>
                                  {{ job.dockingJob.name }} <strong class="pull-right">BOBOT PEKERJAAN : {{ job.jobWorkloadPercentage }}%</strong></b-button>
                              </b-card-header>
                              <b-collapse :id="`task-${indexJob}-${indexJobCategory}`" accordion="my-accordion-sub" role="tabpanel">
                                <table class="table table-striped mt-2">
                                  <tbody>
                                  <tr>
                                    <th width="20%">Start Date</th>
                                    <td width="5%" class="text-center">:</td>
                                    <td width="25%">{{ job.jobStart }}</td>
                                    <th width="20%">Est. Finish</th>
                                    <td width="5%" class="text-center">:</td>
                                    <td width="25%">{{ job.jobEstFinish }}</td>
                                  </tr>
                                  <tr>
                                    <th width="20%">Bobot Pekerjaan</th>
                                    <td width="5%" class="text-center">:</td>
                                    <td width="25%">{{ job.jobWorkloadPercentage }}%</td>
                                    <th width="20%">Dokumen</th>
                                    <td width="5%" class="text-center">:</td>
                                    <td width="25%">
                                      <ul class="d-flex flex-wrap p-0 m-0" v-if="job.jobDoc">
                                        <li class="pb-1" style="width: 30px"><a :href="job.jobDoc.url" target="_blank"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                      </ul>
                                    </td>
                                  </tr>
                                  <tr>
                                    <th width="20%">Created By</th>
                                    <td width="5%" class="text-center">:</td>
                                    <td width="25%">{{ job.createdBy ? JSON.parse(job.createdBy).name : '-' }}</td>
                                    <th width="20%">Review By</th>
                                    <td width="5%" class="text-center">:</td>
                                    <td width="25%">{{ job.reviewedBy ? JSON.parse(job.reviewedBy).name : '-' }}</td>
                                  </tr>
                                  <tr>
                                    <th class="align-top">Notes</th>
                                    <td class="text-center align-top">:</td>
                                    <td colspan="4">
                                      {{job.notes}}
                                    </td>
                                  </tr>
                                  <tr v-if="job.isUseManPower">
                                    <th class="align-top">Internal Man Power</th>
                                    <td class="text-center align-top">:</td>
                                    <td colspan="4">
                                      <h5 class="mt-1" v-for="(manPower, indexManPower) in job.manPowers" :key="indexManPower"><b-badge variant="info">
                                        {{ manPower.name }} - {{ manPower.position }}</b-badge></h5>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                                <table class="table table-striped mt-2" v-if="job.isUseContractor">
                                  <tbody>
                                  <fieldset v-for="(contractor, indexContractor) in job.subContractors" :key="indexContractor" class="form-group border p-3">
                                    <legend class="w-auto px-2">Kontraktor {{indexContractor+1}}</legend>
                                    <tr>
                                      <th width="20%">Nama Kontraktor</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">{{ contractor.name }}</td>
                                      <th width="20%">Executive Engineer</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">{{ contractor.pic }}</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Penawaran</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Rp{{ numberFormat(contractor.offer) }}</td>
                                      <th width="20%">Penawaran Final</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Rp{{ numberFormat(contractor.offerNegotiation) }}</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Note</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">{{ contractor.notes }}</td>
                                      <th width="20%">Attachment</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">
                                        <ul class="d-flex flex-wrap p-0 m-0" v-if="contractor.contractorDoc">
                                          <li class="pb-1" style="width: 30px"><a :href="contractor.contractorDoc.url" target="_blank"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </fieldset>
                                  </tbody>
                                </table>
                                <b-alert :show="true" variant="success">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-check"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Upslip <b>FINISH</b></div>
                                </b-alert>
                              </b-collapse>
                            </b-card>
                          </div>
                        </b-card-body>
                      </b-collapse>
                    </template>
                  </b-card>
                </div>
              </div>
              <b-button block variant="primary" type="submit" class="mt-3">Submit Data</b-button>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

<script>
import { dockingActions, filesActions, fleetsActions } from '@src/Utils/helper'
import { numberFormat } from '@src/plugins/helpers'

export default {
  name: 'DockingSerahTerima',
  async mounted () {
    this.loading = true
    await this.fetchDockingDetail()
    await this.fetchFleetDetail()
    this.loading = false
  },
  data () {
    return {
      loading: true,
      fleetDetail: null,
      dockingDetail: null,
      form: {
        saving: false,
        downSlip: '',
        deliveryDate: '',
        tonageDocking: '',
        startEngine: '',
        finishEngine: '',
        startMe: '',
        finishMe: '',
        startAe: '',
        finishAe: '',
        engineTest: '',
        readySail: '',
        remark: '',
        file: '',
      }
    }
  },
  computed: {
    dockingId () {
      return this.$route.query.dockingId
    }
  },
  methods: {
    ...dockingActions,
    ...filesActions,
    ...fleetsActions,
    numberFormat (number) {
      return numberFormat(number)
    },
    onResetForm () {
      this.form = {
        saving: false,
        downSlip: '',
        deliveryDate: '',
        tonageDocking: '',
        startEngine: '',
        finishEngine: '',
        startMe: '',
        finishMe: '',
        startAe: '',
        finishAe: '',
        engineTest: '',
        readySail: '',
        remark: '',
        file: '',
      }
    },
    async fetchDockingDetail () {
      if (this.dockingId) {
        const { data, status } = await this.getDockingDetail({
          dockingId: this.dockingId
        })

        if ([500, 404, 403].includes(status)) {
          this.$swal(
            `Oops!`,
            'Ada Yang Salah',
            'error'
          )
          return
        }

        if (data.data) {
          this.dockingDetail = data.data
        }
      }
    },
    async fetchFleetDetail () {
      const res = await this.getFleetById(this.dockingDetail.vehicle.id)

      if (res.status === 'success') {
        this.fleetDetail = res.data
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async submit () {
      this.form.saving = true
      const payload = { ...this.form, dockingId: this.dockingId }

      const { status } = await this.createDockingHandOver(payload)

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        return
      }

      if ([500, 404, 403, 400].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      this.$router.push('/docking/dashboard')
    },
    async onUploadAttachment (event) {
      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)
      if (res.status === 'success') {
        this.form.file = res.data.fileName
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
  }
}
</script>

