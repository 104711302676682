import axios from './auth'

const CREWING_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/crewing`
const BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}`
const BASE_PORTAL = `${process.env.VUE_APP_API_BASE_PORTAL}`

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async fetchCrewList({ commit }, params) {
    const url = `${CREWING_BASE_URL}/list-crew`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async getCrewDetail({ commit }, id) {
    const url = `${CREWING_BASE_URL}/detail-crew/${id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchCandidateList({ commit }, params) {
    const url = `${CREWING_BASE_URL}/list-candidate`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchBlackList({ commit }, params) {
    const url = `${CREWING_BASE_URL}/list-blacklist`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async createCandidate({ commit }, payload) {
    const url = `${CREWING_BASE_URL}/create-candidate`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async createCrew({ commit }, payload) {
    const url = `${CREWING_BASE_URL}/create-crew`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async fetchProvience({ commit }, params) {
    const url = `${CREWING_BASE_URL}/provinces`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchCities({ commit }, payload) {
    const url = `${CREWING_BASE_URL}/cities`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async fetchDistricts({ commit }, payload) {
    const url = `${CREWING_BASE_URL}/districts`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async fetchVillages({ commit }, payload) {
    const url = `${CREWING_BASE_URL}/villages`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async fetchVehicles({ commit }, params = {}) {
    const url = `${BASE_URL}/vehicles`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchCompanies({ commit }, params = {}) {
    const url = `${BASE_URL}/companies`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchWorkArea({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/work-area`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchPorts({ commit }, params = {}) {
    const url = `${BASE_URL}/ports`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchPayrollAllowance({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/payroll-allowance`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async fetchUserExpert({ commit }, params = {}) {
    const url = `${BASE_URL}/users`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async fetchPositionList({ commit }, params) {
    const url = `${CREWING_BASE_URL}/list-position`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getProfileCandidate({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/profile-candidate/${params}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async setBlacklistCandidate({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/blacklist-candidate/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.formData,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setBlacklistCrew({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/blacklist-crew/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.formData,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setInterviewCandidate({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/set-interview/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.formData,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setSignOnCandidate({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/sign-on/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async fetchPointInterview({ commit }, params) {
    const url = `${CREWING_BASE_URL}/point`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async fetchCriteriaInterview({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/interview-criteria`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async setFormInterview({}, payload = {}) {
    const url = `${CREWING_BASE_URL}/form-interview/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async medicalCheckup({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/medical-check-up/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async masterDocumentList({ commit }, params = {}) {
    const url = `${BASE_PORTAL}/master-document-list`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async masterSignOffReasons({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/master-signoff-reason`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },

  async setDocumentChecklist({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/document-checklist/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async fetchMasterDepartmentList({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/department`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async fetchInductionChecklist({ commit }, params) {
    const url = `${CREWING_BASE_URL}/induction-checklist/${params}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setInductionCandidate({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/induction-checklist/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.formData,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async setSignOffCrew({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/sign-off/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
  async changeStatusCandidate({ commit }, payload = {}) {
    const url = `${CREWING_BASE_URL}/change-status/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.change_status,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data,
      }
    }
  },
}
