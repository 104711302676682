<template>
  <div v-if="this.$route.params.id && isFetching">
    <p>Fetching Data...</p>
  </div>
  <b-row v-else>
    <b-col md="12" class="mb-4">
      <b-row>
        <b-col md="12" class="text-center mb-4"><h4>Assessor of the Accident</h4></b-col>
        <b-col md="1"></b-col>
        <b-col md="3">
          <b-form-group label="Name">
            <b-form-input class="w-100" type="text" v-model="assessor_fullname" placeholder="Name..."></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Company">
            <b-form-select plain v-model="assessor_company_id" :options="companyList" size="lg">
              <template v-slot:first>
                <b-form-select-option :value="null">Select Company</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Position">
            <b-form-input class="w-100" type="text" v-model="assessor_position" placeholder="Position..."></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="1"></b-col>
      </b-row>
    </b-col>
    <b-col md="12" class="mt-2">
      <b-row>
        <b-col md="1">
          <div class="bg-info text-center text-white p-4">
            <h2 class="text-white">1</h2>
          </div>
        </b-col>
        <b-col md="11">
          <h2 class="mt-4">PERSONAL DATA OF PERSON INVOLVED</h2>
        </b-col>
      </b-row>
    </b-col>
    <b-col md="12" class="mt-2">
      <hr/>
      <b-row>
        <b-col md="1" class="text-right text-primary"><h3>#1</h3></b-col>
        <b-col md="11">
          <b-row>
            <b-col md="3">
              <b-form-group label="Name">
                <b-form-input class="w-100" type="text" v-model="personal_fullname" placeholder="Name..."></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Sex">
                <template v-for="(item,index) in state">
                  <b-form-radio inline v-model="personal_gender" :name="item.name" :key="index" :value="item.value">{{ item.label }}</b-form-radio>
                </template>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Place of Birth">
                <b-form-input class="w-100" type="text" v-model="personal_birthplace" placeholder="Place of Birth..."></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Date of Birth">
                <date-picker type="date" v-model="personal_birthdate" format="YYYY-MM-DD" value-type="format" placeholder="Select date"></date-picker>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Marital Status">
                <b-form-select plain v-model="personal_marital_status" :options="marital" size="lg">
                  <template v-slot:first>
                    <b-form-select-option :value="null">Select Marital Status</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Company">
                <b-form-select plain v-model="personal_company_id" :options="companyList" size="lg">
                  <template v-slot:first>
                    <b-form-select-option :value="null">Select Company</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Position">
                <b-form-input class="w-100" type="text" v-model="personal_position" placeholder="Position..."></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Length of Work">
                <b-input-group size="md" append="Month">
                  <b-form-input type="number" v-model="working_experience"></b-form-input>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Photo">
                <b-form-file accept="image" v-model="personal_photo"></b-form-file>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Sign Picture">
                <b-form-file accept="image" v-model="personal_sign"></b-form-file>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Type">
                <template v-for="(item,index) in types">
                  <b-form-radio inline v-model="type" :name="item.name" :key="index" :value="item.value">{{ item.label }}</b-form-radio>
                </template>
              </b-form-group>
            </b-col>
            <b-col md="3"></b-col>
          </b-row>
          <hr/>
        </b-col>
      </b-row>
      <hr/>
    </b-col>
    <b-col md="12" class="mt-2">
      <b-row>
        <b-col md="1">
          <div class="bg-info text-center text-white p-4">
            <h2 class="text-white">2</h2>
          </div>
        </b-col>
        <b-col md="11">
          <h2 class="mt-4">DEFINE THE PROBLEM</h2>
        </b-col>
      </b-row>
    </b-col>
    <b-col md="12" class="mt-2">
      <b-row>
        <b-col md="12" class="mt-2 mb-2">
          <b-alert show variant="primary"><strong class="text-center">BACKGROUND INFORMATION ABOUT THE ACCIDENT OR PROBLEM</strong></b-alert>
        </b-col>
        <b-col md="12">
          <b-row class="mt-2 mb-2">
            <b-col md="5">
              <div class="bg-light p-4 font-size-18"><strong class="text-center">What part, Product, Process, or Services Involved</strong></div>
            </b-col>
            <b-col md="7">
              <b-form-textarea rows="3" v-model="explanation_damage"></b-form-textarea>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="12">
          <b-row class="mt-2 mb-2">
            <b-col md="5">
              <div class="bg-light p-4 font-size-18"><strong class="text-center">Explain the Problem(s)</strong></div>
            </b-col>
            <b-col md="7">
              <b-form-textarea rows="4" v-model="explanation_problem"></b-form-textarea>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="12">
          <b-row class="mt-2 mb-2">
            <b-col md="5">
              <div class="bg-light p-4 font-size-18"><strong class="text-center">when in the life cycle of the unit is the problem detected ?
                (e.g at the source, intial inspection, final inspection, end user, etc)-Explain</strong></div>
            </b-col>
            <b-col md="7">
              <b-form-textarea rows="4" v-model="explanation_detected"></b-form-textarea>
            </b-col>
          </b-row>
        </b-col>
        <!-- Expectations -->
        <b-col md="12" class="mt-2 mb-2">
          <b-alert show variant="success"><strong class="text-center">EXPECTATIONS !</strong></b-alert>
        </b-col>
        <b-col md="12">
          <b-row class="mt-2 mb-2">
            <b-col md="5">
              <div class="bg-light p-4 font-size-18"><strong class="text-center">RESULT OF DEFINING THE PROBLEM</strong></div>
            </b-col>
            <b-col md="7">
              <b-form-textarea rows="4" v-model="explanation_result"></b-form-textarea>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
    <!--              collecting information-->
    <b-col md="12" class="mt-2">
      <b-row>
        <b-col md="1">
          <div class="bg-info text-center text-white p-4">
            <h2 class="text-white">3</h2>
          </div>
        </b-col>
        <b-col md="11">
          <h2 class="mt-4">COLLECTING INFORMATION</h2>
        </b-col>
      </b-row>
    </b-col>
    <b-col md="12" class="mt-2">
      <hr/>
      <b-row v-for="(why, index) in whys" :key="index">
        <b-col md="1" class="text-right text-primary">
          <h3>WHY #{{ index + 1 }}</h3>
          <b-button variant="danger" @click="whys.splice(index, 1)"><i class="fa fa-trash"></i></b-button>
        </b-col>
        <b-col md="11">
          <b-row>
            <b-col md="12">
              <b-form-group label="DEFECT, PROBLEM OR ERROR ">
                <b-form-input class="w-100" type="text" v-model="why.defect" placeholder="DEFECT, PROBLEM OR ERROR ..."></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="CAUSE">
                <b-form-textarea class="w-100" v-model="why.cause" placeholder="CAUSE"></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col md="3"></b-col>
            <b-col md="3"></b-col>
          </b-row>
          <hr/>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-button variant="warning" block class="mt-2 mb-3" @click="addWhys"><i class="fa fa-plus"></i> Add More Data</b-button>
        </b-col>
      </b-row>
      <hr/>
      <b-row v-for="index in whatisLength" :key="index">
        <b-col md="1" class="text-right text-primary">
          <h5>WHAT IS? WHAT IS NOT? #{{ index }}</h5>
          <b-button variant="danger" @click="whatisLength -= 1"><i class="fa fa-trash"></i></b-button>
        </b-col>
        <b-col md="11">
          <b-row>
            <b-col md="6">
              <b-form-group label="WHAT IS?">
                <b-form-textarea v-model="whatis[index - 1]" class="w-100" placeholder="WHAT IS?"></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="WHAT IS NOT?">
                <b-form-textarea v-model="whatisnot[index - 1]" class="w-100" placeholder="WHAT IS NOT?"></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <hr/>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-button variant="warning" block class="mt-2 mb-3" @click="addWhatis"><i class="fa fa-plus"></i> Add More Data</b-button>
        </b-col>
      </b-row>
    </b-col>

    <!--              identify the root cause-->
    <b-col md="12" class="mt-2">
      <b-row>
        <b-col md="1">
          <div class="bg-info text-center text-white p-4">
            <h2 class="text-white">4</h2>
          </div>
        </b-col>
        <b-col md="11">
          <h2 class="mt-4">IDENTIFY THE ROOT CAUSE</h2>
        </b-col>
      </b-row>
    </b-col>
    <b-col md="12" class="mt-2">
      <hr/>
      <b-row>
        <b-col md="4">
          <b-form-group label="Vessel">
            <b-form-input type="text" :value="vessel.name" placeholder="Select Vessel" readonly />
          </b-form-group>
          <b-form-group label="FMEA CASE :">
            <b-form-input class="w-100" type="text" v-model="fmea_case" placeholder="FMEA CASE"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="FMEA Date (Orig.) :">
            <date-picker type="datetime" v-model="fmea_date" format="YYYY-MM-DD HH:mm:ss" value-type="format" placeholder="Select date and time"></date-picker>
          </b-form-group>
          <b-form-group label="Date of Loss">
            <b-form-input type="datetime" :value="date_of_loss" placeholder="Select date and time" readonly></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <hr/>
      <b-row v-for="index in identificationLength" :key="index">
        <b-col md="1" class="text-right text-primary">
          <h3>#{{ index }}</h3>
          <b-button variant="danger" @click="identificationLength -= 1"><i class="fa fa-trash"></i></b-button>
        </b-col>
        <b-col md="10">
          <b-row v-show="identifications">
            <template v-for="form in identifications">
              <b-col :key="form.id" :md="form.description? '12' : '6'">
                <b-row v-if="form.description" class="mt-2 mb-2">
                  <b-col md="12">
                    <div class="bg-light p-4 font-size-18"><strong class="text-center">{{ form.title }}</strong></div>
                  </b-col>
                  <b-col md="12">
                    <div class="bg-yellow p-4">
                      <div class="font-size-16"><strong class="text-center">{{ form.description }} </strong></div>
                      <b-form-textarea v-model="form.answers[index - 1]" rows="2"></b-form-textarea>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-else class="mt-2 mb-2">
                  <b-col md="12">
                    <div :class="form.title === 'RPN' ? 'bg-primary' : 'bg-info'" class="p-4 font-size-18"><strong class="text-center">{{ form.title }} {{ form.title !== 'RPN' ? '(1 to 10)' : '(Risk Priority Number)' }}</strong></div>
                  </b-col>
                  <b-col md="12">
                    <div class="p-4">
                      <b-form-input
                        @input="setRPN(index - 1)"
                        v-model="form.answers[index - 1]"
                        type="number"
                        :readonly="form.title === 'RPN'"
                        :min="form.title !== 'RPN' ? 1 : ''"
                        :max="form.title !== 'RPN' ? 10 : ''"
                      >
                      </b-form-input>
                      <table v-if="form.title === 'Detection'" class="table w-100 mt-3 table-bordered">
                        <tr>
                          <th bgcolor="#faebd7" width="30%" class="text-center">
                            RATING
                          </th>
                          <th bgcolor="#faebd7" width="70%">
                            DETECTION RATING DESCRIPTION
                          </th>
                        </tr>
                        <tr>
                          <td bgcolor="#b22222" class="text-white text-center">
                            10
                          </td>
                          <td>
                            Controls will not or can not detect the existence of a failure, no known
                            controls available to detect failure mode
                          </td>
                        </tr>
                        <tr>
                          <td bgcolor="#b22222" class="text-white text-center">
                            9
                          </td>
                          <td>
                            controls probably will not detect the exitence of failure
                          </td>
                        </tr>
                        <tr>
                          <td bgcolor="#dc143c" class="text-white text-center">
                            8
                          </td>
                          <td rowspan="2">
                            Controls have a poor change of detecting the existence of a failure
                            mode
                          </td>
                        </tr>
                        <tr>
                          <td bgcolor="#dc143c" class="text-white text-center">
                            7
                          </td>
                        </tr>
                        <tr>
                          <td bgcolor="#d2691e" class="text-center">
                            6
                          </td>
                          <td rowspan="2">
                            Controls may detect the existence of a failure mode
                          </td>
                        </tr>
                        <tr>
                          <td bgcolor="#d2691e" class="text-center">
                            5
                          </td>
                        </tr>
                        <tr>
                          <td bgcolor="#d2691e" class="text-center">
                            4
                          </td>
                          <td rowspan="2">
                            Control have a good chance of detecting failure mode, process
                            Minor - relatively few failures and minor impact to automatically detects failure mode
                          </td>
                        </tr>
                        <tr>
                          <td bgcolor="#ffd700" class="text-center">
                            3
                          </td>
                        </tr>
                        <tr>
                          <td bgcolor="#7fff00" class="text-center">
                            2
                          </td>
                          <td rowspan="2">
                            current control almost centrain to detect the failure mode, reliable
                            detection control are known with similar processes. Process
                            automatically prevents further processing
                          </td>
                        </tr>
                        <tr>
                          <td bgcolor="#7fff00" class="text-center">
                            1
                          </td>
                        </tr>
                      </table>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col :key="form.id" md="6" v-if="form.title === 'Occurrence'">
                <table class="table w-100 table-bordered">
                  <tr>
                    <th bgcolor="#faebd7" width="70%">
                      DESCRIPTION OF OCCURRENCE OF
                      CAUSAL FACTORS
                    </th>
                    <th bgcolor="#faebd7" width="30%" class="text-center">
                      RATING
                    </th>
                  </tr>
                  <tr>
                    <td>
                      Failure is almost inevitable
                    </td>
                    <td bgcolor="#b22222" class="text-white text-center">
                      10
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      This Process or similar processes
                      have often
                    </td>
                    <td bgcolor="#b22222" class="text-white text-center">
                      9
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor="#dc143c" class="text-white text-center">
                      8
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      This Process has occasional failures
                      but will impact damage to Vessel, in
                      major or Minor
                    </td>
                    <td bgcolor="#dc143c" class="text-white text-center">
                      7
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor="#d2691e" class="text-center">
                      6
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="3">
                      slated failures associated with similar
                      processes
                    </td>
                    <td bgcolor="#d2691e" class="text-center">
                      5
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor="#d2691e" class="text-center">
                      4
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor="#ffd700" class="text-center">
                      3
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Only Isolated Failures associated with
                      this process or almost indentical
                      processes
                    </td>
                    <td bgcolor="#ffd700" class="text-center">
                      2
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Failure unlike, No Failures ever
                      associated with this process or almost
                      identical processes
                    </td>
                    <td bgcolor="#7fff00" class="text-center">
                      1
                    </td>
                  </tr>
                </table>
              </b-col>
              <b-col :key="form.id" md="6" v-if="form.title === 'Occurrence, Severity'">
                <table class="table w-100 table-bordered">
                  <tr>
                    <th bgcolor="#faebd7" width="70%">
                      SEVERITY/INFLUENCE RATING DESCRIPTION
                    </th>
                    <th bgcolor="#faebd7" width="30%" class="text-center">
                      RATING
                    </th>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      Very High-Hazardous, Vessel become inactive or
                      cannot be operated and can cause injury or death
                      for the crew, passengers, or other parties ( the
                      effect directly to loss/damage of primary functions
                      of the Vessel)
                    </td>
                    <td bgcolor="#b22222" class="text-white text-center">
                      10
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor="#b22222" class="text-white text-center">
                      9
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2">
                      High - Repeated failures made the vessel have the
                      same problem -negligence, incompetence,
                      misconduct, error in judgment by a person, and
                      latent defect of the vessel
                    </td>
                    <td bgcolor="#dc143c" class="text-white text-center">
                      8
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor="#dc143c" class="text-white text-center">
                      7
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="3">
                      Moderate -Some occasional Failures,
                    </td>
                    <td bgcolor="#d2691e" class="text-center">
                      6
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor="#d2691e" class="text-center">
                      5
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor="#d2691e" class="text-center">
                      4
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Minor - relatively few failures and minor impact to automatically detects failure mode
                      Vessel or other parties
                    </td>
                    <td bgcolor="#ffd700" class="text-center">
                      3
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Very Minor
                    </td>
                    <td bgcolor="#ffd700" class="text-center">
                      2
                    </td>
                  </tr>
                  <tr>
                    <td>
                      No Effect -No Danger
                    </td>
                    <td bgcolor="#7fff00" class="text-center">
                      1
                    </td>
                  </tr>
                </table>
              </b-col>
            </template>
          </b-row>
          <hr/>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-button variant="warning" block class="mt-2 mb-3" @click="addIdentifications"><i class="fa fa-plus"></i> Add More Data</b-button>
        </b-col>
      </b-row>
      <hr/>
    </b-col>

    <!--              implement corrective actions-->
    <b-col md="12" class="mt-2">
      <b-row>
        <b-col md="1">
          <div class="bg-info text-center text-white p-4">
            <h2 class="text-white">5</h2>
          </div>
        </b-col>
        <b-col md="11">
          <h2 class="mt-4">IMPLEMENT CORRECTIVE ACTIONS</h2>
        </b-col>
      </b-row>
    </b-col>
    <b-col md="12" class="mt-2">
      <hr/>
      <b-row v-for="(implementation, index) in implementations" :key="index">
        <b-col md="1" class="text-right text-primary">
          <h3> #{{ index + 1 }}</h3>
          <b-button variant="danger" @click="implementations.splice(index, 1)"><i class="fa fa-trash"></i></b-button>
        </b-col>
        <b-col md="10">
          <b-row>
            <b-col md="6">
              <b-form-group label="Tool">
                <b-form-select v-if="index <= 3" plain v-model="implementation.tool" @change="handleImplementToolChange(index)" size="sm" class="w-100">
                  <b-form-select-option value="Five Whys">Five Whys</b-form-select-option>
                  <b-form-select-option value="Comparative Analysis">Comparative Analysis</b-form-select-option>
                  <b-form-select-option value="Failure Mode and Effect Analysis (FMEA)">Failure Mode and Effect Analysis (FMEA)</b-form-select-option>
                  <b-form-select-option value="DOE">DOE</b-form-select-option>
                </b-form-select>
                <b-form-input v-else class="w-100" type="text" v-model="implementation.tool" placeholder="Tool"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Question">
                <b-form-textarea class="w-100" row="2" v-model="implementation.question" placeholder="Question"></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Findings">
                <b-form-textarea class="w-100" row="2" v-model="implementation.finding" placeholder="Findings"></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Corrective Actions">
                <b-form-textarea class="w-100" row="2" v-model="implementation.corrective_action" placeholder="Corrective Actions"></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <hr/>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-button variant="warning" block class="mt-2 mb-3" @click="addImplementations"><i class="fa fa-plus"></i> Add More Data</b-button>
        </b-col>
      </b-row>
      <hr/>
    </b-col>

    <!--              fish bone-->
    <b-col md="12" class="mt-2">
      <b-row>
        <b-col md="1">
          <div class="bg-info text-center text-white p-4">
            <h2 class="text-white">6</h2>
          </div>
        </b-col>
        <b-col md="11">
          <h2 class="mt-4">FISHBONE DIAGRAM</h2>
        </b-col>
      </b-row>
    </b-col>
    <b-col md="12" class="mt-2">
      <hr/>
      <b-row>
        <b-col md="4">
          <b-form-group label="Diagram Name :">
            <b-form-input class="w-100" type="text" v-model="diagram_title" placeholder="Diagram Name"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Photo">
            <b-form-file accept="image" v-model="diagram_photo"></b-form-file>
          </b-form-group>
        </b-col>
      </b-row>
      <hr/>
      <b-row>
        <b-col v-for="(diagram, index) in diagrams" :key="index" md="12">
          <b-row class="mt-2 mb-2">
            <b-col md="5">
              <div class="bg-light p-4 font-size-18">
                <b-form-group label="Header Name :">
                  <b-form-input class="w-100" type="text" v-model="diagram.header" placeholder="Header Name"></b-form-input>
                </b-form-group>
              </div>
              <b-button variant="danger" @click="diagrams.splice(index, 1)"><i class="fa fa-trash"></i></b-button>
            </b-col>
            <b-col md="7">
              <b-row v-for="idx in diagram.bodyLength" :key="idx" class="mb-3">
                <b-col md="1" class="text-right text-primary">
                  <h3> #{{ idx }}</h3>
                  <b-button variant="danger" @click="diagram.bodyLength -= 1"><i class="fa fa-trash"></i></b-button>
                </b-col>
                <b-col md="11">
                  <b-form-textarea v-model="diagram.body[idx - 1]" rows="2"></b-form-textarea>
                </b-col>
              </b-row>
              <b-col md="12" class="text-right">
                <b-button variant="warning" class="mt-2 mb-3" @click="addDiagramBody(index)"><i class="fa fa-plus"></i> Add More Item</b-button>
              </b-col>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-button variant="warning" block class="mt-2 mb-3" @click="addDiagrams"><i class="fa fa-plus"></i> Add More Data</b-button>
        </b-col>
      </b-row>
      <hr/>
    </b-col>
    <b-col>
      <b-button variant="success" block class="mt-2 mb-3" @click="submitForm"><i class="fa fa-check"></i> Submit Form</b-button>
    </b-col>
  </b-row>
</template>
<script>
import { miActions, companiesActions, vehiclesActions } from '@src/Utils/helper'
import { xray } from '../../config/pluginInit'

export default {
  name: 'RootCauseAnalysisForm',
  async mounted () {
    xray.index()
    if (!this.$route.query.formId) {
      this.$router.push({
        name: 'mi.listInitial'
      })
    }
    if (this.$route.params.id) {
      this.isFetching = true
    }
    if (this.$route.query.formId && this.$route.params.id) {
      if (await this.getDetail(this.$route.params.id)) {
        if (this.form_id.toString() !== this.$route.query.formId.toString()) {
          this.$router.push({
            name: 'mi.listInitial'
          })
        } else {
          this.isFetching = false
        }
      } else {
        this.$router.push({
          name: 'mi.listInitial'
        })
      }
    }
    await this.fetchIncident()
    if (!this.$route.params.id) {
      if (!this.identifications) {
        this.identifications = await this.getFormModel()
      }
      if (!this.companyList.length) {
        this.companyList = await this.getCompanyList()
      }
      if (!this.whys.length) {
        this.whys = await this.getQuestions()
      }
    }
  },
  data () {
    return {
      id: null,
      form_id: null,
      assessor_fullname: '',
      assessor_company_id: null,
      assessor_position: '',
      personal_fullname: '',
      personal_gender: '',
      personal_birthplace: '',
      personal_birthdate: null,
      personal_marital_status: null,
      personal_company_id: null,
      personal_position: '',
      personal_photo: null,
      personal_sign: null,
      working_experience: null,
      vessel: {
        id: '',
        name: ''
      },
      fmea_case: '',
      fmea_date: null,
      date_of_loss: null,
      explanation_damage: '',
      explanation_problem: '',
      explanation_detected: '',
      explanation_result: '',
      identificationLength: 1,
      identifications: null,
      type: '',
      whys: [],
      implementations: [
        {
          tool: '',
          question: '',
          finding: '',
          corrective_action: ''
        },
        {
          tool: '',
          question: '',
          finding: '',
          corrective_action: ''
        },
        {
          tool: '',
          question: '',
          finding: '',
          corrective_action: ''
        },
        {
          tool: '',
          question: '',
          finding: '',
          corrective_action: ''
        }
      ],
      whatisLength: 1,
      whatis: [],
      whatisnot: [],
      diagram_photo: null,
      diagram_title: '',
      diagrams: [
        {
          header: null,
          body: [],
          bodyLength: 2
        },
        {
          header: null,
          body: [],
          bodyLength: 2
        }
      ],
      form: {
        status: false
      },
      stateActive: {
        single: 'active',
        disable: 'active',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      state: [
        {
          label: 'Male',
          value: 'M',
          disabled: false
        },
        {
          label: 'Female',
          value: 'F',
          disabled: false
        }
      ],
      types: [
        {
          label: 'Direct',
          value: 'DIRECT',
          disabled: false
        },
        {
          label: 'Indirect',
          value: 'INDIRECT',
          disabled: false
        }
      ],
      marital: [
        { value: 'SINGLE', text: 'Single' },
        { value: 'MARRIED', text: 'Married' },
        { value: 'DIVORCE', text: 'Divorce' }
      ],
      companyList: [],
      isFetching: false
    }
  },
  methods: {
    ...miActions,
    ...companiesActions,
    ...vehiclesActions,
    setRPN (index) {
      const occurrence = (this.identifications.find(val => val.title === 'Occurrence')).answers
      const saverity = (this.identifications.find(val => val.title === 'Occurrence, Severity')).answers
      const detection = (this.identifications.find(val => val.title === 'Detection')).answers

      if (!(this.identifications.find(val => val.title === 'RPN')).answers[index]) {
        (this.identifications.find(val => val.title === 'RPN')).answers[index] = 1
      }

      if (!occurrence[index] && !saverity[index] && !detection[index]) {
        (this.identifications.find(val => val.title === 'RPN')).answers[index] = undefined
      }

      if (occurrence[index] && saverity[index] && detection[index]) {
        (this.identifications.find(val => val.title === 'RPN')).answers[index] = occurrence[index] * saverity[index] * detection[index]
      } else if (saverity[index] && detection[index]) {
        (this.identifications.find(val => val.title === 'RPN')).answers[index] = saverity[index] * detection[index]
      } else if (detection[index] && occurrence[index]) {
        (this.identifications.find(val => val.title === 'RPN')).answers[index] = detection[index] * occurrence[index]
      } else if (occurrence[index] && saverity[index]) {
        (this.identifications.find(val => val.title === 'RPN')).answers[index] = occurrence[index] * saverity[index]
      } else if (occurrence[index]) {
        (this.identifications.find(val => val.title === 'RPN')).answers[index] = occurrence[index]
      } else if (saverity[index]) {
        (this.identifications.find(val => val.title === 'RPN')).answers[index] = saverity[index]
      } else if (detection[index]) {
        (this.identifications.find(val => val.title === 'RPN')).answers[index] = detection[index]
      }
    },
    async fetchIncident () {
      const { data, status } = await this.fetchDetailMi({
        id: this.$route.query.formId,
        with: 'type_loss,vehicle'
      })

      if (status === 'success') {
        this.date_of_loss = data.date_of_loss
        this.vessel = {
          id: data.vehicle.id,
          name: data.vehicle.name
        }
      } else {
        this.$router.push({
          name: 'mi.listInitial'
        })
      }
    },
    async getDetail (id) {
      const { status, data } = await this.getRCA(id)
      if (status === 'success') {
        for (const key in data) {
          if (key === 'identifications') {
            data[key].forEach(d => {
              if (this.identificationLength < d.answers.length) {
                this.identificationLength = d.answers.length
              }
            })
            this[key] = data[key].map(d => ({
              ...d,
              answers: data[key].find(answer => answer.id === d.id).answers
            }))
          } else if (key === 'whys') {
            data[key].forEach(item => {
              const dataIndex = this.whys.findIndex(d => d.defect === item.defect)
              if (dataIndex === -1) {
                this.whys.push({ defect: item.defect, cause: item.cause })
              }
            })
            this.whys = this.whys.map(why => {
              const dataIndex = data[key].findIndex(item => item.defect === why.defect)
              if (dataIndex === -1) {
                return { ...why }
              }
              return { ...why, cause: data[key][dataIndex].cause }
            })
          } else if (key === 'whatis') {
            this.whatisLength = Math.max(data.whatis.length, data.whatisnot.length)
            this[key] = data[key]
          } else if (key === 'diagrams') {
            this[key] = data[key].map(d => ({
              ...d,
              bodyLength: d.body.length
            }))
          } else {
            this[key] = data[key]
          }
        }
        return true
      }
      return false
    },
    async getFormModel () {
      const { data } = await this.getRCAFormModel()
      return data.map(val => ({ ...val, title: ['saverity', 'severity'].includes(val.title.toLowerCase()) ? 'Occurrence, Severity' : val.title, answers: [] }))
    },
    async getCompanyList () {
      const { data } = await this.getCompanies()
      return data.map(val => ({ value: val.id, text: val.company }))
    },
    async getQuestions () {
      const { data } = await this.getMasterQuestions()
      return data.map(val => ({ defect: val.name, readonly: true, cause: null }))
    },
    async submitForm () {
      const diagram_photo = this.createBase64Image(this.diagram_photo)
      const personal_photo = this.createBase64Image(this.personal_photo)
      const personal_sign = this.createBase64Image(this.personal_sign)
      const payload = {
        form_id: this.$route.query.formId,
        assessor_fullname: this.assessor_fullname,
        assessor_company_id: this.assessor_company_id,
        assessor_position: this.assessor_position,
        personal_fullname: this.personal_fullname,
        personal_gender: this.personal_gender,
        personal_birthplace: this.personal_birthplace,
        personal_birthdate: this.personal_birthdate,
        personal_marital_status: this.personal_marital_status,
        personal_company_id: this.personal_company_id,
        personal_position: this.personal_position,
        personal_photo,
        personal_sign,
        working_experience: this.working_experience,
        vessel_id: this.vessel.id,
        fmea_case: this.fmea_case,
        fmea_date: this.fmea_date,
        date_of_loss: this.date_of_loss,
        explanation_damage: this.explanation_damage,
        explanation_problem: this.explanation_problem,
        explanation_detected: this.explanation_detected,
        explanation_result: this.explanation_result,
        whys: this.whys.filter(why => why.cause !== null).map(why => ({ defect: why.defect, cause: why.cause })),
        whatis: this.whatis.filter(val => val !== null),
        whatisnot: this.whatisnot.filter(val => val !== null),
        identifications: this.identifications.map(identification => ({
          id: identification.id,
          answers: identification.answers.filter(answer => answer !== null).map(answer => answer.toString())
        })),
        type: this.type,
        implementations: this.implementations,
        diagram_title: this.diagram_title,
        diagram_photo,
        diagrams: this.diagrams.filter(diagram => diagram.header !== null && diagram.body.length !== 0).map(diagram => ({
          header: diagram.header,
          body: diagram.body.filter(val => val !== null)
        }))
      }
      let status
      let data
      if (this.$route.params.id) {
        payload.id = this.id
        const res = (await this.editRCA(payload))
        status = res.status
        data = res.data.data
      } else {
        const res = (await this.createRCA(payload))
        status = res.status
        data = res.data.data
      }
      if (status === 'success') {
        this.$swal(
          `Tersimpan`,
          'Data Tersimpan',
          'success'
        )
        setTimeout(() => {
          this.$router.push({ name: 'mi.listInitial' })
        }, 1500)
      } else {
        let err = []
        for (const key in data) {
          err = [ ...err, ...data[key] ]
        }
        this.$swal(
          `Oops!`,
          `${err.join(',')}`,
          'error'
        )
      }
    },
    addIdentifications () {
      this.identificationLength += 1
    },
    addWhatis () {
      this.whatisLength += 1
    },
    addWhys () {
      this.whys.push({
        defect: '',
        readonly: false,
        cause: null
      })
    },
    addImplementations () {
      this.implementations.push({
        tool: '',
        question: '',
        finding: '',
        corrective_action: ''
      })
    },
    addDiagrams () {
      this.diagrams.push({
        header: null,
        body: [],
        bodyLength: 2
      })
    },
    addDiagramBody (index) {
      this.diagrams[index].bodyLength += 1
    },
    createBase64Image (file) {
      if (!file) return null
      const reader = new FileReader()

      reader.onload = (e) => e.target.result
      reader.readAsBinaryString(file)
    },
    handleImplementToolChange (index) {
      if (this.implementations[index]) {
        switch (this.implementations[index].tool) {
          case 'Five Whys':
            this.implementations[index].question = 'Has repeatedly asking "why" drilled down into the process to uncover the root cause?'
            break
          case 'Comparative Analysis':
            this.implementations[index].question = 'Has what is what not, questioning pointed to the root cause?'
            break
          case 'Failure Mode and Effect Analysis (FMEA)':
            this.implementations[index].question = 'Were the causes of high risk potential failures modes worth exploring? '
            break
          case 'DOE':
            this.implementations[index].question = 'Did gaining a deeper understanding of other Problems in the main problem and How to Prevent Problems?'
            break
          default:
            this.implementations[index].question = 'Has repeatedly asking "why" drilled down into the process to uncover the root cause? '
            break
        }
      }
    }
  }
}
</script>

<style>
.v-select * {
  color: #a09e9e;
}
</style>
