<template>
    <b-container fluid>
      <b-row>
        <b-col lg="12">
            <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>REPORT - PROFIT / LOSS (STANDART)</strong></h5>
                </template>
                <template v-slot:headerAction>
                  <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                    <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                        <i class="fa fa-save m-0"></i>
                        </span>
                    </template>
                    <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
                    <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
                  </b-dropdown>
                </template>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12" class="text-center">
                      <h4>PT. CAHAYA MARITIM</h4>
                      <h3>PROFIT / LOSS (STANDART)</h3>
                      <p>Periode : 01 January 2017 - 31 December 2017</p>
                    </b-col>
                    <b-col md="12" class="pl-5 pr-5">
                        <table class="table table-striped table-sm">
                            <thead>
                            <tr>
                                <th width="80%" class="pl-5">DESCRIPTION</th>
                                <th width="20%" class="text-right pr-5">01 JAN - 31 DEC 2017</h5></th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th colspan="2" class="pl-5">REVENUE</th>
                                </tr>
                                <tr>
                                    <td class="pl-5">Total REVENUE</td>
                                    <td class="text-right pr-5">0</td>
                                </tr>

                                <tr>
                                    <th colspan="2" class="pl-5">COST OF GOODS SOLD</th>
                                </tr>
                                <tr>
                                    <td class="pl-5">Total COST OF GOODS SOLD</td>
                                    <td class="text-right pr-5">0</td>
                                </tr>
                                
                                <tr>
                                    <th class="pl-5">GROSS PROFIT</th>
                                    <th class="text-right pr-5">0</th>
                                </tr>

                                <tr>
                                    <th colspan="2" class="pl-5">OPERATING EXPENSES</th>
                                </tr>
                                <tr>
                                    <td class="pl-5">Total OPERATING EXPENSES</td>
                                    <td class="text-right pr-5">0</td>
                                </tr>

                                <tr>
                                    <th class="pl-5">OPERATING REVENUE</th>
                                    <th class="text-right pr-5">0</th>
                                </tr>

                                <tr>
                                    <th colspan="2" class="pl-5">OTHER INCOME and EXPENSES</th>
                                </tr>
                                <tr>
                                    <th colspan="2" style="padding-left:80px">Other Income</th>
                                </tr>
                                <tr>
                                    <td style="padding-left:80px">Total OtdER INCOME and EXPENSES</td>
                                    <td class="text-right pr-5">0</td>
                                </tr>

                                <tr>
                                    <th colspan="2" style="padding-left:80px">Other Expenses</th>
                                </tr>
                                <tr>
                                    <td style="padding-left:80px">Total Otder Expenses</td>
                                    <td class="text-right pr-5">0</td>
                                </tr>

                                <tr>
                                    <th class="pl-5">Total OTHER INCOME and EXPENSES</th>
                                    <th class="text-right pr-5">0</th>
                                </tr>
                                
                                <tr>
                                    <th class="pl-5">NET PROFIT/LOSS</th>
                                    <th class="text-right pr-5">0</th>
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                    <b-col md="12"><hr></b-col>
                  </b-row>
                </template>
            </iq-card>
        </b-col>
      </b-row>
    </b-container>
  </template>
  <script>
  import { xray } from '../../config/pluginInit'
  export default {
    name: 'procurementReportArusKas',
    mounted () {
      xray.index()
    }
  }
  </script>