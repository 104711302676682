var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "removeContent",
    staticClass: "chat-user-detail-popup scroller"
  }, [_c('div', {
    staticClass: "user-profile",
    class: _vm.center ? 'text-center' : ''
  }, [_c('button', {
    staticClass: "close-popup p-3",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.dismiss
    }
  }, [_c('i', {
    staticClass: "ri-close-fill"
  })]), _c('div', {
    staticClass: "user text-center mb-4"
  }, [_c('a', {
    staticClass: "avatar m-0"
  }, [_vm._t("media")], 2), _vm._t("title")], 2), _c('hr'), _c('div', {
    staticClass: "user-detail mt-4 pl-4 pr-4",
    class: _vm.center ? ' text-center ' + _vm.bodyClass : _vm.bodyClass
  }, [_vm._t("body")], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }