<template>
  <b-container fluid>
    <StepNavigation current-step="CLAIM_ANALYSIS" />
    <b-row>
      <b-col md="12" v-if="detailMi">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><b>INITIAL CLAIM ANALYSIS</b></h4>
          </template>
          <template v-slot:headerAction>
            <template v-if="detailFillSurvey">
              <b-button v-if="!isEditMode" @click="isEditMode = !isEditMode" variant="primary">Edit</b-button>
              <b-button v-else variant="primary" @click="isEditMode = !isEditMode">Preview</b-button>
            </template>
          </template>
          <template v-slot:body>
            <form action="" @submit.prevent="onSave">
              <b-row>
                <b-col cols="12" md="6" class="mb-3">
                  <b-form-group class="row" label-cols-sm="3" label="Fleet *" label-for="fleets">
                    <p v-if="detailFillSurvey && !isEditMode" class="form-control-static mb-0 mt-1">{{
                        detailMi.vehicle.name
                    }}</p>
                    <b-form-input v-else readonly :value="detailMi.vehicle.name || '-'" type="text"></b-form-input>
                  </b-form-group>
                  <b-form-group class="row" label-cols-sm="3" label="Cause of Loss *" label-for="col">
                    <p v-if="detailFillSurvey && !isEditMode" class="form-control-static mb-0 mt-1">{{
                        detailMi.type_loss.name
                    }}</p>
                    <b-form-input v-else readonly :value="detailMi.type_loss.name || '-'" type="text"></b-form-input>
                  </b-form-group>
                  <b-form-group class="row" label-cols-sm="3" label="Date of Loss (DOL) *" label-for="dol">
                    <p v-if="detailFillSurvey && !isEditMode" class="form-control-static mb-0 mt-1">{{
                        detailMi.date_of_loss
                    }}</p>
                    <b-form-input v-else readonly :value="detailMi.date_of_loss || '-'" type="text"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" class="mb-3">
                  <b-form-group class="row" label-cols-sm="3" label="Place of Loss (POL) *" label-for="pol">
                    <p v-if="detailFillSurvey && !isEditMode" class="form-control-static mb-0 mt-1">{{
                        detailMi.place_of_loss
                    }}</p>
                    <b-form-input v-else readonly :value="detailMi.place_of_loss || '-'" type="text"></b-form-input>
                  </b-form-group>
                  <b-form-group class="row" label-cols-sm="3" label="Periode of Coverage*"
                    label-for="periodeOfCoverage">
                    <p v-if="detailFillSurvey && !isEditMode" class="form-control-static mb-0 mt-1">{{
                        form.coverage_period
                    }}</p>
                    <date-picker v-else type="year" v-model="form.coverage_period" value-type="YYYY" format="YYYY"
                      placeholder="Select Year"></date-picker>
                  </b-form-group>
                  <b-form-group class="row" label-cols-sm="3" label="Police Number *" label-for="police_number">
                    <p v-if="detailFillSurvey && !isEditMode" class="form-control-static mb-0 mt-1">{{
                        form.police_number
                    }}</p>
                    <b-form-input v-else v-model="form.police_number" required type="text"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <hr />
                </b-col>
                <b-col md="2"></b-col>
                <b-col md="8">
                  <table class="table table-dark table-striped text-white">
                    <tbody>
                      <tr>
                        <td width="20%">80 - 100</td>
                        <td width="5%">:</td>
                        <td width="75%">Recommended</td>
                      </tr>
                      <tr>
                        <td width="20%">65 - 79</td>
                        <td width="5%">:</td>
                        <td width="75%">With Consideration</td>
                      </tr>
                      <tr>
                        <td width="20%">55 - 64</td>
                        <td width="5%">:</td>
                        <td width="75%">It Needs Special Attention</td>
                      </tr>
                      <tr>
                        <td width="20%">0 - 54</td>
                        <td width="5%">:</td>
                        <td width="75%">Not Recommended</td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
                <b-col md="2"></b-col>
                <b-col md="12" v-if="detailSurvey">
                  <b-table-simple responsive width="100%" striped>
                    <b-thead>
                      <b-th width="5%">NO</b-th>
                      <b-th v-for="(header, headerIndex) in detailSurvey.headers" :width="headerIndex === 3 ? '450px' : ''">{{ header.label_name }}</b-th>
                    </b-thead>
                    <b-tbody v-if="detailSurvey.headers[0]">
                      <b-tr v-for="number in detailSurvey.headers[0].contents.length">
                        <b-td>{{ number }}</b-td>
                        <b-td v-for="(header, headerIndex) in detailSurvey.headers">
                          <template v-if="header.contents[number - 1]">
                            <template v-if="header.contents[number - 1].text">
                              {{ header.contents[number - 1].text }}
                            </template>
                            <template v-else-if="header.contents[number - 1].input">
                              <template v-if="detailFillSurvey && !isEditMode">
                                <p class="form-control-static mb-0 mt-1">{{ header.contents[number - 1].input.value }}
                                </p>
                                <input type="hidden" v-if="header.contents[number - 1].input.alias === 'max100'"
                                  class="survey-input-max100" :value="header.contents[number - 1].input.value">
                              </template>
                              <template v-else>
                                <b-form-input @blur="onGenerateTotal()" class="survey-input-max100" type="number"
                                  v-if="header.contents[number - 1].input.alias === 'max100'" :maxlength="3" :min="1"
                                  :max="100" v-model="header.contents[number - 1].input.value"></b-form-input>
                                <b-form-textarea
                                  rows="6"
                                  v-else-if="header.contents[number - 1].input.alias === 'field'"
                                  v-model="header.contents[number - 1].input.value"></b-form-textarea>
                              </template>
                            </template>
                          </template>
                        </b-td>
                      </b-tr>
                      <b-tr class="iq-bg-primary">
                        <b-td colspan="3" class="text-right">
                          <h5><b>Nilai Seluruhnya</b></h5>
                        </b-td>
                        <b-td class="text-center">
                          <h5><b>{{ totalAll }}</b></h5>
                        </b-td>
                        <b-td>&nbsp;</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
                <b-col cols="12" md="6">
                  <h4 class="card-title">Conclusion (Kesimpulan)</h4>
                  <b-form-group label="Decribe of Conclusion">
                    <p v-if="detailFillSurvey && !isEditMode" class="form-control-static mb-0 mt-1">{{ form.conclusion
                    }}</p>
                    <b-form-textarea v-else v-model="form.conclusion" id="exampleFormControlTextarea1" required
                      rows="5"></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <h4 class="card-title">Comments or Suggestions (Komentar atau Saran-saran)</h4>
                  <b-form-group label="Decribe Comment or Suggestions">
                    <p v-if="detailFillSurvey && !isEditMode" class="form-control-static mb-0 mt-1">{{ form.comments }}
                    </p>
                    <b-form-textarea v-else v-model="form.comments" id="exampleFormControlTextarea1" required rows="5">
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="12" class="pull-right">
                  <p v-if="detailFillSurvey && !isEditMode" class="form-control-static mb-0 mt-1"></p>
                  <b-button v-else type="submit" variant="primary">Submit</b-button>
                </b-col>
              </b-row>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import IqCard from '@components/xray/cards/iq-card'
import { fleetsActions, miActions } from '@src/Utils/helper'
import StepNavigation from '@src/views/MI/component/StepNavigation'

export default {
  name: 'Portal',
  components: { StepNavigation, IqCard },
  async mounted () {
    xray.index()
    await this.handleInit()
  },
  data () {
    return {
      totalAll: 0,
      isEditMode: false,
      detailMi: null,
      detailSurvey: null,
      detailFillSurvey: null,
      form: {
        _saving: false,
        police_number: null,
        coverage_period: null,
        conclusion: null,
        comments: null
      }
    }
  },
  watch: {
    totalAll (value) {
      this.form.conclusion = ''
      const total = value ? Number(value) : 0

      if (total >= 0 && total <= 54) {
        this.form.conclusion = 'Not Recommended'
      } else if (total >= 55 && total <= 64) {
        this.form.conclusion = 'It Needs Special Attention'
      } else if (total >= 65 && total <= 79) {
        this.form.conclusion = 'With Consideration'
      } else if (total >= 80) {
        this.form.conclusion = 'Recommended'
      }
    }
  },
  computed: {
  },
  methods: {
    ...fleetsActions,
    ...miActions,
    async handleInit () {
      if (this.$route.query.formId) {
        await this.fetchMi()
        await this.fetchClaimForm()
        await this.fetchSurvey()
        this.onGenerateTotal()
      }
    },
    async fetchMi () {
      const { data, status } = await this.fetchDetailMi({
        id: this.$route.query.formId,
        with: 'vehicle,type_coverage,type_loss,company,documents,company_claimant,status,creator,histories'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        this.form._saving = false
        return
      }

      this.detailMi = data
    },
    async fetchSurvey () {
      const { data, status } = await this.getClaimAnalysisByFormId({
        id: this.$route.query.formId,
        survey: 'init_claim'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        this.form._saving = false
        return
      }

      this.detailSurvey = data

      if (this.detailSurvey.form) {
        this.form.coverage_period = this.detailSurvey.form.coverage_period ? this.detailSurvey.form.coverage_period.toString() : ''
        this.form.conclusion = !this.form.conclusion ? this.detailSurvey.form.conclusion : this.form.conclusion
        this.form.comments = !this.form.comments ? this.detailSurvey.form.comments : this.form.comments
      }

      if (this.detailSurvey.headers) {
        this.detailSurvey.headers.map((header, headerIndex) => {
          if (this.detailFillSurvey.headers[headerIndex] && header.contents) {
            header.contents.map((content, contentIndex) => {
              if (this.detailFillSurvey.headers[headerIndex].contents[contentIndex] &&
                this.detailFillSurvey.headers[headerIndex].contents[contentIndex].value &&
                content.input) {
                content.input.value = this.detailFillSurvey.headers[headerIndex].contents[contentIndex].value.value_anything
              }
            })
          }
        })
      }
    },
    async fetchClaimForm () {
      const { data, status } = await this.getFormClaimAnalysisByFormId({
        id: this.$route.query.formId,
        survey: 'init_claim'
      })

      if (['error'].includes(status)) {
        this.form._saving = false
        return
      }

      this.detailFillSurvey = data

      if (this.detailFillSurvey.form) {
        this.form.coverage_period = this.detailFillSurvey.form.coverage_period ? this.detailFillSurvey.form.coverage_period.toString() : ''
      }

      if (this.detailFillSurvey.claim_analysis) {
        this.form.conclusion = this.detailFillSurvey.claim_analysis.conclusion
        this.form.comments = this.detailFillSurvey.claim_analysis.comments
        this.form.police_number = this.detailFillSurvey.claim_analysis.police_number
      }
    },

    async onSave (e) {
      e.preventDefault()
      this.form._saving = true
      const contents = []
      const mapContents = this.detailSurvey.headers
        .filter(header => header.type === 'user_input' && header.contents[0] && header.contents[0].input)
        .map(header => header.contents)

      if (mapContents) {
        mapContents.forEach(content => {
          content.forEach(c => {
            contents.push({
              'id': c.id,
              'value': c.input ? c.input.value : c.text
            })
          })
        })
      }

      const { data, status } = await this.saveClaimAnalysis({
        'form_id': this.$route.query.formId,
        'survey_id': this.detailSurvey.survey.id,
        'total': this.totalAll,
        'conclusion': this.form.conclusion,
        'comments': this.form.comments,
        'police_number': this.form.police_number,
        'coverage_period': this.form.coverage_period,
        contents
      })

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        this.form._saving = false
        return
      }

      if ([500, 404, 403, 409].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        this.form._saving = false
        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      this.handleInit()
      this.isEditMode = false
      this.form._saving = false
    },
    onGenerateTotal () {
      let ret = 0

      const inputs = document.getElementsByClassName('survey-input-max100')

      if (inputs) {
        const filteredContents = []

        for (const input of inputs) {
          if (typeof input.value !== 'undefined' && input.value !== '') {
            filteredContents.push(input.value)
          }
        }

        filteredContents.forEach(input => {
          ret += parseInt(input)
        })

        if (filteredContents.length > 0) {
          ret = ret / filteredContents.length
        }

        this.totalAll = Math.round(ret)
      }
    }
  }
}
</script>
