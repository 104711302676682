import actions from './actions'
import mutations from './mutations'
import getters from './getters'

// ===
// Private helpers
// ===

function getSavedState(key, token=false) {
	if (token) return window.localStorage.getItem(key);
	return JSON.parse(window.localStorage.getItem(key));
}

const state = {
	profile: getSavedState('profile'),
	token: getSavedState('token', true),
	refresh_token: getSavedState('refresh_token', true)
}

const module = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}

export default module
