var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.detailMi ? _c('form', {
    attrs: {
      "action": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [!_vm.rootCauseAnalysis ? _c('b-alert', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_vm._v("Isi RCA Terlebih Dahulu "), _c('b-button', {
    staticClass: "ml-3",
    attrs: {
      "variant": "primary",
      "to": "/mi/root-cause-analysis?formId=".concat(_vm.$route.query.formId)
    }
  }, [_vm._v("Disini")])], 1) : _c('b-row', [_c('b-col', {
    staticClass: "mb-3 text-right",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.form._saving,
      "type": "submit"
    }
  }, [_vm._v(_vm._s(_vm.form._saving ? 'Menyimpan...' : 'Simpan'))])], 1), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("Assessor of the Accident")])]), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name"
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "readonly": "",
      "type": "text",
      "placeholder": "Name..."
    },
    model: {
      value: _vm.rootCauseAnalysis.assessor_fullname,
      callback: function callback($$v) {
        _vm.$set(_vm.rootCauseAnalysis, "assessor_fullname", $$v);
      },
      expression: "rootCauseAnalysis.assessor_fullname"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Vessel"
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "value": _vm.detailMi.vehicle.name,
      "readonly": "",
      "type": "text",
      "placeholder": "Vessel..."
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Company"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "disabled": "",
      "options": _vm.companies,
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Company")])];
      },
      proxy: true
    }], null, false, 3660131153),
    model: {
      value: _vm.rootCauseAnalysis.assessor_company_id,
      callback: function callback($$v) {
        _vm.$set(_vm.rootCauseAnalysis, "assessor_company_id", $$v);
      },
      expression: "rootCauseAnalysis.assessor_company_id"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Report Number"
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Report Number..."
    },
    model: {
      value: _vm.form.report_number,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "report_number", $$v);
      },
      expression: "form.report_number"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Position"
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "readonly": "",
      "type": "text",
      "placeholder": "Name..."
    },
    model: {
      value: _vm.rootCauseAnalysis.assessor_position,
      callback: function callback($$v) {
        _vm.$set(_vm.rootCauseAnalysis, "assessor_position", $$v);
      },
      expression: "rootCauseAnalysis.assessor_position"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Place of Loss"
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "value": _vm.detailMi.place_of_loss,
      "readonly": "",
      "type": "text",
      "placeholder": "Name..."
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type of Loss"
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "value": _vm.detailMi.type_loss.name,
      "readonly": "",
      "type": "text",
      "placeholder": "Name..."
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Type of Coverage"
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "value": _vm.detailMi.type_coverage.name,
      "readonly": "",
      "type": "text",
      "placeholder": "Name..."
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Date of Loss"
    }
  }, [_c('date-picker', {
    staticClass: "w-100",
    attrs: {
      "type": "datetime",
      "disabled": "",
      "value-type": "YYYY-MM-DD",
      "format": "DD MMM YYYY",
      "placeholder": "Select date and time"
    },
    model: {
      value: _vm.rootCauseAnalysis.date_of_loss,
      callback: function callback($$v) {
        _vm.$set(_vm.rootCauseAnalysis, "date_of_loss", $$v);
      },
      expression: "rootCauseAnalysis.date_of_loss"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Starting Date *"
    }
  }, [_c('date-picker', {
    staticClass: "w-100",
    attrs: {
      "type": "date",
      "is-required": "",
      "value-type": "YYYY-MM-DD",
      "format": "DD MMM YYYY",
      "placeholder": "Select date and time"
    },
    model: {
      value: _vm.form.starting_date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "starting_date", $$v);
      },
      expression: "form.starting_date"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Closing Date *"
    }
  }, [_c('date-picker', {
    staticClass: "w-100",
    attrs: {
      "type": "date",
      "is-required": "",
      "value-type": "YYYY-MM-DD",
      "format": "DD MMM YYYY",
      "placeholder": "Select date and time"
    },
    model: {
      value: _vm.form.closing_date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "closing_date", $$v);
      },
      expression: "form.closing_date"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-info text-center text-white p-4"
  }, [_c('h2', {
    staticClass: "text-white"
  }, [_vm._v("A")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('h2', {
    staticClass: "mt-4"
  }, [_vm._v("INVESTIGATION DETAILS")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "my-3"
  }, [_c('h4', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("A.1 Investigation Team")])]), _c('div', [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Team List")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Name")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Position")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Remarks")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.form.investigation_teams, function (data, index) {
    return _c('tr', [_c('td', [_c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Name..."
      },
      model: {
        value: data.team,
        callback: function callback($$v) {
          _vm.$set(data, "team", $$v);
        },
        expression: "data.team"
      }
    })], 1), _c('td', [_c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Name..."
      },
      model: {
        value: data.name,
        callback: function callback($$v) {
          _vm.$set(data, "name", $$v);
        },
        expression: "data.name"
      }
    })], 1), _c('td', [_c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Position..."
      },
      model: {
        value: data.position,
        callback: function callback($$v) {
          _vm.$set(data, "position", $$v);
        },
        expression: "data.position"
      }
    })], 1), _c('td', [_c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Remarks..."
      },
      model: {
        value: data.remarks,
        callback: function callback($$v) {
          _vm.$set(data, "remarks", $$v);
        },
        expression: "data.remarks"
      }
    })], 1), _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeInvestigationTeam(index);
        }
      }
    }, [_vm._v("Remove")])], 1)]);
  }), 0)]), _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addInvestigationTeam();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")])], 1)]), _c('div', {
    staticClass: "my-3"
  }, [_c('h4', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("A.2 Direct Witness")])]), _c('div', [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Name")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Position")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Remarks")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.form.direct_witnesses, function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.name : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.position : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.remarks : '-') + " ")]), _c('td')]);
  }), 0)])])]), _c('div', {
    staticClass: "my-3"
  }, [_c('h4', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("A.2 Indirect Witness")])]), _c('div', [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Name")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Position")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Remarks")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.form.indirect_witnesses, function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.name : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.position : '-') + " ")]), _c('td', [_vm._v(" " + _vm._s(data.detail ? data.detail.remarks : '-') + " ")]), _c('td')]);
  }), 0)])])])]), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-info text-center text-white p-4"
  }, [_c('h2', {
    staticClass: "text-white"
  }, [_vm._v("B")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('h2', {
    staticClass: "mt-4"
  }, [_vm._v("Supporting Document")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Document")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("Original")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("Copy")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("PIC")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Attachment")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Remarks")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.form.supporting_documents, function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "required": "",
        "type": "text",
        "placeholder": "Name..."
      },
      model: {
        value: data.document,
        callback: function callback($$v) {
          _vm.$set(data, "document", $$v);
        },
        expression: "data.document"
      }
    })], 1), _c('td', {
      staticClass: "text-center"
    }, [_c('b-form-checkbox', {
      staticClass: "w-100",
      attrs: {
        "value": "v",
        "unchecked-value": "x"
      },
      model: {
        value: data.original,
        callback: function callback($$v) {
          _vm.$set(data, "original", $$v);
        },
        expression: "data.original"
      }
    })], 1), _c('td', {
      staticClass: "text-center"
    }, [_c('b-form-checkbox', {
      staticClass: "w-100",
      attrs: {
        "value": "v",
        "unchecked-value": "x"
      },
      model: {
        value: data.copy,
        callback: function callback($$v) {
          _vm.$set(data, "copy", $$v);
        },
        expression: "data.copy"
      }
    })], 1), _c('td', [_c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "required": "",
        "type": "text",
        "placeholder": "PIC..."
      },
      model: {
        value: data.pic,
        callback: function callback($$v) {
          _vm.$set(data, "pic", $$v);
        },
        expression: "data.pic"
      }
    })], 1), _c('td', [data.document ? _c('div', {
      staticClass: "d-flex justify-content-between"
    }, [_c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "text-primary",
      attrs: {
        "href": data.document,
        "title": "Download File",
        "download": ""
      }
    }, [_c('i', {
      staticClass: "fa fa-download"
    }), _vm._v(" Download")]), _c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "text-danger",
      attrs: {
        "href": "#",
        "title": "Download",
        "download": ""
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          data.document = '';
          data.file = '';
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    })])]) : [_c('div', {
      staticClass: "custom-file"
    }, [_c('input', {
      staticClass: "custom-file-input",
      attrs: {
        "type": "file",
        "accept": "image/png,image/jpg,image/jpeg",
        "id": "validatedCustomFile"
      },
      on: {
        "change": function change($event) {
          return _vm.onSupportingDocumentsFileChanged(index, $event);
        }
      }
    }), _c('label', {
      staticClass: "custom-file-label",
      attrs: {
        "for": "validatedCustomFile"
      }
    }, [_vm._v("Browse file...")])])]], 2), _c('td', [_c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Remarks..."
      },
      model: {
        value: data.remarks,
        callback: function callback($$v) {
          _vm.$set(data, "remarks", $$v);
        },
        expression: "data.remarks"
      }
    })], 1), _c('td', {
      staticClass: "text-center"
    }, [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeSupportingDocuments(index);
        }
      }
    }, [_vm._v("Remove")])], 1)]);
  }), 0)]), _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addSupportingDocuments();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")])], 1)]), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-info text-center text-white p-4"
  }, [_c('h2', {
    staticClass: "text-white"
  }, [_vm._v("C")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('h2', {
    staticClass: "mt-4"
  }, [_vm._v("Details Of Investigation")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Images (multiple) *"
    }
  }, [_c('b-form-file', {
    staticClass: "w-100",
    attrs: {
      "accept": "image/*",
      "multiple": "",
      "placeholder": "Select file..."
    },
    on: {
      "change": _vm.onImagesFileChanged
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-info text-center text-white p-4"
  }, [_c('h2', {
    staticClass: "text-white"
  }, [_vm._v("D")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('h2', {
    staticClass: "mt-4"
  }, [_vm._v("Summary Of Accident")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "mt-2 mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "bg-light p-4 font-size-18"
  }, [_c('strong', {
    staticClass: "text-center"
  }, [_vm._v("The Problem *")])])]), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "required": "",
      "rows": "3"
    },
    model: {
      value: _vm.form.problem,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "problem", $$v);
      },
      expression: "form.problem"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "mt-2 mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "bg-light p-4 font-size-18"
  }, [_c('strong', {
    staticClass: "text-center"
  }, [_vm._v("Cause Of Loss *")])])]), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "required": "",
      "rows": "4"
    },
    model: {
      value: _vm.form.cause_of_loss,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cause_of_loss", $$v);
      },
      expression: "form.cause_of_loss"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "mt-2 mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "bg-light p-4 font-size-18"
  }, [_c('strong', {
    staticClass: "text-center"
  }, [_vm._v("Impact Of Damage *")])])]), _c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "required": "",
      "rows": "4"
    },
    model: {
      value: _vm.form.impact,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "impact", $$v);
      },
      expression: "form.impact"
    }
  })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("D.4 Cost of Damage")])]), _c('div', [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Description")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Cost")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Total")]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Remarks")])])]), _c('tbody', _vm._l(_vm.form.damage_costs, function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Name..."
      },
      model: {
        value: data.description,
        callback: function callback($$v) {
          _vm.$set(data, "description", $$v);
        },
        expression: "data.description"
      }
    })], 1), _c('td', [_c('b-input-group', {
      attrs: {
        "prepend": "Rp"
      }
    }, [_c('b-form-input', {
      attrs: {
        "type": "number"
      },
      model: {
        value: data.cost,
        callback: function callback($$v) {
          _vm.$set(data, "cost", $$v);
        },
        expression: "data.cost"
      }
    })], 1)], 1), _c('td', [_c('b-input-group', {
      attrs: {
        "prepend": "Rp"
      }
    }, [_c('b-form-input', {
      attrs: {
        "type": "number"
      },
      model: {
        value: data.total,
        callback: function callback($$v) {
          _vm.$set(data, "total", $$v);
        },
        expression: "data.total"
      }
    })], 1)], 1), _c('td', [_c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Remarks..."
      },
      model: {
        value: data.remarks,
        callback: function callback($$v) {
          _vm.$set(data, "remarks", $$v);
        },
        expression: "data.remarks"
      }
    })], 1)]);
  }), 0), _c('tfoot', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#ffd700",
      "colspan": "2"
    }
  }, [_vm._v(" TOTAL ")]), _c('th', {
    attrs: {
      "bgcolor": "#ffd700"
    }
  }, [_vm._v(" Rp. " + _vm._s(_vm.numberFormat(_vm.costOfDamagesSumCost)) + " ")]), _c('th', {
    attrs: {
      "bgcolor": "#ffd700"
    }
  }, [_vm._v(" Rp. " + _vm._s(_vm.numberFormat(_vm.costOfDamagesSumTotal)) + " ")]), _c('th', {
    attrs: {
      "bgcolor": "#ffd700"
    }
  }), _c('th', {
    attrs: {
      "bgcolor": "#ffd700"
    }
  })])])])])])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("D.5 Cause & Effect (Findings) - For Details please see attachment of Fishbone Diagram for each Witness")])])])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("D.5.1 Human")])]), _c('div', [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Findings")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Proximity Level")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.findingsByPrefix(1), function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(data.value) + " ")]), _c('td', [_c('b-form-textarea', {
      attrs: {
        "required": "",
        "rows": "2"
      },
      model: {
        value: data.text,
        callback: function callback($$v) {
          _vm.$set(data, "text", $$v);
        },
        expression: "data.text"
      }
    })], 1), _c('td', [_c('b-form-select', {
      attrs: {
        "plain": "",
        "size": "lg"
      },
      model: {
        value: data.proximity,
        callback: function callback($$v) {
          _vm.$set(data, "proximity", $$v);
        },
        expression: "data.proximity"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": null
      }
    }, [_vm._v("(Please select)")]), _vm._l(5, function (n) {
      return _c('b-form-select-option', {
        key: n,
        attrs: {
          "value": n
        }
      }, [_vm._v(_vm._s(n))]);
    })], 2)], 1), _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFindings(data.value);
        }
      }
    }, [_vm._v("Remove")])], 1)]);
  }), 0)]), _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addFindings(1);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")])], 1)])]), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/level-priority.png",
      "alt": ""
    }
  })])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("D.5.2 Machinery/Equipment etc.")])]), _c('div', [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Findings")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Proximity Level")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.findingsByPrefix(2), function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(data.value) + " ")]), _c('td', [_c('b-form-textarea', {
      attrs: {
        "required": "",
        "rows": "2"
      },
      model: {
        value: data.text,
        callback: function callback($$v) {
          _vm.$set(data, "text", $$v);
        },
        expression: "data.text"
      }
    })], 1), _c('td', [_c('b-form-select', {
      attrs: {
        "plain": "",
        "size": "lg"
      },
      model: {
        value: data.proximity,
        callback: function callback($$v) {
          _vm.$set(data, "proximity", $$v);
        },
        expression: "data.proximity"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": null
      }
    }, [_vm._v("(Please select)")]), _vm._l(5, function (n) {
      return _c('b-form-select-option', {
        key: n,
        attrs: {
          "value": n
        }
      }, [_vm._v(_vm._s(n))]);
    })], 2)], 1), _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFindings(data.value);
        }
      }
    }, [_vm._v("Remove")])], 1)]);
  }), 0)]), _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addFindings(2);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")])], 1)])]), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/level-priority.png",
      "alt": ""
    }
  })])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("D.5.3 Control, System, Method,Process.")])]), _c('div', [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Findings")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Proximity Level")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.findingsByPrefix(3), function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(data.value) + " ")]), _c('td', [_c('b-form-textarea', {
      attrs: {
        "required": "",
        "rows": "2"
      },
      model: {
        value: data.text,
        callback: function callback($$v) {
          _vm.$set(data, "text", $$v);
        },
        expression: "data.text"
      }
    })], 1), _c('td', [_c('b-form-select', {
      attrs: {
        "plain": "",
        "size": "lg"
      },
      model: {
        value: data.proximity,
        callback: function callback($$v) {
          _vm.$set(data, "proximity", $$v);
        },
        expression: "data.proximity"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": null
      }
    }, [_vm._v("(Please select)")]), _vm._l(5, function (n) {
      return _c('b-form-select-option', {
        key: n,
        attrs: {
          "value": n
        }
      }, [_vm._v(_vm._s(n))]);
    })], 2)], 1), _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFindings(data.value);
        }
      }
    }, [_vm._v("Remove")])], 1)]);
  }), 0)]), _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addFindings(3);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")])], 1)])]), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/level-priority.png",
      "alt": ""
    }
  })])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("D.5.4 Other Factor.")])]), _c('div', [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Findings")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Proximity Level")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.findingsByPrefix(4), function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(data.value) + " ")]), _c('td', [_c('b-form-textarea', {
      attrs: {
        "required": "",
        "rows": "2"
      },
      model: {
        value: data.text,
        callback: function callback($$v) {
          _vm.$set(data, "text", $$v);
        },
        expression: "data.text"
      }
    })], 1), _c('td', [_c('b-form-select', {
      attrs: {
        "plain": "",
        "size": "lg"
      },
      model: {
        value: data.proximity,
        callback: function callback($$v) {
          _vm.$set(data, "proximity", $$v);
        },
        expression: "data.proximity"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": null
      }
    }, [_vm._v("(Please select)")]), _vm._l(5, function (n) {
      return _c('b-form-select-option', {
        key: n,
        attrs: {
          "value": n
        }
      }, [_vm._v(_vm._s(n))]);
    })], 2)], 1), _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeFindings(data.value);
        }
      }
    }, [_vm._v("Remove")])], 1)]);
  }), 0)]), _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addFindings(4);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")])], 1)])]), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/level-priority.png",
      "alt": ""
    }
  })])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-info text-center text-white p-4"
  }, [_c('h2', {
    staticClass: "text-white"
  }, [_vm._v("E")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('h2', {
    staticClass: "mt-4"
  }, [_vm._v("Claims Opportunity")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("E.1 Initial Claim Analysis")])]), _vm.detailSurvey ? _c('div', [_c('b-table-simple', {
    attrs: {
      "responsive": "",
      "width": "100%",
      "striped": ""
    }
  }, [_c('b-thead', [_c('b-th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("NO")]), _vm._l(_vm.detailSurvey.headers, function (header, headerIndex) {
    return _c('b-th', [_vm._v(_vm._s(header.label_name))]);
  })], 2), _vm.detailSurvey.headers[0] ? _c('b-tbody', [_vm._l(_vm.detailSurvey.headers[0].contents.length, function (number) {
    return _c('b-tr', [_c('b-td', [_vm._v(_vm._s(number))]), _vm._l(_vm.detailSurvey.headers, function (header, headerIndex) {
      return _c('b-td', [header.contents[number - 1] ? [header.contents[number - 1].text ? [_vm._v(" " + _vm._s(header.contents[number - 1].text) + " ")] : header.contents[number - 1].input ? [_vm._v(" " + _vm._s(header.contents[number - 1].input.value) + " ")] : [_vm._v(" - ")]] : _vm._e()], 2);
    })], 2);
  }), _c('b-tr', {
    staticClass: "iq-bg-primary"
  }, [_c('b-td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "3"
    }
  }, [_c('h5', [_c('b', [_vm._v("Nilai Seluruhnya")])])]), _c('b-td', {
    staticClass: "text-center"
  }, [_c('h5', [_c('b', [_vm._v(_vm._s(_vm.totalAll))])])]), _c('b-td', [_vm._v(" ")])], 1)], 2) : _vm._e()], 1)], 1) : _c('div', [_vm._v(" - ")]), _vm.detailFillSurvey && _vm.detailFillSurvey.claim_analysis ? [_c('div', {
    staticClass: "mb-2"
  }, [_c('h4', [_vm._v("Conclusion")]), _c('div', [_vm._v(" " + _vm._s(_vm.detailFillSurvey.claim_analysis.conclusion || '-') + " ")])]), _c('div', {
    staticClass: "mb-2"
  }, [_c('h4', [_vm._v("Comments")]), _c('div', [_vm._v(" " + _vm._s(_vm.detailFillSurvey.claim_analysis.comments || '-') + " ")])])] : _vm._e()], 2)])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("E.2 Document Control")])]), _c('div', [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Document")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Remarks")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Status")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.form.document_controls, function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "required": "",
        "type": "text",
        "placeholder": "Name..."
      },
      model: {
        value: data.document,
        callback: function callback($$v) {
          _vm.$set(data, "document", $$v);
        },
        expression: "data.document"
      }
    })], 1), _c('td', [_c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Remarks..."
      },
      model: {
        value: data.remarks,
        callback: function callback($$v) {
          _vm.$set(data, "remarks", $$v);
        },
        expression: "data.remarks"
      }
    })], 1), _c('td', [_c('b-form-select', {
      attrs: {
        "plain": ""
      },
      model: {
        value: data.status,
        callback: function callback($$v) {
          _vm.$set(data, "status", $$v);
        },
        expression: "data.status"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": null
      }
    }, [_vm._v("Status")]), _c('b-form-select-option', {
      attrs: {
        "value": "Top Priority"
      }
    }, [_vm._v("Top Priority")]), _c('b-form-select-option', {
      attrs: {
        "value": "Priority"
      }
    }, [_vm._v("Priority")])], 1)], 1), _c('td', [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeDocumentControls(index);
        }
      }
    }, [_vm._v("Remove")])], 1)]);
  }), 0)])])])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addDocumentControls();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("E.3 Cost of Loss")])]), _c('div', [_c('table', {
    staticClass: "table w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Description")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Cost")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Total")]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Remarks")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.form.loss_costs, function (data, index) {
    return _c('tr', [_c('td', [_vm._v(" " + _vm._s(index + 1) + " ")]), _c('td', [_c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Name..."
      },
      model: {
        value: data.description,
        callback: function callback($$v) {
          _vm.$set(data, "description", $$v);
        },
        expression: "data.description"
      }
    })], 1), _c('td', [_c('b-input-group', {
      attrs: {
        "prepend": "Rp"
      }
    }, [_c('b-form-input', {
      attrs: {
        "type": "number"
      },
      model: {
        value: data.cost,
        callback: function callback($$v) {
          _vm.$set(data, "cost", $$v);
        },
        expression: "data.cost"
      }
    })], 1)], 1), _c('td', [_c('b-input-group', {
      attrs: {
        "prepend": "Rp"
      }
    }, [_c('b-form-input', {
      attrs: {
        "type": "number"
      },
      model: {
        value: data.total,
        callback: function callback($$v) {
          _vm.$set(data, "total", $$v);
        },
        expression: "data.total"
      }
    })], 1)], 1), _c('td', [_c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "type": "text",
        "placeholder": "Remarks..."
      },
      model: {
        value: data.remarks,
        callback: function callback($$v) {
          _vm.$set(data, "remarks", $$v);
        },
        expression: "data.remarks"
      }
    })], 1), _c('td', {
      staticClass: "text-center"
    }, [_c('b-button', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.removeCostLoss(index);
        }
      }
    }, [_vm._v("Remove")])], 1)]);
  }), 0), _c('tfoot', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#ffd700",
      "colspan": "2"
    }
  }, [_vm._v(" TOTAL ")]), _c('th', {
    attrs: {
      "bgcolor": "#ffd700"
    }
  }, [_vm._v(" Rp. " + _vm._s(_vm.numberFormat(_vm.costOfLossSumCost)) + " ")]), _c('th', {
    attrs: {
      "bgcolor": "#ffd700"
    }
  }, [_vm._v(" Rp. " + _vm._s(_vm.numberFormat(_vm.costOfLossSumTotal)) + " ")]), _c('th', {
    attrs: {
      "bgcolor": "#ffd700"
    }
  }), _c('th', {
    attrs: {
      "bgcolor": "#ffd700"
    }
  })])])]), _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addCostLoss();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")])], 1)])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('h4', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("E.4 Impact to Loss Ratio")])]), _c('h6', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("Individual")])]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Years")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Premium")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Claim Loss")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Loss Ratio")])])]), _c('tbody', [_vm._l(_vm.detailLossRatio, function (ratio) {
    return [ratio.individual ? _c('tr', [_c('td', {
      staticClass: "text-center"
    }, [_c('strong', [_vm._v(_vm._s(ratio.individual.year))])]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm.formatCurrency(ratio.individual.premium)) + ",-")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm.formatCurrency(ratio.individual.claim_loss)) + ",-")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(ratio.individual.loss_ratio) + "%")])]) : _vm._e()];
  })], 2)]), _c('h6', {
    staticClass: "my-3"
  }, [_c('strong', [_vm._v("Accumulation")])]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Years")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Acc Premium")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Acc Loss")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Acc Loss Ratio")])])]), _c('tbody', [_vm._l(_vm.detailLossRatio, function (ratio) {
    return [ratio.accumulative ? _c('tr', [_c('td', {
      staticClass: "text-center"
    }, [_c('strong', [_vm._v(_vm._s(ratio.accumulative.year))])]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm.formatCurrency(ratio.accumulative.premium)) + ",-")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm.formatCurrency(ratio.accumulative.claim_loss)) + ",-")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(ratio.accumulative.loss_ratio) + "%")])]) : _vm._e()];
  })], 2)])])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-info text-center text-white p-4"
  }, [_c('h2', {
    staticClass: "text-white"
  }, [_vm._v("F")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('h2', {
    staticClass: "mt-4"
  }, [_vm._v("Conclusion")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('div', [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "5%",
      "rowspan": "2"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "30%",
      "rowspan": "2"
    }
  }, [_vm._v("Damage")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%",
      "colspan": "4"
    }
  }, [_vm._v("Root Cause & Effect (Findings)")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "15%",
      "rowspan": "2"
    }
  }, [_vm._v("Status")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%",
      "rowspan": "2"
    }
  }, [_vm._v("Action")])]), _c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Human")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Machinery/ Equipment")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Control, System, Method,Process")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Other Factor")])])]), _c('tbody', [_vm._l(_vm.form.conclusions, function (data, index) {
    return [_vm._l(data.rows, function (row, indexRow) {
      return _c('tr', [indexRow === 0 ? _c('td', {
        staticClass: "text-center",
        attrs: {
          "rowspan": data.rows.length + 1
        }
      }, [_vm._v(" " + _vm._s(index + 1) + " ")]) : _vm._e(), indexRow === 0 ? _c('td', {
        attrs: {
          "rowspan": data.rows.length + 1
        }
      }, [_c('b-form-textarea', {
        attrs: {
          "required": "",
          "rows": "3"
        },
        model: {
          value: data.damage,
          callback: function callback($$v) {
            _vm.$set(data, "damage", $$v);
          },
          expression: "data.damage"
        }
      }), _c('b-button', {
        attrs: {
          "size": "sm",
          "variant": "link"
        },
        on: {
          "click": function click($event) {
            return _vm.removeConclusion(index);
          }
        }
      }, [_vm._v("Remove")])], 1) : _vm._e(), _c('td', {
        staticClass: "text-center"
      }, [_c('b-form-select', {
        attrs: {
          "required": "",
          "plain": ""
        },
        model: {
          value: row.human,
          callback: function callback($$v) {
            _vm.$set(row, "human", $$v);
          },
          expression: "row.human"
        }
      }, [_c('b-form-select-option', {
        attrs: {
          "value": "-"
        }
      }, [_vm._v("-")]), _vm._l(_vm.findingsByPrefix(1), function (finding) {
        return _c('b-form-select-option', {
          attrs: {
            "value": finding.value
          }
        }, [_vm._v(_vm._s(finding.value))]);
      })], 2)], 1), _c('td', {
        staticClass: "text-center"
      }, [_c('b-form-select', {
        attrs: {
          "required": "",
          "plain": ""
        },
        model: {
          value: row.equipment,
          callback: function callback($$v) {
            _vm.$set(row, "equipment", $$v);
          },
          expression: "row.equipment"
        }
      }, [_c('b-form-select-option', {
        attrs: {
          "value": "-"
        }
      }, [_vm._v("-")]), _vm._l(_vm.findingsByPrefix(2), function (finding) {
        return _c('b-form-select-option', {
          attrs: {
            "value": finding.value
          }
        }, [_vm._v(_vm._s(finding.value))]);
      })], 2)], 1), _c('td', {
        staticClass: "text-center"
      }, [_c('b-form-select', {
        attrs: {
          "required": "",
          "plain": ""
        },
        model: {
          value: row.csmp,
          callback: function callback($$v) {
            _vm.$set(row, "csmp", $$v);
          },
          expression: "row.csmp"
        }
      }, [_c('b-form-select-option', {
        attrs: {
          "value": "-"
        }
      }, [_vm._v("-")]), _vm._l(_vm.findingsByPrefix(3), function (finding) {
        return _c('b-form-select-option', {
          attrs: {
            "value": finding.value
          }
        }, [_vm._v(_vm._s(finding.value))]);
      })], 2)], 1), _c('td', {
        staticClass: "text-center"
      }, [_c('b-form-select', {
        attrs: {
          "required": "",
          "plain": ""
        },
        model: {
          value: row.other,
          callback: function callback($$v) {
            _vm.$set(row, "other", $$v);
          },
          expression: "row.other"
        }
      }, [_c('b-form-select-option', {
        attrs: {
          "value": "-"
        }
      }, [_vm._v("-")]), _vm._l(_vm.findingsByPrefix(4), function (finding) {
        return _c('b-form-select-option', {
          attrs: {
            "value": finding.value
          }
        }, [_vm._v(_vm._s(finding.value))]);
      })], 2)], 1), _c('td', {
        staticClass: "text-center",
        class: {
          'bg-danger text-white': row.status === 'fatal',
          'bg-warning text-white': row.status === 'danger',
          'bg-info text-white': row.status === 'alert',
          'bg-success text-white': row.status === 'normal'
        }
      }, [_c('b-form-select', {
        class: {
          'bg-danger text-white': row.status === 'fatal',
          'bg-warning text-white': row.status === 'danger',
          'bg-info text-white': row.status === 'alert',
          'bg-success text-white': row.status === 'normal'
        },
        attrs: {
          "plain": ""
        },
        model: {
          value: row.status,
          callback: function callback($$v) {
            _vm.$set(row, "status", $$v);
          },
          expression: "row.status"
        }
      }, [_c('b-form-select-option', {
        attrs: {
          "value": null
        }
      }, [_vm._v("Status")]), _c('b-form-select-option', {
        attrs: {
          "value": "fatal"
        }
      }, [_vm._v("Fatal")]), _c('b-form-select-option', {
        attrs: {
          "value": "danger"
        }
      }, [_vm._v("Danger")]), _c('b-form-select-option', {
        attrs: {
          "value": "alert"
        }
      }, [_vm._v("Alert")]), _c('b-form-select-option', {
        attrs: {
          "value": "normal"
        }
      }, [_vm._v("Normal")])], 1)], 1), _c('td', {
        staticClass: "text-center"
      }, [indexRow > 0 ? _c('b-button', {
        attrs: {
          "variant": "danger"
        },
        on: {
          "click": function click($event) {
            return _vm.removeConclusionRow(index, indexRow);
          }
        }
      }, [_vm._v("Remove")]) : _vm._e()], 1)]);
    }), _c('tr', [_c('td', {
      attrs: {
        "colspan": "5"
      }
    }, [_c('b-button', {
      staticClass: "mt-2 mb-3",
      attrs: {
        "variant": "warning",
        "block": ""
      },
      on: {
        "click": function click($event) {
          return _vm.addConclusionRow(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-plus"
    }), _vm._v(" Add More Data")])], 1)])];
  })], 2)]), _c('b-button', {
    staticClass: "mt-2 mb-3",
    attrs: {
      "variant": "warning",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addConclusion();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add More Data")])], 1)])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('div', {
    staticClass: "bg-info text-center text-white p-4"
  }, [_c('h2', {
    staticClass: "text-white"
  }, [_vm._v("G")])])]), _c('b-col', {
    attrs: {
      "md": "11"
    }
  }, [_c('h2', {
    staticClass: "mt-4"
  }, [_vm._v("Recommendation")])])], 1)], 1), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', [_c('div', [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "30%",
      "rowspan": "2"
    }
  }, [_vm._v("Helpful")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "30%",
      "rowspan": "2"
    }
  }, [_vm._v("Harmful")])])]), _c('tbody', [_c('tr', [_c('td', [_c('div', {
    staticClass: "text-bold"
  }, [_vm._v("Strength")]), _c('b-form-textarea', {
    attrs: {
      "required": "",
      "rows": "3"
    },
    model: {
      value: _vm.form.recommendation.strength,
      callback: function callback($$v) {
        _vm.$set(_vm.form.recommendation, "strength", $$v);
      },
      expression: "form.recommendation.strength"
    }
  })], 1), _c('td', [_c('div', {
    staticClass: "text-bold"
  }, [_vm._v("Weakness")]), _c('b-form-textarea', {
    attrs: {
      "required": "",
      "rows": "3"
    },
    model: {
      value: _vm.form.recommendation.weakness,
      callback: function callback($$v) {
        _vm.$set(_vm.form.recommendation, "weakness", $$v);
      },
      expression: "form.recommendation.weakness"
    }
  })], 1)]), _c('tr', [_c('td', [_c('div', {
    staticClass: "text-bold"
  }, [_vm._v("Opportunity")]), _c('b-form-textarea', {
    attrs: {
      "required": "",
      "rows": "3"
    },
    model: {
      value: _vm.form.recommendation.opportunity,
      callback: function callback($$v) {
        _vm.$set(_vm.form.recommendation, "opportunity", $$v);
      },
      expression: "form.recommendation.opportunity"
    }
  })], 1), _c('td', [_c('div', {
    staticClass: "text-bold"
  }, [_vm._v("Threat")]), _c('b-form-textarea', {
    attrs: {
      "required": "",
      "rows": "3"
    },
    model: {
      value: _vm.form.recommendation.threat,
      callback: function callback($$v) {
        _vm.$set(_vm.form.recommendation, "threat", $$v);
      },
      expression: "form.recommendation.threat"
    }
  })], 1)])])])])])])], 1)], 1), _c('b-col', {
    staticClass: "mb-3 text-right",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.form._saving,
      "type": "submit"
    }
  }, [_vm._v(_vm._s(_vm.form._saving ? 'Menyimpan...' : 'Simpan'))])], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }