<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="true"
                  href="#survey"
                  title="MASTER SURVEY"
                />
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="false"
                  href="#cost-breakdown"
                  title="MASTER COST BREAKDOWN"
                />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <tab-content-item :active="true" id="survey">
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                ><strong>MASTER DATA SURVEY</strong></h4
                >
              </template>
              <template v-slot:headerAction>
                <b-button variant="primary" @click="doExportProjects">
                  <template v-if="!loading_export_project">
                    <i class="fa fa-file-excel"></i> &nbsp; Export
                  </template>
                  <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Exporting...
                  </template>
                </b-button>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col cols="12" md="3">
                    <b-form-group
                      label="Search Master Data Survey:"
                      label-for="kind-fleet"
                    >
                      <b-form-input
                        id="fleet_name"
                        class="w-100"
                        type="text"
                        placeholder="Search..."
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="2">
                    <b-form-group label="Search" label-for="button_search">
                      <b-button
                        variant="primary"
                        class="ml-2"
                      ><i class="fa fa-search"></i
                      ></b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">List Master Survey</h4>
              </template>
              <template v-slot:headerAction>
               <router-link to="/mi/add-survey"> <b-button variant="success" class="mr-1"><i class="fa fa-plus"></i>&nbsp;Add More Survey</b-button></router-link>
              </template>
              <template v-slot:body>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th width="10%">No</th>
                      <th width="25%">Survey Name</th>
                      <th width="15%">Status</th>
                      <th width="10%">Count of Question</th>
                      <th width="15%">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(survey, index) in surveys.data">
                      <td>{{index+1}}.</td>
                      <td>{{survey.survey_name}}</td>
                      <td>
                        <b-badge v-if="survey.active" variant="success">Active</b-badge>
                        <b-badge v-else variant="danger">Inactive</b-badge>
                      </td>
                      <td>{{ survey.number_of_label }}</td>
                      <td>
                        <b-button :to="`/mi/add-survey?id=${survey.id}`" variant="success" size="sm" class="mr-2"><i class="fa fa-edit"></i></b-button>
                        <b-button variant="danger" size="sm"><i class="fa fa-trash"></i></b-button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
      <tab-content-item :active="false" id="cost-breakdown">
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                ><strong>MASTER DATA COST BREAKDOWN</strong></h4
                >
              </template>
              <template v-slot:headerAction>
                <b-button variant="primary" @click="doExportProjects">
                  <template v-if="!loading_export_project">
                    <i class="fa fa-file-excel"></i> &nbsp; Export
                  </template>
                  <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Exporting...
                  </template>
                </b-button>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col cols="12" md="3">
                    <b-form-group
                      label="Search Master Data Survey:"
                      label-for="kind-fleet"
                    >
                      <b-form-input
                        id="fleet_name"
                        class="w-100"
                        type="text"
                        placeholder="Search..."
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="2">
                    <b-form-group label="Search" label-for="button_search">
                      <b-button
                        variant="primary"
                        class="ml-2"
                      ><i class="fa fa-search"></i
                      ></b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="12">
            <iq-card>
              <template v-slot:body>
                <b-row>
                  <b-col md="8">
                    <b-table-simple>
                      <thead>
                      <tr width="100%">
                        <th class="text-center" width="5%">NO</th>
                        <th width="35%">DESCRIPTION / ITEM</th>
                        <th width="20%">Status</th>
                        <th width="10%">Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(costBreakdown, index) in costBreakdowns.data">
                        <td>{{index+1}}</td>
                        <td>{{costBreakdown.description}}</td>
                        <td>
                          <b-badge v-if="costBreakdown.active" variant="success">Active</b-badge>
                          <b-badge v-else variant="danger">Inactive</b-badge>
                        </td>
                        <td><b-button variant="success" size="sm" class="mr-2"><i class="fa fa-edit"></i></b-button></td>
                      </tr>
                      </tbody>
                    </b-table-simple>
                  </b-col>
                  <b-col md="4">
                    <iq-card class="border border-light shadow-lg">
                      <template v-slot:headerTitle>
                        <h4 class="card-title">Form Master Options Cost-Breakdown</h4>
                      </template>
                      <template v-slot:body>
                        <form action="" @submit.prevent="onSaveCostBreakdown">
                          <b-row>
                            <b-col>
                              <b-form-group label="Description:" label-for="serial">
                                <b-form-textarea required v-model="formCostBreakdown.description" id="exampleFormControlTextarea1"></b-form-textarea>
                              </b-form-group>
                              <b-form-group label="Status" label-for="sub_sub_project">
                                <b-form-checkbox v-model="formCostBreakdown.active" name="check-button" switch inline>
                                  Active
                                </b-form-checkbox>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-button type="submit" variant="primary">Save</b-button>
                              <b-button variant="none" class="iq-bg-danger ml-3" @click="resetFormCostBreakdown()">Cancel</b-button>
                            </b-col>
                          </b-row>
                        </form>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
    </div>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { companiesActions, fleetsActions, miActions } from '@src/Utils/helper'

export default {
  name: 'BlankPage',
  mounted () {
    xray.index()
    this.fetchSurveys()
    this.fetchCostBreakdowns()
    this.resetFormCostBreakdown()
  },
  data () {
    return {
      image: require('../../assets/images/logo.png'),
      title: 'Add Survey',
      companySelected: [
        {
          id: 'eti',
          name: 'PT.Energy Transporter Indonesia'
        },
        {
          id: 'tcp',
          name: 'PT.Transcoal Pacific'
        }
      ],
      companyOptions: [
        {
          id: 'eti',
          name: 'PT.Energy Transporter Indonesia'
        },
        {
          id: 'tcp',
          name: 'PT.Transcoal Pacific'
        },
        {
          id: 'sml',
          name: 'PT.Sentra Makmur Lines'
        },
        {
          id: 'rmt',
          name: 'PT.Renjani Maritim Transport'
        }
      ],
      typeOfLabel: 'textLabel',
      typeOptions: [
        { value: 'textLabel', text: 'Text Label' },
        { value: 'userInput', text: 'User Input' }
      ],
      userInput: 'numberMax10',
      userInputOptions: [
        { value: 'numberMax10', text: 'No. Max 10' },
        { value: 'numberMax100', text: 'No. Max 100' },
        { value: 'checlist', text: 'Checklist' },
        { value: 'star', text: 'Star' },
        { value: 'text', text: 'Text Field' }
      ],

      surveys: {
        isLoading: false,
        data: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        },
        params: {
          page: 1
          // with: 'vehicle,type_coverage,type_loss,company,documents,company_claimant,status,creator,histories'
        }
      },
      costBreakdowns: {
        isLoading: false,
        data: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        },
        params: {
          page: 1
          // with: 'vehicle,type_coverage,type_loss,company,documents,company_claimant,status,creator,histories'
        }
      },
      formCostBreakdown: {
        'description': '',
        'active': 0
      }
    }
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    ...miActions,
    async fetchSurveys (page = 1) {
      this.surveys.data = []
      this.surveys.isLoading = true
      this.surveys.params.page = page
      const { data, status } = await this.getMasterSurvey(this.surveys.params)

      if (data) {
        this.surveys.data = data
      }

      this.surveys.isLoading = false
    },
    async fetchCostBreakdowns (page = 1) {
      this.costBreakdowns.data = []
      this.costBreakdowns.isLoading = true
      this.costBreakdowns.params.page = page
      const { data, status } = await this.getMasterCostBreakdown(this.costBreakdowns.params)

      if (data) {
        this.costBreakdowns.data = data
      }

      this.costBreakdowns.isLoading = false
    },
    async onSaveCostBreakdown (e) {
      e.preventDefault()

      const { data, status } = await this.saveMasterCostBreakdown(this.formCostBreakdown)

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )
      this.resetFormCostBreakdown()
      this.fetchCostBreakdowns(1)
    },
    resetFormCostBreakdown () {
      this.formCostBreakdown.description = ''
      this.formCostBreakdown.active = 0
    }
  }
}
</script>
