var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-collapse', {
    class: _vm.className,
    attrs: {
      "tag": "ul",
      "visible": _vm.open,
      "id": _vm.idName,
      "accordion": _vm.accordianName
    }
  }, _vm._l(_vm.items, function (item, index) {
    return _c('li', {
      key: index,
      class: {
        'active': _vm.activeLink(item)
      }
    }, [!item.heading ? _c('i', {
      staticClass: "ri-subtract-line"
    }) : _vm._e(), !item.heading ? _c('span', [_vm._v(_vm._s(_vm.$t(item.menu)))]) : _vm._e(), item.heading && item.className != 'true' ? _c('router-link', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: item.menu,
        expression: "item.menu"
      }],
      class: "iq-waves-effect ".concat(_vm.activeLink(item) && item.child ? 'active' : _vm.activeLink(item) ? 'active' : ''),
      attrs: {
        "to": {
          name: item.props,
          query: {
            menuId: item.menuId
          }
        }
      }
    }, [item.iconClass ? _c('i', {
      class: item.icon
    }) : void 0, _c('span', [_vm._v(_vm._s(_vm.$t(item.menu)))])], 2) : _vm._e()], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }