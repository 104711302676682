var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Embeds Video")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-1",
            modifiers: {
              "collapse-1": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Wrap any embed like an "), _c('code', {
          staticClass: "highlighter-rouge"
        }, [_vm._v("<iframe>")]), _vm._v(" in a parent element with "), _c('code', {
          staticClass: "highlighter-rouge"
        }, [_vm._v(".embed-responsive")]), _vm._v(" and an aspect ratio. The "), _c('code', {
          staticClass: "highlighter-rouge"
        }, [_vm._v(".embed-responsive-item")]), _vm._v(" isn’t strictly required, but we encourage it.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-1"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "embeds-video"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-embed\n  type=\"iframe\"\n  aspect=\"16by9\"\n  src=\"https://www.youtube.com/embed/zpOULjyy-n8?rel=0\"\n  allowfullscreen\n></b-embed>\n")])])])])]), _c('b-embed', {
          attrs: {
            "type": "iframe",
            "aspect": "16by9",
            "src": "https://www.youtube.com/embed/zpOULjyy-n8?rel=0",
            "allowfullscreen": ""
          }
        })];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Responsive Aspect ratios 4:3")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-2",
            modifiers: {
              "collapse-2": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Progress components are built with two HTML elements, some CSS to set the width, and a few attributes.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-2"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "ratios-1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-embed\n  type=\"iframe\"\n  aspect=\"4by3\"\n  src=\"https://www.youtube.com/embed/zpOULjyy-n8?rel=0\"\n  allowfullscreen\n></b-embed>\n")])])])])]), _c('b-embed', {
          attrs: {
            "type": "iframe",
            "aspect": "4by3",
            "src": "https://www.youtube.com/embed/zpOULjyy-n8?rel=0",
            "allowfullscreen": ""
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Responsive Aspect ratios 21:9")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-3",
            modifiers: {
              "collapse-3": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Progress components are built with two HTML elements, some CSS to set the width, and a few attributes.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-3"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "ratios-2"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-embed\n  type=\"iframe\"\n  aspect=\"21by9\"\n  src=\"https://www.youtube.com/embed/zpOULjyy-n8?rel=0\"\n  allowfullscreen\n></b-embed>\n")])])])])]), _c('b-embed', {
          attrs: {
            "type": "iframe",
            "aspect": "21by9",
            "src": "https://www.youtube.com/embed/zpOULjyy-n8?rel=0",
            "allowfullscreen": ""
          }
        })];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Responsive Aspect ratios 1:1")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-4",
            modifiers: {
              "collapse-4": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Progress components are built with two HTML elements, some CSS to set the width, and a few attributes.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-4"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "ratios-3"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-embed\n  type=\"iframe\"\n  aspect=\"1by1\"\n  src=\"https://www.youtube.com/embed/zpOULjyy-n8?rel=0\"\n  allowfullscreen\n></b-embed>\n")])])])])]), _c('b-embed', {
          attrs: {
            "type": "iframe",
            "aspect": "1by1",
            "src": "https://www.youtube.com/embed/zpOULjyy-n8?rel=0",
            "allowfullscreen": ""
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }