var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Slides only")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-1",
            modifiers: {
              "collapse-1": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Here’s a carousel with slides only. Note the presence of the "), _c('code', [_vm._v(".d-block")]), _vm._v(" and "), _c('code', [_vm._v(".img-fluid")]), _vm._v(" on carousel images to prevent browser default image alignment.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-1"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "slides-only"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-carousel\n  id=\"carouselExampleSlidesOnly\"\n>\n  <b-carousel-slide :img-src=\"require('../../assets/images/small/img-1.jpg')\"></b-carousel-slide>\n  <b-carousel-slide :img-src=\"require('../../assets/images/small/img-1.jpg')\"></b-carousel-slide>\n  <b-carousel-slide :img-src=\"require('../../assets/images/small/img-1.jpg')\"></b-carousel-slide>\n</b-carousel>\n")])])])])]), _c('b-carousel', {
          attrs: {
            "id": "carouselExampleSlidesOnly"
          }
        }, [_c('b-carousel-slide', {
          attrs: {
            "img-src": require('../../assets/images/small/img-1.jpg')
          }
        }), _c('b-carousel-slide', {
          attrs: {
            "img-src": require('../../assets/images/small/img-1.jpg')
          }
        }), _c('b-carousel-slide', {
          attrs: {
            "img-src": require('../../assets/images/small/img-1.jpg')
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Slides With Controls")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-2",
            modifiers: {
              "collapse-2": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Here’s a carousel with slides only. Note the presence of the "), _c('code', [_vm._v(".d-block")]), _vm._v(" and "), _c('code', [_vm._v(".img-fluid")]), _vm._v(" on carousel images to prevent browser default image alignment.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-2"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "slides-controls"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-carousel\n  id=\"carouselExampleControls\"\n  controls\n>\n  <b-carousel-slide :img-src=\"require('../../assets/images/small/img-1.jpg')\"></b-carousel-slide>\n  <b-carousel-slide :img-src=\"require('../../assets/images/small/img-1.jpg')\"></b-carousel-slide>\n  <b-carousel-slide :img-src=\"require('../../assets/images/small/img-1.jpg')\"></b-carousel-slide>\n</b-carousel>\n")])])])])]), _c('b-carousel', {
          attrs: {
            "id": "carouselExampleControls",
            "controls": ""
          }
        }, [_c('b-carousel-slide', {
          attrs: {
            "img-src": require('../../assets/images/small/img-1.jpg')
          }
        }), _c('b-carousel-slide', {
          attrs: {
            "img-src": require('../../assets/images/small/img-1.jpg')
          }
        }), _c('b-carousel-slide', {
          attrs: {
            "img-src": require('../../assets/images/small/img-1.jpg')
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Slides With Indicators")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-3",
            modifiers: {
              "collapse-3": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Here’s a carousel with slides only. Note the presence of the "), _c('code', [_vm._v(".d-block")]), _vm._v(" and "), _c('code', [_vm._v(".img-fluid")]), _vm._v(" on carousel images to prevent browser default image alignment.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-3"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "slides-indicators"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-carousel\n  id=\"carouselExampleIndicators\"\n  controls\n  indicators\n>\n  <b-carousel-slide :img-src=\"require('../../assets/images/small/img-1.jpg')\"></b-carousel-slide>\n  <b-carousel-slide :img-src=\"require('../../assets/images/small/img-1.jpg')\"></b-carousel-slide>\n  <b-carousel-slide :img-src=\"require('../../assets/images/small/img-1.jpg')\"></b-carousel-slide>\n</b-carousel>\n")])])])])]), _c('b-carousel', {
          attrs: {
            "id": "carouselExampleIndicators",
            "controls": "",
            "indicators": ""
          }
        }, [_c('b-carousel-slide', {
          attrs: {
            "img-src": require('../../assets/images/small/img-1.jpg')
          }
        }), _c('b-carousel-slide', {
          attrs: {
            "img-src": require('../../assets/images/small/img-1.jpg')
          }
        }), _c('b-carousel-slide', {
          attrs: {
            "img-src": require('../../assets/images/small/img-1.jpg')
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Slides With Captions")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-4",
            modifiers: {
              "collapse-4": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Here’s a carousel with slides only. Note the presence of the "), _c('code', [_vm._v(".d-block")]), _vm._v(" and "), _c('code', [_vm._v(".img-fluid")]), _vm._v(" on carousel images to prevent browser default image alignment.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-4"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "slides-captions"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-carousel\n  id=\"carouselExampleCaptions\"\n  controls\n  indicators\n>\n  <b-carousel-slide :img-src=\"require('../../assets/images/small/img-1.jpg')\"></b-carousel-slide>\n  <b-carousel-slide :img-src=\"require('../../assets/images/small/img-1.jpg')\"></b-carousel-slide>\n  <b-carousel-slide :img-src=\"require('../../assets/images/small/img-1.jpg')\"></b-carousel-slide>\n</b-carousel>\n")])])])])]), _c('b-carousel', {
          attrs: {
            "id": "carouselExampleCaptions",
            "controls": "",
            "indicators": ""
          }
        }, [_c('b-carousel-slide', {
          attrs: {
            "img-src": require('../../assets/images/small/img-1.jpg')
          }
        }, [_c('h4', {
          staticClass: "text-white"
        }, [_vm._v("First slide label")]), _c('p', [_vm._v("Nulla vitae elit libero, a pharetra augue mollis interdum.")])]), _c('b-carousel-slide', {
          attrs: {
            "img-src": require('../../assets/images/small/img-1.jpg')
          }
        }, [_c('h4', {
          staticClass: "text-white"
        }, [_vm._v("Second slide label")]), _c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit.")])]), _c('b-carousel-slide', {
          attrs: {
            "img-src": require('../../assets/images/small/img-1.jpg')
          }
        }, [_c('h4', {
          staticClass: "text-white"
        }, [_vm._v("Third slide label")]), _c('p', [_vm._v("Praesent commodo cursus magna, vel scelerisque nisl consectetur.")])])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Slides With Crossfade")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-5",
            modifiers: {
              "collapse-5": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Here’s a carousel with slides only. Note the presence of the "), _c('code', [_vm._v(".d-block")]), _vm._v(" and "), _c('code', [_vm._v(".img-fluid")]), _vm._v(" on carousel images to prevent browser default image alignment.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-5"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "slides-fade"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-carousel\n  id=\"carouselExampleFade\"\n  fade\n  controls\n>\n  <b-carousel-slide :img-src=\"require('../../assets/images/small/img-1.jpg')\"></b-carousel-slide>\n  <b-carousel-slide :img-src=\"require('../../assets/images/small/img-1.jpg')\"></b-carousel-slide>\n  <b-carousel-slide :img-src=\"require('../../assets/images/small/img-1.jpg')\"></b-carousel-slide>\n</b-carousel>\n")])])])])]), _c('b-carousel', {
          attrs: {
            "id": "carouselExampleFade",
            "fade": "",
            "controls": ""
          }
        }, [_c('b-carousel-slide', {
          attrs: {
            "img-src": require('../../assets/images/small/img-1.jpg')
          }
        }), _c('b-carousel-slide', {
          attrs: {
            "img-src": require('../../assets/images/small/img-1.jpg')
          }
        }), _c('b-carousel-slide', {
          attrs: {
            "img-src": require('../../assets/images/small/img-1.jpg')
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }