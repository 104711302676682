var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("{ Selected Year } - Balancing Loss Ratio")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "medium"
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        }), _vm._v(" Edit")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_vm._v("Vehicle Type")]), _c('div', [_c('span', {
          staticClass: "font-size-18"
        }, [_vm._v("Tug Boat, ")]), _c('span', {
          staticClass: "font-size-18"
        }, [_vm._v("Barge, ")]), _c('span', {
          staticClass: "font-size-18"
        }, [_vm._v("Pusher Tug, ")]), _c('span', {
          staticClass: "font-size-18"
        }, [_vm._v("Pusher Barge, ")]), _c('span', {
          staticClass: "font-size-18"
        }, [_vm._v("Sea Truck, ")]), _c('span', {
          staticClass: "font-size-18"
        }, [_vm._v("Floating Crane, ")])])])]), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_vm._v("Year")]), _c('h4', [_vm._v("{ Select Year}")])])])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_c('h4', {
          staticClass: "text-capitalize"
        }, [_vm._v("Forecast "), _c('strong', [_vm._v("{ next_year }")])])]), _c('h3', [_vm._v("IDR -")])])]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_c('h4', {
          staticClass: "text-capitalize"
        }, [_vm._v("Actual Premium "), _c('strong', [_vm._v("{ next_year }")])])]), _c('h3', [_vm._v("IDR -")])])]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_c('h4', {
          staticClass: "text-capitalize"
        }, [_vm._v("Rate HM "), _c('strong', [_vm._v("{ next_year }")])])]), _c('h3', [_vm._v("IDR -")])])])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "8"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_c('h4', {
          staticClass: "text-capitalize"
        }, [_vm._v("Loading Point")])]), _c('h3', [_vm._v("-")])])]), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_c('h4', {
          staticClass: "text-capitalize"
        }, [_vm._v("Loading Point Insurer")])]), _c('h3', [_vm._v("-")])])]), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "form-group"
        }, [_c('label', {
          staticClass: "mb-3"
        }, [_c('h4', {
          staticClass: "text-capitalize"
        }, [_vm._v("Final Loading Point")])]), _c('h3', [_vm._v("-")])])])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "mb-4"
        }, [_vm._v(" NILAI TAMBAH YANG DIBERIKAN OLEH ASURANSI KEPADA OWNER UNTUK MENAMBAH BEBAN RATE ")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Claim")]), _c('th', [_vm._v("Percentage (%)")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("0 - IDR 1.000.000.000")]), _c('td', [_vm._v("2.5%")])]), _c('tr', [_c('td', [_vm._v("IDR 1.100.000.000 - IDR 2.000.000.000")]), _c('td', [_vm._v("5.0%")])]), _c('tr', [_c('td', [_vm._v("IDR 2.100.000.000 - IDR 3.000.000.000")]), _c('td', [_vm._v("7.5%")])]), _c('tr', [_c('td', [_vm._v("IDR 3.100.000.000 - IDR 4.000.000.000")]), _c('td', [_vm._v("10.0%")])]), _c('tr', [_c('td', [_vm._v("IDR 4.100.000.000 - IDR 5.000.000.000")]), _c('td', [_vm._v("12.5%")])]), _c('tr', [_c('td', [_vm._v("> IDR 5.100.000.000")]), _c('td', [_vm._v("15.0%")])])])])])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-capitalize text-black"
        }, [_c('strong', [_vm._v("Step 1 - Pure Risk Component")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6"
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Details")]), _c('th', [_vm._v("Percentage")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("1.")]), _c('td', [_vm._v("OVERHEAD COST (OPERATIONAL)")]), _c('td', [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "%"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "max-width": "50%"
          },
          attrs: {
            "type": "number"
          }
        })], 1)], 1)]), _c('tr', [_c('td', [_vm._v("2.")]), _c('td', [_vm._v("ACQUISITION")]), _c('td', [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "%"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "max-width": "50%"
          },
          attrs: {
            "type": "number"
          }
        })], 1)], 1)]), _c('tr', [_c('td', [_vm._v("3.")]), _c('td', [_vm._v("REINSURANCE COST")]), _c('td', [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "%"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "max-width": "50%"
          },
          attrs: {
            "type": "number"
          }
        })], 1)], 1)]), _c('tr', [_c('td', [_vm._v("4.")]), _c('td', [_vm._v("INFLATION ")]), _c('td', [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "%"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "max-width": "50%"
          },
          attrs: {
            "type": "number"
          }
        })], 1)], 1)]), _c('tr', [_c('td', [_vm._v("5.")]), _c('td', [_vm._v("EXPECTED PROFIT")]), _c('td', [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "%"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "max-width": "50%"
          },
          attrs: {
            "type": "number"
          }
        })], 1)], 1)])]), _c('tfoot', [_c('tr', [_c('td', {
          staticClass: "text-center bg-success",
          attrs: {
            "colspan": "2"
          }
        }, [_c('strong', [_vm._v("Total")])]), _c('td', {
          staticClass: "text-center bg-success"
        }, [_c('strong', [_vm._v("Sum(Point 1 - 5)")])])]), _c('tr', [_c('td', {
          staticClass: "text-center bg-info",
          attrs: {
            "colspan": "2"
          }
        }, [_c('strong', [_vm._v("Pure Risk Cost")])]), _c('td', {
          staticClass: "text-center bg-info"
        }, [_c('strong', [_vm._v("100% - Total")])])])])])])]), _c('div', {
          staticClass: "col-md-6"
        }, [_c('img', {
          staticClass: "mt-5",
          attrs: {
            "src": require("@assets/images/financial-pure-risk-component.jpg"),
            "width": "100%"
          }
        })])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-capitalize text-black"
        }, [_c('strong', [_vm._v("Step 2 - Outstanding & Future Claim")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-12 mb-3"
        }, [_c('h4', {
          staticClass: "mb-4"
        }, [_vm._v("Outstanding")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Type")]), _c('th', [_vm._v("Vessel / Form Claim Linked")]), _c('th', [_vm._v("EST Cost")]), _c('th', [_vm._v("Additional THUMB RULES (1%)")]), _c('th', [_vm._v("Est Closed File")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Period Coverage")]), _c('th', [_vm._v("#")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("1.")]), _c('td', [_c('div', {
          staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color ml-2"
        }, [_c('span', [_c('small', [_vm._v("Manual Input (Free Text) ?  ")])]), _c('div', {
          staticClass: "custom-switch-inner"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.premiumFromBudget.checked,
            expression: "premiumFromBudget.checked"
          }],
          staticClass: "custom-control-input",
          class: 'bg-' + _vm.premiumFromBudget.class,
          attrs: {
            "type": "checkbox",
            "id": 'premiumFromBudget'
          },
          domProps: {
            "checked": Array.isArray(_vm.premiumFromBudget.checked) ? _vm._i(_vm.premiumFromBudget.checked, null) > -1 : _vm.premiumFromBudget.checked
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.premiumFromBudget.checked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.premiumFromBudget, "checked", $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.premiumFromBudget, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.premiumFromBudget, "checked", $$c);
              }
            }
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": 'premiumFromBudget',
            "data-on-label": _vm.premiumFromBudget.activeLabel,
            "data-off-label": _vm.premiumFromBudget.inactiveLabel
          }
        })])])]), _c('td', [_c('b-form-group', {
          attrs: {
            "label": "Link to Case"
          }
        }, [_c('small', [_vm._v("Before & Current Year")]), _c('v-select', {
          attrs: {
            "placeholder": "Link To Case",
            "label": "text"
          }
        })], 1)], 1), _c('td', [_c('b-input-group', {
          attrs: {
            "size": "md",
            "prepend": "IDR"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          }
        })], 1)], 1), _c('td', [_c('h5', [_vm._v("IDR { EST Cost x 1% }")])]), _c('td', [_c('date-picker', {
          attrs: {
            "type": "date",
            "value-type": "YYYY-MM-DD",
            "placeholder": "Select date"
          }
        })], 1), _c('td', [_c('v-select', {
          attrs: {
            "options": _vm.statusOption,
            "reduce": function reduce(statusOption) {
              return statusOption.code;
            },
            "placeholder": "Status",
            "label": "text"
          },
          model: {
            value: _vm.status,
            callback: function callback($$v) {
              _vm.status = $$v;
            },
            expression: "status"
          }
        })], 1), _c('td', [_c('date-picker', {
          attrs: {
            "type": "month",
            "value-type": "YYYY-MM",
            "range": "",
            "placeholder": "Select Period Coverage"
          }
        })], 1), _c('td', [_c('b-button', {
          attrs: {
            "variant": "danger",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash"
        })])], 1)]), _c('tr', [_c('td', [_vm._v("2.")]), _c('td', [_c('div', {
          staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color ml-2"
        }, [_c('span', [_c('small', [_vm._v("Manual Input (Free Text) ?  ")])]), _c('div', {
          staticClass: "custom-switch-inner"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.premiumFromBudget.checked,
            expression: "premiumFromBudget.checked"
          }],
          staticClass: "custom-control-input",
          class: 'bg-' + _vm.premiumFromBudget.class,
          attrs: {
            "type": "checkbox",
            "id": 'premiumFromBudget'
          },
          domProps: {
            "checked": Array.isArray(_vm.premiumFromBudget.checked) ? _vm._i(_vm.premiumFromBudget.checked, null) > -1 : _vm.premiumFromBudget.checked
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.premiumFromBudget.checked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.premiumFromBudget, "checked", $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.premiumFromBudget, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.premiumFromBudget, "checked", $$c);
              }
            }
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": 'premiumFromBudget',
            "data-on-label": _vm.premiumFromBudget.activeLabel,
            "data-off-label": _vm.premiumFromBudget.inactiveLabel
          }
        })])])]), _c('td', [_c('b-form-group', {
          attrs: {
            "label": "Link to Case"
          }
        }, [_c('small', [_vm._v("Before & Current Year")]), _c('v-select', {
          attrs: {
            "placeholder": "Link To Case",
            "label": "text"
          }
        })], 1)], 1), _c('td', [_c('b-input-group', {
          attrs: {
            "size": "md",
            "prepend": "IDR"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          }
        })], 1)], 1), _c('td', [_c('h5', [_vm._v("IDR { EST Cost x 1% }")])]), _c('td', [_c('date-picker', {
          attrs: {
            "type": "date",
            "value-type": "YYYY-MM-DD",
            "placeholder": "Select date"
          }
        })], 1), _c('td', [_c('v-select', {
          attrs: {
            "options": _vm.statusOption,
            "reduce": function reduce(statusOption) {
              return statusOption.code;
            },
            "placeholder": "Status",
            "label": "text"
          },
          model: {
            value: _vm.status,
            callback: function callback($$v) {
              _vm.status = $$v;
            },
            expression: "status"
          }
        })], 1), _c('td', [_c('date-picker', {
          attrs: {
            "type": "month",
            "value-type": "YYYY-MM",
            "range": "",
            "placeholder": "Select Period Coverage"
          }
        })], 1), _c('td', [_c('b-button', {
          attrs: {
            "variant": "danger",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash"
        })])], 1)]), _c('tr', [_c('td', [_vm._v("3.")]), _c('td', [_c('div', {
          staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color ml-2"
        }, [_c('span', [_c('small', [_vm._v("Manual Input (Free Text) ?  ")])]), _c('div', {
          staticClass: "custom-switch-inner"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.premiumFromBudget.checked,
            expression: "premiumFromBudget.checked"
          }],
          staticClass: "custom-control-input",
          class: 'bg-' + _vm.premiumFromBudget.class,
          attrs: {
            "type": "checkbox",
            "id": 'premiumFromBudget'
          },
          domProps: {
            "checked": Array.isArray(_vm.premiumFromBudget.checked) ? _vm._i(_vm.premiumFromBudget.checked, null) > -1 : _vm.premiumFromBudget.checked
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.premiumFromBudget.checked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.premiumFromBudget, "checked", $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.premiumFromBudget, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.premiumFromBudget, "checked", $$c);
              }
            }
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": 'premiumFromBudget',
            "data-on-label": _vm.premiumFromBudget.activeLabel,
            "data-off-label": _vm.premiumFromBudget.inactiveLabel
          }
        })])])]), _c('td', [_c('b-form-group', {
          attrs: {
            "label": "Link to Case"
          }
        }, [_c('small', [_vm._v("Before & Current Year")]), _c('v-select', {
          attrs: {
            "placeholder": "Link To Case",
            "label": "text"
          }
        })], 1)], 1), _c('td', [_c('b-input-group', {
          attrs: {
            "size": "md",
            "prepend": "IDR"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          }
        })], 1)], 1), _c('td', [_c('h5', [_vm._v("IDR { EST Cost x 1% }")])]), _c('td', [_c('date-picker', {
          attrs: {
            "type": "date",
            "value-type": "YYYY-MM-DD",
            "placeholder": "Select date"
          }
        })], 1), _c('td', [_c('v-select', {
          attrs: {
            "options": _vm.statusOption,
            "reduce": function reduce(statusOption) {
              return statusOption.code;
            },
            "placeholder": "Status",
            "label": "text"
          },
          model: {
            value: _vm.status,
            callback: function callback($$v) {
              _vm.status = $$v;
            },
            expression: "status"
          }
        })], 1), _c('td', [_c('date-picker', {
          attrs: {
            "type": "month",
            "value-type": "YYYY-MM",
            "range": "",
            "placeholder": "Select Period Coverage"
          }
        })], 1), _c('td', [_c('b-button', {
          attrs: {
            "variant": "danger",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash"
        })])], 1)])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "warning",
            "block": ""
          }
        }, [_c('i', {
          staticClass: "ri-add-line"
        }), _vm._v(" Add More Data Outstanding")])], 1)], 1), _c('div', {
          staticClass: "col-md-12"
        }, [_c('hr')]), _c('div', {
          staticClass: "col-md-12 mb-3"
        }, [_c('h4', {
          staticClass: "mb-4"
        }, [_vm._v("Future Claim")]), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Items")]), _c('th', [_vm._v("Content")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Est Increase Loss Ratio")]), _c('td', [_c('b-textarea', {
          attrs: {
            "rows": "2",
            "value": "Increase loss ratio up to 100% in the next 2 Years  - Approximate 50% per year in the next 2 years (2022 -2023)"
          }
        })], 1)]), _c('tr', [_c('td', [_vm._v("Condition")]), _c('td', [_c('b-textarea', {
          attrs: {
            "rows": "2",
            "value": "Below </span> <span><strong>{value_of_pure_risk} </strong></span><span>per Years (Pure Risk Cost)"
          }
        })], 1)]), _c('tr', [_c('td', [_vm._v("Amount /Years")]), _c('td', [_c('b-input-group', {
          attrs: {
            "size": "md",
            "prepend": "IDR"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "max-width": "50%"
          },
          attrs: {
            "type": "number"
          }
        })], 1)], 1)]), _c('tr', [_c('td', [_vm._v("Estimate Loss Future Until Renewal")]), _c('td', [_c('b-input-group', {
          attrs: {
            "size": "md",
            "prepend": "IDR"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "max-width": "50%"
          },
          attrs: {
            "type": "number"
          }
        })], 1)], 1)])])])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-capitalize text-black"
        }, [_c('strong', [_vm._v("Step 3 - Calculation & Forecast Condition")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-col', {
          staticClass: "text-center mb-3",
          attrs: {
            "cols": "12"
          }
        }, [_vm._l(_vm.typeOfCalculation, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            staticClass: "custom-radio-color-checked",
            attrs: {
              "inline": "",
              "color": item.color,
              "name": "color",
              "value": item.value,
              "disabled": item.disabled
            },
            model: {
              value: _vm.typeCalculation,
              callback: function callback($$v) {
                _vm.typeCalculation = $$v;
              },
              expression: "typeCalculation"
            }
          }, [_c('strong', [_c('h5', [_vm._v(_vm._s(item.label))])])])];
        })], 2)], 1), _c('b-col', {
          staticClass: "mb-4",
          attrs: {
            "md": "6"
          }
        }, [_vm.typeCalculation === 'data' ? _c('b-input-group', {
          attrs: {
            "size": "md",
            "prepend": "Input Your Max Number of Year for Calculation"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          }
        })], 1) : _vm._e()], 1), _c('b-col', {
          staticClass: "mb-5",
          attrs: {
            "md": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "secondary"
          }
        }, [_c('div', {
          staticClass: "iq-alert-text text-center"
        }, [_vm._v("INDIVIDUAL RECORD")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped table-warning"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Year")]), _c('th', [_vm._v("Premium")]), _c('th', [_vm._v("Claim Loss")]), _c('th', [_vm._v("Loss Ratio")]), _c('th', [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('td', [_c('strong', [_vm._v("2021")])]), _c('td', [_vm._v("9.809.729.019,56")]), _c('td', [_vm._v("892.850.000")]), _c('td', [_vm._v("9,10%")]), _c('td')]), _c('tr', [_c('td', [_c('strong', [_vm._v("2020")])]), _c('td', [_vm._v("11.370.525.019,56")]), _c('td', [_vm._v("892.850.000")]), _c('td', [_vm._v("7,85%")]), _c('td', [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "size": "sm",
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "ri-delete-bin-line"
        })])], 1)]), _vm.typeCalculation === 'manual' ? _c('tr', {
          staticClass: "bg-white"
        }, [_c('td', [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.yearOption,
            "reduce": function reduce(yearOption) {
              return yearOption.value;
            },
            "placeholder": "Select Year"
          },
          model: {
            value: _vm.selectYear,
            callback: function callback($$v) {
              _vm.selectYear = $$v;
            },
            expression: "selectYear"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Input Premium"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Input Claim Loss"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Input Loss Ratio"
          }
        })], 1), _c('td', [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "size": "sm",
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "ri-delete-bin-line"
        })])], 1)]) : _vm._e()])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_vm.typeCalculation === 'manual' ? _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "warning",
            "block": ""
          }
        }, [_c('i', {
          staticClass: "ri-add-line"
        }), _vm._v(" Add Data Before Year")]) : _vm._e()], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "secondary"
          }
        }, [_c('div', {
          staticClass: "iq-alert-text text-center"
        }, [_vm._v("ACCUMULATIVE RECORD")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped table-success"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Year")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Acc.Premium")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Acc.Loss")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Acc.Loss Ratio")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Pure Risk")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Insurer Recovery (Month)")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Insurer Recovery")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("Crosscheck")])]), _c('tr', [_c('th', [_vm._v("Kerugian/Keuntungan yang diterima Asuransi")]), _c('th', [_vm._v("%")])])]), _c('tbody', [_c('tr', [_c('td', [_c('strong', [_vm._v("2021")])]), _c('td', [_vm._v("11.370.525.019,56")]), _c('td', [_vm._v("892.850.000")]), _c('td', [_vm._v("7,85%")]), _c('td', [_vm._v("62,50%")]), _c('td', [_vm._v("35,66%")]), _c('td', [_vm._v("2,80%")]), _c('td')]), _c('tr', [_c('td', [_c('strong', [_vm._v("2020")])]), _c('td', [_vm._v("11.370.525.019,56")]), _c('td', [_vm._v("892.850.000")]), _c('td', [_vm._v("7,85%")]), _c('td', [_vm._v("62,50%")]), _c('td', [_vm._v("35,66%")]), _c('td', [_vm._v("2,80%")])])])])])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-capitalize text-black"
        }, [_c('strong', [_vm._v("Step 4 - Final Calculation Condition")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-col', {
          staticClass: "mb-5",
          attrs: {
            "md": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "secondary"
          }
        }, [_c('div', {
          staticClass: "iq-alert-text text-center"
        }, [_vm._v("forecasting premium by simple linear calculation")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_vm._v("Reduce Premium"), _c('br'), _c('small', {
          staticClass: "text-success"
        }, [_vm._v("Premium (Current) – Forecast")])]), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('h4', [_vm._v("IDR. -")])]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_vm._v("Reduce Premium (%) "), _c('br'), _c('small', {
          staticClass: "text-success"
        }, [_vm._v("Reduce Premium / Premium (Current) x 100%")])]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('h4', [_vm._v("0%")])])], 1), _c('b-row', {
          staticClass: "pl-4"
        }, [_c('b-col', {
          staticClass: "mt-5",
          attrs: {
            "md": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "info"
          }
        }, [_c('div', {
          staticClass: "iq-alert-text text-center"
        }, [_vm._v("PESIMISTIC CALCULATION")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Select Company")]), _c('th', [_vm._v("Current Rate")]), _c('th', [_vm._v("Decrease"), _c('br'), _c('small', [_vm._v("Current Rate x Reduce Premium %")])]), _c('th', [_c('strong', [_vm._v("Result")])]), _c('th', [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('td', [_c('v-select', {
          attrs: {
            "label": "company",
            "options": _vm.companies,
            "placeholder": "Company Name",
            "reduce": function reduce(c) {
              return c.id;
            },
            "multiple": true
          },
          model: {
            value: _vm.companiesSelected,
            callback: function callback($$v) {
              _vm.companiesSelected = $$v;
            },
            expression: "companiesSelected"
          }
        })], 1), _c('td', [_c('div', [_vm._l(_vm.pesimisticRateOptions, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            staticClass: "custom-radio-color-checked",
            attrs: {
              "inline": "",
              "color": item.color,
              "name": "color",
              "value": item.value,
              "disabled": item.disabled
            },
            model: {
              value: _vm.pesimisticRate,
              callback: function callback($$v) {
                _vm.pesimisticRate = $$v;
              },
              expression: "pesimisticRate"
            }
          }, [_c('strong', [_c('h5', [_vm._v(_vm._s(item.label))])])])];
        })], 2), _c('div', [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "%"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "max-width": "50%"
          },
          attrs: {
            "type": "number"
          }
        })], 1)], 1)]), _c('td', [_c('small', [_vm._v("Current Rate x Reduce Premium %")])]), _c('td', [_c('small', [_vm._v("Current Rate - Decrease")])]), _c('td', [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "size": "sm",
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "ri-delete-bin-line"
        })])], 1)])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "info",
            "block": ""
          }
        }, [_c('i', {
          staticClass: "ri-add-line"
        }), _vm._v(" Add Data")])], 1)], 1)], 1), _c('b-col', {
          staticClass: "mt-4",
          attrs: {
            "md": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "success"
          }
        }, [_c('div', {
          staticClass: "iq-alert-text text-center"
        }, [_vm._v("OPTIMISTIC CALCULATION")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Company")]), _c('th', [_vm._v("Pesimistic Rate")]), _c('th', [_vm._v("Additional %")]), _c('th', [_c('strong', [_vm._v("Result")])])])]), _c('tbody', [_c('tr', [_c('td', [_c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("PT.Energy Transporter Indonesia")]), _vm._v("   "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("PT.Renjani Maritim Transportation")]), _vm._v("   "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("PT.Sentra Makmur Lines")]), _vm._v("   ")], 1), _c('td', [_c('i', [_c('strong', [_vm._v("{ Result Pesimistic Calculation }")])])]), _c('td', [_c('i', [_c('strong', [_vm._v("{ Result Pesimistic Calculation x Final Loading Point }")])])]), _c('td', [_c('i', [_c('strong', [_vm._v("{ Pesimistic Rate + Additional % }")])])])])])])])], 1)], 1), _c('b-col', {
          staticClass: "mt-5",
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-bordered table-striped shadow"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "width": "50%",
            "colspan": "2",
            "rowspan": "2"
          }
        }, [_vm._v("FINAL RESULT WITH REDUCE VALUE 15% PER VESSEL ")]), _c('th', {
          attrs: {
            "colspan": "3"
          }
        }, [_vm._v("FINAL RATE FOR RENEWAL ")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_c('strong', [_vm._v("HM")])]), _c('td', {
          staticClass: "text-center"
        }, [_c('strong', [_vm._v("IV")])]), _c('td', {
          staticClass: "text-center"
        }, [_c('strong', [_vm._v("WAR")])])])]), _c('tbody', [_c('tr', [_c('td', [_c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("PT.Energy Transporter Indonesia")]), _vm._v("   "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("PT.Renjani Maritim Transportation")]), _vm._v("   "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("PT.Sentra Makmur Lines")]), _vm._v("   ")], 1), _c('td', [_c('strong', [_vm._v("{ Result Optimistic Calculation }")])]), _c('td', [_c('strong', [_vm._v("{ Result Optimistic Calculation }")])]), _c('td', [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "%"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "width": "50%"
          }
        })], 1)], 1), _c('td', [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "%"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "width": "50%"
          }
        })], 1)], 1)])])])]), _c('b-col', {
          staticClass: "mt-5",
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-bordered table-success shadow"
        })])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-sidebar', {
    attrs: {
      "id": "show-budget",
      "title": "LIST OF BUDGET",
      "width": "1500px",
      "backdrop-variant": "dark",
      "right": "",
      "backdrop": "",
      "shadow": ""
    }
  }, [_c('b-row', {
    staticClass: "pt-2 pl-3 pr-3 pb-2"
  }, [_c('ComponentBudget')], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }