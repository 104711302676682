var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("SERVICE & REPAIR")])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('form', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": true,
            "href": "#add_service_repair",
            "title": "CREATE SERVICE REPAIR"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": false,
            "href": "#request_service_repair",
            "title": "SERVICE REPAIR PROGRESS"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": false,
            "href": "#finish_service_repair",
            "title": "FINISH SERVICE REPAIR"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "iq-edit-list-data"
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "add_service_repair"
    }
  }, [_c('b-alert', {
    staticClass: "text-white bg-warning",
    attrs: {
      "show": true,
      "variant": " "
    }
  }, [_c('div', {
    staticClass: "iq-alert-icon"
  }, [_c('i', {
    staticClass: "ri-alert-line"
  })]), _c('div', {
    staticClass: "iq-alert-text"
  }, [_vm._v("I'm Sorry "), _c('b', [_vm._v("The Fleet")]), _vm._v(" Has Been Add Service Repair Please Go to Tab "), _c('b', [_vm._v("Service Repair Progress")])])]), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "3"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("CREATE SERVICE REPAIR - TB ETI 101")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-toast', {
          attrs: {
            "variant": "success",
            "visible": "",
            "static": "",
            "no-auto-hide": "",
            "no-close-button": "",
            "toast-class": "bg-success",
            "body-class": "text-white",
            "header-class": "bg-success text-white"
          },
          scopedSlots: _vm._u([{
            key: "toast-title",
            fn: function fn() {
              return [_c('strong', {
                staticClass: "mr-auto text-white"
              }, [_c('i', {
                staticClass: "fa fa-bullhorn"
              }), _vm._v(" Notification")]), _c('small', {
                staticClass: "text-white"
              }, [_vm._v("11 mins ago")]), _c('button', {
                staticClass: "ml-2 mb-1 close text-white",
                attrs: {
                  "type": "button",
                  "data-dismiss": "toast",
                  "aria-label": "Close"
                }
              }, [_c('span', {
                attrs: {
                  "aria-hidden": "true"
                }
              }, [_vm._v("×")])])];
            },
            proxy: true
          }])
        }, [_vm._v(" You've Succed Add Service Repair ")]), _c('b-toast', {
          attrs: {
            "variant": "danger",
            "visible": "",
            "static": "",
            "no-auto-hide": "",
            "no-close-button": "",
            "toast-class": "bg-danger",
            "body-class": "text-white",
            "header-class": "bg-danger text-white"
          },
          scopedSlots: _vm._u([{
            key: "toast-title",
            fn: function fn() {
              return [_c('strong', {
                staticClass: "mr-auto text-white"
              }, [_c('i', {
                staticClass: "fa fa-bullhorn"
              }), _vm._v(" Notification")]), _c('small', {
                staticClass: "text-white"
              }, [_vm._v("11 mins ago")]), _c('button', {
                staticClass: "ml-2 mb-1 close text-white",
                attrs: {
                  "type": "button",
                  "data-dismiss": "toast",
                  "aria-label": "Close"
                }
              }, [_c('span', {
                attrs: {
                  "aria-hidden": "true"
                }
              }, [_vm._v("×")])])];
            },
            proxy: true
          }])
        }, [_vm._v(" You've Failed Add Service Repair ")]), _c('b-form-group', {
          attrs: {
            "label": "Accident Date",
            "label-for": "accident_date"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "accident_date"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1), _c('b-form-group', {
          attrs: {
            "label": "Location *",
            "label-for": "location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "PIC *",
            "label-for": "pic"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.PicName,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select PIC")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedPIC,
            callback: function callback($$v) {
              _vm.selectedPIC = $$v;
            },
            expression: "selectedPIC"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "9"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("ADD TASK")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form', [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Clasification *",
            "label-for": "clasification"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.clasification,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Clasification")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedClasification,
            callback: function callback($$v) {
              _vm.selectedClasification = $$v;
            },
            expression: "selectedClasification"
          }
        })], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Category *",
            "label-for": "category"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.category,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Category")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedCategory,
            callback: function callback($$v) {
              _vm.selectedCategory = $$v;
            },
            expression: "selectedCategory"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Problem Case *",
            "label-for": "problem_case"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.problemCase,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Problem Case")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedProblemCase,
            callback: function callback($$v) {
              _vm.selectedProblemCase = $$v;
            },
            expression: "selectedProblemCase"
          }
        })], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Kind of Task *",
            "label-for": "kind_of_task"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.kindTask,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Kind of Task")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedKindTask,
            callback: function callback($$v) {
              _vm.selectedKindTask = $$v;
            },
            expression: "selectedKindTask"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Start Date *",
            "label-for": "start_date"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "start_date"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "End Date *",
            "label-for": "end_date"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "end_date"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1)], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Task Name",
            "label-for": "task_name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Description",
            "label-for": "description"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "2"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Task File *",
            "label-for": "attactment_file"
          }
        }, [_c('b-form-file', {
          attrs: {
            "plain": "",
            "id": "attactment_file"
          }
        }), _c('b-card-text', [_c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" File Type * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "12"
          }
        }, [_c('hr')]), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "12"
          }
        }, [_c('b-card-text', [_c('small', {
          staticClass: "text-muted"
        }, [_vm._v("Sign * : "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-save"
        }), _vm._v(" Save")]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "submit",
            "variant": "none"
          }
        }, [_c('i', {
          staticClass: "fa fa-close"
        }), _vm._v(" Reset Form")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "request_service_repair"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("TB ETI 101")])]), _c('div', {
          staticClass: "about-info m-0 p-0"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-6"
        }, [_c('strong', [_vm._v("Accident Location")])]), _c('div', {
          staticClass: "col-6"
        }, [_vm._v("Asam - Asam")]), _c('div', {
          staticClass: "col-6"
        }, [_c('strong', [_vm._v("Accident Date")])]), _c('div', {
          staticClass: "col-6"
        }, [_vm._v("22 May 2021")]), _c('div', {
          staticClass: "col-6"
        }, [_c('strong', [_vm._v("PIC")])]), _c('div', {
          staticClass: "col-6"
        }, [_vm._v("Nurul Akbar")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-info-box d-flex align-items-center p-3"
        }, [_c('div', {
          staticClass: "info-image mr-3"
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/page-img/30.png"),
            "alt": "image-box"
          }
        })]), _c('div', {
          staticClass: "info-text"
        }, [_c('h3', [_vm._v("3")]), _c('span', [_vm._v("Total Current Task")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-info-box d-flex align-items-center p-3"
        }, [_c('div', {
          staticClass: "info-image mr-3"
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/page-img/31.png"),
            "alt": "image-box"
          }
        })]), _c('div', {
          staticClass: "info-text"
        }, [_c('h3', [_vm._v("Rp. 15.570.321")]), _c('span', [_vm._v("Total Cost")])])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("LIST TASK")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Name Task")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Category")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Clasification")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Start Date")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("End Date")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Description")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("1")]), _c('td', [_vm._v("Pekerjaan Pebrbaikan Mesin Kapal")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Mechanical")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("Medium")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15/05/2021")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12/06/2021")]), _c('td', {
          staticClass: "text-left"
        }, [_vm._v(" Pada saat kapal FAW to A2 berangkat dari dock maribaya selang 1 jam perjalanan ME kiri mengalami trouble dengan ditemukan suara kasar sesuai hasil pengecekan terjadi kerusakan pada CLY NO 3 (L) pushrod bengkok dan piston pecah. oil ")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("Pending")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-check mr-2"
        }), _vm._v("Approve")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-remove mr-2"
        }), _vm._v("Reject")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-5",
            modifiers: {
              "modal-5": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square mr-2"
        }), _vm._v("Update Data")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-2",
            modifiers: {
              "modal-2": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-files-o mr-2"
        }), _vm._v("Task File")])], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("2")]), _c('td', [_vm._v("Pekerjaan Pengelasan Lambung Sisi Kanan")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Welding")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("Medium")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("21/05/2021")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("28/05/2021")]), _c('td', {
          staticClass: "text-left"
        }, [_vm._v(" Pengelasan sisi lambung kapal kanan akibat keretakan ")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info d-block"
          }
        }, [_vm._v("Progress")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-1",
            modifiers: {
              "modal-1": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-list-ol mr-2"
        }), _vm._v("Activity")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-2",
            modifiers: {
              "modal-2": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-files-o mr-2"
        }), _vm._v("Task File")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-6",
            modifiers: {
              "modal-6": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-files-o mr-2"
        }), _vm._v("Sub Contractor File")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-7",
            modifiers: {
              "modal-7": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-files-o mr-2"
        }), _vm._v("Internal Power File")])], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("3")]), _c('td', [_vm._v("Perbaikan Deck Bagian Dalam Kapal")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("General Service")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info d-block"
          }
        }, [_vm._v("Minor")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("21/05/2021")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("28/05/2021")]), _c('td', {
          staticClass: "text-left"
        }, [_vm._v(" Pengelasan sisi lambung kapal kanan akibat keretakan ")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "warning d-block"
          }
        }, [_vm._v("Hold")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-5",
            modifiers: {
              "modal-5": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square mr-2"
        }), _vm._v("Update For Continue")])], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("4")]), _c('td', [_vm._v("Pekerjaan Perbaikan AC Kapal")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Electrical")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Major")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("19/05/2021")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("21/06/2021")]), _c('td', {
          staticClass: "text-left"
        }, [_vm._v(" AC Pada bagian deck mati, dikarenakan korsleting pada bagian inverter ")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Reject")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-check-square mr-2"
        }), _vm._v("Activation")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-2",
            modifiers: {
              "modal-2": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-files-o mr-2"
        }), _vm._v("Task File")])], 1)], 1)])])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "finish_service_repair"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-info-box d-flex align-items-center p-3"
        }, [_c('div', {
          staticClass: "info-image mr-3"
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/page-img/tb.png"),
            "alt": "image-box"
          }
        })]), _c('div', {
          staticClass: "info-text"
        }, [_c('h3', [_vm._v("5")]), _c('span', [_vm._v("Total Service Repair")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-info-box d-flex align-items-center p-3"
        }, [_c('div', {
          staticClass: "info-image mr-3"
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/page-img/30.png"),
            "alt": "image-box"
          }
        })]), _c('div', {
          staticClass: "info-text"
        }, [_c('h5', [_vm._v("25 TASK")]), _c('span', [_vm._v("On Time : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("25 Task")])], 1), _c('br'), _c('span', [_vm._v("Delay : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("5 Task")])], 1)])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-info-box d-flex align-items-center p-3"
        }, [_c('div', {
          staticClass: "info-image mr-3"
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/page-img/31.png"),
            "alt": "image-box"
          }
        })]), _c('div', {
          staticClass: "info-text"
        }, [_c('h3', [_vm._v("Rp. 275.011.341")]), _c('span', [_vm._v("Total Cost")])])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("HISTORY SERVICE REPAIR")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Location")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Accident Date")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("PIC")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Total Task")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("1")]), _c('td', [_vm._v("Asam-Asam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2021-05-15 15:47")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Agung Tri Laksono")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("5/8")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info d-block"
          }
        }, [_vm._v("Progress")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-3",
            modifiers: {
              "modal-3": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-list-ol mr-2"
        }), _vm._v("Task List")])], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("2")]), _c('td', [_vm._v("Tanjung Bara")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-12-07 04:56")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Nurul Akbar")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("7/7")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("Finish")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-3",
            modifiers: {
              "modal-3": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-list-ol mr-2"
        }), _vm._v("Task List")])], 1)], 1)])])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)])])], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-1",
      "size": "xl",
      "title": "Daily Activity",
      "ok-title": "Save Changes",
      "cancel-title": "Close"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "about-info m-0 p-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('strong', [_vm._v("Task Name:")])]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v("Pekerjaan Pengelasan Lambung Sisi Kanan")]), _c('div', {
    staticClass: "col-4"
  }, [_c('strong', [_vm._v("Task Clasification:")])]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v("Medium (3-7 Days)")]), _c('div', {
    staticClass: "col-4"
  }, [_c('strong', [_vm._v("Task Category:")])]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v("Welding")]), _c('div', {
    staticClass: "col-4"
  }, [_c('strong', [_vm._v("Problem Case:")])]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v("Environment")]), _c('div', {
    staticClass: "col-4"
  }, [_c('strong', [_vm._v("Kind Of Task:")])]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v("Repair")]), _c('div', {
    staticClass: "col-4"
  }, [_c('strong', [_vm._v("Start Date:")])]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v("21/05/2021")]), _c('div', {
    staticClass: "col-4"
  }, [_c('strong', [_vm._v("End Date:")])]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v("28/05/2021")])])]), _c('hr'), _c('b-form-group', {
    attrs: {
      "label": "Activity Date *",
      "label-for": "activity_date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date",
      "id": "activity_date"
    }
  }), _c('small', {
    staticStyle: {
      "float": "right"
    }
  }, [_vm._v("click "), _c('i', {
    staticClass: "fa fa-calendar"
  }), _vm._v(" to show")])], 1), _c('b-form-group', {
    attrs: {
      "label": "Description",
      "label-for": "description"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "description",
      "rows": "2"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Activity File *",
      "label-for": "attactment_file"
    }
  }, [_c('b-form-file', {
    attrs: {
      "plain": "",
      "id": "attactment_file"
    }
  }), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" File Type * : "), _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('ul', {
    staticClass: "iq-timeline m-0 p-0",
    staticStyle: {
      "height": "550px"
    }
  }, [_c('li', [_c('div', {
    staticClass: "timeline-dots-fill"
  }), _c('h6', {
    staticClass: "float-left mb-2 text-dark"
  }, [_c('i', {
    staticClass: "ri-time-fill"
  }), _vm._v(" 28-05-2021 15:51")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('p', [_vm._v("Finishing Pengecatan")]), _c('ul', {
    staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
  }, [_c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/pdf.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/2.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/6.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/1.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/4.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/3.jpg"),
      "alt": "gallary-image"
    }
  })])])])])]), _c('li', [_c('div', {
    staticClass: "timeline-dots-fill bg-info"
  }), _c('h6', {
    staticClass: "float-left mb-2 text-dark"
  }, [_c('i', {
    staticClass: "ri-time-fill"
  }), _vm._v(" 27-05-2021 21:00")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('p', [_vm._v("Poles dan Perataan Bagian Pengelasan")])])]), _c('li', [_c('div', {
    staticClass: "timeline-dots-fill bg-info"
  }), _c('h6', {
    staticClass: "float-left mb-2 text-dark"
  }, [_c('i', {
    staticClass: "ri-time-fill"
  }), _vm._v(" 26-05-2021 04:15")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('p', [_vm._v("Pengelasan sisi kanan")])])]), _c('li', [_c('div', {
    staticClass: "timeline-dots-fill bg-info"
  }), _c('h6', {
    staticClass: "float-left mb-2 text-dark"
  }, [_c('i', {
    staticClass: "ri-time-fill"
  }), _vm._v(" 25-05-2021 13:21")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('p', [_vm._v("Peneglasan bagian dalam yang retak")]), _c('ul', {
    staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
  }, [_c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/7.jpg"),
      "alt": "gallary-image"
    }
  })])])])])]), _c('li', [_c('div', {
    staticClass: "timeline-dots-fill bg-info"
  }), _c('h6', {
    staticClass: "float-left mb-2 text-dark"
  }, [_c('i', {
    staticClass: "ri-time-fill"
  }), _vm._v(" 24-05-2021 13:21")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('p', [_vm._v("Pemotongan bagian lambung kapal yang retak")])])]), _c('li', [_c('div', {
    staticClass: "timeline-dots-fill bg-info"
  }), _c('h6', {
    staticClass: "float-left mb-2 text-dark"
  }, [_c('i', {
    staticClass: "ri-time-fill"
  }), _vm._v(" 23-05-2021 07:30")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('p', [_vm._v("Pengukuran pada bagian lambung yang retak")])])]), _c('li', [_c('div', {
    staticClass: "timeline-dots-fill bg-info"
  }), _c('h6', {
    staticClass: "float-left mb-2 text-dark"
  }, [_c('i', {
    staticClass: "ri-time-fill"
  }), _vm._v(" 22-05-2021 23:25")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('p', [_vm._v("Inspeksi kerusakan pada kapal tahap 2")]), _c('ul', {
    staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
  }, [_c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/4.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/5.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/6.jpg"),
      "alt": "gallary-image"
    }
  })])])])])]), _c('li', [_c('div', {
    staticClass: "timeline-dots-fill bg-info"
  }), _c('h6', {
    staticClass: "float-left mb-2 text-dark"
  }, [_c('i', {
    staticClass: "ri-time-fill"
  }), _vm._v(" 21-05-2021 18:16")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('p', [_vm._v("Inspeksi kerusakan pada kapal tahap 1")]), _c('ul', {
    staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
  }, [_c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/1.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/2.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/3.jpg"),
      "alt": "gallary-image"
    }
  })])])])])])])])], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-2",
      "size": "l",
      "title": "Task File",
      "ok-title": "Save Changes",
      "cancel-title": "Close"
    }
  }, [_c('ul', {
    staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
  }, [_c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/pdf.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/pdf.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/pdf.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/excel.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/6.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/1.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/4.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/3.jpg"),
      "alt": "gallary-image"
    }
  })])])])]), _c('b-modal', {
    attrs: {
      "id": "modal-3",
      "size": "xl",
      "title": "List Task",
      "ok-title": "Save Changes",
      "cancel-title": "Close"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Name Task")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Category")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Clasification")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("Start Date")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v("End Date")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Description")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("Status")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('td', {
    staticClass: "text-center"
  }, [_vm._v("1")]), _c('td', [_vm._v("Pekerjaan Pengelasan Lambung Sisi Kanan")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v("Welding")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v("Medium")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v("21/05/2021")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v("28/05/2021")]), _c('td', {
    staticClass: "text-left"
  }, [_vm._v(" Pengelasan sisi lambung kapal kanan akibat keretakan ")]), _c('td', {
    staticClass: "text-center"
  }, [_c('b-badge', {
    attrs: {
      "variant": "info d-block"
    }
  }, [_vm._v("Progress")])], 1), _c('td', {
    staticClass: "text-center"
  }, [_c('b-dropdown', {
    attrs: {
      "size": "lg",
      "variant": "link iq-bg-primary iq-border-radius-10",
      "toggle-class": "text-decoration-none",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('span', {
          staticClass: "dropdown-toggle p-0",
          attrs: {
            "id": "dropdownMenuButton5",
            "data-toggle": "dropdown"
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square-o m-0 text-primary"
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-8",
      modifiers: {
        "modal-8": true
      }
    }],
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "fa fa-list-ol mr-2"
  }), _vm._v("Activity")]), _c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-2",
      modifiers: {
        "modal-2": true
      }
    }],
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "fa fa-files-o mr-2"
  }), _vm._v("Task File")]), _c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-6",
      modifiers: {
        "modal-6": true
      }
    }],
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "fa fa-files-o mr-2"
  }), _vm._v("Sub Contractor File")]), _c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-7",
      modifiers: {
        "modal-7": true
      }
    }],
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "fa fa-files-o mr-2"
  }), _vm._v("Internal Power File")])], 1)], 1)]), _c('tr', [_c('td', {
    staticClass: "text-center"
  }, [_vm._v("2")]), _c('td', [_vm._v("Pekerjaan Perbaikan AC Kapal")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v("Electrical")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v("Minor")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v("19/05/2021")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v("21/05/2021")]), _c('td', {
    staticClass: "text-left"
  }, [_vm._v(" AC Pada bagian deck mati, dikarenakan korsleting pada bagian inverter ")]), _c('td', {
    staticClass: "text-center"
  }, [_c('b-badge', {
    attrs: {
      "variant": "danger d-block"
    }
  }, [_vm._v("Reject")])], 1), _c('td', {
    staticClass: "text-center"
  }, [_c('b-dropdown', {
    attrs: {
      "size": "lg",
      "variant": "link iq-bg-primary iq-border-radius-10",
      "toggle-class": "text-decoration-none",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('span', {
          staticClass: "dropdown-toggle p-0",
          attrs: {
            "id": "dropdownMenuButton5",
            "data-toggle": "dropdown"
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square-o m-0 text-primary"
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-8",
      modifiers: {
        "modal-8": true
      }
    }],
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "fa fa-list-ol mr-2"
  }), _vm._v("Activity")]), _c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-2",
      modifiers: {
        "modal-2": true
      }
    }],
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "fa fa-files-o mr-2"
  }), _vm._v("Task File")])], 1)], 1)]), _c('tr', [_c('td', {
    staticClass: "text-center"
  }, [_vm._v("3")]), _c('td', [_vm._v("Pekerjaan Pebrbaikan Mesin Kapal")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v("Mechanical")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v("Medium")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v("15/05/2021")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v("12/06/2021")]), _c('td', {
    staticClass: "text-left"
  }, [_vm._v(" Pada saat kapal FAW to A2 berangkat dari dock maribaya selang 1 jam perjalanan ME kiri mengalami trouble dengan ditemukan suara kasar sesuai hasil pengecekan terjadi kerusakan pada CLY NO 3 (L) pushrod bengkok dan piston pecah. oil ")]), _c('td', {
    staticClass: "text-center"
  }, [_c('b-badge', {
    attrs: {
      "variant": "success d-block"
    }
  }, [_vm._v("Finish")])], 1), _c('td', {
    staticClass: "text-center"
  }, [_c('b-dropdown', {
    attrs: {
      "size": "lg",
      "variant": "link iq-bg-primary iq-border-radius-10",
      "toggle-class": "text-decoration-none",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('span', {
          staticClass: "dropdown-toggle p-0",
          attrs: {
            "id": "dropdownMenuButton5",
            "data-toggle": "dropdown"
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square-o m-0 text-primary"
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-1",
      modifiers: {
        "modal-1": true
      }
    }],
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "fa fa-list-ol mr-2"
  }), _vm._v("Activity")]), _c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-2",
      modifiers: {
        "modal-2": true
      }
    }],
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "fa fa-files-o mr-2"
  }), _vm._v("Task File")]), _c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-6",
      modifiers: {
        "modal-6": true
      }
    }],
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "fa fa-files-o mr-2"
  }), _vm._v("Sub Contractor File")]), _c('b-dropdown-item', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-7",
      modifiers: {
        "modal-7": true
      }
    }],
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "fa fa-files-o mr-2"
  }), _vm._v("Internal Power File")])], 1)], 1)])])])]), _c('b-modal', {
    attrs: {
      "id": "modal-4",
      "size": "xl",
      "title": "Approve Task",
      "ok-title": "Save Changes",
      "cancel-title": "Close"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('div', {
    staticClass: "about-info m-0 p-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('strong', [_vm._v("Task Name")])]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v("Pekerjaan Pengelasan Lambung Sisi Kanan")]), _c('div', {
    staticClass: "col-4"
  }, [_c('strong', [_vm._v("Task Clasification")])]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v("Medium (3-7 Days)")]), _c('div', {
    staticClass: "col-4"
  }, [_c('strong', [_vm._v("Task Category")])]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v("Welding")]), _c('div', {
    staticClass: "col-4"
  }, [_c('strong', [_vm._v("Problem Case")])]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v("Environment")]), _c('div', {
    staticClass: "col-4"
  }, [_c('strong', [_vm._v("Kind Of Task")])]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v("Repair")]), _c('div', {
    staticClass: "col-4"
  }, [_c('strong', [_vm._v("Start Date")])]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v("21/05/2021")]), _c('div', {
    staticClass: "col-4"
  }, [_c('strong', [_vm._v("End Date")])]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v("28/05/2021")])])]), _c('hr'), _c('h5', {
    staticClass: "card-title text-primary"
  }, [_vm._v("Task File File")]), _c('ul', {
    staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
  }, [_c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/pdf.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/pdf.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/pdf.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/excel.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/6.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/1.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/4.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/3.jpg"),
      "alt": "gallary-image"
    }
  })])])])]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Yes = Sub Contractor ? No = Internal Man Power *",
      "label-for": "sub_contcator"
    }
  }, [_c('div', {
    staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
  }, [_c('div', {
    staticClass: "custom-switch-inner"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.subContractor.checked,
      expression: "subContractor.checked"
    }],
    staticClass: "custom-control-input",
    class: 'bg-' + _vm.subContractor.class,
    attrs: {
      "type": "checkbox",
      "id": 'subContractor'
    },
    domProps: {
      "checked": Array.isArray(_vm.subContractor.checked) ? _vm._i(_vm.subContractor.checked, null) > -1 : _vm.subContractor.checked
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.subContractor.checked,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.subContractor, "checked", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.subContractor, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.subContractor, "checked", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": 'subContractor',
      "data-on-label": _vm.subContractor.activeLabel,
      "data-off-label": _vm.subContractor.inactiveLabel
    }
  })])])]), _vm.subContractor.checked == true ? [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Company Name *",
      "label-for": "company_name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Execute Engineer *",
      "label-for": "execute_engineer"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "value": "Sub Contractor"
    }
  })], 1)], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Negotiation ? (Yes/No) *",
      "label-for": "sub_contcator"
    }
  }, [_c('div', {
    staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
  }, [_c('div', {
    staticClass: "custom-switch-inner"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.negotiation.checked,
      expression: "negotiation.checked"
    }],
    staticClass: "custom-control-input",
    class: 'bg-' + _vm.negotiation.class,
    attrs: {
      "type": "checkbox",
      "id": 'negotiation'
    },
    domProps: {
      "checked": Array.isArray(_vm.negotiation.checked) ? _vm._i(_vm.negotiation.checked, null) > -1 : _vm.negotiation.checked
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.negotiation.checked,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.negotiation, "checked", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.negotiation, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.negotiation, "checked", $$c);
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": 'negotiation',
      "data-on-label": _vm.negotiation.activeLabel,
      "data-off-label": _vm.negotiation.inactiveLabel
    }
  })])])]), _c('b-form-row', {
    staticClass: "mb-3"
  }, [_c('b-col', [_c('label', {
    attrs: {
      "label-for": "quotation"
    }
  }, [_vm._v("Quotation *")]), _c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text"
  }, [_vm._v("Rp.")])]), _c('b-form-input', {
    attrs: {
      "type": "number"
    }
  })], 1)]), _vm.negotiation.checked == true ? [_c('b-col', [_c('label', {
    attrs: {
      "label-for": "negotiation"
    }
  }, [_vm._v("Negotiation *")]), _c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text"
  }, [_vm._v("Rp.")])]), _c('b-form-input', {
    attrs: {
      "type": "number"
    }
  })], 1)])] : _vm._e()], 2), _c('b-form-group', {
    attrs: {
      "label": "Sub Contractor File *",
      "label-for": "attactment_file"
    }
  }, [_c('b-form-file', {
    attrs: {
      "plain": "",
      "id": "attactment_file"
    }
  }), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" File Type * : "), _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v("3 MB / File")])], 1)])], 1)] : _vm._e(), _vm.subContractor.checked == false ? [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Team *",
      "label-for": "team"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Execute Engineer *",
      "label-for": "execute_engineer"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "value": "Internal Man Power"
    }
  })], 1)], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Cost *",
      "label-for": "cost"
    }
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "inputGroupPrepend2"
    }
  }, [_vm._v("Rp.")])]), _c('b-form-input', {
    attrs: {
      "type": "number"
    }
  })], 1)]), _c('b-form-group', {
    attrs: {
      "label": "Internal Man Power File *",
      "label-for": "attactment_file"
    }
  }, [_c('b-form-file', {
    attrs: {
      "plain": "",
      "id": "attactment_file"
    }
  }), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" File Type * : "), _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v("3 MB / File")])], 1)])], 1)] : _vm._e(), _c('b-form-group', {
    attrs: {
      "label": "Description",
      "label-for": "description"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "description",
      "rows": "2"
    }
  })], 1), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Sign * : "), _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Mandatory (Must be Input)")])], 1)])], 2)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-5",
      "size": "xl",
      "title": "Update Data",
      "ok-title": "Save Changes",
      "cancel-title": "Close"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form', [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Clasification *",
      "label-for": "clasification"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "options": _vm.clasification,
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Clasification")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selectedClasification,
      callback: function callback($$v) {
        _vm.selectedClasification = $$v;
      },
      expression: "selectedClasification"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Category *",
      "label-for": "category"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "options": _vm.category,
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Category")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selectedCategory,
      callback: function callback($$v) {
        _vm.selectedCategory = $$v;
      },
      expression: "selectedCategory"
    }
  })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Problem Case *",
      "label-for": "problem_case"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "options": _vm.problemCase,
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Problem Case")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selectedProblemCase,
      callback: function callback($$v) {
        _vm.selectedProblemCase = $$v;
      },
      expression: "selectedProblemCase"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Kind of Task *",
      "label-for": "kind_of_task"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "options": _vm.kindTask,
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Kind of Task")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selectedKindTask,
      callback: function callback($$v) {
        _vm.selectedKindTask = $$v;
      },
      expression: "selectedKindTask"
    }
  })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Start Date *",
      "label-for": "start_date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date",
      "id": "start_date",
      "value": "2021-05-21"
    }
  }), _c('small', {
    staticStyle: {
      "float": "right"
    }
  }, [_vm._v("click "), _c('i', {
    staticClass: "fa fa-calendar"
  }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "End Date *",
      "label-for": "end_date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date",
      "id": "end_date",
      "value": "2021-05-28"
    }
  }), _c('small', {
    staticStyle: {
      "float": "right"
    }
  }, [_vm._v("click "), _c('i', {
    staticClass: "fa fa-calendar"
  }), _vm._v(" to show")])], 1)], 1)], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Task File *",
      "label-for": "attactment_file"
    }
  }, [_c('b-form-file', {
    attrs: {
      "plain": "",
      "id": "attactment_file"
    }
  }), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" File Type * : "), _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Description",
      "label-for": "description"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "description",
      "rows": "2",
      "value": "Pengelasan sisi lambung kapal kanan akibat keretakan"
    }
  })], 1), _c('ul', {
    staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
  }, [_c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/pdf.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/pdf.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/pdf.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/excel.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/6.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/1.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/4.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/3.jpg"),
      "alt": "gallary-image"
    }
  })])])]), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Sign * : "), _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Mandatory (Must be Input)")])], 1)])], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-6",
      "size": "l",
      "title": "Sub Contractor",
      "ok-title": "Save Changes",
      "cancel-title": "Close"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "iq-bg-primary",
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "about-info m-0 p-3"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Company Name")])]), _c('div', {
    staticClass: "col-6"
  }, [_vm._v("PT. Tegal Shipyard Sejahtera")]), _c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Execute Engineer")])]), _c('div', {
    staticClass: "col-6"
  }, [_vm._v("Mr. Ir. Andri Adriansyah. S.T")]), _c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Quotation")])]), _c('div', {
    staticClass: "col-6"
  }, [_vm._v("Rp. 1.489.335")]), _c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Quotation Nego")])]), _c('div', {
    staticClass: "col-6"
  }, [_vm._v("Rp. 1.021.000")]), _c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Description")])]), _c('div', {
    staticClass: "col-6"
  }, [_vm._v("Perbaikan Sisi Lambung Kiri")]), _c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("File Sub Contractor")])]), _c('div', {
    staticClass: "col-6"
  }, [_c('b-badge', {
    attrs: {
      "variant": "info d-block"
    }
  }, [_c('i', {
    staticClass: "fa fa-download mr-2"
  }), _vm._v("4 Files")])], 1)]), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Company Name")])]), _c('div', {
    staticClass: "col-6"
  }, [_vm._v("PT. Tanjung Galangan Sejahtera")]), _c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Execute Engineer")])]), _c('div', {
    staticClass: "col-6"
  }, [_vm._v("Mr. Ahmad Asrori. S.T, M.T")]), _c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Quotation")])]), _c('div', {
    staticClass: "col-6"
  }, [_vm._v("Rp. 915.335")]), _c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Quotation Nego")])]), _c('div', {
    staticClass: "col-6"
  }, [_vm._v("Rp. 721.000")]), _c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Description")])]), _c('div', {
    staticClass: "col-6"
  }, [_vm._v("Perbaikan Sisi Lambung Kiri")]), _c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("File Sub Contractor")])]), _c('div', {
    staticClass: "col-6"
  }, [_c('b-badge', {
    attrs: {
      "variant": "info d-block"
    }
  }, [_c('i', {
    staticClass: "fa fa-download mr-2"
  }), _vm._v("4 Files")])], 1)])])])], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "modal-7",
      "size": "l",
      "title": "Internal Man Power",
      "ok-title": "Save Changes",
      "cancel-title": "Close"
    }
  }, [_c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    staticClass: "iq-bg-primary",
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "about-info m-0 p-3"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Company Name")])]), _c('div', {
    staticClass: "col-6"
  }, [_vm._v("Internal Man Power")]), _c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Execute Engineer")])]), _c('div', {
    staticClass: "col-6"
  }, [_vm._v("Mr. Franz Mangatur")]), _c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Team")])]), _c('div', {
    staticClass: "col-6"
  }, [_vm._v(" - Nurul Akbar"), _c('br'), _vm._v(" - Agung Tri Laksono"), _c('br'), _vm._v(" - Harry Setiawan ")]), _c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Cost")])]), _c('div', {
    staticClass: "col-6"
  }, [_vm._v("Rp. 1.489.335")]), _c('div', {
    staticClass: "col-6"
  }, [_c('strong', [_vm._v("Description")])]), _c('div', {
    staticClass: "col-6"
  }, [_vm._v("Perbaikan Sisi Lambung Kiri")])])])])], 1), _c('ul', {
    staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
  }, [_c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/pdf.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/pdf.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/pdf.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/excel.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/6.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/1.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/4.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/3.jpg"),
      "alt": "gallary-image"
    }
  })])])])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-8",
      "size": "l",
      "title": "Daily Activity",
      "ok-title": "Export",
      "cancel-title": "Close"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('ul', {
    staticClass: "iq-timeline m-0 p-0",
    staticStyle: {
      "height": "550px"
    }
  }, [_c('li', [_c('div', {
    staticClass: "timeline-dots-fill"
  }), _c('h6', {
    staticClass: "float-left mb-2 text-dark"
  }, [_c('i', {
    staticClass: "ri-time-fill"
  }), _vm._v(" 28-05-2021 15:51")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('p', [_vm._v("Finishing Pengecatan")]), _c('ul', {
    staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
  }, [_c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/pdf.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/2.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/6.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/1.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/4.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/3.jpg"),
      "alt": "gallary-image"
    }
  })])])])])]), _c('li', [_c('div', {
    staticClass: "timeline-dots-fill bg-info"
  }), _c('h6', {
    staticClass: "float-left mb-2 text-dark"
  }, [_c('i', {
    staticClass: "ri-time-fill"
  }), _vm._v(" 27-05-2021 21:00")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('p', [_vm._v("Poles dan Perataan Bagian Pengelasan")])])]), _c('li', [_c('div', {
    staticClass: "timeline-dots-fill bg-info"
  }), _c('h6', {
    staticClass: "float-left mb-2 text-dark"
  }, [_c('i', {
    staticClass: "ri-time-fill"
  }), _vm._v(" 26-05-2021 04:15")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('p', [_vm._v("Pengelasan sisi kanan")])])]), _c('li', [_c('div', {
    staticClass: "timeline-dots-fill bg-info"
  }), _c('h6', {
    staticClass: "float-left mb-2 text-dark"
  }, [_c('i', {
    staticClass: "ri-time-fill"
  }), _vm._v(" 25-05-2021 13:21")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('p', [_vm._v("Peneglasan bagian dalam yang retak")]), _c('ul', {
    staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
  }, [_c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/7.jpg"),
      "alt": "gallary-image"
    }
  })])])])])]), _c('li', [_c('div', {
    staticClass: "timeline-dots-fill bg-info"
  }), _c('h6', {
    staticClass: "float-left mb-2 text-dark"
  }, [_c('i', {
    staticClass: "ri-time-fill"
  }), _vm._v(" 24-05-2021 13:21")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('p', [_vm._v("Pemotongan bagian lambung kapal yang retak")])])]), _c('li', [_c('div', {
    staticClass: "timeline-dots-fill bg-info"
  }), _c('h6', {
    staticClass: "float-left mb-2 text-dark"
  }, [_c('i', {
    staticClass: "ri-time-fill"
  }), _vm._v(" 23-05-2021 07:30")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('p', [_vm._v("Pengukuran pada bagian lambung yang retak")])])]), _c('li', [_c('div', {
    staticClass: "timeline-dots-fill bg-info"
  }), _c('h6', {
    staticClass: "float-left mb-2 text-dark"
  }, [_c('i', {
    staticClass: "ri-time-fill"
  }), _vm._v(" 22-05-2021 23:25")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('p', [_vm._v("Inspeksi kerusakan pada kapal tahap 2")]), _c('ul', {
    staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
  }, [_c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/4.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/5.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/6.jpg"),
      "alt": "gallary-image"
    }
  })])])])])]), _c('li', [_c('div', {
    staticClass: "timeline-dots-fill bg-info"
  }), _c('h6', {
    staticClass: "float-left mb-2 text-dark"
  }, [_c('i', {
    staticClass: "ri-time-fill"
  }), _vm._v(" 21-05-2021 18:16")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('p', [_vm._v("Inspeksi kerusakan pada kapal tahap 1")]), _c('ul', {
    staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
  }, [_c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/1.png"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/2.jpg"),
      "alt": "gallary-image"
    }
  })])]), _c('li', {
    staticClass: "col-md-3 col-3 pb-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0);"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/3.jpg"),
      "alt": "gallary-image"
    }
  })])])])])])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }