var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-fluid p-0"
  }, [_c('div', {
    staticClass: "row no-gutters"
  }, [_c('div', {
    staticClass: "col-sm-12 text-center"
  }, [_c('div', {
    staticClass: "iq-error",
    class: _vm.$route.params.code !== '404' ? 'error-500' : ''
  }, [_vm.$route.params.code === '404' ? _c('img', {
    staticClass: "img-fluid iq-error-img",
    attrs: {
      "src": _vm.image404,
      "alt": "404"
    }
  }) : _c('img', {
    staticClass: "img-fluid iq-error-img",
    attrs: {
      "src": _vm.image500,
      "alt": "500"
    }
  }), _c('h2', {
    staticClass: "mb-0"
  }, [_vm._v("Oops! This Page is Not Found.")]), _c('p', [_vm._v("The requested page dose not exist.")]), _c('button', {
    staticClass: "btn btn-primary mt-3",
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('i', {
    staticClass: "ri-home-4-line"
  }), _vm._v("Back to Home")])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }