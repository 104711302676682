var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', _vm._l(_vm.tableData.head, function (state, index) {
    return _c('th', {
      key: index,
      staticClass: "text-center"
    }, [_vm._v(_vm._s(state))]);
  }), 0)]), _c('tbody', _vm._l(_vm.tableData.body, function (data, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm.currentPage > 1 ? _vm.currentPage + index + 1 : index + 1))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          path: "profile-crew/".concat(data.id),
          params: {
            id: data.id
          }
        }
      }
    }, [_vm._v(_vm._s(data.fullname))]), _c('br'), _c('small', {
      staticStyle: {
        "color": "gray"
      }
    }, [_vm._v("NIP : "), _c('b', [_vm._v(_vm._s(data.nip))]), _c('br'), _vm._v("Seaferer Code : " + _vm._s(data.seaferer_code))])], 1), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(data.vehicle)), _c('br'), _c('small', {
      staticStyle: {
        "color": "gray"
      }
    }, [_vm._v(_vm._s(data.company))])]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(data.name_position))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(data.sign_on))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(data.sign_off))]), _c('td', {
      staticClass: "text-center"
    }, [data.status_screw === 'Active' ? _c('b-badge', {
      attrs: {
        "variant": "success d-block"
      }
    }, [_vm._v(_vm._s(data.status_crew))]) : data.status_screw === 'Finish' ? _c('b-badge', {
      attrs: {
        "variant": "danger d-block"
      }
    }, [_vm._v(_vm._s(data.status_crew))]) : _c('b-badge', {
      attrs: {
        "variant": "primary d-block"
      }
    }, [_vm._v(_vm._s(data.status_crew))])], 1), _c('td', {
      staticClass: "text-center"
    }, [_c('b-dropdown', {
      attrs: {
        "size": "lg",
        "variant": "link iq-bg-primary iq-border-radius-10",
        "toggle-class": "text-decoration-none",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton5",
              "data-toggle": "dropdown"
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square-o m-0 text-primary"
          })])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.sign-off",
        modifiers: {
          "sign-off": true
        }
      }],
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-arrow-circle-down mr-2"
    }), _vm._v("Sign Off")]), _c('b-dropdown-item', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.blacklist-crew",
        modifiers: {
          "blacklist-crew": true
        }
      }],
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-ban mr-2"
    }), _vm._v("Black List")]), _c('b-dropdown-item', [_c('router-link', {
      class: 'text-dark',
      attrs: {
        "to": {
          path: "/crewing/condite/".concat(data.id),
          params: {
            id: data.id
          }
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-arrow-circle-down mr-2"
    }), _vm._v("Penilaian Condite")])], 1), _c('b-dropdown-item', [_c('router-link', {
      class: 'text-dark',
      attrs: {
        "to": {
          path: "/crewing/conduct/".concat(data.id),
          params: {
            id: data.id
          }
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-arrow-circle-down mr-2"
    }), _vm._v("Penilaian Conduct")])], 1)], 1)], 1)]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }