<template>
  <b-container fluid>
    <!-- Top Section !-->
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>Forecast Condition - Potential Claim/Loss</strong></h4>
          </template>
          <template v-slot:headerAction>
            <span class="ml-3">
                <a class="text-primary float-right" v-b-toggle.collapse-filter role="button" style="cursor: pointer;">
                  <i class="fa fa-filter" />
                </a>
                </span>
          </template>
          <template v-slot:body>
            <b-collapse id="collapse-filter" visible>
              <b-row>
                <b-col md="6">
                  <b-row>
                    <b-col md="4">
                      <v-select v-model="selectYear" :options="yearOption" placeholder="Select Year"
                                :clearable="false"></v-select>
                    </b-col>
                    <b-col md="2">
                      <b-button @click="fetchListForecast" :disabled="selectYear === ''" variant="primary" class="mb-3 mr-1"><i class="fa fa-search"></i>
                      </b-button>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="6" class="text-right">
                  <router-link :to="{ name: 'mi.forecast-condition-input' }" v-if="canAdd">
                    <b-button variant="success" class="mr-1"><i class="fa fa-plus"></i>&nbsp;Add New Year Forecasting</b-button>
                  </router-link>
                  <b-button variant="primary" class="mr-1" v-if="canExport">
                    <template v-if="!loading_export">
                      <i class="fa fa-file-excel"></i> &nbsp; Export
                    </template>
                    <template v-else>
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                      Exporting...
                    </template>
                  </b-button>
                </b-col>
              </b-row>
            </b-collapse>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Top Section !-->
    <!-- Content Section !-->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <b-row>
              <b-col cols="12">
                <div class="table-responsive">
                  <table class="table mb-0 table-striped table-hover">
                    <thead class="text-center">
                    <tr>
                      <th style="vertical-align: middle">Years</th>
                      <th style="vertical-align: middle">Vehicle Type</th>
                      <th style="vertical-align: middle">Actual Premium</th>
                      <th style="vertical-align: middle">Claim Loss</th>
                      <th style="vertical-align: middle">Estimate Loss Future</th>
                      <th style="vertical-align: middle">Total Claim Loss <br/><small>Claim Loss + Estimate</small></th>
                      <th class="bg-warning" style="vertical-align: middle">Loss Ratio</th>
                      <th class="bg-light" style="vertical-align: middle">Forecast Next Year</th>
<!--                      <th class="bg-light" style="vertical-align: middle">Actual Premium Next Year</th>-->
                      <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="data in lists.data">
                      <td>{{data.year}}</td>
                      <td>{{data.vehicle_type_group.name}}</td>
                      <td>{{numberFormat(data.actual_premium)}}</td>
                      <td>{{numberFormat(data.claim_loss)}}</td>
                      <td>{{numberFormat(data.estimate_loss_future)}}</td>
                      <td>{{numberFormat(parseInt(data.claim_loss) + parseInt(data.estimate_loss_future))}}</td>
                      <td>{{numberFormat(data.claim_loss_ratio)}}</td>
                      <td>{{numberFormat(data.forecast_burden)}}</td>
                      <td>
                        <b-button-group class="mr-2">
                          <b-button
                            v-b-tooltip.hover
                            v-if="canEdit"
                            title="Edit Forecast"
                            @click="handleEdit(data.id)"
                            variant="primary"
                          ><i class="fa fa-edit"></i
                          ></b-button>

                          <b-button
                            variant="dark"
                            v-b-tooltip.hover
                            title="View Forecast"
                            @click="handleView(data.id)"
                          ><i class="fa fa-eye"></i
                          ></b-button>

<!--                          <b-button-->
<!--                            v-b-tooltip.hover-->
<!--                            title="Delete Forecast"-->
<!--                            variant="danger"-->
<!--                            @click="handleDelete(data.id)"-->
<!--                          ><i class="fa fa-trash"></i-->
<!--                          ></b-button>-->
                        </b-button-group>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!--<b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="true"
                  href="#individual_record"
                  title="Individual Record"
                />
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="false"
                  href="#accumulative_record"
                  title="Accumulative Record"
                />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row> -->
    <!-- End Tab -->
    <!--    <div id="pills-tabContent-2" class="tab-content">
      <tab-content-item :active="true" id="individual_record">
        &lt;!&ndash; Top Section !&ndash;&gt;
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:body>
                <b-row>
                  <b-col cols="12">
                    <div class="table-responsive">
                      <table class="table mb-0 table-striped table-hover">
                        <thead class="text-center">
                        <tr>
                          <th>Years</th>
                          <th>Premium</th>
                          <th>Claim Loss</th>
                          <th>Loss Ratio</th>
                          <th class="bg-light">Forecast</th>
                          <th class="bg-light">Trend</th>
                        </tr>
                        </thead>
                      </table>
                    </div>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
      <tab-content-item :active="false" id="accumulative_record">
        &lt;!&ndash; Top Section !&ndash;&gt;
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:body>
                <b-row>
                  <b-col cols="12">
                    <div class="table-responsive">
                      <table class="table mb-0 table-striped table-hover">
                        <thead class="text-center">
                        <tr>
                          <th>Years</th>
                          <th>Acc.Premium</th>
                          <th>Acc.Loss</th>
                          <th>Acc.Loss Ratio</th>
                          <th>Pure Risk</th>
                          <th class="bg-light">Insurer Recovery (Month)</th>
                          <th class="bg-light">Insurer Recovery </th>
                        </tr>
                        </thead>
                      </table>
                    </div>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
    </div>-->
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { companiesActions, fleetsActions, miActions } from '@src/Utils/helper'
import { numberFormat } from '@src/plugins/helpers'

var date = new Date(); var y = date.getFullYear(); var m = date.getMonth()

export default {
  name: 'BlankPage',
  mounted () {
    xray.index()
    this.setYearOption()
    this.fetchListForecast()
  },
  methods: {
    ...miActions,
    numberFormat (number) {
      return numberFormat(number)
    },
    setYearOption () {
      const yearNow = new Date(Date.now()).getFullYear()
      const arr = [yearNow]
      for (let i = 1; i <= 5; i++) {
        arr.unshift(yearNow + i)
      }
      for (let i = 1; i <= 7; i++) {
        arr.push(yearNow - i)
      }
      this.yearOption = arr
    },
    async fetchListForecast () {
      let res = await this.getListForecast({
        ...this.lists.params,
        year: this.selectYear,
        with: 'vehicle_type_group'
      })

      if (res.status === 'success' && res.data) {
        const data = res.data.data
        this.lists.data = data
        this.lists.meta.perPage = res.data.perPage
        this.lists.meta.currentPage = res.data.currentPage
        this.lists.meta.total = res.data.total
        this.lists.meta.totalPage = res.data.totalPage
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    handleEdit (id) {
      this.$router.push({
        name: 'mi.forecast-condition-input',
        query: {
          id
        }
      })
    },
    handleView (id) {
      this.$router.push({
        name: 'mi.forecast-condition-input',
        query: {
          id,
          preview: true
        }
      })
    },
    handleDelete (id) {

    },
  },
  data () {
    return {
      lsProfile: JSON.parse(localStorage.getItem('profile')),
      lists: {
        isLoading: true,
        data: [],
        params: {
          pagination: 1,
          // with: 'risks,vehicle_type_group.types',
          page: 1,
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      selectYear: '',
      loading_export: false,
      yearOption: []
    }
  },
  computed: {
    menuPermission () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Marine Insurance')?.child.find(child => child.menu === 'Financial - Forecast')
    },
    canAdd () {
      return this.menuPermission ? this.menuPermission?.add : false
    },
    canEdit () {
      return this.menuPermission ? this.menuPermission?.update : false
    },
    canApprove () {
      return this.menuPermission ? this.menuPermission?.approval : false
    },
    canReview () {
      return this.menuPermission ? this.menuPermission?.review : false
    },
    canDelete () {
      return this.menuPermission ? this.menuPermission?.delete : false
    },
    canExport () {
      return this.menuPermission ? this.menuPermission?.export : false
    }
  }
}
</script>
