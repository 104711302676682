var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: "right-sidebar-mini " + _vm.miniClass
  }, [_c('div', {
    staticClass: "right-sidebar-toggle",
    class: _vm.toggleClass,
    on: {
      "click": _vm.toggleMini
    }
  }, [_vm.hasIconSlot ? _vm._t("icon") : [_vm.hasOpenIconSlot ? _vm._t("openIcon") : _c('i', {
    staticClass: "ri-arrow-left-line side-left-icon"
  }), _vm.hasCloseIconSlot ? _vm._t("closeIcon") : _c('i', {
    staticClass: "ri-arrow-right-line side-right-icon"
  })]], 2), _c('div', {
    staticClass: "right-sidebar-panel p-0"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }