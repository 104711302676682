<template>
  <b-modal :id="`modal---${id}`" v-model="model" size="xl" title="Set Docking Schedule" header-bg-variant="light" header-text-variant="dark"
           hide-footer
           footer-bg-variant="light" footer-text-variant="dark">
    <b-form @submit.prevent="submit()">
      <b-row>
        <b-col md="6">
          <b-form-group label="Fleet *" label-for="fleet">
            <v-select required v-model="form.vehicleId" label="name" :options="fleets"
                      :reduce="fleet => fleet.id"></v-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Last Docking Special Survey" label-for="last_docking">
            <date-picker type="date" v-model="form.lastDocking" value-type="DD-MM-YYYY" format="DD MMM YYYY" placeholder="Select date"></date-picker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-for="(type, index) in form.dockingType" :key="index">
        <b-col md="3">
          <b-form-group label="Type Dock" label-for="type_dock">
            <b-form-input type="text" readonly :placeholder="type.type === 'INTERMEDIATE' ? 'Intermediate Survey' : 'Special Survey' "></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Interval" label-for="interval">
            <div class="input-group">
              <b-form-input type="number" required v-model="type.interval"></b-form-input>
              <div class="input-group-prepend">
                <span class="input-group-text">Month</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Start Schedule" label-for="start_schedule">
            <div class="input-group">
              <b-form-input type="number" required v-model="type.startSchedule"></b-form-input>
              <div class="input-group-prepend">
                <span class="input-group-text">Month</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="End Schedule" label-for="end_schedule">
            <div class="input-group">
              <b-form-input type="number" v-model="type.endSchedule"></b-form-input>
              <div class="input-group-prepend">
                <span class="input-group-text">Month</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" class="text-right">
          <b-button variant="none" :disabled="form.saving" class="iq-bg-danger text-right" @click="cancel()">Cancel</b-button>
          <b-button type="submit" :disabled="form.saving" variant="primary" class="ml-2 text-right">Save </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import { dockingActions, fleetsActions } from '@src/Utils/helper'

export default {
  name: 'ModalDockingSetSchedule',
  mounted () {
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'set-schedule-docking'
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    model () {
      this.resetForm()
      if (this.model === true) {
        this.fetchFleets()
      }
    }
  },
  data () {
    return {
      fleetParams: {
        page: 1,
        showAll: true,
        search: ''
      },
      fleets: [],
      form: {
        saving: false,
        vehicleId: '',
        lastDocking: '',
        dockingType: [
          {
            'type': 'INTERMEDIATE',
            'interval': '',
            'startSchedule': '',
            'endSchedule': ''
          },
          {
            'type': 'SPECIAL',
            'interval': '',
            'startSchedule': '',
            'endSchedule': ''
          }
        ]
      }
    }
  },
  methods: {
    ...fleetsActions,
    ...dockingActions,
    resetForm () {
      this.form = {
        saving: false,
        vehicleId: '',
        lastDocking: '',
        dockingType: [
          {
            'type': 'INTERMEDIATE',
            'interval': '',
            'startSchedule': '',
            'endSchedule': ''
          },
          {
            'type': 'SPECIAL',
            'interval': '',
            'startSchedule': '',
            'endSchedule': ''
          }
        ]
      }
    },
    cancel () {
      this.resetForm()
      this.$emit('cancel')
    },
    async submit () {
      this.form.saving = true

      const { status } = await this.createDockingScheduleInterval(this.form)

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      this.form.saving = false
      this.$emit('submit')
    },
    async fetchFleets () {
      let params = this.$options.filters.cleanObject(this.fleetParams)
      let res = await this.getFleets(params)

      if (!res.status) {
        this.fleets = []
      } else {
        this.fleets = res.data
      }
    }
  }
}
</script>

<style scoped>

</style>
