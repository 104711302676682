<template>
  <div class="view-maintenance-detail">
    <b-row v-if="maintenanceDetailData" class="pt-2 pl-3 pr-3 pb-2 mb-4">
      <b-col cols="12" class="text-right" v-if="stateAction === 'VIEW_DETAIL'">
        <b-button v-if="['MAINTENANCE'].includes(maintenanceDetailData.status) && canReview" @click="onAction('ON_REVIEW')" variant="info" size="sm" class="mr-2"><i class="fa fa-eye"></i> Review </b-button>
        <b-button v-if="['REVIEW'].includes(maintenanceDetailData.status) && canApprove" @click="onAction('ON_APPROVE')" variant="success" size="sm" class="mr-2"><i class="fa fa-check"></i> Approve </b-button>
        <b-button v-if="['TOWARDS','OVERDUE'].includes(maintenanceDetailData.status) && canAdd" @click="onAction('ON_MAINTENANCE')" variant="primary" size="sm" class="mr-2"><i class="las la-tasks"></i>Maintenance </b-button>
        <b-button v-if="['TOWARDS','OVERDUE'].includes(maintenanceDetailData.status)" @click="onAction('ON_CONVERT')" variant="danger" size="sm" class="mr-2"><i class="las la-anchor"></i> Convert Service & Repair </b-button>
        <b-button v-if="['TOWARDS','OVERDUE'].includes(maintenanceDetailData.status)" @click="onAction('ON_POSTPONE')" variant="warning" size="sm" class="mr-2"><i class="las la-calendar"></i> Postpone </b-button>
      </b-col>
      <b-col cols="12">
        <fieldset class="form-group border p-3">
          <legend class="w-auto px-2">{{maintenanceDetailData.vehicle.vehicleType.name}} - {{maintenanceDetailData.vehicle.name}}</legend>
          <b-row>
            <b-col cols="12">
              <table class="table mb-0 table-striped">
                <tbody>
                  <tr v-for="engine in maintenanceDetailData.vehicle.powers">
                    <th>{{engine.powerType.name}} - {{engine.powerSeries.name}}</th>
                    <td class="text-right">Actual RH : <strong>{{engine.actualRunningHours}} RH</strong></td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col md="3" class="iq-bg-secondary pt-3 mb-3">
              <b-form-group label="Job Code:" label-for="job_name">
                <h5><strong>{{ maintenanceDetailData.jobCode }}</strong></h5>
              </b-form-group>
            </b-col>
            <b-col md="3" class="iq-bg-secondary pt-3 mb-3">
              <b-form-group label="Job Class" label-for="fleet-equipment">
                <h5><strong>{{ `${maintenanceDetailData.jobClass.jobClassCode} - ${maintenanceDetailData.jobClass.jobClass}` }}</strong></h5>
              </b-form-group>
            </b-col>
            <b-col md="6" class="iq-bg-secondary pt-3 mb-3">
              <b-form-group label="Job Name:" label-for="job_name">
                <h5><strong>{{ maintenanceDetailData.jobName }}</strong></h5>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Remarks:" label-for="job_name">
                <h5><strong v-html="maintenanceDetailData.notes"></strong></h5>
              </b-form-group>
            </b-col>
            <b-col md="12" class="mb-3 mt-2"><hr></b-col>
            <b-col md="4">
              <v-jstree :data="treeInitMaintenance" show-checkbox allow-batch whole-row>
                <template slot-scope="_">
                  <div style="display: inherit; width: 200px">
                    <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                    {{_.model.text}}
                  </div>
                </template>
              </v-jstree>
            </b-col>
            <b-col md="8">
              <b-row>
                <b-col cols="12">
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2">{{maintenanceDetailData.vehicle.name}} - {{maintenanceDetailData.vehicle.vehicleType.name}}</legend>
                    <b-row>
                      <b-col md="6">
                        <b-form-group label="Type - Serial No" label-for="job_name">
                          <h6>{{maintenanceDetailData.maintenanceMaster.equipmentId}} - {{maintenanceDetailData.maintenanceMaster.equipmentName}}</h6>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Maker" label-for="job_name" class="ul-lists-styled">
                          <h6><ul><li>Maker No: {{maintenanceDetailData.componentSetup.makerNo}}</li><li>Maker: {{maintenanceDetailData.componentSetup.maker}}</li></ul></h6>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Job Type" label-for="job_name">
                          <h6>{{maintenanceDetailData.jobType.jobTypeCode}} {{maintenanceDetailData.jobType.description}}</h6>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Job Description" label-for="job_name" class="ul-lists-styled">
                          <h6 v-html="maintenanceDetailData.jobDesc"></h6>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" class="text-center mb-4 mt-3 bg-light p-3">
                        <b-row>
                          <b-col md="6">
                            <b-form-group label="Trigger" label-for="job_name">
                              <h6 v-if="maintenanceDetailData.trigger === 'CALENDAR'">Calendar Trigger</h6>
                              <h6 v-else-if="maintenanceDetailData.trigger === 'COUNTER'">Counter Trigger (Running Hours)</h6>
                            </b-form-group>
                          </b-col>
                          <b-col md="6">
                            <b-form-group v-if="maintenanceDetailData.trigger === 'COUNTER'" label="Reset Running Hour for this job ?" label-for="sub_sub_project">
                              <b-form-checkbox v-model="maintenanceDetailData.resetRunningHours" name="check-button" switch inline disabled>
                                <template v-if="maintenanceDetailData.resetRunningHours">Yes</template><template v-else>No</template>
                              </b-form-checkbox>
                            </b-form-group>
                            <b-form-group v-else-if="maintenanceDetailData.trigger === 'CALENDAR'" label="Service Repair" label-for="sub_sub_project">
                              <b-form-checkbox v-model="maintenanceDetailData.serviceRepair" name="check-button" switch inline disabled>
                                <template v-if="maintenanceDetailData.serviceRepair">Yes</template><template v-else>No</template>
                              </b-form-checkbox>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="12">
                        <b-row>
                          <b-col md="12" v-if="maintenanceDetailData.trigger === 'COUNTER'" class="mb-3 bg-light">
                            <fieldset class="form-group border p-3">
                              <legend class="w-auto px-2">
                                <b-checkbox :checked="true" class="custom-switch-color" color="dark" name="check-button" inline disabled>{{maintenanceDetailData.power.powerType.name}} <small>{{maintenanceDetailData.power.powerSeries.name}} {{maintenanceDetailData.power.powerSeries.model}}</small></b-checkbox>
                              </legend>
                              <b-row>
                                <b-col md="4">
                                  <b-form-group label="Interval/Frequency:" label-for="Interval:">
                                    <h6><strong>{{maintenanceDetailData.interval}} Hours</strong></h6>
                                  </b-form-group>
                                </b-col>
                                <b-col md="4">
                                  <b-form-group label="Last Maintenance Date" label-for="Current:">
                                    <strong><b-badge variant="info">{{maintenanceDetailData.lastMaintenanceDate}}</b-badge></strong>
                                  </b-form-group>
                                </b-col>
                                <b-col md="4">
                                  <b-form-group label="Last Maintenance RH" label-for="Current:">
                                    <strong><b-badge variant="info">{{maintenanceDetailData.lastMaintenanceHours}} Hours</b-badge></strong>
                                  </b-form-group>
                                </b-col>
                                <b-col md="4">
                                  <b-form-group label="Status" label-for="Current:">
                                    <strong><b-badge variant="danger">{{maintenanceDetailData.status}}</b-badge></strong>
                                  </b-form-group>
                                </b-col>
                                <b-col md="4">
                                  <b-form-group label="Possible Maintenance RH" label-for="Current:">
                                    <strong><b-badge variant="info">{{maintenanceDetailData.possibleMaintenanceHours}} Hours</b-badge></strong>
                                  </b-form-group>
                                </b-col>
                                <b-col md="4">
                                  <b-form-group label="Actual RH" label-for="Current:">
                                    <strong><b-badge variant="info">{{maintenanceDetailData.power.actualRunningHours}} Hours</b-badge></strong>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </fieldset>
                          </b-col>
                          <b-col md="12" v-else-if="maintenanceDetailData.trigger === 'CALENDAR'" class="mb-3">
                            <b-row>
                              <b-col md="3">
                                <b-form-group label="Interval/Frequency:" label-for="Interval:">
                                  <h6><strong>{{maintenanceDetailData.interval}} Month</strong></h6>
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Last Maintenance Date" label-for="Current:">
                                  <strong><b-badge variant="info">{{maintenanceDetailData.lastMaintenanceDate}}</b-badge></strong>
                                </b-form-group>
                              </b-col>
                              <b-col md="6">
                                <b-form-group label-for="Notes" label="Notes Last Maintenance">
                                  <h5 v-html="maintenanceDetailData.notes">{{maintenanceDetailData.notes || '-'}}</h5>
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Status" label-for="Current:">
                                  <strong><b-badge variant="danger">{{maintenanceDetailData.status}}</b-badge></strong>
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label="Possible Maintenance" label-for="Current:">
                                  <strong><b-badge variant="info">{{maintenanceDetailData.possibleMaintenanceDate}}</b-badge></strong>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset id="form-field" v-if="['ON_MAINTENANCE','ON_POSTPONE','ON_CONVERT'].includes(stateAction)" class="form-group border p-3">
                    <b-row v-if="stateAction === 'ON_MAINTENANCE'">
                      <b-col cols="6">
                        <b-form-group label="Maintenance Date *">
                          <date-picker style="width: 100%;" v-model="formMaintenance.maintenanceDate" value-type="DD-MM-YYYY" format="DD MMM YYYY" type="date" placeholder="Select date"></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Maintenance Running Hours *" v-if="maintenanceDetailData.trigger === 'COUNTER'">
                          <input type="number" v-model="formMaintenance.maintenanceRunningHours" class="form-control">
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="File Maintenance" class="d-flex flex-column">
                          <b-form-file
                            placeholder="Choose a file"
                            name="select-file-maintenance"
                            @change="uploadFile"
                          ></b-form-file>
                          <b-form-text
                            id="file-live-help"
                            class="text-danger"
                            v-if="fileState"
                          >*Max File 5 MB, Please re-upload file.
                          </b-form-text>
                          <small class="text-muted">File Type * :
                        <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge>
                        <br />
                        File Max Capacity * :
                        <b-badge variant="info">5 MB / File</b-badge>
                      </small>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Cost">
                          <input type="number" v-model="formMaintenance.cost" class="form-control">
                          <div class="text-right">
                            {{numberFormat(formMaintenance.cost)}}
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group label="Notes *">
                          <b-form-textarea rows="3" placeholder="Type Notes" v-model="formMaintenance.notes"></b-form-textarea>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-button @click="saveMaintenance()" variant="primary" class="mr-3">Save</b-button>
                        <b-button @click="onAction('VIEW_DETAIL')">Cancel</b-button>
                      </b-col>
                    </b-row>
                    <b-row v-else-if="stateAction === 'ON_POSTPONE'">
                      <b-col cols="6">
                        <b-form-group label="Extra Hours *" v-if="maintenanceDetailData.trigger === 'COUNTER'">
                          <input type="number" v-model="formPostpone.extraHours" class="form-control">
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Extra Date *" v-if="maintenanceDetailData.trigger === 'CALENDAR'">
                          <input type="date" v-model="formPostpone.extraDate" class="form-control">
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group label="Reason *">
                          <b-form-textarea rows="3" placeholder="Type Reason" v-model="formPostpone.reason"></b-form-textarea>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-button @click="savePostpone()" variant="primary" class="mr-3">Save</b-button>
                        <b-button @click="onAction('VIEW_DETAIL')">Cancel</b-button>
                      </b-col>
                    </b-row>
                    <b-row v-else-if="stateAction === 'ON_CONVERT'">
<!--                      todo -->
                    </b-row>
                  </fieldset>
                  <fieldset id="form-field" v-if="['REVIEW','APPROVE'].includes(maintenanceDetailData.status)" class="form-group border p-3">
                    <b-row>
                      <b-col cols="6">
                        <b-form-group label="Last Maintenance Date">
                          {{maintenanceDetailData.lastMaintenanceDate}}
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Maintenance Running Hours" v-if="maintenanceDetailData.trigger === 'COUNTER'">
                          {{maintenanceDetailData.lastMaintenanceHours}}
                        </b-form-group>
                      </b-col>
                      <b-col cols="6" v-if="maintenanceDetailData.file">
                        <b-form-group label="File Maintenance">
                          <a :href="maintenanceDetailData.file.url">{{maintenanceDetailData.file.fileName}}</a>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group label="Cost">
                          Rp.{{numberFormat(maintenanceDetailData.cost)}}
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <b-form-group label="Notes">
                          <span v-if="maintenanceDetailData.status === 'APPROVE'">{{maintenanceDetailData.finishNotes}}</span>
                          <span v-else>{{maintenanceDetailData.notes}}</span>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
                <!-- History Log -->
                <b-col md="12" class="mb-3">
                  <hr>
                  <b-btn block href="#" v-b-toggle.history variant="light">
                    History Form
                    <span class="when-opened"><i class="fa fa-arrow-down" /></span>
                    <span class="when-closed"><i class="fa fa-arrow-up" /></span>
                  </b-btn>

                  <b-collapse id="history" role="tabpanel">
                    <!-- some content -->
                    <ul class="iq-timeline">
                      <li v-if="maintenanceDetailData.logs.length === 0">Tidak ada histori log</li>
                      <li v-for="log in maintenanceDetailData.logs">
                        <div class="timeline-dots border-secondary"></div>
                        <h6 class="" v-if="log.status === 'INIT'">{{ log.createdBy }} telah membuat form</h6>
                        <h6 class="" v-else-if="['APPROVED','APPROVAL','APPROVE'].includes(log.status)">{{ log.createdBy }} telah memberikan approval form</h6>
                        <h6 class="" v-else-if="['REVIEWED','REVIEW'].includes(log.status)">{{ log.createdBy }} telah memberikan review form</h6>
                        <h6 class="" v-else-if="['OVERDUE','TOWARDS'].includes(log.status)">Sertifikat Kadarluasa, butuh perpanjang</h6>
                        <small class="mt-1">{{log.createdAt}}</small>
                      </li>
                    </ul>
                  </b-collapse>
                </b-col>
                <!-- End History Log -->
                <!-- Comment -->
                <b-col cols="12" class="mt-3">
                  <b-btn block href="#" v-b-toggle.comment variant="secondary">
                    <i class="fa fa-comment"></i> Comment Box ({{maintenanceDetailData.comments.length}})
                    <span class="when-opened"><i class="fa fa-arrow-down" /></span>
                    <span class="when-closed"><i class="fa fa-arrow-up" /></span>
                  </b-btn>

                  <b-collapse id="comment" role="tabpanel">
                    <!-- some content -->
                    <div class="detailBox">
                      <div class="titleBox">
                        <label>Comment Box</label>
                        <button type="button" class="close" aria-hidden="true" v-b-toggle.comment>&times;</button>
                      </div>
                      <div class="actionBox">
                        <ul class="commentList">
                          <li v-if="maintenanceDetailData.comments.length === 0">Tidak ada komentar</li>
                          <li v-for="comment in maintenanceDetailData.comments">
<!--                            <div class="commenterImage">-->
<!--                              <img src="http://placekitten.com/50/50" />-->
<!--                            </div>-->
                            <div class="commentText">
                              <p><span><strong>{{comment.name}}</strong> - {{comment.roleName}}</span></p>
                              <p class="">{{comment.comment}}</p> <span class="date sub-text">on {{comment.createdAt}}</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <b-row>
                        <b-col cols="10">
                          <b-form-input v-model="formComment.comment" type="text" placeholder="Input Your Comments..."></b-form-input>
                        </b-col>
                        <b-col cols="2">
                          <b-button variant="primary" :disabled="!formComment.comment" @click="saveComment()" block>Add</b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </b-collapse>
                </b-col>
                <!-- End Comment -->
              </b-row>
            </b-col>
          </b-row>
        </fieldset>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { filesActions, fleetsActions, maintenanceActions } from '@src/Utils/helper'
import { numberFormat } from '@src/plugins/helpers'

export default {
  name: 'ViewMaintenanceDetail',
  props: {
    maintenanceId: {
      type: Number,
      require: true
    },
    action: {
      type: String,
      default: 'VIEW_DETAIL'
    }
  },
  watch: {
    maintenanceId: {
      immediate: true,
      async handler (value) {
        if (value) {
          await this.fetchMaintenanceDetail(value)
          await this.getComponentStruct()
        }
      }
    },
    stateAction: {
      immediate: true,
      async handler (value) {
        let title = ''

        switch (value) {
          case 'VIEW_DETAIL':
            title = 'Maintenance Detail'
            break
          case 'ON_MAINTENANCE':
            title = 'Create Maintenance'
            break
          case 'ON_POSTPONE':
            title = 'Postponed Maintenance'
            break
          case 'ON_CONVERT':
            title = 'Convert Service & Repair'
            break
          default:
            title = 'Detail Maintenance'
            break
        }

        setTimeout(() => {
          document.getElementById('view-detail-maintenance___title__').innerText = title
        },300)
      }
    }
  },
  data () {
    return {
      stateAction: 'VIEW_DETAIL',
      fileState: false,
      checkedFleets: [],
      maintenanceDetailData: null,
      treeInitMaintenance: [],
      formComment: {
        comment: ''
      },
      formMaintenance: {
        fileName: '',
        maintenanceDate: '',
        maintenanceRunningHours: '',
        cost: '',
        notes: ''
      },
      formPostpone: {
        reason: '',
        extraHours: '',
        extraDate: ''
      },
      lsProfile: JSON.parse(localStorage.getItem('profile'))
    }
  },
  computed: {
    maintenancePermission () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Technical')?.child.find(child => child.menu === 'Maintenance')
    },
    canAdd () {
      return this.maintenancePermission ? this.maintenancePermission?.add : false
    },
    canEdit () {
      return this.maintenancePermission ? this.maintenancePermission?.update : false
    },
    canApprove () {
      return this.maintenancePermission ? this.maintenancePermission?.approval : false
    },
    canReview () {
      return this.maintenancePermission ? this.maintenancePermission?.review : false
    },
    canDelete () {
      return this.maintenancePermission ? this.maintenancePermission?.delete : false
    },
    canExport () {
      return this.maintenancePermission ? this.maintenancePermission?.export : false
    }
  },
  methods: {
    ...fleetsActions,
    ...maintenanceActions,
    ...filesActions,
    async fetchMaintenanceDetail (maintenanceId) {
      const res = await this.getMaintenanceDetail(maintenanceId)
      if (res.status === 'success') {
        if (res.data) {
          this.maintenanceDetailData = { ...res.data, maintenanceId }
          this.checkedFleets.push({ ...this.maintenanceDetailData.vehicle, componentSetup: [] })
          this.componentIdSelected = this.maintenanceDetailData.component.id
          this.formMaintenance.maintenanceDate = this.maintenanceDetailData.lastMaintenanceDate
          await this.componentSetupCheck()
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async componentSetupCheck () {
      const paramsQ = {
        vehicleIds: []
      }

      if (this.checkedFleets && this.checkedFleets.length > 0) {
        this.checkedFleets.forEach(el => {
          paramsQ.vehicleIds.push(el.id)
        })
        paramsQ.vehicleIds = paramsQ.vehicleIds.toString()
      }

      const dataNeed = {
        id: this.componentIdSelected,
        params: paramsQ
      }

      const res = await this.checkHasComponentSetup(dataNeed)

      if (res.status === 'success') {
        if (res.data.data && res.data.data.length > 0) {
          if (this.checkedFleets && this.checkedFleets.length > 0) {
            res.data.data.forEach(el => {
              this.checkedFleets.forEach(fleet => {
                if (fleet.id === el.vehicle.id) {
                  fleet.componentSetup = []
                  el.componentSetup.forEach(componentSetup => {
                    fleet.componentSetup.push(componentSetup)
                  })
                }
              })
            })
          }
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async getComponentStruct () {
      this.treeInitMaintenance = []
      const paramsQ = {
        vehicleTypeId: 1
      }
      const res = await this.getComponent(paramsQ)

      if (res.status === 'success') {
        if (res.data.maintenanceComponents && res.data.maintenanceComponents.length > 0) {
          const dataTemp = res.data.maintenanceComponents
          dataTemp.forEach((s) => this.treeInitMaintenance.push(this.putStructureObject(s)))
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async saveComment () {
      let payload = {
        id: this.maintenanceId,
        comment: this.formComment.comment
      }
      const res = await this.saveCommentMaintenance(payload)

      if (res.status === 'success') {
        this.formComment.comment = ''
        await this.fetchMaintenanceDetail(this.maintenanceId)
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async saveMaintenance () {
      // if(!this.formMaintenance.cost || !this.formMaintenance.fileName) {
      //   return alert('Lengkapi form maintenance terlebih dahulu')
      // }

      let payload = {
        id: this.maintenanceId,
        data: this.formMaintenance
      }
      const res = await this.saveDoMaintenance(payload)

      if (res.status === 'success') {
        this.$swal(
          `Maintenance Berhasil`,
          '',
          'success'
        )
        await this.fetchMaintenanceDetail(this.maintenanceId)
        this.onAction('VIEW_DETAIL')
        this.$emit('save')
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async savePostpone () {
      if(!this.formPostpone.reason) {
        return alert('Lengkapi form postpone terlebih dahulu')
      }

      let payload = {
        id: this.maintenanceId,
        data: this.formPostpone
      }
      const res = await this.savePostponeMaintenance(payload)

      if (res.status === 'success') {
        this.$swal(
          `Postpone Berhasil`,
          '',
          'success'
        )
        await this.fetchMaintenanceDetail(this.maintenanceId)
        this.onAction('VIEW_DETAIL')
        this.$emit('save')
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async saveReview () {
      let payload = {
        id: this.maintenanceId
      }
      const res = await this.saveReviewMaintenance(payload)

      if (res.status === 'success') {
        this.$swal(
          `Review Berhasil`,
          '',
          'success'
        )
        await this.fetchMaintenanceDetail(this.maintenanceId)
        this.onAction('VIEW_DETAIL')
        this.$emit('save')
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async saveApprove () {
      let payload = {
        id: this.maintenanceId
      }
      const res = await this.saveApproveMaintenance(payload)

      if (res.status === 'success') {
        this.$swal(
          `Approve Berhasil`,
          '',
          'success'
        )
        await this.fetchMaintenanceDetail(this.maintenanceId)
        this.onAction('VIEW_DETAIL')
        this.$emit('save')
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async uploadFile (event) {
      const files = [...event.target.files]
      let file = files[0]
      this.fileState = false

      if (file && file.size > 5 * 1024 * 1024) { // 5 MB in bytes
        return this.fileState = true; // Set an error state or message
      }

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)
      if (res.status === 'success') {
        this.formMaintenance.fileName = res.data.fileName
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },

    putStructureObject (object) {
      let children = []

      if (object.child.length > 0) {
        object.child.forEach((child) =>
          children.push(this.putStructureObject(child))
        )
      }

      let selected = this.maintenanceDetailData && this.maintenanceDetailData.component ? (this.maintenanceDetailData.component.id === object.component.id) : false
      let disabled = this.maintenanceDetailData

      return {
        id: object.component.id,
        text: object.component.componentName,
        disabled,
        opened: true,
        selected,
        children
      }
    },
    onAction (action) {
      this.resetFormMaintenance()
      this.resetFormPostpone()

      if(action === 'ON_REVIEW') {
        this.$swal({
          title: 'Lakukan Review Maintenance ?',
          text: `Periksa Kembali Seluruh Data`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Review'
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.saveReview()
          }
        })
      } else if(action === 'ON_APPROVE') {
        this.$swal({
          title: 'Lakukan Approve Maintenance ?',
          text: `Periksa Kembali Seluruh Data`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Approve'
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.saveApprove()
          }
        })
      }

      this.stateAction = action
    },
    resetFormMaintenance () {
      this.formMaintenance = {
        fileName: '',
        maintenanceDate: '',
        maintenanceRunningHours: this.maintenanceDetailData?.power?.actualRunningHours ?? 0,
        cost: '',
        notes: ''
      }
    },
    resetFormPostpone () {
      this.formPostpone = {
        reason: '',
        extraHours: '',
        extraDate: ''
      }
    },
    numberFormat(number) {
      return numberFormat(number)
    },
  },
  mounted () {
    this.onAction(this.action)
  }
}
</script>

<style>
.ul-lists-styled ul, .ul-lists-styled {
  list-style: circle;
}
</style>
