var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Default Buttons")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-1",
            modifiers: {
              "collapse-1": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-1"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "default-buttons"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button variant=\"primary\" class=\"mb-3 mr-1\">Primary</b-button>\n<b-button variant=\"secondary\" class=\"mb-3 mr-1\">Secondary</b-button>\n<b-button variant=\"success\" class=\"mb-3 mr-1\">Success</b-button>\n<b-button variant=\"danger\" class=\"mb-3 mr-1\">Danger</b-button>\n<b-button variant=\"warning\" class=\"mb-3 mr-1\">Warning</b-button>\n<b-button variant=\"info\" class=\"mb-3 mr-1\">Info</b-button>\n<b-button variant=\"light\" class=\"mb-3 mr-1\">Light</b-button>\n<b-button variant=\"dark\" class=\"mb-3 mr-1\">Dark</b-button>\n<b-button variant=\"link\" class=\"mb-3 mr-1\">Link</b-button>\n")])])])])]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Primary")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("Secondary")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("Success")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Danger")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("Warning")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Info")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "light"
          }
        }, [_vm._v("Light")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "dark"
          }
        }, [_vm._v("Dark")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "link"
          }
        }, [_vm._v("Link")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Default Buttons Rounded Shape")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-2",
            modifiers: {
              "collapse-2": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-2"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "default-buttons-round-shape"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button pill variant=\"primary\" class=\"mb-3 mr-1\">Primary</b-button>\n<b-button pill variant=\"secondary\" class=\"mb-3 mr-1\">Secondary</b-button>\n<b-button pill variant=\"success\" class=\"mb-3 mr-1\">Success</b-button>\n<b-button pill variant=\"danger\" class=\"mb-3 mr-1\">Danger</b-button>\n<b-button pill variant=\"warning\" class=\"mb-3 mr-1\">Warning</b-button>\n<b-button pill variant=\"info\" class=\"mb-3 mr-1\">Info</b-button>\n<b-button pill variant=\"light\" class=\"mb-3 mr-1\">Light</b-button>\n<b-button pill variant=\"dark\" class=\"mb-3 mr-1\">Dark</b-button>\n<b-button pill variant=\"link\" class=\"mb-3 mr-1\">Link</b-button>\n")])])])])]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "primary"
          }
        }, [_vm._v("Primary")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "secondary"
          }
        }, [_vm._v("Secondary")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "success"
          }
        }, [_vm._v("Success")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "danger"
          }
        }, [_vm._v("Danger")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "warning"
          }
        }, [_vm._v("Warning")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "info"
          }
        }, [_vm._v("Info")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "light"
          }
        }, [_vm._v("Light")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "dark"
          }
        }, [_vm._v("Dark")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "link"
          }
        }, [_vm._v("Link")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Outline Buttons")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-3",
            modifiers: {
              "collapse-3": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-3"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "outline-buttons"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button variant=\"outline-primary\" class=\"mb-3 mr-1\">Primary</b-button>\n<b-button variant=\"outline-secondary\" class=\"mb-3 mr-1\">Secondary</b-button>\n<b-button variant=\"outline-success\" class=\"mb-3 mr-1\">Success</b-button>\n<b-button variant=\"outline-danger\" class=\"mb-3 mr-1\">Danger</b-button>\n<b-button variant=\"outline-warning\" class=\"mb-3 mr-1\">Warning</b-button>\n<b-button variant=\"outline-info\" class=\"mb-3 mr-1\">Info</b-button>\n<b-button variant=\"outline-dark\" class=\"mb-3 mr-1\">Dark</b-button>\n<b-button variant=\"outline-link\" class=\"mb-3 mr-1\">Link</b-button>\n")])])])])]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-primary"
          }
        }, [_vm._v("Primary")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-secondary"
          }
        }, [_vm._v("Secondary")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-success"
          }
        }, [_vm._v("Success")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-danger"
          }
        }, [_vm._v("Danger")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-warning"
          }
        }, [_vm._v("Warning")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-info"
          }
        }, [_vm._v("Info")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-dark"
          }
        }, [_vm._v("Dark")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-link"
          }
        }, [_vm._v("Link")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Rounded Outline Buttons")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-4",
            modifiers: {
              "collapse-4": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-4"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "round-outlines-buttons"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button pill variant=\"outline-primary\" class=\"mb-3 mr-1\">Primary</b-button>\n<b-button pill variant=\"outline-secondary\" class=\"mb-3 mr-1\">Secondary</b-button>\n<b-button pill variant=\"outline-success\" class=\"mb-3 mr-1\">Success</b-button>\n<b-button pill variant=\"outline-danger\" class=\"mb-3 mr-1\">Danger</b-button>\n<b-button pill variant=\"outline-warning\" class=\"mb-3 mr-1\">Warning</b-button>\n<b-button pill variant=\"outline-info\" class=\"mb-3 mr-1\">Info</b-button>\n<b-button pill variant=\"outline-dark\" class=\"mb-3 mr-1\">Dark</b-button>\n<b-button pill variant=\"outline-link\" class=\"mb-3 mr-1\">Link</b-button>\n")])])])])]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "outline-primary"
          }
        }, [_vm._v("Primary")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "outline-secondary"
          }
        }, [_vm._v("Secondary")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "outline-success"
          }
        }, [_vm._v("Success")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "outline-danger"
          }
        }, [_vm._v("Danger")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "outline-warning"
          }
        }, [_vm._v("Warning")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "outline-info"
          }
        }, [_vm._v("Info")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "outline-dark"
          }
        }, [_vm._v("Dark")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "outline-link"
          }
        }, [_vm._v("Link")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Default Buttons")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-5",
            modifiers: {
              "collapse-5": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-5"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "default-buttons-icon"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button variant=\"primary\" class=\"mb-3 mr-1\"><i class=\"ri-bill-fill\"></i>Primary</b-button>\n<b-button variant=\"secondary\" class=\"mb-3 mr-1\"><i class=\"ri-heart-fill\"></i>Secondary</b-button>\n<b-button variant=\"success\" class=\"mb-3 mr-1\"><i class=\"ri-bill-fill\"></i>Success</b-button>\n<b-button variant=\"danger\" class=\"mb-3 mr-1\"><i class=\"ri-heart-fill\"></i>Danger</b-button>\n<b-button variant=\"warning\" class=\"mb-3 mr-1\"><i class=\"ri-bill-fill\"></i>Warning</b-button>\n<b-button variant=\"info\" class=\"mb-3 mr-1\"><i class=\"ri-heart-fill\"></i>Info</b-button>\n<b-button variant=\"light\" class=\"mb-3 mr-1\"><i class=\"ri-bill-fill\"></i>Light</b-button>\n<b-button variant=\"dark\" class=\"mb-3 mr-1\"><i class=\"ri-heart-fill\"></i>Dark</b-button>\n<b-button variant=\"link\" class=\"mb-3 mr-1\"><i class=\"ri-bill-fill\"></i>Link</b-button>\n")])])])])]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "ri-bill-fill"
        }), _vm._v("Primary")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "secondary"
          }
        }, [_c('i', {
          staticClass: "ri-heart-fill"
        }), _vm._v("Secondary")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "ri-bill-fill"
        }), _vm._v("Success")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "ri-heart-fill"
        }), _vm._v("Danger")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "warning"
          }
        }, [_c('i', {
          staticClass: "ri-bill-fill"
        }), _vm._v("Warning")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "info"
          }
        }, [_c('i', {
          staticClass: "ri-heart-fill"
        }), _vm._v("Info")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "light"
          }
        }, [_c('i', {
          staticClass: "ri-bill-fill"
        }), _vm._v("Light")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "ri-heart-fill"
        }), _vm._v("Dark")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "link"
          }
        }, [_c('i', {
          staticClass: "ri-bill-fill"
        }), _vm._v("Link")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Default Buttons Rounded Shape")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-6",
            modifiers: {
              "collapse-6": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-6"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "default-buttons-round-shape-icon"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button pill variant=\"primary\" class=\"mb-3 mr-1\"><i class=\"ri-bill-fill\"></i>Primary</b-button>\n<b-button pill variant=\"secondary\" class=\"mb-3 mr-1\"><i class=\"ri-bill-fill\"></i>Secondary</b-button>\n<b-button pill variant=\"success\" class=\"mb-3 mr-1\"><i class=\"ri-bill-fill\"></i>Success</b-button>\n<b-button pill variant=\"danger\" class=\"mb-3 mr-1\"><i class=\"ri-bill-fill\"></i>Danger</b-button>\n<b-button pill variant=\"warning\" class=\"mb-3 mr-1\"><i class=\"ri-bill-fill\"></i>Warning</b-button>\n<b-button pill variant=\"info\" class=\"mb-3 mr-1\"><i class=\"ri-bill-fill\"></i>Info</b-button>\n<b-button pill variant=\"light\" class=\"mb-3 mr-1\"><i class=\"ri-bill-fill\"></i>Light</b-button>\n<b-button pill variant=\"dark\" class=\"mb-3 mr-1\"><i class=\"ri-bill-fill\"></i>Dark</b-button>\n<b-button pill variant=\"link\" class=\"mb-3 mr-1\"><i class=\"ri-bill-fill\"></i>Link</b-button>\n")])])])])]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "ri-bill-fill"
        }), _vm._v("Primary")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "secondary"
          }
        }, [_vm._v("Secondary")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "ri-bill-fill"
        }), _vm._v("Success")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "danger"
          }
        }, [_vm._v("Danger")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "warning"
          }
        }, [_c('i', {
          staticClass: "ri-bill-fill"
        }), _vm._v("Warning")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "info"
          }
        }, [_vm._v("Info")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "light"
          }
        }, [_c('i', {
          staticClass: "ri-bill-fill"
        }), _vm._v("Light")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "dark"
          }
        }, [_vm._v("Dark")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "link"
          }
        }, [_c('i', {
          staticClass: "ri-bill-fill"
        }), _vm._v("Link")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Outline Buttons")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-7",
            modifiers: {
              "collapse-7": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-7"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "outline-buttons-icons"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button variant=\"outline-primary\" class=\"mb-3 mr-1\"><i class=\"ri-heart-line\"></i>Primary</b-button>\n<b-button variant=\"outline-secondary\" class=\"mb-3 mr-1\"><i class=\"ri-alert-line\"></i>Secondary</b-button>\n<b-button variant=\"outline-success\" class=\"mb-3 mr-1\"><i class=\"ri-heart-line\"></i>Success</b-button>\n<b-button variant=\"outline-danger\" class=\"mb-3 mr-1\"><i class=\"ri-alert-line\"></i>Danger</b-button>\n<b-button variant=\"outline-warning\" class=\"mb-3 mr-1\"><i class=\"ri-heart-line\"></i>Warning</b-button>\n<b-button variant=\"outline-info\" class=\"mb-3 mr-1\"><i class=\"ri-alert-line\"></i>Info</b-button>\n<b-button variant=\"outline-dark\" class=\"mb-3 mr-1\"><i class=\"ri-alert-line\"></i>Dark</b-button>\n<b-button variant=\"outline-link\" class=\"mb-3 mr-1\"><i class=\"ri-heart-line\"></i>Link</b-button>\n")])])])])]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-primary"
          }
        }, [_c('i', {
          staticClass: "ri-heart-line"
        }), _vm._v("Primary")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-secondary"
          }
        }, [_c('i', {
          staticClass: "ri-alert-line"
        }), _vm._v("Secondary")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-success"
          }
        }, [_c('i', {
          staticClass: "ri-heart-line"
        }), _vm._v("Success")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-danger"
          }
        }, [_c('i', {
          staticClass: "ri-alert-line"
        }), _vm._v("Danger")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-warning"
          }
        }, [_c('i', {
          staticClass: "ri-heart-line"
        }), _vm._v("Warning")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-info"
          }
        }, [_c('i', {
          staticClass: "ri-alert-line"
        }), _vm._v("Info")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-dark"
          }
        }, [_c('i', {
          staticClass: "ri-alert-line"
        }), _vm._v("Dark")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-link"
          }
        }, [_c('i', {
          staticClass: "ri-heart-line"
        }), _vm._v("Link")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Rounded Outline Buttons")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-8",
            modifiers: {
              "collapse-8": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-8"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "round-outlines-buttons-icons"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button pill variant=\"outline-primary\" class=\"mb-3 mr-1\"><i class=\"ri-heart-line\"></i>Primary</b-button>\n<b-button pill variant=\"outline-secondary\" class=\"mb-3 mr-1\"><i class=\"ri-alert-line\"></i>Secondary</b-button>\n<b-button pill variant=\"outline-success\" class=\"mb-3 mr-1\"><i class=\"ri-heart-line\"></i>Success</b-button>\n<b-button pill variant=\"outline-danger\" class=\"mb-3 mr-1\"><i class=\"ri-alert-line\"></i>Danger</b-button>\n<b-button pill variant=\"outline-warning\" class=\"mb-3 mr-1\"><i class=\"ri-heart-line\"></i>Warning</b-button>\n<b-button pill variant=\"outline-info\" class=\"mb-3 mr-1\"><i class=\"ri-alert-line\"></i>Info</b-button>\n<b-button pill variant=\"outline-dark\" class=\"mb-3 mr-1\"><i class=\"ri-alert-line\"></i>Dark</b-button>\n<b-button pill variant=\"outline-link\" class=\"mb-3 mr-1\"><i class=\"ri-heart-line\"></i>Link</b-button>\n")])])])])]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "outline-primary"
          }
        }, [_c('i', {
          staticClass: "ri-heart-line"
        }), _vm._v("Primary")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "outline-secondary"
          }
        }, [_c('i', {
          staticClass: "ri-alert-line"
        }), _vm._v("Secondary")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "outline-success"
          }
        }, [_c('i', {
          staticClass: "ri-heart-line"
        }), _vm._v("Success")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "outline-danger"
          }
        }, [_c('i', {
          staticClass: "ri-alert-line"
        }), _vm._v("Danger")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "outline-warning"
          }
        }, [_c('i', {
          staticClass: "ri-heart-line"
        }), _vm._v("Warning")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "outline-info"
          }
        }, [_c('i', {
          staticClass: "ri-alert-line"
        }), _vm._v("Info")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "outline-dark"
          }
        }, [_c('i', {
          staticClass: "ri-alert-line"
        }), _vm._v("Dark")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "pill": "",
            "variant": "outline-link"
          }
        }, [_c('i', {
          staticClass: "ri-heart-line"
        }), _vm._v("Link")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Button Tags")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-9",
            modifiers: {
              "collapse-9": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-9"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "buttons-tags"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button variant=\"primary\" href=\"#\">Link</b-button>\n<b-button type=\"submit\" variant=\"success\">Button</b-button>\n<input class=\"btn btn-danger\" type=\"button\" value=\"Input\">\n<input class=\"btn btn-warning\" type=\"submit\" value=\"Submit\">\n<input class=\"btn btn-info\" type=\"reset\" value=\"Reset\">\n")])])])])]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary",
            "type": "button"
          }
        }, [_vm._v("Link")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "success",
            "type": "button"
          }
        }, [_vm._v("Button")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "danger",
            "type": "button"
          }
        }, [_vm._v("Input")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "warning",
            "type": "button"
          }
        }, [_vm._v("Submit")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "info",
            "type": "button"
          }
        }, [_vm._v("Reset")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Button Sizes")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-10",
            modifiers: {
              "collapse-10": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-10"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "buttons-sizes"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button size=\"sm\" variant=\"primary\" class=\"mr-2\">Small Button</b-button>\n<b-button variant=\"success\" class=\"mr-2\">Button</b-button>\n<b-button size=\"lg\" variant=\"info\">Large Button</b-button>\n")])])])])]), _c('b-button', {
          staticClass: "mr-2",
          attrs: {
            "size": "sm",
            "variant": "primary"
          }
        }, [_vm._v("Small Button")]), _c('b-button', {
          staticClass: "mr-2",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("Button")]), _c('b-button', {
          attrs: {
            "size": "lg",
            "variant": "info"
          }
        }, [_vm._v("Large Button")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Block Buttons")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-11",
            modifiers: {
              "collapse-11": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-11"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "block-buttons-21"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button block variant=\"primary\">Block level button</b-button>\n<b-button block variant=\"success\">Block level button</b-button>\n")])])])])]), _c('b-button', {
          attrs: {
            "block": "",
            "variant": "primary"
          }
        }, [_vm._v("Block level button")]), _c('b-button', {
          attrs: {
            "block": "",
            "variant": "success"
          }
        }, [_vm._v("Block level button")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Icons Buttons")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-12",
            modifiers: {
              "collapse-12": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-12"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "block-buttons"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button variant=\"primary\" class=\"mb-3 mr-1\"><i class=\"ri-heart-fill pr-0\"></i></b-button>\n<b-button variant=\"secondary\" class=\"mb-3 mr-1\"><i class=\"ri-star-fill pr-0\"></i></b-button>\n<b-button variant=\"success\" class=\"mb-3 mr-1\"><i class=\"ri-settings-4-fill pr-0\"></i></b-button>\n<b-button variant=\"danger\" class=\"mb-3 mr-1\"><i class=\"ri-radio-button-fill pr-0\"></i></b-button>\n<b-button variant=\"warning\" class=\"mb-3 mr-1\"><i class=\"ri-delete-bin-2-fill pr-0\"></i></b-button>\n<b-button variant=\"info\" class=\"mb-3 mr-1\"><i class=\"ri-lock-fill pr-0\"></i></b-button>\n<b-button variant=\"light\" class=\"mb-3 mr-1\"><i class=\"ri-time-fill pr-0\"></i></b-button>\n<b-button variant=\"dark\" class=\"mb-3 mr-1\"><i class=\"ri-sun-fill pr-0\"></i></b-button>\n<b-button variant=\"link\" class=\"mb-3 mr-1\"><i class=\"ri-moon-fill pr-0\"></i></b-button>\n<div class=\"d-inline-block w-100 mt-3\">\n  <b-button variant=\"primary\" class=\"mb-3 mr-1\"><i class=\"ri-heart-fill\"></i>Buttons</b-button>\n  <b-button variant=\"secondary\" class=\"mb-3 mr-1\"><i class=\"ri-star-fill\"></i>Buttons</b-button>\n  <b-button variant=\"success\" class=\"mb-3 mr-1\"><i class=\"ri-settings-4-fill\"></i>Buttons</b-button>\n  <b-button variant=\"danger\" class=\"mb-3 mr-1\"><i class=\"ri-radio-button-fill\"></i>Buttons</b-button>\n  <b-button variant=\"info\" class=\"mb-3 mr-1\"><i class=\"ri-lock-fill\"></i>Buttons</b-button>\n</div>\n")])])])])]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "ri-heart-fill pr-0"
        })]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "secondary"
          }
        }, [_c('i', {
          staticClass: "ri-star-fill pr-0"
        })]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "ri-settings-4-fill pr-0"
        })]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "ri-radio-button-fill pr-0"
        })]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "warning"
          }
        }, [_c('i', {
          staticClass: "ri-delete-bin-2-fill pr-0"
        })]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "info"
          }
        }, [_c('i', {
          staticClass: "ri-lock-fill pr-0"
        })]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "light"
          }
        }, [_c('i', {
          staticClass: "ri-time-fill pr-0"
        })]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "ri-sun-fill pr-0"
        })]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "link"
          }
        }, [_c('i', {
          staticClass: "ri-moon-fill pr-0"
        })]), _c('div', {
          staticClass: "d-inline-block w-100 mt-3"
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "ri-heart-fill"
        }), _vm._v("Buttons")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "secondary"
          }
        }, [_c('i', {
          staticClass: "ri-star-fill"
        }), _vm._v("Buttons")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "ri-settings-4-fill"
        }), _vm._v("Buttons")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "ri-radio-button-fill"
        }), _vm._v("Buttons")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "info"
          }
        }, [_c('i', {
          staticClass: "ri-lock-fill"
        }), _vm._v("Buttons")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Social Disabled State")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-13",
            modifiers: {
              "collapse-13": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-13"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "social-disabled-state"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button disabled variant=\"primary\">Primary button</b-button>\n<b-button disabled variant=\"success\">Button</b-button>\n<b-button disabled variant=\"outline-primary\">Button</b-button>\n")])])])])]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "disabled": "",
            "variant": "primary"
          }
        }, [_vm._v("Primary button")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "disabled": "",
            "variant": "success"
          }
        }, [_vm._v("Button")]), _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "disabled": "",
            "variant": "outline-primary"
          }
        }, [_vm._v("Button")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Buttons Toggle States")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-14",
            modifiers: {
              "collapse-14": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-14"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "buttons-toggle-states"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<button type=\"button\" class=\"btn btn-primary\" data-toggle=\"button\" aria-pressed=\"false\">Single toggle</button>\n")])])])])]), _c('button', {
          staticClass: "btn btn-primary",
          attrs: {
            "type": "button",
            "data-toggle": "button",
            "aria-pressed": "false"
          }
        }, [_vm._v("Single toggle")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Default Buttons Active")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-15",
            modifiers: {
              "collapse-15": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-15"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "buttons-active"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button variant=\"primary\" class=\"active mb-3\">Active</b-button>\n<b-button variant=\"secondary\" class=\"active mb-3\">Active</b-button>\n<b-button variant=\"warning\" class=\"active mb-3\">Active</b-button>\n<b-button variant=\"accent\" class=\"mb-3 mr-1\" disabled>Disabled</b-button>\n<b-button variant=\"primary\" class=\"mb-3 mr-1\" disabled>Disabled</b-button>\n<b-button variant=\"outline-primary\" class=\"mb-3 mr-1\" disabled>Disabled</b-button>\n")])])])])]), _c('b-button', {
          staticClass: "active mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Active")]), _c('b-button', {
          staticClass: "active mb-3 mr-1",
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("Active")]), _c('b-button', {
          staticClass: "active mb-3 mr-1",
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("Active")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "accent",
            "disabled": ""
          }
        }, [_vm._v("Disabled")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary",
            "disabled": ""
          }
        }, [_vm._v("Disabled")]), _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "outline-primary",
            "disabled": ""
          }
        }, [_vm._v("Disabled")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Button Group Sizing")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-16",
            modifiers: {
              "collapse-16": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-16"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "button-group-sizing"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button-group size=\"lg\">\n  <b-button variant=\"primary\">Left</b-button>\n  <b-button variant=\"primary\">Middle</b-button>\n  <b-button variant=\"primary\">Right</b-button>\n</b-button-group>\n\n<b-button-group class=\"mt-3\">\n  <b-button variant=\"primary\">Left</b-button>\n  <b-button variant=\"primary\">Middle</b-button>\n  <b-button variant=\"primary\">Right</b-button>\n</b-button-group>\n\n<b-button-group size=\"sm\" class=\"mt-3\">\n  <b-button variant=\"primary\">Left</b-button>\n  <b-button variant=\"primary\">Middle</b-button>\n  <b-button variant=\"primary\">Right</b-button>\n</b-button-group>\n")])])])])]), _c('div', {
          staticClass: "d-block"
        }, [_c('b-button-group', {
          attrs: {
            "size": "lg"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Left")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Middle")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Right")])], 1)], 1), _c('div', {
          staticClass: "d-block"
        }, [_c('b-button-group', {
          staticClass: "mt-3"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Left")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Middle")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Right")])], 1)], 1), _c('div', {
          staticClass: "d-block"
        }, [_c('b-button-group', {
          staticClass: "mt-3",
          attrs: {
            "size": "sm"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Left")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Middle")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Right")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Buttons Group")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-17",
            modifiers: {
              "collapse-17": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-17"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "buttons-group"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button-group>\n  <b-button variant=\"primary\">Left</b-button>\n  <b-button variant=\"primary\">Middle</b-button>\n  <b-button variant=\"primary\">Right</b-button>\n</b-button-group>\n<b-button-toolbar aria-label=\"Toolbar with button groups\" class=\"mt-3\">\n  <b-button-group class=\"mr-2\">\n    <b-button variant=\"secondary\">1</b-button>\n    <b-button variant=\"secondary\">2</b-button>\n    <b-button variant=\"secondary\">3</b-button>\n    <b-button variant=\"secondary\">4</b-button>\n  </b-button-group>\n  <b-button-group class=\"mr-2\">\n    <b-button variant=\"secondary\">5</b-button>\n    <b-button variant=\"secondary\">6</b-button>\n    <b-button variant=\"secondary\">7</b-button>\n  </b-button-group>\n  <b-button-group class=\"mr-2\">\n    <b-button variant=\"secondary\">8</b-button>\n  </b-button-group>\n</b-button-toolbar>\n")])])])])]), _c('b-button-group', [_c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Left")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Middle")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Right")])], 1), _c('b-button-toolbar', {
          staticClass: "mt-3",
          attrs: {
            "aria-label": "Toolbar with button groups"
          }
        }, [_c('b-button-group', {
          staticClass: "mr-2"
        }, [_c('b-button', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("1")]), _c('b-button', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("2")]), _c('b-button', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("3")]), _c('b-button', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("4")])], 1), _c('b-button-group', {
          staticClass: "mr-2"
        }, [_c('b-button', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("5")]), _c('b-button', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("6")]), _c('b-button', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("7")])], 1), _c('b-button-group', {
          staticClass: "mr-2"
        }, [_c('b-button', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("8")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Buttons Dropdown")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-18",
            modifiers: {
              "collapse-18": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "mb-4"
        }, [_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-18"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "buttons-dropdown"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-button-group class=\"mr-2\">\n  <b-button variant=\"primary\">1</b-button>\n  <b-button variant=\"primary\">2</b-button>\n  <b-dropdown variant=\"primary\" text=\"Dropdown\">\n    <b-dropdown-item href=\"#\">Dropdown link</b-dropdown-item>\n    <b-dropdown-item href=\"#\">Dropdown link</b-dropdown-item>\n  </b-dropdown>\n</b-button-group>\n<div class=\"d-block mt-3\">\n  <b-button-group vertical>\n    <b-button variant=\"primary\">1</b-button>\n    <b-button variant=\"primary\">2</b-button>\n    <b-dropdown variant=\"primary\" text=\"Dropdown\">\n      <b-dropdown-item href=\"#\">Dropdown link</b-dropdown-item>\n      <b-dropdown-item href=\"#\">Dropdown link</b-dropdown-item>\n    </b-dropdown>\n  </b-button-group>\n</div>\n")])])])])]), _c('b-button-group', {
          staticClass: "mr-2"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("1")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("2")]), _c('b-dropdown', {
          attrs: {
            "variant": "primary",
            "text": "Dropdown"
          }
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Dropdown link")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Dropdown link")])], 1)], 1), _c('div', {
          staticClass: "d-block mt-3"
        }, [_c('b-button-group', {
          attrs: {
            "vertical": ""
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("1")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("2")]), _c('b-dropdown', {
          attrs: {
            "variant": "primary",
            "text": "Dropdown"
          }
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Dropdown link")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Dropdown link")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }