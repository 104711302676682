var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.lists, function (data, index) {
    return _c('b-row', {
      key: index
    }, [_c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "md": "3"
      }
    }, [_c('strong', [_vm._v("Vessel:")]), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(data.vehicle.name))])]), _c('b-col', {
      attrs: {
        "md": "3"
      }
    }, [_c('strong', [_vm._v("DOL:")]), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(data.date_of_loss))])]), _c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('strong', [_vm._v("Cause of Loss:")]), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(data.classification_of_loss.name))])]), _c('b-col', {
      staticClass: "mt-3",
      attrs: {
        "md": "12"
      }
    }, [_c('table', {
      staticClass: "table table-bordered"
    }, [_c('thead', [_c('tr', {
      staticClass: "bg-warning"
    }, [_c('th', {}, [_vm._v("Claim Status")]), _c('th', {}, [_vm._v("Status")]), _c('th', {}, [_vm._v("Remarks")]), _c('th', {}, [_vm._v("Taken Days")])])]), _c('tbody', [data.progress.length === 0 ? _c('tr', [_c('td', {
      attrs: {
        "colspan": "4"
      }
    }, [_vm._v("No Data")])]) : _vm._e(), _vm._l(data.progress, function (progress) {
      return _c('tr', [_c('td', [_vm._v(" " + _vm._s(progress.actual_claim_progress) + " ")]), _c('td', [_vm._v(" " + _vm._s(progress.status) + " ")]), _c('td', [_vm._v(" " + _vm._s(progress.remarks) + " ")]), _c('td', [_vm._v(" " + _vm._s(progress.total_days) + " ")])]);
    })], 2)])])], 1)], 1)], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }