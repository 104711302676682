var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', {
          staticClass: "uppercase"
        }, [_vm._v("HISTORY REPAIR & MAINTENANCE - " + _vm._s(_vm.fleets))])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-filter",
            modifiers: {
              "collapse-filter": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-calendar"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$list_repair_finis, _vm$list_repair_finis2, _vm$detail_repair_fin, _vm$detail_repair_fin2;
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "5"
          }
        })], 1)], 1), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL REPAIR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL COST")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("LOKASI")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TANGGAL")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("MINOR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("MEDIUM")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("MAJOR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', _vm._l(_vm.list_repair_finished.data, function (state, index) {
          var _state$status, _state$status2, _state$status3, _state$classfication, _state$classfication2, _state$classfication3;
          return _c('tr', {
            key: index
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.total_repair) + " Jobs ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.formatCurrency(state === null || state === void 0 ? void 0 : state.total_cost_real)))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.location))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.moment(state === null || state === void 0 ? void 0 : state.accident_at).format('DD MMMM YYYY')))]), _c('td', {
            staticClass: "text-center"
          }, [_c('b-badge', {
            staticClass: "mb-1",
            attrs: {
              "variant": "info"
            }
          }, [_vm._v("Earlier : " + _vm._s(state === null || state === void 0 ? void 0 : (_state$status = state.status) === null || _state$status === void 0 ? void 0 : _state$status.earlier) + " Jobs")]), _vm._v(" "), _c('br'), _c('b-badge', {
            staticClass: "mb-1",
            attrs: {
              "variant": "success"
            }
          }, [_vm._v("On Time : " + _vm._s(state === null || state === void 0 ? void 0 : (_state$status2 = state.status) === null || _state$status2 === void 0 ? void 0 : _state$status2.onTime) + " Jobs")]), _c('br'), _c('b-badge', {
            attrs: {
              "variant": "danger"
            }
          }, [_vm._v("Delay : " + _vm._s(state === null || state === void 0 ? void 0 : (_state$status3 = state.status) === null || _state$status3 === void 0 ? void 0 : _state$status3.late) + " Jobs")])], 1), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$classfication = state.classfication) === null || _state$classfication === void 0 ? void 0 : _state$classfication.minor) + " Jobs")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$classfication2 = state.classfication) === null || _state$classfication2 === void 0 ? void 0 : _state$classfication2.medium) + " Jobs")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$classfication3 = state.classfication) === null || _state$classfication3 === void 0 ? void 0 : _state$classfication3.major) + " Jobs")]), _c('td', {
            staticClass: "text-center"
          }, [_c('b-dropdown', {
            attrs: {
              "size": "sm",
              "variant": "link iq-bg-primary iq-border-radius-10",
              "toggle-class": "text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton5",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-pencil-square-o m-0 text-primary"
                })])];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            attrs: {
              "href": "#"
            }
          }, [_c('b-dropdown-item', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle.detail_sr",
              modifiers: {
                "detail_sr": true
              }
            }],
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click() {
                return _vm.getDetailRepairServiceAndRepair(state === null || state === void 0 ? void 0 : state.id, state === null || state === void 0 ? void 0 : state.created_at);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-info-circle mr-2"
          }), _vm._v("Detail ")])], 1)], 1)], 1)]);
        }), 0)]), ((_vm$list_repair_finis = _vm.list_repair_finished) === null || _vm$list_repair_finis === void 0 ? void 0 : (_vm$list_repair_finis2 = _vm$list_repair_finis.data) === null || _vm$list_repair_finis2 === void 0 ? void 0 : _vm$list_repair_finis2.length) > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.list_repair_finished.meta.total_item,
            "per-page": _vm.list_repair_finished.meta.per_page,
            "aria-controls": "my-table",
            "align": "center",
            "first-number": ""
          },
          on: {
            "change": _vm.getListFinishedTableServiceAndRepairById
          },
          model: {
            value: _vm.list_repair_finished.meta.page,
            callback: function callback($$v) {
              _vm.$set(_vm.list_repair_finished.meta, "page", $$v);
            },
            expression: "list_repair_finished.meta.page"
          }
        })], 1) : _vm._e(), _c('b-sidebar', {
          attrs: {
            "id": "detail_sr",
            "title": "DETAIL REPAIR & MAINTENANCE - ".concat((_vm$detail_repair_fin = _vm.detail_repair_finished) === null || _vm$detail_repair_fin === void 0 ? void 0 : (_vm$detail_repair_fin2 = _vm$detail_repair_fin.vehicle) === null || _vm$detail_repair_fin2 === void 0 ? void 0 : _vm$detail_repair_fin2.name),
            "width": "70%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var _vm$detail_repair_fin3, _vm$detail_repair_fin4, _vm$detail_repair_fin5, _vm$detail_repair_fin6, _vm$detail_repair_fin7;
              var hide = _ref.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Pekerjaan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$detail_repair_fin3 = _vm.detail_repair_finished) === null || _vm$detail_repair_fin3 === void 0 ? void 0 : _vm$detail_repair_fin3.location))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s(_vm.moment((_vm$detail_repair_fin4 = _vm.detail_repair_finished) === null || _vm$detail_repair_fin4 === void 0 ? void 0 : _vm$detail_repair_fin4.created_at).format('DD MMMM YYYY')))])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Office / Site")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$detail_repair_fin5 = _vm.detail_repair_finished) === null || _vm$detail_repair_fin5 === void 0 ? void 0 : _vm$detail_repair_fin5.site))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC Repair")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s((_vm$detail_repair_fin6 = _vm.detail_repair_finished) === null || _vm$detail_repair_fin6 === void 0 ? void 0 : _vm$detail_repair_fin6.pic))])])])])]), _vm._l((_vm$detail_repair_fin7 = _vm.detail_repair_finished) === null || _vm$detail_repair_fin7 === void 0 ? void 0 : _vm$detail_repair_fin7.form_repair, function (state, index) {
                var _state$status4, _state$status5, _state$status6, _state$status7, _state$status8, _state$task_type, _state$department, _state$category, _state$classification, _state$classification2, _state$classification3, _state$case;
                return _c('div', {
                  key: index
                }, [_c('b-card', {
                  attrs: {
                    "no-body": ""
                  }
                }, [_c('b-card-header', {
                  staticClass: "p-1",
                  staticStyle: {
                    "background-color": "white",
                    "border": "none"
                  },
                  attrs: {
                    "header-tag": "header",
                    "role": "tab"
                  }
                }, [_c('b-button', {
                  directives: [{
                    name: "b-toggle",
                    rawName: "v-b-toggle",
                    value: "accordion-" + index,
                    expression: "`accordion-` + index"
                  }],
                  staticClass: "text-left",
                  attrs: {
                    "block": "",
                    "variant": (state === null || state === void 0 ? void 0 : (_state$status4 = state.status) === null || _state$status4 === void 0 ? void 0 : _state$status4.alias) === 'new' ? 'info' : (state === null || state === void 0 ? void 0 : (_state$status5 = state.status) === null || _state$status5 === void 0 ? void 0 : _state$status5.alias) === 'progress' ? 'warning' : (state === null || state === void 0 ? void 0 : (_state$status6 = state.status) === null || _state$status6 === void 0 ? void 0 : _state$status6.alias) === 'finish' ? 'success' : (state === null || state === void 0 ? void 0 : (_state$status7 = state.status) === null || _state$status7 === void 0 ? void 0 : _state$status7.alias) === 'review' ? 'primary' : 'danger'
                  }
                }, [_c('i', {
                  staticClass: "fa fa-chevron-circle-down"
                }), _vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.name) + " - " + _vm._s(state === null || state === void 0 ? void 0 : state.title) + " "), _c('strong', [_vm._v("(" + _vm._s(state === null || state === void 0 ? void 0 : (_state$status8 = state.status) === null || _state$status8 === void 0 ? void 0 : _state$status8.name) + ")")])])], 1), _c('b-collapse', {
                  attrs: {
                    "id": 'accordion-' + index,
                    "visible": false,
                    "accordion": "my-accordion",
                    "role": "tabpanel"
                  }
                }, [_c('table', {
                  staticClass: "table table-striped mt-3"
                }, [_c('tbody', [_c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Equipment Name")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Repair Title")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.title))])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Type R & M")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$task_type = state.task_type) === null || _state$task_type === void 0 ? void 0 : _state$task_type.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Department")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$department = state.department) === null || _state$department === void 0 ? void 0 : _state$department.name))])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Category")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$category = state.category) === null || _state$category === void 0 ? void 0 : _state$category.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Classification")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$classification = state.classification) === null || _state$classification === void 0 ? void 0 : _state$classification.name) + " (" + _vm._s(state === null || state === void 0 ? void 0 : (_state$classification2 = state.classification) === null || _state$classification2 === void 0 ? void 0 : _state$classification2.interval) + " " + _vm._s(state === null || state === void 0 ? void 0 : (_state$classification3 = state.classification) === null || _state$classification3 === void 0 ? void 0 : _state$classification3.unit) + ")")])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Cause")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$case = state.case) === null || _state$case === void 0 ? void 0 : _state$case.name))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Kind Task")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v("Repair")])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Rencana Mulai")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(_vm.moment(state === null || state === void 0 ? void 0 : state.start_at).format('DD MMMM YYYY')))]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Rencana Selesai (Est)")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(_vm.moment(state === null || state === void 0 ? void 0 : state.actual_finished_at).format('DD MMMM YYYY')))])]), _c('tr', [_c('td', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_c('b', [_vm._v("Estimate Cost")])]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(" " + _vm._s(_vm.formatCurrency(state.cost_estimate)) + " ")]), _c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Estimasi Hari")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.estimate_total_days))])]), _c('tr', [_c('td', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_c('b', [_vm._v("Cost Real")])]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(" " + _vm._s(_vm.formatCurrency(state.cost_real)) + " ")]), _c('td', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_c('b', [_vm._v("Status Pencapaian")])]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "width": "25%"
                  }
                }, [_vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.reward) + " ")])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Berita Acara")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  attrs: {
                    "colspan": "4"
                  }
                }, [_c('ul', {
                  staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
                }, _vm._l(state === null || state === void 0 ? void 0 : state.file, function (file_data, fileIndex) {
                  return _c('li', {
                    key: fileIndex,
                    staticClass: "col-md-1 col-1"
                  }, [_c('a', {
                    attrs: {
                      "href": "javascript:void(0);"
                    }
                  }, [_c('img', {
                    staticClass: "img-fluid",
                    attrs: {
                      "src": require("../../assets/images/activity/pdf.png"),
                      "alt": "gallary-image"
                    },
                    on: {
                      "click": function click($event) {
                        return _vm.handleOpenFilePreview(file_data.file);
                      }
                    }
                  })])]);
                }), 0)])]), _c('tr', [_c('th', {
                  attrs: {
                    "width": "20%"
                  }
                }, [_vm._v("Description")]), _c('td', {
                  staticClass: "text-center",
                  attrs: {
                    "width": "5%"
                  }
                }, [_vm._v(":")]), _c('td', {
                  staticClass: "align-top",
                  attrs: {
                    "colspan": "4"
                  }
                }, [_vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.description) + " ")])])])]), _c('hr'), state !== null && state !== void 0 && state.is_internal ? _c('fieldset', {
                  staticClass: "form-group border p-3"
                }, [_c('legend', {
                  staticClass: "w-auto px-2"
                }, [_vm._v("Internal Man Power")]), _c('table', {
                  staticClass: "table table-striped table-sm"
                }, [_c('tbody', [_c('tr', [_c('th', {
                  staticClass: "text-left"
                }, [_vm._v("NAMA")]), _c('th', {
                  staticClass: "text-left"
                }, [_vm._v("JABATAN")])]), _vm._l(state === null || state === void 0 ? void 0 : state.entity_internal, function (entity, entityIndex) {
                  return _c('tr', {
                    key: entityIndex
                  }, [_c('td', {
                    staticClass: "text-left"
                  }, [_vm._v(_vm._s(entity === null || entity === void 0 ? void 0 : entity.internal_name))]), _c('td', {
                    staticClass: "text-left"
                  }, [_vm._v(_vm._s(_vm.positionName(entity === null || entity === void 0 ? void 0 : entity.internal_position_id)))])]);
                })], 2)])]) : _vm._e(), state !== null && state !== void 0 && state.is_subkon ? _c('fieldset', {
                  staticClass: "form-group border p-3"
                }, [_c('legend', {
                  staticClass: "w-auto px-2"
                }, [_vm._v("Sub Kontraktor")]), _c('table', {
                  staticClass: "table table-striped table-sm"
                }, [_c('tbody', [_c('tr', [_c('th', {
                  staticClass: "pl-3"
                }, [_vm._v("NAMA KONTRAKTOR")]), _c('th', [_vm._v("PIC/EXECUTIVE ENGINEER")]), _c('th', {
                  staticClass: "text-center"
                }, [_vm._v("OFFERING")]), _c('th', {
                  staticClass: "text-center"
                }, [_vm._v("QUOTATION")]), _c('th', {
                  staticClass: "text-center"
                }, [_vm._v("FINAL QUOTATION")]), _c('th', {
                  staticClass: "text-center"
                }, [_vm._v("FILE")])]), _vm._l(state === null || state === void 0 ? void 0 : state.entity_subkon, function (entity, entityIndex) {
                  var _entity$subkon_file;
                  return _c('tr', {
                    key: entityIndex
                  }, [_c('td', {
                    staticClass: "pl-3"
                  }, [_vm._v(_vm._s(entity === null || entity === void 0 ? void 0 : entity.subkon_name))]), _c('td', [_vm._v(_vm._s(entity === null || entity === void 0 ? void 0 : entity.subkon_pic))]), _c('td', {
                    staticClass: "text-center"
                  }, [_vm._v(_vm._s(_vm.formatCurrency(entity === null || entity === void 0 ? void 0 : entity.subkon_price_offering)))]), _c('td', {
                    staticClass: "text-center"
                  }, [_vm._v(_vm._s(_vm.formatCurrency(entity === null || entity === void 0 ? void 0 : entity.subkon_price_nego)))]), _c('td', {
                    staticClass: "text-center"
                  }, [_vm._v(_vm._s(_vm.formatCurrency(entity === null || entity === void 0 ? void 0 : entity.subkon_price_deal)))]), (entity === null || entity === void 0 ? void 0 : (_entity$subkon_file = entity.subkon_file) === null || _entity$subkon_file === void 0 ? void 0 : _entity$subkon_file.length) > 0 ? _c('td', {
                    staticClass: "text-center text-primary"
                  }, _vm._l(entity === null || entity === void 0 ? void 0 : entity.subkon_file, function (fileSubkon, subkonIndexFile) {
                    return _c('div', {
                      key: subkonIndexFile,
                      staticClass: "mr-2"
                    }, [fileSubkon.extension === 'pdf' ? _c('i', {
                      staticClass: "fa fa-file-pdf text-primary h4",
                      staticStyle: {
                        "cursor": "pointer"
                      },
                      on: {
                        "click": function click($event) {
                          return _vm.handleOpenFilePreview(fileSubkon === null || fileSubkon === void 0 ? void 0 : fileSubkon.url);
                        }
                      }
                    }) : _c('img', {
                      staticClass: "img-fluid",
                      staticStyle: {
                        "width": "70px",
                        "height": "70px"
                      },
                      attrs: {
                        "src": fileSubkon === null || fileSubkon === void 0 ? void 0 : fileSubkon.url,
                        "alt": ""
                      }
                    })]);
                  }), 0) : _vm._e()]);
                })], 2)])]) : _vm._e(), _c('ul', {
                  staticClass: "iq-timeline",
                  staticStyle: {
                    "height": "500px"
                  }
                }, _vm._l(state === null || state === void 0 ? void 0 : state.form_repair_activity, function (activity, aIndex) {
                  var _activity$activity$na, _activity$activity, _activity$parsed_file;
                  return _c('li', {
                    key: aIndex
                  }, [_c('div', {
                    staticClass: "timeline-dots border-success"
                  }), _c('h6', {
                    staticClass: "float-left mb-1"
                  }, [_vm._v(_vm._s(activity === null || activity === void 0 ? void 0 : activity.user_name))]), _c('br'), _c('small', [_vm._v(_vm._s(_vm.moment(activity === null || activity === void 0 ? void 0 : activity.date_of_activity).format('DD MMMM YYYY h:mm')))]), _c('div', {
                    staticClass: "d-inline-block w-100"
                  }, [_c('b-badge', {
                    attrs: {
                      "variant": (activity === null || activity === void 0 ? void 0 : activity.activity.activity_flag) === 'running' ? 'primary' : (activity === null || activity === void 0 ? void 0 : activity.activity.activity_flag) === 'finish' ? 'success' : 'danger'
                    }
                  }, [_vm._v(_vm._s((_activity$activity$na = activity === null || activity === void 0 ? void 0 : (_activity$activity = activity.activity) === null || _activity$activity === void 0 ? void 0 : _activity$activity.name) !== null && _activity$activity$na !== void 0 ? _activity$activity$na : '-'))]), _vm._v(" "), _c('br'), _vm._v(" "), _c('p', [_c('strong', {
                    staticClass: "text-muted"
                  }, [_vm._v("Notes:")]), _vm._v(" " + _vm._s(activity === null || activity === void 0 ? void 0 : activity.notes))])], 1), (activity === null || activity === void 0 ? void 0 : (_activity$parsed_file = activity.parsed_file) === null || _activity$parsed_file === void 0 ? void 0 : _activity$parsed_file.length) > 0 ? _c('div', {
                    staticClass: "d-inline-block w-100"
                  }, [_c('ul', {
                    staticClass: "d-flex flex-wrap p-0 m-0"
                  }, _vm._l(activity === null || activity === void 0 ? void 0 : activity.parsed_file, function (file, fileIndex) {
                    return _c('li', {
                      key: fileIndex,
                      staticClass: "pb-2"
                    }, [_c('a', {
                      attrs: {
                        "href": "javascript:void(0);"
                      }
                    }, [file.extension == 'pdf' ? _c('img', {
                      staticClass: "img-fluid",
                      staticStyle: {
                        "width": "70px",
                        "height": "70px"
                      },
                      attrs: {
                        "src": require("../../assets/images/activity/pdf.png"),
                        "alt": "gallary-image"
                      },
                      on: {
                        "click": function click($event) {
                          return _vm.handleOpenFilePreview(file === null || file === void 0 ? void 0 : file.url);
                        }
                      }
                    }) : _c('img', {
                      staticClass: "img-fluid",
                      staticStyle: {
                        "width": "70px",
                        "height": "70px"
                      },
                      attrs: {
                        "src": file.url,
                        "alt": "image"
                      }
                    })])]);
                  }), 0)]) : _vm._e()]);
                }), 0)])], 1)], 1);
              })], 2)], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }