<template>
  <div>
    <b-row
      v-for="(data, index) in lists"
      :key="index"
    >
      <b-col md="8">
        <b-row>
          <b-col md="3">
            <strong>Vessel:</strong>
            <span class="ml-2">{{ data.vehicle.name }}</span>
          </b-col>
          <b-col md="3">
            <strong>DOL:</strong>
            <span class="ml-2">{{ data.date_of_loss }}</span>
          </b-col>
          <b-col md="6">
            <strong>Cause of Loss:</strong>
            <span class="ml-2">{{
                data.classification_of_loss.name
              }}</span>
          </b-col>
          <b-col md="12" class="mt-3">
            <table class="table table-bordered">
              <thead>
              <tr class="bg-warning">
                <th class="">Insurer</th>
                <th class="">Share</th>
                <th class="">Amount</th>
                <th class="">Paid Date</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="data.insurers.length === 0">
                <td colspan="4">No Data</td>
              </tr>
              <tr
                v-for="(insurer, insurerIndex) in data.insurers"
                :key="insurerIndex"
              >
                <td>
                  {{ insurer.insurer_club ? insurer.insurer_club.name : '-' }}
                </td>
                <td>
                  {{ insurer.share }}
                </td>
                <td>
                  {{ insurer.currency.alias }}
                  {{ numberFormat(insurer.amount) }}
                </td>
                <td>
                  {{ insurer.paid_at }}
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="4">
        <table
          class="table table-bordered"
          v-for="(summary, summaryIndex) in data.summaries"
          :key="summaryIndex"
        >
          <thead>
          <tr class="bg-success">
            <th class="">Details</th>
            <th class="">Amount</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td> Actual/Rill Cost </td>
            <td>
              {{ numberFormat(summary.claim_proposed_actual) }}
            </td>
          </tr>
          <tr>
            <td> Claim Proposed </td>
            <td>
              {{ numberFormat(summary.claim_proposed_insurer) }}
            </td>
          </tr>
          <tr>
            <td> Final Adjustment </td>
            <td>
              {{ numberFormat(summary.final_adjusment) }}
            </td>
          </tr>
          <tr>
            <td> Deductible </td>
            <td>
              {{ numberFormat(summary.deductible) }}
            </td>
          </tr>
          <tr>
            <td> Net Claim </td>
            <td>
              {{ numberFormat(summary.net_claim_cost) }}
            </td>
          </tr>
          <tr>
            <td> Total Claim Days </td>
            <td>
              {{ summary.total_claim_days }}
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { numberFormat } from '@src/plugins/helpers'

export default {
  name: 'SummaryManagementReportSettled',
  props: {
    lists: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  methods: {
    numberFormat (number) {
      return numberFormat(number)
    },
  }
}
</script>

<style scoped>

</style>
