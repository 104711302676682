<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-12 p-0" :active="true" href="#hm" title="Sum Insured Fleet of TEL Group" />
                <!--                <tab-nav-items class="col-md-6 p-0" :active="false" href="#budget" title="Budget"/>-->
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <tab-content-item :active="true" id="hm">
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"><strong>Filter Sum Insured</strong></h4>
              </template>
              <template v-slot:headerAction>
                 <b-button variant="primary" @click="exportPriceOfFleets" v-if="canExport">
                  <template v-if="!loading_export">
                    <i class="fa fa-file-excel"></i> &nbsp; Export
                  </template>
                  <template v-else>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Exporting...
                  </template>
                </b-button>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group label="Choose your Type Filter" label-for="fleet-equipment">
                      <template v-for="(item,index) in typeFilterOptions">
                        <b-form-radio v-model="fleetPrices.params.type" class="custom-radio-color-checked" inline :color="item.color" name="color" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                      </template>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="3">
                    <b-form-group label="Company :" label-for="kind-fleet" v-if="fleetPrices.params.type === 'company'">
                      <v-select id="fleet_name" v-model="fleetPrices.params.values" class="w-100" :reduce="(item) => item.value" label="text"
                                placeholder="Select fleet" :options="companyOptions" multiple></v-select>
                    </b-form-group>
                    <b-form-group label="Vehicle Type :" label-for="kind-fleet" v-if="fleetPrices.params.type === 'vehicle_type'">
                      <v-select id="fleet_name" v-model="fleetPrices.params.values" class="w-100" :reduce="(item) => item.value" label="text"
                                placeholder="Select fleet" :options="fleetTypes" multiple></v-select>
                    </b-form-group>
                    <b-form-group label="Fleet :" label-for="kind-fleet" v-if="fleetPrices.params.type === 'vehicle'">
                      <v-select id="fleet_name" v-model="fleetPrices.params.values" class="w-100" :reduce="(item) => item.value" label="text"
                        placeholder="Select fleet" :options="fleetOptions" multiple></v-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="2">
                    <b-form-group label-for="dateIssued" label="Date">
                      <date-picker v-model="fleetPrices.params.date" style="width: 100%" type="date" value-type="YYYY-MM-DD"
                        placeholder="Select date"></date-picker>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="1">
                    <b-form-group label="Search" label-for="button_search">
                      <b-button :disabled="fleetPrices.params.date === ''" @click="fetchFleetPrices(1)" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
          <!-- v1.1 Rev -->
          <b-col md="12">
            <table class="table table-bordered table-striped shadow">
              <thead>
              <tr>
                <th>Currency <small>!= IDR</small></th>
                <th>Kurs to IDR</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(currency, index) in fleetPrices.mapping">
                <tr :key="index">
                  <td><strong>{{ currency.currency.name }}</strong></td>
                  <td>
                    <h5><strong>IDR {{ numberFormat(currency.value) }}</strong></h5>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </b-col>
          <b-col lg="12">
            <iq-card>
              <template v-slot:body>
                <b-row>
                  <b-col md="12" class="text-right">
                    <b-row>
                      <b-col md="6" class="text-left">
                        Data Period of <strong> {{ fleetPrices.params.date}} </strong>
                      </b-col>
                      <b-col md="6" class="text-right">
                        <button v-if="fleetPrices.detail && canEdit" @click="$router.push(`/mi/price-fleet-form?id=${fleetPrices.detail.id}`)" class="btn btn-warning mb-3 mr-2"><i class="fa fa-edit"></i> Edit Data</button>
                        <router-link :to="{ name: 'mi.price-fleet-form' }" v-if="canAdd">
                          <button class="btn btn-primary mb-3"><i class="fa fa-plus"></i> Add Sum Insured New Period</button>
                        </router-link>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="12">
                    <b-alert :show="fleetPrices.data.length === 0" variant="danger">
                      <div class="iq-alert-text text-center">Data Not Found</div>
                    </b-alert>
                  </b-col>
                  <b-col md="12">
                    <template v-for="(company, index) in fleetPrices.data">
                      <b-card no-body class="mb-1" :key="`comp-${index}`">
                        <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="'company' + company.id">
                          <h4 style="cursor: pointer; font-weight: bold" class="p-3">
                            {{ company.company }}
                          </h4>
                        </b-card-header>
                        <b-collapse :id="'company' + company.id" :visible="true" role="tabpanel">
                          <!-- accordion="company-accordion" -->
                          <b-card-body>
                            <!-- Accordion for Category Fleet -->
                            <template v-for="(category, index2) in company.types.filter(category => !category.type_row || (category.type_row && category.type_row !== 'total'))">
                              <b-card no-body class="mb-1" :key="`mt2-${index2}`">
                                <b-card-header
                                  header-tag="header"
                                  class="p-1"
                                  role="tab"
                                  v-b-toggle="`category-${index2}-company-${index}`"
                                >
                                  <h4 style="cursor: pointer" class="p-3">
                                    {{ category.name }}
                                  </h4>
                                </b-card-header>
                                <b-collapse :id="`category-${index2}-company-${index}`" :visible="false" role="tabpanel">
                                  <b-card-body>
                                    <b-card-text>
                                      <div class="table-responsive tableFixHead">
                                        <table class="table mb-0 table-striped table-hover" style="width: 2200px;display: block;height: 700px;overflow-y: scroll;">
                                          <thead class="text-center thead-dark">
                                          <tr>
                                            <th rowspan="2" style="vertical-align: middle;" width="5%">No</th>
                                            <th rowspan="2" style="vertical-align: middle;" width="15%">Fleet</th>
                                            <th rowspan="2" style="vertical-align: middle;" width="15%">Last Value</th>
                                            <th rowspan="2" style="vertical-align: middle;" width="10%">Reduce Value</th>
                                            <th colspan="4" style="vertical-align: middle;">Sum Insured</th>
                                            <th colspan="3" style="vertical-align: middle;">Rate %</th>
                                            <th rowspan="2" style="vertical-align: middle;" width="15%">Deductible</th>
                                            <th rowspan="2" style="vertical-align: middle;" width="12%">Insurance Period</th>
                                          </tr>
                                          <tr>
                                            <td width="12%">TSI/Value(New)</td>
                                            <td width="12%">HM </td>
                                            <td width="12%">IV </td>
                                            <td width="12%">WAR</td>
                                            <td width="10%">Rate HM </td>
                                            <td width="10%">Rate IV </td>
                                            <td width="10%">WAR</td>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          <tr v-for="(content, indexContent) in category.data.filter(content => !content.type_row || (content.type_row && content.type_row !== 'total'))">
                                            <td>{{ indexContent+1 }}.</td>
                                            <td>
                                              {{ content.vehicle.name }}
                                            </td>
                                            <td>
                                              {{ content.currency.alias }} {{ numberFormat(content.value) }}
                                            </td>
                                            <td>
                                              {{ numberFormat(content.reduce) }}%
                                            </td>
                                            <td>{{ content.currency.alias }} {{ numberFormat(Number(content.tsi).toFixed(2)) }} </td>
                                            <td>{{ content.currency.alias }} {{ numberFormat(Number(content.hm).toFixed(2)) }} </td>
                                            <td>{{ content.currency.alias }} {{ numberFormat(Number(content.iv).toFixed(2)) }} </td>
                                            <td>
                                              {{ numberFormat(content.war) }}
                                            </td>
                                            <td>
<!--                                              {{ numberFormat(Number(content.rate_hm).toFixed(4)) }}-->
                                              {{ (Number(content.rate_hm).toFixed(4)) }}
                                            </td>
                                            <td>
<!--                                              {{ numberFormat(Number(content.rate_iv).toFixed(4)) }}-->
                                              {{ (Number(content.rate_iv).toFixed(4)) }}
                                            </td>
                                            <td>
<!--                                              {{ numberFormat(Number(content.rate_war).toFixed(4)) }}-->
                                              {{ (Number(content.rate_war).toFixed(4)) }}
                                            </td>
                                            <td>{{ content.currency.alias }} {{ numberFormat(content.deductible_type === 'float' ? content.deductible_value : content.suggest_rate_hm) }} </td>
                                            <td>
                                              {{ content.from }} - {{ content.to }}
                                            </td>
                                          </tr>
                                          </tbody>
                                          <tfoot class="bg-warning text-white" v-for="(content, indexContent) in category.data.filter(content => content.type_row && content.type_row === 'total')">
                                          <tr>
                                            <td colspan="2" rowspan="2">TOTAL</td>
                                            <td colspan="2">
                                              <template v-if="content.value">
                                                <div v-for="key in Object.keys(content.value)" :key="key">
                                                  {{ key }} {{numberFormat(content.value[key])}}
                                                </div>
                                              </template>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td colspan="6"></td>
                                          </tr>
                                          <tr>
                                            <td colspan="2">
                                              <template v-if="content.value">
                                                <div v-for="key in Object.keys(content.value)" :key="key">
                                                  {{ key }} {{numberFormat(content.value[key])}}
                                                </div>
                                              </template>
                                            </td>
                                            <td>
                                              <template v-if="content.value">
                                                <div v-for="key in Object.keys(content.value)" :key="key">
                                                  {{ key }} {{numberFormat(content.value[key])}}
                                                </div>
                                              </template>
                                            </td>
                                            <td>
                                              <template v-if="content.hm">
                                                <div v-for="key in Object.keys(content.hm)" :key="key">
                                                  {{ key }} {{numberFormat(content.hm[key])}}
                                                </div>
                                              </template>
                                            </td>
                                            <td>
                                              <template v-if="content.iv">
                                                <div v-for="key in Object.keys(content.iv)" :key="key">
                                                  {{ key }} {{numberFormat(content.iv[key])}}
                                                </div>
                                              </template>
                                            </td>
                                            <td>
                                              <template v-if="content.war">
                                                <div v-for="key in Object.keys(content.war)" :key="key">
                                                  {{ key }} {{numberFormat(content.war[key])}}
                                                </div>
                                              </template>
                                            </td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td colspan="6"></td>
                                          </tr>
                                          </tfoot>
                                        </table>
                                      </div>
                                    </b-card-text>
                                  </b-card-body>
                                </b-collapse>
                              </b-card>
                            </template>
                            <!-- END Accordion for Category Fleet -->
                            <table class="table">
                              <thead class="text-center thead-dark">
                              <tr>
                                <th rowspan="2" style="vertical-align: middle;" width="15%"></th>
                                <th rowspan="2" style="vertical-align: middle;" width="15%">Last Value</th>
                                <th colspan="4" style="vertical-align: middle;">Sum Insured</th>
                              </tr>
                              <tr>
                                <td width="12%">TSI/Value(New)</td>
                                <td width="12%">HM </td>
                                <td width="12%">IV </td>
                                <td width="12%">WAR</td>
                              </tr>
                              </thead>
                              <tfoot class="bg-warning text-white" v-for="(category) in company.types.filter(category => category.type_row && category.type_row === 'total')">
                              <tr>
                                <td rowspan="2">TOTAL</td>
                                <td>
                                  <template v-if="category.value">
                                    <div v-for="key in Object.keys(category.value)" :key="key">
                                      {{ key }} {{numberFormat(category.value[key])}}
                                    </div>
                                  </template>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>
                                  <template v-if="category.value">
                                    <div v-for="key in Object.keys(category.value)" :key="key">
                                      {{ key }} {{numberFormat(category.value[key])}}
                                    </div>
                                  </template>
                                </td>
                                <td>
                                  <template v-if="category.value">
                                    <div v-for="key in Object.keys(category.value)" :key="key">
                                      {{ key }} {{numberFormat(category.value[key])}}
                                    </div>
                                  </template>
                                </td>
                                <td>
                                  <template v-if="category.hm">
                                    <div v-for="key in Object.keys(category.hm)" :key="key">
                                      {{ key }} {{numberFormat(category.hm[key])}}
                                    </div>
                                  </template>
                                </td>
                                <td>
                                  <template v-if="category.iv">
                                    <div v-for="key in Object.keys(category.iv)" :key="key">
                                      {{ key }} {{numberFormat(category.iv[key])}}
                                    </div>
                                  </template>
                                </td>
                                <td>
                                  <template v-if="category.war">
                                    <div v-for="key in Object.keys(category.war)" :key="key">
                                      {{ key }} {{numberFormat(category.war[key])}}
                                    </div>
                                  </template>
                                </td>
                              </tr>
                              </tfoot>
                            </table>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </template>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
          <!-- End v1.1 Rev -->
        </b-row>
      </tab-content-item>
    </div>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { miActions, fleetsActions, companiesActions } from '@src/Utils/helper'
import { numberFormat } from '@src/plugins/helpers'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'BlankPage',
  async mounted () {
    xray.index()
    await this.fetchFleetTypes()
    await this.fetchFleets()
    await this.fetchCompanyList()
    await this.getVehicleTypeData()
    await this.fetchFleetPrices()
  },
  data () {
    return {
      lsProfile: JSON.parse(localStorage.getItem('profile')),
      companyOptions: [],
      typeFleet: [],
      companies: [],
      image: require('../../assets/images/logo.png'),
      fleetTypes: [],
      year: '2021',
      fleetOptions: [],
      loading_export: false,
      fleetPrices: {
        params: {
          type: 'company',
          date: moment().format('YYYY-MM-DD'),
          values: []
        },
        data: [],
        detail: null,
        mapping: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      typeFilterOptions: [
        {
          label: 'Company ',
          color: 'primary',
          value: 'company'
        },
        {
          label: 'Vehicle Type ',
          color: 'primary',
          value: 'vehicle_type'
        },
        {
          label: 'Individual Vehicle / Fleet',
          color: 'success',
          value: 'vehicle'
        }
      ]
    }
  },
  watch: {
    'fleetPrices.params.type' () {
      this.fleetPrices.params.values = []
    }
  },
  computed: {
    menuPermission () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Marine Insurance')?.child.find(child => child.menu === 'Financial - TSI')
    },
    canAdd () {
      return this.menuPermission ? this.menuPermission?.add : false
    },
    canEdit () {
      return this.menuPermission ? this.menuPermission?.update : false
    },
    canApprove () {
      return this.menuPermission ? this.menuPermission?.approval : false
    },
    canReview () {
      return this.menuPermission ? this.menuPermission?.review : false
    },
    canDelete () {
      return this.menuPermission ? this.menuPermission?.delete : false
    },
    canExport () {
      return this.menuPermission ? this.menuPermission?.export : false
    }
  },
  methods: {
    ...miActions,
    ...companiesActions,
    ...fleetsActions,
    numberFormat (number) {
      return numberFormat(number)
    },
    async fetchFleetPrices (page = 1) {
      this.fleetPrices.data = []
      this.fleetPrices.detail = null
      this.fleetPrices.mapping = []

      const params = {
        ...this.fleetPrices.params,
        page
      }

      const { status, data } = await this.getFleetPriceList(params)

      if (status === 'success' && data) {
        this.fleetPrices.data = data.data ? data.data : []
        this.fleetPrices.detail = data.pricefleet ? data.pricefleet : null
        this.fleetPrices.mapping = data.mapping ? data.mapping : []
      }
    },
    async exportPriceOfFleets () {
      this.loading_export = true

      const params = {
        ...this.fleetPrices.params,
        excel: true
      }

      const res = await this.getFleetPriceListExport(params)
      if (res.data) {
        this.loading_export = false
        // window.open(res.data)
        // saveAs(res.data, 'TSI List.xlsx')
        let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        let url = window.URL.createObjectURL(blob)
        window.open(url)
      }
    },
    onTotalCompanyTypeValue (companyIndex, categoryIndex, field) {
      if (!this.fleetPrices.data[companyIndex]) {
        return 0
      }

      if (!this.fleetPrices.data[companyIndex].types[categoryIndex]) {
        return 0
      }

      return Math.round(_.sumBy(this.fleetPrices.data[companyIndex].types[categoryIndex].data, (category) => {
        return parseInt(category[field])
      }))
    },
    async fetchCompanyList () {
      let res = await this.getCompanies()

      if (res.status) {
        if (res.data && res.data.length > 0) {
          this.companyOptions = res.data.map(val => ({
            text: val.company,
            value: val.id
          }))
        }
      }
    },
    async fetchFleetTypes () {
      let res = await this.getFleetTypes()
      // this.fleetTypes = res.data

      if (res.status) {
        if (res.data && res.data.length > 0) {
          this.fleetTypes = res.data.map(val => ({
            text: val.name,
            value: val.id
          }))
        }
      }
    },
    async fetchFleets () {
      this.fleetsLoading = true
      this.fleets = []

      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        search: ''
      })
      let res = await this.getFleets(params)

      if (res.status) {
        this.fleetsLoading = false
        if (res.data && res.data.length > 0) {
          this.fleetOptions = res.data.map(val => ({
            text: val.name,
            value: val.id
          }))
        }
      }
    },
    async getVehicleTypeData () {
      let params = { active: true }
      let res = await this.getFleetTypes(params)

      this.typeFleet = res.data
    }
  }
}
</script>
