<template>
    <b-container fluid>
      <b-row>
        <b-col lg="12">
            <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>INVENTORY</strong></h5>
                </template>
                <template v-slot:headerAction>
                <a class="text-primary float-right ml-3" v-b-toggle.filter role="button" style="cursor: pointer">
                <i class="fa fa-filter" />
                </a>
                </template>
                <template v-slot:body>
                  <b-collapse visible id="filter" class="mb-4">
                      <b-row>
                        <b-col md="2">
                            <b-form-select plain v-model="selectInvType" :options="optionInvType" size="sm">
                                <template v-slot:first>
                                <b-form-select-option :value="null">Inventory Type</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-col>
                        <b-col md="1">
                            <b-button variant="primary" size="sm"><i class="fa fa-search"></i></b-button>
                        </b-col>
                        <b-col md="9">
                            <b-button v-b-tooltip.top="'Add Data'" @click="addPurchaseRequest = !addPurchaseRequest;" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-plus"></i></b-button>
                            <b-button v-b-tooltip.top="'Refresh Data'" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-refresh"></i></b-button>
                            <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                                <template v-slot:button-content>
                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-save m-0"></i>
                                    </span>
                                </template>
                                <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
                                <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
                            </b-dropdown>
                        </b-col>
                      </b-row>
                    </b-collapse>
                    <div class="table-responsive">
                      <table class="table table-striped">
                          <thead>
                            <tr>
                              <th class="text-center">INVENTORY ID</th>
                              <th class="text-center">DESCRIPTION</th>
                              <th class="text-center">TYPE</th>
                              <th class="text-center">WAREHOUSE</th>
                              <th class="text-center">ACCOUNT INVENTORY</th>
                              <th class="text-center">DEFAULT PRICE </th>
                              <th class="text-center">UOM</th>
                              <th class="text-center">ACTION</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th class="text-center"><a href="#" @click="pageDetailSalesOrder = !pageDetailSalesOrder;">SP-2210001</a></th>
                              <td class="text-center">THERMOMETER EXHAUST GAS</td>
                              <td class="text-center">Component Sparepart</td>
                              <td class="text-center">Jakarta</td>
                              <td class="text-center">107201</td>
                              <td class="text-center">0</td>
                              <td class="text-center">Pcs</td>
                              <td class="text-center">
                                  <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                  <template v-slot:button-content>
                                      <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                      <i class="fa fa-list-alt m-0 text-primary"></i>
                                      </span>
                                  </template>
                                  <b-dropdown-item href="#" variant="dark"><i class="fa fa-edit mr-2"></i>Update</b-dropdown-item>
                                  <b-dropdown-item href="#" variant="dark"><i class="fa fa-trash mr-2"></i>Delete</b-dropdown-item>
                                  </b-dropdown>
                              </td>
                            </tr>
                          </tbody>
                      </table>
                    </div>
                    <div class="mt-3">
                        <b-pagination :value="1" :total-rows="50" align="center" />
                    </div>
  
                    <!-- Detail Purchase -->
                    <b-sidebar
                      v-model="pageDetailSalesOrder"
                      id="detail_vendor"
                      title="REQ-2210001 - TB. ETI 201"
                      width="80%"
                      backdrop-variant="dark"
                      bg-variant="white"
                      right
                      backdrop
                      shadow>
                      <template #default="{hide}">
                        <b-row class="m-3">
                          <b-col md="12">
                            <fieldset class="form-group border p-3">
                              <legend class="w-auto px-2 h5 text-primary">General Information</legend>
                              <table class="table table-striped mt-2">
                                <tbody>
                                  <tr>
                                    <th width="15%">Request Number</th>
                                    <td width="4%">:</td>
                                    <td width="15%">REQ-2210001</td>
                                    
                                    <th width="15%">Company</th>
                                    <td width="3%">:</td>
                                    <td width="15%">Transcoal Pacific</td>
                                    
                                    <th width="15%">Priority</th>
                                    <td width="3%">:</td>
                                    <td width="15%"><b-badge variant="primary">Normal</b-badge></td>
                                  </tr>
                                  <tr>
                                    <th width="15%">Request Class</th>
                                    <td width="4%">:</td>
                                    <td width="15%">PURCHASE</td>
                                    
                                    <th width="15%">Fleet</th>
                                    <td width="3%">:</td>
                                    <td width="15%">TB. ETI 301</td>
                                    
                                    <th width="15%">Currenct</th>
                                    <td width="3%">:</td>
                                    <td width="15%">IDR</td>
                                  </tr>
                                  <tr>
                                    <th width="15%">Request By</th>
                                    <td width="4%">:</td>
                                    <td width="15%">Agung Tri Laksono</td>
                                    
                                    <th width="15%">Project</th>
                                    <td width="3%">:</td>
                                    <td width="15%">KPC Project</td>
                                    
                                    <th width="15%">Est. Cost</th>
                                    <td width="3%">:</td>
                                    <td width="15%">Rp. 2.500.000</td>
                                  </tr>
                                  <tr>
                                    <th width="15%">Department</th>
                                    <td width="4%">:</td>
                                    <td width="15%">Operational</td>
                                    
                                    <th width="15%">Sub Project</th>
                                    <td width="3%">:</td>
                                    <td width="15%">Long Hauling</td>
                                    
                                    <th width="15%">Open Qty</th>
                                    <td width="3%">:</td>
                                    <td width="15%">25</td>
                                  </tr>
                                  <tr>
                                    <th width="15%">Date</th>
                                    <td width="4%">:</td>
                                    <td width="15%">10/28/2022</td>
                                    
                                    <th width="15%">Location</th>
                                    <td width="3%">:</td>
                                    <td width="15%">Sangatta</td>
                                    
                                    <th width="15%">Description</th>
                                    <td width="3%">:</td>
                                    <td width="15%">Request Sparepart</td>
                                  </tr>
                                </tbody>
                              </table>
                            </fieldset>
                            <tab-nav :tabs="true">
                              <tab-nav-items :active="true" id="item-tab" ariaControls="item" role="tab" :ariaSelected="true" title="Item Detail" />
                              <tab-nav-items :active="false" id="vendor-tab" ariaControls="vendor" role="tab" :ariaSelected="false" title="Vendor Info" />
                            </tab-nav>
                            <div id="myTabContent" class="tab-content">
                              <tab-content-item :active="true" id="item">
                                <div class="table-responsive">
                                  <table class="table table-striped">
                                      <thead>
                                        <tr>
                                          <th class="text-center">INVENTORY ID</th>
                                          <th class="text-center">DESCRIPTION ITEM</th>
                                          <th class="text-center">QTY</th>
                                          <th class="text-center">UOM</th>
                                          <th class="text-center">PRICE</th>
                                          <th class="text-center">SUB TOTAL</th>
                                          <th class="text-center">VENDOR INFO</th>
                                          <th class="text-center">VENDOR CONTACT</th>
                                          <th class="text-center">VENDOR LOCATION</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td class="text-center">INV-02101001</td>
                                          <td class="text-center">Engine Oil Filter</td>
                                          <td class="text-center">10</td>
                                          <td class="text-center">PCS</td>
                                          <td class="text-center">225.000</td>
                                          <td class="text-center">2.250.000</td>
                                          <td class="text-center">Utama Production, CV</td>
                                          <td class="text-center">Agung Tri Laksono</td>
                                          <td class="text-center">Kalimantan Selatan</td>
                                        </tr>
                                        
                                        <tr>
                                          <td class="text-center">INV-02101002</td>
                                          <td class="text-center">Hyd Oil Filter Element</td>
                                          <td class="text-center">20</td>
                                          <td class="text-center">PCS</td>
                                          <td class="text-center">225.000</td>
                                          <td class="text-center">2.250.000</td>
                                          <td class="text-center">Utama Production, CV</td>
                                          <td class="text-center">Agung Tri Laksono</td>
                                          <td class="text-center">Kalimantan Selatan</td>
                                        </tr>
                                      </tbody>
                                  </table>
                                </div>
                              </tab-content-item>
                              <tab-content-item :active="false" id="vendor" aria-labelled-by="vendor-tab">
                                <b-row>
                                  <b-col md="12">
                                    <h4>New Vendor ?</h4>
                                    <template v-for="(item,index) in text">
                                      <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-left" :key="index">
                                        <div class="custom-switch-inner">
                                          <p class="mb-0"> {{ item.label }} </p>
                                          <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                          <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                          </label>
                                        </div>
                                      </div>
                                    </template>
                                  </b-col>
                                  <b-col md="6" class="mt-3">
                                    <fieldset class="form-group border p-3">
                                      <legend class="w-auto px-2 h6 text-primary">Vendor Information</legend>
                                      <b-form>
                                        <b-row>
                                          <b-col>
                                            <b-form-group label="Company ID" label-for="companyID">
                                              <b-form-input id="CompanyID" style="height:40px"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Company Name" label-for="companyName">
                                              <b-form-input id="vendorName" style="height:40px"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row>
                                          <b-col>
                                            <b-form-group label="Telepon" label-for="telepon">
                                              <b-form-input id="telepon" style="height:40px"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="WhatsApp Number" label-for="whatsappNumber">
                                              <b-form-input id="whatsappNumber" style="height:40px"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row>
                                          <b-col>
                                            <b-form-group label="Email" label-for="email">
                                              <b-form-input type="email" id="email" style="height:40px"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Website" label-for="website">
                                              <b-form-input id="website" style="height:40px"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                        <b-row>
                                          <b-col>
                                            <b-form-group label="Address" label-for="address">
                                              <b-form-textarea id="address" rows="3"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </b-form>
                                        
                                        <b-form>
                                          
                                        </b-form>
                                    </fieldset>
                                  </b-col>
                                </b-row>
                              </tab-content-item>
                            </div>
                          </b-col>
                        </b-row>
                      </template>
                    </b-sidebar>
  
                    <b-sidebar
                      v-model="addPurchaseRequest"
                      id="addPurchaseRequest"
                      title="ADD INVENTORY"
                      width="60%"
                      backdrop-variant="dark"
                      bg-variant="white"
                      right
                      backdrop
                      shadow>
                      <template #default="{hide}">
                        <b-row class="m-3">
                          <b-col md="12">
                            <fieldset class="form-group border p-3">
                              <legend class="w-auto px-2 h5 text-primary">General Information</legend>
                              <b-row class="pl-3 pr-3">
                                <b-col md="6">
                                  <b-form>
                                    <b-form-group class="row" label-cols-md="4" label="Inventory ID" label-for="inventoryID">
                                      <b-form-input id="orderNumber" style="height:40px" placeholder="<< CREATE INVENTORY ID >>"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Status" label-for="status">
                                      <b-form-select plain v-model="selectStatus" :options="optionStatus" size="lg">
                                        <template v-slot:first>
                                        <b-form-select-option :value="null">Select Status</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Description Item" label-for="descriptionItem">
                                      <b-form-input type="number" id="descriptionItem" style="height:40px"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="UOM" label-for="uom">
                                      <b-form-select plain>
                                        <b-form-select-option>Pcs</b-form-select-option>
                                        <b-form-select-option>Meter</b-form-select-option>
                                        <b-form-select-option>Box</b-form-select-option>
                                        <b-form-select-option>Lusin</b-form-select-option>
                                      </b-form-select>
                                    </b-form-group>
                                  </b-form>
                                </b-col>
  
                                <b-col md="6">
                                  <b-form>
                                    <b-form-group class="row" label-cols-md="4" label="Department" label-for="Department">
                                      <b-form-select plain v-model="selectDepartment" :options="optionDepartment" size="lg">
                                        <template v-slot:first>
                                        <b-form-select-option :value="null">Select Department</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="PIC Name" label-for="picName">
                                      <b-form-input type="text" class="text-right" id="qty" style="height:40px"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Location" label-for="location">
                                      <b-form-input type="text" class="text-right" id="qty" style="height:40px"></b-form-input>
                                    </b-form-group>
                                  </b-form>
                                </b-col>
                              </b-row>
                            </fieldset>
                            <fieldset class="form-group border p-3">
                              <legend class="w-auto px-2 h5 text-primary">Item Default</legend>
                              <b-row>
                                <b-col md="6">
                                  <b-form>
                                    <b-form-group class="row" label-cols-md="4" label="Item Class" label-for="itemClass">
                                      <b-form-select plain v-model="selectItemClass" :options="optionItemClass" size="lg">
                                        <template v-slot:first>
                                        <b-form-select-option :value="null">Select Item Class</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Item Type" label-for="itemType">
                                      <b-form-select plain v-model="selectItemType" :options="optionItemType" size="lg">
                                        <template v-slot:first>
                                        <b-form-select-option :value="null">Select Item Type</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Valuation Method" label-for="ValuationMethod">
                                      <b-form-select plain v-model="selectValuationMethod" :options="optionValuationMethod" size="lg">
                                        <template v-slot:first>
                                        <b-form-select-option :value="null">Select Valuation Method</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                    </b-form-group>
                                  </b-form>
                                </b-col>
                                <b-col md="6">
                                  <b-form>
                                    <b-form-group class="row" label-cols-md="4" label="Tax Category" label-for="taxtCategory">
                                      <b-form-select plain v-model="selecttaxtCategory" :options="optiontaxtCategory" size="lg">
                                        <template v-slot:first>
                                        <b-form-select-option :value="null">Select Tax Category</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Is a Kit" label-for="isKit">
                                      <template v-for="(item,index) in text">
                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-left" :key="index">
                                          <div class="custom-switch-inner">
                                            <p class="mb-0"> {{ item.label }} </p>
                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                            </label>
                                          </div>
                                        </div>
                                      </template>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Warehouse" label-for="WareHouse">
                                      <b-form-select plain v-model="selectWareHouse" :options="optionWareHouse" size="lg">
                                        <template v-slot:first>
                                        <b-form-select-option :value="null">Select Ware House</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                    </b-form-group>
                                  </b-form>
                                </b-col>
                              </b-row>
                            </fieldset>
                            <fieldset class="form-group border p-3">
                              <legend class="w-auto px-2 h5 text-primary">GL Account</legend>
                              <b-row>
                                <b-col md="6">
                                  <b-form>
                                    <b-form-group class="row" label-cols-md="4" label="Inventory Account" label-for="InvAccount">
                                      <b-form-select plain v-model="selectInvAccount" :options="optionInvAccount" size="lg">
                                        <template v-slot:first>
                                        <b-form-select-option :value="null">Select Inventory Account</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Sales Account" label-for="SalesAccount">
                                      <b-form-select plain v-model="selectSalesAccount" :options="optionSalesAccount" size="lg">
                                        <template v-slot:first>
                                        <b-form-select-option :value="null">Select Sales Account</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="COGS Account" label-for="CogsAccount">
                                      <b-form-select plain v-model="selectCogsAccount" :options="optionCogsAccount" size="lg">
                                        <template v-slot:first>
                                        <b-form-select-option :value="null">Select COGS Account</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                    </b-form-group>
                                  </b-form>
                                </b-col>
                              </b-row>
                            </fieldset>
                          </b-col>
                        </b-row>
                      </template>
                    </b-sidebar>
                </template>
            </iq-card>
        </b-col>
      </b-row>
    </b-container>
  </template>
  <script>
  import { xray } from '../../config/pluginInit'
  export default {
    name: 'procurementPurchaseRequest',
    mounted () {
      xray.index()
    },
    data () {
        return {
          // Sliding Page
          pageDetailSalesOrder: false,
          addPurchaseRequest: false,
  
          selectInvType: null,
          optionInvType: [
              { value: '1', text: 'Finish Good' },
              { value: '2', text: 'Component Part' },
              { value: '3', text: 'Raw Material' }
          ],
          selectStatus: null,
          optionStatus: [
              { value: '1', text: 'Active' },
              { value: '2', text: 'InActive' },
              { value: '3', text: 'No Sales' },
              { value: '4', text: 'No Purchase' },
              { value: '5', text: 'No Request' }
          ],
          selectDepartment: null,
          optionDepartment: [
              { value: '1', text: 'Agency Asam-Asam' },
              { value: '2', text: 'Technical' },
              { value: '3', text: 'Operational' },
              { value: '4', text: 'HSE' },
              { value: '5', text: 'Crewing' }
          ],
          // Checklist
          text: [
            {
              activeLabel: 'Yes',
              inactiveLabel: 'No',
              class: 'primary',
              checked: false
            }
          ]
        }
    }
  }
  </script>
  