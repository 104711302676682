var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "container",
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c('router-view')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }