<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="true"
                  href="#profile"
                  title="Update Profile"
                />
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="false"
                  href="#changePassword"
                  title="Change Username / Password"
                />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <tab-content-item :active="true" id="profile">
        <form @submit.prevent="onUpdateProfile">
          <b-row>
            <b-col lg="3">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">Edit User</h4>
                </template>
                <template v-slot:body>
                  <b-form-group>
                    <div class="add-img-user profile-img-edit">
                      <b-img
                        class="profile-pic height-150 width-150"
                        fluid
                        :src="user.photo | checkPhoto(require('@src/assets/images/user/01.jpg'))"
                        :alt="user.name"
                      />
                      <input type="hidden" v-model="user.photo" />
                      <div class="p-image">
                        <b-button
                          variant="none"
                          class="upload-button iq-bg-primary position-relative"
                        >
                          <input
                            type="file"
                            @change="previewImage"
                            class="h-100 position-absolute"
                            accept="image/png, image/jpeg"
                            style="opacity: 0"
                          />
                          Change Pict
                        </b-button>
                      </div>
                    </div>
                    <div class="img-extension mt-3">
                      <div class="d-inline-block align-items-center">
                        <span>Only</span>
                        <b-link href="javascript:void(0);">.jpg</b-link>
                        <b-link href="javascript:void(0);">.png</b-link>
                        <b-link href="javascript:void(0);">.jpeg</b-link>
                        <span>allowed</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group label="Email:" label-for="uname">
                    <ValidationProvider
                      name="Email"
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="user.email"
                        type="text"
                        placeholder="Email"
                        :class="errors.length > 0 ? ' is-invalid' : ''"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </template>
              </iq-card>
            </b-col>
            <b-col lg="9">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">Edit User Information</h4>
                </template>
                <template v-slot:body>
                  <div class="new-user-info">
                    <b-row>
                      <b-form-group
                        class="col-md-6"
                        label="Name:"
                        label-for="name"
                      >
                        <ValidationProvider
                          name="Name"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            v-model="user.name"
                            type="text"
                            placeholder="Name"
                            :class="errors.length > 0 ? ' is-invalid' : ''"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group
                        class="col-md-6"
                        label="Mobile Number:"
                        label-for="mobno"
                      >
                        <ValidationProvider
                          name="Mobile Number"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            v-model="user.phone"
                            type="text"
                            placeholder="Mobile Number"
                            :class="errors.length > 0 ? ' is-invalid' : ''"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group
                        class="col-sm-6"
                        label-for="gender"
                        label="Gender:"
                      >
                        <b-form-select
                          v-model="user.gender"
                          plain
                          :options="genders"
                          id="gender"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="null"
                              >Select Gender</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>
                      <b-form-group
                        class="col-md-6"
                        label="Birthday:"
                        label-for="birthday"
                      >
                        <b-form-input
                          type="date"
                          id="birthday"
                          v-model="user.birthday"
                        ></b-form-input>
                        <small style="float: right"
                          >click <i class="fa fa-calendar"></i> to show</small
                        >
                      </b-form-group>
                      <b-form-group
                        class="col-md-12"
                        label="Street Address:"
                        label-for="add1"
                      >
                        <b-form-textarea
                          v-model="user.address"
                          name="add1"
                          id="add1"
                          placeholder="Street Address"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-row>
                    <hr />
                    <div style="text-align: right; margin-top: 50px">
                      <router-link to="/me/my-profile">
                        <b-button variant="secondary" type="submit"
                          >Cancel</b-button
                        >
                      </router-link>
                      <b-button class="ml-2" variant="primary" type="submit">
                        <template v-if="!loading"> Update Profile </template>
                        <template v-else>
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Loading...</span>
                        </template>
                      </b-button>
                    </div>
                  </div>
                </template>
              </iq-card>
            </b-col>
          </b-row>
        </form>
      </tab-content-item>

      <tab-content-item :active="false" id="changePassword">
        <form>
          <b-row>
            <b-col lg="4">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">Change Username</h4>
                </template>
                <template v-slot:body>
                  <b-form-group label="Username:" label-for="uname">
                    <ValidationProvider
                      name="Username"
                      rules="required|max:15"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="user.username"
                        type="text"
                        placeholder="User Name"
                        :class="errors.length > 0 ? ' is-invalid' : ''"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </template>
                <template v-slot:footer>
                  <b-row>
                    <b-col lg="12" class="text-right">
                      <b-button variant="primary" type="button" @click.prevent="onChangeUsername">
                        <template v-if="!loading_username"> Change Username </template>
                        <template v-else>
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Loading...</span>
                        </template>
                      </b-button>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-col>
            <b-col lg="8">
              <iq-card>
                <template v-slot:headerTitle>
                  <h4 class="card-title">Change Password</h4>
                </template>
                <template v-slot:body>
                  <b-row>
                    <b-col lg="4">
                      <b-form-group label="Old Password:" label-for="uname">
                        <ValidationProvider
                          name="Old Password"
                          rules="required|min:6"
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            v-model="change_password.oldPassword"
                            type="password"
                            placeholder="Old Password"
                            :class="errors.length > 0 ? ' is-invalid' : ''"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group label="New Password:" label-for="uname">
                        <ValidationProvider
                          name="New Password"
                          rules="required|min:6"
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            v-model="change_password.newPassword"
                            type="password"
                            placeholder="New Password"
                            :class="errors.length > 0 ? ' is-invalid' : ''"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                    <b-col lg="4">
                      <b-form-group
                        label="Confirm New Password:"
                        label-for="uname"
                      >
                        <ValidationProvider
                          name="Confirm New Password"
                          rules="required|min:6"
                          v-slot="{ errors }"
                        >
                          <b-form-input
                            v-model="change_password.confirmNewPassword"
                            type="password"
                            placeholder="Confirm New Password"
                            :class="errors.length > 0 ? ' is-invalid' : ''"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </template>
                <template v-slot:footer>
                  <b-row>
                    <b-col lg="12" class="text-right">
                      <b-button variant="primary" type="button" @click.prevent="onChangePassword">
                        <template v-if="!loading_password"> Change Password </template>
                        <template v-else>
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span class="sr-only">Loading...</span>
                        </template>
                      </b-button>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-col>
          </b-row>
        </form>
      </tab-content-item>
    </div>
  </b-container>
</template>
<script>
import Compress from "compress.js";
import { mapGetters, mapActions } from "vuex";
import { xray } from "../../config/pluginInit";

export default {
  name: "BlankPage",
  mounted() {
    xray.index();
  },
  data() {
    return {
      user: {},
      change_password: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      },
      loading: false,
      loading_username: false,
      loading_password: false,
      loading_upload: false,
      genders: [
        { value: "MALE", text: "Male" },
        { value: "FEMALE", text: "Female" },
      ],
    };
  },
  computed: {
    ...mapGetters("Auth", ["profile"]),
  },
  methods: {
    ...mapActions("Auth", ["getProfile", "updateProfile", "updatePhoto", "updateUsername", "updatePassword"]),
    ...mapActions("File", ["upload"]),
    async onUpdateProfile() {
      this.loading = true;
      const update_profile = {
        email: this.user.email,
        name: this.user.name,
        phone: this.user.phone,
        gender: this.user.gender,
        birthday: this.$options.filters.parseDate(
          this.user.birthday,
          "DD-MM-YYYY"
        ),
        address: this.user.address,
      };

      let res = await this.updateProfile(update_profile);
      if (res.status == "success") {
        this.loading = false;
        this.getProfile();
        this.$swal(
          "Profile Updated!",
          "Your profile data has been updated successfully.",
          "success"
        );
        this.$router.push({ name: "me.myProfile" });
      } else {
        this.loading = false;
        if (res.data.message != null)
          this.$swal("Error", res.data.message, "error");
        else
          this.$swal(
            "Error",
            "Something wrong with the server. Please try again later.",
            "error"
          );
      }
    },
    async onChangeUsername() {
      this.loading_username = true;
      var urlencoded = new URLSearchParams();
      urlencoded.append("username", this.user.username);
      let res = await this.updateUsername(urlencoded);
      if (res.status == "success") {
        this.loading_username = false;
        this.getProfile();
        this.$swal(
          "Username Updated!",
          "Your username has been updated successfully.",
          "success"
        );
        this.$router.push({ name: "me.myProfile" });
      } else {
        this.loading_username = false;
        if (res.data.message != null)
          this.$swal("Error", res.data.message, "error");
        else
          this.$swal(
            "Error",
            "Something wrong with the server. Please try again later.",
            "error"
          );
      }
    },
    async uploadPhoto(photo) {
      let formData = new FormData();
      formData.append("file", photo);

      let res = await this.upload(formData);
      if (res.status == "success") {
        let res_photo = await this.updatePhoto({ photoName: res.data.fileName });
        if (res_photo.status == "success") {
          this.loading_upload = false;
          this.$swal("Photo updated!", "Your profile photo uploaded and updated successfully.", "success");
          this.$router.push({ name: "me.myProfile" });
        } else {
          this.loading_upload = false;
          if (res_photo.data.message != null)
            this.$swal("Error", res_photo.data.message, "error");
          else
            this.$swal("Error", "Something wrong with the server. Please try again later.", "error");
        }
      } else {
        this.loading_upload = false;
        if (res.data.message != null)
          this.$swal("Error", res.data.message, "error");
        else
          this.$swal("Error", "Something wrong with the server. Please try again later.", "error");
      }
    },
    async onChangePassword() {
      this.loading_password = true;

      if(this.change_password.newPassword == this.change_password.confirmNewPassword) {
        let data = {
          oldPassword: this.change_password.oldPassword,
          newPassword: this.change_password.newPassword
        };

        let res = await this.updatePassword(data);
        if (res.status == "success") {
          this.loading_password = false;
          this.$swal(
            "Password Updated!",
            "Your may use your new password while re-login later.",
            "success"
          );
          this.$router.push({ name: "me.myProfile" });
        } else {
          this.loading_password = false;
          if (res.data.message != null)
            this.$swal("Error", res.data.message, "error");
          else
            this.$swal(
              "Error",
              "Something wrong with the server. Please try again later.",
              "error"
            );
        }
      } else {
        this.loading_password = false;
        this.$swal(
          "oops",
          "New Password and Confirm password does not match.",
          "error"
        );
      }
    },
    previewImage: function (event) {
      this.loading_upload = true;
      const files = [...event.target.files]
      const compress = new Compress()

      const file_name = files[0].name;
      const type = files[0].type;

      compress.compress(files, {
        size: 2, // the max size in MB, defaults to 2MB
        quality: .5, // the quality of the image, max is 1,
        maxWidth: 1920, // the max width of the output image, defaults to 1920px
        maxHeight: 1920, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
      }).then((data) => {
        // returns an array of compressed images
        const img1 = data[0];
        const base64str = img1.data;
        const imgExt = img1.ext;
        const compressed_photo = Compress.convertBase64ToFile(base64str, imgExt);

        const reader = new FileReader();
        reader.onload = (e) => {
          this.user.photo = e.target.result;
        };
        reader.readAsDataURL(compressed_photo);

        let theBlob = new File([compressed_photo], file_name, { lastModified: new Date().getTime(), type });
        this.uploadPhoto(theBlob);
      });
    },
    itemClick(node) {
      console.log(node.model.text + " clicked !");
    },
    resetPassword() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You want change this account password ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, I'M Sure",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              "Sent!",
              "Please, Check Your Email. Password has been sent to your email",
              "success"
            );
          }
        });
    },
  },
  mounted() {
    const user = { ...this.profile };

    this.user = {
      email: user.email,
      username: user.username,
      name: user.userDetail.name,
      phone: user.userDetail.phone,
      photo: user.userDetail.photo,
      gender: user.userDetail.gender,
      birthday: this.$options.filters.parseDate(user.userDetail.birthday),
      address: user.userDetail.address,
    };
  },
};
</script>
