var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Breadcrumb")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-1",
            modifiers: {
              "collapse-1": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Use the items in order to programatically generate the breadcrumb links.use class "), _c('code', [_vm._v(".breadcrumb to ol")])]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-1"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "breadcrumb-1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-breadcrumb :items=\"items1\" />\n<b-breadcrumb :items=\"items2\" />\n<b-breadcrumb :items=\"items3\" />\n\n<script>\nexport default {\n  data() {\n    return {\n      items1: [\n        {\n          text: 'Home',\n          active: true\n        }\n      ],\n      items2: [\n        {\n          text: 'Home',\n          href: '#'\n        },\n        {\n          text: 'Library',\n          active: true\n        }\n      ],\n      items3: [\n        {\n          text: 'Home',\n          href: '#'\n        },\n        {\n          text: 'Library',\n          href: '#'\n        },\n        {\n          text: 'Data',\n          active: true\n        }\n      ]\n    }\n  }\n}\n")])])])])]), _c('b-breadcrumb', {
          attrs: {
            "items": _vm.items1
          }
        }), _c('b-breadcrumb', {
          attrs: {
            "items": _vm.items2
          }
        }), _c('b-breadcrumb', {
          attrs: {
            "items": _vm.items3
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Breadcrumb With Icon")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-2",
            modifiers: {
              "collapse-2": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Use the items in order to programatically generate the breadcrumb links.use class "), _c('code', [_vm._v(".breadcrumb to ol")]), _vm._v(" with Icon")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-2"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "breadcrumb-icon-1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-breadcrumb :items=\"itemsIcon1\" />\n<b-breadcrumb :items=\"itemsIcon2\" />\n<b-breadcrumb :items=\"itemsIcon3\" />\n\n<script>\nexport default {\n  data() {\n    return {\n      itemsIcon1: [\n        {\n          html: '<i class=\"ri-home-4-line mr-1 float-left\"></i>Home',\n          active: true\n        }\n      ],\n      itemsIcon2: [\n        {\n          html: '<i class=\"ri-home-4-line mr-1 float-left\"></i>Home',\n          href: '#'\n        },\n        {\n          text: 'Library',\n          active: true\n        }\n      ],\n      itemsIcon3: [\n        {\n          html: '<i class=\"ri-home-4-line mr-1 float-left\"></i>Home',\n          href: '#'\n        },\n        {\n          text: 'Library',\n          href: '#'\n        },\n        {\n          text: 'Data',\n          active: true\n        }\n      ]\n    }\n  }\n}\n")])])])])]), _c('b-breadcrumb', {
          attrs: {
            "items": _vm.itemsIcon1
          }
        }), _c('b-breadcrumb', {
          attrs: {
            "items": _vm.itemsIcon2
          }
        }), _c('b-breadcrumb', {
          attrs: {
            "items": _vm.itemsIcon3
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Breadcrumb")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-3",
            modifiers: {
              "collapse-3": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("use class "), _c('code', [_vm._v(".breadcrumb .bg-primary")])]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-3"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "breadcrumb-2"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<nav aria-label=\"breadcrumb \">\n  <ol class=\"breadcrumb bg-primary\">\n    <li class=\"breadcrumb-item active text-white\" aria-current=\"page\">Home</li>\n  </ol>\n</nav>\n<nav aria-label=\"breadcrumb\">\n  <ol class=\"breadcrumb bg-primary\">\n    <li class=\"breadcrumb-item\"><a href=\"#\" class=\"text-white\">Home</a></li>\n    <li class=\"breadcrumb-item active text-white\" aria-current=\"page\">Library</li>\n  </ol>\n</nav>\n<nav aria-label=\"breadcrumb\">\n  <ol class=\"breadcrumb bg-primary mb-0\">\n    <li class=\"breadcrumb-item\"><a href=\"#\" class=\"text-white\">Home</a></li>\n    <li class=\"breadcrumb-item\"><a href=\"#\" class=\"text-white\">Library</a></li>\n    <li class=\"breadcrumb-item active text-white\" aria-current=\"page\">Data</li>\n  </ol>\n</nav>\n")])])])])]), _c('nav', {
          attrs: {
            "aria-label": "breadcrumb "
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary"
        }, [_c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("Home")])])]), _c('nav', {
          attrs: {
            "aria-label": "breadcrumb"
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary"
        }, [_c('li', {
          staticClass: "breadcrumb-item"
        }, [_c('a', {
          staticClass: "text-white",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Home")])]), _c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("Library")])])]), _c('nav', {
          attrs: {
            "aria-label": "breadcrumb"
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary mb-0"
        }, [_c('li', {
          staticClass: "breadcrumb-item"
        }, [_c('a', {
          staticClass: "text-white",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Home")])]), _c('li', {
          staticClass: "breadcrumb-item"
        }, [_c('a', {
          staticClass: "text-white",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Library")])]), _c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("Data")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Breadcrumb With Icon")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-4",
            modifiers: {
              "collapse-4": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("use class "), _c('code', [_vm._v(".breadcrumb .bg-primary ")]), _vm._v(" With Icon.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-4"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "breadcrumb-icon-2"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<nav aria-label=\"breadcrumb\">\n  <ol class=\"breadcrumb bg-primary\">\n    <li class=\"breadcrumb-item active text-white\" aria-current=\"page\"><i class=\"ri-home-4-line mr-1 float-left\"></i>Home</li>\n  </ol>\n</nav>\n<nav aria-label=\"breadcrumb\">\n  <ol class=\"breadcrumb bg-primary\">\n    <li class=\"breadcrumb-item\"><a href=\"#\" class=\"text-white\"><i class=\"ri-home-4-line mr-1 float-left\"></i>Home</a></li>\n    <li class=\"breadcrumb-item active text-white\" aria-current=\"page\">Library</li>\n  </ol>\n</nav>\n<nav aria-label=\"breadcrumb\">\n  <ol class=\"breadcrumb bg-primary mb-0\">\n    <li class=\"breadcrumb-item\"><a href=\"#\" class=\"text-white\"><i class=\"ri-home-4-line mr-1 float-left\"></i>Home</a></li>\n    <li class=\"breadcrumb-item\"><a href=\"#\" class=\"text-white\">Library</a></li>\n    <li class=\"breadcrumb-item active text-white\" aria-current=\"page\">Data</li>\n  </ol>\n</nav>\n")])])])])]), _c('nav', {
          attrs: {
            "aria-label": "breadcrumb"
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary"
        }, [_c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_c('i', {
          staticClass: "ri-home-4-line mr-1 float-left"
        }), _vm._v("Home")])])]), _c('nav', {
          attrs: {
            "aria-label": "breadcrumb"
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary"
        }, [_c('li', {
          staticClass: "breadcrumb-item"
        }, [_c('a', {
          staticClass: "text-white",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-home-4-line mr-1 float-left"
        }), _vm._v("Home")])]), _c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("Library")])])]), _c('nav', {
          attrs: {
            "aria-label": "breadcrumb"
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary mb-0"
        }, [_c('li', {
          staticClass: "breadcrumb-item"
        }, [_c('a', {
          staticClass: "text-white",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-home-4-line mr-1 float-left"
        }), _vm._v("Home")])]), _c('li', {
          staticClass: "breadcrumb-item"
        }, [_c('a', {
          staticClass: "text-white",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Library")])]), _c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("Data")])])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Breadcrumb With Icon")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-5",
            modifiers: {
              "collapse-5": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("use class "), _c('code', [_vm._v(".breadcrumb .iq-bg-primary")])]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-5"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "breadcrumb-icon-3"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-breadcrumb class=\"iq-bg-primary\" :items=\"itemsIcon1\" />\n<b-breadcrumb class=\"iq-bg-primary\" :items=\"itemsIcon2\" />\n<b-breadcrumb class=\"iq-bg-primary\" :items=\"itemsIcon3\" />\n\n<script>\nexport default {\n  data() {\n    return {\n      itemsIcon1: [\n        {\n          html: '<i class=\"ri-home-4-line mr-1 float-left\"></i>Home',\n          active: true\n        }\n      ],\n      itemsIcon2: [\n        {\n          html: '<i class=\"ri-home-4-line mr-1 float-left\"></i>Home',\n          href: '#'\n        },\n        {\n          text: 'Library',\n          active: true\n        }\n      ],\n      itemsIcon3: [\n        {\n          html: '<i class=\"ri-home-4-line mr-1 float-left\"></i>Home',\n          href: '#'\n        },\n        {\n          text: 'Library',\n          href: '#'\n        },\n        {\n          text: 'Data',\n          active: true\n        }\n      ]\n    }\n  }\n}\n")])])])])]), _c('b-breadcrumb', {
          staticClass: "iq-bg-primary",
          attrs: {
            "items": _vm.itemsIcon1
          }
        }), _c('b-breadcrumb', {
          staticClass: "iq-bg-primary",
          attrs: {
            "items": _vm.itemsIcon2
          }
        }), _c('b-breadcrumb', {
          staticClass: "iq-bg-primary mb-0",
          attrs: {
            "items": _vm.itemsIcon3
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Breadcrumb With Icon")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-6",
            modifiers: {
              "collapse-6": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("use class "), _c('code', [_vm._v(".breadcrumb .iq-bg-danger")])]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-6"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "breadcrumb-icon-4"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<nav aria-label=\"breadcrumb\">\n    <ol class=\"breadcrumb iq-bg-danger\">\n      <li class=\"breadcrumb-item active\" aria-current=\"page\"><i class=\"ri-home-4-line mr-1 float-left\"></i>Home</li>\n    </ol>\n  </nav>\n  <nav aria-label=\"breadcrumb\">\n    <ol class=\"breadcrumb iq-bg-danger\">\n      <li class=\"breadcrumb-item\"><a href=\"#\" class=\"text-danger\"><i class=\"ri-home-4-line mr-1 float-left\"></i>Home</a></li>\n      <li class=\"breadcrumb-item active\" aria-current=\"page\">Library</li>\n    </ol>\n  </nav>\n  <nav aria-label=\"breadcrumb\">\n    <ol class=\"breadcrumb iq-bg-danger mb-0\">\n      <li class=\"breadcrumb-item\"><a href=\"#\" class=\"text-danger\"><i class=\"ri-home-4-line mr-1 float-left\"></i>Home</a></li>\n      <li class=\"breadcrumb-item\"><a href=\"#\" class=\"text-danger\">Library</a></li>\n      <li class=\"breadcrumb-item active\" aria-current=\"page\">Data</li>\n    </ol>\n  </nav>\n")])])])])]), _c('nav', {
          attrs: {
            "aria-label": "breadcrumb"
          }
        }, [_c('ol', {
          staticClass: "breadcrumb iq-bg-danger"
        }, [_c('li', {
          staticClass: "breadcrumb-item active",
          attrs: {
            "aria-current": "page"
          }
        }, [_c('i', {
          staticClass: "ri-home-4-line mr-1 float-left"
        }), _vm._v("Home")])])]), _c('nav', {
          attrs: {
            "aria-label": "breadcrumb"
          }
        }, [_c('ol', {
          staticClass: "breadcrumb iq-bg-danger"
        }, [_c('li', {
          staticClass: "breadcrumb-item"
        }, [_c('a', {
          staticClass: "text-danger",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-home-4-line mr-1 float-left"
        }), _vm._v("Home")])]), _c('li', {
          staticClass: "breadcrumb-item active",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("Library")])])]), _c('nav', {
          attrs: {
            "aria-label": "breadcrumb"
          }
        }, [_c('ol', {
          staticClass: "breadcrumb iq-bg-danger mb-0"
        }, [_c('li', {
          staticClass: "breadcrumb-item"
        }, [_c('a', {
          staticClass: "text-danger",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-home-4-line mr-1 float-left"
        }), _vm._v("Home")])]), _c('li', {
          staticClass: "breadcrumb-item"
        }, [_c('a', {
          staticClass: "text-danger",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Library")])]), _c('li', {
          staticClass: "breadcrumb-item active",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("Data")])])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }