var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('draggable', {
    staticClass: "dragArea",
    attrs: {
      "tag": "ul",
      "list": _vm.tasks,
      "group": {
        name: 'g1'
      }
    }
  }, _vm._l(_vm.tasks, function (el) {
    return _c('li', {
      key: el.name
    }, [_c('p', [_vm._v(_vm._s(el.name))]), _c('nested-draggable', {
      attrs: {
        "tasks": el.tasks
      }
    })], 1);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }