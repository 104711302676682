var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Remixicon Icon")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Use Class "), _c('code', [_vm._v("<i class=\"ri-4k-fill\"></i>")])]), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-4k-fill"
        }), _vm._v("4k Fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-4k-line"
        }), _vm._v("4k line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-account-box-fill"
        }), _vm._v("Account Fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-account-pin-box-line"
        }), _vm._v("account-pin-box")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-award-line"
        }), _vm._v("award-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-award-fill"
        }), _vm._v("award-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-bookmark-line"
        }), _vm._v("bookmark-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-bookmark-fill"
        }), _vm._v("bookmark-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-calculator-line"
        }), _vm._v("calculator-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-calculator-fill"
        }), _vm._v("calculator-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-calendar-line"
        }), _vm._v("calendar-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-calendar-fill"
        }), _vm._v("calendar-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-reply-line"
        }), _vm._v("reply-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-reply-fill"
        }), _vm._v("reply-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-send-plane-line"
        }), _vm._v("send-plane-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-send-plane-fill"
        }), _vm._v("send-plane-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-computer-line"
        }), _vm._v("computer-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-computer-fill"
        }), _vm._v("computer-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-cellphone-line"
        }), _vm._v("cellphone-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-cellphone-fill"
        }), _vm._v("cellphone-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-phone-line"
        }), _vm._v("phone-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-phone-fill"
        }), _vm._v("phone-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-tablet-line"
        }), _vm._v("tablet-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-tablet-fill"
        }), _vm._v("tablet-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-device-line"
        }), _vm._v("device-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-device-fill"
        }), _vm._v("device-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-battery-line"
        }), _vm._v("battery-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-battery-fill"
        }), _vm._v("battery-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-battery-low-line"
        }), _vm._v("battery-low-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-battery-low-fill"
        }), _vm._v("battery-low-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-file-line"
        }), _vm._v("file-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-file-fill"
        }), _vm._v("file-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-book-open-line"
        }), _vm._v("book-open-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-book-open-fill"
        }), _vm._v("book-open-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-lightbulb-line"
        }), _vm._v("lightbulb-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-lightbulb-fill"
        }), _vm._v("lightbulb-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-map-pin-line"
        }), _vm._v("map-pin-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-map-pin-fill"
        }), _vm._v("map-pin-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-drop-line"
        }), _vm._v("drop-line")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-drop-fill"
        }), _vm._v("drop-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-drop-fill"
        }), _vm._v("drop-fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-drop-fill"
        }), _vm._v("drop-fill")])], 1), _c('b-col', {
          staticClass: "text-center mt-3",
          attrs: {
            "sm": "12"
          }
        }, [_c('b-link', {
          staticClass: "btn btn-primary",
          attrs: {
            "href": "https://remixicon.com/",
            "target": "blank"
          }
        }, [_vm._v("View All Icon")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }