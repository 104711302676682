<template>
    <b-container fluid>
      <b-row>
        <b-col lg="12">
            <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>PURCHASE ORDER</strong></h5>
                </template>
                <template v-slot:headerAction>
                <a class="text-primary float-right ml-3" v-b-toggle.filter role="button" style="cursor: pointer">
                <i class="fa fa-filter" />
                </a>
                </template>
                <template v-slot:body>
                  <b-collapse visible id="filter" class="mb-4">
                    <b-row>
                      <b-col md="2">
                          <b-form-select plain v-model="selectRequestClass" :options="optionRequestClass" size="sm">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Request Class</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-col>
                      <b-col md="2">
                          <b-form-select plain v-model="selectStatusPurchaseRequest" :options="optionStatusPurchaseRequest" size="sm">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Status</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-col>
                      <b-col md="2">
                          <b-form-select plain v-model="selectPriority" :options="optionPriority" size="sm">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Priority</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-col>
                      <b-col md="3">
                          <b-form-select plain v-model="selectCompany" :options="optionCompany" size="sm">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Company</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-col>
                      <b-col md="1">
                          <b-button variant="primary" size="sm"><i class="fa fa-search"></i></b-button>
                      </b-col>
                      <b-col md="2">
                          <b-button v-b-tooltip.top="'Add Data'" @click="addPurchaseOrder = !addPurchaseOrder;" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-plus"></i></b-button>
                          <b-button v-b-tooltip.top="'Refresh Data'" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-refresh"></i></b-button>

                          <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                              <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                  <i class="fa fa-save m-0"></i>
                                  </span>
                              </template>
                              <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
                              <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
                          </b-dropdown>
                      </b-col>
                    </b-row>
                  </b-collapse>
                  <table class="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th class="text-center">ORDER NUMBER</th>
                        <th class="text-center">ORDER TYPE</th>
                        <th class="text-center">STATUS</th>
                        <th class="text-center">DATE</th>
                        <th class="text-center">VENDOR NAME</th>
                        <th class="text-center">QTY</th>
                        <th class="text-center">TOTAL</th>
                        <th class="text-center">REQ. NUMBER</th>
                        <th class="text-center">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center"><a href="#" @click="detailPurchaseItem = !detailPurchaseItem;">PO0001</a></td>
                        <td class="text-center"><b-badge variant="primary w-100">Request</b-badge></td>
                        <td class="text-center"><b-badge variant="success w-100">Open</b-badge></td>
                        <td class="text-center">10/28/2022</td>
                        <td class="text-center">Vehement Capital Maritim</td>
                        <td class="text-center">10 Pcs</td>
                        <td class="text-center">1.524.000</td>
                        <td class="text-center"><a href="#" @click="detailPurchaseItem = !detailPurchaseItem;">REQ0001</a></td>
                        <td class="text-center">
                            <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                              <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                  <i class="fa fa-list-alt m-0 text-primary"></i>
                                  </span>
                              </template>
                              <b-dropdown-item href="#" variant="primary"><i class="fa fa-envelope mr-2"></i>Send Email</b-dropdown-item>
                              <b-dropdown-item href="../../assets/images/PURCHASE-DOC.pdf" variant="primary"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
                              <b-dropdown-item href="#" variant="primary"><i class="fa fa-edit mr-2"></i>Update</b-dropdown-item>
                              <b-dropdown-item href="#" variant="primary"><i class="fa fa-trash mr-2"></i>Delete</b-dropdown-item>
                            </b-dropdown>
                        </td>
                      </tr>
                    </tbody>
                </table>
                  <div class="mt-3">
                      <b-pagination :value="1" :total-rows="50" align="center" />
                  </div>
  
                  <b-sidebar
                    v-model="detailPurchaseItem"
                    id="detail_vendor"
                    title="PO001 - TUGBOAT 1001"
                    width="80%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h6 text-primary">General Information</legend>
                            <table class="table table-striped mt-2">
                              <tbody>
                                <tr>
                                  <th width="12%">Order Number</th>
                                  <td width="4%">:</td>
                                  <td width="18%">REQ10001</td>
                                  
                                  <th width="12%">Company</th>
                                  <td width="3%">:</td>
                                  <td width="18%">Vehement Capital</td>
                                  
                                  <th width="12%">Priority</th>
                                  <td width="3%">:</td>
                                  <td width="18%"><b-badge variant="primary">Normal</b-badge></td>
                                </tr>
                                <tr>
                                  <th width="12%">Request Class</th>
                                  <td width="4%">:</td>
                                  <td width="18%">PURCHASE</td>
                                  
                                  <th width="12%">Department</th>
                                  <td width="3%">:</td>
                                  <td width="18%">Technical Sangatta</td>
                                  
                                  <th width="12%">Location</th>
                                  <td width="3%">:</td>
                                  <td width="18%">Sangatta</td>
                                </tr>
                                <tr>
                                  <th width="12%">Vendor</th>
                                  <td width="4%">:</td>
                                  <td width="18%">Utama Production, CV</td>
                                  
                                  <th width="12%">Fleet</th>
                                  <td width="3%">:</td>
                                  <td width="18%">TUGBOAT 1001</td>
                                  
                                  <th width="12%">Request By</th>
                                  <td width="3%">:</td>
                                  <td width="18%">Nurul Akbar</td>
                                </tr>
                                <tr>
                                  <th width="12%">Order Date</th>
                                  <td width="4%">:</td>
                                  <td width="18%">03/11/2022</td>
                                  
                                  <th width="12%">Project</th>
                                  <td width="3%">:</td>
                                  <td width="18%">COAL PROJECT</td>
                                  
                                  <th width="12%">Order By</th>
                                  <td width="3%">:</td>
                                  <td width="18%">Agung Tri Laksono</td>
                                </tr>
                                <tr>
                                  <th width="12%">Promise Date</th>
                                  <td width="4%">:</td>
                                  <td width="15%">04/11/2022</td>
                                  
                                  <th width="12%">Sub Project</th>
                                  <td width="3%">:</td>
                                  <td width="15%">Long Hauling</td>
                                  
                                  <th width="12%">Description</th>
                                  <td width="3%">:</td>
                                  <td width="15%">Request Sparepart for TUGBOAT 1001</td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <tab-nav :tabs="true">
                            <tab-nav-items :active="true" id="item-tab" ariaControls="item" role="tab" :ariaSelected="true" title="Item Detail" />
                            <tab-nav-items :active="false" id="vendor-tab" ariaControls="vendor" role="tab" :ariaSelected="false" title="Vendor Info" />
                          </tab-nav>
                          <div id="myTabContent" class="tab-content">
                            <tab-content-item :active="true" id="item">
                              <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                      <tr>
                                        <th class="text-center">INVENTORY ID</th>
                                        <th class="text-center">DESCRIPTION ITEM</th>
                                        <th class="text-center">QTY</th>
                                        <th class="text-center">UOM</th>
                                        <th class="text-center">PRICE</th>
                                        <th class="text-center">SUB TOTAL</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td class="text-center">[M001] - Engine Oil Filter</td>
                                        <td class="text-center">Engine Oil Filter</td>
                                        <td class="text-center">10</td>
                                        <td class="text-center">PCS</td>
                                        <td class="text-center">225.000</td>
                                        <td class="text-center">2.250.000</td>
                                      </tr>
                                    </tbody>
                                </table>
                              </div>
                            </tab-content-item>
                            <tab-content-item :active="false" id="vendor" aria-labelled-by="vendor-tab">
                              <fieldset class="form-group border p-3">
                                <legend class="w-auto px-2 h6 text-primary">Utama Maritim, CV</legend>
                                <b-row>
                                  <b-col md="8">
                                    <table class="table table-borderless table-sm">
                                      <tbody>
                                        <tr>
                                          <th>Address</th>
                                          <td>Jalan H. Fachrudin No.36 </td>
                                          <th>Phone</th>
                                          <td>021 – 326 632</td>
                                        </tr>
                                        <tr>
                                          <th>&nbsp;</th>
                                          <td>Jakarta Pusat, Indonesia</td>
                                          <th>Ponsel / WA</th>
                                          <td>081212143758</td>
                                        </tr>
                                        <tr>
                                          <th>Post Code</th>
                                          <td>12310</td>
                                          <th>Email</th>
                                          <td>info@gmail.com</td>
                                        </tr>
                                        <tr>
                                          <th>Tax ID</th>
                                          <td>31391734</td>
                                          <th>Website</th>
                                          <td>www.utamamaritim.com</td>
                                        </tr>
                                        <tr>
                                          <th>PKP ID</th>
                                          <td>427424268</td>
                                          <th>&nbsp;</th>
                                          <td>&nbsp;</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </b-col>
                                  <b-col md="12" class="mt-3">
                                    <tab-nav :tabs="true">
                                      <tab-nav-items :active="true" id="first_tab" ariaControls="tab_first" role="tab" :ariaSelected="true" title="Additional Contact" />
                                      <tab-nav-items :active="false" id="second_tab" ariaControls="tab_second" role="tab" :ariaSelected="false" title="Sales & Purchase" />
                                      <tab-nav-items :active="false" id="third_tab" ariaControls="tab_third" role="tab" :ariaSelected="false" title="Accounting" />
                                      <tab-nav-items :active="false" id="fourth_tab" ariaControls="tab_fourth" role="tab" :ariaSelected="false" title="Internal Notes" />
                                    </tab-nav>
                                    <div id="myTabContent" class="tab-content">
                                      <tab-content-item :active="true" id="tab_first">
                                        <b-row>
                                          <b-col md="8">
                                            <table class="table table-striped table-sm">
                                              <tbody>
                                                <tr>
                                                  <th class="pl-3">Nama</th>
                                                  <td>Nurul Akbar</td>
                                                  <th>Phone</th>
                                                  <td>021 – 326 632</td>
                                                </tr>
                                                <tr>
                                                  <th class="pl-3">Address</th>
                                                  <td>Jalan H. Fachrudin No.36 </td>
                                                  <th>Ponsel</th>
                                                  <td>081212143758</td>
                                                </tr>
                                                <tr>
                                                  <th class="pl-3">&nbsp;</th>
                                                  <td>&nbsp;</td>
                                                  <th>Email</th>
                                                  <td>nurulakbar93@gmail.com</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </b-col>
                                        </b-row>
                                      </tab-content-item>
                                      <tab-content-item :active="false" id="tab_second" aria-labelled-by="second_tab">
                                        <b-row>
                                          <b-col md="6">
                                            <fieldset class="form-group border p-3">
                                              <legend class="w-auto px-2 h6 text-primary">Sales</legend>
                                              <table class="table table-striped table-sm">
                                                <tbody>
                                                  <tr>
                                                    <th width="40%" class="pl-3">Seller</th>
                                                    <td width="10%" class="pl-3">:</td>
                                                    <td width="50%" class="pl-3">Nurul Akbar</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="40%" class="pl-3">Term Payment</th>
                                                    <td width="10%" class="pl-3">:</td>
                                                    <td width="50%" class="pl-3">7 Days</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="40%" class="pl-3">Fiscal Postion</th>
                                                    <td width="10%" class="pl-3">:</td>
                                                    <td width="50%" class="pl-3">None</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </fieldset>
                                          </b-col>
                                          <b-col md="6">
                                            <fieldset class="form-group border p-3">
                                              <legend class="w-auto px-2 h6 text-primary">Purchase</legend>
                                              <table class="table table-striped table-sm">
                                                <tbody>
                                                  <tr>
                                                    <th width="40%" class="pl-3">Term Payment</th>
                                                    <td width="10%" class="pl-3">:</td>
                                                    <td width="50%" class="pl-3">7 Days</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="40%" class="pl-3">Receipt Reminder</th>
                                                    <td width="10%" class="pl-3">:</td>
                                                    <td width="50%" class="pl-3">Yes</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="40%" class="pl-3">Company ID</th>
                                                    <td width="10%" class="pl-3">:</td>
                                                    <td width="50%" class="pl-3">EM001</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="40%" class="pl-3">Industry</th>
                                                    <td width="10%" class="pl-3">:</td>
                                                    <td width="50%" class="pl-3">Manufacture</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </fieldset>
                                          </b-col>
                                        </b-row>
                                      </tab-content-item>
                                      <tab-content-item :active="false" id="tab_third" aria-labelled-by="third_tab">
                                        <table class="table table-striped table-sm">
                                          <tbody>
                                            <tr>
                                              <th width="40%" class="pl-3">Bank</th>
                                              <td width="10%" class="pl-3">:</td>
                                              <td width="50%" class="pl-3">Bank Centra Asia</td>
                                            </tr>
                                            <tr>
                                              <th width="40%" class="pl-3">Rekening Number</th>
                                              <td width="10%" class="pl-3">:</td>
                                              <td width="50%" class="pl-3">Yes</td>
                                            </tr>
                                            <tr>
                                              <th width="40%" class="pl-3">Receivable</th>
                                              <td width="10%" class="pl-3">:</td>
                                              <td width="50%" class="pl-3">[112100001] - Account Receivable</td>
                                            </tr>
                                            <tr>
                                              <th width="40%" class="pl-3">Trade Receivable</th>
                                              <td width="10%" class="pl-3">:</td>
                                              <td width="50%" class="pl-3">[113100002] - Trade Receivable</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </tab-content-item>
                                      <tab-content-item :active="false" id="tab_fourth" aria-labelled-by="fourth_tab">
                                        <p class="pl-3">Utama Maritim, CV - Jalan H. Fachrudin No.36</p>
                                      </tab-content-item>
                                    </div>
                                  </b-col>
                                </b-row>
                              </fieldset>
                            </tab-content-item>
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>

                  <b-sidebar
                    v-model="addPurchaseOrder"
                    id="addPurchaseOrder"
                    title="ADD PURCHASE ORDER"
                    width="80%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h6 text-primary">General Information</legend>
                            <b-alert :show="true" variant="primary">
                              <b-row>
                                <b-col md="12">
                                  <div class="iq-alert-text">Please Select Your <b>Type Request Order</b> From </div>
                                </b-col>
                                <b-col md="6">
                                  <b-form-select plain v-model="selectReqType" :options="optionReqType" size="sm" style="border: solid 1px gray;">
                                    <template v-slot:first>
                                      <b-form-select-option :value="null">Select Order Type</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-col>
                                <b-col md="6">
                                  <b-form-select plain v-model="selectReqNumber" :options="optionReqNumber" size="sm" style="border: solid 1px gray;">
                                    <template v-slot:first>
                                    <b-form-select-option :value="null">Select Request Number</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-col>
                              </b-row>
                            </b-alert>
                            <b-row class="pl-3 pr-3">
                              <b-col md="4">
                                <b-form>
                                  <b-form-group class="row" label-cols-md="4" label="Order Number" label-for="orderNumber">
                                    <b-form-input id="orderNumber" style="height:40px" placeholder="<< GENERATE ORDER NUMBER >>"></b-form-input>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Request Class" label-for="requestClass">
                                    <b-form-select plain v-model="selectRequestClass" :options="optionRequestClass" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Class</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Vendor" label-for="vendor">
                                    <b-form-select plain v-model="selectVendor" :options="optionVendor" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Vendor</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Order Date" label-for="orderDate">
                                    <b-form-input type="date" id="orderDate" style="height:40px"></b-form-input>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Promise Date" label-for="promiseDate">
                                    <b-form-input type="date" id="promiseDate" style="height:40px"></b-form-input>
                                  </b-form-group>
                                </b-form>
                              </b-col>

                              <b-col md="4">
                                <b-form>
                                  <b-form-group class="row" label-cols-md="4" label="Company" label-for="requestCompany">
                                    <b-form-select plain v-model="selectCompany" :options="optionCompany" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Company</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Department" label-for="requestDepartment">
                                    <b-form-select plain v-model="selectDepartment" :options="optionDepartment" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Department</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Fleet" label-for="requestFleet">
                                    <b-form-select plain v-model="selectFleet" :options="optionFleet" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Fleet</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Project" label-for="Project">
                                    <b-form-select plain v-model="selectProject" :options="optionProject" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Project</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Sub Project" label-for="requestSubProject">
                                    <b-form-select plain v-model="selectSubProject" :options="optionSubProject" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Sub Project</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </b-form>
                              </b-col>

                              <b-col md="4">
                                <b-form>
                                  <b-form-group class="row" label-cols-md="4" label="Priority" label-for="requestPriority">
                                    <b-form-select plain v-model="selectPriority" :options="optionPriority" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Status</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Location" label-for="requestLocation">
                                    <b-form-select plain v-model="selectLocation" :options="optionLocation" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Location</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Request By" label-for="requestBy">
                                    <b-form-select plain v-model="selectRequestBy" :options="optionRequestBy" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Person</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Order By" label-for="OrderBy">
                                    <b-form-select plain v-model="selectOrderBy" :options="optionOrderBy" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Person</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Description" label-for="description">
                                    <b-form-input type="number" id="description" style="height:40px"></b-form-input>
                                  </b-form-group>
                                </b-form>
                              </b-col>
                            </b-row>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h6 text-primary">Item List</legend>
                              <b-button v-b-tooltip.top="'Add Data'" @click="addPurchaseOrder = !addPurchaseOrder;" variant="primary" class="float-right mr-1 mb-3" size="sm"><i class="fa fa-plus"></i></b-button>
                              <b-button v-b-tooltip.top="'Edit Data'" variant="primary" class="float-right mr-1 mb-3" size="sm"><i class="fa fa-edit"></i></b-button>
                              <b-button v-b-tooltip.top="'Delete Data'" variant="primary" class="float-right mr-1 mb-3" size="sm"><i class="fa fa-trash"></i></b-button>
                              <b-button v-b-tooltip.top="'Refresh Data'" variant="primary" class="float-right mr-1 mb-3" size="sm"><i class="fa fa-refresh"></i></b-button>
                              <div class="table-responsive">
                                <table class="table">
                                  <thead>
                                    <tr class="table-success">
                                      <th width="5%" class="text-center">
                                        <template v-for="(item,index) in state">
                                          <b-checkbox class="custom-switch-color" :color="primary" v-model="item.checked" :disabled="item.disabled" name="check-button" inline :key="index">
                                          </b-checkbox>
                                        </template>
                                      </th>
                                      <th width="20%" class="text-center">ITEM ID</th>
                                      <th width="20%" class="text-center">DESCRIPTION ITEM</th>
                                      <th width="10%" class="text-center">QTY</th>
                                      <th width="10%" class="text-center">UOM</th>
                                      <th width="15%" class="text-center">@PRICE</th>
                                      <th width="5%" class="text-center">DISCOUNT</th>
                                      <th width="15%" class="text-center">TOTAL</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th class="text-center">
                                        <template v-for="(item,index) in state">
                                          <b-checkbox class="custom-switch-color" :color="primary" v-model="item.checked" :disabled="item.disabled" name="check-button" inline :key="index">
                                          </b-checkbox>
                                        </template>
                                      </th>
                                      
                                      <td class="text-center">
                                        <b-form-select plain v-model="selectInventoryID" :options="optionInventoryID">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Item ID</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </td>
                                      <td class="text-center"><a href="">[M001] - Engine Oil Filter</a></td>
                                      <td class="text-center">
                                        <b-form-input type="number" class="text-right" id="qty" style="height:40px" placeholder="10"></b-form-input>
                                      </td>
                                      <td class="text-center">
                                        <b-form-select plain>
                                          <b-form-select-option>Pcs</b-form-select-option>
                                          <b-form-select-option>Meter</b-form-select-option>
                                          <b-form-select-option>Box</b-form-select-option>
                                          <b-form-select-option>Lusin</b-form-select-option>
                                        </b-form-select>
                                      </td>
                                      <td class="text-center">
                                        <b-form-input type="number" id="price" style="height:40px"></b-form-input>
                                      </td>
                                      <td class="text-center">
                                        <b-form-input type="number" id="discount" style="height:40px"></b-form-input>
                                      </td>
                                      <td class="text-center">
                                        <b-form-input type="number" id="total" style="height:40px"></b-form-input>
                                      </td>
                                    </tr> 
                                  </tbody>
                                </table>
                              </div>
                          </fieldset>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                </template>
            </iq-card>
        </b-col>
      </b-row>
    </b-container>
  </template>
  <script>
  import { xray } from '../../config/pluginInit'
  export default {
    name: 'procurementPurchaseRequest',
    mounted () {
      xray.index()
    },
    data () {
        return {
          // Sliding Page
          detailPurchaseItem: false,
          addPurchaseOrder: false,
  
          // Form Option
          selectRequestClass: null,
          optionRequestClass: [
              { value: '1', text: 'Bungker' },
              { value: '2', text: 'Material' },
              { value: '3', text: 'Purchase' },
              { value: '4', text: 'Service' },
              { value: '5', text: 'Travel BOD' },
              { value: '6', text: 'Travel GM' },
              { value: '7', text: 'Travel' },
          ],
          selectStatusPurchaseRequest: null,
          optionStatusPurchaseRequest: [
              { value: '1', text: 'Open' },
              { value: '2', text: 'Pending' },
              { value: '3', text: 'Close' },
              { value: '4', text: 'Reject' },
          ],
          selectPriority: 1,
          optionPriority: [
              { value: '1', text: 'Normal' },
              { value: '2', text: 'Urgent' },
              { value: '3', text: 'Critical' }
          ],
          selectCompany: 1,
          optionCompany: [
              { value: '1', text: 'Vehement Capital Partners' },
              { value: '2', text: 'Umbrella Corporation' },
              { value: '3', text: 'The Acme Corporation' }
          ],

          selectRequestBy: null,
          optionRequestBy: [
              { value: '1', text: 'Nurul Akbar' },
              { value: '2', text: 'Agung Tri Laksono' },
              { value: '3', text: 'Ananda Pratama' },
              { value: '4', text: 'Dicky Setiawan'}
          ],
          selectOrderBy: null,
          optionOrderBy: [
              { value: '1', text: 'Nurul Akbar' },
              { value: '2', text: 'Agung Tri Laksono' },
              { value: '3', text: 'Ananda Pratama' },
              { value: '4', text: 'Dicky Setiawan'}
          ],
          selectFleet: null,
          optionFleet: [
              { value: '1', text: 'TB. ETI 201' },
              { value: '2', text: 'TB. TCP 311' },
              { value: '3', text: 'BG. ETI 3001' },
              { value: '4', text: 'PT. TCP 207'}
          ],
          selectProject: null,
          optionProject: [
            { value: '1', text: 'OIL PROJECT' },
            { value: '2', text: 'COAL PROJECT' },
            { value: '3', text: 'CEMENT PROJECT' },
            { value: '4', text: 'NICKEL PROJECT'}
          ],
          selectSubProject: null,
          optionSubProject: [
              { value: '1', text: 'LONG HAULING' },
              { value: '2', text: 'TRANSHIPMENT' },
              { value: '3', text: 'ASSIST TUG' }
          ],
          selectLocation: null,
          optionLocation: [
              { value: '1', text: 'Asam-Asam' },
              { value: '2', text: 'Sangatta' },
              { value: '3', text: 'Bengalon' },
              { value: '4', text: 'Paiton'}
          ],
          selectDepartment: null,
          optionDepartment: [
              { value: '1', text: 'Operational Asam Asam' },
              { value: '2', text: 'Technical Sangatta' },
              { value: '3', text: 'Crewing Head Office' },
              { value: '4', text: 'Head Office' }
          ],
          selectCurrency: null,
          optionCurrency: [
              { value: '1', text: 'IDR' },
              { value: '2', text: 'USD' },
              { value: '3', text: 'SGD' }
          ],
          selectInventoryID: 1,
          optionInventoryID: [
              { value: '1', text: '[M001] - Engine Oil Filter' },
              { value: '2', text: '[M002] - V Belt Altenator' },
              { value: '3', text: '[M003] - Nozle Injector' }
          ],
          selectReqType: 2,
          optionReqType: [
              { value: '1', text: 'Normal' },
              { value: '2', text: 'Purchase Request' }
          ],
          selectReqNumber: null,
          optionReqNumber: [
              { value: '1', text: 'PR0001 - PERBAIKAN KAPAL 301' },
              { value: '2', text: 'PR0002 - PERBAIKAN ME TB. ETI 2201' },
              { value: '3', text: 'PR0003 - PEMBELIAN STOCK' }
          ],
          selectVendor: null,
          optionVendor: [
              { value: '1', text: 'Solusi Cakra Tangguh, PT' },
              { value: '2', text: 'Chemical Indo Pratama, PT' },
              { value: '3', text: 'Energi Cipta Karya' }
          ],
          selectRequestClass: null,
          optionRequestClass: [
              { value: '1', text: 'Bungker' },
              { value: '2', text: 'Material' },
              { value: '3', text: 'Purchase' },
              { value: '4', text: 'Service' },
              { value: '5', text: 'Travel BOD' },
              { value: '6', text: 'Travel GM' },
              { value: '7', text: 'Travel' },
          ],
          // Checklist
          text: [
            {
              activeLabel: 'Yes',
              inactiveLabel: 'No',
              class: 'primary',
              checked: true
            }
          ],
          state: [
            {
              label: 'False/Inactive',
              checked: false,
              disabled: false
            }
          ],
        }
    }
  }
  </script>
  