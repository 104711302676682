<template>
  <div v-if="detail !== null" style="margin: 8px;">
    <div class="personal-data-person mb-5">
      <b-row class="mb-4 position-relative">
        <b-col md="12" class="text-center mb-4"><h4>PERSONAL DATA OF PERSON INVOLVED</h4></b-col>
        <div class="photo-right-top">
          <img v-if="detail.personal_photo" :src="detail.personal_photo || ''" :alt="detail.personal_fullname">
          <span v-else>No Photo</span>
        </div>
      </b-row>
      <b-row>
        <b-col md="7" class="m-auto">
          <table class="w-100">
            <tbody>
            <tr>
              <th width="20%">Name</th>
              <th width="10%">:</th>
              <td width="40%">{{ detail.personal_fullname }}</td>
            </tr>
            <tr>
              <th width="20%">Sex (M/F)</th>
              <th width="10%">:</th>
              <td width="40%">{{ detail.personal_gender }}</td>
            </tr>
            <tr>
              <th width="20%">Place & Date of Birth</th>
              <th width="10%">:</th>
              <td width="40%">{{ detail.personal_birthplace }}, {{ detail.personal_birthdate }}</td>
            </tr>
            <tr>
              <th width="20%">Age</th>
              <th width="10%">:</th>
              <td width="40%">{{ personAge }}</td>
            </tr>
            <tr>
              <th width="20%">Marital Status</th>
              <th width="10%">:</th>
              <td width="40%">{{ detail.personal_marital_status }}</td>
            </tr>
            <tr>
              <th width="20%">Company</th>
              <th width="10%">:</th>
              <td width="40%">{{ personCompany }}</td>
            </tr>
            <tr>
              <th width="20%">Position</th>
              <th width="10%">:</th>
              <td width="40%">{{ detail.personal_position }}</td>
            </tr>
            <tr>
              <th width="20%">Length of Work</th>
              <th width="10%">:</th>
              <td width="40%">{{ detail.working_experience }}</td>
            </tr>
            <tr>
              <th width="20%">RCA</th>
              <th width="10%">:</th>
              <td width="40%">{{ detail.type }} Witness</td>
            </tr>
            <tr>
              <th width="20%" colspan="2"><div class="border border-dark text-bold">Sign</div></th>
            </tr>
            <tr>
              <th width="20%" colspan="2">
                <div class="photo-sign">
                  <img v-if="detail.personal_sign" :src="detail.personal_sign || ''" alt="">
                  <span v-else>No Photo</span>
                </div>
              </th>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </div>
    <div class="define-the-problem mb-5">
      <b-row class="mb-4 position-relative">
        <b-col md="12" class="text-center mb-4"><h4>Define The Problem</h4></b-col>
        <b-col md="12" class="text-center mb-4"><span>Template for Accident Interviews with Crew or Other Involved Parties to get specified issues</span></b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col class="m-auto" md="7">
          <table border="1" class="w-auto">
            <thead>
            <tr>
              <th width="60%" bgcolor="#1e90ff" style="color:#ffe89a">Assessor of the Accident</th>
              <th width="40%"></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colspan="2">
                <table class="w-100">
                  <thead>
                  <tr>
                    <th width="40%">Name</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">{{ detail.assessor_fullname }}</td>
                  </tr>
                  <tr>
                    <th width="40%">Company</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">{{ assessorCompany }}</td>
                  </tr>
                  <tr>
                    <th width="40%">Current Position</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">{{ detail.assessor_position }}</td>
                  </tr>
                  <tr>
                    <th bgcolor="#1e90ff" style="color:#ffe89a" colspan="3">all data belongs to Marine Insurance Departement Group Trans Energi Logistik </th>
                  </tr>
                  </thead>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
        <b-col class="flex justify-end w-max" md="5">
          <div class="py-1 px-4 text-center text-bold" style="color: #000; background-color: #faebd7;">
            DEFINE THE PROBLEM
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" class="m-auto">
          <table class="table w-100 table-bordered">
            <tbody>
            <tr>
              <th bgcolor="#deb887" colspan="2" class="text-center text-bold">PROBLEM STATEMENT WORKSHEET </th>
            </tr>
            <tr>
              <th bgcolor="#faebd7" colspan="2" class="text-center text-bold">BACKGROUND INFORMATION ABOUT THE ACCIDENT OR PROBLEM </th>
            </tr>
            <tr>
              <th width="25%" bgcolor="#ffd700">What part, Product, Process, or Services Involved </th>
              <td width="75%">
                {{ detail.explanation_damage }}
              </td>
            </tr>
            <tr>
              <th width="25%" bgcolor="#ffd700">Explain the Problem(s)</th>
              <td width="75%">
              </td>
            </tr>
            <tr>
              <td colspan="2">
                {{ detail.explanation_problem }}
              </td>
            </tr>
            <tr>
              <th bgcolor="#ffd700" colspan="2" class="text-bold">
                when in the life cycle of the unit is the problem detected ? (e.g at the source, intial inspection, final inspection, end user, etc)-Explain
              </th>
            </tr>
            <tr>
              <td colspan="2">
                {{ detail.explanation_detected }}
              </td>
            </tr>
            <tr>
              <th bgcolor="#faebd7" colspan="2" class="text-bold text-center">
                Expectations
              </th>
            </tr>
            <tr>
              <th width="25%" bgcolor="#ffd700">Result of Defining The Problem</th>
              <td width="75%">
              </td>
            </tr>
            <tr>
              <td colspan="2">
                {{ detail.explanation_result }}
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </div>
    <div class="collecting-information mb-5">
      <b-row class="mb-4 position-relative">
        <b-col md="12" class="text-center mb-4"><h4>Collecting Information</h4></b-col>
        <b-col md="12" class="text-center mb-4"><span>Template for Accident Interview "WHY" METHOD to Uncovered Problem</span></b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col class="m-auto" md="7">
          <table border="1" class="w-auto">
            <thead>
            <tr>
              <th width="60%" bgcolor="#1e90ff" style="color:#ffe89a">Assessor of the Accident</th>
              <th width="40%"></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colspan="2">
                <table class="w-100">
                  <thead>
                  <tr>
                    <th width="40%">Name</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">{{ detail.assessor_fullname }}</td>
                  </tr>
                  <tr>
                    <th width="40%">Company</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">{{ assessorCompany }}</td>
                  </tr>
                  <tr>
                    <th width="40%">Current Position</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">{{ detail.assessor_position }}</td>
                  </tr>
                  <tr>
                    <th bgcolor="#1e90ff" style="color:#ffe89a" colspan="3">all data belongs to Marine Insurance Departement Group Trans Energi Logistik </th>
                  </tr>
                  </thead>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
        <b-col class="flex justify-end w-max" md="5">
          <div class="py-1 px-4 text-center text-bold" style="color: #000; background-color: #faebd7;">
            COLLECTING INFORMATION
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" class="m-auto">
          <table class="table w-100 table-bordered">
            <tbody>
            <tr>
              <th bgcolor="#deb887" colspan="2" class="text-center text-bold">THE FIVE-WHYS WORKSHEET </th>
            </tr>
            <tr>
              <th bgcolor="#faebd7" width="60%" class="text-center text-bold">DEFECT, PROBLEM OR ERROR </th>
              <th bgcolor="#faebd7" width="40%" class="text-center text-bold">CAUSE </th>
            </tr>
            <tr v-for="(why, index) in detail.whys" :key="index">
              <td class="align-top">
                <div><strong>Why-{{ index + 1 }}</strong></div>
                <div>
                  <h4>{{ why.defect }}</h4>
                </div>
              </td>
              <td>
                {{ why.cause }}
              </td>
            </tr>
            <tr>
              <td colspan="2" bgcolor="black" class="text-white">
                The Cause of one "why" is the basis for the next "why" keep asking "why" until the root of cause is uncovered (why -
                5)
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" class="m-auto">
          <table class="table w-100 table-bordered">
            <tbody>
            <tr>
              <th bgcolor="#faebd7" width="60%" class="text-center text-bold">WHAT IS?</th>
              <th bgcolor="#faebd7" width="40%" class="text-center text-bold">WHAT IS NOT?</th>
            </tr>
            <tr>
              <td v-for="(data, index) in detail.whatis" :key="index">
                {{ data }}
              </td>
              <td v-for="(data, index) in detail.whatisnot" :key="index">
                {{ data }}
              </td>
            </tr>
            <tr>
              <td colspan="2" bgcolor="black" class="text-white">
                Complete the worksheet by addings as many "what is/what is not" condition as relevant to the problem under study
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </div>
    <div class="identify-root-cause mb-5">
      <b-row class="mb-4 position-relative">
        <b-col md="12" class="text-center mb-4"><h4>Identify The Root Cause</h4></b-col>
        <b-col md="12" class="text-center mb-4"><span>Template for identify The Root Cause by Number</span></b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col class="m-auto" md="7">
          <table border="1" class="w-auto">
            <thead>
            <tr>
              <th width="60%" bgcolor="#1e90ff" style="color:#ffe89a">Assessor of the Accident</th>
              <th width="40%"></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colspan="2">
                <table class="w-100">
                  <thead>
                  <tr>
                    <th width="40%">Name</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">{{ detail.assessor_fullname }}</td>
                  </tr>
                  <tr>
                    <th width="40%">Company</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">{{ assessorCompany }}</td>
                  </tr>
                  <tr>
                    <th width="40%">Current Position</th>
                    <td width="10%" class="text-center">:</td>
                    <td width="50%">{{ detail.assessor_position }}</td>
                  </tr>
                  <tr>
                    <th bgcolor="#1e90ff" style="color:#ffe89a" colspan="3">all data belongs to Marine Insurance Departement Group Trans Energi Logistik </th>
                  </tr>
                  </thead>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
        <b-col class="flex justify-end w-max" md="5">
          <div class="py-1 px-4 text-center text-bold" style="color: #000; background-color: #faebd7;">
            IDENTIFY THE ROOT CAUSE
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" class="m-auto">
          <table class="table w-100 table-bordered">
            <tbody>
            <tr>
              <td :colspan="12" bgcolor="#faebd7">
                <table class="table-borderless w-100 mb-3">
                  <tr>
                    <td width="20%">Vessel: </td>
                    <td bgcolor="white" width="30%">{{ vessel }}</td>
                    <td width="20%">FMEA Date (Orig.): </td>
                    <td bgcolor="white" width="30%">{{ detail.fmea_date }}</td>
                  </tr>
                </table>
                <table class="table-borderless w-100 mb-3">
                  <tr>
                    <td width="20%">Date of Loss: </td>
                    <td bgcolor="white" width="30%">{{ detail.date_of_loss }}</td>
                    <td width="20%">FMEA Case: </td>
                    <td bgcolor="white" width="30%">{{ detail.fmea_case }}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <th :colspan="12" bgcolor="#d3d3d3" class="text-center">
                Causal Calculation for Root Cause Determination
              </th>
            </tr>
            <tr>
              <th
                v-for="identification in detail.identifications" :key="`identification-${identification.id}`"
                :bgcolor="identification.title === 'RPN' ? '6495ed': !identification.description ? 'ffd700' : '#d3d3d3'"
                :rowspan="!identification.description ? 2 : undefined"
                class="text-center"
              >
                {{ identification.title }}
                {{ !identification.description && identification.title !== 'RPN' ? '(1 to 10)' : '' }}
              </th>
            </tr>
            <tr>
              <th
                v-for="identification in detail.identifications" :key="`identification-${identification.id}`"
                v-show="identification.description"
                bgcolor="#faebd7"
                class="text-center"
              >
                {{ identification.description }}
              </th>
            </tr>
            <tr
              v-for="idx in answerLength" :key="`answers-${idx}`"
            >
              <td
                v-for="identification in detail.identifications" :key="`identification-${identification.id}`"
                :class="{ 'text-danger': identification.title === 'RPN' }"
              >
                {{ identification.answers[idx - 1] || 'N/A' }}
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <table class="table w-100 table-bordered">
            <tr>
              <th bgcolor="#faebd7" width="70%">
                DESCRIPTION OF OCCURRENCE OF
                CAUSAL FACTORS
              </th>
              <th bgcolor="#faebd7" width="30%" class="text-center">
                RATING
              </th>
            </tr>
            <tr>
              <td>
                Failure is almost inevitable
              </td>
              <td bgcolor="#b22222" class="text-white text-center">
                10
              </td>
            </tr>
            <tr>
              <td rowspan="2">
                This Process or similar processes
                have often
              </td>
              <td bgcolor="#b22222" class="text-white text-center">
                9
              </td>
            </tr>
            <tr>
              <td bgcolor="#dc143c" class="text-white text-center">
                8
              </td>
            </tr>
            <tr>
              <td rowspan="2">
                This Process has occasional failures
                but will impact damage to Vessel, in
                major or Minor
              </td>
              <td bgcolor="#dc143c" class="text-white text-center">
                7
              </td>
            </tr>
            <tr>
              <td bgcolor="#d2691e" class="text-center">
                6
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                slated failures associated with similar
                processes
              </td>
              <td bgcolor="#d2691e" class="text-center">
                5
              </td>
            </tr>
            <tr>
              <td bgcolor="#d2691e" class="text-center">
                4
              </td>
            </tr>
            <tr>
              <td bgcolor="#ffd700" class="text-center">
                3
              </td>
            </tr>
            <tr>
              <td>
                Only Isolated Failures associated with
                this process or almost indentical
                processes
              </td>
              <td bgcolor="#ffd700" class="text-center">
                2
              </td>
            </tr>
            <tr>
              <td>
                Failure unlike, No Failures ever
                associated with this process or almost
                identical processes
              </td>
              <td bgcolor="#7fff00" class="text-center">
                1
              </td>
            </tr>
          </table>
        </b-col>
        <b-col md="4">
          <table class="table w-100 table-bordered">
            <tr>
              <th bgcolor="#faebd7" width="70%">
                SEVERITY/INFLUENCE RATING DESCRIPTION
              </th>
              <th bgcolor="#faebd7" width="30%" class="text-center">
                RATING
              </th>
            </tr>
            <tr>
              <td rowspan="2">
                Very High-Hazardous, Vessel become inactive or
                cannot be operated and can cause injury or death
                for the crew, passengers, or other parties ( the
                effect directly to loss/damage of primary functions
                of the Vessel)
              </td>
              <td bgcolor="#b22222" class="text-white text-center">
                10
              </td>
            </tr>
            <tr>
              <td bgcolor="#b22222" class="text-white text-center">
                9
              </td>
            </tr>
            <tr>
              <td rowspan="2">
                High - Repeated failures made the vessel have the
                same problem -negligence, incompetence,
                misconduct, error in judgment by a person, and
                latent defect of the vessel
              </td>
              <td bgcolor="#dc143c" class="text-white text-center">
                8
              </td>
            </tr>
            <tr>
              <td bgcolor="#dc143c" class="text-white text-center">
                7
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                Moderate -Some occasional Failures,
              </td>
              <td bgcolor="#d2691e" class="text-center">
                6
              </td>
            </tr>
            <tr>
              <td bgcolor="#d2691e" class="text-center">
                5
              </td>
            </tr>
            <tr>
              <td bgcolor="#d2691e" class="text-center">
                4
              </td>
            </tr>
            <tr>
              <td>
                Minor - relatively few failures and minor impact to automatically detects failure mode
                Vessel or other parties
              </td>
              <td bgcolor="#ffd700" class="text-center">
                3
              </td>
            </tr>
            <tr>
              <td>
                Very Minor
              </td>
              <td bgcolor="#ffd700" class="text-center">
                2
              </td>
            </tr>
            <tr>
              <td>
                No Effect -No Danger
              </td>
              <td bgcolor="#7fff00" class="text-center">
                1
              </td>
            </tr>
          </table>
        </b-col>
        <b-col md="4">
          <table class="table w-100 table-bordered">
            <tr>
              <th bgcolor="#faebd7" width="30%" class="text-center">
                RATING
              </th>
              <th bgcolor="#faebd7" width="70%">
                DETECTION RATING DESCRIPTION
              </th>
            </tr>
            <tr>
              <td bgcolor="#b22222" class="text-white text-center">
                10
              </td>
              <td>
                Controls will not or can not detect the existence of a failure, no known
                controls available to detect failure mode
              </td>
            </tr>
            <tr>
              <td bgcolor="#b22222" class="text-white text-center">
                9
              </td>
              <td>
                controls probably will not detect the exitence of failure
              </td>
            </tr>
            <tr>
              <td bgcolor="#dc143c" class="text-white text-center">
                8
              </td>
              <td rowspan="2">
                Controls have a poor change of detecting the existence of a failure
                mode
              </td>
            </tr>
            <tr>
              <td bgcolor="#dc143c" class="text-white text-center">
                7
              </td>
            </tr>
            <tr>
              <td bgcolor="#d2691e" class="text-center">
                6
              </td>
              <td rowspan="2">
                Controls may detect the existence of a failure mode
              </td>
            </tr>
            <tr>
              <td bgcolor="#d2691e" class="text-center">
                5
              </td>
            </tr>
            <tr>
              <td bgcolor="#d2691e" class="text-center">
                4
              </td>
              <td rowspan="2">
                Control have a good chance of detecting failure mode, process
                Minor - relatively few failures and minor impact to automatically detects failure mode
              </td>
            </tr>
            <tr>
              <td bgcolor="#ffd700" class="text-center">
                3
              </td>
            </tr>
            <tr>
              <td bgcolor="#7fff00" class="text-center">
                2
              </td>
              <td rowspan="2">
                current control almost centrain to detect the failure mode, reliable
                detection control are known with similar processes. Process
                automatically prevents further processing
              </td>
            </tr>
            <tr>
              <td bgcolor="#7fff00" class="text-center">
                1
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </div>
    <div class="implement-corrective-actions mb-5">
      <b-row class="mb-4 position-relative">
        <b-col md="12" class="text-center mb-4"><h4>Implement Corrective Actions</h4></b-col>
        <b-col md="12" class="text-center mb-4"><span>Template for Implementation of Corrective Actions</span></b-col>
      </b-row>
      <b-row>
        <b-col md="8" class="m-auto">
          <table class="table w-100 table-bordered">
            <tbody>
              <tr>
                <th bgcolor="#faebd7" class="text-center" width="25%">
                  Tool
                </th>
                <th bgcolor="#faebd7" class="text-center" width="25%">
                  Question
                </th>
                <th bgcolor="#faebd7" class="text-center" width="25%">
                  Findings
                </th>
                <th bgcolor="#faebd7" class="text-center" width="25%">
                  Corrective Actions
                </th>
              </tr>
              <tr v-for="(implementation, index) in detail.implementations" :key="index">
                <td>
                  {{ implementation.tool || 'N/A' }}
                </td>
                <td>
                  {{ implementation.question || 'N/A' }}
                </td>
                <td>
                  {{ implementation.finding || 'N/A' }}
                </td>
                <td>
                  {{ implementation.corrective_action || 'N/A' }}
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            Rarely will all tolls and techniques be needed to uncover a root cause, experience is the best judge to determine the best order to use the various
            tools and techniques available to search and questions processes for the root cause to a specific problem
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="fishbone-diagram-container">
      <b-row class="mb-4 position-relative">
        <b-col md="12" class="text-center mb-4"><h4>Fishbone Diagram</h4></b-col>
        <b-col md="12" class="text-center mb-4"><span>Template for Fishbone Diagram</span></b-col>
      </b-row>
      <div class="fishbone-diagram">
        <div class="fish-head">
          {{detail.diagram_title}}
        </div>
        <template v-for="(diagram, index) in diagramsTop">
          <div :key="index" class="child-bone" :style="{
          top: 0,
                left: `${30 + (index * 350)}px`
        }">
            <div class="child-bone-container">
              <div class="header">
                {{ diagram.header }}
              </div>
              <template v-if="diagram.body">
                <div class="content" v-for="(body, indexBody) in diagram.body" :key="indexBody">
                  {{body}}
                </div>
              </template>
            </div>
          </div>
        </template>
        <div class="main-bone"></div>
        <template v-for="(diagram, index) in diagramsBottom">
          <div :key="index" class="child-bone bottom" :style="{
          top: '520px',
          left: `${100 + (index * 350)}px`
        }">
            <div class="child-bone-container">
              <div class="header">
                {{ diagram.header }}
              </div>
              <template v-if="diagram.body">
                <div class="content" v-for="(body, indexBody) in diagram.body" :key="indexBody">
                  {{body}}
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Fetching Data...</p>
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { companiesActions, miActions, vehiclesActions } from '@/src/Utils/helper'

export default {
  name: 'RootAnalysisPreview',
  async mounted () {
    xray.index()
    await this.getCompanyList()
    await this.getVehicleList()
    if (await this.getDetail(this.$route.params.id)) {
      if (this.detail.form_id.toString() !== this.$route.query.formId.toString()) {
        this.redirectToListInitial()
      }
    } else {
      this.redirectToListInitial()
    }
  },
  data () {
    return {
      detail: null,
      companies: [],
      vehicles: [],
      answerLength: 0
    }
  },
  computed: {
    diagramsTop () {
      return this.detail.diagrams.filter((diagram, index) => index % 2 !== 0)
    },
    diagramsBottom () {
      return this.detail.diagrams.filter((diagram, index) => index % 2 === 0)
    },
    personAge () {
      return (new Date()).getFullYear() - (new Date(this.detail.personal_birthdate)).getFullYear()
    },
    personCompany () {
      return this.companies.find(company => company.id === this.detail.personal_company_id).company
    },
    assessorCompany () {
      return this.companies.find(company => company.id === this.detail.assessor_company_id).company
    },
    vessel () {
      return this.vehicles.find(vehicle => vehicle.id === this.detail.vessel_id).name
    }
  },
  methods: {
    ...miActions,
    ...companiesActions,
    ...vehiclesActions,
    async getDetail (id) {
      const { status, data } = await this.getRCA(id)
      this.detail = data
      if (status === 'success') {
        const model = await this.getRCAFormModel()
        const identifications = model.data.map(identification => {
          const answers = this.detail.identifications.find(data => data.id === identification.id).answers
          if (this.answerLength < answers.length) {
            this.answerLength = answers.length
          }
          return {
            ...identification,
            answers
          }
        })
        this.detail.identifications = identifications
        return true
      }
      return false
    },
    async getCompanyList () {
      const res = await this.getCompanies()
      this.companies = res.data
    },
    async getVehicleList () {
      const { data } = await this.getVehicles({ showAll: true, page: 1 })
      this.vehicles = data.data.map(val => ({ id: val.id, name: val.name }))
    },
    redirectToListInitial () {
      this.$swal(
        `Oops!`,
        'Ada Yang Salah',
        'error'
      )

      setTimeout(() => {
        this.$router.push({ name: 'mi.listInitial' })
      }, 1500)
    }
  }
}
</script>
<style scoped>
.photo-right-top {
  position: absolute;
  width: 150px;
  height: 170px;
  border: 3px solid #333;
  top:0;
  right:40px;
}
.photo-right-top > img {
  width: 150px;
  height: 170px;
}
.photo-sign {
  width: 180px;
  height: 140px;
  border: 3px solid #333;
}
.photo-right-sign > img {
  width: 180px;
  height: 140px;
}
.fishbone-diagram {
  position: relative;
  padding: 700px 20px 700px 20px;
}

.fishbone-diagram .main-bone {
  position: absolute;
  left: 0;
  right: 0;
  top: 500px;
  height: 20px;
  background: #333;
}

.fishbone-diagram .child-bone {
  position: absolute;
  width: 10px;
  height: 500px;
  background: #333;
}

.fishbone-diagram .fish-head {
  z-index: 2;
  position: absolute;
  right: -30px;
  top: 0;
  bottom: 400px;
  margin: auto;
  width: 250px;
  height: 130px;
  border-radius: 100%;
  background: #ad2b35;
  color: #FFF;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fishbone-diagram .child-bone .child-bone-container {
  position: relative;
  display: flex;
  margin-left: 10px;
  gap:10px;
  flex-direction: column;
}

.fishbone-diagram .child-bone.bottom .child-bone-container {
  flex-direction: column-reverse;
  height: 100%;
}

.fishbone-diagram .child-bone .child-bone-container > .header {
  width: 150px;
  color: #FFF;
  background: #333;
  padding: 20px;
  border: 2px solid #333;
}

.fishbone-diagram .child-bone .child-bone-container > .content {
  width: 300px;
  max-height: 200px;
  overflow-y: scroll;
  color: #333;
  font-size: 12px;
  padding: 10px;
  border: 2px solid #333;
}
</style>
