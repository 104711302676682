<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>{ Selected Year } - Balancing Loss Ratio</strong></h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" size="medium"><i class="fa fa-edit"></i> Edit</b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="4">
                <div class="form-group">
                  <label class="mb-3">Vehicle Type</label>
                  <div>
                    <span class="font-size-18">Tug Boat, </span>
                    <span class="font-size-18">Barge, </span>
                    <span class="font-size-18">Pusher Tug, </span>
                    <span class="font-size-18">Pusher Barge, </span>
                    <span class="font-size-18">Sea Truck, </span>
                    <span class="font-size-18">Floating Crane, </span>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <div class="form-group">
                  <label class="mb-3">Year</label>
                    <h4>{ Select Year}</h4>
                </div>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col md="3">
                <div class="form-group">
                  <label class="mb-3"><h4 class="text-capitalize">Forecast <strong>{ next_year }</strong></h4></label>
                  <h3>IDR -</h3>
                </div>
              </b-col>
              <b-col md="3">
                <div class="form-group">
                  <label class="mb-3"><h4 class="text-capitalize">Actual Premium <strong>{ next_year }</strong></h4></label>
                  <h3>IDR -</h3>
                </div>
              </b-col>
              <b-col md="3">
                <div class="form-group">
                  <label class="mb-3"><h4 class="text-capitalize">Rate HM <strong>{ next_year }</strong></h4></label>
                  <h3>IDR -</h3>
                </div>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <!-- Loading Point -->
      <b-col md="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col md="8">
                <b-row>
                  <b-col md="4">
                    <div class="form-group">
                      <label class="mb-3"><h4 class="text-capitalize">Loading Point</h4></label>
                      <h3>-</h3>
                    </div>
                  </b-col>
                  <b-col md="4">
                    <div class="form-group">
                      <label class="mb-3"><h4 class="text-capitalize">Loading Point Insurer</h4></label>
                      <h3>-</h3>
                    </div>
                  </b-col>
                  <b-col md="4">
                    <div class="form-group">
                      <label class="mb-3"><h4 class="text-capitalize">Final Loading Point</h4></label>
                      <h3>-</h3>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="4">
                <div class="mb-4">
                  NILAI TAMBAH YANG DIBERIKAN OLEH ASURANSI KEPADA OWNER UNTUK MENAMBAH BEBAN RATE
                </div>
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th>Claim</th>
                    <th>Percentage (%)</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>0 - IDR 1.000.000.000</td>
                    <td>2.5%</td>
                  </tr>
                  <tr>
                    <td>IDR 1.100.000.000 - IDR 2.000.000.000</td>
                    <td>5.0%</td>
                  </tr>
                  <tr>
                    <td>IDR 2.100.000.000 - IDR 3.000.000.000</td>
                    <td>7.5%</td>
                  </tr>
                  <tr>
                    <td>IDR 3.100.000.000 - IDR 4.000.000.000</td>
                    <td>10.0%</td>
                  </tr>
                  <tr>
                    <td>IDR 4.100.000.000 - IDR 5.000.000.000</td>
                    <td>12.5%</td>
                  </tr>
                  <tr>
                    <td>> IDR 5.100.000.000</td>
                    <td>15.0%</td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <!-- End of Loading Point -->
      <!-- Step 1 -->
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-capitalize text-black"><strong>Step 1 - Pure Risk Component</strong></h4>
          </template>
          <template v-slot:body>
            <div class="row">
              <div class="col-md-6">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th>No</th>
                      <th>Details</th>
                      <th>Percentage</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>1.</td>
                      <td>OVERHEAD COST (OPERATIONAL)</td>
                      <td>
                        <b-input-group size="md" append="%">
                          <b-form-input type="number" style="max-width: 50%;"></b-form-input>
                        </b-input-group>
                      </td>
                    </tr>
                    <tr>
                      <td>2.</td>
                      <td>ACQUISITION</td>
                      <td>
                        <b-input-group size="md" append="%">
                          <b-form-input type="number" style="max-width: 50%;"></b-form-input>
                        </b-input-group>
                      </td>
                    </tr>
                    <tr>
                      <td>3.</td>
                      <td>REINSURANCE COST</td>
                      <td>
                        <b-input-group size="md" append="%">
                          <b-form-input type="number" style="max-width: 50%;"></b-form-input>
                        </b-input-group>
                      </td>
                    </tr>
                    <tr>
                      <td>4.</td>
                      <td>INFLATION </td>
                      <td>
                        <b-input-group size="md" append="%">
                          <b-form-input type="number" style="max-width: 50%;"></b-form-input>
                        </b-input-group>
                      </td>
                    </tr>
                    <tr>
                      <td>5.</td>
                      <td>EXPECTED PROFIT</td>
                      <td>
                        <b-input-group size="md" append="%">
                          <b-form-input type="number" style="max-width: 50%;"></b-form-input>
                        </b-input-group>
                      </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr>
                      <td colspan="2" class="text-center bg-success"><strong>Total</strong></td>
                      <td class="text-center bg-success"><strong>Sum(Point 1 - 5)</strong></td>
                    </tr>
                    <tr>
                      <td colspan="2" class="text-center bg-info"><strong>Pure Risk Cost</strong></td>
                      <td class="text-center bg-info"><strong>100% - Total</strong></td>
                    </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div class="col-md-6">
                <img src="@assets/images/financial-pure-risk-component.jpg" width="100%" class="mt-5"/>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- End Step 1 -->
      <!-- Step 2 -->
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-capitalize text-black"><strong>Step 2 - Outstanding & Future Claim</strong></h4>
          </template>
          <template v-slot:body>
            <div class="row">
              <div class="col-md-12 mb-3">
                <h4 class="mb-4">Outstanding</h4>
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th>No</th>
                      <th>Type</th>
                      <th>Vessel / Form Claim Linked</th>
                      <th>EST Cost</th>
                      <th>Additional THUMB RULES (1%)</th>
                      <th>Est Closed File</th>
                      <th>Status</th>
                      <th>Period Coverage</th>
                      <th>#</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>1.</td>
                      <td>
                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color ml-2">
                          <span><small>Manual Input (Free Text) ? &nbsp;</small></span>
                          <div class="custom-switch-inner">
                            <input type="checkbox" class="custom-control-input" :class="'bg-'+premiumFromBudget.class" :id="'premiumFromBudget'" v-model="premiumFromBudget.checked">
                            <label class="custom-control-label" :for="'premiumFromBudget'" :data-on-label="premiumFromBudget.activeLabel" :data-off-label="premiumFromBudget.inactiveLabel">
                            </label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <b-form-group label="Link to Case">
                          <small>Before & Current Year</small>
                          <v-select placeholder="Link To Case" label="text"></v-select>
                        </b-form-group>
                      </td>
                      <td>
                        <b-input-group size="md" prepend="IDR">
                          <b-form-input type="number"></b-form-input>
                        </b-input-group>
                      </td>
                      <td>
                        <h5>IDR { EST Cost x 1% }</h5>
                      </td>
                      <td>
                        <date-picker type="date" value-type="YYYY-MM-DD" placeholder="Select date"></date-picker>
                      </td>
                      <td>
                        <v-select v-model="status" :options="statusOption" :reduce="statusOption => statusOption.code" placeholder="Status" label="text"></v-select>
                      </td>
                      <td>
                        <date-picker type="month" value-type="YYYY-MM" range placeholder="Select Period Coverage"></date-picker>
                      </td>
                      <td><b-button variant="danger" size="sm"><i class="fa fa-trash"></i> </b-button></td>
                    </tr>
                    <tr>
                      <td>2.</td>
                      <td>
                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color ml-2">
                          <span><small>Manual Input (Free Text) ? &nbsp;</small></span>
                          <div class="custom-switch-inner">
                            <input type="checkbox" class="custom-control-input" :class="'bg-'+premiumFromBudget.class" :id="'premiumFromBudget'" v-model="premiumFromBudget.checked">
                            <label class="custom-control-label" :for="'premiumFromBudget'" :data-on-label="premiumFromBudget.activeLabel" :data-off-label="premiumFromBudget.inactiveLabel">
                            </label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <b-form-group label="Link to Case">
                          <small>Before & Current Year</small>
                          <v-select placeholder="Link To Case" label="text"></v-select>
                        </b-form-group>
                      </td>
                      <td>
                        <b-input-group size="md" prepend="IDR">
                          <b-form-input type="number"></b-form-input>
                        </b-input-group>
                      </td>
                      <td>
                        <h5>IDR { EST Cost x 1% }</h5>
                      </td>
                      <td>
                        <date-picker type="date" value-type="YYYY-MM-DD" placeholder="Select date"></date-picker>
                      </td>
                      <td>
                        <v-select v-model="status" :options="statusOption" :reduce="statusOption => statusOption.code" placeholder="Status" label="text"></v-select>
                      </td>
                      <td>
                        <date-picker type="month" value-type="YYYY-MM" range placeholder="Select Period Coverage"></date-picker>
                      </td>
                      <td><b-button variant="danger" size="sm"><i class="fa fa-trash"></i> </b-button></td>
                    </tr>
                    <tr>
                      <td>3.</td>
                      <td>
                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color ml-2">
                          <span><small>Manual Input (Free Text) ? &nbsp;</small></span>
                          <div class="custom-switch-inner">
                            <input type="checkbox" class="custom-control-input" :class="'bg-'+premiumFromBudget.class" :id="'premiumFromBudget'" v-model="premiumFromBudget.checked">
                            <label class="custom-control-label" :for="'premiumFromBudget'" :data-on-label="premiumFromBudget.activeLabel" :data-off-label="premiumFromBudget.inactiveLabel">
                            </label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <b-form-group label="Link to Case">
                          <small>Before & Current Year</small>
                          <v-select placeholder="Link To Case" label="text"></v-select>
                        </b-form-group>
                      </td>
                      <td>
                        <b-input-group size="md" prepend="IDR">
                          <b-form-input type="number"></b-form-input>
                        </b-input-group>
                      </td>
                      <td>
                        <h5>IDR { EST Cost x 1% }</h5>
                      </td>
                      <td>
                        <date-picker type="date" value-type="YYYY-MM-DD" placeholder="Select date"></date-picker>
                      </td>
                      <td>
                        <v-select v-model="status" :options="statusOption" :reduce="statusOption => statusOption.code" placeholder="Status" label="text"></v-select>
                      </td>
                      <td>
                        <date-picker type="month" value-type="YYYY-MM" range placeholder="Select Period Coverage"></date-picker>
                      </td>
                      <td><b-button variant="danger" size="sm"><i class="fa fa-trash"></i> </b-button></td>
                    </tr>
                    </tbody>
                  </table>
                <b-col md="12">
                  <b-button variant="warning" class="mb-3 mr-1" block><i class="ri-add-line"></i> Add More Data Outstanding</b-button>
                </b-col>
              </div>
              <div class="col-md-12"><hr/></div>
              <div class="col-md-12 mb-3">
                <h4 class="mb-4">Future Claim</h4>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th>Items</th>
                      <th>Content</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>Est Increase Loss Ratio</td>
                      <td>
                        <b-textarea rows="2" value="Increase loss ratio up to 100% in the next 2 Years  - Approximate 50% per year in the next 2 years (2022 -2023)"></b-textarea>
                      </td>
                    </tr>
                    <tr>
                      <td>Condition</td>
                      <td>
                        <b-textarea rows="2" value="Below </span> <span><strong>{value_of_pure_risk} </strong></span><span>per Years (Pure Risk Cost)"></b-textarea>
                      </td>
                    </tr>
                    <tr>
                      <td>Amount /Years</td>
                      <td>
                        <b-input-group size="md" prepend="IDR">
                          <b-form-input type="number" style="max-width: 50%;"></b-form-input>
                        </b-input-group>
                      </td>
                    </tr>
                    <tr>
                      <td>Estimate Loss Future Until Renewal</td>
                      <td>
                        <b-input-group size="md" prepend="IDR">
                          <b-form-input type="number" style="max-width: 50%;"></b-form-input>
                        </b-input-group>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- End Step 2 -->
      <!-- Step 3 -->
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-capitalize text-black"><strong>Step 3 - Calculation & Forecast Condition</strong></h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="6">
                <b-col cols="12" class="text-center mb-3">
                  <template v-for="(item, index) in typeOfCalculation">
                    <b-form-radio
                      v-model="typeCalculation"
                      class="custom-radio-color-checked"
                      inline
                      :color="item.color"
                      name="color"
                      :key="index"
                      :value="item.value"
                      :disabled="item.disabled"
                    ><strong><h5>{{ item.label }}</h5></strong></b-form-radio>
                  </template>
                </b-col>
              </b-col>
              <b-col md="6" class="mb-4">
                <b-input-group size="md" prepend="Input Your Max Number of Year for Calculation" v-if="typeCalculation === 'data'">
                  <b-form-input type="number"></b-form-input>
                </b-input-group>
              </b-col>
              <b-col md="12" class="mb-5">
                <b-alert :show="true" variant="secondary">
                  <div class="iq-alert-text text-center">INDIVIDUAL RECORD</div>
                </b-alert>
                <b-row>
                  <b-col md="12">
                    <table class="table table-striped table-warning">
                      <thead>
                      <tr>
                        <th>Year</th>
                        <th>Premium</th>
                        <th>Claim Loss</th>
                        <th>Loss Ratio</th>
                        <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><strong>2021</strong></td>
                        <td>9.809.729.019,56</td>
                        <td>892.850.000</td>
                        <td>9,10%</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><strong>2020</strong></td>
                        <td>11.370.525.019,56</td>
                        <td>892.850.000</td>
                        <td>7,85%</td>
                        <td><b-button size="sm" variant="danger" class="mb-3 mr-1"><i class="ri-delete-bin-line"></i></b-button></td>
                      </tr>
                      <tr class="bg-white" v-if="typeCalculation === 'manual'">
                        <td><v-select v-model="selectYear" label="text" :options="yearOption" :reduce="yearOption => yearOption.value" placeholder="Select Year"></v-select></td>
                        <td><b-form-input type="text" placeholder="Input Premium"></b-form-input></td>
                        <td><b-form-input type="text" placeholder="Input Claim Loss"></b-form-input></td>
                        <td><b-form-input type="text" placeholder="Input Loss Ratio"></b-form-input></td>
                        <td><b-button size="sm" variant="danger" class="mb-3 mr-1"><i class="ri-delete-bin-line"></i></b-button></td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12">
                    <b-button v-if="typeCalculation === 'manual'" variant="warning" class="mb-3 mr-1" block><i class="ri-add-line"></i> Add Data Before Year</b-button>
                  </b-col>
                </b-row>
              </b-col>
              <!-- Accumulative Record -->
              <b-col md="12">
                <b-alert :show="true" variant="secondary">
                  <div class="iq-alert-text text-center">ACCUMULATIVE RECORD</div>
                </b-alert>
                <b-row>
                  <b-col md="12">
                    <table class="table table-striped table-success">
                      <thead>
                      <tr>
                        <th rowspan="2" style="vertical-align: middle;">Year</th>
                        <th rowspan="2" style="vertical-align: middle;">Acc.Premium</th>
                        <th rowspan="2" style="vertical-align: middle;">Acc.Loss</th>
                        <th rowspan="2" style="vertical-align: middle;">Acc.Loss Ratio</th>
                        <th rowspan="2" style="vertical-align: middle;">Pure Risk</th>
                        <th rowspan="2" style="vertical-align: middle;">Insurer Recovery (Month)</th>
                        <th rowspan="2" style="vertical-align: middle;">Insurer Recovery</th>
                        <th colspan="2" class="text-center">Crosscheck</th>
                      </tr>
                      <tr>
                        <th>Kerugian/Keuntungan yang diterima Asuransi</th>
                        <th>%</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td><strong>2021</strong></td>
                        <td>11.370.525.019,56</td>
                        <td>892.850.000</td>
                        <td>7,85%</td>
                        <td>62,50%</td>
                        <td>35,66%</td>
                        <td>2,80%</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><strong>2020</strong></td>
                        <td>11.370.525.019,56</td>
                        <td>892.850.000</td>
                        <td>7,85%</td>
                        <td>62,50%</td>
                        <td>35,66%</td>
                        <td>2,80%</td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <!-- End Step 3 -->
      <!-- Step 4 -->
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-capitalize text-black"><strong>Step 4 - Final Calculation Condition</strong></h4>
          </template>
          <template v-slot:body>
            <b-col md="12" class="mb-5">
              <b-alert :show="true" variant="secondary">
                <div class="iq-alert-text text-center">forecasting premium by simple linear calculation</div>
              </b-alert>
              <b-row>
                <b-col md="2">Reduce Premium<br/><small class="text-success">Premium (Current) – Forecast</small></b-col>
                <b-col md="4"><h4>IDR. -</h4></b-col>
                <b-col md="3">Reduce Premium (%)
                <br/><small class="text-success">Reduce Premium / Premium (Current) x 100%</small></b-col>
                <b-col md="3">
                  <h4>0%</h4>
                </b-col>
              </b-row>
              <b-row class="pl-4">
                <b-col md="12" class="mt-5">
                  <b-alert :show="true" variant="info">
                    <div class="iq-alert-text text-center">PESIMISTIC CALCULATION</div>
                  </b-alert>
                  <b-row>
                    <b-col md="12">
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th width="25%">Select Company</th>
                          <th>Current Rate</th>
                          <th>Decrease<br/><small>Current Rate x Reduce Premium %</small></th>
                          <th><strong>Result</strong></th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td><v-select v-model="companiesSelected" label="company" :options="companies" placeholder="Company Name" :reduce="(c) => c.id" :multiple="true"></v-select></td>
                          <td>
                            <div>
                              <template v-for="(item, index) in pesimisticRateOptions">
                                <b-form-radio
                                  v-model="pesimisticRate"
                                  class="custom-radio-color-checked"
                                  inline
                                  :color="item.color"
                                  name="color"
                                  :key="index"
                                  :value="item.value"
                                  :disabled="item.disabled"
                                ><strong><h5>{{ item.label }}</h5></strong></b-form-radio>
                              </template>
                            </div>
                            <div>
                            <b-input-group size="md" append="%">
                              <b-form-input type="number" style="max-width: 50%;"></b-form-input>
                            </b-input-group>
                            </div>
                          </td>
                          <td><small>Current Rate x Reduce Premium %</small></td>
                          <td><small>Current Rate - Decrease</small></td>
                          <td><b-button size="sm" variant="danger" class="mb-3 mr-1"><i class="ri-delete-bin-line"></i></b-button></td>
                        </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <b-col md="12">
                      <b-button variant="info" class="mb-3 mr-1" block><i class="ri-add-line"></i> Add Data</b-button>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="12" class="mt-4">
                  <b-alert :show="true" variant="success">
                    <div class="iq-alert-text text-center">OPTIMISTIC CALCULATION</div>
                  </b-alert>
                  <b-row>
                    <b-col md="12">
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th width="25%">Company</th>
                          <th>Pesimistic Rate</th>
                          <th>Additional %</th>
                          <th><strong>Result</strong></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>
                            <b-badge variant="warning">PT.Energy Transporter Indonesia</b-badge> &nbsp;
                            <b-badge variant="warning">PT.Renjani Maritim Transportation</b-badge> &nbsp;
                            <b-badge variant="warning">PT.Sentra Makmur Lines</b-badge> &nbsp;
                          </td>
                          <td>
                            <i><strong>{ Result Pesimistic Calculation }</strong></i>
                          </td>
                          <td><i><strong>{ Result Pesimistic Calculation x Final Loading Point }</strong></i></td>
                          <td><i><strong>{ Pesimistic Rate + Additional % }</strong></i></td>
                        </tr>
                        </tbody>
                      </table>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="12" class="mt-5">
                  <table class="table table-bordered table-striped shadow">
                    <thead>
                    <tr>
                      <th width="50%" colspan="2" rowspan="2" style="vertical-align: middle;">FINAL RESULT  WITH REDUCE VALUE 15% PER VESSEL </th>
                      <th colspan="3">FINAL RATE FOR RENEWAL </th>
                    </tr>
                    <tr>
                      <td class="text-center"><strong>HM</strong></td>
                      <td class="text-center"><strong>IV</strong></td>
                      <td class="text-center"><strong>WAR</strong></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>
                        <b-badge variant="warning">PT.Energy Transporter Indonesia</b-badge> &nbsp;
                        <b-badge variant="warning">PT.Renjani Maritim Transportation</b-badge> &nbsp;
                        <b-badge variant="warning">PT.Sentra Makmur Lines</b-badge> &nbsp;
                      </td>
                      <td><strong>{ Result Optimistic Calculation }</strong></td>
                      <td><strong>{ Result Optimistic Calculation }</strong></td>
                      <td>
                        <b-input-group size="md" append="%">
                          <b-form-input type="number" width="50%"></b-form-input>
                        </b-input-group>
                      </td>
                      <td>
                        <b-input-group size="md" append="%">
                          <b-form-input type="number" width="50%"></b-form-input>
                        </b-input-group>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
                <b-col md="12" class="mt-5">
                  <table class="table table-bordered table-success shadow">
                  </table>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="12"></b-col>
          </template>
        </iq-card>
      </b-col>
      <!-- End Step 4 -->
      <!-- Sidebar View Show Budget -->
      <b-sidebar id="show-budget" title="LIST OF BUDGET" width="1500px" backdrop-variant="dark" right backdrop shadow>
        <b-row class="pt-2 pl-3 pr-3 pb-2">
          <ComponentBudget/>
        </b-row>
      </b-sidebar>
      <!-- End Sidebar View Show Budget -->
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { companiesActions, fleetsActions } from '@src/Utils/helper'
import ComponentBudget from '@src/views/MI/component/ComponentBudget'

var date = new Date(); var y = date.getFullYear(); var m = date.getMonth()

export default {
  name: 'BlankPage',
  components: { ComponentBudget },
  mounted () {
    xray.index()
    this.getVehicleTypeData()
    this.fetchCompanies()
  },
  data () {
    return {
      selectYear: new Date(y, m, 1),
      status: 'open',
      typeFleet: [],
      companies: [],
      companiesSelected: [],
      componentStruct: {
        editMode: false,
        params: {
          search: '',
          vehicleTypeId: 1
        },
        data: [],
        form: {
          parentId: null,
          componentName: '',
          description: '',
          vehicleTypeId: 1,
          haveEquipment: true
        },
        detailData: null
      },
      yearOption: [
        { value: '2022', text: '2022' },
        { value: '2021', text: '2021' },
        { value: '2020', text: '2020' },
        { value: '2019', text: '2019' },
        { value: '2018', text: '2018' }
      ],
      statusOption: [
        { value: 'open', text: 'open' },
        { value: 'close', text: 'close' }
      ],
      premiumFromBudget: {
        activeLabel: 'Yes',
        inactiveLabel: 'No',
        label: '',
        class: 'success',
        checked: false
      },
      typeCalculation: 'data',
      typeOfCalculation: [
        {
          label: 'From Data Before',
          color: 'success',
          value: 'data'
        },
        {
          label: 'Manual Input Years (Free Text)',
          color: 'info',
          value: 'manual'
        }
      ],
      pesimisticRate: 'manual',
      pesimisticRateOptions: [
        {
          label: 'Manual (Free Text)',
          color: 'success',
          value: 'manual'
        },
        {
          label: 'From TSI HM (Current)',
          color: 'info',
          value: 'tsi_hm'
        }
      ],
    }
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    async getVehicleTypeData () {
      const res = await this.getFleetTypes()

      if (res.status == 'success' && res.data && res.data.length > 0) {
        res.data.forEach(element => {
          if (element.active) {
            this.typeFleet.push({
              name: element.name,
              color: element.name === 'Tugboat' ? 'success' : (element.name === 'Barge' ? 'warning' : ' primary'),
              code: element.id
            })
          }
        })
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async fetchCompanies () {
      let res = await this.getCompanies()
      this.companies = res.data
    }
  }
}
</script>
