<template>
  <b-container fluid>
    <b-row>
      <b-col md="12"><h4 class="text-primary mb-2">All Request :</h4></b-col>
      <b-col md="4">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary">
              <template v-slot:body >
              <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-primary"><i class="fas fa-database"></i></div>
                  <div class="text-right">
                    <router-link to="/templates/">
                        <h3 class="mb-0"><span class="counter">0</span></h3>
                        <p>To Send</p>
                    </router-link>
                  </div>
              </div>
              </template>
          </iq-card>
      </b-col>
      <b-col md="4">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary">
              <template v-slot:body >
              <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-primary"><i class="fas fa-database"></i></div>
                  <div class="text-right">
                    <router-link to="/templates/">
                        <h3 class="mb-0"><span class="counter">0</span></h3>
                        <p>Waiting</p>
                    </router-link>
                  </div>
              </div>
              </template>
          </iq-card>
      </b-col>
      <b-col md="4">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary">
              <template v-slot:body >
              <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-primary"><i class="fas fa-database"></i></div>
                  <div class="text-right">
                    <router-link to="/templates/">
                        <h3 class="mb-0"><span class="counter">0</span></h3>
                        <p>Late</p>
                    </router-link>
                  </div>
              </div>
              </template>
          </iq-card>
      </b-col>

      <b-col md="12"><h4 class="text-primary mb-2">My Request :</h4></b-col>
      <b-col md="4">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary">
              <template v-slot:body >
              <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-primary"><i class="fas fa-database"></i></div>
                  <div class="text-right">
                    <router-link to="/templates/">
                        <h3 class="mb-0"><span class="counter">0</span></h3>
                        <p>To Send</p>
                    </router-link>
                  </div>
              </div>
              </template>
          </iq-card>
      </b-col>
      <b-col md="4">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary">
              <template v-slot:body >
              <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-primary"><i class="fas fa-database"></i></div>
                  <div class="text-right">
                    <router-link to="/templates/">
                        <h3 class="mb-0"><span class="counter">0</span></h3>
                        <p>Waiting</p>
                    </router-link>
                  </div>
              </div>
              </template>
          </iq-card>
      </b-col>
      <b-col md="4">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary">
              <template v-slot:body >
              <div class="d-flex align-items-center justify-content-between">
                  <div class="rounded-circle iq-card-icon bg-primary"><i class="fas fa-database"></i></div>
                  <div class="text-right">
                    <router-link to="/templates/">
                        <h3 class="mb-0"><span class="counter">0</span></h3>
                        <p>Late</p>
                    </router-link>
                  </div>
              </div>
              </template>
          </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
          <iq-card>
              <template v-slot:headerTitle>
                  <h5 class="card-title text-primary"><strong>PURCHASE REQUEST</strong></h5>
              </template>
              <template v-slot:headerAction>
              <a class="text-primary float-right ml-3" v-b-toggle.filter role="button" style="cursor: pointer">
              <i class="fa fa-filter" />
              </a>
              </template>
              <template v-slot:body>
                <b-collapse visible id="filter" class="mb-4">
                    <b-row>
                      <b-col md="2">
                          <b-form-select plain v-model="selectRequestClass" :options="optionRequestClass" size="sm">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Request Class</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-col>
                      <b-col md="2">
                          <b-form-select plain v-model="selectStatusPurchaseRequest" :options="optionStatusPurchaseRequest" size="sm">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Status</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-col>
                      <b-col md="2">
                          <b-form-select plain v-model="selectPriority" :options="optionPriority" size="sm">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Priority</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-col>
                      <b-col md="3">
                          <b-form-select plain v-model="selectCompany" :options="optionCompany" size="sm">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Company</b-form-select-option>
                              </template>
                          </b-form-select>
                      </b-col>
                      <b-col md="1">
                          <b-button variant="primary" size="sm"><i class="fa fa-search"></i></b-button>
                      </b-col>
                      <b-col md="2">
                          <b-button v-b-tooltip.top="'Add Data'" @click="addPurchaseRequest = !addPurchaseRequest;" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-plus"></i></b-button>
                          <b-button v-b-tooltip.top="'Refresh Data'" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-refresh"></i></b-button>

                          <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                              <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                  <i class="fa fa-save m-0"></i>
                                  </span>
                              </template>
                              <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
                              <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
                          </b-dropdown>
                      </b-col>
                    </b-row>
                  </b-collapse>
                  <div class="table-responsive">
                    <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th class="text-center">REQ NUMBER</th>
                            <th class="text-center">REQ CLASS</th>
                            <th class="text-center">STATUS</th>
                            <th class="text-center">DATE</th>
                            <th class="text-center">COMPANY</th>
                            <th class="text-center">DEPARTMENT</th>
                            <th class="text-center">REQUESTER</th>
                            <th class="text-center">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">
                              <a href="#" @click="detailPurchaseItem = !detailPurchaseItem;">REQ0001</a></td  >
                            <td class="text-center"><b-badge variant="warning w-100">Purchasing</b-badge></td>
                            <td class="text-center"><b-badge variant="primary w-100">Open</b-badge></td>
                            <td class="text-center">01/11/2022</td>
                            <td class="text-center">Massive Dynamic Maritim</td>
                            <td class="text-center">Technical</td>
                            <td class="text-center">Agung Tri Laksono</td>
                            <td class="text-center">
                                <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-list-alt m-0 text-primary"></i>
                                    </span>
                                </template>
                                <b-dropdown-item href="#" variant="dark"><i class="fa fa-edit mr-2"></i>Update</b-dropdown-item>
                                <b-dropdown-item href="#" variant="dark"><i class="fa fa-trash mr-2"></i>Delete</b-dropdown-item>
                                </b-dropdown>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                  <div class="mt-3">
                      <b-pagination :value="1" :total-rows="50" align="center" />
                  </div>

                  <!-- Detail Purchase -->
                  <b-sidebar
                    v-model="detailPurchaseItem"
                    id="detail_vendor"
                    title="REQ001 - TUGBOAT 1001"
                    width="80%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h6 text-primary">General Information</legend>
                            <table class="table table-striped">
                              <tbody>
                                <tr>
                                  <th width="12%">Request Number</th>
                                  <td width="4%">:</td>
                                  <td width="18%">REQ001</td>
                                  
                                  <th width="12%">Company</th>
                                  <td width="3%">:</td>
                                  <td width="18%">Vehement Capital</td>
                                  
                                  <th width="12%">Sub Project</th>
                                  <td width="3%">:</td>
                                  <td width="18%"><b-badge variant="primary">Long Hauling</b-badge></td>
                                </tr>
                                <tr>
                                  <th width="12%">Request Class</th>
                                  <td width="4%">:</td>
                                  <td width="18%">PURCHASE</td>
                                  
                                  <th width="12%">Department</th>
                                  <td width="3%">:</td>
                                  <td width="18%">Operational</td>
                                  
                                  <th width="12%">Location</th>
                                  <td width="3%">:</td>
                                  <td width="18%">Sangatta</td>
                                </tr>
                                <tr>
                                  <th width="12%">Priority</th>
                                  <td width="4%">:</td>
                                  <td width="18%">Normal</td>
                                  
                                  <th width="12%">Fleet</th>
                                  <td width="3%">:</td>
                                  <td width="18%">TUGBOAT 1001</td>
                                  
                                  <th width="12%">Request By</th>
                                  <td width="3%">:</td>
                                  <td width="18%">Nurul Akbar</td>
                                </tr>
                                <tr>
                                  <th width="12%">Request Date</th>
                                  <td width="4%">:</td>
                                  <td width="18%">01/11/2022</td>
                                  
                                  <th width="12%">Project</th>
                                  <td width="3%">:</td>
                                  <td width="18%">Oil Project</td>
                                  
                                  <th width="12%">Description</th>
                                  <td width="3%">:</td>
                                  <td width="18%">Sparepart TUGBOAT 1001</td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h6 text-primary">Item List</legend>
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th class="text-center">INVENTTORY ID</th>
                                  <th class="text-center">DESCRIPTION ITEM</th>
                                  <th class="text-center">QTY</th>
                                  <th class="text-center">UOM</th>
                                  <th class="text-center">REQUIRED DATE</th>
                                  <th class="text-center">PROMISE DATE</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td class="text-center">[M001] - Engine Oil Filter</td>
                                  <td class="text-center">Engine Oil Filter</td>
                                  <td class="text-center">10</td>
                                  <td class="text-center">pcs</td>
                                  <td class="text-center">02/11/2022</td>
                                  <td class="text-center">03/11/2022</td>
                                </tr>
                              </tbody>
                          </table>
                          </fieldset>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>

                  <b-sidebar
                    v-model="addPurchaseRequest"
                    id="addPurchaseRequest"
                    title="ADD PURCHASE REQUEST"
                    width="80%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h5 text-primary">General Information</legend>
                            <b-row class="pl-3 pr-3">
                              <b-col md="4">
                                <b-form>
                                  <b-form-group class="row" label-cols-md="4" label="Request Number" label-for="requestNumber">
                                    <b-form-input id="requestNumber" style="height:40px" placeholder="<< GENERATE NUMBER >>"></b-form-input>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Request Class" label-for="requestClass">
                                    <b-form-select plain v-model="selectRequestClass" :options="optionRequestClass" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Class</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Priority" label-for="requestPriority">
                                    <b-form-select plain v-model="selectPriority" :options="optionPriority" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Status</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Request Date" label-for="requestDate">
                                    <b-form-input type="date" id="requestDate" style="height:40px"></b-form-input>
                                  </b-form-group>
                                </b-form>
                              </b-col>

                              <b-col md="4">
                                <b-form>
                                  <b-form-group class="row" label-cols-md="4" label="Company" label-for="requestCompany">
                                    <b-form-select plain v-model="selectCompany" :options="optionCompany" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Company</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Department" label-for="requestDepartment">
                                    <b-form-select plain v-model="selectDepartment" :options="optionDepartment" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Department</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Fleet" label-for="requestFleet">
                                    <b-form-select plain v-model="selectFleet" :options="optionFleet" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Fleet</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Project" label-for="Project">
                                    <b-form-select plain v-model="selectProject" :options="optionProject" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Project</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </b-form>
                              </b-col>

                              <b-col md="4">
                                <b-form>
                                  <b-form-group class="row" label-cols-md="4" label="Sub Project" label-for="requestSubProject">
                                    <b-form-select plain v-model="selectSubProject" :options="optionSubProject" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Sub Project</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Location" label-for="requestLocation">
                                    <b-form-select plain v-model="selectLocation" :options="optionLocation" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Location</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Request By" label-for="requestBy">
                                    <b-form-select plain v-model="selectRequestBy" :options="optionRequestBy" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Requester</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Description" label-for="description">
                                    <b-form-input type="number" id="description" style="height:40px"></b-form-input>
                                  </b-form-group>
                                </b-form>
                              </b-col>
                            </b-row>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h5 text-primary">Item List</legend>
                              <b-button v-b-tooltip.top="'Add Data'" @click="addPurchaseRequest = !addPurchaseRequest;" variant="primary" class="float-right mr-1 mb-3" size="sm"><i class="fa fa-plus"></i></b-button>
                              <b-button v-b-tooltip.top="'Edit Data'" variant="primary" class="float-right mr-1 mb-3" size="sm"><i class="fa fa-edit"></i></b-button>
                              <b-button v-b-tooltip.top="'Delete Data'" variant="primary" class="float-right mr-1 mb-3" size="sm"><i class="fa fa-trash"></i></b-button>
                              <b-button v-b-tooltip.top="'Refresh Data'" variant="primary" class="float-right mr-1 mb-3" size="sm"><i class="fa fa-refresh"></i></b-button>
                              <div class="table-responsive">
                                <table class="table table-borderless">
                                    <thead>
                                      <tr class="table-success">
                                        <th width="5%" class="text-center">
                                          <template v-for="(item,index) in state">
                                            <b-checkbox class="custom-switch-color" :color="primary" v-model="item.checked" :disabled="item.disabled" name="check-button" inline :key="index">
                                            </b-checkbox>
                                          </template>
                                        </th>
                                        <th width="20%" class="text-center">INVENORY ID</th>
                                        <th width="35x%" class="text-center">DESCRIPTION</th>
                                        <th width="10%" class="text-center">QTY</th>
                                        <th width="10%" class="text-center">UOM</th>
                                        <th width="10%" class="text-center">REQUIRED DATE</th>
                                        <th width="10%" class="text-center">PROMISE DATE</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th class="text-center">
                                          <template v-for="(item,index) in state">
                                            <b-checkbox class="custom-switch-color" :color="primary" v-model="item.checked" :disabled="item.disabled" name="check-button" inline :key="index">
                                            </b-checkbox>
                                          </template>
                                        </th>
                                        <td class="text-center">
                                          <b-form-select plain v-model="selectInventoryID" :options="optionInventoryID">
                                            <template v-slot:first>
                                              <b-form-select-option :value="null">Select Item ID</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </td>
                                        <td class="text-center">Engine Oil Filter</td>
                                        <td class="text-center">
                                          <b-form-input type="number" class="text-right" id="qty" style="height:35px" placeholder="10"></b-form-input>
                                        </td>
                                        <td class="text-center">
                                          <b-form-select plain>
                                            <b-form-select-option>Pcs</b-form-select-option>
                                            <b-form-select-option>Meter</b-form-select-option>
                                            <b-form-select-option>Box</b-form-select-option>
                                            <b-form-select-option>Lusin</b-form-select-option>
                                          </b-form-select>
                                        </td>
                                        <td class="text-center">
                                          <b-form-input type="date" id="requestDate" style="height:40px"></b-form-input>
                                        </td>
                                        <td class="text-center">
                                          <b-form-input type="date" id="requestDate" style="height:40px"></b-form-input>
                                        </td>
                                      </tr>
                                    </tbody>
                                </table>
                              </div>
                          </fieldset>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
              </template>
          </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
export default {
  name: 'procurementPurchaseRequest',
  mounted () {
    xray.index()
  },
  data () {
      return {
        // Sliding Page
        detailPurchaseItem: false,
        addPurchaseRequest: false,

        // Form Option
        selectRequestClass: null,
        optionRequestClass: [
            { value: '1', text: 'Bungker' },
            { value: '2', text: 'Material' },
            { value: '3', text: 'Purchase' },
            { value: '4', text: 'Service' },
            { value: '5', text: 'Travel BOD' },
            { value: '6', text: 'Travel GM' },
            { value: '7', text: 'Travel' },
        ],
        selectStatusPurchaseRequest: null,
        optionStatusPurchaseRequest: [
            { value: '1', text: 'Open' },
            { value: '2', text: 'Pending' },
            { value: '3', text: 'Close' },
            { value: '4', text: 'Reject' },
        ],
        selectPriority: 1,
        optionPriority: [
            { value: '1', text: 'Normal' },
            { value: '2', text: 'Urgent' },
            { value: '3', text: 'Critical' }
        ],
        selectCompany: 1,
        optionCompany: [
            { value: '1', text: 'Vehement Capital Partners' },
            { value: '2', text: 'Umbrella Corporation' },
            { value: '3', text: 'The Acme Corporation' }
        ],
        selectRequestBy: 1,
        optionRequestBy: [
            { value: '1', text: 'Nurul Akbar' },
            { value: '2', text: 'Agung Tri Laksono' },
            { value: '3', text: 'Ananda Pratama' },
            { value: '4', text: 'Dicky Setiawan'}
        ],
        selectFleet: 1,
        optionFleet: [
            { value: '1', text: 'TUGBOAT 1001' },
            { value: '2', text: 'TUGBOAT 1002' },
            { value: '3', text: 'BARGE 2001' },
            { value: '4', text: 'BARGE 2002'}
        ],
        selectProject: 1,
        optionProject: [
            { value: '1', text: 'OIL PROJECT' },
            { value: '2', text: 'COAL PROJECT' },
            { value: '3', text: 'CEMENT PROJECT' },
            { value: '4', text: 'NICKEL PROJECT'}
        ],
        selectSubProject: 2,
        optionSubProject: [
            { value: '1', text: 'LONG HAULING' },
            { value: '2', text: 'TRANSHIPMENT' },
            { value: '3', text: 'ASSIST TUG' }
        ],
        selectLocation: 2,
        optionLocation: [
            { value: '1', text: 'Asam-Asam' },
            { value: '2', text: 'Sangatta' },
            { value: '3', text: 'Bengalon' },
            { value: '4', text: 'Paiton'}
        ],
        selectDepartment: 1,
        optionDepartment: [
            { value: '1', text: 'Operational' },
            { value: '2', text: 'Technical' },
            { value: '3', text: 'Crewing' }
        ],
        selectInventoryID: 1,
        optionInventoryID: [
            { value: '1', text: '[M001] - Engine Oil Filter' },
            { value: '2', text: '[M002] - V Belt Altenator' },
            { value: '3', text: '[M003] - Nozle Injector' }
        ],
        state: [
          {
            label: 'False/Inactive',
            checked: false,
            disabled: false
          }
        ],
      }
  }
}
</script>
