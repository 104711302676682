<template>
  <div>
    <img
      src="https://coderthemes.com/hyper/saas/assets/images/mail_sent.svg"
      width="80"
      alt=""
    />
    <h1 class="mt-3 mb-0">Reset Password.</h1>
    <form class="mt-4" @submit.prevent="doChangePassword">
      <div class="form-group">
        <label>New Password</label>
        <input
          type="password"
          class="form-control mb-0"
          aria-describedby="emailHelp"
          placeholder="Enter New Password"
          v-model="password"
        />
      </div>

      <div class="form-group">
        <label>New Password Confirmation</label>
        <input
          type="password"
          class="form-control mb-0"
          aria-describedby="emailHelp"
          placeholder="Enter New Password Confirmation"
          v-model="confirm_password"
        />
      </div>

      <div class="d-inline-block w-100">
        <button type="submit" class="btn btn-primary float-right">
          <template v-if="!loading"> Reset Password </template>
          <template v-else>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Loading...</span>
          </template>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ConfirmMail1",
  data() {
    return {
      loading: false,
      password: '',
      confirm_password: ''
    }
  },
  methods: {
    ...mapActions("Auth", ["validateForgotToken", "changePassword"]),
    async checkToken() {
      const token = this.$route.query.token;

      let res = await this.validateForgotToken(token);
      if (res.status == "error") {
        this.$swal(
          "Error",
          "The reset password link is expired or not valid.",
          "error"
        );
        this.$router.push({ name: "auth1.reset-password" });
      }
    },
    async doChangePassword() {
      this.loading = true;

      if (!this.password || !this.confirm_password) {
        this.loading = false;
        this.$swal(
          "Error",
          "All Password must be filled",
          "error"
        );
      } else if (this.password !== this.confirm_password) {
        this.loading = false;
        this.$swal(
          "Error",
          "Password confirmation does not match. Please input again.",
          "error"
        );
      } else {
        const token = this.$route.query.token;
        var urlencoded = new URLSearchParams();
        urlencoded.append("password", this.password);

        const payload = {
          token,
          data: urlencoded
        }

        let res = await this.changePassword(payload);
        if (res.status == "success") {
          this.loading = false;
          this.$swal(
            "Password updated!",
            "Please try to login by using your new password.",
            "success"
          );
          this.$router.push({ name: 'auth1.sign-in1' });
        } else {
          this.loading = false;
          if (res.data.message != null)
            this.$swal("Error", res.data.message, "error");
          else
            this.$swal(
              "Error",
              "Something wrong with the server. Please try again later.",
              "error"
            );
        }
      }
    },
  },
  mounted() {
    this.checkToken();
  },
};
</script>
