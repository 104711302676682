<template>
  <b-container fluid>
    <StepNavigation
      current-step="LIST_RCA"
    />
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"
            ><strong>Root Cause Analysis</strong></h4
            >
          </template>
          <template v-slot:headerAction>
            <!-- <b-form-checkbox v-show="!$route.query.preview" name="check-button" v-model="isPreview" switch inline>
              <template>Preview</template>
            </b-form-checkbox> -->
          </template>
          <template v-slot:body>
            <RootAnalysisPreview v-if="isPreview" />
            <RootCauseAnalysisForm v-else/>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import RootCauseAnalysisForm from '@src/views/MI/RootCauseAnalysisForm'
import RootAnalysisPreview from '@src/views/MI/RootCauseAnalysisPreview'
import StepNavigation from '@src/views/MI/component/StepNavigation'

export default {
  name: 'RootCauseAnalysis',
  components: { StepNavigation, RootAnalysisPreview, RootCauseAnalysisForm },
  mounted () {
    if (this.$route.query.preview === 'true' || this.$route.query.preview === true) {
      this.isPreview = true
    }
    xray.index()
  },
  data () {
    return {
      isPreview: false
    }
  }
}
</script>
