var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Bootstrap Datatables")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Images in Bootstrap are made responsive with "), _c('code', [_vm._v(".img-fluid")]), _vm._v(". "), _c('code', [_vm._v("max-width: 100%;")]), _vm._v(" and "), _c('code', [_vm._v("height: auto;")]), _vm._v(" are applied to the image so that it scales with the parent element.")]), _c('b-row', [_c('b-col', {
          staticClass: "table-responsive",
          attrs: {
            "md": "12"
          }
        }, [_c('datatable', {
          staticClass: "table table-striped table-bordered",
          attrs: {
            "columns": _vm.columns,
            "data": _vm.rows,
            "per-page": 25
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }