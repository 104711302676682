var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Default Validation")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _c('form', {
          attrs: {
            "action": "#"
          }
        }, [_c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationDefault01"
          }
        }, [_vm._v("First name")]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "validationDefault01",
            "required": ""
          }
        })]), _c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationDefault02"
          }
        }, [_vm._v("Last name")]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "validationDefault02",
            "required": ""
          }
        })]), _c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationDefaultUsername"
          }
        }, [_vm._v("Username")]), _c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text",
          attrs: {
            "id": "inputGroupPrepend2"
          }
        }, [_vm._v("@")])]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "validationDefaultUsername",
            "aria-describedby": "inputGroupPrepend2",
            "required": ""
          }
        })])]), _c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationDefault03"
          }
        }, [_vm._v("City")]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "validationDefault03",
            "required": ""
          }
        })]), _c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationDefault04"
          }
        }, [_vm._v("State")]), _c('select', {
          staticClass: "form-control",
          attrs: {
            "id": "validationDefault04",
            "required": ""
          }
        }, [_c('option', {
          attrs: {
            "selected": "",
            "disabled": "",
            "value": ""
          }
        }, [_vm._v("Choose...")]), _c('option', [_vm._v("...")])])]), _c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationDefault05"
          }
        }, [_vm._v("Zip")]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "validationDefault05",
            "required": ""
          }
        })])]), _c('div', {
          staticClass: "form-group"
        }, [_c('div', {
          staticClass: "form-check"
        }, [_c('input', {
          staticClass: "form-check-input",
          attrs: {
            "type": "checkbox",
            "value": "",
            "id": "invalidCheck2",
            "required": ""
          }
        }), _c('label', {
          staticClass: "form-check-label",
          attrs: {
            "for": "invalidCheck2"
          }
        }, [_vm._v(" Agree to terms and conditions ")])])]), _c('div', {
          staticClass: "form-group"
        }, [_c('button', {
          staticClass: "btn btn-primary",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v("Submit form")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Custom Validation")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _c('form', {
          staticClass: "needs-validation",
          attrs: {
            "novalidate": "",
            "action": "#"
          }
        }, [_c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationCustom01"
          }
        }, [_vm._v("First name")]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "validationCustom01",
            "required": ""
          }
        }), _c('div', {
          staticClass: "valid-feedback"
        }, [_vm._v(" Looks good! ")])]), _c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationCustom02"
          }
        }, [_vm._v("Last name")]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "validationCustom02",
            "required": ""
          }
        }), _c('div', {
          staticClass: "valid-feedback"
        }, [_vm._v(" Looks good! ")])]), _c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationCustomUsername"
          }
        }, [_vm._v("Username")]), _c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text",
          attrs: {
            "id": "inputGroupPrepend"
          }
        }, [_vm._v("@")])]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "validationCustomUsername",
            "aria-describedby": "inputGroupPrepend",
            "required": ""
          }
        }), _c('div', {
          staticClass: "invalid-feedback"
        }, [_vm._v(" Please choose a username. ")])])]), _c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationCustom03"
          }
        }, [_vm._v("City")]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "validationCustom03",
            "required": ""
          }
        }), _c('div', {
          staticClass: "invalid-feedback"
        }, [_vm._v(" Please provide a valid city. ")])]), _c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationCustom04"
          }
        }, [_vm._v("State")]), _c('select', {
          staticClass: "form-control",
          attrs: {
            "id": "validationCustom04",
            "required": ""
          }
        }, [_c('option', {
          attrs: {
            "selected": "",
            "disabled": "",
            "value": ""
          }
        }, [_vm._v("Choose...")]), _c('option', [_vm._v("...")])]), _c('div', {
          staticClass: "invalid-feedback"
        }, [_vm._v(" Please select a valid state. ")])]), _c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationCustom05"
          }
        }, [_vm._v("Zip")]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "validationCustom05",
            "required": ""
          }
        }), _c('div', {
          staticClass: "invalid-feedback"
        }, [_vm._v(" Please provide a valid zip. ")])])]), _c('div', {
          staticClass: "form-group"
        }, [_c('div', {
          staticClass: "form-check"
        }, [_c('input', {
          staticClass: "form-check-input",
          attrs: {
            "type": "checkbox",
            "value": "",
            "id": "invalidCheck",
            "required": ""
          }
        }), _c('label', {
          staticClass: "form-check-label",
          attrs: {
            "for": "invalidCheck"
          }
        }, [_vm._v(" Agree to terms and conditions ")]), _c('div', {
          staticClass: "invalid-feedback"
        }, [_vm._v(" You must agree before submitting. ")])])]), _c('button', {
          staticClass: "btn btn-primary",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v("Submit form")])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Supported elements")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _c('form', {
          staticClass: "was-validated",
          attrs: {
            "action": "#"
          }
        }, [_c('div', {
          staticClass: "mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationTextarea"
          }
        }, [_vm._v("Textarea")]), _c('textarea', {
          staticClass: "form-control is-invalid",
          attrs: {
            "id": "validationTextarea",
            "placeholder": "Required example textarea",
            "required": ""
          }
        }), _c('div', {
          staticClass: "invalid-feedback"
        }, [_vm._v(" Please enter a message in the textarea. ")])]), _c('div', {
          staticClass: "custom-control custom-checkbox mb-3"
        }, [_c('input', {
          staticClass: "custom-control-input",
          attrs: {
            "type": "checkbox",
            "id": "customControlValidation1",
            "required": ""
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": "customControlValidation1"
          }
        }, [_vm._v("Check this custom checkbox")]), _c('div', {
          staticClass: "invalid-feedback"
        }, [_vm._v("Example invalid feedback text")])]), _c('div', {
          staticClass: "custom-control custom-radio"
        }, [_c('input', {
          staticClass: "custom-control-input",
          attrs: {
            "type": "radio",
            "id": "customControlValidation2",
            "name": "radio-stacked",
            "required": ""
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": "customControlValidation2"
          }
        }, [_vm._v("Toggle this custom radio")])]), _c('div', {
          staticClass: "custom-control custom-radio mb-3"
        }, [_c('input', {
          staticClass: "custom-control-input",
          attrs: {
            "type": "radio",
            "id": "customControlValidation3",
            "name": "radio-stacked",
            "required": ""
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": "customControlValidation3"
          }
        }, [_vm._v("Or toggle this other custom radio")]), _c('div', {
          staticClass: "invalid-feedback"
        }, [_vm._v("More example invalid feedback text")])]), _c('div', {
          staticClass: "form-group"
        }, [_c('select', {
          staticClass: "custom-select",
          attrs: {
            "required": ""
          }
        }, [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("Open this select menu")]), _c('option', {
          attrs: {
            "value": "1"
          }
        }, [_vm._v("One")]), _c('option', {
          attrs: {
            "value": "2"
          }
        }, [_vm._v("Two")]), _c('option', {
          attrs: {
            "value": "3"
          }
        }, [_vm._v("Three")])]), _c('div', {
          staticClass: "invalid-feedback"
        }, [_vm._v("Example invalid custom select feedback")])]), _c('div', {
          staticClass: "custom-file"
        }, [_c('input', {
          staticClass: "custom-file-input",
          attrs: {
            "type": "file",
            "id": "validatedCustomFile",
            "required": ""
          }
        }), _c('label', {
          staticClass: "custom-file-label",
          attrs: {
            "for": "validatedCustomFile"
          }
        }, [_vm._v("Choose file...")]), _c('div', {
          staticClass: "invalid-feedback"
        }, [_vm._v("Example invalid custom file feedback")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Tooltip")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p'), _c('form', {
          staticClass: "needs-validation",
          attrs: {
            "novalidate": "",
            "action": "#"
          }
        }, [_c('div', {
          staticClass: "form-row"
        }, [_c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationTooltip01"
          }
        }, [_vm._v("First name")]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "validationTooltip01",
            "value": "Mark",
            "required": ""
          }
        }), _c('div', {
          staticClass: "valid-tooltip"
        }, [_vm._v(" Looks good! ")])]), _c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationTooltip02"
          }
        }, [_vm._v("Last name")]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "validationTooltip02",
            "value": "Jhon",
            "required": ""
          }
        }), _c('div', {
          staticClass: "valid-tooltip"
        }, [_vm._v(" Looks good! ")])]), _c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationTooltipUsername"
          }
        }, [_vm._v("Username")]), _c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text",
          attrs: {
            "id": "validationTooltipUsernamePrepend"
          }
        }, [_vm._v("@")])]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "validationTooltipUsername",
            "aria-describedby": "validationTooltipUsernamePrepend",
            "required": ""
          }
        }), _c('div', {
          staticClass: "invalid-tooltip"
        }, [_vm._v(" Please choose a unique and valid username. ")])])]), _c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationTooltip03"
          }
        }, [_vm._v("City")]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "validationTooltip03",
            "required": ""
          }
        }), _c('div', {
          staticClass: "invalid-tooltip"
        }, [_vm._v(" Please provide a valid city. ")])]), _c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationTooltip04"
          }
        }, [_vm._v("State")]), _c('select', {
          staticClass: "form-control",
          attrs: {
            "id": "validationTooltip04",
            "required": ""
          }
        }, [_c('option', {
          attrs: {
            "selected": "",
            "disabled": "",
            "value": ""
          }
        }, [_vm._v("Choose...")]), _c('option', [_vm._v("...")])]), _c('div', {
          staticClass: "invalid-tooltip"
        }, [_vm._v(" Please select a valid state. ")])]), _c('div', {
          staticClass: "col-md-6 mb-3"
        }, [_c('label', {
          attrs: {
            "for": "validationTooltip05"
          }
        }, [_vm._v("Zip")]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "validationTooltip05",
            "required": ""
          }
        }), _c('div', {
          staticClass: "invalid-tooltip"
        }, [_vm._v(" Please provide a valid zip. ")])])]), _c('button', {
          staticClass: "btn btn-primary",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v("Submit form")])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }