import axios from './auth'

const FILE_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/files`;

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
    async upload({ commit }, payload) {
        const url = `${FILE_BASE_URL}/upload`;

        const method = "post";
        const headers = {
            "Content-Type": "multipart/form-data"
        };
        const config = {
            url,
            method,
            data: payload,
            headers
        };

        try {
            const response = await axios(config);
            let data = response.data.data;
            return {
                status: 'success',
                data,
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
}
