var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Unicons Icon")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Use Class "), _c('code', [_vm._v("<i class=\"ion-archive\"></i>")])]), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-archive"
        }), _vm._v("Archive")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-reply"
        }), _vm._v("Reply")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-reply-all"
        }), _vm._v("Reply All")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-forward"
        }), _vm._v("Forward")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-play"
        }), _vm._v("Play")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-volume-high"
        }), _vm._v("Volume High")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-pricetags"
        }), _vm._v("Pricetags")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-pause"
        }), _vm._v("Pause")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-briefcase"
        }), _vm._v("Briefcase")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-medkit"
        }), _vm._v("Medkit")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-at"
        }), _vm._v("At")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-pound"
        }), _vm._v("Pound")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-asterisk"
        }), _vm._v("Asterisk")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-alert"
        }), _vm._v("Alert")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-alert-circled"
        }), _vm._v("Alert Circled")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-refresh"
        }), _vm._v("Refresh")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-loop"
        }), _vm._v("Loop")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-shuffle"
        }), _vm._v("Shuffle")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-home"
        }), _vm._v("Home")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-search"
        }), _vm._v("Search")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-flag"
        }), _vm._v("Flag")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-heart"
        }), _vm._v("Heart")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-heart-broken"
        }), _vm._v("Broken")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-gear-a"
        }), _vm._v("Gear")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-gear-b"
        }), _vm._v("Gear bold")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-toggle-filled"
        }), _vm._v("Toggle")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-trash-b"
        }), _vm._v("Trash fill")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-hammer"
        }), _vm._v("Hammer")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-wrench"
        }), _vm._v("Wrench")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-information-circled"
        }), _vm._v("Information")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-help-circled"
        }), _vm._v("Help")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-plus-circled"
        }), _vm._v("Plush")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-close-circled"
        }), _vm._v("Close")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-email-unread"
        }), _vm._v("Email")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-upload"
        }), _vm._v("Upload")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-soup-can"
        }), _vm._v("Database")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-thumbsup"
        }), _vm._v("Thumbsup")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-thumbsdown"
        }), _vm._v("Thumbsdown")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-pricetag"
        }), _vm._v("Pricetag")])], 1), _c('b-col', {
          attrs: {
            "lg": "2",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ion-bowtie"
        }), _vm._v("Bowtie")])], 1), _c('b-col', {
          staticClass: "text-center mt-3",
          attrs: {
            "sm": "12"
          }
        }, [_c('b-link', {
          staticClass: "btn btn-primary",
          attrs: {
            "href": "https://www.unicon.net/",
            "target": "blank"
          }
        }, [_vm._v("View All Icon")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }