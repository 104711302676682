var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "iq-timeline"
  }, _vm._l(_vm.items, function (item, index) {
    return _c('li', {
      key: index
    }, [_c('div', {
      staticClass: "timeline-dots",
      class: 'border-' + item.color
    }), _c('h6', {
      staticClass: "float-left mb-1",
      domProps: {
        "innerHTML": _vm._s(item.title)
      }
    }), _c('small', {
      staticClass: "float-right mt-1",
      domProps: {
        "innerHTML": _vm._s(item.right)
      }
    }), _c('div', {
      staticClass: "d-inline-block w-100"
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(item.description)
      }
    }), item.child.items.length > 0 && item.child.type === 'img' ? [_c('div', {
      staticClass: "iq-media-group"
    }, _vm._l(item.child.items, function (childItem, childIndex) {
      return _c('a', {
        key: childIndex,
        staticClass: "iq-media",
        attrs: {
          "href": "#"
        }
      }, [_c('img', {
        staticClass: "img-fluid avatar-40 rounded-circle",
        attrs: {
          "src": childItem,
          "alt": "img"
        }
      })]);
    }), 0)] : _vm._e()], 2)]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }