var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Editable Table")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.add
          }
        }, [_vm._v("Add New")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "table-responsive",
          attrs: {
            "md": "12"
          }
        }, [_c('b-table', {
          attrs: {
            "bordered": "",
            "hover": "",
            "items": _vm.rows,
            "fields": _vm.columns,
            "foot-clone": ""
          },
          scopedSlots: _vm._u([{
            key: "cell(name)",
            fn: function fn(data) {
              return [!data.item.editable ? _c('span', [_vm._v(_vm._s(data.item.name))]) : _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: data.item.name,
                  expression: "data.item.name"
                }],
                staticClass: "form-control",
                attrs: {
                  "type": "text"
                },
                domProps: {
                  "value": data.item.name
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) return;
                    _vm.$set(data.item, "name", $event.target.value);
                  }
                }
              })];
            }
          }, {
            key: "cell(position)",
            fn: function fn(data) {
              return [!data.item.editable ? _c('span', [_vm._v(_vm._s(data.item.position))]) : _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: data.item.position,
                  expression: "data.item.position"
                }],
                staticClass: "form-control",
                attrs: {
                  "type": "text"
                },
                domProps: {
                  "value": data.item.position
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) return;
                    _vm.$set(data.item, "position", $event.target.value);
                  }
                }
              })];
            }
          }, {
            key: "cell(office)",
            fn: function fn(data) {
              return [!data.item.editable ? _c('span', [_vm._v(_vm._s(data.item.office))]) : _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: data.item.office,
                  expression: "data.item.office"
                }],
                staticClass: "form-control",
                attrs: {
                  "type": "text"
                },
                domProps: {
                  "value": data.item.office
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) return;
                    _vm.$set(data.item, "office", $event.target.value);
                  }
                }
              })];
            }
          }, {
            key: "cell(age)",
            fn: function fn(data) {
              return [!data.item.editable ? _c('span', [_vm._v(_vm._s(data.item.age))]) : _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: data.item.age,
                  expression: "data.item.age"
                }],
                staticClass: "form-control",
                attrs: {
                  "type": "text"
                },
                domProps: {
                  "value": data.item.age
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) return;
                    _vm.$set(data.item, "age", $event.target.value);
                  }
                }
              })];
            }
          }, {
            key: "cell(start_date)",
            fn: function fn(data) {
              return [!data.item.editable ? _c('span', [_vm._v(_vm._s(data.item.start_date))]) : _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: data.item.start_date,
                  expression: "data.item.start_date"
                }],
                staticClass: "form-control",
                attrs: {
                  "type": "text"
                },
                domProps: {
                  "value": data.item.start_date
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) return;
                    _vm.$set(data.item, "start_date", $event.target.value);
                  }
                }
              })];
            }
          }, {
            key: "cell(salary)",
            fn: function fn(data) {
              return [!data.item.editable ? _c('span', [_vm._v(_vm._s(data.item.salary))]) : _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: data.item.salary,
                  expression: "data.item.salary"
                }],
                staticClass: "form-control",
                attrs: {
                  "type": "text"
                },
                domProps: {
                  "value": data.item.salary
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) return;
                    _vm.$set(data.item, "salary", $event.target.value);
                  }
                }
              })];
            }
          }, {
            key: "cell(action)",
            fn: function fn(data) {
              return [!data.item.editable ? _c('b-button', {
                attrs: {
                  "variant": " iq-bg-success mr-1 mb-1",
                  "size": "sm"
                },
                on: {
                  "click": function click($event) {
                    return _vm.edit(data.item);
                  }
                }
              }, [_c('i', {
                staticClass: "ri-ball-pen-fill m-0"
              })]) : _c('b-button', {
                attrs: {
                  "variant": " iq-bg-success mr-1 mb-1",
                  "size": "sm"
                },
                on: {
                  "click": function click($event) {
                    return _vm.submit(data.item);
                  }
                }
              }, [_vm._v("Ok")]), _c('b-button', {
                attrs: {
                  "variant": " iq-bg-danger",
                  "size": "sm"
                },
                on: {
                  "click": function click($event) {
                    return _vm.remove(data.item);
                  }
                }
              }, [_c('i', {
                staticClass: "ri-delete-bin-line m-0"
              })])];
            }
          }])
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }