<template>
  <b-container fluid>
    <b-row>
        <b-col lg="12">
            <iq-card>
                <template v-slot:headerTitle>
                    <h4 class="card-title text-primary"><strong>LAPORAN CONDITE CREW  / <i>CONDITE CREW REPORT</i></strong></h4>
                </template>
                <template v-slot:body>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Crew Information</legend>
                    <b-row>
                      <b-col md="10">
                        <table class="table table-striped mt-2">
                          <tbody>
                            <tr>
                              <th width="20%">Nama Crew</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">Nurul Akbar</td>
                              <th width="20%">No KTP</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">3174050507930002</td>
                            </tr>
                            <tr>
                              <th width="20%">Tempat/Tanggal Lahir</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">Jakarta / 05 Juli 1993</td>
                              <th width="20%">No NPWP</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">4151 2010 0036</td>
                            </tr>
                            <tr>
                              <th width="20%">Jabatan</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">Master</td>
                              <th width="20%">ID Crew</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">TCP202138001</td>
                            </tr>
                            <tr>
                              <th width="20%">Perusahaan</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">Transcoal Pacific</td>
                              <th width="20%">Seaferer Code</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">33273 99328 01</td>
                            </tr>
                            <tr>
                              <th width="20%">Nama Kapal</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">TB. TCP 201</td>
                              <th width="20%">Seaman Book</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">3275 23366 2177</td>
                            </tr>
                            <tr>
                              <th width="20%">Pelabuhan</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">Bengalon</td>
                              <th width="20%">No Passport</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">32761 121133 01</td>
                            </tr>
                            <tr>
                              <th width="20%">Sign On</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">15 Desember 2021</td>
                              <th width="20%">Nomor Tlp / Hp</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">0812 1214 3758</td>
                            </tr>
                            <tr>
                              <th width="20%">Join Onboard</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">17 Desember 2021</td>
                              <th width="20%">Email</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">nurulakbar93@gmail.com</td>
                            </tr>
                            <tr>
                              <th width="20%">Sign Off</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">15 Desember 2022</td>
                              <th width="20%">Periode Penilaian</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">
                                <b-form-select plain v-model="levelSelected" :options="evaluasi" size="sm">
                                  <template v-slot:first>
                                    <b-form-select-option :value="null">Pilih Periode</b-form-select-option>
                                  </template>
                                </b-form-select>
                              </td>
                            </tr>
                            <tr>
                              <th width="20%">Alamat</th>
                              <td width="5%" class="text-center">:</td>
                              <td colspan="2">
                                Jl. Pondok Pinang 3, RT.005, RW.002 No. 38, Kel. Pondok Pinang, Kec. Kebayoran Lama, Jakarta Selatan, DKI Jakarat (12310)
                              </td>
                              <td width="5%" class="text-center">&nbsp;</td>
                              <td width="25%">&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>
                      </b-col>
                      <b-col md="2" class="text-center">
                        <b-img thumbnail class="ml-0" :src="require('../../assets/images/page-img/10.jpg')" alt="Responsive image"></b-img>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Form Condite</legend>
                    <b-row class="mt-3">
                      <b-col md="12" class="mb-3">
                        <table class="table table-striped">
                            <thead>
                                <tr class="text-center">
                                    <th width="18%" rowspan="2" class="align-middle">URAIAN</th>
                                    <th width="15%">NILAI Poin = 1</th>
                                    <th width="15%">Nilai Poin = 2</th>
                                    <th width="15%">Nilai Poin = 3</th>
                                    <th width="15%">Nilai Poin = 4</th>
                                    <th width="15%">Nilai Poin = 5</th>
                                    <th width="12%" rowspan="2" class="align-middle">POIN</th>
                                </tr>
                                <tr class="text-center">
                                    <th>Kurang Sekali</th>
                                    <th>Kurang</th>
                                    <th>Cukup</th>
                                    <th>Baik</th>
                                    <th>Sangat Baik</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="font-size:9pt">
                                    <th>
                                        Pengetahuan Dalam Bekerja / Job knowledge
                                    </th>
                                    <td>
                                        Sedikit dan tidak ada kemauan untuk belajar / Little and not willing to learn 
                                    </td>
                                    <td>
                                        Sedikit tetapi ada kemauan untuk belajar / Little but willing to learn 
                                    </td>
                                    <td>
                                        Pengetahuan sedang dalam pekerjaan / Average knowledge in his job
                                    </td>
                                    <td>
                                        Mengetahui dalam beberapa hal / Substansial knowledge in his job
                                    </td>
                                    <td>
                                        Mengetahui secara menyeluruh / Comprehensive knowledge in his job
                                    </td>
                                    <td class="text-center">
                                        <b-form-select plain v-model="nilaiSelect" :options="nilai" size="sm" class="col-md-12">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">Nilai</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </td>
                                </tr>
  
                                <tr style="font-size:9pt">
                                    <th>
                                      Kerja Sama / Cooperation
                                    </th>
                                    <td>
                                        Tidak bisa bekerja sama / Not cooperative with others
                                    </td>
                                    <td>
                                        Kerapkali bertengkar dengan yang lain / Often quarrel with others
                                    </td>
                                    <td>
                                        Tidak mempunyai perbedaan yang besar terhadap yang lain / Don't have distinction with others
                                    </td>
                                    <td>
                                        Bekerjasama, ramah / Cooperative, friendly
                                    </td>
                                    <td>
                                        Mau bergaul, bekerjasama, ramah dan menolong / Easy to work with, friendly and helpful
                                    </td>
                                    <td class="text-center">
                                        <b-form-select plain v-model="nilaiSelect" :options="nilai" size="sm" class="col-md-12">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">Nilai</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </td>
                                </tr>
  
                                <tr style="font-size:9pt">
                                    <th>
                                      Kesungguhan Dalam Bekerja / Working Commitment
                                    </th>
                                    <td>
                                        Malas dan tidak mau didorong oleh atasannya / Lazy and not motivated
                                    </td>
                                    <td>
                                        Harus selalu diawasi dan didorong setiap waktu / Always need supervision and motivation
                                    </td>
                                    <td>
                                        Ada kemauan untuk bekerja tetapi lambat / Willinga to work but slow
                                    </td>
                                    <td>
                                      Selalu bekerja dengan baik / Always work well
                                    </td>
                                    <td>
                                        Bekerja sungguh-sungguh dan rajin setiap saat / Always make an effort and diligent in his work
                                    </td>
                                    <td class="text-center">
                                        <b-form-select plain v-model="nilaiSelect" :options="nilai" size="sm" class="col-md-12">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">Nilai</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </td>
                                </tr>
  
                                <tr style="font-size:9pt">
                                    <th>
                                      Kedisiplinan / Discipline
                                    </th>
                                    <td>
                                      Tidak disiplin dan suka menghasut orang lain / Not discipline and like to provoke others
                                    </td>
                                    <td>
                                        Kurang disiplin dan cepat tersinggung / Less discipline and easily offended
                                    </td>
                                    <td>
                                        Disiplin sedang tapi semangat kerja bagus / Average discipline but good working spirit
                                    </td>
                                    <td>
                                      Disiplin dengan baik / Good discipline
                                    </td>
                                    <td>
                                        Disiplin dengan baik dan tidak pernah bermasalah / Good disincline and free of trouble
                                    </td>
                                    <td class="text-center">
                                        <b-form-select plain v-model="nilaiSelect" :options="nilai" size="sm" class="col-md-12">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">Nilai</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </td>
                                </tr>
  
                                <tr style="font-size:9pt">
                                    <th>
                                      Sikap Terhadap Kebijakan Perusahaan / Attitude Towards the Company Policies
                                    </th>
                                    <td>
                                      Terus menerus mengeluh atas kebijakan perusahaan / Keep complaining regarding the company policies
                                    </td>
                                    <td>
                                        Mau menerima kebijakan tetapi membutuhkan pertimbangan keuntungan / Accept the policies but considers their own benefit
                                    </td>
                                    <td>
                                        Dapat menerima kebijakan perusahaan / Accept the policies
                                    </td>
                                    <td>
                                      Menerima kebijakan perusahaan dengan rela / Willingly to accept the policies
                                    </td>
                                    <td>
                                        Simpatik terhadap kebijakan perusahaan / Sympathy toward the policies
                                    </td>
                                    <td class="text-center">
                                        <b-form-select plain v-model="nilaiSelect" :options="nilai" size="sm" class="col-md-12">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">Nilai</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </td>
                                </tr>
  
                                <tr style="font-size:9pt">
                                    <th>
                                      Kesetiaan (Loyalitas) / Loyalty
                                    </th>
                                    <td>
                                      Selalu menjelekkan dan antipati terhadap perusahaan / Always discredit and antipathy towards the company
                                    </td>
                                    <td>
                                        Berkata jelek tentang perusahaan / Say bad thing about the company
                                    </td>
                                    <td>
                                        Mempunyai sikap ragu terhadap perusahaan / Have doubt towards the company
                                    </td>
                                    <td>
                                      Setia dan tidak pernah bicara jelek tentang perusahaan / Loyal and never say bad thing about the company
                                    </td>
                                    <td>
                                      Sangat setia dan selalu mendahulukan perusahaan / Very loyal and always put company first
                                    </td>
                                    <td class="text-center">
                                        <b-form-select plain v-model="nilaiSelect" :options="nilai" size="sm" class="col-md-12">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">Nilai</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </td>
                                </tr>
  
                                <tr style="font-size:9pt">
                                    <th>
                                      Tanggung Jawab / Responsibilities
                                    </th>
                                    <td>
                                      Tidak pernah melaksanakan tanggung jawab penuh / never take responsibilities
                                    </td>
                                    <td>
                                        Mau bekerja jika diawasi / willing to work only under supervision
                                    </td>
                                    <td>
                                        Harus selalu diperiksa secara berkala, umumnya bisa diterima / Must be monitored occasionally, acceptable in general
                                    </td>
                                    <td>
                                      Dapat diandalkan dalam bekerja / Reliable in his job
                                    </td>
                                    <td>
                                      Sangat bertanggung jawab / Very responsible
                                    </td>
                                    <td class="text-center">
                                        <b-form-select plain v-model="nilaiSelect" :options="nilai" size="sm" class="col-md-12">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">Nilai</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                    </td>
                                </tr> 
                            </tbody>
                        </table>
                      </b-col>
                      <b-col md="4">
                        <small class="mb-3"><strong>Level 1 - FPD <br><i>Tingkat 1 - FPD</i></strong></small>
                        <hr>
                        <div class="form-group row">
                          <label label="Clasification *" for="nama_kandidiat" class="col-sm-6">Wawancara Oleh<br><i>Interview by</i></label>
                          <div class="col-sm-6">
                            <b-form-input id="job_name" type="text" placeholder="Nama"></b-form-input>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label label="Clasification *" for="nama_kandidiat" class="col-sm-6">Hasil Wawancara <br><i>Result Interview</i></label>
                          <div class="col-sm-6">
                            <b-form-select plain v-model="wawancaraSelected" :options="wawancara" size="lg">
                                <template v-slot:first>
                                <b-form-select-option :value="null">Hasil Wawancara</b-form-select-option>
                                </template>
                            </b-form-select>
                          </div>
                        </div>
                        <b-form-textarea rows="2" value="Comments if any / Komentar jika ada"></b-form-textarea>
                      </b-col>
                      <b-col md="4">
                        <small class="mb-3"><strong>Level 2 - Nav Committtee / Operation (Only for Master) <br><i>Tingkat 2 - Komite Nav / Operasional (hanya untuk Nakhoda)</i></strong></small>
                        <hr>
                        <div class="form-group row">
                          <label label="Clasification *" for="nama_kandidiat" class="col-sm-6">Wawancara Oleh<br><i>Interview by</i></label>
                          <div class="col-sm-6">
                            <b-form-input id="job_name" type="text" placeholder="Nama"></b-form-input>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label label="Clasification *" for="nama_kandidiat" class="col-sm-6">Hasil Wawancara <br><i>Result Interview</i></label>
                          <div class="col-sm-6">
                            <b-form-select plain v-model="wawancaraSelected" :options="wawancara" size="lg">
                                <template v-slot:first>
                                <b-form-select-option :value="null">Hasil Wawancara</b-form-select-option>
                                </template>
                            </b-form-select>
                          </div>
                        </div>
                        <b-form-textarea rows="2" value="Comments if any / Komentar jika ada"></b-form-textarea>
                      </b-col>
                      <b-col md="4">
                        <small class="mb-3"><strong>Level 3 - Technical (only for senior Officer) <br><i>Level 3 - Teknik (hanya untuk senior officer)</i></strong></small>
                        <hr>
                        <div class="form-group row">
                          <label label="Clasification *" for="nama_kandidiat" class="col-sm-6">Wawancara Oleh<br><i>Interview by</i></label>
                          <div class="col-sm-6">
                            <b-form-input id="job_name" type="text" placeholder="Nama"></b-form-input>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label label="Clasification *" for="nama_kandidiat" class="col-sm-6">Hasil Wawancara <br><i>Result Interview</i></label>
                          <div class="col-sm-6">
                            <b-form-select plain v-model="wawancaraSelected" :options="wawancara" size="lg">
                                <template v-slot:first>
                                <b-form-select-option :value="null">Hasil Wawancara</b-form-select-option>
                                </template>
                            </b-form-select>
                          </div>
                        </div>
                        <b-form-textarea rows="2" value="Comments if any / Komentar jika ada"></b-form-textarea>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <b-row class="mt-3">
                    <b-col md="12" class="mt-3">
                      <b-button type="submit" variant="primary" class="float-right ml-3"><i class="fa fa-save"></i> Save</b-button>
                      <b-button type="reset" variant="none" class="iq-bg-danger float-right"><i class="fa fa-close"></i> Reset Form</b-button>
                    </b-col>
                  </b-row>
                </template>
            </iq-card>
        </b-col>
    </b-row>
  </b-container>
  </template>
  <script>
  import { xray } from '../../config/pluginInit'
  import _ from 'lodash'
  import {
    fleetsActions
  } from '@src/Utils/helper'
  import { createPopper } from '@popperjs/core'
  
  export default {
    name: 'ProfileEdit',
    mounted () {
      xray.index()
      this.fetchFleets()
    },
    data () {
      return { 
        levelSelected: null,
        level: [
          { value: '1', text: 'Master' },
          { value: '2', text: 'Chief Office' },
          { value: '3', text: 'Second Officer' },
          { value: '4', text: 'Chief Engineer' },
          { value: '5', text: 'Second Engineer' },
          { value: '6', text: 'ABK' }
        ],
        wawancaraSelected: 1,
        wawancara: [
          { value: '1', text: 'Suitable / Cocok' },
          { value: '2', text: 'Not Suitable / Tidak Cocok' }
        ],
        evaluasi: [
          { value: '1', text: '3 Bulan Percobaan' },
          { value: '2', text: '2 Bulan Sebelum PKL' },
          { value: '3', text: 'Khusus' },
        ],
        nilaiSelect: 1,
        nilai: [
          { value: '1', text: 'A' },
          { value: '2', text: 'B' },
          { value: '3', text: 'C' },
          { value: '4', text: 'D' },
        ],
        text: [
          {
            activeLabel: 'Yes',
            inactiveLabel: 'No',
            class: 'primary',
            checked: false
          }
        ]
      }
    }
  }
  </script>