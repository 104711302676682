import axios from './auth'

const MI_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/mi/`

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getTypeCoverages ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}type-coverage`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getTypeLoss ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}type-loss`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getDepartments ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}department`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getStatuses ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}form-status`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getInsurers ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}insurer-club`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },

  async getMi ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}form`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async exportInitial ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}form`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      responseType: 'blob',
      params
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async fetchDetailMi ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}form/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async saveMi ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}form`

    const method = 'post'
    const headers = {
    }
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
  async updateMi ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}form/${payload.id}`

    const method = 'put'
    const headers = {
    }
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
  async deleteMi ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}form/${payload.id}`

    const method = 'delete'
    const headers = {
      'Content-Type': 'multipart/form-data'
    }
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
  async updateDocumentSupport ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}form/${payload.get('form_id')}/document/${payload.get('document_id')}`

    const method = 'post'
    const headers = {
      'Content-Type': 'multipart/form-data'
    }
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async getMasterSurvey ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}survey`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getMasterSurveyDetail ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}master-survey/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async saveMasterSurvey ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}survey`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
  async updateMasterSurvey ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}master-survey/${payload.get('id')}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
  async deleteMasterSurvey ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}master-survey/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },

  async getMasterInputSurvey ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}input-survey`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },

  async getMasterCostBreakdown ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}cost-breakdown`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getMasterCostBreakdownDetail ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}cost-breakdown/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async saveMasterCostBreakdown ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}cost-breakdown`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
  async updateMasterCostBreakdown ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}cost-breakdown/${payload.get('id')}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
  async deleteMasterCostBreakdown ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}cost-breakdown/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },

  async saveClaimAnalysis ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}form/${payload.form_id}/claim-analysis`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
  async getClaimAnalysisByFormId ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}claim-analysis/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getFormClaimAnalysisByFormId ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}form/${params.id}/claim-analysis`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getCostBreakdownByFormId ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}costbreakdown-input/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async saveCostBreakdown ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}costbreakdown-input`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },

  async getMasterPureRisk ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}pure-risk`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getMasterPureRiskDetail ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}pure-risk/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async updateMasterPureRisk ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}pure-risk/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
  async saveMasterPureRisk ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}pure-risk`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
  async deleteMasterPureRisk ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}pure-risk/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },

  async getMasterPureRiskItem ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}pure-risk-item`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getMasterPureRiskItemDetail ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}pure-risk-item/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async updateMasterPureRiskItem ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}pure-risk-item/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
  async saveMasterPureRiskItem ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}pure-risk-item`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
  async deleteMasterPureRiskItem ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}pure-risk-item/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
  async getRCAFormModel ({ commit }) {
    const url = `${MI_BASE_URL}form-rca/model`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getRCA ({ commit }, id) {
    const url = `${MI_BASE_URL}form-rca/${id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async createRCA ({ commit }, payload) {
    const url = `${MI_BASE_URL}form-rca`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async editRCA ({ commit }, payload) {
    const url = `${MI_BASE_URL}form-rca/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async deleteRCA ({ commit }, id) {
    const url = `${MI_BASE_URL}form-rca/${id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
  async getRCAFinal ({ commit }, formId) {
    const url = `${MI_BASE_URL}form/${formId}/rca-final`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async createRCAFinal ({ commit }, payload) {
    const url = `${MI_BASE_URL}form/${payload.form_id}/rca-final`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async editRCAFinal ({ commit }, payload) {
    const url = `${MI_BASE_URL}form-rca-final/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteRCAFinal ({ commit }, id) {
    const url = `${MI_BASE_URL}form-rca-final/${id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
  async getMasterQuestions ({ commit }) {
    const url = `${MI_BASE_URL}question`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getMasterCauseOfLoss ({ commit }) {
    const url = `${MI_BASE_URL}cause-of-loss`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getMonitoring ({ commit }, params) {
    const url = `${MI_BASE_URL}list-monitoring`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async saveMonitoring ({ commit }, payload) {
    const url = `${MI_BASE_URL}form/${payload.form_id}/monitoring`
    delete payload.form_id

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async editMonitoring ({ commit }, payload) {
    const url = `${MI_BASE_URL}form/${payload.form_id}/monitoring/${payload.id}`
    delete payload.form_id
    delete payload.id

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async deleteMonitoring ({ commit }, params) {
    const url = `${MI_BASE_URL}form/${params.form_id}/monitoring/${params.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
  async getRecommendation ({ commit }, params) {
    let url
    if (params.form_id) {
      url = `${MI_BASE_URL}form/${params.form_id}/recommendation`
    } else if (params.id) {
      url = `${MI_BASE_URL}form-recommendation/${params.id}`
    }

    delete params.form_id

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async saveRecommendation ({ commit }, payload) {
    let url

    if (payload.form_id) {
      url = `${MI_BASE_URL}form/${payload.form_id}/recommendation`
    } else {
      url = `${MI_BASE_URL}form-recommendation`
    }

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async updateRecommendation ({ commit }, payload) {
    const url = `${MI_BASE_URL}form-recommendation/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async deleteRecommendation ({ commit }, id) {
    let url = `${MI_BASE_URL}form-recommendation/${id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getEvaluation ({ commit }, params) {
    const url = `${MI_BASE_URL}form/${params.form_id}/evaluation`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async saveEvaluation ({ commit }, payload) {
    const url = `${MI_BASE_URL}form/${payload.form_id}/evaluation`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getMasterClassificationOfLoss ({ commit }, payload) {
    const url = `${MI_BASE_URL}classification-of-loss`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getMasterAllErm ({ commit }, payload) {
    const url = `${MI_BASE_URL}/erm`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: payload
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async saveERM ({ commit }, payload) {
    const url = `${MI_BASE_URL}form/${payload.form_id}/erm`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async editERM ({ commit }, payload) {
    const url = `${MI_BASE_URL}erm/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },

  async getLossRatio ({ commit }, payload) {
    // todo wait api
    const url = `${MI_BASE_URL}form/model/lossratio`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async getLossRatioInput ({ commit }, payload) {
    const url = `${MI_BASE_URL}form/model/lossratio-input`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async saveLossRatio ({ commit }, payload) {
    const url = `${MI_BASE_URL}form/${payload.form_id}/loss-ratio`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },

  async getReport ({ commit }, payload) {
    const url = `${MI_BASE_URL}reporting/form`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getRecommendationList ({ commit }, params) {
    const url = `${MI_BASE_URL}form-recommendation`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getSummaryManagement ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}reporting/summary-management`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async createSummaryManagement ({ commit }, payload) {
    let url = `${MI_BASE_URL}form/${payload.formId}/summary`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async createSummaryManagementClaimPurposed ({ commit }, payload) {
    let url = `${MI_BASE_URL}form/summary/${payload.summaryId}/data`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteSummaryManagementClaimPurposed ({ commit }, payload) {
    let url = `${MI_BASE_URL}form/summary/${payload.id}/data`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async getFormSummary ({ commit }, payload) {
    let url = `${MI_BASE_URL}form/summary/${payload.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async createPriceFleet ({ commit }, payload) {
    let url = `${MI_BASE_URL}pricefleet`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updatePriceFleet ({ commit }, payload) {
    let url = `${MI_BASE_URL}pricefleet/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async createPriceFleetChild ({ commit }, payload) {
    let url = `${MI_BASE_URL}pricefleet/data`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updatePriceFleetChild ({ commit }, payload) {
    let url = `${MI_BASE_URL}pricefleet/${payload.pricefleet_id}/data/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deletePriceFleetChild ({ commit }, payload) {
    let url = `${MI_BASE_URL}pricefleet/${payload.pricefleet_id}/data/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async getFleetPriceList ({ commit }, params) {
    let url = `${MI_BASE_URL}pricefleet/search`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getFleetPriceListExport ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}pricefleet/search`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      responseType: 'blob',
      data: params
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getFleetPriceListDetail ({ commit }, params) {
    let url = `${MI_BASE_URL}pricefleet/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getFleetPriceModel ({ commit }, params) {
    let url = `${MI_BASE_URL}pricefleet/model`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getFleetPriceFilter ({ commit }, params) {
    let url = `${MI_BASE_URL}pricefleet/filter`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getFleetPriceLastValue ({ commit }, params) {
    let url = `${MI_BASE_URL}pricefleet/latest/${params.date}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },

  async getFleetByCompanyAndType ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}/vehicle/${params.companyId}/${params.vehicleTypeId}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getFleetForms ({ commit }, params = {}) {
    const url = `${MI_BASE_URL}/vehicle/${params.vehicleId}/form`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getCurrencyMapping ({ commit }, params) {
    let url = `${MI_BASE_URL}currency/mapping`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getCurrencies ({ commit }, params) {
    let url = `${MI_BASE_URL}currency`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async createCurrencyMapping ({ commit }, payload) {
    let url = `${MI_BASE_URL}currency/mapping`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getCheckForecast ({ commit }, payload) {
    let url = `${MI_BASE_URL}forecast/check`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      await axios(config)
      return {
        status: true
      }
    } catch (e) {
      if (e.response.status === 409) {
        return {
          status: false
        }
      }
      return {
        status: true
      }
    }
  },
  async getForecastById ({ commit }, payload) {
    let url = `${MI_BASE_URL}forecast/${payload.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: true,
        data
      }
    } catch (e) {
      if (e.response.status === 409) {
        return {
          status: false
        }
      }
      return {
        status: true
      }
    }
  },
  async getOutstandingForecast ({ commit }, payload = {}) {
    let url = `${MI_BASE_URL}forecast/outstanding/${payload.year}/${payload.group_id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getInitialCalculationForecast ({ commit }, payload) {
    let url = `${MI_BASE_URL}forecast/initial-calculation`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getListForecast ({ commit }, params) {
    let url = `${MI_BASE_URL}forecast`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },

  async getFormDeductible ({ commit }, payload) {
    let url = `${MI_BASE_URL}form/model/deductible`
    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async getPesimistic ({ commit }, payload) {
    let url = `${MI_BASE_URL}forecast/pessimistic`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getForecastPriceFleet ({ commit }, payload) {
    let url = `${MI_BASE_URL}forecast/pricefleet`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async saveForecast ({ commit }, payload) {
    let url = `${MI_BASE_URL}forecast`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async updateForecast ({ commit }, payload = {}) {
    let url = `${MI_BASE_URL}forecast/${payload.id}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async getRaciMatrix ({ commit }, params) {
    const url = `${MI_BASE_URL}form/model/raci-matrix`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async getForecastFinalIndependent ({ commit }, payload) {
    let url = `${MI_BASE_URL}forecast-final-result-independent?with=companies`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async saveForecastFinalIndependent ({ commit }, payload) {
    let url = `${MI_BASE_URL}forecast-final-result-independent`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async updateForecastFinalIndependent ({ commit }, payload = {}) {
    let url = `${MI_BASE_URL}forecast-final-result-independent/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload.data
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      }
    }
  },
  async deleteForecastFinalIndependent ({ commit }, payload = {}) {
    const url = `${MI_BASE_URL}forecast-final-result-independent/${payload.id}`

    const method = 'delete'
    const headers = {
      'Content-Type': 'multipart/form-data'
    }
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data.data
      }
    }
  },
}
