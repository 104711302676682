var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-6"
        }, [_vm.prevUrl ? _c('b-button', {
          attrs: {
            "variant": "outline-danger"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push(_vm.prevUrl);
            }
          }
        }, [_vm._v(_vm._s(_vm.prevNavTitle))]) : _vm._e()], 1), _c('div', {
          staticClass: "col-md-6 text-right"
        }, [_vm.nextUrl ? _c('b-button', {
          attrs: {
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push(_vm.nextUrl);
            }
          }
        }, [_vm._v(_vm._s(_vm.nextNavTitle))]) : _vm._e()], 1)])];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }