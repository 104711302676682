<template>
  <b-collapse tag="ul" :class="className" :visible="open" :id="idName" :accordion="accordianName">
    <li v-for="(item,index) in items" :key="index" :class="{ 'active' : activeLink(item) }">
      <i v-if="!item.heading" class="ri-subtract-line" />
      <span v-if="!item.heading">{{ $t(item.menu) }}</span>
      <router-link :to="{ name: item.props, query: { menuId: item.menuId } }" v-if="item.heading && item.className != 'true'" :class="`iq-waves-effect ${activeLink(item) && item.child ? 'active' : activeLink(item) ? 'active' : ''}`" v-b-toggle="item.menu">
        <i :class="item.icon" v-if="item.iconClass"/>
        <template v-else v-html="item.icon"></template>
        <span>{{ $t(item.menu) }}</span>
      </router-link>
    </li>
  </b-collapse>
</template>
<script>
import List from './ListStyle1'
import { xray } from '../../../config/pluginInit'
export default {
  name: 'List',
  props: {
    items: Array,
    className: { type: String, default: 'iq-menu' },
    horizontal: Boolean,
    open: { type: Boolean, default: false },
    idName: { type: String, default: 'sidebar' },
    accordianName: { type: String, default: 'sidebar' }
  },
  components: {
    List
  },
  methods: {
    activeLink (item) {
      return xray.getActiveLink(item, this.$route.name)
    }
  }
}
</script>
