var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("REPORT - GENERAL LEDGER")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-dropdown', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Export Data',
            expression: "'Export Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "link iq-bg-primary",
            "toggle-class": "text-decoration-none",
            "no-caret": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-save m-0"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _vm._v("Export Excel")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf mr-2"
        }), _vm._v("Export PDF")])], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "text-center",
          attrs: {
            "md": "12"
          }
        }, [_c('h4', [_vm._v("PT. CAHAYA MARITIM")]), _c('h3', [_vm._v("All Journal")]), _c('p', [_vm._v("Report : 01/01/2021 - 31/12/2021")]), _c('hr')]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-borderless table-sm"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Date")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('th', {
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("22 November 2021")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Transaction Type")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('th', {
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("Sales Invoice")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Transaction No")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('th', {
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("9786")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Description")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('th', {
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("Saldo Awal pelanggan PT. Cahaya Maritim")])])])])]), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_vm._v(" ")]), _c('b-col', {
          attrs: {
            "md": "11"
          }
        }, [_c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Account No")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Account Name")]), _c('th', {
          staticClass: "text-right",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Debit")]), _c('th', {
          staticClass: "text-right",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Credit")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("110301")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Piutang Usaha IDR")]), _c('th', {
          staticClass: "text-right"
        }, [_vm._v("11.789.000")]), _c('th', {
          staticClass: "text-right"
        }, [_vm._v("0")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("300001")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Equitas Saldo Awal")]), _c('th', {
          staticClass: "text-right"
        }, [_vm._v("0")]), _c('th', {
          staticClass: "text-right"
        }, [_vm._v("11.789.000")])])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('hr')]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-borderless table-sm"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Date")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('th', {
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("28 November 2021")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Transaction Type")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('th', {
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("Sales Invoice")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Transaction No")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('th', {
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("11245")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Description")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('th', {
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("Saldo Awal pelanggan PT. Cahaya Maritim")])])])])]), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_vm._v(" ")]), _c('b-col', {
          attrs: {
            "md": "11"
          }
        }, [_c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Account No")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Account Name")]), _c('th', {
          staticClass: "text-right",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Debit")]), _c('th', {
          staticClass: "text-right",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Credit")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("110301")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Piutang Usaha IDR")]), _c('th', {
          staticClass: "text-right"
        }, [_vm._v("8.725.000")]), _c('th', {
          staticClass: "text-right"
        }, [_vm._v("0")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("300001")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Equitas Saldo Awal")]), _c('th', {
          staticClass: "text-right"
        }, [_vm._v("0")]), _c('th', {
          staticClass: "text-right"
        }, [_vm._v("8.725.000")])])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('hr')]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-borderless table-sm"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Date")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('th', {
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("30 November 2021")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Transaction Type")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('th', {
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("Purchase Invoice")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Transaction No")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('th', {
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("SF/17/1287")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Description")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('th', {
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("Saldo Awal pemasok CV. Sejahtera Marine")])])])])]), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_vm._v(" ")]), _c('b-col', {
          attrs: {
            "md": "11"
          }
        }, [_c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Account No")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Account Name")]), _c('th', {
          staticClass: "text-right",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Debit")]), _c('th', {
          staticClass: "text-right",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Credit")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("210101")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Piutang Usaha IDR")]), _c('th', {
          staticClass: "text-right"
        }, [_vm._v("0")]), _c('th', {
          staticClass: "text-right"
        }, [_vm._v("8.150.000")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("300001")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Equitas Saldo Awal")]), _c('th', {
          staticClass: "text-right"
        }, [_vm._v("8.150.000")]), _c('th', {
          staticClass: "text-right"
        }, [_vm._v("0")])])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('hr')]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-borderless table-sm"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Date")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('th', {
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("03 December 2021")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Transaction Type")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('th', {
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("Purchase Invoice")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Transaction No")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('th', {
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("INV-MA-9128")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Description")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('th', {
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("Saldo Awal pemasok PT. Indah")])])])])]), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_vm._v(" ")]), _c('b-col', {
          attrs: {
            "md": "11"
          }
        }, [_c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Account No")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Account Name")]), _c('th', {
          staticClass: "text-right",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Debit")]), _c('th', {
          staticClass: "text-right",
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Credit")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("210101")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Piutang Usaha IDR")]), _c('th', {
          staticClass: "text-right"
        }, [_vm._v("0")]), _c('th', {
          staticClass: "text-right"
        }, [_vm._v("12.000.000")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("300001")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Equitas Saldo Awal")]), _c('th', {
          staticClass: "text-right"
        }, [_vm._v("12.000.000")]), _c('th', {
          staticClass: "text-right"
        }, [_vm._v("0")])])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('hr')])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }