var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Default Radio Buttons")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _vm._l(_vm.state, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            attrs: {
              "inline": "",
              "name": item.name,
              "value": item.value,
              "disabled": item.disabled
            },
            model: {
              value: _vm.stateActive[item[Object.keys(item)[0]]],
              callback: function callback($$v) {
                _vm.$set(_vm.stateActive, item[Object.keys(item)[0]], $$v);
              },
              expression: "stateActive[item[Object.keys(item)[0]]]"
            }
          }, [_vm._v(_vm._s(item.label))])];
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Custom Radio Buttons")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _vm._l(_vm.custom, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            attrs: {
              "inline": "",
              "name": item.name,
              "value": item.value,
              "disabled": item.disabled
            },
            model: {
              value: _vm.stateActive[item[Object.keys(item)[0]]],
              callback: function callback($$v) {
                _vm.$set(_vm.stateActive, item[Object.keys(item)[0]], $$v);
              },
              expression: "stateActive[item[Object.keys(item)[0]]]"
            }
          }, [_vm._v(_vm._s(item.label))])];
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Custom Colored Radio Buttons")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _vm._l(_vm.color, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            staticClass: "custom-radio-color",
            attrs: {
              "inline": "",
              "color": item.color,
              "name": "color",
              "value": item.value,
              "disabled": item.disabled
            },
            model: {
              value: _vm.stateActive.color,
              callback: function callback($$v) {
                _vm.$set(_vm.stateActive, "color", $$v);
              },
              expression: "stateActive.color"
            }
          }, [_vm._v(_vm._s(item.label))])];
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Colored Radio Buttons")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _vm._l(_vm.color, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            staticClass: "custom-radio-color-checked",
            attrs: {
              "inline": "",
              "color": item.color,
              "name": "color",
              "value": item.value,
              "disabled": item.disabled
            },
            model: {
              value: _vm.stateActive.color,
              callback: function callback($$v) {
                _vm.$set(_vm.stateActive, "color", $$v);
              },
              expression: "stateActive.color"
            }
          }, [_vm._v(_vm._s(item.label))])];
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }