<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="true"
                  href="#power"
                  title="MASTER DATA POWER"
                />
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="false"
                  href="#rob"
                  title="MASTER DATA ROB TYPE"
                />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <tab-content-item :active="true" id="power">
        <tab-nav :tabs="true" align="center" >
          <tab-nav-items :active="true"  href="#powerList" title="Master Data Type of Power" />
          <tab-nav-items :active="false" href="#series" title="Master Data Series of Power" />
        </tab-nav>
        <div id="myTabContent" class="tab-content">
          <tab-content-item :active="true" id="powerList">
            <b-row>
              <b-col lg="12">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title text-primary"
                    ><strong>MASTER DATA POWER</strong></h4
                    >
                  </template>
                  <template v-slot:headerAction>
                    <!-- <b-button variant="primary">
                      <template v-if="!loading_export_power">
                        <i class="fa fa-file-excel"></i> &nbsp; Export
                      </template>
                      <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                        Exporting...
                      </template>
                    </b-button> -->
                  </template>
                  <template v-slot:body>
                    <b-form class="row" @submit.prevent="getTypePowerList()">
                      <b-col cols="12" md="3">
                        <b-form-group
                          label="Search Master Data Power:"
                          label-for="kind-fleet"
                        >
                          <b-form-input
                            id="fleet_name"
                            class="w-100"
                            type="text"
                            placeholder="Search..."
                            v-model="powersTypeList.params.search"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="2">
                        <b-form-group label="Search" label-for="button_search">
                          <b-button type="submit" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
                        </b-form-group>
                      </b-col>
                    </b-form>
                  </template>
                </iq-card>
              </b-col>
              <b-col sm="8">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">List Master Data Type of Power</h4>
                  </template>
                  <template v-slot:body>
                    <div v-if="powersTypeList._loading" class="text-center my-5">
                      <b-spinner type="grow" label="Spinning"></b-spinner>
                    </div>
                    <template v-else>
                      <div class="table-responsive" v-if="powersTypeList.data && powersTypeList.data.length > 0">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th width="10%">No</th>
                            <th width="25%">List of Power</th>
                            <th width="10%">Status</th>
                            <th width="15%">Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(p, i) in powersTypeList.data" :key="`p-${i}`">
                            <td class="text-center">{{ powersTypeList.meta.currentPage > 1 ? powersTypeList.meta.perPage + i + 1 : i + 1 }}</td>
                            <td>{{ p.name }}</td>
                            <td>{{ p.active ? 'Active' : 'Inactive' }}</td>
                            <td>
                              <b-button variant="success" size="sm" class="mr-2" @click="toEditPower(p, 'types')"><i class="fa fa-edit"></i></b-button>
                              <b-button variant="danger" size="sm" @click="onDeletePowerType(p)">
                                <i v-if="!loading_delete_power" class="fa fa-trash"></i>
                                <template v-else>
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Deleting...
                                </template>
                              </b-button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-if="powersTypeList.data && powersTypeList.data.length > 0">
                        <b-pagination
                          v-model="powersTypeList.meta.currentPage"
                          :total-rows="powersTypeList.meta.total"
                          @change="getTypePowerList"
                          :per-page="powersTypeList.meta.perPage"
                          first-number
                          align="center"
                        ></b-pagination>
                      </div>
                      <p v-else class="text-center my-4">No powers data found.</p>
                    </template>
                  </template>
                </iq-card>
              </b-col>
              <b-col sm="4">
                <iq-card
                  :class="{
                'border border-danger shadow-lg': form_power_edit_mode,
              }"
                >
                  <template v-slot:headerTitle>
                    <h4 class="card-title"
                    >{{ form_power_edit_mode ? 'Edit' : 'Add' }} Power</h4
                    >
                  </template>
                  <template v-slot:body>
                    <b-form>
                      <b-form-group label="Power Name" label-for="project_location">
                        <b-form-input v-model="formPowerType.name" id="Power Name" type="text" placeholder="Power Name" maxlength="30"></b-form-input>
                      </b-form-group>

                      <b-form-group label="Status" label-for="sub_sub_project">
                        <b-form-checkbox v-model="formPowerType.active" name="check-button" switch inline>Active / Inactive
                        </b-form-checkbox>
                      </b-form-group>
                      <hr />
                      <b-form-group class="text-right">
                        <b-button v-if="!form_power_edit_mode" @click.prevent="createPowerTypes()" :disabled="formPowerType.name === '' || formPowerType.name === null" type="submit" variant="primary">
                      <span>Add</span>
                        </b-button>
                        <b-button v-else @click.prevent="updatePowerTypeData()" :disabled="formPowerType.name === '' || formPowerType.name === null" type="submit" variant="primary">
                      <span>Save Edit</span>
                        </b-button>
                        <b-button
                          type="button"
                          variant="danger"
                          class="iq-bg-danger ml-3"
                          @click="initFormPowerType()"
                        >Cancel</b-button>
                      </b-form-group>
                    </b-form>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
          </tab-content-item>
          <tab-content-item :active="false" id="series">
            <b-row>
              <b-col lg="12">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title text-primary"
                    ><strong>MASTER DATA SERIES</strong></h4
                    >
                  </template>
                  <template v-slot:headerAction>
                    <!-- <b-button variant="primary">
                      <template v-if="!loading_export_power">
                        <i class="fa fa-file-excel"></i> &nbsp; Export
                      </template>
                      <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                        Exporting...
                      </template>
                    </b-button> -->
                  </template>
                  <template v-slot:body>
                    <b-form class="row" @submit.prevent="getSeriesPowerList()">
                      <b-col cols="12" md="3">
                        <b-form-group
                          label="Search Master Data Series:"
                          label-for="kind-fleet"
                        >
                          <b-form-input
                            id="fleet_name"
                            class="w-100"
                            type="text"
                            placeholder="Search..."
                            v-model="powersSeriesList.params.search"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="2">
                        <b-form-group label="Search" label-for="button_search">
                          <b-button type="submit" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
                        </b-form-group>
                      </b-col>
                    </b-form>
                  </template>
                </iq-card>
              </b-col>
              <b-col sm="8">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">List Master Data Series of Power</h4>
                  </template>
                  <template v-slot:body>
                    <div v-if="powersSeriesList._loading" class="text-center my-5">
                      <b-spinner type="grow" label="Spinning"></b-spinner>
                    </div>
                    <template v-else>
                      <div class="table-responsive" v-if="powersSeriesList.data && powersSeriesList.data.length > 0">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th>No</th>
                            <th>Series - Model</th>
                            <th>Rated power (kW)</th>
                            <th>No. of cylinders</th>
                            <th>Bore x stroke (mm)</th>
                            <th>Displacement (L)</th>
                            <th>Maximum RPM</th>
                            <th>Fuel</th>
                            <th>Status</th>
                            <th width="12%">Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(p, i) in powersSeriesList.data" :key="`p-${i}`">
                            <td class="text-center">{{ powersSeriesList.meta.currentPage > 1 ? powersSeriesList.meta.perPage + i + 1 : i + 1 }}</td>
                            <td>{{ p.name }} - {{ p.model }}</td>
                            <td>{{ p.ratedPower }}</td>
                            <td>{{ p.noOfCylinders }}</td>
                            <td>{{ p.boreXStroke }}</td>
                            <td>{{ p.displacement }}</td>
                            <td>{{ p.ratedSpeed }}</td>
                            <td>{{ p.fuel }}</td>
                            <td>{{ p.active ? 'Active' : 'Inactive' }}</td>
                            <td>
                              <b-button variant="success" size="sm" class="mr-2" @click="toEditPower(p, 'series')"><i class="fa fa-edit"></i></b-button>
                              <b-button variant="danger" size="sm" @click="onDeletePowerSeries(p)">
                                <i v-if="!loading_delete_power" class="fa fa-trash"></i>
                                <template v-else>
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Deleting...
                                </template>
                              </b-button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-if="powersSeriesList.data && powersSeriesList.data.length > 0">
                        <b-pagination
                          v-model="powersSeriesList.meta.currentPage"
                          :total-rows="powersSeriesList.meta.total"
                          @change="getSeriesPowerList"
                          :per-page="powersSeriesList.meta.perPage"
                          first-number
                          align="center"
                        ></b-pagination>
                      </div>
                      <p v-else class="text-center my-4">No powers data found.</p>
                    </template>
                  </template>
                </iq-card>
              </b-col>
              <b-col sm="4">
                <iq-card
                  :class="{
                'border border-danger shadow-lg': form_power_edit_mode,
              }"
                >
                  <template v-slot:headerTitle>
                    <h4 class="card-title"
                    >{{ form_power_edit_mode ? 'Edit' : 'Add' }} Series</h4
                    >
                  </template>
                  <template v-slot:body>
                    <b-form @submit.prevent="createPowerSeries()">
                      <b-form-group label="Series Name" label-for="project_location">
                        <b-form-input v-model="formPowerSeries.name" id="SeriesName" type="text" placeholder="Series Name" maxlength="30"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Model Name" label-for="project_location">
                        <b-form-input v-model="formPowerSeries.model" id="SeriesName" type="text" placeholder="Model Name" maxlength="30"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Rated power (kW)" label-for="project_location">
                        <b-form-input v-model="formPowerSeries.ratedPower" id="SeriesName" type="text" placeholder="Rated power (kW)" maxlength="30"></b-form-input>
                      </b-form-group>
                      <b-form-group label="No. of cylinders" label-for="project_location">
                        <b-form-input v-model="formPowerSeries.noOfCylinders" id="SeriesName" type="number" placeholder="No. of cylinders" maxlength="30"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Bore x stroke (mm)" label-for="project_location">
                        <b-form-input v-model="formPowerSeries.boreXStroke" id="SeriesName" type="text" placeholder="Bore x stroke (mm)" maxlength="30"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Displacement (L)" label-for="project_location">
                        <b-form-input v-model="formPowerSeries.displacement" id="SeriesName" type="text" placeholder="Displacement (L)" maxlength="30"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Maximum RPM" label-for="project_location">
                        <b-form-input v-model="formPowerSeries.ratedSpeed" id="SeriesName" type="number" placeholder="Maximum RPM" maxlength="30"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Fuel" label-for="project_location">
                        <b-form-input v-model="formPowerSeries.fuel" id="SeriesName" type="text" placeholder="Fuel" maxlength="80"></b-form-input>
                      </b-form-group>

                      <b-form-group label="Status" label-for="sub_sub_project">
                        <b-form-checkbox v-model="formPowerSeries.active" name="check-button" switch inline>Active / Inactive
                        </b-form-checkbox>
                      </b-form-group>
                      <hr />
                      <b-form-group class="text-right">
                        <b-button type="submit" variant="primary">
                          <span v-if="!form_power_edit_mode">Add</span>
                          <span v-else>Save Edit</span>
                        </b-button>
                        <b-button
                          type="button"
                          variant="danger"
                          class="iq-bg-danger ml-3"
                          @click="initFormPowerSeries()"
                        >Cancel</b-button>
                      </b-form-group>
                    </b-form>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
          </tab-content-item>
        </div>
      </tab-content-item>
      <tab-content-item :active="false" id="rob">
        <tab-nav :tabs="true" align="center">
          <tab-nav-items :active="true" href="#robList" title="Master Data Type of ROB" />
          <tab-nav-items :active="false" href="#seriesROB" title="Master Data Series of ROB" />
        </tab-nav>
        <div id="myTabContent2" class="tab-content">
          <tab-content-item :active="true" id="robList">
            <b-row>
              <b-col lg="12">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title text-primary"
                    ><strong>MASTER DATA TYPE OF ROB</strong></h4
                    >
                  </template>
                  <template v-slot:headerAction>
                    <!-- <b-button variant="primary">
                      <template v-if="!loading_export_power">
                        <i class="fa fa-file-excel"></i> &nbsp; Export
                      </template>
                      <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                        Exporting...
                      </template>
                    </b-button> -->
                  </template>
                  <template v-slot:body>
                    <b-form class="row" @submit.prevent="getRobPowerList()">
                      <b-col cols="12" md="3">
                        <b-form-group
                          label="Search Master Data ROB:"
                          label-for="kind-fleet"
                        >
                          <b-form-input
                            id="fleet_name"
                            class="w-100"
                            type="text"
                            placeholder="Search..."
                            v-model="robs.params.search"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="2">
                        <b-form-group label="Search" label-for="button_search">
                          <b-button type="submit" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
                        </b-form-group>
                      </b-col>
                    </b-form>
                  </template>
                </iq-card>
              </b-col>
              <b-col sm="8">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">List Master Data Type of ROB</h4>
                  </template>
                  <template v-slot:body>
                    <div v-if="robs._loading" class="text-center my-5">
                      <b-spinner type="grow" label="Spinning"></b-spinner>
                    </div>
                    <template v-else>
                      <div class="table-responsive" v-if="robs.data && robs.data.length > 0">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th width="10%">No</th>
                            <th width="25%">List of ROB</th>
                            <th width="10%">Status</th>
                            <th width="15%">Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(p, i) in robs.data" :key="`p-${i}`">
                            <td>{{ i + 1 }}</td>
                            <td>{{ p.name }}</td>
                            <td>{{ p.active ? 'Active' : 'Inactive' }}</td>
                            <td>
                              <b-button variant="success" size="sm" class="mr-2" @click="toEditRob(p,'types')"><i class="fa fa-edit"></i></b-button>
                              <b-button variant="danger" size="sm" @click="onDeleteRobType(p)">
                                <i v-if="!loading_delete_power" class="fa fa-trash"></i>
                                <template v-else>
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Deleting...
                                </template>
                              </b-button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-if="robs.data && robs.data.length > 0">
                        <b-pagination
                          v-model="robs.meta.currentPage"
                          :total-rows="robs.meta.total"
                          @change="getRobPowerList"
                          :per-page="robs.meta.perPage"
                          first-number
                          align="center"
                        ></b-pagination>
                      </div>
                      <p v-else class="text-center my-4">No ROB data found.</p>
                    </template>
                  </template>
                </iq-card>
              </b-col>
              <b-col sm="4">
                <iq-card
                  :class="{
                'border border-danger shadow-lg': form_rob_edit_mode,
              }"
                >
                  <template v-slot:headerTitle>
                    <h4 class="card-title"
                    >{{ form_rob_edit_mode ? 'Edit' : 'Add' }} Power</h4
                    >
                  </template>
                  <template v-slot:body>
                    <b-form @submit.prevent="createRobTypes()">
                      <b-form-group label="Type of ROB Name" label-for="project_location">
                        <b-form-input v-model="formRobType.name" id="Type of ROB Name" type="text" placeholder="Type of ROB Name" maxlength="30"></b-form-input>
                      </b-form-group>

                      <b-form-group label="Status" label-for="sub_sub_project">
                        <b-form-checkbox v-model="formRobType.active" name="check-button" switch inline>Active / Inactive
                        </b-form-checkbox>
                      </b-form-group>
                      <hr />
                      <b-form-group class="text-right">
                        <b-button type="submit" variant="primary">
                          <span v-if="!form_rob_edit_mode">Add</span>
                          <span v-else>Save Edit</span>
                        </b-button>
                        <b-button
                          type="button"
                          variant="danger"
                          class="iq-bg-danger ml-3"
                          @click="initFormRobType()"
                        >Cancel</b-button>
                      </b-form-group>
                    </b-form>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
          </tab-content-item>
          <tab-content-item :active="false" id="seriesROB">
            <b-row>
              <b-col lg="12">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title text-primary"
                    ><strong>MASTER DATA SERIES ROB</strong></h4
                    >
                  </template>
                  <template v-slot:headerAction>
                    <!-- <b-button variant="primary">
                      <template v-if="!loading_export_power">
                        <i class="fa fa-file-excel"></i> &nbsp; Export
                      </template>
                      <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                        Exporting...
                      </template>
                    </b-button> -->
                  </template>
                  <template v-slot:body>
                    <b-form class="row" @submit.prevent="getRobSeriesList()">
                      <b-col cols="12" md="3">
                        <b-form-group
                          label="Search Master Data Series ROB:"
                          label-for="kind-fleet"
                        >
                          <b-form-input
                            id="fleet_name"
                            class="w-100"
                            type="text"
                            placeholder="Search..."
                            v-model="seriesROB.params.search"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" md="2">
                        <b-form-group label="Search" label-for="button_search">
                          <b-button type="submit" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
                        </b-form-group>
                      </b-col>
                    </b-form>
                  </template>
                </iq-card>
              </b-col>
              <b-col sm="8">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">List Master Data Series of ROB</h4>
                  </template>
                  <template v-slot:body>
                    <div v-if="seriesROB._loading" class="text-center my-5">
                      <b-spinner type="grow" label="Spinning"></b-spinner>
                    </div>
                    <template v-else>
                      <div class="table-responsive" v-if="seriesROB.data && seriesROB.data.length > 0">
                        <table class="table table-striped">
                          <thead>
                          <tr>
                            <th>No</th>
                            <th>Series</th>
                            <th>Manufacturer</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(p, i) in seriesROB.data" :key="`p-${i}`">
                            <td>{{ i + 1 }}</td>
                            <td>{{ p.series }}</td>
                            <td>{{ p.manufacturer }}</td>
                            <td>{{ p.active ? 'Active' : 'Inactive' }}</td>
                            <td>
                              <b-button variant="success" size="sm" class="mr-2"><i class="fa fa-edit" @click="toEditRob(p,'series')"></i></b-button>
                              <b-button variant="danger" size="sm" @click="onDeleteRobSeries(p)">
                                <i v-if="!loading_delete_power" class="fa fa-trash"></i>
                                <template v-else>
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Deleting...
                                </template>
                              </b-button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-if="seriesROB.data && seriesROB.data.length > 0">
                        <b-pagination
                          v-model="seriesROB.meta.currentPage"
                          :total-rows="seriesROB.meta.total"
                          @change="getRobSeriesList"
                          :per-page="seriesROB.meta.perPage"
                          first-number
                          align="center"
                        ></b-pagination>
                      </div>
                      <p v-else class="text-center my-4">No Series data found.</p>
                    </template>
                  </template>
                </iq-card>
              </b-col>
              <b-col sm="4">
                <iq-card
                  :class="{
                'border border-danger shadow-lg': form_rob_edit_mode,
              }"
                >
                  <template v-slot:headerTitle>
                    <h4 class="card-title"
                    >{{ form_rob_edit_mode ? 'Edit' : 'Add' }} Series</h4
                    >
                  </template>
                  <template v-slot:body>
                    <b-form @submit.prevent="createRobSeries()">
                      <b-form-group label="Series ROB" label-for="project_location">
                        <b-form-input v-model="formRobSeries.series" id="SeriesName" type="text" placeholder="Series ROB" maxlength="30"></b-form-input>
                      </b-form-group>
                      <b-form-group label="Manufacturer" label-for="project_location">
                        <b-form-input v-model="formRobSeries.manufacturer" id="SeriesName" type="text" placeholder="Manufacturer" maxlength="30"></b-form-input>
                      </b-form-group>

                      <b-form-group label="Status" label-for="sub_sub_project">
                        <b-form-checkbox v-model="formRobSeries.active" name="check-button" switch inline>Active / Inactive
                        </b-form-checkbox>
                      </b-form-group>
                      <hr />
                      <b-form-group class="text-right">
                        <b-button type="submit" variant="primary">
                          <span v-if="!form_rob_edit_mode">Add</span>
                          <span v-else>Save Edit</span>
                        </b-button>
                        <b-button
                          type="button"
                          variant="danger"
                          class="iq-bg-danger ml-3"
                          @click="initFormRobSeries()"
                        >Cancel</b-button>
                      </b-form-group>
                    </b-form>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
          </tab-content-item>
        </div>
      </tab-content-item>
    </div>
  </b-container>
</template>

<script>
import { powerActions, robActions } from '@src/Utils/helper'
import _ from 'lodash'
export default {
  name: 'MasterProjectIdle',
  data () {
    return {
      powersTypeList: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          search: '',
          // active: true,
          perPage: 30,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      powersSeriesList: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          search: '',
          // active: true,
          perPage: 30,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      robs: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          search: '',
          // active: true,
          perPage: 30,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      seriesROB: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          search: '',
          // active: true,
          perPage: 30,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      form_power_edit_mode: false,
      form_rob_edit_mode: false,
      loading_power: false,
      loading_powerSeries: false,
      loading_export_power: false,
      loading_delete_power: false,
      loading_form_project: false,
      form_reason_edit_mode: false,
      loading_rob: false,
      loading_robSeries: false,
      loading_export_reason: false,
      loading_delete_reason: false,
      loading_form_reason: false,
      form: {
        selected_power: '',
        powerName: '',
        project: {
          location: '',
          active: true,
          vehicleType: [],
          subProject: [{ name: '' }]
        }
      },
      formPowerType: {
        name: '',
        active: false
      },
      formPowerSeries: {
        name: '',
        model: '',
        ratedPower: '',
        noOfCylinders: null,
        boreXStroke: '',
        displacement: '',
        ratedSpeed: null,
        fuel: '',
        active: false
      },
      formRobType: {
        name: '',
        active: false
      },
      formRobSeries: {
        series: '',
        manufacturer: '',
        active: false
      },
      selected_reason: {},
      powerId: null,
      robId: null
    }
  },
  async mounted () {
    await this.getTypePowerList()
    await this.getSeriesPowerList()
    await this.getRobPowerList()
    await this.getRobSeriesList()
  },
  created () {
    this.getTypePowerList = _.debounce(this.getTypePowerList, 200)
    this.getSeriesPowerList = _.debounce(this.getSeriesPowerList, 200)
    this.getRobPowerList = _.debounce(this.getRobPowerList, 200)
    this.getRobSeriesList = _.debounce(this.getRobSeriesList, 200)
  },
  methods: {
    ...powerActions,
    ...robActions,
    async getTypePowerList (page) {
      this.powersTypeList.params.page = page || 1
      this.powersTypeList._loading = true
      this.powersTypeList.data = []
      let params = this.$options.filters.cleanObject(this.powersTypeList.params)
      const res = await this.getPowerTypes({ params: params })

      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.powersTypeList.data = res.data.data
          this.powersTypeList.meta.perPage = res.data.perPage
          this.powersTypeList.meta.currentPage = res.data.currentPage
          this.powersTypeList.meta.total = res.data.total
          this.powersTypeList.meta.totalPage = res.data.totalPage
        }
        this.powersTypeList._loading = false
      } else {
        this.powersTypeList._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
      /* if (res.status == 'success') {
        this.powersTypeList = res.data
        console.log('fleet', res.data)
        this.loading_power = false
      } else {
        this.powersTypeList = []
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
        this.loading_power = false
      } */
    },
    async getSeriesPowerList (page) {
      this.powersSeriesList.params.page = page || 1
      this.powersSeriesList._loading = true
      this.powersSeriesList.data = []
      let params = this.$options.filters.cleanObject(this.powersSeriesList.params)
      const res = await this.getPowerSeries({ params: params })

      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.powersSeriesList.data = res.data.data
          this.powersSeriesList.meta.perPage = res.data.perPage
          this.powersSeriesList.meta.currentPage = res.data.currentPage
          this.powersSeriesList.meta.total = res.data.total
          this.powersSeriesList.meta.totalPage = res.data.totalPage
        }
        this.powersSeriesList._loading = false
      } else {
        this.powersSeriesList._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
      /* if (res.status == 'success') {
        this.powersSeriesList = res.data
        console.log('fleet', res.data)
        this.loading_powerSeries = false
      } else {
        this.powersSeriesList = []
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
        this.loading_powerSeries = false
      } */
    },
    async createPowerTypes () {
      this.$swal.fire({
        title: 'Processing...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading()
        }
      })

      const res = await this.savePowerType(this.formPowerType)

      if (res.status == 'success') {
        this.$swal('Add Power Types Success!', res.data.message, 'success')
        this.initFormPowerType()
        this.getTypePowerList()
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async createPowerSeries () {
      this.$swal.fire({
        title: 'Processing...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading()
        }
      })

      this.formPowerSeries.noOfCylinders = this.formPowerSeries.noOfCylinders ? Number(this.formPowerSeries.noOfCylinders) : null
      this.formPowerSeries.ratedSpeed = this.formPowerSeries.ratedSpeed ? Number(this.formPowerSeries.ratedSpeed) : null

      if (!this.form_power_edit_mode) {
        const res = await this.savePowerSeries(this.formPowerSeries)

        if (res.status == 'success') {
          this.$swal('Add Power Series Success!', res.data.message, 'success')
          this.initFormPowerSeries()
          this.getSeriesPowerList()
        } else {
          this.$swal(
            `Oops!`,
            res.data,
            'error'
          )
        }
      } else {
        const res = await this.updatePowerSeries({ id: this.powerId, data: this.formPowerSeries })

        if (res.status == 'success') {
          this.$swal('Update Power Series Success!', res.data.message, 'success')
          this.initFormPowerSeries()
          this.getSeriesPowerList()
        } else {
          this.$swal(
            `Oops!`,
            res.data,
            'error'
          )
        }
      }
    },
    initFormPowerType (data) {
      this.formPowerType = {
        name: data ? data.name : '',
        active: data ? data.active : false
      }
      this.form_power_edit_mode = false
      this.powerId = null
    },
    initFormPowerSeries (data) {
      this.formPowerSeries = {
        name: data ? data.name : '',
        model: data ? data.model : '',
        ratedPower: data ? data.ratedPower : '',
        noOfCylinders: data ? data.noOfCylinders : null,
        boreXStroke: data ? data.boreXStroke : '',
        displacement: data ? data.displacement : '',
        ratedSpeed: data ? data.ratedSpeed : null,
        fuel: data ? data.fuel : '',
        active: data ? data.active : false
      }
      this.form_power_edit_mode = false
      this.powerId = null
    },
    async onDeletePowerType (dataType) {
      let data = dataType

      this.$swal({
        title: 'Delete Activity?',
        text: `Power Type ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_power = true
          let res = await this.deletePowerTypeById(data.id)
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.loading_delete_power = false
          } else {
            this.$swal(
              `Power Type deleted!`,
              `Power Type ${data.name} successfully deleted`,
              'success'
            )
            await this.getTypePowerList()
            this.loading_delete_power = false
          }
        }
      })
    },
    async onDeletePowerSeries (dataType) {
      let data = dataType

      this.$swal({
        title: 'Delete Activity?',
        text: `Power Series ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_power = true
          let res = await this.deletePowerSeriesById(data.id)
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.loading_delete_power = false
          } else {
            this.$swal(
              `Power Type deleted!`,
              `Power Type ${data.name} successfully deleted`,
              'success'
            )
            await this.getSeriesPowerList()
            this.loading_delete_power = false
          }
        }
      })
    },
    async toEditPower (data, type) {
      if (type === 'types') {
        const res = await this.getPowerTypeById(data.id)
        if (res.status == 'success') {
          this.initFormPowerType(res.data)
          this.form_power_edit_mode = true
          this.powerId = res.data.id
        } else {
          this.$swal(
            `Oops!`,
            res.data,
            'error'
          )
        }
      } if (type === 'series') {
        const res = await this.getPowerSeriesById(data.id)
        if (res.status == 'success') {
          this.initFormPowerSeries(res.data)
          this.form_power_edit_mode = true
          this.powerId = res.data.id
        } else {
          this.$swal(
            `Oops!`,
            res.data,
            'error'
          )
        }
      }
    },
    async updatePowerTypeData () {
      this.$swal.fire({
        title: 'Processing...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading()
        }
      })

      const res = await this.updatePowerType({ id: this.powerId, data: this.formPowerType })

      if (res.status == 'success') {
        this.$swal('Update Power Types Success!', res.data.message, 'success')
        this.initFormPowerType()
        this.getTypePowerList()
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },

    // ROB SECTION
    async getRobPowerList (page) {
      this.robs.params.page = page || 1
      this.robs._loading = true
      this.robs.data = []
      let params = this.$options.filters.cleanObject(this.robs.params)
      const res = await this.getRobTypes({ params: params })

      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.robs.data = res.data.data
          this.robs.meta.perPage = res.data.perPage
          this.robs.meta.currentPage = res.data.currentPage
          this.robs.meta.total = res.data.total
          this.robs.meta.totalPage = res.data.totalPage
        }
        this.robs._loading = false
      } else {
        this.robs._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }

      /* if (res.status == 'success') {
        this.robs = res.data
        this.loading_rob = false
      } else {
        this.robs = []
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
        this.loading_rob = false
      } */
    },
    async createRobTypes () {
      this.$swal.fire({
        title: 'Processing...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading()
        }
      })

      if (!this.form_rob_edit_mode) {
        const res = await this.saveRobType(this.formRobType)

        if (res.status == 'success') {
          this.$swal('Add Rob Types Success!', res.data.message, 'success')
          this.initFormRobType()
          this.getRobPowerList()
        } else {
          console.log(res)
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      } else {
        const res = await this.updateRobType({ id: this.robId, data: this.formRobType })

        if (res.status == 'success') {
          this.$swal('Update Rob Series Success!', res.data.message, 'success')
          this.initFormRobType()
          this.getRobPowerList()
        } else {
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      }
    },

    initFormRobType (data) {
      this.formRobType = {
        name: data ? data.name : '',
        active: data ? data.active : false
      }
      this.form_rob_edit_mode = false
      this.robId = null
    },
    async toEditRob (data, type) {
      if (type === 'types') {
        const res = await this.getRobTypeById(data.id)
        if (res.status == 'success') {
          this.initFormRobType(res.data)
          this.form_rob_edit_mode = true
          this.robId = res.data.id
        } else {
          this.$swal(
            `Oops!`,
            res.data,
            'error'
          )
        }
      } if (type === 'series') {
        const res = await this.getRobSeriesById(data.id)
        if (res.status == 'success') {
          this.initFormRobSeries(res.data)
          this.form_rob_edit_mode = true
          this.robId = res.data.id
        } else {
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      }
    },

    async onDeleteRobType (dataType) {
      let data = dataType

      this.$swal({
        title: 'Delete Activity?',
        text: `Rob Type ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_power = true
          let res = await this.deleteRobTypeById(data.id)
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.loading_delete_power = false
          } else {
            this.$swal(
              `Rob Type deleted!`,
              `Rob Type ${data.name} successfully deleted`,
              'success'
            )
            await this.getRobPowerList()
            this.loading_delete_power = false
          }
        }
      })
    },

    // ROB SERIES SECTION
    async getRobSeriesList (page) {
      this.seriesROB.params.page = page || 1
      this.seriesROB._loading = true
      this.seriesROB.data = []
      let params = this.$options.filters.cleanObject(this.seriesROB.params)
      const res = await this.getRobSeries({ params: params })

      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.seriesROB.data = res.data.data
          this.seriesROB.meta.perPage = res.data.perPage
          this.seriesROB.meta.currentPage = res.data.currentPage
          this.seriesROB.meta.total = res.data.total
          this.seriesROB.meta.totalPage = res.data.totalPage
        }
        this.seriesROB._loading = false
      } else {
        this.seriesROB._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
      /* if (res.status == 'success') {
        this.seriesROB = res.data
        this.loading_robSeries = false
      } else {
        this.seriesROB = []
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
        this.loading_robSeries = false
      } */
    },
    async createRobSeries () {
      this.$swal.fire({
        title: 'Processing...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading()
        }
      })

      if (!this.form_rob_edit_mode) {
        const res = await this.saveRobSeries(this.formRobSeries)

        if (res.status == 'success') {
          this.$swal('Add Rob Series Success!', res.data.message, 'success')
          this.initFormRobSeries()
          this.getRobSeriesList()
        } else {
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      } else {
        const res = await this.updateRobSeries({ id: this.robId, data: this.formRobSeries })

        if (res.status == 'success') {
          this.$swal('Update Rob Series Success!', res.data.message, 'success')
          this.initFormRobSeries()
          this.getRobSeriesList()
        } else {
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      }
    },

    initFormRobSeries (data) {
      this.formRobSeries = {
        series: data ? data.series : '',
        manufacturer: data ? data.manufacturer : '',
        active: data ? data.active : false
      }
      this.form_rob_edit_mode = false
      this.robId = null
    },

    async onDeleteRobSeries (dataType) {
      let data = dataType

      this.$swal({
        title: 'Delete Activity?',
        text: `Rob Type ${data.series} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_power = true
          let res = await this.deleteRobSeriesById(data.id)
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.loading_delete_power = false
          } else {
            this.$swal(
              `Rob Series deleted!`,
              `Rob Series ${data.series} successfully deleted`,
              'success'
            )
            await this.getRobSeriesList()
            this.loading_delete_power = false
          }
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
