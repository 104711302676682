<template> </template>
<script>
import listCrewOptionsData from '@src/core/mock/data/ListCrewOptionsData'
import { crewingActions } from '@src/Utils/helper'

export default {
  props: {
    userProfile: {
      type: Object,
      default: false,
    },
    modalKey: {
      type: String,
      default: false,
    },
  },
  data() {
    return {
      ...listCrewOptionsData,
      loading: false,
      form: {
        reason_off: '',
        remarks: '',
      },
    }
  },
  methods: {
    ...crewingActions,
  },
}
</script>
