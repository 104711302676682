<template>
  <div class="iq-comingsoon pt-5">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-sm-9 text-center">
          <div class="iq-comingsoon-info">
            <a href="#">
              <img
                :src="require('../../../assets/images/logo.png')"
                class="img-fluid w-25"
                alt="logo"
              />
            </a>
          </div>
        </div>
      </div>

      <form action="#" method="post" @submit="onSave">
        <div class="row justify-content-center mt-3">
          <div class="col-lg-8">
            <iq-card>
              <template v-slot:headerTitle>
                <h5 class="card-title text-primary"
                  ><strong>FORM INTERVIEW</strong></h5
                >
              </template>
              <template v-slot:headerAction>
                <a
                  class="text-primary float-right ml-3"
                  v-b-toggle.filter
                  role="button"
                  style="cursor: pointer"
                  ><i class="fa fa-filter"
                /></a>
              </template>
              <template v-slot:body>
                <b-collapse visible id="filter" class="mb-2">
                  <!-- FILTER DOCKING SCHEDULE-->
                  <b-row>
                    <b-col md="5">
                      <b-form-group
                        label="Candidate Name *"
                        label-for="candidate_list"
                      >
                        <v-select
                          v-model="form.candidate_id"
                          label="text"
                          :options="optionsCandidateList"
                          :reduce="
                            (optionsCandidateList) => optionsCandidateList.value
                          "
                          placeholder="Select Candidate Name"
                          required
                        ></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <v-multiselect
                        v-model="interview_category"
                        tag-placeholder="Interview Category"
                        placeholder="Interview Category"
                        label="name"
                        track-by="id_interview_section_level"
                        :options="criteria_interview"
                        :multiple="true"
                        :taggable="true"
                        required
                      ></v-multiselect>
                    </b-col>
                    <b-col md="1">
                      <b-button
                        variant="primary"
                        class="mb-3 mr-1"
                        @click="mergeInterview"
                        ><i class="fa fa-search"></i
                      ></b-button>
                    </b-col>
                  </b-row>
                  <!-- FILTER DOCKING SCHEDULE END -->
                </b-collapse>
                <b-row>
                  <b-col md="12">
                    <fieldset
                      class="form-group border p-3"
                      v-for="(
                        interview, keyInterview
                      ) in interview_category_selected"
                      :key="keyInterview"
                    >
                      <legend class="w-auto px-2" style="font-size: 12pt">{{
                        interview?.name
                      }}</legend>
                      <b-row>
                        <b-col md="12">
                          <table class="table table-striped">
                            <thead>
                              <tr class="text-center">
                                <th width="45%"></th>
                                <th
                                  width="15%"
                                  v-for="(item, index) in point_interview"
                                  v-bind:key="index"
                                  >{{ item?.name }} <br />
                                  {{ item?.value_letter }}</th
                                >
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(
                                  criteria, keyCriteria
                                ) in interview.data_criteria"
                                :key="keyCriteria"
                              >
                                <th
                                  >{{ criteria.content }}<br /><i
                                    >Pengetahuan Permesinan</i
                                  ></th
                                >
                                <td
                                  class="text-center"
                                  v-for="(point, keyPoint) in criteria?.point"
                                  :key="keyPoint"
                                >
                                  <template>
                                    <b-form-checkbox
                                      v-model="point.checked"
                                      name="check-button"
                                      class="ml-4"
                                      switch
                                      inline
                                      @change="
                                        selectMenus(
                                          point,
                                          keyInterview,
                                          keyCriteria,
                                          keyPoint
                                        )
                                      "
                                    >
                                    </b-form-checkbox>
                                  </template>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <label for="komentar"
                            ><strong>Komentar</strong></label
                          >
                          <b-form-textarea
                            rows="2"
                            value="Comments if any / Komentar jika ada"
                            v-model="interview.comment"
                            required
                          ></b-form-textarea>
                        </b-col>
                      </b-row>
                    </fieldset>
                  </b-col>
                  <div
                    class="px-4 mb-4 d-flex align-items-center justify-content-center w-100 bg-active h5"
                    v-if="interview_category_selected.length <= 0"
                  >
                    <strong class="">
                      PILIH CANDIDATE DAN INTERVIEW CATEGORY TERLEBIH DAHULU.
                    </strong>
                  </div>
                  <hr />
                  <b-col md="6">
                    <div class="form-group row">
                      <label
                        label="Clasification *"
                        for="nama_kandidiat"
                        class="col-sm-6"
                        ><strong>Wawancara Oleh</strong><br /><i
                          >Interview by</i
                        ></label
                      >
                      <div class="col-sm-6">
                        <b-form-input
                          id="job_name"
                          type="text"
                          placeholder="Nama"
                          v-model="form.interview_data.wawancara_oleh"
                          required
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        label="Clasification *"
                        for="nama_kandidiat"
                        class="col-sm-6"
                        ><strong>Hasil Wawancara</strong><br /><i
                          >Result Interview</i
                        ></label
                      >
                      <div class="col-sm-6">
                        <b-form-select
                          plain
                          v-model="form.interview_data.hasil_wawancara"
                          :options="wawancara"
                          size="lg"
                          required
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="null"
                              >Hasil Wawancara</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        label="Clasification *"
                        for="nama_kandidiat"
                        class="col-sm-6"
                        ><strong>Urgensi Wawancara</strong><br /><i
                          >Interview Urgency</i
                        ></label
                      >
                      <div class="col-sm-6">
                        <b-form-select
                          plain
                          v-model="form.interview_data.urgensi"
                          :options="optionsUrgensi"
                          size="lg"
                          required
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="null"
                              >Urgensi</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        label="Clasification *"
                        for="nama_kandidiat"
                        class="col-sm-6"
                        ><strong>Lokasi Wawancara</strong><br /><i
                          >Interview Location</i
                        ></label
                      >
                      <div class="col-sm-6">
                        <b-form-input
                          id="location"
                          type="text"
                          placeholder="Lokasi Wawancara"
                          required
                          v-model="form.interview_data.location"
                        ></b-form-input>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="6" class="mt-3">
                    <b-button
                      type="submit"
                      block
                      variant="primary"
                      class="float-right ml-3"
                      :disabled="saving"
                      ><i class="fa fa-save"></i> Save</b-button
                    >
                    <b-button
                      type="reset"
                      block
                      variant="none"
                      class="iq-bg-danger float-right"
                      @click="resetForm"
                      ><i class="fa fa-close"></i> Reset Form</b-button
                    >
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import { publicActions } from '@src/Utils/helper'
export default {
  name: 'Interview',
  data() {
    return {
      saving: false,
      wawancara: [
        { value: 'Passed', text: 'Suitable / Cocok' },
        { value: 'Failed', text: 'Not Suitable / Tidak Cocok' },
        { value: 'Special Case', text: 'Recommendation' },
      ],
      optionsUrgensi: [
        { value: 'Priority', text: 'Priority' },
        { value: 'Urgent', text: 'Urgent' },
        { value: 'Emergency', text: 'Emergency' },
      ],
      stateActive: {
        single: 'active',
        disable: 'active',
        color: 'danger',
      },
      custom: [
        {
          name: 'number',
          value: 'one',
          disabled: false,
        },
      ],
      form: {
        type_position_id: null,
        candidate_id: null,
        interview_data: {
          location: '',
          hasil_wawancara: '',
          wawancara_oleh: '',
          status: '',
          date: null,
          location: '',
          total: null,
          average: null,
          urgensi: '',
          interview_by: [],
          interview: [],
          content: [],
        },
      },
      optionsCandidateList: [],
      point_interview: [],
      criteria_interview: [],
      interview_category: [],
      interview_category_selected: [],
    }
  },
  async mounted() {
    await this.getCandidateListPublic()
    await this.getPointInterviewPublic()
  },
  watch: {
    'form.candidate_id': {
      async handler() {
        await this.getProfileCandidateAndCriteriaInterviewPublic()
        this.interview_category = []
        this.interview_category_selected = []
      },
      deep: true,
    },
  },
  methods: {
    ...publicActions,
    async getCandidateListPublic() {
      let params = {
        status: 'interview',
      }
      const { status, data } = await this.fetchCandidatePublic(params)

      if (status === 'success') {
        data?.data?.map((state) => {
          this.optionsCandidateList.push({
            value: state.id,
            text: state.full_name,
          })
        })

        console.log(this.optionsCandidateList, 'THIS IS FIXED LIST CANDIDATE')
      }
    },
    async getPointInterviewPublic() {
      const { status, data } = await this.fetchPointListPublic()

      if (status !== 'success') {
        return
      }

      console.log(data, 'THIS IS POINT INTERVIEW')

      this.point_interview = data?.data
    },

    async getProfileCandidateAndCriteriaInterviewPublic() {
      let params = { candidate_id: this.form.candidate_id }
      const { status, data } = await this.fetchProfileCandidatePublic(params)

      if (status !== 'success') {
        return
      }

      console.log(data, 'THIS IS profile candidate')

      this.form.type_position_id =
        data?.personal_data?.position?.type_position_id

      if (this.form.type_position_id) {
        let params = { type_position_id: this.form.type_position_id }

        const { status, data } = await this.fetchInterviewCriteriaPublic(params)

        if (status !== 'success') {
          return
        }

        console.log(data, 'THIS IS CRITERIA INTERVIEW')
        this.criteria_interview = data
      }
    },
    mergeInterview() {
      if (
        this.criteria_interview.length > 0 &&
        this.point_interview.length > 0
      ) {
        this.interview_category_selected = []
        this.interview_category.map((criteria) => {
          this.interview_category_selected.push({
            ...criteria,
            comment: 'no comment',
            data_criteria: criteria.data_criteria.map((criteria) => {
              return {
                ...criteria,
                point: this.point_interview.map((point) => {
                  return {
                    ...point,
                    checked: false,
                  }
                }),
              }
            }),
          })
        })
        console.log(this.interview_category_selected, 'INTERVIEW QUESTIONS')
      } else {
        console.log('Data interview dan point interview tidak ada.')
      }
    },

    selectMenus(point, keyInterview, keyCriteria, keyPoint) {
      if (point.checked) {
        this.interview_category_selected[keyInterview].data_criteria[
          keyCriteria
        ].point.forEach((val, index) => {
          if (index !== keyPoint) {
            val.checked = false
          }
        })
      }
    },

    resetForm() {
      if (this.interview_category_selected.length > 0) {
        this.interview_category_selected.forEach((interview, index) => {
          interview.comment = ''
          interview.data_criteria.forEach((criteria, ind) => {
            criteria.point.forEach((point, i) => {
              return (point.checked = false)
            })
          })
        })
      }
    },

    async onSave(e) {
      e.preventDefault()
      this.saving = true

      let readyToSave = {
        status: this.form.interview_data.hasil_wawancara,
        date: new Date(),
        location: this.form.interview_data.location,
        total: 0,
        average: 0,
        urgensi: this.form.interview_data.urgensi,
        interview_by: [],
        interview: [],
        content: [],
      }

      this.interview_category_selected.forEach((interview, index) => {
        if (interview.comment) {
          readyToSave.interview.push({
            comment: interview.comment,
          })
        }
        interview.data_criteria.forEach((criteria, ind) => {
          criteria.point.forEach((point, i) => {
            if (point.checked) {
              readyToSave.content.push({
                criteria_id: criteria.id_interview_point,
                point_value: point.value,
              })
            }
          })
        })
      })
      if (readyToSave.content.length > 0) {
        readyToSave.total = readyToSave.content.reduce(
          (o, n) => o + n.point_value,
          0
        )
        readyToSave.average =
          readyToSave.content.reduce((o, n) => o + n.point_value, 0) /
          readyToSave.content.length

        this.saving = false
      }
      readyToSave.interview_by.push({
        name: this.form.interview_data.wawancara_oleh,
      })

      if (this.interview_category_selected <= 0) {
        this.saving = false
        this.$swal(
          `Oops!`,
          'Pilih candidate atau interview terlebih dahulu.',
          'warning'
        )
        return
      }
      const { status, data } = await this.setFormInterviewPublic({
        data: readyToSave,
        id: this.form.candidate_id,
      })
      if (status !== 'success') {
        this.saving = false
        this.$swal(`Oops!`, `${data}`, 'error')
        return
      }

      this.$swal(`Tersimpan`, 'Data Tersimpan', 'success')

      this.saving = false
    },
  },
}
</script>
