<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <form action="#" method="post" @submit="onSave">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><b>CLAIM FORM</b></h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-container fluid>
                  <b-row>
                    <b-col cols="12" class="text-center mb-3">
                      <template v-for="(item, index) in typeFormInsurance">
                        <b-form-radio
                          v-model="form.type_form"
                          class="custom-radio-color-checked"
                          inline
                          :color="item.color"
                          name="color"
                          :key="index"
                          :value="item.value"
                          :disabled="item.disabled"
                        ><strong><h5>{{ item.label }}</h5></strong></b-form-radio>
                      </template>
                    </b-col>
                    <b-col cols="12" md="6" class="mb-3">
                      <h5 class="card-title text-success"><b>INFORMATION OF ACCIDENT/INCIDENT</b></h5>
                      <b-form-group class="row" label-cols-sm="3" label="Company *" label-for="company">
                        <v-select label="text" v-model="form.company_id" :options="companies" :reduce="company => company.value"></v-select>
                      </b-form-group>
                      <b-form-group class="row" label-cols-sm="3" label="Fleet Name *" label-for="fleets">
                        <v-select label="name" v-model="form.vehicle_id" :options="fleets" :reduce="fleet => fleet.code"></v-select>
                      </b-form-group>
                      <b-form-group class="row" label-cols-sm="3" label="Date of Loss (DOL) *" label-for="dol">
                        <date-picker v-model="form.date_of_loss" style="width: 100%" type="date" placeholder="Select Date Time" value-type="YYYY-MM-DD" format="DD MMM YYYY"></date-picker>
                      </b-form-group>
                      <b-form-group class="row" label-cols-sm="3" label="Place of Loss (POL) *" label-for="pol">
                        <b-form-input v-model="form.place_of_loss" type="text"></b-form-input>
                      </b-form-group>
                      <b-form-group class="row" label-cols-sm="3" label="Type of Coverage *" label-for="coverage">
                        <v-select label="name" v-model="form.type_coverage_id" :options="typeCoverages" :reduce="coverage => coverage.code"></v-select>
                      </b-form-group>
                      <b-form-group class="row" label-cols-sm="3" label="Type of Loss *" label-for="loss">
                        <v-select label="name" v-model="form.type_loss_id" :options="typeLoss" :reduce="loss => loss.code"></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6" class="mb-3">
                      <h5 class="card-title text-success"><b>CLAIMANT</b></h5>
                      <b-form-group class="row" label-cols-sm="3" label="Company *" label-for="company">
                        <v-select label="text" v-model="form.company_claimant_id" :options="companies" :reduce="company => company.value"></v-select>
                      </b-form-group>
                      <b-form-group class="row" label-cols-sm="3" label="Name *" label-for="name">
                        <b-form-input v-model="form.name" required type="text"></b-form-input>
                      </b-form-group>
                      <b-form-group class="row" label-cols-sm="3" label="Position *" label-for="position">
                        <b-form-input v-model="form.position" type="text"></b-form-input>
                      </b-form-group>
                      <b-form-group class="row" label-cols-sm="3" label="Department of Fleet *" label-for="departments">
                        <v-select label="name" v-model="form.department_id" :options="departments" :reduce="department => department.code"></v-select>
                      </b-form-group>
                      <b-form-group class="row" label-cols-sm="3" label="Cause of Loss *" label-for="cause-of-loss">
                        <v-select label="name" v-model="form.cause_of_loss_id" :options="causesOfLoss" :reduce="causesOfLoss => causesOfLoss.code"></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <h5 class="card-title text-success"><b>DETAILS OF CHRONOLOGY</b></h5>
                      <b-form-group label="Decribe of Detail Chronology">
                        <wysiwyg v-model="form.details_of_chronology"/>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <h5 class="card-title text-success"><b>ESTIMATE DAMAGE OR LOSS</b></h5>
                      <b-form-group label="Decribe of Estimate Damage or Loss">
                        <wysiwyg v-model="form.estimate_damage"/>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" md="6">
                      <h5 class="card-title text-success"><b>CONCLUSION</b></h5>
                      <b-form-group label="Decribe of Conclusion">
                        <wysiwyg v-model="form.conclusion"/>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" md="6">
                      <h5 class="card-title text-success"><b>COST</b></h5>
                      <b-form-group label="Estimate your cost">
                        <div class="input-group">
                          <div class="input-group-append">
                            <span class="input-group-text">Rp</span>
                          </div>
                          <b-form-input v-model="form.cost" step=".001" type="number"></b-form-input>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-container>
                <b-col cols="12" md="12" v-if="form.fileSupport.length > 0">
                  <h5 class="card-title text-success"><b>FILE / DOCUMENT SUPPORT</b></h5>
                  <table class="table mb-0 table-borderless table-box-shadow">
                    <thead>
                    <tr>
                      <th width="5%">No.</th>
                      <th width="50%">Name of File / Document</th>
                      <th width="35%">File / Document</th>
                      <th width="10%">#</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(fileSupport, index) in form.fileSupport" :key="index">
                      <td>{{index+1}}</td>
                      <td>
                        <b-form-input @change="onUpdateDocument(fileSupport)" required type="text" v-model="fileSupport.name" placeholder="Name of File / Document Support" value=""></b-form-input>
                      </td>
                      <td>
                        <div v-if="fileSupport.file" class="d-flex justify-content-between">
                          <a :href="fileSupport.file" class="text-primary" v-b-tooltip.hover title="Download File" download><i class="fa fa-download"></i> Download</a>
                          <a href="#" @click.prevent="fileSupport.file = ''" class="text-danger" v-b-tooltip.hover title="Hapus" download><i class="fa fa-trash"></i></a>
                        </div>
                        <template v-else>
                          <div class="custom-file">
                            <input type="file" required @change="onDocumentsFileChanged(index, $event)" accept="image/png,image/jpg,image/jpeg" class="custom-file-input" id="validatedCustomFile">
                            <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                          </div>
                        </template>
                      </td>
                      <td>
                        <template v-if="form.fileSupport.length > 1">
                          <b-button @click.prevent="removeFileSupportList(index)" size="sm" variant="danger" class="mb-3 mr-1"><i class="fa fa-trash"></i></b-button>
                        </template>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <b-button variant="warning" block class="mt-2 mb-3" @click.prevent="addFileSupportList()"><i class="fa fa-plus"></i> Add More File Support</b-button>
                </b-col>
                <b-col cols="12" md="12" class="pull-right text-center mt-2">
                  <b-form-checkbox class="mb-3" v-model="statementTruth">
                    I Believe (The Claimant Believes) that the facts stated in these particulars of accident / incident are true
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" md="12" class="pull-right text-center mt-3">
                  <b-button type="submit" :disabled="!statementTruth || form._saving" variant="primary">{{form._saving ? 'Menyimpan...' : 'Simpan'}}</b-button>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </form>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { companiesActions, filesActions, fleetsActions, miActions } from '@src/Utils/helper'

export default {
  name: 'Portal',
  mounted () {
    xray.index()
    this.fetchTypeCoverages()
    this.fetchTypeLoss()
    this.fetchDepartments()
    this.getCompanyListData()
    this.fetchCauseOfLoss()
    this.fetchFleets()

    if (this.$route.query.formId) {
      this.fetchMi()
    }
  },
  data () {
    return {
      statementTruth: false,
      companies: [],
      fleetsLoading: true,
      fleets: [],
      typeCoverages: [],
      typeLoss: [],
      departments: [],
      causesOfLoss: [],
      typeFormInsurance: [
        {
          label: 'Claim',
          color: 'warning',
          value: 'claim'
        },
        {
          label: 'Non Claim',
          color: 'info',
          value: 'non_claim'
        }
      ],
      form: {
        _saving: false,
        id: '',
        type_form: 'claim',
        company_claimant_id: '',
        cause_of_loss_id: '',
        company_id: '',
        vehicle_id: '',
        name: '',
        position: '',
        department_id: null,
        date_of_loss: '',
        place_of_loss: '',
        type_coverage_id: '',
        type_loss_id: '',
        details_of_chronology: '',
        estimate_damage: '',
        conclusion: '',
        cost: '',
        remarks: '',
        actual_close_date: '',
        quantification: '',
        coverage_period: '',
        status_coverage: '',
        classification_of_loss_id: '',
        proximity_level: '',
        reporting_status: '',
        follow_up_response: '',
        insurer_id: '',
        status_id: '',
        fileSupport: [
          {
            id: '',
            name: '',
            file: ''
          }
        ]
      }
    }
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    ...miActions,
    ...filesActions,
    async getCompanyListData () {
      const res = await this.getCompanies()

      if (res.status === 'success' && res.data && res.data.length > 0) {
        res.data.forEach(element => {
          this.companies.push({
            // text: `${element.company} - ${element.location}`,
            text: `${element.company}`,
            value: element.id
          })
        })
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async fetchFleets () {
      this.fleetsLoading = true
      this.fleets = []

      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        search: ''
      })
      let res = await this.getFleets(params)

      if (res.status) {
        this.fleetsLoading = false
        if (res.data && res.data.length > 0) {
          res.data.forEach(el => {
            this.fleets.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    async fetchTypeCoverages () {
      this.typeCoverages = []
      let res = await this.getTypeCoverages({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach(el => {
            this.typeCoverages.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    async fetchTypeLoss () {
      this.typeLoss = []
      let res = await this.getTypeLoss({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach(el => {
            this.typeLoss.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    async fetchDepartments () {
      this.departments = []
      let res = await this.getDepartments({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          this.departments = res.data.map(val => ({
            name: val.name,
            code: val.id
          }))
        }
      }
    },
    async fetchCauseOfLoss () {
      this.causesOfLoss = []
      let res = await this.getMasterCauseOfLoss({})

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach(el => {
            this.causesOfLoss.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    addFileSupportList () {
      this.form.fileSupport.push(
        {
          id: '',
          name: '',
          file: ''
        }
      )
    },
    removeFileSupportList (index) {
      this.form.fileSupport.splice(index, 1)
    },

    async onSave (e) {
      e.preventDefault()
      this.form._saving = true

      const payload = { ...this.form, documents: [] }
      payload.fileSupport.filter(file => !file.id).map(file => {
        payload.documents.push({
          filename: file.name,
          file: file.file
        })
      })

      let funcName = 'saveMi'

      if (this.form.id) {
        funcName = 'updateMi'
      }

      const { data, status } = await this[funcName](payload)

      if (status === 422) {
        this.form._saving = false
        return this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
      }

      if ([500, 404, 403].includes(status)) {
        this.form._saving = false
        return this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      setTimeout(() => {
        this.$router.push('/mi/list-initial')
      }, 1500)

      this.form._saving = false
    },
    async fetchMi () {
      const { data, status } = await this.fetchDetailMi({
        id: this.$route.query.formId,
        with: 'vehicle,type_coverage,type_loss,company,documents,company_claimant,status,creator,histories'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        this.form._saving = false
        return
      }

      this.form.id = data.id
      this.form.type_form = data.type_form
      this.form.company_id = data.company_id
      this.form.company_claimant_id = data.company_claimant_id
      this.form.vehicle_id = data.vehicle_id
      this.form.date_of_loss = data.date_of_loss
      this.form.place_of_loss = data.place_of_loss
      this.form.type_loss_id = data.type_loss_id
      this.form.type_coverage_id = data.type_coverage_id
      this.form.department_id = data.department_id
      this.form.cause_of_loss_id = data.cause_of_loss_id
      this.form.name = data.name
      this.form.position = data.position
      this.form.details_of_chronology = data.details_of_chronology
      this.form.estimate_damage = data.estimate_damage
      this.form.conclusion = data.conclusion
      this.form.cost = data.cost
      this.form.status_id = data.status_id
      this.form.insurer_id = data.insurer_id
      this.form.follow_up_response = data.follow_up_response
      this.form.reporting_status = data.reporting_status
      this.form.proximity_level = data.proximity_level
      this.form.classification_of_loss_id = data.classification_of_loss_id
      this.form.coverage_period = data.coverage_period
      this.form.quantification = data.quantification
      this.form.actual_close_date = data.actual_close_date
      this.form.remarks = data.remarks
      this.form.fileSupport = []

      data.documents.forEach(document => {
        this.form.fileSupport.push({
          id: document.id,
          name: document.name_of_file,
          file: document.document
        })
      })
    },
    async handleFileRemoteUpload (event) {
      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      const { data, status } = await this.upload(formData)

      if (status === 'error') {
        return {
          status
        }
      }

      return {
        data, status
      }
    },
    async onDocumentsFileChanged (index, event) {
      const { data, status } = await this.handleFileRemoteUpload(event)

      if (status === 'success') {
        this.form.fileSupport[index].file = data.url
        this.onUpdateDocument(this.form.fileSupport[index])
        return true
      }

      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        this.form.fileSupport[index].file = reader.result
      }

      this.onUpdateDocument(this.form.fileSupport[index])
    },
    async onUpdateDocument (data) {
      if (data && data.id) {
        let formData = new FormData()
        formData.append('form_id', this.form.id)
        formData.append('document_id', data.id)

        if (data.file) {
          formData.append('document', data.file)
        }

        if (data.name) {
          formData.append('filename', data.name)
        }

        await this.updateDocumentSupport(formData)
      }
    }
  }
}
</script>
