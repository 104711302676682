var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Pagination Examples")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-1",
            modifiers: {
              "collapse-1": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("In addition, as pages likely have more than one such navigation section, it’s advisable to provide a descriptive "), _c('code', [_vm._v("aria-label")]), _vm._v(" for the "), _c('code', [_vm._v("<nav>")]), _vm._v(" to reflect its purpose. For example, if the pagination component is used to navigate between a set of search results, an appropriate label could be "), _c('code', [_vm._v("aria-label=\"Search results pages\"")]), _vm._v(".")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-1"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "pagination-examples"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-pagination value=\"1\"\n              prev-text=\"Previous\"\n              next-text=\"Next\"\n              first-text=\"First\"\n              last-text=\"Last\"\n              :total-rows=\"50\"\n></b-pagination>\n")])])])])]), _c('b-pagination', {
          attrs: {
            "value": "1",
            "prev-text": "Previous",
            "next-text": "Next",
            "first-text": "First",
            "last-text": "Last",
            "total-rows": 50
          }
        })];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Disabled and active states")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-2",
            modifiers: {
              "collapse-2": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Pagination links are customizable for different circumstances. Use "), _c('code', [_vm._v("disabled")]), _vm._v(" for links that appear un-clickable and "), _c('code', [_vm._v(".active")]), _vm._v(" to indicate the current page.")]), _c('p', [_vm._v("While the "), _c('code', [_vm._v("disabled")]), _vm._v(" prop uses "), _c('code', [_vm._v("pointer-events: none")]), _vm._v(" to "), _c('em', [_vm._v("try")]), _vm._v(" to disable the link functionality of "), _c('code', [_vm._v("<a>")]), _vm._v("s, that CSS property is not yet standardized and doesn’t account for keyboard navigation. As such, you should always add "), _c('code', [_vm._v("tabindex=\"-1\"")]), _vm._v(" on disabled links and use custom JavaScript to fully disable their functionality.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-2"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "disabled-active-1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-pagination value=\"1\" disabled :total-rows=\"50\" />\n<b-pagination value=\"2\" :total-rows=\"50\" />\n")])])])])]), _c('b-pagination', {
          attrs: {
            "value": "1",
            "disabled": "",
            "total-rows": 50
          }
        }), _c('p', [_vm._v("You can optionally swap out active or disabled anchors for "), _c('code', [_vm._v("<span>")]), _vm._v(", or omit the anchor in the case of the prev/next arrows, to remove click functionality and prevent keyboard focus while retaining intended styles.")]), _c('b-pagination', {
          attrs: {
            "value": "2",
            "total-rows": 50
          }
        })];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Pagination With Color")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-3",
            modifiers: {
              "collapse-3": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("In addition, as pages likely have more than one such navigation section, it’s advisable to provide a descriptive "), _c('code', [_vm._v("aria-label")]), _vm._v(" for the "), _c('code', [_vm._v("<nav>")]), _vm._v(" to reflect its purpose. For example, if the pagination component is used to navigate between a set of search results, an appropriate label could be "), _c('code', [_vm._v("aria-label=\"Search results pages\"")]), _vm._v(".")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-3"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "disabled-active-3"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-pagination class=\"iq-page-primary\" :value=\"2\" :total-rows=\"50\" />\n<b-pagination class=\"iq-page-success\" :value=\"2\" :total-rows=\"50\" />\n<b-pagination class=\"iq-page-info\" :value=\"2\" :total-rows=\"50\" />\n")])])])])]), _c('b-pagination', {
          staticClass: "iq-page-primary",
          attrs: {
            "value": 2,
            "total-rows": 50
          }
        }), _c('b-pagination', {
          staticClass: "iq-page-success",
          attrs: {
            "value": 2,
            "total-rows": 50
          }
        }), _c('b-pagination', {
          staticClass: "iq-page-info",
          attrs: {
            "value": 2,
            "total-rows": 50
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Working with icons")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-4",
            modifiers: {
              "collapse-4": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Looking to use an icon or symbol in place of text for some pagination links? Be sure to provide proper screen reader support with "), _c('code', [_vm._v("aria")]), _vm._v(" attributes.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-4"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "pagination-with-icon"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-pagination value=\"1\"\n              :total-rows=\"50\"\n></b-pagination>\n")])])])])]), _c('b-pagination', {
          attrs: {
            "value": "1",
            "total-rows": 50
          }
        })];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Pagination Sizing")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-5",
            modifiers: {
              "collapse-5": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Fancy larger or smaller pagination? Add prop "), _c('code', [_vm._v("size=\"lg\"")]), _vm._v(" or "), _c('code', [_vm._v("size=\"sm\"")]), _vm._v(" for additional sizes.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-5"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "pagination-with-icon-size"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-pagination value=\"1\" :total-rows=\"50\" size=\"lg\" />\n<b-pagination value=\"1\" :total-rows=\"50\" />\n<b-pagination value=\"1\" :total-rows=\"50\" size=\"sm\" />\n")])])])])]), _c('b-pagination', {
          attrs: {
            "value": "1",
            "total-rows": 50,
            "size": "lg"
          }
        }), _c('b-pagination', {
          attrs: {
            "value": "1",
            "total-rows": 50
          }
        }), _c('b-pagination', {
          attrs: {
            "value": "1",
            "total-rows": 50,
            "size": "sm"
          }
        })];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Alignment")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-6",
            modifiers: {
              "collapse-6": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("By default the pagination component is left aligned. Change the alignment to "), _c('code', [_vm._v("center")]), _vm._v(", "), _c('code', [_vm._v("right")]), _vm._v(" ("), _c('code', [_vm._v("right")]), _vm._v(" is an alias for "), _c('code', [_vm._v("end")]), _vm._v("), or "), _c('code', [_vm._v("fill")]), _vm._v(" by setting the prop align to the appropriate value.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-6"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "pagination-align"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<div>\n  <h6>Left alignment (default)</h6>\n  <b-pagination :value=\"1\" :total-rows=\"50\" />\n</div>\n\n<div class=\"mt-3\">\n  <h6 class=\"text-center\">Center alignment</h6>\n  <b-pagination :value=\"1\" :total-rows=\"50\" align=\"center\" />\n</div>\n\n<div class=\"mt-3\">\n  <h6 class=\"text-right\">Right (end) alignment</h6>\n  <b-pagination :value=\"1\" :total-rows=\"50\" align=\"right\" />\n</div>\n\n<div class=\"mt-3\">\n  <h6 class=\"text-center\">Fill alignment</h6>\n  <b-pagination :value=\"1\" :total-rows=\"50\" align=\"fill\" />\n</div>\n")])])])])]), _c('div', [_c('h6', [_vm._v("Left alignment (default)")]), _c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50
          }
        })], 1), _c('div', {
          staticClass: "mt-3"
        }, [_c('h6', {
          staticClass: "text-center"
        }, [_vm._v("Center alignment")]), _c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('div', {
          staticClass: "mt-3"
        }, [_c('h6', {
          staticClass: "text-right"
        }, [_vm._v("Right (end) alignment")]), _c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "right"
          }
        })], 1), _c('div', {
          staticClass: "mt-3"
        }, [_c('h6', {
          staticClass: "text-center"
        }, [_vm._v("Fill alignment")]), _c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }