<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-4 p-0" :active="true" href="#job_category" title="Job Category"/>
                <tab-nav-items class="col-md-4 p-0" :active="false" href="#docking_job" title="Docking Job"/>
                <tab-nav-items class="col-md-4 p-0" :active="false" href="#shipyard" title="Master Shipyard"/>
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <tab-content-item :active="true" id="job_category">
        <DockingMasterDataJobCategory/>
      </tab-content-item>

      <tab-content-item :active="false" id="docking_job">
        <DockingMasterDataJob/>
      </tab-content-item>

      <tab-content-item :active="false" id="shipyard">
        <DockingMasterDataShipyard/>
      </tab-content-item>
      <!-- End Component Parts -->
    </div>
  </b-container>
</template>

<script>
import DockingMasterDataJobCategory from '@components/Docking/DockingMasterDataJobCategory'
import DockingMasterDataJob from '@components/Docking/DockingMasterDataJob'
import DockingMasterDataShipyard from '@components/Docking/DockingMasterDataShipyard'

export default {
  name: 'MaintenanceMasterEquipment',
  components: { DockingMasterDataShipyard, DockingMasterDataJob, DockingMasterDataJobCategory },
  data () {
    return {
    }
  },
  mounted () {

  },
  methods: {
  }
}
</script>

<style scoped>

</style>
