var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.fleetDetail ? _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_vm._v("OPERATIONAL OF FLOATING CRANE - " + _vm._s(_vm.fleetDetail.name))])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('date-picker', {
          attrs: {
            "type": "month",
            "placeholder": "Select Month",
            "value-type": "YYYY-MM",
            "format": "MMM YYYY"
          },
          on: {
            "change": _vm.generateYearMonthParams
          },
          model: {
            value: _vm.filterMonthYear,
            callback: function callback($$v) {
              _vm.filterMonthYear = $$v;
            },
            expression: "filterMonthYear"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getVoyageDashboard();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "6"
          }
        }, [_c('button', {
          staticClass: "btn btn-dark mb-3 mr-1"
        }, [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v(" Export")]), _c('router-link', {
          attrs: {
            "to": {
              name: 'dashboard.operational-input-fc',
              query: {
                vehicleId: _vm.queryVehicleId
              }
            }
          }
        }, [_c('button', {
          staticClass: "btn btn-primary mb-3"
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add Operational Data")])])], 1)], 1)];
      },
      proxy: true
    }], null, false, 2023094082)
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v(_vm._s(_vm.fleetDetail.name))])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('small', {
          staticClass: "mr-3"
        }, [_vm._v("Periode "), _c('strong', [_vm._v(_vm._s(_vm.moment(_vm.filterMonthYear).format('MMMM YYYY')))])]), _c('span', {
          staticClass: "mr-4"
        }, [_c('small', [_vm._v("Status "), _c('b-badge', {
          attrs: {
            "variant": "warning",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-lock"
        })])], 1)]), _c('span', {
          staticClass: "mt-3"
        }, [_c('button', {
          staticClass: "btn btn-info btn-sm mb-3 mr-1"
        }, [_c('i', {
          staticClass: "fa fa-edit"
        }), _vm._v(" Edit")])]), _c('span', {
          staticClass: "mt-3"
        }, [_c('button', {
          staticClass: "btn btn-danger btn-sm mb-3 mr-1"
        }, [_c('i', {
          staticClass: "fa fa-trash"
        }), _vm._v(" Delete")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_vm.dataFC.length < 1 ? _c('b-alert', {
          attrs: {
            "show": "",
            "variant": "warning"
          }
        }, [_vm._v("Data Not yet input")]) : _vm._e(), _vm.loadingData ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_c('div', {
          staticClass: "table-responsive tableFixHead"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped",
          staticStyle: {
            "width": "2000px",
            "display": "block",
            "height": "700px",
            "overflow-y": "scroll"
          }
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "scope": "col",
            "rowspan": "2"
          }
        }, [_vm._v("No")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "scope": "col",
            "rowspan": "2"
          }
        }, [_vm._v("Vessel's Name")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "scope": "col",
            "rowspan": "2"
          }
        }, [_vm._v("Barge")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "scope": "col",
            "rowspan": "2"
          }
        }, [_vm._v("A/Side")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "scope": "col",
            "rowspan": "2"
          }
        }, [_vm._v("Comm")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "scope": "col",
            "rowspan": "2"
          }
        }, [_vm._v("Compl")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "scope": "col",
            "rowspan": "2"
          }
        }, [_vm._v("Cast Off")]), _c('th', {
          attrs: {
            "scope": "col",
            "colspan": "4"
          }
        }, [_vm._v("Quantity Cargo")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "scope": "col",
            "rowspan": "2"
          }
        }, [_vm._v("Origin Cargo")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "scope": "col",
            "rowspan": "2"
          }
        }, [_vm._v("Cargo Hold")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "scope": "col",
            "rowspan": "2"
          }
        }, [_vm._v("Cargo Loaded on Vessel")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "scope": "col",
            "rowspan": "2"
          }
        }, [_vm._v("Idle Time")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "scope": "col",
            "rowspan": "2"
          }
        }, [_vm._v("Not to Count")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "scope": "col",
            "rowspan": "2"
          }
        }, [_vm._v("To Count")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "scope": "col",
            "rowspan": "2"
          }
        }, [_vm._v("Remarks")])]), _c('tr', [_c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Draft Survey")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Belt Scale FTS")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("D B/S")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Portion Tonnage")])])]), _c('tbody', [_vm._l(_vm.voyageFcActivities, function (data, index) {
          return [_c('tr', [_c('td', {
            staticStyle: {
              "vertical-align": "top"
            },
            attrs: {
              "rowspan": data.detail.length + 1
            }
          }, [_vm._v(_vm._s(index + 1))]), _c('td', {
            staticStyle: {
              "vertical-align": "top"
            },
            attrs: {
              "rowspan": data.detail.length + 1
            }
          }, [_vm._v(_vm._s(data.mvName))]), _c('td', {
            staticClass: "active",
            attrs: {
              "colspan": "11"
            }
          }), _c('td', {
            staticStyle: {
              "vertical-align": "top"
            },
            attrs: {
              "rowspan": data.detail.length + 1
            }
          }, [_c('b-badge', {
            staticClass: "success font-size-14"
          }, [_vm._v(_vm._s(data.cargoLoaded ? data.cargoLoaded : 0) + " TON")])], 1), _c('td', {
            staticClass: "active",
            attrs: {
              "colspan": "4"
            }
          })]), _vm._l(data.detail, function (detail, indexDetail) {
            return _c('tr', [_c('td', [_vm._v("barge " + _vm._s(detail.bargeName))]), _c('td', [_vm._v(_vm._s(detail.side))]), _c('td', [_vm._v(_vm._s(detail.comm))]), _c('td', [_vm._v(_vm._s(detail.compl))]), _c('td', [_vm._v(_vm._s(detail.castOf))]), _c('td', [_vm._v(_vm._s(detail.cargoDraft))]), _c('td', [_vm._v(_vm._s(detail.beltScale))]), _c('td', [_vm._v(_vm._s(detail.cargoDbs))]), _c('td', [_vm._v(_vm._s(detail.portionTonnage))]), _c('td', [_vm._v(_vm._s(detail.cargoOrigin))]), _c('td', [_vm._v(_vm._s(detail.cargoHold))]), _c('td', [_vm._v(_vm._s(detail.idleTime ? detail.idleTime.durationFormat : '-'))]), _c('td', [_vm._v("-")]), _c('td', [_vm._v("-")]), _c('td', [_c('small', [_vm._v(" " + _vm._s(detail.remarks) + " ")])])]);
          })];
        })], 2), _c('tfoot', {
          staticClass: "table-info font-weight-bold"
        }, [_c('tr', [_c('td', [_c('strong', [_vm._v("TOTAL")])]), _c('td', {
          attrs: {
            "colspan": "6"
          }
        }), _c('td', [_vm._v(_vm._s(_vm.totalCargoDraftSurvey))]), _c('td', [_vm._v(_vm._s(_vm.totalCargoBeltScale))]), _c('td', [_vm._v(_vm._s(_vm.totalCargoDbs))]), _c('td', [_vm._v(_vm._s(_vm.totalCargoPortionTonnage))]), _c('td', {
          attrs: {
            "colspan": "2"
          }
        }), _c('td'), _c('td', {
          attrs: {
            "colspan": "4"
          }
        })])])])])]], 2)], 1)];
      },
      proxy: true
    }], null, false, 1264320676)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("Cargo discharged month to date")]), _c('td', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.voyageFcSummary.cargoDischargedMonth) + " MT")])]), _c('tr', [_c('td', [_vm._v("Total Cargo Vessel Complete Loaded")]), _c('td', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.voyageFcSummary.totalCargoLoaded) + " MT")])]), _c('tr', [_c('td', [_vm._v("Day In Month / Hrs")]), _c('td', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.voyageFcSummary.totalHoursInMonth) + " Hours")])]), _c('tr', [_c('td', [_vm._v("Gross Hourly Rate")]), _c('td', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.voyageFcSummary.grossHourlyRate) + " Mt/Hours")])]), _c('tr', [_c('td', [_vm._v("Gross Daily Rate")]), _c('td', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.voyageFcSummary.grossDailyRate) + " Mt/Day")])]), _c('tr', [_c('td', [_vm._v("Net Hourly Rate")]), _c('td', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.voyageFcSummary.netHourlyRate) + " Mt/Hours")])]), _c('tr', [_c('td', [_vm._v("Net Daily Rate")]), _c('td', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.voyageFcSummary.netDailyRate) + " Mt/Day")])])])])];
      },
      proxy: true
    }], null, false, 2412994610)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', {
          staticClass: "table-warning"
        }, [_c('tr', [_c('th', [_vm._v("Performance")]), _c('th', [_vm._v("Actual")]), _c('th', [_vm._v("Plan")]), _c('th', [_vm._v("Var")])])]), _c('tbody', [_vm._l(_vm.voyageFcPerformances, function (performance) {
          return _c('tr', [_c('td', [_vm._v(_vm._s(performance.name))]), _c('td', [_vm._v(_vm._s(performance.actual))]), _c('td', [_vm._v(_vm._s(performance.plan))]), _c('td', [_vm._v(_vm._s(performance.vars))])]);
        }), _vm.voyageFcPerformances.length === 0 ? _c('tr', [_c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v("No Data")])]) : _vm._e()], 2)])];
      },
      proxy: true
    }], null, false, 1285797804)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("DATA ANALYSIS OF MONTH TO DATE")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', {
          staticClass: "thead-dark"
        }, [_c('tr', [_c('th', [_vm._v("DESCRIPTION")]), _c('th', [_vm._v("Dd/Hh/Mm")]), _c('th', [_vm._v("HOURS")]), _c('th', [_vm._v("PERCENTAGE")])])]), _c('tbody', [_vm._l(_vm.voyageAnalysisOfMonth.data, function (data) {
          return _c('tr', [_c('td', [_vm._v(_vm._s(data.name))]), _c('td', [_vm._v(_vm._s(data.ddHhMm))]), _c('td', [_vm._v(_vm._s(data.hours))]), _c('td', [_vm._v(_vm._s(data.percentage))])]);
        }), _vm.voyageAnalysisOfMonth.data.length === 0 ? _c('tr', [_c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v("No Data")])]) : _vm._e()], 2), _c('tfoot', {
          staticClass: "table-info"
        }, [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("Total")]), _c('td', [_vm._v(_vm._s(_vm.voyageAnalysisOfMonth.total.ddHhMm))]), _c('td', [_vm._v(_vm._s(_vm.voyageAnalysisOfMonth.total.hours))]), _c('td', [_vm._v(_vm._s(_vm.voyageAnalysisOfMonth.total.percentage))])])])])];
      },
      proxy: true
    }], null, false, 4238352134)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("IDLE TIME ")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', {
          staticClass: "thead-dark"
        }, [_c('tr', [_c('th', [_vm._v("DESCRIPTION")]), _c('th', [_vm._v("Dd/Hh/Mm")]), _c('th', [_vm._v("HOURS")]), _c('th', [_vm._v("PERCENTAGE")])])]), _c('tbody', [_vm._l(_vm.voyageIdleOfMonth.data, function (data) {
          return _c('tr', [_c('td', [_vm._v(_vm._s(data.name))]), _c('td', [_vm._v(_vm._s(data.ddHhMm))]), _c('td', [_vm._v(_vm._s(data.hours))]), _c('td', [_vm._v(_vm._s(data.percentage))])]);
        }), _vm.voyageIdleOfMonth.data.length === 0 ? _c('tr', [_c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v("No Data")])]) : _vm._e()], 2), _c('tfoot', {
          staticClass: "table-info"
        }, [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("Total")]), _c('td', [_vm._v(_vm._s(_vm.voyageIdleOfMonth.total.ddHhMm))]), _c('td', [_vm._v(_vm._s(_vm.voyageIdleOfMonth.total.hours))]), _c('td', [_vm._v(_vm._s(_vm.voyageIdleOfMonth.total.percentage))])])])])];
      },
      proxy: true
    }], null, false, 3688159637)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("To Account")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', {
          staticClass: "thead-dark"
        }, [_c('tr', [_c('th', [_vm._v("DESCRIPTION")]), _c('th', [_vm._v("Dd/Hh/Mm")]), _c('th', [_vm._v("HOURS")]), _c('th', [_vm._v("PERCENTAGE")])])]), _c('tbody', [_vm._l(_vm.voyageToAccount.data, function (data) {
          return _c('tr', [_c('td', [_vm._v(_vm._s(data.name))]), _c('td', [_vm._v(_vm._s(data.ddHhMm))]), _c('td', [_vm._v(_vm._s(data.hours))]), _c('td', [_vm._v(_vm._s(data.percentage))])]);
        }), _vm.voyageToAccount.data.length === 0 ? _c('tr', [_c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v("No Data")])]) : _vm._e()], 2), _c('tfoot', {
          staticClass: "table-info"
        }, [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("Total")]), _c('td', [_vm._v(_vm._s(_vm.voyageToAccount.total.ddHhMm))]), _c('td', [_vm._v(_vm._s(_vm.voyageToAccount.total.hours))]), _c('td', [_vm._v(_vm._s(_vm.voyageToAccount.total.percentage))])])])])];
      },
      proxy: true
    }], null, false, 3013641945)
  })], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }