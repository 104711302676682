var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "fab-container"
  }, [['SUPER ADMIN', 'PORT CAPTAIN', 'MANAGEMENT', 'RADIO CONTROL ROOM'].includes(_vm.role) ? _c('div', {
    staticClass: "fab shadow",
    on: {
      "click": function click($event) {
        _vm.dashboard = 'operational';
      }
    }
  }, [_c('div', {
    staticClass: "fab-content bg-blue"
  }, [_c('span', [_c('i', {
    staticClass: "ri ri-safari-fill mr-2"
  })]), _c('span', {
    staticStyle: {
      "font-family": "Arial, Helvetica, sans-serif"
    }
  }, [_vm._v(" Operational")])])]) : _vm._e()]), _vm.layout === 'maps' ? _c('b-row', [_c('a', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.add-fleet",
      modifiers: {
        "add-fleet": true
      }
    }],
    staticClass: "button-float",
    attrs: {
      "id": "add-vehicle",
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "fa fa-plus my-button-float"
  }), _c('i', {
    staticClass: "fa fa-plus fa fa-ship my-button-float"
  })]), _c('b-modal', {
    attrs: {
      "id": "add-fleet",
      "size": "xl",
      "no-close-on-backdrop": true,
      "header-bg-variant": _vm.modal.headerBgVariant,
      "header-text-variant": _vm.modal.headerTextVariant,
      "body-bg-variant": _vm.modal.bodyBgVariant,
      "body-text-variant": _vm.modal.bodyTextVariant,
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('h5', [_vm._v("Add More Your Fleet")]), _c('b-button', {
          attrs: {
            "size": "md",
            "variant": "outline-danger"
          },
          on: {
            "click": close
          }
        }, [_c('i', {
          staticClass: "fa fa-times"
        }), _vm._v(" Close ")])];
      }
    }], null, false, 801979076)
  }, [_c('AddFleet', {
    attrs: {
      "isAddForChild": true
    },
    on: {
      "childAction": function childAction($event) {
        return _vm.$bvModal.hide('add-fleet');
      }
    }
  })], 1), _c('b-tooltip', {
    attrs: {
      "target": "add-vehicle"
    }
  }, [_vm._v("Add More Your Fleet")]), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "100%",
      "width": "100%",
      "position": "fixed",
      "margin-left": "-30px",
      "margin-top": "-120px"
    }
  }, [_c('l-map', {
    staticStyle: {
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "zoom": _vm.zoom,
      "center": _vm.center,
      "options": _vm.mapOptions
    },
    on: {
      "update:center": _vm.centerUpdate,
      "update:zoom": _vm.zoomUpdate
    }
  }, [_c('l-tile-layer', {
    attrs: {
      "url": _vm.url,
      "attribution": _vm.attribution
    }
  }), _c('l-control-zoom', {
    attrs: {
      "position": "bottomleft"
    }
  }), _vm._l(_vm.fleetActivities, function (f, i) {
    return [f.latitude && f.longitude ? [_c('l-marker', {
      key: "fleet-".concat(i),
      attrs: {
        "lat-lng": [f.latitude, f.longitude]
      }
    }, [_c('l-icon', {
      attrs: {
        "icon-size": _vm.iconSize,
        "icon-anchor": _vm.iconAnchor
      }
    }, [_c('img', {
      staticStyle: {
        "width": "72px"
      },
      attrs: {
        "src": _vm.getIcon(f.vehicleType.icon)
      }
    })]), _c('l-popup', {
      attrs: {
        "options": {
          maxWidth: 'auto'
        },
        "lat-lng": [f.latitude, f.longitude]
      }
    }, [_c('div', {
      staticStyle: {
        "width": "400px"
      }
    }, [_c('router-link', {
      attrs: {
        "to": "/ops/operational"
      }
    }, [_c('h4', [_vm._v(_vm._s(f.name))])]), _c('div', {
      staticClass: "mb-5 position-absolute",
      staticStyle: {
        "right": "20px",
        "top": "10px"
      }
    }, [_c('b-dropdown', {
      attrs: {
        "size": "sm",
        "text": "Small",
        "variant": "light",
        "right": ""
      }
    }, [_c('template', {
      slot: "button-content"
    }, [_c('i', {
      staticClass: "fa fa-ellipsis-h"
    })]), _c('b-dropdown-item', {
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.fetchFleetDetail(f.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-edit"
    }), _vm._v(" Edit ")]), _c('b-dropdown-item', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteFleet(f);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    }), _vm._v(" Delete ")])], 2)], 1), _c('b-row', [_c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('b-card-text', [_c('b-row', {
      staticClass: "text-center"
    }, [_vm._l(f.modules, function (m, im) {
      return [m.hasAccess && (_vm.validateTechnicalPermission(m.url) || _vm.validateOperationalPermission(m.url) || _vm.validateReportPermission(m.url)) ? _c('b-col', {
        key: "m-".concat(im),
        staticClass: "mb-3",
        attrs: {
          "cols": "3",
          "md": "3"
        }
      }, [_c('router-link', {
        attrs: {
          "to": {
            path: m.url,
            query: {
              vehicleId: f.id
            }
          }
        }
      }, [m.icon && m.icon.url ? _c('img', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.top",
          value: m.name,
          expression: "m.name",
          modifiers: {
            "top": true
          }
        }],
        attrs: {
          "src": m.icon.url,
          "alt": "Responsive image"
        }
      }) : _c('img', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.top",
          value: 'Operational',
          expression: "'Operational'",
          modifiers: {
            "top": true
          }
        }],
        attrs: {
          "src": require("@assets/images/flaticon/operational.png"),
          "alt": "Responsive image"
        }
      })])], 1) : _vm._e()];
    })], 2)], 1)], 1)], 1)], 1)]), _c('l-tooltip', {
      attrs: {
        "options": {
          permanent: false,
          interactive: true
        }
      }
    }, [_vm._v(_vm._s(f.name))])], 1)] : _vm._e()];
  })], 2)], 1)])], 1) : _vm._e(), _c('b-row', {
    staticClass: "mb-3",
    staticStyle: {
      "z-index": "999 !important"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_vm.layout === 'maps' ? _c('div', {
    staticClass: "justify-content-end text-left"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "btn-group"
  }, [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-filter",
      modifiers: {
        "collapse-filter": true
      }
    }],
    attrs: {
      "href": "#collapse-filter",
      "variant": "light"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-filter"
  }), _vm._v(" Filter ")]), _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.upcoming-job",
      modifiers: {
        "upcoming-job": true
      }
    }],
    attrs: {
      "href": "#upcoming-job",
      "variant": "dark"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-info"
  }), _vm._v(" Jobs Maintenance Information "), _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("10")])], 1)], 1), _vm._v("> ")]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-collapse', {
    staticClass: "mt-2",
    attrs: {
      "id": "collapse-filter"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Category:",
      "label-for": "kind-fleet"
    }
  }, [_vm._l(_vm.vehicleTypes, function (item, index) {
    return [_c('b-form-checkbox', {
      key: index,
      attrs: {
        "name": "check-button",
        "switch": "",
        "inline": ""
      },
      model: {
        value: item.checked,
        callback: function callback($$v) {
          _vm.$set(item, "checked", $$v);
        },
        expression: "item.checked"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])];
  })], 2)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cari"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "size": "xs"
    },
    model: {
      value: _vm.params.search,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "search", $$v);
      },
      expression: "params.search"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Category Ownership"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "options": _vm.categoryOwnership,
      "id": "gender"
    },
    model: {
      value: _vm.params.ownership,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "ownership", $$v);
      },
      expression: "params.ownership"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Filter",
      "label-for": "button_search"
    }
  }, [_c('b-button', {
    staticClass: "mb-3 mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.fetchFleetActivities
    }
  }, [_c('i', {
    staticClass: "fa fa-filter"
  })])], 1)], 1)], 1)], 1)], 1), _c('b-collapse', {
    staticClass: "mt-2",
    attrs: {
      "id": "upcoming-job"
    }
  }, [_c('b-card', _vm._l(_vm.maintenance.data, function (vehicle, indexVehicle) {
    return _c('div', {
      staticClass: "bg-white shadow rounded px-3 pt-3 pb-3 border border-white mb-3"
    }, [vehicle.jobs.length === 0 ? _c('div', [_vm._v("No Job")]) : _vm._e(), _vm._l(vehicle.jobs, function (job, indexJob) {
      return [_c('div', {
        staticClass: "flex justify-between mb-2"
      }, [_c('b-btn', {
        directives: [{
          name: "b-toggle",
          rawName: "v-b-toggle",
          value: 'maintenance-job-' + indexVehicle + '-' + indexJob,
          expression: "'maintenance-job-' + indexVehicle + '-' + indexJob"
        }],
        staticClass: "text-left",
        attrs: {
          "block": "",
          "href": "#",
          "variant": "secondary"
        }
      }, [_vm._v(" " + _vm._s(job.jobName) + " "), job.statusDetail ? _c('b-badge', {
        staticClass: "ml-2",
        attrs: {
          "variant": "danger"
        }
      }, [_vm._v(_vm._s(job.statusDetail))]) : _vm._e(), _c('span', {
        staticClass: "when-opened text-right pull-right"
      }, [_c('i', {
        staticClass: "fa fa-arrow-down"
      })]), _c('span', {
        staticClass: "when-closed text-right pull-right"
      }, [_c('i', {
        staticClass: "fa fa-arrow-up"
      })])], 1)], 1), _c('b-collapse', {
        attrs: {
          "id": 'maintenance-job-' + indexVehicle + '-' + indexJob,
          "role": "tabpanel"
        }
      }, [_c('b-row', [_c('b-col', {
        attrs: {
          "md": "12"
        }
      }, [_c('table', {
        staticClass: "table mb-0"
      }, [_c('thead', {
        staticClass: "text-center"
      }, [_c('tr', [_c('td', [_vm._v("Component Name")]), _c('td', [_vm._v("Frequency")]), _c('td', [_vm._v("Last Maintenance")]), _c('td', [_vm._v("Current")]), _c('td', [_vm._v("Due Date / Hours")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v(_vm._s(job.componentName))]), _c('td', [_vm._v(_vm._s(job.interval))]), _c('td', [_vm._v(_vm._s(job.lastMaintenanceDate))]), _c('td', [_vm._v(_vm._s(job.lastMaintenanceDate))]), _c('td', [_c('div', [_c('small', [_vm._v("Last Due #1 : " + _vm._s(job.dueDate))])])])])])])])], 1)], 1)];
    }), _c('b-row', {
      staticClass: "mt-3"
    }, [_c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('h5', [_vm._v(_vm._s(vehicle.group))])])], 1)], 2);
  }), 0), _c('b-modal', {
    attrs: {
      "id": "edit-fleet",
      "size": "xl",
      "no-close-on-backdrop": true,
      "header-bg-variant": _vm.modal.headerBgVariant,
      "header-text-variant": _vm.modal.headerTextVariant,
      "body-bg-variant": _vm.modal.bodyBgVariant,
      "body-text-variant": _vm.modal.bodyTextVariant,
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref2) {
        var close = _ref2.close;
        return [_c('h5', [_vm._v("Edit Your Fleet")]), _c('b-button', {
          attrs: {
            "size": "md",
            "variant": "outline-danger"
          },
          on: {
            "click": close
          }
        }, [_c('i', {
          staticClass: "fa fa-times"
        }), _vm._v(" Close ")])];
      }
    }], null, false, 1900618220)
  }, [_c('AddFleet', {
    attrs: {
      "formData": _vm.fleetDetail
    },
    on: {
      "childAction": function childAction($event) {
        return _vm.$bvModal.hide('edit-fleet');
      }
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e()]), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "justify-content-end text-right"
  }, [_c('strong', {
    class: {
      'text-white': _vm.layout === 'maps'
    }
  }, [_vm._v("Display :  ")]), _c('div', {
    staticClass: "btn-group"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    class: {
      active: _vm.layout === 'list',
      'btn-outline-light': _vm.layout === 'maps'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.layout = 'list';
      }
    }
  }, [_c('span', {
    staticClass: "fa fa-list"
  }), _vm._v(" Dashboard List ")]), _c('button', {
    staticClass: "btn btn-outline-primary",
    class: {
      active: _vm.layout === 'maps'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.layout = 'maps';
      }
    }
  }, [_c('span', {
    staticClass: "fa fa-map"
  }), _vm._v(" Maps ")])])])])], 1), _vm.layout === 'list' ? _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/ops/certificate"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h5', {
          staticClass: "card-title mb-2 mt-2 text-primary"
        }, [_c('strong', [_vm._v("TOTAL CERTIFICATE")])]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.statsCertificateData.totalCertificate !== null ? _vm.statsCertificateData.totalCertificate : '-'))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "las la-certificate"
        })])])];
      },
      proxy: true
    }], null, false, 2613475270)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/ops/certificate?status_certificate=expired"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h5', {
          staticClass: "card-title mb-2 mt-2 text-primary"
        }, [_c('strong', [_vm._v("EXPIRED")])]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.statsCertificateData.totalExpired !== null ? _vm.statsCertificateData.totalExpired : '-'))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-danger"
        }, [_c('i', {
          staticClass: "las la-exclamation"
        })])])];
      },
      proxy: true
    }], null, false, 1669229095)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/ops/certificate?status_certificate=nextMonth"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h5', {
          staticClass: "card-title mb-2 mt-2 text-primary"
        }, [_c('strong', [_vm._v("1 MONTH WILL EXPIRED")])]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.statsCertificateData.totalOneMonth !== null ? _vm.statsCertificateData.totalOneMonth : '-'))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "las la-tasks"
        })])])];
      },
      proxy: true
    }], null, false, 1798092020)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/ops/certificate?status=progress"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h5', {
          staticClass: "card-title mb-2 mt-2 text-primary"
        }, [_c('strong', [_vm._v("PROGRESS")])]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.statsCertificateData.totalProcess !== null ? _vm.statsCertificateData.totalProcess : '-'))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-success"
        }, [_c('i', {
          staticClass: "fa fa-clock-o"
        })])])];
      },
      proxy: true
    }], null, false, 3452351567)
  })], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "bg-white shadow p-3 mb-4 mt-2 text-center",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "btn-group ml-auto"
  }, [['SUPER ADMIN', 'PORT CAPTAIN', 'MANAGEMENT', 'RADIO CONTROL ROOM'].includes(_vm.role) ? _c('button', {
    staticClass: "btn btn-outline-primary",
    class: {
      active: _vm.dashboard === 'operational'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.dashboard = 'operational';
      }
    }
  }, [_c('span', {
    staticClass: "ri ri-safari-fill"
  }), _vm._v(" Operational Dashboard ")]) : _vm._e()])]), _vm.dashboard === 'operational' ? [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": ""
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("DAILY REPORT FLEET POSITION & PROGRESS")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('span', [_c('small', [_vm._v("click this button for show data! →")])]), _c('span', {
          staticClass: "ml-3"
        }, [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-position",
            modifiers: {
              "collapse-position": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-circle"
        })])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-position"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category:",
            "label-for": "kind-fleet"
          }
        }, [_vm._l(_vm.vehicle, function (item, index) {
          return [_c('b-form-checkbox', {
            key: "v-".concat(index),
            attrs: {
              "value": item.value,
              "disabled": item.disabled,
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            model: {
              value: _vm.filterDailyOps.vehicleTypeIds,
              callback: function callback($$v) {
                _vm.$set(_vm.filterDailyOps, "vehicleTypeIds", $$v);
              },
              expression: "filterDailyOps.vehicleTypeIds"
            }
          }, [_vm._v(" " + _vm._s(item.text) + " ")])];
        })], 2)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Date Filter"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date"
          },
          model: {
            value: _vm.filterDailyOps.date,
            callback: function callback($$v) {
              _vm.$set(_vm.filterDailyOps, "date", $$v);
            },
            expression: "filterDailyOps.date"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show ")])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Category Ownership"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.categoryOwnership,
            "id": "gender",
            "size": "lg"
          },
          model: {
            value: _vm.filterDailyOps.ownership,
            callback: function callback($$v) {
              _vm.$set(_vm.filterDailyOps, "ownership", $$v);
            },
            expression: "filterDailyOps.ownership"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Project Filter"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.projectOptions,
            "multiple": true,
            "group-values": "sub",
            "group-label": "project",
            "group-select": true,
            "placeholder": "Select one",
            "track-by": "name",
            "label": "name"
          },
          model: {
            value: _vm.filterDailyOps.subProject,
            callback: function callback($$v) {
              _vm.$set(_vm.filterDailyOps, "subProject", $$v);
            },
            expression: "filterDailyOps.subProject"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.fetchDailyOps();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "10"
          }
        }, [_c('tab-nav', {
          attrs: {
            "tabs": true
          }
        }, [_c('tab-nav-items', {
          attrs: {
            "active": true,
            "id": "position-tab",
            "ariaControls": "position",
            "role": "tab",
            "ariaSelected": true,
            "title": "Update Position"
          }
        })], 1)], 1), _vm.findMenuPermission() ? _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "2"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1 mt-3",
          attrs: {
            "variant": "dark"
          },
          on: {
            "click": _vm.exportDailyOps
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("Export ")])], 1) : _vm._e()], 1), _c('div', {
          staticClass: "tab-content",
          attrs: {
            "id": "myTabContent"
          }
        }, [_c('tab-content-item', {
          attrs: {
            "active": true,
            "id": "position"
          }
        }, [_c('div', {
          staticClass: "table-responsive tableFixHead"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover",
          staticStyle: {
            "width": "2200px",
            "display": "block",
            "height": "700px",
            "overflow-y": "scroll"
          }
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Fleet")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Start Date")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("End Date")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Current Position")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Port Loading")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Port Discharge")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Agent POL")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Agent POD")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("PIC / PE")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Departure")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("ETA")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Update Today")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Reason")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Idle (Hours)")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Remarks")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Document on Board")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Cargo Loading")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("GPS")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Speed")])])]), _c('tbody', [_vm._l(_vm.dailyOpsData, function (position, index) {
          return _vm.dailyOpsData.length > 0 ? [_c('tr', {
            key: "pos-".concat(index)
          }, [_c('td', {
            attrs: {
              "colspan": "22"
            }
          }, [_c('h5', [_c('strong', [_vm._v(_vm._s(position.projectSub.project.projectName) + " - " + _vm._s(position.projectSub.name))])])])]), _vm._l(position.voyage, function (project, idx) {
            return position.voyage ? [_c('tr', {
              key: "proj-".concat(index).concat(idx)
            }, [_c('td', [_vm._v(_vm._s(idx + 1))]), _c('td', _vm._l(project.vehicle, function (each) {
              return _c('span', [_c('b-badge', {
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v(_vm._s(each.name))]), _vm._v("  ")], 1);
            }), 0), _c('td', [_vm._v(_vm._s(project.startDate))]), _c('td', [_vm._v(_vm._s(project.endDate))]), _c('td', [_vm._v(_vm._s(project.currentPosition))]), _c('td', [_vm._v(_vm._s(project.portLoading ? project.portLoading.portName : '-'))]), _c('td', [_vm._v(_vm._s(project.portDischarge ? project.portDischarge.portName : '-'))]), _c('td', [_vm._v(_vm._s(project.agentPol || '-'))]), _c('td', [_vm._v(_vm._s(project.agentPod || '-'))]), _c('td', _vm._l(project.pic, function (each) {
              return _c('span', [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(_vm._s(each.name))]), _vm._v("  ")], 1);
            }), 0), _c('td', [_vm._v(_vm._s(project.startDate))]), _c('td', [_vm._v(_vm._s(project.arrival || '-'))]), _c('td', [_vm._v(_vm._s(_vm.formatDate(_vm.filterDailyOps.date) || '-'))]), _c('td', [_vm._v(_vm._s(project.reason ? project.reason.name : '-'))]), _c('td', [_vm._v(_vm._s(project.activityDurationFormat || '-'))]), _c('td', [_vm._v(_vm._s(project.remark || '-'))]), _c('td', [_vm._v(_vm._s(project.docOnBoard || '-'))]), _c('td', [_vm._v(_vm._s(_vm.numberFormat(project.cargoLoading) || '-'))]), _c('td', [_vm._v(_vm._s(project.dmsLatitude || '-') + ", " + _vm._s(project.dmsLongitude || '-'))]), _c('td', [_vm._v(_vm._s(project.speed || '-'))])])] : _vm._e();
          })] : _vm._e();
        }), _vm.dailyOpsData.length === 0 ? _c('tr', [_c('td', {
          staticClass: "text-center",
          staticStyle: {
            "width": "2200px",
            "height": "700px"
          },
          attrs: {
            "colspan": "22"
          }
        }, [_vm._v(" No Data")])]) : _vm._e()], 2)])])]), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "repair",
            "aria-labelled-by": "profile-tab"
          }
        }, [_c('div', {
          staticClass: "table-responsive tableFixHead"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Fleet")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Current Position")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Remarks")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Arrival")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Update Today / On Going Repair")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Stoppage Time (Hours)")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Est Complete Repair")])])]), _c('tbody', _vm._l(_vm.idleRepair, function (repair, index) {
          return _c('tr', {
            key: "rep-".concat(index)
          }, [_c('td', [_vm._v(_vm._s(repair.no))]), _c('td', [_vm._v(_vm._s(repair.fleet))]), _c('td', [_vm._v(_vm._s(repair.currentPosition))]), _c('td', [_vm._v(_vm._s(repair.remarks))]), _c('td', [_vm._v(_vm._s(repair.arrival))]), _c('td', [_vm._v(_vm._s(repair.onGoingRepair))]), _c('td', [_vm._v(_vm._s(repair.StoppageTime))]), _c('td', [_vm._v(_vm._s(repair.completeRepair))])]);
        }), 0)])])]), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "docking",
            "aria-labelled-by": "contact-tab"
          }
        }, [_c('div', {
          staticClass: "table-responsive tableFixHead"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover",
          staticStyle: {
            "width": "1800px"
          }
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "10%"
          }
        }, [_vm._v("Fleet")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "15%"
          }
        }, [_vm._v("Position")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Preedocking")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Up Slip")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Waiting Up Slip (H)")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Date")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Docking Progress (Hours)")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Down Slip")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Waiting Down Slip (H)")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Completed Docking")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "5%"
          }
        }, [_vm._v("Progress %")]), _c('th', {
          attrs: {
            "scope": "col",
            "width": "25%"
          }
        }, [_vm._v("Remarks")])])]), _c('tbody', _vm._l(_vm.shipyard, function (shipyard, index) {
          return _c('tr', {
            key: "ship-".concat(index)
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(shipyard.no))]), _c('td', [_vm._v(_vm._s(shipyard.fleet))]), _c('td', [_vm._v(_vm._s(shipyard.position))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(shipyard.predocking))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(shipyard.upSlip))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(shipyard.waitingUpSlip) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(shipyard.updateToday))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(shipyard.dockingProgress) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(shipyard.downSlip))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(shipyard.waitingDownSlip) + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(shipyard.completed))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(shipyard.progress))]), _c('td', [_vm._v(_vm._s(shipyard.remarks))])]);
        }), 0)])])])], 1)], 1)];
      },
      proxy: true
    }], null, false, 3269678749)
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": ""
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MER (Monthly Employment Report) FILTER")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.filter-statistic",
            modifiers: {
              "filter-statistic": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$filterMonthlyOps, _vm$filterMonthlyOps$, _vm$filterMonthlyOps2;
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "filter-statistic"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category:",
            "label-for": "kind-fleet"
          }
        }, [_vm._l(_vm.vehicle, function (item, index) {
          return [_c('b-form-checkbox', {
            key: "ve-".concat(index),
            attrs: {
              "disabled": item.disabled,
              "value": item.value,
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            model: {
              value: _vm.filterMonthlyOps.vehicleTypeIds,
              callback: function callback($$v) {
                _vm.$set(_vm.filterMonthlyOps, "vehicleTypeIds", $$v);
              },
              expression: "filterMonthlyOps.vehicleTypeIds"
            }
          }, [_vm._v(" " + _vm._s(item.text) + " ")])];
        })], 2)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Date Range Filter"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.filterMonthlyOps.dateRange,
            callback: function callback($$v) {
              _vm.$set(_vm.filterMonthlyOps, "dateRange", $$v);
            },
            expression: "filterMonthlyOps.dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Category Ownership"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.categoryOwnership,
            "id": "gender"
          },
          model: {
            value: _vm.filterMonthlyOps.ownership,
            callback: function callback($$v) {
              _vm.$set(_vm.filterMonthlyOps, "ownership", $$v);
            },
            expression: "filterMonthlyOps.ownership"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Project Filter"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.projectOptions,
            "multiple": true,
            "group-values": "sub",
            "group-label": "project",
            "group-select": true,
            "placeholder": "Type to search",
            "track-by": "name",
            "label": "name"
          },
          model: {
            value: _vm.filterMonthlyOps.subProject,
            callback: function callback($$v) {
              _vm.$set(_vm.filterMonthlyOps, "subProject", $$v);
            },
            expression: "filterMonthlyOps.subProject"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.fetchMonthlyOps();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1), _c('hr')], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category:",
            "label-for": "kind-fleet"
          }
        }, [_c('h5', [_c('strong', [_vm._v(_vm._s(_vm.filterMonthlyOps.vehicleTypeIds.length > 0 ? _vm.showTextVehicleType(_vm.filterMonthlyOps.vehicleTypeIds) : 'All Category Vehicle'))])])])], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Date Range"
          }
        }, [_c('h5', [_c('strong', [_vm._v(_vm._s(_vm.formatDate(_vm.filterMonthlyOps.dateRange[0])) + " - " + _vm._s(_vm.formatDate(_vm.filterMonthlyOps.dateRange[1])))])])])], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Category Ownership"
          }
        }, [_c('h5', [_c('strong', [_vm._v(_vm._s(_vm.filterMonthlyOps.ownership ? _vm.showTextOwnership(_vm.filterMonthlyOps.ownership) : 'All Category Ownership'))])])])], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Project Filter"
          }
        }, [((_vm$filterMonthlyOps = _vm.filterMonthlyOps) === null || _vm$filterMonthlyOps === void 0 ? void 0 : (_vm$filterMonthlyOps$ = _vm$filterMonthlyOps.subProject) === null || _vm$filterMonthlyOps$ === void 0 ? void 0 : _vm$filterMonthlyOps$.length) > 0 ? _c('h5', _vm._l((_vm$filterMonthlyOps2 = _vm.filterMonthlyOps) === null || _vm$filterMonthlyOps2 === void 0 ? void 0 : _vm$filterMonthlyOps2.subProject, function (each, i) {
          return _c('strong', {
            key: i,
            staticClass: "mr-2"
          }, [_vm._v(_vm._s(each.name) + ",")]);
        }), 0) : _c('h5', [_c('strong', [_vm._v("All Project")])])])], 1)], 1)];
      },
      proxy: true
    }], null, false, 1790976260)
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block",
      "body-class": ""
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("OPERATIONAL FLEET MATRIX")])])];
      },
      proxy: true
    }, _vm.findMenuPermission() ? {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          staticClass: "mb-3 mr-1 mt-3",
          attrs: {
            "variant": "dark"
          },
          on: {
            "click": function click($event) {
              return _vm.exportMonthlyOps();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("Export ")])];
      },
      proxy: true
    } : null, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_vm.loadingOpsMatrix ? _c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm._l(_vm.opsMatrix.matrix, function (matrix, index) {
          return [_c('b-card', {
            key: "mat-".concat(index),
            staticClass: "mb-1",
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-header', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: 'matrix' + matrix.projectSub.id,
              expression: "'matrix' + matrix.projectSub.id"
            }],
            staticClass: "p-1",
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('h4', {
            staticClass: "p-3",
            staticStyle: {
              "cursor": "pointer",
              "text-transform": "uppercase",
              "font-weight": "bold"
            }
          }, [_vm._v(" " + _vm._s(matrix.projectSub.project.projectName) + " - " + _vm._s(matrix.projectSub.name) + " ")])]), _c('b-collapse', {
            attrs: {
              "id": 'matrix' + matrix.projectSub.id,
              "visible": matrix.visibility,
              "role": "tabpanel"
            }
          }, [_c('b-card-body', [_vm._l(matrix.vehicleTypes, function (category, index2) {
            var _category$vehicles$;
            return [_c('b-card', {
              key: "mt2-".concat(index2),
              staticClass: "mb-1",
              attrs: {
                "no-body": ""
              }
            }, [_c('b-card-header', {
              directives: [{
                name: "b-toggle",
                rawName: "v-b-toggle",
                value: 'category' + index2,
                expression: "'category' + index2"
              }],
              staticClass: "p-1",
              attrs: {
                "header-tag": "header",
                "role": "tab"
              }
            }, [_c('h4', {
              staticClass: "p-3",
              staticStyle: {
                "cursor": "pointer"
              }
            }, [_vm._v(" " + _vm._s(category.vehicleType.name) + " ")])]), _c('b-collapse', {
              attrs: {
                "id": 'category' + index2,
                "visible": "",
                "role": "tabpanel"
              }
            }, [_c('b-card-body', [_c('b-card-text', [category.vehicles.vehicle !== 'he' ? _c('div', {
              staticClass: "table-responsive"
            }, [_c('table', {
              staticClass: "table mb-0 table-striped table-hover"
            }, [_c('thead', {
              staticClass: "text-center"
            }, [_c('tr', {
              staticClass: "bg-primary",
              staticStyle: {
                "text-transform": "uppercase"
              }
            }, [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Fleet")]), _c('th', [_vm._v("Date")]), category.vehicles[0] ? _vm._l((_category$vehicles$ = category.vehicles[0]) === null || _category$vehicles$ === void 0 ? void 0 : _category$vehicles$.activities, function (activity) {
              return _c('th', [_vm._v(_vm._s(activity.name))]);
            }) : _vm._e()], 2)]), _c('tbody', _vm._l(category.vehicles, function (ops, idx) {
              return _c('tr', {
                key: "cat-".concat(idx)
              }, [_c('td', {
                staticClass: "text-center"
              }, [_vm._v(" " + _vm._s(idx + 1) + " ")]), _c('td', [_vm._v(_vm._s(ops.vehicle.name))]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v(_vm._s(_vm.formatDate(_vm.filterMonthlyOps.dateRange[0])) + " - " + _vm._s(_vm.formatDate(_vm.filterMonthlyOps.dateRange[1])))]), _vm._l(ops === null || ops === void 0 ? void 0 : ops.activities, function (activity) {
                return _c('td', {
                  staticClass: "text-center"
                }, [_vm._v(" " + _vm._s(activity.durationFormat) + " ")]);
              })], 2);
            }), 0)])]) : _vm._e()])], 1)], 1)], 1)];
          })], 2)], 1)], 1)];
        })]], 2)];
      },
      proxy: true
    }], null, true)
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block",
      "body-class": ""
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("OPERATIONAL STATISTIC")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.loadingOpsMatrix ? _c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_c('tab-nav', {
          attrs: {
            "tabs": true
          }
        }, [_c('tab-nav-items', {
          attrs: {
            "active": true,
            "id": "percent-tab",
            "ariaControls": "percent",
            "role": "tab",
            "ariaSelected": true,
            "title": "Based on Percentage"
          }
        }), _c('tab-nav-items', {
          attrs: {
            "active": false,
            "id": "hours-tab",
            "ariaControls": "hours",
            "role": "tab",
            "ariaSelected": false,
            "title": "Based on Hours"
          }
        })], 1), _c('div', {
          staticClass: "tab-content",
          attrs: {
            "id": "myTabContent"
          }
        }, [_c('tab-content-item', {
          attrs: {
            "active": true,
            "id": "percent"
          }
        }, [_vm._l(_vm.opsMatrix.matrix, function (percentage, index) {
          return [_c('b-card', {
            key: "per-".concat(index),
            staticClass: "mb-1",
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-header', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: 'percentage' + percentage.projectSub.id,
              expression: "'percentage' + percentage.projectSub.id"
            }],
            staticClass: "p-1",
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('h4', {
            staticClass: "p-3",
            staticStyle: {
              "cursor": "pointer"
            }
          }, [_vm._v(" " + _vm._s(percentage.projectSub.project.projectName) + " - " + _vm._s(percentage.projectSub.name) + " ")])]), _c('b-collapse', {
            attrs: {
              "id": 'percentage' + percentage.projectSub.id,
              "visible": percentage.visibility,
              "role": "tabpanel"
            }
          }, [_c('b-card-body', [_vm._l(percentage.vehicleTypes, function (category, index2) {
            return [_c('b-card', {
              key: "per2-".concat(index2),
              staticClass: "mb-1",
              attrs: {
                "no-body": ""
              }
            }, [_c('b-card-header', {
              directives: [{
                name: "b-toggle",
                rawName: "v-b-toggle",
                value: 'category' + index2,
                expression: "'category' + index2"
              }],
              staticClass: "p-1",
              attrs: {
                "header-tag": "header",
                "role": "tab"
              }
            }, [_c('h4', {
              staticClass: "p-3",
              staticStyle: {
                "cursor": "pointer"
              }
            }, [_vm._v(" " + _vm._s(category.vehicleType.name) + " ")])]), _c('b-collapse', {
              attrs: {
                "id": 'category' + index2,
                "visible": "",
                "role": "tabpanel"
              }
            }, [_c('b-card-body', [percentage.TypeVehicleOpt ? _c('b-form-group', {
              attrs: {
                "label": 'Filter Chart ' + category.vehicleType.name
              }
            }, [_c('v-multiselect', {
              attrs: {
                "tag-placeholder": "Category",
                "placeholder": "Search or statistic",
                "label": "name",
                "track-by": "name",
                "options": percentage.TypeVehicleOpt,
                "multiple": true,
                "taggable": true
              },
              on: {
                "input": function input($event) {
                  return _vm.onChange($event, index, index2);
                }
              },
              model: {
                value: category.bodyData.data,
                callback: function callback($$v) {
                  _vm.$set(category.bodyData, "data", $$v);
                },
                expression: "category.bodyData.data"
              }
            })], 1) : _vm._e(), _c('b-card-text', [_c('AmChart', {
              attrs: {
                "element": "chart-".concat(percentage.projectSub.id, "-").concat(index, "-").concat(index2, "-").concat(category.bodyData.type),
                "type": category.bodyData.type,
                "option": category.bodyData
              }
            })], 1)], 1)], 1)], 1)];
          })], 2)], 1)], 1)];
        })], 2), _c('tab-content-item', {
          attrs: {
            "active": false,
            "id": "hours",
            "aria-labelled-by": "hours-tab"
          }
        }, [_vm._l(_vm.opsMatrix.matrix, function (hours, index) {
          return [_c('b-card', {
            key: "stat-".concat(index),
            staticClass: "mb-1",
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-header', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: 'hours' + hours.projectSub.id,
              expression: "'hours' + hours.projectSub.id"
            }],
            staticClass: "p-1",
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('h4', {
            staticClass: "p-3",
            staticStyle: {
              "cursor": "pointer"
            }
          }, [_vm._v(" " + _vm._s(hours.projectSub.project.projectName) + " - " + _vm._s(hours.projectSub.name) + " ")])]), _c('b-collapse', {
            attrs: {
              "id": 'hours' + hours.projectSub.id,
              "visible": hours.visibility,
              "accordion": "my-accordion",
              "role": "tabpanel"
            }
          }, [_c('b-card-body', [_vm._l(hours.vehicleTypes, function (category, index2) {
            var _category$bodyData$da;
            return [_c('b-card', {
              key: "stat-h-".concat(index2),
              staticClass: "mb-1",
              attrs: {
                "no-body": ""
              }
            }, [_c('b-card-header', {
              directives: [{
                name: "b-toggle",
                rawName: "v-b-toggle",
                value: "category-".concat(hours.projectSub.id, "=").concat(index2),
                expression: "`category-${hours.projectSub.id}=${index2}`"
              }],
              staticClass: "p-1",
              attrs: {
                "header-tag": "header",
                "role": "tab"
              }
            }, [_c('h4', {
              staticClass: "p-3",
              staticStyle: {
                "cursor": "pointer"
              }
            }, [_vm._v(" " + _vm._s(category.vehicleType.name) + " ")])]), _c('b-collapse', {
              attrs: {
                "id": "category-".concat(hours.projectSub.id, "=").concat(index2),
                "visible": "",
                "role": "tabpanel"
              }
            }, [_c('b-card-body', [_c('b-form-group', {
              attrs: {
                "label": 'Filter Chart ' + category.vehicleType.name
              }
            }, [_c('v-multiselect', {
              attrs: {
                "tag-placeholder": "Category",
                "placeholder": "Search or statistic",
                "options": (_category$bodyData$da = category.bodyData.data) === null || _category$bodyData$da === void 0 ? void 0 : _category$bodyData$da.map(function (f) {
                  return f.name;
                }),
                "multiple": true,
                "taggable": true
              },
              on: {
                "input": function input($event) {
                  return _vm.onChangeBarChart($event, index, index2);
                }
              },
              model: {
                value: category.opsChart.xaxis.categories,
                callback: function callback($$v) {
                  _vm.$set(category.opsChart.xaxis, "categories", $$v);
                },
                expression: "category.opsChart.xaxis.categories"
              }
            })], 1), _c('b-card-text', [!category.loadingChart ? _c('ApexChart', {
              attrs: {
                "element": "chart-hours-".concat(hours.projectSub.id, "-").concat(index, "-").concat(index2, "-").concat(category.bodyData.type),
                "chartOption": category.opsChart
              }
            }) : _vm._e()], 1)], 1)], 1)], 1)];
          })], 2)], 1)], 1)];
        })], 2)], 1)]];
      },
      proxy: true
    }], null, false, 2230560459)
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("TRIP & CARGO")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_vm._l(_vm.opsMatrix.tripCargo, function (cargo, index) {
          return [_c('b-card', {
            key: "trip-".concat(index),
            staticClass: "mb-1",
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-header', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: 'cargo' + cargo.projectSub.id,
              expression: "'cargo' + cargo.projectSub.id"
            }],
            staticClass: "p-1",
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('h4', {
            staticClass: "p-3",
            staticStyle: {
              "cursor": "pointer"
            }
          }, [_vm._v(" " + _vm._s(cargo.projectSub.project.projectName) + " - " + _vm._s(cargo.projectSub.name) + " ")])]), _c('b-collapse', {
            attrs: {
              "id": 'cargo' + cargo.projectSub.id,
              "visible": cargo.visibility,
              "role": "tabpanel"
            }
          }, [_c('b-card-body', [_c('b-card-text', [_c('div', {
            staticClass: "table-responsive"
          }, [_c('table', {
            staticClass: "table mb-0 table-striped table-hover"
          }, [_c('thead', {
            staticClass: "thead-dark text-center"
          }, [_c('tr', [_c('th', {
            attrs: {
              "scope": "col"
            }
          }, [_vm._v("No")]), _c('th', {
            attrs: {
              "scope": "col"
            }
          }, [_vm._v("Fleet")]), _c('th', {
            attrs: {
              "scope": "col"
            }
          }, [_vm._v("Date")]), _c('th', {
            attrs: {
              "scope": "col"
            }
          }, [_vm._v("Trip Status")]), _c('th', {
            attrs: {
              "scope": "col"
            }
          }, [_vm._v("Cargo Status")])])]), _c('tbody', [_vm._l(cargo.trip, function (trip, index2) {
            return _c('tr', {
              key: "trip-d-".concat(index2)
            }, [_c('td', [_vm._v(_vm._s(index2 + 1))]), _c('td', _vm._l(trip.vehicle, function (each, index3) {
              return _c('span', {
                key: "fleet-".concat(index3)
              }, [_vm._v(" " + _vm._s(index3 > 0 ? ' / ' + each.name : each.name) + " ")]);
            }), 0), _c('td', {
              staticClass: "text-center"
            }, [_vm._v(_vm._s(_vm.formatDate(_vm.filterMonthlyOps.dateRange[0])) + " - " + _vm._s(_vm.formatDate(_vm.filterMonthlyOps.dateRange[1])))]), _c('td', {
              staticClass: "text-center"
            }, [_vm._v(_vm._s(trip.tripStatus))]), _c('td', {
              staticClass: "text-center"
            }, [_vm._v(_vm._s(trip.cargoStatus))])]);
          }), _c('tr', [_c('td', {
            staticClass: "text-right font-weight-bold",
            attrs: {
              "colspan": "3"
            }
          }, [_vm._v("Total")]), _c('td', {
            staticClass: "text-center font-weight-bold"
          }, [_vm._v(_vm._s(_vm.numberFormat(cargo.totalTripStatus)))]), _c('td', {
            staticClass: "text-center font-weight-bold"
          }, [_vm._v(_vm._s(_vm.numberFormat(cargo.totalCargoStatus)))])])], 2)])])])], 1)], 1)], 1)];
        })], 2)];
      },
      proxy: true
    }], null, false, 2845483396)
  })], 1), _c('b-col', {
    attrs: {
      "sm": "6 "
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("TRIP STATISTIC")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_vm._l(_vm.opsMatrix.tripCargo, function (trip, index) {
          return [_c('b-card', {
            key: "trip-stat-".concat(index),
            staticClass: "mb-1",
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-header', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: 'trip' + trip.projectSub.id,
              expression: "'trip' + trip.projectSub.id"
            }],
            staticClass: "p-1",
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('h4', {
            staticClass: "p-3",
            staticStyle: {
              "cursor": "pointer"
            }
          }, [_vm._v(" " + _vm._s(trip.projectSub.project.projectName) + " - " + _vm._s(trip.projectSub.name) + " ")])]), _c('b-collapse', {
            attrs: {
              "id": 'trip' + trip.projectSub.id,
              "visible": trip.visibilityTripStats,
              "role": "tabpanel"
            }
          }, [_c('b-card-body', [_c('b-card-text', [_vm.$route.meta.dark ? _c('ApexChart', {
            attrs: {
              "element": 'patient-chart-02' + trip.projectSub.id,
              "chartOption": trip.tripChart
            }
          }) : _c('ApexChart', {
            attrs: {
              "element": 'patient-chart-02' + trip.projectSub.id,
              "chartOption": trip.tripChart
            }
          })], 1)], 1)], 1)], 1)];
        })], 2)];
      },
      proxy: true
    }], null, false, 1282505632)
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_vm._v("Cargo Statistic")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm._l(_vm.opsMatrix.tripCargo, function (cargo, index) {
          return [_c('b-card', {
            key: "car-stat-".concat(index),
            staticClass: "mb-1",
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-header', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: 'cargoStatistic' + cargo.projectSub.id,
              expression: "'cargoStatistic' + cargo.projectSub.id"
            }],
            staticClass: "p-1",
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('h4', {
            staticClass: "p-3",
            staticStyle: {
              "cursor": "pointer"
            }
          }, [_vm._v(" " + _vm._s(cargo.projectSub.project.projectName) + " - " + _vm._s(cargo.projectSub.name) + " ")])]), _c('b-collapse', {
            attrs: {
              "id": 'cargoStatistic' + cargo.projectSub.id,
              "visible": cargo.visibilityStats,
              "role": "tabpanel"
            }
          }, [_c('b-card-body', [_c('b-card-text', [_vm.$route.meta.dark ? _c('ApexChart', {
            attrs: {
              "element": 'patient-chart-03' + cargo.projectSub.id,
              "chartOption": cargo.cargoChart
            }
          }) : _c('ApexChart', {
            attrs: {
              "element": 'patient-chart-03' + cargo.projectSub.id,
              "chartOption": cargo.cargoChart
            }
          })], 1)], 1)], 1)], 1)];
        })];
      },
      proxy: true
    }], null, false, 3416303818)
  })], 1)], 1)], 1)] : _vm.dashboard === 'mi' ? [_c('SummaryManagement')] : _vm.dashboard === 'technical_maintenance' ? void 0 : _vm.dashboard === 'crewing' ? [_c('h3', [_vm._v("On Development")])] : _vm.dashboard === 'mi_list_unsettled' ? [_c('ListInitialTable', {
    attrs: {
      "show-status-action": false,
      "show-filter": false,
      "non-settled": "",
      "type-form": "claim"
    }
  })] : _vm._e()], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }