<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>SERVICE & REPAIR DASHBOARD</strong>
            </h4>
          </template>
          <template v-slot:headerAction>
            <a class="text-primary float-right" v-b-toggle.collapse-filter role="button" style="cursor: pointer">
              <i class="fa fa-filter" />
            </a>
          </template>
          <template v-slot:body>
            <b-collapse visible id="collapse-filter" class="mb-2">
              <b-row>
                <b-col md="3">
                  <b-form-group label="Fleet Category" label-for="fleet_category">
                    <v-multiselect v-model="fleetCategorySelect" tag-placeholder="Category" placeholder="Fleet Category" label="text" track-by="value" :options="fleetCategory" :multiple="true" :taggable="true"></v-multiselect>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Fleet *" label-for="Fleet">
                    <b-form-select plain v-model="selectedFleets" :options="kapal" size="lg">
                      <template v-slot:first>
                        <b-form-select-option :value="null">Select Fleet</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Clasification *" label-for="clasification">
                    <b-form-select plain v-model="selectedClasification" :options="clasification" size="lg">
                      <template v-slot:first>
                        <b-form-select-option :value="null">Select Clasification</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Period" label-for="Period">
                    <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range"></date-picker>
                  </b-form-group>
                </b-col>
                <b-col md="1">
                  <b-form-group label="Filter" label-for="button_search">
                    <b-button variant="primary" class="mb-3 mr-1">
                      <i class="fa fa-filter"></i>
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-collapse>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-6 p-0" :active="true" href="#request" title="REQUEST & PROGRESS" />
                <tab-nav-items class="col-md-6 p-0" :active="false" href="#finish" title="FINISH SERVICE & REPAIR" />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <div class="iq-edit-list-data">
          <div id="pills-tabContent-2" class="tab-content">
            <tab-content-item :active="true" id="request">
              <iq-card>
                <template v-slot:headerTitle>
                  <h5 class="card-title text-primary">
                    <strong>REQUEST SERVICE & REPAIR</strong>
                  </h5>
                </template>
                <template v-slot:headerAction>
                  <b-button block variant="primary" v-b-modal.add-init @click="showInitModal = !showInitModal">
                    <i class="fa fa-plus"></i> &nbsp; Add Data
                  </b-button>
                  <!-- Modal -->
                  <b-modal v-model="showInitModal" id="add-init" size="xl" title="Add Service & Repair" header-bg-variant="light" header-text-variant="dark" footer-bg-variant="light" footer-text-variant="dark">
                    <b-row class="mb-4">
                      <b-col md="4" class="text-right">
                        <b-form-input type="text" placeholder="Search fleets..." v-model="fleet_params.search" @input="fetchFleets"></b-form-input>
                      </b-col>
                    </b-row>
                    <div v-if="loading_fleet" class="text-center my-5">
                      <b-spinner type="grow" label="Spinning"></b-spinner>
                    </div>
                    <template v-else>
                      <b-row v-if="fleets.length > 0">
                        <b-col md="3" class="text-center py-5 border-top" v-for="(fleet, i) in fleets" :key="`fleet-${i}`">
                          <div class="custom-control custom-checkbox image-checkbox">
                            <input :id="`ck-fl-${i}`" type="checkbox" class="custom-control-input" :value="fleet.id" v-model="checkedFleets" />
                            <label class="custom-control-label" :for="`ck-fl-${i}`">
                              <img :src="
                                fleet.vehicleType.icon.url
                                  | checkPhoto(
                                    require('@src/assets/images/fleet/PusherBarge.png')
                                  )
                              " alt="#" class="img-fluid" />
                            </label>
                            <small>
                              <strong>{{ fleet.name }}</strong>
                            </small>
                          </div>
                        </b-col>
                      </b-row>
                      <div v-else class="text-center my-5">
                        <p class="mb-0 text-muted">No fleets data found.</p>
                      </div>
                    </template>
                    <template #modal-footer="{ ok, cancel, hide }">
                      <b-row>
                        <b-col md="12" class="text-center">
                          <b-button variant="none" class="iq-bg-danger text-right" @click="cancel()">Cancel</b-button>
                          <b-button type="submit" variant="primary" @click="showInitModalNext = !showInitModalNext;showInitModal = !showInitModal" class="ml-2 text-right">Next <i class="fa fa-arrow-right"></i>
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                  </b-modal>
                </template>
                <template v-slot:body>
                  <table class="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th class="text-center">FLEET&nbsp;NAME</th>
                        <th class="text-center">LOCATION</th>
                        <th class="text-center">ACCIDENT</th>
                        <th class="text-center">TOTAL REPAIR</th>
                        <th class="text-center">CLASSIFICATION</th>
                        <th class="text-center">OFFICE/PIC</th>
                        <th class="text-center">STATUS</th>
                        <th class="text-center">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">TB. TCP 201</td>
                        <td class="text-center">Lubuk Tutung</td>
                        <td class="text-center">12/06/2021</td>
                        <td class="text-center">11 Repairs</td>
                        <td class="text-center">
                          <b-badge variant="success d-block" class="mb-1">Minor : 2 Repair</b-badge>
                          <b-badge variant="primary d-block" class="mb-1">Medium : 6 Repair</b-badge>
                          <b-badge variant="danger d-block">Major : 3 Repair</b-badge>
                        </td>
                        <td class="text-center"> Asam Asam Office <br>
                          <small>PIC : <strong>Darmawan</strong>
                          </small>
                        </td>
                        <td class="text-center">
                          <b-badge variant="success d-block">Created</b-badge>
                        </td>
                        <td class="text-center">
                          <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#" v-b-toggle.review_sr>
                              <i class="fa fa-eye mr-2"></i>Review & Approve
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-modal.reject-modal>
                              <i class="fa fa-remove mr-2"></i>Reject
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.update_sr>
                              <i class="fa fa-pencil-square mr-2"></i>Update Data
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.add-sr>
                              <i class="fa fa-plus mr-2"></i>Add Repair
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.detail_sr>
                              <i class="fa fa-info-circle mr-2"></i>Detail
                            </b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">PT. TCP 207</td>
                        <td class="text-center">Celukan Bawang</td>
                        <td class="text-center">21/10/2022</td>
                        <td class="text-center">12 Repairs</td>
                        <td class="text-center">
                          <b-badge variant="success d-block" class="mb-1">Minor : 10 Repair</b-badge>
                          <b-badge variant="primary d-block" class="mb-1">Medium : 2 Repair</b-badge>
                          <b-badge variant="danger d-block">Major : 0 Repair</b-badge>
                        </td>
                        <td class="text-center"> Sangatta Office <br>
                          <small>PIC : <strong>Hendra Ridwan</strong>
                          </small>
                        </td>
                        <td class="text-center">
                          <b-badge variant="danger d-block">Reject</b-badge>
                        </td>
                        <td class="text-center">
                          <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#">
                              <i class="fa fa-check-square mr-2"></i>Activation
                            </b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-3">
                    <b-pagination :value="1" :total-rows="50" align="center" />
                  </div>
                  <!-- SLIDE ADD DATA -->
                  <b-sidebar v-model="showInitModalNext" id="add-sr" title="ADD SERVICE REPAIR - TB. TCP 201" width="90%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col cols="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Engine Information</legend>
                            <table class="table mb-0 table-striped">
                              <tbody>
                                <tr>
                                  <th>Main Engine 1 - Yanmar 12LAK</th>
                                  <td class="text-right">Actual RH : <strong>2.800 RH</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Main Engine 1 - Yanmar 12LAK</th>
                                  <td class="text-right">Actual RH : <strong>2.800 RH</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Auxuauxiliary Engine 1 - Cummin KW32</th>
                                  <td class="text-right">Actual RH : <strong>1.800 RH</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Auxuauxiliary Engine 1 - Cummin KW32</th>
                                  <td class="text-right">Actual RH : <strong>1.800 RH</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                        </b-col>
                        <b-col cols="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Service & Repair Form</legend>
                            <b-row>
                              <b-col md="10">
                                <b-form-input id="job_name" type="text" placeholder="Equipmenr Name"></b-form-input>
                              </b-col>
                              <b-col md="2">
                                <b-button variant="success" block>SEARCH</b-button>
                              </b-col>
                              <b-col md="4">
                                <v-jstree :data="tree" show-checkbox allow-batch whole-row :item-events="itemEvents" @item-click="itemClick" class="mt-3">
                                  <template slot-scope="_">
                                    <div style="display: inherit; width: 200px" @click.ctrl="customItemClickWithCtrl">
                                      <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                                      {{_.model.text}}
                                    </div>
                                  </template>
                                </v-jstree>
                              </b-col>
                              <b-col md="8">
                                <b-row class="mt-3">
                                  <b-col md="12">
                                    <b-alert :show="true" variant="primary">
                                      <div class="custom-control custom-control-inline">
                                        <label>
                                          <b>SELECT JOB FROM :</b>
                                        </label>
                                      </div>
                                      <div class="custom-control custom-control-inline custom-radio">
                                        <input type="radio" name="number" class="custom-control-input" value="one" id="fleet_equipment">
                                        <label class="custom-control-label" for="fleet_equipment">From Equipment</label>
                                      </div>
                                      <div class="custom-control custom-control-inline custom-radio">
                                        <input type="radio" name="number" class="custom-control-input" value="two" id="general_sr">
                                        <label class="custom-control-label" for="general_sr">Others Service & Repair</label>
                                      </div>
                                    </b-alert>
                                  </b-col>
                                  <b-col md="12">
                                    <b-button block variant="primary" class="mt-3 mb-3">
                                      <i class="fa fa-plus"></i> ADD REPAIR JOB
                                    </b-button>
                                  </b-col>
                                  <b-col md="12">
                                    <fieldset class="form-group border p-3">
                                      <legend class="w-auto px-2 text-primary">Detail Information</legend>
                                      <table class="table mb-0 table-striped">
                                        <tbody>
                                          <tr>
                                            <th width="15%">Location</th>
                                            <td width="5%">:</td>
                                            <td width="30%">
                                              <b-form-input type="text" placeholder="Example : Celukan Bawang"></b-form-input>
                                            </td>
                                            <th width="15%">Date Accident</th>
                                            <td width="5%">:</td>
                                            <td width="30%">
                                              <b-form-input type="date"></b-form-input>
                                            </td>
                                          </tr>
                                          <tr>
                                            <th width="15%">Office / Site</th>
                                            <td width="5%">:</td>
                                            <td width="30%">
                                              <b-form-input type="text" placeholder="Example : Asam-Asam"></b-form-input>
                                            </td>
                                            <th width="15%">PIC Repair</th>
                                            <td width="5%">:</td>
                                            <td width="30%">
                                              <b-form-input type="text"></b-form-input>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </fieldset>
                                    <hr>
                                    <fieldset class="form-group border p-3">
                                      <legend class="w-auto px-2 text-primary">Repair #1</legend>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Equipment Name" label-for="equipmentName">
                                            <b-form-input type="text"></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Repair Title" label-for="repairTitle">
                                            <b-form-input type="text"></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Clasification *" label-for="clasification">
                                            <b-form-select plain v-model="selectedClasification" :options="clasification" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Clasification</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Category *" label-for="category">
                                            <b-form-select plain v-model="selectedCategory" :options="category" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Category</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Problem Case *" label-for="problem_case">
                                            <b-form-select plain v-model="selectedProblemCase" :options="problemCase" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Problem Case</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Kind of Task *" label-for="kind_of_task">
                                            <b-form-select plain v-model="selectedKindTask" :options="kindTask" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Kind of Task</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Start Date *" label-for="start_date">
                                            <b-form-input type="date" id="start_date"></b-form-input>
                                            <small style="float: right">click <i class="fa fa-calendar"></i> to show </small>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Estimation Finish *" label-for="estFinish">
                                            <b-form-input type="date" id="end_date"></b-form-input>
                                            <small style="float: right">click <i class="fa fa-calendar"></i> to show </small>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Task File *" label-for="attactment_file">
                                            <b-form-file plain id="attactment_file"></b-form-file>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Description" label-for="description">
                                            <b-form-textarea id="description" rows="3"></b-form-textarea>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-row class="mt-2">
                                        <b-col md="12">
                                          <b-button variant="primary" class="pull-right" size="sm">
                                            <i class="fa fa-trash"></i> Delete Repair
                                          </b-button>
                                        </b-col>
                                      </b-row>
                                    </fieldset>
                                    <fieldset class="form-group border p-3">
                                      <legend class="w-auto px-2 text-primary">Repair #2</legend>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Equipment Name" label-for="equipmentName">
                                            <b-form-input type="text"></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Repair Title" label-for="repairTitle">
                                            <b-form-input type="text"></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Clasification *" label-for="clasification">
                                            <b-form-select plain v-model="selectedClasification" :options="clasification" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Clasification</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Category *" label-for="category">
                                            <b-form-select plain v-model="selectedCategory" :options="category" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Category</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Problem Case *" label-for="problem_case">
                                            <b-form-select plain v-model="selectedProblemCase" :options="problemCase" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Problem Case</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Kind of Task *" label-for="kind_of_task">
                                            <b-form-select plain v-model="selectedKindTask" :options="kindTask" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Kind of Task</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Start Date *" label-for="start_date">
                                            <b-form-input type="date" id="start_date"></b-form-input>
                                            <small style="float: right">click <i class="fa fa-calendar"></i> to show </small>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Estimation Finish *" label-for="estFinish">
                                            <b-form-input type="date" id="end_date"></b-form-input>
                                            <small style="float: right">click <i class="fa fa-calendar"></i> to show </small>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Task File *" label-for="attactment_file">
                                            <b-form-file plain id="attactment_file"></b-form-file>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Description" label-for="description">
                                            <b-form-textarea id="description" rows="3"></b-form-textarea>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-row class="mt-2">
                                        <b-col md="12">
                                          <b-button variant="primary" class="pull-right" size="sm">
                                            <i class="fa fa-trash"></i> Delete Repair
                                          </b-button>
                                        </b-col>
                                      </b-row>
                                    </fieldset>
                                    <b-button variant="success" block>
                                      <i class="fa fa-plus"></i> ADD REPAIR
                                    </b-button>
                                  </b-col>
                                  <b-col md="12" class="text-right mt-2">
                                    <b-card-text>
                                      <small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge>
                                      </small>
                                    </b-card-text>
                                    <b-button type="submit" variant="primary">
                                      <i class="fa fa-save"></i> Save
                                    </b-button>
                                    <b-button type="submit" variant="none" class="iq-bg-danger ml-3">
                                      <i class="fa fa-close"></i> Reset Form
                                    </b-button>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </fieldset>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE END -->
                  <!-- SLIDE REVIEW -->
                  <b-sidebar id="review_sr" title="REVIEW SERVICE REPAIR - TB. ETI 201" width="50%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="12">
                          <div class="accordion" role="tablist">
                            <b-card no-body>
                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle.job-1-1 variant="info" class="text-left">
                                  <i class="fa fa-chevron-circle-down"></i> FUEL INJECTOR - PEBAIKAN KEBOCORAN FUEL INJECTOR
                                </b-button>
                              </b-card-header>
                              <b-collapse id="job-1-1" accordion="my-accordion-sub" role="tabpanel">
                                <table class="table table-striped mt-3">
                                  <tbody>
                                    <tr>
                                      <th width="20%">Lokasi Kejadian</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Morosi</td>
                                      <th width="20%">Tanggal Kejadian</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">12 Desember 2022</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Job Number</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">#1025</td>
                                      <th width="20%">Job Name</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Perbaikan Navigation Light</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Classification</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Medium</td>
                                      <th width="20%">Category</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Welding</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Problem Case</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Material Fatique</td>
                                      <th width="20%">Kind Task</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">General Service</td>
                                    </tr>
                                    <tr>
                                      <td width="20%">
                                        <b>Cost Estimasi</b>
                                      </td>
                                      <td width="5%">
                                        <b>:</b>
                                      </td>
                                      <td width="25%">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                          </div>
                                          <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                        </div>
                                      </td>
                                      <td width="20%">
                                        <b>Cost Real</b>
                                      </td>
                                      <td width="5%">
                                        <b>:</b>
                                      </td>
                                      <td width="25%">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                          </div>
                                          <input type="number" class="form-control" id="penawaran" placeholder="1.300.000">
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20%">
                                        <b>PIC</b>
                                      </td>
                                      <td width="5%">
                                        <b>:</b>
                                      </td>
                                      <td colspan="4">Nurul Akbar</td>
                                    </tr>
                                    <tr>
                                      <td width="20%">
                                        <b>Attachment</b>
                                      </td>
                                      <td width="5%">
                                        <b>:</b>
                                      </td>
                                      <td colspan="4">
                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="align-top" width="20%">
                                        <b>Description</b>
                                      </td>
                                      <td class="align-top" width="5%">
                                        <b>:</b>
                                      </td>
                                      <td class="align-top" colspan="4">
                                        <ul type="number">
                                          <li>- Remove the injector</li>
                                          <li> - Clean up deposit <ul>
                                              <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                              <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                              <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                            </ul>
                                          </li>
                                          <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                          <li>- Fit up</li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <b-form-row>
                                  <b-col>
                                    <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                      <template v-for="(item,index) in text">
                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                          <div class="custom-switch-inner">
                                            <p class="mb-0"> {{ item.label }} </p>
                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel"></label>
                                          </div>
                                        </div>
                                      </template>
                                    </b-form-group>
                                  </b-col>
                                </b-form-row>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                        <b-form-select plain v-model="selectedJabatan" :options="optionsJabatan" size="lg">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                        <b-form-select plain v-model="selectedJabatan" :options="optionsJabatan" size="lg">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-button variant="success" class="mt-3">
                                    <i class="fa fa-users"></i> ADD INTERNAL MAN POWER
                                  </b-button>
                                </fieldset>
                                <b-form-row>
                                  <b-col>
                                    <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                      <template v-for="(item,index) in text">
                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                          <div class="custom-switch-inner">
                                            <p class="mb-0"> {{ item.label }} </p>
                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel"></label>
                                          </div>
                                        </div>
                                      </template>
                                    </b-form-group>
                                  </b-col>
                                </b-form-row>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                        <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                        <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Penawaran" label-for="penawaran">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                          </div>
                                          <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                        </div>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                          </div>
                                          <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                        </div>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Notes" label-for="notes">
                                        <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Attachment" label-for="doc_shipyard">
                                        <div class="custom-file">
                                          <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                          <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                        </div>
                                        <small class="text-muted"> File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge>
                                          <br> File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                        </small>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-button variant="success" class="mb-3">
                                    <i class="fa fa-users"></i> ADD SUB KONTRAKTOR
                                  </b-button>
                                </fieldset>
                                <b-alert :show="true" variant="success">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-thumbs-up"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Upslip <b>APPROVED</b>
                                  </div>
                                </b-alert>
                                <b-alert :show="true" variant="danger">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-close"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Upslip <b>REJECT</b>
                                  </div>
                                </b-alert>
                                <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1">
                                  <i class="fa fa-close"></i> REJECT
                                </b-button>
                                <b-button type="submit" variant="primary" class="float-right">
                                  <i class="fa fa-thumbs-up"></i> APPROVE
                                </b-button>
                              </b-collapse>
                            </b-card>
                            <b-card no-body>
                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle.job-1-2 variant="info" class="text-left">
                                  <i class="fa fa-chevron-circle-down"></i> SENSOR - KALIBRASI SESNOR SONAR
                                </b-button>
                              </b-card-header>
                              <b-collapse id="job-1-2" accordion="my-accordion-sub" role="tabpanel">
                                <table class="table table-striped mt-3">
                                  <tbody>
                                    <tr>
                                      <th width="20%">Lokasi Kejadian</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Morosi</td>
                                      <th width="20%">Tanggal Kejadian</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">12 Desember 2022</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Job Number</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">#1025</td>
                                      <th width="20%">Job Name</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Perbaikan Navigation Light</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Classification</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Medium</td>
                                      <th width="20%">Category</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Welding</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Problem Case</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Material Fatique</td>
                                      <th width="20%">Kind Task</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">General Service</td>
                                    </tr>
                                    <tr>
                                      <td width="20%">
                                        <b>Cost Estimasi</b>
                                      </td>
                                      <td width="5%">
                                        <b>:</b>
                                      </td>
                                      <td width="25%">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                          </div>
                                          <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                        </div>
                                      </td>
                                      <td width="20%">
                                        <b>Cost Real</b>
                                      </td>
                                      <td width="5%">
                                        <b>:</b>
                                      </td>
                                      <td width="25%">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                          </div>
                                          <input type="number" class="form-control" id="penawaran" placeholder="1.300.000">
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td width="20%">
                                        <b>PIC</b>
                                      </td>
                                      <td width="5%">
                                        <b>:</b>
                                      </td>
                                      <td colspan="4">Nurul Akbar</td>
                                    </tr>
                                    <tr>
                                      <td width="20%">
                                        <b>Attachment</b>
                                      </td>
                                      <td width="5%">
                                        <b>:</b>
                                      </td>
                                      <td colspan="4">
                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="align-top" width="20%">
                                        <b>Description</b>
                                      </td>
                                      <td class="align-top" width="5%">
                                        <b>:</b>
                                      </td>
                                      <td class="align-top" colspan="4">
                                        <ul type="number">
                                          <li>- Remove the injector</li>
                                          <li> - Clean up deposit <ul>
                                              <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                              <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                              <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                            </ul>
                                          </li>
                                          <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                          <li>- Fit up</li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <b-form-row>
                                  <b-col>
                                    <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                      <template v-for="(item,index) in text">
                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                          <div class="custom-switch-inner">
                                            <p class="mb-0"> {{ item.label }} </p>
                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel"></label>
                                          </div>
                                        </div>
                                      </template>
                                    </b-form-group>
                                  </b-col>
                                </b-form-row>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                        <b-form-select plain v-model="selectedJabatan" :options="optionsJabatan" size="lg">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                        <b-form-select plain v-model="selectedJabatan" :options="optionsJabatan" size="lg">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-button variant="success" class="mt-3">
                                    <i class="fa fa-users"></i> ADD INTERNAL MAN POWER
                                  </b-button>
                                </fieldset>
                                <b-form-row>
                                  <b-col>
                                    <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                      <template v-for="(item,index) in text">
                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                          <div class="custom-switch-inner">
                                            <p class="mb-0"> {{ item.label }} </p>
                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel"></label>
                                          </div>
                                        </div>
                                      </template>
                                    </b-form-group>
                                  </b-col>
                                </b-form-row>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                        <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                        <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Penawaran" label-for="penawaran">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                          </div>
                                          <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                        </div>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                          </div>
                                          <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                        </div>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Notes" label-for="notes">
                                        <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Attachment" label-for="doc_shipyard">
                                        <div class="custom-file">
                                          <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                          <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                        </div>
                                        <small class="text-muted"> File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge>
                                          <br> File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                        </small>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-button variant="success" class="mb-3">
                                    <i class="fa fa-users"></i> ADD SUB KONTRAKTOR
                                  </b-button>
                                </fieldset>
                                <b-alert :show="true" variant="success">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-thumbs-up"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Upslip <b>APPROVED</b>
                                  </div>
                                </b-alert>
                                <b-alert :show="true" variant="danger">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-close"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Upslip <b>REJECT</b>
                                  </div>
                                </b-alert>
                                <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1">
                                  <i class="fa fa-close"></i> REJECT
                                </b-button>
                                <b-button type="submit" variant="primary" class="float-right">
                                  <i class="fa fa-thumbs-up"></i> APPROVE
                                </b-button>
                              </b-collapse>
                            </b-card>
                          </div>
                        </b-col>
                        <b-col md="12">
                          <b-button variant="primary" class="mt-2 pull-right">REVIEW & SUBMIT</b-button>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE END -->
                  <!-- SLIDE REVIEW -->
                  <b-sidebar id="detail_sr" title="DETAIL SERVICE REPAIR - TB. ETI 201" width="60%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Engine Information</legend>
                            <table class="table mb-0 table-striped table-success">
                              <tbody>
                                <tr>
                                  <th>Main Engine 1 - Yanmar 12LAK</th>
                                  <td class="text-right">Actual RH : <strong>2.800 RH</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Main Engine 1 - Yanmar 12LAK</th>
                                  <td class="text-right">Actual RH : <strong>2.800 RH</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Auxuauxiliary Engine 1 - Cummin KW32</th>
                                  <td class="text-right">Actual RH : <strong>1.800 RH</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Auxuauxiliary Engine 1 - Cummin KW32</th>
                                  <td class="text-right">Actual RH : <strong>1.800 RH</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Job Information</legend>
                            <table class="table table-striped">
                              <tbody>
                                <tr>
                                  <th width="20%">Lokasi Kejadian</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">Morosi</td>
                                  <th width="20%">Tanggal Kejadian</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">12 Desember</td>
                                </tr>
                                <tr>
                                  <th width="20%">Job Number</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">#1025</td>
                                  <th width="20%">Job Name</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">Perbaikan Navigation Light</td>
                                </tr>
                                <tr>
                                  <th width="20%">Classification</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">Medium</td>
                                  <th width="20%">Category</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">Welding</td>
                                </tr>
                                <tr>
                                  <th width="20%">Problem Case</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">Material Fatique</td>
                                  <th width="20%">Kind Task</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">General Service</td>
                                </tr>
                                <tr>
                                  <td width="20%">
                                    <b>Cost Estimasi</b>
                                  </td>
                                  <td width="5%">
                                    <b>:</b>
                                  </td>
                                  <td width="25%">Rp. 1.500.000</td>
                                  <td width="20%">
                                    <b>Cost Real</b>
                                  </td>
                                  <td width="5%">
                                    <b>:</b>
                                  </td>
                                  <td width="25%">Rp. 1.300.000</td>
                                </tr>
                                <tr>
                                  <td width="20%">
                                    <b>PIC</b>
                                  </td>
                                  <td width="5%">
                                    <b>:</b>
                                  </td>
                                  <td colspan="4">Nurul Akbar</td>
                                </tr>
                                <tr>
                                  <td width="20%">
                                    <b>Attachment</b>
                                  </td>
                                  <td width="5%">
                                    <b>:</b>
                                  </td>
                                  <td colspan="4">
                                    <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                      <li class="col-md-1 col-1">
                                        <a href="javascript:void(0);">
                                          <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                        </a>
                                      </li>
                                      <li class="col-md-1 col-1">
                                        <a href="javascript:void(0);">
                                          <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                        </a>
                                      </li>
                                      <li class="col-md-1 col-1">
                                        <a href="javascript:void(0);">
                                          <img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid">
                                        </a>
                                      </li>
                                      <li class="col-md-1 col-1">
                                        <a href="javascript:void(0);">
                                          <img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid">
                                        </a>
                                      </li>
                                      <li class="col-md-1 col-1">
                                        <a href="javascript:void(0);">
                                          <img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid">
                                        </a>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="20%">
                                    <b>Description</b>
                                  </td>
                                  <td width="5%">
                                    <b>:</b>
                                  </td>
                                  <td colspan="4"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE END -->
                  <!-- SLIDE ADD DATA -->
                  <b-sidebar id="update_sr" title="Update Service & Repair" width="60%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col cols="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Engine Information</legend>
                            <table class="table mb-0 table-striped">
                              <tbody>
                                <tr>
                                  <th>Main Engine 1 - Yanmar 12LAK</th>
                                  <td class="text-right">Actual RH : <strong>2.800 RH</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Main Engine 1 - Yanmar 12LAK</th>
                                  <td class="text-right">Actual RH : <strong>2.800 RH</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Auxuauxiliary Engine 1 - Cummin KW32</th>
                                  <td class="text-right">Actual RH : <strong>1.800 RH</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Auxuauxiliary Engine 1 - Cummin KW32</th>
                                  <td class="text-right">Actual RH : <strong>1.800 RH</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Detail Information</legend>
                            <table class="table mb-0 table-striped">
                              <tbody>
                                <tr>
                                  <th width="15%">Location</th>
                                  <td width="5%">:</td>
                                  <td width="30%">
                                    <b-form-input type="text" placeholder="Example : Celukan Bawang"></b-form-input>
                                  </td>
                                  <th width="15%">Date Accident</th>
                                  <td width="5%">:</td>
                                  <td width="30%">
                                    <b-form-input type="date"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <th width="15%">Office / Site</th>
                                  <td width="5%">:</td>
                                  <td width="30%">
                                    <b-form-input type="text" placeholder="Example : Asam-Asam"></b-form-input>
                                  </td>
                                  <th width="15%">PIC Repair</th>
                                  <td width="5%">:</td>
                                  <td width="30%">
                                    <b-form-input type="text"></b-form-input>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <hr>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Repair #1</legend>
                            <b-form-row>
                              <b-col>
                                <b-form-group label="Equipment Name" label-for="equipmentName">
                                  <b-form-input type="text"></b-form-input>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Repair Title" label-for="repairTitle">
                                  <b-form-input type="text"></b-form-input>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Clasification *" label-for="clasification">
                                  <b-form-select plain v-model="selectedClasification" :options="clasification" size="lg">
                                    <template v-slot:first>
                                      <b-form-select-option :value="null">Select Clasification</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                            <b-form-row>
                              <b-col>
                                <b-form-group label="Category *" label-for="category">
                                  <b-form-select plain v-model="selectedCategory" :options="category" size="lg">
                                    <template v-slot:first>
                                      <b-form-select-option :value="null">Select Category</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Problem Case *" label-for="problem_case">
                                  <b-form-select plain v-model="selectedProblemCase" :options="problemCase" size="lg">
                                    <template v-slot:first>
                                      <b-form-select-option :value="null">Select Problem Case</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Kind of Task *" label-for="kind_of_task">
                                  <b-form-select plain v-model="selectedKindTask" :options="kindTask" size="lg">
                                    <template v-slot:first>
                                      <b-form-select-option :value="null">Select Kind of Task</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                            <b-form-row>
                              <b-col>
                                <b-form-group label="Start Date *" label-for="start_date">
                                  <b-form-input type="date" id="start_date"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show </small>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Estimation Finish *" label-for="estFinish">
                                  <b-form-input type="date" id="end_date"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show </small>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Task File *" label-for="attactment_file">
                                  <b-form-file plain id="attactment_file"></b-form-file>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                            <b-form-row>
                              <b-col>
                                <b-form-group label="Description" label-for="description">
                                  <b-form-textarea id="description" rows="3"></b-form-textarea>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Repair #2</legend>
                            <b-form-row>
                              <b-col>
                                <b-form-group label="Equipment Name" label-for="equipmentName">
                                  <b-form-input type="text"></b-form-input>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Repair Title" label-for="repairTitle">
                                  <b-form-input type="text"></b-form-input>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Clasification *" label-for="clasification">
                                  <b-form-select plain v-model="selectedClasification" :options="clasification" size="lg">
                                    <template v-slot:first>
                                      <b-form-select-option :value="null">Select Clasification</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                            <b-form-row>
                              <b-col>
                                <b-form-group label="Category *" label-for="category">
                                  <b-form-select plain v-model="selectedCategory" :options="category" size="lg">
                                    <template v-slot:first>
                                      <b-form-select-option :value="null">Select Category</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Problem Case *" label-for="problem_case">
                                  <b-form-select plain v-model="selectedProblemCase" :options="problemCase" size="lg">
                                    <template v-slot:first>
                                      <b-form-select-option :value="null">Select Problem Case</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Kind of Task *" label-for="kind_of_task">
                                  <b-form-select plain v-model="selectedKindTask" :options="kindTask" size="lg">
                                    <template v-slot:first>
                                      <b-form-select-option :value="null">Select Kind of Task</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                            <b-form-row>
                              <b-col>
                                <b-form-group label="Start Date *" label-for="start_date">
                                  <b-form-input type="date" id="start_date"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show </small>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Estimation Finish *" label-for="estFinish">
                                  <b-form-input type="date" id="end_date"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show </small>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Task File *" label-for="attactment_file">
                                  <b-form-file plain id="attactment_file"></b-form-file>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                            <b-form-row>
                              <b-col>
                                <b-form-group label="Description" label-for="description">
                                  <b-form-textarea id="description" rows="3"></b-form-textarea>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                          </fieldset>
                        </b-col>
                        <b-col md="12" class="text-right">
                          <b-card-text>
                            <small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge>
                            </small>
                          </b-card-text>
                          <b-button type="submit" variant="primary">
                            <i class="fa fa-save"></i> Save
                          </b-button>
                          <b-button type="submit" variant="none" class="iq-bg-danger ml-3">
                            <i class="fa fa-close"></i> Reset Form
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE END -->
                </template>
              </iq-card>
              <iq-card>
                <template v-slot:headerTitle>
                  <h5 class="card-title text-primary">
                    <strong>PROGRESS SERVICE & REPAIR</strong>
                  </h5>
                </template>
                <template v-slot:body>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">FLEET&nbsp;NAME</th>
                        <th class="text-center">LOCATION</th>
                        <th class="text-center">ACCIDENT</th>
                        <th class="text-center">TOTAL REPAIR</th>
                        <th class="text-center">CLASSIFICATION</th>
                        <th class="text-center">OFFICE/PIC</th>
                        <th class="text-center">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">TB. TCP 201</td>
                        <td class="text-center">Tanjung Bara</td>
                        <td class="text-center">12/06/2021</td>
                        <td class="text-center">5 Repairs</td>
                        <td class="text-center">
                          <b-badge variant="success d-block" class="mb-1">Minor : 2 Repair</b-badge>
                          <b-badge variant="primary d-block" class="mb-1">Medium : 3 Repair</b-badge>
                          <b-badge variant="danger d-block">Major : 0 Repair</b-badge>
                        </td>
                        <td class="text-center"> Asam Asam Office <br>
                          <small>PIC : <strong>Darmawan</strong>
                          </small>
                        </td>
                        <td class="text-center">
                          <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#" v-b-toggle.add-activity>
                              <i class="fa fa-comments mr-2"></i>Update Activity
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.add-sr>
                              <i class="fa fa-plus mr-2"></i>Add Repair
                            </b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">TB. TCP 201</td>
                        <td class="text-center">Tanjung Bara</td>
                        <td class="text-center">12/06/2021</td>
                        <td class="text-center">5 Repairs</td>
                        <td class="text-center">
                          <b-badge variant="success d-block" class="mb-1">Minor : 2 Repair</b-badge>
                          <b-badge variant="primary d-block" class="mb-1">Medium : 3 Repair</b-badge>
                          <b-badge variant="danger d-block">Major : 0 Repair</b-badge>
                        </td>
                        <td class="text-center"> Asam Asam Office <br>
                          <small>PIC : <strong>Darmawan</strong>
                          </small>
                        </td>
                        <td class="text-center">
                          <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#" v-b-toggle.add-activity>
                              <i class="fa fa-comments mr-2"></i>Update Activity
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.add-sr>
                              <i class="fa fa-plus mr-2"></i>Add Repair
                            </b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-3">
                    <b-pagination :value="1" :total-rows="50" align="center" />
                  </div>
                  <!-- SLIDE REVIEW -->
                  <b-sidebar id="add-activity" title="UPDATE ACTIVITY" width="50%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="12">
                          <tab-content-item :active="true" id="power">
                            <tab-nav :tabs="true">
                              <tab-nav-items :active="true" href="#activity" title="Activity" />
                              <tab-nav-items :active="false" href="#logs" title="Logs" />
                            </tab-nav>
                            <div id="myTabContent" class="tab-content">
                              <tab-content-item :active="true" id="activity">
                                <b-row>
                                  <b-col md="12" class="mb-5">
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Select Repair List" label-for="repair_list">
                                          <b-form-select plain v-model="selectedDockingJob" :options="optionsDockingJob">
                                            <template v-slot:first>
                                              <b-form-select-option :value="null">Select Repair</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label-for="progress_bar">
                                          <h4 class="mb-2">Progrees : <b-badge variant="warning">80%</b-badge>
                                          </h4>
                                          <b-progress :value="80" variant="success" max="100"></b-progress>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Start *" label-for="start">
                                          <b-form-input type="date" id="start"></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Progress Pekerjaan *" label-for="progress_pekerjaan">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text bg-primary" id="progress_pekerjaan">%</span>
                                            </div>
                                            <input type="text" class="form-control" id="progress_pekerjaan" placeholder="80">
                                          </div>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Report Progress" label-for="report_progress">
                                          <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                            <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                          </div>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Notes" label-for="notes">
                                          <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <div class="form-group row align-items-center mt-3 m-0">
                                      <div class="d-flex align-items-center">
                                        <div class="send-panel float-right">
                                          <div class="send-btn">
                                            <b-button variant="success">
                                              <i class="fa fa-check"></i> Finish
                                            </b-button>
                                            <b-button variant="primary" class="ml-2">
                                              <i class="fa fa-comment"></i> Update Activity
                                            </b-button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-col>
                                  <b-col md="12">
                                    <ul class="iq-timeline" style="height:100%">
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6>
                                        <br />
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2">
                                              <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid">
                                              </a>
                                            </li>
                                            <li class="col-md-2 col-2 pb-2">
                                              <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid">
                                              </a>
                                            </li>
                                            <li class="col-md-2 col-2 pb-2">
                                              <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid">
                                              </a>
                                            </li>
                                            <li class="col-md-2 col-2 pb-2">
                                              <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid">
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6>
                                        <br />
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Barang tiba di galangan</p>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6>
                                        <br />
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6>
                                        <br />
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2">
                                              <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                              </a>
                                            </li>
                                            <li class="col-md-2 col-2 pb-2">
                                              <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                              </a>
                                            </li>
                                            <li class="col-md-2 col-2 pb-2">
                                              <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                              </a>
                                            </li>
                                            <li class="col-md-2 col-2 pb-2">
                                              <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid">
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                    </ul>
                                  </b-col>
                                </b-row>
                              </tab-content-item>
                              <tab-content-item :active="false" id="logs">
                                <b-row>
                                  <b-col md="12">
                                    <ul class="iq-timeline" style="height:100%">
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6>
                                        <br />
                                        <small>19 Oktober 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Service Repair Finish</p>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6>
                                        <br />
                                        <small>18 Oktober 2019 15:41</small>
                                        <div class="d-inline-block w-100">
                                          <p>Update Stage</p>
                                          <b-button variant="success" class="mr-1">REVIEW & APPROVE</b-button>
                                          <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                          <b-button variant="danger" class="ml-1">REJECT</b-button>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">System</h6>
                                        <br />
                                        <small>17 Oktober 2019 18:30</small>
                                        <div class="d-inline-block w-100">
                                          <p>System mengirim email ke : <b-badge class="border border-primary text-primary ml-1" variant="none">aguuungtriilaksono@gmail.com</b-badge>
                                            <b-badge class="border border-primary text-primary ml-1" variant="none">nurulakbar93@gmail.com</b-badge>
                                          </p>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6>
                                        <br />
                                        <small>17 Oktober 2019 18:30</small>
                                        <div class="d-inline-block w-100">
                                          <p>Update Stage</p>
                                          <b-button variant="primary" class="mr-1">REQUEST</b-button>
                                          <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                          <b-button variant="success" class="ml-1">REVIEW & APPROVE</b-button>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">System</h6>
                                        <br />
                                        <small>15 Oktober 2019 16:00</small>
                                        <div class="d-inline-block w-100">
                                          <p>System mengirim email ke : <b-badge class="border border-primary text-primary ml-1" variant="none">aguuungtriilaksono@gmail.com</b-badge>
                                            <b-badge class="border border-primary text-primary ml-1" variant="none">nurulakbar93@gmail.com</b-badge>
                                          </p>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6>
                                        <br />
                                        <small>15 Oktober 2019 20:24</small>
                                        <div class="d-inline-block w-100">
                                          <p>Mengupdate data service repair</p>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6>
                                        <br />
                                        <small>15 Oktober 2019 16:00</small>
                                        <div class="d-inline-block w-100">
                                          <p>Membuat data service repair</p>
                                        </div>
                                      </li>
                                    </ul>
                                  </b-col>
                                </b-row>
                              </tab-content-item>
                            </div>
                          </tab-content-item>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE END -->
                </template>
              </iq-card>
            </tab-content-item>
            <tab-content-item :active="false" id="finish">
              <b-row>
                <b-col md="4" lg="4">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                    <template v-slot:body>
                      <div class="iq-info-box d-flex align-items-center p-3">
                        <div class="info-image mr-3">
                          <img src="../../assets/images/page-img/tb.png" class="img-fluid" alt="image-box">
                        </div>
                        <div class="info-text">
                          <h3>34 Fleet</h3>
                          <span>Total Repair Fleet</span>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="4" lg="4">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                    <template v-slot:body>
                      <div class="iq-info-box d-flex align-items-center p-3">
                        <div class="info-image mr-3">
                          <img src="../../assets/images/page-img/30.png" class="img-fluid" alt="image-box">
                        </div>
                        <div class="info-text">
                          <h5>152 TASK</h5>
                          <b-badge variant="success" class="mr-2">125 Repair On Time</b-badge>
                          <b-badge variant="primary">27 Repair Delay</b-badge>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="4" lg="4">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                    <template v-slot:body>
                      <div class="iq-info-box d-flex align-items-center p-3">
                        <div class="info-image mr-3">
                          <img src="../../assets/images/page-img/31.png" class="img-fluid" alt="image-box">
                        </div>
                        <div class="info-text">
                          <h3>Rp. 275.011.341</h3>
                          <span>Total Cost</span>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h5 class="card-title text-primary">
                        <strong>HISTORY SERVICE REPAIR</strong>
                      </h5>
                    </template>
                    <template v-slot:body>
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="text-center">NO</th>
                            <th class="text-center">FLEET NAME</th>
                            <th class="text-center">CURRENT POSITION</th>
                            <th class="text-center">RUNNING HOURS</th>
                            <th class="text-center">PIC</th>
                            <th class="text-center">TOTAL REPAIR</th>
                            <th class="text-center">MINOR</th>
                            <th class="text-center">MEDIUM</th>
                            <th class="text-center">MAJOR</th>
                            <th class="text-center">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">1</td>
                            <td>TB. TCP 201</td>
                            <td class="text-center">Asam-Asam</td>
                            <td class="text-center">2.500 H</td>
                            <td class="text-center">Agung Tri Laksono</td>
                            <td class="text-center">53</td>
                            <td class="text-center">15</td>
                            <td class="text-center">31</td>
                            <td class="text-center">22</td>
                            <td class="text-center">
                              <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#" v-b-modal.modal-3>
                                  <i class="fa fa-list-ol mr-2"></i>Task List
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">2</td>
                            <td>TB. ETI 203</td>
                            <td class="text-center">Sangatta</td>
                            <td class="text-center">3.130 H</td>
                            <td class="text-center">Agung Tri Laksono</td>
                            <td class="text-center">36</td>
                            <td class="text-center">15</td>
                            <td class="text-center">12</td>
                            <td class="text-center">16</td>
                            <td class="text-center">
                              <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#" v-b-modal.modal-3>
                                  <i class="fa fa-list-ol mr-2"></i>Task List
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
            </tab-content-item>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal id="reject-modal" size="l" title="Reject (Navigation Equipment - Navigation Light)" ok-title="Save Changes" cancel-title="Close">
      <b-row>
        <b-col md="12">
          <b-form-group label="Reason *" label-for="reason">
            <b-form-textarea id="reason" rows="2"></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>
<script>
  import {
    xray
  } from '../../config/pluginInit'
  import _ from 'lodash'
  import {
    fleetsActions
  } from '@src/Utils/helper'
  import {
    createPopper
  } from '@popperjs/core'
  export default {
    name: 'ProfileEdit',
    mounted() {
      xray.index()
      this.fetchFleets()
    },
    data() {
      return {
        // fleet
        showInitModal: false,
        showInitModalNext: false,
        select_all_fleets: false,
        loading_fleet: false,
        editingNode: null,
        fleet_params: {
          page: 1,
          showAll: true,
          search: ''
        },
        fleetCategorySelect: [],
        fleetCategory: [{
          text: 'Tug Boat',
          color: 'success',
          value: 'tugboat'
        }, {
          text: 'Barge',
          color: 'warning',
          value: 'barge'
        }, {
          text: 'Mother Vessel',
          color: 'primary',
          value: 'mother_vessel'
        }, {
          text: 'Heavy Equipment',
          color: 'primary',
          value: 'heavy_equipment'
        }, {
          text: 'Pusher Tug',
          color: 'primary',
          value: 'pusher_tug'
        }, {
          text: 'Pusher Barge',
          color: 'primary',
          value: 'pusher_barge'
        }],
        dateRange: '',
        menus: [],
        checkedFleets: [],
        selectedJabatan: null,
        optionsJabatan: [{
          value: '1',
          text: 'Port Enginer'
        }, {
          value: '2',
          text: 'Port Captain'
        }, {
          value: '3',
          text: 'Technical Superintendent'
        }, {
          value: '4',
          text: 'Technical Manager'
        }],
        selectedClasification: 1,
        clasification: [{
          value: '1',
          text: 'Minor'
        }, {
          value: '2',
          text: 'Medium'
        }, {
          value: '3',
          text: 'Major'
        }],
        selectedFleets: null,
        kapal: [{
          value: '1',
          text: 'TB. ETI 201'
        }, {
          value: '2',
          text: 'TB. ETI 202'
        }, {
          value: '3',
          text: 'TB. TCP 301'
        }],
        selectedCategory: 1,
        category: [{
          value: '1',
          text: 'Welding'
        }, {
          value: '2',
          text: 'Mechanical'
        }, {
          value: '3',
          text: 'Electrical'
        }, {
          value: '4',
          text: 'General Service'
        }],
        selectedProblemCase: 4,
        problemCase: [{
          value: '1',
          text: 'Human Error'
        }, {
          value: '2',
          text: 'Engine Failure'
        }, {
          value: '3',
          text: 'Material Fatigue'
        }, {
          value: '4',
          text: 'Environment'
        }],
        selectedKindTask: 1,
        kindTask: [{
          value: '1',
          text: 'Repair'
        }, {
          value: '2',
          text: 'Renew'
        }],
        selectedJobClass: 1,
        jobClass: [{
          value: '1',
          text: 'Corrective Maintenance'
        }, {
          value: '2',
          text: 'Claim'
        }, {
          value: '3',
          text: 'Preventive Maintenance'
        }, {
          value: '4',
          text: 'Routines'
        }, {
          value: '5',
          text: 'Short Service'
        }],
        text: [{
          activeLabel: 'Yes',
          inactiveLabel: 'No',
          class: 'primary',
          checked: true
        }],
        negotiation: {
          activeLabel: 'Yes',
          inactiveLabel: 'No',
          class: 'primary',
          checked: true
        },
        color: {
          color: 'success',
          checked: false
        },
        selectedDockingJob: null,
        optionsDockingJob: [{
          value: '1',
          text: 'Fuel Injector - Perbaikan Kerusakan Kebocoran Fuel Injector'
        }, {
          value: '2',
          text: 'Sensor - Kalibrasi Sendir Sonar'
        }],
        tree: [{
          'text': 'Acocommodation & Hull',
          'opened': true,
          'selected': false,
          'children': [{
            'text': 'Tanks',
            'selected': false
          }]
        }, {
          'text': 'Deck Equipment & Outfitting',
          'opened': true,
          'selected': false,
          'children': [{
            'text': 'Mooring Equipment',
            'selected': false
          }, {
            'text': 'Propeller',
            'selected': false
          }, {
            'text': 'Winch & Windlass',
            'selected': false
          }]
        }, {
          'text': 'Electrical Components & Parts',
          'opened': true,
          'selected': false
        }, {
          'text': 'Engine & Other Machinery',
          'opened': true,
          'selected': false,
          'children': [{
            'text': 'Gear Box',
            'selected': false
          }, {
            'text': 'Generator # Auxiliary Engine',
            'selected': false
          }, {
            'text': 'Generator Emergency',
            'selected': false
          }]
        }, {
          'text': 'Machinery Equipment',
          'opened': true,
          'selected': false,
          'children': [{
            'text': 'Main Engine',
            'selected': false
          }]
        }, {
          'text': 'Fire & Safety Equipment',
          'opened': true,
          'selected': false,
          'children': [{
            'text': 'Safety Equipment',
            'selected': false
          }]
        }, {
          'text': 'Navigation & Communication Equipment',
          'opened': true,
          'selected': false,
          'children': [{
            'text': 'Radio EQ',
            'selected': false
          }]
        }, {
          'text': 'Pipes & Fitting',
          'opened': true,
          'selected': false
        }, {
          'text': 'Pumps',
          'opened': true,
          'selected': false
        }],
        jobTypeList: [{
          name: 'Inspection',
          code: 'Inspection'
        }, {
          name: 'Overhaul',
          code: 'Overhaul'
        }, {
          name: 'Change',
          code: 'Change'
        }],
        tasks: {
          ideas: ['Chain Locker - CL-oo1'],
          todos: ['Cofferdam - CD-f03', 'Wheel House Accomodation'],
          completed: []
        }
      }
    },
    watch: {
      select_all_fleets: {
        deep: true,
        handler: function(checked) {
          this.checkedFleets = []
          if (checked) {
            this.fleets.forEach((f) => this.checkedFleets.push(f.id))
          }
        }
      }
    },
    created() {
      this.fetchFleets = _.debounce(this.fetchFleets, 1000)
    },
    methods: {
      ...fleetsActions,
      async fetchFleets() {
        this.loading_fleet = true
        let params = this.$options.filters.cleanObject(this.fleet_params)
        let res = await this.getFleets(params)
        if (!res.status) {
          this.loading_fleet = false
          this.fleets = []
        } else {
          this.loading_fleet = false
          this.fleets = res.data
        }
      },
      withPopper(dropdownList, component, {
        width
      }) {
        /**
         * We need to explicitly define the dropdown width since
         * it is usually inherited from the parent with CSS.
         */
        dropdownList.style.width = width
        /**
         * Here we position the dropdownList relative to the $refs.toggle Element.
         *
         * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
         * the dropdownList overlap by 1 pixel.
         *
         * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
         * wrapper so that we can set some styles for when the dropdown is placed
         * above.
         */
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.placement,
          modifiers: [{
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          }, {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({
              state
            }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            }
          }]
        })
        /**
         * To prevent memory leaks Popper needs to be destroyed.
         * If you return function, it will be called just before dropdown is removed from DOM.
         */
        return () => popper.destroy()
      },
      itemClick(node) {
        console.log(node.model.text + ' clicked !')
      },
      customItemClick: function(node, item, e) {
        e.stopPropagation()
        var index = node.parentItem.indexOf(item)
        node.parentItem.splice(index, 1)
      },
      customItemClickWithCtrl: function() {
        console.log('click + ctrl')
      }
    }
  }
</script>