<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-4 p-0" :active="true" href="#list" title="LIST ACCIDENT & INCIDENT" />
                <tab-nav-items class="col-md-4 p-0" :active="false" href="#report" title="REPORT" />
                <tab-nav-items class="col-md-4 p-0" :active="false" href="#recommendationForm"
                  title="RECOMMENDATION FORM" />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <!-- Operational -->
      <tab-content-item :active="true" id="list">
        <ListInitialTable/>
      </tab-content-item>
      <tab-content-item :active="false" id="report">
        <ListInitialReport/>
      </tab-content-item>
      <tab-content-item :active="false" id="recommendationForm">
        <ListInitialRecommendation/>
      </tab-content-item>
    </div>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import ListInitialTable from '@src/views/MI/ListInitialTable'
import ListInitialReport from '@src/views/MI/ListInitialReport'
import ListInitialRecommendation from '@src/views/MI/ListInitialRecommendation'

export default {
  name: 'ListInitial',
  components: { ListInitialRecommendation, ListInitialReport, ListInitialTable },
  mounted () {
    xray.index()
  },
  data () {
    return {}
  },
  methods: {
  }
}
</script>
<style scoped>
.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
  display: none;
}
#sidebar-view-next *,
#sidebar-view *,
#sidebar-view >>> * {
  font-size: 18px;
}
</style>
