var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("INVENTORY")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.filter",
            modifiers: {
              "filter": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-4",
          attrs: {
            "visible": "",
            "id": "filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionInvType,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Inventory Type")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectInvType,
            callback: function callback($$v) {
              _vm.selectInvType = $$v;
            },
            expression: "selectInvType"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1), _c('b-col', {
          attrs: {
            "md": "9"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Add Data',
            expression: "'Add Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              _vm.addPurchaseRequest = !_vm.addPurchaseRequest;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        })]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Refresh Data',
            expression: "'Refresh Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-refresh"
        })]), _c('b-dropdown', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Export Data',
            expression: "'Export Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "link iq-bg-primary",
            "toggle-class": "text-decoration-none",
            "no-caret": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-save m-0"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _vm._v("Export Excel")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf mr-2"
        }), _vm._v("Export PDF")])], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("INVENTORY ID")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DESCRIPTION")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TYPE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("WAREHOUSE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACCOUNT INVENTORY")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DEFAULT PRICE ")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("UOM")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              _vm.pageDetailSalesOrder = !_vm.pageDetailSalesOrder;
            }
          }
        }, [_vm._v("SP-2210001")])]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("THERMOMETER EXHAUST GAS")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Component Sparepart")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Jakarta")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("107201")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("0")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Pcs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-list-alt m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-edit mr-2"
        }), _vm._v("Update")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("Delete")])], 1)], 1)])])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('b-sidebar', {
          attrs: {
            "id": "detail_vendor",
            "title": "REQ-2210001 - TB. ETI 201",
            "width": "80%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var hide = _ref.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("General Information")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Request Number")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("REQ-2210001")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Company")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Transcoal Pacific")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Priority")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_c('b-badge', {
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("Normal")])], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Request Class")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("PURCHASE")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Fleet")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("TB. ETI 301")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Currenct")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("IDR")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Request By")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Project")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("KPC Project")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Est. Cost")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Rp. 2.500.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Department")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Operational")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Sub Project")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Long Hauling")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Open Qty")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("25")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Date")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("10/28/2022")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Location")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Sangatta")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Description")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Request Sparepart")])])])])]), _c('tab-nav', {
                attrs: {
                  "tabs": true
                }
              }, [_c('tab-nav-items', {
                attrs: {
                  "active": true,
                  "id": "item-tab",
                  "ariaControls": "item",
                  "role": "tab",
                  "ariaSelected": true,
                  "title": "Item Detail"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "vendor-tab",
                  "ariaControls": "vendor",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Vendor Info"
                }
              })], 1), _c('div', {
                staticClass: "tab-content",
                attrs: {
                  "id": "myTabContent"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "item"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("INVENTORY ID")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("DESCRIPTION ITEM")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("QTY")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("UOM")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("PRICE")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("SUB TOTAL")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("VENDOR INFO")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("VENDOR CONTACT")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("VENDOR LOCATION")])])]), _c('tbody', [_c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("INV-02101001")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Engine Oil Filter")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("10")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("PCS")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("225.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("2.250.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Utama Production, CV")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Kalimantan Selatan")])]), _c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("INV-02101002")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Hyd Oil Filter Element")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("20")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("PCS")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("225.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("2.250.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Utama Production, CV")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Kalimantan Selatan")])])])])])]), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "vendor",
                  "aria-labelled-by": "vendor-tab"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('h4', [_vm._v("New Vendor ?")]), _vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-left"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2), _c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "6"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h6 text-primary"
              }, [_vm._v("Vendor Information")]), _c('b-form', [_c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Company ID",
                  "label-for": "companyID"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "CompanyID"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Company Name",
                  "label-for": "companyName"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "vendorName"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Telepon",
                  "label-for": "telepon"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "telepon"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "WhatsApp Number",
                  "label-for": "whatsappNumber"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "whatsappNumber"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Email",
                  "label-for": "email"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "email",
                  "id": "email"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Website",
                  "label-for": "website"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "website"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Address",
                  "label-for": "address"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "address",
                  "rows": "3"
                }
              })], 1)], 1)], 1)], 1), _c('b-form')], 1)])], 1)], 1)], 1)], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.pageDetailSalesOrder,
            callback: function callback($$v) {
              _vm.pageDetailSalesOrder = $$v;
            },
            expression: "pageDetailSalesOrder"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "addPurchaseRequest",
            "title": "ADD INVENTORY",
            "width": "60%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hide = _ref2.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("General Information")]), _c('b-row', {
                staticClass: "pl-3 pr-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Inventory ID",
                  "label-for": "inventoryID"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "orderNumber",
                  "placeholder": "<< CREATE INVENTORY ID >>"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Status",
                  "label-for": "status"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionStatus,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Status")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectStatus,
                  callback: function callback($$v) {
                    _vm.selectStatus = $$v;
                  },
                  expression: "selectStatus"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Description Item",
                  "label-for": "descriptionItem"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "descriptionItem"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "UOM",
                  "label-for": "uom"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": ""
                }
              }, [_c('b-form-select-option', [_vm._v("Pcs")]), _c('b-form-select-option', [_vm._v("Meter")]), _c('b-form-select-option', [_vm._v("Box")]), _c('b-form-select-option', [_vm._v("Lusin")])], 1)], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Department",
                  "label-for": "Department"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionDepartment,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Department")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectDepartment,
                  callback: function callback($$v) {
                    _vm.selectDepartment = $$v;
                  },
                  expression: "selectDepartment"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "PIC Name",
                  "label-for": "picName"
                }
              }, [_c('b-form-input', {
                staticClass: "text-right",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "text",
                  "id": "qty"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Location",
                  "label-for": "location"
                }
              }, [_c('b-form-input', {
                staticClass: "text-right",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "text",
                  "id": "qty"
                }
              })], 1)], 1)], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("Item Default")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Item Class",
                  "label-for": "itemClass"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionItemClass,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Item Class")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectItemClass,
                  callback: function callback($$v) {
                    _vm.selectItemClass = $$v;
                  },
                  expression: "selectItemClass"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Item Type",
                  "label-for": "itemType"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionItemType,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Item Type")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectItemType,
                  callback: function callback($$v) {
                    _vm.selectItemType = $$v;
                  },
                  expression: "selectItemType"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Valuation Method",
                  "label-for": "ValuationMethod"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionValuationMethod,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Valuation Method")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectValuationMethod,
                  callback: function callback($$v) {
                    _vm.selectValuationMethod = $$v;
                  },
                  expression: "selectValuationMethod"
                }
              })], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Tax Category",
                  "label-for": "taxtCategory"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optiontaxtCategory,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Tax Category")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selecttaxtCategory,
                  callback: function callback($$v) {
                    _vm.selecttaxtCategory = $$v;
                  },
                  expression: "selecttaxtCategory"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Is a Kit",
                  "label-for": "isKit"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-left"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Warehouse",
                  "label-for": "WareHouse"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionWareHouse,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Ware House")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectWareHouse,
                  callback: function callback($$v) {
                    _vm.selectWareHouse = $$v;
                  },
                  expression: "selectWareHouse"
                }
              })], 1)], 1)], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("GL Account")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Inventory Account",
                  "label-for": "InvAccount"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionInvAccount,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Inventory Account")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectInvAccount,
                  callback: function callback($$v) {
                    _vm.selectInvAccount = $$v;
                  },
                  expression: "selectInvAccount"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Sales Account",
                  "label-for": "SalesAccount"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionSalesAccount,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Sales Account")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectSalesAccount,
                  callback: function callback($$v) {
                    _vm.selectSalesAccount = $$v;
                  },
                  expression: "selectSalesAccount"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "COGS Account",
                  "label-for": "CogsAccount"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionCogsAccount,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select COGS Account")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectCogsAccount,
                  callback: function callback($$v) {
                    _vm.selectCogsAccount = $$v;
                  },
                  expression: "selectCogsAccount"
                }
              })], 1)], 1)], 1)], 1)], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.addPurchaseRequest,
            callback: function callback($$v) {
              _vm.addPurchaseRequest = $$v;
            },
            expression: "addPurchaseRequest"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }