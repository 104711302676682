var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "view-maintenance-detail"
  }, [_vm.maintenanceDetailData ? _c('b-row', {
    staticClass: "pt-2 pl-3 pr-3 pb-2 mb-4"
  }, [_vm.stateAction === 'VIEW_DETAIL' ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12"
    }
  }, [['MAINTENANCE'].includes(_vm.maintenanceDetailData.status) && _vm.canReview ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "info",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onAction('ON_REVIEW');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-eye"
  }), _vm._v(" Review ")]) : _vm._e(), ['REVIEW'].includes(_vm.maintenanceDetailData.status) && _vm.canApprove ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "success",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onAction('ON_APPROVE');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-check"
  }), _vm._v(" Approve ")]) : _vm._e(), ['TOWARDS', 'OVERDUE'].includes(_vm.maintenanceDetailData.status) && _vm.canAdd ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onAction('ON_MAINTENANCE');
      }
    }
  }, [_c('i', {
    staticClass: "las la-tasks"
  }), _vm._v("Maintenance ")]) : _vm._e(), ['TOWARDS', 'OVERDUE'].includes(_vm.maintenanceDetailData.status) ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "danger",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onAction('ON_CONVERT');
      }
    }
  }, [_c('i', {
    staticClass: "las la-anchor"
  }), _vm._v(" Convert Service & Repair ")]) : _vm._e(), ['TOWARDS', 'OVERDUE'].includes(_vm.maintenanceDetailData.status) ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "warning",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onAction('ON_POSTPONE');
      }
    }
  }, [_c('i', {
    staticClass: "las la-calendar"
  }), _vm._v(" Postpone ")]) : _vm._e()], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2"
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.vehicle.vehicleType.name) + " - " + _vm._s(_vm.maintenanceDetailData.vehicle.name))]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('table', {
    staticClass: "table mb-0 table-striped"
  }, [_c('tbody', _vm._l(_vm.maintenanceDetailData.vehicle.powers, function (engine) {
    return _c('tr', [_c('th', [_vm._v(_vm._s(engine.powerType.name) + " - " + _vm._s(engine.powerSeries.name))]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v("Actual RH : "), _c('strong', [_vm._v(_vm._s(engine.actualRunningHours) + " RH")])])]);
  }), 0)])]), _c('b-col', {
    staticClass: "iq-bg-secondary pt-3 mb-3",
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Job Code:",
      "label-for": "job_name"
    }
  }, [_c('h5', [_c('strong', [_vm._v(_vm._s(_vm.maintenanceDetailData.jobCode))])])])], 1), _c('b-col', {
    staticClass: "iq-bg-secondary pt-3 mb-3",
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Job Class",
      "label-for": "fleet-equipment"
    }
  }, [_c('h5', [_c('strong', [_vm._v(_vm._s("".concat(_vm.maintenanceDetailData.jobClass.jobClassCode, " - ").concat(_vm.maintenanceDetailData.jobClass.jobClass)))])])])], 1), _c('b-col', {
    staticClass: "iq-bg-secondary pt-3 mb-3",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Job Name:",
      "label-for": "job_name"
    }
  }, [_c('h5', [_c('strong', [_vm._v(_vm._s(_vm.maintenanceDetailData.jobName))])])])], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Remarks:",
      "label-for": "job_name"
    }
  }, [_c('h5', [_c('strong', {
    domProps: {
      "innerHTML": _vm._s(_vm.maintenanceDetailData.notes)
    }
  })])])], 1), _c('b-col', {
    staticClass: "mb-3 mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('hr')]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('v-jstree', {
    attrs: {
      "data": _vm.treeInitMaintenance,
      "show-checkbox": "",
      "allow-batch": "",
      "whole-row": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_) {
        return [_c('div', {
          staticStyle: {
            "display": "inherit",
            "width": "200px"
          }
        }, [!_.model.loading ? _c('i', {
          class: _.vm.themeIconClasses,
          attrs: {
            "role": "presentation"
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
      }
    }], null, false, 4262975071)
  })], 1), _c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2"
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.vehicle.name) + " - " + _vm._s(_vm.maintenanceDetailData.vehicle.vehicleType.name))]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type - Serial No",
      "label-for": "job_name"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.maintenanceDetailData.maintenanceMaster.equipmentId) + " - " + _vm._s(_vm.maintenanceDetailData.maintenanceMaster.equipmentName))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "ul-lists-styled",
    attrs: {
      "label": "Maker",
      "label-for": "job_name"
    }
  }, [_c('h6', [_c('ul', [_c('li', [_vm._v("Maker No: " + _vm._s(_vm.maintenanceDetailData.componentSetup.makerNo))]), _c('li', [_vm._v("Maker: " + _vm._s(_vm.maintenanceDetailData.componentSetup.maker))])])])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Job Type",
      "label-for": "job_name"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.maintenanceDetailData.jobType.jobTypeCode) + " " + _vm._s(_vm.maintenanceDetailData.jobType.description))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "ul-lists-styled",
    attrs: {
      "label": "Job Description",
      "label-for": "job_name"
    }
  }, [_c('h6', {
    domProps: {
      "innerHTML": _vm._s(_vm.maintenanceDetailData.jobDesc)
    }
  })])], 1), _c('b-col', {
    staticClass: "text-center mb-4 mt-3 bg-light p-3",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Trigger",
      "label-for": "job_name"
    }
  }, [_vm.maintenanceDetailData.trigger === 'CALENDAR' ? _c('h6', [_vm._v("Calendar Trigger")]) : _vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('h6', [_vm._v("Counter Trigger (Running Hours)")]) : _vm._e()])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('b-form-group', {
    attrs: {
      "label": "Reset Running Hour for this job ?",
      "label-for": "sub_sub_project"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": "",
      "disabled": ""
    },
    model: {
      value: _vm.maintenanceDetailData.resetRunningHours,
      callback: function callback($$v) {
        _vm.$set(_vm.maintenanceDetailData, "resetRunningHours", $$v);
      },
      expression: "maintenanceDetailData.resetRunningHours"
    }
  }, [_vm.maintenanceDetailData.resetRunningHours ? [_vm._v("Yes")] : [_vm._v("No")]], 2)], 1) : _vm.maintenanceDetailData.trigger === 'CALENDAR' ? _c('b-form-group', {
    attrs: {
      "label": "Service Repair",
      "label-for": "sub_sub_project"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": "",
      "disabled": ""
    },
    model: {
      value: _vm.maintenanceDetailData.serviceRepair,
      callback: function callback($$v) {
        _vm.$set(_vm.maintenanceDetailData, "serviceRepair", $$v);
      },
      expression: "maintenanceDetailData.serviceRepair"
    }
  }, [_vm.maintenanceDetailData.serviceRepair ? [_vm._v("Yes")] : [_vm._v("No")]], 2)], 1) : _vm._e()], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('b-col', {
    staticClass: "mb-3 bg-light",
    attrs: {
      "md": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2"
  }, [_c('b-checkbox', {
    staticClass: "custom-switch-color",
    attrs: {
      "checked": true,
      "color": "dark",
      "name": "check-button",
      "inline": "",
      "disabled": ""
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.power.powerType.name) + " "), _c('small', [_vm._v(_vm._s(_vm.maintenanceDetailData.power.powerSeries.name) + " " + _vm._s(_vm.maintenanceDetailData.power.powerSeries.model))])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Interval/Frequency:",
      "label-for": "Interval:"
    }
  }, [_c('h6', [_c('strong', [_vm._v(_vm._s(_vm.maintenanceDetailData.interval) + " Hours")])])])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Last Maintenance Date",
      "label-for": "Current:"
    }
  }, [_c('strong', [_c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.lastMaintenanceDate))])], 1)])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Last Maintenance RH",
      "label-for": "Current:"
    }
  }, [_c('strong', [_c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.lastMaintenanceHours) + " Hours")])], 1)])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-for": "Current:"
    }
  }, [_c('strong', [_c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.status))])], 1)])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Possible Maintenance RH",
      "label-for": "Current:"
    }
  }, [_c('strong', [_c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.possibleMaintenanceHours) + " Hours")])], 1)])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Actual RH",
      "label-for": "Current:"
    }
  }, [_c('strong', [_c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.power.actualRunningHours) + " Hours")])], 1)])], 1)], 1)], 1)]) : _vm.maintenanceDetailData.trigger === 'CALENDAR' ? _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Interval/Frequency:",
      "label-for": "Interval:"
    }
  }, [_c('h6', [_c('strong', [_vm._v(_vm._s(_vm.maintenanceDetailData.interval) + " Month")])])])], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Last Maintenance Date",
      "label-for": "Current:"
    }
  }, [_c('strong', [_c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.lastMaintenanceDate))])], 1)])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "Notes",
      "label": "Notes Last Maintenance"
    }
  }, [_c('h5', {
    domProps: {
      "innerHTML": _vm._s(_vm.maintenanceDetailData.notes)
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.notes || '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-for": "Current:"
    }
  }, [_c('strong', [_c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.status))])], 1)])], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Possible Maintenance",
      "label-for": "Current:"
    }
  }, [_c('strong', [_c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.possibleMaintenanceDate))])], 1)])], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1), ['ON_MAINTENANCE', 'ON_POSTPONE', 'ON_CONVERT'].includes(_vm.stateAction) ? _c('fieldset', {
    staticClass: "form-group border p-3",
    attrs: {
      "id": "form-field"
    }
  }, [_vm.stateAction === 'ON_MAINTENANCE' ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Maintenance Date *"
    }
  }, [_c('date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "value-type": "DD-MM-YYYY",
      "format": "DD MMM YYYY",
      "type": "date",
      "placeholder": "Select date"
    },
    model: {
      value: _vm.formMaintenance.maintenanceDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formMaintenance, "maintenanceDate", $$v);
      },
      expression: "formMaintenance.maintenanceDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('b-form-group', {
    attrs: {
      "label": "Maintenance Running Hours *"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formMaintenance.maintenanceRunningHours,
      expression: "formMaintenance.maintenanceRunningHours"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.formMaintenance.maintenanceRunningHours
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formMaintenance, "maintenanceRunningHours", $event.target.value);
      }
    }
  })]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "d-flex flex-column",
    attrs: {
      "label": "File Maintenance"
    }
  }, [_c('b-form-file', {
    attrs: {
      "placeholder": "Choose a file",
      "name": "select-file-maintenance"
    },
    on: {
      "change": _vm.uploadFile
    }
  }), _vm.fileState ? _c('b-form-text', {
    staticClass: "text-danger",
    attrs: {
      "id": "file-live-help"
    }
  }, [_vm._v("*Max File 5 MB, Please re-upload file. ")]) : _vm._e(), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("File Type * : "), _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Max Capacity * : "), _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v("5 MB / File")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cost"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formMaintenance.cost,
      expression: "formMaintenance.cost"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.formMaintenance.cost
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formMaintenance, "cost", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "text-right"
  }, [_vm._v(" " + _vm._s(_vm.numberFormat(_vm.formMaintenance.cost)) + " ")])])], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Notes *"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "3",
      "placeholder": "Type Notes"
    },
    model: {
      value: _vm.formMaintenance.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.formMaintenance, "notes", $$v);
      },
      expression: "formMaintenance.notes"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.saveMaintenance();
      }
    }
  }, [_vm._v("Save")]), _c('b-button', {
    on: {
      "click": function click($event) {
        return _vm.onAction('VIEW_DETAIL');
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1) : _vm.stateAction === 'ON_POSTPONE' ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('b-form-group', {
    attrs: {
      "label": "Extra Hours *"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formPostpone.extraHours,
      expression: "formPostpone.extraHours"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.formPostpone.extraHours
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formPostpone, "extraHours", $event.target.value);
      }
    }
  })]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.maintenanceDetailData.trigger === 'CALENDAR' ? _c('b-form-group', {
    attrs: {
      "label": "Extra Date *"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formPostpone.extraDate,
      expression: "formPostpone.extraDate"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "date"
    },
    domProps: {
      "value": _vm.formPostpone.extraDate
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formPostpone, "extraDate", $event.target.value);
      }
    }
  })]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Reason *"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "3",
      "placeholder": "Type Reason"
    },
    model: {
      value: _vm.formPostpone.reason,
      callback: function callback($$v) {
        _vm.$set(_vm.formPostpone, "reason", $$v);
      },
      expression: "formPostpone.reason"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.savePostpone();
      }
    }
  }, [_vm._v("Save")]), _c('b-button', {
    on: {
      "click": function click($event) {
        return _vm.onAction('VIEW_DETAIL');
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1) : _vm.stateAction === 'ON_CONVERT' ? _c('b-row') : _vm._e()], 1) : _vm._e(), ['REVIEW', 'APPROVE'].includes(_vm.maintenanceDetailData.status) ? _c('fieldset', {
    staticClass: "form-group border p-3",
    attrs: {
      "id": "form-field"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Last Maintenance Date"
    }
  }, [_vm._v(" " + _vm._s(_vm.maintenanceDetailData.lastMaintenanceDate) + " ")])], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('b-form-group', {
    attrs: {
      "label": "Maintenance Running Hours"
    }
  }, [_vm._v(" " + _vm._s(_vm.maintenanceDetailData.lastMaintenanceHours) + " ")]) : _vm._e()], 1), _vm.maintenanceDetailData.file ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "File Maintenance"
    }
  }, [_c('a', {
    attrs: {
      "href": _vm.maintenanceDetailData.file.url
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.file.fileName))])])], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cost"
    }
  }, [_vm._v(" Rp." + _vm._s(_vm.numberFormat(_vm.maintenanceDetailData.cost)) + " ")])], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Notes"
    }
  }, [_vm.maintenanceDetailData.status === 'APPROVE' ? _c('span', [_vm._v(_vm._s(_vm.maintenanceDetailData.finishNotes))]) : _c('span', [_vm._v(_vm._s(_vm.maintenanceDetailData.notes))])])], 1)], 1)], 1) : _vm._e()]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('b-btn', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.history",
      modifiers: {
        "history": true
      }
    }],
    attrs: {
      "block": "",
      "href": "#",
      "variant": "light"
    }
  }, [_vm._v(" History Form "), _c('span', {
    staticClass: "when-opened"
  }, [_c('i', {
    staticClass: "fa fa-arrow-down"
  })]), _c('span', {
    staticClass: "when-closed"
  }, [_c('i', {
    staticClass: "fa fa-arrow-up"
  })])]), _c('b-collapse', {
    attrs: {
      "id": "history",
      "role": "tabpanel"
    }
  }, [_c('ul', {
    staticClass: "iq-timeline"
  }, [_vm.maintenanceDetailData.logs.length === 0 ? _c('li', [_vm._v("Tidak ada histori log")]) : _vm._e(), _vm._l(_vm.maintenanceDetailData.logs, function (log) {
    return _c('li', [_c('div', {
      staticClass: "timeline-dots border-secondary"
    }), log.status === 'INIT' ? _c('h6', {}, [_vm._v(_vm._s(log.createdBy) + " telah membuat form")]) : ['APPROVED', 'APPROVAL', 'APPROVE'].includes(log.status) ? _c('h6', {}, [_vm._v(_vm._s(log.createdBy) + " telah memberikan approval form")]) : ['REVIEWED', 'REVIEW'].includes(log.status) ? _c('h6', {}, [_vm._v(_vm._s(log.createdBy) + " telah memberikan review form")]) : ['OVERDUE', 'TOWARDS'].includes(log.status) ? _c('h6', {}, [_vm._v("Sertifikat Kadarluasa, butuh perpanjang")]) : _vm._e(), _c('small', {
      staticClass: "mt-1"
    }, [_vm._v(_vm._s(log.createdAt))])]);
  })], 2)])], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('b-btn', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.comment",
      modifiers: {
        "comment": true
      }
    }],
    attrs: {
      "block": "",
      "href": "#",
      "variant": "secondary"
    }
  }, [_c('i', {
    staticClass: "fa fa-comment"
  }), _vm._v(" Comment Box (" + _vm._s(_vm.maintenanceDetailData.comments.length) + ") "), _c('span', {
    staticClass: "when-opened"
  }, [_c('i', {
    staticClass: "fa fa-arrow-down"
  })]), _c('span', {
    staticClass: "when-closed"
  }, [_c('i', {
    staticClass: "fa fa-arrow-up"
  })])]), _c('b-collapse', {
    attrs: {
      "id": "comment",
      "role": "tabpanel"
    }
  }, [_c('div', {
    staticClass: "detailBox"
  }, [_c('div', {
    staticClass: "titleBox"
  }, [_c('label', [_vm._v("Comment Box")]), _c('button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.comment",
      modifiers: {
        "comment": true
      }
    }],
    staticClass: "close",
    attrs: {
      "type": "button",
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])]), _c('div', {
    staticClass: "actionBox"
  }, [_c('ul', {
    staticClass: "commentList"
  }, [_vm.maintenanceDetailData.comments.length === 0 ? _c('li', [_vm._v("Tidak ada komentar")]) : _vm._e(), _vm._l(_vm.maintenanceDetailData.comments, function (comment) {
    return _c('li', [_c('div', {
      staticClass: "commentText"
    }, [_c('p', [_c('span', [_c('strong', [_vm._v(_vm._s(comment.name))]), _vm._v(" - " + _vm._s(comment.roleName))])]), _c('p', {}, [_vm._v(_vm._s(comment.comment))]), _vm._v(" "), _c('span', {
      staticClass: "date sub-text"
    }, [_vm._v("on " + _vm._s(comment.createdAt))])])]);
  })], 2)]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Input Your Comments..."
    },
    model: {
      value: _vm.formComment.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.formComment, "comment", $$v);
      },
      expression: "formComment.comment"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": !_vm.formComment.comment,
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.saveComment();
      }
    }
  }, [_vm._v("Add")])], 1)], 1)], 1)])], 1)], 1)], 1)], 1)], 1)])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }