var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "text-center"
  }, [_vm._v(" Loading Content... ")]) : [['CREATE_EMERGENCY', 'CREATE_FROM_SCHEDULE'].includes(_vm.action) ? _c('fieldset', {
    staticClass: "form-group border p-3 iq-bg-info mt-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2"
  }, [_vm._v("Docking Information")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Arrival Shipyard *",
      "label-for": "arrival_shipyard"
    }
  }, [_c('date-picker', {
    attrs: {
      "required": "",
      "id": "arrival_shipyard",
      "value-type": "DD-MM-YYYY",
      "type": "date",
      "placeholder": "Select date",
      "format": "DD MMM YYYY"
    },
    on: {
      "change": function change() {
        _vm.onValidateAllDates('arrival');
        _vm.onGenerateWaitingDockSpace();
      }
    },
    model: {
      value: _vm.form.arrival,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "arrival", $$v);
      },
      expression: "form.arrival"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Up Slip *",
      "label-for": "up_slip"
    }
  }, [_c('date-picker', {
    attrs: {
      "required": "",
      "id": "up_slip",
      "value-type": "DD-MM-YYYY",
      "type": "date",
      "placeholder": "Select date",
      "format": "DD MMM YYYY"
    },
    on: {
      "change": function change() {
        _vm.onValidateAllDates('upSlip');
        _vm.onGenerateWaitingDockSpace();
      }
    },
    model: {
      value: _vm.form.upSlip,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "upSlip", $$v);
      },
      expression: "form.upSlip"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Waiting Dock Space *",
      "label-for": "waiting_dock_space"
    }
  }, [_c('b-form-input', {
    staticStyle: {
      "border": "solid 1px gray"
    },
    attrs: {
      "required": "",
      "type": "number",
      "id": "waiting_dock_space"
    },
    model: {
      value: _vm.form.waitingDockSpace,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "waitingDockSpace", $$v);
      },
      expression: "form.waitingDockSpace"
    }
  })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Down Slip *",
      "label-for": "down_slip"
    }
  }, [_c('date-picker', {
    attrs: {
      "required": "",
      "id": "down_slip",
      "value-type": "DD-MM-YYYY",
      "type": "date",
      "placeholder": "Select date",
      "format": "DD MMM YYYY"
    },
    on: {
      "change": function change() {
        _vm.onValidateAllDates('downSlip');
      }
    },
    model: {
      value: _vm.form.downSlip,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "downSlip", $$v);
      },
      expression: "form.downSlip"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Complete Date *",
      "label-for": "delivery_date"
    }
  }, [_c('date-picker', {
    attrs: {
      "required": "",
      "id": "delivery_date",
      "value-type": "DD-MM-YYYY",
      "type": "date",
      "placeholder": "Select date",
      "format": "DD MMM YYYY"
    },
    on: {
      "change": function change() {
        _vm.onValidateAllDates('deliveryDate');
      }
    },
    model: {
      value: _vm.form.deliveryDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "deliveryDate", $$v);
      },
      expression: "form.deliveryDate"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Estimasi Budget",
      "label-for": "est_budget"
    }
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text bg-primary"
  }, [_vm._v("Rp.")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.budgetEstimation,
      expression: "form.budgetEstimation"
    }],
    staticClass: "form-control w-100",
    staticStyle: {
      "border": "solid 1px gray"
    },
    attrs: {
      "type": "number",
      "id": "est_budget"
    },
    domProps: {
      "value": _vm.form.budgetEstimation
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "budgetEstimation", $event.target.value);
      }
    }
  })]), _c('small', [_vm._v("Rp. " + _vm._s(_vm.numberFormat(_vm.form.budgetEstimation)))])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Shipyard *",
      "label-for": "shipyard"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.shipyards,
      "reduce": function reduce(type) {
        return type.id;
      }
    },
    model: {
      value: _vm.form.dockingShipyardId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dockingShipyardId", $$v);
      },
      expression: "form.dockingShipyardId"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "PIC",
      "label-for": "pic"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.users,
      "reduce": function reduce(type) {
        return type.value;
      }
    },
    model: {
      value: _vm.form.userId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "userId", $$v);
      },
      expression: "form.userId"
    }
  })], 1)], 1), _vm._v("> "), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Attachment Shipyard",
      "label-for": "doc_shipyard"
    }
  }, [_vm.form.docShipyard ? _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "text-primary",
    attrs: {
      "href": _vm.form.docShipyardUrl,
      "title": "Download File",
      "download": ""
    }
  }, [_c('i', {
    staticClass: "fa fa-download"
  }), _vm._v(" Download")]), _c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "text-danger",
    attrs: {
      "href": "#",
      "title": "Hapus",
      "download": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return function () {
          _vm.form.docShipyard = '';
          _vm.form.docShipyardUrl = '';
        }.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-trash"
  })])]) : [_c('div', {
    staticClass: "custom-file"
  }, [_c('input', {
    staticClass: "custom-file-input",
    attrs: {
      "type": "file",
      "accept": "image/png,image/jpg,image/jpeg,.pdf,.xls,.xlsx,.doc,.docx",
      "id": "attachment-shipyard"
    },
    on: {
      "change": _vm.onUploadShipyardFile
    }
  }), _c('label', {
    staticClass: "custom-file-label",
    attrs: {
      "for": "attachment-shipyard"
    }
  }, [_vm._v("Browse file...")])]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" File Type * : "), _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v("3 MB / File")])], 1)]], 2)], 1)], 1)], 1) : ['REVIEW', 'APPROVE', 'UPDATE', 'DETAIL'].includes(_vm.action) ? _c('table', {
    staticClass: "table table-striped mt-3"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Arrival Shipyard")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm.action === 'DETAIL' ? _c('div', [_vm._v(" " + _vm._s(_vm.form.arrival) + " ")]) : [_vm.currentStatus !== 'PROGRESS' ? _c('date-picker', {
    attrs: {
      "required": "",
      "id": "arrival_shipyard",
      "value-type": "DD-MM-YYYY",
      "type": "date",
      "placeholder": "Select date",
      "format": "DD MMM YYYY"
    },
    on: {
      "change": _vm.onGenerateWaitingDockSpace
    },
    model: {
      value: _vm.form.arrival,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "arrival", $$v);
      },
      expression: "form.arrival"
    }
  }) : _c('span', [_vm._v(" " + _vm._s(_vm.form.arrival) + " ")])]], 2), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Up Slip")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm.action === 'DETAIL' ? _c('div', [_vm._v(" " + _vm._s(_vm.form.upSlip) + " ")]) : [_vm.currentStatus !== 'PROGRESS' ? _c('date-picker', {
    attrs: {
      "required": "",
      "id": "up_slip",
      "value-type": "DD-MM-YYYY",
      "type": "date",
      "placeholder": "Select date",
      "format": "DD MMM YYYY"
    },
    on: {
      "change": _vm.onGenerateWaitingDockSpace
    },
    model: {
      value: _vm.form.upSlip,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "upSlip", $$v);
      },
      expression: "form.upSlip"
    }
  }) : _c('span', [_vm._v(" " + _vm._s(_vm.form.upSlip) + " ")])]], 2)]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Waiting Dock Space")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s(_vm.form.waitingDockSpace) + " Hari")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Down Slip")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm.action === 'DETAIL' ? _c('div', [_vm._v(" " + _vm._s(_vm.form.downSlip) + " ")]) : _c('date-picker', {
    attrs: {
      "required": "",
      "id": "down_slip",
      "value-type": "DD-MM-YYYY",
      "type": "date",
      "placeholder": "Select date",
      "format": "DD MMM YYYY"
    },
    model: {
      value: _vm.form.downSlip,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "downSlip", $$v);
      },
      expression: "form.downSlip"
    }
  })], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Complete Date")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm.action === 'DETAIL' ? _c('div', [_vm._v(" " + _vm._s(_vm.form.deliveryDate) + " ")]) : _c('date-picker', {
    attrs: {
      "required": "",
      "id": "delivery_date",
      "value-type": "DD-MM-YYYY",
      "type": "date",
      "placeholder": "Select date",
      "format": "DD MMM YYYY"
    },
    model: {
      value: _vm.form.deliveryDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "deliveryDate", $$v);
      },
      expression: "form.deliveryDate"
    }
  })], 1), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Budget")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v("Rp. " + _vm._s(_vm.numberFormat(_vm.form.budgetEstimation)) + ",-")])]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Shipyard")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s(_vm.form.dockingShipyardId && _vm.shipyards.find(function (s) {
    return s.id === _vm.form.dockingShipyardId;
  }) ? _vm.shipyards.find(function (s) {
    return s.id === _vm.form.dockingShipyardId;
  }).name : '-'))]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Penawaran")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm.action === 'DETAIL' ? _c('div', [_vm._v(" Rp. " + _vm._s(_vm.numberFormat(_vm.form.budget)) + " ")]) : [_c('div', {
    staticClass: "input-group"
  }, [_vm._m(0), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.budget,
      expression: "form.budget"
    }],
    staticClass: "form-control",
    staticStyle: {
      "border": "solid 1px gray"
    },
    attrs: {
      "type": "number",
      "required": "",
      "id": "real_budget"
    },
    domProps: {
      "value": _vm.form.budget
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "budget", $event.target.value);
      }
    }
  })]), _c('small', [_vm._v("Rp. " + _vm._s(_vm.numberFormat(_vm.form.budget)))])]], 2)]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("PIC")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s(_vm.form.userId && _vm.users.find(function (s) {
    return s.value === _vm.form.userId;
  }) ? _vm.users.find(function (s) {
    return s.value === _vm.form.userId;
  }).text : '-'))]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Document Shipyard")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm.form.docShipyard ? _c('ul', {
    staticClass: "d-flex flex-wrap p-0 m-0"
  }, [_c('li', {
    staticClass: "pb-1",
    staticStyle: {
      "width": "30px"
    }
  }, [_c('a', {
    attrs: {
      "href": _vm.form.docShipyardUrl,
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/pdf.png"),
      "alt": "gallary-image"
    }
  })])])]) : _vm._e()])])])]) : _vm._e(), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-primary rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "fa fa-percent"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v(_vm._s(_vm.onCalcJobCatPercentage()) + "%")])]), _c('h5', {}, [_vm._v("Persentase Job Category")])])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "accordion",
    attrs: {
      "role": "tablist"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_vm._l(_vm.form.dockingTaskCategories, function (jobCategory, indexJobCategory) {
    return [_c('iq-card', {
      key: indexJobCategory,
      staticClass: "align-middle",
      attrs: {
        "class-name": "iq-card-block iq-card-stretch iq-card-height",
        "body-class": "iq-bg-success"
      },
      scopedSlots: _vm._u([{
        key: "body",
        fn: function fn() {
          return [_c('table', {
            staticClass: "table table-sm table-borderless"
          }, [_c('tr', [_c('td', {
            staticClass: "text-center",
            attrs: {
              "width": "20%"
            }
          }, [_c('b-button', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: "jobCat-".concat(indexJobCategory),
              expression: "`jobCat-${indexJobCategory}`"
            }],
            staticClass: "mr-1",
            attrs: {
              "variant": "primary"
            }
          }, [_c('i', {
            staticClass: "fa fa-chevron-circle-down"
          }), _vm._v("Click, Job Detail")])], 1), _c('td', {
            staticClass: "text-center",
            attrs: {
              "width": "35%"
            }
          }, [_c('h4', {
            staticClass: "mb-0"
          }, [_vm._v(_vm._s(jobCategory.dockingCategoryName) + " "), _c('small', [_vm._v("(" + _vm._s(_vm.filteredDockingTasks(jobCategory.dockingTasks).length) + " jobs)")])])]), _c('td', {
            staticClass: "text-right",
            attrs: {
              "width": "35%"
            }
          }, [_vm.action === 'DETAIL' ? _c('div', [_c('strong', [_vm._v(" BOBOT PEKERJAAN: " + _vm._s(jobCategory.percentage) + "% ")])]) : _c('div', {
            staticClass: "input-group"
          }, [_c('div', {
            staticClass: "input-group-prepend"
          }, [_c('span', {
            staticClass: "input-group-text bg-primary"
          }, [_vm._v("%")])]), _c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: jobCategory.percentage,
              expression: "jobCategory.percentage"
            }],
            staticClass: "form-control",
            attrs: {
              "type": "number",
              "required": "",
              "id": "real_budget",
              "placeholder": "Isi Persentase"
            },
            domProps: {
              "value": jobCategory.percentage
            },
            on: {
              "input": function input($event) {
                if ($event.target.composing) return;
                _vm.$set(jobCategory, "percentage", $event.target.value);
              }
            }
          })])]), !['APPROVE', 'DETAIL'].includes(_vm.action) ? _c('td', {
            staticClass: "text-center",
            attrs: {
              "width": "10%"
            }
          }, [_c('b-button', {
            attrs: {
              "size": "sm",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteTaskCategory(indexJobCategory);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash m-0"
          })])], 1) : _vm._e()])])];
        },
        proxy: true
      }], null, true)
    }), _c('b-collapse', {
      key: indexJobCategory,
      attrs: {
        "id": "jobCat-".concat(indexJobCategory),
        "accordion": "my-accordion",
        "role": "tabpanel"
      }
    }, [_c('b-card-body', [_c('iq-card', {
      attrs: {
        "class-name": "iq-card-block iq-card-stretch iq-card-height",
        "body-class": "iq-bg-info rounded"
      },
      scopedSlots: _vm._u([{
        key: "body",
        fn: function fn() {
          return [_c('div', {
            staticClass: "d-flex align-items-center justify-content-between"
          }, [_c('div', {
            staticClass: "rounded-circle iq-card-icon bg-info"
          }, [_c('i', {
            staticClass: "fa fa-percent"
          })]), _c('div', {
            staticClass: "text-right"
          }, [_c('h2', {
            staticClass: "mb-0"
          }, [_c('span', {
            staticClass: "counter"
          }, [_vm._v(_vm._s(_vm.onCalcJobPercentage(indexJobCategory)) + "%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
        },
        proxy: true
      }], null, true)
    }), _c('div', {
      staticClass: "accordion",
      attrs: {
        "role": "tablist"
      }
    }, _vm._l(_vm.filteredDockingTasks(jobCategory.dockingTasks), function (job, indexJob) {
      return _c('b-card', {
        key: "".concat(indexJob, "-").concat(indexJobCategory),
        attrs: {
          "no-body": ""
        }
      }, [_c('b-card-header', {
        staticClass: "p-1",
        staticStyle: {
          "background-color": "white",
          "border": "none"
        },
        attrs: {
          "header-tag": "header",
          "role": "tab"
        }
      }, [_c('b-button', {
        directives: [{
          name: "b-toggle",
          rawName: "v-b-toggle",
          value: "task-".concat(indexJob, "-").concat(indexJobCategory),
          expression: "`task-${indexJob}-${indexJobCategory}`"
        }],
        staticClass: "text-left",
        attrs: {
          "block": "",
          "variant": _vm.colorCard(job)
        }
      }, [_c('i', {
        staticClass: "fa fa-chevron-circle-down"
      }), _vm._v(" " + _vm._s(indexJob + 1) + ". " + _vm._s(job.dockingJobName) + " "), _c('strong', {
        staticClass: "pull-right"
      }, [_vm._v("BOBOT PEKERJAAN : " + _vm._s(job.percentage) + "%")])])], 1), _c('b-collapse', {
        attrs: {
          "id": "task-".concat(indexJob, "-").concat(indexJobCategory),
          "accordion": "my-accordion-sub",
          "role": "tabpanel"
        }
      }, [['APPROVE', 'DETAIL'].includes(_vm.action) && job.dockingTaskId || job.isApprove === true ? _c('div', {
        staticClass: "mb-2"
      }, [_c('table', {
        staticClass: "table table-striped mt-2"
      }, [_c('tbody', [_c('tr', [_c('th', {
        attrs: {
          "width": "20%"
        }
      }, [_vm._v("Start Date")]), _c('td', {
        staticClass: "text-center",
        attrs: {
          "width": "5%"
        }
      }, [_vm._v(":")]), _c('td', {
        attrs: {
          "width": "25%"
        }
      }, [_vm._v(_vm._s(job.startDate))]), _c('th', {
        attrs: {
          "width": "20%"
        }
      }, [_vm._v("Est. Finish")]), _c('td', {
        staticClass: "text-center",
        attrs: {
          "width": "5%"
        }
      }, [_vm._v(":")]), _c('td', {
        attrs: {
          "width": "25%"
        }
      }, [_vm._v(_vm._s(job.estFinishDate))])]), _c('tr', [_c('th', {
        attrs: {
          "width": "20%"
        }
      }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
        staticClass: "text-center",
        attrs: {
          "width": "5%"
        }
      }, [_vm._v(":")]), _c('td', {
        attrs: {
          "width": "25%"
        }
      }, [_vm.action === 'DETAIL' ? _c('div', [_vm._v(" " + _vm._s(job.percentage) + "% ")]) : [job.isApprove === true || ['APPROVE'].includes(_vm.action) ? _c('div', {
        staticClass: "input-group"
      }, [_c('div', {
        staticClass: "input-group-prepend"
      }, [_c('span', {
        staticClass: "input-group-text bg-primary"
      }, [_vm._v("%")])]), _c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: job.percentage,
          expression: "job.percentage"
        }],
        staticClass: "form-control",
        attrs: {
          "type": "number",
          "required": ""
        },
        domProps: {
          "value": job.percentage
        },
        on: {
          "input": function input($event) {
            if ($event.target.composing) return;
            _vm.$set(job, "percentage", $event.target.value);
          }
        }
      })]) : _c('span', [_vm._v(" " + _vm._s(job.percentage) + "% ")])]], 2), _c('th', {
        attrs: {
          "width": "20%"
        }
      }, [_vm._v("Dokumen")]), _c('td', {
        staticClass: "text-center",
        attrs: {
          "width": "5%"
        }
      }, [_vm._v(":")]), _c('td', {
        attrs: {
          "width": "25%"
        }
      }, [job.attachmentJob ? _c('ul', {
        staticClass: "d-flex flex-wrap p-0 m-0"
      }, [_c('li', {
        staticClass: "pb-1",
        staticStyle: {
          "width": "30px"
        }
      }, [_c('a', {
        attrs: {
          "href": job.attachmentJobUrl,
          "target": "_blank"
        }
      }, [_c('img', {
        staticClass: "img-fluid",
        attrs: {
          "src": require("../../assets/images/activity/pdf.png"),
          "alt": "gallary-image"
        }
      })])])]) : _vm._e()])]), _c('tr', [_c('th', {
        attrs: {
          "width": "20%"
        }
      }, [_vm._v("Created By")]), _c('td', {
        staticClass: "text-center",
        attrs: {
          "width": "5%"
        }
      }, [_vm._v(":")]), _c('td', {
        attrs: {
          "width": "25%"
        }
      }, [_vm._v(_vm._s(job.createdBy))]), _c('th', {
        attrs: {
          "width": "20%"
        }
      }, [_vm._v("Review By")]), _c('td', {
        staticClass: "text-center",
        attrs: {
          "width": "5%"
        }
      }, [_vm._v(":")]), _c('td', {
        attrs: {
          "width": "25%"
        }
      }, [_vm._v(_vm._s(job.reviewedBy))])]), _c('tr', [_c('th', {
        staticClass: "align-top"
      }, [_vm._v("Notes")]), _c('td', {
        staticClass: "text-center align-top"
      }, [_vm._v(":")]), _c('td', {
        attrs: {
          "colspan": "4"
        }
      }, [_vm._v(" " + _vm._s(job.notes) + " ")])]), job.isUsingManPower ? _c('tr', [_c('th', {
        staticClass: "align-top"
      }, [_vm._v("Internal Man Power")]), _c('td', {
        staticClass: "text-center align-top"
      }, [_vm._v(":")]), _c('td', {
        attrs: {
          "colspan": "4"
        }
      }, _vm._l(job.manPowers, function (manPower, indexManPower) {
        return _c('h5', {
          key: indexManPower,
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" " + _vm._s(manPower.name) + " - " + _vm._s(manPower.position))])], 1);
      }), 0)]) : _vm._e()])]), job.isUsingSubcontractor ? _c('table', {
        staticClass: "table table-striped mt-2"
      }, [_c('tbody', _vm._l(job.subContractors, function (contractor, indexContractor) {
        return _c('fieldset', {
          key: indexContractor,
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Kontraktor " + _vm._s(indexContractor + 1))]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kontraktor")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s(contractor.name))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Executive Engineer")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s(contractor.pic))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp" + _vm._s(_vm.numberFormat(contractor.offer)))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran Final")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp" + _vm._s(_vm.numberFormat(contractor.offerNegotiation)))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Note")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s(contractor.notes))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Attachment")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [contractor.attachmentShipyard ? _c('ul', {
          staticClass: "d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "pb-1",
          staticStyle: {
            "width": "30px"
          }
        }, [_c('a', {
          attrs: {
            "href": contractor.attachmentShipyardUrl,
            "target": "_blank"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])])]) : _vm._e()])])]);
      }), 0)]) : _vm._e(), _c('b-alert', {
        attrs: {
          "show": job.isApprove === true,
          "variant": "success"
        }
      }, [_c('div', {
        staticClass: "iq-alert-icon"
      }, [_c('i', {
        staticClass: "fa fa-thumbs-up"
      })]), _c('div', {
        staticClass: "iq-alert-text"
      }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
        attrs: {
          "show": job.isApprove === false,
          "variant": "danger"
        }
      }, [_c('div', {
        staticClass: "iq-alert-icon"
      }, [_c('i', {
        staticClass: "fa fa-close"
      })]), _c('div', {
        staticClass: "iq-alert-text"
      }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), ['APPROVE'].includes(_vm.action) ? [_c('b-button', {
        staticClass: "iq-bg-danger float-right mb-3 ml-1",
        attrs: {
          "variant": "none"
        },
        on: {
          "click": function click($event) {
            job.isApprove = false;
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-close"
      }), _vm._v(" REJECT")]), _c('b-button', {
        staticClass: "float-right",
        attrs: {
          "variant": "primary"
        },
        on: {
          "click": function click($event) {
            job.isApprove = true;
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-thumbs-up"
      }), _vm._v(" APPROVE")])] : _vm._e()], 2) : [_c('b-form-row', {
        staticClass: "mt-3"
      }, [_c('b-col', [_c('b-form-group', {
        attrs: {
          "label": "Start *",
          "label-for": "start"
        }
      }, [_c('date-picker', {
        attrs: {
          "required": "",
          "value-type": "DD-MM-YYYY",
          "type": "date",
          "placeholder": "Select date",
          "format": "DD MMM YYYY"
        },
        model: {
          value: job.startDate,
          callback: function callback($$v) {
            _vm.$set(job, "startDate", $$v);
          },
          expression: "job.startDate"
        }
      })], 1)], 1), _c('b-col', [_c('b-form-group', {
        attrs: {
          "label": "Est. Finish *",
          "label-for": "end"
        }
      }, [_c('date-picker', {
        attrs: {
          "required": "",
          "value-type": "DD-MM-YYYY",
          "type": "date",
          "placeholder": "Select date",
          "format": "DD MMM YYYY"
        },
        model: {
          value: job.estFinishDate,
          callback: function callback($$v) {
            _vm.$set(job, "estFinishDate", $$v);
          },
          expression: "job.estFinishDate"
        }
      })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
        attrs: {
          "label": "Bobot Pekerjaan *",
          "label-for": "bobot_pekerjaan"
        }
      }, [_c('div', {
        staticClass: "input-group"
      }, [_c('div', {
        staticClass: "input-group-prepend"
      }, [_c('span', {
        staticClass: "input-group-text bg-primary"
      }, [_vm._v("%")])]), _c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: job.percentage,
          expression: "job.percentage"
        }],
        staticClass: "form-control",
        attrs: {
          "type": "number",
          "required": ""
        },
        domProps: {
          "value": job.percentage
        },
        on: {
          "input": function input($event) {
            if ($event.target.composing) return;
            _vm.$set(job, "percentage", $event.target.value);
          }
        }
      })])])], 1), _c('b-col', [_c('b-form-group', {
        attrs: {
          "label": "Attachment Job",
          "label-for": "attachment_job"
        }
      }, [job.attachmentJob ? _c('div', {
        staticClass: "d-flex justify-content-between"
      }, [_c('a', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          modifiers: {
            "hover": true
          }
        }],
        staticClass: "text-primary",
        attrs: {
          "href": job.attachmentJobUrl,
          "title": "Download File",
          "download": ""
        }
      }, [_c('i', {
        staticClass: "fa fa-download"
      }), _vm._v(" Download")]), _c('a', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover",
          modifiers: {
            "hover": true
          }
        }],
        staticClass: "text-danger",
        attrs: {
          "href": "#",
          "title": "Hapus",
          "download": ""
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            return function () {
              job.attachmentJob = '';
              job.attachmentJobUrl = '';
            }.apply(null, arguments);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-trash"
      })])]) : [_c('div', {
        staticClass: "custom-file"
      }, [_c('input', {
        staticClass: "custom-file-input",
        attrs: {
          "type": "file"
        },
        on: {
          "change": function change($event) {
            return _vm.onUploadDockingTaskAttachment($event, indexJobCategory, indexJob);
          }
        }
      }), _c('label', {
        staticClass: "custom-file-label"
      }, [_vm._v("Browse file...")])]), _c('small', {
        staticClass: "text-muted"
      }, [_vm._v(" File Type * : "), _c('b-badge', {
        attrs: {
          "variant": "info"
        }
      }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
        attrs: {
          "variant": "info"
        }
      }, [_vm._v("3 MB / File")])], 1)]], 2)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
        attrs: {
          "label": "Notes",
          "label-for": "notes"
        }
      }, [_c('b-form-textarea', {
        attrs: {
          "rows": "3",
          "placeholder": "Notes"
        },
        model: {
          value: job.notes,
          callback: function callback($$v) {
            _vm.$set(job, "notes", $$v);
          },
          expression: "job.notes"
        }
      })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
        attrs: {
          "label": "Internal Man Power"
        }
      }, [_c('b-form-checkbox', {
        attrs: {
          "required": "",
          "switch": "",
          "inline": "",
          "value": true,
          "unchecked-value": false
        },
        model: {
          value: job.isUsingManPower,
          callback: function callback($$v) {
            _vm.$set(job, "isUsingManPower", $$v);
          },
          expression: "job.isUsingManPower"
        }
      })], 1)], 1)], 1), job.isUsingManPower ? _c('fieldset', {
        staticClass: "form-group border p-3"
      }, [_c('legend', {
        staticClass: "w-auto px-2"
      }, [_vm._v("Internal Man Power")]), _vm._l(job.manPowers, function (manPower, indexManPower) {
        return _c('b-form-row', {
          key: indexManPower
        }, [_c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Nama Internal Man Power *"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "required": "",
            "placeholder": "Nama"
          },
          model: {
            value: manPower.name,
            callback: function callback($$v) {
              _vm.$set(manPower, "name", $$v);
            },
            expression: "manPower.name"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Jabatan *",
            "label-for": "jabatan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.positions,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Jabatan")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: manPower.position,
            callback: function callback($$v) {
              _vm.$set(manPower, "position", $$v);
            },
            expression: "manPower.position"
          }
        })], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Action"
          }
        }, [indexManPower > 0 ? _c('b-button', {
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.onRemoveManPower(indexJobCategory, indexJob, indexManPower);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-trash m-0"
        })]) : _vm._e()], 1)], 1)], 1);
      }), _c('b-button', {
        staticClass: "mt-3",
        attrs: {
          "variant": "success"
        },
        on: {
          "click": function click($event) {
            return _vm.onAddManPower(indexJobCategory, indexJob);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-users"
      }), _vm._v(" ADD INTERNAL MAN POWER")])], 2) : _vm._e(), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
        attrs: {
          "label": "Sub Kontraktor",
          "label-for": "subkontraktor"
        }
      }, [_c('b-form-checkbox', {
        attrs: {
          "required": "",
          "switch": "",
          "inline": "",
          "value": true,
          "unchecked-value": false
        },
        model: {
          value: job.isUsingSubcontractor,
          callback: function callback($$v) {
            _vm.$set(job, "isUsingSubcontractor", $$v);
          },
          expression: "job.isUsingSubcontractor"
        }
      })], 1)], 1)], 1), job.isUsingSubcontractor ? _c('fieldset', {
        staticClass: "form-group border p-3"
      }, [_vm._l(job.subContractors, function (subCon, indexSubCon) {
        return [_c('legend', {
          key: indexSubCon,
          staticClass: "w-auto"
        }, [_vm._v("Sub Kontraktor #" + _vm._s(indexSubCon + 1))]), _c('div', {
          key: indexSubCon
        }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Nama Kontraktor *",
            "label-for": "nama_kontraktor"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Nama Kontraktor",
            "required": ""
          },
          model: {
            value: subCon.name,
            callback: function callback($$v) {
              _vm.$set(subCon, "name", $$v);
            },
            expression: "subCon.name"
          }
        })], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "PIC / Executive Engineer *",
            "label-for": "executive_engineer"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "required": "",
            "placeholder": "PIC"
          },
          model: {
            value: subCon.pic,
            callback: function callback($$v) {
              _vm.$set(subCon, "pic", $$v);
            },
            expression: "subCon.pic"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Penawaran",
            "label-for": "penawaran"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("Rp.")])]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: subCon.offer,
            expression: "subCon.offer"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "placeholder": "Isi Penawaran"
          },
          domProps: {
            "value": subCon.offer
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(subCon, "offer", $event.target.value);
            }
          }
        })]), _c('small', [_vm._v("Rp. " + _vm._s(_vm.numberFormat(subCon.offer)))])])], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Penawaran Nego",
            "label-for": "penawaran_nego"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("Rp.")])]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: subCon.offerNegotiation,
            expression: "subCon.offerNegotiation"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "placeholder": "Isi Nego"
          },
          domProps: {
            "value": subCon.offerNegotiation
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(subCon, "offerNegotiation", $event.target.value);
            }
          }
        })]), _c('small', [_vm._v("Rp. " + _vm._s(_vm.numberFormat(subCon.offerNegotiation)))])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Notes",
            "label-for": "notes"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "rows": "2",
            "placeholder": "Catatan"
          },
          model: {
            value: subCon.notes,
            callback: function callback($$v) {
              _vm.$set(subCon, "notes", $$v);
            },
            expression: "subCon.notes"
          }
        })], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Attachment",
            "label-for": "doc_shipyard"
          }
        }, [subCon.attachmentShipyard ? _c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('a', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-primary",
          attrs: {
            "href": subCon.attachmentShipyardUrl,
            "title": "Download File",
            "download": ""
          }
        }, [_c('i', {
          staticClass: "fa fa-download"
        }), _vm._v(" Download")]), _c('a', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-danger",
          attrs: {
            "href": "#",
            "title": "Hapus",
            "download": ""
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return function () {
                subCon.attachmentShipyard = '';
                subCon.attachmentShipyardUrl = '';
              }.apply(null, arguments);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-trash"
        })])]) : [_c('div', {
          staticClass: "custom-file"
        }, [_c('input', {
          staticClass: "custom-file-input",
          attrs: {
            "type": "file",
            "id": "validatedCustomFile"
          },
          on: {
            "change": function change($event) {
              return _vm.onUploadDockingTaskSubContractorAttachment($event, indexJobCategory, indexJob, indexSubCon);
            }
          }
        }), _c('label', {
          staticClass: "custom-file-label"
        }, [_vm._v("Browse file...")])]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" File Type * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("3 MB / File")])], 1)]], 2)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-button', {
          staticClass: "mb-3",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.onAddSubContractor(indexJobCategory, indexJob);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-users"
        }), _vm._v(" ADD SUB KONTRAKTOR")])], 1), _c('b-col', {
          staticClass: "text-right"
        }, [indexSubCon > 0 ? _c('b-button', {
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.onRemoveSubContractor(indexJobCategory, indexJob, indexSubCon);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-trash m-0"
        })]) : _vm._e()], 1)], 1)], 1)];
      }), job.subContractors.length === 0 ? _c('b-button', {
        staticClass: "mb-3",
        attrs: {
          "variant": "success"
        },
        on: {
          "click": function click($event) {
            return _vm.onAddSubContractor(indexJobCategory, indexJob);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-users"
      }), _vm._v(" ADD SUB KONTRAKTOR")]) : _vm._e()], 2) : _vm._e()]], 2)], 1);
    }), 1)], 1)], 1)];
  })], 2)], 1), _c('b-col', {
    staticClass: "mt-3",
    attrs: {
      "md": "12"
    }
  }, [_vm.action !== 'DETAIL' ? [_c('b-card-text', {
    staticClass: "pull-right"
  }, [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Sign * : "), _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('br'), _c('br'), ['APPROVE'].includes(_vm.action) && !_vm.canApprove() ? [_c('b-button', {
    staticClass: "pull-right mr-1",
    attrs: {
      "disabled": "",
      "variant": "success"
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" Approve ")])] : _c('b-button', {
    staticClass: "pull-right mr-1",
    attrs: {
      "type": "submit",
      "variant": "success"
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), ['CREATE_EMERGENCY', 'CREATE_FROM_SCHEDULE'].includes(_vm.action) ? _c('span', [_vm._v("Save")]) : ['UPDATE'].includes(_vm.action) ? _c('span', [_vm._v("Update")]) : ['REVIEW'].includes(_vm.action) ? _c('span', [_vm._v("Review")]) : ['APPROVE'].includes(_vm.action) ? _c('span', [_vm._v("Approve")]) : _vm._e()])] : _vm._e()], 2)]], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text bg-primary",
    attrs: {
      "id": "inputGroupPrepend2"
    }
  }, [_vm._v("Rp.")])]);

}]

export { render, staticRenderFns }