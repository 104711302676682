var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-primary rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        var _vm$report_dashboard$, _vm$report_dashboard, _vm$report_dashboard$2;
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "fa fa-ship"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h5', {
          staticClass: "mb-0 font-weight-bold"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v(_vm._s((_vm$report_dashboard$ = (_vm$report_dashboard = _vm.report_dashboard) === null || _vm$report_dashboard === void 0 ? void 0 : (_vm$report_dashboard$2 = _vm$report_dashboard.dashboard) === null || _vm$report_dashboard$2 === void 0 ? void 0 : _vm$report_dashboard$2.fleets_repair) !== null && _vm$report_dashboard$ !== void 0 ? _vm$report_dashboard$ : 0))])]), _c('h5', {}, [_vm._v("Fleets on Repairing")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-warning rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        var _vm$report_dashboard$3, _vm$report_dashboard2, _vm$report_dashboard3;
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "fas fa-dollar-sign"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h5', {
          staticClass: "mb-0 font-weight-bold"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v(_vm._s(_vm.formatCurrency((_vm$report_dashboard$3 = (_vm$report_dashboard2 = _vm.report_dashboard) === null || _vm$report_dashboard2 === void 0 ? void 0 : (_vm$report_dashboard3 = _vm$report_dashboard2.dashboard) === null || _vm$report_dashboard3 === void 0 ? void 0 : _vm$report_dashboard3.total_all_cost) !== null && _vm$report_dashboard$3 !== void 0 ? _vm$report_dashboard$3 : 0)))])]), _c('h5', {}, [_vm._v("Cost Repair All Fleet")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-danger rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        var _vm$report_dashboard$4, _vm$report_dashboard4, _vm$report_dashboard5;
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-danger"
        }, [_c('i', {
          staticClass: "fa fa-tools"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h5', {
          staticClass: "mb-0 font-weight-bold"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v(_vm._s((_vm$report_dashboard$4 = (_vm$report_dashboard4 = _vm.report_dashboard) === null || _vm$report_dashboard4 === void 0 ? void 0 : (_vm$report_dashboard5 = _vm$report_dashboard4.dashboard) === null || _vm$report_dashboard5 === void 0 ? void 0 : _vm$report_dashboard5.all_repair) !== null && _vm$report_dashboard$4 !== void 0 ? _vm$report_dashboard$4 : 0))])]), _c('h5', {}, [_vm._v("Repairs Job All Fleet")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-info rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        var _vm$report_dashboard$5, _vm$report_dashboard6, _vm$report_dashboard7;
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-info"
        }, [_c('i', {
          staticClass: "fa fa-spinner"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h5', {
          staticClass: "mb-0 font-weight-bold"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v(_vm._s((_vm$report_dashboard$5 = (_vm$report_dashboard6 = _vm.report_dashboard) === null || _vm$report_dashboard6 === void 0 ? void 0 : (_vm$report_dashboard7 = _vm$report_dashboard6.dashboard) === null || _vm$report_dashboard7 === void 0 ? void 0 : _vm$report_dashboard7.all_repair_progress) !== null && _vm$report_dashboard$5 !== void 0 ? _vm$report_dashboard$5 : 0))])]), _c('h5', {}, [_vm._v("On Progress Repairs Job")])])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_vm._l(_vm.classification, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._l(_vm.cause, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._l(_vm.typeRM, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._l(_vm.costRM, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._l(_vm.categoryRunningRM, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._l(_vm.categoryDelayRM, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Matriks Realisasi R & M")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$report_dashboard8, _vm$report_dashboard9, _vm$report_dashboard10, _vm$report_dashboard11, _vm$report_dashboard12, _vm$report_dashboard13, _vm$report_dashboard14, _vm$report_dashboard15, _vm$report_dashboard16, _vm$report_dashboard17, _vm$report_dashboard18, _vm$report_dashboard19, _vm$report_dashboard20, _vm$report_dashboard21, _vm$report_dashboard22;
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("Group")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Rencana R & M (jam)")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Aktual R&M (jam)")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Realisasi (%)")])])]), _c('tbody', [_vm._l((_vm$report_dashboard8 = _vm.report_dashboard) === null || _vm$report_dashboard8 === void 0 ? void 0 : (_vm$report_dashboard9 = _vm$report_dashboard8.dashboard_tables) === null || _vm$report_dashboard9 === void 0 ? void 0 : (_vm$report_dashboard10 = _vm$report_dashboard9.realisasi_rnm) === null || _vm$report_dashboard10 === void 0 ? void 0 : _vm$report_dashboard10.data, function (state, index) {
          var _state$group_name;
          return _c('tr', {
            key: 'realisasi-' + index
          }, [_c('th', [_vm._v(_vm._s((_state$group_name = state === null || state === void 0 ? void 0 : state.group_name) !== null && _state$group_name !== void 0 ? _state$group_name : '-'))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.total_plan) + " Jam")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.aktual) + " Jam")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.realisasi) + "%")])]);
        }), _c('tr', [_c('th', [_vm._v("TOTAL")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v(_vm._s((_vm$report_dashboard11 = _vm.report_dashboard) === null || _vm$report_dashboard11 === void 0 ? void 0 : (_vm$report_dashboard12 = _vm$report_dashboard11.dashboard_tables) === null || _vm$report_dashboard12 === void 0 ? void 0 : (_vm$report_dashboard13 = _vm$report_dashboard12.realisasi_rnm) === null || _vm$report_dashboard13 === void 0 ? void 0 : (_vm$report_dashboard14 = _vm$report_dashboard13.total) === null || _vm$report_dashboard14 === void 0 ? void 0 : _vm$report_dashboard14.total_plan) + " Jam")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v(_vm._s((_vm$report_dashboard15 = _vm.report_dashboard) === null || _vm$report_dashboard15 === void 0 ? void 0 : (_vm$report_dashboard16 = _vm$report_dashboard15.dashboard_tables) === null || _vm$report_dashboard16 === void 0 ? void 0 : (_vm$report_dashboard17 = _vm$report_dashboard16.realisasi_rnm) === null || _vm$report_dashboard17 === void 0 ? void 0 : (_vm$report_dashboard18 = _vm$report_dashboard17.total) === null || _vm$report_dashboard18 === void 0 ? void 0 : _vm$report_dashboard18.aktual) + " Jam")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v(_vm._s((_vm$report_dashboard19 = _vm.report_dashboard) === null || _vm$report_dashboard19 === void 0 ? void 0 : (_vm$report_dashboard20 = _vm$report_dashboard19.dashboard_tables) === null || _vm$report_dashboard20 === void 0 ? void 0 : (_vm$report_dashboard21 = _vm$report_dashboard20.realisasi_rnm) === null || _vm$report_dashboard21 === void 0 ? void 0 : (_vm$report_dashboard22 = _vm$report_dashboard21.total) === null || _vm$report_dashboard22 === void 0 ? void 0 : _vm$report_dashboard22.realisasi) + "%")])])], 2)])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Type R & M")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$report_dashboard23, _vm$report_dashboard24, _vm$report_dashboard25, _vm$report_dashboard26;
        return [_c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', _vm._l((_vm$report_dashboard23 = _vm.report_dashboard) === null || _vm$report_dashboard23 === void 0 ? void 0 : (_vm$report_dashboard24 = _vm$report_dashboard23.dashboard_tables) === null || _vm$report_dashboard24 === void 0 ? void 0 : _vm$report_dashboard24.type_rnm, function (state, index) {
          return _c('th', {
            key: index,
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state.label))]);
        }), 0)]), _c('tbody', [_c('tr', _vm._l((_vm$report_dashboard25 = _vm.report_dashboard) === null || _vm$report_dashboard25 === void 0 ? void 0 : (_vm$report_dashboard26 = _vm$report_dashboard25.dashboard_tables) === null || _vm$report_dashboard26 === void 0 ? void 0 : _vm$report_dashboard26.type_rnm, function (state, index) {
          return _c('td', {
            key: index,
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state.value) + " Jobs")]);
        }), 0)])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Status R & M")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$report_dashboard27, _vm$report_dashboard28;
        return [_c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('tbody', _vm._l((_vm$report_dashboard27 = _vm.report_dashboard) === null || _vm$report_dashboard27 === void 0 ? void 0 : (_vm$report_dashboard28 = _vm$report_dashboard27.dashboard_tables) === null || _vm$report_dashboard28 === void 0 ? void 0 : _vm$report_dashboard28.status_rnm, function (state, index) {
          return _c('tr', {
            key: index
          }, [_c('th', [_vm._v(_vm._s(state.label))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state.value) + " Jobs")])]);
        }), 0)])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Vessel Type R & M")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$report_dashboard29, _vm$report_dashboard30, _vm$report_dashboard31, _vm$report_dashboard32;
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', _vm._l((_vm$report_dashboard29 = _vm.report_dashboard) === null || _vm$report_dashboard29 === void 0 ? void 0 : (_vm$report_dashboard30 = _vm$report_dashboard29.dashboard_tables) === null || _vm$report_dashboard30 === void 0 ? void 0 : _vm$report_dashboard30.vessel_type_rnm, function (state, index) {
          return _c('th', {
            key: index,
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(state.label))]);
        }), 0), _c('tr', _vm._l((_vm$report_dashboard31 = _vm.report_dashboard) === null || _vm$report_dashboard31 === void 0 ? void 0 : (_vm$report_dashboard32 = _vm$report_dashboard31.dashboard_tables) === null || _vm$report_dashboard32 === void 0 ? void 0 : _vm$report_dashboard32.vessel_type_rnm, function (state, index) {
          return _c('td', {
            key: index,
            staticClass: "text-center"
          }, [_vm._v(" " + _vm._s(state.value) + " Jobs")]);
        }), 0)])])];
      },
      proxy: true
    }])
  })], 1)], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }