var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('div', {
    class: {
      bold: _vm.isFolder
    },
    on: {
      "click": _vm.toggle,
      "dblclick": _vm.makeFolder
    }
  }, [_vm.isFolder ? _c('span', [_vm.isOpen ? [_vm.hasGroupOpenSlot ? [_vm._t("groupClosed")] : [_c('i', {
    staticClass: "fas fa-minus-square"
  })]] : [_vm.hasGroupCloseSlot ? [_vm._t("groupOpened")] : [_c('i', {
    staticClass: "fas fa-plus-square"
  })]]], 2) : _c('span', [_vm.hasItemSlot ? [_vm._t("item")] : [_c('i', {
    staticClass: "fas fa-caret-square-right"
  })]], 2), _vm._v(" " + _vm._s(_vm.item.name) + " ")]), _vm.isFolder ? _c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isOpen,
      expression: "isOpen"
    }],
    staticClass: "tree"
  }, [_vm.item.children ? _vm._l(_vm.item.children, function (child, index) {
    return _c('TreeView', {
      key: index,
      attrs: {
        "item": child
      },
      scopedSlots: _vm._u([{
        key: "groupOpened",
        fn: function fn() {
          return [_vm.hasGroupOpenSlot ? [_vm._t("groupOpened")] : [_c('i', {
            staticClass: "fas fa-plus-square"
          })]];
        },
        proxy: true
      }, {
        key: "groupClosed",
        fn: function fn() {
          return [_vm.hasGroupOpenSlot ? [_vm._t("groupClosed")] : [_c('i', {
            staticClass: "fas fa-minus-square"
          })]];
        },
        proxy: true
      }, {
        key: "item",
        fn: function fn() {
          return [_vm.hasItemSlot ? [_vm._t("item")] : [_c('i', {
            staticClass: "fas fa-caret-square-right"
          })]];
        },
        proxy: true
      }], null, true)
    });
  }) : _vm._e()], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }