var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('StepNavigation', {
    attrs: {
      "current-step": "RCA_FINAL"
    }
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Final Report Root Cause Analysis")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_vm.canPreview ? _c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.isPreview,
            callback: function callback($$v) {
              _vm.isPreview = $$v;
            },
            expression: "isPreview"
          }
        }, [[_vm._v("Preview")]], 2) : _vm._e()];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.isPreview ? _c('FinalReportRootCauseAnalysisPreview') : _c('FinalReportRootCauseAnalysisForm')];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }