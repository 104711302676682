var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": true,
            "href": "#schedule",
            "title": "SCHEDULE"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": false,
            "href": "#progress",
            "title": "PROGRESS"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": false,
            "href": "#finish",
            "title": "FINISH"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "iq-edit-list-data"
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "schedule"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("DOCKING SCHEDULE")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.add-init",
            modifiers: {
              "add-init": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.showInitModal = !_vm.showInitModal;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        }), _vm._v("   CREATE EMERGENCY DOCKING")]), _c('a', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.setup-calendar",
            modifiers: {
              "setup-calendar": true
            }
          }],
          staticClass: "btn btn-primary mr-2",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-calendar mr-2"
        }), _vm._v("SET SCHEDULE DOCKING")]), _c('router-link', {
          attrs: {
            "to": "/ops/docking-create"
          }
        }), _c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.docking_schedule_card",
            modifiers: {
              "docking_schedule_card": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })]), _c('b-modal', {
          attrs: {
            "id": "add-init",
            "size": "xl",
            "title": "Select Fleet",
            "header-bg-variant": "light",
            "header-text-variant": "dark",
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref) {
              var ok = _ref.ok,
                cancel = _ref.cancel,
                hide = _ref.hide;
              return [_c('b-row', [_c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "iq-bg-danger text-right",
                attrs: {
                  "variant": "none"
                },
                on: {
                  "click": function click($event) {
                    return cancel();
                  }
                }
              }, [_vm._v("Cancel")]), _c('b-button', {
                staticClass: "ml-2 text-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                },
                on: {
                  "click": function click($event) {
                    _vm.modalCreateDocking = !_vm.modalCreateDocking;
                    _vm.showInitModal = !_vm.showInitModal;
                  }
                }
              }, [_vm._v("Next "), _c('i', {
                staticClass: "fa fa-arrow-right"
              })])], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.showInitModal,
            callback: function callback($$v) {
              _vm.showInitModal = $$v;
            },
            expression: "showInitModal"
          }
        }, [_c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Search fleets..."
          },
          on: {
            "input": _vm.fetchFleets
          },
          model: {
            value: _vm.fleet_params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.fleet_params, "search", $$v);
            },
            expression: "fleet_params.search"
          }
        })], 1)], 1), _vm.loading_fleet ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.fleets.length > 0 ? _c('b-row', _vm._l(_vm.fleets, function (fleet, i) {
          return _c('b-col', {
            key: "fleet-".concat(i),
            staticClass: "text-center py-5 border-top",
            attrs: {
              "md": "3"
            }
          }, [_c('div', {
            staticClass: "custom-control custom-checkbox image-checkbox"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.checkedFleets,
              expression: "checkedFleets"
            }],
            staticClass: "custom-control-input",
            attrs: {
              "id": "ck-fl-".concat(i),
              "type": "checkbox"
            },
            domProps: {
              "value": fleet.id,
              "checked": Array.isArray(_vm.checkedFleets) ? _vm._i(_vm.checkedFleets, fleet.id) > -1 : _vm.checkedFleets
            },
            on: {
              "change": function change($event) {
                var $$a = _vm.checkedFleets,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = fleet.id,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && (_vm.checkedFleets = $$a.concat([$$v]));
                  } else {
                    $$i > -1 && (_vm.checkedFleets = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.checkedFleets = $$c;
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": "ck-fl-".concat(i)
            }
          }, [_c('img', {
            staticClass: "img-fluid",
            attrs: {
              "src": _vm._f("checkPhoto")(fleet.vehicleType.icon.url, require('@src/assets/images/fleet/PusherBarge.png')),
              "alt": "#"
            }
          })]), _c('small', [_c('strong', [_vm._v(_vm._s(fleet.name))])])])]);
        }), 1) : _c('div', {
          staticClass: "text-center my-5"
        }, [_c('p', {
          staticClass: "mb-0 text-muted"
        }, [_vm._v("No fleets data found.")])])]], 2), _c('b-modal', {
          attrs: {
            "id": "setup-calendar",
            "size": "lg",
            "title": "Set Schedule",
            "ok-title": "Save",
            "cancel-title": "Close"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet *",
            "label-for": "fleet"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.kapal,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Fleet")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedFleets,
            callback: function callback($$v) {
              _vm.selectedFleets = $$v;
            },
            expression: "selectedFleets"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Last Docking Special Survey",
            "label-for": "last_docking"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "end"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Type Dock",
            "label-for": "type_dock"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "readonly": "",
            "placeholder": "Intermediate Survey"
          }
        })], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Interval",
            "label-for": "interval"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "30"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Month")])])], 1)])], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Start Schedule",
            "label-for": "start_schedule"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "3"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Month")])])], 1)])], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "End Schedule",
            "label-for": "end_schedule"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "3"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Month")])])], 1)])], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Type Dock",
            "label-for": "type_dock"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "readonly": "",
            "placeholder": "Special Survey"
          }
        })], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Interval",
            "label-for": "interval"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "60"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Month")])])], 1)])], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Start Schedule",
            "label-for": "start_schedule"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "15"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Month")])])], 1)])], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "End Schedule",
            "label-for": "end_schedule"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "0"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Month")])])], 1)])], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "visible": "",
            "id": "docking_schedule_card"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet Category",
            "label-for": "fleet_category"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Fleet Category",
            "label": "text",
            "track-by": "value",
            "options": _vm.fleetCategory,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.fleetCategorySelect,
            callback: function callback($$v) {
              _vm.fleetCategorySelect = $$v;
            },
            expression: "fleetCategorySelect"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Docking Type",
            "label-for": "docking_type"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.docking_type,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Docking Type")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedDockingType,
            callback: function callback($$v) {
              _vm.selectedDockingType = $$v;
            },
            expression: "selectedDockingType"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.dockingStatus,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Status")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedDockingStatus,
            callback: function callback($$v) {
              _vm.selectedDockingStatus = $$v;
            },
            expression: "selectedDockingStatus"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Period",
            "label-for": "Period"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DOCKING TYPE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("REMAINING DAYS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("SCHEDULE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("1")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TCP 201")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-03-15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("ACTIVE")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-ship m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1)], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("2")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TCP 202")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("180 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2022-07-17")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "warning d-block"
          }
        }, [_vm._v("DUE DATE")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-ship m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.modalCreateDocking = !_vm.modalCreateDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square-o mr-2"
        }), _vm._v("DOCKING")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.pending_docking",
            modifiers: {
              "pending_docking": true
            }
          }],
          attrs: {
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-clock mr-2"
        }), _vm._v("PENDING")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1)], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("3")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TCP 203")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("31 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2023-09-25")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary d-block"
          }
        }, [_vm._v("FIRST PENDING")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-ship m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.modalCreateDocking = !_vm.modalCreateDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square-o mr-2"
        }), _vm._v("DOCKING")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.pending_docking",
            modifiers: {
              "pending_docking": true
            }
          }],
          attrs: {
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-clock mr-2"
        }), _vm._v("PENDING")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1)], 1)]), _c('tr', {
          staticClass: "bg-danger"
        }, [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("4")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TCP 204")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("SPECIAL SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("- 15 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2023-03-17")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "light d-block"
          }
        }, [_vm._v("OVER SCHEDULE")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-ship m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.modalCreateDocking = !_vm.modalCreateDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square-o mr-2"
        }), _vm._v("DOCKING")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.pending_docking",
            modifiers: {
              "pending_docking": true
            }
          }],
          attrs: {
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-clock mr-2"
        }), _vm._v("PENDING")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1)], 1)]), _c('tr', {
          staticClass: "bg-info"
        }, [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("5")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. SML 201")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("31 DAYS")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-01-15")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_c('strong', [_vm._v("PROGRESS - CHECK TAB PROGRESS")])])]), _c('tr', {
          staticClass: "bg-warning"
        }, [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("6")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. SML 201")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-01-15")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_c('strong', [_vm._v("REVIEW - CHECK TAB PROGRESS")])])]), _c('tr', {
          staticClass: "bg-primary"
        }, [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("7")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. SML 201")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-01-15")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_c('strong', [_vm._v("APPROVE - CHECK TAB PROGRESS")])])])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1)], 1), _c('b-modal', {
          attrs: {
            "id": "pending_docking",
            "size": "md",
            "title": "Pending Docking - TB. ETI 201",
            "ok-title": "Save",
            "cancel-title": "Close"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Pending Date *",
            "label-for": "pending_date"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "docking_date"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1), _c('b-form-group', {
          attrs: {
            "label": "Reason Pending",
            "label-for": "reason_pending"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "reason_pending",
            "rows": "3"
          }
        })], 1), _c('b-button', {
          attrs: {
            "block": "",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-calendar mr-2"
        }), _vm._v("Pending Docking")])], 1)], 1)], 1), _c('b-sidebar', {
          attrs: {
            "id": "emergency_docking",
            "title": "CREATE DOCKING",
            "width": "90%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hide = _ref2.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('span', {
                staticClass: "mr-5"
              }, [_c('h4', [_vm._v("Fleet / Equipment : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("TB. TCP 201")])], 1)]), _c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table"
              }, [_c('tbody', [_c('tr', {
                staticClass: "bg-primary"
              }, [_c('td', [_c('ul', {
                staticClass: "text-left"
              }, [_c('li', [_vm._v("Main Engine 1 - Yanmar 12LAK "), _c('span', {
                staticClass: "pull-right"
              }, [_vm._v("Actual RH : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("2.800 RH")])], 1)]), _c('li', [_vm._v("Main Engine 2 - Cummin KW321 "), _c('span', {
                staticClass: "pull-right"
              }, [_vm._v("Actual RH : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("2.800 RH")])], 1)])])])])])])])]), _c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Select Docking Job")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "10"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "job_name",
                  "type": "text",
                  "placeholder": "Search Job Name"
                }
              })], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-button', {
                attrs: {
                  "variant": "success",
                  "block": ""
                }
              }, [_vm._v("SEARCH")])], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('v-jstree', {
                staticClass: "mt-3",
                attrs: {
                  "data": _vm.tree,
                  "show-checkbox": "",
                  "allow-batch": "",
                  "whole-row": "",
                  "item-events": _vm.itemEvents
                },
                on: {
                  "item-click": _vm.itemClick
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_) {
                    return [_c('div', {
                      staticStyle: {
                        "display": "inherit",
                        "width": "200px"
                      },
                      on: {
                        "click": function click($event) {
                          if (!$event.ctrlKey) return null;
                          return _vm.customItemClickWithCtrl.apply(null, arguments);
                        }
                      }
                    }, [!_.model.loading ? _c('i', {
                      class: _.vm.themeIconClasses,
                      attrs: {
                        "role": "presentation"
                      }
                    }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
                  }
                }], null, true)
              })], 1), _c('b-col', {
                attrs: {
                  "md": "8"
                }
              }, [_c('b-row', [_c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v(" ADD DOCKING JOB")]), _c('fieldset', {
                staticClass: "form-group border p-3 iq-bg-info mt-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Docking Information")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Arrival Shipyard *",
                  "label-for": "arrival_shipyard"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "arrival_shipyard",
                  "placeholder": "05/07/2020"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Up Slip *",
                  "label-for": "up_slip"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "up_slip"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Waiting Dock Space *",
                  "label-for": "waiting_dock_space"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "number",
                  "id": "waiting_dock_space"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Down Slip *",
                  "label-for": "down_slip"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "down_slip"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Complete Date *",
                  "label-for": "delivery_date"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "down_slip"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Estimasi Budget",
                  "label-for": "est_budget"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "number",
                  "id": "est_budget"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Shipyard *",
                  "label-for": "shipyard"
                }
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "options": _vm.shipyard,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Shipyard")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedShipyard,
                  callback: function callback($$v) {
                    _vm.selectedShipyard = $$v;
                  },
                  expression: "selectedShipyard"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC",
                  "label-for": "pic"
                }
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "options": _vm.pic,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select PIC")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedPIC,
                  callback: function callback($$v) {
                    _vm.selectedPIC = $$v;
                  },
                  expression: "selectedPIC"
                }
              })], 1)], 1), _vm._v("> "), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-primary rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-primary"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Job Category")])])])];
                  },
                  proxy: true
                }], null, true)
              })], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-success"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('b-button', {
                      directives: [{
                        name: "b-toggle",
                        rawName: "v-b-toggle.jobCat-1",
                        modifiers: {
                          "jobCat-1": true
                        }
                      }],
                      staticClass: "mr-1",
                      attrs: {
                        "variant": "primary"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-chevron-circle-down"
                    }), _vm._v("Click, Job Detail")]), _c('h4', {
                      staticClass: "mb-0"
                    }, [_vm._v("DOCKING / UNDOCKING")]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('div', {
                      staticClass: "input-group"
                    }, [_c('div', {
                      staticClass: "input-group-prepend"
                    }, [_c('span', {
                      staticClass: "input-group-text bg-primary",
                      attrs: {
                        "id": "inputGroupPrepend2"
                      }
                    }, [_vm._v("%")])]), _c('input', {
                      staticClass: "form-control",
                      attrs: {
                        "type": "number",
                        "id": "real_budget",
                        "placeholder": "30"
                      }
                    })])])], 1)];
                  },
                  proxy: true
                }], null, true)
              }), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-1",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Up Slip "), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan",
                  "placeholder": "50"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Down Slip "), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan",
                  "placeholder": "50"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-success"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('b-button', {
                      directives: [{
                        name: "b-toggle",
                        rawName: "v-b-toggle.jobCat-2",
                        modifiers: {
                          "jobCat-2": true
                        }
                      }],
                      staticClass: "mr-1",
                      attrs: {
                        "variant": "primary"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-chevron-circle-down"
                    }), _vm._v("Click, Job Detail")]), _c('h4', {
                      staticClass: "mb-0"
                    }, [_vm._v("SAND BLASTING")]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('div', {
                      staticClass: "input-group"
                    }, [_c('div', {
                      staticClass: "input-group-prepend"
                    }, [_c('span', {
                      staticClass: "input-group-text bg-primary",
                      attrs: {
                        "id": "inputGroupPrepend2"
                      }
                    }, [_vm._v("%")])]), _c('input', {
                      staticClass: "form-control",
                      attrs: {
                        "type": "number",
                        "id": "real_budget",
                        "placeholder": "30"
                      }
                    })])])], 1)];
                  },
                  proxy: true
                }], null, true)
              }), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-2",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-1",
                  modifiers: {
                    "job-2-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Scraping teritip di lambung dan lunas"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-2",
                  modifiers: {
                    "job-2-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Pencucian lambung dengan air tawar"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-3",
                  modifiers: {
                    "job-2-3": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Sand blasting lunas"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-4",
                  modifiers: {
                    "job-2-4": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Sand blasting deck dan bulwark"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
                staticClass: "accordion-jobCat-3",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-success"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('b-button', {
                      directives: [{
                        name: "b-toggle",
                        rawName: "v-b-toggle.jobCat-3",
                        modifiers: {
                          "jobCat-3": true
                        }
                      }],
                      staticClass: "mr-1",
                      attrs: {
                        "variant": "primary"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-chevron-circle-down"
                    }), _vm._v("Click, Job Detail")]), _c('h4', {
                      staticClass: "mb-0"
                    }, [_vm._v("MACHINERY EQUIPMENT")]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('div', {
                      staticClass: "input-group"
                    }, [_c('div', {
                      staticClass: "input-group-prepend"
                    }, [_c('span', {
                      staticClass: "input-group-text bg-primary",
                      attrs: {
                        "id": "inputGroupPrepend2"
                      }
                    }, [_vm._v("%")])]), _c('input', {
                      staticClass: "form-control",
                      attrs: {
                        "type": "number",
                        "id": "real_budget",
                        "placeholder": "30"
                      }
                    })])])], 1)];
                  },
                  proxy: true
                }], null, true)
              }), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-3",
                  "accordion": "my-accordion-jobCat-3",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-1",
                  modifiers: {
                    "job-3-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" General Overhaul ME (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-2",
                  modifiers: {
                    "job-3-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Kalibrasi injector (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-3",
                  modifiers: {
                    "job-3-3": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Service/ganti baru dynamo starter (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-4",
                  modifiers: {
                    "job-3-4": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Service/ganti baru alternator (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', {
                staticClass: "pull-right"
              }, [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('br'), _c('br'), _c('b-button', {
                staticClass: "pull-right",
                attrs: {
                  "type": "submit",
                  "variant": "danger"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" Reset Form")]), _c('b-button', {
                staticClass: "pull-right mr-1",
                attrs: {
                  "type": "submit",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save")])], 1)], 1)], 1)], 1)], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.modalCreateDocking,
            callback: function callback($$v) {
              _vm.modalCreateDocking = $$v;
            },
            expression: "modalCreateDocking"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "progress"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("DOCKING PROGRESS")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.docking_progress_card",
            modifiers: {
              "docking_progress_card": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })]), _c('b-modal', {
          attrs: {
            "id": "add-init",
            "size": "xl",
            "title": "Select Fleet",
            "header-bg-variant": "light",
            "header-text-variant": "dark",
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref3) {
              var ok = _ref3.ok,
                cancel = _ref3.cancel,
                hide = _ref3.hide;
              return [_c('b-row', [_c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "iq-bg-danger text-right",
                attrs: {
                  "variant": "none"
                },
                on: {
                  "click": function click($event) {
                    return cancel();
                  }
                }
              }, [_vm._v("Cancel")]), _c('b-button', {
                staticClass: "ml-2 text-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                },
                on: {
                  "click": function click($event) {
                    _vm.modalCreateDocking = !_vm.modalCreateDocking;
                    _vm.showInitModal = !_vm.showInitModal;
                  }
                }
              }, [_vm._v("Next "), _c('i', {
                staticClass: "fa fa-arrow-right"
              })])], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.showInitModal,
            callback: function callback($$v) {
              _vm.showInitModal = $$v;
            },
            expression: "showInitModal"
          }
        }, [_c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Search fleets..."
          },
          on: {
            "input": _vm.fetchFleets
          },
          model: {
            value: _vm.fleet_params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.fleet_params, "search", $$v);
            },
            expression: "fleet_params.search"
          }
        })], 1)], 1), _vm.loading_fleet ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.fleets.length > 0 ? _c('b-row', _vm._l(_vm.fleets, function (fleet, i) {
          return _c('b-col', {
            key: "fleet-".concat(i),
            staticClass: "text-center py-5 border-top",
            attrs: {
              "md": "3"
            }
          }, [_c('div', {
            staticClass: "custom-control custom-checkbox image-checkbox"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.checkedFleets,
              expression: "checkedFleets"
            }],
            staticClass: "custom-control-input",
            attrs: {
              "id": "ck-fl-".concat(i),
              "type": "checkbox"
            },
            domProps: {
              "value": fleet.id,
              "checked": Array.isArray(_vm.checkedFleets) ? _vm._i(_vm.checkedFleets, fleet.id) > -1 : _vm.checkedFleets
            },
            on: {
              "change": function change($event) {
                var $$a = _vm.checkedFleets,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = fleet.id,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && (_vm.checkedFleets = $$a.concat([$$v]));
                  } else {
                    $$i > -1 && (_vm.checkedFleets = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.checkedFleets = $$c;
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": "ck-fl-".concat(i)
            }
          }, [_c('img', {
            staticClass: "img-fluid",
            attrs: {
              "src": _vm._f("checkPhoto")(fleet.vehicleType.icon.url, require('@src/assets/images/fleet/PusherBarge.png')),
              "alt": "#"
            }
          })]), _c('small', [_c('strong', [_vm._v(_vm._s(fleet.name))])])])]);
        }), 1) : _c('div', {
          staticClass: "text-center my-5"
        }, [_c('p', {
          staticClass: "mb-0 text-muted"
        }, [_vm._v("No fleets data found.")])])]], 2)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "visible": "",
            "id": "docking_progress_card"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet Category",
            "label-for": "fleet_category"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Fleet Category",
            "label": "text",
            "track-by": "value",
            "options": _vm.fleetCategory,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.fleetCategorySelect,
            callback: function callback($$v) {
              _vm.fleetCategorySelect = $$v;
            },
            expression: "fleetCategorySelect"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Docking Type",
            "label-for": "docking_type"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.docking_type,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Docking Type")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedDockingType,
            callback: function callback($$v) {
              _vm.selectedDockingType = $$v;
            },
            expression: "selectedDockingType"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.dockingStatus,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Status")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedDockingStatus,
            callback: function callback($$v) {
              _vm.selectedDockingStatus = $$v;
            },
            expression: "selectedDockingStatus"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Period",
            "label-for": "Period"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DOCKING TYPE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("SHIPYARD")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DOCKING DATE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("COMPLETE DATE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("JOB")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("1")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TCP 201")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. CAHAYA BAHARI SHIPYARD")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2017-05-10")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2022-06-12")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("0/20")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "warning d-block"
          }
        }, [_vm._v("NEED REVIEW")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              _vm.slideReviewDocking = !_vm.slideReviewDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search mr-2"
        }), _vm._v("REVIEW")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1)], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("2")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. SML 301")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("SPECIAL SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. CITRA SHIPYARD INDONESIA")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2018-03-25")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2018-04-30")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("0/15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary d-block"
          }
        }, [_vm._v("NEED APPROVE")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              _vm.slideApproveDocking = !_vm.slideApproveDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-check mr-2"
        }), _vm._v("APPROVE")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1)], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("3")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. ETI 304")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. SARANA SHIPYARD BAHARI")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-01-15")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-02-20")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("0/15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info d-block"
          }
        }, [_vm._v("ON PROGRESS")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "variant": "info"
          },
          on: {
            "click": function click($event) {
              _vm.slideActivity = !_vm.slideActivity;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-comments mr-2"
        }), _vm._v("ACTIVITY")]), _c('b-dropdown-item', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.slideUpdateDocking = !_vm.slideUpdateDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus-circle mr-2"
        }), _vm._v("ADD JOB")]), _c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1)], 1)]), _c('tr', {
          staticClass: "bg-danger"
        }, [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("4")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. SML 201")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. SARANA SHIPYARD BAHARI")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-01-15")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-02-20")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("0/15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "light d-block"
          }
        }, [_vm._v("REJECT")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.slideUpdateDocking = !_vm.slideUpdateDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-percent mr-2"
        }), _vm._v("UPDATE DOCKING")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1)], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("5")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TCP 3301")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. CITRA SHIPYARD INDONESIA")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2018-03-25")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2018-04-30")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15/15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("SEA TRIAL")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('router-link', {
          attrs: {
            "to": "/ops/docking"
          }
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-ship mr-2"
        }), _vm._v("SEA TRIAL")])], 1), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1)], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("6")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TCP 3301")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. CITRA SHIPYARD INDONESIA")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2018-03-25")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2018-04-30")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15/15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary d-block"
          }
        }, [_vm._v("FINISH DOCKING")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.slideFinishDocking = !_vm.slideFinishDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-check mr-2"
        }), _vm._v("FINISH DOCKING")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1)], 1)])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1)], 1), _c('b-sidebar', {
          attrs: {
            "id": "review_docking",
            "title": "FORM REVIEW DOCKING - TB. SML 201",
            "width": "90%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var hide = _ref4.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Select Docking Job")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "10"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "job_name",
                  "type": "text",
                  "placeholder": "Search Job Name"
                }
              })], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-button', {
                attrs: {
                  "variant": "success",
                  "block": ""
                }
              }, [_vm._v("SEARCH")])], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('v-jstree', {
                staticClass: "mt-3",
                attrs: {
                  "data": _vm.tree,
                  "show-checkbox": "",
                  "allow-batch": "",
                  "whole-row": "",
                  "item-events": _vm.itemEvents
                },
                on: {
                  "item-click": _vm.itemClick
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_) {
                    return [_c('div', {
                      staticStyle: {
                        "display": "inherit",
                        "width": "200px"
                      },
                      on: {
                        "click": function click($event) {
                          if (!$event.ctrlKey) return null;
                          return _vm.customItemClickWithCtrl.apply(null, arguments);
                        }
                      }
                    }, [!_.model.loading ? _c('i', {
                      class: _.vm.themeIconClasses,
                      attrs: {
                        "role": "presentation"
                      }
                    }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
                  }
                }], null, true)
              })], 1), _c('b-col', {
                attrs: {
                  "md": "8"
                }
              }, [_c('b-row', [_c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v(" ADD DOCKING JOB")]), _c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Arrival Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "arrival_shipyard"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Up Slip")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "upslip"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Waiting Dock Space")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("5 Hari")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Down Slip")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "downslip"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Complete Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "delivery_date"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Budget")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 385.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA BAHARI SHIPYARD")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Real Badget")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "real_budget",
                  "placeholder": "324.000.000"
                }
              })])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("NURUL AKBAR")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])]), _c('hr'), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-primary rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-primary"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Job Category")])])])];
                  },
                  proxy: true
                }], null, true)
              })], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-success"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('b-button', {
                      directives: [{
                        name: "b-toggle",
                        rawName: "v-b-toggle.jobCat-1",
                        modifiers: {
                          "jobCat-1": true
                        }
                      }],
                      staticClass: "mr-1",
                      attrs: {
                        "variant": "primary"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-chevron-circle-down"
                    }), _vm._v("Click, Job Detail")]), _c('h4', {
                      staticClass: "mb-0"
                    }, [_vm._v("DOCKING / UNDOCKING")]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('div', {
                      staticClass: "input-group"
                    }, [_c('div', {
                      staticClass: "input-group-prepend"
                    }, [_c('span', {
                      staticClass: "input-group-text bg-primary",
                      attrs: {
                        "id": "inputGroupPrepend2"
                      }
                    }, [_vm._v("%")])]), _c('input', {
                      staticClass: "form-control",
                      attrs: {
                        "type": "number",
                        "id": "real_budget",
                        "placeholder": "30"
                      }
                    })])])], 1)];
                  },
                  proxy: true
                }], null, true)
              }), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-1",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Up Slip "), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan",
                  "placeholder": "50"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Down Slip "), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan",
                  "placeholder": "50"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-success"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('b-button', {
                      directives: [{
                        name: "b-toggle",
                        rawName: "v-b-toggle.jobCat-2",
                        modifiers: {
                          "jobCat-2": true
                        }
                      }],
                      staticClass: "mr-1",
                      attrs: {
                        "variant": "primary"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-chevron-circle-down"
                    }), _vm._v("Click, Job Detail")]), _c('h4', {
                      staticClass: "mb-0"
                    }, [_vm._v("SAND BLASTING")]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('div', {
                      staticClass: "input-group"
                    }, [_c('div', {
                      staticClass: "input-group-prepend"
                    }, [_c('span', {
                      staticClass: "input-group-text bg-primary",
                      attrs: {
                        "id": "inputGroupPrepend2"
                      }
                    }, [_vm._v("%")])]), _c('input', {
                      staticClass: "form-control",
                      attrs: {
                        "type": "number",
                        "id": "real_budget",
                        "placeholder": "30"
                      }
                    })])])], 1)];
                  },
                  proxy: true
                }], null, true)
              }), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-2",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-1",
                  modifiers: {
                    "job-2-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Scraping teritip di lambung dan lunas"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-2",
                  modifiers: {
                    "job-2-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Pencucian lambung dengan air tawar"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-3",
                  modifiers: {
                    "job-2-3": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Sand blasting lunas"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-4",
                  modifiers: {
                    "job-2-4": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Sand blasting deck dan bulwark"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
                staticClass: "accordion-jobCat-3",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-success"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('b-button', {
                      directives: [{
                        name: "b-toggle",
                        rawName: "v-b-toggle.jobCat-3",
                        modifiers: {
                          "jobCat-3": true
                        }
                      }],
                      staticClass: "mr-1",
                      attrs: {
                        "variant": "primary"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-chevron-circle-down"
                    }), _vm._v("Click, Job Detail")]), _c('h4', {
                      staticClass: "mb-0"
                    }, [_vm._v("MACHINERY EQUIPMENT")]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('div', {
                      staticClass: "input-group"
                    }, [_c('div', {
                      staticClass: "input-group-prepend"
                    }, [_c('span', {
                      staticClass: "input-group-text bg-primary",
                      attrs: {
                        "id": "inputGroupPrepend2"
                      }
                    }, [_vm._v("%")])]), _c('input', {
                      staticClass: "form-control",
                      attrs: {
                        "type": "number",
                        "id": "real_budget",
                        "placeholder": "30"
                      }
                    })])])], 1)];
                  },
                  proxy: true
                }], null, true)
              }), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-3",
                  "accordion": "my-accordion-jobCat-3",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-1",
                  modifiers: {
                    "job-3-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" General Overhaul ME (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Internal Man Power")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Kontraktor 1")]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Final")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Note")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Note Here")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Attachment")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("APPROVED")])])])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-2",
                  modifiers: {
                    "job-3-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Kalibrasi injector (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Internal Man Power")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("APPROVED")])])])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-3",
                  modifiers: {
                    "job-3-3": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Service/ganti baru dynamo starter (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Kontraktor 1")]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Final")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Note")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Note Here")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Attachment")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("APPROVED")])])])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-4",
                  modifiers: {
                    "job-3-4": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Service/ganti baru alternator (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Kontraktor 1")]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Final")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Note")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Note Here")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Attachment")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("APPROVED")])])])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', {
                staticClass: "pull-right"
              }, [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('br'), _c('br'), _c('b-button', {
                staticClass: "pull-right mr-1",
                attrs: {
                  "type": "submit",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Review")])], 1)], 1)], 1)], 1)], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.slideReviewDocking,
            callback: function callback($$v) {
              _vm.slideReviewDocking = $$v;
            },
            expression: "slideReviewDocking"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "update_docking",
            "title": "FORM UPDATE DOCKING - TB. SML 201",
            "width": "90%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var hide = _ref5.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Select Docking Job")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "10"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "job_name",
                  "type": "text",
                  "placeholder": "Search Job Name"
                }
              })], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-button', {
                attrs: {
                  "variant": "success",
                  "block": ""
                }
              }, [_vm._v("SEARCH")])], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('v-jstree', {
                staticClass: "mt-3",
                attrs: {
                  "data": _vm.tree,
                  "show-checkbox": "",
                  "allow-batch": "",
                  "whole-row": "",
                  "item-events": _vm.itemEvents
                },
                on: {
                  "item-click": _vm.itemClick
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_) {
                    return [_c('div', {
                      staticStyle: {
                        "display": "inherit",
                        "width": "200px"
                      },
                      on: {
                        "click": function click($event) {
                          if (!$event.ctrlKey) return null;
                          return _vm.customItemClickWithCtrl.apply(null, arguments);
                        }
                      }
                    }, [!_.model.loading ? _c('i', {
                      class: _.vm.themeIconClasses,
                      attrs: {
                        "role": "presentation"
                      }
                    }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
                  }
                }], null, true)
              })], 1), _c('b-col', {
                attrs: {
                  "md": "8"
                }
              }, [_c('b-row', [_c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v(" ADD DOCKING JOB")]), _c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Arrival Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Up Slip")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Waiting Dock Space")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("5 Hari")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Down Slip")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Complete Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Budget")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 385.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA BAHARI SHIPYARD")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Real Badget")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 350.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("NURUL AKBAR")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])]), _c('hr'), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-primary rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-primary"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Job Category")])])])];
                  },
                  proxy: true
                }], null, true)
              })], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-success"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('b-button', {
                      directives: [{
                        name: "b-toggle",
                        rawName: "v-b-toggle.jobCat-1",
                        modifiers: {
                          "jobCat-1": true
                        }
                      }],
                      staticClass: "mr-1",
                      attrs: {
                        "variant": "primary"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-chevron-circle-down"
                    }), _vm._v("Click, Job Detail")]), _c('h4', {
                      staticClass: "mb-0"
                    }, [_vm._v("DOCKING / UNDOCKING")]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('div', {
                      staticClass: "input-group"
                    }, [_c('div', {
                      staticClass: "input-group-prepend"
                    }, [_c('span', {
                      staticClass: "input-group-text bg-primary",
                      attrs: {
                        "id": "inputGroupPrepend2"
                      }
                    }, [_vm._v("%")])]), _c('input', {
                      staticClass: "form-control",
                      attrs: {
                        "type": "number",
                        "id": "real_budget",
                        "placeholder": "30"
                      }
                    })])])], 1)];
                  },
                  proxy: true
                }], null, true)
              }), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-1",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Up Slip "), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Internal Man Power")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Kontraktor 1")]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Final")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Note")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Note Here")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Attachment")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("APPROVED")])])])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Down Slip "), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Internal Man Power")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Kontraktor 1")]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Final")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Note")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Note Here")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Attachment")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("APPROVED")])])])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-success"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('b-button', {
                      directives: [{
                        name: "b-toggle",
                        rawName: "v-b-toggle.jobCat-2",
                        modifiers: {
                          "jobCat-2": true
                        }
                      }],
                      staticClass: "mr-1",
                      attrs: {
                        "variant": "primary"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-chevron-circle-down"
                    }), _vm._v("Click, Job Detail")]), _c('h4', {
                      staticClass: "mb-0"
                    }, [_vm._v("SAND BLASTING")]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('div', {
                      staticClass: "input-group"
                    }, [_c('div', {
                      staticClass: "input-group-prepend"
                    }, [_c('span', {
                      staticClass: "input-group-text bg-primary",
                      attrs: {
                        "id": "inputGroupPrepend2"
                      }
                    }, [_vm._v("%")])]), _c('input', {
                      staticClass: "form-control",
                      attrs: {
                        "type": "number",
                        "id": "real_budget",
                        "placeholder": "30"
                      }
                    })])])], 1)];
                  },
                  proxy: true
                }], null, true)
              }), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-2",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-1",
                  modifiers: {
                    "job-2-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Scraping teritip di lambung dan lunas"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('b-form-row', {
                staticClass: "mt-3"
              }, [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-2",
                  modifiers: {
                    "job-2-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Pencucian lambung dengan air tawar"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Internal Man Power")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Kontraktor 1")]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Final")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Note")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Note Here")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Attachment")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("APPROVED")])])])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-3",
                  modifiers: {
                    "job-2-3": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Sand blasting lunas"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Internal Man Power")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Kontraktor 1")]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Final")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Note")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Note Here")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Attachment")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("APPROVED")])])])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-4",
                  modifiers: {
                    "job-2-4": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Sand blasting deck dan bulwark"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Internal Man Power")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Kontraktor 1")]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Final")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Note")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Note Here")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Attachment")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("APPROVED")])])])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
                staticClass: "accordion-jobCat-3",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-success"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('b-button', {
                      directives: [{
                        name: "b-toggle",
                        rawName: "v-b-toggle.jobCat-3",
                        modifiers: {
                          "jobCat-3": true
                        }
                      }],
                      staticClass: "mr-1",
                      attrs: {
                        "variant": "primary"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-chevron-circle-down"
                    }), _vm._v("Click, Job Detail")]), _c('h4', {
                      staticClass: "mb-0"
                    }, [_vm._v("MACHINERY EQUIPMENT")]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('div', {
                      staticClass: "input-group"
                    }, [_c('div', {
                      staticClass: "input-group-prepend"
                    }, [_c('span', {
                      staticClass: "input-group-text bg-primary",
                      attrs: {
                        "id": "inputGroupPrepend2"
                      }
                    }, [_vm._v("%")])]), _c('input', {
                      staticClass: "form-control",
                      attrs: {
                        "type": "number",
                        "id": "real_budget",
                        "placeholder": "30"
                      }
                    })])])], 1)];
                  },
                  proxy: true
                }], null, true)
              }), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-3",
                  "accordion": "my-accordion-jobCat-3",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-1",
                  modifiers: {
                    "job-3-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" General Overhaul ME (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Internal Man Power")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Kontraktor 1")]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Final")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Note")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Note Here")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Attachment")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("APPROVED")])])])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-2",
                  modifiers: {
                    "job-3-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Kalibrasi injector (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Internal Man Power")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("APPROVED")])])])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-3",
                  modifiers: {
                    "job-3-3": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Service/ganti baru dynamo starter (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Kontraktor 1")]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Final")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Note")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Note Here")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Attachment")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("APPROVED")])])])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-4",
                  modifiers: {
                    "job-3-4": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Service/ganti baru alternator (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Kontraktor 1")]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Final")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Note")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Note Here")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Attachment")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("APPROVED")])])])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', {
                staticClass: "pull-right"
              }, [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('br'), _c('br'), _c('b-button', {
                staticClass: "pull-right",
                attrs: {
                  "type": "submit",
                  "variant": "danger"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" Reset Form")]), _c('b-button', {
                staticClass: "pull-right mr-1",
                attrs: {
                  "type": "submit",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save")])], 1)], 1)], 1)], 1)], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.slideUpdateDocking,
            callback: function callback($$v) {
              _vm.slideUpdateDocking = $$v;
            },
            expression: "slideUpdateDocking"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "approve_docking",
            "title": "FORM APPROVE DOCKING - TB. SML 201",
            "width": "90%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var hide = _ref6.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Select Docking Job")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "10"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "job_name",
                  "type": "text",
                  "placeholder": "Search Job Name"
                }
              })], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-button', {
                attrs: {
                  "variant": "success",
                  "block": ""
                }
              }, [_vm._v("SEARCH")])], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('v-jstree', {
                staticClass: "mt-3",
                attrs: {
                  "data": _vm.tree,
                  "show-checkbox": "",
                  "allow-batch": "",
                  "whole-row": "",
                  "item-events": _vm.itemEvents
                },
                on: {
                  "item-click": _vm.itemClick
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_) {
                    return [_c('div', {
                      staticStyle: {
                        "display": "inherit",
                        "width": "200px"
                      },
                      on: {
                        "click": function click($event) {
                          if (!$event.ctrlKey) return null;
                          return _vm.customItemClickWithCtrl.apply(null, arguments);
                        }
                      }
                    }, [!_.model.loading ? _c('i', {
                      class: _.vm.themeIconClasses,
                      attrs: {
                        "role": "presentation"
                      }
                    }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
                  }
                }], null, true)
              })], 1), _c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "8"
                }
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Arrival Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "arrival_shipyard"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Up Slip")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "upslip"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Waiting Dock Space")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("5 Hari")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Down Slip")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "downslip"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Complete Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "delivery_date"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Budget")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 385.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA BAHARI SHIPYARD")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "real_budget",
                  "placeholder": "324.000.000"
                }
              })])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("NURUL AKBAR")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-primary rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-primary"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Job Category")])])])];
                  },
                  proxy: true
                }], null, true)
              })], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-1",
                  modifiers: {
                    "jobCat-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v("DOCKING / UNDOCKING "), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-1",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('b-modal', {
                attrs: {
                  "id": "reason_reject",
                  "size": "md",
                  "title": "Reject - Docking Up Slip",
                  "ok-title": "Save",
                  "cancel-title": "Close"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Reason Reject",
                  "label-for": "reason_reject"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "reason_reject",
                  "rows": "3"
                }
              })], 1), _c('b-button', {
                attrs: {
                  "block": "",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-close mr-2"
              }), _vm._v("Reject Docking Job")])], 1)], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Up Slip "), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Internal Man Power")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Down Slip "), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-2",
                  modifiers: {
                    "jobCat-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v("SAND BLASTING"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 70%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-2",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-1",
                  modifiers: {
                    "job-2-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Scraping teritip di lambung dan lunas"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-2",
                  modifiers: {
                    "job-2-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Pencucian lambung dengan air tawar"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-3",
                  modifiers: {
                    "job-2-3": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Sand blasting lunas"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-4",
                  modifiers: {
                    "job-2-4": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Sand blasting lambung"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 40%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-5",
                  modifiers: {
                    "job-2-5": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Sand blasting deck dan bulwark"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-5",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-3",
                  modifiers: {
                    "jobCat-3": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v("MACHINERY EQUIPMENT"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-3",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-1",
                  modifiers: {
                    "job-3-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" General Overhaul ME (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-2",
                  modifiers: {
                    "job-3-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Kalibrasi injector (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-3",
                  modifiers: {
                    "job-3-3": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Service/ganti baru dynamo starter (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-4",
                  modifiers: {
                    "job-3-4": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Service/ganti baru alternator (P/S)"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 40%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-3-5",
                  modifiers: {
                    "job-3-5": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Service turbocharger"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-3-5",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', {
                staticClass: "pull-right"
              }, [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('br'), _c('br'), _c('b-button', {
                staticClass: "pull-right mr-1",
                attrs: {
                  "type": "submit",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save")])], 1)], 1)], 1)], 1)], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.slideApproveDocking,
            callback: function callback($$v) {
              _vm.slideApproveDocking = $$v;
            },
            expression: "slideApproveDocking"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "activity",
            "title": "UPDATE ACTIVITY",
            "width": "50%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var hide = _ref7.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "power"
                }
              }, [_c('tab-nav', {
                attrs: {
                  "tabs": true
                }
              }, [_c('tab-nav-items', {
                attrs: {
                  "active": true,
                  "href": "#activity",
                  "title": "Activity"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "href": "#logs",
                  "title": "Logs"
                }
              })], 1), _c('div', {
                staticClass: "tab-content",
                attrs: {
                  "id": "myTabContent"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "activity"
                }
              }, [_c('b-row', [_c('b-col', {
                staticClass: "mb-5",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Select Docking Job",
                  "label-for": "docking_job"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.docking_job
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("List Docking Job")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedDockingJob,
                  callback: function callback($$v) {
                    _vm.selectedDockingJob = $$v;
                  },
                  expression: "selectedDockingJob"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label-for": "progress_bar"
                }
              }, [_c('h4', {
                staticClass: "mb-2"
              }, [_vm._v("Progrees : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("80%")])], 1), _c('b-progress', {
                attrs: {
                  "value": 80,
                  "variant": "success",
                  "max": "100"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Progress Pekerjaan *",
                  "label-for": "progress_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "progress_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "progress_pekerjaan",
                  "placeholder": "80"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Report Progress",
                  "label-for": "report_progress"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('div', {
                staticClass: "form-group row align-items-center mt-3 m-0"
              }, [_c('div', {
                staticClass: "d-flex flex-grow-1 align-items-center"
              }, [_c('div', {
                staticClass: "send-panel"
              }, [_c('label', {
                staticClass: "mb-0 iq-bg-primary rounded",
                attrs: {
                  "for": "file"
                }
              }, [_c('input', {
                staticStyle: {
                  "display": "none"
                },
                attrs: {
                  "type": "file",
                  "id": "file"
                }
              }), _c('a', [_c('i', {
                staticClass: "ri-attachment-line"
              })])]), _c('label', {
                staticClass: "ml-2 mb-0 iq-bg-primary rounded"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0)"
                }
              }, [_c('i', {
                staticClass: "ri-gallery-line text-primary"
              })])])])]), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "send-panel float-left"
              }, [_c('div', {
                staticClass: "send-btn"
              }, [_c('b-button', {
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-check"
              }), _vm._v(" Finish")]), _c('b-button', {
                staticClass: "ml-2",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-comment"
              }), _vm._v(" Update Activity")])], 1)])])])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "100%"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/6.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/1.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])], 1)], 1), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "logs"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "100%"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("19 Oktober 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Docking Upslip - Finish")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("System")]), _c('br'), _c('small', [_vm._v("19 Oktober 2019 18:30")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("System mengirim email ke : "), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("aguuungtriilaksono@gmail.com")]), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("nurulakbar93@gmail.com")])], 1)])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("18 Oktober 2019 15:41")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Update Stage")]), _c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("APPROVE")]), _c('i', {
                staticClass: "fa fa-arrow-circle-right",
                attrs: {
                  "aria-hidden": "true"
                }
              }), _c('b-button', {
                staticClass: "ml-1",
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("PROGRESS")])], 1)]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("System")]), _c('br'), _c('small', [_vm._v("19 Oktober 2019 18:30")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("System mengirim email ke : "), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("aguuungtriilaksono@gmail.com")]), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("nurulakbar93@gmail.com")])], 1)])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("18 Oktober 2019 15:41")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Update Stage")]), _c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("REVIEW")]), _c('i', {
                staticClass: "fa fa-arrow-circle-right",
                attrs: {
                  "aria-hidden": "true"
                }
              }), _c('b-button', {
                staticClass: "ml-1",
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("APPROVE")])], 1)]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("System")]), _c('br'), _c('small', [_vm._v("19 Oktober 2019 18:30")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("System mengirim email ke : "), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("aguuungtriilaksono@gmail.com")]), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("nurulakbar93@gmail.com")])], 1)])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("18 Oktober 2019 15:41")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Update Stage")]), _c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("REVIEW")]), _c('i', {
                staticClass: "fa fa-arrow-circle-right",
                attrs: {
                  "aria-hidden": "true"
                }
              }), _c('b-button', {
                staticClass: "ml-1",
                attrs: {
                  "variant": "danger"
                }
              }, [_vm._v("REJECT")])], 1)]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("System")]), _c('br'), _c('small', [_vm._v("17 Oktober 2019 16:00")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("System mengirim email ke : "), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("aguuungtriilaksono@gmail.com")]), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("nurulakbar93@gmail.com")])], 1)])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("16 Oktober 2019 18:30")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Update Stage")]), _c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "light"
                }
              }, [_vm._v("CREATE")]), _c('i', {
                staticClass: "fa fa-arrow-circle-right",
                attrs: {
                  "aria-hidden": "true"
                }
              }), _c('b-button', {
                staticClass: "ml-1",
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("REVIEW")])], 1)]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("15 Oktober 2019 20:24")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Mengupdate Job Upslip")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("15 Oktober 2019 16:00")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Membuat Job Upslip")])])])])])], 1)], 1)], 1)], 1)], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.slideActivity,
            callback: function callback($$v) {
              _vm.slideActivity = $$v;
            },
            expression: "slideActivity"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "finish_docking",
            "title": "FORM FINISH DOCKING - TB. SML 201",
            "width": "60%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var hide = _ref8.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Arrival Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("01-07-2022")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Up Slip")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("06-07-2022")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Down Slip")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "downslip"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Complete Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "delivery_date"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Budget")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 385.000.000,-")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 350.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA BAHARI SHIPYARD")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("NURUL AKBAR")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tonage Docking")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Tons")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "tonage_docking",
                  "placeholder": "5"
                }
              })])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Engine")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start_engine"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Finish Engine")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "finish_engine"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start ME")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start_me"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Finish ME")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "finish_me"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start AE")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start_ae"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Finish AE")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "finish_ae"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Engine Test")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "engine_test"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Ready Sail")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "ready_sail"
                }
              })], 1)])])]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-primary rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-primary"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Job Category")])])])];
                  },
                  proxy: true
                }], null, true)
              })], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-1",
                  modifiers: {
                    "jobCat-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v("DOCKING / UNDOCKING "), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-1",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('b-modal', {
                attrs: {
                  "id": "reason_reject",
                  "size": "md",
                  "title": "Reject - Docking Up Slip",
                  "ok-title": "Save",
                  "cancel-title": "Close"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Reason Reject",
                  "label-for": "reason_reject"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "reason_reject",
                  "rows": "3"
                }
              })], 1), _c('b-button', {
                attrs: {
                  "block": "",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-close mr-2"
              }), _vm._v("Reject Docking Job")])], 1)], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Up Slip "), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Internal Man Power")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-check"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("FINISH")])])])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Down Slip "), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-check"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("FINISH")])])])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-2",
                  modifiers: {
                    "jobCat-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v("SAND BLASTING"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 70%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-2",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('iq-card', {
                attrs: {
                  "class-name": "iq-card-block iq-card-stretch iq-card-height",
                  "body-class": "iq-bg-info rounded"
                },
                scopedSlots: _vm._u([{
                  key: "body",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "d-flex align-items-center justify-content-between"
                    }, [_c('div', {
                      staticClass: "rounded-circle iq-card-icon bg-info"
                    }, [_c('i', {
                      staticClass: "fa fa-percent"
                    })]), _c('div', {
                      staticClass: "text-right"
                    }, [_c('h2', {
                      staticClass: "mb-0"
                    }, [_c('span', {
                      staticClass: "counter"
                    }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
                  },
                  proxy: true
                }], null, true)
              }), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-1",
                  modifiers: {
                    "job-2-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Scraping teritip di lambung dan lunas"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-check"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("FINISH")])])])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-2",
                  modifiers: {
                    "job-2-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Pencucian lambung dengan air tawar"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-check"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("FINISH")])])])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-3",
                  modifiers: {
                    "job-2-3": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Sand blasting lunas"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-check"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("FINISH")])])])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-4",
                  modifiers: {
                    "job-2-4": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Sand blasting lambung"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 40%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-check"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("FINISH")])])])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-5",
                  modifiers: {
                    "job-2-5": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" Sand blasting deck dan bulwark"), _c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-2-5",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Clean motor housing including stator and rotor"), _c('br'), _vm._v(" Check bearing, replace when necessary"), _c('br'), _vm._v(" Check cable installations and connections"), _c('br'), _vm._v(" Check foundation and tighten all bolts ")])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-check"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("FINISH")])])])], 1)], 1)], 1)], 1), _c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', {
                staticClass: "pull-right"
              }, [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('br'), _c('br'), _c('b-button', {
                staticClass: "pull-right",
                attrs: {
                  "type": "submit",
                  "variant": "danger"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" Reset Form")]), _c('b-button', {
                staticClass: "pull-right mr-1",
                attrs: {
                  "type": "submit",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save")])], 1)], 1)], 1)], 1)], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.slideFinishDocking,
            callback: function callback($$v) {
              _vm.slideFinishDocking = $$v;
            },
            expression: "slideFinishDocking"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }