<template>
  <b-container fluid>
    <b-row>
      <b-col lg="4">
        <iq-card class-name="iq-user-profile-block1">
          <template v-slot:body>
            <div class="user-details-block">
              <div class="user-profile text-center">
                <img
                  :src="user?.photo ?? default_photo"
                  alt="profile-img"
                  class="avatar-130 img-fluid"
                />
              </div>
              <div class="text-center mt-3">
                <h4
                  ><b>{{ user?.personal_data?.full_name ?? '-' }}</b></h4
                >
                <p>
                  {{ countAge }} Tahun,
                  {{ user?.personal_data?.place_of_birth ?? '-' }}
                  <br />
                  <b-badge variant="primary"
                    >{{ user?.status_hire ?? '-' }},
                    {{ user?.created_at ?? '-' }}</b-badge
                  >
                </p>
              </div>
            </div>
            <b-alert :show="true" class="text-center text-white bg-primary">
              <div class="iq-alert-text"><strong>DATA DIRI</strong></div>
            </b-alert>
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td><strong>Nomor Pegawai</strong></td>
                  <td>{{ user?.nip ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>Nomor KTP</strong></td>
                  <td>{{ user?.personal_data?.nik_ktp ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>Seaferer Code</strong></td>
                  <td>{{ user?.personal_data?.seaferer_code ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>Nomor NPWP</strong></td>
                  <td>{{ user?.personal_data?.npwp_number ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>Jabatan/Ijazah</strong></td>
                  <td>{{ user?.personal_data?.position?.name ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>Fleet</strong></td>
                  <td>{{ user?.vehicle?.name ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>Kewarganegaraan</strong></td>
                  <td>{{ user?.personal_data?.citizenship ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>Tempat/Tanggal Lahir</strong></td>
                  <td>
                    {{ user?.personal_data?.place_of_birth ?? '-' }} /
                    {{ user?.personal_data?.date_of_birth ?? '-' }}</td
                  >
                </tr>
                <tr>
                  <td><strong>Golongan Darah</strong></td>
                  <td>{{ user?.personal_data?.blood_type?.alias }}</td>
                </tr>
                <tr>
                  <td><strong>Agama</strong></td>
                  <td>{{ user?.personal_data?.religion?.name }}</td>
                </tr>
                <tr>
                  <td><strong>Status</strong></td>
                  <td>{{ user?.personal_data?.marital_status?.name }}</td>
                </tr>
                <tr>
                  <td><strong>Anak Sendiri</strong></td>
                  <td>{{ user?.personal_data?.number_of_children ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>Nomor Tlp./HP</strong></td>
                  <td>{{ user?.personal_data?.hp_number ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>Email</strong></td>
                  <td>{{ user?.personal_data?.email ?? '-' }}</td>
                </tr>
                <tr>
                  <td colspan="2"><strong>Alamat KTP</strong></td>
                </tr>
                <tr>
                  <td colspan="2">
                    {{ user?.personal_data?.address_of_ktp ?? '-' }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2"><strong>Alamat Domisili</strong></td>
                </tr>
                <tr>
                  <td colspan="2">
                    {{ user?.personal_data?.address_of_domisili ?? '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>
        <iq-card>
          <template v-slot:body>
            <table class="table table-striped">
              <tbody>
                <tr class="bg-info">
                  <td colspan="2" class="text-center"
                    ><strong>JOIN DATE</strong></td
                  >
                </tr>
                <tr>
                  <td><strong>Sign On</strong></td>
                  <td>01 January 2020</td>
                </tr>
                <tr>
                  <td><strong>Sign Off</strong></td>
                  <td>01 January 2025</td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>
        <iq-card>
          <template v-slot:body>
            <table class="table table-striped">
              <tbody>
                <tr class="bg-info">
                  <td colspan="2" class="text-center"
                    ><strong>PAKAIAN & SEPATU</strong></td
                  >
                </tr>
                <tr>
                  <td><strong>Tinggi Badan</strong></td>
                  <td>N/A CM</td>
                </tr>
                <tr>
                  <td><strong>Berat Badan</strong></td>
                  <td>{{ user?.personal_data?.weight ?? '-' }} KG</td>
                </tr>
                <tr>
                  <td><strong>Wearpack</strong></td>
                  <td>{{ user?.personal_data?.wearpack ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>Sepatu</strong></td>
                  <td>{{ user?.personal_data?.size_of_shoes ?? '-' }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>
        <iq-card>
          <template v-slot:body>
            <table class="table table-striped">
              <tbody>
                <tr class="bg-info">
                  <td colspan="2" class="text-center"
                    ><strong>EMERGENCY CALL</strong></td
                  >
                </tr>
                <tr>
                  <td><strong>Nama Lengkap</strong></td>
                  <td>{{
                    user?.personal_data?.family_emergency_name ?? '-'
                  }}</td>
                </tr>
                <tr>
                  <td><strong>No. Tlp/HP</strong></td>
                  <td>{{ user?.personal_data?.emergency_number ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>Hubungan</strong></td>
                  <td>{{
                    user?.personal_data?.family_emergency_status ?? '-'
                  }}</td>
                </tr>
                <tr>
                  <td><strong>alamat</strong></td>
                  <td colspan="2">
                    {{ user?.personal_data?.family_emergency_address ?? '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:body>
            <table class="table table-striped">
              <tbody>
                <tr class="bg-info">
                  <td colspan="2" class="text-center"
                    ><strong>VERIFICATION DOCUMENT</strong></td
                  >
                </tr>
                <tr>
                  <td width="90%"><strong>CURRICULUM VITAE</strong></td>
                  <td width="10%"
                    ><i class="fa fa-check mr-3 text-primary"></i
                  ></td>
                </tr>
                <tr>
                  <td width="90%"
                    ><strong>CERTIFICATE OF COMPETENCY</strong></td
                  >
                  <td width="10%"
                    ><i class="fa fa-check mr-3 text-primary"></i
                  ></td>
                </tr>
                <tr>
                  <td width="90%"
                    ><strong
                      >CERTIFICATE OF PROFICIENCY (BST, PSCRB, AFF, MFA,
                      ETC)</strong
                    ></td
                  >
                  <td width="10%"
                    ><i class="fa fa-check mr-3 text-primary"></i
                  ></td>
                </tr>
                <tr>
                  <td width="90%"><strong>ORU / GOC (IF ANY)</strong></td>
                  <td width="10%"
                    ><i class="fa fa-check mr-3 text-primary"></i
                  ></td>
                </tr>
                <tr>
                  <td width="90%"><strong>SEAMAN BOOK</strong></td>
                  <td width="10%"
                    ><i class="fa fa-check mr-3 text-primary"></i
                  ></td>
                </tr>
                <tr>
                  <td width="90%"><strong>PASSPORT</strong></td>
                  <td width="10%"
                    ><i class="fa fa-check mr-3 text-primary"></i
                  ></td>
                </tr>
                <tr>
                  <td width="90%"><strong>MCU</strong></td>
                  <td width="10%"
                    ><i class="fa fa-check mr-3 text-primary"></i
                  ></td>
                </tr>
                <tr>
                  <td width="90%"><strong>KTP</strong></td>
                  <td width="10%"
                    ><i class="fa fa-check mr-3 text-primary"></i
                  ></td>
                </tr>
                <tr>
                  <td width="90%"><strong>KARTU KELUARGA, NPWP</strong></td>
                  <td width="10%"
                    ><i class="fa fa-check mr-3 text-primary"></i
                  ></td>
                </tr>
                <tr>
                  <td width="90%"><strong>PHOTOGRAPH</strong></td>
                  <td width="10%"
                    ><i class="fa fa-check mr-3 text-primary"></i
                  ></td>
                </tr>
                <tr>
                  <td width="90%"><strong>FORM INTERVIEW</strong></td>
                  <td width="10%"
                    ><i class="fa fa-check mr-3 text-primary"></i
                  ></td>
                </tr>
                <tr>
                  <td width="90%"><strong>FORM INDUCTION</strong></td>
                  <td width="10%"
                    ><i class="fa fa-check mr-3 text-primary"></i
                  ></td>
                </tr>
                <tr>
                  <td width="90%"
                    ><strong
                      >COPY PKL, PAKTA INTEGRITAS, SURAT MUTASI ON</strong
                    ></td
                  >
                  <td width="10%"
                    ><i class="fa fa-check mr-3 text-primary"></i
                  ></td>
                </tr>
                <tr>
                  <td width="90%"><strong>BUKU REKENING</strong></td>
                  <td width="10%"
                    ><i class="fa fa-check mr-3 text-primary"></i
                  ></td>
                </tr>
                <tr>
                  <td width="90%"
                    ><strong>EMBARKING TICKET (IF ANY)</strong></td
                  >
                  <td width="10%"
                    ><i class="fa fa-check mr-3 text-primary"></i
                  ></td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="8">
        <iq-card v-for="(item, index) in charts" :key="index">
          <template v-slot:headerTitle>
            <h4>{{ item.title }}</h4>
          </template>
          <template v-slot:body>
            <ApexChart :element="item.type" :chartOption="item.bodyData" />
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">PENDIDIKAN</h4>
          </template>
          <template v-slot:body>
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th class="align-middle text-center">Sekolah/Universitas</th>
                  <th class="align-middle text-center">Jenjang</th>
                  <th class="align-middle text-center">Jurusan</th>
                  <th class="align-middle text-center">IPK/Nilai Akhir</th>
                  <th class="align-middle text-center">Tahun Mulai</th>
                  <th class="align-middle text-center">Tahun Selesai</th>
                  <th class="align-middle text-center">Kota/Negara</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(state, index) in user?.education"
                  v-bind:key="index"
                >
                  <td>{{ state?.sekolah ?? '-' }}</td>
                  <td class="text-center">{{ state?.jenjang ?? '-' }}</td>
                  <td class="text-center">{{ state?.jurusan ?? '-' }}</td>
                  <td class="text-center">{{ state?.nilai ?? '-' }}</td>
                  <td class="text-center">{{ state?.tahun_mulai ?? '-' }}</td>
                  <td class="text-center">{{ state?.tahun_selesai ?? '-' }}</td>
                  <td class="text-center"
                    >{{ state?.kota }} / {{ state?.negara }}</td
                  >
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">DATA KELUARGA</h4>
          </template>
          <template v-slot:body>
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th class="text-center">No</th>
                  <th class="text-center">Nama Lengkap</th>
                  <th class="text-center">Hubungan</th>
                  <th class="text-center">Jenis Kelamin</th>
                  <th class="text-center">Umur</th>
                  <th class="text-center">Pekerjaan</th>
                  <th class="text-center">No.Hp</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(state, index) in user?.family" v-bind:key="index">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center">{{ state.nama_lengkap }}</td>
                  <td class="text-center">{{ state.status }}</td>
                  <td class="text-center">{{ state.jenis_kelamin }}</td>
                  <td class="text-center">{{ state.age_family }}</td>
                  <td class="text-center">{{ state.pekerjaan }}</td>
                  <td class="text-center">{{ state.no_hp }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">TRAVEL DOCUMENT</h4>
          </template>
          <template v-slot:body>
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th class="text-center">No</th>
                  <th class="text-center">Nama Document</th>
                  <th class="text-center">No Dokument</th>
                  <th class="text-center">Place & Date Issued</th>
                  <th class="text-center">Masa Berlaku</th>
                  <th class="text-center">File</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(state, index) in user?.travel_document"
                  v-bind:key="index"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center">{{ state.document_name }}</td>
                  <td class="text-center">{{ state.document_number }}</td>
                  <td class="text-center">{{
                    state.date_issued + ', ' + state.place_issued
                  }}</td>
                  <td class="text-center">{{ state.validity_period }}</td>
                  <td class="text-center" v-if="state.file"
                    ><i class="fa fa-file-alt text-primary"></i
                  ></td>
                  <td class="text-center" v-else>{{ '-' }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">CERTIFICATE OF COMPETENCY</h4>
          </template>
          <template v-slot:body>
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th class="text-center">No</th>
                  <th class="text-center">Ijazah & Endorse</th>
                  <th class="text-center">Nomor Sertifikat</th>
                  <th class="text-center">Place & Date Issued</th>
                  <th class="text-center">Masa Berlaku</th>
                  <th class="text-center">File</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(state, index) in user?.certificate_of_competency"
                  v-bind:key="index"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center">{{ state.document_name }}</td>
                  <td class="text-center">{{ state.document_number }}</td>
                  <td class="text-center">{{
                    state.date_issued + ', ' + state.place_issued
                  }}</td>
                  <td class="text-center">{{ state.validity_period }}</td>
                  <td class="text-center" v-if="state.file"
                    ><i class="fa fa-file-alt text-primary"></i
                  ></td>
                  <td class="text-center" v-else>{{ '-' }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">CERTIFICATE OF PROFICIENCY</h4>
          </template>
          <template v-slot:body>
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th class="text-center">No</th>
                  <th class="text-center">Nama Sertifikat</th>
                  <th class="text-center">Nomor Sertifikat</th>
                  <th class="text-center">Place & Date Issued</th>
                  <th class="text-center">Masa Berlaku</th>
                  <th class="text-center">File</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(state, index) in user?.certificate_of_proficiency"
                  v-bind:key="index"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center">{{ state.document_name }}</td>
                  <td class="text-center">{{ state.document_number }}</td>
                  <td class="text-center">{{
                    state.date_issued + ', ' + state.place_issued
                  }}</td>
                  <td class="text-center">{{ state.validity_period }}</td>
                  <td class="text-center" v-if="state.file"
                    ><i class="fa fa-file-alt text-primary"></i
                  ></td>
                  <td class="text-center" v-else>{{ '-' }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"
              >PENGALAMAN BERLAYAR, MINIMAL 5 PENGALAMAN TERAKHIR</h4
            >
          </template>
          <template v-slot:body>
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th class="text-center align-middle" rowspan="2">No</th>
                  <th class="text-center align-middle" rowspan="2"
                    >Kapal / Pemilik</th
                  >
                  <th class="text-center align-middle" rowspan="2"
                    >Bendera / Kawasan</th
                  >
                  <th class="text-center align-middle" rowspan="2">Jabatan</th>
                  <th class="text-center align-middle" rowspan="2">Telp./HP</th>
                  <th class="text-center align-middle" colspan="2"
                    >Periode Of Service</th
                  >
                </tr>
                <tr>
                  <th class="text-center align-middle">Dari</th>
                  <th class="text-center align-middle">Sampai</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(state, index) in user?.sail_experience"
                  v-bind:key="index"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center"
                    >{{ state?.ship_name ?? '-' }}<br /><small
                      style="color: gray"
                      >{{ state?.owner ?? '-' }}</small
                    ></td
                  >
                  <td class="text-center"
                    >{{ state?.flag ?? '-' }}<br /><small style="color: gray">{{
                      state?.area ?? '-'
                    }}</small></td
                  >
                  <td class="text-center">{{ state?.position_id ?? '-' }}</td>
                  <td class="text-center">{{
                    state?.no_number_company ?? '-'
                  }}</td>
                  <td class="text-center">{{ state?.date_join ?? '-' }}</td>
                  <td class="text-center">{{ state?.date_end ?? '-' }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">ORGANISASI</h4>
          </template>
          <template v-slot:body>
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th class="text-center">Nama Organisasi</th>
                  <th class="text-center">Jabatan</th>
                  <th class="text-center">Tanggal Bergabung</th>
                  <th class="text-center">Tanggal Selesai</th>
                  <th class="text-center">Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(state, index) in user?.organization"
                  v-bind:key="index"
                >
                  <td>{{ state?.nama_organisasi }}</td>
                  <td class="text-center"
                    >{{ state?.posisi }} <br /><b-badge variant="primary">{{
                      state?.tempat
                    }}</b-badge></td
                  >
                  <td class="text-center">{{ state?.durasi_awal }}</td>
                  <td class="text-center">{{ state?.durasi_akhir }}</td>
                  <td class="text-center">-</td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { crewingActions } from '@src/Utils/helper'
import moment from 'moment'

export default {
  name: 'ProfileCrew',
  async mounted() {
    xray.index()
    await this.getProfileCrew()
  },
  data() {
    return {
      charts: [
        {
          title: 'EVALUASI KONDITE',
          type: 'bar',
          bodyData: {
            chart: {
              height: 420,
              type: 'bar',
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            series: [
              {
                data: [4, 3, 5, 4, 2, 4, 5, 5, 3, 5, 2, 5, 4, 2, 4],
              },
            ],
            xaxis: {
              categories: [
                'Kebijakan Perusahaan',
                'Keahlian',
                'Inisiatif',
                'Sikap',
                'Komunikasi',
                'Motivator',
                'Harmonisasi',
                'Impelementasi ISM Code',
                'Laporan ISM Code',
                'Latihan Keselamatan',
                'Pengoperasian Kapal',
                'Pemeliharaan Kapal',
                'Pemeliharaan Perlengkapan Kapal',
                'Pemeliharaan Perlengkapan Keselamatan Kapal',
                'Kejadian & Kecelakaan Kapal / Kecelakaan Kerja',
              ],
            },
            yaxis: {
              labels: {
                maxWidth: 350,
              },
            },
          },
        },
        {
          title: 'LAPORAN KONDITE CREW',
          type: 'bar',
          bodyData: {
            chart: {
              height: 350,
              type: 'bar',
            },
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
            dataLabels: {
              enabled: false,
            },
            series: [
              {
                data: [4, 5, 3, 2, 4, 5, 3, 4, 3, 5, 3],
              },
            ],
            xaxis: {
              categories: [
                'Pengetahuan Dalam Bekerja',
                'Kerja Sama',
                'Kesungguhan Dalam Bekerja',
                'Kedisiplinan',
                'Sikap Terhadap Kebijakan Perusahaan',
                'Kesetiaan (Loyalitas)',
                'Tanggung Jawab',
                'Inisiatif',
                'Ketepatan Waktu',
                'Kesehatan',
                'Kepemimpinan',
              ],
            },
            yaxis: {
              labels: {
                maxWidth: 350,
              },
            },
          },
        },
      ],
      user: null,
      default_photo: require('../../assets/images/user/11.png'),
    }
  },
  computed: {
    countAge() {
      let userAge = moment().diff(
        new Date(this?.user?.personal_data?.date_of_birth),
        'years'
      )
      return userAge
    },
  },
  methods: {
    ...crewingActions,
    async getProfileCrew() {
      if (this.$route.params.id) {
        let method = 'getCrewDetail'

        const { status, data } = await this[method](this.$route.params.id)

        if (status !== 'success') {
          this.$swal(`Oops!`, `Crew tidak ada.`, 'error')
          return
        }

        this.user = data?.[0]
        console.log(this.user, 'THIS IS DETAIL CREW PROFILE')
      }
    },
  },
}
</script>
