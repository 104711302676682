var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Filter Goods Stock Movement")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Selecte Date",
            "label-for": "serial"
          }
        }, [_c('date-picker', {
          attrs: {
            "type": "date",
            "range": "",
            "placeholder": "Select Date",
            "format": "DD-MM-YYYY"
          },
          model: {
            value: _vm.historiesParamsDate,
            callback: function callback($$v) {
              _vm.historiesParamsDate = $$v;
            },
            expression: "historiesParamsDate"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet / Heavy Equipment",
            "label-for": "serial"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Search or add a fleet",
            "label": "name",
            "track-by": "code",
            "options": _vm.fleetEquipments,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.fleetSelected,
            callback: function callback($$v) {
              _vm.fleetSelected = $$v;
            },
            expression: "fleetSelected"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Code / Name Goods",
            "label-for": "serial"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Code / Name Goods..."
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "Status"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.statusGoods,
            "reduce": function reduce(type) {
              return type.value;
            },
            "placeholder": "select category"
          },
          model: {
            value: _vm.statusSelected,
            callback: function callback($$v) {
              _vm.statusSelected = $$v;
            },
            expression: "statusSelected"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-button', {
          staticClass: "mt-4",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Data Stock Movement")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "3%"
          }
        }, [_vm._v("No")]), _c('th', [_vm._v("Fleet")]), _c('th', [_vm._v("Date")]), _c('th', [_vm._v("Images")]), _c('th', [_vm._v("Category")]), _c('th', [_vm._v("Goods (Code-Name)")]), _c('th', [_vm._v("Opening Qty")]), _c('th', [_vm._v("Qty In")]), _c('th', [_vm._v("Qty Out")]), _c('th', [_vm._v("Closing Qty")]), _c('th', [_vm._v("Status")])])])])])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }