var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('img', {
          staticClass: "img-fluid w-20",
          attrs: {
            "src": _vm.image,
            "alt": "logo"
          }
        })]), _c('b-col', {
          attrs: {
            "cols": "6",
            "align-self": "center"
          }
        }, [_c('h4', {
          staticClass: "mb-0 float-right"
        }, [_vm._v(_vm._s(_vm.title))])]), _c('b-col', {
          attrs: {
            "sm": "12"
          }
        }, [_c('hr', {
          staticClass: "mt-3"
        }), _c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(_vm.invoice.name))]), _c('p', [_vm._v(_vm._s(_vm.invoice.description))])])], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('div', {
          staticClass: "table-responsive-sm"
        }, [_c('b-table', {
          attrs: {
            "items": _vm.invoice.order
          },
          scopedSlots: _vm._u([{
            key: "cell(orderStatus)",
            fn: function fn(data) {
              return [_c('span', {
                staticClass: "badge badge-danger"
              }, [_vm._v("Unpaid")])];
            }
          }, {
            key: "cell(billingAddress)",
            fn: function fn(data) {
              return [_c('p', {
                domProps: {
                  "innerHTML": _vm._s(data.value)
                }
              })];
            }
          }, {
            key: "cell(shippingAddress)",
            fn: function fn(data) {
              return [_c('p', {
                domProps: {
                  "innerHTML": _vm._s(data.value)
                }
              })];
            }
          }])
        })], 1)])], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('h5', [_vm._v(_vm._s(_vm.invoice.summary))]), _c('div', {
          staticClass: "table-responsive-sm"
        }, [_c('b-table-simple', {
          staticClass: "text-center",
          attrs: {
            "striped": ""
          }
        }, [_c('thead', [_c('tr', _vm._l(_vm.invoice.orderSummaryFields, function (item, index) {
          return _c('th', {
            key: index,
            class: item.key === 'item' ? 'text-left' : ''
          }, [_vm._v(_vm._s(item.label))]);
        }), 0)]), _c('tbody', _vm._l(_vm.invoice.orderSummary, function (body, bodyKey) {
          return _c('tr', {
            key: bodyKey
          }, [_vm._l(_vm.invoice.orderSummaryFields, function (item, index) {
            return [item.key === 'id' ? _c('th', {
              key: item.key + index
            }, [_vm._v(_vm._s(body[item.key]))]) : item.key === 'item' ? _c('td', {
              key: item.key + index,
              staticClass: "text-left"
            }, [_c('h6', {
              staticClass: "mb-0"
            }, [_vm._v(_vm._s(body[item.key].title))]), _c('p', {
              staticClass: "mb-0"
            }, [_vm._v(_vm._s(body[item.key].description))])]) : _c('td', {
              key: item.key + index,
              class: item.key === 'total' ? 'font-weight-bold' : ''
            }, [_vm._v(" " + _vm._s(body[item.key]) + " ")])];
          })], 2);
        }), 0)])], 1), _c('h5', [_vm._v(_vm._s(_vm.invoice.detail))]), _c('div', {
          staticClass: "table-responsive-sm"
        }, [_c('b-table-simple', {
          attrs: {
            "striped": ""
          }
        }, [_c('thead', [_c('tr', _vm._l(_vm.invoice.OrderDetailField, function (item, index) {
          return _c('th', {
            key: index,
            class: item.key === 'bank' ? 'text-left' : ''
          }, [_vm._v(_vm._s(item.label))]);
        }), 0)]), _c('tbody', _vm._l(_vm.invoice.OrderDetails, function (body, bodyKey) {
          return _c('tr', {
            key: bodyKey
          }, [_vm._l(_vm.invoice.OrderDetailField, function (item, index) {
            return [item.key === 'bank' ? _c('th', {
              key: item.key + index
            }, [_vm._v(_vm._s(body[item.key]))]) : _c('td', {
              key: item.key + index,
              class: item.key === 'total' ? 'font-weight-bold' : ''
            }, [_vm._v(" " + _vm._s(body[item.key]) + " ")])];
          })], 2);
        }), 0)])], 1)]), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "offset": "6",
            "cols": "6"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "link mr-3"
          }
        }, [_c('i', {
          staticClass: "ri-printer-line"
        }), _vm._v(" Download Print ")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v(" Submit ")])], 1), _c('b-col', {
          staticClass: "mt-3"
        }, [_c('b', {
          staticClass: "text-danger"
        }, [_vm._v("Note")]), _c('p', [_vm._v(_vm._s(_vm.invoice.note))])])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }