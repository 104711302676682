<template>
    <b-container fluid>
      <b-row>
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">
                <strong>SERVICE & REPAIR DASHBOARD</strong>
              </h4>
            </template>
            <template v-slot:headerAction>
              <a class="text-primary float-right" v-b-toggle.collapse-filter role="button" style="cursor: pointer">
                <i class="fa fa-filter" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse visible id="collapse-filter" class="mb-2">
                <b-row>
                  <b-col md="3">
                    <v-multiselect v-model="params.fleetCategory" tag-placeholder="Category" placeholder="Fleet Category" label="text" track-by="value" :options="optionsVehicleType" :multiple="true" :taggable="true"></v-multiselect>
                  </b-col>
                  <b-col md="2">
                    <v-select v-model="params.fleet" placeholder="Fleets" label="text" track-by="value" :options="optionsFleets">
                    </v-select>
                  </b-col>
                  <b-col md="2">
                    <b-form-select plain v-model="params.status"  size="lg">
                      <template v-slot:first>
                        <b-form-select-option :value="''">All</b-form-select-option>
                        <b-form-select-option :value="'new'">New</b-form-select-option>
                        <b-form-select-option :value="'review'">Review</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-col>
                  <b-col md="3">
                    <date-picker v-model="params.dateRange" type="date" range placeholder="Select date range"></date-picker>
                  </b-col>
                  <b-col md="2">
                      <b-button
                        @click="onFilter"
                        variant="primary"
                        class="ml-5"
                        ><i class="fa fa-filter"></i
                      ></b-button>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="12">
          <iq-card body-class="p-0">
            <template v-slot:body>
              <div class="iq-edit-list">
                <tab-nav :pills="true" class="iq-edit-profile d-flex">
                  <tab-nav-items class="col-md-4 p-0" :active="true" href="#request" title="REQUEST & PROGRESS" />
                  <tab-nav-items class="col-md-4 p-0" :active="false" href="#finish" title="FINISH REPAIR & MAINTENANCE" />
                  <tab-nav-items class="col-md-4 p-0" :active="false" href="#report" title="REPORT REPAIR & MAINTENANCE" />
                </tab-nav>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="12">
          <div class="iq-edit-list-data">
            <div id="pills-tabContent-2" class="tab-content">
              <tab-content-item :active="true" id="request">
                 <TabRequestAndProgress :readOnly="false" :query="params" :filtering="filtering" />
              </tab-content-item>
              <tab-content-item :active="false" id="finish">
                  <TabFinish  :query="params" :filtering="filtering" />
              </tab-content-item>
              <tab-content-item :active="false" id="report">
                  <TabReport :query="params" :filtering="filtering" />
              </tab-content-item>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </template>
  <script>
    import { xray } from '../../config/pluginInit'
    import _ from 'lodash'
    import {
      fleetsActions,
      crewingActions,
      srActions
    } from '@src/Utils/helper'
    import { createPopper } from '@popperjs/core'
    import moment from 'moment'
    import optionsData from '@src/core/mock/data'
    import TabRequestAndProgress from './TabRequestAndProgress.vue'
    import TabFinish from './TabFinish.vue'
    import TabReport from './TabReport.vue'

    export default {
      name: 'ServiceRepairDashboard',
      data() {
        return {
          // fleet
          ...optionsData,
          filtering: false,
          optionsVehicleType:[],
          optionsFleets:[],
          params:{
            fleetCategory: [],
            fleet:null,
            dateRange: null,
            status:''
          },
          fleet_params: {
            page: 1,
            showAll: true,
            search: ''
          },
        }
      },
      components:{
        TabRequestAndProgress,
        TabFinish,
        TabReport
      },
      watch: {
        select_all_fleets: {
          deep: true,
          handler: function(checked) {
            this.checkedFleets = null
            this.fleetNameChecked = null
            if (checked) {
              this.fleets.map((f) => this.checkedFleets = f.id)
            }
          }
        },
        'checkedFleets': {
          handler(value) {
            if (value) {
              let nameFleet = this.fleets.find((f) => this.checkedFleets === f.id)
              this.fleetNameChecked = nameFleet.name
            }
          },
        },
        'activity_form.form_repair_id': {
          async handler() {
            this.activity_repair_data = null
            this.activity_repair_loading = true

            await this.getSingleRepairData()

            this.activity_repair_loading = false
          },
          deep: true,
        },
      },
      computed: {
        defaultRange() {
          const currentDate = new Date() ;
          const nextDay = new Date(currentDate);
          nextDay.setDate(currentDate.getDate() + 1);
          // Get the last date of the current month
          let lastDateOfMonth = moment().endOf('month');
          lastDateOfMonth = new Date(lastDateOfMonth)
          // Format it to show only the date
          const firstMonthOfTheYear = new Date(
            currentDate.getFullYear(),
            0,
            1
          );
          return [firstMonthOfTheYear, lastDateOfMonth];
        },
      },
      created() {
        this.fetchFleets = _.debounce(this.fetchFleets, 1000)
      },
      async mounted() {
        xray.index()
        this.params.dateRange = this.defaultRange;

        await this.fetchFleets()
        await this.getListVehicleType()

        this.onFilter()
      },
      methods: {
        ...fleetsActions,
        ...srActions,
        ...crewingActions,
        onFilter(){
          this.filtering = !this.filtering
        },

        async fetchFleets() {
          this.loading_fleet = true
          let params = this.$options.filters.cleanObject(this.fleet_params)
          let res = await this.getFleets(params)
          if (!res.status) {
            this.loading_fleet = false
            this.fleets = []
            this.optionsFleets = []
          } else {
            this.loading_fleet = false
            this.fleets = res.data
            res.data.map(val => {
              this.optionsFleets.push({
                text:val.name,
                value:val.id,
              })
            })
          }
        },

        async getListVehicleType() {
          let res = await this.fetchListVehicleType()
          if (res.status === 'error') {
            this.optionsVehicleType = []
          } else {
            res.data.data.map(val => {
                this.optionsVehicleType.push({
                    text:val.name,
                    value:val.id
                })
            })
          }
        },
      }
    }
  </script>