import axios from './auth'

const ROB_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/rob`;

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getRobTypes({ commit }, payload) {
    const url = `${ROB_BASE_URL}/types`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params: payload.params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getRobSeries({ commit }, payload) {
    const url = `${ROB_BASE_URL}/series`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params: payload.params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getRobTypeById({ commit }, id) {
    const url = `${ROB_BASE_URL}/types/${id}`;

    const method = "get";
    const headers = {};
    const config = {
        url,
        method,
        headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getRobSeriesById({ commit }, id) {
    const url = `${ROB_BASE_URL}/series/${id}`;

    const method = "get";
    const headers = {};
    const config = {
        url,
        method,
        headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async saveRobType({ commit }, payload) {
    const url = `${ROB_BASE_URL}/types`;

    const method = "post";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async saveRobSeries({ commit }, payload) {
    const url = `${ROB_BASE_URL}/series`;

    const method = "post";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
async deleteRobTypeById({ commit }, id) {
  const url = `${ROB_BASE_URL}/types/${id}`;

  const method = "delete";
  const headers = {};
  const config = {
      url,
      method,
      headers
  };

  try {
      await axios(config);
      return { status: 'success' };
  } catch (e) {
      return {
          status: 'error',
          data: e.response.data
      };
  }
},
async deleteRobSeriesById({ commit }, id) {
  const url = `${ROB_BASE_URL}/series/${id}`;

  const method = "delete";
  const headers = {};
  const config = {
      url,
      method,
      headers
  };

  try {
      await axios(config);
      return { status: 'success' };
  } catch (e) {
      return {
          status: 'error',
          data: e.response.data
      };
  }
},
async updateRobType({ commit }, payload) {
  const url = `${ROB_BASE_URL}/types/${payload.id}`;

  const method = "put";
  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
  };
  const config = {
    url,
    method,
    data: payload.data,
    headers
  };

  try {
    const response = await axios(config);
    let data = response.data.data;
    return {
      status: 'success',
      data,
    };
  } catch (e) {
    return {
      status: 'error',
      data: e.response.data
    };
  }
},
async updateRobSeries({ commit }, payload) {
  const url = `${ROB_BASE_URL}/series/${payload.id}`;

  const method = "put";
  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
  };
  const config = {
    url,
    method,
    data: payload.data,
    headers
  };

  try {
    const response = await axios(config);
    let data = response.data.data;
    return {
      status: 'success',
      data,
    };
  } catch (e) {
    return {
      status: 'error',
      data: e.response.data
    };
  }
},

}
