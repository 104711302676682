<template>
  <b-container fluid>
    <!-- Floating -->
    <div class="fab-container">
      <div class="fab shadow" v-if="['SUPER ADMIN', 'PORT CAPTAIN', 'MANAGEMENT', 'RADIO CONTROL ROOM'].includes(role)" @click="dashboard = 'operational'">
        <div class="fab-content bg-blue">
          <span>
            <i class="ri ri-safari-fill mr-2"></i>
          </span>
          <span style="font-family:Arial, Helvetica, sans-serif"> Operational</span>
        </div>
      </div>
<!--      <div class="fab shadow" v-if="['SUPER ADMIN', 'MANAGEMENT', 'MARINE INSURANCE'].includes(role)" @click="dashboard = 'mi'">
        <div class="fab-content bg-warning">
          <span>
            <i class="ri ri-ship-2-line mr-2"></i>
          </span>
          <span style="font-family:Arial, Helvetica, sans-serif"> Marine Insurance</span>
        </div>
      </div>
      <div class="fab shadow" v-if="['SUPER ADMIN', 'MANAGEMENT', 'MARINE INSURANCE'].includes(role)" @click="dashboard = 'mi_list_unsettled'">
        <div class="fab-content bg-dark">
          <span>
            <i class="ri ri-ship-fill mr-2"></i>
          </span>
          <span style="font-family:Arial, Helvetica, sans-serif"> Vessel Case</span>
        </div>
      </div>-->
    </div>
    <!-- End Floating -->
    <!-- Maps -->
    <b-row v-if="layout === 'maps'">
      <!-- Add Fleet -->
      <a id="add-vehicle" v-b-modal.add-fleet href="#" class="button-float">
        <i class="fa fa-plus my-button-float"></i>
        <i class="fa fa-plus fa fa-ship my-button-float"></i>
      </a>
      <b-modal id="add-fleet" size="xl" :no-close-on-backdrop="true" :header-bg-variant="modal.headerBgVariant" :header-text-variant="modal.headerTextVariant" :body-bg-variant="modal.bodyBgVariant" :body-text-variant="modal.bodyTextVariant" hide-footer>
        <template v-slot:modal-header="{ close }">
          <h5>Add More Your Fleet</h5>
          <!-- Emulate built in modal header close button action -->
          <b-button size="md" variant="outline-danger" @click="close">
            <i class="fa fa-times"></i> Close
          </b-button>
        </template>
        <AddFleet v-bind:isAddForChild="true" @childAction="$bvModal.hide('add-fleet');" />
      </b-modal>
      <b-tooltip target="add-vehicle">Add More Your Fleet</b-tooltip>
      <!-- End Add Fleet -->
      <b-col sm="12">
        <div style="
            height: 100%;
            width: 100%;
            position: fixed;
            margin-left: -30px;
            margin-top: -120px;
          ">
          <l-map :zoom="zoom" :center="center" :options="mapOptions" style="width: 100%; height: 100%" @update:center="centerUpdate" @update:zoom="zoomUpdate">
            <l-tile-layer :url="url" :attribution="attribution" />
            <l-control-zoom position="bottomleft"></l-control-zoom>
            <template v-for="(f, i) in fleetActivities">
              <template v-if="f.latitude && f.longitude">
                <l-marker :lat-lng="[f.latitude, f.longitude]" :key="`fleet-${i}`">
                  <l-icon :icon-size="iconSize" :icon-anchor="iconAnchor">
                    <img :src="getIcon(f.vehicleType.icon)" style="width: 72px">
                  </l-icon>
                  <l-popup :options="{maxWidth: 'auto'}" :lat-lng="[f.latitude, f.longitude]">
                    <div style="width: 400px">
                      <router-link to="/ops/operational">
                        <h4>{{ f.name }}</h4>
                      </router-link>
                      <div class="mb-5 position-absolute" style="right:20px;top:10px">
                        <b-dropdown size="sm" text="Small" variant="light" right>
                          <template slot="button-content">
                            <i class="fa fa-ellipsis-h"></i>
                          </template>
                          <b-dropdown-item variant="primary" @click="fetchFleetDetail(f.id)">
                            <i class="fa fa-edit"></i> Edit
                          </b-dropdown-item>
                          <b-dropdown-item variant="danger" @click="onDeleteFleet(f)">
                            <i class="fa fa-trash"></i> Delete
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                      <b-row>
                        <b-col md="12">
                          <b-card-text>
                            <b-row class="text-center">
                              <template v-for="(m, im) in f.modules">
                                <b-col cols="3" md="3" class="mb-3" :key="`m-${im}`" v-if="m.hasAccess  && (validateTechnicalPermission(m.url) || validateOperationalPermission(m.url) || validateReportPermission(m.url))">
                                  <router-link :to="{ path: m.url, query: {vehicleId: f.id } }">
                                    <img v-if="
                                  m.icon
                                  && m.icon.url
                                " :src="m.icon.url" alt="Responsive image" v-b-tooltip.top="m.name">
                                    <img v-else src="@assets/images/flaticon/operational.png" alt="Responsive image" v-b-tooltip.top="'Operational'">
                                  </router-link>
                                </b-col>
                              </template>
                              <!-- <b-col md="12"><router-link to="/ops/docking"><b-button block variant="warning" class="mb-3"
                                    ><i class="fa fa-ship"></i>Docking Intermediate
                                    Survey</b-button
                                  ></router-link></b-col> -->
                            </b-row>
                          </b-card-text>
                        </b-col>
                      </b-row>
                    </div>
                  </l-popup>
                  <l-tooltip :options="{ permanent: false, interactive: true }">{{ f.name }}</l-tooltip>
                </l-marker>
              </template>
            </template>
          </l-map>
        </div>
      </b-col>
    </b-row>
    <!-- FILTERS -->
    <b-row class="mb-3" style="z-index: 999 !important">
      <!-- DISPLAY -->
      <b-col md="9">
        <div v-if="layout === 'maps'" class="justify-content-end text-left">
          <b-row>
            <b-col md="12">
              <div class="btn-group">
                <b-button v-b-toggle.collapse-filter href="#collapse-filter" @click.prevent variant="light">
                  <i class="fa fa-filter" /> Filter
                </b-button>
                <b-button v-b-toggle.upcoming-job href="#upcoming-job" @click.prevent variant="dark">
                  <i class="fa fa-info" /> Jobs Maintenance Information <b-badge variant="warning">10</b-badge>
                </b-button>
              </div>>
            </b-col>
            <b-col md="12">
              <b-collapse id="collapse-filter" class="mt-2">
                <b-card>
                  <b-row>
                    <b-col md="4">
                      <b-form-group label="Category:" label-for="kind-fleet">
                        <template v-for="(item,index) in vehicleTypes">
                          <b-form-checkbox v-model="item.checked" name="check-button" switch inline :key="index">
                            {{ item.name }}
                          </b-form-checkbox>
                        </template>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Cari">
                        <b-form-input type="text" v-model="params.search" size="xs" />
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label-for="dateIssued" label="Category Ownership">
                        <b-form-select plain :options="categoryOwnership" id="gender" v-model="params.ownership"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="1">
                      <b-form-group label="Filter" label-for="button_search">
                        <b-button @click="fetchFleetActivities" variant="primary" class="mb-3 mr-1">
                          <i class="fa fa-filter"></i>
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-collapse>
              <b-collapse id="upcoming-job" class="mt-2">
                <b-card>
                  <div v-for="(vehicle, indexVehicle) in maintenance.data" class="bg-white shadow rounded px-3 pt-3 pb-3 border border-white mb-3">
                    <div v-if="vehicle.jobs.length === 0">No Job</div>
                    <template v-for="(job, indexJob) in vehicle.jobs">
                      <div class="flex justify-between mb-2">
                        <b-btn block href="#" v-b-toggle="'maintenance-job-' + indexVehicle + '-' + indexJob" variant="secondary" class="text-left">
                          {{ job.jobName }}
                          <b-badge v-if="job.statusDetail" variant="danger" class="ml-2">{{job.statusDetail}}</b-badge>
                          <span class="when-opened text-right pull-right">
                            <i class="fa fa-arrow-down" />
                          </span>
                          <span class="when-closed text-right pull-right">
                            <i class="fa fa-arrow-up" />
                          </span>
                        </b-btn>
                      </div>
                      <b-collapse :id="'maintenance-job-' + indexVehicle + '-' + indexJob" role="tabpanel">
                        <b-row>
                          <b-col md="12">
                            <table class="table mb-0">
                              <thead class="text-center">
                                <tr>
                                  <td>Component Name</td>
                                  <td>Frequency</td>
                                  <td>Last Maintenance</td>
                                  <td>Current</td>
                                  <td>Due Date / Hours</td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{{job.componentName}}</td>
                                  <td>{{job.interval}}</td>
                                  <td>{{job.lastMaintenanceDate}}</td>
                                  <td>{{job.lastMaintenanceDate}}</td>
                                  <td>
                                    <div>
                                      <small>Last Due #1 : {{job.dueDate}}</small>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </b-col>
                        </b-row>
                      </b-collapse>
                    </template>
                    <b-row class="mt-3">
                      <b-col md="6">
                        <h5>{{ vehicle.group }}</h5>
                      </b-col>
                    </b-row>
                  </div>
                  <!--                  <b-row class="text-center mt-5">-->
                  <!--                    <b-col>-->
                  <!--                      <b-pagination-->
                  <!--                        v-model="currentPage"-->
                  <!--                        :total-rows="rows"-->
                  <!--                        :per-page="perPage"-->
                  <!--                        first-number-->
                  <!--                        align="center"-->
                  <!--                      ></b-pagination>-->
                  <!--                    </b-col>-->
                  <!--                  </b-row>-->
                </b-card>
                <!-- Edit Fleet Form -->
                <b-modal id="edit-fleet" size="xl" :no-close-on-backdrop="true" :header-bg-variant="modal.headerBgVariant" :header-text-variant="modal.headerTextVariant" :body-bg-variant="modal.bodyBgVariant" :body-text-variant="modal.bodyTextVariant" hide-footer>
                  <template v-slot:modal-header="{ close }">
                    <h5>Edit Your Fleet</h5>
                    <!-- Emulate built in modal header close button action -->
                    <b-button size="md" variant="outline-danger" @click="close">
                      <i class="fa fa-times"></i> Close
                    </b-button>
                  </template>
                  <AddFleet v-bind:formData="fleetDetail" @childAction="$bvModal.hide('edit-fleet');" />
                </b-modal>
                <!-- End Edit Fleet Form -->
              </b-collapse>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col md="3">
        <div class="justify-content-end text-right">
          <strong :class="{ 'text-white': layout === 'maps' }">Display : &nbsp;</strong>
          <div class="btn-group">
            <button type="button" @click="layout = 'list'" class="btn btn-outline-primary" :class="{
                active: layout === 'list',
                'btn-outline-light': layout === 'maps',
              }">
              <span class="fa fa-list"></span> Dashboard List </button>
            <button type="button" @click="layout = 'maps'" class="btn btn-outline-primary" :class="{ active: layout === 'maps' }">
              <span class="fa fa-map"></span> Maps </button>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- End Maps -->
    <!-- List Dashboard -->
    <b-row v-if="layout === 'list'">
      <!-- CERTIFICATE -->
      <b-col sm="12">
        <b-row>
          <b-col md="6" lg="3">
            <router-link to="/ops/certificate">
              <iq-card class-name="iq-card-block iq-card-stretch">
                <template v-slot:body>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="text-left">
                      <h5 class="card-title mb-2 mt-2 text-primary">
                        <strong>TOTAL CERTIFICATE</strong>
                      </h5>
                      <h3 class="mb-0 line-height">{{statsCertificateData.totalCertificate !== null ? statsCertificateData.totalCertificate : '-'}}</h3>
                    </div>
                    <div class="rounded-circle iq-card-icon bg-primary">
                      <i class="las la-certificate"></i>
                    </div>
                  </div>
                </template>
              </iq-card>
            </router-link>
          </b-col>
          <b-col md="6" lg="3">
            <router-link to="/ops/certificate?status_certificate=expired">
              <iq-card class-name="iq-card-block iq-card-stretch">
                <template v-slot:body>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="text-left">
                      <h5 class="card-title mb-2 mt-2 text-primary">
                        <strong>EXPIRED</strong>
                      </h5>
                      <h3 class="mb-0 line-height">{{statsCertificateData.totalExpired !== null ? statsCertificateData.totalExpired : '-'}}</h3>
                    </div>
                    <div class="rounded-circle iq-card-icon bg-danger">
                      <i class="las la-exclamation"></i>
                    </div>
                  </div>
                </template>
              </iq-card>
            </router-link>
          </b-col>
          <b-col md="6" lg="3">
            <router-link to="/ops/certificate?status_certificate=nextMonth">
              <iq-card class-name="iq-card iq-card-block iq-card-stretch">
                <template v-slot:body>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="text-left">
                      <h5 class="card-title mb-2 mt-2 text-primary">
                        <strong>1 MONTH WILL EXPIRED</strong>
                      </h5>
                      <h3 class="mb-0 line-height">{{statsCertificateData.totalOneMonth !== null ? statsCertificateData.totalOneMonth : '-'}}</h3>
                    </div>
                    <div class="rounded-circle iq-card-icon bg-warning">
                      <i class="las la-tasks"></i>
                    </div>
                  </div>
                </template>
              </iq-card>
            </router-link>
          </b-col>
          <b-col md="6" lg="3">
            <router-link to="/ops/certificate?status=progress">
              <iq-card class-name="iq-card-block iq-card-stretch">
                <!-- <div class="iq-card-body P-0 rounded" :style="`background: url(${require('../../assets/images/page-img/3827.jpg')}) no-repeat scroll center center; background-size: contain; min-height: 127px;`"></div>-->
                <template v-slot:body>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="text-left">
                      <h5 class="card-title mb-2 mt-2 text-primary">
                        <strong>PROGRESS</strong>
                      </h5>
                      <h3 class="mb-0 line-height">{{statsCertificateData.totalProcess !== null ? statsCertificateData.totalProcess : '-'}}</h3>
                    </div>
                    <div class="rounded-circle iq-card-icon bg-success">
                      <i class="fa fa-clock-o"></i>
                    </div>
                  </div>
                </template>
              </iq-card>
            </router-link>
          </b-col>
        </b-row>
      </b-col>
      <!-- END CERTIFICATE -->
      <!-- OPS -->
      <b-col md="12" class="bg-white shadow p-3 mb-4 mt-2 text-center">
        <div class="btn-group ml-auto">
          <button v-if="['SUPER ADMIN', 'PORT CAPTAIN', 'MANAGEMENT', 'RADIO CONTROL ROOM'].includes(role)" type="button" @click="dashboard = 'operational'" class="btn btn-outline-primary" :class="{
                active: dashboard === 'operational',
              }">
            <span class="ri ri-safari-fill"></span> Operational Dashboard </button>
            <!--          <button v-if="['SUPER ADMIN', 'MANAGEMENT', 'MARINE INSURANCE'].includes(role)" type="button" @click="dashboard = 'mi'" class="btn btn-outline-warning" :class="{ active: dashboard === 'mi' }">
            <span class="ri ri-ship-2-line"></span> MI Dashboard </button>
          <button v-if="['SUPER ADMIN', 'MANAGEMENT', 'MARINE INSURANCE'].includes(role)" type="button" @click="dashboard = 'mi_list_unsettled'" class="btn btn-outline-dark" :class="{ active: dashboard === 'mi_list_unsettled' }">
            <span class="fa fa-ship fa-solid"></span> On Going Vessel Case </button>-->
        </div>
      </b-col>
      <template v-if="dashboard === 'operational'">
        <b-col md="12">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="">
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">
                <strong>DAILY REPORT FLEET POSITION & PROGRESS</strong>
              </h4>
            </template>
            <template v-slot:headerAction>
              <span>
                <small>click this button for show data! &rarr;</small>
              </span>
              <span class="ml-3">
                <a class="text-primary float-right" v-b-toggle.collapse-position role="button" style="cursor: pointer">
                  <i class="fa fa-circle" />
                </a>
              </span>
            </template>
            <template v-slot:body>
              <b-collapse id="collapse-position" class="mb-2">
                <b-row>
                  <b-col md="3">
                    <b-form-group label="Category:" label-for="kind-fleet">
                      <template v-for="(item, index) in vehicle">
                        <b-form-checkbox v-model="filterDailyOps.vehicleTypeIds" :value="item.value" :disabled="item.disabled" name="check-button" switch inline :key="`v-${index}`">
                          {{ item.text }}
                        </b-form-checkbox>
                      </template>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label-for="dateIssued" label="Date Filter">
                      <b-form-input type="date" v-model="filterDailyOps.date"></b-form-input>
                      <small style="float: right">click <i class="fa fa-calendar"></i> to show </small>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label-for="dateIssued" label="Category Ownership">
                      <b-form-select plain :options="categoryOwnership" id="gender" v-model="filterDailyOps.ownership" size="lg"></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label-for="dateIssued" label="Project Filter">
                      <v-multiselect v-model="filterDailyOps.subProject" :options="projectOptions" :multiple="true" group-values="sub" group-label="project" :group-select="true" placeholder="Select one" track-by="name" label="name">
                        <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                      </v-multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col md="1">
                    <b-form-group label="Filter" label-for="button_search">
                      <b-button @click="fetchDailyOps()" variant="primary" class="mb-3 mr-1">
                        <i class="fa fa-filter"></i>
                      </b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="10">
                    <tab-nav :tabs="true">
                      <tab-nav-items :active="true" id="position-tab" ariaControls="position" role="tab" :ariaSelected="true" title="Update Position" />
                      <!-- <tab-nav-items
                        :active="false"
                        id="repair-tab"
                        ariaControls="repair"
                        role="tab"
                        :ariaSelected="false"
                        title="Update Idle / Repair"
                      /><tab-nav-items
                        :active="false"
                        id="docking-tab"
                        ariaControls="docking"
                        role="tab"
                        :ariaSelected="false"
                        title="Update at Shipyard"
                      /> -->
                      <!-- <tab-nav-items :active="false" id="tug-assist" ariaControls="assist" role="tab" :ariaSelected="false" title="Tug Assist" />-->
                    </tab-nav>
                  </b-col>
                  <b-col md="2" class="text-right" v-if="findMenuPermission()">
                    <b-button @click="exportDailyOps" variant="dark" class="mb-3 mr-1 mt-3">
                      <i class="fa fa-file-excel"></i>Export
                    </b-button>
                  </b-col>
                </b-row>
                <div id="myTabContent" class="tab-content">
                  <tab-content-item :active="true" id="position">
                    <div class="table-responsive tableFixHead">
                      <table class="table mb-0 table-striped table-hover" style="
                        width: 2200px;
                        display: block;
                        height: 700px;
                        overflow-y: scroll;
                      ">
                        <thead class="thead-dark text-center">
                          <tr>
                            <th scope="col">No</th>
                            <th scope="col">Fleet</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                            <th scope="col">Current Position</th>
                            <th scope="col">Port Loading</th>
                            <th scope="col">Port Discharge</th>
                            <th scope="col">Agent POL</th>
                            <th scope="col">Agent POD</th>
                            <th scope="col">PIC / PE</th>
                            <th scope="col">Departure</th>
                            <th scope="col">ETA</th>
                            <th scope="col">Update Today</th>
                            <th scope="col">Reason</th>
                            <th scope="col">Idle (Hours)</th>
                            <th scope="col">Remarks</th>
                            <th scope="col">Document on Board</th>
                            <th scope="col">Cargo Loading</th>
                            <th scope="col">GPS</th>
                            <th scope="col">Speed</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="(position, index) in dailyOpsData" v-if="dailyOpsData.length > 0">
                            <tr :key="`pos-${index}`">
                              <td colspan="22">
                                <h5>
                                  <strong>{{ position.projectSub.project.projectName }} - {{ position.projectSub.name }}</strong>
                                </h5>
                              </td>
                            </tr>
                            <template v-for="(project, idx) in position.voyage" v-if="position.voyage">
                              <tr :key="`proj-${index}${idx}`">
                                <td>{{ idx+1 }}</td>
                                <td>
                                  <span v-for="each in project.vehicle">
                                    <b-badge variant="success">{{each.name}}</b-badge>&nbsp;
                                  </span>
                                </td>
                                <td>{{ project.startDate }}</td>
                                <td>{{ project.endDate }}</td>
                                <td>{{ project.currentPosition }}</td>
                                <td>{{ project.portLoading ? project.portLoading.portName : '-' }}</td>
                                <td>{{ project.portDischarge ? project.portDischarge.portName : '-' }}</td>
                                <td>{{ project.agentPol || '-' }}</td>
                                <td>{{ project.agentPod || '-' }}</td>
                                <td>
                                  <span v-for="each in project.pic">
                                    <b-badge variant="info">{{each.name}}</b-badge>&nbsp;
                                  </span>
                                </td>
                                <td>{{ project.startDate }}</td>
                                <td>{{ project.arrival || '-'}}</td>
                                <td>{{ formatDate(filterDailyOps.date)|| '-'}}</td>
                                <td>{{ project.reason ?  project.reason.name : '-' }}</td>
                                <td>{{ project.activityDurationFormat || '-'}}</td>
                                <td>{{ project.remark || '-'}}</td>
                                <td>{{ project.docOnBoard || '-'}}</td>
                                <td>{{ numberFormat(project.cargoLoading) || '-' }}</td>
                                <td>{{ project.dmsLatitude || '-'}}, {{ project.dmsLongitude || '-'}}</td>
                                <td>{{ project.speed || '-'}}</td>
                              </tr>
                            </template>
                          </template>
                          <tr v-if="dailyOpsData.length === 0">
                            <td colspan="22" style="width: 2200px; height: 700px;" class="text-center"> No Data</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </tab-content-item>
                  <tab-content-item :active="false" id="repair" aria-labelled-by="profile-tab">
                    <div class="table-responsive tableFixHead">
                      <table class="table mb-0 table-striped table-hover">
                        <thead class="thead-dark text-center">
                          <tr>
                            <th scope="col">No</th>
                            <th scope="col">Fleet</th>
                            <th scope="col">Current Position</th>
                            <th scope="col">Remarks</th>
                            <th scope="col">Arrival</th>
                            <th scope="col">Update Today / On Going Repair</th>
                            <th scope="col">Stoppage Time (Hours)</th>
                            <th scope="col">Est Complete Repair</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(repair, index) in idleRepair" :key="`rep-${index}`">
                            <td>{{ repair.no }}</td>
                            <td>{{ repair.fleet }}</td>
                            <td>{{ repair.currentPosition }}</td>
                            <td>{{ repair.remarks }}</td>
                            <td>{{ repair.arrival }}</td>
                            <td>{{ repair.onGoingRepair }}</td>
                            <td>{{ repair.StoppageTime }}</td>
                            <td>{{ repair.completeRepair }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </tab-content-item>
                  <tab-content-item :active="false" id="docking" aria-labelled-by="contact-tab">
                    <div class="table-responsive tableFixHead">
                      <table class="table mb-0 table-striped table-hover" style="width: 1800px">
                        <thead class="thead-dark text-center">
                          <tr>
                            <th scope="col" width="5%">No</th>
                            <th scope="col" width="10%">Fleet</th>
                            <th scope="col" width="15%">Position</th>
                            <th scope="col" width="5%">Preedocking</th>
                            <th scope="col" width="5%">Up Slip</th>
                            <th scope="col" width="5%">Waiting Up Slip (H)</th>
                            <th scope="col" width="5%">Date</th>
                            <th scope="col" width="5%">Docking Progress (Hours)</th>
                            <th scope="col" width="5%">Down Slip</th>
                            <th scope="col" width="5%">Waiting Down Slip (H)</th>
                            <th scope="col" width="5%">Completed Docking</th>
                            <th scope="col" width="5%">Progress %</th>
                            <th scope="col" width="25%">Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(shipyard, index) in shipyard" :key="`ship-${index}`">
                            <td class="text-center">{{ shipyard.no }}</td>
                            <td>{{ shipyard.fleet }}</td>
                            <td>{{ shipyard.position }}</td>
                            <td class="text-center">{{ shipyard.predocking }}</td>
                            <td class="text-center">{{ shipyard.upSlip }}</td>
                            <td class="text-center">
                              {{ shipyard.waitingUpSlip }}
                            </td>
                            <td class="text-center">{{ shipyard.updateToday }}</td>
                            <td class="text-center">
                              {{ shipyard.dockingProgress }}
                            </td>
                            <td class="text-center">{{ shipyard.downSlip }}</td>
                            <td class="text-center">
                              {{ shipyard.waitingDownSlip }}
                            </td>
                            <td class="text-center">{{ shipyard.completed }}</td>
                            <td class="text-center">{{ shipyard.progress }}</td>
                            <td>{{ shipyard.remarks }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </tab-content-item>
                  <!-- <tab-content-item :active="false" id="assist" aria-labelled-by="tug-assist"><div class="table-responsive tableFixHead"><table class="table mb-0 table-striped table-hover"><thead class="thead-dark text-center"><tr><th scope="col">No</th><th scope="col">Fleet </th><th scope="col">Assist</th><th scope="col">Date</th><th scope="col">Position</th><th scope="col">Remark</th></tr></thead><tbody><tr><td>1</td><td>TB. TCP 207</td><td>TB. SML 102</td><td>23.00 9/10/2020</td><td>Jetty Asam Asam </td><td>-</td></tr><tr><td>2</td><td>TB. ETI 301</td><td>TB. TCP 202</td><td>23.00 9/10/2020</td><td>Jetty Asam Asam </td><td>-</td></tr></tbody></table></div></tab-content-item>-->
                </div>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
        <b-col sm="12">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="">
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">
                <strong>MER (Monthly Employment Report) FILTER</strong>
              </h4>
            </template>
            <template v-slot:headerAction>
              <a class="text-primary float-right" v-b-toggle.filter-statistic role="button" style="cursor: pointer">
                <i class="fa fa-filter" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse id="filter-statistic" class="mb-2">
                <b-row>
                  <b-col md="3">
                    <b-form-group label="Category:" label-for="kind-fleet">
                      <template v-for="(item, index) in vehicle">
                        <b-form-checkbox v-model="filterMonthlyOps.vehicleTypeIds" :disabled="item.disabled" :value="item.value" name="check-button" switch inline :key="`ve-${index}`">
                          {{ item.text }}
                        </b-form-checkbox>
                      </template>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label-for="dateIssued" label="Date Range Filter">
                      <date-picker :lang="lang" v-model="filterMonthlyOps.dateRange" type="date" range placeholder="Select date range"></date-picker>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label-for="dateIssued" label="Category Ownership">
                      <b-form-select plain :options="categoryOwnership" id="gender" v-model="filterMonthlyOps.ownership"></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group label-for="dateIssued" label="Project Filter">
                      <v-multiselect v-model="filterMonthlyOps.subProject" :options="projectOptions" :multiple="true" group-values="sub" group-label="project" :group-select="true" placeholder="Type to search" track-by="name" label="name">
                        <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                      </v-multiselect>
                    </b-form-group>
                  </b-col>
                  <b-col md="1">
                    <b-form-group label="Filter" label-for="button_search">
                      <b-button @click.prevent="fetchMonthlyOps()" variant="primary" class="mb-3 mr-1">
                        <i class="fa fa-filter"></i>
                      </b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr />
              </b-collapse>
              <b-row>
                <b-col md="4">
                  <b-form-group label="Category:" label-for="kind-fleet">
                    <h5>
                      <strong>{{filterMonthlyOps.vehicleTypeIds.length > 0 ? showTextVehicleType(filterMonthlyOps.vehicleTypeIds) : 'All Category Vehicle'}}</strong>
                    </h5>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label-for="dateIssued" label="Date Range">
                    <h5>
                      <strong>{{formatDate(filterMonthlyOps.dateRange[0])}} - {{formatDate(filterMonthlyOps.dateRange[1])}}</strong>
                    </h5>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label-for="dateIssued" label="Category Ownership">
                    <h5>
                      <strong>{{filterMonthlyOps.ownership ? showTextOwnership(filterMonthlyOps.ownership) : 'All Category Ownership'}}</strong>
                    </h5>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label-for="dateIssued" label="Project Filter">
                    <h5 v-if="filterMonthlyOps?.subProject?.length > 0">
                      <strong class="mr-2" v-for="(each, i) in filterMonthlyOps?.subProject" v-bind:key="i">{{each.name}},</strong>
                    </h5>
                    <h5 v-else>
                      <strong>All Project</strong>
                    </h5>
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <!-- Operational Fleet Matrix -->
        <b-col md="12">
          <iq-card class-name="iq-card-block" body-class="">
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">
                <strong>OPERATIONAL FLEET MATRIX</strong>
              </h4>
            </template>
            <template v-slot:headerAction v-if="findMenuPermission()">
              <b-button @click="exportMonthlyOps()" variant="dark" class="mb-3 mr-1 mt-3">
                <i class="fa fa-file-excel"></i>Export
              </b-button>
            </template>
            <template v-slot:body>
              <div class="accordion" role="tablist">
                <div class="text-center" v-if="loadingOpsMatrix">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <template v-for="(matrix, index) in opsMatrix.matrix">
                    <b-card no-body class="mb-1" :key="`mat-${index}`">
                      <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="'matrix' + matrix.projectSub.id">
                        <h4 style="cursor: pointer; text-transform: uppercase; font-weight: bold" class="p-3">
                          {{ matrix.projectSub.project.projectName }} - {{ matrix.projectSub.name }}
                        </h4>
                      </b-card-header>
                      <b-collapse :id="'matrix' + matrix.projectSub.id" :visible="matrix.visibility" role="tabpanel">
                        <!-- accordion="matrix-accordion" -->
                        <b-card-body>
                          <!-- Accordion for Category Fleet -->
                          <template v-for="(category, index2) in matrix.vehicleTypes">
                            <b-card no-body class="mb-1" :key="`mt2-${index2}`">
                              <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="'category' + index2">
                                <h4 style="cursor: pointer;" class="p-3">
                                  {{ category.vehicleType.name }}
                                </h4>
                              </b-card-header>
                              <b-collapse :id="'category' + index2" visible role="tabpanel">
                                <b-card-body>
                                  <b-card-text>
                                    <div class="table-responsive" v-if="category.vehicles.vehicle !== 'he'">
                                      <table class="table mb-0 table-striped table-hover">
                                        <thead class="text-center">
                                          <tr class="bg-primary" style="text-transform: uppercase;">
                                            <th>No</th>
                                            <th>Fleet</th>
                                            <th>Date</th>
                                            <template v-if="category.vehicles[0]">
                                              <th v-for="activity in category.vehicles[0]?.activities">{{activity.name}}</th>
                                            </template>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-for="(ops, idx) in category.vehicles" :key="`cat-${idx}`">
                                            <td class="text-center">
                                              {{ idx+1 }}
                                            </td>
                                            <td>{{ ops.vehicle.name }}</td>
                                            <td class="text-center">{{formatDate(filterMonthlyOps.dateRange[0])}} - {{formatDate(filterMonthlyOps.dateRange[1])}}</td>
                                            <td class="text-center" v-for="activity in ops?.activities">
                                              {{ activity.durationFormat }}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <!--<div class="table-responsive" v-else><table
                                        class="table mb-0 table-striped table-hover"
                                      ><thead class="text-center"><tr><th>No</th><th>Fleet</th><th>Date</th><th>Utility</th><th>Tech</th><th>Standby for Use</th></tr></thead><tbody><tr
                                            v-for="(ops, idx) in category.content"
                                            :key="`cat-${idx}`"
                                          ><td class="text-center">
                                              {{ ops.no }}
                                            </td><td>{{ ops.fleet }}</td><td class="text-center">
                                              {{ ops.date }}
                                            </td><td class="text-center">
                                              {{ ops.utility }}
                                            </td><td class="text-center">
                                              {{ ops.tech }}
                                            </td><td class="text-center">
                                              {{ ops.standby }}
                                            </td></tr><tr><td class="text-center" colspan="3"><b>Total</b></td><td class="text-center"><b>12.29</b></td><td class="text-center"><b>4.32</b></td><td class="text-center"><b>0.00</b></td></tr></tbody></table></div>-->
                                  </b-card-text>
                                </b-card-body>
                              </b-collapse>
                            </b-card>
                          </template>
                          <!-- END Accordion for Category Fleet -->
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </template>
                </template>
              </div>
            </template>
          </iq-card>
        </b-col>
        <!-- End Operational Fleet Matrix -->
        <b-col md="12">
          <b-row>
            <b-col md="12">
              <iq-card class-name="iq-card-block" body-class="">
                <template v-slot:headerTitle>
                  <h4 class="card-title text-primary">
                    <strong>OPERATIONAL STATISTIC</strong>
                  </h4>
                </template>
                <template v-slot:body>
                  <div class="text-center" v-if="loadingOpsMatrix">
                    <b-spinner type="grow" label="Spinning"></b-spinner>
                  </div>
                  <template v-else>
                    <tab-nav :tabs="true">
                      <tab-nav-items :active="true" id="percent-tab" ariaControls="percent" role="tab" :ariaSelected="true" title="Based on Percentage" />
                      <tab-nav-items :active="false" id="hours-tab" ariaControls="hours" role="tab" :ariaSelected="false" title="Based on Hours" />
                    </tab-nav>
                    <div id="myTabContent" class="tab-content">
                      <tab-content-item :active="true" id="percent">
                        <template v-for="(percentage, index) in opsMatrix.matrix">
                          <b-card no-body class="mb-1" :key="`per-${index}`">
                            <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="'percentage' + percentage.projectSub.id">
                              <h4 style="cursor: pointer" class="p-3">
                                {{ percentage.projectSub.project.projectName }} - {{ percentage.projectSub.name }}
                              </h4>
                            </b-card-header>
                            <b-collapse :id="'percentage' + percentage.projectSub.id" :visible="percentage.visibility" role="tabpanel">
                              <!--accordion="percentage-accordion"-->
                              <b-card-body>
                                <!-- Accordion for Category Fleet -->
                                <template v-for="(category, index2) in percentage.vehicleTypes">
                                  <b-card no-body class="mb-1" :key="`per2-${index2}`">
                                    <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="'category' + index2">
                                      <h4 style="cursor: pointer" class="p-3">
                                        {{ category.vehicleType.name }}
                                        <!-- <b-badge>{{ category.total }}</b-badge> -->
                                      </h4>
                                    </b-card-header>
                                    <b-collapse :id="'category' + index2" visible role="tabpanel">
                                      <b-card-body>
                                        <b-form-group v-if="percentage.TypeVehicleOpt" :label="'Filter Chart ' + category.vehicleType.name">
                                          <v-multiselect @input="onChange($event, index, index2)" v-model="category.bodyData.data" tag-placeholder="Category" placeholder="Search or statistic" label="name" track-by="name" :options="percentage.TypeVehicleOpt" :multiple="true" :taggable="true"></v-multiselect>
                                        </b-form-group>
                                        <b-card-text>
                                          <AmChart :element="`chart-${percentage.projectSub.id}-${index}-${index2}-${category.bodyData.type}`" :type="category.bodyData.type" :option="category.bodyData" />
                                        </b-card-text>
                                      </b-card-body>
                                    </b-collapse>
                                  </b-card>
                                </template>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </template>
                      </tab-content-item>
                      <tab-content-item :active="false" id="hours" aria-labelled-by="hours-tab">
                        <template v-for="(hours, index) in opsMatrix.matrix">
                          <b-card no-body class="mb-1" :key="`stat-${index}`">
                            <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="'hours' + hours.projectSub.id">
                              <h4 style="cursor: pointer" class="p-3">
                                {{ hours.projectSub.project.projectName }} - {{ hours.projectSub.name }}
                              </h4>
                            </b-card-header>
                            <b-collapse :id="'hours' + hours.projectSub.id" :visible="hours.visibility" accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                                <!-- Accordion for Category Fleet -->
                                <template v-for="(category, index2) in hours.vehicleTypes">
                                  <b-card no-body class="mb-1" :key="`stat-h-${index2}`">
                                    <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="`category-${hours.projectSub.id}=${index2}`">
                                      <h4 style="cursor: pointer" class="p-3">
                                        {{ category.vehicleType.name }}
                                        <!-- <b-badge>{{ category.total }}</b-badge> -->
                                      </h4>
                                    </b-card-header>
                                    <b-collapse :id="`category-${hours.projectSub.id}=${index2}`" visible role="tabpanel">
                                      <b-card-body>
                                        <b-form-group :label="'Filter Chart ' + category.vehicleType.name">
                                          <v-multiselect @input="onChangeBarChart($event, index, index2)" v-model="category.opsChart.xaxis.categories" tag-placeholder="Category" placeholder="Search or statistic" :options="category.bodyData.data?.map(f => f.name)" :multiple="true" :taggable="true"></v-multiselect>
                                        </b-form-group>
                                        <b-card-text>
                                          <ApexChart v-if="!category.loadingChart" :element="`chart-hours-${hours.projectSub.id}-${index}-${index2}-${category.bodyData.type}`" :chartOption="category.opsChart" />
                                        </b-card-text>
                                      </b-card-body>
                                    </b-collapse>
                                  </b-card>
                                </template>
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </template>
                      </tab-content-item>
                    </div>
                  </template>
                </template>
              </iq-card>
            </b-col>
            <!-- <b-col md="12"><iq-card><template v-slot:headerTitle><h4 class="card-title text-primary"><strong>REPORT DOCKING</strong></h4></template><template v-slot:body><b-row><b-col lg="4"><div class="training-block d-flex align-items-center mt-3"><div class="ml-2"><h5 class="">32 Task</h5><p class="mb-0">Total Task</p></div></div></b-col><b-col lg="4"><div class="training-block d-flex align-items-center mt-3"><div class="ml-2"><h5 class="">15 Task</h5><p class="mb-0">Delay</p></div></div></b-col><b-col lg="4"><div class="training-block d-flex align-items-center mt-3"><div class="ml-2"><h5 class="">Rp. 1.523.000.000</h5><p class="mb-0">Total Cost</p></div></div></b-col></b-row><div class="btn btn-primary d-block mt-4"><i class="fa fa-search"></i> View Detail
                  </div></template></iq-card></b-col> -->
          </b-row>
        </b-col>
        <!-- <b-col md="4 d-none"><iq-card
    class-name="iq-card-block iq-card-stretch iq-card-height"
    body-class=""
  ><template v-slot:headerTitle><h4 class="card-title text-primary"><strong>SERVICE & REPAIR REPORT</strong></h4></template><template v-slot:body><div class="training-block d-flex align-items-center"><div class="rounded-circle iq-card-icon iq-bg-primary"><i class="fa fa-wrench"></i></div><div class="ml-3"><h5 class="">52 Service Repair</h5><p class="mb-0">Total Process</p></div></div><div class="training-block d-flex align-items-center mt-5"><div class="rounded-circle iq-card-icon iq-bg-primary"><i class="fa fa-wrench"></i></div><div class="ml-3"><h5 class="">125 Task</h5><p class="mb-0">Total Task</p></div></div><div class="training-block d-flex align-items-center mt-5"><div class="rounded-circle iq-card-icon iq-bg-primary"><i class="fa fa-wrench"></i></div><div class="ml-3"><h5 class="">103 Task</h5><p class="mb-0">Total On Time</p></div></div><div class="training-block d-flex align-items-center mt-5"><div class="rounded-circle iq-card-icon iq-bg-primary"><i class="fa fa-wrench"></i></div><div class="ml-3"><h5 class="">22 Task</h5><p class="mb-0">Total Delay</p></div></div><div class="training-block d-flex align-items-center mt-5"><div class="rounded-circle iq-card-icon iq-bg-primary"><i class="fa fa-money"></i></div><div class="ml-3"><h5 class="">Rp. 1.523.000.000</h5><p class="mb-0">Total Cost</p></div></div><div class="btn btn-primary d-block mt-5"><i class="fa fa-search"></i> View Detail
      </div></template></iq-card></b-col> -->
        <!-- <b-col sm="12"><iq-card class-name="iq-card-block iq-card-stretch iq-card-height"><template v-slot:headerTitle><h4 class="card-title text-primary"><strong>TOP 10 FLEET (BASED ON RUNNING)</strong></h4></template><template
              v-slot:body
              class="
                d-flex
                flex-wrap
                align-items-center
                p-0
                slick-initialized slick-slider
              "
            ><Slick :option="slickOptions" id="doster-list-slide"><div
                  class="docter-list-item-inner rounded text-center"
                  v-for="fleet in fleetList"
                  :key="fleet.id"
                ><div class="top"><div class="boat"><img
                        :src="fleet.img"
                        class="img-fluid rounded-circle"
                        alt="user-img"
                      /></div><div class="waves"></div></div><div class="bottom"><div class="float"></div></div><div class="doctor-detail mt-3"><h5>{{ fleet.name }}</h5><h6>{{ fleet.position }}</h6></div><hr /><div class="doctor-description"><p class="mb-0 text-center pl-2 pr-2">{{ fleet.run }}</p></div></div></Slick></template></iq-card></b-col>-->
        <b-col sm="6">
          <iq-card class-name="iq-card-block iq-card-stretch">
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">
                <strong>TRIP & CARGO</strong>
              </h4>
            </template>
            <template v-slot:headerAction></template>
            <template v-slot:body>
              <div class="accordion" role="tablist">
                <template v-for="(cargo, index) in opsMatrix.tripCargo">
                  <b-card no-body class="mb-1" :key="`trip-${index}`">
                    <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="'cargo' + cargo.projectSub.id">
                      <h4 style="cursor: pointer" class="p-3">
                        {{ cargo.projectSub.project.projectName }} - {{ cargo.projectSub.name }}
                      </h4>
                    </b-card-header>
                    <b-collapse :id="'cargo' + cargo.projectSub.id" :visible="cargo.visibility" role="tabpanel">
                      <b-card-body>
                        <b-card-text>
                          <div class="table-responsive">
                            <table class="table mb-0 table-striped table-hover">
                              <thead class="thead-dark text-center">
                                <tr>
                                  <th scope="col">No</th>
                                  <th scope="col">Fleet</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Trip Status</th>
                                  <th scope="col">Cargo Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(trip, index2) in cargo.trip" :key="`trip-d-${index2}`">
                                  <td>{{ index2+1 }}</td>
                                  <td>
                                    <span v-for="(each, index3) in trip.vehicle" :key="`fleet-${index3}`">
                                      {{index3 > 0 ? ' / ' + each.name : each.name}}
                                    </span>
                                  </td>
                                  <td class="text-center">{{formatDate(filterMonthlyOps.dateRange[0])}} - {{formatDate(filterMonthlyOps.dateRange[1])}}</td>
                                  <td class="text-center">{{ trip.tripStatus }}</td>
                                  <td class="text-center">{{ trip.cargoStatus }}</td>
                                </tr>
                                <tr>
                                  <td colspan="3" class="text-right font-weight-bold">Total</td>
                                  <td class="text-center font-weight-bold">{{numberFormat(cargo.totalTripStatus)}}</td>
                                  <td class="text-center font-weight-bold">{{numberFormat(cargo.totalCargoStatus)}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </template>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col sm="6 ">
          <b-row>
            <b-col md="12">
              <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                <template v-slot:headerTitle>
                  <h4 class="card-title text-primary">
                    <strong>TRIP STATISTIC</strong>
                  </h4>
                </template>
                <template v-slot:headerAction></template>
                <template v-slot:body>
                  <div class="accordion" role="tablist">
                    <template v-for="(trip, index) in opsMatrix.tripCargo">
                      <b-card no-body class="mb-1" :key="`trip-stat-${index}`">
                        <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="'trip' + trip.projectSub.id">
                          <h4 style="cursor: pointer" class="p-3">
                            {{ trip.projectSub.project.projectName }} - {{ trip.projectSub.name }}
                          </h4>
                        </b-card-header>
                        <b-collapse :id="'trip' + trip.projectSub.id" :visible="trip.visibilityTripStats" role="tabpanel">
                          <b-card-body>
                            <b-card-text>
                              <ApexChart :element="'patient-chart-02' + trip.projectSub.id" :chartOption="trip.tripChart" v-if="$route.meta.dark" />
                              <ApexChart :element="'patient-chart-02' + trip.projectSub.id" :chartOption="trip.tripChart" v-else />
                            </b-card-text>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </template>
                  </div>
                </template>
              </iq-card>
            </b-col>
            <b-col md="12">
              <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                <template v-slot:headerTitle>
                  <h4 class="card-title text-primary">Cargo Statistic</h4>
                </template>
                <template v-slot:headerAction></template>
                <template v-slot:body>
                  <template v-for="(cargo, index) in opsMatrix.tripCargo">
                    <b-card no-body class="mb-1" :key="`car-stat-${index}`">
                      <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="'cargoStatistic' + cargo.projectSub.id">
                        <h4 style="cursor: pointer" class="p-3">
                          {{ cargo.projectSub.project.projectName }} - {{ cargo.projectSub.name }}
                        </h4>
                      </b-card-header>
                      <b-collapse :id="'cargoStatistic' + cargo.projectSub.id" :visible="cargo.visibilityStats" role="tabpanel">
                        <b-card-body>
                          <b-card-text>
                            <ApexChart :element="'patient-chart-03' + cargo.projectSub.id" :chartOption="cargo.cargoChart" v-if="$route.meta.dark" />
                            <ApexChart :element="'patient-chart-03' + cargo.projectSub.id" :chartOption="cargo.cargoChart" v-else />
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </template>
                </template>
              </iq-card>
            </b-col>
          </b-row>
        </b-col>
      </template>
      <template v-else-if="dashboard === 'mi'">
        <SummaryManagement />
      </template>
      <template v-else-if="dashboard === 'technical_maintenance'"></template>
      <template v-else-if="dashboard === 'crewing'">
        <h3>On Development</h3>
      </template>
      <template v-else-if="dashboard === 'mi_list_unsettled'">
        <ListInitialTable :show-status-action="false" :show-filter="false" non-settled type-form="claim" />
      </template>
    </b-row>
    <!-- End List Dashboard -->
  </b-container>
</template>

<script>
import { latLng, icon } from 'leaflet'
import { fleetsActions, dashboardActions, projectsActions, maintenanceActions, reasonsActions } from '@src/Utils/helper'
import moment from 'moment'
import AddFleet from '../MasterPages/AddFleet.vue'
import { numberFormat } from '@src/plugins/helpers'
import SummaryManagement from '@src/views/MI/SummaryManagement'
import ListInitialTable from '@src/views/MI/ListInitialTable'
/* const body = document.getElementsByTagName('body') */
export default {
  name: 'FleetDashboard',
  components: { ListInitialTable, SummaryManagement, AddFleet },
  data () {
    return {
      layout: 'list',
      dashboard: 'operational',
      rows: 100,
      perPage: 1,
      currentPage: 5,
      modal: {
        show: false,
        variants: [
          'primary',
          'secondary',
          'success',
          'warning',
          'danger',
          'info',
          'light',
          'dark'
        ],
        headerBgVariant: 'light',
        headerTextVariant: 'dark',
        bodyBgVariant: 'default',
        bodyTextVariant: 'dark',
        footerBgVariant: '#000000',
        footerTextVariant: 'dark'
      },
      // Maps
      selectedCompanyName: null,
      selectedContract: null,
      selectedMainEngine: null,
      selectedFleetCategory: 'tugboat',
      fleetCategory: [
        {
          label: 'Tug Boat',
          color: 'success',
          value: 'tugboat'
        },
        {
          label: 'Barge',
          color: 'warning',
          value: 'barge'
        },
        {
          label: 'Mother Vessel',
          color: 'primary',
          value: 'mother_vessel'
        },
        {
          label: 'Heavy Equipment',
          color: 'primary',
          value: 'heavy_equipment'
        },
        {
          label: 'Pusher Tug',
          color: 'primary',
          value: 'pusher_tug'
        },
        {
          label: 'Pusher Barge',
          color: 'primary',
          value: 'pusher_barge'
        }
      ],
      technicalActive: {
        technical_module: 'active'
      },
      formPower: {
        _saving: false,
        typeOfPower: [
          {
            type: '',
            series: '',
            actual: ''
          }
        ]
      },
      powerOptions: [
        { value: '1', text: 'Main Engine 1' },
        { value: '2', text: 'Main Engine 1' },
        { value: '3', text: 'Auxiliary Engine 1' },
        { value: '4', text: 'Auxiliary Engine 2' },
        { value: '5', text: 'Diesel Generator 1' },
        { value: '6', text: 'Diesel Generator 2' },
        { value: '7', text: 'Diesel Generator 3' },
        { value: '8', text: 'Engine Crane 1' },
        { value: '9', text: 'Emergency Generator' }
      ],
      ROBOptions: [
        { value: '1', text: 'Fuel Oil' },
        { value: '2', text: 'Fresh Water' },
        { value: '3', text: 'Drill Water' },
        { value: '4', text: 'Barite' },
        { value: '5', text: 'Bentonite' },
        { value: '6', text: 'Cement' },
        { value: '7', text: 'Brine' }
      ],
      seriesROBOptions: [
        { value: '1', text: 'TELS 46' },
        { value: '2', text: 'TELS 48' },
        { value: '3', text: 'OMEL 100' },
        { value: '4', text: 'SHELL' }
      ],
      formROBType: {
        _saving: false,
        typeOfROB: [
          {
            type: '',
            series: '',
            actual: ''
          }
        ]
      },
      technical: [
        {
          name: 'technical_module',
          label: 'Yes',
          value: 'active',
          disabled: false
        },
        {
          name: 'technical_module',
          label: 'No',
          value: 'inactive',
          disabled: false
        }
      ],
      main_engine: [
        { value: 'Yanmar 12LAK-STE2', text: 'Yanmar 12LAK-STE2' },
        { value: 'Weichai TD226B - 3CDI/4CD', text: 'Weichai TD226B - 3CDI/4CD' },
        { value: 'Cummin s6BT5.9DM', text: 'Cummin s6BT5.9DM' },
        { value: 'MITSUBISHI S6R2-T2MTK3L', text: 'MITSUBISHI S6R2-T2MTK3L' },
        { value: 'Caterpillar C-32', text: 'Caterpillar C-32' }
      ],
      selectedAuxEngine: null,
      aux_engine: [
        { value: 'Yanmar 12LAK-STE2', text: 'Yanmar 12LAK-STE2' },
        { value: 'Weichai TD226B - 3CDI/4CD', text: 'Weichai TD226B - 3CDI/4CD' },
        { value: 'Cummin s6BT5.9DM', text: 'Cummin s6BT5.9DM' },
        { value: 'MITSUBISHI S6R2-T2MTK3L', text: 'MITSUBISHI S6R2-T2MTK3L' },
        { value: 'Caterpillar C-32', text: 'Caterpillar C-32' }
      ],
      options2: [
        { value: 'select-1', text: 'select-1' },
        { value: 'select-2', text: 'select-2' },
        { value: 'select-3', text: 'select-3' },
        { value: 'select-4', text: 'select-4' },
        { value: 'select-5', text: 'select-5' },
        { value: 'select-6', text: 'select-6' },
        { value: 'select-7', text: 'select-7' },
        { value: 'select-8', text: 'select-8' }
      ],
      checked1: true,
      checked2: true,
      checked3: true,
      radio1: false,
      radio2: true,
      radio3: true,
      radio5: true,
      radio6: 'true',
      radio7: false,
      radio8: 'dissel',
      switch1: true,
      options3: [
        { value: '1', text: 'One' },
        { value: '2', text: 'Two' },
        { value: '3', text: 'Three' }
      ],
      CompanyName: [
        { value: '1', text: 'Transcoal Pacific' },
        { value: '2', text: 'Sentra Makmur Lines' },
        { value: '3', text: 'Energi Transporter Indonesia' }
      ],
      selectedCertificateName: null,
      certificateName: [
        { value: '1', text: 'SNPP/IOPP/Marpol' },
        { value: '2', text: 'Class Mesin' },
        { value: '3', text: 'Class Garis Muat' },
        { value: '4', text: 'Class Lambung' },
        { value: '5', text: 'Sertifikat Keselamatan Radio Kapal Barang' },
        { value: '6', text: 'Surat Laut' },
        { value: '7', text: 'Sertifikat Safe Manning' }
      ],
      selectedCertificateType: null,
      certificateType: [
        { value: '1', text: 'Permanent-Endorsement' },
        { value: '2', text: 'Unpermanent-Endrsement' }
      ],
      Contract: [
        { value: '1', text: 'Owned by Company' },
        { value: '2', text: 'Chartered' }
      ],
      job_check: [
        {
          checked: false,
          disabled: false
        }
      ],
      formFileSupportList: {
        _saving: false,
        fileSupport: [
          {
            name: '',
            file: ''
          }
        ]
      },
      zoom: 5,
      center: latLng(-2.548926, 118.0148634),
      url: 'https://api?.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=T7IfHizkxdz98X76UCBi',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      currentCenter: latLng(-2.548926, 118.0148634),
      showParagraph: true,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false
      },
      showMap: true,
      withPopup: latLng(-2.548926, 118.0148634),
      icon: icon({
        iconUrl:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Tugboat_diagram-en.svg/2560px-Tugboat_diagram-en.svg.png',
        iconSize: [62, 67],
        iconAnchor: [16, 37]
      }),
      lang: {
        formatLocale: {
          // MMMM
          months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
          ],
          // MMM
          monthsShort: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
          ],
          // dddd
          weekdays: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday'
          ],
          // ddd
          weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          // dd
          weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
          // first day of week
          firstDayOfWeek: 0,
          // first week contains January 1st.
          firstWeekContainsDate: 1
        },
        monthBeforeYear: false
      },
      dateRange: [],
      config: {
        dateFormat: 'Y-m-d',
        inline: true
      },
      vehicle: [],
      selectedCategoryOwnership: 'all',
      categoryOwnership: [
        { value: '', text: 'All Category Ownership' },
        { value: 'OWNED', text: 'Owned By Company' },
        { value: 'CHARTERED', text: 'Chartered' }
      ],
      projectOptions: [],
      projectValue: [],
      updatePosition: [
        {
          title: 'KPC Project - Direct Barging Sangatta',
          project: [
            {
              no: '1',
              fleet: 'TB. ETI 109 - BG. HAZAIRA',
              currentPosition: 'Sea Passage',
              loading: 'TANJUNG BARA',
              discharge: 'CELWeatherAN BAWANG',
              pol: 'SEA HORSE',
              pod: 'BYU',
              pic: 'Mr. Fadly / Mr. Herman Yulio',
              updateToday: '08.00 8/4/2021',
              timeDeparture: '22.00 LT 03/04/2021',
              eta: '23.00 9/4/2021',
              reason: 'Running',
              idle: '-',
              remarks: 'Sailed from Tg.Bara to CelWeatheran Bawang\t\t',
              dob: '18.00 LT 03/04/21',
              cargoLoading: '10.134.000',
              gps: '4° 51\' 3.67" S, 115° 50\' 48.84" E\t',
              speed: '2.3 Knots'
            },
            {
              no: '2',
              fleet: 'TB. ETI 303 - BG. ETI 3312',
              currentPosition: 'Sea Passage',
              loading: 'TANJUNG BARA',
              discharge: 'CELWeatherAN BAWANG',
              pol: 'SEA HORSE',
              pod: 'BYU',
              pic: 'Mr. Donny Lumabiang / Mr. Teguh P',
              updateToday: '08.00 8/4/2021',
              timeDeparture: '18.30 LT 05/04/2021',
              eta: '15.00 12/4/2021',
              reason: 'Running',
              idle: '-',
              remarks: 'Sailed from Tg.Bara to CelWeatheran Bawang\t\t',
              dob: '-',
              cargoLoading: '10.001.000',
              gps: '2° 58\' 56.39" S, 116° 38\' 52.37" E',
              speed: '3.8 Knots'
            },
            {
              no: '3',
              fleet: 'TB. ETI 306 - BG. SML 3301',
              currentPosition: 'Anchorage at Asam2',
              loading: 'TANJUNG BARA',
              discharge: 'CELWeatherAN BAWANG',
              pol: 'SEA HORSE',
              pod: 'BYU',
              pic: 'Mr. Donny Lumabiang / Mr. Teguh P',
              updateToday: '08.00 8/4/2021',
              timeDeparture: '14.00 5/4/2021',
              eta: '08.00 8/4/2021',
              reason: 'Ops',
              idle: '66',
              remarks:
                'support stanchion sidedoor ps dan pemasangan pintu Etc PM 08/04/21',
              dob: '-',
              cargoLoading: '-',
              gps: '4° 0\' 4.72" S, 115° 9\' 1.40" E',
              speed: '0.0 Knots'
            },
            {
              no: '4',
              fleet: 'TB. ETI 307 - BG. MISHA',
              currentPosition: 'Anchorage at Tj.Bara',
              loading: 'TANJUNG BARA',
              discharge: 'CELWeatherAN BAWANG',
              pol: 'SEA HORSE',
              pod: 'BYU',
              pic: 'Mr. Donny Lumabiang / Mr. Teguh P',
              updateToday: '08.00 8/4/2021',
              timeDeparture: '00.00 6/4/2021',
              eta: '08.00 8/4/2021',
              reason: 'Loading',
              idle: '-',
              remarks: 'Waiting for Loading',
              dob: '-',
              cargoLoading: '-',
              gps: '0° 30\' 35.60" N, 117° 38\' 37.43" E\t',
              speed: '0.0 Knots'
            }
          ]
        },
        {
          title: 'KPC Project - Transhipment',
          project: [
            {
              no: '1',
              fleet: 'TB. KEITARO 0 - BG. ETI 3006',
              currentPosition: 'Anchorage at Lontar',
              loading: 'MV. ALIYAH PERMATA',
              discharge: 'LONTAR',
              pol: 'TCP',
              pod: 'TCP',
              pic: 'Mr. Donny Lumabiang / Mr. Teguh P',
              updateToday: '08.00 8/4/2021',
              timeDeparture: '10.10 LT 24/03/2021',
              eta: '12.30 24/3/2021',
              reason: 'Loading',
              idle: '356',
              remarks: 'Waiting MV ( STS LONTAR )\t\t',
              dob: '-',
              cargoLoading: '-',
              gps: '6° 1\' 5.99" S, 106° 27\' 45.68" E\t',
              speed: '2.3 Knots'
            }
          ]
        },
        {
          title: 'AI Project - Direct Barging',
          project: [
            {
              no: '1',
              fleet: 'TB. ETI 304 - BG. ETI 3301',
              currentPosition: 'Anchorage at Asam2',
              loading: 'ASAM ASAM',
              discharge: 'REMBANG',
              pol: 'TCP',
              pod: 'SAJ',
              pic: 'Mr. Fadly / Mr. Herman Yulio',
              updateToday: '08.00 8/4/2021',
              timeDeparture: '20.45 LT 06/04/2021',
              eta: '22.00 6/4/2021',
              reason: 'Ops',
              idle: '34',
              remarks: 'Change Crew ( Nakhoda )',
              dob: '',
              cargoLoading: ' 9.509.667 ',
              gps: '3° 59\' 26.84" S, 115° 8\' 45.71" E',
              speed: '0.0 Knots'
            },
            {
              no: '2',
              fleet: 'TB. ETI 308 - BG. ETI 3304',
              currentPosition: 'Sea Passage',
              loading: 'KINTAP',
              discharge: 'REMBANG',
              pol: 'TCP',
              pod: 'SAJ',
              pic: 'Mr. Donny Lumabiang / Mr. Teguh P',
              updateToday: '08.00 8/4/2021',
              timeDeparture: '10.30 LT 07/04/2021',
              eta: '18.00 9/4/2021',
              reason: 'Running',
              idle: '-',
              remarks: 'Sailed from Rembang to Asam',
              dob: '-',
              cargoLoading: '-',
              gps: '5° 45\' 7.56" S, 113° 12\' 47.23" E\t',
              speed: '4.8 Knots'
            },
            {
              no: '3',
              fleet: 'TB. TCP 201 - BG. HANIQA',
              currentPosition: 'Sea Passage',
              loading: 'SATUI',
              discharge: 'POMALA',
              pol: 'TCP',
              pod: 'ANTAM',
              pic: 'Mr. Donny Lumabiang / Mr. Teguh P',
              updateToday: '08.00 8/4/2021',
              timeDeparture: '21.00 LT 04/04/2021',
              eta: '09.00 9/4/2021',
              reason: 'Running',
              idle: '-',
              remarks: 'Sailed from Satui to Pomala',
              dob: '-',
              cargoLoading: '-',
              gps: '4° 59\' 5.28" S, 120° 58\' 28.63" E',
              speed: '5.4 Knots'
            },
            {
              no: '4',
              fleet: 'TB. ETI 307 - BG. MISHA',
              currentPosition: 'Anchorage at Tj.Bara',
              loading: 'TANJUNG BARA',
              discharge: 'CELWeatherAN BAWANG',
              pol: 'SEA HORSE',
              pod: 'BYU',
              pic: 'Mr. Donny Lumabiang / Mr. Teguh P',
              updateToday: '08.00 8/4/2021',
              timeDeparture: '00.00 6/4/2021',
              eta: '08.00 8/4/2021',
              reason: 'Loading',
              idle: '-',
              remarks: 'Waiting for Loading',
              dob: '-',
              cargoLoading: '-',
              gps: '0° 30\' 35.60" N, 117° 38\' 37.43" E\t',
              speed: '0.0 Knots'
            }
          ]
        },
        {
          title: 'Nickel Project - Direct Barging',
          project: [
            {
              no: '1',
              fleet: 'TB. ETI 112 - BG. ETI 3008',
              currentPosition: 'Shelter at P. Tanah Keke',
              loading: 'MAROMBO',
              discharge: 'BOJONEGARA',
              pol: 'PMI',
              pod: 'TCP',
              pic: 'Mr. Jemmy / Mr. Sopian',
              updateToday: '08.00 8/4/2021',
              timeDeparture: '18.00 LT 02/04/2021',
              eta: '22.00 6/4/2021',
              reason: 'Running',
              idle: '10',
              remarks: 'Shelter Due to Bad Weather',
              dob: '16.45 LT 02/04/21',
              cargoLoading: '7.548.216',
              gps: '5° 30\' 48.10" S, 119° 19\' 54.41" E',
              speed: '0.0 Knots'
            }
          ]
        }
      ],
      idleRepair: [
        {
          no: '1',
          fleet: 'Seatrans 01',
          currentPosition: 'Muara Asam-asam',
          remarks:
            'Continue Pembuatan DudWeatheran ME\t\t\n' +
            'persiapan bubut flywheel ME\t\t\n' +
            'pembuatan housing connector ME-Gearbox\t\t',
          arrival: '08.00 15/9/2020',
          onGoingRepair: '08.00 8/4/2021',
          StoppageTime: ' 4.920 ',
          completeRepair: '14/04/2021'
        },
        {
          no: '2',
          fleet: 'BG. KANAYA',
          currentPosition: 'Sungai Danau',
          remarks:
            'Perbaikan sideboard, engine winch, anchor rack dan penggantian wire jangkar',
          arrival: '21.30 29/3/2021',
          onGoingRepair: '08.00 8/4/2021',
          StoppageTime: '  227  ',
          completeRepair: '30/04/2021'
        },
        {
          no: '3',
          fleet: 'TB. NAVARENO',
          currentPosition: 'Darmais',
          remarks: 'Menunggu FIP Pump Kalibrasi BJM Pump',
          arrival: '20.00 1/4/2021',
          onGoingRepair: '08.00 8/4/2021',
          StoppageTime: '  156 ',
          completeRepair: 'TBN'
        }
      ],
      shipyard: [
        {
          no: '1',
          fleet: 'TB. RAVA II',
          position: 'Citra Bahari Shipyard - Tegal',
          predocking: '23.00.00 09/10/2020',
          upSlip: '08.00 13/12/2020]',
          waitingUpSlip: '1.545',
          updateToday: '08.00 8/4/2021',
          dockingProgress: ' 4.329',
          downSlip: '',
          waitingDownSlip: '',
          completed: '',
          progress: '92%',
          remarks:
            '<b>OUT FITTING</b>' +
            'Install Rantai & Jangkarn' +
            'PIPING' +
            'PROPULTION & STEERING SYSTEM' +
            'NAVIGATION & ELECTRICAL' +
            'Continue Wiring Cable DC' +
            'Wiring Cable Data Main Engine P/S' +
            'CARPENTRY' +
            'Maintenance by crew' +
            'Continue Finishing Area Wheelhouse & Deck House' +
            'Continue Painting Area Engine Room Area' +
            'Continue Painting Area M Deck + Marking Ket Work & Internal Bulwark'
        },
        {
          no: '2',
          fleet: 'BG. ETI 3309',
          position: 'Karya Pacific Teknik - Samarinda',
          predocking: '08:00 14/2/2021',
          upSlip: '08:00 2/3/2021',
          waitingUpSlip: '384',
          updateToday: '08:00 8/4/2021',
          dockingProgress: '1,272',
          downSlip: '',
          waitingDownSlip: '',
          completed: '',
          progress: '99%',
          remarks:
            '1.Progress report mengacu dari update kapro PT KPT<br/>2.Pihak dari PT KPT belum submite update progress'
        },
        {
          no: '3',
          fleet: 'TB. RAVA III',
          position: 'Karya Pacific Teknik - Samarinda',
          predocking: '14:00 31/12/2020',
          upSlip: '08:00 10/1/2021',
          waitingUpSlip: '234',
          updateToday: '08:00 8/4/2021',
          dockingProgress: '2,346',
          downSlip: '',
          waitingDownSlip: '',
          completed: '',
          progress: '100%',
          remarks: 'Standby di dock PT KPT'
        },
        {
          no: '4',
          fleet: 'TB. ETI 3007',
          position: 'Citra Bahari Shipyard - Tegal',
          predocking: '03:00 18/1/2021',
          upSlip: '08:00 21/1/2021',
          waitingUpSlip: '77',
          updateToday: '08:00 8/4/2021',
          dockingProgress: '1,925',
          downSlip: '',
          waitingDownSlip: '',
          completed: '',
          progress: '99%',
          remarks: 'Standby Area Jetty'
        },
        {
          no: '5',
          fleet: 'TB. ETI 105',
          position: 'Citra Bahari Shipyard - Tegal',
          predocking: '12:00 11/2/2021',
          upSlip: '08:00 21/1/2021',
          waitingUpSlip: '1,340',
          updateToday: '08:00 8/4/2021',
          dockingProgress: '1,340',
          downSlip: '',
          waitingDownSlip: '',
          completed: '',
          progress: '77%',
          remarks:
            'Out Fitting:<br/>Hull Construction<br/>1.Additional job: Replating main deck setelah dilakWeatheran chiping oleh crew didapati<br/>main deck keropos<br/>Out fitting<br/>1.Pembuatan penyangga tutup terpal winch<br/>2.Pemasangan safety quick closing FO Day  valve (Finish)<br/>3.Pergantian seating out door AC (Finish)<br/>4.Pergantian rubber water tight door at whell house deck & Emergency exit crew room<br/>5.Doubling conduit pipe cable accu whell house <br/>6.Pergantian housing blower crew room'
        }
      ],
      loadingOpsMatrix: true,
      opsMatrix: {
        matrix: [],
        tripCargo: []
      },
      statisticAccordion: [
        {
          project: 'KPC Project - Direct Barging Sangatta',
          code: 'AccordionStatisticData1',
          visibility: true,
          data: [
            {
              category: 'Tug Boat',
              code: 'tb',
              total: '4',
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948'
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Running',
                    litres: 501.9,
                    fill: 'red'
                  },
                  {
                    country: 'Crewing',
                    litres: 165.8
                  },
                  {
                    country: 'Tech',
                    litres: 139.9
                  },
                  {
                    country: 'Waiting Loading',
                    litres: 128.3
                  },
                  {
                    country: 'Weather',
                    litres: 99
                  },
                  {
                    country: 'Ops',
                    litres: 60
                  }
                ]
              }
            },
            {
              category: 'Barge',
              code: 'bg',
              total: '4',
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948'
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Running',
                    litres: 501.9,
                    fill: 'red'
                  },
                  {
                    country: 'Crewing',
                    litres: 165.8
                  },
                  {
                    country: 'Tech',
                    litres: 139.9
                  },
                  {
                    country: 'Waiting Loading',
                    litres: 128.3
                  },
                  {
                    country: 'Weather',
                    litres: 99
                  },
                  {
                    country: 'Ops',
                    litres: 60
                  }
                ]
              }
            }
          ]
        },
        {
          project: 'KPC Project - Transhipment',
          code: 'AccordionStatisticData2',
          visibility: false,
          data: [
            {
              category: 'Tug Boat',
              code: 'tb',
              total: '3',
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948'
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Running',
                    litres: 501.9,
                    fill: 'red'
                  },
                  {
                    country: 'Crewing',
                    litres: 165.8
                  },
                  {
                    country: 'Tech',
                    litres: 139.9
                  },
                  {
                    country: 'Waiting Loading',
                    litres: 128.3
                  },
                  {
                    country: 'Weather',
                    litres: 99
                  },
                  {
                    country: 'Ops',
                    litres: 60
                  }
                ]
              }
            },
            {
              category: 'Barge',
              code: 'bg',
              total: '3',
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948'
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Running',
                    litres: 501.9,
                    fill: 'red'
                  },
                  {
                    country: 'Crewing',
                    litres: 165.8
                  },
                  {
                    country: 'Tech',
                    litres: 139.9
                  },
                  {
                    country: 'Waiting Loading',
                    litres: 128.3
                  },
                  {
                    country: 'Weather',
                    litres: 99
                  },
                  {
                    country: 'Ops',
                    litres: 60
                  }
                ]
              }
            }
          ]
        },
        {
          project: 'KPC Project - Heavy Equipment',
          code: 'kpc-he',
          visibility: true,
          data: [
            {
              category: 'Heavy Equipment',
              code: 'he',
              total: 2,
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948'
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Utility',
                    litres: 501.9,
                    fill: 'red'
                  },
                  {
                    country: 'Tech',
                    litres: 165.8
                  },
                  {
                    country: 'Standby for Use',
                    litres: 139.9
                  }
                ]
              }
            }
          ]
        },
        {
          project: 'AI Project - Direct Barging',
          code: 'ai-direct',
          visibility: false,
          data: [
            {
              category: 'Pusher Tug',
              code: 'pushertug',
              total: '2',
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948'
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Running',
                    litres: 501.9,
                    fill: 'red'
                  },
                  {
                    country: 'Crewing',
                    litres: 165.8
                  },
                  {
                    country: 'Tech',
                    litres: 139.9
                  },
                  {
                    country: 'Waiting Loading',
                    litres: 128.3
                  },
                  {
                    country: 'Weather',
                    litres: 99
                  },
                  {
                    country: 'Ops',
                    litres: 60
                  }
                ]
              }
            },
            {
              category: 'Pusher Barge',
              code: 'pusherbarge',
              total: '2',
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948'
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Running',
                    litres: 501.9,
                    fill: 'red'
                  },
                  {
                    country: 'Crewing',
                    litres: 165.8
                  },
                  {
                    country: 'Tech',
                    litres: 139.9
                  },
                  {
                    country: 'Waiting Loading',
                    litres: 128.3
                  },
                  {
                    country: 'Weather',
                    litres: 99
                  },
                  {
                    country: 'Ops',
                    litres: 60
                  }
                ]
              }
            }
          ]
        },
        {
          project: 'Nickel Project - Transhipment',
          code: 'nickel-transhipment',
          visibility: false,
          data: [
            {
              category: 'Tug Boat',
              code: 'tb',
              total: '3',
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948'
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Running',
                    litres: 501.9,
                    fill: 'red'
                  },
                  {
                    country: 'Crewing',
                    litres: 165.8
                  },
                  {
                    country: 'Tech',
                    litres: 139.9
                  },
                  {
                    country: 'Waiting Loading',
                    litres: 128.3
                  },
                  {
                    country: 'Weather',
                    litres: 99
                  },
                  {
                    country: 'Ops',
                    litres: 60
                  }
                ]
              }
            },
            {
              category: 'Barge',
              code: 'bg',
              total: '3',
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948'
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Running',
                    litres: 501.9,
                    fill: 'red'
                  },
                  {
                    country: 'Crewing',
                    litres: 165.8
                  },
                  {
                    country: 'Tech',
                    litres: 139.9
                  },
                  {
                    country: 'Waiting Loading',
                    litres: 128.3
                  },
                  {
                    country: 'Weather',
                    litres: 99
                  },
                  {
                    country: 'Ops',
                    litres: 60
                  }
                ]
              }
            }
          ]
        }
      ],
      statisticAccordionHours: [
        {
          project: 'KPC Project - Direct Barging Sangatta',
          code: 'AccordionStatisticDataHours1',
          visibility: true,
          data: [
            {
              category: 'Tug Boat',
              code: 'tb',
              total: '3',
              opsChart: {
                series: [
                  {
                    name: 'Reason Day',
                    data: [44, 55, 41, 67, 22, 43, 20, 33, 45, 31, 15, 14, 41]
                  }
                ],
                annotations: {
                  points: [
                    {
                      x: 'Bananas',
                      seriesIndex: 0,
                      label: {
                        borderColor: '#775DD0',
                        offsetY: 0,
                        style: {
                          color: '#fff',
                          background: '#775DD0'
                        },
                        text: 'Bananas are good'
                      }
                    }
                  ]
                },
                chart: {
                  height: 300,
                  type: 'bar'
                },
                colors: ['#089bab'],
                dataLabels: {
                  enabled: false
                },
                grid: {
                  row: {
                    colors: ['#fff', '#f2f2f2']
                  }
                },
                xaxis: {
                  labels: {
                    rotate: -45
                  },
                  categories: [
                    'Run',
                    'Tech',
                    'Dock',
                    'Weather',
                    'Ops',
                    'Wait Load',
                    'Wait Unload',
                    'Doc POL',
                    'Doc POD',
                    'Crewing',
                    'Finance',
                    'Loading',
                    'Unloading'
                  ],
                  tickPlacement: 'on'
                }
              }
            },
            {
              category: 'Barge',
              code: 'bg',
              total: '3',
              opsChart: {
                series: [
                  {
                    name: 'Reason Day',
                    data: [44, 55, 41, 67, 22, 43, 20, 33, 45, 31, 15, 14, 41]
                  }
                ],
                annotations: {
                  points: [
                    {
                      x: 'Bananas',
                      seriesIndex: 0,
                      label: {
                        borderColor: '#775DD0',
                        offsetY: 0,
                        style: {
                          color: '#fff',
                          background: '#775DD0'
                        },
                        text: 'Bananas are good'
                      }
                    }
                  ]
                },
                chart: {
                  height: 300,
                  type: 'bar'
                },
                colors: ['#089bab'],
                dataLabels: {
                  enabled: false
                },
                grid: {
                  row: {
                    colors: ['#fff', '#f2f2f2']
                  }
                },
                xaxis: {
                  labels: {
                    rotate: -45
                  },
                  categories: [
                    'Run',
                    'Tech',
                    'Dock',
                    'Weather',
                    'Ops',
                    'Wait Load',
                    'Wait Unload',
                    'Doc POL',
                    'Doc POD',
                    'Crewing',
                    'Finance',
                    'Loading',
                    'Unloading'
                  ],
                  tickPlacement: 'on'
                }
              }
            }
          ]
        },
        {
          project: 'KPC Project - Transhipment',
          code: 'AccordionStatisticDataHours2',
          visibility: false,
          data: [
            {
              category: 'Tug Boat',
              code: 'tb',
              total: '3',
              opsChart: {
                series: [
                  {
                    name: 'Reason Day',
                    data: [44, 55, 41, 67, 22, 43, 20, 33, 45, 31, 15, 14, 41]
                  }
                ],
                annotations: {
                  points: [
                    {
                      x: 'Bananas',
                      seriesIndex: 0,
                      label: {
                        borderColor: '#775DD0',
                        offsetY: 0,
                        style: {
                          color: '#fff',
                          background: '#775DD0'
                        },
                        text: 'Bananas are good'
                      }
                    }
                  ]
                },
                chart: {
                  height: 300,
                  type: 'bar'
                },
                colors: ['#089bab'],
                dataLabels: {
                  enabled: false
                },
                grid: {
                  row: {
                    colors: ['#fff', '#f2f2f2']
                  }
                },
                xaxis: {
                  labels: {
                    rotate: -45
                  },
                  categories: [
                    'Run',
                    'Tech',
                    'Dock',
                    'Weather',
                    'Ops',
                    'Wait Load',
                    'Wait Unload',
                    'Doc POL',
                    'Doc POD',
                    'Crewing',
                    'Finance',
                    'Loading',
                    'Unloading'
                  ],
                  tickPlacement: 'on'
                }
              }
            },
            {
              category: 'Barge',
              code: 'bg',
              total: '3',
              opsChart: {
                series: [
                  {
                    name: 'Reason Day',
                    data: [44, 55, 41, 67, 22, 43, 20, 33, 45, 31, 15, 14, 41]
                  }
                ],
                annotations: {
                  points: [
                    {
                      x: 'Bananas',
                      seriesIndex: 0,
                      label: {
                        borderColor: '#775DD0',
                        offsetY: 0,
                        style: {
                          color: '#fff',
                          background: '#775DD0'
                        },
                        text: 'Bananas are good'
                      }
                    }
                  ]
                },
                chart: {
                  height: 300,
                  type: 'bar'
                },
                colors: ['#089bab'],
                dataLabels: {
                  enabled: false
                },
                grid: {
                  row: {
                    colors: ['#fff', '#f2f2f2']
                  }
                },
                xaxis: {
                  labels: {
                    rotate: -45
                  },
                  categories: [
                    'Run',
                    'Tech',
                    'Dock',
                    'Weather',
                    'Ops',
                    'Wait Load',
                    'Wait Unload',
                    'Doc POL',
                    'Doc POD',
                    'Crewing',
                    'Finance',
                    'Loading',
                    'Unloading'
                  ],
                  tickPlacement: 'on'
                }
              }
            }
          ]
        },
        {
          project: 'AI Project - Direct Barging',
          code: 'AccordionStatisticDataHours3',
          visibility: false,
          data: [
            {
              category: 'Tug Boat',
              code: 'tb',
              total: '3',
              opsChart: {
                series: [
                  {
                    name: 'Reason Day',
                    data: [44, 55, 41, 67, 22, 43, 20, 33, 45, 31, 15, 14, 41]
                  }
                ],
                annotations: {
                  points: [
                    {
                      x: 'Bananas',
                      seriesIndex: 0,
                      label: {
                        borderColor: '#775DD0',
                        offsetY: 0,
                        style: {
                          color: '#fff',
                          background: '#775DD0'
                        },
                        text: 'Bananas are good'
                      }
                    }
                  ]
                },
                chart: {
                  height: 300,
                  type: 'bar'
                },
                colors: ['#089bab'],
                dataLabels: {
                  enabled: false
                },
                grid: {
                  row: {
                    colors: ['#fff', '#f2f2f2']
                  }
                },
                xaxis: {
                  labels: {
                    rotate: -45
                  },
                  categories: [
                    'Run',
                    'Tech',
                    'Dock',
                    'Weather',
                    'Ops',
                    'Wait Load',
                    'Wait Unload',
                    'Doc POL',
                    'Doc POD',
                    'Crewing',
                    'Finance',
                    'Loading',
                    'Unloading'
                  ],
                  tickPlacement: 'on'
                }
              }
            },
            {
              category: 'Barge',
              code: 'bg',
              total: '3',
              opsChart: {
                series: [
                  {
                    name: 'Reason Day',
                    data: [44, 55, 41, 67, 22, 43, 20, 33, 45, 31, 15, 14, 41]
                  }
                ],
                annotations: {
                  points: [
                    {
                      x: 'Bananas',
                      seriesIndex: 0,
                      label: {
                        borderColor: '#775DD0',
                        offsetY: 0,
                        style: {
                          color: '#fff',
                          background: '#775DD0'
                        },
                        text: 'Bananas are good'
                      }
                    }
                  ]
                },
                chart: {
                  height: 300,
                  type: 'bar'
                },
                colors: ['#089bab'],
                dataLabels: {
                  enabled: false
                },
                grid: {
                  row: {
                    colors: ['#fff', '#f2f2f2']
                  }
                },
                xaxis: {
                  labels: {
                    rotate: -45
                  },
                  categories: [
                    'Run',
                    'Tech',
                    'Dock',
                    'Weather',
                    'Ops',
                    'Wait Load',
                    'Wait Unload',
                    'Doc POL',
                    'Doc POD',
                    'Crewing',
                    'Finance',
                    'Loading',
                    'Unloading'
                  ],
                  tickPlacement: 'on'
                }
              }
            }
          ]
        },
        {
          project: 'KPC Project - Heavy Equipment',
          code: 'AccordionStatisticDataHours4',
          visibility: false,
          data: [
            {
              category: 'Heavy Equipment',
              code: 'he',
              total: '1',
              opsChart: {
                series: [
                  {
                    name: 'Reason Day',
                    data: [44, 55, 41]
                  }
                ],
                annotations: {
                  points: [
                    {
                      x: 'Bananas',
                      seriesIndex: 0,
                      label: {
                        borderColor: '#775DD0',
                        offsetY: 0,
                        style: {
                          color: '#fff',
                          background: '#775DD0'
                        },
                        text: 'Bananas are good'
                      }
                    }
                  ]
                },
                chart: {
                  height: 300,
                  type: 'bar'
                },
                colors: ['#089bab'],
                dataLabels: {
                  enabled: false
                },
                grid: {
                  row: {
                    colors: ['#fff', '#f2f2f2']
                  }
                },
                xaxis: {
                  labels: {
                    rotate: -45
                  },
                  categories: ['Utility', 'Tech', 'Standby for Use'],
                  tickPlacement: 'on'
                }
              }
            }
          ]
        },
        {
          project: 'Virtue Dragon Nickel Industry Project - Direct Barging',
          code: 'AccordionStatisticDataHours5',
          visibility: false,
          opsChart: {
            series: [
              {
                name: 'Reason Day',
                data: [44, 55, 41, 67, 22, 43, 21, 33, 45, 31, 15, 14, 41]
              }
            ],
            annotations: {
              points: [
                {
                  x: 'Bananas',
                  seriesIndex: 0,
                  label: {
                    borderColor: '#775DD0',
                    offsetY: 0,
                    style: {
                      color: '#fff',
                      background: '#775DD0'
                    },
                    text: 'Bananas are good'
                  }
                }
              ]
            },
            chart: {
              height: 300,
              type: 'bar'
            },
            colors: ['#089bab'],
            dataLabels: {
              enabled: false
            },
            grid: {
              row: {
                colors: ['#fff', '#f2f2f2']
              }
            },
            xaxis: {
              labels: {
                rotate: -45
              },
              categories: [
                'Run',
                'Tech',
                'Dock',
                'Weather',
                'Ops',
                'Waiting Loading',
                'Waiting Unloading',
                'Doc POL',
                'Doc POD',
                'Crewing',
                'Finance',
                'Loading',
                'Unloading'
              ],
              tickPlacement: 'on'
            }
          }
        }
      ],
      tripCargoStatus: [
        {
          project: 'KPC Project - Direct Barging Sangatta',
          code: 'kpc-direct',
          visibility: true,
          data: [
            {
              no: '1',
              fleet: 'TB.ETI 301 / BG.ETI 3601',
              date: '1 Mei 2021 - 24 Mei 2021',
              trip: '2',
              cargo: '23.943,12'
            },
            {
              no: '2',
              fleet: 'TB.ETI 302 / BG.ETI 3602',
              date: '1 Mei 2021 - 24 Mei 2021',
              trip: '1',
              cargo: '12.022,13'
            },
            {
              no: '3',
              fleet: 'TB.ANUGERAH 1 / BG.ETI 801',
              date: '1 Mei 2021 - 24 Mei 2021',
              trip: '7',
              cargo: '52.649,40'
            },
            {
              no: '4',
              fleet: 'TB.ETI 103 / BG.ETI 3003',
              date: '1 Mei 2021 - 24 Mei 2021',
              trip: '1',
              cargo: '7.518,74'
            }
          ]
        },
        {
          project: 'KPC Project - Transhipment',
          code: 'kpc-transhipment',
          visibility: false,
          data: [
            {
              no: '1',
              fleet: 'TB.NAVARENO / BG.ETI 3004',
              date: '1 Mei 2021 - 24 Mei 2021',
              trip: '3',
              cargo: '22.519,13'
            },
            {
              no: '2',
              fleet: 'TB.ETI 101 / BG.ETI 3301',
              date: '1 Mei 2021 - 24 Mei 2021',
              trip: '8',
              cargo: '76.536,26'
            }
          ]
        },
        {
          project: 'AI Project - Direct Barging',
          code: 'ai-direct',
          visibility: false,
          data: [
            {
              no: '1',
              fleet: 'TB.ETI 304 / BG.ETI 3302',
              date: '1 Mei 2021 - 24 Mei 2021',
              trip: '7',
              cargo: '57.131,08'
            },
            {
              no: '2',
              fleet: 'TB.ETI 308 / BG.ETI 3304',
              date: '1 Mei 2021 - 24 Mei 2021',
              trip: '3',
              cargo: '28.578,94'
            },
            {
              no: '9',
              fleet: 'TB.LOTUS 22-07 / BG.MHKL 35',
              date: '1 Mei 2021 - 24 Mei 2021',
              trip: '6',
              cargo: '42.784,80'
            }
          ]
        },
        {
          project: 'Nickel Project - Transhipment',
          code: 'nickel-transhipment',
          visibility: false,
          data: [
            {
              no: '10',
              fleet: 'TB.ETI 311 / BG.HM 303',
              date: '1 Mei 2021 - 24 Mei 2021',
              trip: '5',
              cargo: '34.630,66'
            }
          ]
        }
      ],
      tripStatistic: [
        {
          project: 'KPC Project - Direct Barging Sangatta',
          code: 'kpc-direct',
          visibility: true,
          tripChart: {
            series: [
              {
                name: 'Trip Statistic',
                data: [2, 1, 7, 1, 3, 8, 7, 3, 6, 5]
              }
            ],
            annotations: {
              points: [
                {
                  x: 'Bananas',
                  seriesIndex: 0,
                  label: {
                    borderColor: '#775DD0',
                    offsetY: 0,
                    style: {
                      color: '#fff',
                      background: '#775DD0'
                    },
                    text: 'Bananas are good'
                  }
                }
              ]
            },
            chart: {
              height: 350,
              type: 'bar'
            },
            colors: ['#089bab'],
            plotOptions: {
              bar: {
                columnWidth: '50%',
                endingShape: 'rounded'
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: 2
            },

            grid: {
              row: {
                colors: ['#fff', '#f2f2f2']
              }
            },
            xaxis: {
              labels: {
                rotate: -45
              },
              categories: [
                'TB.ETI 301 / BG.ETI 3601',
                'TB.ETI 302 / BG.ETI 3602',
                'TB.ANUGERAH 1 / BG.ETI 801',
                'TB.ETI 103 / BG.ETI 3003',
                'TB.NAVARENO / BG.ETI 3004',
                'TB.ETI 101 / BG.ETI 3301',
                'TB.ETI 304 / BG.ETI 3302',
                'TB.ETI 308 / BG.ETI 3304',
                'TB.LOTUS 22-07 / BG.MHKL 35',
                'TB.ETI 311 / BG.HM 303'
              ],
              tickPlacement: 'on'
            },
            yaxis: {
              title: {
                text: 'Trip Statistics - Transhipment Project'
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                type: 'horizontal',
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [50, 0, 100]
              }
            }
          }
        },
        {
          project: 'KPC Project - Transhipment',
          code: 'kpc-transhipment',
          visibility: true,
          tripChart: {
            series: [
              {
                name: 'Trip Statistic',
                data: [2, 1, 7, 1, 3, 8, 7, 3, 6, 5]
              }
            ],
            annotations: {
              points: [
                {
                  x: 'Bananas',
                  seriesIndex: 0,
                  label: {
                    borderColor: '#775DD0',
                    offsetY: 0,
                    style: {
                      color: '#fff',
                      background: '#775DD0'
                    },
                    text: 'Bananas are good'
                  }
                }
              ]
            },
            chart: {
              height: 350,
              type: 'bar'
            },
            colors: ['#089bab'],
            plotOptions: {
              bar: {
                columnWidth: '50%',
                endingShape: 'rounded'
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: 2
            },

            grid: {
              row: {
                colors: ['#fff', '#f2f2f2']
              }
            },
            xaxis: {
              labels: {
                rotate: -45
              },
              categories: [
                'TB.ETI 301 / BG.ETI 3601',
                'TB.ETI 302 / BG.ETI 3602',
                'TB.ANUGERAH 1 / BG.ETI 801',
                'TB.ETI 103 / BG.ETI 3003',
                'TB.NAVARENO / BG.ETI 3004',
                'TB.ETI 101 / BG.ETI 3301',
                'TB.ETI 304 / BG.ETI 3302',
                'TB.ETI 308 / BG.ETI 3304',
                'TB.LOTUS 22-07 / BG.MHKL 35',
                'TB.ETI 311 / BG.HM 303'
              ],
              tickPlacement: 'on'
            },
            yaxis: {
              title: {
                text: 'Trip Statistics - Transhipment Project'
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                type: 'horizontal',
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [50, 0, 100]
              }
            }
          }
        },
        {
          project: 'AI Project - Direct Barging',
          code: 'ai-direct',
          visibility: false,
          tripChart: {
            series: [
              {
                name: 'Trip Statistic',
                data: [2, 1, 7, 1, 3, 8, 7, 3, 6, 5]
              }
            ],
            annotations: {
              points: [
                {
                  x: 'Bananas',
                  seriesIndex: 0,
                  label: {
                    borderColor: '#775DD0',
                    offsetY: 0,
                    style: {
                      color: '#fff',
                      background: '#775DD0'
                    },
                    text: 'Bananas are good'
                  }
                }
              ]
            },
            chart: {
              height: 350,
              type: 'bar'
            },
            colors: ['#089bab'],
            plotOptions: {
              bar: {
                columnWidth: '50%',
                endingShape: 'rounded'
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: 2
            },

            grid: {
              row: {
                colors: ['#fff', '#f2f2f2']
              }
            },
            xaxis: {
              labels: {
                rotate: -45
              },
              categories: [
                'TB.ETI 301 / BG.ETI 3601',
                'TB.ETI 302 / BG.ETI 3602',
                'TB.ANUGERAH 1 / BG.ETI 801',
                'TB.ETI 103 / BG.ETI 3003',
                'TB.NAVARENO / BG.ETI 3004',
                'TB.ETI 101 / BG.ETI 3301',
                'TB.ETI 304 / BG.ETI 3302',
                'TB.ETI 308 / BG.ETI 3304',
                'TB.LOTUS 22-07 / BG.MHKL 35',
                'TB.ETI 311 / BG.HM 303'
              ],
              tickPlacement: 'on'
            },
            yaxis: {
              title: {
                text: 'Trip Statistics - Transhipment Project'
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                type: 'horizontal',
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [50, 0, 100]
              }
            }
          }
        },
        {
          project: 'Nickel Project - Transhipment',
          code: 'nickel-transhipment',
          visibility: false,
          tripChart: {
            series: [
              {
                name: 'Trip Statistic',
                data: [2, 1, 7, 1, 3, 8, 7, 3, 6, 5]
              }
            ],
            annotations: {
              points: [
                {
                  x: 'Bananas',
                  seriesIndex: 0,
                  label: {
                    borderColor: '#775DD0',
                    offsetY: 0,
                    style: {
                      color: '#fff',
                      background: '#775DD0'
                    },
                    text: 'Bananas are good'
                  }
                }
              ]
            },
            chart: {
              height: 350,
              type: 'bar'
            },
            colors: ['#089bab'],
            plotOptions: {
              bar: {
                columnWidth: '50%',
                endingShape: 'rounded'
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: 2
            },

            grid: {
              row: {
                colors: ['#fff', '#f2f2f2']
              }
            },
            xaxis: {
              labels: {
                rotate: -45
              },
              categories: [
                'TB.ETI 301 / BG.ETI 3601',
                'TB.ETI 302 / BG.ETI 3602',
                'TB.ANUGERAH 1 / BG.ETI 801',
                'TB.ETI 103 / BG.ETI 3003',
                'TB.NAVARENO / BG.ETI 3004',
                'TB.ETI 101 / BG.ETI 3301',
                'TB.ETI 304 / BG.ETI 3302',
                'TB.ETI 308 / BG.ETI 3304',
                'TB.LOTUS 22-07 / BG.MHKL 35',
                'TB.ETI 311 / BG.HM 303'
              ],
              tickPlacement: 'on'
            },
            yaxis: {
              title: {
                text: 'Trip Statistics - Transhipment Project'
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                type: 'horizontal',
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [50, 0, 100]
              }
            }
          }
        }
      ],
      cargoStatistic: [
        {
          project: 'KPC Project - Direct Barging Sangatta',
          code: 'kpc-direct',
          visibility: true,
          cargoChart: {
            series: [
              {
                name: 'Cargo Statistic',
                data: [
                  '23.943,12',
                  '12.022,13',
                  '52.649,40',
                  '7.518,74',
                  '22.519,13',
                  '76.536,26',
                  '57.131,08',
                  '28.578,94',
                  '42.784,80',
                  '34.630,66'
                ]
              }
            ],
            annotations: {
              points: [
                {
                  x: 'Bananas',
                  seriesIndex: 0,
                  label: {
                    borderColor: '#775DD0',
                    offsetY: 0,
                    style: {
                      color: '#fff',
                      background: '#775DD0'
                    },
                    text: 'Bananas are good'
                  }
                }
              ]
            },
            chart: {
              height: 350,
              type: 'bar'
            },
            colors: ['#089bab'],
            plotOptions: {
              bar: {
                columnWidth: '50%',
                endingShape: 'rounded'
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: 2
            },

            grid: {
              row: {
                colors: ['#fff', '#f2f2f2']
              }
            },
            xaxis: {
              labels: {
                rotate: -45
              },
              categories: [
                'TB.ETI 301 / BG.ETI 3601',
                'TB.ETI 302 / BG.ETI 3602',
                'TB.ANUGERAH 1 / BG.ETI 801',
                'TB.ETI 103 / BG.ETI 3003',
                'TB.NAVARENO / BG.ETI 3004',
                'TB.ETI 101 / BG.ETI 3301',
                'TB.ETI 304 / BG.ETI 3302',
                'TB.ETI 308 / BG.ETI 3304',
                'TB.LOTUS 22-07 / BG.MHKL 35',
                'TB.ETI 311 / BG.HM 303'
              ],
              tickPlacement: 'on'
            },
            yaxis: {
              title: {
                text: 'Cargo Statistics - Transhipment Project'
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                type: 'horizontal',
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [50, 0, 100]
              }
            }
          }
        },
        {
          project: 'KPC Project - Transhipment',
          code: 'kpc-transhipment',
          visibility: true,
          cargoChart: {
            series: [
              {
                name: 'Cargo Statistic',
                data: [
                  '23.943,12',
                  '12.022,13',
                  '52.649,40',
                  '7.518,74',
                  '22.519,13',
                  '76.536,26',
                  '57.131,08',
                  '28.578,94',
                  '42.784,80',
                  '34.630,66'
                ]
              }
            ],
            annotations: {
              points: [
                {
                  x: 'Bananas',
                  seriesIndex: 0,
                  label: {
                    borderColor: '#775DD0',
                    offsetY: 0,
                    style: {
                      color: '#fff',
                      background: '#775DD0'
                    },
                    text: 'Bananas are good'
                  }
                }
              ]
            },
            chart: {
              height: 350,
              type: 'bar'
            },
            colors: ['#089bab'],
            plotOptions: {
              bar: {
                columnWidth: '50%',
                endingShape: 'rounded'
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: 2
            },

            grid: {
              row: {
                colors: ['#fff', '#f2f2f2']
              }
            },
            xaxis: {
              labels: {
                rotate: -45
              },
              categories: [
                'TB.ETI 301 / BG.ETI 3601',
                'TB.ETI 302 / BG.ETI 3602',
                'TB.ANUGERAH 1 / BG.ETI 801',
                'TB.ETI 103 / BG.ETI 3003',
                'TB.NAVARENO / BG.ETI 3004',
                'TB.ETI 101 / BG.ETI 3301',
                'TB.ETI 304 / BG.ETI 3302',
                'TB.ETI 308 / BG.ETI 3304',
                'TB.LOTUS 22-07 / BG.MHKL 35',
                'TB.ETI 311 / BG.HM 303'
              ],
              tickPlacement: 'on'
            },
            yaxis: {
              title: {
                text: 'Cargo Statistics - Transhipment Project'
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                type: 'horizontal',
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [50, 0, 100]
              }
            }
          }
        },
        {
          project: 'AI Project - Direct Barging',
          code: 'ai-direct',
          visibility: false,
          cargoChart: {
            series: [
              {
                name: 'Cargo Statistic',
                data: [
                  '23.943,12',
                  '12.022,13',
                  '52.649,40',
                  '7.518,74',
                  '22.519,13',
                  '76.536,26',
                  '57.131,08',
                  '28.578,94',
                  '42.784,80',
                  '34.630,66'
                ]
              }
            ],
            annotations: {
              points: [
                {
                  x: 'Bananas',
                  seriesIndex: 0,
                  label: {
                    borderColor: '#775DD0',
                    offsetY: 0,
                    style: {
                      color: '#fff',
                      background: '#775DD0'
                    },
                    text: 'Bananas are good'
                  }
                }
              ]
            },
            chart: {
              height: 350,
              type: 'bar'
            },
            colors: ['#089bab'],
            plotOptions: {
              bar: {
                columnWidth: '50%',
                endingShape: 'rounded'
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: 2
            },

            grid: {
              row: {
                colors: ['#fff', '#f2f2f2']
              }
            },
            xaxis: {
              labels: {
                rotate: -45
              },
              categories: [
                'TB.ETI 301 / BG.ETI 3601',
                'TB.ETI 302 / BG.ETI 3602',
                'TB.ANUGERAH 1 / BG.ETI 801',
                'TB.ETI 103 / BG.ETI 3003',
                'TB.NAVARENO / BG.ETI 3004',
                'TB.ETI 101 / BG.ETI 3301',
                'TB.ETI 304 / BG.ETI 3302',
                'TB.ETI 308 / BG.ETI 3304',
                'TB.LOTUS 22-07 / BG.MHKL 35',
                'TB.ETI 311 / BG.HM 303'
              ],
              tickPlacement: 'on'
            },
            yaxis: {
              title: {
                text: 'Cargo Statistics - Transhipment Project'
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                type: 'horizontal',
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [50, 0, 100]
              }
            }
          }
        },
        {
          project: 'Nickel Project - Transhipment',
          code: 'nickel-transhipment',
          visibility: false,
          cargoChart: {
            series: [
              {
                name: 'Cargo Statistic',
                data: [
                  '23.943,12',
                  '12.022,13',
                  '52.649,40',
                  '7.518,74',
                  '22.519,13',
                  '76.536,26',
                  '57.131,08',
                  '28.578,94',
                  '42.784,80',
                  '34.630,66'
                ]
              }
            ],
            annotations: {
              points: [
                {
                  x: 'Bananas',
                  seriesIndex: 0,
                  label: {
                    borderColor: '#775DD0',
                    offsetY: 0,
                    style: {
                      color: '#fff',
                      background: '#775DD0'
                    },
                    text: 'Bananas are good'
                  }
                }
              ]
            },
            chart: {
              height: 350,
              type: 'bar'
            },
            colors: ['#089bab'],
            plotOptions: {
              bar: {
                columnWidth: '50%',
                endingShape: 'rounded'
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: 2
            },

            grid: {
              row: {
                colors: ['#fff', '#f2f2f2']
              }
            },
            xaxis: {
              labels: {
                rotate: -45
              },
              categories: [
                'TB.ETI 301 / BG.ETI 3601',
                'TB.ETI 302 / BG.ETI 3602',
                'TB.ANUGERAH 1 / BG.ETI 801',
                'TB.ETI 103 / BG.ETI 3003',
                'TB.NAVARENO / BG.ETI 3004',
                'TB.ETI 101 / BG.ETI 3301',
                'TB.ETI 304 / BG.ETI 3302',
                'TB.ETI 308 / BG.ETI 3304',
                'TB.LOTUS 22-07 / BG.MHKL 35',
                'TB.ETI 311 / BG.HM 303'
              ],
              tickPlacement: 'on'
            },
            yaxis: {
              title: {
                text: 'Cargo Statistics - Transhipment Project'
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                type: 'horizontal',
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 0.85,
                opacityTo: 0.85,
                stops: [50, 0, 100]
              }
            }
          }
        }
      ],
      tripChart: {
        series: [
          {
            name: 'Trip Statistic',
            data: [2, 1, 7, 1, 3, 8, 7, 3, 6, 5]
          }
        ],
        annotations: {
          points: [
            {
              x: 'Bananas',
              seriesIndex: 0,
              label: {
                borderColor: '#775DD0',
                offsetY: 0,
                style: {
                  color: '#fff',
                  background: '#775DD0'
                },
                text: 'Bananas are good'
              }
            }
          ]
        },
        chart: {
          height: 350,
          type: 'bar'
        },
        colors: ['#089bab'],
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },

        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: [
            'TB.ETI 301 / BG.ETI 3601',
            'TB.ETI 302 / BG.ETI 3602',
            'TB.ANUGERAH 1 / BG.ETI 801',
            'TB.ETI 103 / BG.ETI 3003',
            'TB.NAVARENO / BG.ETI 3004',
            'TB.ETI 101 / BG.ETI 3301',
            'TB.ETI 304 / BG.ETI 3302',
            'TB.ETI 308 / BG.ETI 3304',
            'TB.LOTUS 22-07 / BG.MHKL 35',
            'TB.ETI 311 / BG.HM 303'
          ],
          tickPlacement: 'on'
        },
        yaxis: {
          title: {
            text: 'Trip Statistics - Transhipment Project'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          }
        }
      },
      cargoChart: {
        series: [
          {
            name: 'Cargo Statistic',
            data: [
              '23.943,12',
              '12.022,13',
              '52.649,40',
              '7.518,74',
              '22.519,13',
              '76.536,26',
              '57.131,08',
              '28.578,94',
              '42.784,80',
              '34.630,66'
            ]
          }
        ],
        annotations: {
          points: [
            {
              x: 'Bananas',
              seriesIndex: 0,
              label: {
                borderColor: '#775DD0',
                offsetY: 0,
                style: {
                  color: '#fff',
                  background: '#775DD0'
                },
                text: 'Bananas are good'
              }
            }
          ]
        },
        chart: {
          height: 350,
          type: 'bar'
        },
        colors: ['#089bab'],
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },

        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: [
            'TB.ETI 301 / BG.ETI 3601',
            'TB.ETI 302 / BG.ETI 3602',
            'TB.ANUGERAH 1 / BG.ETI 801',
            'TB.ETI 103 / BG.ETI 3003',
            'TB.NAVARENO / BG.ETI 3004',
            'TB.ETI 101 / BG.ETI 3301',
            'TB.ETI 304 / BG.ETI 3302',
            'TB.ETI 308 / BG.ETI 3304',
            'TB.LOTUS 22-07 / BG.MHKL 35',
            'TB.ETI 311 / BG.HM 303'
          ],
          tickPlacement: 'on'
        },
        yaxis: {
          title: {
            text: 'Cargo Statistics - Transhipment Project'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          }
        }
      },
      opsChart: {
        series: [
          {
            name: 'Reason Day',
            data: [44, 55, 41, 67, 22, 43, 21, 33, 45, 31, 15, 14, 41]
          }
        ],
        annotations: {
          points: [
            {
              x: 'Bananas',
              seriesIndex: 0,
              label: {
                borderColor: '#775DD0',
                offsetY: 0,
                style: {
                  color: '#fff',
                  background: '#775DD0'
                },
                text: 'Bananas are good'
              }
            }
          ]
        },
        chart: {
          height: 300,
          type: 'bar'
        },
        colors: ['#089bab'],
        dataLabels: {
          enabled: false
        },
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: [
            'Run',
            'Tech',
            'Dock',
            'Weather',
            'Ops',
            'Waiting Loading',
            'Waiting Unloading',
            'Doc POL',
            'Doc POD',
            'Crewing',
            'Finance',
            'Loading',
            'Unloading'
          ],
          tickPlacement: 'on'
        }
      },
      slickOptions: {
        centerMode: false,
        centerPadding: '60px',
        slidesToShow: 5,
        slidesToScroll: 1,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '30',
              slidesToShow: 3
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '15',
              slidesToShow: 1
            }
          }
        ],
        nextArrow: '<a href="#" class="ri-arrow-left-s-line left"></a>',
        prevArrow: '<a href="#" class="ri-arrow-right-s-line right"></a>'
      },
      fleetList: [
        {
          name: 'TB.ETI 301',
          img: require('../../assets/images/Km3FO09.png'),
          run: '545 Hours',
          position: '#1'
        },
        {
          name: 'TB.TCP 207',
          img: require('../../assets/images/Km3FO09.png'),
          run: '538 Hours',
          position: '#2'
        },
        {
          name: 'BG.TCP 3303',
          img: require('../../assets/images/Barge.png'),
          run: '530 Hours',
          position: '#3'
        },
        {
          name: 'TB.SML 103',
          img: require('../../assets/images/Km3FO09.png'),
          run: '510 Hours',
          position: '#4'
        },
        {
          name: 'BG.Navareno 2002',
          img: require('../../assets/images/Barge.png'),
          run: '480 Hours',
          position: '#5'
        },
        {
          name: 'TB.Navareno 1',
          img: require('../../assets/images/Km3FO09.png'),
          run: '460 Hours',
          position: '#6'
        }
      ],
      iconSize: [62, 67],
      iconAnchor: [16, 37],
      params: {
        ownership: '',
        search: '',
        page: 1,
        active: true,
        showAll: true
      },
      maintenance: {
        dateRange: [new Date(moment().subtract(1, 'months').startOf('month')), new Date(moment().endOf('month'))],
        isLoading: false,
        params: {
          action: '',
          search: '',
          status: 'OVERDUE',
          startDate: null,
          endDate: null,
          perPage: 250,
          page: 1
        },
        data: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      fleetActivities: [],
      vehicleTypes: [],
      statsCertificateData: {
        totalCertificate: null,
        totalExpired: null,
        totalOneMonth: null,
        totalProcess: null
      },
      filterDailyOps: {
        date: moment().format('YYYY-MM-DD'),
        ownership: 'OWNED',
        subProject: [],
        vehicleTypeIds: [1, 2]
      },
      filterMonthlyOps: {
        dateRange: [],
        ownership: 'OWNED',
        subProject: [],
        vehicleTypeIds: [1, 2]
      },
      dailyOpsData: [],
      isFetchChart: false,
      fleetDetail: null,
      reasons:[],
      lsProfile: JSON.parse(localStorage.getItem('profile'))
      
    }
  },
  computed: {
    role () {
      return this.lsProfile && this.lsProfile.role ? this.lsProfile.role.roles.toUpperCase() : ''
    },
    technicalPermission () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Technical')
    },
    operationalPermission () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Operations')
    },
    reportPermission () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Report')
    }
  },
  async mounted () {
    this.initFilterDateRange()
    await this.fetchReasons()
    await this.fetchFleetTypes()
    await this.getProjectList()
    this.fetchFleetActivities()
    this.getMaintenanceList()
    await this.fetchStatsCertificate()
    await this.fetchDailyOps()
    await this.fetchMonthlyOps()
    console.log(this.findMenuPermission())
  },
  destroyed () {
    /* body[0].classList.remove('sidebar-main-menu') */
  },
  methods: {
    ...fleetsActions,
    ...dashboardActions,
    ...projectsActions,
    ...maintenanceActions,
    ...reasonsActions,
    validateTechnicalPermission (url) {
      let getPermission = this.technicalPermission ? this.technicalPermission.child.find(menu => menu.url === url) : null
      return getPermission ? getPermission.active : false
    },
    validateOperationalPermission (url) {
      let getPermission = this.operationalPermission ? this.operationalPermission.child.find(menu => menu.url === url) : null
      return getPermission ? getPermission.active : false
    },
    validateReportPermission (url) {
      let getPermission = this.reportPermission ? this.reportPermission.child.find(menu => menu.url === url) : null
      return getPermission ? getPermission.active : false
    },
    numberFormat (number) {
      return numberFormat(number)
    },
    addPower () {
      this.formPower.typeOfPower.push(
        {
          type: '',
          series: '',
          actual: ''
        }
      )
    },
    removePower (index) {
      this.formPower.typeOfPower.splice(index, 1)
    },
    addROBType () {
      this.formROBType.typeOfROB.push(
        {
          type: '',
          actual: ''
        }
      )
    },
    removeROBType (index) {
      this.formROBType.typeOfROB.splice(index, 1)
    },
    addFileSupportList () {
      this.formFileSupportList.fileSupport.push(
        {
          name: ''
        }
      )
    },
    removeFileSupportList (index) {
      this.formFileSupportList.fileSupport.splice(index, 1)
    },
    zoomUpdate (zoom) {
      this.zoom = zoom
    },
    centerUpdate (center) {
      this.center = center
    },
    generateChartData () {
      let chartData = []
      let firstDate = new Date()
      firstDate.setDate(firstDate.getDate() - 1000)
      let visits = 1200
      for (let i = 0; i < 500; i++) {
        let newDate = new Date(firstDate)
        newDate.setDate(newDate.getDate() + i)

        visits += Math.round(
          (Math.random() < 0.5 ? 1 : -1) * Math.random() * 10
        )

        chartData.push({
          date: newDate,
          visits: visits
        })
      }
      return chartData
    },
    async getMaintenanceList () {
      this.maintenance.isLoading = true
      this.maintenance.data = []

      const paramsQ = {
        search: this.maintenance.params.search,
        perPage: this.maintenance.params.perPage,
        page: this.maintenance.params.page,
        status: this.maintenance.params.status
      }

      if (paramsQ.search === '') {
        delete paramsQ.search
      }

      const res = await this.getMaintenance(paramsQ)

      if (res.status === 'success') {
        if (res.data.data && res.data.data.length > 0) {
          const groupData = [...new Set(res.data.data?.map(item => item.vehicleName))]?.map(group => {
            return {
              group: group,
              jobs: res.data.data.filter(maintenance => maintenance.vehicleName === group)
            }
          })

          this.maintenance.data = groupData
          this.maintenance.meta.perPage = res.data.perPage
          this.maintenance.meta.currentPage = res.data.currentPage
          this.maintenance.meta.total = res.data.total
          this.maintenance.meta.totalPage = res.data.totalPage
        }
        this.maintenance.isLoading = false
      } else {
        this.maintenance.data = []
        this.maintenance.isLoading = false
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async fetchFleetActivities () {
      let params = { ...this.params }

      if (this.vehicleTypes.length > 0) {
        params.vehicleTypeId = this.vehicleTypes.filter(type => type.checked)?.map(type => type.id).join(',')
      }

      params = this.$options.filters.cleanObject(params)

      let res = await this.getFleetActivities(params)

      this.fleetActivities = res.data
    },
    async fetchFleetTypes () {
      let params = { active: true }
      let res = await this.getFleetTypes(params)

      let data = []

      if (res.data) {
        res.data.forEach(f => {
          data.push({
            ...f,
            checked: true,
            disabled: false
          })
          this.vehicle.push({
            value: f.id,
            text: f.name,
            disabled: false
          })
        })
      }

      this.vehicleTypes = data
    },
    async fetchReasons() {
      let params = {
        active: true,
        perPage: 50,
        page: 1
      }

      let res = await this.getReasons(params)
      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.reasons = res.data.data
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async fetchFleetDetail (id) {
      const res = await this.getFleetById(id)

      if (res.status === 'success') {
        this.fleetDetail = res.data
        this.$bvModal.show('edit-fleet')
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async onDeleteFleet (dataVal) {
      let data = dataVal

      this.$swal({
        title: 'Delete Fleet?',
        text: `Fleet ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteFleet(data.id)
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.fetchFleetActivities()
            this.$swal(
              `Fleet deleted!`,
              `Fleet ${data.name} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    checkedFleetTypes (array) {
      let checked = []

      this.vehicleTypes.forEach(fleet => {
        if (fleet.checked) {
          checked.push(fleet.id)
        }
      })

      return checked
    },
    getLatLng (lat, lng) {
      return latLng(lat, lng)
    },
    getIcon (icon) {
      if (!icon || !icon.url) {
        return 'fleet/PusherTug.png'
      }

      return this.$options.filters.checkPhoto(icon.url, 'fleet/PusherTug.png')
    },

    async fetchStatsCertificate () {
      let res = await this.getStatsCertificate()
      if (res.data) {
        const data = res.data
        this.statsCertificateData.totalCertificate = data.totalCertificate
        this.statsCertificateData.totalExpired = data.totalExpired
        this.statsCertificateData.totalOneMonth = data.totalOneMonth
        this.statsCertificateData.totalProcess = data.totalProcess
      }
    },
    async fetchDailyOps () {
      let subProjectVal = []
      this.dailyOpsData = []
      if (this.filterDailyOps?.subProject?.length !== 0) {
        this.filterDailyOps?.subProject?.forEach(el => {
          subProjectVal.push(el.id)
        })
      }
      const params = {
        date: this.filterDailyOps.date,
        ownership: this.filterDailyOps.ownership,
        subProjectIds: subProjectVal.toString(),
        vehicleTypeIds: this.filterDailyOps.vehicleTypeIds.toString()
      }
      if (this.filterDailyOps.ownership === '' || this.filterDailyOps.ownership === null) delete params.ownership
      if (this.filterDailyOps.subProject.length === 0) delete params.subProjectIds
      if (params.date) params.date = moment(params.date).format('DD-MM-YYYY')

      let res = await this.getDailyOps(params)

      console.log(this.filterDailyOps.subProject)
      if (res.data) {
        const data = res.data
        // this.dailyOpsData = data;
        data.forEach(res => {
          if (res.voyage && res.voyage.length > 0) {
            this.dailyOpsData.push(res)
          }
        })
      }
    },
    async exportDailyOps () {
      let subProjectVal = []
      if (this.filterDailyOps.subProject.length !== 0) {
        this.filterDailyOps.subProject.forEach(el => {
          subProjectVal.push(el.id)
        })
      }
      const params = {
        date: this.filterDailyOps.date,
        ownership: this.filterDailyOps.ownership,
        subProjectIds: subProjectVal.toString(),
        vehicleTypeIds: this.filterDailyOps.vehicleTypeIds.toString()
      }
      if (this.filterDailyOps.ownership === '' || this.filterDailyOps.ownership === null) delete params.ownership
      if (this.filterDailyOps.subProject.length === 0) delete params.subProjectIds
      if (params.date) params.date = moment(params.date).format('DD-MM-YYYY')

      let res = await this.getDailyOpsExport(params)

      if (res.data) {
        let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        let url = window.URL.createObjectURL(blob)
        window.open(url)
      }
    },
    async fetchMonthlyOps() {
      if(this.reasons.length <= 0) return

      let params = {
        startDate: moment(this.filterMonthlyOps.dateRange[0]).format('DD-MM-YYYY'),
        endDate: moment(this.filterMonthlyOps.dateRange[1]).format('DD-MM-YYYY'),
        ownership: this.filterMonthlyOps.ownership,
        vehicleTypeIds: this.filterMonthlyOps.vehicleTypeIds.toString()
      }

      console.log(this.filterMonthlyOps?.subProject, "this.filterMonthlyOps?.subProject")
      if (this.filterMonthlyOps?.subProject && this.filterMonthlyOps?.subProject?.length > 0) {
        let dataTemp = []
        this.filterMonthlyOps?.subProject?.forEach(el => {
          dataTemp.push(el.id)
        })
        params.subProjectIds = dataTemp.toString()
      }
      this.loadingOpsMatrix = true
      let res = await this.getMonthlyOps(params)
      if (res.status == 'success') {
        if (res.data) {
          const new_data = {
            matrix: null,
            tripCargo:res.data.tripCargo
          }
          let tempMatrix = [...res.data.matrix]
  
          new_data.matrix = tempMatrix?.map((matrix,index) => {
            matrix.vehicleTypes?.map((vehicleType,i) => {
              vehicleType.vehicles?.map((vehicle) => {
                let tempArr = []
                if(vehicle?.activities?.length > 0){
                  vehicle?.activities?.map(activity => {
                    this.reasons?.map((reason) => {
                      if(activity.name === reason.name && reason.active === true){
                        tempArr.push(activity)
                      } 
                    })
                  })
                  const uniqueData = tempArr.reduce((acc, curr) => {
                    // Check if the current object's id already exists in the accumulator
                    if (acc[curr.name]) {
                      // If the current object has a non-zero value, replace the existing object in the accumulator
                      if (curr.duration !== 0) {
                        acc[curr.name] = curr;
                      }
                    } else {
                      // Add the current object to the accumulator
                      acc[curr.name] = curr;
                    }
                    return acc;
                  }, {});
                  // Convert the object back to an array of objects
                  const result = Object.values(uniqueData);

                  vehicle.activities = result
                }
        
                return vehicle
              })
              return vehicleType
            })
            return matrix
          })

          const data = new_data

          this.opsMatrix = {
            matrix: [],
            tripCargo: []
          }

          setTimeout(() => {
            data.matrix.forEach((el, i) => {
              el.vehicleTypes.forEach((el2, el2Index) => {
                el2.bodyData = {
                  type: 'pie',
                  colors: [
                    '#0084ff',
                    '#00ca00',
                    '#e64141',
                    '#ffd400',
                    '#00d0ff',
                    '#374948'
                  ],
                  value: ['duration'],
                  category: ['name'],
                  data: el2.vehicles[el2Index]?.activities
                }

                el.TypeVehicleOpt = []

                let dataValBarChart = []
                let dataHeaderBarChart = []

                el2.vehicles[0]?.activities?.forEach(el3 => {
                  dataValBarChart.push(el3.duration)
                  dataHeaderBarChart.push(el3.name)
                  el.TypeVehicleOpt.push(el3)
                })

                el2.opsChart = {
                  series: [
                    {
                      name: 'Reason Day',
                      data: dataValBarChart
                    }
                  ],
                  annotations: {
                    points: [
                      {
                        x: 'Bananas',
                        seriesIndex: 0,
                        label: {
                          borderColor: '#775DD0',
                          offsetY: 0,
                          style: {
                            color: '#fff',
                            background: '#775DD0'
                          },
                          text: 'Bananas are good'
                        }
                      }
                    ]
                  },
                  chart: {
                    height: 300,
                    type: 'bar'
                  },
                  colors: ['#089bab'],
                  dataLabels: {
                    enabled: false
                  },
                  grid: {
                    row: {
                      colors: ['#fff', '#f2f2f2']
                    }
                  },
                  xaxis: {
                    labels: {
                      rotate: -45
                    },
                    categories: dataHeaderBarChart,
                    tickPlacement: 'on'
                  }
                }
                el2.loadingChart = false
              })
              el.visibility = false
              if (i == 0) el.visibility = true
            })
            data.tripCargo.forEach((el, i) => {
              el.visibility = false
              el.visibilityTripStats = false
              el.visibilityStats = false
              if (i == 0) {
                el.visibility = true
                el.visibilityTripStats = true
                el.visibilityStats = true
              }
              el.totalTripStatus = 0
              el.totalCargoStatus = 0
              let dataValTripStatsChart = []
              let dataValCargoStatsChart = []
              let dataHeaderTripStatsChart = []
              el.trip.forEach(el2 => {
                dataValTripStatsChart.push(el2.tripStatus)
                dataValCargoStatsChart.push(el2.cargoStatus)
                let nameTrip = ''
                el2.vehicle.forEach((el3, idx3) => {
                  if (idx3 > 0) {
                    nameTrip = nameTrip + ' / ' + el3.name
                  } else {
                    nameTrip = el3.name
                  }
                })
                dataHeaderTripStatsChart.push(nameTrip)

                el.totalTripStatus += parseInt(el2.tripStatus)
                el.totalCargoStatus += parseInt(el2.cargoStatus)
              })

              el.tripChart = {
                series: [
                  {
                    name: 'Trip Statistic',
                    data: dataValTripStatsChart
                  }
                ],
                annotations: {
                  points: [
                    {
                      x: 'Bananas',
                      seriesIndex: 0,
                      label: {
                        borderColor: '#775DD0',
                        offsetY: 0,
                        style: {
                          color: '#fff',
                          background: '#775DD0'
                        },
                        text: 'Bananas are good'
                      }
                    }
                  ]
                },
                chart: {
                  height: 350,
                  type: 'bar'
                },
                colors: ['#089bab'],
                plotOptions: {
                  bar: {
                    columnWidth: '50%',
                    endingShape: 'rounded'
                  }
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  width: 2
                },

                grid: {
                  row: {
                    colors: ['#fff', '#f2f2f2']
                  }
                },
                xaxis: {
                  labels: {
                    rotate: -45
                  },
                  categories: dataHeaderTripStatsChart,
                  tickPlacement: 'on'
                },
                yaxis: {
                  title: {
                    text: 'Trip Statistics - Transhipment Project'
                  }
                },
                fill: {
                  type: 'gradient',
                  gradient: {
                    shade: 'light',
                    type: 'horizontal',
                    shadeIntensity: 0.25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [50, 0, 100]
                  }
                }
              }

              el.cargoChart = {
                series: [
                  {
                    name: 'Cargo Statistic',
                    data: dataValCargoStatsChart
                  }
                ],
                annotations: {
                  points: [
                    {
                      x: 'Bananas',
                      seriesIndex: 0,
                      label: {
                        borderColor: '#775DD0',
                        offsetY: 0,
                        style: {
                          color: '#fff',
                          background: '#775DD0'
                        },
                        text: 'Bananas are good'
                      }
                    }
                  ]
                },
                chart: {
                  height: 350,
                  type: 'bar'
                },
                colors: ['#089bab'],
                plotOptions: {
                  bar: {
                    columnWidth: '50%',
                    endingShape: 'rounded'
                  }
                },
                dataLabels: {
                  enabled: false
                },
                stroke: {
                  width: 2
                },

                grid: {
                  row: {
                    colors: ['#fff', '#f2f2f2']
                  }
                },
                xaxis: {
                  labels: {
                    rotate: -45
                  },
                  categories: dataHeaderTripStatsChart,
                  tickPlacement: 'on'
                },
                yaxis: {
                  title: {
                    text: 'Cargo Statistics - Transhipment Project'
                  }
                },
                fill: {
                  type: 'gradient',
                  gradient: {
                    shade: 'light',
                    type: 'horizontal',
                    shadeIntensity: 0.25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [50, 0, 100]
                  }
                }
              }
            })

            this.opsMatrix = data

            this.loadingOpsMatrix = false
          }, 500)
          this.loadingOpsMatrix = false
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async exportMonthlyOps () {
      let params = {
        startDate: moment(this.filterMonthlyOps.dateRange[0]).format('DD-MM-YYYY'),
        endDate: moment(this.filterMonthlyOps.dateRange[1]).format('DD-MM-YYYY'),
        ownership: this.filterMonthlyOps.ownership,
        vehicleTypeIds: this.filterMonthlyOps.vehicleTypeIds.toString()
      }

      if (this.filterMonthlyOps?.subProject && this.filterMonthlyOps?.subProject?.length > 0) {
        let dataTemp = []
        this.filterMonthlyOps?.subProject?.forEach(el => {
          dataTemp.push(el.id)
        })
        params.subProjectIds = dataTemp.toString()
      }

      let res = await this.getMonthlyOpsExport(params)
      if (res.data) {
        let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        let url = window.URL.createObjectURL(blob)
        window.open(url)
      }
    },

    async getProjectList () {
      let params = {
        active: true,
        search: '',
        page: 1,
        perPage: 250
      }
      const res = await this.getProjects(params)
      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(val => {
            this.projectOptions.push({
              project: val.projectName,
              sub: val.subProject
            })
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data.data,
          'error'
        )
      }
    },

    initFilterDateRange () {
      var date = new Date(); var y = date.getFullYear(); var m = date.getMonth()
      var firstDay = new Date(y, m, 1)
      var lastDay = new Date(y, m + 1, 0)

      this.filterMonthlyOps.dateRange = [firstDay, lastDay]
    },
    formatDate (val) {
      if (val) {
        // let dateVal = `${val.split('-')[2]}-${val.split('-')[1]}-${val.split('-')[0]}`
        return moment(val).format('DD MMMM YYYY')
      }
    },
    showTextOwnership (val) {
      if (val) {
        let idxFind = this.categoryOwnership.findIndex(val => val.value === this.filterMonthlyOps.ownership)
        return this.categoryOwnership[idxFind].text
      }
    },
    showTextVehicleType (val) {
      if (val && val.length > 0) {
        let textShow = ''
        this.filterMonthlyOps.vehicleTypeIds.forEach(el => {
          let idxFind = this.vehicle.findIndex(val => val.value === el)
          if (idxFind !== -1) {
            textShow += `${this.vehicle[idxFind].text}, `
          }
        })
        return textShow
      }
    },

    onChange (e, i, j) {
      this.opsMatrix.matrix[i].vehicleTypes[j].bodyData.data = e
    },
    onChangeBarChart (e, i, j) {
      this.opsMatrix.matrix[i].vehicleTypes[j].loadingChart = true

      setTimeout(() => {
        this.opsMatrix.matrix[i].vehicleTypes[j].loadingChart = false
      }, 100)
    }
  }
}
</script>

<style scoped>
.column-width {
  min-width: 320px;
  width: 320px;
}
/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #4299e1;
}
.collapsed > .when-opened,
 :not(.collapsed) > .when-closed {
   display: none;
}
</style>
