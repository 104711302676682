<template>
  <b-container fluid>
    <form>
      <b-row>
        <b-col lg="4">
          <iq-card class-name="iq-user-profile-block1">
            <template v-slot:body>
              <div class="user-details-block">
                <div class="user-profile text-center">
                  <img src="../../assets/images/ship.png" alt="profile-img" class="avatar-130 img-fluid">
                </div>
                <div class="text-center mt-3">
                  <h4>
                    <b>TB. ETI 201</b>
                  </h4>
                  <b-badge variant="info"></b-badge>
                </div>
              </div>
              <table class="table table-striped mt-3">
                <tbody>
                  <tr class="bg-primary">
                    <td colspan="2" class="text-center">
                      <strong>SHIP PARTICULAR</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Type Kapal</strong>
                    </td>
                    <td>TUG BOAT</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Bendera</strong>
                    </td>
                    <td>Indonesia</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Klasifikasi</strong>
                    </td>
                    <td>BKI Class</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Tahun Pembuatan</strong>
                    </td>
                    <td>2021</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Tempat Pembangunan</strong>
                    </td>
                    <td>Samarinda</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Tempat Pendaftaran</strong>
                    </td>
                    <td>Jakarta</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Ukuran Kapal</strong>
                    </td>
                    <td>200 X</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Main Engine</strong>
                    </td>
                    <td>
                      <span>Starboard RH : <b-badge variant="info">
                          <strong>1.250</strong>
                        </b-badge>
                      </span>
                      <br>
                      <span>Portside RH : <b-badge variant="info">
                          <strong>1.250</strong>
                        </b-badge>
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Auxuliary Engine</strong>
                    </td>
                    <td>
                      <span>Starboard RH : <b-badge variant="info">
                          <strong>1.250</strong>
                        </b-badge>
                      </span>
                      <br>
                      <span>Portside RH : <b-badge variant="info">
                          <strong>1.250</strong>
                        </b-badge>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="8">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">FINAL SEA TRIAL RECORD</h4>
            </template>
            <template v-slot:body>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary h6">PRINCIPAL DIMENSION</legend>
                <b-row>
                  <b-form-group class="col-md-4" label="L.O.A" label-for="loa">
                    <div class="input-group">
                      <input type="number" required class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="L.W.L" label-for="lwl">
                    <div class="input-group">
                      <input type="number" class="form-control" required>
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="L.B.P" label-for="lbp">
                    <div class="input-group">
                      <input type="number" class="form-control" required>
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-form-group class="col-md-4" label="B mld" label-for="bmld">
                    <div class="input-group">
                      <input type="number" class="form-control" required>
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="D mld" label-for="dmld">
                    <div class="input-group">
                      <input type="number" class="form-control" required>
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="T" label-for="t">
                    <div class="input-group">
                      <input type="number" class="form-control" required>
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-form-group class="col-md-4" label="Main Engine" label-for="main_engine">
                    <b-form-select plain v-model="selectedMerkEngine" :options="optionsMerkEngine" size="lg">
                      <template v-slot:first>
                      <b-form-select-option :value="null">Select Fleet</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Auxiliary Engine" label-for="generator">
                    <b-form-select plain v-model="selectedMerkEngine" :options="optionsMerkEngine" size="lg">
                      <template v-slot:first>
                      <b-form-select-option :value="null">Select Fleet</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Gearbox" label-for="gearbox">
                    <b-form-select plain v-model="selectedMerkEngine" :options="optionsMerkEngine" size="lg">
                      <template v-slot:first>
                      <b-form-select-option :value="null">Select Fleet</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary h6">FULL LOAD RECORD</legend>
                <b-row>
                  <b-form-group class="col-md-4" label="Tanggal Percobaan" label-for="tanggal_percobaan">
                    <input type="date" class="form-control" id="tanggal_percobaan">
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Tempat Percobaan" label-for="tempat_percobaan">
                    <b-form-input required></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Kedalaman" label-for="kedalaman">
                    <div class="input-group">
                      <input type="text" class="form-control" id="kedalaman">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Jarak Pengukuran" label-for="jarak_pengukuran">
                    <input type="text" class="form-control">
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Arah Angin" label-for="arah_angin">
                    <input type="text" class="form-control">
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Kecepatan Angin" label-for="cuaca">
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">Knots</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Cuaca" label-for="cuaca">
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">°C</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Sarat Depan" label-for="sarat_depan">
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Sarat Belakang" label-for="sarat_belakang">
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Perbedaan Trim" label-for="perbedaan_trim">
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Tempratur Udara" label-for="tempratur_udara">
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">°C</span>
                      </div>
                    </div>
                  </b-form-group>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary h6">MUATAN DAN TANGKI</legend>
                <b-row>
                  <b-form-group class="col-md-12" label="Muatan" label-for="muatan">
                    <input type="text" class="form-control">
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Jumlah Orang Atas Kapal" label-for="jml_crew">
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">Orang</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Tangki Induk BBM" label-for="tangkiIndukBBM">
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">Liter</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Tangki Induk Harian" label-for="tangkiIndukHarian">
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">Liter</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Tangki Induk Oli" label-for="tangkiIndukOli">
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">Liter</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Tangki Harian Oli" label-for="tangkiHarianOli">
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">Liter</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Tangki Air Tawar" label-for="TangkiAirTawar">
                    <div class="input-group">
                      <input class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">Ton</span>
                      </div>
                    </div>
                  </b-form-group>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary h6">ATTACHMENT SEA TRIAL</legend>
                <b-row>
                  <b-form-group class="col-md-6" label="Nama File" label-for="nama_file">
                    <input type="text" class="form-control">
                  </b-form-group>
                  <b-form-group class="col-md-5" label="Jumlah Orang Atas Kapal" label-for="jml_crew">
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                      <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                    </div>
                    <small class="text-muted">
                      File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                      File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                    </small>
                  </b-form-group>
                  <b-form-group class="col-md-1" label="Action" label-for="nama_file">
                    <b-button variant="success"><i class="fa fa-plus pr-0"></i></b-button>
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-form-group class="col-md-6" label="Nama File" label-for="nama_file">
                    <input type="text" class="form-control">
                  </b-form-group>
                  <b-form-group class="col-md-5" label="Jumlah Orang Atas Kapal" label-for="jml_crew">
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                      <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                    </div>
                    <small class="text-muted">
                      File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                      File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                    </small>
                  </b-form-group>
                  <b-form-group class="col-md-1" label="Action" label-for="nama_file">
                    <b-button variant="danger"><i class="fa fa-trash pr-0"></i></b-button>
                  </b-form-group>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary h5">MAIN ENGINE RECORD</legend>
                <fieldset class="form-group border p-3 mt-1">
                  <legend class="w-auto text-primary h6">Main Engine - Starboard Side</legend>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead class="table-success">
                        <tr>
                          <th class="text-center align-middle" rowspan="2">&nbsp;NO&nbsp;</th>
                          <th class="text-center align-middle" rowspan="2">Load&nbsp;(Percent)</th>
                          <th class="text-center align-middle" rowspan="2">Time&nbsp;(Menit)</th>
                          <th class="text-center align-middle" colspan="6">MAIN ENGINE</th>
                          <th class="text-center align-middle" colspan="2">GEARBOX</th>
                          <th class="text-center align-middle" rowspan="2">Temp.&nbsp;Cover Crankcase&nbsp;(°C)</th>
                          <th class="text-center align-middle" rowspan="2">Interm.&nbsp;Shaft Propeller&nbsp;(°C)</th>
                          <th class="text-center align-middle" rowspan="2">Shaft&nbsp;Propeller&nbsp;(°C)</th>
                        </tr>
                        <tr>
                          <th class="text-center align-middle">Speed&nbsp;(Knots)</th>
                          <th class="text-center align-middle">Engine&nbsp;(RPM)</th>
                          <th class="text-center align-middle">Exhaust&nbsp;Temperatur&nbsp;(°C)</th>
                          <th class="text-center align-middle">FW&nbsp;Temp&nbsp;(°C)</th>
                          <th class="text-center align-middle">LO&nbsp;Temp&nbsp;(°C)</th>
                          <th class="text-center align-middle">LO&nbsp;Press&nbsp;(kg/cm2)</th>
                          <th class="text-center align-middle">LO&nbsp;Press&nbsp;(Psi)</th>
                          <th class="text-center align-middle">LO&nbsp;Temp&nbsp;(°C)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th class="text-center">
                            #1<br><a href="#" class="text-danger">Remove</a>
                          </th>
                          <td>
                            <b-form-input type="number" id="load" name="load"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="time" name="time"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="speed" name="speed"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="engine_rpm" name="engine_rpm"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="exhaust_temp" name="exhaust_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="fw_temp" name="fw_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_temp_me" name="lo_temp_me"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_press_me" name="lo_press_me"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_press_gb" name="lo_press_gb"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_temp_gb" name="lo_temp_gb"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="temp_cover" name="temp_cover"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="interm_shaft_propeller" name="interm_shaft_propeller"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="shaft_propeller" name="shaft_propeller"></b-form-input>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center">
                            #2<br><a href="#" class="text-danger">Remove</a>
                          </th>
                          <td>
                            <b-form-input type="number" id="load" name="load"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="time" name="time"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="speed" name="speed"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="engine_rpm" name="engine_rpm"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="exhaust_temp" name="exhaust_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="fw_temp" name="fw_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_temp_me" name="lo_temp_me"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_press_me" name="lo_press_me"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_press_gb" name="lo_press_gb"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_temp_gb" name="lo_temp_gb"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="temp_cover" name="temp_cover"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="interm_shaft_propeller" name="interm_shaft_propeller"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="shaft_propeller" name="shaft_propeller"></b-form-input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <b-button variant="success" class="mt-1">
                    <i class="fa fa-plus"></i> ADD FORM
                  </b-button>
                </fieldset>
                <hr>
                <fieldset class="form-group border p-3 mt-1">
                  <legend class="w-auto text-primary h6">Main Engine - Port Side</legend>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead class="table-success">
                        <tr>
                          <th class="text-center align-middle" rowspan="2">&nbsp;NO&nbsp;</th>
                          <th class="text-center align-middle" rowspan="2">Load&nbsp;(Percent)</th>
                          <th class="text-center align-middle" rowspan="2">Time&nbsp;(Menit)</th>
                          <th class="text-center align-middle" colspan="6">MAIN ENGINE</th>
                          <th class="text-center align-middle" colspan="2">GEARBOX</th>
                          <th class="text-center align-middle" rowspan="2">Temp.&nbsp;Cover Crankcase&nbsp;(°C)</th>
                          <th class="text-center align-middle" rowspan="2">Interm.&nbsp;Shaft Propeller&nbsp;(°C)</th>
                          <th class="text-center align-middle" rowspan="2">Shaft&nbsp;Propeller&nbsp;(°C)</th>
                        </tr>
                        <tr>
                          <th class="text-center align-middle">Speed&nbsp;(Knots)</th>
                          <th class="text-center align-middle">Engine&nbsp;(RPM)</th>
                          <th class="text-center align-middle">Exhaust&nbsp;Temperatur&nbsp;(°C)</th>
                          <th class="text-center align-middle">FW&nbsp;Temp&nbsp;(°C)</th>
                          <th class="text-center align-middle">LO&nbsp;Temp&nbsp;(°C)</th>
                          <th class="text-center align-middle">LO&nbsp;Press&nbsp;(kg/cm2)</th>
                          <th class="text-center align-middle">LO&nbsp;Press&nbsp;(Psi)</th>
                          <th class="text-center align-middle">LO&nbsp;Temp&nbsp;(°C)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th class="text-center">
                            #1<br><a href="#" class="text-danger">Remove</a>
                          </th>
                          <td>
                            <b-form-input type="number" id="load" name="load"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="time" name="time"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="speed" name="speed"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="engine_rpm" name="engine_rpm"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="exhaust_temp" name="exhaust_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="fw_temp" name="fw_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_temp_me" name="lo_temp_me"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_press_me" name="lo_press_me"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_press_gb" name="lo_press_gb"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_temp_gb" name="lo_temp_gb"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="temp_cover" name="temp_cover"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="interm_shaft_propeller" name="interm_shaft_propeller"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="shaft_propeller" name="shaft_propeller"></b-form-input>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center">
                            #2<br><a href="#" class="text-danger">Remove</a>
                          </th>
                          <td>
                            <b-form-input type="number" id="load" name="load"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="time" name="time"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="speed" name="speed"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="engine_rpm" name="engine_rpm"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="exhaust_temp" name="exhaust_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="fw_temp" name="fw_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_temp_me" name="lo_temp_me"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_press_me" name="lo_press_me"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_press_gb" name="lo_press_gb"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_temp_gb" name="lo_temp_gb"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="temp_cover" name="temp_cover"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="interm_shaft_propeller" name="interm_shaft_propeller"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="shaft_propeller" name="shaft_propeller"></b-form-input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <b-button variant="success" class="mt-1"><i class="fa fa-plus"></i> ADD FORM</b-button>
                </fieldset>
                <b>Notes : </b>RPM bervariasi per kapal, bisa sampai 1600 RPM
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary h5">AUXILIARY ENGINE RECORD</legend>
                <fieldset class="form-group border p-3 mt-1">
                  <legend class="w-auto text-primary h6">Auxiliary Engine - Port Side</legend>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                        <thead class="table-success">
                        <tr>
                          <th class="text-center align-middle" rowspan="2">&nbsp;NO&nbsp;</th>
                          <th class="text-center align-middle" rowspan="2">Load&nbsp;(Percent)</th>
                          <th class="text-center align-middle" rowspan="2">Engine&nbsp;(RPM)</th>
                          <th class="text-center align-middle" colspan="6">ENGINE RECORD</th>
                          <th class="text-center align-middle" rowspan="2">Temp.&nbsp;Cover Crankcase&nbsp;(°C)</th>
                        </tr>
                        <tr>
                          <th class="text-center align-middle">LO&nbsp;Press&nbsp;(kg/cm2)</th>
                          <th class="text-center align-middle">SW&nbsp;Press&nbsp;(kg/cm2)</th>
                          <th class="text-center align-middle">LO&nbsp;Press&nbsp;(kg/cm2)</th>
                          <th class="text-center align-middle">LO&nbsp;Temp&nbsp;(°C)</th>
                          <th class="text-center align-middle">FW&nbsp;Temp&nbsp;(°C)</th>
                          <th class="text-center align-middle">Exh&nbsp;Temp&nbsp;(°C)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th class="text-center">
                            #1<br><a href="#" class="text-danger">Remove</a>
                          </th>
                          <td>
                            <b-form-input type="number" id="load" name="load"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="engine_rpm" name="engine_rpm"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_press_1" name="lo_press_1"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="sw_press" name="sw_press"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_press_2" name="lo_press_2"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_temp" name="lo_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="fw_temp" name="fw_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="exhaust_temp" name="exhaust_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="temp_cover" name="temp_cover"></b-form-input>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center">
                            #2<br><a href="#" class="text-danger">Remove</a>
                          </th>
                          <td>
                            <b-form-input type="number" id="load" name="load"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="engine_rpm" name="engine_rpm"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_press_1" name="lo_press_1"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="sw_press" name="sw_press"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_press_2" name="lo_press_2"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_temp" name="lo_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="fw_temp" name="fw_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="exhaust_temp" name="exhaust_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="temp_cover" name="temp_cover"></b-form-input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </fieldset>
                <fieldset class="form-group border p-3 mt-1">
                  <legend class="w-auto text-primary h6">Auxiliary Engine - Starboard Side</legend>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                        <thead class="table-success">
                        <tr>
                          <th class="text-center align-middle" rowspan="2">&nbsp;NO&nbsp;</th>
                          <th class="text-center align-middle" rowspan="2">Load&nbsp;(Percent)</th>
                          <th class="text-center align-middle" rowspan="2">Engine&nbsp;(RPM)</th>
                          <th class="text-center align-middle" colspan="6">ENGINE RECORD</th>
                          <th class="text-center align-middle" rowspan="2">Temp.&nbsp;Cover Crankcase&nbsp;(°C)</th>
                        </tr>
                        <tr>
                          <th class="text-center align-middle">LO&nbsp;Press&nbsp;(kg/cm2)</th>
                          <th class="text-center align-middle">SW&nbsp;Press&nbsp;(kg/cm2)</th>
                          <th class="text-center align-middle">LO&nbsp;Press&nbsp;(kg/cm2)</th>
                          <th class="text-center align-middle">LO&nbsp;Temp&nbsp;(°C)</th>
                          <th class="text-center align-middle">FW&nbsp;Temp&nbsp;(°C)</th>
                          <th class="text-center align-middle">Exh&nbsp;Temp&nbsp;(°C)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th class="text-center">
                            #1<br><a href="#" class="text-danger">Remove</a>
                          </th>
                          <td>
                            <b-form-input type="number" id="load" name="load"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="engine_rpm" name="engine_rpm"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_press_1" name="lo_press_1"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="sw_press" name="sw_press"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_press_2" name="lo_press_2"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_temp" name="lo_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="fw_temp" name="fw_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="exhaust_temp" name="exhaust_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="temp_cover" name="temp_cover"></b-form-input>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center">
                            #2<br><a href="#" class="text-danger">Remove</a>
                          </th>
                          <td>
                            <b-form-input type="number" id="load" name="load"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="engine_rpm" name="engine_rpm"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_press_1" name="lo_press_1"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="sw_press" name="sw_press"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_press_2" name="lo_press_2"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="lo_temp" name="lo_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="fw_temp" name="fw_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="exhaust_temp" name="exhaust_temp"></b-form-input>
                          </td>
                          <td>
                            <b-form-input type="number" id="temp_cover" name="temp_cover"></b-form-input>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </fieldset>
                <b-button variant="success" class="mt-1">
                  <i class="fa fa-plus"></i> ADD FORM
                </b-button>
              </fieldset>
              <hr>
              <b-row>
                <b-col md="12">
                  <b-form-group label="RECOMMENDATION" label-for="recomendation">
                    <wysiwyg />
                  </b-form-group>
                </b-col>
                <b-form-group class="col-md-4" label="Prepared By - OS" label-for="prepared_by">
                  <v-select label="text"></v-select>
                </b-form-group>
                <b-form-group class="col-md-4" label="Acknowledge By - INCO Mechanic" label-for="acknowledge_by">
                  <v-select label="text"></v-select>
                </b-form-group>
                <b-form-group class="col-md-4" label="Acknowledge By - SPT / Mechanic" label-for="acknowledge_by">
                  <v-select label="text"></v-select>
                </b-form-group>
                <b-form-group class="col-md-4" label="Acknowledge By - KKM TB. ETI 201" label-for="acknowledge_by">
                  <v-select label="text"></v-select>
                </b-form-group>
                <b-form-group class="col-md-4" label="Acknowledge By - Captain TB. ETI 201" label-for="acknowledge_by">
                  <v-select label="text"></v-select>
                </b-form-group>
              </b-row>
              <b-button block variant="primary" type="submit" class="mt-3">Submit Data</b-button>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
export default {
  name: 'Sea Trial',
  mounted () {
    xray.index()
  },
  data () {
    return {
      selectedMerkEngine: null,
      optionsMerkEngine: [
        { value: '1', text: 'YANMAR 4TNV 106T GGE' },
        { value: '2', text: 'MITSUBISHI S6R2 MPTK2/3L' },
        { value: '3', text: 'YANMAR 12LAK STE2' }
      ],
    }
  }
}
</script>