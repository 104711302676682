<template>
  <b-card class="shadow border mt-4 p-4 rounded-lg">
    <b-row>
      <b-col md="12">
        <h5 class="text-primary"><strong>Download and Complete The Template</strong></h5>
        <span>Download template below and write your data</span>
      </b-col>
      <b-col md="12" class="my-4">
        <div class="d-flex p-2 px-4 rounded-lg bg-primary align-items-center justify-content-between" style="cursor: pointer;" @click="doExport">
          <span>template_bulk_data.xlsx</span>
          <i class="fa fa-download" />
        </div>
        <p class="mt-2 text-primary" v-if="loading_export">Please wait, downloading file is in process...</p>
      </b-col>
      <b-col md="12" class="mb-2">
        <strong class="text-black">Few Tips</strong>
        <ul class="tips-list">
          <li>Make sure your data is correct</li>
          <li>Make sure your data format follow the rule of template</li>
          <li>Check sheet instruction on spreadsheet to read instruction</li>
        </ul>
      </b-col>
      <b-col md="6"> 
        <b-form-group label="Upload Document *" label-for="fleet_name">
          <b-form-file type="file" accept="application/*" v-model="file" @change="uploadFile($event, true)" />
          <p class="mt-2 text-primary" v-if="loading_upload_file">Please wait, uploading file is in process...</p>
        </b-form-group>
      </b-col>
      <b-col md="12" v-if="responseUpload">
        <div class="border w-100 d-flex my-2" />
        <span>{{ responseUpload }}</span>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { voyagesActions } from '@src/Utils/helper'
import { saveAs } from 'file-saver'
import { FormWizard, TabContent } from "vue-step-wizard";
import "vue-step-wizard/dist/vue-step-wizard.css";

export default {
  name: "ImportWizardOperational",
  components: {
    FormWizard,
    TabContent
  },
  // mixins: [ValidationHelper],
  data() {
    return {
      loading_upload_file:false,
      loading_export:false,
      saving:false,
      file:"",
      responseUpload: null,
    };
  },
  methods: {
    ...voyagesActions,
    async uploadFile(event) {
      this.loading_upload_file = true

      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.uploadVoyageBulk(formData)
      if (res.status == 'success') {
        this.loading_upload_file = false
        this.responseUpload = res.data
      } else {
        this.loading_upload_file = false
        this.file = ''
        this.responseUpload = null
        if (res.data.message != null)
          this.$swal('Error', res.data.message, 'error')
        else
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
      }
    },
    async doExport () {
      this.loading_export = true
      let res = await this.fetchTemplateUpload()
      if (res.status == 'success') {
        this.loading_export = false
        saveAs(res.data, 'Template Bulk Update.xlsx')
      } else {
        this.loading_export = false
        if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
  }
};
</script>

<style scoped>
.tips-list {
  list-style-type: disc;
  padding-left: 1.5em;
  color: #4a4a4a; /* Adjust color to match the style */
}
</style>