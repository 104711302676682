import Vue from 'vue'
import '@babel/polyfill'
import 'mutationobserver-shim'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
import './registerServiceWorker'
import i18n from './i18n'
import axios from './axios'

import wysiwyg from 'vue-wysiwyg'
require('vue-wysiwyg/dist/vueWysiwyg.css')
global.Raphael = Raphael
Vue.config.productionTip = false
window._ = require('lodash')
Vue.use(wysiwyg, {
  hideModules: { 'image': true },
})

Vue.mixin({
  methods: {
    findMenuPermission (lv = 1, output = 'export', menuId = 1, subMenuId = null, tabMenuId = null) {
      const menus = this.$store.state.auth.profile.menu
      let found_menu = {}

      const menu = menus.find(menu => menu.menuId == menuId)

      switch (lv) {
        case 1:
          found_menu = menus.find(menu => menu.menuId == menuId)
          break

        case 2:
          found_menu = menu.child.find(subMenu => subMenu.menuId == subMenuId)
          break

        default:
          const sub_menu = menu.child.find(subMenu => subMenu.menuId == subMenuId)
          found_menu = sub_menu.child.find(tabMenu => tabMenu.menuId == tabMenuId)
          break
      }

      if (found_menu && output === 'childs') {
        return found_menu.child
      }

      return found_menu[output]
    },
    findMenuPermissionLv2ByIndex (output = 'export', menuId = 1, subMenuIndex = 0) {
      const menus = this.$store.state.auth.profile.menu
      const menu = menus.find(m => m.menuId == menuId)

      return menu.child[subMenuIndex][output]
    }
  }
})

let vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
window.axios = axios
