<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-4 p-0" :active="true" href="#masterCategory" :title="findMenuPermissionLv2ByIndex('menu', $route.query.menuId, 0)" />
                <tab-nav-items class="col-md-4 p-0" :active="false" href="#masterModule" :title="findMenuPermissionLv2ByIndex('menu', $route.query.menuId, 1)" />
                <tab-nav-items class="col-md-4 p-0" :active="false" href="#setting" :title="findMenuPermissionLv2ByIndex('menu', $route.query.menuId, 2)" />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <tab-content-item :active="true" id="masterCategory" >
        <b-row>
          <b-col :md="!findMenuPermissionLv2ByIndex('update', $route.query.menuId, 0) && !findMenuPermissionLv2ByIndex('add', $route.query.menuId, 0) ? '12' : '8'">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">List Fleets Category</h4>
              </template>
              <template v-slot:body>
                <div v-if="loading_category" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div class="table-responsive" v-if="fleet_types.length > 0">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th width="10%">No</th>
                          <th width="25%">Category</th>
                          <th width="10%">Images/Icon</th>
                          <th width="10%">Status</th>
                          <th width="15%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(c, i) in fleet_types" :key="`c-${i}`">
                          <td>{{ i + 1 }}</td>
                          <td>{{ c.name }}</td>
                          <td>
                            <img
                              v-if="c.icon"
                              :src="c.icon.url"
                              :alt="c.name"
                              width="175px"
                              height="auto"
                            />
                            <span v-else>-</span>
                          </td>
                          <td>{{ c.active ? 'Active' : 'Inactive' }}</td>
                          <td>
                            <b-button
                              v-if="findMenuPermissionLv2ByIndex('update', $route.query.menuId, 0)"
                              variant="success"
                              size="sm"
                              class="mr-2"
                              @click="onEditCategory(c)"
                              ><i class="fa fa-edit"></i
                            ></b-button>
                            <b-button
                              v-if="findMenuPermissionLv2ByIndex('delete', $route.query.menuId, 0)"
                              variant="danger"
                              size="sm"
                              @click="onDeleteCategory(c)"
                              ><i class="fa fa-trash"></i
                            ></b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p v-else class="text-center my-4"
                    >No categories data found.</p
                  >
                </template>
              </template>
            </iq-card>
          </b-col>
          <b-col md="4"
            v-if="findMenuPermissionLv2ByIndex('update', $route.query.menuId, 0) || findMenuPermissionLv2ByIndex('add', $route.query.menuId, 0)">
            <iq-card :class="{ 'border border-danger shadow-lg': form_category_edit_mode }">
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ form_category_edit_mode ? 'Edit' : 'Add' }} Fleets Category</h4>
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="onSubmitFormCategory">
                  <b-form-group
                    label="Category Name"
                    label-for="category_name"
                  >
                    <b-form-input
                      id="category_name"
                      type="text"
                      placeholder="Category Name"
                      v-model="form.category.name"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Icon"
                    label-for="imagesModulUrl"
                    description="Accept only .jpg, .jpeg, .gif, and .png"
                  >
                    <img
                      v-if="form.category.icon && form.category.icon.url"
                      :src="form.category.icon.url"
                      :alt="form.category.name"
                      width="256px"
                      height="auto"
                      class="mb-3"
                    />
                    <b-form-file
                      accept="image/jpeg, image/png, image/jpg, image/gif"
                      name="category_icon"
                      :disabled="loading_upload_icon"
                      :browse-text="
                        loading_upload_icon ? 'Uploading...' : 'Browse'
                      "
                      ref="form_upload_icon"
                      @change="onChangeIcon"
                    ></b-form-file>
                  </b-form-group>

                  <b-form-group label="Status" label-for="sub_sub_project">
                    <b-form-checkbox
                      v-model="form.category.active"
                      name="check-button"
                      switch
                      inline
                    >
                      {{ form.category.active ? 'Active' : 'Inactive' }}
                    </b-form-checkbox>
                  </b-form-group>
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span v-if="!loading_form_category">{{
                        form_category_edit_mode || !findMenuPermissionLv2ByIndex('add', $route.query.menuId, 0) ? 'Save' : 'Add'
                      }}</span>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </template>
                    </b-button>
                    <b-button
                      type="button"
                      variant="danger"
                      class="iq-bg-danger ml-3"
                      @click.prevent="resetFormCategory"
                      >Cancel</b-button
                    >
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>

      <tab-content-item :active="false" id="masterModule" >
        <b-row>
          <b-col  :md="!findMenuPermissionLv2ByIndex('update', $route.query.menuId, 1) && !findMenuPermissionLv2ByIndex('add', $route.query.menuId, 1) ? '12' : '8'">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">List Module / Fitur</h4>
              </template>
              <template v-slot:body>
                <div v-if="loading_module" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div class="table-responsive" v-if="modules.length > 0">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th width="5%">No</th>
                          <th width="20%">Modul / Fitur Name</th>
                          <th width="15%">Modul / Fitur Url</th>
                          <th width="15%">Icon</th>
                          <th width="15%">Same Category</th>
                          <th width="10%">Status</th>
                          <th width="15%">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(m, i) in modules" :key="`mod-${i}`">
                          <td>{{ i + 1 }}</td>
                          <td>{{ m.name }}</td>
                          <td>{{ m.url }}</td>
                          <td>
                            <img
                              v-if="m.icon"
                              :src="m.icon.url"
                              :alt="m.name"
                              width="64px"
                              height="auto"
                            />
                            <span v-else>-</span>
                          </td>
                          <td>
                            {{ m.sameCategory ? 'Yes' : 'No' }}
                            <template v-if="m.referenceId">
                              , with
                              <br />
                              <strong>{{ m.referenceId.name }}</strong>
                            </template>
                          </td>
                          <td>{{ m.active ? 'Active' : 'Inactive' }}</td>
                          <td>
                            <b-button
                              v-if="findMenuPermissionLv2ByIndex('update', $route.query.menuId, 1)"
                              variant="success"
                              size="sm"
                              class="mr-2"
                              @click="onEditModule(m)"
                              ><i class="fa fa-edit"></i
                            ></b-button>
                            <b-button
                              v-if="findMenuPermissionLv2ByIndex('delete', $route.query.menuId, 1)"
                              variant="danger"
                              size="sm"
                              @click="onDeleteModule(m)"
                              ><i class="fa fa-trash"></i
                            ></b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p v-else class="text-center my-4">No module data found.</p>
                </template>
              </template>
            </iq-card>
          </b-col>

          <b-col md="4"
            v-if="findMenuPermissionLv2ByIndex('update', $route.query.menuId, 1) || findMenuPermissionLv2ByIndex('add', $route.query.menuId, 1)">
            <iq-card :class="{ 'border border-danger shadow-lg': form_module_edit_mode }">
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ form_module_edit_mode ? 'Edit' : 'Add' }} Module / Fitur</h4>
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="onSubmitFormModule">
                  <b-form-group
                    label="Modul / Fitur Name"
                    label-for="modulName"
                  >
                    <b-form-input
                      id="modulName"
                      type="text"
                      placeholder="Modul / Fitur Name"
                      v-model="form.module.name"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Modul / Fitur Url"
                    label-for="modulUrl"
                  >
                    <div class="input-group">
                      <div class="input-group-append">
                        <span class="input-group-text"
                          >https://base_url/</span
                        >
                      </div>
                      <b-form-input
                        id="modulUrl"
                        type="text"
                        placeholder="Url after base"
                        v-model="form.module.url"
                      ></b-form-input>
                    </div>
                  </b-form-group>

                  <b-form-group
                    label="Images Fitur / Modul"
                    label-for="imagesModulUrl"
                    description="Accept only .jpg, .jpeg, and .png"
                  >
                    <img
                      v-if="form.module.icon && form.module.icon.url"
                      :src="form.module.icon.url"
                      :alt="form.module.name"
                      width="128px"
                      height="auto"
                      class="mb-3"
                    />
                    <b-form-file
                      accept="image/jpeg, image/png, image/jpg"
                      name="module_icon"
                      :disabled="loading_upload_image"
                      :browse-text="
                        loading_upload_image ? 'Uploading...' : 'Browse'
                      "
                      ref="form_upload_image"
                      @change="onChangeImage"
                    ></b-form-file>
                  </b-form-group>

                  <b-form-group
                    label="Same category with existing module?"
                    label-for="has_sub"
                  >
                    <b-form-checkbox
                      v-model="form.module.sameCategory"
                      name="check-button"
                      switch
                      inline
                    >
                      {{ form.module.sameCategory ? 'Yes' : 'No' }}
                    </b-form-checkbox>
                  </b-form-group>

                  <b-form-group
                    v-if="form.module.sameCategory"
                    label="Same with module"
                    label-for="category"
                  >
                    <v-select
                      v-model="form.module.referenceId"
                      label="name"
                      :options="modules"
                      :reduce="(m) => m.id"
                    ></v-select>
                  </b-form-group>

                  <b-form-group label="Status" label-for="status">
                    <b-form-checkbox
                      v-model="form.module.active"
                      name="check-button"
                      switch
                      inline
                    >
                      {{ form.module.active ? 'Active' : 'Inactive' }}
                    </b-form-checkbox>
                  </b-form-group>
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span v-if="!loading_form_module">{{
                        form_module_edit_mode || !findMenuPermissionLv2ByIndex('add', $route.query.menuId, 1) ? 'Save' : 'Add'
                      }}</span>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </template>
                    </b-button>
                    <b-button
                      type="button"
                      variant="danger"
                      class="iq-bg-danger ml-3"
                      @click.prevent="resetFormModule"
                      >Cancel</b-button
                    >
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>

      <tab-content-item :active="false" id="setting" >
        <b-row>
          <b-col md="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Setup Fleets Module</h4>
              </template>
              <template v-slot:body>
                <div class="table-responsive">
                  <table class="table mb-0 table-striped table-hover">
                    <thead class="thead-dark text-center">
                      <tr>
                        <th width="20%">Category</th>
                        <th v-for="(m, i) in modules" :key="`th-mod-${i}`">{{ m.name }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(cat, ic) in merged_module_categories" :key="`cat-${ic}`">
                        <td>{{ cat.name }}</td>
                        <td v-for="(m, i) in cat.modules" :key="`bd-mod-${i}`" class="text-center">
                          <b-form-checkbox
                            name="operational-1"
                            :disabled="!findMenuPermissionLv2ByIndex('update', $route.query.menuId, 2)"
                            switch
                            inline
                            v-model="m.hasAccess"
                          >
                          </b-form-checkbox>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <template v-slot:footer
                v-if="findMenuPermissionLv2ByIndex('update', $route.query.menuId, 2)">
                <b-row>
                  <b-col cols="12" class="text-right">
                    <b-button variant="primary" class="mb-3 mr-1" @click="saveSetting">
                      <span v-if="!loading_form_setting">Save</span>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </template>
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
    </div>
  </b-container>
</template>
<script>
import { fleetsActions, filesActions, modulesActions } from '@src/Utils/helper'

export default {
  name: 'ModuleCategory',
  data () {
    return {
      fleet_types: [],
      modules: [],
      module_categories: [],
      merged_module_categories: [],
      selected_category: {},
      selected_module: {},
      uploaded_category_icon: {},
      uploaded_module_image: {},
      loading_category: true,
      loading_module: true,
      loading_form_category: false,
      loading_form_module: false,
      loading_form_setting: false,
      loading_delete_category: false,
      loading_delete_module: false,
      loading_upload_icon: false,
      loading_upload_image: false,
      form_category_edit_mode: false,
      form_module_edit_mode: false,
      category_params: {
        active: true,
        search: '',
      },
      form: {
        category: {
          name: '',
          icon: {
            url: '',
            iconName: '',
          },
          active: true,
        },
        module: {
          name: '',
          url: '',
          icon: {
            url: '',
            iconName: '',
          },
          sameCategory: false,
          referenceId: '',
          active: true,
        },
      },
      user: {},
    }
  },
  async mounted() {
    await this.fetchFleetTypes()
    await this.fetchModules()
    this.fetchModuleCategories()
  },
  methods: {
    ...fleetsActions,
    ...filesActions,
    ...modulesActions,
    async fetchFleetTypes() {
      this.loading_category = true
      let params = this.$options.filters.cleanObject(this.category_params)
      let res = await this.getFleetTypes(params)

      this.fleet_types = res.data
      this.loading_category = false
    },
    async fetchModules() {
      this.loading_module = true
      let res = await this.getModules()

      this.modules = res.data
      this.loading_module = false
    },
    async fetchModuleCategories() {
      let res = await this.getModuleCategories()
      this.module_categories = res.data
      this.mergeModuleCategories()
    },
    async onSubmitFormCategory() {
      this.loading_form_category = true

      let payload = this.form.category
      if (payload.icon && payload.icon.url) payload.icon = payload.icon.iconName
      else payload.icon = ''

      let form_has_empty_value = false
      for (var key in payload) {
        if (payload[key] === '') form_has_empty_value = true
        else if (Array.isArray(payload[key]) && !payload[key].length)
          form_has_empty_value = true
      }

      if (form_has_empty_value)
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )
      else {
        if (!this.form_category_edit_mode) {
          let res = await this.addFleetType(payload)
          if (res.status == 'success') {
            this.resetFormCategory()
            this.fetchFleetTypes()
            this.$swal(
              'Category added!',
              'Category added successfully.',
              'success'
            )
          } else {
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
          }
        } else {
          let res = await this.updateFleetType({
            id: this.selected_category.id,
            data: payload,
          })
          if (res.status == 'success') {
            this.resetFormCategory()
            this.fetchFleetTypes()
            this.$swal(
              'Category updated!',
              'Category updated successfully.',
              'success'
            )
          } else {
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
          }
        }
      }
      this.loading_form_category = false
    },
    async onSubmitFormModule() {
      this.loading_form_module = true

      let payload = {
        name: this.form.module.name,
        url: this.form.module.url,
        iconName: this.form.module.icon.iconName,
        sameCategory: this.form.module.sameCategory,
        active: this.form.module.active,
      }
      
      if (this.form.module.sameCategory) {
        Object.assign(payload, { referenceId: this.form.module.referenceId })
      }

      let form_has_empty_value = false
      for (var key in payload) {
        if (['referenceId', 'sameCategory'].includes(key)) {
          continue
        } else {
          if (payload[key] === '')
            form_has_empty_value = true
          else if (Array.isArray(payload[key]) && !payload[key].length)
            form_has_empty_value = true
        }
      }

      if (form_has_empty_value)
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )
      else {
        if (!this.form_module_edit_mode) {
          let res = await this.addModule(payload)
          if (res.status == 'success') {
            this.resetFormModule()
            this.fetchModules()
            this.$swal('Module added!', 'Module added successfully.', 'success')
          } else {
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
          }
        } else {
          let res = await this.updateModule({
            id: this.selected_module.id,
            data: payload,
          })
          if (res.status == 'success') {
            this.resetFormModule()
            this.fetchModules()
            this.$swal(
              'Module updated!',
              'Module updated successfully.',
              'success'
            )
          } else {
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
          }
        }
      }
      this.loading_form_module = false
    },
    async uploadFile(photo, is_module = false) {
      let formData = new FormData()
      formData.append('file', photo)

      let res = await this.upload(formData)
      if (res.status == 'success') {
        if (is_module) {
          this.loading_upload_image = false
          this.uploaded_module_image = res.data
          this.form.module.icon = {
            url: res.data.url,
            iconName: res.data.fileName,
          }
        } else {
          this.loading_upload_icon = false
          this.uploaded_category_icon = res.data
          this.form.category.icon = {
            url: res.data.url,
            iconName: res.data.fileName,
          }
        }
      } else {
        if (is_module) this.loading_upload_image = false
        else this.loading_upload_icon = false

        if (res.data.message != null)
          this.$swal('Error', res.data.message, 'error')
        else
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
      }
    },
    async saveSetting() {
      this.loading_form_setting = true
      let payload = []

      this.merged_module_categories.forEach(category => {
        let modules = []

        category.modules.forEach(m => modules.push({
          id: m.id,
          hasAccess: m.hasAccess
        }))

        payload.push({
          id: category.id,
          modules
        })
      })

      let res = await this.saveModuleCategories(payload)
      if (res.status == 'success') {
        this.fetchModuleCategories()
        this.$swal('Setting saved!', 'Module categories updated successfully.', 'success')
      } else {
        if (res.data.message != null)
          this.$swal('Error', res.data.message, 'error')
        else
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
      }
      this.loading_form_setting = false
    },
    onChangeIcon(event) {
      this.loading_upload_icon = true
      const files = [...event.target.files]

      const file_name = files[0].name

      const reader = new FileReader()
      reader.onload = (e) => {
        this.form.category.icon = {
          url: e.target.result,
          iconName: file_name,
        }
      }
      reader.readAsDataURL(files[0])

      this.uploadFile(files[0])
    },
    onChangeImage(event) {
      this.loading_upload_image = true
      const files = [...event.target.files]

      const file_name = files[0].name

      const reader = new FileReader()
      reader.onload = (e) => {
        this.form.module.icon.url = e.target.result
        this.form.module.icon.iconName = file_name
      }
      reader.readAsDataURL(files[0])
      
      this.uploadFile(files[0], true)
    },
    onEditCategory(category) {
      this.form_category_edit_mode = true
      this.selected_category = category
      this.form.category = {
        name: category.name,
        active: category.active,
        icon: category.icon,
      }
    },
    onEditModule(module_obj) {
      this.form_module_edit_mode = true
      this.selected_module = module_obj
      this.form.module = {
        name: module_obj.name,
        url: module_obj.url,
        icon: module_obj.icon,
        sameCategory: module_obj.sameCategory,
        referenceId: module_obj.referenceId ? module_obj.referenceId.id : null,
        active: module_obj.active,
      }
    },
    onDeleteCategory(category) {
      this.$swal({
        title: 'Delete category?',
        text: `Category ${category.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_category = true
          let res = await this.deleteFleetType(category.id)
          if (res.status != 'success') {
            this.loading_delete_category = false
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
          } else {
            this.loading_delete_category = false
            this.fetchFleetTypes()
            this.$swal(
              `Category deleted!`,
              `Category ${category.name} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    onDeleteModule(module_obj) {
      this.$swal({
        title: 'Delete module?',
        text: `Module ${module_obj.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_module = true
          let res = await this.deleteModule(module_obj.id)
          if (res.status != 'success') {
            this.loading_delete_module = false
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
          } else {
            this.loading_delete_module = false
            this.fetchFleetTypes()
            this.$swal(
              `Module deleted!`,
              `Module ${module_obj.name} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    resetFormCategory() {
      this.$refs.form_upload_icon.files = []
      this.form_category_edit_mode = false
      this.selected_category = {}
      this.form.category = {
        name: '',
        icon: {
          url: '',
          iconName: '',
        },
        active: true,
      }
    },
    resetFormModule() {
      this.$refs.form_upload_image.files = []
      this.form_module_edit_mode = false
      this.selected_module = {}
      this.form.module = {
        name: '',
        url: '',
        icon: {
          url: '',
          iconName: '',
        },
        sameCategory: false,
        referenceId: '',
        active: true,
      }
    },
    isCategoryHasAccess(module_id, categories_module) {
      let result = categories_module.find(cm => cm.id == module_id)
      if (result) return result.hasAccess
      return false
    },
    mergeModuleCategories() {
      if (this.module_categories.length > 0) {
        this.merged_module_categories = []
        this.module_categories.forEach(cat => {
          let modules = []
          this.modules.forEach(m => {
            modules.push({
              id: m.id,
              name: m.name,
              hasAccess: this.isCategoryHasAccess(m.id, cat.modules)
            })
          })
  
          this.merged_module_categories.push({
            id: cat.id,
            name: cat.name,
            modules
          })
        })
      }
    }
  },
}
</script>
