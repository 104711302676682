var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Summary Management")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.filter",
            modifiers: {
              "filter": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          attrs: {
            "visible": "",
            "id": "filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('date-picker', {
          attrs: {
            "type": "year",
            "format": "YYYY",
            "value-type": "format",
            "placeholder": "Select year"
          },
          model: {
            value: _vm.summary.params.year,
            callback: function callback($$v) {
              _vm.$set(_vm.summary.params, "year", $$v);
            },
            expression: "summary.params.year"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.typeOptions,
            "reduce": function reduce(type) {
              return type.value;
            },
            "placeholder": "Select type",
            "clearable": false
          },
          model: {
            value: _vm.summary.params.type,
            callback: function callback($$v) {
              _vm.$set(_vm.summary.params, "type", $$v);
            },
            expression: "summary.params.type"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.kapal,
            "reduce": function reduce(type) {
              return type.value;
            },
            "placeholder": "select Fleet",
            "multiple": true
          },
          model: {
            value: _vm.summary.params.vehicle_ids,
            callback: function callback($$v) {
              _vm.$set(_vm.summary.params, "vehicle_ids", $$v);
            },
            expression: "summary.params.vehicle_ids"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.fetchSummary
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1), _c('hr')];
      },
      proxy: true
    }])
  })], 1)], 1), _vm.isLoading ? _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(" Loading... ")]) : [_vm.summary.data !== null ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-primary rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.handleOpenTotalCase();
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "ri-ship-fill"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v(_vm._s(_vm.summary.data.total_case))])]), _c('h5', {}, [_vm._v("Total Case")])])])])];
      },
      proxy: true
    }], null, false, 1013241179)
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-info rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.handleOpenNonClaim();
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-blue text-white"
        }, [_c('i', {
          staticClass: "ri-ship-2-line"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v(_vm._s(_vm.summary.data.total_nonclaim))])]), _c('h5', {}, [_vm._v("Non Claim")])])])])];
      },
      proxy: true
    }], null, false, 2917012401)
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-danger rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.handleOpenClaim();
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-danger"
        }, [_c('i', {
          staticClass: "ri-ship-2-fill"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v(_vm._s(_vm.summary.data.total_claim))])]), _c('h5', {}, [_vm._v("Claim")])])])])];
      },
      proxy: true
    }], null, false, 1700797773)
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-success rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.handleOpenImprovement();
            }
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-success"
        }, [_c('i', {
          staticClass: "ri-hospital-line"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h5', {}, [_vm._v("Continual Improvement")])])])])];
      },
      proxy: true
    }], null, false, 543474342)
  })], 1)], 1) : _vm._e(), _vm.summary.data !== null ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Case Condition")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('ApexChart', {
          attrs: {
            "element": "chart-case-condition",
            "chartOption": _vm.chartCaseCondition
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 363251103)
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Claim - Recovery")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('ApexChart', {
          attrs: {
            "element": "chart-claim-recovery",
            "chartOption": _vm.chartClaimRecovery
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 236488540)
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Non Claim - Cost of Loss")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('ApexChart', {
          attrs: {
            "element": "chart-nonclaim-loss",
            "chartOption": _vm.chartNonClaimLoss
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 272696913)
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Cost of Loss")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return _vm._l(_vm.summary.data.cost_of_loss, function (data, index) {
          return _c('table', {
            key: index,
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', [_c('th', {
            staticClass: "bg-danger text-center"
          }, [_vm._v(_vm._s(data.name))])])]), _c('tbody', [_c('tr', [_c('td', {
            staticClass: "text-center"
          }, [_c('h3', [_vm._v(_vm._s(_vm.formatCurrency(data.total)))])])])])]);
        });
      },
      proxy: true
    }], null, false, 2602987165)
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Recovery")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return _vm._l(_vm.summary.data.recovery, function (data, index) {
          return _c('table', {
            key: index,
            staticClass: "table table-bordered"
          }, [_c('thead', [_c('tr', [_c('th', {
            staticClass: "bg-success text-center"
          }, [_vm._v(_vm._s(data.name))])])]), _c('tbody', [_c('tr', [_c('td', {
            staticClass: "text-center"
          }, [_c('h3', [_vm._v(_vm._s(_vm.formatCurrency(data.total)))])])])])]);
        });
      },
      proxy: true
    }], null, false, 1748973509)
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Rate Record")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return _vm._l(_vm.chartRateRecords, function (chart, index) {
          return _c('b-row', {
            key: index
          }, [_c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('h3', [_vm._v(_vm._s(chart.name))]), _c('ApexChart', {
            attrs: {
              "element": "chart-rate-record-".concat(chart.alias),
              "chartOption": chart
            }
          })], 1)], 1);
        });
      },
      proxy: true
    }], null, false, 3675909986)
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Loss Ratio")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return _vm._l(_vm.chartLossRatio, function (chart, index) {
          return _c('b-row', {
            key: index
          }, [_c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('h3', [_vm._v(_vm._s(chart.name))]), _c('ApexChart', {
            attrs: {
              "element": "chart-loss-ratio-".concat(chart.alias),
              "chartOption": chart
            }
          })], 1)], 1);
        });
      },
      proxy: true
    }], null, false, 282752604)
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Details classification of case & cause of loss")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "overflow-auto",
          staticStyle: {
            "height": "300px"
          }
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("Classification of Case")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Total")])])]), _c('tbody', [_vm._l(_vm.summary.data.matrix_coverage, function (data, index) {
          return _c('tr', {
            key: index,
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                data.value !== null ? _vm.onShowAccidentForm(data.forms) : function () {};
              }
            }
          }, [data.value === null ? _c('th', {
            staticClass: "bg-warning text-center",
            attrs: {
              "colspan": "2"
            }
          }, [_vm._v(_vm._s(data.label))]) : _vm._e(), data.value !== null ? _c('td', [_vm._v(_vm._s(data.label))]) : _vm._e(), data.value !== null ? _c('td', {
            staticClass: "text-center"
          }, [_c('strong', [_vm._v(_vm._s(data.value))])]) : _vm._e()]);
        }), _c('tr', {
          staticClass: "bg-info",
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.onShowMatrixClassificationOfLossTable();
            }
          }
        }, [_c('td', [_c('strong', [_vm._v("Total")])]), _c('td', {
          staticClass: "text-center"
        }, [_c('strong', [_vm._v(_vm._s(_vm.summary.data.matrix_coverage_total))])])])], 2)])]), _c('b-col', {
          staticClass: "iq-bg-success",
          attrs: {
            "md": "6"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("Cause of Loss")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Amount")])])]), _c('tbody', [_vm._l(_vm.summary.data.matrix_cause_of_loss, function (data, index) {
          return _c('tr', {
            key: index,
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.onShowAccidentForm(data.forms);
              }
            }
          }, [_c('td', [_vm._v(_vm._s(data.label))]), _c('td', {
            staticClass: "text-center"
          }, [_c('strong', [_vm._v(_vm._s(data.value))])])]);
        }), _c('tr', {
          staticClass: "bg-info",
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.onShowMatrixCauseOfLossTable();
            }
          }
        }, [_c('td', [_c('strong', [_vm._v("Total")])]), _c('td', {
          staticClass: "text-center"
        }, [_c('strong', [_vm._v(_vm._s(_vm.summary.data.matrix_cause_of_loss.reduce(function (a, b) {
          return a + b.value;
        }, 0)))])])])], 2)])])], 1)];
      },
      proxy: true
    }], null, false, 290350208)
  })], 1)], 1) : _vm._e(), _c('b-row', {
    staticClass: "mt-1 d-none"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Detail Accident")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('span', [_c('small', [_vm._v("click this button for show and collapse data! →")])]), _c('span', {
          staticClass: "ml-3"
        }, [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-detail-accident",
            modifiers: {
              "collapse-detail-accident": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-circle"
        })])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "visible": "",
            "id": "collapse-detail-accident"
          }
        }, [_vm.summary.data ? _c('SummaryManagementDetailAccidentList', {
          attrs: {
            "data": _vm.summary.data.forms
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _vm.summary.data !== null ? _c('iq-card', {
    class: "d-none",
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.showSettledBox,
            callback: function callback($$v) {
              _vm.showSettledBox = $$v;
            },
            expression: "showSettledBox"
          }
        }, [_vm._v(" Show Settled ")]), _c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.showOnGoingBox,
            callback: function callback($$v) {
              _vm.showOnGoingBox = $$v;
            },
            expression: "showOnGoingBox"
          }
        }, [_vm._v(" Show On Going ")])];
      },
      proxy: true
    }], null, false, 329366982)
  }) : _vm._e(), _vm.summary.data !== null && _vm.showSettledBox ? _c('b-col', {
    attrs: {
      "md": "12 d-none"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Settled")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.summary.data.claim_status_report ? [_c('SummaryManagementReportSettled', {
          attrs: {
            "lists": _vm.summary.data.claim_status_report.settled
          }
        })] : _vm._e()];
      },
      proxy: true
    }], null, false, 246034783)
  })], 1) : _vm._e(), _vm.summary.data !== null && _vm.showOnGoingBox ? _c('b-col', {
    attrs: {
      "md": "12 d-none"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("On Going")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.summary.data.claim_status_report ? [_c('SummaryManagementReportUnSettled', {
          attrs: {
            "lists": _vm.summary.data.claim_status_report.unsettled
          }
        })] : _vm._e()];
      },
      proxy: true
    }], null, false, 3128141037)
  })], 1) : _vm._e(), _c('b-modal', {
    attrs: {
      "id": "modal-accident",
      "hide-header": "",
      "size": "xl",
      "hide-footer": ""
    },
    model: {
      value: _vm.showAccidentPopup,
      callback: function callback($$v) {
        _vm.showAccidentPopup = $$v;
      },
      expression: "showAccidentPopup"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Detail Accident")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "mt-2 m-auto",
          attrs: {
            "md": "12"
          }
        }, [_vm.filteredForms ? _c('SummaryManagementDetailAccidentList', {
          attrs: {
            "data": _vm.filteredForms
          }
        }) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
          staticClass: "text-right mt-4",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              _vm.showAccidentPopup = false;
            }
          }
        }, [_vm._v("Close ")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-cause-of-loss-table",
      "hide-header": "",
      "size": "xl",
      "hide-footer": ""
    },
    model: {
      value: _vm.showMatrixCauseOfLossTable,
      callback: function callback($$v) {
        _vm.showMatrixCauseOfLossTable = $$v;
      },
      expression: "showMatrixCauseOfLossTable"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Cause of Loss")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "mt-2 m-auto",
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Date.")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("No.")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Name of Vessel")]), _vm._l(_vm.summary.data.matrix_cause_of_loss, function (data, index) {
          return _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "20%"
            }
          }, [_vm._v(_vm._s(data.label) + " ")]);
        })], 2)]), _c('tbody', [_vm._l(_vm.matrixCauseOfLossTableData, function (data, index) {
          return [_c('tr', {
            key: index
          }, [_c('td', {
            staticClass: "text-center",
            attrs: {
              "rowspan": data.lists.length + 1
            }
          }, [_vm._v(_vm._s(data.month_year))])]), _vm._l(data.lists, function (list, listIndex) {
            return _c('tr', {
              key: listIndex
            }, [_c('td', {
              staticClass: "text-center"
            }, [_vm._v(_vm._s(listIndex + 1))]), _c('td', {
              staticClass: "text-center"
            }, [_vm._v(_vm._s(list.vehicle))]), _vm._l(_vm.summary.data.matrix_cause_of_loss, function (data2) {
              return [_c('td', {
                class: list.check_id === data2.id ? 'text-center text-white bg-danger' : 'text-center',
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v(_vm._s(list.check_id === data2.id ? 'V' : '-'))])];
            })], 2);
          })];
        })], 2)])])], 1), _c('b-row', [_c('b-col', {
          staticClass: "text-right mt-4",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              _vm.showMatrixCauseOfLossTable = false;
            }
          }
        }, [_vm._v("Close ")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-classification-of-loss-table",
      "hide-header": "",
      "size": "xl",
      "hide-footer": ""
    },
    model: {
      value: _vm.showMatrixClassificationOfLossTable,
      callback: function callback($$v) {
        _vm.showMatrixClassificationOfLossTable = $$v;
      },
      expression: "showMatrixClassificationOfLossTable"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Classification of Loss")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "mt-2 m-auto overflow-auto",
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Date.")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("No.")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Name of Vessel")]), _vm._l(_vm.summary.data.matrix_coverage, function (data, index) {
          return [data.value !== null ? _c('th', {
            staticClass: "text-center",
            attrs: {
              "width": "20%"
            }
          }, [_vm._v(_vm._s(data.label))]) : _vm._e()];
        })], 2)]), _c('tbody', [_vm._l(_vm.matrixCoverageTableData, function (data, index) {
          return [_c('tr', {
            key: index
          }, [_c('td', {
            staticClass: "text-center",
            attrs: {
              "rowspan": data.lists.length + 1
            }
          }, [_vm._v(_vm._s(data.month_year))])]), _vm._l(data.lists, function (list, listIndex) {
            return _c('tr', {
              key: listIndex
            }, [_c('td', {
              staticClass: "text-center"
            }, [_vm._v(_vm._s(listIndex + 1))]), _c('td', {
              staticClass: "text-center"
            }, [_vm._v(_vm._s(list.vehicle))]), _vm._l(_vm.summary.data.matrix_coverage, function (data2) {
              return [data2.value !== null ? _c('td', {
                class: list.check_id === data2.id ? 'text-center bg-danger text-white' : 'text-center',
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v(_vm._s(list.check_id === data2.id ? 'V' : '-') + " ")]) : _vm._e()];
            })], 2);
          })];
        })], 2)])])], 1), _c('b-row', [_c('b-col', {
          staticClass: "text-right mt-4",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              _vm.showMatrixClassificationOfLossTable = false;
            }
          }
        }, [_vm._v(" Close ")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], _c('b-sidebar', {
    attrs: {
      "id": "sidebar-total-case",
      "title": "TOTAL CASE DETAIL",
      "width": "1300px",
      "backdrop-variant": "dark",
      "right": "",
      "backdrop": "",
      "shadow": ""
    }
  }, [_c('b-row', {
    staticClass: "pt-2 pl-3 pr-3 pb-2"
  }, [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _vm.summary.data ? _c('SummaryManagementDetailAccidentList', {
    attrs: {
      "data": _vm.summary.data.forms
    }
  }) : _vm._e()], 1)], 1)], 1), _c('b-sidebar', {
    attrs: {
      "id": "sidebar-claim",
      "title": "CLAIM DATA",
      "width": "1300px",
      "backdrop-variant": "dark",
      "right": "",
      "backdrop": "",
      "shadow": ""
    }
  }, [_c('b-row', {
    staticClass: "pt-2 pl-3 pr-3 pb-2"
  }, [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr')]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": true,
            "href": "#on-going",
            "title": "On Going"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": false,
            "href": "#settled",
            "title": "Settled"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "on-going"
    }
  }, [_vm.summary.data && _vm.summary.data.claim_status_report ? _c('SummaryManagementReportUnSettled', {
    attrs: {
      "lists": _vm.summary.data.claim_status_report.unsettled
    }
  }) : _vm._e()], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "settled"
    }
  }, [_vm.summary.data && _vm.summary.data.claim_status_report ? _c('SummaryManagementReportSettled', {
    attrs: {
      "lists": _vm.summary.data.claim_status_report.settled
    }
  }) : _vm._e()], 1)], 1)])], 1)], 1), _c('b-sidebar', {
    attrs: {
      "id": "sidebar-non-claim",
      "title": "NON CLAIM DATA",
      "width": "1300px",
      "backdrop-variant": "dark",
      "right": "",
      "backdrop": "",
      "shadow": ""
    }
  }, [_c('b-row', {
    staticClass: "pt-2 pl-3 pr-3 pb-2"
  }, [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _vm.summary.data ? _c('SummaryManagementNonClaim', {
    attrs: {
      "lists": _vm.summary.data.nonclaim_ongoing
    }
  }) : _vm._e()], 1)], 1)], 1), _c('b-sidebar', {
    attrs: {
      "id": "sidebar-improvement",
      "title": "CONTINUAL IMPROVEMENT",
      "width": "100%",
      "backdrop-variant": "dark",
      "right": "",
      "backdrop": "",
      "shadow": ""
    }
  }, [_c('b-row', {
    staticClass: "pt-2 pl-3 pr-3 pb-2"
  }, [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr')]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": true,
            "href": "#recommendation",
            "title": "Recommendation"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": false,
            "href": "#evaluation",
            "title": "Evaluation"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": false,
            "href": "#erm",
            "title": "Enterprise Risk Management"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-3"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "recommendation"
    }
  }, [_vm.summary.data ? _c('SummaryManagementRecommendations', {
    attrs: {
      "show-action-buttons": false,
      "lists": _vm.summary.data.form_with_recommendation
    }
  }) : _vm._e()], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "evaluation"
    }
  }, [_vm.summary.data ? _c('SummaryManagementEvaluations', {
    attrs: {
      "lists": _vm.summary.data.form_with_evaluation
    }
  }) : _vm._e()], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "erm"
    }
  }, [_vm.summary.data ? _c('SummaryManagementERM', {
    attrs: {
      "lists": _vm.summary.data.form_with_erm
    }
  }) : _vm._e()], 1)], 1)])], 1)], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }