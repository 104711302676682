var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Forecast Condition - Potential Claim/Loss")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('span', {
          staticClass: "ml-3"
        }, [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-filter",
            modifiers: {
              "collapse-filter": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          attrs: {
            "id": "collapse-filter",
            "visible": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('v-select', {
          attrs: {
            "options": _vm.yearOption,
            "placeholder": "Select Year",
            "clearable": false
          },
          model: {
            value: _vm.selectYear,
            callback: function callback($$v) {
              _vm.selectYear = $$v;
            },
            expression: "selectYear"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "disabled": _vm.selectYear === '',
            "variant": "primary"
          },
          on: {
            "click": _vm.fetchListForecast
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "6"
          }
        }, [_vm.canAdd ? _c('router-link', {
          attrs: {
            "to": {
              name: 'mi.forecast-condition-input'
            }
          }
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add New Year Forecasting")])], 1) : _vm._e(), _vm.canExport ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [!_vm.loading_export ? [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("   Export ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Exporting... ")]], 2) : _vm._e()], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "text-center"
        }, [_c('tr', [_c('th', {
          staticStyle: {
            "vertical-align": "middle"
          }
        }, [_vm._v("Years")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          }
        }, [_vm._v("Vehicle Type")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          }
        }, [_vm._v("Actual Premium")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          }
        }, [_vm._v("Claim Loss")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          }
        }, [_vm._v("Estimate Loss Future")]), _c('th', {
          staticStyle: {
            "vertical-align": "middle"
          }
        }, [_vm._v("Total Claim Loss "), _c('br'), _c('small', [_vm._v("Claim Loss + Estimate")])]), _c('th', {
          staticClass: "bg-warning",
          staticStyle: {
            "vertical-align": "middle"
          }
        }, [_vm._v("Loss Ratio")]), _c('th', {
          staticClass: "bg-light",
          staticStyle: {
            "vertical-align": "middle"
          }
        }, [_vm._v("Forecast Next Year")]), _c('th', [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.lists.data, function (data) {
          return _c('tr', [_c('td', [_vm._v(_vm._s(data.year))]), _c('td', [_vm._v(_vm._s(data.vehicle_type_group.name))]), _c('td', [_vm._v(_vm._s(_vm.numberFormat(data.actual_premium)))]), _c('td', [_vm._v(_vm._s(_vm.numberFormat(data.claim_loss)))]), _c('td', [_vm._v(_vm._s(_vm.numberFormat(data.estimate_loss_future)))]), _c('td', [_vm._v(_vm._s(_vm.numberFormat(parseInt(data.claim_loss) + parseInt(data.estimate_loss_future))))]), _c('td', [_vm._v(_vm._s(_vm.numberFormat(data.claim_loss_ratio)))]), _c('td', [_vm._v(_vm._s(_vm.numberFormat(data.forecast_burden)))]), _c('td', [_c('b-button-group', {
            staticClass: "mr-2"
          }, [_vm.canEdit ? _c('b-button', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            attrs: {
              "title": "Edit Forecast",
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.handleEdit(data.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _c('b-button', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            attrs: {
              "variant": "dark",
              "title": "View Forecast"
            },
            on: {
              "click": function click($event) {
                return _vm.handleView(data.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-eye"
          })])], 1)], 1)]);
        }), 0)])])])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }