var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSave();
            }
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('img', {
          staticClass: "img-fluid w-20",
          attrs: {
            "src": _vm.image,
            "alt": "logo"
          }
        })]), _c('b-col', {
          attrs: {
            "cols": "6",
            "align-self": "center"
          }
        }), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('hr')]), _c('b-col', {
          staticClass: "mt-4",
          attrs: {
            "cols": "5"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Form Survey Name:",
            "label-for": "serial"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "serial",
            "type": "text",
            "required": "",
            "placeholder": "Nomor Register"
          },
          model: {
            value: _vm.form.survey_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "survey_name", $$v);
            },
            expression: "form.survey_name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Description:",
            "label-for": "serial"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "exampleFormControlTextarea1",
            "required": ""
          },
          model: {
            value: _vm.form.description,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "description", $$v);
            },
            expression: "form.description"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "sub_sub_project"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "required": "",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.active,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "active", $$v);
            },
            expression: "form.active"
          }
        }, [_vm._v(" Active ")])], 1), _c('b-form-group', {
          attrs: {
            "label": "Number of Label:",
            "label-for": "serial"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "serial",
            "required": "",
            "type": "number"
          },
          on: {
            "change": _vm.generateListLabels
          },
          model: {
            value: _vm.form.number_of_label,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "number_of_label", $$v);
            },
            expression: "form.number_of_label"
          }
        })], 1), _c('p'), _c('h4', [_vm._v("LABEL")]), _c('hr'), _c('p'), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("#")]), _c('th', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Label Name")]), _c('th', {
          attrs: {
            "width": "40%"
          }
        }, [_vm._v("Type")])])]), _c('tbody', _vm._l(_vm.form.labels, function (label, index) {
          return _c('tr', [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_c('b-form-input', {
            attrs: {
              "id": "serial",
              "type": "text",
              "placeholder": "Input your label name..."
            },
            model: {
              value: label.name,
              callback: function callback($$v) {
                _vm.$set(label, "name", $$v);
              },
              expression: "label.name"
            }
          })], 1), _c('td', [_c('v-select', {
            attrs: {
              "label": "text",
              "options": _vm.typeOptions,
              "reduce": function reduce(type) {
                return type.value;
              }
            },
            model: {
              value: label.type,
              callback: function callback($$v) {
                _vm.$set(label, "type", $$v);
              },
              expression: "label.type"
            }
          })], 1)]);
        }), 0)])], 1), _c('b-col', {
          attrs: {
            "cols": "7"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("#")]), _vm._l(_vm.form.labels, function (label, index) {
          return _c('th', {
            attrs: {
              "width": "10%"
            }
          }, [_vm._v(_vm._s(label.name))]);
        }), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("Act")])], 2)]), _c('tbody', _vm._l(_vm.form.labelContents, function (content, contentLabel) {
          return _c('tr', [_c('td', [_vm._v(_vm._s(contentLabel + 1))]), _vm._l(_vm.form.labels, function (label, labelIndex) {
            return _c('td', [label.type === 'text_label' ? _c('b-form-textarea', {
              attrs: {
                "required": "",
                "placeholder": "Input your text..."
              },
              model: {
                value: content[labelIndex],
                callback: function callback($$v) {
                  _vm.$set(content, labelIndex, $$v);
                },
                expression: "content[labelIndex]"
              }
            }) : label.type === 'user_input' ? _c('v-select', {
              attrs: {
                "required": "",
                "label": "text",
                "options": _vm.userInputOptions,
                "reduce": function reduce(type) {
                  return type.value;
                }
              },
              model: {
                value: content[labelIndex],
                callback: function callback($$v) {
                  _vm.$set(content, labelIndex, $$v);
                },
                expression: "content[labelIndex]"
              }
            }) : _vm._e()], 1);
          }), _c('td', [_c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.removeLabelContent(contentLabel);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])], 1)], 2);
        }), 0)]), _c('p', [_c('b-button', {
          staticClass: "mr-2",
          attrs: {
            "variant": "success",
            "block": "",
            "size": "md"
          },
          on: {
            "click": _vm.addLabelContent
          }
        }, [_vm._v("ADD MORE "), _c('i', {
          staticClass: "fa fa-plus"
        })])], 1)])], 1), _c('b-row', [_c('b-col', {
          staticClass: "text-right mt-4",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "disabled": _vm.form._saving,
            "variant": "primary"
          }
        }, [_vm._v(_vm._s(_vm.form._saving ? "Saving..." : 'Submit') + " Submit")]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "disabled": _vm.form._saving,
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.go(-1);
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }