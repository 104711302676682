<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-4 p-0" :active="true" href="#goodsSetup" title="Goods Setup in Fleets"/>
                <tab-nav-items class="col-md-4 p-0" :active="false" href="#goods" title="Goods"/>
                <tab-nav-items class="col-md-4 p-0" :active="false" href="#categoryUnits" title="Category & Units"/>
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <tab-content-item :active="true" id="goodsSetup">
        <ComponentGoodsSetup/>
      </tab-content-item>
      <tab-content-item :active="false" id="goods">
        <ComponentGoods/>
      </tab-content-item>
      <tab-content-item :active="false" id="categoryUnits">
        <ComponentCategoryUnits/>
      </tab-content-item>
    </div>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import ComponentGoods from '@src/views/Goods/component/ComponentGoods'
import ComponentGoodsSetup from '@src/views/Goods/component/ComponentGoodsSetup'
import ComponentCategoryUnits from '@src/views/Goods/component/ComponentCategoryUnits'

export default {
  name: 'BlankPage',
  components: { ComponentCategoryUnits, ComponentGoodsSetup, ComponentGoods },
  mounted () {
    xray.index()
  },
  data () {
    return {

    }
  }
}
</script>
