<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title text-primary">
                        <strong class="uppercase">HISTORY REPAIR & MAINTENANCE - {{ fleets }}</strong>
                        </h4>
                    </template>
                    <template v-slot:headerAction>
                        <a class="text-primary float-right" v-b-toggle.collapse-filter role="button" style="cursor: pointer"><i class="fa fa-calendar" /></a>
                    </template>
                    <template v-slot:body>
                    <b-collapse id="collapse-filter" class="mb-2">
                        <b-row>
                            <b-col md="6">
                                <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range"></date-picker>
                            </b-col>
                            <b-col md="5" />
                        </b-row>
                    </b-collapse>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th class="text-center">TOTAL REPAIR</th>
                                <th class="text-center">TOTAL COST</th>
                                <th class="text-center">LOKASI</th>
                                <th class="text-center">TANGGAL</th>
                                <th class="text-center">STATUS</th>
                                <th class="text-center">MINOR</th>
                                <th class="text-center">MEDIUM</th>
                                <th class="text-center">MAJOR</th>
                                <th class="text-center">ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(state, index) in list_repair_finished.data" :key="index">
                                <td class="text-center">{{ state?.total_repair }} Jobs </td>
                                <td class="text-center">{{ formatCurrency(state?.total_cost_real) }}</td>
                                <td class="text-center">{{  state?.location }}</td>
                                <td class="text-center">{{  moment(state?.accident_at).format('DD MMMM YYYY') }}</td>
                             
                              <td class="text-center">
                                <b-badge variant="info" class="mb-1">Earlier : {{ state?.status?.earlier }} Jobs</b-badge> <br/>
                                <b-badge variant="success" class="mb-1">On Time : {{ state?.status?.onTime }} Jobs</b-badge><br>
                                <b-badge variant="danger">Delay : {{ state?.status?.late }} Jobs</b-badge>
                              </td>
                              <td class="text-center">{{ state?.classfication?.minor }} Jobs</td>
                              <td class="text-center">{{ state?.classfication?.medium }} Jobs</td>
                              <td class="text-center">{{ state?.classfication?.major }} Jobs</td>
                              <td class="text-center">
                                    <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                        <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                        </span>
                                    </template>
                                    <b-dropdown-item href="#">
                                        <b-dropdown-item href="#" v-b-toggle.detail_sr @click="() => getDetailRepairServiceAndRepair(state?.id,  state?.created_at)">
                                            <i class="fa fa-info-circle mr-2"></i>Detail
                                        </b-dropdown-item>
                                    </b-dropdown-item>
                                    </b-dropdown>
                                </td>
                            </tr>
                          </tbody>
                    </table>
                    <div class="mt-3" v-if="list_repair_finished?.data?.length > 0">
                        <b-pagination
                            v-model="list_repair_finished.meta.page"
                            :total-rows="list_repair_finished.meta.total_item"
                            :per-page="list_repair_finished.meta.per_page"
                            aria-controls="my-table"
                            @change="getListFinishedTableServiceAndRepairById"
                            align="center"
                            first-number
                        />
                    </div>
                    <!-- SLIDE DETAIL -->
                    <b-sidebar id="detail_sr" :title="`DETAIL REPAIR & MAINTENANCE - ${detail_repair_finished?.vehicle?.name}`" width="70%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                        <template #default="{hide}">
                        <b-row class="m-3">
                            <b-col md="12">
                            <fieldset class="form-group border p-3">
                                <legend class="w-auto px-2 text-primary">Detail Information</legend>
                                <table class="table mb-0 table-striped">
                                <tbody>
                                    <tr>
                                        <th width="20%">Lokasi Pekerjaan</th>
                                        <td width="5%">:</td>
                                        <td width="25%">{{ detail_repair_finished?.location }}</td>
                                        <th width="20%">Tanggal</th>
                                        <td width="5%">:</td>
                                        <td width="25%">{{ moment(detail_repair_finished?.created_at).format('DD MMMM YYYY') }}</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Office / Site</th>
                                        <td width="5%">:</td>
                                        <td width="25%">{{ detail_repair_finished?.site }}</td>
                                        <th width="20%">PIC Repair</th>
                                        <td width="5%">:</td>
                                        <td width="25%">{{ detail_repair_finished?.pic }}</td>
                                    </tr>
                                </tbody>
                                </table>
                            </fieldset>
                            <div v-for="(state, index) in detail_repair_finished?.form_repair" :key="index">
                                <b-card no-body>
                                    <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                        <b-button block v-b-toggle="`accordion-` + index" 
                                        :variant="state?.status?.alias === 'new' ? 'info' : 
                                        state?.status?.alias === 'progress' ? 'warning' : 
                                        state?.status?.alias === 'finish' ? 'success' :
                                        state?.status?.alias === 'review' ? 'primary' : 
                                        'danger'" class="text-left">
                                          <i class="fa fa-chevron-circle-down"></i> {{ state?.name }} - {{ state?.title }}  <strong>({{ state?.status?.name }})</strong>
                                        </b-button>
                                      </b-card-header>
                                        <b-collapse :id="'accordion-' + index" :visible="false" accordion="my-accordion" role="tabpanel">
                                            <table class="table table-striped mt-3">
                                                <tbody>
                                                <tr>
                                                    <th width="20%">Equipment Name</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ state?.name }}</td>
                                                    <th width="20%">Repair Title</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ state?.title }}</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Type R & M</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ state?.task_type?.name }}</td>
                                                    <th width="20%">Department</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{state?.department?.name }}</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Category</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ state?.category?.name }}</td>
                                                    <th width="20%">Classification</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ state?.classification?.name }} ({{ state?.classification?.interval }} {{ state?.classification?.unit }})</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Cause</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ state?.case?.name }}</td>
                                                    <th width="20%">Kind Task</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Repair</td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Rencana Mulai</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ moment(state?.start_at).format('DD MMMM YYYY') }}</td>
                                                    <th width="20%">Rencana Selesai (Est)</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ moment(state?.actual_finished_at).format('DD MMMM YYYY') }}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%"><b>Estimate Cost</b></td>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                    {{ formatCurrency(state.cost_estimate) }}
                                                    </td>
                                                    <th width="20%">Estimasi Hari</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">{{ state?.estimate_total_days }}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%"><b>Cost Real</b></td>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                    {{ formatCurrency(state.cost_real) }}
                                                    </td>
                                                    <td width="20%"><b>Status Pencapaian</b></td>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                    {{ state?.reward }}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Berita Acara</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td colspan="4">
                                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                            <li class="col-md-1 col-1" v-for="(file_data, fileIndex) in state?.file" :key="fileIndex">
                                                            <a href="javascript:void(0);">
                                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" 
                                                                class="img-fluid" @click="handleOpenFilePreview(file_data.file)" />
                                                            </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th width="20%">Description</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td class="align-top" colspan="4">
                                                        {{ state?.description }}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <hr>
                                            <fieldset class="form-group border p-3" v-if="state?.is_internal">
                                            <legend class="w-auto px-2">Internal Man Power</legend>
                                            <table class="table table-striped table-sm">
                                                <tbody>
                                                <tr>
                                                    <th class="text-left">NAMA</th>
                                                    <th class="text-left">JABATAN</th>
                                                </tr>
                                                <tr v-for="(entity, entityIndex) in state?.entity_internal" :key="entityIndex">
                                                    <td class="text-left">{{ entity?.internal_name }}</td>
                                                    <td class="text-left">{{ positionName(entity?.internal_position_id) }}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </fieldset>
                                            <fieldset class="form-group border p-3" v-if="state?.is_subkon">
                                            <legend class="w-auto px-2">Sub Kontraktor</legend>
                                            <table class="table table-striped table-sm">
                                                <tbody>
                                                <tr>
                                                    <th class="pl-3">NAMA KONTRAKTOR</th>
                                                    <th>PIC/EXECUTIVE ENGINEER</th>
                                                    <!-- <th>EMAIL</th>
                                                    <th>PHONE</th> -->
                                                    <th class="text-center">OFFERING</th>
                                                    <th class="text-center">QUOTATION</th>
                                                    <th class="text-center">FINAL QUOTATION</th>
                                                    <th class="text-center">FILE</th>
                                                </tr>
                                                <tr v-for="(entity, entityIndex) in state?.entity_subkon" :key="entityIndex">
                                                    <td class="pl-3" >{{ entity?.subkon_name }}</td>
                                                    <td >{{ entity?.subkon_pic }}</td>
                                                    <!-- <td >{{ entity?.subkon_pic_email }}</td>
                                                    <td >{{ entity?.subkon_pic_phone }}</td> -->
                                                    <td class="text-center" >{{ formatCurrency(entity?.subkon_price_offering) }}</td>
                                                    <td class="text-center" >{{ formatCurrency(entity?.subkon_price_nego) }}</td>
                                                    <td class="text-center" >{{ formatCurrency(entity?.subkon_price_deal) }}</td>
                                                    <td class="text-center text-primary" v-if="entity?.subkon_file?.length > 0">
                                                        <div v-for="(fileSubkon, subkonIndexFile) in entity?.subkon_file" :key="subkonIndexFile"  class="mr-2">
                                                            <i v-if="fileSubkon.extension === 'pdf'" class="fa fa-file-pdf text-primary h4" style="cursor:pointer" @click="handleOpenFilePreview(fileSubkon?.url)"></i>
                                                            <img v-else :src="fileSubkon?.url" alt="" class="img-fluid" style="width: 70px; height: 70px;">
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            </fieldset>
                                            <ul class="iq-timeline" style="height:500px">
                                                <li v-for="(activity, aIndex) in state?.form_repair_activity" :key="aIndex">
                                                <div class="timeline-dots border-success"></div>
                                                <h6 class="float-left mb-1">{{ activity?.user_name }}</h6>
                                                <br />
                                                <small>{{ moment(activity?.date_of_activity).format('DD MMMM YYYY h:mm') }}</small>
                                                <div class="d-inline-block w-100">
                                                <b-badge :variant="activity?.activity.activity_flag === 'running' ? 'primary' : activity?.activity.activity_flag === 'finish' ? 'success' : 'danger'">{{ activity?.activity?.name ?? '-' }}</b-badge> <br /> <p><strong class="text-muted">Notes:</strong> {{ activity?.notes }}</p>
                                            </div>
                                                <div class="d-inline-block w-100" v-if="activity?.parsed_file?.length > 0">
                                                    <ul class="d-flex flex-wrap p-0 m-0">
                                                    <li class="pb-2" v-for="(file, fileIndex) in activity?.parsed_file" :key="fileIndex">
                                                        <a href="javascript:void(0);">
                                                            <img v-if="file.extension == 'pdf'" src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid" style="width: 70px; height: 70px;" @click="handleOpenFilePreview(file?.url)" />
                                                            <img v-else :src="file.url" alt="image" class="img-fluid"  style="width: 70px; height: 70px;"/>
                                                        </a>
                                                    </li>
                                                    </ul>
                                                </div>
                                                </li>
                                                <!-- <li>
                                                    <b-button type="button" variant="primary" @click="getSingleServiceAndRepair(state.form_id, 10)" v-if="state?.form_repair_activity?.length > 0">
                                                        Load More
                                                    </b-button>
                                                </li> -->
                                            </ul>
                                        </b-collapse>
                                </b-card>
                            </div>
                            </b-col>
                        </b-row>
                        </template>
                    </b-sidebar>
                    <!-- SLIDE END -->
                </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { xray }from '../../config/pluginInit'
import {
      fleetsActions,
      crewingActions,
      srActions
    } from '@src/Utils/helper'
import moment from 'moment'
import optionsData from '@src/core/mock/data'

export default {
    name: 'ServiceRepairHistory',
    data(){
        return {
            ...optionsData,
            fleets:'',
            dateRange:null,
            optionsDepartment:[],

            loading_table:false,
            loading_detail:false,

            list_repair_finished:{
                data:[],
                meta:null
            },
            detail_repair_finished:null,
        }
    },
   async mounted () {
        xray.index()
        this.dateRange = this.defaultRange;

        await this.getListFinishedTableServiceAndRepairById()
        await this.getMasterDepartment()
        await this.fetchFleets()
    },
    computed: {
        defaultRange() {
          const currentDate = new Date() ;
          const nextDay = new Date(currentDate);
          nextDay.setDate(currentDate.getDate() + 1);
          // Get the last date of the current month
          let lastDateOfMonth = moment().endOf('month');
          lastDateOfMonth = new Date(lastDateOfMonth)
          // Format it to show only the date
          const firstMonthOfTheYear = new Date(
            currentDate.getFullYear(),
            0,
            1
          );
          return [firstMonthOfTheYear, lastDateOfMonth];
        },
    },
    created() {
        this.getListFinishedTableServiceAndRepairById = _.debounce(this.getListFinishedTableServiceAndRepairById, 500)
    },
    watch:{
        'dateRange': {
        handler(n, o) {
            this.getListFinishedTableServiceAndRepairById()
        },
        immediate: true,
        },
    },
    methods:{
        ...fleetsActions,
        ...srActions,
        ...crewingActions,
        positionName(value){
            let position_name = this.optionsJabatan.find(obj => Number(value) === Number(obj.value))

            return position_name?.text ?? "N/A"
        },
        formatCurrency(value) {
          return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
          }).format(value)
        },
         handleOpenFilePreview: function (url) {
            window.open(url, "_blank");
        },
        departmentName(value){
        let department_name = null
          return department_name?.text ?? "N/A"
        },
        async getMasterDepartment() {
          let res = await this.fetchMasterDepartmentList()
          if (!res.status) {
            this.optionsDepartment = []
          } else {
            res.data.data.map(val => {
                this.optionsDepartment.push({
                    text:val.name,
                    value:val.id
                })
            })
          }
        }, 
        
        async fetchFleets() {
            let params = {
                page: 1,
                showAll: true,
            }
            let res = await this.getFleets(params)
            if (!res.status) {
                this.fleets = 'N/A'
            } else {
                let tempFleets = res.data.find(obj => Number(this.$route.query.vehicleId) === obj.id) 
                this.fleets = tempFleets.name
            }
        },

        async getListFinishedTableServiceAndRepairById(page){
            if(this.$route.query.vehicleId){
                this.loading_table = true
                let params = {
                    start_date: moment(this.dateRange?.[0]).format('YYYY-MM-DD'),
                    end_date: moment(this.dateRange?.[1]).format('YYYY-MM-DD'),
                    page: page || 1
                }
                
                let res = await this.fetchListTableFinishedServiceAndRepairById({id:this.$route.query.vehicleId, params})
                
                if (res.status === 'error') {
                    this.list_repair_finished = null
                    this.loading_table = false
                } else {
                    this.list_repair_finished.data = res.data.data
                    this.list_repair_finished.meta = res.data.meta
                    this.loading_table = false
                }
            }
        }, 
        async getDetailRepairServiceAndRepair(id, time){
            if(id){
                this.loading_detail = true

                const payload = {
                    params: {
                        accident_at: time
                    },
                    id
                }
               
                let res = await this.fetchDetailRepairServiceAndRepair(payload)
                if (res.status === 'error') {
                    this.detail_repair_finished = null
                    this.loading_detail = false
                } else {
                    let result = res?.data?.form_repair?.map(val => {
                        let entity_internal = []
                        let entity_subkon = []

                        if(val?.form_repair_entity?.length > 0){
                            val?.form_repair_entity?.map(val => {
                                if(val.entity_type === 'internal'){
                                    entity_internal.push({
                                        entity_type:val.entity_type,
                                        internal_name: val.internal_name,
                                        internal_position_id: val.internal_position_id,
                                    })
                                } else {
                                    entity_subkon.push({
                                        subkon_name:val.subkon_name,
                                        subkon_pic:val.subkon_pic,
                                        subkon_pic_phone:val.subkon_pic_phone,
                                        subkon_pic_email:val.subkon_pic_email,
                                        subkon_price_offering:val.subkon_price_offering,
                                        subkon_price_nego:val.subkon_price_nego,
                                        subkon_price_deal:val.subkon_price_deal,
                                        subkon_notes:val.subkon_notes,
                                        subkon_file:JSON.parse(val.subkon_file),
                                    })
                                }
                            })
                        }

                        val.entity_internal = entity_internal
                        val.entity_subkon = entity_subkon
                        return val
                    })   
                    
                    this.detail_repair_finished = {
                        ...res.data,
                        form_repair:result
                    }

                    console.log(this.detail_repair_finished, "DETAIL REPAIR FINISHED")

                    this.detail_repair_finished.form_repair = this.detail_repair_finished.form_repair.map(detail => {
                        if(detail.form_repair_activity.length > 0){
                            detail.form_repair_activity = detail.form_repair_activity.map(val => {
                                let file = JSON.parse(val.file)

                                if(file.length > 0){
                                    file = file.map(obj => {
                                    const fileExtension = obj.url.split('.').pop().toLowerCase();
                                    obj.extension = fileExtension

                                    return obj
                                })
                                }

                                val.parsed_file = file
                                return val
                            })
                        }

                        return detail
                    })
                }
              
                this.loading_detail = false
            }
        },
    }

}
</script>