<template>
  <b-container fluid>
    <b-row>
      <b-col lg="4">
        <iq-card body-class="iq-card-body px-0 py-5">
          <template v-slot:body>
            <div class="docter-details-block">
              <div class="docter-profile text-center">
                <b-img :src="profile.userDetail.photo | checkPhoto(require('@src/assets/images/user/01.jpg'))" :alt="profile.userDetail.name" class="avatar-130 img-fluid" />
              </div>
              <div class="text-center mt-3 pl-3 pr-3">
                <h4><b>{{ profile.userDetail.name }}</b></h4>
                <p>{{ profile.role.roles }}</p>
                <p class="mb-0">{{ profile.company.location }} - {{ profile.company.company }}</p>
                <p class="mb-0"><b-badge :variant="profile.active ? 'success' : 'warning'" class="ml-2">{{ profile.active ? 'Active' : 'Not Active' }}</b-badge> </p>
              </div>
            </div>
          </template>
        </iq-card>
        <iq-card body-class="iq-card-body">
          <template v-slot:body>
            <div class="iq-card-header d-flex justify-content-between">
              <div class="iq-header-title">
                <h4 class="card-title">Personal Information</h4>
              </div>
            </div>
            <div class="iq-card-body">
              <div class="about-info m-0 p-0">
                <div class="row">
                  <div class="col-4">Birthday:</div>
                  <div class="col-8">{{ profile.userDetail.birthday | parseDate('DD MMMM YYYY') }}</div>
                  <div class="col-4">Age:</div>
                  <div class="col-8">{{ profile.userDetail.age }}</div>
                  <div class="col-4">Email:</div>
                  <div class="col-8"><a :href="`mailto:${profile.email}`"> {{ profile.email }} </a></div>
                  <div class="col-4">Phone:</div>
                  <div class="col-8"><a :ref="`tel:${profile.userDetail.phone}`"> {{ profile.userDetail.phone ? profile.userDetail.phone : '-' }}</a></div>
                  <div class="col-4">Address:</div>
                  <div class="col-8">{{ profile.userDetail.address ? profile.userDetail.address : '-' }}</div>
                  <div class="col-4 mt-3">
                    <router-link to="/me/update-profile">
                      <b-button block variant="primary" class="mb-3 mr-1"><i class="ri-edit-2-line"></i>Edit</b-button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="8">
        <b-row>
          <b-col md="6">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Log History</h4>
              </template>
              <template v-slot:body>
                <ul class="iq-timeline" v-if="profile.userLog.length > 0">
                  <li v-for="(log, i)  in profile.userLog" :key="`log-${i}`">
                    <div class="timeline-dots border-secondary"></div>
                    <h6 class="">{{ log.activity }}</h6>
                    <small class="mt-1">{{ log.date }}</small>
                  </li>
                </ul>
                <p v-else>No log history found.</p>
              </template>
            </iq-card>
          </b-col>
          <b-col md="6">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Notifications</h4>
              </template>
              <template v-slot:body>
                <div v-if="loading_notifications" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <ul class="iq-timeline" v-if="notifications.length > 0">
                    <li v-for="(notif, i) in notifications" :key="`notif-${i}`">
                      <div class="timeline-dots"
                        :class="notif.hasRead ? 'border-secondary' : 'border-warning'"></div>
                      <h6 class="">{{ notif.title }}</h6>
                      <small class="mt-1">{{ notif.createAt }}</small>
                    </li>
                  </ul>
                  <p v-else>There is no notification found.</p>
                </template>
              </template>
            </iq-card>
          </b-col>
          <b-col md="12">
            <iq-card body-class="iq-card-body">
              <template v-slot:body>
                <div class="iq-card-header d-flex justify-content-between">
                  <div class="iq-header-title">
                    <h4 class="card-title">Fleet Responsible</h4>
                  </div>
                </div>
                <div class="iq-card-body">
                  <b-row v-if="profile.vehicle.length > 0">
                    <b-col md="4" class="text-center" v-for="(fleet, i) in profile.vehicle" :key="`fleet-${i}`">
                      <label>
                        <img :src="fleet.vehicleType.icon.url | checkPhoto(require('@src/assets/images/Km3FO09.png'))" :alt="fleet.name" class="img-fluid">
                      </label>
                      <small><strong>{{ fleet.name }}</strong></small>
                    </b-col>
                  </b-row>
                  <p v-else>No fleet found.</p>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { notificationsActions } from '@src/Utils/helper'

export default {
  name: 'MyProfile',
  mounted () {
    this.fetchNotifications()
  },
  data () {
    return {
      notifications: [],
      notification_pagination: {},
      loading_notifications: false,
    }
  },
  computed: {
    ...mapGetters("Auth", ["profile"]),
  },
  methods: {
    ...notificationsActions,
    async fetchNotifications () {
      this.loading_notifications = true

      const params = { page: 1 }

      const res = await this.getNotifications(params)

      if (res.status == 'success') {
        const { data, pagination } = res
        this.notifications = data
        this.notification_pagination = pagination
      } else {
        this.notifications = []
        this.notification_pagination = {}
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }

      this.loading_notifications = false
    }
  }
}
</script>
