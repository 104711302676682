<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-input v-model="keyword" class="mb-3" placeholder="Search..."></b-input>
      </b-col>
      <b-col md="12" class="mt-4 m-auto">
        <iq-card
          class-name="iq-card-block iq-card-stretch iq-card-height"
          body-class=""
        >
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>Recommendations</strong>
            </h4>
          </template>
          <template v-slot:headerAction>
            <a
              class="text-primary float-right"
              v-b-toggle.collapse-recommendations
              role="button"
              style="cursor: pointer"
            >
              <i class="fa fa-filter" />
            </a>
          </template>
          <template v-slot:body>
            <b-collapse id="collapse-recommendations" class="mb-2" visible>
              <table class="table table-bordered">
                <thead>
                <tr class="bg-warning">
                  <th class="">Vehicle</th>
                  <th class="">Problem</th>
                  <th class="">Root Cause</th>
                  <th class="">Vessel Case</th>
                  <th class="">Recommendation</th>
                  <th class="">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="recommendations.length === 0">
                  <td colspan="6">No Data or Search not found</td>
                </tr>
                <template
                  v-for="(list, index) in recommendations"
                >
                  <tr :key="index" v-if="list.data.length > 0">
                    <td :rowspan="Number(list.data.length)+1">
                      {{ list.vehicle ? list.vehicle.name : '-' }} {{(list.data.length)}}
                    </td>
                  </tr>
                  <tr :key="index" v-else>
                    <td :rowspan="Number(list.data.length)+1">
                      {{ list.vehicle ? list.vehicle.name : '-' }} {{(list.data.length)}}
                    </td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                  <tr
                    v-for="(listData, indexData) in list.data"
                  >
                    <td>
                      {{listData.problem}}
                    </td>
                    <td>
                      {{listData.root_cause}}
                    </td>
                    <td>
                      {{listData.vessel_case}}
                    </td>
                    <td>
                      {{listData.recommendation}}
                    </td>
                    <td>
                      {{listData.status}}
                      <p v-if="showActionButtons">
                        <b-button @click="onCreateEvaluation(listData.form_id)" variant="dark" size="sm">
                          Add More Evaluation
                        </b-button>
                      </p>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </b-collapse>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { numberFormat } from '@src/plugins/helpers'

export default {
  name: 'SummaryManagementRecommendations',
  props: {
    lists: {
      type: Array,
      default: () => {
        return []
      }
    },
    showActionButtons: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
  },
  computed: {
    recommendations () {
      if (!this.lists) {
        return []
      }

      return this.lists.filter(form => {
        if (!this.keyword) {
          return true
        }

        return (form.vehicle && form.vehicle.name.toString().toLowerCase().includes(this.keyword.toLowerCase()))
      })
    }
  },
  data () {
    return {
      keyword: ''
    }
  },
  methods: {
    numberFormat (number) {
      return numberFormat(number)
    },
    onCreateEvaluation (id) {
      this.$router.push({
        name: 'mi.add-form-evaluation',
        query: {
          formId: id
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
