var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "text-center mb-3",
    attrs: {
      "cols": "12"
    }
  }, [_vm._l(_vm.typeFormInsurance, function (item, index) {
    return [_c('b-form-radio', {
      key: index,
      staticClass: "custom-radio-color-checked",
      attrs: {
        "inline": "",
        "color": item.color,
        "name": "color",
        "value": item.value,
        "disabled": _vm.readonly
      },
      model: {
        value: _vm.incident.type_form,
        callback: function callback($$v) {
          _vm.$set(_vm.incident, "type_form", $$v);
        },
        expression: "incident.type_form"
      }
    }, [_c('strong', [_c('h5', [_vm._v(_vm._s(item.label))])])])];
  })], 2), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "card-title text-success"
  }, [_c('b', [_vm._v("INFORMATION OF ACCIDENT/INCIDENT")])]), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-sm": "3",
      "label": "Company *",
      "label-for": "company"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "lg",
      "disabled": _vm.readonly
    },
    model: {
      value: _vm.incident.company_id,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "company_id", $$v);
      },
      expression: "incident.company_id"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": null
    }
  }, [_vm._v("Select Company")]), _vm._l(_vm.companies, function (company) {
    return _c('b-form-select-option', {
      key: company.value,
      attrs: {
        "value": company.value
      }
    }, [_vm._v(" " + _vm._s(company.label) + " ")]);
  })], 2)], 1), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-sm": "3",
      "label": "Fleet Name *",
      "label-for": "vehicles"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "lg",
      "disabled": _vm.readonly
    },
    model: {
      value: _vm.incident.vehicle_id,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "vehicle_id", $$v);
      },
      expression: "incident.vehicle_id"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": null
    }
  }, [_vm._v("Select Fleets")]), _vm._l(_vm.vehicles, function (vehicle) {
    return _c('b-form-select-option', {
      key: vehicle.value,
      attrs: {
        "value": vehicle.value
      }
    }, [_vm._v(" " + _vm._s(vehicle.label) + " ")]);
  })], 2)], 1), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-sm": "3",
      "label": "Date of Loss (DOL) *",
      "label-for": "dol"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "disabled": _vm.readonly
    },
    model: {
      value: _vm.incident.date_of_loss,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "date_of_loss", $$v);
      },
      expression: "incident.date_of_loss"
    }
  })], 1), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-sm": "3",
      "label": "Place of Loss (POL) *",
      "label-for": "pol"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "disabled": _vm.readonly
    },
    model: {
      value: _vm.incident.place_of_loss,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "place_of_loss", $$v);
      },
      expression: "incident.place_of_loss"
    }
  })], 1), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-sm": "3",
      "label": "Type of Coverage *",
      "label-for": "coverage"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "lg",
      "disabled": _vm.readonly
    },
    model: {
      value: _vm.incident.type_coverage_id,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "type_coverage_id", $$v);
      },
      expression: "incident.type_coverage_id"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": null
    }
  }, [_vm._v("Select of Coverage")]), _vm._l(_vm.coverages, function (coverage) {
    return _c('b-form-select-option', {
      key: coverage.value,
      attrs: {
        "value": coverage.value
      }
    }, [_vm._v(" " + _vm._s(coverage.label) + " ")]);
  })], 2)], 1), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-sm": "3",
      "label": "Type of Loss *",
      "label-for": "loss"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "lg",
      "disabled": _vm.readonly
    },
    model: {
      value: _vm.incident.type_loss_id,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "type_loss_id", $$v);
      },
      expression: "incident.type_loss_id"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": null
    }
  }, [_vm._v("Select of Loss")]), _vm._l(_vm.losses, function (loss) {
    return _c('b-form-select-option', {
      key: loss.value,
      attrs: {
        "value": loss.value
      }
    }, [_vm._v(" " + _vm._s(loss.label) + " ")]);
  })], 2)], 1)], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "card-title text-success"
  }, [_c('b', [_vm._v("CLAIMANT")])]), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-sm": "3",
      "label": "Company *",
      "label-for": "company"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "lg",
      "disabled": _vm.readonly
    },
    model: {
      value: _vm.incident.company_claimant_id,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "company_claimant_id", $$v);
      },
      expression: "incident.company_claimant_id"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": null
    }
  }, [_vm._v("Select Company")]), _vm._l(_vm.companies, function (company) {
    return _c('b-form-select-option', {
      key: company.value,
      attrs: {
        "value": company.value
      }
    }, [_vm._v(" " + _vm._s(company.label) + " ")]);
  })], 2)], 1), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-sm": "3",
      "label": "Name *",
      "label-for": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "disabled": _vm.readonly
    },
    model: {
      value: _vm.incident.name,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "name", $$v);
      },
      expression: "incident.name"
    }
  })], 1), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-sm": "3",
      "label": "Position *",
      "label-for": "position"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "disabled": _vm.readonly
    },
    model: {
      value: _vm.incident.position,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "position", $$v);
      },
      expression: "incident.position"
    }
  })], 1), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-sm": "3",
      "label": "Department of FLeet *",
      "label-for": "departments"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "lg",
      "disabled": _vm.readonly
    },
    model: {
      value: _vm.incident.department_id,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "department_id", $$v);
      },
      expression: "incident.department_id"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": null
    }
  }, [_vm._v("Select Department")]), _vm._l(_vm.departments, function (department) {
    return _c('b-form-select-option', {
      key: department.value,
      attrs: {
        "value": department.value
      }
    }, [_vm._v(" " + _vm._s(department.label) + " ")]);
  })], 2)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "card-title text-success"
  }, [_c('b', [_vm._v("DETAILS OF CHRONOLOGY")])]), _c('b-form-group', {
    attrs: {
      "label": "Decribe of Detail Chronology"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "exampleFormControlTextarea1",
      "rows": "5",
      "disabled": _vm.readonly
    },
    model: {
      value: _vm.incident.details_of_chronology,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "details_of_chronology", $$v);
      },
      expression: "incident.details_of_chronology"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "card-title text-success"
  }, [_c('b', [_vm._v("ESTIMATE DAMAGE OR LOSS")])]), _c('b-form-group', {
    attrs: {
      "label": "Decribe of Estimate Damage or Loss"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "exampleFormControlTextarea1",
      "rows": "5",
      "disabled": _vm.readonly
    },
    model: {
      value: _vm.incident.estimate_damage,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "estimate_damage", $$v);
      },
      expression: "incident.estimate_damage"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6",
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "card-title text-success"
  }, [_c('b', [_vm._v("CONCLUSION")])]), _c('b-form-group', {
    attrs: {
      "label": "Decribe of Conclusion"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "exampleFormControlTextarea1",
      "rows": "3"
    },
    model: {
      value: _vm.incident.conclusion,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "conclusion", $$v);
      },
      expression: "incident.conclusion"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6",
      "md": "6"
    }
  }, [_c('h5', {
    staticClass: "card-title text-success"
  }, [_c('b', [_vm._v("COST")])]), _c('b-form-group', {
    attrs: {
      "label": "Estimate your cost"
    }
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-append"
  }, [_c('span', {
    staticClass: "input-group-text"
  }, [_vm._v("Rp")])]), _c('b-form-input', {
    attrs: {
      "type": "number",
      "disabled": _vm.readonly
    },
    model: {
      value: _vm.incident.cost,
      callback: function callback($$v) {
        _vm.$set(_vm.incident, "cost", $$v);
      },
      expression: "incident.cost"
    }
  })], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }