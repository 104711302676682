<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Add Stock Opname</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="text-right mb-5">
                <span class="mr-2"><b-button variant="primary"><i class="fa fa-save"></i> Save </b-button></span>
                <span><b-button variant="danger"><i class="fa fa-times"></i> Cancel </b-button></span>
              </b-col>
            </b-row>
            <b-row class="mb-3 p-4 bg-transparent iq-bg-primary">
              <b-col md="1"></b-col>
              <b-col md="5">
                <b-form-group class="row" label-cols-sm="3" label="Name *" label-for="dol">
                  <b-form-input type="text"></b-form-input>
                </b-form-group>
                <b-form-group class="row" label-cols-sm="3" label="Fleet *" label-for="dol">
                  <v-multiselect v-model="fleetSelected" tag-placeholder="Category" placeholder="Search or add a fleet" label="name" track-by="code" :options="fleetEquipments">
                  </v-multiselect>
                </b-form-group>
                <b-form-group class="row" label-cols-sm="3" label="Status *" label-for="dol">
                  <v-select label="text" v-model="statusSelected" :options="statusGoods" :reduce="(type) => type.value" placeholder="select category"></v-select>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group class="row" label-cols-sm="3" label="Date *" label-for="dol">
                  <date-picker v-model="historiesParamsDate" type="date" placeholder="Select Date" format="DD-MM-YYYY"></date-picker>
                </b-form-group>
                <b-form-group class="row" label-cols-sm="3" label="Notes" label-for="dol">
                  <b-form-textarea id="description" rows="2"></b-form-textarea>
                  <small style="float: left"><i class="fa fa-warning"></i> Max 500 Character</small>
                </b-form-group>
              </b-col>
              <b-col md="1"></b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="mt-3">
                <hr/>
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th width="3%">No</th>
                    <th width="20%">Goods</th>
                    <th width="25%">Code of Goods</th>
                    <th>Stock in System</th>
                    <th>Physical Stock Different</th>
                  </tr>
                  </thead>
                  <tr>
                    <td></td>
                    <td>
                      <v-select label="text" v-model="goodsSelected" :options="categoryGoods" :reduce="(type) => type.value" placeholder="select category"></v-select>
                    </td>
                    <td>
                      <b-form-input type="text" placeholder="search..."></b-form-input>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Pyston</td>
                    <td>PS-092</td>
                    <td>20</td>
                    <td><b-form-input type="number" placeholder="Stock Different"></b-form-input></td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { fleetsActions } from '@src/Utils/helper'

export default {
  name: 'AddStockOpname',
  data () {
    return {
      fleetEquipments: [],
      fleetSelected: [],
      fleetParams: {
        page: 1,
        showAll: true,
        active: true,
        search: ''
      },
      historiesParamsDate: [],
      statusSelected: [],
      typeStockSelected: [
        { value: 'stock-in', text: 'Stock In' },
        { value: 'stock-out', text: 'Stock Out' }
      ],
      category: [
        { value: 'stock-in', text: 'Stock In' },
        { value: 'stock-out', text: 'Stock Out' }
      ],
      statusGoods: [
        { value: 'all', text: 'All Status' },
        { value: 'available', text: 'Available Stock' },
        { value: 'towards-runs-out', text: 'Stock Runs Out Soon' },
        { value: 'out-of-stock', text: 'Out of Stock' }
      ]
    }
  },
  async mounted () {
    xray.index()
    await this.fetchFleets()
  },
  methods: {
    ...fleetsActions,
    async fetchFleets () {
      this.fleetsLoading = true
      this.fleets = []

      let params = this.$options.filters.cleanObject(this.fleetParams)
      let res = await this.getFleets(params)

      if (res.status) {
        this.fleetsLoading = false
        if (res.data && res.data.length > 0) {
          res.data.forEach(el => {
            el.componentSetup = []
            this.fleets.push(el)
            this.fleetEquipments.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
  }
}
</script>
