<template>
  <b-container fluid v-if="dockingDetail && fleetDetail">
    <form @submit.prevent="submit">
      <b-row>
        <b-col lg="4">
          <iq-card class-name="iq-user-profile-block1">
            <template v-slot:body>
              <div class="user-details-block">
                <div class="user-profile text-center">
                  <img src="../../assets/images/ship.png" alt="profile-img" class="avatar-130 img-fluid">
                </div>
                <div class="text-center mt-3">
                  <h4><b>{{ fleetDetail.name }}</b></h4>
                  <b-badge variant="info">{{ fleetDetail.company.company }}</b-badge>
                </div>
              </div>
              <table class="table table-borderless mt-3">
                <tbody>
                <tr class="bg-primary">
                  <td colspan="2" class="text-center"><strong>SHIP PARTICULAR</strong></td>
                </tr>
                <tr>
                  <td><strong>Type Kapal</strong></td>
                  <td>{{ fleetDetail.vehicleType.name }}</td>
                </tr>
                <tr>
                  <td><strong>Bendera</strong></td>
                  <td>{{ fleetDetail.flag }}</td>
                </tr>
                <tr>
                  <td><strong>Klasifikasi</strong></td>
                  <td>{{ fleetDetail.classification }}</td>
                </tr>
                <tr>
                  <td><strong>Tahun Pembuatan</strong></td>
                  <td>-</td>
                </tr>
                <tr>
                  <td><strong>Tempat Pembangunan</strong></td>
                  <td>{{ fleetDetail.yearBuildCountry }}</td>
                </tr>
                <tr>
                  <td><strong>Tempat Pendaftaran</strong></td>
                  <td>-</td>
                </tr>
                <tr>
                  <td><strong>Ukuran Kapal</strong></td>
                  <td>{{ fleetDetail.dimension.width.meter }} x {{ fleetDetail.dimension.length.meter }} x {{ fleetDetail.dimension.depth.meter }} meter</td>
                </tr>
                <tr>
                  <td><strong>Main Engine</strong></td>
                  <td>
                    <ul class="text-left">
                      <li v-for="power in fleetDetail.powers">{{power.powerType ? power.powerType.name : '-'}} - {{power.powerSeries ? power.powerSeries.name : '-'}} {{power.powerSeries ? power.powerSeries.model : '-'}} <span class="pull-right">Actual RH : <b-badge variant="info"><strong>{{power.actualRunningHours}} RH</strong></b-badge></span></li>
                    </ul>
                  </td>
                </tr>
                </tbody>
              </table>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="8">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">FINAL SEA TRIAL RECORD</h4>
            </template>
            <template v-slot:body>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt;">PRINCIPAL DIMENSION</legend>
                <b-row>
                  <b-form-group class="col-md-4" label="L.O.A" label-for="loa">
                    <div class="input-group">
                      <input type="number" v-model="form.loa" required class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="L.W.L" label-for="lwl">
                    <div class="input-group">
                      <input type="number" class="form-control" v-model="form.lwl" required>
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="L.B.P" label-for="lbp">
                    <div class="input-group">
                      <input type="number" class="form-control" v-model="form.lbp" required>
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-form-group class="col-md-4" label="B mld" label-for="bmld">
                    <div class="input-group">
                      <input type="number" class="form-control" v-model="form.bmld" required>
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="D mld" label-for="dmld">
                    <div class="input-group">
                      <input type="number" class="form-control" v-model="form.dmld" required>
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="T" label-for="t">
                    <div class="input-group">
                      <input type="number" class="form-control" v-model="form.t" required>
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary">mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                </b-row>
                <b-row>
                  <b-form-group class="col-md-4" label="Main Engine" label-for="main_engine">
                    <v-select label="text" :options="fleetDetail.powers"
                              :get-option-label='type => type.powerType.name'
                              :reduce="(type) => type.id" v-model="form.mainEngine"></v-select>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Generator" label-for="generator">
                    <v-select label="text" :options="fleetDetail.powers"
                              :get-option-label='type => type.powerType.name'
                              :reduce="(type) => type.id" v-model="form.generator"></v-select>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Gearbox Ration" label-for="gearbox_ratio">
                    <b-form-input v-model="form.gearboxRotation" required></b-form-input>
                  </b-form-group>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt;">FULL LOAD RECORD</legend>
                <b-row>
                  <b-form-group class="col-md-4" label="Tanggal Percobaan" label-for="tanggal_percobaan">
                    <date-picker required v-model="form.trialDate" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Tempat Percobaan" label-for="tempat_percobaan">
                    <b-form-input v-model="form.trialPlace" required></b-form-input>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Kedalaman" label-for="kedalaman">
                    <div class="input-group">
                      <input type="number" v-model="form.depth" class="form-control" id="kedalaman">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary" >mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Jarak Pengukuran" label-for="jarak_pengukuran">
                    <input type="number" v-model="form.measurementDistance" class="form-control">
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Arah Angin" label-for="arah_angin">
                    <input type="number" v-model="form.windDirection" class="form-control">
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Cuaca" label-for="cuaca">
                    <div class="input-group">
                      <input type="number" v-model="form.weather" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary" >°C</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Sarat Depan" label-for="sarat_depan">
                    <div class="input-group">
                      <input type="number" v-model="form.saratDepan" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary" >mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Perbedaan Trim" label-for="perbedaan_trim">
                    <div class="input-group">
                      <input type="number" v-model="form.trimDifference" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary" >mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Sarat Belakang" label-for="sarat_belakang">
                    <div class="input-group">
                      <input type="number" v-model="form.saratBelakang" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary" >mtr</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Tempratur Udara" label-for="tempratur_udara">
                    <div class="input-group">
                      <input type="number" v-model="form.airTemperature" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary" >°C</span>
                      </div>
                    </div>
                  </b-form-group>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt;">MUATAN DAN TANGKI</legend>
                <b-row>
                  <b-form-group class="col-md-12" label="Muatan" label-for="muatan">
                    <input v-model="form.load" class="form-control">
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Jumlah Orang Atas Kapal" label-for="jml_crew">
                    <div class="input-group">
                      <input v-model="form.numberOfPeople" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary" >Orang</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Tangki Induk BBM" label-for="tangkiIndukBBM">
                    <div class="input-group">
                      <input v-model="form.mainGasTank" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary" >Liter</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Tangki Induk Harian" label-for="tangkiIndukHarian">
                    <div class="input-group">
                      <input v-model="form.dailyGasTank" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary" >Liter</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Tangki Induk Oli" label-for="tangkiIndukOli">
                    <div class="input-group">
                      <input v-model="form.mainOilTank" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary" >Liter</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Tangki Harian Oli" label-for="tangkiHarianOli">
                    <div class="input-group">
                      <input v-model="form.dailyOilTank" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary" >Liter</span>
                      </div>
                    </div>
                  </b-form-group>
                  <b-form-group class="col-md-4" label="Tangki Air Tawar" label-for="TangkiAirTawar">
                    <div class="input-group">
                      <input v-model="form.waterTank" class="form-control">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-primary" >Liter</span>
                      </div>
                    </div>
                  </b-form-group>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt;">MAIN ENGINE RECORD</legend>
                <fieldset class="form-group border p-3 mt-1">
                  <legend class="w-auto text-primary" style="font-size: 12pt;"><strong>ME - Starboard Side</strong></legend>
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead class="text-primary">
                      <tr>
                        <th class="text-center align-middle" rowspan="2">&nbsp;NO&nbsp;</th>
                        <th class="text-center align-middle" rowspan="2">Load&nbsp;(Percent)</th>
                        <th class="text-center align-middle" rowspan="2">Time&nbsp;(Menit)</th>
                        <th class="text-center align-middle" rowspan="2">Speed&nbsp;(Knots)</th>
                        <th class="text-center align-middle" colspan="5">MAIN ENGINE</th>
                        <th class="text-center align-middle" colspan="2">GEARBOX</th>
                        <th class="text-center align-middle" rowspan="2">Temp.&nbsp;Cover Crankcase&nbsp;(°C)</th>
                        <th class="text-center align-middle" rowspan="2">Interm.&nbsp;Shaft Propeller&nbsp;(°C)</th>
                        <th class="text-center align-middle" rowspan="2">Shaft&nbsp;Propeller&nbsp;(°C)</th>
                      </tr>
                      <tr>
                        <th class="text-center align-middle">Engine&nbsp;(RPM)</th>
                        <th class="text-center align-middle">Exhaust&nbsp;Temperatur&nbsp;(°C)</th>
                        <th class="text-center align-middle">FW&nbsp;Temp&nbsp;(°C)</th>
                        <th class="text-center align-middle">LO&nbsp;Temp&nbsp;(°C)</th>
                        <th class="text-center align-middle">LO&nbsp;Press&nbsp;(kg/cm2)</th>
                        <th class="text-center align-middle">LO&nbsp;Press&nbsp;(Psi)</th>
                        <th class="text-center align-middle">LO&nbsp;Temp&nbsp;(°C)</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(data, index) in form.meStarboard">
                        <td>
                          <b>
                            # {{index+1}}
                          </b>
                          <br>
                          <a href="#" @click.prevent="onDeleteMeStarboard(index)" class="text-danger">Remove</a>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.load" id="load" name="load"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.time" id="time" name="time"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.speed" id="speed" name="speed"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.engine" id="engine_rpm" name="engine_rpm"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.exhaustTemperatur" id="exhaust_temp" name="exhaust_temp"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.fwTemp" id="fw_temp" name="fw_temp"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.loTempEngine" id="lo_temp_me" name="lo_temp_me"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.loPressEngine" id="lo_press_me" name="lo_press_me"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.loPressGearbox" id="lo_press_gb" name="lo_press_gb"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.loTempGearbox" id="lo_temp_gb" name="lo_temp_gb"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.tempCoverCrankcase" id="temp_cover" name="temp_cover"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.intermShaftPropeller" id="interm_shaft_propeller" name="interm_shaft_propeller"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.shaftPropeller" id="shaft_propeller" name="shaft_propeller"></b-form-input>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <b-button variant="success" @click="onAddMeStarboard()" class="mt-1"><i class="fa fa-plus"></i> ADD FORM</b-button>
                </fieldset>
                <hr>
                <fieldset class="form-group border p-3 mt-1">
                  <legend class="w-auto text-primary" style="font-size: 12pt;"><strong>ME - Port Side</strong></legend>
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead class="text-primary">
                      <tr>
                        <th class="text-center align-middle" rowspan="2">&nbsp;NO&nbsp;</th>
                        <th class="text-center align-middle" rowspan="2">Load&nbsp;(Percent)</th>
                        <th class="text-center align-middle" rowspan="2">Time&nbsp;(Menit)</th>
                        <th class="text-center align-middle" rowspan="2">Speed&nbsp;(Knots)</th>
                        <th class="text-center align-middle" colspan="5">MAIN ENGINE</th>
                        <th class="text-center align-middle" colspan="2">GEARBOX</th>
                        <th class="text-center align-middle" rowspan="2">Temp.&nbsp;Cover Crankcase&nbsp;(°C)</th>
                        <th class="text-center align-middle" rowspan="2">Interm.&nbsp;Shaft Propeller&nbsp;(°C)</th>
                        <th class="text-center align-middle" rowspan="2">Shaft&nbsp;Propeller&nbsp;(°C)</th>
                      </tr>
                      <tr>
                        <th class="text-center align-middle">Engine&nbsp;(RPM)</th>
                        <th class="text-center align-middle">Exhaust&nbsp;Temperatur&nbsp;(°C)</th>
                        <th class="text-center align-middle">FW&nbsp;Temp&nbsp;(°C)</th>
                        <th class="text-center align-middle">LO&nbsp;Temp&nbsp;(°C)</th>
                        <th class="text-center align-middle">LO&nbsp;Press&nbsp;(kg/cm2)</th>
                        <th class="text-center align-middle">LO&nbsp;Press&nbsp;(Psi)</th>
                        <th class="text-center align-middle">LO&nbsp;Temp&nbsp;(°C)</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(data, index) in form.mePort">
                        <td>
                          <b>
                            # {{index+1}}
                          </b>
                          <br>
                          <a href="#" @click.prevent="onDeleteMePort(index)" class="text-danger">Remove</a>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.load" id="load" name="load"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.time" id="time" name="time"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.speed" id="speed" name="speed"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.engine" id="engine_rpm" name="engine_rpm"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.exhaustTemperatur" id="exhaust_temp" name="exhaust_temp"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.fwTemp" id="fw_temp" name="fw_temp"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.loTempEngine" id="lo_temp_me" name="lo_temp_me"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.loPressEngine" id="lo_press_me" name="lo_press_me"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.loPressGearbox" id="lo_press_gb" name="lo_press_gb"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.loTempGearbox" id="lo_temp_gb" name="lo_temp_gb"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.tempCoverCrankcase" id="temp_cover" name="temp_cover"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.intermShaftPropeller" id="interm_shaft_propeller" name="interm_shaft_propeller"></b-form-input>
                        </td>
                        <td>
                          <b-form-input type="number" v-model="data.shaftPropeller" id="shaft_propeller" name="shaft_propeller"></b-form-input>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <b-button variant="success" @click="onAddMePort()" class="mt-1"><i class="fa fa-plus"></i> ADD FORM</b-button>
                </fieldset>
                <b>Notes : </b>RPM bervariasi per kapal, bisa sampai 1600 RPM
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt;">AUXILIARY ENGINE RECORD</legend>
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead class="text-primary">
                    <tr>
                      <th class="text-center align-middle" rowspan="2">&nbsp;NO&nbsp;</th>
                      <th class="text-center align-middle" rowspan="2">Load&nbsp;(Percent)</th>
                      <th class="text-center align-middle" rowspan="2">Engine&nbsp;(RPM)</th>
                      <th class="text-center align-middle" colspan="6">ENGINE RECORD</th>
                      <th class="text-center align-middle" rowspan="2">Temp.&nbsp;Cover Crankcase&nbsp;(°C)</th>
                    </tr>
                    <tr>
                      <th class="text-center align-middle">LO&nbsp;Press&nbsp;(kg/cm2)</th>
                      <th class="text-center align-middle">SW&nbsp;Press&nbsp;(kg/cm2)</th>
                      <th class="text-center align-middle">LO&nbsp;Temp&nbsp;(°C)</th>
                      <th class="text-center align-middle">FW&nbsp;Temp&nbsp;(°C)</th>
                      <th class="text-center align-middle">Exhaust&nbsp;Temp&nbsp;(°C)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(data, index) in form.aeRecord">
                      <td>
                        <b># {{index+1}}</b>
                        <br>
                        <a href="#" @click.prevent="onDeleteAeRecord(index)" class="text-danger">Remove</a>
                      </td>
                      <td>
                        <b-form-input type="number" v-model="data.load" id="load" name="load"></b-form-input>
                      </td>
                      <td>
                        <b-form-input type="number" v-model="data.engine" id="engine_rpm" name="engine_rpm"></b-form-input>
                      </td>
                      <td>
                        <b-form-input type="number" v-model="data.loPress" id="lo_press_1" name="lo_press_1"></b-form-input>
                      </td>
                      <td>
                        <b-form-input type="number" v-model="data.swPress" id="sw_press" name="sw_press"></b-form-input>
                      </td>
                      <td>
                        <b-form-input type="number" v-model="data.loTemp" id="lo_temp" name="lo_temp"></b-form-input>
                      </td>
                      <td>
                        <b-form-input type="number" v-model="data.fwTemp" id="fw_temp" name="fw_temp"></b-form-input>
                      </td>
                      <td>
                        <b-form-input type="number" v-model="data.exhaustTemp" id="exhaust_temp" name="exhaust_temp"></b-form-input>
                      </td>
                      <td>
                        <b-form-input type="number" v-model="data.tempCoverCrankcase" id="temp_cover" name="temp_cover"></b-form-input>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <b-button variant="success" @click="onAddAeRecord()" class="mt-1"><i class="fa fa-plus"></i> ADD FORM</b-button>
              </fieldset>
              <hr>
              <b-row>
                <b-col md="12">
                  <b-form-group label="RECOMMENDATION" label-for="recomendation">
                    <wysiwyg v-model="form.recommendation"/>
                  </b-form-group>
                </b-col>
                <b-form-group class="col-md-4" label="Prepared By - OS" label-for="prepared_by">
                  <v-select v-model="form.preparedBy" label="text"
                            :options="users" :reduce="type => type.value"></v-select>
                </b-form-group>
                <b-form-group class="col-md-4" label="Acknowledge By - INCO Mechanic" label-for="acknowledge_by">
                  <v-select v-model="form.ackByInco" label="text"
                            :options="users" :reduce="type => type.value"></v-select>
                </b-form-group>
                <b-form-group class="col-md-4" label="Acknowledge By - SPT / Mechanic" label-for="acknowledge_by">
                  <v-select v-model="form.ackBySpt" label="text"
                            :options="users" :reduce="type => type.value"></v-select>
                </b-form-group>
                <b-form-group class="col-md-4" label="Acknowledge By - KKM TB. ETI 201" label-for="acknowledge_by">
                  <v-select v-model="form.ackByKkm" label="text"
                            :options="users" :reduce="type => type.value"></v-select>
                </b-form-group>
                <b-form-group class="col-md-4" label="Acknowledge By - Captain TB. ETI 201" label-for="acknowledge_by">
                  <v-select v-model="form.ackByCaptain" label="text"
                            :options="users" :reduce="type => type.value"></v-select>
                </b-form-group>
              </b-row>
              <b-button block variant="primary" type="submit" class="mt-3">Submit Data</b-button>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>

import { dockingActions, fleetsActions, usersActions } from '@src/Utils/helper'

export default {
  name: 'DockingSeaTrial',
  async mounted () {
    this.loading = true
    await this.fetchDockingDetail()
    await this.fetchUsers()
    await this.fetchFleetDetail()
    this.loading = false
  },
  data () {
    return {
      loading: true,
      users: [],
      fleetDetail: null,
      dockingDetail: null,
      form: {
        saving: false,
        loa: '',
        lwl: '',
        lbp: '',
        bmld: '',
        dmld: '',
        t: '',
        generator: '',
        mainEngine: '',
        gearboxRotation: '',
        trialDate: '',
        depth: '',
        trialPlace: '',
        measurementDistance: '',
        windDirection: '',
        trimDifference: '',
        weather: '',
        airTemperature: '',
        saratDepan: '',
        saratBelakang: '',
        load: '',
        numberOfPeople: '',
        mainGasTank: '',
        dailyGasTank: '',
        mainOilTank: '',
        dailyOilTank: '',
        waterTank: '',
        preparedBy: '',
        ackByInco: '',
        ackBySpt: '',
        ackByKkm: '',
        ackByCaptain: '',
        recommendation: '',
        mePort: [],
        meStarboard: [],
        aeRecord: []
      }
    }
  },
  computed: {
    dockingId () {
      return this.$route.query.dockingId
    }
  },
  methods: {
    ...dockingActions,
    ...usersActions,
    ...fleetsActions,
    onResetForm () {
      this.form = {
        saving: false,
        loa: '',
        lwl: '',
        lbp: '',
        bmld: '',
        dmld: '',
        t: '',
        generator: '',
        mainEngine: '',
        gearboxRotation: '',
        trialDate: '',
        depth: '',
        trialPlace: '',
        measurementDistance: '',
        windDirection: '',
        trimDifference: '',
        weather: '',
        airTemperature: '',
        saratDepan: '',
        saratBelakang: '',
        load: '',
        numberOfPeople: '',
        mainGasTank: '',
        dailyGasTank: '',
        mainOilTank: '',
        dailyOilTank: '',
        waterTank: '',
        preparedBy: '',
        ackByInco: '',
        ackBySpt: '',
        ackByKkm: '',
        ackByCaptain: '',
        recommendation: '',
        mePort: [],
        meStarboard: [],
        aeRecord: []
      }
    },
    async fetchUsers () {
      this.users = []
      let params = {
        active: true,
        page: 1,
        perPage: 9999
      }
      const res = await this.getUsers(params)

      if (res.status === 'success' && res.data) {
        let rawData = res.data.data
        this.users = rawData.map((val) => ({
          value: val.id, text: val.userDetail.name
        }))
      }
    },
    async fetchDockingDetail () {
      if (this.dockingId) {
        const { data, status } = await this.getDockingDetail({
          dockingId: this.dockingId
        })

        if ([500, 404, 403].includes(status)) {
          this.$swal(
            `Oops!`,
            'Ada Yang Salah',
            'error'
          )
          return
        }

        if (data.data) {
          this.dockingDetail = data.data
        }
      }
    },
    async fetchFleetDetail () {
      const res = await this.getFleetById(this.dockingDetail.vehicle.id)

      if (res.status === 'success') {
        this.fleetDetail = res.data
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    onAddMePort () {
      this.form.mePort.push({
        load: '',
        engine: '',
        time: '',
        speed: '',
        exhaustTemperatur: '',
        fwTemp: '',
        loTempEngine: '',
        loPressEngine: '',
        loPressGearbox: '',
        tempCoverCrankcase: '',
        loTempGearbox: '',
        intermShaftPropeller: '',
        shaftPropeller: ''
      })
    },
    onAddMeStarboard () {
      this.form.meStarboard.push({
        load: '',
        engine: '',
        time: '',
        speed: '',
        exhaustTemperatur: '',
        fwTemp: '',
        loTempEngine: '',
        loPressEngine: '',
        loPressGearbox: '',
        tempCoverCrankcase: '',
        loTempGearbox: '',
        intermShaftPropeller: '',
        shaftPropeller: ''
      })
    },
    onAddAeRecord () {
      this.form.aeRecord.push({
        load: '',
        engine: '',
        loPress: '',
        swPress: '',
        loTemp: '',
        fwTemp: '',
        exhaustTemp: '',
        tempCoverCrankcase: ''
      })
    },
    onDeleteMePort (index) {
      this.form.mePort.splice(index, 1)
    },
    onDeleteMeStarboard (index) {
      this.form.meStarboard.splice(index, 1)
    },
    onDeleteAeRecord (index) {
      this.form.aeRecord.splice(index, 1)
    },
    async submit () {
      this.form.saving = true
      const payload = { ...this.form, dockingId: this.dockingId }

      const { status } = await this.createDockingSeaTrial(payload)

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        return
      }

      if ([500, 404, 403, 400].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      this.$router.push('/docking/dashboard')
    }
  }
}
</script>
