<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"
              ><strong>EVELUASI KONDUK / <i>CONDUCT EVALUATION</i></strong></h4
            >
          </template>
          <template v-slot:body>
            <fieldset class="form-group border p-3">
              <legend class="w-auto px-2 text-primary">Crew Information</legend>
              <b-row>
                <b-col md="10">
                  <table class="table table-striped mt-2">
                    <tbody>
                      <tr>
                        <th width="20%">Nama Crew</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">Nurul Akbar</td>
                        <th width="20%">No KTP</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">3174050507930002</td>
                      </tr>
                      <tr>
                        <th width="20%">Tempat/Tanggal Lahir</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">Jakarta / 05 Juli 1993</td>
                        <th width="20%">No NPWP</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">4151 2010 0036</td>
                      </tr>
                      <tr>
                        <th width="20%">Jabatan</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">Master</td>
                        <th width="20%">ID Crew</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">TCP202138001</td>
                      </tr>
                      <tr>
                        <th width="20%">Perusahaan</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">Transcoal Pacific</td>
                        <th width="20%">Seaferer Code</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">33273 99328 01</td>
                      </tr>
                      <tr>
                        <th width="20%">Nama Kapal</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">TB. TCP 201</td>
                        <th width="20%">Seaman Book</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">3275 23366 2177</td>
                      </tr>
                      <tr>
                        <th width="20%">Pelabuhan</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">Bengalon</td>
                        <th width="20%">No Passport</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">32761 121133 01</td>
                      </tr>
                      <tr>
                        <th width="20%">Sign On</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">15 Desember 2021</td>
                        <th width="20%">Nomor Tlp / Hp</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">0812 1214 3758</td>
                      </tr>
                      <tr>
                        <th width="20%">Join Onboard</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">17 Desember 2021</td>
                        <th width="20%">Email</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">nurulakbar93@gmail.com</td>
                      </tr>
                      <tr>
                        <th width="20%">Sign Off</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">15 Desember 2022</td>
                        <th width="20%">Alamat</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="25%">
                          Jl. Pondok Pinang 3, RT.005, RW.002 No. 38, Kel.
                          Pondok Pinang, Kec. Kebayoran Lama, Jakarta Selatan,
                          DKI Jakarat (12310)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
                <b-col md="2" class="text-center">
                  <b-img
                    thumbnail
                    class="ml-0"
                    :src="require('../../assets/images/page-img/10.jpg')"
                    alt="Responsive image"
                  ></b-img>
                </b-col>
              </b-row>
            </fieldset>
            <fieldset class="form-group border p-3">
              <legend class="w-auto px-2 text-primary"
                >Form Evaluasi Conduct</legend
              >
              <b-row>
                <b-col md="12">
                  <table class="table table-striped">
                    <thead>
                      <tr class="text-center">
                        <th width="40%">DESKRIPSI</th>
                        <th width="20%">PENILAIAN</th>
                        <th width="40%">NOTES</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Kebijakan Perusahaan</th>
                        <td class="text-center">
                          <b-form-select
                            plain
                            v-model="nilaiSelect"
                            :options="nilai"
                            size="sm"
                            class="col-md-12"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Nilai</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </td>
                        <td
                          ><b-form-textarea
                            rows="4"
                            value="Notes"
                          ></b-form-textarea
                        ></td>
                      </tr>
                      <tr>
                        <th>Keahlian</th>
                        <td class="text-center">
                          <b-form-select
                            plain
                            v-model="nilaiSelect"
                            :options="nilai"
                            size="sm"
                            class="col-md-12"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Nilai</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </td>
                        <td
                          ><b-form-textarea
                            rows="4"
                            value="Notes"
                          ></b-form-textarea
                        ></td>
                      </tr>
                      <tr>
                        <th>Inisiatif</th>
                        <td class="text-center">
                          <b-form-select
                            plain
                            v-model="nilaiSelect"
                            :options="nilai"
                            size="sm"
                            class="col-md-12"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Nilai</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </td>
                        <td
                          ><b-form-textarea
                            rows="4"
                            value="Notes"
                          ></b-form-textarea
                        ></td>
                      </tr>
                      <tr>
                        <th>Sikap</th>
                        <td class="text-center">
                          <b-form-select
                            plain
                            v-model="nilaiSelect"
                            :options="nilai"
                            size="sm"
                            class="col-md-12"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Nilai</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </td>
                        <td
                          ><b-form-textarea
                            rows="4"
                            value="Notes"
                          ></b-form-textarea
                        ></td>
                      </tr>
                      <tr>
                        <th>Komunikasi</th>
                        <td class="text-center">
                          <b-form-select
                            plain
                            v-model="nilaiSelect"
                            :options="nilai"
                            size="sm"
                            class="col-md-12"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Nilai</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </td>
                        <td
                          ><b-form-textarea
                            rows="4"
                            value="Notes"
                          ></b-form-textarea
                        ></td>
                      </tr>
                      <tr>
                        <th>Motivasi</th>
                        <td class="text-center">
                          <b-form-select
                            plain
                            v-model="nilaiSelect"
                            :options="nilai"
                            size="sm"
                            class="col-md-12"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Nilai</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </td>
                        <td
                          ><b-form-textarea
                            rows="4"
                            value="Notes"
                          ></b-form-textarea
                        ></td>
                      </tr>
                      <tr>
                        <th>Harmonisasi</th>
                        <td class="text-center">
                          <b-form-select
                            plain
                            v-model="nilaiSelect"
                            :options="nilai"
                            size="sm"
                            class="col-md-12"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Nilai</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </td>
                        <td
                          ><b-form-textarea
                            rows="4"
                            value="Notes"
                          ></b-form-textarea
                        ></td>
                      </tr>
                      <tr>
                        <th>Implementasi ISM Code</th>
                        <td class="text-center">
                          <b-form-select
                            plain
                            v-model="nilaiSelect"
                            :options="nilai"
                            size="sm"
                            class="col-md-12"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Nilai</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </td>
                        <td
                          ><b-form-textarea
                            rows="4"
                            value="Notes"
                          ></b-form-textarea
                        ></td>
                      </tr>
                      <tr>
                        <th>Laporan ISM Code</th>
                        <td class="text-center">
                          <b-form-select
                            plain
                            v-model="nilaiSelect"
                            :options="nilai"
                            size="sm"
                            class="col-md-12"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Nilai</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </td>
                        <td
                          ><b-form-textarea
                            rows="4"
                            value="Notes"
                          ></b-form-textarea
                        ></td>
                      </tr>
                      <tr>
                        <th>Latihan Keselamatan</th>
                        <td class="text-center">
                          <b-form-select
                            plain
                            v-model="nilaiSelect"
                            :options="nilai"
                            size="sm"
                            class="col-md-12"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Nilai</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </td>
                        <td
                          ><b-form-textarea
                            rows="4"
                            value="Notes"
                          ></b-form-textarea
                        ></td>
                      </tr>
                      <tr>
                        <th>Pengoperasian Kapal</th>
                        <td class="text-center">
                          <b-form-select
                            plain
                            v-model="nilaiSelect"
                            :options="nilai"
                            size="sm"
                            class="col-md-12"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Nilai</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </td>
                        <td
                          ><b-form-textarea
                            rows="4"
                            value="Notes"
                          ></b-form-textarea
                        ></td>
                      </tr>
                      <tr>
                        <th>Pemeliharaan Kapal</th>
                        <td class="text-center">
                          <b-form-select
                            plain
                            v-model="nilaiSelect"
                            :options="nilai"
                            size="sm"
                            class="col-md-12"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Nilai</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </td>
                        <td
                          ><b-form-textarea
                            rows="4"
                            value="Notes"
                          ></b-form-textarea
                        ></td>
                      </tr>
                      <tr>
                        <th>Pemeliharaan Perlengkapan Kapal</th>
                        <td class="text-center">
                          <b-form-select
                            plain
                            v-model="nilaiSelect"
                            :options="nilai"
                            size="sm"
                            class="col-md-12"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Nilai</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </td>
                        <td
                          ><b-form-textarea
                            rows="4"
                            value="Notes"
                          ></b-form-textarea
                        ></td>
                      </tr>
                      <tr>
                        <th>Pemeliharaan Perlengkapan</th>
                        <td class="text-center">
                          <b-form-select
                            plain
                            v-model="nilaiSelect"
                            :options="nilai"
                            size="sm"
                            class="col-md-12"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Nilai</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </td>
                        <td
                          ><b-form-textarea
                            rows="4"
                            value="Notes"
                          ></b-form-textarea
                        ></td>
                      </tr>
                      <tr>
                        <th>Keselamatan Kapal</th>
                        <td class="text-center">
                          <b-form-select
                            plain
                            v-model="nilaiSelect"
                            :options="nilai"
                            size="sm"
                            class="col-md-12"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Nilai</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </td>
                        <td
                          ><b-form-textarea
                            rows="4"
                            value="Notes"
                          ></b-form-textarea
                        ></td>
                      </tr>
                      <tr>
                        <th>Kejadian & Kecelaan Kapal / Keselamatan Kerja</th>
                        <td class="text-center">
                          <b-form-select
                            plain
                            v-model="nilaiSelect"
                            :options="nilai"
                            size="sm"
                            class="col-md-12"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Nilai</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </td>
                        <td
                          ><b-form-textarea
                            rows="4"
                            value="Notes"
                          ></b-form-textarea
                        ></td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </fieldset>
            <b-row class="mt-3">
              <b-col md="6">
                <h4 class="text-primary"
                  >Evaluasi Oleh / <i>Evaluation by </i></h4
                >
                <hr />
                <b-form-group label="PIC" label-for="pic">
                  <b-form-select
                    plain
                    v-model="selectedPIC"
                    :options="pic"
                    size="lg"
                    style="border: solid 1px gray"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select PIC</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group label="Jabatan" label-for="jabatan">
                  <b-form-select
                    plain
                    v-model="selectedJabatan"
                    :options="optionsJabatan"
                    size="lg"
                    style="border: solid 1px gray"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Jabatan</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group label="Notes / Remarks" label-for="pic">
                  <b-form-textarea
                    rows="2"
                    value="Comments if any / Komentar jika ada"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <h4 class="text-primary"
                  >Diketahui Oleh / <i>Acknowledge by </i></h4
                >
                <hr />
                <b-form-group label="PIC" label-for="pic">
                  <b-form-select
                    plain
                    v-model="selectedPIC"
                    :options="pic"
                    size="lg"
                    style="border: solid 1px gray"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select PIC</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group label="Jabatan" label-for="jabatan">
                  <b-form-select
                    plain
                    v-model="selectedJabatan"
                    :options="optionsJabatan"
                    size="lg"
                    style="border: solid 1px gray"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Jabatan</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group label="Notes / Remarks" label-for="pic">
                  <b-form-textarea
                    rows="2"
                    value="Comments if any / Komentar jika ada"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col md="12" class="mt-3">
                <b-button
                  type="submit"
                  variant="primary"
                  class="float-right ml-3"
                  ><i class="fa fa-save"></i> Save</b-button
                >
                <b-button
                  type="reset"
                  variant="none"
                  class="iq-bg-danger float-right"
                  ><i class="fa fa-close"></i> Reset Form</b-button
                >
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import _ from 'lodash'
import { fleetsActions } from '@src/Utils/helper'
import { crewingActions } from '@src/Utils/helper'
import { createPopper } from '@popperjs/core'
import { optionsJabatan } from '@/src/core/mock/data/optionsData'

export default {
  name: 'ProfileEdit',

  data() {
    return {
      nilaiSelect: 1,
      nilai: [
        { value: '1', text: 'A / Istimewa' },
        { value: '2', text: 'B / Bagus' },
        { value: '3', text: 'C / Cukup' },
        { value: '4', text: 'D / Kurang' },
      ],
      selectedPIC: null,
      pic: [
        { value: '1', text: 'Nurul Akbar' },
        { value: '2', text: 'Agung Tri Laksono' },
        { value: '3', text: 'Hanel Prillian Chozzy' },
      ],
      selectedJabatan: null,
      optionsJabatan: [
        { value: '1', text: 'Port Enginer' },
        { value: '2', text: 'Port Captain' },
        { value: '3', text: 'Technical Superintendent' },
        { value: '4', text: 'Technical Manager' },
      ],
      text: [
        {
          activeLabel: 'Yes',
          inactiveLabel: 'No',
          class: 'primary',
          checked: false,
        },
      ],

      userDetails: null,
    }
  },
  async mounted() {
    xray.index()
    // this.fetchFleets()
    await this.getProfileUser()
  },

  methods: {
    ...crewingActions,
    async getProfileUser() {
      if (this.$route.params.id) {
        let method = 'getCrewDetail'

        const { status, data } = await this[method](this.$route.params.id)

        console.log(this.$route.params.id, 'ID CREW')
        console.log(data, 'THIS IS PROFILE CREW')

        if (status !== 'success') {
          this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
          return
        }

        this.userDetails = data[0]
      }
    },
  },
}
</script>
