var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "iq-card"
  }, [_c('div', {
    staticClass: "iq-card-body"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-md-12 mb-3"
  }, [_vm._m(0), _vm.modal ? _c('button', {
    staticClass: "float-right close-popup",
    attrs: {
      "type": "submit",
      "data-dismiss": "modal"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('i', {
    staticClass: "ri-close-fill"
  })]) : _vm._e()])]), _vm._m(1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h5', {
    staticClass: "text-primary float-left"
  }, [_c('i', {
    staticClass: "ri-pencil-fill"
  }), _vm._v(" Compose Message")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    staticClass: "email-form"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2 col-form-label",
    attrs: {
      "for": "inputEmail3"
    }
  }, [_vm._v("To:")]), _c('div', {
    staticClass: "col-sm-10"
  }, [_c('select', {
    staticClass: "select2jsMultiSelect form-control",
    attrs: {
      "id": "inputEmail3",
      "multiple": "multiple"
    }
  }, [_c('option', {
    attrs: {
      "value": "Petey Cruiser"
    }
  }, [_vm._v("Petey Cruiser")]), _c('option', {
    attrs: {
      "value": "Bob Frapples"
    }
  }, [_vm._v("Bob Frapples")]), _c('option', {
    attrs: {
      "value": "Barb Ackue"
    }
  }, [_vm._v("Barb Ackue")]), _c('option', {
    attrs: {
      "value": "Greta Life"
    }
  }, [_vm._v("Greta Life")])])])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2 col-form-label",
    attrs: {
      "for": "cc"
    }
  }, [_vm._v("Cc:")]), _c('div', {
    staticClass: "col-sm-10"
  }, [_c('select', {
    staticClass: "select2jsMultiSelect form-control",
    attrs: {
      "id": "cc",
      "multiple": "multiple"
    }
  }, [_c('option', {
    attrs: {
      "value": "Brock Lee"
    }
  }, [_vm._v("Brock Lee")]), _c('option', {
    attrs: {
      "value": "Rick O'Shea"
    }
  }, [_vm._v("Rick O'Shea")]), _c('option', {
    attrs: {
      "value": "Cliff Hanger"
    }
  }, [_vm._v("Cliff Hanger")]), _c('option', {
    attrs: {
      "value": "Barb Dwyer"
    }
  }, [_vm._v("Barb Dwyer")])])])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2 col-form-label",
    attrs: {
      "for": "subject"
    }
  }, [_vm._v("Subject:")]), _c('div', {
    staticClass: "col-sm-10"
  }, [_c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "subject"
    }
  })])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2 col-form-label",
    attrs: {
      "for": "subject"
    }
  }, [_vm._v("Your Message:")]), _c('div', {
    staticClass: "col-md-10"
  }, [_c('textarea', {
    staticClass: "textarea form-control",
    attrs: {
      "rows": "5"
    }
  }, [_vm._v("Next, use our Get Started docs to setup Tiny!")])])]), _c('div', {
    staticClass: "form-group row align-items-center pt-3 m-0"
  }, [_c('div', {
    staticClass: "d-flex flex-grow-1 align-items-center"
  }, [_c('div', {
    staticClass: "send-btn"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("Send")])]), _c('div', {
    staticClass: "send-panel"
  }, [_c('label', {
    staticClass: "ml-2 mb-0 iq-bg-primary rounded",
    attrs: {
      "for": "file"
    }
  }, [_c('input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "id": "file"
    }
  }), _vm._v(" "), _c('a', [_c('i', {
    staticClass: "ri-attachment-line"
  })])]), _c('label', {
    staticClass: "ml-2 mb-0 iq-bg-primary rounded"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_c('i', {
    staticClass: "ri-map-pin-user-line text-primary"
  })])]), _c('label', {
    staticClass: "ml-2 mb-0 iq-bg-primary rounded"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_c('i', {
    staticClass: "ri-drive-line text-primary"
  })])]), _c('label', {
    staticClass: "ml-2 mb-0 iq-bg-primary rounded"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_c('i', {
    staticClass: "ri-camera-line text-primary"
  })])]), _c('label', {
    staticClass: "ml-2 mb-0 iq-bg-primary rounded"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_c('i', {
    staticClass: "ri-user-smile-line text-primary"
  })])])])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "send-panel float-right"
  }, [_c('label', {
    staticClass: "ml-2 mb-0 iq-bg-primary rounded"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_c('i', {
    staticClass: "ri-settings-2-line text-primary"
  })])]), _c('label', {
    staticClass: "ml-2 mb-0 iq-bg-primary rounded"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_c('i', {
    staticClass: "ri-delete-bin-line text-primary"
  })])])])])])]);

}]

export { render, staticRenderFns }