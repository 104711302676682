var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MAINTENANCE - TB ETI 101")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.deck_equipment",
            modifiers: {
              "deck_equipment": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" DECK EQUIPMENT & OUTFITTING")])], 1), _c('b-collapse', {
          attrs: {
            "id": "deck_equipment",
            "accordion": "my-accordion",
            "role": "tabpanel"
          }
        }, [_c('b-card-body', [_c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.accomodation",
            modifiers: {
              "accomodation": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" ACCOMMODATION")])], 1), _c('b-collapse', {
          attrs: {
            "id": "accomodation",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Equipment")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Job Index")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Inteval")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Last Maintenance")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Run Hours")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Due Date")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Wheel House")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Inspection")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1 M")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-12-05")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-01-05")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Maintenance")])], 1), _c('td', [_c('b-dropdown', {
          staticClass: "ml-3",
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-5",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-right mr-2"
        }), _vm._v("Current Date")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-2"
        }), _vm._v("Back Date")])], 1)], 1)]), _c('tr', [_c('td', [_vm._v("Engine Room")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Inspection")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1 M")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-05-15")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-06-15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Maintenance")])], 1), _c('td', [_c('b-dropdown', {
          staticClass: "ml-3",
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-5",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-right mr-2"
        }), _vm._v("Current Date")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-2"
        }), _vm._v("Back Date")])], 1)], 1)]), _c('tr', [_c('td', [_vm._v("Acomodation Room")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Inspection")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("3 M")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-07-15")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-10-16")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Maintenance")])], 1), _c('td', [_c('b-dropdown', {
          staticClass: "ml-3",
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-5",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-right mr-2"
        }), _vm._v("Current Date")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-2"
        }), _vm._v("Back Date")])], 1)], 1)])])])])], 1), _c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.hull_loadline",
            modifiers: {
              "hull_loadline": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" HULL & LOAD LINE")])], 1), _c('b-collapse', {
          attrs: {
            "id": "hull_loadline",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Equipment")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Job Index")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Inteval")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Last Maintenance")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Run Hours")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Due Date")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Hull")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Inspection")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1 M")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-12-05")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-01-05")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Maintenance")])], 1), _c('td', [_c('b-dropdown', {
          staticClass: "ml-3",
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-5",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-right mr-2"
        }), _vm._v("Current Date")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-2"
        }), _vm._v("Back Date")])], 1)], 1)]), _c('tr', [_c('td', [_vm._v("Load Line")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Inspection")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1 M")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-05-15")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-06-15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Maintenance")])], 1), _c('td', [_c('b-dropdown', {
          staticClass: "ml-3",
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-5",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-right mr-2"
        }), _vm._v("Current Date")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-2"
        }), _vm._v("Back Date")])], 1)], 1)])])])])], 1)], 1)])], 1)], 1), _c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.machinery",
            modifiers: {
              "machinery": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" MACHINERY EQUIPMENT")])], 1), _c('b-collapse', {
          attrs: {
            "id": "machinery",
            "accordion": "my-accordion",
            "role": "tabpanel"
          }
        }, [_c('b-card-body', [_c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.main_engine",
            modifiers: {
              "main_engine": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" MAIN ENGINE")])], 1), _c('b-collapse', {
          attrs: {
            "id": "main_engine",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Equipment")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Job Index")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Inteval")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Last Maintenance")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Run Hours")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Due Date")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Sea Water Cooling Pump No. 1 (Ebara)")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Inspection")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1 M")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-12-05")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-01-05")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Maintenance")])], 1), _c('td', [_c('b-dropdown', {
          staticClass: "ml-3",
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-5",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-right mr-2"
        }), _vm._v("Current Date")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-2"
        }), _vm._v("Back Date")])], 1)], 1)]), _c('tr', [_c('td', [_vm._v("Sea Water Cooling Pump No. 1 (Ebara)")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Overhaul")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("30 M")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-05-15")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-06-15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Maintenance")])], 1), _c('td', [_c('b-dropdown', {
          staticClass: "ml-3",
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-5",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-right mr-2"
        }), _vm._v("Current Date")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-2"
        }), _vm._v("Back Date")])], 1)], 1)])])]), _c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.mitsubishi_s6r2_t2mtk3l",
            modifiers: {
              "mitsubishi_s6r2_t2mtk3l": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "info"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" MITSUBISHI S6R2-T2MTK3L")])], 1), _c('b-collapse', {
          attrs: {
            "id": "mitsubishi_s6r2_t2mtk3l",
            "accordion": "my-accordion-sub-sub",
            "role": "tabpanel"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Equipment")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Job Index")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Inteval")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Last Maintenance")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Run Hours")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Due Date")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Fuel Oil System - Fuel Oil Filter | No. 1")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Change")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("300 H")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2018-07-04")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("280 H")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Maintenance")])], 1), _c('td', [_c('b-dropdown', {
          staticClass: "ml-3",
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-5",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-right mr-2"
        }), _vm._v("Current Date")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-2"
        }), _vm._v("Back Date")])], 1)], 1)]), _c('tr', [_c('td', [_vm._v("Lubricating Oil System - Bypass LO Filter | No. 1")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Overhaul")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1.000 H")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-05-15")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("950 H")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Maintenance")])], 1), _c('td', [_c('b-dropdown', {
          staticClass: "ml-3",
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-5",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-right mr-2"
        }), _vm._v("Current Date")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-2"
        }), _vm._v("Back Date")])], 1)], 1)])])])])], 1)])], 1), _c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.aux_engine",
            modifiers: {
              "aux_engine": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" AUXUILARY ENGINE")])], 1), _c('b-collapse', {
          attrs: {
            "id": "aux_engine",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Equipment")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Job Index")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Inteval")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Last Maintenance")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Run Hours")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Due Date")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Sea Water Cooling Pump No. 1 (Ebara)")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Inspection")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1 M")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-12-05")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-01-05")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Maintenance")])], 1), _c('td', [_c('b-dropdown', {
          staticClass: "ml-3",
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-5",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-right mr-2"
        }), _vm._v("Current Date")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-2"
        }), _vm._v("Back Date")])], 1)], 1)]), _c('tr', [_c('td', [_vm._v("Sea Water Cooling Pump No. 1 (Ebara)")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Overhaul")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("30 M")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-05-15")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-06-15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Maintenance")])], 1), _c('td', [_c('b-dropdown', {
          staticClass: "ml-3",
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-5",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-right mr-2"
        }), _vm._v("Current Date")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-2"
        }), _vm._v("Back Date")])], 1)], 1)])])]), _c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.weichai_ccfj_30j_wj",
            modifiers: {
              "weichai_ccfj_30j_wj": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "info"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" WEICHAI CCFJ-30J-WJ")])], 1), _c('b-collapse', {
          attrs: {
            "id": "weichai_ccfj_30j_wj",
            "accordion": "my-accordion-sub-sub",
            "role": "tabpanel"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Equipment")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Job Index")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Inteval")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Last Maintenance")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Run Hours")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Due Date")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Fuel Oil System - Fuel Oil Filter | No. 1")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Change")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("300 H")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2018-07-04")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("280 H")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Maintenance")])], 1), _c('td', [_c('b-dropdown', {
          staticClass: "ml-3",
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-5",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-right mr-2"
        }), _vm._v("Current Date")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-2"
        }), _vm._v("Back Date")])], 1)], 1)]), _c('tr', [_c('td', [_vm._v("Lubricating Oil System - Bypass LO Filter | No. 1")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Overhaul")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1.000 H")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-05-15")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("950 H")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Maintenance")])], 1), _c('td', [_c('b-dropdown', {
          staticClass: "ml-3",
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-5",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-right mr-2"
        }), _vm._v("Current Date")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-2"
        }), _vm._v("Back Date")])], 1)], 1)])])])])], 1)], 1)])], 1), _c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.gearbox",
            modifiers: {
              "gearbox": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" GEARBOX")])], 1), _c('b-collapse', {
          attrs: {
            "id": "gearbox",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Equipment")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Job Index")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Inteval")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Last Maintenance")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Run Hours")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Due Date")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Sea Water Cooling Pump No. 1 (Ebara)")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Inspection")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1 M")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-12-05")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-01-05")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Maintenance")])], 1), _c('td', [_c('b-dropdown', {
          staticClass: "ml-3",
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-5",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-right mr-2"
        }), _vm._v("Current Date")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-2"
        }), _vm._v("Back Date")])], 1)], 1)]), _c('tr', [_c('td', [_vm._v("Sea Water Cooling Pump No. 1 (Ebara)")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Overhaul")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("30 M")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-05-15")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2019-06-15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Maintenance")])], 1), _c('td', [_c('b-dropdown', {
          staticClass: "ml-3",
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-5",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-right mr-2"
        }), _vm._v("Current Date")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-4",
            modifiers: {
              "modal-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-arrow-left mr-2"
        }), _vm._v("Back Date")])], 1)], 1)])])])])], 1)], 1)])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }