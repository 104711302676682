var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.detail !== null ? _c('div', {
    staticStyle: {
      "margin": "8px"
    }
  }, [_c('div', {
    staticClass: "personal-data-person mb-5"
  }, [_c('b-row', {
    staticClass: "mb-4 position-relative"
  }, [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("PERSONAL DATA OF PERSON INVOLVED")])]), _c('div', {
    staticClass: "photo-right-top"
  }, [_vm.detail.personal_photo ? _c('img', {
    attrs: {
      "src": _vm.detail.personal_photo || '',
      "alt": _vm.detail.personal_fullname
    }
  }) : _c('span', [_vm._v("No Photo")])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "7"
    }
  }, [_c('table', {
    staticClass: "w-100"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Name")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v(_vm._s(_vm.detail.personal_fullname))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Sex (M/F)")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v(_vm._s(_vm.detail.personal_gender))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Place & Date of Birth")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v(_vm._s(_vm.detail.personal_birthplace) + ", " + _vm._s(_vm.detail.personal_birthdate))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Age")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v(_vm._s(_vm.personAge))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Marital Status")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v(_vm._s(_vm.detail.personal_marital_status))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Company")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v(_vm._s(_vm.personCompany))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Position")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v(_vm._s(_vm.detail.personal_position))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Length of Work")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v(_vm._s(_vm.detail.working_experience))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("RCA")]), _c('th', {
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v(_vm._s(_vm.detail.type) + " Witness")])]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%",
      "colspan": "2"
    }
  }, [_c('div', {
    staticClass: "border border-dark text-bold"
  }, [_vm._v("Sign")])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%",
      "colspan": "2"
    }
  }, [_c('div', {
    staticClass: "photo-sign"
  }, [_vm.detail.personal_sign ? _c('img', {
    attrs: {
      "src": _vm.detail.personal_sign || '',
      "alt": ""
    }
  }) : _c('span', [_vm._v("No Photo")])])])])])])])], 1)], 1), _c('div', {
    staticClass: "define-the-problem mb-5"
  }, [_c('b-row', {
    staticClass: "mb-4 position-relative"
  }, [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("Define The Problem")])]), _c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('span', [_vm._v("Template for Accident Interviews with Crew or Other Involved Parties to get specified issues")])])], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "7"
    }
  }, [_c('table', {
    staticClass: "w-auto",
    attrs: {
      "border": "1"
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "color": "#ffe89a"
    },
    attrs: {
      "width": "60%",
      "bgcolor": "#1e90ff"
    }
  }, [_vm._v("Assessor of the Accident")]), _c('th', {
    attrs: {
      "width": "40%"
    }
  })])]), _c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('table', {
    staticClass: "w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Name")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.detail.assessor_fullname))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Company")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.assessorCompany))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Current Position")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.detail.assessor_position))])]), _c('tr', [_c('th', {
    staticStyle: {
      "color": "#ffe89a"
    },
    attrs: {
      "bgcolor": "#1e90ff",
      "colspan": "3"
    }
  }, [_vm._v("all data belongs to Marine Insurance Departement Group Trans Energi Logistik ")])])])])])])])])]), _c('b-col', {
    staticClass: "flex justify-end w-max",
    attrs: {
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "py-1 px-4 text-center text-bold",
    staticStyle: {
      "color": "#000",
      "background-color": "#faebd7"
    }
  }, [_vm._v(" DEFINE THE PROBLEM ")])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table w-100 table-bordered"
  }, [_c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center text-bold",
    attrs: {
      "bgcolor": "#deb887",
      "colspan": "2"
    }
  }, [_vm._v("PROBLEM STATEMENT WORKSHEET ")])]), _c('tr', [_c('th', {
    staticClass: "text-center text-bold",
    attrs: {
      "bgcolor": "#faebd7",
      "colspan": "2"
    }
  }, [_vm._v("BACKGROUND INFORMATION ABOUT THE ACCIDENT OR PROBLEM ")])]), _c('tr', [_c('th', {
    attrs: {
      "width": "25%",
      "bgcolor": "#ffd700"
    }
  }, [_vm._v("What part, Product, Process, or Services Involved ")]), _c('td', {
    attrs: {
      "width": "75%"
    }
  }, [_vm._v(" " + _vm._s(_vm.detail.explanation_damage) + " ")])]), _c('tr', [_c('th', {
    attrs: {
      "width": "25%",
      "bgcolor": "#ffd700"
    }
  }, [_vm._v("Explain the Problem(s)")]), _c('td', {
    attrs: {
      "width": "75%"
    }
  })]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.detail.explanation_problem) + " ")])]), _c('tr', [_c('th', {
    staticClass: "text-bold",
    attrs: {
      "bgcolor": "#ffd700",
      "colspan": "2"
    }
  }, [_vm._v(" when in the life cycle of the unit is the problem detected ? (e.g at the source, intial inspection, final inspection, end user, etc)-Explain ")])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.detail.explanation_detected) + " ")])]), _c('tr', [_c('th', {
    staticClass: "text-bold text-center",
    attrs: {
      "bgcolor": "#faebd7",
      "colspan": "2"
    }
  }, [_vm._v(" Expectations ")])]), _c('tr', [_c('th', {
    attrs: {
      "width": "25%",
      "bgcolor": "#ffd700"
    }
  }, [_vm._v("Result of Defining The Problem")]), _c('td', {
    attrs: {
      "width": "75%"
    }
  })]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.detail.explanation_result) + " ")])])])])])], 1)], 1), _c('div', {
    staticClass: "collecting-information mb-5"
  }, [_c('b-row', {
    staticClass: "mb-4 position-relative"
  }, [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("Collecting Information")])]), _c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('span', [_vm._v("Template for Accident Interview \"WHY\" METHOD to Uncovered Problem")])])], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "7"
    }
  }, [_c('table', {
    staticClass: "w-auto",
    attrs: {
      "border": "1"
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "color": "#ffe89a"
    },
    attrs: {
      "width": "60%",
      "bgcolor": "#1e90ff"
    }
  }, [_vm._v("Assessor of the Accident")]), _c('th', {
    attrs: {
      "width": "40%"
    }
  })])]), _c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('table', {
    staticClass: "w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Name")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.detail.assessor_fullname))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Company")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.assessorCompany))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Current Position")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.detail.assessor_position))])]), _c('tr', [_c('th', {
    staticStyle: {
      "color": "#ffe89a"
    },
    attrs: {
      "bgcolor": "#1e90ff",
      "colspan": "3"
    }
  }, [_vm._v("all data belongs to Marine Insurance Departement Group Trans Energi Logistik ")])])])])])])])])]), _c('b-col', {
    staticClass: "flex justify-end w-max",
    attrs: {
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "py-1 px-4 text-center text-bold",
    staticStyle: {
      "color": "#000",
      "background-color": "#faebd7"
    }
  }, [_vm._v(" COLLECTING INFORMATION ")])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table w-100 table-bordered"
  }, [_c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center text-bold",
    attrs: {
      "bgcolor": "#deb887",
      "colspan": "2"
    }
  }, [_vm._v("THE FIVE-WHYS WORKSHEET ")])]), _c('tr', [_c('th', {
    staticClass: "text-center text-bold",
    attrs: {
      "bgcolor": "#faebd7",
      "width": "60%"
    }
  }, [_vm._v("DEFECT, PROBLEM OR ERROR ")]), _c('th', {
    staticClass: "text-center text-bold",
    attrs: {
      "bgcolor": "#faebd7",
      "width": "40%"
    }
  }, [_vm._v("CAUSE ")])]), _vm._l(_vm.detail.whys, function (why, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "align-top"
    }, [_c('div', [_c('strong', [_vm._v("Why-" + _vm._s(index + 1))])]), _c('div', [_c('h4', [_vm._v(_vm._s(why.defect))])])]), _c('td', [_vm._v(" " + _vm._s(why.cause) + " ")])]);
  }), _c('tr', [_c('td', {
    staticClass: "text-white",
    attrs: {
      "colspan": "2",
      "bgcolor": "black"
    }
  }, [_vm._v(" The Cause of one \"why\" is the basis for the next \"why\" keep asking \"why\" until the root of cause is uncovered (why - 5) ")])])], 2)])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table w-100 table-bordered"
  }, [_c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center text-bold",
    attrs: {
      "bgcolor": "#faebd7",
      "width": "60%"
    }
  }, [_vm._v("WHAT IS?")]), _c('th', {
    staticClass: "text-center text-bold",
    attrs: {
      "bgcolor": "#faebd7",
      "width": "40%"
    }
  }, [_vm._v("WHAT IS NOT?")])]), _c('tr', [_vm._l(_vm.detail.whatis, function (data, index) {
    return _c('td', {
      key: index
    }, [_vm._v(" " + _vm._s(data) + " ")]);
  }), _vm._l(_vm.detail.whatisnot, function (data, index) {
    return _c('td', {
      key: index
    }, [_vm._v(" " + _vm._s(data) + " ")]);
  })], 2), _c('tr', [_c('td', {
    staticClass: "text-white",
    attrs: {
      "colspan": "2",
      "bgcolor": "black"
    }
  }, [_vm._v(" Complete the worksheet by addings as many \"what is/what is not\" condition as relevant to the problem under study ")])])])])])], 1)], 1), _c('div', {
    staticClass: "identify-root-cause mb-5"
  }, [_c('b-row', {
    staticClass: "mb-4 position-relative"
  }, [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("Identify The Root Cause")])]), _c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('span', [_vm._v("Template for identify The Root Cause by Number")])])], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "7"
    }
  }, [_c('table', {
    staticClass: "w-auto",
    attrs: {
      "border": "1"
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "color": "#ffe89a"
    },
    attrs: {
      "width": "60%",
      "bgcolor": "#1e90ff"
    }
  }, [_vm._v("Assessor of the Accident")]), _c('th', {
    attrs: {
      "width": "40%"
    }
  })])]), _c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('table', {
    staticClass: "w-100"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Name")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.detail.assessor_fullname))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Company")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.assessorCompany))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "40%"
    }
  }, [_vm._v("Current Position")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "10%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "50%"
    }
  }, [_vm._v(_vm._s(_vm.detail.assessor_position))])]), _c('tr', [_c('th', {
    staticStyle: {
      "color": "#ffe89a"
    },
    attrs: {
      "bgcolor": "#1e90ff",
      "colspan": "3"
    }
  }, [_vm._v("all data belongs to Marine Insurance Departement Group Trans Energi Logistik ")])])])])])])])])]), _c('b-col', {
    staticClass: "flex justify-end w-max",
    attrs: {
      "md": "5"
    }
  }, [_c('div', {
    staticClass: "py-1 px-4 text-center text-bold",
    staticStyle: {
      "color": "#000",
      "background-color": "#faebd7"
    }
  }, [_vm._v(" IDENTIFY THE ROOT CAUSE ")])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table w-100 table-bordered"
  }, [_c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "colspan": 12,
      "bgcolor": "#faebd7"
    }
  }, [_c('table', {
    staticClass: "table-borderless w-100 mb-3"
  }, [_c('tr', [_c('td', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Vessel: ")]), _c('td', {
    attrs: {
      "bgcolor": "white",
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.vessel))]), _c('td', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("FMEA Date (Orig.): ")]), _c('td', {
    attrs: {
      "bgcolor": "white",
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.detail.fmea_date))])])]), _c('table', {
    staticClass: "table-borderless w-100 mb-3"
  }, [_c('tr', [_c('td', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Date of Loss: ")]), _c('td', {
    attrs: {
      "bgcolor": "white",
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.detail.date_of_loss))]), _c('td', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("FMEA Case: ")]), _c('td', {
    attrs: {
      "bgcolor": "white",
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.detail.fmea_case))])])])])]), _c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "colspan": 12,
      "bgcolor": "#d3d3d3"
    }
  }, [_vm._v(" Causal Calculation for Root Cause Determination ")])]), _c('tr', _vm._l(_vm.detail.identifications, function (identification) {
    return _c('th', {
      key: "identification-".concat(identification.id),
      staticClass: "text-center",
      attrs: {
        "bgcolor": identification.title === 'RPN' ? '6495ed' : !identification.description ? 'ffd700' : '#d3d3d3',
        "rowspan": !identification.description ? 2 : undefined
      }
    }, [_vm._v(" " + _vm._s(identification.title) + " " + _vm._s(!identification.description && identification.title !== 'RPN' ? '(1 to 10)' : '') + " ")]);
  }), 0), _c('tr', _vm._l(_vm.detail.identifications, function (identification) {
    return _c('th', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: identification.description,
        expression: "identification.description"
      }],
      key: "identification-".concat(identification.id),
      staticClass: "text-center",
      attrs: {
        "bgcolor": "#faebd7"
      }
    }, [_vm._v(" " + _vm._s(identification.description) + " ")]);
  }), 0), _vm._l(_vm.answerLength, function (idx) {
    return _c('tr', {
      key: "answers-".concat(idx)
    }, _vm._l(_vm.detail.identifications, function (identification) {
      return _c('td', {
        key: "identification-".concat(identification.id),
        class: {
          'text-danger': identification.title === 'RPN'
        }
      }, [_vm._v(" " + _vm._s(identification.answers[idx - 1] || 'N/A') + " ")]);
    }), 0);
  })], 2)])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('table', {
    staticClass: "table w-100 table-bordered"
  }, [_c('tr', [_c('th', {
    attrs: {
      "bgcolor": "#faebd7",
      "width": "70%"
    }
  }, [_vm._v(" DESCRIPTION OF OCCURRENCE OF CAUSAL FACTORS ")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#faebd7",
      "width": "30%"
    }
  }, [_vm._v(" RATING ")])]), _c('tr', [_c('td', [_vm._v(" Failure is almost inevitable ")]), _c('td', {
    staticClass: "text-white text-center",
    attrs: {
      "bgcolor": "#b22222"
    }
  }, [_vm._v(" 10 ")])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(" This Process or similar processes have often ")]), _c('td', {
    staticClass: "text-white text-center",
    attrs: {
      "bgcolor": "#b22222"
    }
  }, [_vm._v(" 9 ")])]), _c('tr', [_c('td', {
    staticClass: "text-white text-center",
    attrs: {
      "bgcolor": "#dc143c"
    }
  }, [_vm._v(" 8 ")])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(" This Process has occasional failures but will impact damage to Vessel, in major or Minor ")]), _c('td', {
    staticClass: "text-white text-center",
    attrs: {
      "bgcolor": "#dc143c"
    }
  }, [_vm._v(" 7 ")])]), _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#d2691e"
    }
  }, [_vm._v(" 6 ")])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v(" slated failures associated with similar processes ")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#d2691e"
    }
  }, [_vm._v(" 5 ")])]), _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#d2691e"
    }
  }, [_vm._v(" 4 ")])]), _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#ffd700"
    }
  }, [_vm._v(" 3 ")])]), _c('tr', [_c('td', [_vm._v(" Only Isolated Failures associated with this process or almost indentical processes ")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#ffd700"
    }
  }, [_vm._v(" 2 ")])]), _c('tr', [_c('td', [_vm._v(" Failure unlike, No Failures ever associated with this process or almost identical processes ")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#7fff00"
    }
  }, [_vm._v(" 1 ")])])])]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('table', {
    staticClass: "table w-100 table-bordered"
  }, [_c('tr', [_c('th', {
    attrs: {
      "bgcolor": "#faebd7",
      "width": "70%"
    }
  }, [_vm._v(" SEVERITY/INFLUENCE RATING DESCRIPTION ")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#faebd7",
      "width": "30%"
    }
  }, [_vm._v(" RATING ")])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(" Very High-Hazardous, Vessel become inactive or cannot be operated and can cause injury or death for the crew, passengers, or other parties ( the effect directly to loss/damage of primary functions of the Vessel) ")]), _c('td', {
    staticClass: "text-white text-center",
    attrs: {
      "bgcolor": "#b22222"
    }
  }, [_vm._v(" 10 ")])]), _c('tr', [_c('td', {
    staticClass: "text-white text-center",
    attrs: {
      "bgcolor": "#b22222"
    }
  }, [_vm._v(" 9 ")])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(" High - Repeated failures made the vessel have the same problem -negligence, incompetence, misconduct, error in judgment by a person, and latent defect of the vessel ")]), _c('td', {
    staticClass: "text-white text-center",
    attrs: {
      "bgcolor": "#dc143c"
    }
  }, [_vm._v(" 8 ")])]), _c('tr', [_c('td', {
    staticClass: "text-white text-center",
    attrs: {
      "bgcolor": "#dc143c"
    }
  }, [_vm._v(" 7 ")])]), _c('tr', [_c('td', {
    attrs: {
      "rowspan": "3"
    }
  }, [_vm._v(" Moderate -Some occasional Failures, ")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#d2691e"
    }
  }, [_vm._v(" 6 ")])]), _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#d2691e"
    }
  }, [_vm._v(" 5 ")])]), _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#d2691e"
    }
  }, [_vm._v(" 4 ")])]), _c('tr', [_c('td', [_vm._v(" Minor - relatively few failures and minor impact to automatically detects failure mode Vessel or other parties ")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#ffd700"
    }
  }, [_vm._v(" 3 ")])]), _c('tr', [_c('td', [_vm._v(" Very Minor ")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#ffd700"
    }
  }, [_vm._v(" 2 ")])]), _c('tr', [_c('td', [_vm._v(" No Effect -No Danger ")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#7fff00"
    }
  }, [_vm._v(" 1 ")])])])]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('table', {
    staticClass: "table w-100 table-bordered"
  }, [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#faebd7",
      "width": "30%"
    }
  }, [_vm._v(" RATING ")]), _c('th', {
    attrs: {
      "bgcolor": "#faebd7",
      "width": "70%"
    }
  }, [_vm._v(" DETECTION RATING DESCRIPTION ")])]), _c('tr', [_c('td', {
    staticClass: "text-white text-center",
    attrs: {
      "bgcolor": "#b22222"
    }
  }, [_vm._v(" 10 ")]), _c('td', [_vm._v(" Controls will not or can not detect the existence of a failure, no known controls available to detect failure mode ")])]), _c('tr', [_c('td', {
    staticClass: "text-white text-center",
    attrs: {
      "bgcolor": "#b22222"
    }
  }, [_vm._v(" 9 ")]), _c('td', [_vm._v(" controls probably will not detect the exitence of failure ")])]), _c('tr', [_c('td', {
    staticClass: "text-white text-center",
    attrs: {
      "bgcolor": "#dc143c"
    }
  }, [_vm._v(" 8 ")]), _c('td', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(" Controls have a poor change of detecting the existence of a failure mode ")])]), _c('tr', [_c('td', {
    staticClass: "text-white text-center",
    attrs: {
      "bgcolor": "#dc143c"
    }
  }, [_vm._v(" 7 ")])]), _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#d2691e"
    }
  }, [_vm._v(" 6 ")]), _c('td', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(" Controls may detect the existence of a failure mode ")])]), _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#d2691e"
    }
  }, [_vm._v(" 5 ")])]), _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#d2691e"
    }
  }, [_vm._v(" 4 ")]), _c('td', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(" Control have a good chance of detecting failure mode, process Minor - relatively few failures and minor impact to automatically detects failure mode ")])]), _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#ffd700"
    }
  }, [_vm._v(" 3 ")])]), _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#7fff00"
    }
  }, [_vm._v(" 2 ")]), _c('td', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(" current control almost centrain to detect the failure mode, reliable detection control are known with similar processes. Process automatically prevents further processing ")])]), _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#7fff00"
    }
  }, [_vm._v(" 1 ")])])])])], 1)], 1), _c('div', {
    staticClass: "implement-corrective-actions mb-5"
  }, [_c('b-row', {
    staticClass: "mb-4 position-relative"
  }, [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("Implement Corrective Actions")])]), _c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('span', [_vm._v("Template for Implementation of Corrective Actions")])])], 1), _c('b-row', [_c('b-col', {
    staticClass: "m-auto",
    attrs: {
      "md": "8"
    }
  }, [_c('table', {
    staticClass: "table w-100 table-bordered"
  }, [_c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#faebd7",
      "width": "25%"
    }
  }, [_vm._v(" Tool ")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#faebd7",
      "width": "25%"
    }
  }, [_vm._v(" Question ")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#faebd7",
      "width": "25%"
    }
  }, [_vm._v(" Findings ")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "bgcolor": "#faebd7",
      "width": "25%"
    }
  }, [_vm._v(" Corrective Actions ")])]), _vm._l(_vm.detail.implementations, function (implementation, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(" " + _vm._s(implementation.tool || 'N/A') + " ")]), _c('td', [_vm._v(" " + _vm._s(implementation.question || 'N/A') + " ")]), _c('td', [_vm._v(" " + _vm._s(implementation.finding || 'N/A') + " ")]), _c('td', [_vm._v(" " + _vm._s(implementation.corrective_action || 'N/A') + " ")])]);
  })], 2)]), _c('div', [_vm._v(" Rarely will all tolls and techniques be needed to uncover a root cause, experience is the best judge to determine the best order to use the various tools and techniques available to search and questions processes for the root cause to a specific problem ")])])], 1)], 1), _c('div', {
    staticClass: "fishbone-diagram-container"
  }, [_c('b-row', {
    staticClass: "mb-4 position-relative"
  }, [_c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_vm._v("Fishbone Diagram")])]), _c('b-col', {
    staticClass: "text-center mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('span', [_vm._v("Template for Fishbone Diagram")])])], 1), _c('div', {
    staticClass: "fishbone-diagram"
  }, [_c('div', {
    staticClass: "fish-head"
  }, [_vm._v(" " + _vm._s(_vm.detail.diagram_title) + " ")]), _vm._l(_vm.diagramsTop, function (diagram, index) {
    return [_c('div', {
      key: index,
      staticClass: "child-bone",
      style: {
        top: 0,
        left: "".concat(30 + index * 350, "px")
      }
    }, [_c('div', {
      staticClass: "child-bone-container"
    }, [_c('div', {
      staticClass: "header"
    }, [_vm._v(" " + _vm._s(diagram.header) + " ")]), diagram.body ? _vm._l(diagram.body, function (body, indexBody) {
      return _c('div', {
        key: indexBody,
        staticClass: "content"
      }, [_vm._v(" " + _vm._s(body) + " ")]);
    }) : _vm._e()], 2)])];
  }), _c('div', {
    staticClass: "main-bone"
  }), _vm._l(_vm.diagramsBottom, function (diagram, index) {
    return [_c('div', {
      key: index,
      staticClass: "child-bone bottom",
      style: {
        top: '520px',
        left: "".concat(100 + index * 350, "px")
      }
    }, [_c('div', {
      staticClass: "child-bone-container"
    }, [_c('div', {
      staticClass: "header"
    }, [_vm._v(" " + _vm._s(diagram.header) + " ")]), diagram.body ? _vm._l(diagram.body, function (body, indexBody) {
      return _c('div', {
        key: indexBody,
        staticClass: "content"
      }, [_vm._v(" " + _vm._s(body) + " ")]);
    }) : _vm._e()], 2)])];
  })], 2)], 1)]) : _c('div', [_c('p', [_vm._v("Fetching Data...")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }