var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "table-responsive"
        }, [_c('b-table-simple', {
          staticClass: "text-center"
        }, [_c('b-thead', [_c('b-tr', _vm._l(_vm.headerColumn, function (item, index) {
          return _c('th', {
            key: item.key + index
          }, [_vm._v(_vm._s(item.label))]);
        }), 0)], 1), _c('b-tbody', _vm._l(_vm.rows, function (item, index) {
          return _c('b-tr', {
            key: index
          }, [_c('th', {
            attrs: {
              "scope": "row"
            }
          }, [_vm._v(_vm._s(item.name))]), _c('td', [item.button ? [_c('h2', [_vm._v(_vm._s(item.starter) + " "), _c('small', [_vm._v(_vm._s(item.starter_per))])]), _c('b-button', {
            attrs: {
              "variant": "primary  mt-3"
            }
          }, [_vm._v("Purchase")])] : _vm._e(), item.starter && !item.button ? _c('i', {
            staticClass: "ri-check-line ri-2x text-success"
          }) : _vm._e()], 2), _c('td', [item.button ? [_c('h2', [_vm._v(_vm._s(item.business) + " "), _c('small', [_vm._v(_vm._s(item.business_per))])]), _c('b-button', {
            attrs: {
              "variant": "primary  mt-3"
            }
          }, [_vm._v("Purchase")])] : _vm._e(), item.business && !item.button ? _c('i', {
            staticClass: "ri-check-line ri-2x text-success"
          }) : _vm._e()], 2), _c('td', [item.button ? [_c('h2', [_vm._v(_vm._s(item.enterprise) + " "), _c('small', [_vm._v(_vm._s(item.enterprise_per))])]), _vm._v(". "), _c('b-button', {
            attrs: {
              "variant": "primary  mt-3"
            }
          }, [_vm._v("Purchase")])] : _vm._e(), item.enterprise && !item.button ? _c('i', {
            staticClass: "ri-check-line ri-2x text-success"
          }) : _vm._e()], 2), _c('td', [item.button ? [_c('h2', [_vm._v(_vm._s(item.unlimited) + " "), _c('small', [_vm._v(_vm._s(item.unlimited_per))])]), _c('b-button', {
            attrs: {
              "variant": "primary  mt-3"
            }
          }, [_vm._v("Purchase")])] : _vm._e(), item.unlimited && !item.button ? _c('i', {
            staticClass: "ri-check-line ri-2x text-success"
          }) : _vm._e()], 2)]);
        }), 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }