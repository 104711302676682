<template>
  <div>
    <section class="sign-in-page pt-4">
      <b-container class="sign-in-page-bg p-3">
        <div class="row no-gutters">
          <div class="col-md-6 text-center position-relative">
            <div class="sign-in-detail text-white">
              <a class="mb-5" href="#"><img :src="logo" class="img-fluid mb-5" alt="logo" width="300px" height="auto"></a>
              <Slick :option="slickOptions">
                <div class="item">
                  <div class="top">
                    <div class="boat"><img :src="require('../../assets/images/Km3FO09.png')" alt=""></div>
                    <div class="waves"></div>
                  </div>
                  <div class="bottom"><div class="float"></div></div>
                  <h4 class="mb-1 text-white">Manage your Tug Boat Operations</h4>
                  <p>This system will help you to manage your Tug Boat Operations.</p>
                </div>
                <div class="item">
                  <div class="top">
                    <div class="boat"><img :src="require('../../assets/images/Barge.png')" alt=""></div>
                    <div class="waves"></div>
                  </div>
                  <div class="bottom">
                    <div class="float"></div>
                  </div>
                  <h4 class="mb-1 text-white">Manage your Barge Operations</h4>
                  <p>This system will help you to manage your Tug Boat Operations.</p>
                </div>
                <div class="item">
                  <div class="top">
                    <div class="boat"><img :src="require('../../assets/images/fleet/PusherTug.png')" alt=""></div>
                    <div class="waves"></div>
                  </div>
                  <div class="bottom"><div class="float"></div></div>
                  <h4 class="mb-1 text-white">Manage your Pusher Tug</h4>
                  <p>This system will help you to manage your Pusher Tug Operations.</p>
                </div>
                <div class="item">
                  <div class="top">
                    <div class="boat"><img :src="require('../../assets/images/fleet/PusherBarge.png')" alt=""></div>
                    <div class="waves"></div>
                  </div>
                  <div class="bottom"><div class="float"></div></div>
                  <h4 class="mb-1 text-white">Manage your Pusher Barge</h4>
                  <p>This system will help you to manage your Pusher Barge Operations.</p>
                </div>
              </Slick>
              <b-row>
                <b-col md="12">
                  <b-button @click="del_cookies()" size="sm" block variant="warning"><i class="fa fa-refresh"></i> Clear Your Cache!</b-button>
                </b-col>
              </b-row>
            </div>
          </div>
          <div class="col-md-6 position-relative">
            <div class="sign-in-from">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>
<script>
import Loader from '../../components/xray/loader/Loader'
import logo from '../../assets/images/logo_new.png'
import bgImage from '../../assets/images/login/2.png'
export default {
  name: 'AuthLayout1',
  components: {
    Loader
  },
  data () {
    return {
      slickOptions: {
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 2000,
        fade: true,
        slidesToShow: 1,
        arrows: false,
        dots: true
      },
      logo: logo,
      bgImageURL: bgImage
    }
  },
  methods: {
    del_cookies () {
      /* Split Cookies into a two-dimensional array: */
      var cA = document.cookie.split(';').map(cookie => cookie.split('='))
      var c = 0
      /* Repeat following prozess for the number of cookies */
      while (c < cA.length) {
        /* Get first element of every array inside the c_a array: */
        var cAName = cA[c][0]

        /* DEBUGGING: (logs the name of the cookies in the console) */
        /* console.log(c_a[c][0]); */

        /* set the expire date to some date in the past to delete the cookie: */
        document.cookie = cAName + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
        /* increase c to get the next array */
        c++
      }
      sessionStorage.clear()
      localStorage.clear()
    }
  }
}
</script>
