<template>
  <div class="container mt-5">
    <!-- <video src="../../../assets/videos/bg-login-compress.mp4" autoplay loop playsinline muted></video> -->
    <div class="row justify-content-center">
      <div class="col-xl-5">
        <div class="card">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-md-12 p-5">
                <div class="mx-auto mb-5 text-center">
                  <a routerLink="/">
                    <img :src="logo" alt height="54" />
                  </a>
                </div>

                <h6 class="h5 mb-0 mt-4 text-muted">Welcome back!</h6>
                <p class="text-muted mt-1 mb-4">
                  Enter your username and password to access admin
                  panel.
                </p>

                <b-alert
                  v-model="isAuthError"
                  variant="danger"
                  dismissible
                >{{ authError }}</b-alert
                >

                <b-form
                  class="authentication-form"
                  @submit.prevent="tryToLogIn"
                >
                  <div class="form-group">
                    <div class="input-group input-group-merge">
                      <div class="input-group-prepend bg-white">
                        <span class="input-group-text">
                          <i class="fa fa-user"></i>
                        </span>
                      </div>
                      <b-form-input
                        id="input-1"
                        v-model="username"
                        type="text"
                        required
                        placeholder="Enter username"
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="form-group mt-4">
                    <label class="form-control-label text-muted">Password</label>
                    <router-link
                      to="forget-password"
                      class="float-right text-unline-dashed ml-1"
                    >Forgot your password?</router-link
                    >
                    <div class="input-group input-group-merge">
                      <div class="input-group-prepend bg-white">
                        <span class="input-group-text">
                          <i class="fa fa-lock"></i>
                        </span>
                      </div>
                      <b-form-input
                        id="password"
                        v-model="password"
                        type="password"
                        required
                        placeholder="Enter your password"
                      ></b-form-input>
                    </div>
                  </div>
                  <b-form-group id="button-group" class="mt-4 mb-1">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="btn-block" @click="onSubmit"
                    >Log In</b-button
                    >
                  </b-form-group>
                </b-form>
                <div class="py-3 text-center">
                  <div class="row mt-3">
                    <div class="col-12 text-center">
                      <p class="text-muted-50">
                        Don't have an account?
                        <router-link
                          tag="a"
                          to=""
                          class="text-primary font-weight-bold ml-1"
                        >
                          <b>Contact Your Admin!</b>
                        </router-link>
                        <!-- <a
                          routerLink="/account/signup"
                          class="text-primary font-weight-bold ml-1"
                        >Sign Up</a>-->
                      </p>
                    </div>
                    <!-- end col -->
                  </div>
                  <!-- end row -->
                </div>
              </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </div>
</template>

<script>
import auth0 from 'auth0-js'
import constant from '../../../config/constant'
import logo from '../../../assets/images/logo.png'
import bgImage from '../../../assets/images/login/2.png'
import { mapGetters } from 'vuex'

export default {
  name: 'SignIn2',
  components: {  },
  data () {
    return {
      logo: logo,
      bgImageURL: bgImage
    }
  },
  mounted () {
    const loggedIn = localStorage.getItem('access_token')
    if (loggedIn !== undefined && loggedIn !== null) {
      this.$router.push({ name: 'mini.dashboard.home-2' })
    }
  },
  computed: {
    ...mapGetters({
      stateUsers: 'Setting/usersState'
    })
  },
  methods: {
    loginOAuth0: function () {
      new auth0.WebAuth(constant.auth0Config).authorize()
    },
    onSubmit () {
      this.login()
    },
    login () {
      this.$router.push({ name: 'dashboard.home' })
    }
  }
}
</script>
