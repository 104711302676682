var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "iq-card-body px-0 py-5"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "docter-details-block"
        }, [_c('div', {
          staticClass: "docter-profile text-center"
        }, [_c('b-img', {
          staticClass: "avatar-130 img-fluid",
          attrs: {
            "src": _vm._f("checkPhoto")(_vm.profile.userDetail.photo, require('@src/assets/images/user/01.jpg')),
            "alt": _vm.profile.userDetail.name
          }
        })], 1), _c('div', {
          staticClass: "text-center mt-3 pl-3 pr-3"
        }, [_c('h4', [_c('b', [_vm._v(_vm._s(_vm.profile.userDetail.name))])]), _c('p', [_vm._v(_vm._s(_vm.profile.role.roles))]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(_vm.profile.company.location) + " - " + _vm._s(_vm.profile.company.company))]), _c('p', {
          staticClass: "mb-0"
        }, [_c('b-badge', {
          staticClass: "ml-2",
          attrs: {
            "variant": _vm.profile.active ? 'success' : 'warning'
          }
        }, [_vm._v(_vm._s(_vm.profile.active ? 'Active' : 'Not Active'))])], 1)])])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    attrs: {
      "body-class": "iq-card-body"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-card-header d-flex justify-content-between"
        }, [_c('div', {
          staticClass: "iq-header-title"
        }, [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Personal Information")])])]), _c('div', {
          staticClass: "iq-card-body"
        }, [_c('div', {
          staticClass: "about-info m-0 p-0"
        }, [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-4"
        }, [_vm._v("Birthday:")]), _c('div', {
          staticClass: "col-8"
        }, [_vm._v(_vm._s(_vm._f("parseDate")(_vm.profile.userDetail.birthday, 'DD MMMM YYYY')))]), _c('div', {
          staticClass: "col-4"
        }, [_vm._v("Age:")]), _c('div', {
          staticClass: "col-8"
        }, [_vm._v(_vm._s(_vm.profile.userDetail.age))]), _c('div', {
          staticClass: "col-4"
        }, [_vm._v("Email:")]), _c('div', {
          staticClass: "col-8"
        }, [_c('a', {
          attrs: {
            "href": "mailto:".concat(_vm.profile.email)
          }
        }, [_vm._v(" " + _vm._s(_vm.profile.email) + " ")])]), _c('div', {
          staticClass: "col-4"
        }, [_vm._v("Phone:")]), _c('div', {
          staticClass: "col-8"
        }, [_c('a', {
          ref: "tel:".concat(_vm.profile.userDetail.phone)
        }, [_vm._v(" " + _vm._s(_vm.profile.userDetail.phone ? _vm.profile.userDetail.phone : '-'))])]), _c('div', {
          staticClass: "col-4"
        }, [_vm._v("Address:")]), _c('div', {
          staticClass: "col-8"
        }, [_vm._v(_vm._s(_vm.profile.userDetail.address ? _vm.profile.userDetail.address : '-'))]), _c('div', {
          staticClass: "col-4 mt-3"
        }, [_c('router-link', {
          attrs: {
            "to": "/me/update-profile"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "block": "",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "ri-edit-2-line"
        }), _vm._v("Edit")])], 1)], 1)])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Log History")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.profile.userLog.length > 0 ? _c('ul', {
          staticClass: "iq-timeline"
        }, _vm._l(_vm.profile.userLog, function (log, i) {
          return _c('li', {
            key: "log-".concat(i)
          }, [_c('div', {
            staticClass: "timeline-dots border-secondary"
          }), _c('h6', {}, [_vm._v(_vm._s(log.activity))]), _c('small', {
            staticClass: "mt-1"
          }, [_vm._v(_vm._s(log.date))])]);
        }), 0) : _c('p', [_vm._v("No log history found.")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Notifications")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.loading_notifications ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.notifications.length > 0 ? _c('ul', {
          staticClass: "iq-timeline"
        }, _vm._l(_vm.notifications, function (notif, i) {
          return _c('li', {
            key: "notif-".concat(i)
          }, [_c('div', {
            staticClass: "timeline-dots",
            class: notif.hasRead ? 'border-secondary' : 'border-warning'
          }), _c('h6', {}, [_vm._v(_vm._s(notif.title))]), _c('small', {
            staticClass: "mt-1"
          }, [_vm._v(_vm._s(notif.createAt))])]);
        }), 0) : _c('p', [_vm._v("There is no notification found.")])]];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "iq-card-body"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-card-header d-flex justify-content-between"
        }, [_c('div', {
          staticClass: "iq-header-title"
        }, [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Fleet Responsible")])])]), _c('div', {
          staticClass: "iq-card-body"
        }, [_vm.profile.vehicle.length > 0 ? _c('b-row', _vm._l(_vm.profile.vehicle, function (fleet, i) {
          return _c('b-col', {
            key: "fleet-".concat(i),
            staticClass: "text-center",
            attrs: {
              "md": "4"
            }
          }, [_c('label', [_c('img', {
            staticClass: "img-fluid",
            attrs: {
              "src": _vm._f("checkPhoto")(fleet.vehicleType.icon.url, require('@src/assets/images/Km3FO09.png')),
              "alt": fleet.name
            }
          })]), _c('small', [_c('strong', [_vm._v(_vm._s(fleet.name))])])]);
        }), 1) : _c('p', [_vm._v("No fleet found.")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }