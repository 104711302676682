var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "email-app-details",
    class: _vm.active ? 'show' : ''
  }, [_c('div', {
    staticClass: "iq-card"
  }, [_c('div', {
    staticClass: "iq-card-body p-0"
  }, [_c('div', {}, [_c('div', {
    staticClass: "iq-email-to-list p-3"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('ul', [_c('li', {
    staticClass: "mr-3"
  }, [_c('a', {
    attrs: {
      "href": "javascript: void(0);"
    },
    on: {
      "click": function click($event) {
        return _vm.closeDetail(_vm.item);
      }
    }
  }, [_vm._m(0)])]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "title": "Read"
    }
  }, [_vm._m(1)]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "title": "Info"
    }
  }, [_vm._m(2)]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "title": "Delete"
    }
  }, [_vm._m(3)]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "title": "Unread"
    }
  }, [_vm._m(4)]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "title": "Forward"
    }
  }, [_vm._m(5)]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "title": "Bookmark"
    }
  }, [_vm._m(6)])]), _c('div', {
    staticClass: "iq-email-search d-flex"
  }, [_c('ul', [_vm._m(7), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "title": "Previous"
    }
  }, [_vm._m(8)]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "title": "Next"
    }
  }, [_vm._m(9)])])])])]), _c('hr', {
    staticClass: "mt-0"
  }), _c('div', {
    staticClass: "iq-inbox-subject p-3"
  }, [_c('h5', {
    staticClass: "mt-0"
  }, [_vm._v("Your elite author Graphic Optimization reward is ready!")]), _c('div', {
    staticClass: "iq-inbox-subject-info"
  }, [_c('div', {
    staticClass: "iq-subject-info"
  }, [_c('img', {
    staticClass: "img-fluid rounded-circle",
    attrs: {
      "src": require('../../../assets/images/user/1.jpg'),
      "alt": "#"
    }
  }), _vm._m(10), _c('span', {
    staticClass: "float-right align-self-center"
  }, [_vm._v("Jan 15, 2029, 10:20AM")])]), _c('div', {
    staticClass: "iq-inbox-body mt-5"
  }, [_c('p', [_vm._v("Hi " + _vm._s(_vm.item.title) + ",")]), _c('p', [_vm._v("It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. ")]), _c('p', [_vm._v("Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).")]), _vm._m(11)]), _c('hr'), _vm._m(12)])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h4', {
    staticClass: "m-0"
  }, [_c('i', {
    staticClass: "ri-arrow-left-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-mail-open-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-information-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-delete-bin-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-mail-unread-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-folder-transfer-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-bookmark-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', {
    staticClass: "mr-3"
  }, [_c('a', {
    staticClass: "font-size-14",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("1 of 505")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-arrow-left-s-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-arrow-right-s-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "iq-subject-status align-self-center"
  }, [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v("Xray team "), _c('a', {
    attrs: {
      "href": "dummy@Xray.com"
    }
  }, [_vm._v("dummy@Xray.com")])]), _c('div', {
    staticClass: "dropdown"
  }, [_c('a', {
    staticClass: "dropdown-toggle",
    attrs: {
      "href": "#",
      "id": "dropdownMenuButton",
      "data-toggle": "dropdown",
      "aria-haspopup": "true",
      "aria-expanded": "false"
    }
  }, [_vm._v(" to Me ")]), _c('div', {
    staticClass: "dropdown-menu font-size-12",
    attrs: {
      "aria-labelledby": "dropdownMenuButton"
    }
  }, [_c('table', {
    staticClass: "iq-inbox-details"
  }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("from:")]), _c('td', [_vm._v("Medium Daily Digest")])]), _c('tr', [_c('td', [_vm._v("reply-to:")]), _c('td', [_vm._v("noreply@gmail.com")])]), _c('tr', [_c('td', [_vm._v("to:")]), _c('td', [_vm._v("iqonicdesigns@gmail.com")])]), _c('tr', [_c('td', [_vm._v("date:")]), _c('td', [_vm._v("13 Dec 2019, 08:30")])]), _c('tr', [_c('td', [_vm._v("subject:")]), _c('td', [_vm._v("The Golden Rule")])]), _c('tr', [_c('td', [_vm._v("security:")]), _c('td', [_vm._v("Standard encryption")])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "mt-5 mb-0"
  }, [_vm._v("Regards,"), _c('span', {
    staticClass: "d-inline-block w-100"
  }, [_vm._v("John Deo")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "attegement"
  }, [_c('h6', {
    staticClass: "mb-2"
  }, [_vm._v("ATTACHED FILES:")]), _c('ul', [_c('li', {
    staticClass: "icon icon-attegment"
  }, [_c('i', {
    staticClass: "fa fa-file-text-o",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "ml-1"
  }, [_vm._v("mydoc.doc")])]), _c('li', {
    staticClass: "icon icon-attegment"
  }, [_c('i', {
    staticClass: "fa fa-file-text-o",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "ml-1"
  }, [_vm._v("mydoc.pdf")])])])]);

}]

export { render, staticRenderFns }