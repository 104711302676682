var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal---".concat(_vm.id),
      "size": "xl",
      "title": "Set Docking Schedule",
      "header-bg-variant": "light",
      "header-text-variant": "dark",
      "hide-footer": "",
      "footer-bg-variant": "light",
      "footer-text-variant": "dark"
    },
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit();
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fleet *",
      "label-for": "fleet"
    }
  }, [_c('v-select', {
    attrs: {
      "required": "",
      "label": "name",
      "options": _vm.fleets,
      "reduce": function reduce(fleet) {
        return fleet.id;
      }
    },
    model: {
      value: _vm.form.vehicleId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "vehicleId", $$v);
      },
      expression: "form.vehicleId"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Last Docking Special Survey",
      "label-for": "last_docking"
    }
  }, [_c('date-picker', {
    attrs: {
      "type": "date",
      "value-type": "DD-MM-YYYY",
      "format": "DD MMM YYYY",
      "placeholder": "Select date"
    },
    model: {
      value: _vm.form.lastDocking,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "lastDocking", $$v);
      },
      expression: "form.lastDocking"
    }
  })], 1)], 1)], 1), _vm._l(_vm.form.dockingType, function (type, index) {
    return _c('b-row', {
      key: index
    }, [_c('b-col', {
      attrs: {
        "md": "3"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": "Type Dock",
        "label-for": "type_dock"
      }
    }, [_c('b-form-input', {
      attrs: {
        "type": "text",
        "readonly": "",
        "placeholder": type.type === 'INTERMEDIATE' ? 'Intermediate Survey' : 'Special Survey'
      }
    })], 1)], 1), _c('b-col', [_c('b-form-group', {
      attrs: {
        "label": "Interval",
        "label-for": "interval"
      }
    }, [_c('div', {
      staticClass: "input-group"
    }, [_c('b-form-input', {
      attrs: {
        "type": "number",
        "required": ""
      },
      model: {
        value: type.interval,
        callback: function callback($$v) {
          _vm.$set(type, "interval", $$v);
        },
        expression: "type.interval"
      }
    }), _c('div', {
      staticClass: "input-group-prepend"
    }, [_c('span', {
      staticClass: "input-group-text"
    }, [_vm._v("Month")])])], 1)])], 1), _c('b-col', [_c('b-form-group', {
      attrs: {
        "label": "Start Schedule",
        "label-for": "start_schedule"
      }
    }, [_c('div', {
      staticClass: "input-group"
    }, [_c('b-form-input', {
      attrs: {
        "type": "number",
        "required": ""
      },
      model: {
        value: type.startSchedule,
        callback: function callback($$v) {
          _vm.$set(type, "startSchedule", $$v);
        },
        expression: "type.startSchedule"
      }
    }), _c('div', {
      staticClass: "input-group-prepend"
    }, [_c('span', {
      staticClass: "input-group-text"
    }, [_vm._v("Month")])])], 1)])], 1), _c('b-col', [_c('b-form-group', {
      attrs: {
        "label": "End Schedule",
        "label-for": "end_schedule"
      }
    }, [_c('div', {
      staticClass: "input-group"
    }, [_c('b-form-input', {
      attrs: {
        "type": "number"
      },
      model: {
        value: type.endSchedule,
        callback: function callback($$v) {
          _vm.$set(type, "endSchedule", $$v);
        },
        expression: "type.endSchedule"
      }
    }), _c('div', {
      staticClass: "input-group-prepend"
    }, [_c('span', {
      staticClass: "input-group-text"
    }, [_vm._v("Month")])])], 1)])], 1)], 1);
  }), _c('b-row', [_c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "iq-bg-danger text-right",
    attrs: {
      "variant": "none",
      "disabled": _vm.form.saving
    },
    on: {
      "click": function click($event) {
        return _vm.cancel();
      }
    }
  }, [_vm._v("Cancel")]), _c('b-button', {
    staticClass: "ml-2 text-right",
    attrs: {
      "type": "submit",
      "disabled": _vm.form.saving,
      "variant": "primary"
    }
  }, [_vm._v("Save ")])], 1)], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }