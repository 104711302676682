var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("REPORT - CASH FLOW")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-dropdown', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Export Data',
            expression: "'Export Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "link iq-bg-primary",
            "toggle-class": "text-decoration-none",
            "no-caret": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-save m-0"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _vm._v("Export Excel")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf mr-2"
        }), _vm._v("Export PDF")])], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "text-center",
          attrs: {
            "md": "12"
          }
        }, [_c('h4', [_vm._v("PT. CAHAYA MARITIM")]), _c('h3', [_vm._v("CASH FLOW (INDIRECT)")]), _c('p', [_vm._v("Form Periode January - December 2017")])]), _c('b-col', {
          staticClass: "pl-5 pr-5",
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-borderless table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "pl-5",
          attrs: {
            "width": "80%"
          }
        }, [_vm._v("DESCRIPTION")]), _c('th', {
          staticClass: "text-right pr-5",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("AMOUNT")])])]), _c('tbody', [_c('tr', [_c('th', {
          staticClass: "pl-5",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("Operational Activity")])]), _c('tr', [_c('td', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Profit/Loss")]), _c('td', {
          staticClass: "text-right pr-5"
        }, [_vm._v("0")])]), _c('tr', [_c('td', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Add Accumulated Depreciation")]), _c('td', {
          staticClass: "text-right pr-5"
        }, [_vm._v("77.160.000")])]), _c('tr', [_c('td', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Add Payable")]), _c('td', {
          staticClass: "text-right pr-5"
        }, [_vm._v("30.495.000")])]), _c('tr', [_c('td', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Add Other Current Liability")]), _c('td', {
          staticClass: "text-right pr-5"
        }, [_vm._v("12.500.000")])]), _c('tr', [_c('td', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Less Receivable")]), _c('td', {
          staticClass: "text-right pr-5"
        }, [_vm._v("-30.695.000")])]), _c('tr', [_c('td', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Less Inventory")]), _c('td', {
          staticClass: "text-right pr-5"
        }, [_vm._v("-19.410.000")])]), _c('tr', [_c('td', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Less Other Current Asset")]), _c('td', {
          staticClass: "text-right pr-5",
          staticStyle: {
            "border-bottom": "solid 1px gray"
          }
        }, [_vm._v("-48.000.000")])]), _c('tr', [_c('th', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Total Operational Activity")]), _c('th', {
          staticClass: "text-right pr-5"
        }, [_vm._v("22.050.000")])]), _c('tr', [_c('th', {
          staticClass: "pl-5",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("Investment")])]), _c('tr', [_c('td', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Less Fixed Asset")]), _c('td', {
          staticClass: "text-right pr-5"
        }, [_vm._v("0")])]), _c('tr', [_c('td', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Less Other Asset")]), _c('td', {
          staticClass: "text-right pr-5",
          staticStyle: {
            "border-bottom": "solid 1px gray"
          }
        }, [_vm._v("0")])]), _c('tr', [_c('th', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Total Investment")]), _c('th', {
          staticClass: "text-right pr-5"
        }, [_vm._v("0")])]), _c('tr', [_c('th', {
          staticClass: "pl-5",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("Funding")])]), _c('tr', [_c('td', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Add Long Term Liability")]), _c('td', {
          staticClass: "text-right pr-5"
        }, [_vm._v("185.000.000 ")])]), _c('tr', [_c('td', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Add Equity")]), _c('td', {
          staticClass: "text-right pr-5",
          staticStyle: {
            "border-bottom": "solid 1px gray"
          }
        }, [_vm._v("302.056.000")])]), _c('tr', [_c('th', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Total Funding")]), _c('th', {
          staticClass: "text-right pr-5",
          staticStyle: {
            "border-bottom": "solid 1px gray"
          }
        }, [_vm._v("487.056.000")])]), _c('tr', [_c('th', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Total of Current cash flow (used) in this period")]), _c('th', {
          staticClass: "text-right pr-5",
          staticStyle: {
            "border-bottom": "solid 1px gray"
          }
        }, [_vm._v("509.106.000")])]), _c('tr', [_c('th', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Cash & Cash equivalent on Opening period")]), _c('th', {
          staticClass: "text-right pr-5",
          staticStyle: {
            "border-bottom": "solid 1px gray"
          }
        }, [_vm._v("0")])]), _c('tr', [_c('th', {
          staticStyle: {
            "padding-left": "80px"
          }
        }, [_vm._v("Cash & Cash equivalent on End period")]), _c('th', {
          staticClass: "text-right pr-5"
        }, [_vm._v("509.106.000")])])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('hr')])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }