<template>
  <b-container fluid>
    <b-row>
      <b-col lg="4">
        <iq-card class-name="iq-user-profile-block1">
          <template v-slot:body>
            <div class="user-details-block">
              <div class="user-profile text-center">
                <img
                  :src="
                    form?.personal_data?.photo ??
                    '../../assets/images/user/11.png'
                  "
                  alt="profile-img"
                  class="avatar-130 img-fluid"
                />
              </div>
              <div class="text-center mt-3">
                <h4
                  ><b>{{ form?.personal_data?.full_name ?? '-' }}</b></h4
                >
                <p>
                  {{ countAge }} Tahun,
                  {{ form?.personal_data?.place_of_birth ?? '-' }} <br />
                  <b-badge variant="primary"
                    >Apply Date, {{ form?.apply_date ?? '-' }}</b-badge
                  >
                </p>
              </div>
            </div>
            <table class="table table-striped">
              <tbody>
                <tr class="bg-info">
                  <td colspan="2" class="text-center"
                    ><strong>DATA DIRI</strong></td
                  >
                </tr>
                <tr>
                  <td><strong>Tempat/Tanggal Lahir</strong></td>
                  <td
                    >{{ form?.personal_data?.place_of_birth ?? '-' }} /
                    {{ form?.personal_data?.date_of_birth ?? '-' }}</td
                  >
                </tr>
                <tr>
                  <td><strong>Jabatan/Ijazah</strong></td>
                  <td>{{ form?.personal_data?.position?.name ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>No KTP</strong></td>
                  <td>{{ form?.personal_data?.nik_ktp ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>Seaferer Code</strong></td>
                  <td>{{ form?.personal_data?.seaferer_code ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>No NPWP</strong></td>
                  <td>{{ form?.personal_data?.npwp_number ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>Status</strong></td>
                  <td>{{
                    form?.personal_data?.marital_status?.name ?? '-'
                  }}</td>
                </tr>
                <tr>
                  <td><strong>Anak Sendiri</strong></td>
                  <td
                    >{{
                      form?.personal_data?.number_of_children ?? '-'
                    }}
                    Orang</td
                  >
                </tr>
                <tr>
                  <td><strong>Golongan Darah</strong></td>
                  <td>{{ form?.personal_data?.blood_type?.alias ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>Agama</strong></td>
                  <td>{{ form?.personal_data?.religion?.name ?? '-' }}</td>
                </tr>
                <tr>
                  <td colspan="2"><strong>Alamat KTP</strong></td>
                </tr>
                <tr>
                  <td colspan="2">
                    {{ form?.alamat_ktp?.[0]?.alamat ?? '-' }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2"><strong>Alamat Domisili</strong></td>
                </tr>
                <tr>
                  <td colspan="2">
                    {{ form?.alamat_domisili?.[0]?.alamat ?? '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:body>
            <table class="table table-striped">
              <tbody>
                <tr class="bg-info">
                  <td colspan="2" class="text-center"
                    ><strong>EMERGENCY CALL</strong></td
                  >
                </tr>
                <tr>
                  <td><strong>Nama Lengkap</strong></td>
                  <td>{{
                    form?.personal_data?.family_emergency_name ?? '-'
                  }}</td>
                </tr>
                <tr>
                  <td><strong>No. Tlp/HP</strong></td>
                  <td>{{ form?.personal_data?.emergency_number ?? '-' }}</td>
                </tr>
                <tr>
                  <td><strong>Hubungan</strong></td>
                  <td>{{
                    form?.personal_data?.family_emergency_status ?? '-'
                  }}</td>
                </tr>
                <tr>
                  <td><strong>Alamat</strong></td>
                  <td colspan="2">
                    {{ form?.personal_data?.family_emergency_address ?? '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:body>
            <table class="table table-striped">
              <tbody>
                <tr class="bg-info">
                  <td colspan="2" class="text-center"
                    ><strong>SUPPORT DOCUMENT</strong></td
                  >
                </tr>
                <tr
                  v-for="(state, index) in form?.support_document"
                  v-bind:key="index"
                >
                  <td width="90%"
                    ><strong>{{ state.document_list.name }}</strong></td
                  >
                  <td width="10%" class="text-center"
                    ><a href="" v-if="state.file"
                      ><i class="fas fa-download mr-3 text-primary"></i></a
                  ></td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="8">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">PENDIDIKAN</h4>
          </template>
          <template v-slot:body>
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th class="align-middle text-center">Sekolah/Universitas</th>
                  <th class="align-middle text-center">Jenjang</th>
                  <th class="align-middle text-center">IPK/Nilai Akhir</th>
                  <th class="align-middle text-center">Tahun Mulai</th>
                  <th class="align-middle text-center">Tahun Selesai</th>
                  <th class="align-middle text-center">Kota/Negara</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(state, index) in form?.pendidikan"
                  v-bind:key="index"
                >
                  <td>{{ state?.sekolah ?? '-' }}</td>
                  <td class="text-center">{{ state?.jenjang ?? '-' }}</td>
                  <td class="text-center">{{ state?.nilai ?? '-' }}</td>
                  <td class="text-center">{{ state?.tahun_mulai ?? '-' }}</td>
                  <td class="text-center">{{ state?.tahun_selesai ?? '-' }}</td>
                  <td class="text-center"
                    >{{ state?.kota }} / {{ state?.negara }}</td
                  >
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">DATA KELUARGA</h4>
          </template>
          <template v-slot:body>
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th class="text-center">No</th>
                  <th class="text-center">Nama Lengkap</th>
                  <th class="text-center">Hubungan</th>
                  <th class="text-center">Jenis Kelamin</th>
                  <th class="text-center">Umur</th>
                  <th class="text-center">Pekerjaan</th>
                  <th class="text-center">No.Hp</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(state, index) in form?.family" v-bind:key="index">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center">{{ state.nama_lengkap }}</td>
                  <td class="text-center">{{ state.status }}</td>
                  <td class="text-center">{{ state.jenis_kelamin }}</td>
                  <td class="text-center">{{ state.age_family }}</td>
                  <td class="text-center">{{ state.pekerjaan }}</td>
                  <td class="text-center">{{ state.no_hp }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">TRAVEL DOCUMENT</h4>
          </template>
          <template v-slot:body>
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th class="text-center">No</th>
                  <th class="text-center">Nama Document</th>
                  <th class="text-center">No Dokument</th>
                  <th class="text-center">Place & Date Issued</th>
                  <th class="text-center">Masa Berlaku</th>
                  <th class="text-center">File</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(state, index) in form?.travel_document"
                  v-bind:key="index"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center">{{ state.document_name }}</td>
                  <td class="text-center">{{ state.document_number }}</td>
                  <td class="text-center">{{
                    state.date_issued + ', ' + state.place_issued
                  }}</td>
                  <td class="text-center">{{ state.validity_period }}</td>
                  <td class="text-center" v-if="state.file"
                    ><a :href="state?.file" download
                      ><i class="fa fa-file-alt text-primary"></i></a
                  ></td>
                  <td class="text-center" v-else>{{ '-' }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">CERTIFICATE OF COMPETENCY</h4>
          </template>
          <template v-slot:body>
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th class="text-center">No</th>
                  <th class="text-center">Ijazah & Endorse</th>
                  <th class="text-center">Nomor Sertifikat</th>
                  <th class="text-center">Place & Date Issued</th>
                  <th class="text-center">Masa Berlaku</th>
                  <th class="text-center">File</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(state, index) in form?.certificate_competency"
                  v-bind:key="index"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center">{{ state.document_name }}</td>
                  <td class="text-center">{{ state.document_number }}</td>
                  <td class="text-center">{{
                    state.date_issued + ', ' + state.place_issued
                  }}</td>
                  <td class="text-center">{{ state.validity_period }}</td>
                  <td class="text-center" v-if="state.file"
                    ><a :href="state?.file" download
                      ><i class="fa fa-file-alt text-primary"></i></a
                  ></td>
                  <td class="text-center" v-else>{{ '-' }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">CERTIFICATE OF PROFICIENCY</h4>
          </template>
          <template v-slot:body>
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th class="text-center">No</th>
                  <th class="text-center">Nama Sertifikat</th>
                  <th class="text-center">Nomor Sertifikat</th>
                  <th class="text-center">Place & Date Issued</th>
                  <th class="text-center">Masa Berlaku</th>
                  <th class="text-center">File</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(state, index) in form?.certificate_proficiency"
                  v-bind:key="index"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center">{{ state.document_name }}</td>
                  <td class="text-center">{{ state.document_number }}</td>
                  <td class="text-center">{{
                    state.date_issued + ', ' + state.place_issued
                  }}</td>
                  <td class="text-center">{{ state.validity_period }}</td>
                  <td class="text-center" v-if="state.file"
                    ><a :href="state?.file" download
                      ><i class="fa fa-file-alt text-primary"></i></a
                  ></td>
                  <td class="text-center" v-else>{{ '-' }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"
              >PENGALAMAN BERLAYAR, MINIMAL 5 PENGALAMAN TERAKHIR</h4
            >
          </template>
          <template v-slot:body>
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th class="text-center">No</th>
                  <th class="text-center">Nama Kapal</th>
                  <th class="text-center">Pemilik</th>
                  <th class="text-center">Bendera</th>
                  <th class="text-center">Kawasan</th>
                  <th class="text-center">Jabatan</th>
                  <th class="text-center">No.Tlp Perusahaan</th>
                  <th class="text-center">Tanggal Bergabung</th>
                  <th class="text-center">Tanggal Berhenti</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(state, index) in form?.sail_experience"
                  v-bind:key="index"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center">{{ state.ship_name }}</td>
                  <td class="text-center">{{ state.owner }}</td>
                  <td class="text-center">{{ state.flag }}</td>
                  <td class="text-center">{{ state.area }}</td>
                  <td class="text-center">{{ state.position_id }}</td>
                  <td class="text-center">{{ state.no_number_company }}</td>
                  <td class="text-center">{{ state.date_join }}</td>
                  <td class="text-center">{{ state.date_end }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>

        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">ORGANISASI</h4>
          </template>
          <template v-slot:body>
            <table class="table table-striped mb-0">
              <thead>
                <tr>
                  <th class="text-center">Nama Organisasi</th>
                  <th class="text-center">Jabatan</th>
                  <th class="text-center">Tanggal Bergabung</th>
                  <th class="text-center">Tanggal Selesai</th>
                  <th class="text-center">Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(state, index) in form?.organisasi"
                  v-bind:key="index"
                >
                  <td>{{ state?.nama_organisasi }}</td>
                  <td class="text-center"
                    >{{ state?.posisi }} <br /><b-badge variant="primary">{{
                      state?.tempat
                    }}</b-badge></td
                  >
                  <td class="text-center">{{ state?.durasi_awal }}</td>
                  <td class="text-center">{{ state?.durasi_akhir }}</td>
                  <td class="text-center">-</td>
                </tr>
              </tbody>
            </table>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { crewingActions } from '@src/Utils/helper'
import moment from 'moment'

export default {
  name: 'ProfileCrew',
  data() {
    return {
      loading: false,
      form: null,
    }
  },
  async mounted() {
    xray.index()
    await this.getProfileUser()
  },
  computed: {
    countAge() {
      let userAge = moment().diff(
        new Date(this?.form?.personal_data?.date_of_birth),
        'years'
      )
      return userAge
    },
  },
  methods: {
    ...crewingActions,
    async getProfileUser() {
      console.log(this.$route.params.id)
      if (this.$route.params.id) {
        let method = 'getProfileCandidate'

        const { status, data } = await this[method](this.$route.params.id)

        if (status !== 'success') {
          this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
          return
        }

        this.form = data

        console.log(this.form, 'THIS IS FORM USER')
      }
    },
  },
}
</script>
