var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Master Goods Category")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.add-master-goods",
            modifiers: {
              "add-master-goods": true
            }
          }],
          staticClass: "mt-1",
          attrs: {
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add Data")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "mb-3 mt-4",
          attrs: {
            "md": "12"
          }
        }, [_c('b-collapse', {
          attrs: {
            "id": "add-master-goods"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Goods Code"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Goods Code..."
          },
          model: {
            value: _vm.form.code,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "code", $$v);
            },
            expression: "form.code"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category",
            "label-for": "serial"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.category,
            "reduce": function reduce(type) {
              return type.value;
            },
            "placeholder": "select category"
          },
          model: {
            value: _vm.form.category,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "category", $$v);
            },
            expression: "form.category"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "serial"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.status,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status", $$v);
            },
            expression: "form.status"
          }
        }, [_vm.form.status === true || _vm.form.status === 'true' ? [_vm._v("Yes")] : [_vm._v("No")]], 2)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-button', {
          staticClass: "mt-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_vm._v("Add")])], 1)], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('hr'), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Code")]), _c('th', [_vm._v("Category")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Action")])])])])]), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "12"
          }
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.rows,
            "align": "center"
          },
          model: {
            value: _vm.currentPage,
            callback: function callback($$v) {
              _vm.currentPage = $$v;
            },
            expression: "currentPage"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Master Goods Units")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.add-master-units",
            modifiers: {
              "add-master-units": true
            }
          }],
          staticClass: "mt-1",
          attrs: {
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add Data")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "mb-3 mt-4",
          attrs: {
            "md": "12"
          }
        }, [_c('b-collapse', {
          attrs: {
            "id": "add-master-units"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Unit Name"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Unit Name..."
          },
          model: {
            value: _vm.form.code,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "code", $$v);
            },
            expression: "form.code"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "serial"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.status,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status", $$v);
            },
            expression: "form.status"
          }
        }, [_vm.form.status === true || _vm.form.status === 'true' ? [_vm._v("Yes")] : [_vm._v("No")]], 2)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-button', {
          staticClass: "mt-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_vm._v("Add")])], 1)], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Units")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Action")])])])])]), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "12"
          }
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.rows,
            "align": "center"
          },
          model: {
            value: _vm.currentPage,
            callback: function callback($$v) {
              _vm.currentPage = $$v;
            },
            expression: "currentPage"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }