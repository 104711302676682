var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Add Stock Opname")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "text-right mb-5",
          attrs: {
            "md": "12"
          }
        }, [_c('span', {
          staticClass: "mr-2"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-save"
        }), _vm._v(" Save ")])], 1), _c('span', [_c('b-button', {
          attrs: {
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-times"
        }), _vm._v(" Cancel ")])], 1)])], 1), _c('b-row', {
          staticClass: "mb-3 p-4 bg-transparent iq-bg-primary"
        }, [_c('b-col', {
          attrs: {
            "md": "1"
          }
        }), _c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Name *",
            "label-for": "dol"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Fleet *",
            "label-for": "dol"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Search or add a fleet",
            "label": "name",
            "track-by": "code",
            "options": _vm.fleetEquipments
          },
          model: {
            value: _vm.fleetSelected,
            callback: function callback($$v) {
              _vm.fleetSelected = $$v;
            },
            expression: "fleetSelected"
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Status *",
            "label-for": "dol"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.statusGoods,
            "reduce": function reduce(type) {
              return type.value;
            },
            "placeholder": "select category"
          },
          model: {
            value: _vm.statusSelected,
            callback: function callback($$v) {
              _vm.statusSelected = $$v;
            },
            expression: "statusSelected"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Date *",
            "label-for": "dol"
          }
        }, [_c('date-picker', {
          attrs: {
            "type": "date",
            "placeholder": "Select Date",
            "format": "DD-MM-YYYY"
          },
          model: {
            value: _vm.historiesParamsDate,
            callback: function callback($$v) {
              _vm.historiesParamsDate = $$v;
            },
            expression: "historiesParamsDate"
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Notes",
            "label-for": "dol"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "2"
          }
        }), _c('small', {
          staticStyle: {
            "float": "left"
          }
        }, [_c('i', {
          staticClass: "fa fa-warning"
        }), _vm._v(" Max 500 Character")])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        })], 1), _c('b-row', [_c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "12"
          }
        }, [_c('hr'), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "3%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Goods")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Code of Goods")]), _c('th', [_vm._v("Stock in System")]), _c('th', [_vm._v("Physical Stock Different")])])]), _c('tr', [_c('td'), _c('td', [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.categoryGoods,
            "reduce": function reduce(type) {
              return type.value;
            },
            "placeholder": "select category"
          },
          model: {
            value: _vm.goodsSelected,
            callback: function callback($$v) {
              _vm.goodsSelected = $$v;
            },
            expression: "goodsSelected"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "search..."
          }
        })], 1), _c('td'), _c('td'), _c('td')]), _c('tr', [_c('td', [_vm._v("1")]), _c('td', [_vm._v("Pyston")]), _c('td', [_vm._v("PS-092")]), _c('td', [_vm._v("20")]), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Stock Different"
          }
        })], 1)])])])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }