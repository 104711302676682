var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "wrapper"
  }, [_c('SideBarStyle1', {
    attrs: {
      "items": _vm.profile.menu,
      "horizontal": _vm.horizontal,
      "logo": _vm.logo
    },
    on: {
      "toggle": _vm.sidebarMini
    }
  }), _c('div', {
    staticClass: "content-page",
    class: _vm.horizontal ? 'ml-0' : '',
    attrs: {
      "id": "content-page"
    }
  }, [_c('NavBarStyle1', {
    attrs: {
      "title": "Dashboard",
      "homeURL": {
        name: 'dashboard1.home'
      },
      "logo": _vm.logo,
      "horizontal": _vm.horizontal,
      "items": _vm.horizontalMenu
    },
    on: {
      "toggle": _vm.sidebarMini
    }
  }, [_c('template', {
    slot: "responsiveRight"
  }, [_c('ul', {
    staticClass: "navbar-nav ml-auto navbar-list"
  }, [_c('li', {
    staticClass: "nav-item iq-full-screen"
  }, [_c('a', {
    staticClass: "iq-waves-effect",
    attrs: {
      "href": "#",
      "id": "btnFullscreen"
    }
  }, [_c('i', {
    staticClass: "ri-fullscreen-line"
  })])]), _vm.notifications.length === 0 ? _c('li', {
    staticClass: "nav-item"
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "href": "#",
      "title": "There is no notification yet."
    }
  }, [_c('i', {
    staticClass: "ri-notification-3-fill"
  })])]) : _c('li', {
    staticClass: "nav-item"
  }, [_c('a', {
    staticClass: "search-toggle iq-waves-effect",
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-notification-3-fill"
  }), _vm.notifications.length > 0 ? _c('span', {
    staticClass: "bg-danger dots"
  }) : _vm._e()]), _c('div', {
    staticClass: "iq-sub-dropdown"
  }, [_c('div', {
    staticClass: "iq-card shadow-none m-0"
  }, [_c('div', {
    staticClass: "iq-card-body p-0"
  }, [_c('div', {
    staticClass: "bg-primary p-3"
  }, [_c('h5', {
    staticClass: "mb-0 text-white"
  }, [_vm._v(_vm._s(_vm.$t('nav.allNotifications'))), _c('small', {
    staticClass: "badge badge-light float-right pt-1"
  }, [_vm._v(_vm._s(_vm.notifications.length))])])]), _vm._l(_vm.notifications, function (item, index) {
    return [_c('div', {
      key: index,
      staticClass: "iq-sub-card"
    }, [_c('div', {
      staticClass: "media align-items-center"
    }, [_c('div', {
      staticClass: "media-body ml-3"
    }, [_c('div', {
      staticClass: "d-flex align-item-center justify-content-between"
    }, [_c('h6', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(item.title))]), _c('small', {
      staticClass: "float-right font-size-12"
    }, [_vm._v(_vm._s(item.createAt))])]), _c('div', {
      staticClass: "d-flex align-item-center justify-content-between"
    }, [item.message.length >= 40 ? _c('p', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(item.message.substring(0, 40) + '...'))]) : _c('p', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(item.message))]), _c('a', {
      staticClass: "font-size-12",
      attrs: {
        "href": ""
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.doReadNotification(item.id);
        }
      }
    }, [_vm._v("Mark as read")])])])])])];
  })], 2)])])])])]), _c('template', {
    slot: "right"
  }, [_c('ul', {
    staticClass: "navbar-list"
  }, [_c('li', [_c('a', {
    staticClass: "search-toggle iq-waves-effect d-flex align-items-center",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "img-fluid rounded mr-3",
    attrs: {
      "src": _vm._f("checkPhoto")(_vm.profile.userDetail.photo, require('@src/assets/images/user/01.jpg')),
      "alt": _vm.profile.userDetail.name
    }
  }), _c('div', {
    staticClass: "caption"
  }, [_c('h6', {
    staticClass: "mb-0 line-height text-white"
  }, [_vm._v(_vm._s(_vm.profile.userDetail.name))]), _c('span', {
    staticClass: "font-size-12"
  }, [_vm._v(_vm._s(_vm.profile.role.roles))])])]), _c('div', {
    staticClass: "iq-sub-dropdown iq-dropdown"
  }, [_c('div', {
    staticClass: "iq-card shadow-none bg-light m-0"
  }, [_c('div', {
    staticClass: "iq-card-body p-0"
  }, [_c('div', {
    staticClass: "bg-dark p-3"
  }, [_c('h5', {
    staticClass: "mb-0 text-white line-height"
  }, [_vm._v("Hello " + _vm._s(_vm.profile.userDetail.name))]), _c('span', {
    staticClass: "text-white font-size-12"
  }, [_vm._v(_vm._s(_vm.$t('nav.user.available')))])]), _c('router-link', {
    staticClass: "iq-sub-card iq-bg-primary-hover",
    attrs: {
      "to": "/me/my-profile"
    }
  }, [_c('div', {
    staticClass: "media align-items-center"
  }, [_c('div', {
    staticClass: "rounded iq-card-icon iq-bg-primary"
  }, [_c('i', {
    staticClass: "ri-file-line"
  })]), _c('div', {
    staticClass: "media-body ml-3"
  }, [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t('nav.user.profileTitle')))]), _c('p', {
    staticClass: "mb-0 font-size-12"
  }, [_vm._v(_vm._s(_vm.$t('nav.user.profileSub')))])])])]), _c('router-link', {
    staticClass: "iq-sub-card iq-bg-primary-success-hover",
    attrs: {
      "to": "/me/update-profile"
    }
  }, [_c('div', {
    staticClass: "media align-items-center"
  }, [_c('div', {
    staticClass: "rounded iq-card-icon iq-bg-success"
  }, [_c('i', {
    staticClass: "ri-profile-line"
  })]), _c('div', {
    staticClass: "media-body ml-3"
  }, [_c('h6', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t('nav.user.profileEditTitle')))]), _c('p', {
    staticClass: "mb-0 font-size-12"
  }, [_vm._v(_vm._s(_vm.$t('nav.user.profileEditSub')))])])])]), _c('div', {
    staticClass: "d-inline-block w-100 text-center p-3"
  }, [_c('a', {
    staticClass: "iq-bg-danger iq-sign-btn",
    attrs: {
      "href": "javascript:void(0)",
      "role": "button"
    },
    on: {
      "click": _vm.doLogout
    }
  }, [_vm._v(_vm._s(_vm.$t('nav.user.signout'))), _c('i', {
    staticClass: "ri-login-box-line ml-2"
  })])])], 1)])])])])])], 2), _c('transition', {
    attrs: {
      "name": "router-anim",
      "enter-active-class": "animated ".concat(_vm.animated.enter),
      "mode": "out-in",
      "leave-active-class": "animated ".concat(_vm.animated.exit)
    }
  }, [_c('router-view')], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }