var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("State")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _vm._l(_vm.state, function (item, index) {
          return [_c('b-form-checkbox', {
            key: index,
            attrs: {
              "disabled": item.disabled,
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            model: {
              value: item.checked,
              callback: function callback($$v) {
                _vm.$set(item, "checked", $$v);
              },
              expression: "item.checked"
            }
          }, [_vm._v(" " + _vm._s(item.label) + " ")])];
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Text")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _vm._l(_vm.text, function (item, index) {
          return [_c('div', {
            key: index,
            staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
          }, [_c('div', {
            staticClass: "custom-switch-inner"
          }, [_c('p', {
            staticClass: "mb-0"
          }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: item.checked,
              expression: "item.checked"
            }],
            staticClass: "custom-control-input",
            class: 'bg-' + item.class,
            attrs: {
              "type": "checkbox",
              "id": 'customSwitch-11' + index
            },
            domProps: {
              "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
            },
            on: {
              "change": function change($event) {
                var $$a = item.checked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(item, "checked", $$c);
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": 'customSwitch-11' + index,
              "data-on-label": item.activeLabel,
              "data-off-label": item.inactiveLabel
            }
          })])])];
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Color")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _vm._l(_vm.color, function (item, index) {
          return [_c('b-form-checkbox', {
            key: index,
            staticClass: "custom-switch-color",
            attrs: {
              "color": item.color,
              "disabled": item.disabled,
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            model: {
              value: item.checked,
              callback: function callback($$v) {
                _vm.$set(item, "checked", $$v);
              },
              expression: "item.checked"
            }
          }, [_vm._v(" " + _vm._s(item.label) + " ")])];
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Icon")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vulputate, ex ac venenatis mollis, diam nibh finibus leo")]), _vm._l(_vm.icon, function (item, index) {
          return [_c('div', {
            key: index,
            staticClass: "custom-control custom-switch custom-switch-icon custom-switch-color custom-control-inline"
          }, [_c('div', {
            staticClass: "custom-switch-inner"
          }, [_c('p', {
            staticClass: "mb-0"
          }, [_vm._v(" Primary ")]), _c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: item.checked,
              expression: "item.checked"
            }],
            staticClass: "custom-control-input",
            class: "bg-".concat(item.color),
            attrs: {
              "type": "checkbox",
              "id": "customSwitch-1-".concat(index)
            },
            domProps: {
              "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
            },
            on: {
              "change": function change($event) {
                var $$a = item.checked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(item, "checked", $$c);
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": "customSwitch-1-".concat(index)
            }
          }, [_c('span', {
            staticClass: "switch-icon-left",
            domProps: {
              "innerHTML": _vm._s(item.activeLabel)
            }
          }), _c('span', {
            staticClass: "switch-icon-right",
            domProps: {
              "innerHTML": _vm._s(item.inactiveLabel)
            }
          })])])])];
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }