<template>
  <div class="summary-management-detail-accident-list">
    <b-row>
      <b-col md="4">
        <b-input v-model="keyword" placeholder="Search..."></b-input>
      </b-col>
      <b-col md="12" class="mt-2 m-auto">
        <iq-card
          class-name="iq-card-block iq-card-stretch iq-card-height mt-3"
          body-class=""
        >
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>Detail Accident</strong>
            </h4>
          </template>
          <template v-slot:headerAction>
            <a
              class="text-primary float-right"
              v-b-toggle.collapse-detail-accident
              role="button"
              style="cursor: pointer"
            >
              <i class="fa fa-filter" />
            </a>
          </template>
          <template v-slot:body>
            <b-collapse id="collapse-detail-accident" class="mb-2" visible>
              <table class="table table-striped mt-3">
                <thead>
                <tr>
                  <th width="20%" class="text-center">No.</th>
                  <th width="20%" class="text-center">Name of Vessel</th>
                  <th width="20%" class="text-center">Type</th>
                  <th width="20%" class="text-center">Type of Coverage</th>
                  <th width="20%" class="text-center">Type of Loss</th>
                  <th width="20%" class="text-center">Cost</th>
                  <th width="20%" class="text-center">Remarks</th>
                  <th width="20%" class="text-center">Status</th>
                  <th width="20%" class="text-center"
                  >Date of Loss / Created</th
                  >
                  <th width="20%" class="text-center">Cause of Loss</th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="(data, index) in filterableForms"
                  :key="index"
                >
                  <td class="text-center">{{ index + 1 }}.</td>
                  <td class="text-center">{{ data.vehicle.name }}</td>
                  <td class="text-center">
                    <b-button
                      v-if="data.type_form === 'claim'"
                      variant="info"
                      ref="button"
                    >Claim</b-button
                    >
                    <b-button
                      v-if="data.type_form === 'non_claim'"
                      variant="warning"
                      ref="button"
                    >Non Claim
                    </b-button>
                  </td>
                  <td class="text-center">{{ data.type_coverage.name }}</td>
                  <td class="text-center">{{ data.type_loss.name }}</td>
                  <td class="text-right">
                    <div>
                      <span>Estimate Cost: </span>
                      <span>{{ formatCurrency(data.cost) }}</span>
                    </div>
                    <div>
                      <span>Recovery </span>
                      <span>{{ formatCurrency(data.recovery) }}</span>
                    </div>
                  </td>
                  <td class="text-center">{{ data.remarks || '-' }}</td>
                  <td class="text-center">
                    <b-button
                      :id="`popover-status-${index}`"
                      variant="primary"
                      ref="button"
                    >{{ data.status ? data.status.name : '-' }}</b-button
                    >
                  </td>
                  <td class="text-center">
                    {{ data.date_of_loss }}
                    <br />
                    <strong
                    >PIC:
                      {{
                        data.creator ? data.creator.username : '-'
                      }}</strong
                    >
                  </td>
                  <td class="text-center">{{ data.cause_of_loss.name }}</td>
                </tr>
                </tbody>
              </table>
            </b-collapse>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'SummaryManagementDetailAccidentList',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    filterableForms () {
      if (!this.data) {
        return []
      }

      return this.data.filter(form => {
        if (!this.keyword) {
          return true
        }

        return (form.vehicle && form.vehicle.name.toString().toLowerCase().includes(this.keyword.toLowerCase())) ||
          (form.type_coverage && form.type_coverage.name.toString().toLowerCase().includes(this.keyword.toLowerCase())) ||
          (form.type_loss && form.type_loss.name.toString().toLowerCase().includes(this.keyword.toLowerCase()))
      })
    }
  },
  data () {
    return {
      keyword: ''
    }
  },
  methods: {
    formatCurrency (number) {
      const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 2
      })
      return formatter.format(Number.isNaN(number) ? 0 : number)
    },
  }
}
</script>

<style scoped>

</style>
