var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: 'height: ' + _vm.height + 'px;',
    attrs: {
      "id": _vm.element
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }