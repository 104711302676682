var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-user-profile-block1"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "user-details-block"
        }, [_c('div', {
          staticClass: "user-profile text-center"
        }, [_c('img', {
          staticClass: "avatar-130 img-fluid",
          attrs: {
            "src": require("../../assets/images/ship.png"),
            "alt": "profile-img"
          }
        })]), _c('div', {
          staticClass: "text-center mt-3"
        }, [_c('h4', [_c('b', [_vm._v("TB. ETI 201")])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        })], 1)]), _c('table', {
          staticClass: "table table-striped mt-3"
        }, [_c('tbody', [_c('tr', {
          staticClass: "bg-primary"
        }, [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_c('strong', [_vm._v("SHIP PARTICULAR")])])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Type Kapal")])]), _c('td', [_vm._v("TUG BOAT")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Bendera")])]), _c('td', [_vm._v("Indonesia")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Klasifikasi")])]), _c('td', [_vm._v("BKI Class")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Tahun Pembuatan")])]), _c('td', [_vm._v("2021")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Tempat Pembangunan")])]), _c('td', [_vm._v("Samarinda")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Tempat Pendaftaran")])]), _c('td', [_vm._v("Jakarta")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Ukuran Kapal")])]), _c('td', [_vm._v("200 X")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Main Engine")])]), _c('td', [_c('span', [_vm._v("Starboard RH : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_c('strong', [_vm._v("1.250")])])], 1), _c('br'), _c('span', [_vm._v("Portside RH : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_c('strong', [_vm._v("1.250")])])], 1)])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Auxuliary Engine")])]), _c('td', [_c('span', [_vm._v("Starboard RH : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_c('strong', [_vm._v("1.250")])])], 1), _c('br'), _c('span', [_vm._v("Portside RH : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_c('strong', [_vm._v("1.250")])])], 1)])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_vm._v("Serah Terima")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 h6 text-primary"
        }, [_vm._v("Docking Information")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Arrival Shipyard")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Up Slip")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Waiting Dock")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_vm._v("5 Hari")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Down Slip")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Complete Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Budget")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_vm._v("Rp. 385.000.000,-")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Shipyard")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_vm._v("PT. CAHAYA BAHARI SHIPYARD")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Real Badget")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_vm._v("Rp. 350.000.000,-")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("PIC")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_vm._v("NURUL AKBAR")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Doc. Shipyard")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf text-primary h3 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h3 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h3 mr-2"
        })])])])]), _c('table', {
          staticClass: "table table-borderless"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Enegine")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "startEngine"
          }
        })], 1), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Finish Engine")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "finishEngine"
          }
        })], 1)]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start ME")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "startME"
          }
        })], 1), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Finish ME")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "finishME"
          }
        })], 1)]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start AE")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "startAE"
          }
        })], 1), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Finish AE")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "finishAE"
          }
        })], 1)]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Engine Test")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "startAE"
          }
        })], 1), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Ready Sail")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "finishAE"
          }
        })], 1)])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 h6 text-primary"
        }, [_vm._v("Satistification Notes")]), _c('table', {
          staticClass: "table table-borderless"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Date Certificate")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "startME"
          }
        })], 1), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Doc. S-Note")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_c('input', {
          staticClass: "form-control-file",
          attrs: {
            "type": "file",
            "id": "attachmentSNote"
          }
        })])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Estimasi Budget")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_vm._v("Rp. 3.500.000.000")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Real Budget")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "inputGroupPrepend2"
          }
        }, [_vm._v("Rp.")])]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "est_budget"
          }
        })])])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Estimasi Tonase")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_vm._v("10 Ton")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Actual Tonase")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "1%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "29%"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "inputGroupPrepend2"
          }
        }, [_vm._v("Ton")])]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "est_tonase"
          }
        })])])])])])])])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_vm._v("Summary Job")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('iq-card', {
          attrs: {
            "class-name": "iq-card-block iq-card-stretch iq-card-height",
            "body-class": "iq-bg-primary rounded"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between"
              }, [_c('div', {
                staticClass: "rounded-circle iq-card-icon bg-primary"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "text-right"
              }, [_c('h2', {
                staticClass: "mb-0"
              }, [_c('span', {
                staticClass: "counter"
              }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Job Category")])])])];
            },
            proxy: true
          }])
        })], 1)], 1), _c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('iq-card', {
          attrs: {
            "class-name": "iq-card-block iq-card-stretch iq-card-height",
            "body-class": "iq-bg-success"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between"
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-1",
                  modifiers: {
                    "jobCat-1": true
                  }
                }],
                staticClass: "mr-1",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v("Click, Job Detail")]), _c('h5', {
                staticClass: "mb-0"
              }, [_vm._v("DOCKING / UNDOCKING - REVIEW")]), _c('div', {
                staticClass: "text-right"
              }, [_c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1)];
            },
            proxy: true
          }])
        }), _c('b-collapse', {
          attrs: {
            "id": "jobCat-1",
            "accordion": "my-accordion",
            "role": "tabpanel"
          }
        }, [_c('b-card-body', [_c('iq-card', {
          attrs: {
            "class-name": "iq-card-block iq-card-stretch iq-card-height",
            "body-class": "iq-bg-info rounded"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between"
              }, [_c('div', {
                staticClass: "rounded-circle iq-card-icon bg-info"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "text-right"
              }, [_c('h2', {
                staticClass: "mb-0"
              }, [_c('span', {
                staticClass: "counter"
              }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
            },
            proxy: true
          }])
        }), _c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          staticStyle: {
            "background-color": "white",
            "border": "none"
          },
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.job-1-1",
            modifiers: {
              "job-1-1": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" Up Slip "), _c('strong', {
          staticClass: "pull-right"
        }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1), _c('b-collapse', {
          attrs: {
            "id": "job-1-1",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Est. Finish")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("50%")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        })])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Created By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Review By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Internal Man Power")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 h6 text-primary"
        }, [_vm._v("Kontraktor 1")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kontraktor")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Executive Engineer")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran Final")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Note")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Note Here")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Attachment")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        })])])])])]), _c('b-alert', {
          attrs: {
            "show": true,
            "variant": "success"
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fa fa-thumbs-up"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("FINISH")])])])], 1)], 1), _c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          staticStyle: {
            "background-color": "white",
            "border": "none"
          },
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.job-1-2",
            modifiers: {
              "job-1-2": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" Down Slip "), _c('strong', {
          staticClass: "pull-right"
        }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1), _c('b-collapse', {
          attrs: {
            "id": "job-1-2",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Est. Finish")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("50%")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        })])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Created By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Review By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Internal Man Power")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 h6 text-primary"
        }, [_vm._v("Kontraktor 1")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kontraktor")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Executive Engineer")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran Final")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Note")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Note Here")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Attachment")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        })])])])])]), _c('b-alert', {
          attrs: {
            "show": true,
            "variant": "success"
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fa fa-thumbs-up"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("FINISH")])])])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('iq-card', {
          attrs: {
            "class-name": "iq-card-block iq-card-stretch iq-card-height",
            "body-class": "iq-bg-success"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between"
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-2",
                  modifiers: {
                    "jobCat-2": true
                  }
                }],
                staticClass: "mr-1",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v("Click, Job Detail")]), _c('h5', {
                staticClass: "mb-0"
              }, [_vm._v("SAND BLASTING - ON PROGRESS")]), _c('div', {
                staticClass: "text-right"
              }, [_c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1)];
            },
            proxy: true
          }])
        }), _c('b-collapse', {
          attrs: {
            "id": "jobCat-2",
            "accordion": "my-accordion",
            "role": "tabpanel"
          }
        }, [_c('b-card-body', [_c('iq-card', {
          attrs: {
            "class-name": "iq-card-block iq-card-stretch iq-card-height",
            "body-class": "iq-bg-info rounded"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between"
              }, [_c('div', {
                staticClass: "rounded-circle iq-card-icon bg-info"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "text-right"
              }, [_c('h2', {
                staticClass: "mb-0"
              }, [_c('span', {
                staticClass: "counter"
              }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
            },
            proxy: true
          }])
        }), _c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          staticStyle: {
            "background-color": "white",
            "border": "none"
          },
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.job-2-1",
            modifiers: {
              "job-2-1": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" Sand blasting lunas"), _c('strong', {
          staticClass: "pull-right"
        }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
          attrs: {
            "id": "job-2-1",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Est. Finish")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("50%")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        })])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Created By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Review By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Internal Man Power")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 h6 text-primary"
        }, [_vm._v("Kontraktor 1")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kontraktor")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Executive Engineer")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran Final")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Note")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Note Here")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Attachment")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        })])])])])]), _c('b-alert', {
          attrs: {
            "show": true,
            "variant": "success"
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fa fa-thumbs-up"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("FINISH")])])])], 1)], 1), _c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          staticStyle: {
            "background-color": "white",
            "border": "none"
          },
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.job-2-2",
            modifiers: {
              "job-2-2": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" Sand blasting deck dan bulwark"), _c('strong', {
          staticClass: "pull-right"
        }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
          attrs: {
            "id": "job-2-2",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Est. Finish")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("50%")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        })])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Created By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Review By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Internal Man Power")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 h6 text-primary"
        }, [_vm._v("Kontraktor 1")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kontraktor")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Executive Engineer")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran Final")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Note")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Note Here")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Attachment")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        })])])])])]), _c('b-alert', {
          attrs: {
            "show": true,
            "variant": "success"
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fa fa-thumbs-up"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("FINISH")])])])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "accordion-jobCat-3",
          attrs: {
            "role": "tablist"
          }
        }, [_c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('iq-card', {
          attrs: {
            "class-name": "iq-card-block iq-card-stretch iq-card-height",
            "body-class": "iq-bg-success"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between"
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-3",
                  modifiers: {
                    "jobCat-3": true
                  }
                }],
                staticClass: "mr-1",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v("Click, Job Detail")]), _c('h5', {
                staticClass: "mb-0"
              }, [_vm._v("MACHINERY EQUIPMENT")]), _c('div', {
                staticClass: "text-right"
              }, [_c('strong', {
                staticClass: "pull-right"
              }, [_vm._v("BOBOT PEKERJAAN : 50%")])])], 1)];
            },
            proxy: true
          }])
        }), _c('b-collapse', {
          attrs: {
            "id": "jobCat-3",
            "accordion": "my-accordion-jobCat-3",
            "role": "tabpanel"
          }
        }, [_c('b-card-body', [_c('iq-card', {
          attrs: {
            "class-name": "iq-card-block iq-card-stretch iq-card-height",
            "body-class": "iq-bg-info rounded"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between"
              }, [_c('div', {
                staticClass: "rounded-circle iq-card-icon bg-info"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "text-right"
              }, [_c('h2', {
                staticClass: "mb-0"
              }, [_c('span', {
                staticClass: "counter"
              }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
            },
            proxy: true
          }])
        }), _c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          staticStyle: {
            "background-color": "white",
            "border": "none"
          },
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.job-3-1",
            modifiers: {
              "job-3-1": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" General Overhaul ME (P/S)"), _c('strong', {
          staticClass: "pull-right"
        }, [_vm._v("BOBOT PEKERJAAN : 30%")])])], 1), _c('b-collapse', {
          attrs: {
            "id": "job-3-1",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Est. Finish")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("30%")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        })])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Created By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Review By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Internal Man Power")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 h6 text-primary"
        }, [_vm._v("Kontraktor 1")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kontraktor")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Executive Engineer")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran Final")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Note")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Note Here")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Attachment")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        })])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 h6 text-primary"
        }, [_vm._v("Daily Progress")]), _c('ul', {
          staticClass: "iq-timeline",
          staticStyle: {
            "height": "500px"
          }
        }, [_c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/6.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/1.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/4.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/3.jpg"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])]), _c('b-alert', {
          attrs: {
            "show": true,
            "variant": "success"
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fa fa-thumbs-up"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("FINISH")])])])], 1)], 1), _c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          staticStyle: {
            "background-color": "white",
            "border": "none"
          },
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.job-3-2",
            modifiers: {
              "job-3-2": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" Kalibrasi injector (P/S)"), _c('strong', {
          staticClass: "pull-right"
        }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
          attrs: {
            "id": "job-3-2",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Est. Finish")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("10%")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        })])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Created By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Review By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Internal Man Power")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Agung Tri Laksono - Port Captain")])], 1), _c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Nurul Akbar - Port Engineer")])], 1), _c('h5', {
          staticClass: "mt-1"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Tri Leksono - Electrical Engineer")])], 1)])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 h6 text-primary"
        }, [_vm._v("Daily Progress")]), _c('ul', {
          staticClass: "iq-timeline",
          staticStyle: {
            "height": "500px"
          }
        }, [_c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/6.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/1.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/4.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/3.jpg"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])]), _c('b-alert', {
          staticClass: "mt-3",
          attrs: {
            "show": true,
            "variant": "success"
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fa fa-thumbs-up"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("FINISH")])])])], 1)], 1), _c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          staticStyle: {
            "background-color": "white",
            "border": "none"
          },
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.job-3-3",
            modifiers: {
              "job-3-3": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" Service/ganti baru dynamo starter (P/S)"), _c('strong', {
          staticClass: "pull-right"
        }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
          attrs: {
            "id": "job-3-3",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Est. Finish")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("10%")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        })])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Created By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Review By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 h6 text-primary"
        }, [_vm._v("Kontraktor 1")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kontraktor")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Executive Engineer")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran Final")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Note")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Note Here")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Attachment")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        })])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 h6 text-primary"
        }, [_vm._v("Daily Progress")]), _c('ul', {
          staticClass: "iq-timeline",
          staticStyle: {
            "height": "500px"
          }
        }, [_c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/6.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/1.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/4.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/3.jpg"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])]), _c('b-alert', {
          staticClass: "mt-3",
          attrs: {
            "show": true,
            "variant": "success"
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fa fa-thumbs-up"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("FINISH")])])])], 1)], 1), _c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          staticClass: "p-1",
          staticStyle: {
            "background-color": "white",
            "border": "none"
          },
          attrs: {
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.job-3-4",
            modifiers: {
              "job-3-4": true
            }
          }],
          staticClass: "text-left",
          attrs: {
            "block": "",
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-chevron-circle-down"
        }), _vm._v(" Service/ganti baru alternator (P/S)"), _c('strong', {
          staticClass: "pull-right"
        }, [_vm._v("BOBOT PEKERJAAN : 10%")])])], 1), _c('b-collapse', {
          attrs: {
            "id": "job-3-4",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Est. Finish")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("10%")]), _c('td', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen")])]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        }), _c('i', {
          staticClass: "fa fa-file-pdf text-primary h4 mr-2"
        })])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Created By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Review By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 h6 text-primary"
        }, [_vm._v("Kontraktor 1")]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kontraktor")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("PT. Tavia Digital Solusi")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Executive Engineer")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp.300.000.000,-")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran Final")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp. 28.000.000,-")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Note")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Note Here")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Attachment")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 h6 text-primary"
        }, [_vm._v("Daily Progress")]), _c('ul', {
          staticClass: "iq-timeline",
          staticStyle: {
            "height": "500px"
          }
        }, [_c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/6.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/1.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/4.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/3.jpg"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])]), _c('b-alert', {
          staticClass: "mt-3",
          attrs: {
            "show": true,
            "variant": "success"
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fa fa-thumbs-up"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_vm._v("General Overhaul ME (P/S) "), _c('b', [_vm._v("FINISH")])])])], 1)], 1)], 1)], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }