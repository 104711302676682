var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    class: "nav nav-".concat(_vm.pills ? 'pills' : '').concat(_vm.tabs ? 'tabs' : '', " ").concat(_vm.vertical ? 'flex-column' : '', " ").concat(_vm.align ? 'justify-content-' + _vm.align : ''),
    attrs: {
      "id": _vm.id
    }
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }