var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("LAPORAN CONDITE CREW / "), _c('i', [_vm._v("CONDITE CREW REPORT")])])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Crew Information")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "10"
          }
        }, [_c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Crew")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("No KTP")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("3174050507930002")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Tempat/Tanggal Lahir")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Jakarta / 05 Juli 1993")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("No NPWP")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("4151 2010 0036")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Jabatan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Master")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("ID Crew")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("TCP202138001")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Perusahaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Transcoal Pacific")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Seaferer Code")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("33273 99328 01")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kapal")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("TB. TCP 201")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Seaman Book")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("3275 23366 2177")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Pelabuhan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Bengalon")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("No Passport")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("32761 121133 01")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Sign On")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("15 Desember 2021")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nomor Tlp / Hp")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("0812 1214 3758")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Join Onboard")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("17 Desember 2021")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Email")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("nurulakbar93@gmail.com")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Sign Off")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("15 Desember 2022")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Periode Penilaian")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.evaluasi,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Periode")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.levelSelected,
            callback: function callback($$v) {
              _vm.levelSelected = $$v;
            },
            expression: "levelSelected"
          }
        })], 1)]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Alamat")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v(" Jl. Pondok Pinang 3, RT.005, RW.002 No. 38, Kel. Pondok Pinang, Kec. Kebayoran Lama, Jakarta Selatan, DKI Jakarat (12310) ")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(" ")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(" ")])])])])]), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "md": "2"
          }
        }, [_c('b-img', {
          staticClass: "ml-0",
          attrs: {
            "thumbnail": "",
            "src": require('../../assets/images/page-img/10.jpg'),
            "alt": "Responsive image"
          }
        })], 1)], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Form Condite")]), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          staticClass: "mb-3",
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', {
          staticClass: "text-center"
        }, [_c('th', {
          staticClass: "align-middle",
          attrs: {
            "width": "18%",
            "rowspan": "2"
          }
        }, [_vm._v("URAIAN")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("NILAI Poin = 1")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Nilai Poin = 2")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Nilai Poin = 3")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Nilai Poin = 4")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Nilai Poin = 5")]), _c('th', {
          staticClass: "align-middle",
          attrs: {
            "width": "12%",
            "rowspan": "2"
          }
        }, [_vm._v("POIN")])]), _c('tr', {
          staticClass: "text-center"
        }, [_c('th', [_vm._v("Kurang Sekali")]), _c('th', [_vm._v("Kurang")]), _c('th', [_vm._v("Cukup")]), _c('th', [_vm._v("Baik")]), _c('th', [_vm._v("Sangat Baik")])])]), _c('tbody', [_c('tr', {
          staticStyle: {
            "font-size": "9pt"
          }
        }, [_c('th', [_vm._v(" Pengetahuan Dalam Bekerja / Job knowledge ")]), _c('td', [_vm._v(" Sedikit dan tidak ada kemauan untuk belajar / Little and not willing to learn ")]), _c('td', [_vm._v(" Sedikit tetapi ada kemauan untuk belajar / Little but willing to learn ")]), _c('td', [_vm._v(" Pengetahuan sedang dalam pekerjaan / Average knowledge in his job ")]), _c('td', [_vm._v(" Mengetahui dalam beberapa hal / Substansial knowledge in his job ")]), _c('td', [_vm._v(" Mengetahui secara menyeluruh / Comprehensive knowledge in his job ")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1)]), _c('tr', {
          staticStyle: {
            "font-size": "9pt"
          }
        }, [_c('th', [_vm._v(" Kerja Sama / Cooperation ")]), _c('td', [_vm._v(" Tidak bisa bekerja sama / Not cooperative with others ")]), _c('td', [_vm._v(" Kerapkali bertengkar dengan yang lain / Often quarrel with others ")]), _c('td', [_vm._v(" Tidak mempunyai perbedaan yang besar terhadap yang lain / Don't have distinction with others ")]), _c('td', [_vm._v(" Bekerjasama, ramah / Cooperative, friendly ")]), _c('td', [_vm._v(" Mau bergaul, bekerjasama, ramah dan menolong / Easy to work with, friendly and helpful ")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1)]), _c('tr', {
          staticStyle: {
            "font-size": "9pt"
          }
        }, [_c('th', [_vm._v(" Kesungguhan Dalam Bekerja / Working Commitment ")]), _c('td', [_vm._v(" Malas dan tidak mau didorong oleh atasannya / Lazy and not motivated ")]), _c('td', [_vm._v(" Harus selalu diawasi dan didorong setiap waktu / Always need supervision and motivation ")]), _c('td', [_vm._v(" Ada kemauan untuk bekerja tetapi lambat / Willinga to work but slow ")]), _c('td', [_vm._v(" Selalu bekerja dengan baik / Always work well ")]), _c('td', [_vm._v(" Bekerja sungguh-sungguh dan rajin setiap saat / Always make an effort and diligent in his work ")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1)]), _c('tr', {
          staticStyle: {
            "font-size": "9pt"
          }
        }, [_c('th', [_vm._v(" Kedisiplinan / Discipline ")]), _c('td', [_vm._v(" Tidak disiplin dan suka menghasut orang lain / Not discipline and like to provoke others ")]), _c('td', [_vm._v(" Kurang disiplin dan cepat tersinggung / Less discipline and easily offended ")]), _c('td', [_vm._v(" Disiplin sedang tapi semangat kerja bagus / Average discipline but good working spirit ")]), _c('td', [_vm._v(" Disiplin dengan baik / Good discipline ")]), _c('td', [_vm._v(" Disiplin dengan baik dan tidak pernah bermasalah / Good disincline and free of trouble ")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1)]), _c('tr', {
          staticStyle: {
            "font-size": "9pt"
          }
        }, [_c('th', [_vm._v(" Sikap Terhadap Kebijakan Perusahaan / Attitude Towards the Company Policies ")]), _c('td', [_vm._v(" Terus menerus mengeluh atas kebijakan perusahaan / Keep complaining regarding the company policies ")]), _c('td', [_vm._v(" Mau menerima kebijakan tetapi membutuhkan pertimbangan keuntungan / Accept the policies but considers their own benefit ")]), _c('td', [_vm._v(" Dapat menerima kebijakan perusahaan / Accept the policies ")]), _c('td', [_vm._v(" Menerima kebijakan perusahaan dengan rela / Willingly to accept the policies ")]), _c('td', [_vm._v(" Simpatik terhadap kebijakan perusahaan / Sympathy toward the policies ")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1)]), _c('tr', {
          staticStyle: {
            "font-size": "9pt"
          }
        }, [_c('th', [_vm._v(" Kesetiaan (Loyalitas) / Loyalty ")]), _c('td', [_vm._v(" Selalu menjelekkan dan antipati terhadap perusahaan / Always discredit and antipathy towards the company ")]), _c('td', [_vm._v(" Berkata jelek tentang perusahaan / Say bad thing about the company ")]), _c('td', [_vm._v(" Mempunyai sikap ragu terhadap perusahaan / Have doubt towards the company ")]), _c('td', [_vm._v(" Setia dan tidak pernah bicara jelek tentang perusahaan / Loyal and never say bad thing about the company ")]), _c('td', [_vm._v(" Sangat setia dan selalu mendahulukan perusahaan / Very loyal and always put company first ")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1)]), _c('tr', {
          staticStyle: {
            "font-size": "9pt"
          }
        }, [_c('th', [_vm._v(" Tanggung Jawab / Responsibilities ")]), _c('td', [_vm._v(" Tidak pernah melaksanakan tanggung jawab penuh / never take responsibilities ")]), _c('td', [_vm._v(" Mau bekerja jika diawasi / willing to work only under supervision ")]), _c('td', [_vm._v(" Harus selalu diperiksa secara berkala, umumnya bisa diterima / Must be monitored occasionally, acceptable in general ")]), _c('td', [_vm._v(" Dapat diandalkan dalam bekerja / Reliable in his job ")]), _c('td', [_vm._v(" Sangat bertanggung jawab / Very responsible ")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-form-select', {
          staticClass: "col-md-12",
          attrs: {
            "plain": "",
            "options": _vm.nilai,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Nilai")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.nilaiSelect,
            callback: function callback($$v) {
              _vm.nilaiSelect = $$v;
            },
            expression: "nilaiSelect"
          }
        })], 1)])])])]), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('small', {
          staticClass: "mb-3"
        }, [_c('strong', [_vm._v("Level 1 - FPD "), _c('br'), _c('i', [_vm._v("Tingkat 1 - FPD")])])]), _c('hr'), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "Clasification *",
            "for": "nama_kandidiat"
          }
        }, [_vm._v("Wawancara Oleh"), _c('br'), _c('i', [_vm._v("Interview by")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-input', {
          attrs: {
            "id": "job_name",
            "type": "text",
            "placeholder": "Nama"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "Clasification *",
            "for": "nama_kandidiat"
          }
        }, [_vm._v("Hasil Wawancara "), _c('br'), _c('i', [_vm._v("Result Interview")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.wawancara,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Hasil Wawancara")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.wawancaraSelected,
            callback: function callback($$v) {
              _vm.wawancaraSelected = $$v;
            },
            expression: "wawancaraSelected"
          }
        })], 1)]), _c('b-form-textarea', {
          attrs: {
            "rows": "2",
            "value": "Comments if any / Komentar jika ada"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('small', {
          staticClass: "mb-3"
        }, [_c('strong', [_vm._v("Level 2 - Nav Committtee / Operation (Only for Master) "), _c('br'), _c('i', [_vm._v("Tingkat 2 - Komite Nav / Operasional (hanya untuk Nakhoda)")])])]), _c('hr'), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "Clasification *",
            "for": "nama_kandidiat"
          }
        }, [_vm._v("Wawancara Oleh"), _c('br'), _c('i', [_vm._v("Interview by")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-input', {
          attrs: {
            "id": "job_name",
            "type": "text",
            "placeholder": "Nama"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "Clasification *",
            "for": "nama_kandidiat"
          }
        }, [_vm._v("Hasil Wawancara "), _c('br'), _c('i', [_vm._v("Result Interview")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.wawancara,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Hasil Wawancara")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.wawancaraSelected,
            callback: function callback($$v) {
              _vm.wawancaraSelected = $$v;
            },
            expression: "wawancaraSelected"
          }
        })], 1)]), _c('b-form-textarea', {
          attrs: {
            "rows": "2",
            "value": "Comments if any / Komentar jika ada"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('small', {
          staticClass: "mb-3"
        }, [_c('strong', [_vm._v("Level 3 - Technical (only for senior Officer) "), _c('br'), _c('i', [_vm._v("Level 3 - Teknik (hanya untuk senior officer)")])])]), _c('hr'), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "Clasification *",
            "for": "nama_kandidiat"
          }
        }, [_vm._v("Wawancara Oleh"), _c('br'), _c('i', [_vm._v("Interview by")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-input', {
          attrs: {
            "id": "job_name",
            "type": "text",
            "placeholder": "Nama"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "Clasification *",
            "for": "nama_kandidiat"
          }
        }, [_vm._v("Hasil Wawancara "), _c('br'), _c('i', [_vm._v("Result Interview")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.wawancara,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Hasil Wawancara")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.wawancaraSelected,
            callback: function callback($$v) {
              _vm.wawancaraSelected = $$v;
            },
            expression: "wawancaraSelected"
          }
        })], 1)]), _c('b-form-textarea', {
          attrs: {
            "rows": "2",
            "value": "Comments if any / Komentar jika ada"
          }
        })], 1)], 1)], 1), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "float-right ml-3",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-save"
        }), _vm._v(" Save")]), _c('b-button', {
          staticClass: "iq-bg-danger float-right",
          attrs: {
            "type": "reset",
            "variant": "none"
          }
        }, [_c('i', {
          staticClass: "fa fa-close"
        }), _vm._v(" Reset Form")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }