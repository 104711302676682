var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-user-profile-block1"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "user-details-block"
        }, [_c('div', {
          staticClass: "user-profile text-center"
        }, [_c('img', {
          staticClass: "avatar-130 img-fluid",
          attrs: {
            "src": require("../../assets/images/user/11.png"),
            "alt": "profile-img"
          }
        })]), _c('div', {
          staticClass: "text-center mt-3"
        }, [_c('h4', [_c('b', [_vm._v("Bess Willis")])]), _c('p', [_vm._v("27 years, California")])]), _c('ul', {
          staticClass: "doctoe-sedual d-flex align-items-center justify-content-between p-0 mt-4 mb-0"
        }, [_c('li', {
          staticClass: "text-center"
        }, [_c('h6', {
          staticClass: "text-primary"
        }, [_vm._v("Weight")]), _c('h3', [_vm._v("60"), _c('span', [_vm._v("kg")])])]), _c('li', {
          staticClass: "text-center"
        }, [_c('h6', {
          staticClass: "text-primary"
        }, [_vm._v("Height")]), _c('h3', [_vm._v("170"), _c('span', [_vm._v("cm")])])]), _c('li', {
          staticClass: "text-center"
        }, [_c('h6', {
          staticClass: "text-primary"
        }, [_vm._v("Goal")]), _c('h3', {
          staticClass: "text-warning"
        }, [_vm._v("55"), _c('span', [_vm._v("kg")])])])])])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    attrs: {
      "body-class": "patient-steps"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('div', {
          staticClass: "data-block"
        }, [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Walked")]), _c('h5', [_vm._v("4532 steps")])]), _c('div', {
          staticClass: "data-block mt-3"
        }, [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v("My Goal")]), _c('h5', [_vm._v("6500 steps")])])]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('div', {
          staticClass: "progress-round patient-progress mx-auto",
          attrs: {
            "data-value": "80"
          }
        }, [_c('span', {
          staticClass: "progress-left"
        }, [_c('span', {
          staticClass: "progress-bar border-secondary"
        })]), _c('span', {
          staticClass: "progress-right"
        }, [_c('span', {
          staticClass: "progress-bar border-secondary"
        })]), _c('div', {
          staticClass: "progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center text-center"
        }, [_c('div', {
          staticClass: "h4 mb-0"
        }, [_vm._v("4532"), _c('br'), _vm._v(" "), _c('span', {
          staticClass: "font-size-14"
        }, [_vm._v("left")])])])])])], 1), _c('ul', {
          staticClass: "patient-role list-inline d-flex align-items-center p-0 mt-4 mb-0"
        }, [_c('li', {
          staticClass: "text-left"
        }, [_c('h6', {
          staticClass: "text-primary"
        }, [_vm._v("Carbs")]), _c('div', {
          staticClass: "iq-progress-bar-linear d-inline-block w-100"
        }, [_c('div', {
          staticClass: "iq-progress-bar"
        }, [_c('span', {
          staticClass: "bg-primary",
          attrs: {
            "data-percent": "85"
          }
        })])])]), _c('li', {
          staticClass: "text-left"
        }, [_c('h6', {
          staticClass: "text-primary"
        }, [_vm._v("Protein")]), _c('div', {
          staticClass: "iq-progress-bar-linear d-inline-block w-100"
        }, [_c('div', {
          staticClass: "iq-progress-bar"
        }, [_c('span', {
          staticClass: "bg-danger",
          attrs: {
            "data-percent": "65"
          }
        })])])]), _c('li', {
          staticClass: "text-left"
        }, [_c('h6', {
          staticClass: "text-primary"
        }, [_vm._v("Fat")]), _c('div', {
          staticClass: "iq-progress-bar-linear d-inline-block w-100"
        }, [_c('div', {
          staticClass: "iq-progress-bar"
        }, [_c('span', {
          staticClass: "bg-info",
          attrs: {
            "data-percent": "70"
          }
        })])])])]), _c('hr'), _c('div', {
          staticClass: "patient-steps2"
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('div', {
          staticClass: "data-block"
        }, [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Burned")]), _c('h5', [_vm._v("325 kcal")])]), _c('div', {
          staticClass: "data-block mt-3"
        }, [_c('p', {
          staticClass: "mb-0"
        }, [_vm._v("My Goal")]), _c('h5', [_vm._v("800 kcal")])])]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('div', {
          staticClass: "progress-round patient-progress mx-auto",
          attrs: {
            "data-value": "60"
          }
        }, [_c('span', {
          staticClass: "progress-left"
        }, [_c('span', {
          staticClass: "progress-bar border-secondary"
        })]), _c('span', {
          staticClass: "progress-right"
        }, [_c('span', {
          staticClass: "progress-bar border-secondary"
        })]), _c('div', {
          staticClass: "progress-value w-100 h-100 rounded-circle d-flex align-items-center justify-content-center text-center"
        }, [_c('div', {
          staticClass: "h4 mb-0 text-warning"
        }, [_vm._v("325"), _c('br'), _vm._v(" "), _c('span', {
          staticClass: "font-size-14 text-secondary"
        }, [_vm._v("left")])])])])])], 1), _c('ul', {
          staticClass: "patient-role list-inline d-flex align-items-center p-0 mt-4 mb-0"
        }, [_c('li', {
          staticClass: "text-left"
        }, [_c('h6', {
          staticClass: "text-primary"
        }, [_vm._v("Carbs")]), _c('div', {
          staticClass: "iq-progress-bar-linear d-inline-block w-100"
        }, [_c('div', {
          staticClass: "iq-progress-bar"
        }, [_c('span', {
          staticClass: "bg-primary",
          attrs: {
            "data-percent": "50"
          }
        })])])]), _c('li', {
          staticClass: "text-left"
        }, [_c('h6', {
          staticClass: "text-primary"
        }, [_vm._v("Protein")]), _c('div', {
          staticClass: "iq-progress-bar-linear d-inline-block w-100"
        }, [_c('div', {
          staticClass: "iq-progress-bar"
        }, [_c('span', {
          staticClass: "bg-danger",
          attrs: {
            "data-percent": "60"
          }
        })])])]), _c('li', {
          staticClass: "text-left"
        }, [_c('h6', {
          staticClass: "text-primary"
        }, [_vm._v("Fat")]), _c('div', {
          staticClass: "iq-progress-bar-linear d-inline-block w-100"
        }, [_c('div', {
          staticClass: "iq-progress-bar"
        }, [_c('span', {
          staticClass: "bg-info",
          attrs: {
            "data-percent": "70"
          }
        })])])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "pb-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "sm": "12"
          }
        }, [_c('iq-card', {
          attrs: {
            "body-class": "bg-primary rounded pt-2 pb-2 pr-2"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between"
              }, [_c('p', {
                staticClass: "mb-0"
              }, [_vm._v("Advice! Connect your Apple Watch for better results.")]), _c('div', {
                staticClass: "rounded iq-card-icon bg-white"
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/page-img/37.png"),
                  "alt": "icon"
                }
              })])])];
            },
            proxy: true
          }])
        }), _c('div', {
          staticClass: "iq-card"
        }, [_c('div', {
          staticClass: "iq-header-title"
        }, [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_vm._v("Popular Training")])]), _c('div', {
          staticClass: "iq-card-body pl-0 pr-0 pb-0"
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "training-block d-flex align-items-center"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon iq-bg-primary"
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/page-img/34.png"),
            "alt": "icon"
          }
        })]), _c('div', {
          staticClass: "ml-3"
        }, [_c('h5', {}, [_vm._v("Power Training")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("395 kcal / h")])])])]), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "training-block d-flex align-items-center"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon iq-bg-primary"
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/page-img/35.png"),
            "alt": "icon"
          }
        })]), _c('div', {
          staticClass: "ml-3"
        }, [_c('h5', {}, [_vm._v("Yoga Training")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("395 kcal / h")])])])]), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('div', {
          staticClass: "training-block d-flex align-items-center"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon iq-bg-primary"
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/page-img/36.png"),
            "alt": "icon"
          }
        })]), _c('div', {
          staticClass: "ml-3"
        }, [_c('h5', {}, [_vm._v("Stretching")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("395 kcal / h")])])])])], 1)], 1)])], 1), _c('b-col', {
          attrs: {
            "lg": "8"
          }
        }, [_c('iq-card', {
          attrs: {
            "header-class": "d-flex justify-content-between p-0 bg-white"
          },
          scopedSlots: _vm._u([{
            key: "headerTitle",
            fn: function fn() {
              return [_c('h4', {
                staticClass: "card-title text-primary"
              }, [_vm._v("Activity Statistic")])];
            },
            proxy: true
          }, {
            key: "body",
            fn: function fn() {
              return [_vm.$route.meta.dark ? _c('ApexChart', {
                attrs: {
                  "element": "patient-chart-01",
                  "chartOption": _vm.patientChart
                }
              }) : _c('ApexChart', {
                attrs: {
                  "element": "patient-chart-01",
                  "chartOption": _vm.patientChart
                }
              })];
            },
            proxy: true
          }])
        })], 1), _c('b-col', {
          attrs: {
            "lg": "4"
          }
        }, [_c('iq-card', {
          attrs: {
            "class-name": "mb-0",
            "body-class": "p-0",
            "header-class": "d-flex justify-content-between p-0 bg-white"
          },
          scopedSlots: _vm._u([{
            key: "headerTitle",
            fn: function fn() {
              return [_c('h4', {
                staticClass: "card-title text-primary"
              }, [_vm._v("My Training")])];
            },
            proxy: true
          }, {
            key: "headerAction",
            fn: function fn() {
              return [_c('b-dropdown', {
                attrs: {
                  "size": "lg",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "ri-add-line m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }])
              }, [_c('b-dropdown-item', {
                attrs: {
                  "href": "#"
                }
              }, [_c('i', {
                staticClass: "ri-eye-fill mr-2"
              }), _vm._v("View")]), _c('b-dropdown-item', {
                attrs: {
                  "href": "#"
                }
              }, [_c('i', {
                staticClass: "ri-delete-bin-6-fill mr-2"
              }), _vm._v("Delete")]), _c('b-dropdown-item', {
                attrs: {
                  "href": "#"
                }
              }, [_c('i', {
                staticClass: "ri-pencil-fill mr-2"
              }), _vm._v("Edit")]), _c('b-dropdown-item', {
                attrs: {
                  "href": "#"
                }
              }, [_c('i', {
                staticClass: "ri-printer-fill mr-2"
              }), _vm._v("Print")]), _c('b-dropdown-item', {
                attrs: {
                  "href": "#"
                }
              }, [_c('i', {
                staticClass: "ri-file-download-fill mr-2"
              }), _vm._v("Download")])], 1)];
            },
            proxy: true
          }, {
            key: "body",
            fn: function fn() {
              return [_c('table', {
                staticClass: "table mb-0 table-borderless table-box-shadow"
              }, [_c('thead', [_c('tr', [_c('th', {
                attrs: {
                  "scope": "col"
                }
              }, [_vm._v("Training")]), _c('th', {
                attrs: {
                  "scope": "col"
                }
              }, [_vm._v("TRX Cardio")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Burned")]), _c('td', [_vm._v("350 kcal")])]), _c('tr', [_c('td', [_vm._v("Spend")]), _c('td', [_vm._v("1hr 45m")])])])]), _c('table', {
                staticClass: "table mb-0 table-borderless mt-4 table-box-shadow"
              }, [_c('thead', [_c('tr', [_c('th', {
                attrs: {
                  "scope": "col"
                }
              }, [_vm._v("Training")]), _c('th', {
                attrs: {
                  "scope": "col"
                }
              }, [_vm._v("Stretching")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Burned")]), _c('td', [_vm._v("180 kcal")])]), _c('tr', [_c('td', [_vm._v("Spend")]), _c('td', [_vm._v("30m")])])])])];
            },
            proxy: true
          }])
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('iq-card', {
          scopedSlots: _vm._u([{
            key: "headerTitle",
            fn: function fn() {
              return [_c('h4', {
                staticClass: "card-title text-primary"
              }, [_vm._v("Heart Rate")])];
            },
            proxy: true
          }, {
            key: "body",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "mr-3"
              }, [_c('h4', {}, [_vm._v("75 bpm")]), _c('p', {
                staticClass: "mb-0 text-primary"
              }, [_vm._v("Health Zone")])]), _c('div', {
                staticClass: "rounded-circle iq-card-icon iq-bg-primary"
              }, [_c('i', {
                staticClass: "ri-windy-fill"
              })])])];
            },
            proxy: true
          }])
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('iq-card', {
          attrs: {
            "body-class": "p-0",
            "header-class": "d-flex justify-content-between p-0 bg-white"
          },
          scopedSlots: _vm._u([{
            key: "headerTitle",
            fn: function fn() {
              return [_c('h4', {
                staticClass: "card-title text-primary"
              }, [_vm._v("Water Balance")])];
            },
            proxy: true
          }, {
            key: "body",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "mr-3 text-left"
              }, [_c('p', {
                staticClass: "mb-0"
              }, [_vm._v("Drunk")]), _c('h4', {}, [_vm._v("1250 ml/ 2000 ml")])]), _c('div', {
                staticClass: "rounded-circle iq-card-icon iq-bg-primary"
              }, [_c('i', {
                staticClass: "ri-add-fill"
              })])])];
            },
            proxy: true
          }])
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }