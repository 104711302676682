var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('img', {
    attrs: {
      "src": "https://coderthemes.com/hyper/saas/assets/images/mail_sent.svg",
      "width": "80",
      "alt": ""
    }
  }), _c('h1', {
    staticClass: "mt-3 mb-0"
  }, [_vm._v("Success !")]), _c('p', [_vm._v("A email has been send to youremail@domain.com. Please check for an email from company and click on the included link to reset your password.")]), _c('div', {
    staticClass: "d-inline-block w-100"
  }, [_c('button', {
    staticClass: "btn btn-primary mt-3",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("Back to Home")])])]);

}]

export { render, staticRenderFns }