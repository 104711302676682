<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Filter Goods Stock Movement</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="3">
                <b-form-group label="Selecte Date" label-for="serial">
                  <date-picker v-model="historiesParamsDate" type="date" range placeholder="Select Date" format="DD-MM-YYYY"></date-picker>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Fleet / Heavy Equipment" label-for="serial">
                  <v-multiselect v-model="fleetSelected" tag-placeholder="Category" placeholder="Search or add a fleet" label="name" track-by="code" :options="fleetEquipments" :multiple="true" :taggable="true" >
                  </v-multiselect>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Code / Name Goods" label-for="serial">
                  <b-form-input id="fleet_name" class="w-100" type="text" placeholder="Code / Name Goods..."></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Status" label-for="Status">
                  <v-select label="text" v-model="statusSelected" :options="statusGoods" :reduce="(type) => type.value" placeholder="select category"></v-select>
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-button type="submit" variant="primary" class="mt-4"><i class="fa fa-filter"></i></b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>

      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Data Stock Movement</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th width="3%">No</th>
                    <th>Fleet</th>
                    <th>Date</th>
                    <th>Images</th>
                    <th>Category</th>
                    <th>Goods (Code-Name)</th>
                    <th>Opening Qty</th>
                    <th>Qty In</th>
                    <th>Qty Out</th>
                    <th>Closing Qty</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                </table>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { fleetsActions } from '@src/Utils/helper'

export default {
  name: 'GoodsStockMovement',
  data () {
    return {
      fleetEquipments: [],
      fleetSelected: [],
      fleetParams: {
        page: 1,
        showAll: true,
        active: true,
        search: ''
      },
      historiesParamsDate: [],
      statusSelected: [],
      typeStockSelected: [
        { value: 'stock-in', text: 'Stock In' },
        { value: 'stock-out', text: 'Stock Out' }
      ],
      category: [
        { value: 'stock-in', text: 'Stock In' },
        { value: 'stock-out', text: 'Stock Out' }
      ],
      statusGoods: [
        { value: 'all', text: 'All Status' },
        { value: 'available', text: 'Available Stock' },
        { value: 'towards-runs-out', text: 'Stock Runs Out Soon' },
        { value: 'out-of-stock', text: 'Out of Stock' }
      ]
    }
  },
  async mounted () {
    xray.index()
    await this.fetchFleets()
  },
  methods: {
    ...fleetsActions,
    async fetchFleets () {
      this.fleetsLoading = true
      this.fleets = []

      let params = this.$options.filters.cleanObject(this.fleetParams)
      let res = await this.getFleets(params)

      if (res.status) {
        this.fleetsLoading = false
        if (res.data && res.data.length > 0) {
          res.data.forEach(el => {
            el.componentSetup = []
            this.fleets.push(el)
            this.fleetEquipments.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
  }
}
</script>
