<template>
  <form @submit.prevent="onSave">
    <div v-if="loading" class="text-center">
      Loading Content...
    </div>
    <template v-else>
      <!-- DOCKING INFORMATION -->
      <fieldset v-if="['CREATE_EMERGENCY', 'CREATE_FROM_SCHEDULE'].includes(action)" class="form-group border p-3 iq-bg-info mt-3">
        <legend class="w-auto px-2">Docking Information</legend>
        <b-form-row>
          <b-col>
            <b-form-group label="Arrival Shipyard *" label-for="arrival_shipyard">
              <date-picker required id="arrival_shipyard" v-model="form.arrival" @change="() => {
                onValidateAllDates('arrival')
                onGenerateWaitingDockSpace()
              }" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Up Slip *" label-for="up_slip">
              <date-picker required id="up_slip" v-model="form.upSlip" @change="() => {
                onValidateAllDates('upSlip')
                onGenerateWaitingDockSpace()
              }" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Waiting Dock Space *" label-for="waiting_dock_space">
              <b-form-input required type="number" v-model="form.waitingDockSpace" id="waiting_dock_space" style="border: solid 1px gray"></b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group label="Down Slip *" label-for="down_slip">
              <date-picker required id="down_slip" v-model="form.downSlip" @change="() => {
                onValidateAllDates('downSlip')
              }" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Complete Date *" label-for="delivery_date">
              <date-picker required id="delivery_date" v-model="form.deliveryDate" @change="() => {
                onValidateAllDates('deliveryDate')
              }" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Estimasi Budget" label-for="est_budget">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-primary">Rp.</span>
                </div>
                <input type="number" v-model="form.budgetEstimation" class="form-control w-100" id="est_budget" style="border: solid 1px gray">
              </div>
              <small>Rp. {{numberFormat(form.budgetEstimation)}}</small>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group label="Shipyard *" label-for="shipyard">
              <v-select v-model="form.dockingShipyardId" label="name"
                        :options="shipyards" :reduce="type => type.id">
              </v-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="PIC" label-for="pic">
              <v-select v-model="form.userId" label="text"
                        :options="users" :reduce="type => type.value">
              </v-select>
            </b-form-group>
          </b-col>>
          <b-col>
            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
              <div v-if="form.docShipyard" class="d-flex justify-content-between">
                <a :href="form.docShipyardUrl" class="text-primary" v-b-tooltip.hover title="Download File" download><i class="fa fa-download"></i> Download</a>
                <a href="#" @click.prevent="() => {
                  form.docShipyard = ''
                  form.docShipyardUrl = ''
                   }" class="text-danger" v-b-tooltip.hover title="Hapus" download><i class="fa fa-trash"></i></a>
              </div>
              <template v-else>
                <div class="custom-file">
                  <input type="file" @change="onUploadShipyardFile"
                         accept="image/png,image/jpg,image/jpeg,.pdf,.xls,.xlsx,.doc,.docx"
                         class="custom-file-input" id="attachment-shipyard">
                  <label class="custom-file-label" for="attachment-shipyard">Browse file...</label>
                </div>
                <small class="text-muted">
                  File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                  File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                </small>
              </template>
            </b-form-group>
          </b-col>
        </b-form-row>
      </fieldset>
      <table v-else-if="['REVIEW', 'APPROVE', 'UPDATE', 'DETAIL'].includes(action)" class="table table-striped mt-3">
        <tbody>
        <tr>
          <th width="20%">Arrival Shipyard</th>
          <td width="5%" class="text-center">:</td>
          <td width="25%">
            <div v-if="action === 'DETAIL'">
              {{form.arrival}}
            </div>
            <template v-else>
              <date-picker v-if="currentStatus !== 'PROGRESS'" required id="arrival_shipyard" v-model="form.arrival" @change="onGenerateWaitingDockSpace" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
              <span v-else>
                {{form.arrival}}
              </span>
            </template>
          </td>
          <th width="20%">Up Slip</th>
          <td width="5%" class="text-center">:</td>
          <td width="25%">
            <div v-if="action === 'DETAIL'">
              {{form.upSlip}}
            </div>
            <template v-else>
              <date-picker v-if="currentStatus !== 'PROGRESS'" required id="up_slip" v-model="form.upSlip" @change="onGenerateWaitingDockSpace" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
              <span v-else>
                {{form.upSlip}}
              </span>
            </template>
          </td>
        </tr>
        <tr>
          <th width="20%">Waiting Dock Space</th>
          <td width="5%" class="text-center">:</td>
          <td width="25%">{{form.waitingDockSpace}} Hari</td>
          <th width="20%">Down Slip</th>
          <td width="5%" class="text-center">:</td>
          <td width="25%">
            <div v-if="action === 'DETAIL'">
              {{form.downSlip}}
            </div>
            <date-picker v-else required id="down_slip" v-model="form.downSlip" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
          </td>
        </tr>
        <tr>
          <th width="20%">Complete Date</th>
          <td width="5%" class="text-center">:</td>
          <td width="25%">
            <div v-if="action === 'DETAIL'">
              {{form.deliveryDate}}
            </div>
            <date-picker v-else required id="delivery_date" v-model="form.deliveryDate" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
          </td>
          <th width="20%">Budget</th>
          <td width="5%" class="text-center">:</td>
          <td width="25%">Rp. {{ numberFormat(form.budgetEstimation) }},-</td>
        </tr>
        <tr>
          <th width="20%">Shipyard</th>
          <td width="5%" class="text-center">:</td>
          <td width="25%">{{ form.dockingShipyardId && shipyards.find(s => s.id === form.dockingShipyardId) ? shipyards.find(s => s.id === form.dockingShipyardId).name : '-' }}</td>
          <th width="20%">Penawaran</th>
          <td width="5%" class="text-center">:</td>
          <td width="25%">
            <div v-if="action === 'DETAIL'">
              Rp. {{ numberFormat(form.budget) }}
            </div>
            <template v-else>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                </div>
                <input type="number" v-model="form.budget" required class="form-control" id="real_budget" style="border: solid 1px gray">
              </div>
              <small>Rp. {{ numberFormat(form.budget) }}</small>
            </template>
          </td>
        </tr>
        <tr>
          <th width="20%">PIC</th>
          <td width="5%" class="text-center">:</td>
          <td width="25%">{{ form.userId && users.find(s => s.value === form.userId) ? users.find(s => s.value === form.userId).text : '-' }}</td>
          <th width="20%">Document Shipyard</th>
          <td width="5%" class="text-center">:</td>
          <td width="25%">
            <ul class="d-flex flex-wrap p-0 m-0" v-if="form.docShipyard">
              <li class="pb-1" style="width: 30px"><a :href="form.docShipyardUrl" target="_blank"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
            </ul>
          </td>
        </tr>
        </tbody>
      </table>

      <!-- DOCKING INFORMATION END -->
      <hr>
      <!-- JOB CATEGORY PERCENT -->
      <b-row>
        <b-col md="12">
          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary rounded">
            <template v-slot:body >
              <div class="d-flex align-items-center justify-content-between">
                <div class="rounded-circle iq-card-icon bg-primary"><i class="fa fa-percent"></i></div>
                <div class="text-right">
                  <h2 class="mb-0"><span class="counter">{{ onCalcJobCatPercentage() }}%</span></h2>
                  <h5 class="">Persentase Job Category</h5>
                </div>
              </div>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      <!-- JOB CATEGORY PERCENT END -->
      <!-- DOCKING JOB -->
      <div class="accordion" role="tablist">
        <b-card no-body>
          <template v-for="(jobCategory, indexJobCategory) in form.dockingTaskCategories">
<!--             TODO: enable if needed-->
<!--            <div v-if="['APPROVE'].includes(action)" :key="indexJobCategory" class="mb-2">-->
<!--              <b-button block v-b-toggle="`jobCat-${indexJobCategory}`" variant="primary" class="text-left"><i class="fa fa-chevron-circle-down"></i>{{jobCategory.dockingCategoryName}} <strong class="pull-right">BOBOT PEKERJAAN :-->
<!--                {{ jobCategory.percentage }}%</strong></b-button>-->
<!--            </div>-->
            <iq-card :key="indexJobCategory" class-name="iq-card-block iq-card-stretch iq-card-height" class="align-middle" body-class="iq-bg-success">
              <template v-slot:body >
                <table class="table table-sm table-borderless">
                  <tr>
                    <td class="text-center" width="20%">
                      <b-button variant="primary" class="mr-1" v-b-toggle="`jobCat-${indexJobCategory}`"><i class="fa fa-chevron-circle-down"></i>Click, Job Detail</b-button>
                    </td>
                    <td class="text-center" width="35%">
                      <h4 class="mb-0">{{jobCategory.dockingCategoryName}} <small>({{filteredDockingTasks(jobCategory.dockingTasks).length}} jobs)</small></h4>
                    </td>
                    <td class="text-right" width="35%">
                      <div v-if="action === 'DETAIL'">
                        <strong>
                          BOBOT PEKERJAAN: {{jobCategory.percentage}}%
                        </strong>
                      </div>
                      <div class="input-group" v-else>
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-primary">%</span>
                        </div>
                        <input type="number" required v-model="jobCategory.percentage" class="form-control" id="real_budget" placeholder="Isi Persentase">
                      </div>
                    </td>
                    <td class="text-center" width="10%" v-if="!['APPROVE', 'DETAIL'].includes(action)">
                      <b-button size="sm" @click="onDeleteTaskCategory(indexJobCategory)" variant="danger"><i class="fa fa-trash m-0  "></i></b-button>
                    </td>
                  </tr>
                </table>
              </template>
            </iq-card>

            <b-collapse :key="indexJobCategory" :id="`jobCat-${indexJobCategory}`" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <!-- BOBOT DOCKING JOB -->
                <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                  <template v-slot:body >
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                      <div class="text-right">
                        <h2 class="mb-0"><span class="counter">{{onCalcJobPercentage(indexJobCategory)}}%</span></h2>
                        <h5 class="">Persentase Docking Job</h5>
                      </div>
                    </div>
                  </template>
                </iq-card>
                <!-- BOBOT DOCKING JOB END -->
                <div class="accordion" role="tablist">
                  <b-card v-for="(job, indexJob) in filteredDockingTasks(jobCategory.dockingTasks)" no-body :key="`${indexJob}-${indexJobCategory}`">
                    <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                      <b-button block v-b-toggle="`task-${indexJob}-${indexJobCategory}`" :variant="colorCard(job)" class="text-left"><i class="fa fa-chevron-circle-down"></i>
                        {{indexJob + 1}}. {{ job.dockingJobName }} <strong class="pull-right">BOBOT PEKERJAAN : {{ job.percentage }}%</strong></b-button>
                    </b-card-header>
                    <b-collapse :id="`task-${indexJob}-${indexJobCategory}`" accordion="my-accordion-sub" role="tabpanel">
                      <div v-if="['APPROVE', 'DETAIL'].includes(action) && job.dockingTaskId || job.isApprove === true" class="mb-2">
                        <table class="table table-striped mt-2">
                          <tbody>
                          <tr>
                            <th width="20%">Start Date</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">{{ job.startDate }}</td>
                            <th width="20%">Est. Finish</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">{{ job.estFinishDate }}</td>
                          </tr>
                          <tr>
                            <th width="20%">Bobot Pekerjaan</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">
                              <div v-if="action === 'DETAIL'">
                                {{job.percentage}}%
                              </div>
                              <template v-else>
                                <div class="input-group" v-if="job.isApprove === true || ['APPROVE'].includes(action)">
                                  <div class="input-group-prepend">
                                    <span class="input-group-text bg-primary">%</span>
                                  </div>
                                  <input type="number" required v-model="job.percentage" class="form-control">
                                </div>
                                <span v-else>
                              {{ job.percentage }}%
                              </span>
                              </template>
                            </td>
                            <th width="20%">Dokumen</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">
                              <ul class="d-flex flex-wrap p-0 m-0" v-if="job.attachmentJob">
                                <li class="pb-1" style="width: 30px"><a :href="job.attachmentJobUrl" target="_blank"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                              </ul>
                            </td>
                          </tr>
                          <tr>
                            <th width="20%">Created By</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">{{ job.createdBy }}</td>
                            <th width="20%">Review By</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">{{ job.reviewedBy }}</td>
                          </tr>
                          <tr>
                            <th class="align-top">Notes</th>
                            <td class="text-center align-top">:</td>
                            <td colspan="4">
                              {{job.notes}}
                            </td>
                          </tr>
                          <tr v-if="job.isUsingManPower">
                            <th class="align-top">Internal Man Power</th>
                            <td class="text-center align-top">:</td>
                            <td colspan="4">
                              <h5 class="mt-1" v-for="(manPower, indexManPower) in job.manPowers" :key="indexManPower"><b-badge variant="info">
                                {{ manPower.name }} - {{ manPower.position }}</b-badge></h5>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                        <table class="table table-striped mt-2" v-if="job.isUsingSubcontractor">
                          <tbody>
                          <fieldset v-for="(contractor, indexContractor) in job.subContractors" :key="indexContractor" class="form-group border p-3">
                            <legend class="w-auto px-2">Kontraktor {{indexContractor+1}}</legend>
                            <tr>
                              <th width="20%">Nama Kontraktor</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">{{ contractor.name }}</td>
                              <th width="20%">Executive Engineer</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">{{ contractor.pic }}</td>
                            </tr>
                            <tr>
                              <th width="20%">Penawaran</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">Rp{{ numberFormat(contractor.offer) }}</td>
                              <th width="20%">Penawaran Final</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">Rp{{ numberFormat(contractor.offerNegotiation) }}</td>
                            </tr>
                            <tr>
                              <th width="20%">Note</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">{{ contractor.notes }}</td>
                              <th width="20%">Attachment</th>
                              <td width="5%" class="text-center">:</td>
                              <td width="25%">
                                <ul class="d-flex flex-wrap p-0 m-0" v-if="contractor.attachmentShipyard">
                                  <li class="pb-1" style="width: 30px"><a :href="contractor.attachmentShipyardUrl" target="_blank"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                </ul>
                              </td>
                            </tr>
                          </fieldset>
                          </tbody>
                        </table>
                        <b-alert :show="job.isApprove === true" variant="success">
                          <div class="iq-alert-icon">
                            <i class="fa fa-thumbs-up"></i>
                          </div>
                          <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                        </b-alert>
                        <b-alert :show="job.isApprove === false" variant="danger">
                          <div class="iq-alert-icon">
                            <i class="fa fa-close"></i>
                          </div>
                          <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                        </b-alert>
                        <!--                        <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>-->
                        <template v-if="['APPROVE'].includes(action)">
                          <b-button @click="job.isApprove = false" variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                          <b-button @click="job.isApprove = true" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                        </template>
                      </div>
                      <template v-else>
                        <b-form-row class="mt-3">
                          <b-col>
                            <b-form-group label="Start *" label-for="start">
                              <date-picker required v-model="job.startDate" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group label="Est. Finish *" label-for="end">
                              <date-picker required v-model="job.estFinishDate" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row>
                          <b-col>
                            <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text bg-primary">%</span>
                                </div>
                                <input type="number" required v-model="job.percentage" class="form-control">
                              </div>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group label="Attachment Job" label-for="attachment_job">
                              <div v-if="job.attachmentJob" class="d-flex justify-content-between">
                                <a :href="job.attachmentJobUrl" class="text-primary" v-b-tooltip.hover title="Download File" download><i class="fa fa-download"></i> Download</a>
                                <a href="#" @click.prevent="() => {
                                  job.attachmentJob = ''
                                  job.attachmentJobUrl = ''
                                }" class="text-danger" v-b-tooltip.hover title="Hapus" download><i class="fa fa-trash"></i></a>
                              </div>
                              <template v-else>
                                <div class="custom-file">
                                  <input type="file" @change="onUploadDockingTaskAttachment($event, indexJobCategory, indexJob)" class="custom-file-input">
                                  <label class="custom-file-label">Browse file...</label>
                                </div>
                                <small class="text-muted">
                                  File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                  File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                </small>
                              </template>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row>
                          <b-col>
                            <b-form-group label="Notes" label-for="notes">
                              <b-form-textarea v-model="job.notes" rows="3" placeholder="Notes"></b-form-textarea>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <b-form-row>
                          <b-col>
                            <b-form-group label="Internal Man Power">
                              <b-form-checkbox
                                required
                                v-model="job.isUsingManPower"
                                switch
                                inline
                                :value="true"
                                :unchecked-value="false"
                              >
                              </b-form-checkbox>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <fieldset class="form-group border p-3" v-if="job.isUsingManPower">
                          <legend class="w-auto px-2">Internal Man Power</legend>
                          <b-form-row v-for="(manPower, indexManPower) in job.manPowers" :key="indexManPower">
                            <b-col md="5">
                              <b-form-group label="Nama Internal Man Power *">
                                <b-form-input type="text" required v-model="manPower.name" placeholder="Nama"></b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col md="5">
                              <b-form-group label="Jabatan *" label-for="jabatan">
                                <b-form-select plain v-model="manPower.position" :options="positions" size="lg">
                                  <template v-slot:first>
                                    <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                  </template>
                                </b-form-select>
                              </b-form-group>
                            </b-col>
                            <b-col>
                              <b-form-group label="Action">
                                <b-button variant="danger" v-if="indexManPower > 0" @click="onRemoveManPower(indexJobCategory, indexJob, indexManPower)"><i class="fa fa-trash m-0"></i></b-button>
                              </b-form-group>
                            </b-col>
                          </b-form-row>
                          <b-button variant="success" @click="onAddManPower(indexJobCategory, indexJob)" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                        </fieldset>
                        <b-form-row>
                          <b-col>
                            <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                              <b-form-checkbox
                                required
                                v-model="job.isUsingSubcontractor"
                                switch
                                inline
                                :value="true"
                                :unchecked-value="false"
                              >
                              </b-form-checkbox>
                            </b-form-group>
                          </b-col>
                        </b-form-row>
                        <fieldset class="form-group border p-3" v-if="job.isUsingSubcontractor">
                          <template v-for="(subCon, indexSubCon) in job.subContractors">
                            <legend :key="indexSubCon" class="w-auto">Sub Kontraktor #{{ indexSubCon+1 }}</legend>
                            <div :key="indexSubCon">
                              <b-form-row>
                                <b-col>
                                  <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                    <b-form-input type="text" placeholder="Nama Kontraktor" v-model="subCon.name" required></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                    <b-form-input type="text" v-model="subCon.pic" required placeholder="PIC"></b-form-input>
                                  </b-form-group>
                                </b-col>
                              </b-form-row>
                              <b-form-row>
                                <b-col>
                                  <b-form-group label="Penawaran" label-for="penawaran">
                                    <div class="input-group">
                                      <div class="input-group-prepend">
                                        <span class="input-group-text bg-primary">Rp.</span>
                                      </div>
                                      <input type="number" v-model="subCon.offer" class="form-control" placeholder="Isi Penawaran">
                                    </div>
                                    <small>Rp. {{numberFormat(subCon.offer)}}</small>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                    <div class="input-group">
                                      <div class="input-group-prepend">
                                        <span class="input-group-text bg-primary">Rp.</span>
                                      </div>
                                      <input type="number" v-model="subCon.offerNegotiation" class="form-control" placeholder="Isi Nego">
                                    </div>
                                    <small>Rp. {{numberFormat(subCon.offerNegotiation)}}</small>
                                  </b-form-group>
                                </b-col>
                              </b-form-row>
                              <b-form-row>
                                <b-col>
                                  <b-form-group label="Notes" label-for="notes">
                                    <b-form-textarea v-model="subCon.notes" rows="2" placeholder="Catatan"></b-form-textarea>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-form-group label="Attachment" label-for="doc_shipyard">
                                    <div v-if="subCon.attachmentShipyard" class="d-flex justify-content-between">
                                      <a :href="subCon.attachmentShipyardUrl" class="text-primary" v-b-tooltip.hover title="Download File" download><i class="fa fa-download"></i> Download</a>
                                      <a href="#" @click.prevent="() => {
                                          subCon.attachmentShipyard = ''
                                          subCon.attachmentShipyardUrl = ''
                                        }" class="text-danger" v-b-tooltip.hover title="Hapus" download><i class="fa fa-trash"></i></a>
                                    </div>
                                    <template v-else>
                                      <div class="custom-file">
                                        <input type="file" @change="onUploadDockingTaskSubContractorAttachment($event, indexJobCategory, indexJob, indexSubCon)" class="custom-file-input" id="validatedCustomFile">
                                        <label class="custom-file-label">Browse file...</label>
                                      </div>
                                      <small class="text-muted">
                                        File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                        File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                      </small>
                                    </template>
                                  </b-form-group>
                                </b-col>
                              </b-form-row>
                              <b-form-row>
                                <b-col>
                                  <b-button variant="success" @click="onAddSubContractor(indexJobCategory, indexJob)" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                </b-col>
                                <b-col class="text-right">
                                  <b-button variant="danger" v-if="indexSubCon > 0" @click="onRemoveSubContractor(indexJobCategory, indexJob, indexSubCon)"><i class="fa fa-trash m-0"></i></b-button>
                                </b-col>
                              </b-form-row>
                            </div>
                          </template>
                          <b-button v-if="job.subContractors.length === 0" variant="success" @click="onAddSubContractor(indexJobCategory, indexJob)" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                        </fieldset>
                      </template>
                    </b-collapse>
                  </b-card>
                </div>
              </b-card-body>
            </b-collapse>
          </template>
        </b-card>
      </div>
      <!-- DOCKING JOB END -->
      <b-col md="12" class="mt-3">

<!--        <b-button type="submit" variant="danger" class="pull-right"><i class="fa fa-close"></i> Reset Form</b-button>-->
        <template v-if="action !== 'DETAIL'">
          <b-card-text class="pull-right"><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
          <br><br>
          <template v-if="['APPROVE'].includes(action) && !canApprove()">
            <b-button disabled variant="success" class="pull-right mr-1"><i class="fa fa-save"></i>
              Approve
            </b-button>
          </template>
          <b-button v-else type="submit" variant="success" class="pull-right mr-1"><i class="fa fa-save"></i>
            <span v-if="['CREATE_EMERGENCY', 'CREATE_FROM_SCHEDULE'].includes(action)">Save</span>
            <span v-else-if="['UPDATE'].includes(action)">Update</span>
            <span v-else-if="['REVIEW'].includes(action)">Review</span>
            <span v-else-if="['APPROVE'].includes(action)">Approve</span>
          </b-button>
        </template>
      </b-col>
    </template>
  </form>
</template>

<script>
import { dockingActions, filesActions, usersActions } from '@src/Utils/helper'
import _ from 'lodash'
import { numberFormat } from '@src/plugins/helpers'
import moment from 'moment'

export default {
  name: 'DockingScheduleForm',
  props: {
    action: {
      type: String,
      default: 'CREATE_EMERGENCY' // CREATE_EMERGENCY, CREATE_FROM_SCHEDULE, REVIEW, APPROVE, UPDATE
    },
    dockingId: {
      type: String,
      default: null
    },
    vehicleId: {
      type: Number,
      default: null
    },
    groupOfJobs: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    groupOfJobs: {
      deep: true,
      immediate: true,
      handler (val, oldVal) {
        this.onDockingTaskMap()
      }
    }
  },
  computed: {
  },
  async mounted () {
    this.loading = true
    await Promise.all([
      this.fetchJobShipyards(),
      this.fetchUsers(),
      this.fetchDockingDetail()
    ])
    this.loading = false
  },
  data () {
    return {
      loading: true,
      shipyards: [],
      users: [],
      positions: [
        { value: 'Master', text: 'Master' },
        { value: 'Chief Officer', text: 'Chief Officer' },
        { value: 'Second Officer', text: 'Second Officer' },
        { value: 'Chief Engineer', text: 'Chief Engineer' },
        { value: 'Second Engineer', text: 'Second Engineer' },
        { value: 'Third Engineer', text: 'Third Engineer' },
        { value: 'AB', text: 'AB' },
        { value: 'Olier', text: 'Olier' },
        { value: 'Cooker', text: 'Cooker' },
        { value: 'Mechanic', text: 'Mechanic' }
      ],
      currentStatus: '',
      form: {
        saving: false,
        'vehicleId': '',
        'arrival': '',
        'upSlip': '',
        'downSlip': '',
        'deliveryDate': '',
        'waitingDockSpace': '',
        'budgetEstimation': null,
        'budget': null,
        'docShipyardUrl': '',
        'docShipyard': '',
        'userId': '',
        'dockingShipyardId': '',
        'dockingTaskCategories': []
      }
    }
  },
  methods: {
    ...dockingActions,
    ...usersActions,
    ...filesActions,
    numberFormat (number) {
      return numberFormat(number)
    },
    convertToMoment (date, format = 'DD-MM-YYYY HH:mm') {
      return moment(date, format)
    },
    async fetchJobShipyards () {
      this.shipyards = []
      const res = await this.getShipyards({
        page: 1,
        perPage: 999999999,
        isActive: true
      })

      if (res.status === 'success' && res.data) {
        this.shipyards = res.data.data
      }
    },
    async fetchUsers () {
      this.users = []
      let params = {
        active: true,
        page: 1,
        perPage: 9999
      }
      const res = await this.getUsers(params)

      if (res.status === 'success' && res.data) {
        let rawData = res.data.data
        this.users = rawData.map((val) => ({
          value: val.id, text: val.userDetail.name
        }))
      }
    },
    async fetchDockingDetail () {
      if (this.dockingId) {
        const { data, status } = await this.getDockingDetail({
          dockingId: this.dockingId
        })

        if ([500, 404, 403].includes(status)) {
          this.$swal(
            `Oops!`,
            'Ada Yang Salah',
            'error'
          )
          return
        }

        if (data.data) {
          this.currentStatus = data.data.status
          this.form.arrival = data.data.arrival
          this.form.upSlip = data.data.upSlip
          this.form.downSlip = data.data.downSlip
          this.form.waitingDockSpace = data.data.waitingDockSpace
          this.form.deliveryDate = data.data.deliveryDate
          this.form.budgetEstimation = data.data.budgetEstimation
          this.form.budget = data.data.budgetReal
          this.form.dockingShipyardId = data.data.shipyard ? data.data.shipyard.id : ''
          this.form.userId = data.data.user ? data.data.user.id : ''
          this.form.docShipyard = data.data.docShipyard ? data.data.docShipyard.fileName : ''
          this.form.docShipyardUrl = data.data.docShipyard ? data.data.docShipyard.url : ''

          if (data.data.dockingTaskCategory && data.data.dockingTaskCategory.length > 0) {
            this.form.dockingTaskCategories = data.data.dockingTaskCategory.map(category => {
              return {
                taskCategoryId: category.id,
                dockingCategoryId: category.dockingJobCategory.id,
                dockingCategoryName: category.dockingJobCategory.name,
                percentage: category.wokloadPercentage,
                dockingTasks: category.dockingTask.map(task => {
                  return {
                    dockingTaskId: task.id,
                    dockingJobId: task.dockingJob.id,
                    dockingJobName: task.dockingJob.name,
                    startDate: task.jobStart,
                    estFinishDate: task.jobEstFinish,
                    percentage: task.jobWorkloadPercentage,
                    attachmentJob: task.jobDoc ? task.jobDoc.fileName : '',
                    attachmentJobUrl: task.jobDoc ? task.jobDoc.url : '',
                    notes: task.notes,
                    manPowers: task.manPowers,
                    status: task.status,
                    subContractors: task.subContractors.map(subCon => {
                      return {
                        ...subCon,
                        attachmentShipyard: subCon.contractorDoc ? subCon.contractorDoc.fileName : '',
                        attachmentShipyardUrl: subCon.contractorDoc ? subCon.contractorDoc.url : ''
                      }
                    }),
                    isUsingManPower: task.isUseManPower,
                    isUsingSubcontractor: task.isUseContractor,
                    isApprove: task.approvedBy && task.id ? true : null,
                    isRejected: task.rejectedBy && task.id ? true : null,
                    createdBy: task.createdBy ? JSON.parse(task.createdBy).name : '-',
                    reviewedBy: task.reviewedBy ? JSON.parse(task.reviewedBy).name : '-',
                    approvedBy: task.approvedBy ? JSON.parse(task.approvedBy).name : '-',
                    rejectedBy: task.rejectedBy ? JSON.parse(task.rejectedBy).name : '-'
                  }
                })
              }
            })
          }

          this.$emit('onFetch', this.form.dockingTaskCategories)
        }
      }
    },
    async onUploadShipyardFile (event) {
      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)
      if (res.status === 'success') {
        this.form.docShipyard = res.data.fileName
        this.form.docShipyardUrl = res.data.url
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    async onUploadDockingTaskAttachment (event, i, j) {
      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)
      if (res.status === 'success') {
        this.form.dockingTaskCategories[i].dockingTasks[j].attachmentJob = res.data.fileName
        this.form.dockingTaskCategories[i].dockingTasks[j].attachmentJobUrl = res.data.url
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    async onUploadDockingTaskSubContractorAttachment (event, i, j, k) {
      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)
      if (res.status === 'success') {
        this.form.dockingTaskCategories[i].dockingTasks[j].subContractors[k].attachmentShipyard = res.data.fileName
        this.form.dockingTaskCategories[i].dockingTasks[j].subContractors[k].attachmentShipyardUrl = res.data.url
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    async onSave () {
      let errorMsg = ''
      let isValid = true
      const payload = { ...this.form }
      payload.vehicleId = this.vehicleId
      payload.dockingTaskCategories.map((category, categoryIndex) => {
        category.dockingTasks = this.filteredDockingTasks(category.dockingTasks)
        return category
      }).every((category, categoryIndex) => {
        if (this.onCalcJobPercentage(categoryIndex) !== 100) {
          isValid = false
          errorMsg = `Persentase Docking Job "${category.dockingCategoryName}" Harus 100%`
          return false
        }

        category.dockingTasks.forEach((job, jobIndex) => {
          if (!job.isUsingManPower) {
            job.manPowers = []
          }

          if (!job.isUsingSubcontractor) {
            job.subContractors = []
          }
        })

        return true
      })

      if (this.dockingId) {
        payload.dockingId = this.dockingId
      }

      if (this.onCalcJobCatPercentage() !== 100) {
        isValid = false
        errorMsg = 'Persentase Job Category Harus 100%'
      }

      if (!isValid) {
        return this.$swal(
          `Input Tidak Berhasil`,
          errorMsg,
          'error'
        )
      }

      let method = ''

      if (this.action === 'CREATE_EMERGENCY') {
        method = 'createDockingEmergency'
      } else if (this.action === 'CREATE_FROM_SCHEDULE' || this.action === 'UPDATE') {
        method = 'createDockingFromSchedule'
      } else if (this.action === 'REVIEW') {
        method = 'reviewDocking'
      } else if (this.action === 'APPROVE') {
        method = 'approveDocking'
        const dockingTaskApproval = []
        payload.dockingTaskCategories.forEach(category => {
          category.dockingTasks.forEach(task => {
            dockingTaskApproval.push({
              dockingTaskId: task.dockingTaskId,
              isApprove: task.isApprove
            })
          })
        })

        payload.dockingTaskApproval = dockingTaskApproval
      }

      this.form.saving = true

      const { status, data } = await this[method](payload)

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        return
      }

      if ([500, 404, 403, 400].includes(status)) {
        this.$swal(
          `Oops!`,
          data.message,
          'error'
        )
        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      this.form.saving = false
      this.$emit('submit')
    },

    canApprove () {
      let totalCategories = 0
      let totalCategoriesDoneApproval = 0

      this.form.dockingTaskCategories.forEach(category => {
        totalCategories += category.dockingTasks.filter(task => task.dockingTaskId).length
        totalCategoriesDoneApproval += category.dockingTasks.filter(task =>
          task.dockingTaskId &&
          task.isApprove !== null &&
          task.isApprove !== '' &&
          task.isApprove !== undefined).length
      })

      return totalCategories > 0 && totalCategoriesDoneApproval > 0 && totalCategories === totalCategoriesDoneApproval
    },

    filteredDockingTasks (tasks) {
      return tasks.filter(task => !task.isRejected)
    },

    colorCard ({ status, isRejected, isApprove }) {
      if (status === 'APPROVE') {
        return 'success'
      }

      if (status === 'REVIEW') {
        return 'warning'
      }

      if (status === 'REJECT' || isRejected || isApprove === false) {
        return 'danger'
      }

      return 'info'
    },

    onDockingTaskMap () {
      function mapDockingTask (job) {
        return {
          dockingTaskId: '',
          dockingJobId: job.id,
          dockingJobName: job.name,
          startDate: '',
          estFinishDate: '',
          percentage: 0,
          attachmentJob: '',
          attachmentJobUrl: '',
          notes: '',
          manPowers: [
            {
              name: '',
              position: ''
            }
          ],
          subContractors: [
            {
              name: '',
              pic: '',
              offer: '',
              offerNegotiation: '',
              notes: '',
              attachmentShipyard: '',
              attachmentShipyardUrl: ''
            }
          ],
          isUsingManPower: false,
          isUsingSubcontractor: false
        }
      }
      this.groupOfJobs.forEach(group => {
        const existingCategory = this.form.dockingTaskCategories.find(category => category.dockingCategoryId === group.id)

        if (existingCategory) {
          group.jobs.forEach(job => {
            if (!existingCategory.dockingTasks.find(task => task.dockingJobId === job.id)) {
              existingCategory.dockingTasks.push(mapDockingTask(job))
            }
          })
        } else {
          this.form.dockingTaskCategories.push({
            taskCategoryId: '',
            dockingCategoryId: group.id,
            dockingCategoryName: group.name,
            percentage: 0,
            dockingTasks: group.jobs.map(job => mapDockingTask(job))
          })
        }
      })

      // Sync Deleted Value
      this.form.dockingTaskCategories = this.form.dockingTaskCategories
        .filter(category => this.groupOfJobs.find(group => group.id === category.dockingCategoryId))
        .map(category => {
          category.dockingTasks = category.dockingTasks
            .filter(task =>
              this.groupOfJobs.find(group =>
                group.id === category.dockingCategoryId
              ).jobs.find(job => job.id === task.dockingJobId)
            )

          return category
        })
    },
    onCalcJobCatPercentage () {
      return (_.sumBy(this.form.dockingTaskCategories, (category) => {
        return Number(category.percentage)
      }))
    },
    onCalcJobPercentage (categoryIndex) {
      if (!this.form.dockingTaskCategories[categoryIndex]) {
        return 0
      }

      return (_.sumBy(this.form.dockingTaskCategories[categoryIndex].dockingTasks.filter(task => !task.isRejected), (task) => {
        return Number(task.percentage)
      }))
    },
    onAddManPower (categoryIndex, jobIndex) {
      if (this.form.dockingTaskCategories[categoryIndex] && this.form.dockingTaskCategories[categoryIndex].dockingTasks[jobIndex]) {
        this.form.dockingTaskCategories[categoryIndex].dockingTasks[jobIndex].manPowers.push({
          name: '',
          position: ''
        })
      }
    },
    onRemoveManPower (categoryIndex, jobIndex, currentIndex) {
      if (this.form.dockingTaskCategories[categoryIndex] && this.form.dockingTaskCategories[categoryIndex].dockingTasks[jobIndex]) {
        this.form.dockingTaskCategories[categoryIndex].dockingTasks[jobIndex].manPowers.splice(currentIndex, 1)
      }
    },
    onAddSubContractor (categoryIndex, jobIndex) {
      if (this.form.dockingTaskCategories[categoryIndex] && this.form.dockingTaskCategories[categoryIndex].dockingTasks[jobIndex]) {
        this.form.dockingTaskCategories[categoryIndex].dockingTasks[jobIndex].subContractors.push({
          name: '',
          pic: '',
          offer: '',
          offerNegotiation: '',
          notes: '',
          attachmentShipyard: '',
          attachmentShipyardUrl: ''
        })
      }
    },
    onRemoveSubContractor (categoryIndex, jobIndex, currentIndex) {
      if (this.form.dockingTaskCategories[categoryIndex] && this.form.dockingTaskCategories[categoryIndex].dockingTasks[jobIndex]) {
        this.form.dockingTaskCategories[categoryIndex].dockingTasks[jobIndex].subContractors.splice(currentIndex, 1)
      }
    },
    onGenerateWaitingDockSpace () {
      if (!this.form.upSlip || !this.form.arrival) {
        this.form.waitingDockSpace = 0
        return
      }

      const uoSlip = this.convertToMoment(this.form.upSlip, 'DD-MM-YYYY')
      const arrival = this.convertToMoment(this.form.arrival, 'DD-MM-YYYY')

      this.form.waitingDockSpace = uoSlip.diff(arrival, 'days')
    },
    onValidateAllDates (key) {
      // validate upslip
      if (this.form.upSlip) {
        if (this.form.arrival) {
          if (!(this.convertToMoment(this.form.upSlip) >= this.convertToMoment(this.form.arrival))) {
            this.$swal(
              `Oops!`,
              'Up Slip Harus lebih dan sama dengan dari Arrival Shipyard',
              'error'
            )
            this.form[key] = ''
            return
          }
        }
      }

      // validate downslip
      if (this.form.downSlip) {
        if (this.form.arrival) {
          if (!(this.convertToMoment(this.form.downSlip) > this.convertToMoment(this.form.arrival))) {
            this.$swal(
              `Oops!`,
              'Down Slip Harus lebih dari Arrival Shipyard',
              'error'
            )
            this.form[key] = ''
          }
        }
      }

      // validate Complete Date
      if (this.form.deliveryDate) {
        if (this.form.downSlip) {
          if (!(this.convertToMoment(this.form.deliveryDate) >= this.convertToMoment(this.form.downSlip))) {
            this.$swal(
              `Oops!`,
              'Complete Date Harus lebih dan sama dengan dari Arrival Shipyard',
              'error'
            )
            this.form[key] = ''
          }
        }
      }
    },
    onDeleteTaskCategory (index) {
      this.form.dockingTaskCategories.splice(index, 1)
    }
  }
}
</script>

<style scoped>

</style>
