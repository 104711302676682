var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal---".concat(_vm.id),
      "size": "xl",
      "title": "Select Fleet",
      "header-bg-variant": "light",
      "header-text-variant": "dark",
      "footer-bg-variant": "light",
      "footer-text-variant": "dark"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var ok = _ref.ok,
          cancel = _ref.cancel,
          hide = _ref.hide;
        return [_c('b-row', [_c('b-col', {
          staticClass: "text-center",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "iq-bg-danger text-right",
          attrs: {
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v("Cancel")]), _c('b-button', {
          staticClass: "ml-2 text-right",
          attrs: {
            "type": "submit",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.submit();
            }
          }
        }, [_vm._v("Next "), _c('i', {
          staticClass: "fa fa-arrow-right"
        })])], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  }, [_c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search fleets..."
    },
    on: {
      "input": _vm.fetchFleets
    },
    model: {
      value: _vm.params.search,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "search", $$v);
      },
      expression: "params.search"
    }
  })], 1)], 1), _vm.loading ? _c('div', {
    staticClass: "text-center my-5"
  }, [_c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Spinning"
    }
  })], 1) : [_vm.fleets.length > 0 ? _c('b-row', {
    staticClass: "container-fleets"
  }, _vm._l(_vm.fleets, function (fleet, i) {
    var _domProps;
    return _c('b-col', {
      key: "fleet-".concat(i),
      staticClass: "text-center py-5 border-top",
      attrs: {
        "md": "3"
      }
    }, [_c('div', {
      staticClass: "custom-control custom-checkbox image-checkbox"
    }, [(_vm.multiple ? 'checkbox' : 'radio') === 'checkbox' ? _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.checkedFleets,
        expression: "checkedFleets"
      }],
      staticClass: "custom-control-input",
      attrs: {
        "id": "ck-fl-".concat(i),
        "type": "checkbox"
      },
      domProps: {
        "value": fleet.id,
        "checked": Array.isArray(_vm.checkedFleets) ? _vm._i(_vm.checkedFleets, fleet.id) > -1 : _vm.checkedFleets
      },
      on: {
        "change": function change($event) {
          var $$a = _vm.checkedFleets,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = fleet.id,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.checkedFleets = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.checkedFleets = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.checkedFleets = $$c;
          }
        }
      }
    }) : (_vm.multiple ? 'checkbox' : 'radio') === 'radio' ? _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.checkedFleets,
        expression: "checkedFleets"
      }],
      staticClass: "custom-control-input",
      attrs: {
        "id": "ck-fl-".concat(i),
        "type": "radio"
      },
      domProps: {
        "value": fleet.id,
        "checked": _vm._q(_vm.checkedFleets, fleet.id)
      },
      on: {
        "change": function change($event) {
          _vm.checkedFleets = fleet.id;
        }
      }
    }) : _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.checkedFleets,
        expression: "checkedFleets"
      }],
      staticClass: "custom-control-input",
      attrs: {
        "id": "ck-fl-".concat(i),
        "type": _vm.multiple ? 'checkbox' : 'radio'
      },
      domProps: (_domProps = {
        "value": fleet.id
      }, _domProps["value"] = _vm.checkedFleets, _domProps),
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.checkedFleets = $event.target.value;
        }
      }
    }), _c('label', {
      staticClass: "custom-control-label",
      attrs: {
        "for": "ck-fl-".concat(i)
      }
    }, [_c('img', {
      staticClass: "img-fluid",
      attrs: {
        "src": _vm._f("checkPhoto")(fleet.vehicleType.icon.url, require('@src/assets/images/fleet/PusherBarge.png')),
        "alt": "#"
      }
    })]), _c('small', [_c('strong', [_vm._v(_vm._s(fleet.name))])])])]);
  }), 1) : _c('div', {
    staticClass: "text-center my-5"
  }, [_c('p', {
    staticClass: "mb-0 text-muted"
  }, [_vm._v("No fleets data found.")])])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }