var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.fleetDetail ? _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("OPERATIONAL INPUT - " + _vm._s(_vm.fleetDetail.name))])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6",
            "md": "6"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Select Month First *"
          }
        }, [_c('date-picker', {
          attrs: {
            "value-type": "YYYY-MM",
            "type": "month",
            "placeholder": "Select Month",
            "format": "MMM YYYY"
          },
          on: {
            "change": _vm.generateYearMonthFormParams
          },
          model: {
            value: _vm.periodMonthYear,
            callback: function callback($$v) {
              _vm.periodMonthYear = $$v;
            },
            expression: "periodMonthYear"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Open"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getFcActivirites();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "6",
            "md": "6"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 2646450306)
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, _vm._l(_vm.form.activities, function (activity, index) {
    return _c('iq-card', {
      scopedSlots: _vm._u([{
        key: "body",
        fn: function fn() {
          return [index !== 0 ? _c('b-button', {
            staticClass: "float-right",
            attrs: {
              "size": "sm",
              "title": "Delete Certificate",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.removeActivity(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e(), _c('b-row', [_c('b-col', {
            attrs: {
              "lg": "3"
            }
          }, [_c('b-row', [_c('b-col', {
            attrs: {
              "md": "2"
            }
          }, [_c('span', {
            staticClass: "font-size-24 font-weight-bold text-black"
          }, [_vm._v("#" + _vm._s(parseInt(1 + index)))])]), _c('b-col', {
            staticClass: "mt-2",
            attrs: {
              "md": "10"
            }
          }, [_c('small', {
            staticStyle: {
              "margin-right": "5px"
            }
          }, [_vm._v(" Mother Vessel (Owned) ")]), _c('div', {
            staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
          }, [_c('div', {
            staticClass: "custom-switch-inner"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: activity.isMvOwned,
              expression: "activity.isMvOwned"
            }],
            staticClass: "custom-control-input",
            class: 'bg-success',
            attrs: {
              "type": "checkbox",
              "id": 'mvOwned' + index
            },
            domProps: {
              "checked": Array.isArray(activity.isMvOwned) ? _vm._i(activity.isMvOwned, null) > -1 : activity.isMvOwned
            },
            on: {
              "change": function change($event) {
                var $$a = activity.isMvOwned,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(activity, "isMvOwned", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(activity, "isMvOwned", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(activity, "isMvOwned", $$c);
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": 'mvOwned' + index,
              "data-on-label": "Yes",
              "data-off-label": "No"
            }
          })])])]), _c('b-col', {
            attrs: {
              "md": "2"
            }
          }), _c('b-col', {
            staticStyle: {
              "margin-top": "5px"
            },
            attrs: {
              "md": "10"
            }
          }, [activity.isMvOwned ? [_c('b-form-group', {
            attrs: {
              "label": "Select MV *"
            }
          }, [_c('v-select', {
            attrs: {
              "options": _vm.motherVesselVehicles,
              "label": "name",
              "reduce": function reduce(vehicle) {
                return vehicle.code;
              },
              "placeholder": "Select Vehicle"
            },
            model: {
              value: activity.mvId,
              callback: function callback($$v) {
                _vm.$set(activity, "mvId", $$v);
              },
              expression: "activity.mvId"
            }
          })], 1)] : [_c('b-form-group', {
            attrs: {
              "label": "Input Your MV *"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "text",
              "placeholder": "Input MV Name"
            },
            model: {
              value: activity.mvName,
              callback: function callback($$v) {
                _vm.$set(activity, "mvName", $$v);
              },
              expression: "activity.mvName"
            }
          })], 1)]], 2)], 1)], 1), _c('b-col', {
            attrs: {
              "lg": "2"
            }
          }, [_c('b-form-group', {
            staticClass: "mt-5",
            attrs: {
              "label": "Cargo Loaded on Vessel *"
            }
          }, [_c('b-input-group', {
            attrs: {
              "size": "md",
              "append": "Ton"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number"
            },
            model: {
              value: activity.cargoLoaded,
              callback: function callback($$v) {
                _vm.$set(activity, "cargoLoaded", $$v);
              },
              expression: "activity.cargoLoaded"
            }
          })], 1)], 1)], 1)], 1), _c('hr'), _vm._l(activity.detail, function (detail, indexDetail) {
            return _c('b-row', [_c('b-col', {
              staticClass: "text-right",
              attrs: {
                "md": "1"
              }
            }, [_c('span', {
              staticClass: "font-size-24 font-weight-bold text-black"
            }, [_vm._v("#" + _vm._s(parseInt(1 + indexDetail)))])]), _c('b-col', {
              staticClass: "mt-2",
              attrs: {
                "md": "11"
              }
            }, [indexDetail !== 0 ? _c('b-button', {
              staticClass: "float-right",
              attrs: {
                "size": "sm",
                "title": "Delete Certificate",
                "variant": "danger"
              },
              on: {
                "click": function click($event) {
                  $event.preventDefault();
                  return _vm.removeActivityDetail(index, indexDetail);
                }
              }
            }, [_c('i', {
              staticClass: "fa fa-trash"
            })]) : _vm._e(), _c('b-row', [_c('b-col', {
              attrs: {
                "md": "12"
              }
            }, [_c('small', {
              staticStyle: {
                "margin-right": "5px"
              }
            }, [_vm._v(" With Barge ? ")]), _c('div', {
              staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
            }, [_c('div', {
              staticClass: "custom-switch-inner"
            }, [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: detail.withBarge,
                expression: "detail.withBarge"
              }],
              staticClass: "custom-control-input",
              class: 'bg-success',
              attrs: {
                "type": "checkbox",
                "id": "withBarge-".concat(index, "-").concat(indexDetail)
              },
              domProps: {
                "checked": Array.isArray(detail.withBarge) ? _vm._i(detail.withBarge, null) > -1 : detail.withBarge
              },
              on: {
                "change": function change($event) {
                  var $$a = detail.withBarge,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false;
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v);
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(detail, "withBarge", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(detail, "withBarge", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(detail, "withBarge", $$c);
                  }
                }
              }
            }), _c('label', {
              staticClass: "custom-control-label",
              attrs: {
                "for": "withBarge-".concat(index, "-").concat(indexDetail),
                "data-on-label": "Yes",
                "data-off-label": "No"
              }
            })])])]), _c('b-col', {
              staticClass: "mt-1",
              attrs: {
                "md": "12"
              }
            }, [detail.withBarge ? [_c('b-row', [_c('b-col', {
              attrs: {
                "md": "3"
              }
            }, [_c('div', {
              staticClass: "form-group"
            }, [_c('label', [_vm._v("Barge (Owned)")]), _c('div', {
              staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color ml-2"
            }, [_c('div', {
              staticClass: "custom-switch-inner"
            }, [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: detail.isBargeOwned,
                expression: "detail.isBargeOwned"
              }],
              staticClass: "custom-control-input",
              class: 'bg-success',
              attrs: {
                "type": "checkbox",
                "id": "bgOwned-".concat(index, "-").concat(indexDetail)
              },
              domProps: {
                "checked": Array.isArray(detail.isBargeOwned) ? _vm._i(detail.isBargeOwned, null) > -1 : detail.isBargeOwned
              },
              on: {
                "change": function change($event) {
                  var $$a = detail.isBargeOwned,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false;
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v);
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(detail, "isBargeOwned", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(detail, "isBargeOwned", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(detail, "isBargeOwned", $$c);
                  }
                }
              }
            }), _c('label', {
              staticClass: "custom-control-label",
              attrs: {
                "for": "bgOwned-".concat(index, "-").concat(indexDetail),
                "data-on-label": "Yes",
                "data-off-label": "No"
              }
            })])]), detail.isBargeOwned ? [_c('v-select', {
              attrs: {
                "options": _vm.bargeVehicles,
                "label": "name",
                "reduce": function reduce(vehicle) {
                  return vehicle.code;
                },
                "placeholder": "Select Vehicle"
              },
              model: {
                value: detail.bargeId,
                callback: function callback($$v) {
                  _vm.$set(detail, "bargeId", $$v);
                },
                expression: "detail.bargeId"
              }
            })] : [_c('b-form-input', {
              attrs: {
                "type": "text",
                "placeholder": "Input Your Barge"
              },
              model: {
                value: detail.bargeName,
                callback: function callback($$v) {
                  _vm.$set(detail, "bargeName", $$v);
                },
                expression: "detail.bargeName"
              }
            })]], 2)]), _c('b-col', {
              staticClass: "mr-3",
              attrs: {
                "md": "2"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "A/Side"
              }
            }, [_c('date-picker', {
              attrs: {
                "type": "datetime",
                "placeholder": "Select date time",
                "value-type": "DD-MM-YYYY HH:mm",
                "format": "DD MMM YYYY HH:mm"
              },
              on: {
                "close": function close($event) {
                  return _vm.validateAllDatesActivityDetail(index, indexDetail, 'side');
                }
              },
              model: {
                value: detail.side,
                callback: function callback($$v) {
                  _vm.$set(detail, "side", $$v);
                },
                expression: "detail.side"
              }
            })], 1)], 1), _c('b-col', {
              staticClass: "mr-3",
              attrs: {
                "md": "2"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Comm *"
              }
            }, [_c('date-picker', {
              attrs: {
                "type": "datetime",
                "placeholder": "Select date time",
                "value-type": "DD-MM-YYYY HH:mm",
                "format": "DD MMM YYYY HH:mm"
              },
              on: {
                "close": function close($event) {
                  return _vm.validateAllDatesActivityDetail(index, indexDetail, 'comm');
                }
              },
              model: {
                value: detail.comm,
                callback: function callback($$v) {
                  _vm.$set(detail, "comm", $$v);
                },
                expression: "detail.comm"
              }
            })], 1)], 1), _c('b-col', {
              staticClass: "mr-3",
              attrs: {
                "md": "2"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Compl *"
              }
            }, [_c('date-picker', {
              attrs: {
                "type": "datetime",
                "placeholder": "Select date time",
                "value-type": "DD-MM-YYYY HH:mm",
                "format": "DD MMM YYYY HH:mm"
              },
              on: {
                "close": function close($event) {
                  return _vm.validateAllDatesActivityDetail(index, indexDetail, 'compl');
                }
              },
              model: {
                value: detail.compl,
                callback: function callback($$v) {
                  _vm.$set(detail, "compl", $$v);
                },
                expression: "detail.compl"
              }
            })], 1)], 1), _c('b-col', {
              staticClass: "mr-3",
              attrs: {
                "md": "2"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Cast Off *"
              }
            }, [_c('date-picker', {
              attrs: {
                "type": "datetime",
                "placeholder": "Select date time",
                "value-type": "DD-MM-YYYY HH:mm",
                "format": "DD MMM YYYY HH:mm"
              },
              on: {
                "close": function close($event) {
                  return _vm.validateAllDatesActivityDetail(index, indexDetail, 'castOf');
                }
              },
              model: {
                value: detail.castOf,
                callback: function callback($$v) {
                  _vm.$set(detail, "castOf", $$v);
                },
                expression: "detail.castOf"
              }
            })], 1)], 1), _c('b-col', {
              attrs: {
                "md": "1"
              }
            })], 1), _c('b-row', [_c('b-col', {
              attrs: {
                "md": "2"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Draft Survey *"
              }
            }, [_c('b-input-group', {
              attrs: {
                "size": "md",
                "append": "Ton"
              }
            }, [_c('b-form-input', {
              attrs: {
                "type": "number"
              },
              on: {
                "change": function change($event) {
                  return _vm.calculateDBS(index, indexDetail);
                }
              },
              model: {
                value: detail.cargoDraft,
                callback: function callback($$v) {
                  _vm.$set(detail, "cargoDraft", $$v);
                },
                expression: "detail.cargoDraft"
              }
            })], 1)], 1)], 1), _c('b-col', {
              attrs: {
                "md": "2"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Belt Scale FTS *"
              }
            }, [_c('b-input-group', {
              attrs: {
                "size": "md",
                "append": "Ton"
              }
            }, [_c('b-form-input', {
              attrs: {
                "type": "number"
              },
              on: {
                "change": function change($event) {
                  return _vm.calculateDBS(index, indexDetail);
                }
              },
              model: {
                value: detail.beltScale,
                callback: function callback($$v) {
                  _vm.$set(detail, "beltScale", $$v);
                },
                expression: "detail.beltScale"
              }
            })], 1)], 1)], 1), _c('b-col', {
              attrs: {
                "md": "2"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "D B/S *"
              }
            }, [_c('b-input-group', {
              attrs: {
                "size": "md",
                "append": "Ton"
              }
            }, [_c('b-form-input', {
              attrs: {
                "type": "number"
              },
              on: {
                "change": function change($event) {
                  return _vm.calculateDBS(index, indexDetail);
                }
              },
              model: {
                value: detail.cargoDbs,
                callback: function callback($$v) {
                  _vm.$set(detail, "cargoDbs", $$v);
                },
                expression: "detail.cargoDbs"
              }
            })], 1)], 1)], 1), _c('b-col', {
              attrs: {
                "md": "2"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Portion Tonnage *"
              }
            }, [_c('b-input-group', {
              attrs: {
                "size": "md",
                "append": "Ton"
              }
            }, [_c('b-form-input', {
              attrs: {
                "type": "number"
              },
              model: {
                value: detail.portionTonnage,
                callback: function callback($$v) {
                  _vm.$set(detail, "portionTonnage", $$v);
                },
                expression: "detail.portionTonnage"
              }
            })], 1)], 1)], 1), _c('b-col', {
              attrs: {
                "md": "2"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Origin Cargo *"
              }
            }, [_c('b-form-input', {
              attrs: {
                "type": "text"
              },
              model: {
                value: detail.cargoOrigin,
                callback: function callback($$v) {
                  _vm.$set(detail, "cargoOrigin", $$v);
                },
                expression: "detail.cargoOrigin"
              }
            })], 1)], 1), _c('b-col', {
              attrs: {
                "md": "2"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Cargo Hold *"
              }
            }, [_c('b-form-input', {
              attrs: {
                "type": "text"
              },
              model: {
                value: detail.cargoHold,
                callback: function callback($$v) {
                  _vm.$set(detail, "cargoHold", $$v);
                },
                expression: "detail.cargoHold"
              }
            })], 1)], 1)], 1)] : _vm._e(), _c('b-row', [_c('b-col', {
              attrs: {
                "md": "12"
              }
            }, [_c('b-alert', {
              attrs: {
                "show": true,
                "variant": "secondary"
              }
            }, [_c('div', {
              staticClass: "iq-alert-text text-center"
            }, [_vm._v("IDLE TIME & NOT TO COUNT")])])], 1), _c('b-col', {
              attrs: {
                "md": "7"
              }
            }, [_c('b-row', [_c('b-col', {
              attrs: {
                "md": "4"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Idle"
              }
            }, [_c('v-select', {
              attrs: {
                "label": "text",
                "options": _vm.paramIdles,
                "reduce": function reduce(idle) {
                  return idle.value;
                },
                "placeholder": "Select Idle"
              },
              model: {
                value: detail.idleParamType,
                callback: function callback($$v) {
                  _vm.$set(detail, "idleParamType", $$v);
                },
                expression: "detail.idleParamType"
              }
            })], 1)], 1), detail.idleParamType === 'Input Start-Finish' ? [_c('b-col', {
              attrs: {
                "md": "4"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Start *"
              }
            }, [_c('date-picker', {
              attrs: {
                "type": "datetime",
                "placeholder": "Select date time",
                "value-type": "DD-MM-YYYY HH:mm",
                "format": "DD MMM YYYY HH:mm"
              },
              on: {
                "close": function close($event) {
                  return _vm.validateAllDatesActivityDetail(index, indexDetail, 'idleParamStart');
                }
              },
              model: {
                value: detail.idleParamStart,
                callback: function callback($$v) {
                  _vm.$set(detail, "idleParamStart", $$v);
                },
                expression: "detail.idleParamStart"
              }
            })], 1)], 1), _c('b-col', {
              attrs: {
                "md": "4"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Finish *"
              }
            }, [_c('date-picker', {
              attrs: {
                "type": "datetime",
                "placeholder": "Select date time",
                "value-type": "DD-MM-YYYY HH:mm",
                "format": "DD MMM YYYY HH:mm"
              },
              on: {
                "close": function close($event) {
                  return _vm.validateAllDatesActivityDetail(index, indexDetail, 'idleParamEnd');
                }
              },
              model: {
                value: detail.idleParamEnd,
                callback: function callback($$v) {
                  _vm.$set(detail, "idleParamEnd", $$v);
                },
                expression: "detail.idleParamEnd"
              }
            })], 1)], 1)] : !detail.idleParamType ? [_c('small', [_vm._v("Choose your idle!")])] : [_c('b-col', {
              attrs: {
                "md": "6"
              }
            }, [_c('b-row', [_c('b-col', {
              attrs: {
                "md": "6"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Select Date *"
              }
            }, [_c('date-picker', {
              attrs: {
                "type": "datetime",
                "placeholder": "Select date time",
                "value-type": "DD-MM-YYYY HH:mm",
                "format": "DD MMM YYYY HH:mm"
              },
              model: {
                value: detail.idleParamEnd,
                callback: function callback($$v) {
                  _vm.$set(detail, "idleParamEnd", $$v);
                },
                expression: "detail.idleParamEnd"
              }
            })], 1)], 1), _c('b-col', {
              staticClass: "mt-2",
              attrs: {
                "md": "6"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Maneuvering ?"
              }
            }, [_c('div', {
              staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
            }, [_c('div', {
              staticClass: "custom-switch-inner"
            }, [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: detail.isManeuvering,
                expression: "detail.isManeuvering"
              }],
              staticClass: "custom-control-input",
              class: 'bg-success',
              attrs: {
                "type": "checkbox",
                "id": "connectmaneuvering-".concat(index, "-").concat(indexDetail)
              },
              domProps: {
                "checked": Array.isArray(detail.isManeuvering) ? _vm._i(detail.isManeuvering, null) > -1 : detail.isManeuvering
              },
              on: {
                "change": [function ($event) {
                  var $$a = detail.isManeuvering,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false;
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v);
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(detail, "isManeuvering", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(detail, "isManeuvering", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(detail, "isManeuvering", $$c);
                  }
                }, function ($event) {
                  return _vm.validateManeuvering(index, indexDetail);
                }]
              }
            }), _c('label', {
              staticClass: "custom-control-label",
              attrs: {
                "for": "connectmaneuvering-".concat(index, "-").concat(indexDetail),
                "data-on-label": "Yes",
                "data-off-label": "No"
              }
            })])])]), detail.isManeuvering ? _c('b-form-group', {
              attrs: {
                "label": "trigger subtraction from ?"
              }
            }, [_c('v-select', {
              attrs: {
                "label": "text",
                "options": _vm.triggerFCCastOff,
                "reduce": function reduce(triggerFCCastOff) {
                  return triggerFCCastOff.value;
                },
                "placeholder": "Select Idle"
              },
              model: {
                value: detail.manuveringReff,
                callback: function callback($$v) {
                  _vm.$set(detail, "manuveringReff", $$v);
                },
                expression: "detail.manuveringReff"
              }
            })], 1) : _vm._e()], 1)], 1)], 1)]], 2)], 1), _c('b-col', {
              attrs: {
                "md": "5"
              }
            }, [_c('table', {
              staticClass: "table table-striped table-box-shadow table-light"
            }, [_c('tbody', [detail.withBarge ? _c('tr', [_c('td', {
              attrs: {
                "width": "55%"
              }
            }, [_vm._v("Barge Cast Off")]), _c('td', {
              staticClass: "font-weight-bold",
              domProps: {
                "innerHTML": _vm._s(_vm.calculateBargeCastOffDurations(index, indexDetail))
              }
            })]) : _vm._e(), detail.withBarge ? _c('tr', [_c('td', [_vm._v("Barge Alongside")]), _c('td', {
              staticClass: "font-weight-bold",
              domProps: {
                "innerHTML": _vm._s(_vm.calculateBargeAlongsideDurations(index, indexDetail))
              }
            })]) : _vm._e(), _c('tr', [_c('td', [_c('v-select', {
              attrs: {
                "label": "text",
                "disabled": detail.isManeuvering,
                "options": _vm.notToCountAndCountIdles,
                "reduce": function reduce(notToCount) {
                  return notToCount.value;
                },
                "placeholder": "Select Idle"
              },
              model: {
                value: detail.idleName,
                callback: function callback($$v) {
                  _vm.$set(detail, "idleName", $$v);
                },
                expression: "detail.idleName"
              }
            })], 1), _c('td', {
              staticClass: "font-weight-bold",
              domProps: {
                "innerHTML": _vm._s(_vm.calculateIdleTimeDurations(index, indexDetail))
              }
            })]), _c('tr', [_c('td', [_vm._v("Draft Survey")]), _c('td', {
              staticClass: "font-weight-bold"
            }, [_c('b-input-group', {
              attrs: {
                "size": "md",
                "append": "Minutes"
              }
            }, [_c('b-form-input', {
              attrs: {
                "type": "number",
                "value": "0"
              },
              model: {
                value: detail.idleDraftSurveyMinutes,
                callback: function callback($$v) {
                  _vm.$set(detail, "idleDraftSurveyMinutes", $$v);
                },
                expression: "detail.idleDraftSurveyMinutes"
              }
            })], 1), _c('b-input-group', {
              staticClass: "mt-1",
              attrs: {
                "size": "md",
                "append": "Second"
              }
            }, [_c('b-form-input', {
              attrs: {
                "type": "number",
                "value": "0"
              },
              model: {
                value: detail.idleDraftSurveySecond,
                callback: function callback($$v) {
                  _vm.$set(detail, "idleDraftSurveySecond", $$v);
                },
                expression: "detail.idleDraftSurveySecond"
              }
            })], 1)], 1)])])])]), _c('b-col', {
              attrs: {
                "md": "12"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Remarks"
              }
            }, [_c('b-form-textarea', {
              attrs: {
                "id": "remarks",
                "rows": "2"
              },
              model: {
                value: detail.remarks,
                callback: function callback($$v) {
                  _vm.$set(detail, "remarks", $$v);
                },
                expression: "detail.remarks"
              }
            })], 1)], 1)], 1)], 2), _c('hr'), _c('b-col', {
              attrs: {
                "md": "12"
              }
            }, [_c('b-button', {
              staticClass: "mb-3 mr-1",
              attrs: {
                "block": "",
                "size": "sm",
                "variant": "light"
              },
              on: {
                "click": function click($event) {
                  $event.preventDefault();
                  return _vm.addActivityDetail(index);
                }
              }
            }, [_c('i', {
              staticClass: "ri-add-line"
            }), _vm._v(" Add More Data")])], 1)], 1)], 1)], 1);
          })];
        },
        proxy: true
      }], null, true)
    });
  }), 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "mb-3 mr-1",
    attrs: {
      "block": "",
      "size": "sm",
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.addActivity();
      }
    }
  }, [_c('i', {
    staticClass: "ri-add-line"
  }), _vm._v(" Add Vessel (MV)")])], 1), _c('b-col', {
    staticClass: "pull-right text-center mt-3 mb-5",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.save();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" Save")]), _c('b-button', {
    staticClass: "iq-bg-danger ml-3",
    attrs: {
      "type": "submit",
      "variant": "none"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }