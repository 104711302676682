<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" class="text-center mb-3">
        <template v-for="(item, index) in typeFormInsurance">
          <b-form-radio
            v-model="incident.type_form"
            class="custom-radio-color-checked"
            inline
            :color="item.color"
            name="color"
            :key="index"
            :value="item.value"
            :disabled="readonly"
          ><strong><h5>{{ item.label }}</h5></strong></b-form-radio
          >
        </template>
      </b-col>
      <b-col cols="12" md="6" class="mb-3">
        <h5 class="card-title text-success"><b>INFORMATION OF ACCIDENT/INCIDENT</b></h5>
        <b-form-group class="row" label-cols-sm="3" label="Company *" label-for="company">
          <b-form-select plain v-model="incident.company_id" size="lg" :disabled="readonly">
            <b-form-select-option :value="null">Select Company</b-form-select-option>
            <b-form-select-option
              v-for="company in companies"
              :key="company.value"
              :value="company.value"
            >
              {{ company.label }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group class="row" label-cols-sm="3" label="Fleet Name *" label-for="vehicles">
          <b-form-select plain v-model="incident.vehicle_id" size="lg" :disabled="readonly">
            <b-form-select-option :value="null">Select Fleets</b-form-select-option>
            <b-form-select-option
              v-for="vehicle in vehicles"
              :key="vehicle.value"
              :value="vehicle.value"
            >
              {{ vehicle.label }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group class="row" label-cols-sm="3" label="Date of Loss (DOL) *" label-for="dol">
          <b-form-input type="text" v-model="incident.date_of_loss" :disabled="readonly"></b-form-input>
        </b-form-group>
        <b-form-group class="row" label-cols-sm="3" label="Place of Loss (POL) *" label-for="pol">
          <b-form-input type="text" v-model="incident.place_of_loss" :disabled="readonly"></b-form-input>
        </b-form-group>
        <b-form-group class="row" label-cols-sm="3" label="Type of Coverage *" label-for="coverage">
          <b-form-select plain v-model="incident.type_coverage_id" size="lg" :disabled="readonly">
            <b-form-select-option :value="null">Select of Coverage</b-form-select-option>
            <b-form-select-option
              v-for="coverage in coverages"
              :key="coverage.value"
              :value="coverage.value"
            >
              {{ coverage.label }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group class="row" label-cols-sm="3" label="Type of Loss *" label-for="loss">
          <b-form-select plain v-model="incident.type_loss_id" size="lg" :disabled="readonly">
            <b-form-select-option :value="null">Select of Loss</b-form-select-option>
            <b-form-select-option
              v-for="loss in losses"
              :key="loss.value"
              :value="loss.value"
            >
              {{ loss.label }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6" class="mb-3">
        <h5 class="card-title text-success"><b>CLAIMANT</b></h5>
        <b-form-group class="row" label-cols-sm="3" label="Company *" label-for="company">
          <b-form-select plain v-model="incident.company_claimant_id" size="lg" :disabled="readonly">
            <b-form-select-option :value="null">Select Company</b-form-select-option>
            <b-form-select-option
              v-for="company in companies"
              :key="company.value"
              :value="company.value"
            >
              {{ company.label }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group class="row" label-cols-sm="3" label="Name *" label-for="name">
          <b-form-input type="text" v-model="incident.name" :disabled="readonly"></b-form-input>
        </b-form-group>
        <b-form-group class="row" label-cols-sm="3" label="Position *" label-for="position">
          <b-form-input type="text" v-model="incident.position" :disabled="readonly"></b-form-input>
        </b-form-group>
        <b-form-group class="row" label-cols-sm="3" label="Department of FLeet *" label-for="departments">
          <b-form-select plain v-model="incident.department_id" size="lg" :disabled="readonly">
            <b-form-select-option :value="null">Select Department</b-form-select-option>
            <b-form-select-option
              v-for="department in departments"
              :key="department.value"
              :value="department.value"
            >
              {{ department.label }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <h5 class="card-title text-success"><b>DETAILS OF CHRONOLOGY</b></h5>
        <b-form-group label="Decribe of Detail Chronology">
          <b-form-textarea id="exampleFormControlTextarea1" rows="5" v-model="incident.details_of_chronology" :disabled="readonly"></b-form-textarea>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <h5 class="card-title text-success"><b>ESTIMATE DAMAGE OR LOSS</b></h5>
        <b-form-group label="Decribe of Estimate Damage or Loss">
          <b-form-textarea id="exampleFormControlTextarea1" rows="5" v-model="incident.estimate_damage" :disabled="readonly"></b-form-textarea>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="6">
        <h5 class="card-title text-success"><b>CONCLUSION</b></h5>
        <b-form-group label="Decribe of Conclusion">
          <b-form-textarea id="exampleFormControlTextarea1" rows="3" v-model="incident.conclusion"></b-form-textarea>
        </b-form-group>
      </b-col>
      <b-col cols="6" md="6">
        <h5 class="card-title text-success"><b>COST</b></h5>
        <b-form-group label="Estimate your cost">
          <div class="input-group">
            <div class="input-group-append">
              <span class="input-group-text">Rp</span>
            </div>
            <b-form-input type="number" v-model="incident.cost" :disabled="readonly"></b-form-input>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'

export default {
  name: 'ComponentClaim',
  mounted () {
    xray.index()
  },
  props: {
    incident: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    companies: {
      type: Array,
      default: () => []
    },
    vehicles: {
      type: Array,
      default: () => []
    },
    departments: {
      type: Array,
      default: () => []
    },
    coverages: {
      type: Array,
      default: () => []
    },
    losses: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      statementTruth: false,
      typeFormInsurance: [
        {
          label: 'Claim',
          color: 'warning',
          value: 'claim',
        },
        {
          label: 'Non Claim',
          color: 'info',
          value: 'non_claim'
        }
      ]
    }
  }
}
</script>
