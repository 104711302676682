import axios from './auth'

const MAINTENANCE_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/maintenance`
const RUNNING_HOURS_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/maintenance/rh`
const ROB_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/maintenance/rob`

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getJobType({ commit }, params = {}) {
    const url = `${MAINTENANCE_BASE_URL}/job-type`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getJobClass({ commit }, params = {}) {
    const url = `${MAINTENANCE_BASE_URL}/job-class`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getMasterEquipment({ commit }, params = {}) {
    const url = `${MAINTENANCE_BASE_URL}/master`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getComponent({ commit }, params = {}) {
    const url = `${MAINTENANCE_BASE_URL}/component`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getComponentSetup({ commit }, dataRequest) {
    const url = `${MAINTENANCE_BASE_URL}/setup/vehicle/${dataRequest.vehicleId}/component/${dataRequest.componentId}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: dataRequest.params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async checkHasComponentSetup({ commit }, dataRequest) {
    const url = `${MAINTENANCE_BASE_URL}/setup/component/${dataRequest.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: dataRequest.params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getMaintenance({ commit }, params) {
    const url = `${MAINTENANCE_BASE_URL}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getMaintenanceFinished({ commit }, params) {
    const url = `${MAINTENANCE_BASE_URL}/finish`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getInitiation({ commit }, params) {
    const url = `${MAINTENANCE_BASE_URL}/init`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getJobTypeById({ commit }, id) {
    const url = `${MAINTENANCE_BASE_URL}/job-type/${id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getJobClassById({ commit }, id) {
    const url = `${MAINTENANCE_BASE_URL}/job-class/${id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getMasterEquipmentById({ commit }, id) {
    const url = `${MAINTENANCE_BASE_URL}/master/${id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getComponentById({ commit }, id) {
    const url = `${MAINTENANCE_BASE_URL}/component/${id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getComponentSetupById({ commit }, dataRequest) {
    const url = `${MAINTENANCE_BASE_URL}/setup/vehicle/${dataRequest.vehicleId}/component/${dataRequest.componentId}/${dataRequest.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async createJobType({ commit }, payload) {
    const url = `${MAINTENANCE_BASE_URL}/job-type`

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: payload,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async createJobClass({ commit }, payload) {
    const url = `${MAINTENANCE_BASE_URL}/job-class`

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: payload,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async createMasterEquipment({ commit }, payload) {
    const url = `${MAINTENANCE_BASE_URL}/master`

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: payload,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async createComponent({ commit }, payload) {
    const url = `${MAINTENANCE_BASE_URL}/component`

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: payload,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async createComponentSetup({ commit }, dataPayload) {
    const url = `${MAINTENANCE_BASE_URL}/setup/vehicle/${dataPayload.vehicleId}/component/${dataPayload.componentId}`

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: dataPayload.payload,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async saveInitMaintenance({ commit }, dataPayload) {
    const url = `${MAINTENANCE_BASE_URL}/init`

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: dataPayload,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async saveComponentStructures({ commit }, payload) {
    const url = `${MAINTENANCE_BASE_URL}/component/structures`

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: payload.data,
      params: payload.params,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getMaintenanceDetail({ commit }, id) {
    const url = `${MAINTENANCE_BASE_URL}/${id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async saveCommentMaintenance({ commit }, payload) {
    const url = `${MAINTENANCE_BASE_URL}/${payload.id}/comments`

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: {
        comment: payload.comment,
      },
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async saveDoMaintenance({ commit }, payload) {
    const url = `${MAINTENANCE_BASE_URL}/${payload.id}`

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: payload.data,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async savePostponeMaintenance({ commit }, payload) {
    const url = `${MAINTENANCE_BASE_URL}/${payload.id}/postpone`

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: payload.data,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async saveReviewMaintenance({ commit }, payload) {
    const url = `${MAINTENANCE_BASE_URL}/${payload.id}/review`

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async saveApproveMaintenance({ commit }, payload) {
    const url = `${MAINTENANCE_BASE_URL}/${payload.id}/approve`

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async updateJobType({ commit }, payload) {
    const url = `${MAINTENANCE_BASE_URL}/job-type/${payload.id}`

    const method = 'put'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: payload.data,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async updateJobClass({ commit }, payload) {
    const url = `${MAINTENANCE_BASE_URL}/job-class/${payload.id}`

    const method = 'put'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: payload.data,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async updateMasterEquipment({ commit }, payload) {
    const url = `${MAINTENANCE_BASE_URL}/master/${payload.id}`

    const method = 'put'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: payload.data,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async updateComponent({ commit }, payload) {
    const url = `${MAINTENANCE_BASE_URL}/component/${payload.id}`

    const method = 'put'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: payload.data,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async updateComponentSetup({ commit }, payload) {
    const url = `${MAINTENANCE_BASE_URL}/setup/vehicle/${payload.vehicleId}/component/${payload.componentId}/${payload.id}`

    const method = 'put'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: payload.payload,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async updateMaintenance({ commit }, payload) {
    const url = `${MAINTENANCE_BASE_URL}/${payload.id}`

    const method = 'put'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }

    const config = {
      url,
      method,
      data: payload.data,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async deleteJobTypeById({ commit }, id) {
    const url = `${MAINTENANCE_BASE_URL}/job-type/${id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      await axios(config)
      return { status: 'success' }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async deleteJobClassById({ commit }, id) {
    const url = `${MAINTENANCE_BASE_URL}/job-class/${id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      await axios(config)
      return { status: 'success' }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async deleteMasterById({ commit }, id) {
    const url = `${MAINTENANCE_BASE_URL}/master/${id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      await axios(config)
      return { status: 'success' }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async deleteComponentById({ commit }, id) {
    const url = `${MAINTENANCE_BASE_URL}/component/${id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      await axios(config)
      return { status: 'success' }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async deleteComponentSetupyId({ commit }, dataReq) {
    const url = `${MAINTENANCE_BASE_URL}/setup/vehicle/${dataReq.vehicleId}/component/${dataReq.componentId}/${dataReq.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      await axios(config)
      return { status: 'success' }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async deleteInitMaintenanceById({ commit }, id) {
    const url = `${MAINTENANCE_BASE_URL}/init/${id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      await axios(config)
      return { status: 'success' }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getRunningHours({ commit }, params) {
    const url = `${RUNNING_HOURS_URL}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getRunningHoursExport({ commit }, params) {
    const url = `${RUNNING_HOURS_URL}/export`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      responseType: 'arraybuffer',
      params: params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getLatestRunningHours({ commit }, vehicleId) {
    const url = `${RUNNING_HOURS_URL}/latest`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: {
        vehicleId,
      },
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async saveRunningHours({ commit }, { method, data }) {
    const url = `${RUNNING_HOURS_URL}`

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }

    const config = {
      url,
      method,
      data,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async deleteRunningHours({ commit }, { vehicleId, date }) {
    const url = `${RUNNING_HOURS_URL}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: {
        vehicleId,
        date,
      },
    }

    try {
      await axios(config)
      return { status: 'success' }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getRob({ commit }, params) {
    const url = `${ROB_URL}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getLatestRob({ commit }, vehicleId) {
    const url = `${ROB_URL}/latest`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: {
        vehicleId,
      },
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getRobExport({ commit }, params) {
    const url = `${RUNNING_HOURS_URL}/export`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      responseType: 'arraybuffer',
      params: params,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async saveRob({ commit }, { method, data }) {
    const url = `${ROB_URL}`

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }

    const config = {
      url,
      method,
      data,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async deleteRob({ commit }, { vehicleId, date }) {
    const url = `${ROB_URL}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params: {
        vehicleId,
        date,
      },
    }

    try {
      await axios(config)
      return { status: 'success' }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
}
