var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "iq-comingsoon pt-5"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-sm-9 text-center"
  }, [_c('div', {
    staticClass: "iq-comingsoon-info"
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "img-fluid w-25",
    attrs: {
      "src": require('../../../assets/images/logo.png'),
      "alt": "logo"
    }
  })])])])]), _c('form', {
    attrs: {
      "action": "#",
      "method": "post"
    },
    on: {
      "submit": _vm.onSave
    }
  }, [_c('div', {
    staticClass: "row justify-content-center mt-3"
  }, [_c('div', {
    staticClass: "col-lg-8"
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("FORM INTERVIEW")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.filter",
            modifiers: {
              "filter": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "visible": "",
            "id": "filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Candidate Name *",
            "label-for": "candidate_list"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.optionsCandidateList,
            "reduce": function reduce(optionsCandidateList) {
              return optionsCandidateList.value;
            },
            "placeholder": "Select Candidate Name",
            "required": ""
          },
          model: {
            value: _vm.form.candidate_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "candidate_id", $$v);
            },
            expression: "form.candidate_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Interview Category",
            "placeholder": "Interview Category",
            "label": "name",
            "track-by": "id_interview_section_level",
            "options": _vm.criteria_interview,
            "multiple": true,
            "taggable": true,
            "required": ""
          },
          model: {
            value: _vm.interview_category,
            callback: function callback($$v) {
              _vm.interview_category = $$v;
            },
            expression: "interview_category"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.mergeInterview
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, _vm._l(_vm.interview_category_selected, function (interview, keyInterview) {
          return _c('fieldset', {
            key: keyInterview,
            staticClass: "form-group border p-3"
          }, [_c('legend', {
            staticClass: "w-auto px-2",
            staticStyle: {
              "font-size": "12pt"
            }
          }, [_vm._v(_vm._s(interview === null || interview === void 0 ? void 0 : interview.name))]), _c('b-row', [_c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('table', {
            staticClass: "table table-striped"
          }, [_c('thead', [_c('tr', {
            staticClass: "text-center"
          }, [_c('th', {
            attrs: {
              "width": "45%"
            }
          }), _vm._l(_vm.point_interview, function (item, index) {
            return _c('th', {
              key: index,
              attrs: {
                "width": "15%"
              }
            }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.name) + " "), _c('br'), _vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.value_letter))]);
          })], 2)]), _c('tbody', _vm._l(interview.data_criteria, function (criteria, keyCriteria) {
            return _c('tr', {
              key: keyCriteria
            }, [_c('th', [_vm._v(_vm._s(criteria.content)), _c('br'), _c('i', [_vm._v("Pengetahuan Permesinan")])]), _vm._l(criteria === null || criteria === void 0 ? void 0 : criteria.point, function (point, keyPoint) {
              return _c('td', {
                key: keyPoint,
                staticClass: "text-center"
              }, [[_c('b-form-checkbox', {
                staticClass: "ml-4",
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": ""
                },
                on: {
                  "change": function change($event) {
                    return _vm.selectMenus(point, keyInterview, keyCriteria, keyPoint);
                  }
                },
                model: {
                  value: point.checked,
                  callback: function callback($$v) {
                    _vm.$set(point, "checked", $$v);
                  },
                  expression: "point.checked"
                }
              })]], 2);
            })], 2);
          }), 0)]), _c('label', {
            attrs: {
              "for": "komentar"
            }
          }, [_c('strong', [_vm._v("Komentar")])]), _c('b-form-textarea', {
            attrs: {
              "rows": "2",
              "value": "Comments if any / Komentar jika ada",
              "required": ""
            },
            model: {
              value: interview.comment,
              callback: function callback($$v) {
                _vm.$set(interview, "comment", $$v);
              },
              expression: "interview.comment"
            }
          })], 1)], 1)], 1);
        }), 0), _vm.interview_category_selected.length <= 0 ? _c('div', {
          staticClass: "px-4 mb-4 d-flex align-items-center justify-content-center w-100 bg-active h5"
        }, [_c('strong', {}, [_vm._v(" PILIH CANDIDATE DAN INTERVIEW CATEGORY TERLEBIH DAHULU. ")])]) : _vm._e(), _c('hr'), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "Clasification *",
            "for": "nama_kandidiat"
          }
        }, [_c('strong', [_vm._v("Wawancara Oleh")]), _c('br'), _c('i', [_vm._v("Interview by")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-input', {
          attrs: {
            "id": "job_name",
            "type": "text",
            "placeholder": "Nama",
            "required": ""
          },
          model: {
            value: _vm.form.interview_data.wawancara_oleh,
            callback: function callback($$v) {
              _vm.$set(_vm.form.interview_data, "wawancara_oleh", $$v);
            },
            expression: "form.interview_data.wawancara_oleh"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "Clasification *",
            "for": "nama_kandidiat"
          }
        }, [_c('strong', [_vm._v("Hasil Wawancara")]), _c('br'), _c('i', [_vm._v("Result Interview")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.wawancara,
            "size": "lg",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Hasil Wawancara")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.form.interview_data.hasil_wawancara,
            callback: function callback($$v) {
              _vm.$set(_vm.form.interview_data, "hasil_wawancara", $$v);
            },
            expression: "form.interview_data.hasil_wawancara"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "Clasification *",
            "for": "nama_kandidiat"
          }
        }, [_c('strong', [_vm._v("Urgensi Wawancara")]), _c('br'), _c('i', [_vm._v("Interview Urgency")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsUrgensi,
            "size": "lg",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Urgensi")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.form.interview_data.urgensi,
            callback: function callback($$v) {
              _vm.$set(_vm.form.interview_data, "urgensi", $$v);
            },
            expression: "form.interview_data.urgensi"
          }
        })], 1)]), _c('div', {
          staticClass: "form-group row"
        }, [_c('label', {
          staticClass: "col-sm-6",
          attrs: {
            "label": "Clasification *",
            "for": "nama_kandidiat"
          }
        }, [_c('strong', [_vm._v("Lokasi Wawancara")]), _c('br'), _c('i', [_vm._v("Interview Location")])]), _c('div', {
          staticClass: "col-sm-6"
        }, [_c('b-form-input', {
          attrs: {
            "id": "location",
            "type": "text",
            "placeholder": "Lokasi Wawancara",
            "required": ""
          },
          model: {
            value: _vm.form.interview_data.location,
            callback: function callback($$v) {
              _vm.$set(_vm.form.interview_data, "location", $$v);
            },
            expression: "form.interview_data.location"
          }
        })], 1)])]), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "6"
          }
        }, [_c('b-button', {
          staticClass: "float-right ml-3",
          attrs: {
            "type": "submit",
            "block": "",
            "variant": "primary",
            "disabled": _vm.saving
          }
        }, [_c('i', {
          staticClass: "fa fa-save"
        }), _vm._v(" Save")]), _c('b-button', {
          staticClass: "iq-bg-danger float-right",
          attrs: {
            "type": "reset",
            "block": "",
            "variant": "none"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_c('i', {
          staticClass: "fa fa-close"
        }), _vm._v(" Reset Form")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }