import axios from './auth'

const REASON_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/reasons`;

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
    async getReasons({ commit }, params = {}) {
        const url = REASON_BASE_URL;

        const method = "get";
        const headers = {};
        const config = {
            url,
            method,
            headers,
            params
        };

        try {
            const response = await axios(config);
            let data = response.data;
            return {
                status: 'success',
                data,
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async getReasonById({ commit }, payload) {
        const url = `${REASON_BASE_URL}/${payload}`;

        const method = "get";
        const headers = {};
        const config = {
            url,
            method,
            headers
        };

        try {
            const response = await axios(config);
            let data = response.data.data;
            return {
                status: 'success',
                data,
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async addReason({ commit }, payload) {
        const url = REASON_BASE_URL;

        const method = "post";
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        const config = {
            url,
            method,
            data: payload,
            headers
        };

        try {
        const response = await axios(config);
        let data = response.data.data;
        return {
            status: 'success',
            data,
        };
        } catch (e) {
        return {
            status: 'error',
            data: e.response.data
        };
        }
    },
    async updateReason({ commit }, payload) {
        const url = `${REASON_BASE_URL}/${payload.id}`;

        const method = "put";
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        const config = {
            url,
            method,
            data: payload.data,
            headers
        };

        try {
        const response = await axios(config);
        let data = response.data.data;
        return {
            status: 'success',
            data,
        };
        } catch (e) {
        return {
            status: 'error',
            data: e.response.data
        };
        }
    },
    async deleteReason({ commit }, payload) {
        const url = `${REASON_BASE_URL}/${payload}`;

        const method = "delete";
        const headers = {};
        const config = {
            url,
            method,
            headers
        };

        try {
            await axios(config);
            return { status: 'success' };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async exportReasons({ commit }, params = {}) {
        const url = `${REASON_BASE_URL}/export`;

        const method = "get";
        const headers = {};
        const config = {
            url,
            method,
            headers,
            params,
            responseType: 'blob'
        };

        try {
            const response = await axios(config);
            let data = response.data;
            return {
                status: 'success',
                data,
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
}
