var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("PRODUCTS")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.filter",
            modifiers: {
              "filter": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-4",
          attrs: {
            "visible": "",
            "id": "filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "height": "35px"
          },
          attrs: {
            "id": "search",
            "placeholder": "Type Product"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1), _c('b-col', {
          attrs: {
            "md": "8"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Add Data',
            expression: "'Add Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              _vm.slideAddProduct = !_vm.slideAddProduct;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        })]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Refresh Data',
            expression: "'Refresh Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-refresh"
        })]), _c('b-dropdown', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Export Data',
            expression: "'Export Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "toggle-class": "text-decoration-none",
            "no-caret": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-save m-0"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _vm._v("Export Excel")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf mr-2"
        }), _vm._v("Export PDF")])], 1)], 1)], 1)], 1), _c('b-sidebar', {
          attrs: {
            "id": "slideAddProduct",
            "title": "NEW DATA",
            "width": "60%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var hide = _ref.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form', [_c('b-form-group', {
                attrs: {
                  "label": "Product Name",
                  "label-for": "productName"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "productName",
                  "type": "text"
                }
              })], 1), _vm._l(_vm.state, function (item, index) {
                return [_c('b-form-radio', {
                  key: index,
                  attrs: {
                    "inline": "",
                    "name": item.name,
                    "value": item.value,
                    "disabled": item.disabled
                  },
                  model: {
                    value: _vm.stateActive[item[Object.keys(item)[0]]],
                    callback: function callback($$v) {
                      _vm.$set(_vm.stateActive, item[Object.keys(item)[0]], $$v);
                    },
                    expression: "stateActive[item[Object.keys(item)[0]]]"
                  }
                }, [_vm._v(_vm._s(item.label))])];
              })], 2), _c('hr'), _c('tab-nav', {
                staticClass: "text-primary",
                attrs: {
                  "tabs": true
                }
              }, [_c('tab-nav-items', {
                attrs: {
                  "active": true,
                  "id": "first-tab",
                  "ariaControls": "tab-first",
                  "role": "tab",
                  "ariaSelected": true,
                  "title": "General Information"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "second-tab",
                  "ariaControls": "tab-second",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Sales"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "third-tab",
                  "ariaControls": "tab-third",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Purchase"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "fourth-tab",
                  "ariaControls": "tab-fourth",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Inventory Stock"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "fifth-tab",
                  "ariaControls": "tab-fifth",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Accounting"
                }
              })], 1), _c('div', {
                staticClass: "tab-content",
                attrs: {
                  "id": "myTabContent"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "tab-first"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Product Type",
                  "label-for": "productType"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionProductType,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Product Type")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectProductType,
                  callback: function callback($$v) {
                    _vm.selectProductType = $$v;
                  },
                  expression: "selectProductType"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Billing Policy",
                  "label-for": "billingPolicy"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionBillingPolicy,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Billing Policy")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectBillingPolicy,
                  callback: function callback($$v) {
                    _vm.selectBillingPolicy = $$v;
                  },
                  expression: "selectBillingPolicy"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Product Category",
                  "label-for": "productCategory"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionProductCat,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Product Category")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectProductCat,
                  callback: function callback($$v) {
                    _vm.selectProductCat = $$v;
                  },
                  expression: "selectProductCat"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Photo Product",
                  "label-for": "photoProduct"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('b-img', {
                staticClass: "w-50 mt-2",
                attrs: {
                  "thumbnail": "",
                  "src": require('../../assets/images/page-img/material.jpg'),
                  "alt": "Responsive image"
                }
              })], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Item Price",
                  "label-for": "price"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "text",
                  "id": "price"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Customer Tax",
                  "label-for": "customerTax"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "number",
                  "id": "customerTax"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Cost Production",
                  "label-for": "costProduction"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "number",
                  "id": "costProduction"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Reference",
                  "label-for": "reference"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "text",
                  "id": "reference"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Barcode",
                  "label-for": "barcode"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "text",
                  "id": "barcode"
                }
              })], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Remarks",
                  "label-for": "remarks"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "remarks",
                  "rows": "3"
                }
              })], 1)], 1)], 1)], 1), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "tab-second",
                  "aria-labelled-by": "second-tab"
                }
              }, [_c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Product Relate",
                  "label-for": "productRelate"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionInventory,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Other Products")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectInventory,
                  callback: function callback($$v) {
                    _vm.selectInventory = $$v;
                  },
                  expression: "selectInventory"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Description",
                  "label-for": "taxID"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "address",
                  "rows": "2"
                }
              })], 1)], 1)], 1)], 1), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "tab-third",
                  "aria-labelled-by": "third-tab"
                }
              }, [_c('b', {
                staticClass: "text-primary"
              }, [_vm._v("OTHER VENDOR")]), _c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('table', {
                staticClass: "table table-sm"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("Vendor Name")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Product ID")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Product Name")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Price")])])]), _c('tbody', [_c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "text",
                  "id": "vendorName"
                }
              })], 1), _c('td', {
                staticClass: "pl-3"
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionInventory,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Other Products")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectInventory,
                  callback: function callback($$v) {
                    _vm.selectInventory = $$v;
                  },
                  expression: "selectInventory"
                }
              })], 1), _c('td', {
                staticClass: "pl-3"
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "text",
                  "id": "vendorName"
                }
              })], 1), _c('td', {
                staticClass: "pl-3"
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "number",
                  "id": "price"
                }
              })], 1)]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "text",
                  "id": "vendorName"
                }
              })], 1), _c('td', {
                staticClass: "pl-3"
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionInventory,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Other Products")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectInventory,
                  callback: function callback($$v) {
                    _vm.selectInventory = $$v;
                  },
                  expression: "selectInventory"
                }
              })], 1), _c('td', {
                staticClass: "pl-3"
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "text",
                  "id": "vendorName"
                }
              })], 1), _c('td', {
                staticClass: "pl-3"
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "number",
                  "id": "price"
                }
              })], 1)])])]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v("Add Row Data")]), _c('b-button', {
                staticClass: "float-right mr-1",
                attrs: {
                  "variant": "danger",
                  "size": "sm"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash"
              }), _vm._v("Delete Row Data")])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('hr')]), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form', [_c('b-form-group', {
                attrs: {
                  "label": "TAX",
                  "label-cols-md": "4",
                  "label-for": "tax"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "text",
                  "id": "tax"
                }
              })], 1), _c('b-form-group', {
                attrs: {
                  "label": "Term Payment",
                  "label-cols-md": "4",
                  "label-for": "Term Payment"
                }
              }, [_vm._l(_vm.term, function (item, index) {
                return [_c('b-form-radio', {
                  key: index,
                  attrs: {
                    "inline": "",
                    "name": item.name,
                    "value": item.value,
                    "disabled": item.disabled
                  },
                  model: {
                    value: _vm.stateActive[item[Object.keys(item)[0]]],
                    callback: function callback($$v) {
                      _vm.$set(_vm.stateActive, item[Object.keys(item)[0]], $$v);
                    },
                    expression: "stateActive[item[Object.keys(item)[0]]]"
                  }
                }, [_vm._v(_vm._s(item.label))])];
              })], 2), _c('b-form-group', {
                attrs: {
                  "label": "Description",
                  "label-cols-md": "4",
                  "label-for": "description"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2"
                }
              })], 1)], 1)], 1)], 1)], 1), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "tab-fourth",
                  "aria-labelled-by": "fourth-tab"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b', {
                staticClass: "text-primary"
              }, [_vm._v("OPERATIONS")]), _c('hr'), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Item Price",
                  "label-for": "price"
                }
              }, [_vm._l(_vm.invent, function (item, index) {
                return [_c('b-checkbox', {
                  key: index,
                  staticClass: "custom-switch-color",
                  attrs: {
                    "color": item.color,
                    "disabled": item.disabled,
                    "name": "check-button",
                    "inline": ""
                  },
                  model: {
                    value: item.checked,
                    callback: function callback($$v) {
                      _vm.$set(item, "checked", $$v);
                    },
                    expression: "item.checked"
                  }
                }, [_vm._v(" " + _vm._s(item.label) + " ")])];
              })], 2), _c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Description Receipt",
                  "label-for": "descReceipt"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "descReceipt",
                  "rows": "2"
                }
              })], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b', {
                staticClass: "text-primary"
              }, [_vm._v("LOGISTIC")]), _c('hr'), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "PIC",
                  "label-for": "pic"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('input', {
                staticClass: "form-control",
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "number",
                  "id": "pic"
                }
              })])]), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Weight",
                  "label-for": "weight"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("KG")])]), _c('input', {
                staticClass: "form-control",
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "number",
                  "id": "weight"
                }
              })])]), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Volume",
                  "label-for": "volume"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("M2")])]), _c('input', {
                staticClass: "form-control",
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "number",
                  "id": "volume"
                }
              })])]), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Production Days",
                  "label-for": "daysProduction"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Day")])]), _c('input', {
                staticClass: "form-control",
                staticStyle: {
                  "height": "35px"
                },
                attrs: {
                  "type": "number",
                  "id": "volume"
                }
              })])]), _c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Description Logistic",
                  "label-for": "descLogistic"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "descLogistic",
                  "rows": "2"
                }
              })], 1)], 1)], 1)], 1)], 1), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "tab-fifth",
                  "aria-labelled-by": "fourth-tab"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b', {
                staticClass: "text-primary"
              }, [_vm._v("KREDIT ACCOUNT")]), _c('hr'), _c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionBankAccount,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Bank Account")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectBankAccount,
                  callback: function callback($$v) {
                    _vm.selectBankAccount = $$v;
                  },
                  expression: "selectBankAccount"
                }
              })], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b', {
                staticClass: "text-primary"
              }, [_vm._v("DEBIT ACCOUNT")]), _c('hr'), _c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionBankAccount,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Bank Account")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectBankAccount,
                  callback: function callback($$v) {
                    _vm.selectBankAccount = $$v;
                  },
                  expression: "selectBankAccount"
                }
              })], 1)], 1)], 1)], 1)], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.slideAddProduct,
            callback: function callback($$v) {
              _vm.slideAddProduct = $$v;
            },
            expression: "slideAddProduct"
          }
        }), _c('b-row', [_c('b-col', {
          staticClass: "text-center",
          attrs: {
            "sm": "3"
          }
        }, [_c('b-card', {
          attrs: {
            "img-src": require('../../assets/images/page-img/material.jpg'),
            "img-alt": "#",
            "img-top": ""
          }
        }, [_c('a', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.detailVendor",
            modifiers: {
              "detailVendor": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('h5', {
          staticClass: "text-primary"
        }, [_vm._v("Engine Oil Filter")])]), _c('p', [_c('strong', [_vm._v("Product Type : ")]), _vm._v(" Material"), _c('br'), _c('strong', [_vm._v("Harga : ")]), _vm._v(" Rp. 0,00"), _c('br'), _c('strong', [_vm._v("Stock : ")]), _vm._v(" 0,00 pcs"), _c('br')])])], 1), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "sm": "3"
          }
        }, [_c('b-card', {
          attrs: {
            "img-src": require('../../assets/images/page-img/material.jpg'),
            "img-alt": "#",
            "img-top": ""
          }
        }, [_c('a', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.detailVendor",
            modifiers: {
              "detailVendor": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('h5', {
          staticClass: "text-primary"
        }, [_vm._v("Hydraulic Oil Filter")])]), _c('p', [_c('strong', [_vm._v("Product Type : ")]), _vm._v(" Material"), _c('br'), _c('strong', [_vm._v("Harga : ")]), _vm._v(" Rp. 0,00"), _c('br'), _c('strong', [_vm._v("Stock : ")]), _vm._v(" 0,00 pcs"), _c('br')])])], 1), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "sm": "3"
          }
        }, [_c('b-card', {
          attrs: {
            "img-src": require('../../assets/images/page-img/material.jpg'),
            "img-alt": "#",
            "img-top": ""
          }
        }, [_c('a', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.detailVendor",
            modifiers: {
              "detailVendor": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('h5', {
          staticClass: "text-primary"
        }, [_vm._v("V-BELT MITSHUBISHI")])]), _c('p', [_c('strong', [_vm._v("Product Type : ")]), _vm._v(" Material"), _c('br'), _c('strong', [_vm._v("Harga : ")]), _vm._v(" Rp. 0,00"), _c('br'), _c('strong', [_vm._v("Stock : ")]), _vm._v(" 0,00 pcs"), _c('br')])])], 1), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "sm": "3"
          }
        }, [_c('b-card', {
          attrs: {
            "img-src": require('../../assets/images/page-img/material.jpg'),
            "img-alt": "#",
            "img-top": ""
          }
        }, [_c('a', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.detailVendor",
            modifiers: {
              "detailVendor": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('h5', {
          staticClass: "text-primary"
        }, [_vm._v("FO FILTER ELEMENT ASSY")])]), _c('p', [_c('strong', [_vm._v("Product Type : ")]), _vm._v(" Material"), _c('br'), _c('strong', [_vm._v("Harga : ")]), _vm._v(" Rp. 0,00"), _c('br'), _c('strong', [_vm._v("Stock : ")]), _vm._v(" 0,00 pcs"), _c('br')])])], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1)])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }