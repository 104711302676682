<template>
  <b-container fluid>
    <form action="#" method="post" @submit="onSave">
      <b-row>
        <b-col lg="4">
          <iq-card class-name="iq-user-profile-block1">
            <template v-slot:body>
              <div class="user-details-block">
                <div class="user-profile text-center">
                  <img
                    src="../../assets/images/user/11.png"
                    alt="profile-img"
                    class="avatar-130 img-fluid"
                  />
                </div>
                <div class="text-center mt-3">
                  <h4
                    ><b>{{
                      userDetails?.personal_data?.full_name ?? '-'
                    }}</b></h4
                  >
                  <p>
                    27 Tahun,
                    {{ userDetails?.personal_data?.place_of_birth ?? '-' }}
                    <br />
                    <b-badge variant="primary">Lokasi : Asam-Asam</b-badge>
                  </p>
                </div>
              </div>
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td><strong>Nomor KTP</strong></td>
                    <td>{{ userDetails?.personal_data?.nik_ktp ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Seaferer Code</strong></td>
                    <td>{{
                      userDetails?.personal_data?.seaferer_code ?? '-'
                    }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor Seaman Book</strong></td>
                    <td>{{
                      userDetails?.personal_data?.seaman_book_number ?? '-'
                    }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor Passport</strong></td>
                    <td>{{
                      userDetails?.personal_data?.passport_number ?? '-'
                    }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor NPWP</strong></td>
                    <td>{{
                      userDetails?.personal_data?.npwp_number ?? '-'
                    }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="8">
          <iq-card>
            <template v-slot:body>
              <h4 class="card-title text-primary"
                ><strong>DATA DIRI</strong></h4
              >
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td width="25%"><strong>Apply Date</strong></td>
                    <td width="25%">{{
                      userDetails?.personal_data?.created_at ?? '-'
                    }}</td>
                    <td width="25%"><strong>Tempat / Tgl Lahir</strong></td>
                    <td width="25%"
                      >{{ userDetails?.personal_data?.place_of_birth ?? '-' }} /
                      {{ userDetails?.personal_data?.date_of_birth ?? '-' }}</td
                    >
                  </tr>
                  <tr>
                    <td width="25%"><strong>Kewarganegaraan</strong></td>
                    <td width="25%">{{
                      userDetails?.personal_data?.citizenship ?? '-'
                    }}</td>
                    <td width="25%"><strong>Status Pernikahan</strong></td>
                    <td width="25%">{{
                      userDetails?.personal_data?.marital_status?.name ?? '-'
                    }}</td>
                  </tr>
                  <tr>
                    <td width="25%"><strong>Agama</strong></td>
                    <td width="25%">{{
                      userDetails?.personal_data?.religion?.name ?? '-'
                    }}</td>
                    <td width="25%"><strong>Jumlah Anak</strong></td>
                    <td width="25%"
                      >{{
                        userDetails?.personal_data?.number_of_children ?? '-'
                      }}
                      Orang</td
                    >
                  </tr>
                  <tr>
                    <td width="25%"><strong>Golongan Darah</strong></td>
                    <td width="25%">{{
                      userDetails?.personal_data?.blood_type?.alias ?? '-'
                    }}</td>
                    <td width="25%"><strong>Nomor Tlp/Hp</strong></td>
                    <td width="25%">{{
                      userDetails?.personal_data?.hp_number ?? '-'
                    }}</td>
                  </tr>
                  <tr>
                    <td width="25%"><strong>Jenis Kelamin</strong></td>
                    <td width="25%">{{
                      userDetails?.personal_data?.gender ?? '-'
                    }}</td>
                    <td width="25%"><strong>Email</strong></td>
                    <td width="25%">{{
                      userDetails?.personal_data?.email ?? '-'
                    }}</td>
                  </tr>
                  <tr>
                    <td width="25%"><strong>Alamat</strong></td>
                    <td colspan="3">{{
                      userDetails?.alamat_ktp?.[0]?.alamat ?? '-'
                    }}</td>
                  </tr>
                  <tr>
                    <td width="25%"><strong>Alamat Domisili</strong></td>
                    <td colspan="3">{{
                      userDetails?.alamat_domisili?.[0]?.alamat ?? '-'
                    }}</td>
                  </tr>
                </tbody>
              </table>
              <!-- PROFILE DETAIL USER -->
              <!--<b-row>
                <b-form-group
                  class="col-md-4"
                  label="Nama Kandidat"
                  label-for="nama_kandidat"
                >
                  <strong>{{ userDetails.full_name }}</strong>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Jabatan / Ijazah"
                  label-for="jabatan_ijazah"
                >
                  <strong>
                    {{ userDetails.jabatan }}
                  </strong>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Tanggal Melamar"
                  label-for="tanggal_melamar"
                >
                  <strong>
                    {{
                      userDetails?.created_at ? userDetails?.created_at : '-'
                    }}
                  </strong>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Nomor KTP"
                  label-for="no_ktp"
                >
                  <strong>
                    {{ userDetails.nik_ktp }}
                  </strong>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Nomor Telp / Hp"
                  label-for="no_tlp"
                >
                  <strong>
                    {{ userDetails.hp_number }}
                  </strong>
                </b-form-group>
                <b-form-group class="col-md-4" label="Email" label-for="email">
                  <strong>
                    {{ userDetails.email ? userDetails.email : '-' }}
                  </strong>
                </b-form-group>
                <hr />
                <b-form-group
                  class="col-md-6"
                  label="Alamat KTP"
                  label-for="alamat KTP"
                >
                  {{ alamat_ktp }}
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Alamat Domisili"
                  label-for="alamat Domisili"
                >
                  {{ alamat_domisili }}
                </b-form-group>
              </b-row>-->
            </template>
          </iq-card>
        </b-col>
        <b-col>
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"
                ><strong>USER DETAILS</strong></h4
              >
            </template>
            <template v-slot:headerAction>
              <b-button
                v-b-toggle.collapse-filter
                href="#experience-accordion"
                @click.prevent
                variant="light"
                ><i class="fa fa-caret-down"
              /></b-button>
            </template>
            <template v-slot:body>
              <b-collapse id="experience-accordion" class="mt-2 gap-2">
                <!-- SAIL EXPERIENCE -->
                <b-col>
                  <template>
                    <h6 class="card-title text-primary"
                      >PENGALAMAN BERLAYAR, MINIMAL 5 PENGALAMAN TERAKHIR</h6
                    >
                  </template>
                  <table class="table mb-0 table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">No</th>
                        <th class="text-center">Nama Kapal</th>
                        <th class="text-center">Pemilik</th>
                        <th class="text-center">Bendera</th>
                        <th class="text-center">Kawasan</th>
                        <th class="text-center">Jabatan</th>
                        <th class="text-center">No.Tlp Perusahaan</th>
                        <th class="text-center">Tanggal Bergabung</th>
                        <th class="text-center">Tanggal Berhenti</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(state, index) in userDetails.sail_experience"
                        v-bind:key="index"
                      >
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">{{ state.ship_name }}</td>
                        <td class="text-center">{{ state.owner }}</td>
                        <td class="text-center">{{ state.flag }}</td>
                        <td class="text-center">{{ state.area }}</td>
                        <td class="text-center">{{ state.position_id }}</td>
                        <td class="text-center">{{
                          state.no_number_company
                        }}</td>
                        <td class="text-center">{{ state.date_join }}</td>
                        <td class="text-center">{{ state.date_end }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
                <div class="d-flex w-100 px-2 my-3 border-bottom" />
                <!-- PENDIDIKAN -->
                <b-col>
                  <template>
                    <h6 class="card-title text-primary">PENDIDIKAN</h6>
                  </template>
                  <table class="table mb-0 table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">No</th>
                        <th class="text-center">Sekolah</th>
                        <th class="text-center">Jenjang</th>
                        <th class="text-center">Jurusan</th>
                        <th class="text-center">Kota</th>
                        <th class="text-center">Negara</th>
                        <th class="text-center">Nilai</th>
                        <th class="text-center">Tahun Mulai</th>
                        <th class="text-center">Tahun Selesai</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(state, index) in userDetails.pendidikan"
                        v-bind:key="index"
                      >
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">{{ state.sekolah }}</td>
                        <td class="text-center">{{ state.jenjang }}</td>
                        <td class="text-center">{{ state.jurusan }}</td>
                        <td class="text-center">{{ state.kota }}</td>
                        <td class="text-center">{{ state.negara }}</td>
                        <td class="text-center">{{ state.nilai }}</td>
                        <td class="text-center">{{ state.tahun_mulai }}</td>
                        <td class="text-center">{{ state.tahun_selesai }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
                <div class="d-flex w-100 px-2 my-3 border-bottom" />
                <!-- FAMILY -->
                <b-col>
                  <template>
                    <h6 class="card-title text-primary">DATA KELUARGA</h6>
                  </template>
                  <table class="table mb-0 table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">No</th>
                        <th class="text-center">Nama Lengkap</th>
                        <th class="text-center">Hubungan</th>
                        <th class="text-center">Jenis Kelamin</th>
                        <th class="text-center">Umur</th>
                        <th class="text-center">Pekerjaan</th>
                        <th class="text-center">No.Hp</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(state, index) in userDetails.family"
                        v-bind:key="index"
                      >
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">{{ state.nama_lengkap }}</td>
                        <td class="text-center">{{ state.status }}</td>
                        <td class="text-center">{{ state.jenis_kelamin }}</td>
                        <td class="text-center">{{ state.age_family }}</td>
                        <td class="text-center">{{ state.pekerjaan }}</td>
                        <td class="text-center">{{ state.no_hp }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
                <!-- TRAVEL DOCUMENT -->
                <div class="d-flex w-100 px-2 my-3 border-bottom" />
                <b-col>
                  <template>
                    <h6 class="card-title text-primary">TRAVEL DOCUMENT</h6>
                  </template>
                  <table class="table mb-0 table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">No</th>
                        <th class="text-center">Nama Document</th>
                        <th class="text-center">No Dokument</th>
                        <th class="text-center">Place & Date Issued</th>
                        <th class="text-center">Masa Berlaku</th>
                        <th class="text-center">File</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(state, index) in userDetails.travel_document"
                        v-bind:key="index"
                      >
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">{{ state.document_name }}</td>
                        <td class="text-center">{{ state.document_number }}</td>
                        <td class="text-center">{{
                          state.date_issued + ', ' + state.place_issued
                        }}</td>
                        <td class="text-center">{{ state.validity_period }}</td>
                        <td class="text-center" v-if="state.file"
                          ><i class="fa fa-file-alt text-primary"></i
                        ></td>
                        <td class="text-center" v-else>{{ '-' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
                <div class="d-flex w-100 px-2 my-3 border-bottom" />
                <!-- CERTIFICATE COMPETENCY -->
                <b-col>
                  <template>
                    <h6 class="card-title text-primary"
                      >CERTIFICATE COMPETENCY</h6
                    >
                  </template>
                  <table class="table mb-0 table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">No</th>
                        <th class="text-center">Ijazah & Endorse</th>
                        <th class="text-center">Nomor Sertifikat</th>
                        <th class="text-center">Place & Date Issued</th>
                        <th class="text-center">Masa Berlaku</th>
                        <th class="text-center">File</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(
                          state, index
                        ) in userDetails.certificate_competency"
                        v-bind:key="index"
                      >
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">{{ state.document_name }}</td>
                        <td class="text-center">{{ state.document_number }}</td>
                        <td class="text-center">{{
                          state.date_issued + ', ' + state.place_issued
                        }}</td>
                        <td class="text-center">{{ state.validity_period }}</td>
                        <td class="text-center" v-if="state.file"
                          ><i class="fa fa-file-alt text-primary"></i
                        ></td>
                        <td class="text-center" v-else>{{ '-' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
                <div class="d-flex w-100 px-2 my-3 border-bottom" />
                <!-- CERTIFICATE PROFICIENCY -->
                <b-col>
                  <template>
                    <h6 class="card-title text-primary"
                      >CERTIFICATE PROFICIENCY</h6
                    >
                  </template>
                  <table class="table mb-0 table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">No</th>
                        <th class="text-center">Nama Sertifikat</th>
                        <th class="text-center">Nomor Sertifikat</th>
                        <th class="text-center">Place & Date Issued</th>
                        <th class="text-center">Masa Berlaku</th>
                        <th class="text-center">File</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(
                          state, index
                        ) in userDetails.certificate_proficiency"
                        v-bind:key="index"
                      >
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">{{ state.document_name }}</td>
                        <td class="text-center">{{ state.document_number }}</td>
                        <td class="text-center">{{
                          state.date_issued + ', ' + state.place_issued
                        }}</td>
                        <td class="text-center">{{ state.validity_period }}</td>
                        <td class="text-center" v-if="state.file"
                          ><i class="fa fa-file-alt text-primary"></i
                        ></td>
                        <td class="text-center" v-else>{{ '-' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
                <div class="d-flex w-100 px-2 my-3 border-bottom" />
                <!-- ORGANISASI -->
                <b-col>
                  <template>
                    <h6 class="card-title text-primary">ORGANISASI</h6>
                  </template>
                  <table class="table mb-0 table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">No</th>
                        <th class="text-center">Nama Organisasi</th>
                        <th class="text-center">Jabatan</th>
                        <th class="text-center">Instansi</th>
                        <th class="text-center">Tanggal Bergabung</th>
                        <th class="text-center">Tanggal Selesai</th>
                        <th class="text-center">Deskripsi</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(state, index) in userDetails.organisasi"
                        v-bind:key="index"
                      >
                        <td class="text-center">{{ index + 1 }}</td>
                        <td class="text-center">{{ state.nama_organisasi }}</td>
                        <td class="text-center">{{ state.posisi }}</td>
                        <td class="text-center">{{ state.tempat }}</td>
                        <td class="text-center">{{ state.durasi_awal }}</td>
                        <td class="text-center">{{ state.durasi_akhir }}</td>
                        <td class="text-center">{{ state.deskripsi }}</td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>

        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"
                ><strong
                  >FORMULIR WAWANCARA PERWIRA MESIN KAPAL /
                  <i>ENGINE OFFICERS INTERVIEW FORM</i></strong
                ></h4
              >
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="12">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="float-right ml-3"
                    @click="onSave"
                    ><i class="fa fa-save"></i> Save</b-button
                  >
                  <b-button
                    type="reset"
                    variant="none"
                    class="iq-bg-danger float-right"
                    @click="resetForm"
                    ><i class="fa fa-close"></i> Reset Form</b-button
                  >
                </b-col>
                <b-col md="12">
                  <fieldset
                    class="form-group border p-3"
                    v-for="(interview, keyInterview) in interview_questions"
                    :key="keyInterview"
                  >
                    <legend class="w-auto px-2" style="font-size: 12pt">{{
                      interview?.name
                    }}</legend>
                    <b-row>
                      <b-col md="12">
                        <table class="table table-striped">
                          <thead>
                            <tr class="text-center">
                              <th width="45%"></th>
                              <th
                                width="15%"
                                v-for="(item, index) in point_interview"
                                v-bind:key="index"
                                >{{ item?.name }} <br />
                                {{ item?.value_letter }}</th
                              >
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                criteria, keyCriteria
                              ) in interview.data_criteria"
                              :key="keyCriteria"
                            >
                              <th
                                >{{ criteria.content }}<br /><i
                                  >Pengetahuan Permesinan</i
                                ></th
                              >
                              <td
                                class="text-center"
                                v-for="(point, keyPoint) in criteria?.point"
                                :key="keyPoint"
                              >
                                <template>
                                  <b-form-checkbox
                                    v-model="point.checked"
                                    name="check-button"
                                    class="ml-4"
                                    switch
                                    inline
                                    @change="
                                      selectMenus(
                                        point,
                                        keyInterview,
                                        keyCriteria,
                                        keyPoint
                                      )
                                    "
                                  >
                                  </b-form-checkbox>
                                </template>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <label for="komentar"><strong>Komentar</strong></label>
                        <b-form-textarea
                          rows="2"
                          value="Comments if any / Komentar jika ada"
                          v-model="interview.comment"
                          required
                        ></b-form-textarea>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
              </b-row>
              <hr />
              <b-row class="mt-3">
                <b-col md="6">
                  <div class="form-group row">
                    <label
                      label="Clasification *"
                      for="nama_kandidiat"
                      class="col-sm-6"
                      ><strong>Wawancara Oleh</strong><br /><i
                        >Interview by</i
                      ></label
                    >
                    <div class="col-sm-6">
                      <b-form-input
                        id="job_name"
                        type="text"
                        placeholder="Nama"
                        v-model="wawancara_oleh"
                        required
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      label="Clasification *"
                      for="nama_kandidiat"
                      class="col-sm-6"
                      ><strong>Hasil Wawancara</strong><br /><i
                        >Result Interview</i
                      ></label
                    >
                    <div class="col-sm-6">
                      <b-form-select
                        plain
                        v-model="hasil_wawancara"
                        :options="wawancara"
                        size="lg"
                        required
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >Hasil Wawancara</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      label="Clasification *"
                      for="nama_kandidiat"
                      class="col-sm-6"
                      ><strong>Urgensi Wawancara</strong><br /><i
                        >Interview Urgency</i
                      ></label
                    >
                    <div class="col-sm-6">
                      <b-form-select
                        plain
                        v-model="urgensi"
                        :options="optionsUrgensi"
                        size="lg"
                        required
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >None</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      label="Clasification *"
                      for="nama_kandidiat"
                      class="col-sm-6"
                      ><strong>Lokasi Wawancara</strong><br /><i
                        >Interview Location</i
                      ></label
                    >
                    <div class="col-sm-6">
                      <b-form-input
                        id="location"
                        type="text"
                        placeholder="Lokasi Wawancara"
                        v-model="location"
                        required
                      ></b-form-input>
                    </div>
                  </div>
                </b-col>
                <b-col md="6" class="mt-3">
                  <b-button
                    type="submit"
                    block
                    variant="primary"
                    class="float-right ml-3"
                    :disabled="saving"
                    ><i class="fa fa-save"></i> Save</b-button
                  >
                  <b-button
                    type="reset"
                    block
                    variant="none"
                    class="iq-bg-danger float-right"
                    @click="resetForm"
                    ><i class="fa fa-close"></i> Reset Form</b-button
                  >
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { crewingActions } from '@src/Utils/helper'

export default {
  name: 'ProfileEdit',
  data() {
    return {
      wawancara: [
        { value: 'Passed', text: 'Suitable / Cocok' },
        { value: 'Failed', text: 'Not Suitable / Tidak Cocok' },
        { value: 'Special Case', text: 'Recommendation' },
      ],
      optionsUrgensi: [
        { value: 'Priority', text: 'Priority' },
        { value: 'Urgent', text: 'Urgent' },
        { value: 'Emergency', text: 'Emergency' },
      ],
      loading: false,
      saving: false,
      urgensi: '',
      location: '',
      hasil_wawancara: '',
      wawancara_oleh: '',
      userDetails: '',
      alamat_ktp: '',
      alamat_domisili: '',
      type_position_id: '',
      point_interview: [],
      criteria_interview: [],
      interview_questions: [],
    }
  },

  async mounted() {
    xray.index()
    // this.fetchFleets()
    await this.getProfileUser()
    await this.getPointInterview()
    await this.getCriteriaInterview()
    this.mergeInterview()
  },
  methods: {
    ...crewingActions,
    async getProfileUser() {
      if (this.$route.params.id) {
        let method = 'getProfileCandidate'

        const { status, data } = await this[method](this.$route.params.id)

        console.log(this.$route.params.id, 'ID CANDIDATE')
        console.log(data, 'THIS IS PROFILE CANDIDATE')

        if (status !== 'success') {
          this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
          return
        }

        this.userDetails = data
        this.alamat_ktp = data.alamat_ktp?.[0]?.alamat
        this.alamat_domisili = data.alamat_domisili?.[0]?.alamat
        this.type_position_id = data.personal_data.position.type_position_id

        console.log(this.type_position_id, 'POSITION ID')
      }
    },

    async getPointInterview() {
      const { status, data } = await this.fetchPointInterview()

      if (status !== 'success') {
        return
      }

      console.log(data, 'THIS IS POINT INTERVIEW')

      this.point_interview = data?.data
    },

    async getCriteriaInterview() {
      if (this.type_position_id) {
        let params = { type_position_id: this.type_position_id }

        const { status, data } = await this.fetchCriteriaInterview(params)

        console.log(data, 'THIS IS CRITERIA INTERVIEW')
        this.criteria_interview = data
      }
    },

    mergeInterview() {
      if (
        this.criteria_interview.length > 0 &&
        this.point_interview.length > 0
      ) {
        this.criteria_interview.map((criteria) => {
          this.interview_questions.push({
            ...criteria,
            comment: '',
            data_criteria: criteria.data_criteria.map((criteria) => {
              return {
                ...criteria,
                point: this.point_interview.map((point) => {
                  return {
                    ...point,
                    checked: false,
                  }
                }),
              }
            }),
          })
        })
        console.log(this.interview_questions, 'INTERVIEW QUESTIONS')
      } else {
        console.log('Data interview dan point interview tidak ada.')
      }
    },

    selectMenus(point, keyInterview, keyCriteria, keyPoint) {
      if (point.checked) {
        this.interview_questions[keyInterview].data_criteria[
          keyCriteria
        ].point.forEach((val, index) => {
          if (index !== keyPoint) {
            val.checked = false
          }
        })
      }
    },

    resetForm() {
      if (this.interview_questions.length > 0) {
        this.interview_questions.forEach((interview, index) => {
          interview.comment = ''
          interview.data_criteria.forEach((criteria, ind) => {
            criteria.point.forEach((point, i) => {
              return (point.checked = false)
            })
          })
        })
      }
    },

    async onSave(e) {
      e.preventDefault()
      this.saving = true

      let readyToSave = {
        status: this.hasil_wawancara,
        date: new Date(),
        location: this.location,
        total: 0,
        average: 0,
        urgensi: this.urgensi,
        interview_by: [],
        interview: [],
        content: [],
      }

      this.interview_questions.forEach((interview, index) => {
        if (interview.comment) {
          readyToSave.interview.push({
            comment: interview.comment,
          })
        }
        interview.data_criteria.forEach((criteria, ind) => {
          criteria.point.forEach((point, i) => {
            if (point.checked) {
              readyToSave.content.push({
                criteria_id: criteria.id_interview_point,
                point_value: point.value,
              })
            }
          })
        })
      })
      if (readyToSave.content.length > 0) {
        readyToSave.total = readyToSave.content.reduce(
          (o, n) => o + n.point_value,
          0
        )
        readyToSave.average =
          readyToSave.content.reduce((o, n) => o + n.point_value, 0) /
          readyToSave.content.length
      }
      readyToSave.interview_by.push({ name: this.wawancara_oleh })
      console.log(readyToSave, 'READY TO SAVE')
      const { status } = await this.setFormInterview({
        data: readyToSave,
        id: this.$route.params.id,
      })

      let changeStatus = new FormData()

      changeStatus.append('change_status', 'mcu')

      await this.changeStatusCandidate({
        id: this.$route.params.id,
        change_status: changeStatus,
      })

      if (status !== 'success') {
        this.saving = false
        this.$swal(`Oops!`, 'Semua Field Wajib Diisi', 'error')
        return
      }

      this.$swal(`Tersimpan`, 'Data Tersimpan', 'success')

      this.saving = false

      this.$router.push('/crewing/list-crew')
    },
  },
}
</script>
