var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("PURCHASE RECEIPT")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.filter",
            modifiers: {
              "filter": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-4",
          attrs: {
            "visible": "",
            "id": "filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionStatusPurchaseRequest,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Status")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectStatusPurchaseRequest,
            callback: function callback($$v) {
              _vm.selectStatusPurchaseRequest = $$v;
            },
            expression: "selectStatusPurchaseRequest"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionPriority,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Priority")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectPriority,
            callback: function callback($$v) {
              _vm.selectPriority = $$v;
            },
            expression: "selectPriority"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionCompany,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Company")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectCompany,
            callback: function callback($$v) {
              _vm.selectCompany = $$v;
            },
            expression: "selectCompany"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1), _c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Add Data',
            expression: "'Add Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              _vm.addPurchaseOrder = !_vm.addPurchaseOrder;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        })]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Refresh Data',
            expression: "'Refresh Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-refresh"
        })]), _c('b-dropdown', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Export Data',
            expression: "'Export Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "link iq-bg-primary",
            "toggle-class": "text-decoration-none",
            "no-caret": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-save m-0"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _vm._v("Export Excel")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf mr-2"
        }), _vm._v("Export PDF")])], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("RECEIPT NUMBER")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DATE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DEPARTMENT")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("FROM")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ORDER TOTAL")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("SOUCRE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              _vm.detailPurchaseItem = !_vm.detailPurchaseItem;
            }
          }
        }, [_vm._v("REC0001")])]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary w-100"
          }
        }, [_vm._v("Progress")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("10/28/2022")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Technical")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("VDR001 - Global Marine, PT")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("WAREHOUSE JAKARTA")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1.524.000")]), _c('th', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              _vm.detailPurchaseItem = !_vm.detailPurchaseItem;
            }
          }
        }, [_vm._v("PO0001")])]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-list-alt m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-edit mr-2"
        }), _vm._v("Update")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("Delete")])], 1)], 1)])])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('b-sidebar', {
          attrs: {
            "id": "detail_vendor",
            "title": "REQ-2210001 - TB. ETI 201",
            "width": "80%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var hide = _ref.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("General Information")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Request Number")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("REQ-2210001")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Company")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Transcoal Pacific")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Priority")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_c('b-badge', {
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("Normal")])], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Request Class")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("PURCHASE")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Fleet")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("TB. ETI 301")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Currenct")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("IDR")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Request By")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Project")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("KPC Project")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Est. Cost")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Rp. 2.500.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Department")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Operational")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Sub Project")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Long Hauling")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Open Qty")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("25")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Date")]), _c('td', {
                attrs: {
                  "width": "4%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("10/28/2022")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Location")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Sangatta")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Description")]), _c('td', {
                attrs: {
                  "width": "3%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Request Sparepart")])])])])]), _c('tab-nav', {
                attrs: {
                  "tabs": true
                }
              }, [_c('tab-nav-items', {
                attrs: {
                  "active": true,
                  "id": "item-tab",
                  "ariaControls": "item",
                  "role": "tab",
                  "ariaSelected": true,
                  "title": "Item Detail"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "vendor-tab",
                  "ariaControls": "vendor",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Vendor Info"
                }
              })], 1), _c('div', {
                staticClass: "tab-content",
                attrs: {
                  "id": "myTabContent"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "item"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("INVENTORY ID")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("DESCRIPTION ITEM")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("QTY")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("UOM")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("PRICE")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("SUB TOTAL")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("VENDOR INFO")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("VENDOR CONTACT")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("VENDOR LOCATION")])])]), _c('tbody', [_c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("INV-02101001")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Engine Oil Filter")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("10")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("PCS")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("225.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("2.250.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Utama Production, CV")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Kalimantan Selatan")])]), _c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("INV-02101002")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Hyd Oil Filter Element")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("20")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("PCS")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("225.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("2.250.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Utama Production, CV")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Kalimantan Selatan")])])])])])]), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "vendor",
                  "aria-labelled-by": "vendor-tab"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('h4', [_vm._v("New Vendor ?")]), _vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-left"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2), _c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "6"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h6 text-primary"
              }, [_vm._v("Vendor Information")]), _c('b-form', [_c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Company ID",
                  "label-for": "companyID"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "CompanyID"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Company Name",
                  "label-for": "companyName"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "vendorName"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Phone",
                  "label-for": "phone"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "phone"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "WhatsApp Number",
                  "label-for": "whatsappNumber"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "whatsappNumber"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Email",
                  "label-for": "email"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "email",
                  "id": "email"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Website",
                  "label-for": "website"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "website"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Address",
                  "label-for": "address"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "address",
                  "rows": "3"
                }
              })], 1)], 1)], 1)], 1), _c('b-form')], 1)])], 1)], 1)], 1)], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.detailPurchaseItem,
            callback: function callback($$v) {
              _vm.detailPurchaseItem = $$v;
            },
            expression: "detailPurchaseItem"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "addPurchaseOrder",
            "title": "ADD PURCHASE RECEIPT",
            "width": "80%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hide = _ref2.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("General Information")]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "primary"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Please Select Your "), _c('b', [_vm._v("Type Receipt")]), _vm._v(" From ")])]), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "options": _vm.optionReceiptType,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Order Type")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectReceiptType,
                  callback: function callback($$v) {
                    _vm.selectReceiptType = $$v;
                  },
                  expression: "selectReceiptType"
                }
              })], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "options": _vm.optionOrderNumber,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Order Number")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectOrderNumber,
                  callback: function callback($$v) {
                    _vm.selectOrderNumber = $$v;
                  },
                  expression: "selectOrderNumber"
                }
              })], 1)], 1)], 1), _c('b-row', {
                staticClass: "pl-3 pr-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Receipt Number",
                  "label-for": "receiptNumber"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "receiptNumber",
                  "placeholder": "< GENERATE RECEIPT NUMBER >"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Date",
                  "label-for": "Date"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "date",
                  "id": "Date"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Complete Date",
                  "label-for": "deliveryDate"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "date",
                  "id": "deliveryDate"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Request By",
                  "label-for": "requestBy"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionRequestBy,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Requester")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectRequestBy,
                  callback: function callback($$v) {
                    _vm.selectRequestBy = $$v;
                  },
                  expression: "selectRequestBy"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Department",
                  "label-for": "requestDepartment"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionDepartment,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Department")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectDepartment,
                  callback: function callback($$v) {
                    _vm.selectDepartment = $$v;
                  },
                  expression: "selectDepartment"
                }
              })], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Company",
                  "label-for": "requestCompany"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionCompany,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Company")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectCompany,
                  callback: function callback($$v) {
                    _vm.selectCompany = $$v;
                  },
                  expression: "selectCompany"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Fleet",
                  "label-for": "requestFleet"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionFleet,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Fleet")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectFleet,
                  callback: function callback($$v) {
                    _vm.selectFleet = $$v;
                  },
                  expression: "selectFleet"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Project",
                  "label-for": "Project"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionProject,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Project")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectProject,
                  callback: function callback($$v) {
                    _vm.selectProject = $$v;
                  },
                  expression: "selectProject"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Sub Project",
                  "label-for": "requestSubProject"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionSubProject,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Sub Project")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectSubProject,
                  callback: function callback($$v) {
                    _vm.selectSubProject = $$v;
                  },
                  expression: "selectSubProject"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Location",
                  "label-for": "requestLocation"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionLocation,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Location")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectLocation,
                  callback: function callback($$v) {
                    _vm.selectLocation = $$v;
                  },
                  expression: "selectLocation"
                }
              })], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Owner Data",
                  "label-for": "ownerData"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionOwnerData,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Owner Data")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectOwnerData,
                  callback: function callback($$v) {
                    _vm.selectOwnerData = $$v;
                  },
                  expression: "selectOwnerData"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Priority",
                  "label-for": "requestPriority"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionPriority,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Status")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectPriority,
                  callback: function callback($$v) {
                    _vm.selectPriority = $$v;
                  },
                  expression: "selectPriority"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Total Qty",
                  "label-for": "TotalQty"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "TotalQty",
                  "placeholder": "45 pcs",
                  "readonly": ""
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Total Cost",
                  "label-for": "totalCost"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "totalCost",
                  "placeholder": "Rp. 174.000.000",
                  "readonly": ""
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Description",
                  "label-for": "description"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "description"
                }
              })], 1)], 1)], 1)], 1)], 1), _c('tab-nav', {
                attrs: {
                  "tabs": true
                }
              }, [_c('tab-nav-items', {
                attrs: {
                  "active": true,
                  "id": "add_pr_item-tab",
                  "ariaControls": "add_pr_item",
                  "role": "tab",
                  "ariaSelected": true,
                  "title": "Item Detail"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "id": "add_pr_vendor-tab",
                  "ariaControls": "add_pr_vendor",
                  "role": "tab",
                  "ariaSelected": false,
                  "title": "Vendor Info"
                }
              })], 1), _c('div', {
                staticClass: "tab-content",
                attrs: {
                  "id": "myTabContent"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "add_pr_item"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("PO NUMBER")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("INVENTORY ID")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("DESCRIPTION ITEM")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("OPEN QTY")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("ORDER QTY")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("RECEIPT QTY")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("RETURN QTY")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("UOM")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("WAREHOUSE")])])]), _c('tbody', [_c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("PO-22100001")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("INV-02101001")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Engine Oil Filter")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("10")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("10")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("10")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("0")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Pcs")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Jakarta")])])])])])]), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "add_pr_vendor",
                  "aria-labelled-by": "add_pr_vendor-tab"
                }
              }, [_c('b-row', [_c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h6 text-primary"
              }, [_vm._v("Vendor")]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "primary"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("New vendor ? ("), _c('b', [_vm._v("Yes")]), _vm._v(" = New Vendor / "), _c('b', [_vm._v("No")]), _vm._v(" : Vendor Existing)")])]), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-left"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2), _c('b-col', {
                staticClass: "mt-2",
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "options": _vm.optionVendor,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Vendor")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectVendor,
                  callback: function callback($$v) {
                    _vm.selectVendor = $$v;
                  },
                  expression: "selectVendor"
                }
              })], 1)], 1)], 1), _c('b-form', [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-primary",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Company Info")])])]), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Company ID",
                  "label-for": "companyID"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "CompanyID",
                  "placeholder": "<< GENERATE COMPANY ID >>"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Company Name",
                  "label-for": "companyName"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "vendorName"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Phone",
                  "label-for": "phone"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "phone"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "WhatsApp Number",
                  "label-for": "whatsappNumber"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "whatsappNumber"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Email",
                  "label-for": "email"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "email",
                  "id": "email"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Website",
                  "label-for": "website"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "website"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Address",
                  "label-for": "address"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "address",
                  "rows": "3"
                }
              })], 1)], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-primary",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Shipping To")])])]), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Company Name",
                  "label-for": "companyName"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "CompanyName"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Phone",
                  "label-for": "phone"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "phone"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Email",
                  "label-for": "email"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "email",
                  "id": "email"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Website",
                  "label-for": "website"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "website"
                }
              })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Currency",
                  "label-for": "currency"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionCurrency,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Currency")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectCurrency,
                  callback: function callback($$v) {
                    _vm.selectCurrency = $$v;
                  },
                  expression: "selectCurrency"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Tax",
                  "label-for": "tax"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-left"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Payment Condition",
                  "label-for": "website"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": ""
                }
              }, [_c('b-form-select-option', [_vm._v("CASH")]), _c('b-form-select-option', [_vm._v("COD")]), _c('b-form-select-option', [_vm._v("NET 7")]), _c('b-form-select-option', [_vm._v("NET 15")]), _c('b-form-select-option', [_vm._v("NET 30")]), _c('b-form-select-option', [_vm._v("NET 60")])], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Address",
                  "label-for": "address"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "address",
                  "rows": "3"
                }
              })], 1)], 1)], 1)], 1)], 1)], 1)], 1)])], 1)], 1)], 1)], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.addPurchaseOrder,
            callback: function callback($$v) {
              _vm.addPurchaseOrder = $$v;
            },
            expression: "addPurchaseOrder"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }