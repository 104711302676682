var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "3"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "title": "Card Title",
      "img-src": require('../../assets/images/page-img/07.jpg'),
      "img-alt": "#",
      "img-top": ""
    }
  }, [_c('b-card-text', [_vm._v(" It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ")]), _c('b-button', {
    attrs: {
      "href": "#",
      "variant": "primary"
    }
  }, [_vm._v("Button")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "3"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "title": "Card Title",
      "img-src": require('../../assets/images/page-img/07.jpg'),
      "img-alt": "#",
      "img-top": ""
    }
  }, [_c('b-card-text', [_vm._v(" It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. ")]), _c('p', [_vm._v("It is a long established fact that a the readable content of a page when looking at its layout.")]), _c('a', {
    staticClass: "card-link",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Card link")]), _c('b-link', {
    staticClass: "card-link",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Another link")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "3"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "title": "Card Title",
      "img-src": require('../../assets/images/page-img/07.jpg'),
      "img-alt": "#",
      "img-top": ""
    }
  }, [_c('b-card-text', [_vm._v("It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.")]), _c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_vm._v("Cras justo odio")]), _c('b-list-group-item', [_vm._v("Vestibulum at eros")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "3"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": "",
      "img-src": require('../../assets/images/page-img/07.jpg'),
      "img-alt": "#",
      "img-top": ""
    }
  }, [_c('b-card-body', [_c('b-card-title', [_vm._v("Card Title")]), _c('b-card-text', [_vm._v("Some quick example text to build on the card title and make up the bulk of the card's content.")])], 1), _c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_vm._v("Cras justo odio")])], 1), _c('b-card-body', [_c('a', {
    staticClass: "card-link",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Card link")]), _c('a', {
    staticClass: "card-link",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Another link")])])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "title": "Special title treatment"
    }
  }, [_c('b-card-text', [_vm._v("It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.")]), _c('b-button', {
    attrs: {
      "href": "#",
      "block": "",
      "variant": "primary"
    }
  }, [_vm._v("Go somewhere")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "title": "Special title treatment"
    }
  }, [_c('b-card-text', [_vm._v("It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.")]), _c('b-button', {
    attrs: {
      "href": "#",
      "block": "",
      "variant": "primary"
    }
  }, [_vm._v("Go somewhere")])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "title": "Special title treatment",
      "header": "Featured"
    }
  }, [_c('b-card-text', [_vm._v("With supporting text below as a natural lead-in to additional content.")]), _c('b-button', {
    attrs: {
      "href": "#",
      "variant": "primary"
    }
  }, [_vm._v("Go somewhere")])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    attrs: {
      "header": "Quote"
    }
  }, [_c('blockquote', {
    staticClass: "blockquote mb-0"
  }, [_c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
    staticClass: "blockquote-footer"
  }, [_vm._v(" Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])])], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    attrs: {
      "header": "Featured",
      "footer": "2 days ago",
      "footer-tag": "footer",
      "footer-class": "text-muted"
    }
  }, [_c('b-button', {
    attrs: {
      "href": "#",
      "variant": "primary"
    }
  }, [_vm._v("Go somewhere")])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "title": "Special title treatment"
    }
  }, [_c('b-card-text', [_vm._v(" With supporting text below as a natural lead-in to additional content. ")]), _c('b-button', {
    attrs: {
      "href": "#",
      "block": "",
      "variant": "primary"
    }
  }, [_vm._v("Go somewhere")])], 1)], 1), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "title": "Special title treatment"
    }
  }, [_c('b-card-text', [_vm._v(" With supporting text below as a natural lead-in to additional content. ")]), _c('b-button', {
    attrs: {
      "href": "#",
      "block": "",
      "variant": "primary"
    }
  }, [_vm._v("Go somewhere")])], 1)], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "title": "Special title treatment"
    }
  }, [_c('b-card-text', [_vm._v(" With supporting text below as a natural lead-in to additional content. ")]), _c('b-button', {
    attrs: {
      "href": "#",
      "block": "",
      "variant": "primary"
    }
  }, [_vm._v("Go somewhere")])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "title": "Card title",
      "img-src": require('../../assets/images/page-img/07.jpg'),
      "img-alt": "#",
      "img-top": ""
    }
  }, [_c('b-card-text', [_vm._v("This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.")]), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Last updated 3 mins ago")])])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "title": "Card title",
      "img-src": require('../../assets/images/page-img/07.jpg'),
      "img-alt": "#",
      "img-bottom": ""
    }
  }, [_c('b-card-text', [_vm._v("This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.")]), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Last updated 3 mins ago")])])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "bg-variant": "dark",
      "text-variant": "white",
      "title": "Card title",
      "overlay": "",
      "img-src": require('../../assets/images/page-img/07.jpg'),
      "img-alt": "#",
      "img-bottom": ""
    }
  }, [_c('b-card-text', [_vm._v("This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.")]), _c('b-card-text', [_vm._v("Last updated 3 mins ago")])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('h3', {
    staticClass: "mt-4 mb-3"
  }, [_vm._v("Horizontal Card")])]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-card-img', {
    attrs: {
      "src": require('../../assets/images/page-img/08.jpg')
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('b-card-body', {
    attrs: {
      "title": "Card Title"
    }
  }, [_c('b-card-text', [_vm._v(" This is a wider card with supporting text below as a natural lead-in. a little bit longer. ")]), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Last updated 3 mins ago")])])], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    staticClass: "flex-row-reverse",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-card-img', {
    attrs: {
      "src": require('../../assets/images/page-img/09.jpg')
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('b-card-body', {
    attrs: {
      "title": "Card Title",
      "align": "right"
    }
  }, [_c('b-card-text', [_vm._v(" This is a wider card with supporting text below as a natural lead-in. a little bit longer. ")]), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Last updated 3 mins ago")])])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('h3', {
    staticClass: "mt-4 mb-3"
  }, [_vm._v("Card Colored Styles")])]), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "white",
      "bg-variant": "primary"
    }
  }, [_c('b-card-title', {
    staticClass: "text-white"
  }, [_vm._v("Primary card title")]), _c('blockquote', {
    staticClass: "blockquote mb-0"
  }, [_c('p', {
    staticClass: "font-size-14"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
    staticClass: "blockquote-footer text-white font-size-12"
  }, [_vm._v("Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "white",
      "bg-variant": "secondary"
    }
  }, [_c('b-card-title', {
    staticClass: "text-white"
  }, [_vm._v("Secondary card title")]), _c('blockquote', {
    staticClass: "blockquote mb-0"
  }, [_c('p', {
    staticClass: "font-size-14"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
    staticClass: "blockquote-footer text-white font-size-12"
  }, [_vm._v("Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "white",
      "bg-variant": "success"
    }
  }, [_c('b-card-title', {
    staticClass: "text-white"
  }, [_vm._v("Success card title")]), _c('blockquote', {
    staticClass: "blockquote mb-0"
  }, [_c('p', {
    staticClass: "font-size-14"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
    staticClass: "blockquote-footer text-white font-size-12"
  }, [_vm._v("Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "white",
      "bg-variant": "danger"
    }
  }, [_c('b-card-title', {
    staticClass: "text-white"
  }, [_vm._v("Danger card title")]), _c('blockquote', {
    staticClass: "blockquote mb-0"
  }, [_c('p', {
    staticClass: "font-size-14"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
    staticClass: "blockquote-footer text-white font-size-12"
  }, [_vm._v("Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "white",
      "bg-variant": "warning"
    }
  }, [_c('b-card-title', {
    staticClass: "text-white"
  }, [_vm._v("Warning card title")]), _c('blockquote', {
    staticClass: "blockquote mb-0"
  }, [_c('p', {
    staticClass: "font-size-14"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
    staticClass: "blockquote-footer text-white font-size-12"
  }, [_vm._v("Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "white",
      "bg-variant": "info"
    }
  }, [_c('b-card-title', {
    staticClass: "text-white"
  }, [_vm._v("Info card title")]), _c('blockquote', {
    staticClass: "blockquote mb-0"
  }, [_c('p', {
    staticClass: "font-size-14"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
    staticClass: "blockquote-footer text-white font-size-12"
  }, [_vm._v("Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('h3', {
    staticClass: "mt-4 mb-3"
  }, [_vm._v("Border Card")])]), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "primary",
      "border-variant": "primary"
    }
  }, [_c('b-card-title', {
    staticClass: "text-primary"
  }, [_vm._v("Primary card title")]), _c('blockquote', {
    staticClass: "blockquote mb-0"
  }, [_c('p', {
    staticClass: "font-size-14"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
    staticClass: "blockquote-footer text-primary font-size-12"
  }, [_vm._v("Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "secondary",
      "border-variant": "secondary"
    }
  }, [_c('b-card-title', {
    staticClass: "text-secondary"
  }, [_vm._v("Secondary card title")]), _c('blockquote', {
    staticClass: "blockquote mb-0"
  }, [_c('p', {
    staticClass: "font-size-14"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
    staticClass: "blockquote-footer text-secondary font-size-12"
  }, [_vm._v("Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "success",
      "border-variant": "success"
    }
  }, [_c('b-card-title', {
    staticClass: "text-success"
  }, [_vm._v("Success card title")]), _c('blockquote', {
    staticClass: "blockquote mb-0"
  }, [_c('p', {
    staticClass: "font-size-14"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
    staticClass: "blockquote-footer text-success font-size-12"
  }, [_vm._v("Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "danger",
      "border-variant": "danger"
    }
  }, [_c('b-card-title', {
    staticClass: "text-danger"
  }, [_vm._v("Danger card title")]), _c('blockquote', {
    staticClass: "blockquote mb-0"
  }, [_c('p', {
    staticClass: "font-size-14"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
    staticClass: "blockquote-footer text-danger font-size-12"
  }, [_vm._v("Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "warning",
      "border-variant": "warning"
    }
  }, [_c('b-card-title', {
    staticClass: "text-warning"
  }, [_vm._v("Warning card title")]), _c('blockquote', {
    staticClass: "blockquote mb-0"
  }, [_c('p', {
    staticClass: "font-size-14"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
    staticClass: "blockquote-footer text-warning font-size-12"
  }, [_vm._v("Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "info",
      "border-variant": "info"
    }
  }, [_c('b-card-title', {
    staticClass: "text-info"
  }, [_vm._v("Info card title")]), _c('blockquote', {
    staticClass: "blockquote mb-0"
  }, [_c('p', {
    staticClass: "font-size-14"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
    staticClass: "blockquote-footer text-info font-size-12"
  }, [_vm._v("Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('h3', {
    staticClass: "mt-4 mb-3"
  }, [_vm._v("Card groups")])]), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-card-group', {
    staticClass: "iq-mb-3"
  }, [_c('b-card', {
    attrs: {
      "title": "Card title",
      "img-src": require('../../assets/images/page-img/07.jpg'),
      "img-alt": "#",
      "img-top": ""
    }
  }, [_c('b-card-text', [_vm._v(" This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer. ")]), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Last updated 3 mins ago")])])], 1), _c('b-card', {
    attrs: {
      "title": "Card title",
      "img-src": require('../../assets/images/page-img/07.jpg'),
      "img-alt": "#",
      "img-top": ""
    }
  }, [_c('b-card-text', [_vm._v(" This card has supporting text below as a natural lead-in to additional content. ")]), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Last updated 3 mins ago")])])], 1), _c('b-card', {
    attrs: {
      "title": "Card title",
      "img-src": require('../../assets/images/page-img/07.jpg'),
      "img-alt": "#",
      "img-top": ""
    }
  }, [_c('b-card-text', [_vm._v(" This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action. ")]), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Last updated 3 mins ago")])])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-card-group', {
    staticClass: "iq-mb-3"
  }, [_c('b-card', {
    attrs: {
      "title": "Card title",
      "img-src": require('../../assets/images/page-img/07.jpg'),
      "img-alt": "#",
      "img-top": ""
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('small', {
          staticClass: "text-muted"
        }, [_vm._v("Last updated 3 mins ago")])];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v(" This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer. ")])], 1), _c('b-card', {
    attrs: {
      "title": "Card title",
      "img-src": require('../../assets/images/page-img/07.jpg'),
      "img-alt": "#",
      "img-top": ""
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('small', {
          staticClass: "text-muted"
        }, [_vm._v("Last updated 3 mins ago")])];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v(" This card has supporting text below as a natural lead-in to additional content. ")])], 1), _c('b-card', {
    attrs: {
      "title": "Card title",
      "img-src": require('../../assets/images/page-img/07.jpg'),
      "img-alt": "#",
      "img-top": ""
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('small', {
          staticClass: "text-muted"
        }, [_vm._v("Last updated 3 mins ago")])];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v(" This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action. ")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('h3', {
    staticClass: "mb-3"
  }, [_vm._v("Card Masonry Columns")]), _c('b-card-group', {
    attrs: {
      "columns": ""
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "title": "Card title that wraps to a new line",
      "img-src": require('../../assets/images/page-img/07.jpg'),
      "img-alt": "#",
      "img-top": ""
    }
  }, [_c('b-card-text', [_vm._v(" This is a longer card with supporting text below as a natural lead-in to additional content. This content is a little bit longer. ")])], 1), _c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "no-body": ""
    }
  }, [_c('blockquote', {
    staticClass: "blockquote mb-0 card-body"
  }, [_c('p', {
    staticClass: "font-size-14"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
    staticClass: "blockquote-footer"
  }, [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])])]), _c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "title": "Card title",
      "img-src": require('../../assets/images/page-img/07.jpg'),
      "img-alt": "#",
      "img-top": ""
    }
  }, [_c('b-card-text', [_vm._v("This is a longer card with supporting text below as a natural lead-in to additional content.")]), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Last updated 3 mins ago")])])], 1), _c('b-card', {
    staticClass: "iq-mb-3 text-center",
    attrs: {
      "no-body": "",
      "bg-variant": "primary",
      "text-variant": "white"
    }
  }, [_c('blockquote', {
    staticClass: "blockquote mb-0 card-body"
  }, [_c('p', {
    staticClass: "font-size-14"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
    staticClass: "blockquote-footer"
  }, [_c('small', {
    staticClass: "text-white"
  }, [_vm._v("Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])])]), _c('b-card', {
    staticClass: "iq-mb-3 text-center",
    attrs: {
      "title": "Card title"
    }
  }, [_c('b-card-text', [_vm._v("This card has a regular title and short paragraphy of text below it.")]), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Last updated 3 mins ago")])])], 1), _c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "img-src": require('../../assets/images/page-img/07.jpg'),
      "img-alt": "#",
      "img-top": "",
      "no-body": ""
    }
  }), _c('b-card', {
    staticClass: "iq-mb-3 text-right",
    attrs: {
      "no-body": ""
    }
  }, [_c('blockquote', {
    staticClass: "blockquote mb-0 card-body"
  }, [_c('p', {
    staticClass: "font-size-14"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.")]), _c('footer', {
    staticClass: "blockquote-footer"
  }, [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Someone famous in "), _c('cite', {
    attrs: {
      "title": "Source Title"
    }
  }, [_vm._v("Source Title")])])])])]), _c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "title": "Card title"
    }
  }, [_c('b-card-text', [_vm._v("This is another card with title and supporting text below. This card has some additional content to make it slightly taller overall.")]), _c('b-card-text', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Last updated 3 mins ago")])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }