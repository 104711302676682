<template>
  <div>
    <div v-for="(menu, menuIndex) in menus" :key="menuIndex">
      <h4 class="card-title text-primary">{{ menu.menu }}</h4>
      <b-row class="text-center mb-4" v-if="menu.menu === 'Operations'">
        <b-col
          v-for="(child, childIndex) in menu.child" :key="childIndex"
          cols="4"
          md="1">
          <template v-if="['SCL','Operational'].includes(child.menu)">
          <img
           @click="openModalOps(child)"
          class="cursor-pointer"
              :src="child.icon ? child.icon : fallbackIcon"
              alt="Responsive image"
              v-b-tooltip.top="child.menu"
            />
            <p><small>{{ child.menu }}</small></p>
          </template>
          <template v-else>
            <router-link :to="{ path: child.link.name, query: { menuId: $route.query.menuId, menuObject: hashObject(child) } }">
              <img
                :src="child.icon ? child.icon : fallbackIcon"
                alt="Responsive image"
                v-b-tooltip.top="child.menu"
              />
              <p><small>{{ child.menu }}</small></p>
            </router-link>
          </template>
        </b-col>
      </b-row>
      <b-row class="text-center mb-4" v-else>
        <b-col
          v-for="(child, childIndex) in menu.child" :key="childIndex"
          cols="4"
          md="1">
          <router-link :to="{ path: child.link.name, query: { menuId: $route.query.menuId, menuObject: hashObject(child) } }">
            <img
              :src="child.icon ? child.icon : fallbackIcon"
              alt="Responsive image"
              v-b-tooltip.top="child.menu"
            />
            <p><small>{{ child.menu }}</small></p>
          </router-link>
        </b-col>
      </b-row>
    </div>

    <b-modal id="modal-select-vehicle" title="Select Vehicle First" centered no-close-on-backdrop>
      <div class="my-4">
        <v-select v-model="selectedFleet" label="name" :options="fleetList" :reduce="(fleetList) => fleetList.id"></v-select>
      </div>
      <template #modal-footer>
      <b-button size="sm" variant="danger" @click="$bvModal.hide('modal-select-vehicle')">
        Cancel
      </b-button>
      <b-button size="sm" variant="success" @click="selectVehicle()" :disabled="selectedFleet === null || selectedFleet === ''">
        OK
      </b-button>
    </template>
    </b-modal>
  </div>
</template>

<script>
import fallbackIcon from '../../assets/images/flaticon/operational.png'
import { fleetsActions } from '@src/Utils/helper'

export default {
  name: 'InAppMenu',
  props: {
    menus: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      fallbackIcon,
      fleetList: [],
      selectedFleet: null,
      fleetData: null
    }
  },
  methods: {
    hashObject(obj) {
      return window.btoa(JSON.stringify(obj))
    },
    selectVehicle() {
      this.$router.push({ path: this.fleetData.link.name, query: {vehicleId: this.selectedFleet, menuId: this.$route.query.menuId, menuObject: this.hashObject(this.fleetData) } })
      this.$bvModal.hide('modal-select-vehicle');
    },
    openModalOps(data) {
      this.fleetData = data;
      this.$bvModal.show('modal-select-vehicle');
    },
    ...fleetsActions,
    async getListFleet() {
        let params = {
          page: 1,
          active: true,
          showAll: true
        }
        const res = await this.getFleets(params)
        if (res.status == 'success') {
          this.fleetList = res.data
        } else {
          this.fleetList = []
          this.$swal(
            `Oops!`,
            res.data,
            'error'
          )
        }
    }
  },
  mounted() {
    console.log(this.menus, 'asksk')
    this.getListFleet();
  }
}
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}
</style>
