import axios from './auth'

const DOCKING_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/docking`;

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getJobCategories ({ commit }, params = {}) {
    const url = `${DOCKING_BASE_URL}/job-category`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async getJobCategoryDetail ({ commit }, params = {}) {
    const url = `${DOCKING_BASE_URL}/job-category/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async saveJobCategory ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/job-category`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateJobCategory ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/job-category/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteJobCategory ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/job-category/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async getJobs ({ commit }, params = {}) {
    const url = `${DOCKING_BASE_URL}/job`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async getJobDetail ({ commit }, params = {}) {
    const url = `${DOCKING_BASE_URL}/job/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async saveJob ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/job`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateJob ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/job/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteJob ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/job/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async getShipyards ({ commit }, params = {}) {
    const url = `${DOCKING_BASE_URL}/shipyard`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async getShipyardDetail ({ commit }, params = {}) {
    const url = `${DOCKING_BASE_URL}/shipyard/${params.id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async saveShipyard ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/shipyard`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async updateShipyard ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/shipyard/${payload.id}`

    const method = 'put'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async deleteShipyard ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/shipyard/${payload.id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },

  async getActiveDockingSchedules ({ commit }, params = {}) {
    const url = `${DOCKING_BASE_URL}/schedule/active`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async getProgressDockingSchedules ({ commit }, params = {}) {
    const url = `${DOCKING_BASE_URL}/schedule/progress`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async getDockingDetail ({ commit }, params = {}) {
    const url = `${DOCKING_BASE_URL}/schedule/${params.dockingId}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async getDockingTasks ({ commit }, params = {}) {
    const url = `${DOCKING_BASE_URL}/schedule/${params.dockingId}/task`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async getDockingActivities ({ commit }, params = {}) {
    const url = `${DOCKING_BASE_URL}/schedule/${params.dockingId}/task/${params.taskId}/activity`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: params
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async getDockingDetailTask ({ commit }, params = {}) {
    const url = `${DOCKING_BASE_URL}/schedule/${params.dockingId}/task/${params.taskId}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async createDockingScheduleInterval ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/schedule/interval`

    const method = 'post'
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
  };
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async createDockingEmergency ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/schedule/emergency-docking`

    const method = 'post'
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
  };
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async createDockingFromSchedule ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/schedule/${payload.dockingId}`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async createDockingPending ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/schedule/${payload.dockingId}/pending`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async createDockingTaskActivity ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/schedule/${payload.dockingId}/task/${payload.taskId}/activity`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async createDockingSeaTrial ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/schedule/${payload.dockingId}/sea-trial`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async createDockingHandOver ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/schedule/${payload.dockingId}/handover`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async reviewDocking ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/schedule/${payload.dockingId}/review`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
  async approveDocking ({ commit }, payload = {}) {
    const url = `${DOCKING_BASE_URL}/schedule/${payload.dockingId}/approve`

    const method = 'post'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      data: payload
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data
      }
    }
  },
}
