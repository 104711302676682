<template>
  <b-container fluid>
    <!-- Top Section !-->
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Master Port</h4>
          </template>
          <template v-slot:headerAction>
            <b-breadcrumb
              class="mt-3"
              :items="breadcrumIcon"
              style="background: none"
            />
          </template>
          <template v-slot:body>
            <b-row>
              <b-col cols="4" class="d-flex">
                <b-form-input
                  id="fleet_name"
                  type="text"
                  placeholder="Search Ports..."
                  v-model="ports.params.name"
                ></b-form-input>
                <b-button variant="primary" @click="fetchPorts(1)" class="ml-2">
                  <i class="fa fa-search"></i>
                </b-button>
              </b-col>
              <b-col cols="6"> </b-col>
              <b-col cols="2"
                v-if="menuPermission('export')">
                <b-button block variant="primary" @click="doExport">
                  <template v-if="!loading_export">
                    <i class="fa fa-file-excel"></i> &nbsp; Export Excel
                  </template>
                  <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Exporting...
                  </template>
                </b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Top Section !-->
    <!-- Content Section !-->
    <b-row>
      <!-- Card Fleet -->
      <b-col :sm="!menuPermission('add') && !menuPermission('update') ? '12' : '8'">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">List Port</h4>
          </template>
          <template v-slot:body>
            <div v-if="ports._loading" class="text-center my-5">
              <b-spinner type="grow" label="Spinning"></b-spinner>
            </div>
            <template v-else>
              <div class="table-responsive" v-if="ports.data && ports.data.length > 0">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th width="5%" class="text-center">No</th>
                      <th width="25%">Port</th>
                      <th width="15%">Type</th>
                      <th width="35%">PIC</th>
                      <th width="20%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(port, i) in ports.data" :key="`port-${i}`">
                      <td class="text-center">{{ ports.meta.currentPage > 1 ? ports.meta.perPage + i + 1 : i + 1 }}</td>
                      <td>{{ port.portName }}</td>
                      <td>{{ port.portType | replace('_',' ')  }}</td>
                      <td>
                        <ul class="m-0 pl-3">
                          <li
                            v-for="(user, is) in port.user"
                            :key="`user-${is}`"
                            style="list-style-type: circle"
                            >{{ user.name }}</li
                          >
                        </ul>
                      </td>
                      <td>
                        <b-button
                          v-if="menuPermission('update')"
                          variant="success"
                          size="sm"
                          class="mr-2"
                          @click="onEdit(port)"
                          ><i class="fa fa-edit"></i
                        ></b-button>
                        <b-button
                          v-if="menuPermission('delete')"
                          variant="danger"
                          size="sm"
                          @click="onDeletePort(port)"
                        >
                          <i v-if="!loading_delete" class="fa fa-trash"></i>
                          <template v-else>
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Deleting...
                          </template>
                        </b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="ports.data && ports.data.length > 0">
                <b-pagination
                  v-model="ports.meta.currentPage"
                  :total-rows="ports.meta.total"
                  @change="fetchPorts"
                  :per-page="ports.meta.perPage"
                  first-number
                  align="center"
                ></b-pagination>
              </div>
              <p v-else class="text-center my-4">No ports data found.</p>
            </template>
          </template>
        </iq-card>
      </b-col>
      <b-col sm="4"
        v-if="menuPermission('update') || menuPermission('add')">
        <iq-card :class="{ 'border border-danger shadow-lg': edit_mode }">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ edit_mode ? 'Edit' : 'Add' }} Port</h4>
          </template>
          <template v-slot:body>
            <b-form>
              <b-form-group label="Port" label-for="port_name">
                <b-form-input
                  id="port_name"
                  type="text"
                  placeholder="Port"
                  v-model="form.portName"
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Type Port" label-for="type_port">
                <v-select
                  v-model="form.portType"
                  :getOptionLabel="(type) => type.text"
                  :options="port_types"
                  :reduce="type => type.value"
                  placeholder="Select Type"
                ></v-select>
              </b-form-group>

              <b-form-group label="PIC" label-for="user">
                <!--<v-select
                  v-model="form.user"
                  multiple
                  :getOptionLabel="(user) => user.userDetail.name"
                  :options="users"
                  :reduce="user => user.id"
                  placeholder="Select PIC"
                ></v-select>-->
                <v-select v-model="form.user" label="text" :options="users" :reduce="users => users.value" placeholder="Select PIC" :multiple="true"></v-select>
              </b-form-group>

              <b-form-group class="text-right">
                <b-button type="button" variant="primary" @click="onSubmit">
                  <template v-if="!loading_form">
                    {{ edit_mode || !menuPermission('add') ? 'Save' : 'Add' }}
                  </template>
                  <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Saving...
                  </template>
                </b-button>
                <b-button
                  type="button"
                  variant="danger"
                  class="iq-bg-danger ml-2"
                  @click="onCancel"
                  >Cancel</b-button
                >
              </b-form-group>
            </b-form>
          </template>
        </iq-card>
      </b-col>
      <!-- End Card Fleet -->
    </b-row>
    <!-- End Content Section !-->
  </b-container>
</template>
<script>
import _ from 'lodash'
import { saveAs } from 'file-saver'
import { portsActions, usersActions } from '@src/Utils/helper'

export default {
  name: 'UiBreadcrumb',
  data () {
    return {
      breadcrumIcon: [
        {
          html: '<i class="ri-home-4-line mr-1 float-left"></i>Apps',
          href: '/ops/portal?menuId=2'
        },
        {
          text: 'Master Port',
          active: true
        }
      ],
      port_types: [
        { value: 'DOCKING', text: 'Docking' },
        { value: 'LOADING_PORT', text: 'Loading Port' },
        { value: 'DISCHARGING_PORT', text: 'Discharging Port' },
        { value: 'SHELTER', text: 'Shelter' },
        { value: 'BUNKER_POINT', text: 'Bunker Point' },
        { value: 'REPAIR_POINT', text: 'Repair Point' }
      ],
      users: [],
      loading: true,
      loading_form: false,
      loading_delete: false,
      loading_export: false,
      edit_mode: false,
      ports: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          name: '',
          active: true,
          perPage: 30,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      form: {
        portName: '',
        portType: '',
        user: []
      },
      edit_port_id: ''
    }
  },
  created () {
    this.fetchPorts = _.debounce(this.fetchPorts, 500)
  },
  mounted () {
    this.fetchPorts()
    this.fetchUsers()
  },
  methods: {
    ...portsActions,
    ...usersActions,
    async fetchPorts (page) {
      this.ports.params.page = page || 1
      this.ports._loading = true
      this.ports.data = []
      let params = this.$options.filters.cleanObject(this.ports.params)

      let res = await this.getPorts(params)
      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.ports.data = res.data.data
          this.ports.meta.perPage = res.data.perPage
          this.ports.meta.currentPage = res.data.currentPage
          this.ports.meta.total = res.data.total
          this.ports.meta.totalPage = res.data.totalPage
        }
        this.ports._loading = false
      } else {
        this.ports._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async fetchUsers () {
      /* let params = {
        page: 1,
        active: true
      }
      let res = await this.getUsers(params)
      this.users = res.data */
      let params = {
        active: true,
        page: 1,
        perPage: 1000
      }
      const res = await this.getUsers(params)
      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(val => {
            this.users.push({ value: val.id, text: val.userDetail.name })
          })
          // const findIdx = this.picList.findIndex(val => val.value === this.fleet.id)
          // if (findIdx !== -1) this.picSelected = this.fleet.id
        }
      } else {
        this.$swal(
          `Oops!`,
          res.message,
          'error'
        )
      }
    },
    async onSubmit () {
      let form_has_empty_value = false
      for (var key in this.form) {
        if (this.form[key] === '') form_has_empty_value = true
        else if (Array.isArray(this.form[key]) && !this.form[key].length) { form_has_empty_value = true }
      }

      if (form_has_empty_value) {
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )
      } else {
        this.loading_form = true

        let data = {
          portName: this.form.portName,
          portType: this.form.portType,
          user: []
        }
        if (Array.isArray(this.form.user)) { this.form.user.forEach((user) => data.user.push({ id: user })) } else data.user = [{ id: this.form.user }]

        if (!this.edit_mode) {
          let res = await this.savePort(data)
          if (res.status == 'success') {
            this.loading_form = false
            this.$swal('Port added!', 'Port added successfully.', 'success')
            this.fetchPorts()
            this.onCancel()
          } else {
            this.loading_form = false
            if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
            }
          }
        } else {
          let res = await this.updatePort({ id: this.edit_port_id, data })
          if (res.status == 'success') {
            this.loading_form = false
            this.$swal('Port updated!', 'Port updated successfully.', 'success')
            this.fetchPorts()
            this.onCancel()
          } else {
            this.loading_form = false
            if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
            }
          }
        }
      }
    },
    async doExport () {
      this.loading_export = true
      let res = await this.exportPort()
      if (res.status == 'success') {
        this.loading_export = false
        saveAs(res.data, 'Port List.xlsx')
      } else {
        this.loading_export = false
        if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    onEdit (port) {
      this.edit_mode = true
      this.edit_port_id = port.id
      this.form.portName = port.portName
      this.form.portType = port.portType
      this.form.user = []
      port.user.forEach((user) => (this.form.user = user.id))
    },
    onCancel () {
      this.edit_mode = false
      this.form = {
        portName: '',
        portType: '',
        user: []
      }
    },
    onDeletePort (port) {
      this.$swal({
        title: 'Delete port?',
        text: `Port ${port.portName} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete = true
          let res = await this.deletePort(port.id)
          if (res.status != 'success') {
            this.loading_delete = false
            if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.loading_delete = false
            this.fetchPorts()
            this.$swal(
              `Role deleted!`,
              `Role ${port.portName} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    menuPermission (output) {
      const { menuObject } = this.$route.query

      if (!menuObject) {
        return true
      }

      return JSON.parse(window.atob(menuObject))[output]
    }
  },
  filters: {
    replace: function (st, rep, repWith) {
      const result = st.split(rep).join(repWith)
      return result
    }
  }
}
</script>
