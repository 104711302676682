var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    attrs: {
      "action": "#",
      "method": "post"
    },
    on: {
      "submit": _vm.onSave
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("CREW BARU")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$user$photo, _vm$error, _vm$error2, _vm$error3, _vm$error4;
        return [_c('b-form-group', {
          staticClass: "text-center"
        }, [_c('div', {
          staticClass: "add-img-user profile-img-edit"
        }, [_c('b-img', {
          staticClass: "profile-pic height-150 width-150",
          attrs: {
            "fluid": "",
            "src": (_vm$user$photo = _vm.user.photo) !== null && _vm$user$photo !== void 0 ? _vm$user$photo : _vm.user.default_photo,
            "alt": "profile-pic"
          }
        }), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.user.photo,
            expression: "user.photo"
          }],
          attrs: {
            "type": "hidden"
          },
          domProps: {
            "value": _vm.user.photo
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.user, "photo", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "p-image"
        }, [_c('b-button', {
          staticClass: "upload-button iq-bg-primary position-relative",
          attrs: {
            "variant": "none"
          }
        }, [_c('input', {
          staticClass: "h-100 position-absolute",
          staticStyle: {
            "opacity": "0"
          },
          attrs: {
            "type": "file",
            "accept": "image/*",
            "required": ""
          },
          on: {
            "change": _vm.previewImage
          }
        }), _vm._v(" File Upload ")])], 1)], 1), _c('div', {
          staticClass: "img-extension mt-3"
        }, [_c('div', {
          staticClass: "d-inline-block align-items-center"
        }, [_c('span', [_vm._v("Only")]), _c('b-link', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_vm._v(".jpg")]), _c('b-link', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_vm._v(".png")]), _c('b-link', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_vm._v(".jpeg")]), _c('span', [_vm._v("allowed")])], 1)])]), _c('b-alert', {
          staticClass: "text-center text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-text"
        }, [_c('strong', [_vm._v("DATA DIRI")])])]), _c('b-form-group', {
          attrs: {
            "label": "Nama Lengkap",
            "label-for": "nama_lengkap"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "nama_lengkap",
            "name": "nama_lengkap",
            "required": ""
          },
          model: {
            value: _vm.user.full_name,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "full_name", $$v);
            },
            expression: "user.full_name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Nama Panggilan",
            "label-for": "nama_panggilan"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "nama_panggilan",
            "name": "nama_panggilan",
            "required": ""
          },
          model: {
            value: _vm.user.nick_name,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "nick_name", $$v);
            },
            expression: "user.nick_name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Nomor Induk Kependudukan (NIK)",
            "label-for": "nik"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "nik",
            "name": "nik",
            "required": ""
          },
          model: {
            value: _vm.user.nik_ktp,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "nik_ktp", $$v);
            },
            expression: "user.nik_ktp"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Seaferer Code",
            "label-for": "seafererCode"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "seafererCode",
            "name": "seafererCode",
            "required": ""
          },
          model: {
            value: _vm.user.seaferer_code,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "seaferer_code", $$v);
            },
            expression: "user.seaferer_code"
          }
        }), (_vm$error = _vm.error) !== null && _vm$error !== void 0 && _vm$error.seaferer_code ? _c('b-form-text', {
          staticClass: "text-danger",
          attrs: {
            "id": "seafererCode-live-help"
          }
        }, [_vm._v("*" + _vm._s((_vm$error2 = _vm.error) === null || _vm$error2 === void 0 ? void 0 : _vm$error2.seaferer_code))]) : _vm._e()], 1), _c('b-form-group', {
          attrs: {
            "label": "Nomor Pokok Wajib Pajak (NPWP)",
            "label-for": "npwp"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "npwp",
            "name": "npwp",
            "required": ""
          },
          model: {
            value: _vm.user.npwp_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "npwp_number", $$v);
            },
            expression: "user.npwp_number"
          }
        })], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Jabatan / Ijazah",
            "label-for": "jabatan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsJabatan,
            "required": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Jabatan")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.position_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "position_id", $$v);
            },
            expression: "user.position_id"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Nama Kapal",
            "label-for": "kapal"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsVehicles,
            "required": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Nama Kapal")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.vehicle_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "vehicle_id", $$v);
            },
            expression: "user.vehicle_id"
          }
        })], 1)], 1), _c('b-form-group', {
          attrs: {
            "label": "Kewarganegaraan",
            "label-for": "kewarganegaraan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsKewarganegaraan,
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Kewarganegaraan")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.citizenship,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "citizenship", $$v);
            },
            expression: "user.citizenship"
          }
        })], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Tempat Lahir",
            "label-for": "tempat_lahir"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "tempat_lahir",
            "name": "tempat_lahir",
            "required": ""
          },
          model: {
            value: _vm.user.place_of_birth,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "place_of_birth", $$v);
            },
            expression: "user.place_of_birth"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Tanggal Lahir",
            "label-for": "tanggal_lahir"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "tanggal_lahir",
            "required": ""
          },
          model: {
            value: _vm.user.date_of_birth,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "date_of_birth", $$v);
            },
            expression: "user.date_of_birth"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show ")])], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Golongan Darah",
            "label-for": "golongan_darah"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsGolonganDarah,
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Golongan Darah")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.golongan_darah_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "golongan_darah_id", $$v);
            },
            expression: "user.golongan_darah_id"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Agama",
            "label-for": "Agama"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsAgama,
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Agama")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.religion_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "religion_id", $$v);
            },
            expression: "user.religion_id"
          }
        })], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Status Pernikahan",
            "label-for": "StatusPernikahan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsStatusPernikahan,
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Status")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.marital_status_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "marital_status_id", $$v);
            },
            expression: "user.marital_status_id"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Jumlah Anak",
            "label-for": "jumlah_anak"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "jumlah_anak",
            "name": "jumlah_anak",
            "required": ""
          },
          model: {
            value: _vm.user.number_of_children,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "number_of_children", $$v);
            },
            expression: "user.number_of_children"
          }
        })], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "No. Tlp / Handphone",
            "label-for": "no_handphone"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "no_handphone",
            "name": "no_handphone",
            "required": ""
          },
          model: {
            value: _vm.user.hp_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "hp_number", $$v);
            },
            expression: "user.hp_number"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Email",
            "label-for": "email"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "email",
            "name": "email",
            "required": ""
          },
          model: {
            value: _vm.user.email,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "email", $$v);
            },
            expression: "user.email"
          }
        }), (_vm$error3 = _vm.error) !== null && _vm$error3 !== void 0 && _vm$error3.email ? _c('b-form-text', {
          staticClass: "text-danger",
          attrs: {
            "id": "email-live-help"
          }
        }, [_vm._v("*" + _vm._s((_vm$error4 = _vm.error) === null || _vm$error4 === void 0 ? void 0 : _vm$error4.email))]) : _vm._e()], 1)], 1), _c('b-alert', {
          staticClass: "text-center text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-text"
        }, [_c('strong', [_vm._v("JOIN DATE")])])]), _c('b-form-group', {
          attrs: {
            "label": "Nomor Pegawai",
            "label-for": "nip"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "nip",
            "name": "nip"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Sign On",
            "label-for": "sign_on"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "sign_on",
            "name": "sign_on",
            "type": "date",
            "required": ""
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Join Onboard",
            "label-for": "join_onboard"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "join_onboard",
            "name": "join_onboard",
            "type": "date"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Sign Off",
            "label-for": "sign_off"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "sign_off",
            "name": "sign_off",
            "type": "date",
            "required": ""
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "File Contract",
            "label-for": "file-contract"
          }
        }, [_c('b-form-file', {
          attrs: {
            "plain": "",
            "id": "file-contract"
          }
        })], 1), _c('b-alert', {
          staticClass: "text-center text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-text"
        }, [_c('strong', [_vm._v("PAKAIAN & SEPATU")])])]), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Tinggi Badan",
            "label-for": "tinggi_badan"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.user.height,
            expression: "user.height"
          }],
          staticClass: "form-control",
          staticStyle: {
            "border": "solid 1px gray"
          },
          attrs: {
            "type": "number",
            "id": "tinggi_badan",
            "required": ""
          },
          domProps: {
            "value": _vm.user.height
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.user, "height", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "inputGroupPrepend2"
          }
        }, [_vm._v("CM")])])])]), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Berat Badan",
            "label-for": "berat_badan"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.user.weight,
            expression: "user.weight"
          }],
          staticClass: "form-control",
          staticStyle: {
            "border": "solid 1px gray"
          },
          attrs: {
            "type": "number",
            "id": "berat_badan",
            "required": ""
          },
          domProps: {
            "value": _vm.user.weight
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.user, "weight", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "inputGroupPrepend2"
          }
        }, [_vm._v("KG")])])])])], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Wearpack",
            "label-for": "wearpack"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.wearpackCrew,
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Wearpack")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.wearpack,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "wearpack", $$v);
            },
            expression: "user.wearpack"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Sepatu Safety",
            "label-for": "sepatu_safety"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "sepatu_safety",
            "name": "sepatu_safety",
            "placeholder": "E.g. 40",
            "required": ""
          },
          model: {
            value: _vm.user.size_of_shoes,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "size_of_shoes", $$v);
            },
            expression: "user.size_of_shoes"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Helmet",
            "label-for": "helmet"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "helmet",
            "name": "helmet"
          }
        })], 1)], 1), _c('b-alert', {
          staticClass: "text-center text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-text"
        }, [_c('strong', [_vm._v("BASIC SALLARY")])])]), _c('b-form-group', {
          attrs: {
            "label": "Gaji Pokok",
            "label-for": "gaji_pokok"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "inputGroupPrepend2"
          }
        }, [_vm._v("Rp.")])]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "gaji_pokok"
          }
        })])]), _c('b-form-group', {
          attrs: {
            "label": "Uang Cuti",
            "label-for": "uang_cuti"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "inputGroupPrepend2"
          }
        }, [_vm._v("Rp.")])]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_cuti"
          }
        })])]), _c('b-form-group', {
          attrs: {
            "label": "Lembur / Jam (Tetap)",
            "label-for": "uang_lembur"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "inputGroupPrepend2"
          }
        }, [_vm._v("Rp.")])]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_lembur"
          }
        })])]), _c('b-form-group', {
          attrs: {
            "label": "Tunjangan Jabatan",
            "label-for": "tunjangan_jabatan"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "inputGroupPrepend2"
          }
        }, [_vm._v("Rp.")])]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "tunjangan_jabatan"
          }
        })])]), _c('b-form-group', {
          attrs: {
            "label": "Uang Makan / Lain-lain",
            "label-for": "uang_makan"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "inputGroupPrepend2"
          }
        }, [_vm._v("Rp.")])]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "uang_makan"
          }
        })])]), _c('b-form-group', {
          staticClass: "text-right",
          attrs: {
            "label": "Fix Income"
          }
        }, [_c('h3', {
          staticClass: "text-primary"
        }, [_vm._v("Rp. 7.000.000,-")])]), _c('b-alert', {
          staticClass: "text-center text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-text"
        }, [_c('strong', [_vm._v("AKUN BANK")])])]), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Atas Nama",
            "label-for": "atas_nama"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "atas_nama",
            "name": "atas_nama",
            "placeholder": "Atas Nama",
            "required": ""
          },
          model: {
            value: _vm.user.rekening_name,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "rekening_name", $$v);
            },
            expression: "user.rekening_name"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Nama Bank",
            "label-for": "nama_bank"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsBank,
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Bank")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.bank_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "bank_id", $$v);
            },
            expression: "user.bank_id"
          }
        })], 1)], 1), _c('b-form-group', {
          attrs: {
            "label": "Nomor Rekening",
            "label-for": "no_rek"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "no_rek",
            "name": "no_rek",
            "placeholder": "Nomor Rekening",
            "required": ""
          },
          model: {
            value: _vm.user.rekening_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "rekening_number", $$v);
            },
            expression: "user.rekening_number"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("FORM CREW BARU")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("ALAMAT KTP")]), _c('FormInputAddress', {
          model: {
            value: _vm.user.ktp_address,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "ktp_address", $$v);
            },
            expression: "user.ktp_address"
          }
        })], 1), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("ALAMAT DOMISILI")]), _c('FormInputAddress', {
          attrs: {
            "checked": _vm.user._checked
          },
          model: {
            value: _vm.user.domicile_address,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "domicile_address", $$v);
            },
            expression: "user.domicile_address"
          }
        }), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-12"
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": ""
          },
          model: {
            value: _vm.user._checked,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "_checked", $$v);
            },
            expression: "user._checked"
          }
        }, [_c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" Alamat domisili sama dengan alamat ktp")])])], 1)], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("KELUARGA YANG BISA DI HUBUNGI ( EMERGENCY CALL )")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Nama Lengkap",
            "label-for": "nama_lengkap"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "nama_lengkap",
            "name": "nama_lengkap",
            "required": ""
          },
          model: {
            value: _vm.user.family_emergency_call.family_emergency_name,
            callback: function callback($$v) {
              _vm.$set(_vm.user.family_emergency_call, "family_emergency_name", $$v);
            },
            expression: "\n                        user.family_emergency_call.family_emergency_name\n                      "
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "No. Tlp/Handphone",
            "label-for": "handphone"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "handphone",
            "name": "handphone",
            "required": ""
          },
          model: {
            value: _vm.user.family_emergency_call.emergency_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user.family_emergency_call, "emergency_number", $$v);
            },
            expression: "user.family_emergency_call.emergency_number"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Hubungan",
            "label-for": "hubungan"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "hubungan",
            "name": "hubungan",
            "required": ""
          },
          model: {
            value: _vm.user.family_emergency_call.family_emergency_status,
            callback: function callback($$v) {
              _vm.$set(_vm.user.family_emergency_call, "family_emergency_status", $$v);
            },
            expression: "\n                        user.family_emergency_call.family_emergency_status\n                      "
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Alamat",
            "label-for": "alamat"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "alamat",
            "name": "alamat",
            "rows": "9",
            "required": ""
          },
          model: {
            value: _vm.user.family_emergency_call.family_emergency_address,
            callback: function callback($$v) {
              _vm.$set(_vm.user.family_emergency_call, "family_emergency_address", $$v);
            },
            expression: "\n                        user.family_emergency_call.family_emergency_address\n                      "
          }
        })], 1)], 1)], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("PENDIDIKAN")]), _vm._l(_vm.user.pendidikan, function (state, index) {
          return _c('b-row', {
            key: index
          }, [_vm.user.pendidikan.length > 1 && index > 0 ? _c('div', {
            staticClass: "d-flex w-100 px-3 my-1 justify-content-end cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.deletePendidikanForm(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e(), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Sekolah/Universitas",
              "label-for": "instansiPendidikan"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "instansiPendidikan",
              "name": "instansiPendidikan",
              "required": ""
            },
            model: {
              value: state.sekolah,
              callback: function callback($$v) {
                _vm.$set(state, "sekolah", $$v);
              },
              expression: "state.sekolah"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Jenjang",
              "label-for": "jenjang"
            }
          }, [_c('b-form-select', {
            attrs: {
              "plain": "",
              "options": _vm.optionsJenjang,
              "size": "sm",
              "required": ""
            },
            scopedSlots: _vm._u([{
              key: "first",
              fn: function fn() {
                return [_c('b-form-select-option', {
                  attrs: {
                    "value": null
                  }
                }, [_vm._v("Select Jenjang")])];
              },
              proxy: true
            }], null, true),
            model: {
              value: state.jenjang,
              callback: function callback($$v) {
                _vm.$set(state, "jenjang", $$v);
              },
              expression: "state.jenjang"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Jurusan",
              "label-for": "jurusan"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "jurusan",
              "name": "jurusan",
              "required": ""
            },
            model: {
              value: state.jurusan,
              callback: function callback($$v) {
                _vm.$set(state, "jurusan", $$v);
              },
              expression: "state.jurusan"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "IPK/Nilai Akhir",
              "label-for": "ipk"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "ipk",
              "name": "ipk",
              "step": "0.0001",
              "min": "0",
              "required": ""
            },
            model: {
              value: state.nilai,
              callback: function callback($$v) {
                _vm.$set(state, "nilai", $$v);
              },
              expression: "state.nilai"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Tahun Mulai",
              "label-for": "tahunMulai"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "tahunMulai",
              "name": "tahunMulai",
              "required": ""
            },
            model: {
              value: state.tahun_mulai,
              callback: function callback($$v) {
                _vm.$set(state, "tahun_mulai", $$v);
              },
              expression: "state.tahun_mulai"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Tahun Selesai",
              "label-for": "tahunSelesai"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "tahunSelesai",
              "name": "tahunSelesai",
              "required": ""
            },
            model: {
              value: state.tahun_selesai,
              callback: function callback($$v) {
                _vm.$set(state, "tahun_selesai", $$v);
              },
              expression: "state.tahun_selesai"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Kota",
              "label-for": "kota"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "kota",
              "name": "kota",
              "required": ""
            },
            model: {
              value: state.kota,
              callback: function callback($$v) {
                _vm.$set(state, "kota", $$v);
              },
              expression: "state.kota"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Negara",
              "label-for": "negara"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "negara",
              "name": "negara",
              "required": ""
            },
            model: {
              value: state.negara,
              callback: function callback($$v) {
                _vm.$set(state, "negara", $$v);
              },
              expression: "state.negara"
            }
          })], 1), _vm.user.pendidikan.length > 1 ? _c('div', {
            staticClass: "d-flex w-100 px-2 my-1 border-bottom"
          }) : _vm._e()], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.addPendidikanForm
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM ")])], 1)], 1)], 2), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("DATA KELUARGA")]), _vm._l(_vm.user.family, function (state, index) {
          return _c('div', {
            key: index
          }, [_vm.user.family.length > 1 && index > 0 ? _c('div', {
            staticClass: "d-flex w-100 px-1 my-1 justify-content-end cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.deleteFamilyForm(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e(), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Nama Lengkap",
              "label-for": "nama_lengkap"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "nama_lengkap",
              "name": "nama_lengkap",
              "required": ""
            },
            model: {
              value: state.nama_lengkap,
              callback: function callback($$v) {
                _vm.$set(state, "nama_lengkap", $$v);
              },
              expression: "state.nama_lengkap"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Hubungan",
              "label-for": "hubungan"
            }
          }, [_c('b-form-select', {
            attrs: {
              "plain": "",
              "options": _vm.hubungan,
              "size": "sm",
              "required": ""
            },
            scopedSlots: _vm._u([{
              key: "first",
              fn: function fn() {
                return [_c('b-form-select-option', {
                  attrs: {
                    "value": null
                  }
                }, [_vm._v("Select Hubungan")])];
              },
              proxy: true
            }], null, true),
            model: {
              value: state.status,
              callback: function callback($$v) {
                _vm.$set(state, "status", $$v);
              },
              expression: "state.status"
            }
          })], 1)], 1), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Jenis Kelamin",
              "label-for": "jenis_kelamin"
            }
          }, [_c('b-form-select', {
            attrs: {
              "plain": "",
              "options": _vm.jenisKelaminFamily,
              "size": "sm",
              "required": ""
            },
            scopedSlots: _vm._u([{
              key: "first",
              fn: function fn() {
                return [_c('b-form-select-option', {
                  attrs: {
                    "value": null
                  }
                }, [_vm._v("Select Jenis Kelamin")])];
              },
              proxy: true
            }], null, true),
            model: {
              value: state.jenis_kelamin,
              callback: function callback($$v) {
                _vm.$set(state, "jenis_kelamin", $$v);
              },
              expression: "state.jenis_kelamin"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Umur",
              "label-for": "umur"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "umur",
              "name": "umur",
              "required": ""
            },
            model: {
              value: state.age_family,
              callback: function callback($$v) {
                _vm.$set(state, "age_family", $$v);
              },
              expression: "state.age_family"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Pekerjaan",
              "label-for": "pekerjaan"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "pekerjaan",
              "name": "pekerjaan",
              "required": ""
            },
            model: {
              value: state.pekerjaan,
              callback: function callback($$v) {
                _vm.$set(state, "pekerjaan", $$v);
              },
              expression: "state.pekerjaan"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "No. Tlp/Handphone",
              "label-for": "handphone"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "handphone",
              "name": "handphone",
              "required": ""
            },
            model: {
              value: state.no_hp,
              callback: function callback($$v) {
                _vm.$set(state, "no_hp", $$v);
              },
              expression: "state.no_hp"
            }
          })], 1)], 1), _vm.user.family.length > 1 ? _c('div', {
            staticClass: "d-flex w-100 px-2 my-1 border-bottom"
          }) : _vm._e()], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.addFamilyForm
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM ")])], 1)], 1)], 2), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("TRAVEL DOCUMENT")]), _vm._l(_vm.user.travel_document, function (state, index) {
          return _c('div', {
            key: index
          }, [_vm.user.travel_document.length > 1 && index > 0 ? _c('div', {
            staticClass: "d-flex w-100 px-1 my-1 justify-content-end cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.deleteTravelDocument(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e(), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Nama Dokumen",
              "label-for": "nama_dokumen"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "nama_dokumen",
              "name": "nama_dokumen",
              "required": ""
            },
            model: {
              value: state.document_name,
              callback: function callback($$v) {
                _vm.$set(state, "document_name", $$v);
              },
              expression: "state.document_name"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Kategori Dokumen",
              "label-for": "kategori_dokumen"
            },
            model: {
              value: state.document_name,
              callback: function callback($$v) {
                _vm.$set(state, "document_name", $$v);
              },
              expression: "state.document_name"
            }
          }, [_c('b-form-select', {
            attrs: {
              "plain": "",
              "options": _vm.kategoriDokumen,
              "size": "sm",
              "required": ""
            },
            scopedSlots: _vm._u([{
              key: "first",
              fn: function fn() {
                return [_c('b-form-select-option', {
                  attrs: {
                    "value": null
                  }
                }, [_vm._v("Select Kategori")])];
              },
              proxy: true
            }], null, true),
            model: {
              value: state.document_category,
              callback: function callback($$v) {
                _vm.$set(state, "document_category", $$v);
              },
              expression: "state.document_category"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Nomor Dokumen",
              "label-for": "nomor_dokumen"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "nomor_dokumen",
              "name": "nomor_dokumen",
              "required": ""
            },
            model: {
              value: state.document_number,
              callback: function callback($$v) {
                _vm.$set(state, "document_number", $$v);
              },
              expression: "state.document_number"
            }
          })], 1)], 1), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Date Issued",
              "label-for": "date_issued"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "date_issued",
              "required": ""
            },
            model: {
              value: state.date_issued,
              callback: function callback($$v) {
                _vm.$set(state, "date_issued", $$v);
              },
              expression: "state.date_issued"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Masa Berlaku",
              "label-for": "masa_berlaku"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "masa_berlaku",
              "required": ""
            },
            model: {
              value: state.validity_period,
              callback: function callback($$v) {
                _vm.$set(state, "validity_period", $$v);
              },
              expression: "state.validity_period"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1)], 1), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Place Issued",
              "label-for": "place_issued"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "place_issued",
              "name": "place_issued",
              "required": ""
            },
            model: {
              value: state.place_issued,
              callback: function callback($$v) {
                _vm.$set(state, "place_issued", $$v);
              },
              expression: "state.place_issued"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Attachment",
              "label-for": "attachment"
            }
          }, [_c('div', {
            staticClass: "custom-file"
          }, [_c('input', {
            staticClass: "custom-file-input",
            attrs: {
              "type": "file",
              "id": "validatedCustomFile",
              "required": ""
            },
            on: {
              "change": function change(event) {
                return _vm.prefiewFiles(event, state);
              }
            }
          }), !state.file ? _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v("Browse file...")]) : _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v(_vm._s(state.file_label))])]), _c('small', {
            staticClass: "text-muted"
          }, [_vm._v(" File Type * : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File CapaKota * : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_vm._v("3 MB / File")])], 1)])], 1), _vm.user.travel_document.length > 1 ? _c('div', {
            staticClass: "d-flex w-100 px-2 my-1 border-bottom"
          }) : _vm._e()], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.addTravelDocumentForm
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM ")])], 1)], 1)], 2), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("CERTIFICATE OF COMPETENCY")]), _vm._l(_vm.user.certificate_of_competency, function (state, index) {
          return _c('div', {
            key: index
          }, [_vm.user.certificate_of_competency.length > 1 && index > 0 ? _c('div', {
            staticClass: "d-flex w-100 px-1 my-1 justify-content-end cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.deleteCertificateOfCompetencyForm(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e(), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Ijazah & Endorse",
              "label-for": "ijazah-endorse"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "ijazah-endorse",
              "name": "ijazah-endorse",
              "required": ""
            },
            model: {
              value: state.document_name,
              callback: function callback($$v) {
                _vm.$set(state, "document_name", $$v);
              },
              expression: "state.document_name"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Nomor Sertifikat",
              "label-for": "nomor_sertifikat"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "nomor_sertifikat",
              "name": "nomor_sertifikat",
              "required": ""
            },
            model: {
              value: state.document_number,
              callback: function callback($$v) {
                _vm.$set(state, "document_number", $$v);
              },
              expression: "state.document_number"
            }
          })], 1)], 1), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Date Issued",
              "label-for": "date_issued"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "date_issued",
              "required": ""
            },
            model: {
              value: state.date_issued,
              callback: function callback($$v) {
                _vm.$set(state, "date_issued", $$v);
              },
              expression: "state.date_issued"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Masa Berlaku",
              "label-for": "masa_berlaku"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "masa_berlaku",
              "required": ""
            },
            model: {
              value: state.validity_period,
              callback: function callback($$v) {
                _vm.$set(state, "validity_period", $$v);
              },
              expression: "state.validity_period"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1)], 1), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Place Issued",
              "label-for": "place_issued"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "place_issued",
              "name": "place_issued",
              "required": ""
            },
            model: {
              value: state.place_issued,
              callback: function callback($$v) {
                _vm.$set(state, "place_issued", $$v);
              },
              expression: "state.place_issued"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Attachment",
              "label-for": "attachment"
            }
          }, [_c('div', {
            staticClass: "custom-file"
          }, [_c('input', {
            staticClass: "custom-file-input",
            attrs: {
              "type": "file",
              "id": "validatedCustomFile",
              "required": ""
            },
            on: {
              "change": function change(event) {
                return _vm.prefiewFiles(event, state);
              }
            }
          }), !state.file ? _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v("Browse file...")]) : _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v(_vm._s(state.file_label))])]), _c('small', {
            staticClass: "text-muted"
          }, [_vm._v(" File Type * : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File CapaKota * : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_vm._v("3 MB / File")])], 1)])], 1), _vm.user.certificate_of_competency.length > 1 ? _c('div', {
            staticClass: "d-flex w-100 px-2 my-1 border-bottom"
          }) : _vm._e()], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.addCertificateOfCompetencyForm
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM ")])], 1)], 1)], 2), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("CERTIFICATE OF PROFICIENCY")]), _vm._l(_vm.user.certificate_of_proficiency, function (state, index) {
          return _c('div', {
            key: index
          }, [_vm.user.certificate_of_proficiency.length > 1 && index > 0 ? _c('div', {
            staticClass: "d-flex w-100 px-1 my-1 justify-content-end cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.deleteCertificateOfProfiencyForm(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e(), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Nama Sertifikat",
              "label-for": "nama_sertifikat"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "nama_sertifikat",
              "name": "nama_sertifikat",
              "required": ""
            },
            model: {
              value: state.document_name,
              callback: function callback($$v) {
                _vm.$set(state, "document_name", $$v);
              },
              expression: "state.document_name"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Nomor Dokumen",
              "label-for": "nomor_dokumen"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "nomor_dokumen",
              "name": "nomor_dokumen",
              "required": ""
            },
            model: {
              value: state.document_number,
              callback: function callback($$v) {
                _vm.$set(state, "document_number", $$v);
              },
              expression: "state.document_number"
            }
          })], 1)], 1), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Date Issued",
              "label-for": "date_issued"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "date_issued",
              "required": ""
            },
            model: {
              value: state.date_issued,
              callback: function callback($$v) {
                _vm.$set(state, "date_issued", $$v);
              },
              expression: "state.date_issued"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Masa Berlaku",
              "label-for": "masa_berlaku"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "masa_berlaku",
              "required": ""
            },
            model: {
              value: state.validity_period,
              callback: function callback($$v) {
                _vm.$set(state, "validity_period", $$v);
              },
              expression: "state.validity_period"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1)], 1), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Place Issued",
              "label-for": "place_issued"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "place_issued",
              "name": "place_issued",
              "placeholder": "E.g Jakarta",
              "required": ""
            },
            model: {
              value: state.place_issued,
              callback: function callback($$v) {
                _vm.$set(state, "place_issued", $$v);
              },
              expression: "state.place_issued"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Attachment",
              "label-for": "attachment"
            }
          }, [_c('div', {
            staticClass: "custom-file"
          }, [_c('input', {
            staticClass: "custom-file-input",
            attrs: {
              "type": "file",
              "id": "validatedCustomFile",
              "required": ""
            },
            on: {
              "change": function change(event) {
                return _vm.prefiewFiles(event, state);
              }
            }
          }), !state.file ? _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v("Browse file...")]) : _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v(_vm._s(state.file_label))])]), _c('small', {
            staticClass: "text-muted"
          }, [_vm._v(" File Type * : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File CapaKota * : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_vm._v("3 MB / File")])], 1)])], 1), _vm.user.certificate_of_proficiency.length > 1 ? _c('div', {
            staticClass: "d-flex w-100 px-2 my-1 border-bottom"
          }) : _vm._e()], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.addCertificateOfProfiencyForm
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM ")])], 1)], 1)], 2), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("PENGALAMAN BERLAYAR, MINIMAL 5 PENGALAMAN TERAKHIR")]), _vm._l(_vm.user.sail_experience, function (state, index) {
          return _c('div', {
            key: index
          }, [_vm.user.sail_experience.length > 1 && index > 0 ? _c('div', {
            staticClass: "d-flex w-100 px-1 my-1 justify-content-end cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.deleteSailExperienceForm(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e(), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Nama Kapal",
              "label-for": "nama_kapal"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "nama_kapal",
              "name": "nama_kapal",
              "required": ""
            },
            model: {
              value: state.ship_name,
              callback: function callback($$v) {
                _vm.$set(state, "ship_name", $$v);
              },
              expression: "state.ship_name"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Pemilik",
              "label-for": "pemilik"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "pemilik",
              "name": "pemilik",
              "required": ""
            },
            model: {
              value: state.owner,
              callback: function callback($$v) {
                _vm.$set(state, "owner", $$v);
              },
              expression: "state.owner"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Bendera",
              "label-for": "bendera"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "bendera",
              "name": "bendera",
              "required": ""
            },
            model: {
              value: state.flag,
              callback: function callback($$v) {
                _vm.$set(state, "flag", $$v);
              },
              expression: "state.flag"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Kawasan",
              "label-for": "kawasan"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "kawasan",
              "name": "kawasan",
              "required": ""
            },
            model: {
              value: state.area,
              callback: function callback($$v) {
                _vm.$set(state, "area", $$v);
              },
              expression: "state.area"
            }
          })], 1)], 1), _c('b-row', [_c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Jabatan",
              "label-for": "jabatan"
            }
          }, [_c('b-form-select', {
            attrs: {
              "plain": "",
              "options": _vm.optionsJabatan,
              "size": "sm"
            },
            scopedSlots: _vm._u([{
              key: "first",
              fn: function fn() {
                return [_c('b-form-select-option', {
                  attrs: {
                    "value": null
                  }
                }, [_vm._v("Select Jabatan")])];
              },
              proxy: true
            }], null, true),
            model: {
              value: state.position_id,
              callback: function callback($$v) {
                _vm.$set(state, "position_id", $$v);
              },
              expression: "state.position_id"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "No. Tlp Perusaahaan",
              "label-for": "no_tlp"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "no_tlp",
              "name": "no_tlp",
              "required": ""
            },
            model: {
              value: state.no_number_company,
              callback: function callback($$v) {
                _vm.$set(state, "no_number_company", $$v);
              },
              expression: "state.no_number_company"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Tanggal Bergabung",
              "label-for": "tanggal_bergabung"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "tanggal_bergabung",
              "required": ""
            },
            model: {
              value: state.date_join,
              callback: function callback($$v) {
                _vm.$set(state, "date_join", $$v);
              },
              expression: "state.date_join"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1), _c('b-form-group', {
            staticClass: "col-md-3",
            attrs: {
              "label": "Tanggal Behenti",
              "label-for": "tanggal_berhenti"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "tanggal_berhenti",
              "required": ""
            },
            model: {
              value: state.date_end,
              callback: function callback($$v) {
                _vm.$set(state, "date_end", $$v);
              },
              expression: "state.date_end"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1)], 1), _vm.user.sail_experience.length > 1 ? _c('div', {
            staticClass: "d-flex w-100 px-2 my-1 border-bottom"
          }) : _vm._e()], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.addSailExperienceForm
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM ")])], 1)], 1)], 2), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("ORGANISASI")]), _vm._l(_vm.user.organisasi, function (state, index) {
          return _c('b-row', {
            key: index
          }, [_vm.user.organisasi.length > 1 && index > 0 ? _c('div', {
            staticClass: "d-flex w-100 px-1 my-1 justify-content-end cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.deleteOrganisasiForm(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e(), _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('b-row', [_c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Nama Organisasi",
              "label-for": "namaOrganisasi"
            }
          }, [_c('b-form-input', {
            staticStyle: {
              "height": "35px"
            },
            attrs: {
              "id": "namaOrganisasi",
              "name": "namaOrganisasi",
              "required": ""
            },
            model: {
              value: state.nama_organisasi,
              callback: function callback($$v) {
                _vm.$set(state, "nama_organisasi", $$v);
              },
              expression: "state.nama_organisasi"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Jabatan",
              "label-for": "jabatanOrganisasi"
            }
          }, [_c('b-form-input', {
            staticStyle: {
              "height": "35px"
            },
            attrs: {
              "id": "jabatanOrganisasi",
              "name": "jabatanOrganisasi",
              "required": ""
            },
            model: {
              value: state.posisi,
              callback: function callback($$v) {
                _vm.$set(state, "posisi", $$v);
              },
              expression: "state.posisi"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Tanggal Bergabung",
              "label-for": "tanggal_bergabung"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "tanggal_bergabung",
              "required": ""
            },
            model: {
              value: state.durasi_awal,
              callback: function callback($$v) {
                _vm.$set(state, "durasi_awal", $$v);
              },
              expression: "state.durasi_awal"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1), _c('b-form-group', {
            staticClass: "col-md-6",
            attrs: {
              "label": "Tanggal Selesai",
              "label-for": "tanggal_selesai"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "date",
              "id": "tanggal_selesai",
              "required": ""
            },
            model: {
              value: state.durasi_akhir,
              callback: function callback($$v) {
                _vm.$set(state, "durasi_akhir", $$v);
              },
              expression: "state.durasi_akhir"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show ")])], 1)], 1)], 1), _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('b-row', [_c('b-form-group', {
            staticClass: "col-md-12",
            attrs: {
              "label": "Instansi",
              "label-for": "instansi"
            }
          }, [_c('b-form-input', {
            staticStyle: {
              "height": "35px"
            },
            attrs: {
              "id": "instansi",
              "name": "instansi",
              "required": ""
            },
            model: {
              value: state.tempat,
              callback: function callback($$v) {
                _vm.$set(state, "tempat", $$v);
              },
              expression: "state.tempat"
            }
          })], 1), _c('b-form-group', {
            staticClass: "col-md-12",
            attrs: {
              "label": "Deskripsi",
              "label-for": "deskripsi"
            }
          }, [_c('b-form-input', {
            staticStyle: {
              "height": "35px"
            },
            attrs: {
              "id": "deskripsi",
              "name": "deskripsi",
              "required": ""
            },
            model: {
              value: state.deskripsi,
              callback: function callback($$v) {
                _vm.$set(state, "deskripsi", $$v);
              },
              expression: "state.deskripsi"
            }
          })], 1)], 1)], 1), _vm.user.organisasi.length > 1 ? _c('div', {
            staticClass: "d-flex w-100 px-2 my-1 border-bottom"
          }) : _vm._e()], 1);
        }), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.addOrganisasiForm
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM ")])], 1)], 1)], 2), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("SUPPORTING DOCUMENT")]), _c('b-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Nama Dokumen",
            "label-for": "nama_dokumen"
          }
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "nama_dokumen"
          }
        })])], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "File",
            "label-for": "file"
          }
        }, [_c('div', {
          staticClass: "custom-file"
        }, [_c('input', {
          staticClass: "custom-file-input",
          attrs: {
            "type": "file",
            "id": "validatedCustomFile",
            "required": ""
          }
        }), _c('label', {
          staticClass: "custom-file-label",
          attrs: {
            "for": "validatedCustomFile"
          }
        }, [_vm._v("Browse file...")])])])], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM")])], 1)], 1)], 1), _c('b-button', {
          staticClass: "mt-3",
          attrs: {
            "disabled": _vm.user._saving,
            "block": "",
            "variant": "primary",
            "type": "submit"
          }
        }, [_vm.user._saving ? _c('b-spinner', {
          attrs: {
            "variant": "secondary",
            "label": "Spinning"
          }
        }) : _c('span', [_vm._v("Submit Data")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }