var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "alert info"
  }, [_vm._v("Loading Data....")]) : _c('form', {
    attrs: {
      "action": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.handleSavePriceFleet.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v(_vm._s(_vm.id ? 'Edit' : 'Add') + " Sum Insured - Insurance Period")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          staticClass: "iq-bg-danger mr-3",
          attrs: {
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push('/mi/price-fleet');
            }
          }
        }, [_vm._v("Cancel")]), _c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary",
            "disabled": _vm.form.saving
          }
        }, [_vm._v(_vm._s(_vm.form.saving ? 'Menyimpan..' : 'Save'))])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Insurance Periode",
            "label-for": "serial"
          }
        }, [_c('date-picker', {
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "type": "date",
            "value-type": "YYYY-MM-DD",
            "range": "",
            "placeholder": "Select date range"
          },
          on: {
            "change": _vm.onChangeDate
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "HM Percentage",
            "label-for": "serial"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "sm",
            "append": "%"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "required": "",
            "min": "1",
            "value": "75"
          },
          model: {
            value: _vm.form.hm,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "hm", $$v);
            },
            expression: "form.hm"
          }
        })], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "IV Percentage",
            "label-for": "serial"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "sm",
            "append": "%"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "required": "",
            "min": "1",
            "value": "25"
          },
          model: {
            value: _vm.form.iv,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "iv", $$v);
            },
            expression: "form.iv"
          }
        })], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_c('b-card', {
          staticClass: "mb-5",
          attrs: {
            "no-body": ""
          }
        }, [_c('b-card-header', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.kurs",
            modifiers: {
              "kurs": true
            }
          }],
          staticClass: "p-1",
          attrs: {
            "variant": "danger",
            "header-tag": "header",
            "role": "tab"
          }
        }, [_c('h4', {
          staticClass: "p-3",
          staticStyle: {
            "cursor": "pointer"
          }
        }, [_vm._v(" Pilih Data Kurs ")])]), _c('b-collapse', {
          attrs: {
            "id": "kurs",
            "visible": true,
            "role": "tabpanel"
          }
        }, [_c('b-card-body', [_c('b-card-text', [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("#")]), _vm._l(_vm.currencies, function (currencyMaster) {
          return currencyMaster.alias !== 'IDR' ? _c('th', {
            key: currencyMaster.id,
            attrs: {
              "scope": "col"
            }
          }, [_vm._v(_vm._s(currencyMaster.alias))]) : _vm._e();
        }), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Update Kurs")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Action")])], 2)]), _c('tbody', [_vm._l(_vm.currenciesMapping, function (currency, index) {
          return _c('tr', {
            key: index,
            on: {
              "click": function click($event) {
                _vm.form.currency_mapping_id = currency.id;
              }
            }
          }, [_c('td', {
            staticClass: "text-center"
          }, [_c('b-form-radio', {
            attrs: {
              "name": "some-radios",
              "value": currency.id
            },
            model: {
              value: _vm.form.currency_mapping_id,
              callback: function callback($$v) {
                _vm.$set(_vm.form, "currency_mapping_id", $$v);
              },
              expression: "form.currency_mapping_id"
            }
          })], 1), _vm._l(_vm.currencies, function (currencyMaster) {
            return currencyMaster.alias !== 'IDR' ? _c('td', {
              key: currencyMaster.id
            }, [_c('h5', [_vm._v("IDR " + _vm._s(currency.childs.find(function (child) {
              return child.currency_id === currencyMaster.id;
            }) ? currency.childs.find(function (child) {
              return child.currency_id === currencyMaster.id;
            }).value : '-') + ",-")])]) : _vm._e();
          }), _c('td', [_vm._v(" " + _vm._s(currency.date) + " ")]), _c('td')], 2);
        }), _c('tr', [_c('td', {
          staticClass: "text-center"
        }), _vm._l(_vm.formCurrency.contents, function (child) {
          return _c('td', {
            attrs: {
              "scope": "col"
            }
          }, [_c('b-input-group', {
            attrs: {
              "size": "md",
              "prepend": child.currency
            }
          }, [_c('b-form-input', {
            staticStyle: {
              "max-width": "50%"
            },
            attrs: {
              "type": "number"
            },
            model: {
              value: child.value,
              callback: function callback($$v) {
                _vm.$set(child, "value", $$v);
              },
              expression: "child.value"
            }
          })], 1)], 1);
        }), _c('td', [_c('date-picker', {
          attrs: {
            "type": "date",
            "value-type": "YYYY-MM-DD",
            "placeholder": "Select date"
          },
          model: {
            value: _vm.formCurrency.date,
            callback: function callback($$v) {
              _vm.$set(_vm.formCurrency, "date", $$v);
            },
            expression: "formCurrency.date"
          }
        })], 1), _c('td', [_c('b-button', {
          attrs: {
            "disabled": _vm.formCurrency.date === '' || _vm.formCurrency.contents.filter(function (content) {
              return content.value === '';
            }).length > 0,
            "variant": "primary"
          },
          on: {
            "click": _vm.handleSaveCurrencyMapping
          }
        }, [_c('i', {
          staticClass: "fa fa-save"
        }), _vm._v(" Submit ")])], 1)], 2)], 2)])])])], 1)], 1)], 1)], 1)]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-bordered table-striped shadow"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Currency "), _c('small', [_vm._v("!= IDR")])]), _c('th', [_vm._v("Input Kurs to IDR")])])]), _c('tbody', [_vm._l(_vm.currenciesMapping.filter(function (currency) {
          return currency.id === _vm.form.currency_mapping_id;
        }), function (currency, index) {
          return _vm._l(currency.childs, function (type, indexType) {
            return _c('tr', {
              key: indexType
            }, [_c('td', [_c('strong', [_vm._v(_vm._s(type.currency.name))])]), _c('td', [_c('h5', [_c('strong', [_vm._v("IDR " + _vm._s(type.value) + "-")])])])]);
          });
        })], 2)])])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_vm._l(_vm.companies, function (company, index) {
          return [_c('b-card', {
            key: "comp-".concat(index),
            staticClass: "mb-1",
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-header', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: 'company' + company.id,
              expression: "'company' + company.id"
            }],
            staticClass: "p-1",
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('h4', {
            staticClass: "p-3",
            staticStyle: {
              "cursor": "pointer",
              "font-weight": "bold"
            }
          }, [_vm._v(" " + _vm._s(company.company) + " ")])]), _c('b-collapse', {
            attrs: {
              "id": 'company' + company.id,
              "visible": index === 0,
              "role": "tabpanel"
            }
          }, [_c('b-card-body', [_vm._l(company.types, function (category, index2) {
            return [_c('b-card', {
              key: "mt2-".concat(index2),
              staticClass: "mb-1",
              attrs: {
                "no-body": ""
              }
            }, [_c('b-card-header', {
              directives: [{
                name: "b-toggle",
                rawName: "v-b-toggle",
                value: "category-".concat(index2, "-company-").concat(index),
                expression: "`category-${index2}-company-${index}`"
              }],
              staticClass: "p-1",
              attrs: {
                "header-tag": "header",
                "role": "tab"
              }
            }, [_c('h4', {
              staticClass: "p-3",
              staticStyle: {
                "cursor": "pointer"
              }
            }, [_vm._v(" " + _vm._s(category.name) + " ")])]), _c('b-collapse', {
              attrs: {
                "id": "category-".concat(index2, "-company-").concat(index),
                "role": "tabpanel"
              },
              on: {
                "shown": function shown($event) {
                  return _vm.handleGetFleetByCompanyAndType(company.id, category.id);
                }
              }
            }, [_c('b-card-body', [_c('b-card-text', [_c('div', {
              staticClass: "mb-4"
            }, [_c('b-row', [_c('b-col', {
              attrs: {
                "md": "4"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Option Type Last Value",
                "label-for": "serial"
              }
            }, [_c('b-form-radio-group', {
              attrs: {
                "options": _vm.optionsLastValue,
                "button-variant": "outline-primary",
                "size": "md",
                "name": "radio-btn-outline",
                "buttons": ""
              },
              on: {
                "change": function change($event) {
                  return _vm.handleGetLastValue(company.id, category.id);
                }
              },
              model: {
                value: category.initial_value,
                callback: function callback($$v) {
                  _vm.$set(category, "initial_value", $$v);
                },
                expression: "category.initial_value"
              }
            })], 1)], 1)], 1)], 1), _c('div', {
              staticClass: "table-responsive tableFixHead"
            }, [_c('table', {
              staticClass: "table mb-0 table-striped table-hover",
              staticStyle: {
                "width": "2500px",
                "display": "block",
                "height": "700px",
                "overflow-y": "scroll"
              }
            }, [_c('thead', {
              staticClass: "text-center thead-dark"
            }, [_c('tr', [_c('th', {
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "rowspan": "2",
                "width": "15px"
              }
            }, [_vm._v("No")]), _c('th', {
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "rowspan": "2",
                "width": "250px"
              }
            }, [_vm._v("Fleet")]), _c('th', {
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "rowspan": "2",
                "width": "200px"
              }
            }, [_vm._v("Currency")]), _c('th', {
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "rowspan": "2",
                "width": "250px"
              }
            }, [_vm._v("Last Value")]), _c('th', {
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "rowspan": "2",
                "width": "180px"
              }
            }, [_vm._v("Reduce Value")]), _c('th', {
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "colspan": "4"
              }
            }, [_vm._v("Sum Insured")]), _c('th', {
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "colspan": "3"
              }
            }, [_vm._v("Rate %")]), _c('th', {
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "rowspan": "2",
                "width": "250px"
              }
            }, [_vm._v("Deductible")]), _c('th', {
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "rowspan": "2",
                "width": "250px"
              }
            }, [_vm._v("Insurance Period")]), _c('th', {
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "rowspan": "2",
                "width": "180px"
              }
            }, [_vm._v("Action")])]), _c('tr', [_c('td', [_vm._v("TSI/Value(New)")]), _c('td', {
              attrs: {
                "width": "100px"
              }
            }, [_vm._v("HM ")]), _c('td', {
              attrs: {
                "width": "100px"
              }
            }, [_vm._v("IV ")]), _c('td', {
              attrs: {
                "width": "100px"
              }
            }, [_vm._v("WAR")]), _c('td', {
              attrs: {
                "width": "150px"
              }
            }, [_vm._v("Rate HM ")]), _c('td', {
              attrs: {
                "width": "150px"
              }
            }, [_vm._v("Rate IV ")]), _c('td', {
              attrs: {
                "width": "150px"
              }
            }, [_vm._v("WAR")])])]), _c('tbody', _vm._l(_vm.handleFilterContents(company.id, category.id), function (content, indexContent) {
              return _c('tr', {
                key: indexContent
              }, [_c('td', [_vm._v(_vm._s(indexContent + 1) + ".")]), _c('td', [_c('v-select', {
                attrs: {
                  "label": "name",
                  "options": content.fleets,
                  "reduce": function reduce(type) {
                    return type.id;
                  },
                  "placeholder": "select Fleet"
                },
                on: {
                  "input": function input($event) {
                    return _vm.onFilteredFleets(company.id, category.id, indexContent);
                  }
                },
                model: {
                  value: content.vehicle_id,
                  callback: function callback($$v) {
                    _vm.$set(content, "vehicle_id", $$v);
                  },
                  expression: "content.vehicle_id"
                }
              })], 1), _c('td', [_c('v-select', {
                attrs: {
                  "label": "alias",
                  "options": _vm.currencies,
                  "reduce": function reduce(type) {
                    return type.id;
                  }
                },
                model: {
                  value: content.currency_id,
                  callback: function callback($$v) {
                    _vm.$set(content, "currency_id", $$v);
                  },
                  expression: "content.currency_id"
                }
              })], 1), _c('td', [_c('b-input-group', {
                attrs: {
                  "size": "md",
                  "prepend": "Value"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "required": "",
                  "type": "number",
                  "step": ".01",
                  "min": "1"
                },
                model: {
                  value: content.value,
                  callback: function callback($$v) {
                    _vm.$set(content, "value", $$v);
                  },
                  expression: "content.value"
                }
              })], 1), _c('div', {
                staticClass: "text-right"
              }, [_c('small', [_vm._v(_vm._s(_vm.numberFormat(content.value)))])])], 1), _c('td', [_c('b-input-group', {
                attrs: {
                  "size": "md",
                  "append": "%"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "max-width": "40%"
                },
                attrs: {
                  "required": "",
                  "type": "number",
                  "step": ".01"
                },
                model: {
                  value: content.reduce,
                  callback: function callback($$v) {
                    _vm.$set(content, "reduce", $$v);
                  },
                  expression: "content.reduce"
                }
              })], 1)], 1), _c('td', [content.vehicle_type_id === 3 ? [_vm._v(" " + _vm._s(_vm.numberFormat(content.war = content.tsi = content.reduce == 0 ? Number(content.value) : content.value > 0 && content.reduce > 0 ? Number(content.value) - Number(content.value) * Number(content.reduce) / 100 : 0)) + " ")] : [_vm._v(" " + _vm._s(_vm.numberFormat(content.tsi = content.reduce == 0 ? Number(content.value) : content.value > 0 && content.reduce > 0 ? Number(content.value) - Number(content.value) * Number(content.reduce) / 100 : 0)) + " ")]], 2), _c('td', [_vm._v(_vm._s(_vm.numberFormat(content.hm = content.tsi > 0 && _vm.form.hm > 0 ? Number(content.tsi) * Number(_vm.form.hm) / 100 : 0)))]), _c('td', [_vm._v(_vm._s(_vm.numberFormat(content.iv = content.tsi > 0 && _vm.form.iv > 0 ? Number(content.tsi) * Number(_vm.form.iv) / 100 : 0)))]), _c('td', [_c('b-form-input', {
                staticStyle: {
                  "max-width": "70%"
                },
                attrs: {
                  "required": "",
                  "type": "number",
                  "step": ".001"
                },
                model: {
                  value: content.war,
                  callback: function callback($$v) {
                    _vm.$set(content, "war", $$v);
                  },
                  expression: "content.war"
                }
              }), _c('p', [_vm._v(_vm._s(_vm.numberFormat(content.war)))])], 1), _c('td', [_c('b-input-group', {
                attrs: {
                  "size": "md",
                  "append": "%"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "max-width": "70%"
                },
                attrs: {
                  "required": "",
                  "type": "number",
                  "step": ".0001"
                },
                model: {
                  value: content.rate_hm,
                  callback: function callback($$v) {
                    _vm.$set(content, "rate_hm", $$v);
                  },
                  expression: "content.rate_hm"
                }
              })], 1)], 1), _c('td', [_c('b-input-group', {
                attrs: {
                  "size": "md",
                  "append": "%"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "max-width": "70%"
                },
                attrs: {
                  "required": "",
                  "type": "number",
                  "step": ".0001"
                },
                model: {
                  value: content.rate_iv,
                  callback: function callback($$v) {
                    _vm.$set(content, "rate_iv", $$v);
                  },
                  expression: "content.rate_iv"
                }
              })], 1)], 1), _c('td', [_c('b-input-group', {
                attrs: {
                  "size": "md",
                  "append": "%"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "max-width": "70%"
                },
                attrs: {
                  "required": "",
                  "type": "number",
                  "step": ".0001"
                },
                model: {
                  value: content.rate_war,
                  callback: function callback($$v) {
                    _vm.$set(content, "rate_war", $$v);
                  },
                  expression: "content.rate_war"
                }
              })], 1)], 1), _c('td', [_c('div', [_c('b-form-group', {
                attrs: {
                  "label": "Deductible Type",
                  "label-for": "fleet-equipment"
                }
              }, [_c('b-form-radio-group', {
                model: {
                  value: content.deductible_type,
                  callback: function callback($$v) {
                    _vm.$set(content, "deductible_type", $$v);
                  },
                  expression: "content.deductible_type"
                }
              }, [_c('b-form-radio', {
                staticClass: "custom-radio-color-checked",
                attrs: {
                  "inline": "",
                  "name": "color",
                  "value": "percentage"
                }
              }, [_vm._v("Percentage")]), _c('b-form-radio', {
                staticClass: "custom-radio-color-checked",
                attrs: {
                  "inline": "",
                  "name": "color",
                  "value": "float"
                }
              }, [_vm._v("By Value")])], 1)], 1)], 1), _c('div', [content.deductible_type === 'percentage' ? _c('b-form-group', {
                attrs: {
                  "label": "Deductible Percentage:",
                  "label-for": "serial"
                }
              }, [_c('b-input-group', {
                attrs: {
                  "size": "md",
                  "append": "%"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "max-width": "40%"
                },
                attrs: {
                  "type": "number",
                  "step": ".0001"
                },
                model: {
                  value: content.deductible_value,
                  callback: function callback($$v) {
                    _vm.$set(content, "deductible_value", $$v);
                  },
                  expression: "content.deductible_value"
                }
              })], 1)], 1) : _vm._e(), content.deductible_type === 'float' ? _c('b-form-group', {
                attrs: {
                  "label": "Input Value:",
                  "label-for": "serial"
                }
              }, [_c('b-input-group', {
                attrs: {
                  "size": "md",
                  "prepend": "value"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "max-width": "40%"
                },
                attrs: {
                  "type": "number",
                  "step": ".0001"
                },
                model: {
                  value: content.deductible_value,
                  callback: function callback($$v) {
                    _vm.$set(content, "deductible_value", $$v);
                  },
                  expression: "content.deductible_value"
                }
              })], 1)], 1) : _vm._e()], 1), _c('br'), content.deductible_type === 'percentage' ? _c('span', [_vm._v(" " + _vm._s(_vm.numberFormat(content.suggest_rate_hm = content.deductible_value === 0 ? Number(content.hm) : Number(content.deductible_value) > 0 ? Number(content.deductible_value) / 100 * Number(content.hm) : 0)) + " "), _c('p', {
                staticClass: "d-none"
              }, [_vm._v(_vm._s(content.deductible_final_value = Number(content.deductible_value) > 0 ? Number(content.deductible_value) / 100 * Number(content.hm) : 0))])]) : _vm._e(), content.deductible_type === 'float' ? _c('span', {
                staticClass: "d-none"
              }, [_vm._v(" " + _vm._s(_vm.numberFormat(content.suggest_rate_hm = Number(0))) + " " + _vm._s(_vm.numberFormat(content.deductible_final_value = Number(content.deductible_value))) + " ")]) : _vm._e()]), _c('td', [_c('date-picker', {
                staticStyle: {
                  "width": "100%"
                },
                attrs: {
                  "value": [content.from, content.to],
                  "type": "date",
                  "value-type": "YYYY-MM-DD",
                  "range": "",
                  "placeholder": "Select date range"
                },
                on: {
                  "change": function change($event) {
                    return _vm.onChangeContentDate(company.id, category.id, indexContent, $event);
                  }
                }
              })], 1), _c('td', [content.pricefleet_id ? _c('b-button', {
                attrs: {
                  "variant": "danger",
                  "size": "sm"
                },
                on: {
                  "click": function click($event) {
                    return _vm.handleDeletePriceFleet(company.id, category.id, indexContent);
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-trash"
              })]) : _c('b-button', {
                attrs: {
                  "variant": "danger",
                  "size": "sm"
                },
                on: {
                  "click": function click($event) {
                    return _vm.onDeleteContent(company.id, category.id, indexContent);
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-trash"
              })])], 1)]);
            }), 0)])]), _c('div', [_c('b-button', {
              staticClass: "mb-3 mr-1",
              attrs: {
                "variant": "warning",
                "block": ""
              },
              on: {
                "click": function click($event) {
                  return _vm.onAddContent(company.id, category.id);
                }
              }
            }, [_c('i', {
              staticClass: "ri-add-line"
            }), _vm._v(" Add More Data")])], 1)])], 1)], 1)], 1)];
          })], 2)], 1)], 1)];
        })], 2)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }