var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: "tab-pane fade ".concat(_vm.active ? 'show active' : ''),
    attrs: {
      "id": _vm.id,
      "role": _vm.role,
      "aria-labelledby": _vm.ariaLabelledBy
    }
  }, [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }