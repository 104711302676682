<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:body>
            <form @submit.prevent="onSave()">
              <b-row>
                <b-col cols="6">
                  <img :src="image" alt="logo" class="img-fluid w-20">
                </b-col>
                <b-col cols="6" align-self="center">
                  <!--                <h4 class="mb-0 float-right text-primary"><b>{{ title }}</b></h4>-->
                </b-col>
                <b-col cols="12"><hr/></b-col>
                <b-col cols="5" class="mt-4">
                  <b-form-group label="Form Survey Name:" label-for="serial">
                    <b-form-input id="serial" v-model="form.survey_name" type="text" required placeholder="Nomor Register"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Description:" label-for="serial">
                    <b-form-textarea id="exampleFormControlTextarea1" required v-model="form.description"></b-form-textarea>
                  </b-form-group>
                  <!--                <b-form-group label="For Company" label-for="category_for">-->
                  <!--                  <v-multiselect v-model="companySelected" placeholder="For Company" label="text" track-by="value" :options="companies" :multiple="true">-->
                  <!--                    <span slot="noResult">Company not found.</span>-->
                  <!--                  </v-multiselect>-->
                  <!--                </b-form-group>-->
                  <b-form-group label="Status" label-for="sub_sub_project">
                    <b-form-checkbox name="check-button" v-model="form.active" required switch inline>
                      Active
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group label="Number of Label:" label-for="serial">
                    <b-form-input id="serial" v-model="form.number_of_label" required @change="generateListLabels" type="number"></b-form-input>
                  </b-form-group>
                  <p><h4>LABEL</h4><hr/></p>
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th width="10%">#</th>
                      <th width="50%">Label Name</th>
                      <th width="40%">Type</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(label, index) in form.labels">
                      <td>{{index+1}}</td>
                      <td>
                        <b-form-input v-model="label.name" id="serial" type="text" placeholder="Input your label name..."></b-form-input>
                      </td>
                      <td>
                        <v-select label="text" :options="typeOptions" :reduce="(type) => type.value" v-model="label.type"></v-select>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </b-col>
                <b-col cols="7">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th width="5%">#</th>
                      <th width="10%" v-for="(label, index) in form.labels">{{label.name}}</th>
                      <th width="5%">Act</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(content, contentLabel) in form.labelContents">
                      <td>{{contentLabel+1}}</td>
                      <td v-for="(label, labelIndex) in form.labels">
                        <b-form-textarea v-if="label.type === 'text_label'" required v-model="content[labelIndex]" placeholder="Input your text..."></b-form-textarea>
                        <v-select v-else-if="label.type === 'user_input'" required label="text" :options="userInputOptions" :reduce="(type) => type.value" v-model="content[labelIndex]"></v-select>
                      </td>
                      <td>
                        <b-button @click="removeLabelContent(contentLabel)" variant="danger" size="sm"><i class="fa fa-trash"></i></b-button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <p>
                    <b-button @click="addLabelContent" variant="success" block size="md" class="mr-2">ADD MORE <i class="fa fa-plus"></i></b-button>
                  </p>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="text-right mt-4">
                  <b-button type="submit" :disabled="form._saving" variant="primary">{{form._saving ? "Saving..." : 'Submit'}} Submit</b-button>
                  <b-button @click="$router.go(-1)" :disabled="form._saving" variant="none" class="iq-bg-danger ml-3">Cancel</b-button>
                </b-col>
              </b-row>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { companiesActions, miActions } from '@src/Utils/helper'

export default {
  name: 'BlankPage',
  mounted () {
    xray.index()
    this.generateListLabels()
    this.getMasterInputSurveyList()
  },
  data () {
    return {
      image: require('../../assets/images/logo.png'),
      companies: [],
      typeOptions: [
        { value: 'text_label', text: 'Text Label' },
        { value: 'user_input', text: 'User Input' }
      ],
      userInputOptions: [
        // { value: 'numberMax10', text: 'No. Max 10' },
      ],

      form: {
        _saving: false,
        survey_name: '',
        alias: '',
        number_of_label: 1,
        active: 1,
        description: '',
        labels: [
          // {
          //   name: 'test',
          //   type: 'text_label'
          // }
        ],
        labelContents: []
      }
    }
  },
  methods: {
    ...companiesActions,
    ...miActions,
    async getCompanyListData () {
      const res = await this.getCompanies()

      if (res.status === 'success' && res.data && res.data.length > 0) {
        res.data.forEach(element => {
          this.companies.push({
            // text: `${element.company} - ${element.location}`,
            text: `${element.company}`,
            value: element.id
          })
        })
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async getMasterInputSurveyList () {
      const res = await this.getMasterInputSurvey()

      if (res.status === 'success' && res.data && res.data.length > 0) {
        res.data.forEach(element => {
          this.userInputOptions.push({
            // text: `${element.company} - ${element.location}`,
            text: `${element.name}`,
            value: element.id
          })
        })
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    generateListLabels (events) {
      const self = this
      function generate () {
        self.form.labels = []

        for (let i = 1; i <= self.form.number_of_label; i++) {
          self.addLabel()
        }
      }
      if (typeof events !== 'undefined' && this.form.labelContents.length > 0) {
        return this.$swal({
          title: `Daa Label Konten Sudah Ada, yakin melanjutkan ?`,
          text: `Data Konten label akan terhapus jika anda mengganti Jumlah Label`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: `Lanjutkan`
        }).then(async (result) => {
          if (result.isConfirmed) {
            generate()
          }
        })
      }

      generate()
    },
    addLabel () {
      this.form.labels.push(
        {
          name: '',
          type: ''
        }
      )
    },
    removeLabel (index) {
      this.form.labels.splice(index, 1)
    },
    addLabelContent () {
      const objectToPush = {}

      this.form.labels.forEach((label, index) => {
        objectToPush[index] = ''
      })

      this.form.labelContents.push(
        objectToPush
      )

      console.log('this.form.labelContents', this.form.labelContents)
    },
    removeLabelContent (index) {
      this.form.labelContents.splice(index, 1)
    },
    generateAlias () {
      return this.form.survey_name.replaceAll(' ', '-').toLowerCase()
    },
    async onSave () {
      let payload = {
        survey_name: this.form.survey_name,
        alias: this.generateAlias(),
        number_of_label: this.form.number_of_label,
        active: this.form.active,
        description: this.form.description,
        labels: []
      }

      this.form.labels.forEach((label, labelIndex) => {
        const contents = []

        this.form.labelContents.forEach((content, contentIndex) => {
          contents.push({
            [label.type === 'user_input' ? 'input' : 'text']: content[labelIndex]
          })
        })

        payload.labels.push({
          ...label,
          contents
        })
      })

      this.form._saving = true

      const { data, status } = await this.saveMasterSurvey(payload)

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        this.form._saving = false
        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        this.form._saving = false
        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      setTimeout(() => {
        this.$router.push('/mi/master-survey-costbreakdown')
      }, 1500)

      this.form._saving = false
    }
  }
}
</script>
