import axios from './auth'

const CERTIFICATE_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/certificates`;

export const state = {
    modal_detail: {
        show: false,
        certificate: {}
    },
    modal_log: {
        show: false,
        certificate: {}
    },
    modal_endorsement: {
        show: false,
        certificate: {}
    },
    modal_renewal: {
        show: false,
        certificate: {}
    },
}

export const getters = {}

export const mutations = {
    SET_MODAL_DETAIL(state, value) {
        state.modal_detail = value
    },
    SET_MODAL_LOG(state, value) {
        state.modal_log = value
    },
    SET_MODAL_ENDORSEMENT(state, value) {
        state.modal_endorsement = value
    },
    SET_MODAL_RENEWAL(state, value) {
        state.modal_renewal = value
    },
}

export const actions = {
    async getCertificates({ commit }, params = {}) {
        const url = CERTIFICATE_BASE_URL;

        const method = "get";
        const headers = {};
        const config = {
            url,
            method,
            headers,
            params
        };

        try {
            const response = await axios(config);
            let { data, ...pagination } = response.data;
            return {
                status: 'success',
                data,
                pagination
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async getCertificateVehicles({ commit }, params = {}) {
        const url = `${CERTIFICATE_BASE_URL}/vehicle`;

        const method = "get";
        const headers = {};
        const config = {
            url,
            method,
            headers,
            params
        };

        try {
            const response = await axios(config);
            let { data, ...pagination } = response.data;
            return {
                status: 'success',
                data,
                pagination
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async getCertificateVehicleLogs({ commit }, payload) {
        const url = `${CERTIFICATE_BASE_URL}/vehicle/${payload.id}/logs`;

        const method = "get";
        const headers = {};
        const config = {
            url,
            method,
            headers,
            params: payload.params
        };

        try {
            const response = await axios(config);
            let data = response.data.data;
            return {
                status: 'success',
                data,
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async getEndorsementDates({ commit }, payload) {
        const url = `${CERTIFICATE_BASE_URL}/vehicle/endorsement/${payload.type}`;

        const method = "get";
        const headers = {};
        const config = {
            url,
            method,
            headers,
            params: payload.params
        };

        try {
            const response = await axios(config);
            let data = response.data.data;
            return {
                status: 'success',
                data,
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async getCertificateById({ commit }, payload) {
        const url = `${CERTIFICATE_BASE_URL}/${payload}`;

        const method = "get";
        const headers = {};
        const config = {
            url,
            method,
            headers
        };

        try {
            const response = await axios(config);
            let data = response.data.data;
            return {
                status: 'success',
                data,
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async getCertificateVehicleById({ commit }, payload) {
        const url = `${CERTIFICATE_BASE_URL}/vehicle/${payload}`;

        const method = "get";
        const headers = {};
        const config = {
            url,
            method,
            headers
        };

        try {
            const response = await axios(config);
            let data = response.data.data;
            return {
                status: 'success',
                data,
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async getCertificateCategories({ commit }, params = {}) {
        const url = `${CERTIFICATE_BASE_URL}/category`;

        const method = "get";
        const headers = {};
        const config = {
            url,
            method,
            headers,
            params
        };

        try {
            const response = await axios(config);
            let data = response.data.data;
            return {
                status: 'success',
                data,
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async addCertificate({ commit }, payload) {
        const url = CERTIFICATE_BASE_URL;

        const method = "post";
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        const config = {
            url,
            method,
            data: payload,
            headers
        };

        try {
        const response = await axios(config);
        let data = response.data.data;
        return {
            status: 'success',
            data,
        };
        } catch (e) {
        return {
            status: 'error',
            data: e.response.data
        };
        }
    },
    async processRenewalCertificateVehicle({ commit }, payload) {
        const url = `${CERTIFICATE_BASE_URL}/vehicle/${payload.id}/renewal/process`;

        const method = "post";
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        const config = {
            url,
            method,
            data: payload.data,
            headers
        };

        try {
        const response = await axios(config);
        let data = response.data.data;
        return {
            status: 'success',
            data,
        };
        } catch (e) {
        return {
            status: 'error',
            data: e.response.data
        };
        }
    },
    async finishRenewalCertificateVehicle({ commit }, payload) {
        const url = `${CERTIFICATE_BASE_URL}/vehicle/${payload.id}/renewal/finish`;

        const method = "post";
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        const config = {
            url,
            method,
            data: payload.data,
            headers
        };

        try {
        const response = await axios(config);
        let data = response.data.data;
        return {
            status: 'success',
            data,
        };
        } catch (e) {
        return {
            status: 'error',
            data: e.response.data
        };
        }
    },
    async endorsementCertificateVehicle({ commit }, payload) {
        const url = `${CERTIFICATE_BASE_URL}/vehicle/${payload.id}/endorsement/${payload.id_endorsement}`;

        const method = "post";
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        const config = {
            url,
            method,
            data: payload.data,
            headers
        };

        try {
        const response = await axios(config);
        let data = response.data.data;
        return {
            status: 'success',
            data,
        };
        } catch (e) {
        return {
            status: 'error',
            data: e.response.data
        };
        }
    },
    async addCertificateVehicle({ commit }, payload) {
        const url = `${CERTIFICATE_BASE_URL}/vehicle`;

        const method = "post";
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        const config = {
            url,
            method,
            data: payload,
            headers
        };

        try {
        const response = await axios(config);
        let data = response.data.data;
        return {
            status: 'success',
            data,
        };
        } catch (e) {
        return {
            status: 'error',
            data: e.response.data
        };
        }
    },
    async addCertificateCategory({ commit }, payload) {
        const url = `${CERTIFICATE_BASE_URL}/category`;

        const method = "post";
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        const config = {
            url,
            method,
            data: payload,
            headers
        };

        try {
        const response = await axios(config);
        let data = response.data.data;
        return {
            status: 'success',
            data,
        };
        } catch (e) {
        return {
            status: 'error',
            data: e.response.data
        };
        }
    },
    async updateCertificateCategory({ commit }, payload) {
        const url = `${CERTIFICATE_BASE_URL}/category/${payload.id}`;

        const method = "put";
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        const config = {
            url,
            method,
            data: payload.data,
            headers
        };

        try {
        const response = await axios(config);
        let data = response.data.data;
        return {
            status: 'success',
            data,
        };
        } catch (e) {
        return {
            status: 'error',
            data: e.response.data
        };
        }
    },
    async updateCertificate({ commit }, payload) {
        const url = `${CERTIFICATE_BASE_URL}/${payload.id}`;

        const method = "put";
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        const config = {
            url,
            method,
            data: payload.data,
            headers
        };

        try {
        const response = await axios(config);
        let data = response.data.data;
        return {
            status: 'success',
            data,
        };
        } catch (e) {
        return {
            status: 'error',
            data: e.response.data
        };
        }
    },
    async updateCertificateVehicle({ commit }, payload) {
        const url = `${CERTIFICATE_BASE_URL}/vehicle/${payload.id}`;

        const method = "put";
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        const config = {
            url,
            method,
            data: payload.data,
            headers
        };

        try {
        const response = await axios(config);
        let data = response.data.data;
        return {
            status: 'success',
            data,
        };
        } catch (e) {
        return {
            status: 'error',
            data: e.response.data
        };
        }
    },
    async deleteCertificate({ commit }, payload) {
        const url = `${CERTIFICATE_BASE_URL}/${payload}`;

        const method = "delete";
        const headers = {};
        const config = {
            url,
            method,
            headers
        };

        try {
            await axios(config);
            return { status: 'success' };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async deleteCertificateVehicle({ commit }, payload) {
        const url = `${CERTIFICATE_BASE_URL}/vehicle/${payload}`;

        const method = "delete";
        const headers = {};
        const config = {
            url,
            method,
            headers
        };

        try {
            await axios(config);
            return { status: 'success' };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async exportCertificates({ commit }, params = {}) {
        const url = `${CERTIFICATE_BASE_URL}/export`;

        const method = "get";
        const headers = {};
        const config = {
            url,
            method,
            headers,
            params,
            responseType: 'blob'
        };

        try {
            const response = await axios(config);
            let data = response.data;
            return {
                status: 'success',
                data,
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async exportCertificateVehicles({ commit }, params = {}) {
        const url = `${CERTIFICATE_BASE_URL}/vehicle/export`;

        const method = "get";
        const headers = {};
        const config = {
            url,
            method,
            headers,
            params,
            responseType: 'blob'
        };

        try {
            const response = await axios(config);
            let data = response.data;
            return {
                status: 'success',
                data,
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
}
