<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-6 p-0" :active="true" href="#pureRiskComponent" title="Pure Risk Component (Year)"/>
                <tab-nav-items class="col-md-6 p-0" :active="false" href="#pureRiskDetail" title="Pure Risk Detail"/>
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <tab-content-item :active="true" id="pureRiskComponent">
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                ><strong>MASTER PURE RISK COMPONENT (YEAR)</strong></h4
                >
              </template>
              <template v-slot:headerAction>
                <b-button variant="primary" @click="doExportProjects">
                  <template v-if="!loading_export_project">
                    <i class="fa fa-file-excel"></i> &nbsp; Export
                  </template>
                  <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Exporting...
                  </template>
                </b-button>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col cols="12" md="3">
                    <b-form-group label="Search :" label-for="kind-fleet">
                      <b-form-input id="fleet_name" class="w-100" type="text" placeholder="Search..."></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="2">
                    <b-form-group label="Year :" label-for="kind-fleet">
                      <v-select label="text" :options="yearOptions" :reduce="(type) => type.value" v-model="year"></v-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="2">
                    <b-form-group label="Search" label-for="button_search">
                      <b-button
                        variant="primary"
                        class="ml-2"
                      ><i class="fa fa-search"></i
                      ></b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="8">
            <iq-card>
              <template v-slot:body>
                <b-table-simple>
                  <thead>
                  <tr>
                    <th class="text-center" width="5%">NO</th>
                    <th width="25%">Year</th>
                    <th width="15%">Pure Risk Cost</th>
                    <th width="25%">Details %</th>
                    <th width="10%">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in pureRisks.data">
                    <td>{{index+1}}</td>
                    <td>{{data.year}}</td>
                    <td>{{ data.pure_risk_cost }}%</td>
                    <td>
                      <ol class="m-0 pl-3">
                        <li v-for="item in data.items">{{ item.item.name }}: {{ item.value }}%</li>
                      </ol>
                    </td>
                    <td>
                      <b-button @click="onEditPureRisk(data.id)" variant="success" size="sm" class="mr-2"><i class="fa fa-edit"></i></b-button>
                      <b-button @click="onDeletePureRisk(data.id)" variant="danger" size="sm"><i class="fa fa-trash"></i></b-button>
                    </td>
                  </tr>
                  </tbody>
                </b-table-simple>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="4">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{pureRiskForm.id ? "Edit" : "Add"}} Pure Risk Component (Year)</h4>
              </template>
              <template v-slot:body>
                <form @submit.prevent="onSavePureRisk">
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Year of Budget:" label-for="serial">
                        <v-select label="text" :options="yearOptions" :reduce="(type) => type.value" v-model="pureRiskForm.year"></v-select>
                      </b-form-group>
                      <p class="text-center"><h4>PERCENTAGE SHARING</h4><hr/></p>
                      <table class="table">
                        <thead>
                        <tr>
                          <th colspan="2"><h5>#DETAILS</h5></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in pureRiskForm.items">
                          <td width="40%">{{ item.name }}</td>
                          <td width="60%">
                            <b-input-group size="md" append="%">
                              <b-form-input v-model="item.value" type="number" min="1"></b-form-input>
                            </b-input-group>
                          </td>
                        </tr>
                        <tr>
                          <td width="40%">SUM</td>
                          <td width="60%">
                            <h5 :class="{'text-danger' : isSumPureRiskItemsMoreThan100}"><strong>{{sumPureRiskItems}}%</strong></h5>
                            <small class="text-danger" v-if="isSumPureRiskItemsMoreThan100"><strong>Jangan Melebihi 100%</strong></small>
                          </td>
                        </tr>
                        <tr>
                          <td width="40%">PURE RISK COST</td>
                          <td width="60%">
                            <small class="text-danger" v-if="isSumPureRiskItemsMoreThan100"><strong>Jangan Melebihi 100%</strong></small>
                            <h5 v-else><strong>{{pureRiskItemsCost}}%</strong></h5>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <b-col class="text-right">
                      <b-button type="submit" variant="primary">Save</b-button>
                      <b-button @click="resetFormPureRisk()" variant="none" class="iq-bg-danger ml-3">Cancel</b-button>
                    </b-col>
                  </b-row>
                </form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
      <tab-content-item :active="false" id="pureRiskDetail">
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                ><strong>MASTER PURE RISK DETAIL</strong></h4
                >
              </template>
              <template v-slot:headerAction>
                <b-button variant="primary" @click="doExportProjects">
                  <template v-if="!loading_export_project">
                    <i class="fa fa-file-excel"></i> &nbsp; Export
                  </template>
                  <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Exporting...
                  </template>
                </b-button>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-col cols="12" md="3">
                    <b-form-group
                      label="Search Master Data Pure Risk:"
                      label-for="kind-fleet"
                    >
                      <b-form-input
                        id="fleet_name"
                        class="w-100"
                        type="text"
                        placeholder="Search..."
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="2">
                    <b-form-group label="Search" label-for="button_search">
                      <b-button
                        variant="primary"
                        class="ml-2"
                      ><i class="fa fa-search"></i
                      ></b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
          <b-col md="8">
            <iq-card>
              <template v-slot:body>
                <b-table-simple>
                  <thead>
                  <tr width="100%">
                    <th class="text-center" width="5%">NO</th>
                    <th width="65%">DESCRIPTION / ITEM</th>
                    <th width="20%">Status</th>
                    <th width="10%">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in pureRiskItems.data">
                    <td>{{index+1}}</td>
                    <td>{{data.name}}</td>
                    <td>
                      <b-badge v-if="data.active" variant="success">Active</b-badge>
                      <b-badge v-else variant="danger">Inactive</b-badge>
                    </td>
                    <td>
                      <b-button variant="success" size="sm" @click="onEditPureRiskItem(data.id)" class="mr-2"><i class="fa fa-edit"></i></b-button>
                      <b-button variant="danger" size="sm" @click="onDeletePureRiskItem(data.id)"><i class="fa fa-trash"></i></b-button>
                    </td>
                  </tr>
                  </tbody>
                </b-table-simple>
              </template>
            </iq-card>
          </b-col>
          <b-col md="4">
            <iq-card class="border border-light shadow-lg">
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ pureRiskItemForm.id ? "Edit" : "Add" }} Pure Risk Detail</h4>
              </template>
              <template v-slot:body>
                <form @submit.prevent="onSavePureRiskItems">
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Details:" label-for="serial">
                        <b-form-textarea v-model="pureRiskItemForm.name" required></b-form-textarea>
                      </b-form-group>
                      <b-form-group label="Status" label-for="sub_sub_project">
                        <b-form-checkbox v-model="pureRiskItemForm.active" :value="1" :unchecked-value="0" name="check-button" required switch inline>
                          Active
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-button type="submit" variant="primary">Save</b-button>
                      <b-button @click="resetFormPureRiskItems()" variant="none" class="iq-bg-danger ml-3">Cancel</b-button>
                    </b-col>
                  </b-row>
                </form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
    </div>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import { companiesActions, fleetsActions, miActions } from '@src/Utils/helper'
import moment from 'moment'

export default {
  name: 'BlankPage',
  async mounted () {
    xray.index()
    this.generateYear()

    this.fetchPureRisks()
    await this.fetchPureRiskItems()

    this.resetFormPureRisk()
  },
  computed: {
    isSumPureRiskItemsMoreThan100 () {
      return this.sumPureRiskItems > 100
    },
    sumPureRiskItems () {
      let ret = 0

      this.pureRiskForm.items.forEach(item => {
        if (item.value) {
          ret += parseInt(item.value)
        }
      })

      return ret
    },
    pureRiskItemsCost () {
      return 100 - this.sumPureRiskItems
    }
  },
  data () {
    return {
      image: require('@assets/images/logo.png'),
      title: 'Add Survey',
      yearOptions: [
        // { value: '2020', text: '2020' },
      ],

      pureRisks: {
        isLoading: false,
        data: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        },
        params: {
          page: 1,
        }
      },
      pureRiskForm: {
        'saving': false,
        'id': '',
        'year': '',
        'sum': '',
        'pure_risk_cost': '',
        'items': [
          // {
          //   'id': 1,
          //   'value': 123124
          // }
        ]
      },
      pureRiskItems: {
        isLoading: false,
        data: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        },
        params: {
          page: 1
          // with: 'vehicle,type_coverage,type_loss,company,documents,company_claimant,status,creator,histories'
        }
      },
      pureRiskItemForm: {
        'id': '',
        'name': '',
        'active': 0
      }
    }
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    ...miActions,
    async fetchPureRiskItems (page = 1) {
      this.pureRiskItems.data = []
      this.pureRiskItems.isLoading = true
      this.pureRiskItems.params.page = page
      const { data, status } = await this.getMasterPureRiskItem(this.pureRiskItems.params)

      if (data) {
        this.pureRiskItems.data = data
      }

      this.pureRiskItems.isLoading = false
    },
    async onSavePureRiskItems (e) {
      e.preventDefault()

      let funct = 'saveMasterPureRiskItem'

      if (this.pureRiskItemForm.id) {
        funct = 'updateMasterPureRiskItem'
      }

      const { data, status } = await this[funct](this.pureRiskItemForm)

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )
      this.resetFormPureRiskItems()
      this.fetchPureRiskItems(1)
    },
    async onDeletePureRiskItem (id) {
      this.$swal({
        title: 'Hapus Data Pure Risk Item ? ?',
        text: `Tidak dapat diurungkan`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let payload = {
            id
          }
          const res = await this.deleteMasterPureRiskItem(payload)

          if (res.status === 'success') {
            this.$swal(
              `Delete Success`,
              '',
              'success'
            )
            await this.fetchPureRiskItems(1)
          } else {
            this.$swal(
              `Oops!`,
              res.data.message,
              'error'
            )
          }
        }
      })
    },
    async onEditPureRiskItem (id) {
      this.resetFormPureRiskItems()
      const { data, status } = await this.getMasterPureRiskItemDetail({ id })
      this.pureRiskItemForm.id = id
      this.pureRiskItemForm.name = data.name
      this.pureRiskItemForm.active = data.active
    },
    resetFormPureRiskItems () {
      this.pureRiskItemForm.id = ''
      this.pureRiskItemForm.name = ''
      this.pureRiskItemForm.active = 0
    },

    async fetchPureRisks (page = 1) {
      this.pureRisks.data = []
      this.pureRisks.isLoading = true
      this.pureRisks.params.page = page
      const { data, status } = await this.getMasterPureRisk(this.pureRisks.params)

      if (data) {
        this.pureRisks.data = data
      }

      this.pureRisks.isLoading = false
    },
    async onSavePureRisk (e) {
      e.preventDefault()

      let funct = 'saveMasterPureRisk'

      if (this.pureRiskForm.id) {
        funct = 'updateMasterPureRisk'
      }

      this.pureRiskForm.sum = this.sumPureRiskItems
      this.pureRiskForm.pure_risk_cost = this.pureRiskItemsCost

      const { data, status } = await this[funct](this.pureRiskForm)

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )
      this.resetFormPureRisk()
      this.fetchPureRisks(1)
    },
    async onDeletePureRisk (id) {
      this.$swal({
        title: 'Hapus Data Pure Risk ? ?',
        text: `Tidak dapat diurungkan`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let payload = {
            id
          }
          const res = await this.deleteMasterPureRisk(payload)

          if (res.status === 'success') {
            this.$swal(
              `Delete Success`,
              '',
              'success'
            )
            await this.fetchPureRisks(1)
          } else {
            this.$swal(
              `Oops!`,
              res.data.message,
              'error'
            )
          }
        }
      })
    },
    async onEditPureRisk (id) {
      this.resetFormPureRisk()
      const { data, status } = await this.getMasterPureRiskDetail({ id })
      this.pureRiskForm.saving = false
      this.pureRiskForm.id = data.id
      this.pureRiskForm.pure_risk_cost = data.pure_risk_cost
      this.pureRiskForm.sum = data.sum
      this.pureRiskForm.year = data.year
      this.pureRiskForm.items = this.pureRiskItems.data.map(item => {
        return {
          id: item.id,
          name: item.name,
          value: data.items.find(t => t.master_pure_risk_item_id === item.id) ? data.items.find(t => t.master_pure_risk_item_id === item.id).value : 0
        }
      })
    },
    generateYear () {
      this.yearOptions = []
      for (let i = moment().year(); i >= moment().subtract(15, 'years').year(); i--) {
        this.yearOptions.push({
          value: i, text: i
        })
      }
    },
    resetFormPureRisk () {
      this.pureRiskForm.saving = false
      this.pureRiskForm.id = ''
      this.pureRiskForm.pure_risk_cost = ''
      this.pureRiskForm.sum = ''
      this.pureRiskForm.year = ''
      this.pureRiskForm.items = this.pureRiskItems.data.map(item => (
        {
          id: item.id,
          name: item.name,
          value: ''
        }
      ))
    }
  }
}
</script>
