var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-email-list"
        }, [_c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.compose-email-popup",
            modifiers: {
              "compose-email-popup": true
            }
          }],
          staticClass: "font-size-16 p-3 mb-3",
          attrs: {
            "variant": "primary",
            "size": "lg",
            "block": ""
          }
        }, [_c('i', {
          staticClass: "ri-send-plane-line mr-2"
        }), _vm._v("New Message")]), _c('tab-nav', {
          staticClass: "iq-email-ui",
          attrs: {
            "pills": true,
            "vertical": "",
            "id": "myTab"
          },
          on: {
            "change": _vm.updateTab
          }
        }, [_c('tab-nav-items', {
          attrs: {
            "li-class": "nav-link p-0",
            "active": true,
            "id": "mail-inbox-tab",
            "ariaControls": "mail-inbox",
            "role": "tab",
            "ariaSelected": true
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function fn() {
              return [_c('i', {
                staticClass: "ri-mail-line"
              }), _vm._v("Inbox"), _c('span', {
                staticClass: "badge badge-primary ml-2"
              }, [_vm._v("4")])];
            },
            proxy: true
          }])
        }), _c('tab-nav-items', {
          attrs: {
            "li-class": "nav-link p-0",
            "active": false,
            "id": "mail-starred-tab",
            "ariaControls": "mail-starred",
            "role": "tab",
            "ariaSelected": true
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function fn() {
              return [_c('i', {
                staticClass: "ri-star-line"
              }), _vm._v("Starred ")];
            },
            proxy: true
          }])
        }), _c('tab-nav-items', {
          attrs: {
            "li-class": "nav-link p-0",
            "active": false,
            "id": "mail-snoozed-tab",
            "ariaControls": "mail-snoozed",
            "role": "tab",
            "ariaSelected": true
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function fn() {
              return [_c('i', {
                staticClass: "ri-time-line"
              }), _vm._v("Snoozed ")];
            },
            proxy: true
          }])
        }), _c('tab-nav-items', {
          attrs: {
            "li-class": "nav-link p-0",
            "active": false,
            "id": "mail-draft-tab",
            "ariaControls": "mail-draft",
            "role": "tab",
            "ariaSelected": true
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function fn() {
              return [_c('i', {
                staticClass: "ri-file-list-2-line"
              }), _vm._v("Draft ")];
            },
            proxy: true
          }])
        }), _c('tab-nav-items', {
          attrs: {
            "li-class": "nav-link p-0",
            "active": false,
            "id": "mail-sent-tab",
            "ariaControls": "mail-sent",
            "role": "tab",
            "ariaSelected": true
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function fn() {
              return [_c('i', {
                staticClass: "ri-mail-send-line"
              }), _vm._v("Sent Mail ")];
            },
            proxy: true
          }])
        }), _c('tab-nav-items', {
          attrs: {
            "li-class": "nav-link p-0",
            "active": false,
            "id": "mail-trash-tab",
            "ariaControls": "mail-trash",
            "role": "tab",
            "ariaSelected": true
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function fn() {
              return [_c('i', {
                staticClass: "ri-delete-bin-line"
              }), _vm._v("Trash ")];
            },
            proxy: true
          }])
        }), _c('tab-nav-items', {
          attrs: {
            "li-class": "nav-link p-0",
            "active": false,
            "id": "mail-important-tab",
            "ariaControls": "mail-important",
            "role": "tab",
            "ariaSelected": true
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function fn() {
              return [_c('i', {
                staticClass: "ri-bookmark-line"
              }), _vm._v("Important ")];
            },
            proxy: true
          }])
        }), _c('tab-nav-items', {
          attrs: {
            "li-class": "nav-link p-0",
            "active": false,
            "id": "mail-spam-tab",
            "ariaControls": "mail-spam",
            "role": "tab",
            "ariaSelected": true
          },
          scopedSlots: _vm._u([{
            key: "title",
            fn: function fn() {
              return [_c('i', {
                staticClass: "ri-spam-line"
              }), _vm._v("Spam ")];
            },
            proxy: true
          }])
        })], 1), _c('h6', {
          staticClass: "mt-4 mb-3"
        }, [_vm._v("Labels")]), _c('ul', {
          staticClass: "iq-email-ui iq-email-label"
        }, [_c('li', [_c('a', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-focus-fill text-primary"
        }), _vm._v("Personal")])]), _c('li', [_c('a', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-focus-fill text-danger"
        }), _vm._v("Company")])]), _c('li', [_c('a', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-focus-fill text-success"
        }), _vm._v("Important")])]), _c('li', [_c('a', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-focus-fill text-info"
        }), _vm._v("Private")])]), _c('li', [_c('a', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-focus-fill text-warning"
        }), _vm._v("Private")])]), _c('li', [_c('a', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-add-circle-line"
        }), _vm._v("Add New Labels")])])]), _c('div', {
          staticClass: "iq-progress-bar-linear d-inline-block w-100"
        }, [_c('h6', {
          staticClass: "mt-4 mb-3"
        }, [_vm._v("Storage")]), _c('div', {
          staticClass: "iq-progress-bar-linear d-inline-block w-100"
        }, [_c('b-progress', {
          staticClass: "iq-progress-bar",
          attrs: {
            "value": 95,
            "variant": "danger"
          }
        })], 1), _c('span', {
          staticClass: "mt-1 d-inline-block w-100"
        }, [_vm._v("13.02 GB (95%) of 15 GB used")])])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "9"
    }
  }, [_c('iq-card', [_c('div', {
    staticClass: "iq-email-to-list p-3"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('ul', [_c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    staticClass: "custom-control-input",
    attrs: {
      "type": "checkbox",
      "id": "customCheck1"
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "customCheck1"
    }
  }, [_c('i', {
    staticClass: "ri-arrow-down-s-line"
  })])])])]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "title": "Reload"
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-restart-line"
  })])]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "title": "Archive"
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-mail-open-line"
  })])]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "title": "Spam"
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-information-line"
  })])]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "title": "Delete"
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-delete-bin-line"
  })])]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "title": "Inbox"
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-mail-unread-line"
  })])]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "title": "Zoom"
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-drag-move-2-line"
  })])])]), _c('div', {
    staticClass: "iq-email-search d-flex"
  }, [_c('form', {
    staticClass: "mr-3 position-relative"
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "email",
      "id": "exampleInputEmail1",
      "aria-describedby": "emailHelp",
      "placeholder": "Search"
    }
  }), _c('a', {
    staticClass: "search-link",
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-search-line"
  })])])]), _c('ul', [_c('li', {
    staticClass: "mr-3"
  }, [_c('a', {
    staticClass: "font-size-14",
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_vm._v(" 1 - 50 of 505 ")])]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "title": "Previous"
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-arrow-left-s-line"
  })])]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    attrs: {
      "title": "Next"
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-arrow-right-s-line"
  })])]), _c('li', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      modifiers: {
        "top": true
      }
    }],
    staticClass: "mr-0",
    attrs: {
      "title": "Setting"
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-list-settings-line"
  })])])])])])]), _c('div', {
    staticClass: "iq-email-listbox"
  }, [_c('tab-content', [_c('EmailDetail', {
    attrs: {
      "active": _vm.active,
      "item": _vm.item
    },
    on: {
      "update": _vm.closeDetail
    }
  }), _c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "mail-inbox",
      "aria-labelled-by": "mail-inbox-tab",
      "role": "tabpanel"
    }
  }, [_c('ul', {
    staticClass: "iq-email-sender-list"
  }, _vm._l(_vm.inboxItems, function (items, index) {
    return _c('li', {
      key: index,
      class: items.is_unread ? 'iq-unread' : ''
    }, [_c('EmailItem', {
      attrs: {
        "items": items
      },
      on: {
        "update": _vm.openDetail
      }
    })], 1);
  }), 0)]), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "mail-starred",
      "aria-labelled-by": "mail-starred-tab",
      "role": "tabpanel"
    }
  }, [_c('ul', {
    staticClass: "iq-email-sender-list"
  }, _vm._l(_vm.starredItems, function (items, index) {
    return _c('li', {
      key: index,
      class: items.is_unread ? 'iq-unread' : ''
    }, [_c('EmailItem', {
      attrs: {
        "items": items
      },
      on: {
        "update": _vm.openDetail
      }
    })], 1);
  }), 0)]), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "mail-snoozed",
      "aria-labelled-by": "mail-snoozed-tab",
      "role": "tabpanel"
    }
  }, [_c('ul', {
    staticClass: "iq-email-sender-list"
  }, _vm._l(_vm.starredItems, function (items, index) {
    return _c('li', {
      key: index,
      class: items.is_unread ? 'iq-unread' : ''
    }, [_c('EmailItem', {
      attrs: {
        "items": items
      },
      on: {
        "update": _vm.openDetail
      }
    })], 1);
  }), 0)]), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "mail-draft",
      "aria-labelled-by": "mail-draft-tab",
      "role": "tabpanel"
    }
  }, [_c('ul', {
    staticClass: "iq-email-sender-list"
  }, _vm._l(_vm.draftItems, function (items, index) {
    return _c('li', {
      key: index,
      class: items.is_unread ? 'iq-unread' : ''
    }, [_c('EmailItem', {
      attrs: {
        "items": items
      },
      on: {
        "update": _vm.openDetail
      }
    })], 1);
  }), 0)]), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "mail-sent",
      "aria-labelled-by": "mail-sent-tab",
      "role": "tabpanel"
    }
  }, [_c('ul', {
    staticClass: "iq-email-sender-list"
  }, _vm._l(_vm.sendItems, function (items, index) {
    return _c('li', {
      key: index,
      class: items.is_unread ? 'iq-unread' : ''
    }, [_c('EmailItem', {
      attrs: {
        "items": items
      },
      on: {
        "update": _vm.openDetail
      }
    })], 1);
  }), 0)]), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "mail-trash",
      "aria-labelled-by": "mail-trash-tab",
      "role": "tabpanel"
    }
  }, [_c('ul', {
    staticClass: "iq-email-sender-list"
  }, _vm._l(_vm.deleteItems, function (items, index) {
    return _c('li', {
      key: index,
      class: items.is_unread ? 'iq-unread' : ''
    }, [_c('EmailItem', {
      attrs: {
        "items": items
      },
      on: {
        "update": _vm.openDetail
      }
    })], 1);
  }), 0)]), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "mail-important",
      "aria-labelled-by": "mail-important-tab",
      "role": "tabpanel"
    }
  }, [_c('ul', {
    staticClass: "iq-email-sender-list"
  }, _vm._l(_vm.starredItems, function (items, index) {
    return _c('li', {
      key: index,
      class: items.is_unread ? 'iq-unread' : ''
    }, [_c('EmailItem', {
      attrs: {
        "items": items
      },
      on: {
        "update": _vm.openDetail
      }
    })], 1);
  }), 0)]), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "mail-spam",
      "aria-labelled-by": "mail-spam-tab",
      "role": "tabpanel"
    }
  }, [_c('ul', {
    staticClass: "iq-email-sender-list"
  }, _vm._l(_vm.spamItems, function (items, index) {
    return _c('li', {
      key: index,
      class: items.is_unread ? 'iq-unread' : ''
    }, [_c('EmailItem', {
      attrs: {
        "items": items
      },
      on: {
        "update": _vm.openDetail
      }
    })], 1);
  }), 0)])], 1)], 1)])], 1), _c('b-modal', {
    staticClass: "compose-popup modal-sticky-bottom-right modal-sticky-lg",
    attrs: {
      "id": "compose-email-popup",
      "size": "lg",
      "hide-footer": "",
      "hide-header": "",
      "content-class": "border-0",
      "dialog-class": "m-0",
      "static": "",
      "body-class": "p-0",
      "cancel-title": "Close"
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('ComposeBody', {
    staticClass: "mb-0",
    attrs: {
      "modal": true
    },
    on: {
      "close": _vm.closeModal
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }