var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_vm.incident ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('StepNavigation', {
    attrs: {
      "current-step": "RECOMMENDATION",
      "claim-type": _vm.incident.type_form === 'non_claim' ? 'NON_CLAIM' : 'CLAIM'
    }
  })], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSave();
            }
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('img', {
          staticClass: "img-fluid w-20",
          attrs: {
            "src": _vm.image,
            "alt": "logo"
          }
        })]), _c('b-col', {
          attrs: {
            "cols": "6",
            "align-self": "center"
          }
        }), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('hr')]), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_vm.$route.query.formId ? _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "mt-5",
          attrs: {
            "label": "Link to Case"
          }
        }, [_c('v-select', {
          attrs: {
            "placeholder": "Link To Case",
            "options": _vm.linkToCaseOptions,
            "disabled": "",
            "label": "text",
            "reduce": function reduce(type) {
              return type.value;
            },
            "clearable": false
          },
          model: {
            value: _vm.linkToCase,
            callback: function callback($$v) {
              _vm.linkToCase = $$v;
            },
            expression: "linkToCase"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "mt-5",
          attrs: {
            "label": "Notif to Department"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "placeholder": "Notif",
            "label": "name",
            "track-by": "id",
            "options": _vm.notifOptions,
            "multiple": true
          },
          model: {
            value: _vm.department_ids,
            callback: function callback($$v) {
              _vm.department_ids = $$v;
            },
            expression: "department_ids"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Data not found.")])])], 1)], 1)], 1) : _vm._e(), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No.")]), _c('th', [_vm._v("Permasalahan")]), _c('th', [_vm._v("Vessel Case")]), _c('th', [_vm._v("Root Cause")]), _c('th', [_vm._v("Rekomendasi")]), _c('th', [_vm._v("Cause of Loss")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Closed Date")]), _c('th', [_vm._v("#")])])]), _vm._l(_vm.forms, function (form, idx) {
          return _c('tbody', {
            key: idx
          }, [!_vm.$route.query.formId ? _c('tr', [_c('td', {
            attrs: {
              "colspan": "4"
            }
          }, [_c('b-form-group', {
            staticClass: "mt-5",
            attrs: {
              "label": "Link to Case"
            }
          }, [_c('v-select', {
            attrs: {
              "placeholder": "Link To Case",
              "options": _vm.linkToCaseOptions,
              "label": "text",
              "reduce": function reduce(type) {
                return type.value;
              },
              "clearable": false
            },
            model: {
              value: form.form_id,
              callback: function callback($$v) {
                _vm.$set(form, "form_id", $$v);
              },
              expression: "form.form_id"
            }
          })], 1)], 1), _c('td', {
            attrs: {
              "colspan": "5"
            }
          }, [_c('b-form-group', {
            staticClass: "mt-5",
            attrs: {
              "label": "Notif to Department"
            }
          }, [_c('v-multiselect', {
            attrs: {
              "placeholder": "Notif",
              "label": "name",
              "track-by": "id",
              "options": _vm.notifOptions,
              "multiple": true
            },
            model: {
              value: form.department_ids,
              callback: function callback($$v) {
                _vm.$set(form, "department_ids", $$v);
              },
              expression: "form.department_ids"
            }
          }, [_c('span', {
            attrs: {
              "slot": "noResult"
            },
            slot: "noResult"
          }, [_vm._v("Data not found.")])])], 1)], 1)]) : _vm._e(), _c('tr', [_c('td', [_vm._v(_vm._s(idx + 1) + ".")]), _c('td', [_c('b-form-textarea', {
            attrs: {
              "id": "exampleFormControlTextarea1",
              "rows": "3"
            },
            model: {
              value: form.problem,
              callback: function callback($$v) {
                _vm.$set(form, "problem", $$v);
              },
              expression: "form.problem"
            }
          })], 1), _c('td', [_c('b-form-textarea', {
            attrs: {
              "id": "exampleFormControlTextarea1",
              "rows": "3"
            },
            model: {
              value: form.vessel_case,
              callback: function callback($$v) {
                _vm.$set(form, "vessel_case", $$v);
              },
              expression: "form.vessel_case"
            }
          })], 1), _c('td', [_c('b-form-textarea', {
            attrs: {
              "id": "exampleFormControlTextarea1",
              "rows": "3"
            },
            model: {
              value: form.root_cause,
              callback: function callback($$v) {
                _vm.$set(form, "root_cause", $$v);
              },
              expression: "form.root_cause"
            }
          })], 1), _c('td', [_c('b-form-textarea', {
            attrs: {
              "id": "exampleFormControlTextarea1",
              "rows": "3"
            },
            model: {
              value: form.recommendation,
              callback: function callback($$v) {
                _vm.$set(form, "recommendation", $$v);
              },
              expression: "form.recommendation"
            }
          })], 1), _c('td', [_c('v-multiselect', {
            attrs: {
              "placeholder": "Cause of Loss",
              "label": "name",
              "track-by": "id",
              "options": _vm.causeOfLossOptions
            },
            model: {
              value: form.cause_of_loss_id,
              callback: function callback($$v) {
                _vm.$set(form, "cause_of_loss_id", $$v);
              },
              expression: "form.cause_of_loss_id"
            }
          }, [_c('span', {
            attrs: {
              "slot": "noResult"
            },
            slot: "noResult"
          }, [_vm._v("Data not found.")])])], 1), _c('td', [_c('v-multiselect', {
            attrs: {
              "placeholder": "Status",
              "label": "name",
              "track-by": "id",
              "options": _vm.statusOptions
            },
            model: {
              value: form.status,
              callback: function callback($$v) {
                _vm.$set(form, "status", $$v);
              },
              expression: "form.status"
            }
          }, [_c('span', {
            attrs: {
              "slot": "noResult"
            },
            slot: "noResult"
          }, [_vm._v("Data not found.")])])], 1), _c('td', [_c('date-picker', {
            attrs: {
              "name": "closedDate",
              "type": "date",
              "value-type": "YYYY-MM-DD",
              "format": "DD MMM YYYY",
              "placeholder": "Select date"
            },
            model: {
              value: form.closed_at,
              callback: function callback($$v) {
                _vm.$set(form, "closed_at", $$v);
              },
              expression: "form.closed_at"
            }
          })], 1), _c('td', [!_vm.$route.params.id ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.deleteForm(idx);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e()], 1)])]);
        })], 2), !_vm.$route.params.id ? _c('b-button', {
          staticClass: "mt-2 mb-3",
          attrs: {
            "variant": "warning",
            "block": ""
          },
          on: {
            "click": _vm.addForm
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add More Data")]) : _vm._e()], 1)])], 1), _c('b-row', [_c('b-col', {
          staticClass: "text-right mt-4",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "disabled": _vm.saving,
            "variant": "primary"
          }
        }, [_vm._v(" Submit")]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "disabled": _vm.saving,
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.go(-1);
            }
          }
        }, [_vm._v("Cancel ")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }