<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
        <h4 class="card-title text-primary"><strong>MAINTENANCE - TB ETI 101</strong></h4>
        </template>
        <template v-slot:body>
            <div class="accordion" role="tablist">
            <b-card no-body >
                <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.deck_equipment variant="primary" class="text-left"><i class="fa fa-chevron-circle-down"></i> DECK EQUIPMENT & OUTFITTING</b-button>
                </b-card-header>

                <b-collapse id="deck_equipment" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="accordion" role="tablist">
                    <b-card no-body >
                        <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.accomodation variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> ACCOMMODATION</b-button>
                        </b-card-header>
                        <b-collapse id="accomodation" accordion="my-accordion-sub" role="tabpanel">
                            <table class="table table-striped">
                                <thead>
                                    <tr>                                    
                                        <th width="15%" class="text-center">Equipment</th>
                                        <th width="10%" class="text-center">Job Index</th>
                                        <th width="10%" class="text-center">Inteval</th>
                                        <th width="15%" class="text-center">Last Maintenance</th>
                                        <th width="10%" class="text-center">Run Hours</th>
                                        <th width="10%" class="text-center">Due Date</th>
                                        <th width="10%" class="text-center">Status</th>
                                        <th width="20%" class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Wheel House</td>
                                        <td class="text-center">Inspection</td>
                                        <td class="text-center">1 M</td>
                                        <td class="text-center">2019-12-05</td>
                                        <td class="text-center">-</td>
                                        <td class="text-center">2020-01-05</td>
                                        <td class="text-center"><b-badge variant="danger d-block">Maintenance</b-badge></td>
                                        <td>
                                            <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-5" toggle-class="text-decoration-none" no-caret class="ml-3">
                                                <template v-slot:button-content>
                                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                                    </span>
                                                </template>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-right mr-2"></i>Current Date</b-dropdown-item>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-left mr-2"></i>Back Date</b-dropdown-item>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Engine Room</td>
                                        <td class="text-center">Inspection</td>
                                        <td class="text-center">1 M</td>
                                        <td class="text-center">2019-05-15</td>
                                        <td class="text-center">-</td>
                                        <td class="text-center">2019-06-15</td>
                                        <td class="text-center"><b-badge variant="danger d-block">Maintenance</b-badge></td>
                                        <td>
                                            <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-5" toggle-class="text-decoration-none" no-caret class="ml-3">
                                                <template v-slot:button-content>
                                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                                    </span>
                                                </template>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-right mr-2"></i>Current Date</b-dropdown-item>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-left mr-2"></i>Back Date</b-dropdown-item>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Acomodation Room</td>
                                        <td class="text-center">Inspection</td>
                                        <td class="text-center">3 M</td>
                                        <td class="text-center">2019-07-15</td>
                                        <td class="text-center">-</td>
                                        <td class="text-center">2019-10-16</td>
                                        <td class="text-center"><b-badge variant="danger d-block">Maintenance</b-badge></td>
                                        <td>
                                            <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-5" toggle-class="text-decoration-none" no-caret class="ml-3">
                                                <template v-slot:button-content>
                                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                                    </span>
                                                </template>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-right mr-2"></i>Current Date</b-dropdown-item>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-left mr-2"></i>Back Date</b-dropdown-item>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-collapse>
                    </b-card>
                    <b-card no-body>
                        <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.hull_loadline variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> HULL & LOAD LINE</b-button>
                        </b-card-header>
                        <b-collapse id="hull_loadline" accordion="my-accordion-sub" role="tabpanel">
                            <table class="table table-striped">
                                <thead>
                                    <tr>                                    
                                        <th width="15%" class="text-center">Equipment</th>
                                        <th width="10%" class="text-center">Job Index</th>
                                        <th width="10%" class="text-center">Inteval</th>
                                        <th width="15%" class="text-center">Last Maintenance</th>
                                        <th width="10%" class="text-center">Run Hours</th>
                                        <th width="10%" class="text-center">Due Date</th>
                                        <th width="10%" class="text-center">Status</th>
                                        <th width="20%" class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Hull</td>
                                        <td class="text-center">Inspection</td>
                                        <td class="text-center">1 M</td>
                                        <td class="text-center">2019-12-05</td>
                                        <td class="text-center">-</td>
                                        <td class="text-center">2020-01-05</td>
                                        <td class="text-center"><b-badge variant="danger d-block">Maintenance</b-badge></td>
                                        <td>
                                            <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-5" toggle-class="text-decoration-none" no-caret class="ml-3">
                                                <template v-slot:button-content>
                                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                                    </span>
                                                </template>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-right mr-2"></i>Current Date</b-dropdown-item>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-left mr-2"></i>Back Date</b-dropdown-item>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Load Line</td>
                                        <td class="text-center">Inspection</td>
                                        <td class="text-center">1 M</td>
                                        <td class="text-center">2019-05-15</td>
                                        <td class="text-center">-</td>
                                        <td class="text-center">2019-06-15</td>
                                        <td class="text-center"><b-badge variant="danger d-block">Maintenance</b-badge></td>
                                        <td>
                                            <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-5" toggle-class="text-decoration-none" no-caret class="ml-3">
                                                <template v-slot:button-content>
                                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                                    </span>
                                                </template>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-right mr-2"></i>Current Date</b-dropdown-item>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-left mr-2"></i>Back Date</b-dropdown-item>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-collapse>
                    </b-card>
                    </div>
                </b-card-body>
                </b-collapse>
            </b-card>

            <b-card no-body>
                <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.machinery variant="primary" class="text-left"><i class="fa fa-chevron-circle-down"></i> MACHINERY EQUIPMENT</b-button>
                </b-card-header>
                <b-collapse id="machinery" accordion="my-accordion" role="tabpanel">
                <b-card-body>

                    <div class="accordion" role="tablist">
                    <b-card no-body >
                        <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.main_engine variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> MAIN ENGINE</b-button>
                        </b-card-header>
                        <b-collapse id="main_engine" accordion="my-accordion-sub" role="tabpanel">
                            <table class="table table-striped">
                                <thead>
                                    <tr>                                    
                                        <th width="15%" class="text-center">Equipment</th>
                                        <th width="10%" class="text-center">Job Index</th>
                                        <th width="10%" class="text-center">Inteval</th>
                                        <th width="15%" class="text-center">Last Maintenance</th>
                                        <th width="10%" class="text-center">Run Hours</th>
                                        <th width="10%" class="text-center">Due Date</th>
                                        <th width="10%" class="text-center">Status</th>
                                        <th width="20%" class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Sea Water Cooling Pump No. 1 (Ebara)</td>
                                        <td class="text-center">Inspection</td>
                                        <td class="text-center">1 M</td>
                                        <td class="text-center">2019-12-05</td>
                                        <td class="text-center">-</td>
                                        <td class="text-center">2020-01-05</td>
                                        <td class="text-center"><b-badge variant="danger d-block">Maintenance</b-badge></td>
                                        <td>
                                            <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-5" toggle-class="text-decoration-none" no-caret class="ml-3">
                                                <template v-slot:button-content>
                                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                                    </span>
                                                </template>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-right mr-2"></i>Current Date</b-dropdown-item>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-left mr-2"></i>Back Date</b-dropdown-item>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Sea Water Cooling Pump No. 1 (Ebara)</td>
                                        <td class="text-center">Overhaul</td>
                                        <td class="text-center">30 M</td>
                                        <td class="text-center">2019-05-15</td>
                                        <td class="text-center">-</td>
                                        <td class="text-center">2019-06-15</td>
                                        <td class="text-center"><b-badge variant="danger d-block">Maintenance</b-badge></td>
                                        <td>
                                            <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-5" toggle-class="text-decoration-none" no-caret class="ml-3">
                                                <template v-slot:button-content>
                                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                                    </span>
                                                </template>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-right mr-2"></i>Current Date</b-dropdown-item>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-left mr-2"></i>Back Date</b-dropdown-item>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="accordion" role="tablist">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button block v-b-toggle.mitsubishi_s6r2_t2mtk3l variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i>  MITSUBISHI S6R2-T2MTK3L</b-button>
                                </b-card-header>
                                <b-collapse id="mitsubishi_s6r2_t2mtk3l" accordion="my-accordion-sub-sub" role="tabpanel">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>                                    
                                                <th width="15%" class="text-center">Equipment</th>
                                                <th width="10%" class="text-center">Job Index</th>
                                                <th width="10%" class="text-center">Inteval</th>
                                                <th width="15%" class="text-center">Last Maintenance</th>
                                                <th width="10%" class="text-center">Run Hours</th>
                                                <th width="10%" class="text-center">Due Date</th>
                                                <th width="10%" class="text-center">Status</th>
                                                <th width="20%" class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Fuel Oil System - Fuel Oil Filter | No. 1</td>
                                                <td class="text-center">Change</td>
                                                <td class="text-center">300 H</td>
                                                <td class="text-center">2018-07-04</td>
                                                <td class="text-center">280 H</td>
                                                <td class="text-center">-</td>
                                                <td class="text-center"><b-badge variant="danger d-block">Maintenance</b-badge></td>
                                                <td>
                                                    <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-5" toggle-class="text-decoration-none" no-caret class="ml-3">
                                                        <template v-slot:button-content>
                                                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                                            <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                                            </span>
                                                        </template>
                                                        <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-right mr-2"></i>Current Date</b-dropdown-item>
                                                        <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-left mr-2"></i>Back Date</b-dropdown-item>
                                                    </b-dropdown>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Lubricating Oil System - Bypass LO Filter | No. 1</td>
                                                <td class="text-center">Overhaul</td>
                                                <td class="text-center">1.000 H</td>
                                                <td class="text-center">2019-05-15</td>
                                                <td class="text-center">950 H</td>
                                                <td class="text-center">-</td>
                                                <td class="text-center"><b-badge variant="danger d-block">Maintenance</b-badge></td>
                                                <td>
                                                    <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-5" toggle-class="text-decoration-none" no-caret class="ml-3">
                                                        <template v-slot:button-content>
                                                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                                            <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                                            </span>
                                                        </template>
                                                        <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-right mr-2"></i>Current Date</b-dropdown-item>
                                                        <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-left mr-2"></i>Back Date</b-dropdown-item>
                                                    </b-dropdown>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </b-collapse>
                            </div>
                        </b-collapse>
                    </b-card>

                    <b-card no-body >
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.aux_engine variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> AUXUILARY ENGINE</b-button>
                        </b-card-header>
                        <b-collapse id="aux_engine" accordion="my-accordion-sub" role="tabpanel">
                            <table class="table table-striped">
                                <thead>
                                    <tr>                                    
                                        <th width="15%" class="text-center">Equipment</th>
                                        <th width="10%" class="text-center">Job Index</th>
                                        <th width="10%" class="text-center">Inteval</th>
                                        <th width="15%" class="text-center">Last Maintenance</th>
                                        <th width="10%" class="text-center">Run Hours</th>
                                        <th width="10%" class="text-center">Due Date</th>
                                        <th width="10%" class="text-center">Status</th>
                                        <th width="20%" class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Sea Water Cooling Pump No. 1 (Ebara)</td>
                                        <td class="text-center">Inspection</td>
                                        <td class="text-center">1 M</td>
                                        <td class="text-center">2019-12-05</td>
                                        <td class="text-center">-</td>
                                        <td class="text-center">2020-01-05</td>
                                        <td class="text-center"><b-badge variant="danger d-block">Maintenance</b-badge></td>
                                        <td>
                                            <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-5" toggle-class="text-decoration-none" no-caret class="ml-3">
                                                <template v-slot:button-content>
                                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                                    </span>
                                                </template>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-right mr-2"></i>Current Date</b-dropdown-item>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-left mr-2"></i>Back Date</b-dropdown-item>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Sea Water Cooling Pump No. 1 (Ebara)</td>
                                        <td class="text-center">Overhaul</td>
                                        <td class="text-center">30 M</td>
                                        <td class="text-center">2019-05-15</td>
                                        <td class="text-center">-</td>
                                        <td class="text-center">2019-06-15</td>
                                        <td class="text-center"><b-badge variant="danger d-block">Maintenance</b-badge></td>
                                        <td>
                                            <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-5" toggle-class="text-decoration-none" no-caret class="ml-3">
                                                <template v-slot:button-content>
                                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                                    </span>
                                                </template>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-right mr-2"></i>Current Date</b-dropdown-item>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-left mr-2"></i>Back Date</b-dropdown-item>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="accordion" role="tablist">
                                <b-card no-body >
                                    <b-card-header header-tag="header" class="p-1" role="tab">
                                        <b-button block v-b-toggle.weichai_ccfj_30j_wj variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i>  WEICHAI CCFJ-30J-WJ</b-button>
                                    </b-card-header>
                                    <b-collapse id="weichai_ccfj_30j_wj" accordion="my-accordion-sub-sub" role="tabpanel">
                                        <table class="table table-striped">
                                        <thead>
                                            <tr>                                    
                                                <th width="15%" class="text-center">Equipment</th>
                                                <th width="10%" class="text-center">Job Index</th>
                                                <th width="10%" class="text-center">Inteval</th>
                                                <th width="15%" class="text-center">Last Maintenance</th>
                                                <th width="10%" class="text-center">Run Hours</th>
                                                <th width="10%" class="text-center">Due Date</th>
                                                <th width="10%" class="text-center">Status</th>
                                                <th width="20%" class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Fuel Oil System - Fuel Oil Filter | No. 1</td>
                                                <td class="text-center">Change</td>
                                                <td class="text-center">300 H</td>
                                                <td class="text-center">2018-07-04</td>
                                                <td class="text-center">280 H</td>
                                                <td class="text-center">-</td>
                                                <td class="text-center"><b-badge variant="danger d-block">Maintenance</b-badge></td>
                                                <td>
                                                    <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-5" toggle-class="text-decoration-none" no-caret class="ml-3">
                                                        <template v-slot:button-content>
                                                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                                            <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                                            </span>
                                                        </template>
                                                        <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-right mr-2"></i>Current Date</b-dropdown-item>
                                                        <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-left mr-2"></i>Back Date</b-dropdown-item>
                                                    </b-dropdown>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Lubricating Oil System - Bypass LO Filter | No. 1</td>
                                                <td class="text-center">Overhaul</td>
                                                <td class="text-center">1.000 H</td>
                                                <td class="text-center">2019-05-15</td>
                                                <td class="text-center">950 H</td>
                                                <td class="text-center">-</td>
                                                <td class="text-center"><b-badge variant="danger d-block">Maintenance</b-badge></td>
                                                <td>
                                                    <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-5" toggle-class="text-decoration-none" no-caret class="ml-3">
                                                        <template v-slot:button-content>
                                                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                                            <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                                            </span>
                                                        </template>
                                                        <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-right mr-2"></i>Current Date</b-dropdown-item>
                                                        <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-left mr-2"></i>Back Date</b-dropdown-item>
                                                    </b-dropdown>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </b-collapse>
                            </b-card>
                            </div>
                        </b-collapse>
                    </b-card>

                    <b-card no-body>
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <b-button block v-b-toggle.gearbox variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> GEARBOX</b-button>
                        </b-card-header>
                        <b-collapse id="gearbox" accordion="my-accordion-sub" role="tabpanel">
                            <table class="table table-striped">
                                <thead>
                                    <tr>                                    
                                        <th width="15%" class="text-center">Equipment</th>
                                        <th width="10%" class="text-center">Job Index</th>
                                        <th width="10%" class="text-center">Inteval</th>
                                        <th width="15%" class="text-center">Last Maintenance</th>
                                        <th width="10%" class="text-center">Run Hours</th>
                                        <th width="10%" class="text-center">Due Date</th>
                                        <th width="10%" class="text-center">Status</th>
                                        <th width="20%" class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Sea Water Cooling Pump No. 1 (Ebara)</td>
                                        <td class="text-center">Inspection</td>
                                        <td class="text-center">1 M</td>
                                        <td class="text-center">2019-12-05</td>
                                        <td class="text-center">-</td>
                                        <td class="text-center">2020-01-05</td>
                                        <td class="text-center"><b-badge variant="danger d-block">Maintenance</b-badge></td>
                                        <td>
                                            <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-5" toggle-class="text-decoration-none" no-caret class="ml-3">
                                                <template v-slot:button-content>
                                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                                    </span>
                                                </template>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-right mr-2"></i>Current Date</b-dropdown-item>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-left mr-2"></i>Back Date</b-dropdown-item>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Sea Water Cooling Pump No. 1 (Ebara)</td>
                                        <td class="text-center">Overhaul</td>
                                        <td class="text-center">30 M</td>
                                        <td class="text-center">2019-05-15</td>
                                        <td class="text-center">-</td>
                                        <td class="text-center">2019-06-15</td>
                                        <td class="text-center"><b-badge variant="danger d-block">Maintenance</b-badge></td>
                                        <td>
                                            <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-5" toggle-class="text-decoration-none" no-caret class="ml-3">
                                                <template v-slot:button-content>
                                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                                    </span>
                                                </template>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-right mr-2"></i>Current Date</b-dropdown-item>
                                                <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-arrow-left mr-2"></i>Back Date</b-dropdown-item>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-collapse>
                    </b-card>

                    </div>

                </b-card-body>
                </b-collapse>
            </b-card>
            </div>
        </template>
    </iq-card>


  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'

export default {
  name: 'Maintenance',
  mounted () {
    xray.index()
  },
  data () {
    return {
      job_check: [
        {
          checked: false,
          disabled: false
        }
      ],
    }
  }
}
</script>
