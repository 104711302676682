var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-breadcrumb', {
    attrs: {
      "items": _vm.breadCrumbItems
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('span', [_c('h4', {
          staticClass: "card-title"
        }, [_c('i', {
          staticClass: "ri-ship-2-fill",
          staticStyle: {
            "margin-right": "10px"
          }
        }), _vm._v(" TB.ETI 301 "), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Reject This Voyage',
            expression: "'Reject This Voyage'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "mb-3 mr-1",
          staticStyle: {
            "margin": "12px 0 0 10px"
          },
          attrs: {
            "size": "sm",
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "ri-close-fill"
        }), _vm._v(" Reject")]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Accept This Voyage',
            expression: "'Accept This Voyage'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "mb-3 mr-1",
          staticStyle: {
            "margin": "12px 0 0 10px"
          },
          attrs: {
            "size": "sm",
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "ri-check-line"
        }), _vm._v(" Accept")])], 1)])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          staticClass: "mb-3 mr-1",
          staticStyle: {
            "margin": "15px 0 0 5px"
          },
          attrs: {
            "variant": "info"
          }
        }, [_c('i', {
          staticClass: "ri-save-fill"
        }), _vm._v(" Save")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('form-wizard', {
    attrs: {
      "step-size": "lg",
      "title": _vm.title,
      "subtitle": _vm.subtitle,
      "back-button-text": "Go back!",
      "next-button-text": "Save!",
      "finish-button-text": "We're there"
    },
    on: {
      "on-complete": _vm.onComplete
    }
  }, [_c('tab-content', {
    attrs: {
      "title": "History, Port Plan, Loading Port - In Pos Jetty",
      "icon": "ri-anchor-line"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Voyage History")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-dark table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Barge")]), _c('th', [_vm._v("Last Port")]), _c('th', [_vm._v("OTW / Departure Time")]), _c('th', [_vm._v("Check")]), _c('th', [_vm._v("ROB Fuel Oil")]), _c('th', [_vm._v("Remark")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-info"
        }, [_vm._v("BG ETI 202")]), _c('td', [_vm._v("Suralaya")]), _c('td', [_vm._v("15 September 2020 20:00")]), _c('td', [_vm._v("9 September 2020 13:05")]), _c('td', [_vm._v("476.264 Liter")]), _c('td', [_vm._v("-")])])])])])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Port Planing")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Current Port")]), _c('th', [_vm._v("Arrival / Drop Anchor(Bouy) (Engine Off)")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "iq-bg-primary mb-1 mr-1"
        }, [_vm._v("Sangatta")]), _c('td', [_vm._v("15 September 2020 20:00")])])])])]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Number of Voyage *",
            "label-for": "numberOfVoyage"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.numberOfVoyage,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select number of voyage")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedNumberOfVoyage,
            callback: function callback($$v) {
              _vm.selectedNumberOfVoyage = $$v;
            },
            expression: "selectedNumberOfVoyage"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Destination Port *",
            "label-for": "destination"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.destination,
            "placeholder": "Select Destination"
          },
          model: {
            value: _vm.destinationLoading,
            callback: function callback($$v) {
              _vm.destinationLoading = $$v;
            },
            expression: "destinationLoading"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Barge ? [Yes / No] *",
            "label-for": "bargeYesNo"
          }
        }, [_c('div', {
          staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
        }, [_c('div', {
          staticClass: "custom-switch-inner"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.bargeYesNo.checked,
            expression: "bargeYesNo.checked"
          }],
          staticClass: "custom-control-input",
          class: 'bg-' + _vm.bargeYesNo.class,
          attrs: {
            "type": "checkbox",
            "id": 'bargeYesNo'
          },
          domProps: {
            "checked": Array.isArray(_vm.bargeYesNo.checked) ? _vm._i(_vm.bargeYesNo.checked, null) > -1 : _vm.bargeYesNo.checked
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.bargeYesNo.checked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.bargeYesNo, "checked", $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.bargeYesNo, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.bargeYesNo, "checked", $$c);
              }
            }
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": 'bargeYesNo',
            "data-on-label": _vm.bargeYesNo.activeLabel,
            "data-off-label": _vm.bargeYesNo.inactiveLabel
          }
        })])])])], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_vm.bargeYesNo.checked == true ? [_c('b-form-group', {
          attrs: {
            "label": "Barge *",
            "label-for": "barge"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.barge,
            "placeholder": "Select Barge"
          },
          model: {
            value: _vm.bargeLoading,
            callback: function callback($$v) {
              _vm.bargeLoading = $$v;
            },
            expression: "bargeLoading"
          }
        })], 1)] : _vm._e()], 2), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Bunker ? [Yes / No] *",
            "label-for": "bunkerYesNo"
          }
        }, [_c('div', {
          staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
        }, [_c('div', {
          staticClass: "custom-switch-inner"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.bunkerYesNo.checked,
            expression: "bunkerYesNo.checked"
          }],
          staticClass: "custom-control-input",
          class: 'bg-' + _vm.bunkerYesNo.class,
          attrs: {
            "type": "checkbox",
            "id": 'bunkerYesNo'
          },
          domProps: {
            "checked": Array.isArray(_vm.bunkerYesNo.checked) ? _vm._i(_vm.bunkerYesNo.checked, null) > -1 : _vm.bunkerYesNo.checked
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.bunkerYesNo.checked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.bunkerYesNo, "checked", $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.bunkerYesNo, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.bunkerYesNo, "checked", $$c);
              }
            }
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": 'bunkerYesNo',
            "data-on-label": _vm.bunkerYesNo.activeLabel,
            "data-off-label": _vm.bunkerYesNo.inactiveLabel
          }
        })])])])], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_vm.bunkerYesNo.checked == true ? [_c('b-form-group', {
          attrs: {
            "label": "Date *",
            "label-for": "Date"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "dateBunker1",
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.bunkerDate,
            callback: function callback($$v) {
              _vm.bunkerDate = $$v;
            },
            expression: "bunkerDate"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1), _vm.visibleROB === true ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "rob-fuel-oil",
            "type": "number",
            "value": "2356"
          },
          on: {
            "blur": _vm.onBlurNumberROB
          },
          model: {
            value: _vm.amountROB,
            callback: function callback($$v) {
              _vm.amountROB = $$v;
            },
            expression: "amountROB"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Liter")])])], 1) : _vm._e(), _vm.visibleROB === false ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "rob-fuel-oil",
            "type": "text",
            "value": "2356"
          },
          on: {
            "focus": _vm.onFocusTextROB
          },
          model: {
            value: _vm.amountROB,
            callback: function callback($$v) {
              _vm.amountROB = $$v;
            },
            expression: "amountROB"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Liter")])])], 1) : _vm._e()] : _vm._e()], 2)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Activity in Loading Port")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Arrival / Drop Anchor(Bouy) (Engine Off)")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("15 September 2020 20:00")])])])])]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Anchor Up / Standby Engine *",
            "label-for": "anchorUpLoading"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.anchorUpLoading,
            callback: function callback($$v) {
              _vm.anchorUpLoading = $$v;
            },
            expression: "anchorUpLoading"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addIdleLoading();
            }
          }
        }, [_c('i', {
          staticClass: "ri-add-line"
        }), _vm._v(" Add Idle")])], 1), _vm.formNoteIdleLoading.noteIdle.length > 0 ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped table-warning"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Note Idle")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Idle")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("End Idle")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Using ME?")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.formNoteIdleLoading.noteIdle, function (idle, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_c('b-form-group', {
            attrs: {
              "label": "",
              "label-for": ""
            }
          }, [_c('v-select', {
            attrs: {
              "disabled": _vm.formNoteIdleLoading._saving,
              "label": "text",
              "options": _vm.noteIdle,
              "placeholder": "Select Note Idle"
            },
            model: {
              value: idle.note,
              callback: function callback($$v) {
                _vm.$set(idle, "note", $$v);
              },
              expression: "idle.note"
            }
          })], 1)], 1), _c('td', [_c('b-form-group', {
            attrs: {
              "label": "",
              "label-for": "anchorUpLoading"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "datetime-local",
              "value": "2019-12-19T13:45:00"
            },
            model: {
              value: idle.startIdle,
              callback: function callback($$v) {
                _vm.$set(idle, "startIdle", $$v);
              },
              expression: "idle.startIdle"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('b-form-group', {
            attrs: {
              "label": "",
              "label-for": "anchorUpLoading"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "datetime-local",
              "value": "2019-12-19T13:45:00"
            },
            model: {
              value: idle.endIdle,
              callback: function callback($$v) {
                _vm.$set(idle, "endIdle", $$v);
              },
              expression: "idle.endIdle"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('div', {
            staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
          }, [_c('div', {
            staticClass: "custom-switch-inner"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: idle.me.checked,
              expression: "idle.me.checked"
            }],
            staticClass: "custom-control-input",
            class: 'bg-' + idle.me.class,
            attrs: {
              "type": "checkbox",
              "id": 'idle.me' + index
            },
            domProps: {
              "checked": Array.isArray(idle.me.checked) ? _vm._i(idle.me.checked, null) > -1 : idle.me.checked
            },
            on: {
              "change": function change($event) {
                var $$a = idle.me.checked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(idle.me, "checked", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(idle.me, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(idle.me, "checked", $$c);
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": 'idle.me' + index,
              "data-on-label": idle.me.activeLabel,
              "data-off-label": idle.me.inactiveLabel
            }
          })])])]), _c('td', [_c('b-button', {
            staticClass: "mb-3 mr-1",
            attrs: {
              "size": "sm",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.removeIdleLoading(index);
              }
            }
          }, [_c('i', {
            staticClass: "ri-delete-bin-line"
          })])], 1)]);
        }), 0)])]) : _vm._e()], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Change Loading Port")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('small', {
          staticStyle: {
            "margin-right": "5px"
          }
        }, [_vm._v("Save first, if you want change loading port! ")]), _c('div', {
          staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
        }, [_c('div', {
          staticClass: "custom-switch-inner"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.changePort.checked,
            expression: "changePort.checked"
          }],
          staticClass: "custom-control-input",
          class: 'bg-' + _vm.changePort.class,
          attrs: {
            "type": "checkbox",
            "id": 'changePort'
          },
          domProps: {
            "checked": Array.isArray(_vm.changePort.checked) ? _vm._i(_vm.changePort.checked, null) > -1 : _vm.changePort.checked
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.changePort.checked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.changePort, "checked", $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.changePort, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.changePort, "checked", $$c);
              }
            }
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": 'changePort',
            "data-on-label": _vm.changePort.activeLabel,
            "data-off-label": _vm.changePort.inactiveLabel
          }
        })])]), _vm.changePort.checked == true ? _c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "size": "sm",
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addChangePort();
            }
          }
        }, [_c('i', {
          staticClass: "ri-add-line"
        }), _vm._v(" Add Destination")]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.changePort.checked == true ? _vm._l(_vm.formChangePort.port, function (port, index) {
          return _c('b-row', {
            key: index
          }, [_c('b-col', {
            attrs: {
              "md": "8"
            }
          }, [_c('h4', [_vm._v("Change Port #" + _vm._s(index + 1))])]), _c('b-col', {
            staticClass: "text-right",
            attrs: {
              "md": "4"
            }
          }, [_c('b-button', {
            staticClass: "mb-3 mr-1",
            attrs: {
              "size": "sm",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.removeChangePort(index);
              }
            }
          }, [_c('i', {
            staticClass: "ri-close-line"
          }), _vm._v(" Delete")])], 1), _c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('table', {
            staticClass: "table table-light table-striped"
          }, [_c('thead', [_c('tr', [_c('th', {
            attrs: {
              "width": "30%"
            }
          }, [_vm._v("Destination Port *")]), _c('th', {
            attrs: {
              "width": "20%"
            }
          }, [_vm._v("Barge *")]), _c('th', {
            attrs: {
              "width": "25%"
            }
          }, [_vm._v("Arrival / Drop Anchor(Bouy) (Engine Off) *")]), _c('th', {
            attrs: {
              "width": "25%"
            }
          }, [_vm._v("Anchor Up / Start Engine *")])])]), _c('tbody', [_c('tr', [_c('td', [_c('v-select', {
            attrs: {
              "label": "text",
              "options": _vm.destination,
              "placeholder": "Select Destination"
            },
            model: {
              value: port.destination,
              callback: function callback($$v) {
                _vm.$set(port, "destination", $$v);
              },
              expression: "port.destination"
            }
          })], 1), _c('td', [_c('div', {
            staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
          }, [_c('div', {
            staticClass: "custom-switch-inner"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: port.bargeYesNo.checked,
              expression: "port.bargeYesNo.checked"
            }],
            staticClass: "custom-control-input",
            class: 'bg-' + port.bargeYesNo.class,
            attrs: {
              "type": "checkbox",
              "id": 'port.bargeYesNo' + index
            },
            domProps: {
              "checked": Array.isArray(port.bargeYesNo.checked) ? _vm._i(port.bargeYesNo.checked, null) > -1 : port.bargeYesNo.checked
            },
            on: {
              "change": function change($event) {
                var $$a = port.bargeYesNo.checked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(port.bargeYesNo, "checked", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(port.bargeYesNo, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(port.bargeYesNo, "checked", $$c);
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": 'port.bargeYesNo' + index,
              "data-on-label": port.bargeYesNo.activeLabel,
              "data-off-label": port.bargeYesNo.inactiveLabel
            }
          })])]), port.bargeYesNo.checked == true ? [_c('v-select', {
            attrs: {
              "label": "text",
              "options": _vm.barge,
              "placeholder": "Select Barge"
            },
            model: {
              value: port.barge,
              callback: function callback($$v) {
                _vm.$set(port, "barge", $$v);
              },
              expression: "port.barge"
            }
          })] : _vm._e()], 2), _c('td', [_c('b-form-group', {
            attrs: {
              "label": "",
              "label-for": "anchorUpLoading"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "datetime-local",
              "value": "2019-12-19T13:45:00"
            },
            model: {
              value: port.dropAnchor,
              callback: function callback($$v) {
                _vm.$set(port, "dropAnchor", $$v);
              },
              expression: "port.dropAnchor"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('b-form-group', {
            attrs: {
              "label": "",
              "label-for": "anchorUpLoading"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "datetime-local",
              "value": "2019-12-19T13:45:00"
            },
            model: {
              value: port.dropAnchor,
              callback: function callback($$v) {
                _vm.$set(port, "dropAnchor", $$v);
              },
              expression: "port.dropAnchor"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show")])], 1)], 1)])])])]), port.idle.length > 0 ? _c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('table', {
            staticClass: "table table-striped table-warning"
          }, [_c('thead', [_c('tr', [_c('th', {
            attrs: {
              "width": "35%"
            }
          }, [_vm._v("Note Idle")]), _c('th', {
            attrs: {
              "width": "20%"
            }
          }, [_vm._v("Start Idle")]), _c('th', {
            attrs: {
              "width": "20%"
            }
          }, [_vm._v("End Idle")]), _c('th', {
            attrs: {
              "width": "15%"
            }
          }, [_vm._v("Using ME?")]), _c('th', {
            attrs: {
              "width": "10%"
            }
          }, [_vm._v("#")])])]), _c('tbody', _vm._l(port.idle, function (idle, indexIdle) {
            return _c('tr', {
              key: indexIdle
            }, [_c('td', [_c('b-form-group', {
              attrs: {
                "label": "",
                "label-for": ""
              }
            }, [_c('v-select', {
              attrs: {
                "label": "text",
                "options": _vm.noteIdle,
                "placeholder": "Select Note Idle"
              },
              model: {
                value: idle.note,
                callback: function callback($$v) {
                  _vm.$set(idle, "note", $$v);
                },
                expression: "idle.note"
              }
            })], 1)], 1), _c('td', [_c('b-form-group', {
              attrs: {
                "label": "",
                "label-for": "anchorUpLoading"
              }
            }, [_c('b-form-input', {
              attrs: {
                "type": "datetime-local",
                "value": "2019-12-19T13:45:00"
              },
              model: {
                value: idle.startIdle,
                callback: function callback($$v) {
                  _vm.$set(idle, "startIdle", $$v);
                },
                expression: "idle.startIdle"
              }
            }), _c('small', {
              staticStyle: {
                "float": "right"
              }
            }, [_vm._v("click "), _c('i', {
              staticClass: "fa fa-calendar"
            }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('b-form-group', {
              attrs: {
                "label": "",
                "label-for": "anchorUpLoading"
              }
            }, [_c('b-form-input', {
              attrs: {
                "type": "datetime-local",
                "value": "2019-12-19T13:45:00"
              },
              model: {
                value: idle.endIdle,
                callback: function callback($$v) {
                  _vm.$set(idle, "endIdle", $$v);
                },
                expression: "idle.endIdle"
              }
            }), _c('small', {
              staticStyle: {
                "float": "right"
              }
            }, [_vm._v("click "), _c('i', {
              staticClass: "fa fa-calendar"
            }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('div', {
              staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
            }, [_c('div', {
              staticClass: "custom-switch-inner"
            }, [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: idle.me.checked,
                expression: "idle.me.checked"
              }],
              staticClass: "custom-control-input",
              class: 'bg-' + idle.me.class,
              attrs: {
                "type": "checkbox",
                "id": 'idle.me' + index + indexIdle
              },
              domProps: {
                "checked": Array.isArray(idle.me.checked) ? _vm._i(idle.me.checked, null) > -1 : idle.me.checked
              },
              on: {
                "change": function change($event) {
                  var $$a = idle.me.checked,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false;
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v);
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(idle.me, "checked", $$a.concat([$$v]));
                    } else {
                      $$i > -1 && _vm.$set(idle.me, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                    }
                  } else {
                    _vm.$set(idle.me, "checked", $$c);
                  }
                }
              }
            }), _c('label', {
              staticClass: "custom-control-label",
              attrs: {
                "for": 'idle.me' + index + indexIdle,
                "data-on-label": idle.me.activeLabel,
                "data-off-label": idle.me.inactiveLabel
              }
            })])])]), _c('td', [_c('b-button', {
              staticClass: "mb-3 mr-1",
              attrs: {
                "size": "sm",
                "variant": "danger"
              },
              on: {
                "click": function click($event) {
                  $event.preventDefault();
                  return _vm.removeIdleChangePort(index, indexIdle);
                }
              }
            }, [_c('i', {
              staticClass: "ri-delete-bin-line"
            })])], 1)]);
          }), 0)])]) : _vm._e(), _c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('b-button', {
            staticClass: "mb-3 mr-1",
            attrs: {
              "block": "",
              "size": "sm",
              "variant": "warning"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.addIdleChangePort(index);
              }
            }
          }, [_c('i', {
            staticClass: "ri-add-line"
          }), _vm._v(" Add Idle")])], 1)], 1);
        }) : _vm._e()];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Remark")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form-group', {
          attrs: {
            "label": "Input Remark in Loading Port",
            "label-for": "remarkLoading"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "remarkLoading",
            "rows": "1"
          },
          model: {
            value: _vm.remarkLoading,
            callback: function callback($$v) {
              _vm.remarkLoading = $$v;
            },
            expression: "remarkLoading"
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _c('small', [_vm._v("field with * is required!")])], 1), _c('tab-content', {
    attrs: {
      "title": "Completed, Departure Time",
      "icon": "ri-ship-line"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Completed Cast Off")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-light table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Anchor Up / Start Engine")]), _c('th', [_vm._v("In Pos Jetty / Made All Fast")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "iq-bg-primary mb-1 mr-1"
        }, [_vm._v("23 September 2020 12:00")]), _c('td', [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "inPosJetty"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep2.inPosJetty,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep2, "inPosJetty", $$v);
            },
            expression: "formStep2.inPosJetty"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1)])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-light table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Comm Loading")]), _c('th', [_vm._v("Completed Loading")]), _c('th', [_vm._v("Cast Off / Unberthing")])])]), _c('tbody', [_c('tr', [_c('td', [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "commLoading"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep2.commLoading,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep2, "commLoading", $$v);
            },
            expression: "formStep2.commLoading"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "completedLoading"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep2.completedLoading,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep2, "completedLoading", $$v);
            },
            expression: "formStep2.completedLoading"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "castOff"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep2.castOff,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep2, "castOff", $$v);
            },
            expression: "formStep2.castOff"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1)])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-dark table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Cargo Type")]), _c('th', [_vm._v("Cargo Loaded")])])]), _c('tbody', [_c('tr', [_c('td', [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "cargoType"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep2.cargoType,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep2, "cargoType", $$v);
            },
            expression: "formStep2.cargoType"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('td', [_vm.visibleCargoLoaded === true ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "rob-fuel-oil",
            "type": "number",
            "value": "2356"
          },
          on: {
            "blur": _vm.onBlurNumberCargoLoaded
          },
          model: {
            value: _vm.formStep2.cargoLoaded,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep2, "cargoLoaded", $$v);
            },
            expression: "formStep2.cargoLoaded"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Ton")])])], 1) : _vm._e(), _vm.visibleCargoLoaded === false ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "rob-fuel-oil",
            "type": "text",
            "value": "2356"
          },
          on: {
            "focus": _vm.onFocusTextCargoLoaded
          },
          model: {
            value: _vm.formStep2.cargoLoaded,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep2, "cargoLoaded", $$v);
            },
            expression: "formStep2.cargoLoaded"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Ton")])])], 1) : _vm._e(), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v(" Input Specific Number for Cargo Loaded!")])])])])])])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Departure Time")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-light table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Document On Board")]), _c('th', [_vm._v("Drop Anchor(Bouy) (Engine Off)")]), _c('th', [_vm._v("Anchor Up / Start Engine / Departure")])])]), _c('tbody', [_c('tr', [_c('td', [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "documentOnBoard"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep2.documentOnBoard,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep2, "documentOnBoard", $$v);
            },
            expression: "formStep2.documentOnBoard"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('td', {
          staticClass: "iq-bg-info mb-1 mr-1"
        }, [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "dropAnchorBouy"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep2.dropAnchorBouy,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep2, "dropAnchorBouy", $$v);
            },
            expression: "formStep2.dropAnchorBouy"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('td', {
          staticClass: "iq-bg-info mb-1 mr-1"
        }, [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "anchorUp"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep2.anchorUp,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep2, "anchorUp", $$v);
            },
            expression: "formStep2.anchorUp"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1)])])])]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('p', [_vm._v("Other Activity before Departure to Discharge Port")])]), _c('b-col', {
          attrs: {
            "md": "6 text-right"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "size": "sm",
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addIdleStep2();
            }
          }
        }, [_c('i', {
          staticClass: "ri-add-line"
        }), _vm._v(" Add Idle")])], 1), _vm.formStep2.idle.length > 0 ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped table-warning"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Note Idle")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Idle")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("End Idle")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Using ME?")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.formStep2.idle, function (idle, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_c('b-form-group', {
            attrs: {
              "label": "",
              "label-for": ""
            }
          }, [_c('v-select', {
            attrs: {
              "disabled": _vm.formStep2._saving,
              "label": "text",
              "options": _vm.noteIdle,
              "placeholder": "Select Note Idle"
            },
            model: {
              value: idle.note,
              callback: function callback($$v) {
                _vm.$set(idle, "note", $$v);
              },
              expression: "idle.note"
            }
          })], 1)], 1), _c('td', [_c('b-form-group', {
            attrs: {
              "label": "",
              "label-for": "anchorUpLoading"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "datetime-local",
              "value": "2019-12-19T13:45:00"
            },
            model: {
              value: idle.startIdle,
              callback: function callback($$v) {
                _vm.$set(idle, "startIdle", $$v);
              },
              expression: "idle.startIdle"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('b-form-group', {
            attrs: {
              "label": "",
              "label-for": "anchorUpLoading"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "datetime-local",
              "value": "2019-12-19T13:45:00"
            },
            model: {
              value: idle.endIdle,
              callback: function callback($$v) {
                _vm.$set(idle, "endIdle", $$v);
              },
              expression: "idle.endIdle"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('div', {
            staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
          }, [_c('div', {
            staticClass: "custom-switch-inner"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: idle.me.checked,
              expression: "idle.me.checked"
            }],
            staticClass: "custom-control-input",
            class: 'bg-' + idle.me.class,
            attrs: {
              "type": "checkbox",
              "id": 'idle.me' + index
            },
            domProps: {
              "checked": Array.isArray(idle.me.checked) ? _vm._i(idle.me.checked, null) > -1 : idle.me.checked
            },
            on: {
              "change": function change($event) {
                var $$a = idle.me.checked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(idle.me, "checked", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(idle.me, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(idle.me, "checked", $$c);
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": 'idle.me' + index,
              "data-on-label": idle.me.activeLabel,
              "data-off-label": idle.me.inactiveLabel
            }
          })])])]), _c('td', [_c('b-button', {
            staticClass: "mb-3 mr-1",
            attrs: {
              "size": "sm",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.removeIdleStep2(index);
              }
            }
          }, [_c('i', {
            staticClass: "ri-delete-bin-line"
          })])], 1)]);
        }), 0)])]) : _vm._e()], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Remark")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form-group', {
          attrs: {
            "label": "Input Remark in Loading",
            "label-for": "formStep2.remark"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "formStep2.remark",
            "rows": "1"
          },
          model: {
            value: _vm.formStep2.remark,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep2, "remark", $$v);
            },
            expression: "formStep2.remark"
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _c('small', [_vm._v("field with * is required!")])], 1), _c('tab-content', {
    attrs: {
      "title": "Discharge Port, In Pos Jetty",
      "icon": "ri-map-pin-time-fill"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Voyage Obstacles go to Discharge Port")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-light table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Anchor Up / Start Engine (Loading Port [In Pos Jetty] )")]), _c('th', [_vm._v("Voyage Obstacles ?")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "iq-bg-primary mb-1 mr-1"
        }, [_vm._v("23 September 2020 12:00")]), _c('td', [_c('div', {
          staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
        }, [_c('div', {
          staticClass: "custom-switch-inner"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.formStep3.voyageObstaclesYesNo.checked,
            expression: "formStep3.voyageObstaclesYesNo.checked"
          }],
          staticClass: "custom-control-input",
          class: 'bg-' + _vm.formStep3.voyageObstaclesYesNo.class,
          attrs: {
            "type": "checkbox",
            "id": 'formStep3.voyageObstaclesYesNo'
          },
          domProps: {
            "checked": Array.isArray(_vm.formStep3.voyageObstaclesYesNo.checked) ? _vm._i(_vm.formStep3.voyageObstaclesYesNo.checked, null) > -1 : _vm.formStep3.voyageObstaclesYesNo.checked
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.formStep3.voyageObstaclesYesNo.checked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.formStep3.voyageObstaclesYesNo, "checked", $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.formStep3.voyageObstaclesYesNo, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.formStep3.voyageObstaclesYesNo, "checked", $$c);
              }
            }
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": 'formStep3.voyageObstaclesYesNo',
            "data-on-label": _vm.formStep3.voyageObstaclesYesNo.activeLabel,
            "data-off-label": _vm.formStep3.voyageObstaclesYesNo.inactiveLabel
          }
        })])])])])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_vm.formStep3.voyageObstaclesYesNo.checked == true ? _c('table', {
          staticClass: "table table-light table-striped"
        }, [_c('thead', [_c('tr', [_c('td', {
          staticClass: "text-right"
        }, [_c('strong', [_vm._v("Shelter ? ")])]), _c('td', {
          staticClass: "text-left"
        }, [_c('div', {
          staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
        }, [_c('div', {
          staticClass: "custom-switch-inner"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.formStep3.voyageObstacles.shelterYesNo.checked,
            expression: "formStep3.voyageObstacles.shelterYesNo.checked"
          }],
          staticClass: "custom-control-input",
          class: 'bg-' + _vm.formStep3.voyageObstacles.shelterYesNo.class,
          attrs: {
            "type": "checkbox",
            "id": 'formStep3.shelterYesNo'
          },
          domProps: {
            "checked": Array.isArray(_vm.formStep3.voyageObstacles.shelterYesNo.checked) ? _vm._i(_vm.formStep3.voyageObstacles.shelterYesNo.checked, null) > -1 : _vm.formStep3.voyageObstacles.shelterYesNo.checked
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.formStep3.voyageObstacles.shelterYesNo.checked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.formStep3.voyageObstacles.shelterYesNo, "checked", $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.formStep3.voyageObstacles.shelterYesNo, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.formStep3.voyageObstacles.shelterYesNo, "checked", $$c);
              }
            }
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": 'formStep3.shelterYesNo',
            "data-on-label": _vm.formStep3.voyageObstacles.shelterYesNo.activeLabel,
            "data-off-label": _vm.formStep3.voyageObstacles.shelterYesNo.inactiveLabel
          }
        })])])])])]), _c('tbody', {
          staticClass: "iq-bg-danger"
        }, [_vm.formStep3.voyageObstacles.shelterYesNo.checked == true ? [_c('tr', [_c('td', {
          attrs: {
            "colspan": "2"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "block": "",
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addShelter();
            }
          }
        }, [_c('i', {
          staticClass: "ri-add-line"
        }), _vm._v(" Add Shelter")])], 1)]), _vm._l(_vm.formStep3.voyageObstacles.shelter, function (shelter, index) {
          return [_c('tr', [_c('td', [_c('h4', [_vm._v("Shelter #" + _vm._s(index + 1))])]), _c('td', {
            staticClass: "text-right"
          }, [_c('b-button', {
            staticClass: "mb-3 mr-1",
            attrs: {
              "size": "sm",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.removeShelter(index);
              }
            }
          }, [_c('i', {
            staticClass: "ri-close-line"
          }), _vm._v(" Remove")])], 1)]), _c('tr', [_c('td', {
            attrs: {
              "width": "50%"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Shelter Location *",
              "label-for": "shelter.shelterLocation"
            }
          }, [_c('v-select', {
            attrs: {
              "label": "text",
              "options": _vm.destination,
              "placeholder": "Select Shelter Location"
            },
            model: {
              value: shelter.shelterLocation,
              callback: function callback($$v) {
                _vm.$set(shelter, "shelterLocation", $$v);
              },
              expression: "shelter.shelterLocation"
            }
          })], 1)], 1), _c('td', {
            attrs: {
              "width": "50%"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Shelter Reason *",
              "label-for": "shelter.shelterReason"
            }
          }, [_c('v-select', {
            attrs: {
              "label": "text",
              "options": _vm.noteIdle,
              "placeholder": "Select Shelter Reason"
            },
            model: {
              value: shelter.shelterReason,
              callback: function callback($$v) {
                _vm.$set(shelter, "shelterReason", $$v);
              },
              expression: "shelter.shelterReason"
            }
          })], 1)], 1)]), _c('tr', [_c('td', {
            attrs: {
              "width": "50%"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Bunker ? [Yes / No] *",
              "label-for": "shelter.fuelOilYesNo"
            }
          }, [_c('div', {
            staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
          }, [_c('div', {
            staticClass: "custom-switch-inner"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: shelter.fuelOilYesNo.checked,
              expression: "shelter.fuelOilYesNo.checked"
            }],
            staticClass: "custom-control-input",
            class: 'bg-' + shelter.fuelOilYesNo.class,
            attrs: {
              "type": "checkbox",
              "id": 'shelter.fuelOilYesNo' + index
            },
            domProps: {
              "checked": Array.isArray(shelter.fuelOilYesNo.checked) ? _vm._i(shelter.fuelOilYesNo.checked, null) > -1 : shelter.fuelOilYesNo.checked
            },
            on: {
              "change": function change($event) {
                var $$a = shelter.fuelOilYesNo.checked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(shelter.fuelOilYesNo, "checked", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(shelter.fuelOilYesNo, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(shelter.fuelOilYesNo, "checked", $$c);
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": 'shelter.fuelOilYesNo' + index,
              "data-on-label": shelter.fuelOilYesNo.activeLabel,
              "data-off-label": shelter.fuelOilYesNo.inactiveLabel
            }
          })])])])], 1), _c('td', {
            attrs: {
              "width": "50%"
            }
          }, [shelter.fuelOilYesNo.checked == true ? [_c('b-form-group', {
            attrs: {
              "label": "Amount *",
              "label-for": "shelter.fuelOilAmount"
            }
          }, [_vm.visibleFuelOilAmountShelterStep3 === true ? _c('div', {
            staticClass: "input-group"
          }, [_c('b-form-input', {
            attrs: {
              "id": "shelter.fuelOilAmount",
              "type": "number",
              "value": "2356"
            },
            on: {
              "blur": _vm.onBlurNumberROBShelter
            },
            model: {
              value: shelter.fuelOilAmount,
              callback: function callback($$v) {
                _vm.$set(shelter, "fuelOilAmount", $$v);
              },
              expression: "shelter.fuelOilAmount"
            }
          }), _c('div', {
            staticClass: "input-group-prepend"
          }, [_c('span', {
            staticClass: "input-group-text"
          }, [_vm._v("Liter")])])], 1) : _vm._e(), _vm.visibleFuelOilAmountShelterStep3 === false ? _c('div', {
            staticClass: "input-group"
          }, [_c('b-form-input', {
            attrs: {
              "id": "shelter.fuelOilAmount",
              "type": "text",
              "value": "2356"
            },
            on: {
              "focus": _vm.onFocusTextROBShelter
            },
            model: {
              value: shelter.fuelOilAmount,
              callback: function callback($$v) {
                _vm.$set(shelter, "fuelOilAmount", $$v);
              },
              expression: "shelter.fuelOilAmount"
            }
          }), _c('div', {
            staticClass: "input-group-prepend"
          }, [_c('span', {
            staticClass: "input-group-text"
          }, [_vm._v("Liter")])])], 1) : _vm._e()])] : _vm._e()], 2)]), _c('tr', [_c('td', {
            attrs: {
              "width": "50%"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Drop Anchor(Bouy) (Engine Off)",
              "label-for": "shelter.dropAnchor"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "datetime-local",
              "value": "2019-12-19T13:45:00"
            },
            model: {
              value: shelter.dropAnchor,
              callback: function callback($$v) {
                _vm.$set(shelter, "dropAnchor", $$v);
              },
              expression: "shelter.dropAnchor"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show")])], 1)], 1), _c('td', {
            attrs: {
              "width": "50%"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Anchor Up / Start Engine",
              "label-for": "shelter.anchorUp"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "datetime-local",
              "value": "2019-12-19T13:45:00"
            },
            model: {
              value: shelter.anchorUp,
              callback: function callback($$v) {
                _vm.$set(shelter, "anchorUp", $$v);
              },
              expression: "shelter.anchorUp"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show")])], 1)], 1)])];
        })] : [_c('tr', [_c('td', [_c('b-form-group', {
          attrs: {
            "label": "Drop Anchor(Bouy) (Engine Off)",
            "label-for": "formStep3.voyageObstacles.engineOff"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep3.voyageObstacles.engineOff,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep3.voyageObstacles, "engineOff", $$v);
            },
            expression: "formStep3.voyageObstacles.engineOff"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('b-form-group', {
          attrs: {
            "label": "Anchor Up / Start Engine",
            "label-for": "formStep3.voyageObstacles.startEngine"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep3.voyageObstacles.startEngine,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep3.voyageObstacles, "startEngine", $$v);
            },
            expression: "formStep3.voyageObstacles.startEngine"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1)]), _c('tr', [_c('td', {
          attrs: {
            "colspan": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Input Reason of Voyage Obstacles",
            "label-for": "formStep3.voyageObstacles.reason"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "formStep2.remark",
            "rows": "1"
          },
          model: {
            value: _vm.formStep3.voyageObstacles.reason,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep3.voyageObstacles, "reason", $$v);
            },
            expression: "formStep3.voyageObstacles.reason"
          }
        })], 1)], 1)])]], 2)]) : _vm._e()]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-light table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "35%"
          }
        }), _c('th', {
          attrs: {
            "width": "35%"
          }
        }), _c('th', {
          attrs: {
            "width": "30%"
          }
        })])]), _c('tbody', [_c('tr', [_c('td', [_c('b-form-group', {
          attrs: {
            "label": "Bunker ?",
            "label-for": "formStep3.fuelOilYesNo"
          }
        }, [_c('div', {
          staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
        }, [_c('div', {
          staticClass: "custom-switch-inner"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.formStep3.fuelOilYesNo.checked,
            expression: "formStep3.fuelOilYesNo.checked"
          }],
          staticClass: "custom-control-input",
          class: 'bg-' + _vm.formStep3.fuelOilYesNo.class,
          attrs: {
            "type": "checkbox",
            "id": 'formStep3.fuelOilYesNo'
          },
          domProps: {
            "checked": Array.isArray(_vm.formStep3.fuelOilYesNo.checked) ? _vm._i(_vm.formStep3.fuelOilYesNo.checked, null) > -1 : _vm.formStep3.fuelOilYesNo.checked
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.formStep3.fuelOilYesNo.checked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.formStep3.fuelOilYesNo, "checked", $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.formStep3.fuelOilYesNo, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.formStep3.fuelOilYesNo, "checked", $$c);
              }
            }
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": 'formStep3.fuelOilYesNo',
            "data-on-label": _vm.formStep3.fuelOilYesNo.activeLabel,
            "data-off-label": _vm.formStep3.fuelOilYesNo.inactiveLabel
          }
        })])])])], 1), _c('td', [_vm.formStep3.fuelOilYesNo.checked == true ? [_c('b-form-group', {
          attrs: {
            "label": "Bunker Date",
            "label-for": "formStep3.fuelOilDate"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep3.fuelOilDate,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep3, "fuelOilDate", $$v);
            },
            expression: "formStep3.fuelOilDate"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)] : _vm._e()], 2), _c('td', [_vm.formStep3.fuelOilYesNo.checked == true ? [_c('b-form-group', {
          attrs: {
            "label": "Bunker Amount",
            "label-for": "formStep3.fuelOilAmount"
          }
        }, [_vm.visibleFuelOilAmountStep3 === true ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "formStep3.fuelOilAmount",
            "type": "number",
            "value": "2356"
          },
          on: {
            "blur": _vm.onBlurNumberROBStep3
          },
          model: {
            value: _vm.formStep3.fuelOilAmount,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep3, "fuelOilAmount", $$v);
            },
            expression: "formStep3.fuelOilAmount"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Liter")])])], 1) : _vm._e(), _vm.visibleFuelOilAmountStep3 === false ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "formStep3.fuelOilAmount",
            "type": "text",
            "value": "2356"
          },
          on: {
            "focus": _vm.onFocusTextROBStep3
          },
          model: {
            value: _vm.formStep3.fuelOilAmount,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep3, "fuelOilAmount", $$v);
            },
            expression: "formStep3.fuelOilAmount"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Liter")])])], 1) : _vm._e()])] : _vm._e()], 2)])])])])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Discharge Port - In Pos Jetty")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-light table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "30%"
          }
        }, [_vm._v("Destination (Discharge) Port")]), _c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Arrival / Drop Anchor(Bouy) (Engine Off)")]), _c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Anchor Up / Start Engine / Departure")])])]), _c('tbody', [_c('tr', [_c('td', [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "destination"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.destination,
            "placeholder": "Select Destination"
          },
          model: {
            value: _vm.formStep3.destinationPort,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep3, "destinationPort", $$v);
            },
            expression: "formStep3.destinationPort"
          }
        })], 1)], 1), _c('td', [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "formStep3.arrivalDropAnchor"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep3.arrivalDropAnchor,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep3, "arrivalDropAnchor", $$v);
            },
            expression: "formStep3.arrivalDropAnchor"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "formStep3.departureAnchorUp"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep3.departureAnchorUp,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep3, "departureAnchorUp", $$v);
            },
            expression: "formStep3.departureAnchorUp"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1)])])])]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('p', [_vm._v("Other Activity before Comm Disch")])]), _c('b-col', {
          attrs: {
            "md": "6 text-right"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "size": "sm",
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addIdleStep3();
            }
          }
        }, [_c('i', {
          staticClass: "ri-add-line"
        }), _vm._v(" Add Idle")])], 1), _vm.formStep3.idle.length > 0 ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped table-warning"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Note Idle")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Idle")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("End Idle")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Using ME?")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.formStep3.idle, function (idle, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_c('b-form-group', {
            attrs: {
              "label": "",
              "label-for": ""
            }
          }, [_c('v-select', {
            attrs: {
              "disabled": _vm.formStep3._saving,
              "label": "text",
              "options": _vm.noteIdle,
              "placeholder": "Select Note Idle"
            },
            model: {
              value: idle.note,
              callback: function callback($$v) {
                _vm.$set(idle, "note", $$v);
              },
              expression: "idle.note"
            }
          })], 1)], 1), _c('td', [_c('b-form-group', {
            attrs: {
              "label": "",
              "label-for": "anchorUpLoading"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "datetime-local",
              "value": "2019-12-19T13:45:00"
            },
            model: {
              value: idle.startIdle,
              callback: function callback($$v) {
                _vm.$set(idle, "startIdle", $$v);
              },
              expression: "idle.startIdle"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('b-form-group', {
            attrs: {
              "label": "",
              "label-for": "anchorUpLoading"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "datetime-local",
              "value": "2019-12-19T13:45:00"
            },
            model: {
              value: idle.endIdle,
              callback: function callback($$v) {
                _vm.$set(idle, "endIdle", $$v);
              },
              expression: "idle.endIdle"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('div', {
            staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
          }, [_c('div', {
            staticClass: "custom-switch-inner"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: idle.me.checked,
              expression: "idle.me.checked"
            }],
            staticClass: "custom-control-input",
            class: 'bg-' + idle.me.class,
            attrs: {
              "type": "checkbox",
              "id": 'idle.me' + index
            },
            domProps: {
              "checked": Array.isArray(idle.me.checked) ? _vm._i(idle.me.checked, null) > -1 : idle.me.checked
            },
            on: {
              "change": function change($event) {
                var $$a = idle.me.checked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(idle.me, "checked", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(idle.me, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(idle.me, "checked", $$c);
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": 'idle.me' + index,
              "data-on-label": idle.me.activeLabel,
              "data-off-label": idle.me.inactiveLabel
            }
          })])])]), _c('td', [_c('b-button', {
            staticClass: "mb-3 mr-1",
            attrs: {
              "size": "sm",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.removeIdleStep3(index);
              }
            }
          }, [_c('i', {
            staticClass: "ri-delete-bin-line"
          })])], 1)]);
        }), 0)])]) : _vm._e()], 1)];
      },
      proxy: true
    }])
  }), _c('small', [_vm._v("field with * is required!")])], 1), _c('tab-content', {
    attrs: {
      "title": "Completed Disch / Cash-Off - Loading Port",
      "icon": "ri-compass-3-fill"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Completed Cast Off")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-light table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Anchor Up / Start Engine (Discharge Port)")]), _c('th', [_vm._v("In Pos Jetty / Made All Fast")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "iq-bg-primary mb-1 mr-1"
        }, [_vm._v("23 September 2020 12:00")]), _c('td', [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "formStep4.inPosJetty"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep4.inPosJetty,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4, "inPosJetty", $$v);
            },
            expression: "formStep4.inPosJetty"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1)])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-light table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Comm Discharge")]), _c('th', [_vm._v("Completed Discharge")]), _c('th', [_vm._v("Cast Off / Unberthing")])])]), _c('tbody', [_c('tr', [_c('td', [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "commDischarge"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep4.commDischarge,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4, "commDischarge", $$v);
            },
            expression: "formStep4.commDischarge"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "completedDischarge"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep4.completedDischarge,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4, "completedDischarge", $$v);
            },
            expression: "formStep4.completedDischarge"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "castOff"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep4.castOff,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4, "castOff", $$v);
            },
            expression: "formStep4.castOff"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1)])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-dark table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Cargo Loaded")]), _c('th', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Cargo Discharge")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "iq-bg-warning"
        }, [_vm._v(" 7.234,42 Ton ")]), _c('td', [_vm.visibleCargoDischarge === true ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "rob-fuel-oil",
            "type": "number",
            "value": "2356"
          },
          on: {
            "blur": _vm.onBlurNumberCargoDischarge
          },
          model: {
            value: _vm.formStep4.cargoDischarge,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4, "cargoDischarge", $$v);
            },
            expression: "formStep4.cargoDischarge"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Ton")])])], 1) : _vm._e(), _vm.visibleCargoDischarge === false ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "rob-fuel-oil",
            "type": "text",
            "value": "2356"
          },
          on: {
            "focus": _vm.onFocusTextCargoDischarge
          },
          model: {
            value: _vm.formStep4.cargoDischarge,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4, "cargoDischarge", $$v);
            },
            expression: "formStep4.cargoDischarge"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Ton")])])], 1) : _vm._e(), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v(" Input Specific Number for Cargo Discharge!")])])])])])])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Agent, Bunker Supplier, Remarks")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Name Of Agent",
            "label-for": "formStep4.nameOfAgent"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "id": "formStep4.nameOfAgent",
            "rows": "1"
          },
          model: {
            value: _vm.formStep4.nameOfAgent,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4, "nameOfAgent", $$v);
            },
            expression: "formStep4.nameOfAgent"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Bunker Supplier",
            "label-for": "formStep4.bunkerSupplier"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "id": "formStep4.bunkerSupplier",
            "rows": "1"
          },
          model: {
            value: _vm.formStep4.bunkerSupplier,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4, "bunkerSupplier", $$v);
            },
            expression: "formStep4.bunkerSupplier"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Input Remark in Discharge",
            "label-for": "formStep4.remark"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "formStep4.remark",
            "rows": "1"
          },
          model: {
            value: _vm.formStep4.remark,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4, "remark", $$v);
            },
            expression: "formStep4.remark"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Departure Time")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-light table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Drop Anchor(Bouy) (Engine Off)")]), _c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Anchor Up / Start Engine / Departure")]), _c('th', {
          attrs: {
            "width": "30%"
          }
        }, [_vm._v("Destination Port")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "iq-bg-info mb-1 mr-1"
        }, [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "dropAnchorBouy"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep4.dropAnchorBouy,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4, "dropAnchorBouy", $$v);
            },
            expression: "formStep4.dropAnchorBouy"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('td', {
          staticClass: "iq-bg-info mb-1 mr-1"
        }, [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "anchorUp"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep4.anchorUp,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4, "anchorUp", $$v);
            },
            expression: "formStep4.anchorUp"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "destination"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.destination,
            "placeholder": "Select Destination"
          },
          model: {
            value: _vm.formStep4.destinationPort,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4, "destinationPort", $$v);
            },
            expression: "formStep4.destinationPort"
          }
        })], 1)], 1)])])])]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('p', [_vm._v("Other Activity before Departure to Loading Port")])]), _c('b-col', {
          attrs: {
            "md": "6 text-right"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "size": "sm",
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addIdleStep4();
            }
          }
        }, [_c('i', {
          staticClass: "ri-add-line"
        }), _vm._v(" Add Idle")])], 1), _vm.formStep4.idle.length > 0 ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped table-warning"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Note Idle")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Idle")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("End Idle")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Using ME?")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.formStep4.idle, function (idle, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_c('b-form-group', {
            attrs: {
              "label": "",
              "label-for": ""
            }
          }, [_c('v-select', {
            attrs: {
              "disabled": _vm.formStep4._saving,
              "label": "text",
              "options": _vm.noteIdle,
              "placeholder": "Select Note Idle"
            },
            model: {
              value: idle.note,
              callback: function callback($$v) {
                _vm.$set(idle, "note", $$v);
              },
              expression: "idle.note"
            }
          })], 1)], 1), _c('td', [_c('b-form-group', {
            attrs: {
              "label": "",
              "label-for": "anchorUpLoading"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "datetime-local",
              "value": "2019-12-19T13:45:00"
            },
            model: {
              value: idle.startIdle,
              callback: function callback($$v) {
                _vm.$set(idle, "startIdle", $$v);
              },
              expression: "idle.startIdle"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('b-form-group', {
            attrs: {
              "label": "",
              "label-for": "anchorUpLoading"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "datetime-local",
              "value": "2019-12-19T13:45:00"
            },
            model: {
              value: idle.endIdle,
              callback: function callback($$v) {
                _vm.$set(idle, "endIdle", $$v);
              },
              expression: "idle.endIdle"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('div', {
            staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
          }, [_c('div', {
            staticClass: "custom-switch-inner"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: idle.me.checked,
              expression: "idle.me.checked"
            }],
            staticClass: "custom-control-input",
            class: 'bg-' + idle.me.class,
            attrs: {
              "type": "checkbox",
              "id": 'idle.me' + index
            },
            domProps: {
              "checked": Array.isArray(idle.me.checked) ? _vm._i(idle.me.checked, null) > -1 : idle.me.checked
            },
            on: {
              "change": function change($event) {
                var $$a = idle.me.checked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(idle.me, "checked", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(idle.me, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(idle.me, "checked", $$c);
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": 'idle.me' + index,
              "data-on-label": idle.me.activeLabel,
              "data-off-label": idle.me.inactiveLabel
            }
          })])])]), _c('td', [_c('b-button', {
            staticClass: "mb-3 mr-1",
            attrs: {
              "size": "sm",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.removeIdleStep4(index);
              }
            }
          }, [_c('i', {
            staticClass: "ri-delete-bin-line"
          })])], 1)]);
        }), 0)])]) : _vm._e()], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    staticClass: "iq-bg-success",
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Arrival Port (Destination/Loading) - Arrival Condition")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-light table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Voyage Obstacles ?")])])]), _c('tbody', [_c('tr', [_c('td', [_c('div', {
          staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
        }, [_c('div', {
          staticClass: "custom-switch-inner"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.formStep4.arrivalPort.voyageObstaclesYesNo.checked,
            expression: "formStep4.arrivalPort.voyageObstaclesYesNo.checked"
          }],
          staticClass: "custom-control-input",
          class: 'bg-' + _vm.formStep4.arrivalPort.voyageObstaclesYesNo.class,
          attrs: {
            "type": "checkbox",
            "id": 'formStep4.arrivalPort.voyageObstaclesYesNo'
          },
          domProps: {
            "checked": Array.isArray(_vm.formStep4.arrivalPort.voyageObstaclesYesNo.checked) ? _vm._i(_vm.formStep4.arrivalPort.voyageObstaclesYesNo.checked, null) > -1 : _vm.formStep4.arrivalPort.voyageObstaclesYesNo.checked
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.formStep4.arrivalPort.voyageObstaclesYesNo.checked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.formStep4.arrivalPort.voyageObstaclesYesNo, "checked", $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.formStep4.arrivalPort.voyageObstaclesYesNo, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.formStep4.arrivalPort.voyageObstaclesYesNo, "checked", $$c);
              }
            }
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": 'formStep4.arrivalPort.voyageObstaclesYesNo',
            "data-on-label": _vm.formStep4.arrivalPort.voyageObstaclesYesNo.activeLabel,
            "data-off-label": _vm.formStep4.arrivalPort.voyageObstaclesYesNo.inactiveLabel
          }
        })])])])])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_vm.formStep4.arrivalPort.voyageObstaclesYesNo.checked == true ? _c('table', {
          staticClass: "table table-light table-striped"
        }, [_c('thead', [_c('tr', [_c('td', {
          staticClass: "text-right"
        }, [_c('strong', [_vm._v("Shelter ? ")])]), _c('td', {
          staticClass: "text-left"
        }, [_c('div', {
          staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
        }, [_c('div', {
          staticClass: "custom-switch-inner"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.formStep4.arrivalPort.voyageObstacles.shelterYesNo.checked,
            expression: "formStep4.arrivalPort.voyageObstacles.shelterYesNo.checked"
          }],
          staticClass: "custom-control-input",
          class: 'bg-' + _vm.formStep4.arrivalPort.voyageObstacles.shelterYesNo.class,
          attrs: {
            "type": "checkbox",
            "id": 'formStep4.arrivalPort.voyageObstacles.shelterYesNo'
          },
          domProps: {
            "checked": Array.isArray(_vm.formStep4.arrivalPort.voyageObstacles.shelterYesNo.checked) ? _vm._i(_vm.formStep4.arrivalPort.voyageObstacles.shelterYesNo.checked, null) > -1 : _vm.formStep4.arrivalPort.voyageObstacles.shelterYesNo.checked
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.formStep4.arrivalPort.voyageObstacles.shelterYesNo.checked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.formStep4.arrivalPort.voyageObstacles.shelterYesNo, "checked", $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.formStep4.arrivalPort.voyageObstacles.shelterYesNo, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.formStep4.arrivalPort.voyageObstacles.shelterYesNo, "checked", $$c);
              }
            }
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": 'formStep4.arrivalPort.voyageObstacles.shelterYesNo',
            "data-on-label": _vm.formStep4.arrivalPort.voyageObstacles.shelterYesNo.activeLabel,
            "data-off-label": _vm.formStep4.arrivalPort.voyageObstacles.shelterYesNo.inactiveLabel
          }
        })])])])])]), _c('tbody', {
          staticClass: "iq-bg-danger"
        }, [_vm.formStep4.arrivalPort.voyageObstacles.shelterYesNo.checked == true ? [_c('tr', [_c('td', {
          attrs: {
            "colspan": "2"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "block": "",
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addShelterArrival();
            }
          }
        }, [_c('i', {
          staticClass: "ri-add-line"
        }), _vm._v(" Add Shelter")])], 1)]), _vm._l(_vm.formStep4.arrivalPort.voyageObstacles.shelter, function (shelter, index) {
          return [_c('tr', [_c('td', [_c('h4', [_vm._v("Shelter #" + _vm._s(index + 1))])]), _c('td', {
            staticClass: "text-right"
          }, [_c('b-button', {
            staticClass: "mb-3 mr-1",
            attrs: {
              "size": "sm",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.removeShelterArrival(index);
              }
            }
          }, [_c('i', {
            staticClass: "ri-close-line"
          }), _vm._v(" Remove")])], 1)]), _c('tr', [_c('td', {
            attrs: {
              "width": "50%"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Shelter Location *",
              "label-for": "shelter.shelterLocation"
            }
          }, [_c('v-select', {
            attrs: {
              "label": "text",
              "options": _vm.destination,
              "placeholder": "Select Shelter Location"
            },
            model: {
              value: shelter.shelterLocation,
              callback: function callback($$v) {
                _vm.$set(shelter, "shelterLocation", $$v);
              },
              expression: "shelter.shelterLocation"
            }
          })], 1)], 1), _c('td', {
            attrs: {
              "width": "50%"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Shelter Reason *",
              "label-for": "shelter.shelterReason"
            }
          }, [_c('v-select', {
            attrs: {
              "label": "text",
              "options": _vm.noteIdle,
              "placeholder": "Select Shelter Reason"
            },
            model: {
              value: shelter.shelterReason,
              callback: function callback($$v) {
                _vm.$set(shelter, "shelterReason", $$v);
              },
              expression: "shelter.shelterReason"
            }
          })], 1)], 1)]), _c('tr', [_c('td', {
            attrs: {
              "width": "50%"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Bunker ? [Yes / No] *",
              "label-for": "shelter.fuelOilYesNo"
            }
          }, [_c('div', {
            staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
          }, [_c('div', {
            staticClass: "custom-switch-inner"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: shelter.fuelOilYesNo.checked,
              expression: "shelter.fuelOilYesNo.checked"
            }],
            staticClass: "custom-control-input",
            class: 'bg-' + shelter.fuelOilYesNo.class,
            attrs: {
              "type": "checkbox",
              "id": 'shelter.fuelOilYesNo' + index
            },
            domProps: {
              "checked": Array.isArray(shelter.fuelOilYesNo.checked) ? _vm._i(shelter.fuelOilYesNo.checked, null) > -1 : shelter.fuelOilYesNo.checked
            },
            on: {
              "change": function change($event) {
                var $$a = shelter.fuelOilYesNo.checked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(shelter.fuelOilYesNo, "checked", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(shelter.fuelOilYesNo, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(shelter.fuelOilYesNo, "checked", $$c);
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": 'shelter.fuelOilYesNo' + index,
              "data-on-label": shelter.fuelOilYesNo.activeLabel,
              "data-off-label": shelter.fuelOilYesNo.inactiveLabel
            }
          })])])])], 1), _c('td', {
            attrs: {
              "width": "50%"
            }
          }, [shelter.fuelOilYesNo.checked == true ? [_c('b-form-group', {
            attrs: {
              "label": "Amount *",
              "label-for": "shelter.fuelOilAmount"
            }
          }, [_vm.visibleFuelOilAmountShelterStep4 === true ? _c('div', {
            staticClass: "input-group"
          }, [_c('b-form-input', {
            attrs: {
              "id": "shelter.fuelOilAmount",
              "type": "number",
              "value": "2356"
            },
            on: {
              "blur": _vm.onBlurNumberROBShelterStep4
            },
            model: {
              value: shelter.fuelOilAmount,
              callback: function callback($$v) {
                _vm.$set(shelter, "fuelOilAmount", $$v);
              },
              expression: "shelter.fuelOilAmount"
            }
          }), _c('div', {
            staticClass: "input-group-prepend"
          }, [_c('span', {
            staticClass: "input-group-text"
          }, [_vm._v("Liter")])])], 1) : _vm._e(), _vm.visibleFuelOilAmountShelterStep4 === false ? _c('div', {
            staticClass: "input-group"
          }, [_c('b-form-input', {
            attrs: {
              "id": "shelter.fuelOilAmount",
              "type": "text",
              "value": "2356"
            },
            on: {
              "focus": _vm.onFocusTextROBShelterStep4
            },
            model: {
              value: shelter.fuelOilAmount,
              callback: function callback($$v) {
                _vm.$set(shelter, "fuelOilAmount", $$v);
              },
              expression: "shelter.fuelOilAmount"
            }
          }), _c('div', {
            staticClass: "input-group-prepend"
          }, [_c('span', {
            staticClass: "input-group-text"
          }, [_vm._v("Liter")])])], 1) : _vm._e()])] : _vm._e()], 2)]), _c('tr', [_c('td', {
            attrs: {
              "width": "50%"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Fresh Water ? [Yes / No] *",
              "label-for": "shelter.freshWaterYesNo"
            }
          }, [_c('div', {
            staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
          }, [_c('div', {
            staticClass: "custom-switch-inner"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: shelter.freshWaterYesNo.checked,
              expression: "shelter.freshWaterYesNo.checked"
            }],
            staticClass: "custom-control-input",
            class: 'bg-' + shelter.freshWaterYesNo.class,
            attrs: {
              "type": "checkbox",
              "id": 'shelter.freshWaterYesNo' + index
            },
            domProps: {
              "checked": Array.isArray(shelter.freshWaterYesNo.checked) ? _vm._i(shelter.freshWaterYesNo.checked, null) > -1 : shelter.freshWaterYesNo.checked
            },
            on: {
              "change": function change($event) {
                var $$a = shelter.freshWaterYesNo.checked,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && _vm.$set(shelter.freshWaterYesNo, "checked", $$a.concat([$$v]));
                  } else {
                    $$i > -1 && _vm.$set(shelter.freshWaterYesNo, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.$set(shelter.freshWaterYesNo, "checked", $$c);
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": 'shelter.freshWaterYesNo' + index,
              "data-on-label": shelter.freshWaterYesNo.activeLabel,
              "data-off-label": shelter.freshWaterYesNo.inactiveLabel
            }
          })])])])], 1), _c('td', {
            attrs: {
              "width": "50%"
            }
          }, [shelter.freshWaterYesNo.checked == true ? [_c('b-form-group', {
            attrs: {
              "label": "Amount *",
              "label-for": "shelter.freshWater"
            }
          }, [_vm.visibleFreshWaterAmountShelterStep4 === true ? _c('div', {
            staticClass: "input-group"
          }, [_c('b-form-input', {
            attrs: {
              "id": "shelter.freshWaterAmount",
              "type": "number",
              "value": "2356"
            },
            on: {
              "blur": _vm.onBlurNumberFWShelterStep4
            },
            model: {
              value: shelter.freshWaterAmount,
              callback: function callback($$v) {
                _vm.$set(shelter, "freshWaterAmount", $$v);
              },
              expression: "shelter.freshWaterAmount"
            }
          }), _c('div', {
            staticClass: "input-group-prepend"
          }, [_c('span', {
            staticClass: "input-group-text"
          }, [_vm._v("Liter")])])], 1) : _vm._e(), _vm.visibleFreshWaterAmountShelterStep4 === false ? _c('div', {
            staticClass: "input-group"
          }, [_c('b-form-input', {
            attrs: {
              "id": "shelter.freshWaterAmount",
              "type": "text",
              "value": "2356"
            },
            on: {
              "focus": _vm.onFocusTextFWShelterStep4
            },
            model: {
              value: shelter.freshWaterAmount,
              callback: function callback($$v) {
                _vm.$set(shelter, "freshWaterAmount", $$v);
              },
              expression: "shelter.freshWaterAmount"
            }
          }), _c('div', {
            staticClass: "input-group-prepend"
          }, [_c('span', {
            staticClass: "input-group-text"
          }, [_vm._v("Liter")])])], 1) : _vm._e()])] : _vm._e()], 2)]), _c('tr', [_c('td', {
            attrs: {
              "width": "50%"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Drop Anchor(Bouy) (Engine Off)",
              "label-for": "shelter.dropAnchor"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "datetime-local",
              "value": "2019-12-19T13:45:00"
            },
            model: {
              value: shelter.dropAnchor,
              callback: function callback($$v) {
                _vm.$set(shelter, "dropAnchor", $$v);
              },
              expression: "shelter.dropAnchor"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show")])], 1)], 1), _c('td', {
            attrs: {
              "width": "50%"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Anchor Up / Start Engine",
              "label-for": "shelter.anchorUp"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "datetime-local",
              "value": "2019-12-19T13:45:00"
            },
            model: {
              value: shelter.anchorUp,
              callback: function callback($$v) {
                _vm.$set(shelter, "anchorUp", $$v);
              },
              expression: "shelter.anchorUp"
            }
          }), _c('small', {
            staticStyle: {
              "float": "right"
            }
          }, [_vm._v("click "), _c('i', {
            staticClass: "fa fa-calendar"
          }), _vm._v(" to show")])], 1)], 1)])];
        })] : [_c('tr', [_c('td', [_c('b-form-group', {
          attrs: {
            "label": "Drop Anchor(Bouy) (Engine Off)",
            "label-for": "formStep3.voyageObstacles.engineOff"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep4.arrivalPort.voyageObstacles.engineOff,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4.arrivalPort.voyageObstacles, "engineOff", $$v);
            },
            expression: "formStep4.arrivalPort.voyageObstacles.engineOff"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('td', [_c('b-form-group', {
          attrs: {
            "label": "Anchor Up / Start Engine",
            "label-for": "formStep3.voyageObstacles.startEngine"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep4.arrivalPort.voyageObstacles.startEngine,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4.arrivalPort.voyageObstacles, "startEngine", $$v);
            },
            expression: "formStep4.arrivalPort.voyageObstacles.startEngine"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1)]), _c('tr', [_c('td', {
          attrs: {
            "colspan": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Input Reason of Voyage Obstacles",
            "label-for": "formStep3.voyageObstacles.reason"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "formStep2.remark",
            "rows": "1"
          },
          model: {
            value: _vm.formStep4.arrivalPort.voyageObstacles.reason,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4.arrivalPort.voyageObstacles, "reason", $$v);
            },
            expression: "formStep4.arrivalPort.voyageObstacles.reason"
          }
        })], 1)], 1)])]], 2)]) : _vm._e()]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-dark table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Arrival / Drop Anchor(Bouy) / Engine Off")]), _c('th', [_vm._v("ROB Fuel Oil")])])]), _c('tbody', [_c('tr', [_c('td', {
          attrs: {
            "width": "50%"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "",
            "label-for": "formStep4.arrivalPort.arrivalDropAnchor"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "datetime-local",
            "value": "2019-12-19T13:45:00"
          },
          model: {
            value: _vm.formStep4.arrivalPort.arrivalDropAnchor,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4.arrivalPort, "arrivalDropAnchor", $$v);
            },
            expression: "formStep4.arrivalPort.arrivalDropAnchor"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('td', {
          attrs: {
            "width": "50%"
          }
        }, [_vm.visibleFuelOilAmountStep4 === true ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "formStep4.arrivalPort.ROBFuelOil",
            "type": "number",
            "value": "2356"
          },
          on: {
            "blur": _vm.onBlurNumberROBStep4
          },
          model: {
            value: _vm.formStep4.arrivalPort.ROBFuelOil,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4.arrivalPort, "ROBFuelOil", $$v);
            },
            expression: "formStep4.arrivalPort.ROBFuelOil"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Liter")])])], 1) : _vm._e(), _vm.visibleFuelOilAmountStep4 === false ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "formStep4.arrivalPort.ROBFuelOil",
            "type": "text",
            "value": "2356"
          },
          on: {
            "focus": _vm.onFocusTextROBStep4
          },
          model: {
            value: _vm.formStep4.arrivalPort.ROBFuelOil,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep4.arrivalPort, "ROBFuelOil", $$v);
            },
            expression: "formStep4.arrivalPort.ROBFuelOil"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Liter")])])], 1) : _vm._e()])])])])])], 1)];
      },
      proxy: true
    }])
  }), _c('small', [_vm._v("field with * is required!")])], 1), _c('tab-content', {
    attrs: {
      "title": "Final Review",
      "icon": "ri-road-map-line"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Voyage Parameter")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-light table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Port")]), _c('th', [_vm._v("Type")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "iq-bg-primary mb-1 mr-1"
        }, [_vm._v("Sangatta")]), _c('td', {
          staticClass: "iq-bg-primary mb-1 mr-1"
        }, [_vm._v("Laden Sea")])])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-light table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "30%"
          }
        }, [_vm._v("Distance")]), _c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Parameter Average Speed")]), _c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Parameter Liter per Hour")])])]), _c('tbody', [_c('tr', [_c('td', [_vm.visibleDistance === true ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "formStep5.distance",
            "type": "number",
            "value": "2356"
          },
          on: {
            "blur": _vm.onBlurNumberDistance
          },
          model: {
            value: _vm.formStep5.distance,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep5, "distance", $$v);
            },
            expression: "formStep5.distance"
          }
        })], 1) : _vm._e(), _vm.visibleDistance === false ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "formStep5.distance",
            "type": "text",
            "value": "2356"
          },
          on: {
            "focus": _vm.onFocusTextDistance
          },
          model: {
            value: _vm.formStep5.distance,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep5, "distance", $$v);
            },
            expression: "formStep5.distance"
          }
        })], 1) : _vm._e()]), _c('td', [_vm.visibleParameterAverageSpeed === true ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "formStep5.parameterAverageSpeed",
            "type": "number",
            "value": "2356"
          },
          on: {
            "blur": _vm.onBlurNumberParameterAverageSpeed
          },
          model: {
            value: _vm.formStep5.parameterAverageSpeed,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep5, "parameterAverageSpeed", $$v);
            },
            expression: "formStep5.parameterAverageSpeed"
          }
        })], 1) : _vm._e(), _vm.visibleParameterAverageSpeed === false ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "formStep5.parameterAverageSpeed",
            "type": "text",
            "value": "2356"
          },
          on: {
            "focus": _vm.onFocusTextParameterAverageSpeed
          },
          model: {
            value: _vm.formStep5.parameterAverageSpeed,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep5, "parameterAverageSpeed", $$v);
            },
            expression: "formStep5.parameterAverageSpeed"
          }
        })], 1) : _vm._e()]), _c('td', [_vm.visibleParameterLiterPerHour === true ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "formStep5.parameterLiterPerHour",
            "type": "number",
            "value": "2356"
          },
          on: {
            "blur": _vm.onBlurNumberParameterLiterPerHour
          },
          model: {
            value: _vm.formStep5.parameterLiterPerHour,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep5, "parameterLiterPerHour", $$v);
            },
            expression: "formStep5.parameterLiterPerHour"
          }
        })], 1) : _vm._e(), _vm.visibleParameterLiterPerHour === false ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "formStep5.parameterLiterPerHour",
            "type": "text",
            "value": "2356"
          },
          on: {
            "focus": _vm.onFocusTextParameterLiterPerHour
          },
          model: {
            value: _vm.formStep5.parameterLiterPerHour,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep5, "parameterLiterPerHour", $$v);
            },
            expression: "formStep5.parameterLiterPerHour"
          }
        })], 1) : _vm._e()])])])])])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Parameter AE, ME Premi")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-light table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "30%"
          }
        }, [_vm._v("Parameter Auxiliary Engine")]), _c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Parameter Maneuvering, Idle, Running Free")]), _c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Deduction")])])]), _c('tbody', [_c('tr', [_c('td', [_vm.visibleParameterAE === true ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "formStep5.parameterAE",
            "type": "number",
            "value": "2356"
          },
          on: {
            "blur": _vm.onBlurNumberParameterAE
          },
          model: {
            value: _vm.formStep5.parameterAE,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep5, "parameterAE", $$v);
            },
            expression: "formStep5.parameterAE"
          }
        })], 1) : _vm._e(), _vm.visibleParameterAE === false ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "formStep5.parameterAE",
            "type": "text",
            "value": "2356"
          },
          on: {
            "focus": _vm.onFocusTextParameterAE
          },
          model: {
            value: _vm.formStep5.parameterAE,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep5, "parameterAE", $$v);
            },
            expression: "formStep5.parameterAE"
          }
        })], 1) : _vm._e()]), _c('td', [_vm.visibleParameterManuvering === true ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "formStep5.parameterManuvering",
            "type": "number",
            "value": "2356"
          },
          on: {
            "blur": _vm.onBlurNumberParameterManuvering
          },
          model: {
            value: _vm.formStep5.parameterManuvering,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep5, "parameterManuvering", $$v);
            },
            expression: "formStep5.parameterManuvering"
          }
        })], 1) : _vm._e(), _vm.visibleParameterManuvering === false ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "formStep5.parameterManuvering",
            "type": "text",
            "value": "2356"
          },
          on: {
            "focus": _vm.onFocusTextParameterManuvering
          },
          model: {
            value: _vm.formStep5.parameterManuvering,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep5, "parameterManuvering", $$v);
            },
            expression: "formStep5.parameterManuvering"
          }
        })], 1) : _vm._e()]), _c('td', [_vm.visibleDeduction === true ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "formStep5.deduction",
            "type": "number",
            "value": "2356"
          },
          on: {
            "blur": _vm.onBlurNumberDeduction
          },
          model: {
            value: _vm.formStep5.deduction,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep5, "deduction", $$v);
            },
            expression: "formStep5.deduction"
          }
        })], 1) : _vm._e(), _vm.visibleDeduction === false ? _c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "id": "formStep5.deduction",
            "type": "text",
            "value": "2356"
          },
          on: {
            "focus": _vm.onFocusTextDeduction
          },
          model: {
            value: _vm.formStep5.deduction,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep5, "deduction", $$v);
            },
            expression: "formStep5.deduction"
          }
        })], 1) : _vm._e()])])])])])];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Remark")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form-group', {
          attrs: {
            "label": "Input Remark in This Voyage",
            "label-for": "formStep5.remark"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "formStep5.remark",
            "rows": "1"
          },
          model: {
            value: _vm.formStep5.remark,
            callback: function callback($$v) {
              _vm.$set(_vm.formStep5, "remark", $$v);
            },
            expression: "formStep5.remark"
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _c('small', [_vm._v("field with * is required!")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }