<template>
<b-container fluid>
<!-- Top Section !-->
    <b-row>
        <b-col lg="12">
            <iq-card>
                <template v-slot:headerTitle>
                    <h4 class="card-title text-primary"><strong>SERVICE & REPAIR</strong></h4>
                </template>
            </iq-card>
        </b-col>
    </b-row>
<!-- End Top Section !-->
<form>
    <b-row>
        <b-col lg="12">
          <iq-card body-class="p-0">
            <template v-slot:body>
              <div class="iq-edit-list">
                <tab-nav :pills="true" class="iq-edit-profile d-flex">
                  <tab-nav-items class="col-md-4 p-0" :active="true" href="#add_service_repair" title="CREATE SERVICE REPAIR" />
                  <tab-nav-items class="col-md-4 p-0" :active="false" href="#request_service_repair" title="SERVICE REPAIR PROGRESS" />
                  <tab-nav-items class="col-md-4 p-0" :active="false" href="#finish_service_repair" title="FINISH SERVICE REPAIR" />
                </tab-nav>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="12">
          <div class="iq-edit-list-data">
            <div id="pills-tabContent-2" class="tab-content">
              <tab-content-item :active="true" id="add_service_repair" >
                <b-alert :show="true" variant=" " class="text-white bg-warning">
                  <div class="iq-alert-icon">
                      <i class="ri-alert-line"></i>
                  </div>
                  <div class="iq-alert-text">I'm Sorry <b>The Fleet</b> Has Been Add Service Repair Please Go to Tab <b>Service Repair Progress</b></div>
                </b-alert>
                <b-row>
                  <b-col sm="3">
                    <iq-card>
                        <template v-slot:headerTitle>
                            <h5 class="card-title text-primary"><strong>CREATE SERVICE REPAIR - TB ETI 101</strong></h5>
                        </template>
                        <template v-slot:body>
                            <b-toast variant="success" visible static no-auto-hide no-close-button toast-class="bg-success" body-class="text-white"  header-class="bg-success text-white">
                            <template v-slot:toast-title>
                                <strong class="mr-auto text-white"><i class="fa fa-bullhorn"></i> Notification</strong>
                                <small class="text-white">11 mins ago</small>
                                <button type="button" class="ml-2 mb-1 close text-white" data-dismiss="toast" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </template>
                            You've Succed Add Service Repair
                            </b-toast>

                            <b-toast variant="danger" visible static no-auto-hide no-close-button toast-class="bg-danger" body-class="text-white"  header-class="bg-danger text-white">
                            <template v-slot:toast-title>
                                <strong class="mr-auto text-white"><i class="fa fa-bullhorn"></i> Notification</strong>
                                <small class="text-white">11 mins ago</small>
                                <button type="button" class="ml-2 mb-1 close text-white" data-dismiss="toast" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </template>
                            You've Failed Add Service Repair
                            </b-toast>
                            <b-form-group label="Accident Date" label-for="accident_date">
                                <b-form-input type="date" id="accident_date"></b-form-input>
                                <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                            </b-form-group>
                            <b-form-group label="Location *" label-for="location">
                                <b-form-input type="text"></b-form-input>
                            </b-form-group>
                            <b-form-group label="PIC *" label-for="pic">
                                <b-form-select plain v-model="selectedPIC" :options="PicName" size="lg">
                                    <template v-slot:first>
                                    <b-form-select-option :value="null">Select PIC</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-form-group>
                        </template>
                    </iq-card>
                  </b-col>
                  <b-col sm="9">
                      <iq-card>
                      <template v-slot:headerTitle>
                          <h5 class="card-title text-primary"><strong>ADD TASK</strong></h5>
                      </template>
                      <template v-slot:body>
                          <b-row>
                              <b-col md="6">
                                  <b-form>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Clasification *" label-for="clasification">
                                              <b-form-select plain v-model="selectedClasification" :options="clasification" size="lg">
                                                  <template v-slot:first>
                                                  <b-form-select-option :value="null">Select Clasification</b-form-select-option>
                                                  </template>
                                              </b-form-select>
                                          </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Category *" label-for="category">
                                            <b-form-select plain v-model="selectedCategory" :options="category" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Category</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Problem Case *" label-for="problem_case">
                                              <b-form-select plain v-model="selectedProblemCase" :options="problemCase" size="lg">
                                                  <template v-slot:first>
                                                  <b-form-select-option :value="null">Select Problem Case</b-form-select-option>
                                                  </template>
                                              </b-form-select>
                                          </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Kind of Task *" label-for="kind_of_task">
                                            <b-form-select plain v-model="selectedKindTask" :options="kindTask" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Kind of Task</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Start Date *" label-for="start_date">
                                          <b-form-input type="date" id="start_date"></b-form-input>
                                          <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="End Date *" label-for="end_date">
                                          <b-form-input type="date" id="end_date"></b-form-input>
                                          <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                  </b-form>
                              </b-col>
                              <b-col md="6">
                                  <b-form-group label="Task Name" label-for="task_name">
                                      <b-form-input type="text"></b-form-input>
                                  </b-form-group>
                                  <b-form-group label="Description" label-for="description">
                                      <b-form-textarea id="description" rows="2"></b-form-textarea>
                                  </b-form-group>
                                  <b-form-group label="Task File *" label-for="attactment_file">
                                      <b-form-file plain id="attactment_file"></b-form-file>
                                      <b-card-text>
                                        <small class="text-muted">
                                          File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                          File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                        </small>
                                      </b-card-text>
                                  </b-form-group>
                              </b-col>
                              <b-col md="12" class="mt-3">
                                  <hr>
                              </b-col>
                              <b-col md="12" class="mt-3">
                                  <b-card-text><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
                                  <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Save</b-button>
                                  <b-button type="submit" variant="none" class="iq-bg-danger ml-3"><i class="fa fa-close"></i> Reset Form</b-button>
                              </b-col>
                          </b-row>
                      </template>
                      </iq-card>
                  </b-col>
                </b-row>
              </tab-content-item>
              <tab-content-item :active="false" id="request_service_repair">
                <b-row>
                  <b-col md="4" lg="4">
                    <iq-card>
                        <template v-slot:body>
                          <h5 class="card-title text-primary"><strong>TB ETI 101</strong></h5>
                          <div class="about-info m-0 p-0">
                            <div class="row">
                              <div class="col-6"><strong>Accident Location</strong></div>
                              <div class="col-6">Asam - Asam</div>
                              <div class="col-6"><strong>Accident Date</strong></div>
                              <div class="col-6">22 May 2021</div>
                              <div class="col-6"><strong>PIC</strong></div>
                              <div class="col-6">Nurul Akbar</div>
                            </div>
                          </div>
                        </template>
                    </iq-card>
                  </b-col>
                  <b-col md="4" lg="4">
                    <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                      <template v-slot:body>
                        <div class="iq-info-box d-flex align-items-center p-3">
                          <div class="info-image mr-3">
                            <img src="../../assets/images/page-img/30.png" class="img-fluid" alt="image-box">
                          </div>
                          <div class="info-text">
                            <h3>3</h3>
                            <span>Total Current Task</span>
                          </div>
                        </div>
                      </template>
                    </iq-card>
                  </b-col>
                  <b-col md="4" lg="4">
                    <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                      <template v-slot:body>
                        <div class="iq-info-box d-flex align-items-center p-3">
                          <div class="info-image mr-3">
                            <img src="../../assets/images/page-img/31.png" class="img-fluid" alt="image-box">
                          </div>
                          <div class="info-text">
                            <h3>Rp. 15.570.321</h3>
                            <span>Total Cost</span>
                          </div>
                        </div>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                      <iq-card>
                      <template v-slot:headerTitle>
                          <h5 class="card-title text-primary"><strong>LIST TASK</strong></h5>
                      </template>
                      <template v-slot:body>
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th class="text-center" width="5%">No</th>
                              <th class="text-center" width="20%">Name&nbsp;Task</th>
                              <th class="text-center" width="10%">Category</th>
                              <th class="text-center" width="10%">Clasification</th>
                              <th class="text-center" width="10%">Start Date</th>
                              <th class="text-center" width="10%">End Date</th>
                              <th class="text-center" width="25%">Description</th>
                              <th class="text-center" width="5%">Status</th>
                              <th class="text-center" width="5%">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="text-center">1</td>
                              <td>Pekerjaan Pebrbaikan Mesin Kapal</td>
                              <td class="text-center">Mechanical</td>
                              <td class="text-center"><b-badge variant="success d-block">Medium</b-badge></td>
                              <td class="text-center">15/05/2021</td>
                              <td class="text-center">12/06/2021</td>
                              <td class="text-left">
                                Pada saat kapal FAW to A2 berangkat dari dock maribaya selang 1 jam perjalanan ME kiri mengalami trouble dengan ditemukan suara kasar sesuai hasil pengecekan terjadi kerusakan pada CLY NO 3 (L) pushrod bengkok dan piston pecah. oil
                              </td>
                              <td class="text-center"><b-badge variant="success d-block">Pending</b-badge></td>
                              <td class="text-center">
                                <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                  <template v-slot:button-content>
                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                      <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                    </span>
                                  </template>
                                  <b-dropdown-item href="#" v-b-modal.modal-4><i class="fa fa-check mr-2"></i>Approve</b-dropdown-item>
                                  <b-dropdown-item href="#"><i class="fa fa-remove mr-2"></i>Reject</b-dropdown-item>
                                  <b-dropdown-item href="#" v-b-modal.modal-5><i class="fa fa-pencil-square mr-2"></i>Update Data</b-dropdown-item>
                                  <b-dropdown-item href="#" v-b-modal.modal-2><i class="fa fa-files-o mr-2"></i>Task File</b-dropdown-item>
                                </b-dropdown>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-center">2</td>
                              <td>Pekerjaan Pengelasan Lambung Sisi Kanan</td>
                              <td class="text-center">Welding</td>
                              <td class="text-center"><b-badge variant="success d-block">Medium</b-badge></td>
                              <td class="text-center">21/05/2021</td>
                              <td class="text-center">28/05/2021</td>
                              <td class="text-left">
                                Pengelasan sisi lambung kapal kanan akibat keretakan
                              </td>
                              <td class="text-center"><b-badge variant="info d-block">Progress</b-badge></td>
                              <td class="text-center">
                                <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                  <template v-slot:button-content>
                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                      <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                    </span>
                                  </template>
                                  <b-dropdown-item href="#" v-b-modal.modal-1><i class="fa fa-list-ol mr-2"></i>Activity</b-dropdown-item>
                                  <b-dropdown-item href="#" v-b-modal.modal-2><i class="fa fa-files-o mr-2"></i>Task File</b-dropdown-item>
                                  <b-dropdown-item href="#" v-b-modal.modal-6><i class="fa fa-files-o mr-2"></i>Sub Contractor File</b-dropdown-item>
                                  <b-dropdown-item href="#" v-b-modal.modal-7><i class="fa fa-files-o mr-2"></i>Internal Power File</b-dropdown-item>
                                </b-dropdown>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-center">3</td>
                              <td>Perbaikan Deck Bagian Dalam Kapal</td>
                              <td class="text-center">General Service</td>
                              <td class="text-center"><b-badge variant="info d-block">Minor</b-badge></td>
                              <td class="text-center">21/05/2021</td>
                              <td class="text-center">28/05/2021</td>
                              <td class="text-left">
                                Pengelasan sisi lambung kapal kanan akibat keretakan
                              </td>
                              <td class="text-center"><b-badge variant="warning d-block">Hold</b-badge></td>
                              <td class="text-center">
                                <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                  <template v-slot:button-content>
                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                      <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                    </span>
                                  </template>
                                  <b-dropdown-item href="#" v-b-modal.modal-5><i class="fa fa-pencil-square mr-2"></i>Update For Continue</b-dropdown-item>
                                </b-dropdown>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-center">4</td>
                              <td>Pekerjaan Perbaikan AC Kapal</td>
                              <td class="text-center">Electrical</td>
                              <td class="text-center"><b-badge variant="danger d-block">Major</b-badge></td>
                              <td class="text-center">19/05/2021</td>
                              <td class="text-center">21/06/2021</td>
                              <td class="text-left">
                                AC Pada bagian deck mati, dikarenakan korsleting pada bagian inverter
                              </td>
                              <td class="text-center"><b-badge variant="danger d-block">Reject</b-badge></td>
                              <td class="text-center">
                                <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                  <template v-slot:button-content>
                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                      <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                    </span>
                                  </template>
                                  <b-dropdown-item href="#"><i class="fa fa-check-square mr-2"></i>Activation</b-dropdown-item>
                                  <b-dropdown-item href="#" v-b-modal.modal-2><i class="fa fa-files-o mr-2"></i>Task File</b-dropdown-item>
                                </b-dropdown>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </template>
                      </iq-card>
                  </b-col>
                </b-row>
              </tab-content-item>
              <tab-content-item :active="false" id="finish_service_repair">
                <b-row>                  
                  <b-col md="4" lg="4">
                    <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                      <template v-slot:body>
                        <div class="iq-info-box d-flex align-items-center p-3">
                          <div class="info-image mr-3">
                            <img src="../../assets/images/page-img/tb.png" class="img-fluid" alt="image-box">
                          </div>
                          <div class="info-text">
                            <h3>5</h3>
                            <span>Total Service Repair</span>
                          </div>
                        </div>
                      </template>
                    </iq-card>
                  </b-col>
                  <b-col md="4" lg="4">
                    <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                      <template v-slot:body>
                        <div class="iq-info-box d-flex align-items-center p-3">
                          <div class="info-image mr-3">
                            <img src="../../assets/images/page-img/30.png" class="img-fluid" alt="image-box">
                          </div>
                          <div class="info-text">
                            <h5>25 TASK</h5>
                            <span>On Time : <b-badge variant="info">25 Task</b-badge></span><br>
                            <span>Delay : <b-badge variant="info">5 Task</b-badge></span>
                          </div>
                        </div>
                      </template>
                    </iq-card>
                  </b-col>
                  <b-col md="4" lg="4">
                    <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                      <template v-slot:body>
                        <div class="iq-info-box d-flex align-items-center p-3">
                          <div class="info-image mr-3">
                            <img src="../../assets/images/page-img/31.png" class="img-fluid" alt="image-box">
                          </div>
                          <div class="info-text">
                            <h3>Rp. 275.011.341</h3>
                            <span>Total Cost</span>
                          </div>
                        </div>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12">
                      <iq-card>
                      <template v-slot:headerTitle>
                          <h5 class="card-title text-primary"><strong>HISTORY SERVICE REPAIR</strong></h5>
                      </template>
                      <template v-slot:body>
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th class="text-center">No</th>
                              <th class="text-center">Location</th>
                              <th class="text-center">Accident Date</th>
                              <th class="text-center">PIC</th>
                              <th class="text-center">Total Task</th>
                              <th class="text-center">Status</th>
                              <th class="text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="text-center">1</td>
                              <td>Asam-Asam</td>
                              <td class="text-center">2021-05-15 15:47</td>
                              <td class="text-center">Agung Tri Laksono</td>
                              <td class="text-center">5/8</td>
                              <td class="text-center"><b-badge variant="info d-block">Progress</b-badge></td>
                              <td class="text-center">
                                <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                  <template v-slot:button-content>
                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                      <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                    </span>
                                  </template>
                                  <b-dropdown-item href="#" v-b-modal.modal-3><i class="fa fa-list-ol mr-2"></i>Task List</b-dropdown-item>
                                </b-dropdown>
                              </td>
                            </tr>
                            <tr>
                              <td class="text-center">2</td>
                              <td>Tanjung Bara</td>
                              <td class="text-center">2020-12-07 04:56</td>
                              <td class="text-center">Nurul Akbar</td>
                              <td class="text-center">7/7</td>
                              <td class="text-center"><b-badge variant="success d-block">Finish</b-badge></td>
                              <td class="text-center">
                                <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                  <template v-slot:button-content>
                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                      <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                    </span>
                                  </template>
                                  <b-dropdown-item href="#" v-b-modal.modal-3><i class="fa fa-list-ol mr-2"></i>Task List</b-dropdown-item>
                                </b-dropdown>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </template>
                      </iq-card>
                  </b-col>
                </b-row>
              </tab-content-item>
            </div>
          </div>
        </b-col>
    </b-row>
</form>
<b-modal id="modal-1" size="xl" title="Daily Activity" ok-title="Save Changes" cancel-title="Close">
  <b-row>
    <b-col lg="6">
      <div class="about-info m-0 p-0">
        <div class="row">
          <div class="col-4"><strong>Task Name:</strong></div>
          <div class="col-8">Pekerjaan Pengelasan Lambung Sisi Kanan</div>
          <div class="col-4"><strong>Task Clasification:</strong></div>
          <div class="col-8">Medium (3-7 Days)</div>
          <div class="col-4"><strong>Task Category:</strong></div>
          <div class="col-8">Welding</div>
          <div class="col-4"><strong>Problem Case:</strong></div>
          <div class="col-8">Environment</div>
          <div class="col-4"><strong>Kind Of Task:</strong></div>
          <div class="col-8">Repair</div>
          <div class="col-4"><strong>Start Date:</strong></div>
          <div class="col-8">21/05/2021</div>
          <div class="col-4"><strong>End Date:</strong></div>
          <div class="col-8">28/05/2021</div>
        </div>
      </div>
      <hr>
      <b-form-group label="Activity Date *" label-for="activity_date">
        <b-form-input type="date" id="activity_date"></b-form-input>
        <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
      </b-form-group>
      <b-form-group label="Description" label-for="description">
          <b-form-textarea id="description" rows="2"></b-form-textarea>
      </b-form-group>
      <b-form-group label="Activity File *" label-for="attactment_file">
          <b-form-file plain id="attactment_file"></b-form-file>
          <b-card-text>
            <small class="text-muted">
              File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
              File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
            </small>
          </b-card-text>
      </b-form-group>
    </b-col>
    <b-col lg="6">
      <ul class="iq-timeline m-0 p-0" style="height:550px">
        <li>
          <div class="timeline-dots-fill"></div>
          <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> 28-05-2021 15:51</h6>
          <div class="d-inline-block w-100">
            <p>Finishing Pengecatan</p>
            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/2.jpg" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
            </ul>
          </div>
        </li>
        <li>
          <div class="timeline-dots-fill bg-info"></div>
          <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> 27-05-2021 21:00</h6>
          <div class="d-inline-block w-100">
            <p>Poles dan Perataan Bagian Pengelasan</p>
          </div>
        </li>
        <li>
          <div class="timeline-dots-fill bg-info"></div>
          <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> 26-05-2021 04:15</h6>
          <div class="d-inline-block w-100">
            <p>Pengelasan sisi kanan</p>
          </div>
        </li>
        <li>
          <div class="timeline-dots-fill bg-info"></div>
          <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> 25-05-2021 13:21</h6>
          <div class="d-inline-block w-100">
            <p>Peneglasan bagian dalam yang retak</p>
            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/7.jpg" alt="gallary-image" class="img-fluid"></a></li>
            </ul>
          </div>
        </li>
        <li>
          <div class="timeline-dots-fill bg-info"></div>
          <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> 24-05-2021 13:21</h6>
          <div class="d-inline-block w-100">
            <p>Pemotongan bagian lambung kapal yang retak</p>
          </div>
        </li>
        <li>
          <div class="timeline-dots-fill bg-info"></div>
          <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> 23-05-2021 07:30</h6>
          <div class="d-inline-block w-100">
            <p>Pengukuran pada bagian lambung yang retak</p>
          </div>
        </li>
        <li>
          <div class="timeline-dots-fill bg-info"></div>
          <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> 22-05-2021 23:25</h6>
          <div class="d-inline-block w-100">
            <p>Inspeksi kerusakan pada kapal tahap 2</p>
            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/5.jpg" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
            </ul>
          </div>
        </li>
        <li>
          <div class="timeline-dots-fill bg-info"></div>
          <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> 21-05-2021 18:16</h6>
          <div class="d-inline-block w-100">
            <p>Inspeksi kerusakan pada kapal tahap 1</p>
            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/2.jpg" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
            </ul>
          </div>
        </li>
      </ul>
    </b-col>
  </b-row>
</b-modal>
<b-modal id="modal-2" size="l" title="Task File" ok-title="Save Changes" cancel-title="Close">
  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
    <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
    <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
    <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
    <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
    <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
    <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
    <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
    <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
  </ul>
</b-modal>
<b-modal id="modal-3" size="xl" title="List Task" ok-title="Save Changes" cancel-title="Close">
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="text-center" width="5%">No</th>
        <th class="text-center" width="20%">Name&nbsp;Task</th>
        <th class="text-center" width="10%">Category</th>
        <th class="text-center" width="10%">Clasification</th>
        <th class="text-center" width="10%">Start Date</th>
        <th class="text-center" width="10%">End Date</th>
        <th class="text-center" width="25%">Description</th>
        <th class="text-center" width="5%">Status</th>
        <th class="text-center" width="5%">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-center">1</td>
        <td>Pekerjaan Pengelasan Lambung Sisi Kanan</td>
        <td class="text-center">Welding</td>
        <td class="text-center">Medium</td>
        <td class="text-center">21/05/2021</td>
        <td class="text-center">28/05/2021</td>
        <td class="text-left">
          Pengelasan sisi lambung kapal kanan akibat keretakan
        </td>
        <td class="text-center"><b-badge variant="info d-block">Progress</b-badge></td>
        <td class="text-center">
          <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
              </span>
            </template>
            <b-dropdown-item href="#" v-b-modal.modal-8><i class="fa fa-list-ol mr-2"></i>Activity</b-dropdown-item>
            <b-dropdown-item href="#" v-b-modal.modal-2><i class="fa fa-files-o mr-2"></i>Task File</b-dropdown-item>
            <b-dropdown-item href="#" v-b-modal.modal-6><i class="fa fa-files-o mr-2"></i>Sub Contractor File</b-dropdown-item>
            <b-dropdown-item href="#" v-b-modal.modal-7><i class="fa fa-files-o mr-2"></i>Internal Power File</b-dropdown-item>
          </b-dropdown>
        </td>
      </tr>
      <tr>
        <td class="text-center">2</td>
        <td>Pekerjaan Perbaikan AC Kapal</td>
        <td class="text-center">Electrical</td>
        <td class="text-center">Minor</td>
        <td class="text-center">19/05/2021</td>
        <td class="text-center">21/05/2021</td>
        <td class="text-left">
          AC Pada bagian deck mati, dikarenakan korsleting pada bagian inverter
        </td>
        <td class="text-center"><b-badge variant="danger d-block">Reject</b-badge></td>
        <td class="text-center">
          <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
              </span>
            </template>
            <b-dropdown-item href="#" v-b-modal.modal-8><i class="fa fa-list-ol mr-2"></i>Activity</b-dropdown-item>
            <b-dropdown-item href="#" v-b-modal.modal-2><i class="fa fa-files-o mr-2"></i>Task File</b-dropdown-item>
          </b-dropdown>
        </td>
      </tr>
      <tr>
        <td class="text-center">3</td>
        <td>Pekerjaan Pebrbaikan Mesin Kapal</td>
        <td class="text-center">Mechanical</td>
        <td class="text-center">Medium</td>
        <td class="text-center">15/05/2021</td>
        <td class="text-center">12/06/2021</td>
        <td class="text-left">
          Pada saat kapal FAW to A2 berangkat dari dock maribaya selang 1 jam perjalanan ME kiri mengalami trouble dengan ditemukan suara kasar sesuai hasil pengecekan terjadi kerusakan pada CLY NO 3 (L) pushrod bengkok dan piston pecah. oil
        </td>
        <td class="text-center"><b-badge variant="success d-block">Finish</b-badge></td>
        <td class="text-center">
          <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
              </span>
            </template>
            <b-dropdown-item href="#" v-b-modal.modal-1><i class="fa fa-list-ol mr-2"></i>Activity</b-dropdown-item>
            <b-dropdown-item href="#" v-b-modal.modal-2><i class="fa fa-files-o mr-2"></i>Task File</b-dropdown-item>
            <b-dropdown-item href="#" v-b-modal.modal-6><i class="fa fa-files-o mr-2"></i>Sub Contractor File</b-dropdown-item>
            <b-dropdown-item href="#" v-b-modal.modal-7><i class="fa fa-files-o mr-2"></i>Internal Power File</b-dropdown-item>
          </b-dropdown>
        </td>
      </tr>
    </tbody>
  </table>
</b-modal>
<b-modal id="modal-4" size="xl" title="Approve Task" ok-title="Save Changes" cancel-title="Close">
  <b-row>
    <b-col lg="6">
      <div class="about-info m-0 p-0">
        <div class="row">
          <div class="col-4"><strong>Task Name</strong></div>
          <div class="col-8">Pekerjaan Pengelasan Lambung Sisi Kanan</div>
          <div class="col-4"><strong>Task Clasification</strong></div>
          <div class="col-8">Medium (3-7 Days)</div>
          <div class="col-4"><strong>Task Category</strong></div>
          <div class="col-8">Welding</div>
          <div class="col-4"><strong>Problem Case</strong></div>
          <div class="col-8">Environment</div>
          <div class="col-4"><strong>Kind Of Task</strong></div>
          <div class="col-8">Repair</div>
          <div class="col-4"><strong>Start Date</strong></div>
          <div class="col-8">21/05/2021</div>
          <div class="col-4"><strong>End Date</strong></div>
          <div class="col-8">28/05/2021</div>
        </div>
      </div>
      <hr>
      <h5 class="card-title text-primary">Task File File</h5>
      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
        <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
        <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
        <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
        <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
        <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
        <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
        <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
        <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
      </ul>
    </b-col>
    <b-col lg="6">
      <b-form>        
        <b-form-group label="Yes = Sub Contractor ? No = Internal Man Power *" label-for="sub_contcator">
          <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
            <div class="custom-switch-inner">
              <input type="checkbox" class="custom-control-input" :class="'bg-'+subContractor.class" :id="'subContractor'" v-model="subContractor.checked">
              <label class="custom-control-label" :for="'subContractor'" :data-on-label="subContractor.activeLabel" :data-off-label="subContractor.inactiveLabel">
              </label>
            </div>
          </div>
        </b-form-group>
        <template v-if="subContractor.checked == true">
          <b-form-row>
            <b-col>
              <b-form-group label="Company Name *" label-for="company_name">
                <b-form-input type="text"></b-form-input>      
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Execute Engineer *" label-for="execute_engineer">
                <b-form-input type="text" value="Sub Contractor"></b-form-input>      
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-group label="Negotiation ? (Yes/No) *" label-for="sub_contcator">
            <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
              <div class="custom-switch-inner">
                <input type="checkbox" class="custom-control-input" :class="'bg-'+negotiation.class" :id="'negotiation'" v-model="negotiation.checked">
                <label class="custom-control-label" :for="'negotiation'" :data-on-label="negotiation.activeLabel" :data-off-label="negotiation.inactiveLabel">
                </label>
              </div>
            </div>
          </b-form-group>
          <b-form-row class="mb-3">
            <b-col>
              <label label-for="quotation">Quotation *</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Rp.</span>
                </div>
                <b-form-input type="number"></b-form-input>
              </div>
            </b-col>
            <template v-if="negotiation.checked == true">
            <b-col>
              <label label-for="negotiation">Negotiation *</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">Rp.</span>
                </div>
                <b-form-input type="number"></b-form-input>
              </div>
            </b-col>
            </template>
          </b-form-row>
          <b-form-group label="Sub Contractor File *" label-for="attactment_file">
              <b-form-file plain id="attactment_file"></b-form-file>
              <b-card-text>
                <small class="text-muted">
                  File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                  File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                </small>
              </b-card-text>
          </b-form-group>
        </template>
        <template v-if="subContractor.checked == false">
          <b-form-row>
            <b-col>
              <b-form-group label="Team *" label-for="team">
                <b-form-input type="text"></b-form-input>      
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Execute Engineer *" label-for="execute_engineer">
                <b-form-input type="text" value="Internal Man Power"></b-form-input>      
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-group label="Cost *" label-for="cost">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="inputGroupPrepend2">Rp.</span>
              </div>
              <b-form-input type="number"></b-form-input>
            </div>
          </b-form-group>
          <b-form-group label="Internal Man Power File *" label-for="attactment_file">
              <b-form-file plain id="attactment_file"></b-form-file>
              <b-card-text>
                <small class="text-muted">
                  File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                  File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                </small>
              </b-card-text>
          </b-form-group>
        </template>
        <b-form-group label="Description" label-for="description">
          <b-form-textarea id="description" rows="2"></b-form-textarea>
        </b-form-group>
        <b-card-text><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
      </b-form>
    </b-col>
  </b-row>
</b-modal>
<b-modal id="modal-5" size="xl" title="Update Data" ok-title="Save Changes" cancel-title="Close">
  <b-row>
      <b-col md="6">
        <b-form>
          <b-form-row>
            <b-col>
              <b-form-group label="Clasification *" label-for="clasification">
                    <b-form-select plain v-model="selectedClasification" :options="clasification" size="lg">
                        <template v-slot:first>
                        <b-form-select-option :value="null">Select Clasification</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Category *" label-for="category">
                  <b-form-select plain v-model="selectedCategory" :options="category" size="lg">
                    <template v-slot:first>
                      <b-form-select-option :value="null">Select Category</b-form-select-option>
                    </template>
                  </b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group label="Problem Case *" label-for="problem_case">
                    <b-form-select plain v-model="selectedProblemCase" :options="problemCase" size="lg">
                        <template v-slot:first>
                        <b-form-select-option :value="null">Select Problem Case</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Kind of Task *" label-for="kind_of_task">
                  <b-form-select plain v-model="selectedKindTask" :options="kindTask" size="lg">
                    <template v-slot:first>
                      <b-form-select-option :value="null">Select Kind of Task</b-form-select-option>
                    </template>
                  </b-form-select>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group label="Start Date *" label-for="start_date">
                <b-form-input type="date" id="start_date" value="2021-05-21"></b-form-input>
                <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="End Date *" label-for="end_date">
                <b-form-input type="date" id="end_date" value="2021-05-28"></b-form-input>
                <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form>
        <b-form-group label="Task File *" label-for="attactment_file">
          <b-form-file plain id="attactment_file"></b-form-file>
          <b-card-text>
            <small class="text-muted">
              File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
              File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
            </small>
          </b-card-text>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Description" label-for="description">
            <b-form-textarea id="description" rows="2" value="Pengelasan sisi lambung kapal kanan akibat keretakan"></b-form-textarea>
        </b-form-group>
        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
          <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
          <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
          <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
          <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
          <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
          <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
          <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
          <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
        </ul>
        <b-card-text><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>          
      </b-col>
  </b-row>
</b-modal>
<b-modal id="modal-6" size="l" title="Sub Contractor" ok-title="Save Changes" cancel-title="Close">
  <b-row>
    <b-col lg="12" class="iq-bg-primary">
      <div class="about-info m-0 p-3">
        <div class="row">
          <div class="col-6"><strong>Company Name</strong></div>
          <div class="col-6">PT. Tegal Shipyard Sejahtera</div>
          <div class="col-6"><strong>Execute Engineer</strong></div>
          <div class="col-6">Mr. Ir. Andri Adriansyah. S.T</div>
          <div class="col-6"><strong>Quotation</strong></div>
          <div class="col-6">Rp. 1.489.335</div>
          <div class="col-6"><strong>Quotation Nego</strong></div>
          <div class="col-6">Rp. 1.021.000</div>
          <div class="col-6"><strong>Description</strong></div>
          <div class="col-6">Perbaikan Sisi Lambung Kiri</div>
          <div class="col-6"><strong>File Sub Contractor</strong></div>
          <div class="col-6"><b-badge variant="info d-block"><i class="fa fa-download mr-2"></i>4 Files</b-badge></div>
        </div>
        <hr>
        <div class="row">
          <div class="col-6"><strong>Company Name</strong></div>
          <div class="col-6">PT. Tanjung Galangan Sejahtera</div>
          <div class="col-6"><strong>Execute Engineer</strong></div>
          <div class="col-6">Mr. Ahmad Asrori. S.T, M.T</div>
          <div class="col-6"><strong>Quotation</strong></div>
          <div class="col-6">Rp. 915.335</div>
          <div class="col-6"><strong>Quotation Nego</strong></div>
          <div class="col-6">Rp. 721.000</div>
          <div class="col-6"><strong>Description</strong></div>
          <div class="col-6">Perbaikan Sisi Lambung Kiri</div>
          <div class="col-6"><strong>File Sub Contractor</strong></div>
          <div class="col-6"><b-badge variant="info d-block"><i class="fa fa-download mr-2"></i>4 Files</b-badge></div>
        </div>
      </div>
    </b-col>
  </b-row>
</b-modal>
<b-modal id="modal-7" size="l" title="Internal Man Power" ok-title="Save Changes" cancel-title="Close">
  <b-row class="mb-3">
    <b-col lg="12" class="iq-bg-primary">
      <div class="about-info m-0 p-3">
        <div class="row">
          <div class="col-6"><strong>Company Name</strong></div>
          <div class="col-6">Internal Man Power</div>
          <div class="col-6"><strong>Execute Engineer</strong></div>
          <div class="col-6">Mr. Franz Mangatur</div>
          <div class="col-6"><strong>Team</strong></div>
          <div class="col-6">
            - Nurul Akbar<br>
            - Agung Tri Laksono<br>
            - Harry Setiawan
          </div>
          <div class="col-6"><strong>Cost</strong></div>
          <div class="col-6">Rp. 1.489.335</div>
          <div class="col-6"><strong>Description</strong></div>
          <div class="col-6">Perbaikan Sisi Lambung Kiri</div>
        </div>
      </div>
    </b-col>
  </b-row>
  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
    <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
    <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
    <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
    <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
    <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
    <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
    <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
    <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
  </ul>
</b-modal>
<b-modal id="modal-8" size="l" title="Daily Activity" ok-title="Export" cancel-title="Close">
  <b-row>
    <b-col lg="12">
      <ul class="iq-timeline m-0 p-0" style="height:550px">
        <li>
          <div class="timeline-dots-fill"></div>
          <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> 28-05-2021 15:51</h6>
          <div class="d-inline-block w-100">
            <p>Finishing Pengecatan</p>
            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/2.jpg" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
            </ul>
          </div>
        </li>
        <li>
          <div class="timeline-dots-fill bg-info"></div>
          <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> 27-05-2021 21:00</h6>
          <div class="d-inline-block w-100">
            <p>Poles dan Perataan Bagian Pengelasan</p>
          </div>
        </li>
        <li>
          <div class="timeline-dots-fill bg-info"></div>
          <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> 26-05-2021 04:15</h6>
          <div class="d-inline-block w-100">
            <p>Pengelasan sisi kanan</p>
          </div>
        </li>
        <li>
          <div class="timeline-dots-fill bg-info"></div>
          <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> 25-05-2021 13:21</h6>
          <div class="d-inline-block w-100">
            <p>Peneglasan bagian dalam yang retak</p>
            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/7.jpg" alt="gallary-image" class="img-fluid"></a></li>
            </ul>
          </div>
        </li>
        <li>
          <div class="timeline-dots-fill bg-info"></div>
          <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> 24-05-2021 13:21</h6>
          <div class="d-inline-block w-100">
            <p>Pemotongan bagian lambung kapal yang retak</p>
          </div>
        </li>
        <li>
          <div class="timeline-dots-fill bg-info"></div>
          <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> 23-05-2021 07:30</h6>
          <div class="d-inline-block w-100">
            <p>Pengukuran pada bagian lambung yang retak</p>
          </div>
        </li>
        <li>
          <div class="timeline-dots-fill bg-info"></div>
          <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> 22-05-2021 23:25</h6>
          <div class="d-inline-block w-100">
            <p>Inspeksi kerusakan pada kapal tahap 2</p>
            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/5.jpg" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
            </ul>
          </div>
        </li>
        <li>
          <div class="timeline-dots-fill bg-info"></div>
          <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> 21-05-2021 18:16</h6>
          <div class="d-inline-block w-100">
            <p>Inspeksi kerusakan pada kapal tahap 1</p>
            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/2.jpg" alt="gallary-image" class="img-fluid"></a></li>
              <li class="col-md-3 col-3 pb-3"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
            </ul>
          </div>
        </li>
      </ul>
    </b-col>
  </b-row>
</b-modal>
</b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'

export default {
  name: 'ProfileEdit',
  mounted () {
    xray.index()
  },
  data () {
    return {      
      selectedPIC: 1,
      PicName: [
        { value: '1', text: 'Agung Tri Laksono' },
        { value: '2', text: 'Nurul Akbar' },
        { value: '3', text: 'Hanel Prilian' }
      ],
      selectedClasification: 1,
      clasification: [
        { value: '1', text: 'Minor' },
        { value: '2', text: 'Medium' },
        { value: '3', text: 'Major' }
      ],
      selectedCategory: 1,
      category: [
        { value: '1', text: 'Welding' },
        { value: '2', text: 'Mechanical' },
        { value: '3', text: 'Electrical' },
        { value: '4', text: 'General Service' }
      ],
      selectedProblemCase : 4,
      problemCase: [
        { value: '1', text: 'Human Error' },
        { value: '2', text: 'Engine Failure' },
        { value: '3', text: 'Material Fatigue' },
        { value: '4', text: 'Environment' }
      ],
      selectedKindTask : 1,
      kindTask: [
        { value: '1', text: 'Repair' },
        { value: '2', text: 'Renew' }
      ],

      subContractor: {
        activeLabel: 'Yes',
        inactiveLabel: 'No',
        label: '',
        class: 'primary',
        checked: true
      },

      negotiation: {
        activeLabel: 'Yes',
        inactiveLabel: 'No',
        label: '',
        class: 'primary',
        checked: true
      }
    }
  }
}
</script>
