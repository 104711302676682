var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', {
    staticClass: "chat-data-left scroller",
    attrs: {
      "lg": "3"
    }
  }, [_c('div', {
    staticClass: "chat-sidebar-channel scroller pl-3"
  }, [_c('tab-nav', {
    staticClass: "iq-chat-ui",
    attrs: {
      "pills": true,
      "vertical": true,
      "id": "chat-list-data"
    }
  }, [_vm._l(_vm.publicChannel, function (item, index) {
    return [_c('tab-nav-items', {
      key: index,
      attrs: {
        "liClass": "",
        "data-toggle": "pill",
        "href": "#chatbox"
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c('chatItem', {
            attrs: {
              "item": item
            }
          })];
        },
        proxy: true
      }], null, true)
    })];
  })], 2)], 1)]), _c('div', {
    staticClass: "col-lg-9 chat-data p-0 chat-data-right"
  }, [_c('tab-content', {
    attrs: {
      "id": "v-pills-tabContent"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "v-pills-default",
      "aria-labelled-by": "v-pills-default"
    }
  }, [[_c('ToggleButton', {
    attrs: {
      "mini-toggle": true,
      "close-button": false,
      "toggleShow": "#chat-user-detail-popup",
      "mediaClass": "chat-user-profile"
    },
    scopedSlots: _vm._u([{
      key: "media",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "chat-start"
  }, [_c('span', {
    staticClass: "iq-start-icon text-primary"
  }, [_c('i', {
    staticClass: "ri-message-3-line"
  })]), _c('div', {
    staticClass: "mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary iq-waves-effect",
      "size": "lg"
    }
  }, [_vm._v("Start Conversation!")])], 1)])]], 2), _c('tab-content-item', {
    attrs: {
      "id": "chatbox",
      "aria-labelled-by": "v-pills-default"
    }
  }, [[_c('div', {
    staticClass: "chat-head"
  }, [_c('header', {
    staticClass: "d-flex justify-content-between align-items-center bg-white pt-3 pr-3 pb-3"
  }, [_c('ToggleButton', {
    attrs: {
      "mini-toggle": true,
      "close-button": false,
      "toggleShow": "#chat-user-detail-popup",
      "mediaClass": "chat-user-profile"
    },
    scopedSlots: _vm._u([{
      key: "media",
      fn: function fn() {
        return [_c('img', {
          staticClass: "avatar-50",
          attrs: {
            "src": _vm.checkUser(5, 'image'),
            "alt": "avatar"
          }
        }), _c('span', {
          staticClass: "avatar-status"
        }, [_c('i', {
          staticClass: "ri-checkbox-blank-circle-fill text-success"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(_vm.checkUser(5, 'name')))])];
      },
      proxy: true
    }])
  }), _c('ToggleContent', {
    attrs: {
      "id": "chat-user-detail-popup",
      "bodyClass": "chatuser-detail",
      "center": ""
    },
    scopedSlots: _vm._u([{
      key: "media",
      fn: function fn() {
        return [_c('img', {
          attrs: {
            "src": _vm.checkUser(5, 'image'),
            "alt": "avatar"
          }
        })];
      },
      proxy: true
    }, {
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "user-name mt-4"
        }, [_c('h4', [_vm._v(_vm._s(_vm.checkUser(5, 'name')))])]), _c('div', {
          staticClass: "user-desc"
        }, [_c('p', [_vm._v("Cape Town, RSA")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-6 col-md-6 title"
        }, [_vm._v("Nik Name:")]), _c('div', {
          staticClass: "col-6 col-md-6 text-right"
        }, [_vm._v(_vm._s(_vm.checkUser(5, 'name')))])]), _c('hr'), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-6 col-md-6 title"
        }, [_vm._v("Tel:")]), _c('div', {
          staticClass: "col-6 col-md-6 text-right"
        }, [_vm._v("072 143 9920")])]), _c('hr'), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-6 col-md-6 title"
        }, [_vm._v("Date Of Birth:")]), _c('div', {
          staticClass: "col-6 col-md-6 text-right"
        }, [_vm._v("July 12, 1989")])]), _c('hr'), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-6 col-md-6 title"
        }, [_vm._v("Gender:")]), _c('div', {
          staticClass: "col-6 col-md-6 text-right"
        }, [_vm._v("Male")])]), _c('hr'), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-6 col-md-6 title"
        }, [_vm._v("Language:")]), _c('div', {
          staticClass: "col-6 col-md-6 text-right"
        }, [_vm._v("Engliah")])])];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "chat-header-icons d-flex"
  }, [_c('a', {
    staticClass: "iq-bg-primary iq-waves-effect mr-1 chat-icon-phone"
  }, [_c('i', {
    staticClass: "ri-phone-line mr-0"
  })]), _c('a', {
    staticClass: "iq-bg-primary iq-waves-effect mr-1 chat-icon-video"
  }, [_c('i', {
    staticClass: "ri-vidicon-line mr-0"
  })]), _c('a', {
    staticClass: "iq-bg-primary iq-waves-effect mr-1 chat-icon-delete"
  }, [_c('i', {
    staticClass: "ri-delete-bin-line mr-0"
  })]), _c('b-dropdown', {
    attrs: {
      "id": "dropdownMenuButton2",
      "right": "",
      "variant": "none iq-bg-primary iq-waves-effect remove-toggle"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('i', {
          staticClass: "ri-more-2-line mr-0"
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "fa fa-thumb-tack mr-0",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" Pin to top")]), _c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "fa fa-trash-o mr-0",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" Delete chat")]), _c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "fa fa-ban mr-0",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" Block")])], 1)], 1)], 1)]), _c('div', {
    staticClass: "chat-content scroller"
  }, [_vm._l(_vm.chat, function (item, index) {
    return [!item.me ? _c('div', {
      key: index,
      staticClass: "chat"
    }, [_c('div', {
      staticClass: "chat-user"
    }, [_c('a', {
      staticClass: "avatar m-0"
    }, [_c('img', {
      staticClass: "avatar-35",
      attrs: {
        "src": _vm.checkUser(item.userId, 'image'),
        "alt": "avatar"
      }
    })]), _c('span', {
      staticClass: "chat-time mt-1"
    }, [_vm._v(_vm._s(item.time))])]), _c('div', {
      staticClass: "chat-detail"
    }, [_c('div', {
      staticClass: "chat-message"
    }, [_c('p', [_vm._v(_vm._s(item.text))])])])]) : _c('div', {
      key: index,
      staticClass: "chat chat-left"
    }, [_c('div', {
      staticClass: "chat-user"
    }, [_c('a', {
      staticClass: "avatar m-0"
    }, [_c('img', {
      staticClass: "avatar-35",
      attrs: {
        "src": _vm.checkUser(item.userId, 'image'),
        "alt": "avatar"
      }
    })]), _c('span', {
      staticClass: "chat-time mt-1"
    }, [_vm._v(_vm._s(item.time))])]), _c('div', {
      staticClass: "chat-detail"
    }, [_c('div', {
      staticClass: "chat-message"
    }, [_c('p', [_vm._v(_vm._s(item.text))])])])])];
  })], 2), _c('div', {
    staticClass: "chat-footer p-3 bg-white"
  }, [_c('form', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "action": "javascript:void(0);"
    }
  }, [_c('div', {
    staticClass: "chat-attagement d-flex"
  }, [_c('a', {
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_c('i', {
    staticClass: "fa fa-smile-o pr-3",
    attrs: {
      "aria-hidden": "true"
    }
  })]), _c('a', {
    attrs: {
      "href": "javascript:void(0)"
    }
  }, [_c('i', {
    staticClass: "fa fa-paperclip pr-3",
    attrs: {
      "aria-hidden": "true"
    }
  })])]), _c('input', {
    staticClass: "form-control mr-3",
    attrs: {
      "type": "text",
      "placeholder": "Type your message"
    }
  }), _c('button', {
    staticClass: "btn btn-primary d-flex align-items-center p-2",
    attrs: {
      "type": "submit"
    }
  }, [_c('i', {
    staticClass: "fas fa-paper-plane",
    attrs: {
      "aria-hidden": "true"
    }
  }), _c('span', {
    staticClass: "d-none d-lg-block ml-1"
  }, [_vm._v("Send")])])])])]], 2)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }