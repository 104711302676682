<template>
  <div class="maintenance-summary-init">
    <!-- Top Section !-->
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>Filter Maintenance Initiation {{queryVehicleId && initMaintenance.params.vehicleIds[0] ? ' - ' + initMaintenance.params.vehicleIds[0].name : ''}}</strong></h4>
          </template>
          <template v-slot:headerAction>
            <!-- <b-button block variant="primary" @click="doExport">
              <template v-if="!loading_export">
                <i class="fa fa-file-excel"></i> &nbsp; Export
              </template>
              <template v-else>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Exporting...
              </template>
            </b-button> -->
            <span class="ml-3"><a class="text-primary float-right" v-b-toggle.collapse-filter role="button" style="cursor: pointer;">
                  <i class="fa fa-filter" />
                </a></span>
          </template>
          <template v-slot:body>
            <b-collapse id="collapse-filter" visible>
              <b-row>
                <b-col md="3" v-if="!queryVehicleId">
                  <b-form-group label="Fleet/Equipment:" label-for="fleet-equipment">
                    <v-multiselect v-model="initMaintenance.params.vehicleIds" :disabled="queryVehicleId" tag-placeholder="Category" placeholder="Search or add a category" label="name" track-by="code" :options="fleetEquipments" :multiple="true" :taggable="true" >
                    </v-multiselect>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Choose your Job Filter" label-for="fleet-equipment">
                    <template v-for="(item,index) in jobOptions">
                      <b-form-radio v-model="initMaintenance.selectedJob" class="custom-radio-color-checked" inline :color="item.color" name="color" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                    </template>
                  </b-form-group>
                  <b-form-group label="Job Class" label-for="fleet-equipment" v-if="initMaintenance.selectedJob === 'job_class'">
                    <v-multiselect v-model="initMaintenance.params.jobClassIds" tag-placeholder="Category" placeholder="Search or add a job class" label="name" track-by="code" :options="jobClassList" :multiple="true" :taggable="true" >
                    </v-multiselect>
                  </b-form-group>
                  <b-form-group label="Job Type" label-for="fleet-equipment" v-if="initMaintenance.selectedJob === 'job_type'">
                    <v-multiselect v-model="initMaintenance.params.jobTypeIds" tag-placeholder="Category" placeholder="Search or add a job type" label="name" track-by="code" :options="jobTypeList" :multiple="true" :taggable="true" >
                    </v-multiselect>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Job Name/Equipment" label-for="equipment">
                    <b-form-input v-model="initMaintenance.params.search" type="text" placeholder="search..."></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Filter" label-for="button_search">
                    <b-button @click="getInitiationList()" variant="primary" class="mb-3 mr-1"><i class="fa fa-filter"></i></b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-collapse>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Top Section !-->
    <!-- Content Section !-->
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>Maintenance Initiation {{queryVehicleId && initMaintenance.params.vehicleIds[0] ? ' - ' + initMaintenance.params.vehicleIds[0].name : ''}}</strong></h4>
          </template>
          <template v-slot:headerAction>
            <!-- <b-button v-b-modal.import variant="dark"><i class="fa fa-file-import"></i> Import</b-button> -->
            <b-modal id="import" size="large" title="Import Data Maintenance Initiation Using Template" header-bg-variant="light" header-text-variant="dark"
                     footer-bg-variant="light" footer-text-variant="dark">
              <p class="my-2">
                Download Template for Importing first
                <b-button variant="success"><i class="fa fa-file-excel-o"></i> Template</b-button>
              </p>
              <p class="my-2">
                <b-form-group label="Make Sure your data using template">
                  <b-form-file
                    id="fileSupport"
                    placeholder="Choose a file"
                    accept="application/pdf" ></b-form-file>
                </b-form-group>
              </p>
            </b-modal>
            <span class="ml-2"><b-button v-if="canAdd" v-b-modal.add-init @click="setFormInitMaintenance()" variant="success" class="mt-2 mb-3 mr-1"><i class="fa fa-plus"></i>Maintenance Initiation</b-button></span>
            <b-modal v-model="showInitModal" id="add-init" size="xl" title="Add Initiation - Choose Fleet/Equipment First" header-bg-variant="light" header-text-variant="dark"
                     footer-bg-variant="light" scrollable footer-text-variant="dark">
              <b-row class="mb-4">
                <b-col md="4" class="text-right">
                  <b-form-input
                    type="text"
                    placeholder="Search fleets..."
                    v-model="fleetParams.search"
                    @input="fetchFleets"
                  ></b-form-input>
                </b-col>
                <b-col md="8" class="text-right">
                  <b-form-checkbox
                    name="check-button"
                    switch
                    inline
                    v-model="selectAllFleets"
                  >
                    Select All
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <div v-if="fleetsLoading" class="text-center my-5">
                <b-spinner type="grow" label="Spinning"></b-spinner>
              </div>
              <template v-else>
                <b-row v-if="fleets.length > 0">
                  <b-col
                    md="3"
                    class="text-center py-5 border-top"
                    v-for="(fleet, i) in fleets"
                    :key="`fleet-${i}`"
                  >
                    <div class="custom-control custom-checkbox image-checkbox">
                      <input
                        :id="`ck-fl-${i}`"
                        type="checkbox"
                        class="custom-control-input"
                        :value="fleet"
                        :disabled="queryVehicleId"
                        v-model="checkedFleets"
                      />
                      <label class="custom-control-label" :for="`ck-fl-${i}`">
                        <img
                          :src="
                            fleet.vehicleType.icon.url
                          "
                          alt="#"
                          class="img-fluid"
                        />
                      </label>
                      <small
                      ><strong>{{ fleet.name }}</strong></small
                      >
                    </div>
                  </b-col>
                </b-row>
                <div v-else class="text-center my-5">
                  <p class="mb-0 text-muted">No fleets data found.</p>
                </div>
              </template>
              <template #modal-footer="{ cancel }">
                <b-row>
                  <b-col md="12" class="text-center">
                    <b-button variant="none" class="iq-bg-danger text-right" @click="cancel()">Cancel</b-button>
                    <b-button :disabled="checkedFleets && checkedFleets.length === 0" type="submit" variant="primary" @click="nextStep()" class="ml-2 text-right">Next <i class="fa fa-arrow-right"></i> </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-modal>
            <b-modal scrollable v-model="showInitModalNext" id="add-init-next" size="huge" title="Init your maintenance Job" header-bg-variant="light" header-text-variant="dark"
                     footer-bg-variant="light" footer-text-variant="dark">
              <b-row>
                <b-col cols="12" class="text-center">
                  <span class="mr-5"><h4>Fleet / Equipment : </h4></span>
                  <div class="table-responsive" v-if="checkedFleets && checkedFleets.length > 0">
                    <table class="table mb-0 table-striped table-hover table-dark">
                      <tbody >
                      <tr v-for="(each) in checkedFleets" :key="each.id">
                        <td width="15%">{{ each.name }}</td>
                        <td width="5%">:</td>
                        <td>
                          <ul class="text-left" v-if="each.powers && each.powers.length > 0">
                            <li v-for="power in each.powers" :key="power.id">{{power.powerType ? power.powerType.name : '-'}} - {{power.powerSeries ? power.powerSeries.name : '-'}} {{power.powerSeries ? power.powerSeries.model : '-'}} <span class="pull-right text-warning">Actual RH: <strong>{{power.actualRunningHours}} RH</strong></span></li>
                          </ul>
                          <b-alert show v-else variant="warning"> no powers data</b-alert>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
                <b-col cols="12">
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2">Setup Job Component / Equipment:</legend>
                    <b-row>
                      <b-col md="3">
                        <b-form-group label="Job Code:" label-for="job_name">
                          <b-form-input v-model="formInitMaintenance.jobCode" id="job_name" type="text" placeholder="Job Code"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group label="Job Class" label-for="fleet-equipment">
                          <v-multiselect v-model="formInitMaintenance.jobClassId" tag-placeholder="Category" placeholder="Search or add a job class" label="name" track-by="code" :options="jobClassList" :multiple="false" :taggable="false" >
                          </v-multiselect>
                        </b-form-group>
                      </b-col>
                      <b-col md="5">
                        <b-form-group label="Job Name:" label-for="job_name">
                          <b-form-input v-model="formInitMaintenance.jobName" id="job_name" type="text" placeholder="Job Name"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <v-jstree :data="treeInitMaintenance" show-checkbox allow-batch whole-row @item-click="itemClickInitComponent">
                          <template slot-scope="_">
                            <div style="display: inherit; width: 200px">
                              <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                              {{_.model.text}}
                            </div>
                          </template>
                        </v-jstree>
                      </b-col>
                      <b-col md="8">
                        <b-row v-if="checkedFleets && checkedFleets.length > 0 && componentIdSelected">
                          <fieldset class="form-group border p-3" v-for="(each, i) in checkedFleets" :key="i">
                            <legend class="w-auto px-2"><h5><b-badge>{{each.name}}</b-badge></h5></legend>
                            <b-row v-if="each.componentSetup && each.componentSetup.length === 0">
                              <b-col cols="12">
                                <b-alert :show="true" variant=" " class="text-white bg-warning">
                                  <div class="iq-alert-icon">
                                    <i class="ri-alert-line"></i>
                                  </div>
                                  <div class="iq-alert-text">For Information Equipment Not Setup for Fleet <b>{{each.name}}</b>, Please Setup First</div>
                                </b-alert>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col cols="12">
                                <div class="table-responsive">
                                  <table class="table mb-0 table-striped table-hover">
                                    <thead class="text-center">
                                    <tr>
                                      <td width="3%">#</td>
                                      <td>Equipment - Code</td>
                                      <td>Type - Serial No</td>
                                      <td>Maker</td>
                                      <td>Job Index</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="components in each.componentSetup" :key="components.id">
                                      <td>
                                        <b-form-group>
                                          <b-form-radio :disabled="maintenanceDetailData" v-model="formInitMaintenance.vehicles[i].componentSetupId" :name="`radio-component-vehicle-${formInitMaintenance.vehicles[i].vehicleId}`" :value="components.id"></b-form-radio>
                                        </b-form-group>
                                      </td>
                                      <td>{{components.maintenanceMaster.equipmentId}} - {{components.maintenanceMaster.equipmentName}}</td>
                                      <td>{{components.typeNo}} - {{components.serialNo}}</td>
                                      <td><ul><li>Maker No: {{components.makerNo}}</li><li>Maker: {{components.maker}}</li></ul></td>
                                      <td class="text-center">
                                        <small v-if="components.status.length === 0"><b-badge variant="danger d-block">NOT YET INIT</b-badge></small>
                                        <small v-else>
                                          <b-badge variant="success d-block" v-for="stats in components.status" class="mb-1">{{ stats }}</b-badge>
                                        </small>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </b-col>
                              <b-col cols="12">
                                <b-row>
                                  <b-col cols="6">
                                    <b-form-group label="Job Type" label-for="fleet-equipment">
                                      <v-multiselect v-model="formInitMaintenance.vehicles[i].jobTypeId" tag-placeholder="Category" placeholder="Search or add a job type" label="name" track-by="code" :options="jobTypeList" :multiple="false" :taggable="false" >
                                      </v-multiselect>
                                    </b-form-group>
                                  </b-col>
                                  <b-col cols="6">
                                    <b-form-group label="Job Description" label-for="fleet-equipment">
                                      <wysiwyg style="width: 100%" v-model="formInitMaintenance.vehicles[i].jobDesc" id="description"/>
                                    </b-form-group>
                                  </b-col>
                                  <b-col md="12" class="text-center mb-4 mt-3 bg-light p-3">
                                    <h4>Trigger</h4>
                                    <template v-for="(item,index) in triggers">
                                      <b-form-radio v-model="formInitMaintenance.vehicles[i].trigger" class="custom-radio-color-checked" inline :color="item.color" :name="`radio-trigger-${index}-vehicle-${formInitMaintenance.vehicles[i].vehicleId}`" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                                    </template>
                                  </b-col>
                                  <b-col md="12">
                                    <b-row class="p-3">
                                      <template v-if="formInitMaintenance.vehicles[i].trigger === 'CALENDAR'">
                                        <b-col md="6">
                                          <b-form-group label-for="pattern" label="Pattern">
                                            <template v-for="(item,index2) in patternCalendar">
                                              <b-form-radio v-model="formInitMaintenance.vehicles[i].calendar.calendarType" class="custom-radio-color-checked" inline :color="item.color" :name="`radio-pattern-${index2}-vehicle-${formInitMaintenance.vehicles[i].vehicleId}`" :key="index2" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                          <b-form-group label="Interval/Frequency:" label-for="Interval:">
                                            <b-input-group size="md" :append="formInitMaintenance.vehicles[i].calendar.calendarType === 'DAY' ? 'days' : (formInitMaintenance.vehicles[i].calendar.calendarType === 'WEEK' ? 'Weeks' : 'Months')">
                                              <b-form-input type="number" v-model="formInitMaintenance.vehicles[i].calendar.interval"></b-form-input>
                                            </b-input-group>
                                          </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                          <b-form-group label="Last Maintenance:" label-for="lastMaintenance">
                                            <date-picker style="width: 100%;" v-model="formInitMaintenance.vehicles[i].calendar.lastMaintenanceDate" value-type="DD-MM-YYYY" format="DD MMM YYYY" type="date" placeholder="Select date"></date-picker>
                                          </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                          <b-form-group label="Notif Before Days:" label-for="cost">
                                            <b-input-group size="md">
                                              <b-form-input type="number" v-model="formInitMaintenance.vehicles[i].calendar.notifBeforeDays"></b-form-input>
                                            </b-input-group>
                                          </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                          <b-form-group label="Cost:" label-for="cost">
                                            <b-input-group size="md" prepend="Rp">
                                              <b-form-input type="number" v-model="formInitMaintenance.vehicles[i].calendar.cost"></b-form-input>
                                            </b-input-group>
                                          </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                          <b-form-group label-for="maintenanceFile" label="File Maintenance">
                                            <b-form-file
                                              placeholder="Choose a file"
                                              :name="`maintenance-file-${i}-vehicle-${formInitMaintenance.vehicles[i].vehicleId}`"
                                              :id="`maintenance-file-${i}-vehicle-${formInitMaintenance.vehicles[i].vehicleId}`"
                                              @change="uploadFile($event,i, null)"
                                            ></b-form-file>
                                          </b-form-group>
                                        </b-col>
                                        <b-col md="12">
                                          <b-form-group label-for="Notes" label="Notes">
                                            <b-form-textarea id="notes" rows="11" placeholder="Type Notes" v-model="formInitMaintenance.vehicles[i].calendar.notes"></b-form-textarea>
                                          </b-form-group>
                                        </b-col>
                                        <b-col md="6">
                                          <b-form-group label="Service Repair">
                                            <b-form-radio v-model="formInitMaintenance.vehicles[i].calendar.isServiceRepair" :value="true">Yes</b-form-radio>
                                            <b-form-radio v-model="formInitMaintenance.vehicles[i].calendar.isServiceRepair" :value="false">No</b-form-radio>
                                          </b-form-group>
                                        </b-col>
                                      </template>
                                      <template v-if="formInitMaintenance.vehicles[i].trigger === 'COUNTER'">
                                        <!-- <b-col md="12" class="mb-3 text-right">
                                          <b-form-group label="Check All Type of Power" label-for="sub_sub_project">
                                            <b-form-checkbox v-model="checkAllTypeofPower" name="check-button" switch inline @change="checkAllTypeofPower ? [typePowerCounter = true,typePowerCounter2 = true] : [typePowerCounter = false,typePowerCounter2 = false]; ">
                                              <template v-if="checkAllTypeofPower">Checked</template><template v-else>No</template>
                                            </b-form-checkbox>
                                          </b-form-group>
                                        </b-col> -->
                                        <b-col md="12" class="mb-3 bg-light w-100" v-if="each.powers && each.powers.length > 0">
                                          <template v-for="(powersData, index) in each.powers">
                                            <fieldset :key="index" class="form-group border p-3 w-100 mb-3" v-if="(maintenanceDetailData && maintenanceDetailData.power && maintenanceDetailData.power.id === powersData.id) || !maintenanceDetailData">
                                              <legend class="w-auto px-2">
                                                <b-form-checkbox
                                                  :id="`checkbox-counter-${index}-vehicle-${formInitMaintenance.vehicles[i].vehicleId}`"
                                                  v-model="formInitMaintenance.vehicles[i].counter[index].selected"
                                                  :name="`checkbox-counter-${index}-vehicle-${formInitMaintenance.vehicles[i].vehicleId}`"
                                                >
                                                  {{powersData.powerType ? powersData.powerType.name : ''}} {{powersData.powerType ? powersData.powerType.model : ''}} <small>{{powersData.powerSeries ? powersData.powerSeries.name : ''}} - {{powersData.powerSeries ? powersData.powerSeries.model : ''}}</small>
                                                </b-form-checkbox>
                                                <!-- <span class="pull-right ml-5 font-size-14" v-if="typePowerCounter2">Same Data with first input ? &nbsp;<span><b-form-checkbox name="check-button" switch inline></b-form-checkbox></span></span> -->
                                              </legend>
                                              <b-row v-if="formInitMaintenance.vehicles[i].counter[index].selected">
                                                <b-col md="6">
                                                  <b-form-group label="Interval/Frequency:" label-for="Interval:">
                                                    <b-input-group size="md" append="Hours">
                                                      <b-form-input v-model="formInitMaintenance.vehicles[i].counter[index].interval" type="number"></b-form-input>
                                                    </b-input-group>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col md="6">
                                                  <b-form-group label-for="maintenanceFile" label="File Maintenance">
                                                    <b-form-file
                                                      :name="`maintenance-file-counter-${i}-${index}-vehicle-${formInitMaintenance.vehicles[i].vehicleId}`"
                                                      :id="`maintenance-file-counter-${i}-${index}-vehicle-${formInitMaintenance.vehicles[i].vehicleId}`"
                                                      placeholder="Choose a file"
                                                      @change="uploadFile($event,i, index)"
                                                    ></b-form-file>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col md="6">
                                                  <b-form-group label="Last Maintenance Date:" label-for="lastMaintenance">
                                                    <date-picker style="width: 100%;" v-model="formInitMaintenance.vehicles[i].counter[index].lastMaintenanceDate" value-type="DD-MM-YYYY" format="DD MMM YYYY" type="date" placeholder="Select date"></date-picker>
                                                    <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col md="6">
                                                  <b-form-group label="Last Maintenance Hours:" label-for="lastMaintenanceHours:">
                                                    <b-input-group size="md" append="Hours">
                                                      <b-form-input type="number" v-model="formInitMaintenance.vehicles[i].counter[index].lastMaintenanceHours"></b-form-input>
                                                    </b-input-group>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col md="6">
                                                  <b-form-group label="Cost:" label-for="cost">
                                                    <b-input-group size="md" prepend="Rp">
                                                      <b-form-input type="number" v-model="formInitMaintenance.vehicles[i].counter[index].cost"></b-form-input>
                                                    </b-input-group>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col md="6">
                                                  <b-form-group label="Notif Before Percents:" label-for="cost">
                                                    <b-input-group size="md">
                                                      <b-form-input type="number" v-model="formInitMaintenance.vehicles[i].counter[index].notifBeforePercents"></b-form-input>
                                                    </b-input-group>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col md="6" class="mb-3">
                                                  <b-form-group label="Reset Running Hour for this job ?" label-for="sub_sub_project">
                                                    <b-form-checkbox v-model="formInitMaintenance.vehicles[i].counter[index].isResetRunningHours" name="check-button" switch inline>
                                                      <template v-if="formInitMaintenance.vehicles[i].counter[index].isResetRunningHours">Yes</template><template v-else>No</template>
                                                    </b-form-checkbox>
                                                  </b-form-group>
                                                </b-col>
                                                <b-col md="6">
                                                  <b-form-group label-for="Notes" label="Notes">
                                                    <b-form-textarea v-model="formInitMaintenance.vehicles[i].counter[index].notes" id="notes" rows="3" placeholder="Type Notes"></b-form-textarea>
                                                  </b-form-group>
                                                </b-col>
                                              </b-row>
                                            </fieldset>
                                          </template>
                                        </b-col>
                                      </template>
                                    </b-row>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </fieldset>
                        </b-row>
                        <b-row v-else>
                          <b-col>
                            <b-alert show variant="warning">Please component first!</b-alert>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
                <!-- <b-col cols="12" class="text-center">
                  <h5 style="cursor:pointer"><i class="fa fa-plus-circle text-success"></i></h5>
                </b-col> -->
              </b-row>
              <template #modal-footer>
                <b-row>
                  <b-col md="12" class="text-center">
                    <b-button variant="warning" v-if="!maintenanceDetailData" class="iq-bg-danger text-right" @click="showInitModalNext = !showInitModalNext;showInitModal = !showInitModal"><i class="fa fa-arrow-left"></i> Back </b-button>
                    <b-button type="submit" variant="primary" class="ml-2 text-right" @click="submitFormInit()">Submit <i class="fa fa-save"></i> </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-modal>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col cols="12">
                <div class="table-responsive">
                  <table class="table mb-0 table-striped table-hover">
                    <thead class="text-center">
                    <tr>
                      <th>No</th>
                      <th v-if="!queryVehicleId">Fleet/Equipment</th>
                      <th>Job Name - Code</th>
                      <th>Category</th>
                      <th>Job Type</th>
                      <th>Trigger - Interval</th>
                      <th>Last Maintenance</th>
                      <th>Due Date / Hours</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                    </thead>
                    <tbody v-if="initMaintenance.data.length === 0">
                    <tr v-if="initMaintenance.isLoading">
                      <td class="text-center" colspan="12"><b-spinner label="Spinning"></b-spinner></td>
                    </tr>
                    <tr v-else>
                      <td class="text-center" colspan="12">No Data</td>
                    </tr>
                    </tbody>
                    <tbody v-if="initMaintenance.data && initMaintenance.data.length > 0">
                    <template v-for="(job, index) in initMaintenance.data">
                      <tr :key="index">
                        <td class="text-center">{{ index+1 }}</td>
                        <td v-if="!queryVehicleId"><a href="#" @click.prevent="viewDetailMaintenance(job.id)">{{ job.vehicleName }}</a></td>
                        <td><router-link to="" v-b-toggle="'view-job-init'+index">{{ job.jobName }}</router-link></td>
                        <td class="text-center">{{ job.componentName }}</td>
                        <td class="text-center">{{ job.jobTypeName }}</td>
                        <td class="text-center"><strong>{{ job.trigger }}</strong></td>
                        <td class="text-center">{{ job.lastMaintenanceDate }}</td>
                        <td class="text-center"><span>{{ job.dueDate }}</span></td>
                        <td class="text-center"><b-badge variant="success">{{ job.status }}</b-badge></td>
                        <td class="text-center">
                          <template v-if="job.status !== 'Already Run' && job.status !== 'already_run' && job.status !== 'already run'">
                            <b-dropdown variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                              <template v-slot:button-content>
                                <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-caret-down m-0 text-primary"></i>
                                </span>
                              </template>
                              <b-dropdown-item href="#" v-if="canEdit" @click="fetchMaintenanceDetail(job.id)" class="m-0 p-0"><i class="fa fa-pencil-square-o mr-2"></i>Edit</b-dropdown-item>
                              <b-dropdown-item href="#" v-if="canDelete" @click="onDeleteInit(job)" class="m-0 p-0"><i class="fa fa-trash mr-2"></i>Delete</b-dropdown-item>
                            </b-dropdown>
                          </template>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </div>
              </b-col>
              <b-col class="mt-4" v-if="initMaintenance.data && initMaintenance.data.length > 0">
                <b-pagination
                  v-model="initMaintenance.meta.currentPage"
                  :total-rows="initMaintenance.meta.total"
                  @change="getInitiationList"
                  :per-page="initMaintenance.meta.perPage"
                  first-number
                  align="center"
                ></b-pagination>
              </b-col>
              <b-col cols="12">
                <small>NB: Jika sudah masuk jadwal dan sudah berjalan, initiation berpindah ke maintenance board</small>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <!-- Sidebar View -->
        <b-sidebar
          v-model="viewDetailMaintenanceSidebar"
          id="view-detail-maintenance"
          title="View Job Maintenance"
          width="1300px"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{ hide }">
            <ViewMaintenanceDetail v-if="viewDetailMaintenanceSidebar" :maintenance-id="selectedMaintenanceId" />
          </template>
        </b-sidebar>
        <!-- End Sidebar View -->
      </b-col>
    </b-row>
    <!-- End Content Section !-->
  </div>
</template>

<script>
import {
  fleetsActions, maintenanceActions, filesActions
} from '@src/Utils/helper'
import _ from 'lodash'
import ViewMaintenanceDetail from '@src/views/Maintenance/ViewMaintenanceDetail'

export default {
  name: 'MaintenanceSummaryInit',
  components: { ViewMaintenanceDetail },
  data () {
    return {
      fleetsLoading: false,
      fleetParams: {
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        search: ''
      },
      fleets: [],
      fleetEquipments: [],
      initMaintenance: {
        selectedJob: 'job_class',
        isLoading: false,
        editMode: false,
        params: {
          search: '',
          perPage: 50,
          page: 1,
          vehicleIds: [],
          jobTypeIds: [],
          jobClassIds: []
        },
        data: [],
        detailData: null,
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      maintenanceDetailData: null,
      componentIdSelected: null,
      selectedMaintenanceId: null,
      viewDetailMaintenanceSidebar: false,
      selectAllFleets: false,
      showInitModal: false,
      showInitModalNext: false,
      checkedFleets: [],
      treeInitMaintenance: [],
      formInitMaintenance: {
        jobCode: '',
        jobClassId: null,
        jobName: '',
        componentId: null,
        vehicles: []
      },
      vehiclesInitForm: {
        vehicleId: null,
        componentSetupId: null,
        jobTypeId: null,
        jobDesc: '',
        trigger: 'CALENDAR',
        calendar: {
          calendarType: 'MONTH',
          lastMaintenanceDate: '',
          interval: null,
          cost: '',
          notes: '',
          fileName: null,
          notifBeforeDays: null,
          isServiceRepair: false
        },
        counter: []
      },
      counterForm: {
        vehiclePowerId: '',
        lastMaintenanceDate: '',
        interval: null,
        cost: '',
        notes: '',
        fileName: null,
        lastMaintenanceHours: null,
        notifBeforePercents: null,
        isResetRunningHours: false,
        isServiceRepair: false,
        selected: false
      },
      jobClassList: [],
      jobTypeList: [],
      tree: [
        {
          'text': 'Acocommodation & Hull',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Tanks',
              'selected': false
            }
          ]
        },
        {
          'text': 'Deck Equipment & Outfitting',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Mooring Equipment',
              'selected': false
            },
            {
              'text': 'Propeller',
              'selected': false
            },
            {
              'text': 'Winch & Windlass',
              'selected': false
            }
          ]
        },
        {
          'text': 'Electrical Components & Parts',
          'opened': true,
          'selected': false
        },
        {
          'text': 'Engine & Other Machinery',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Gear Box',
              'selected': false
            },
            {
              'text': 'Generator # Auxiliary Engine',
              'selected': true
            },
            {
              'text': 'Generator Emergency',
              'selected': false
            }
          ]
        },
        {
          'text': 'Machinery Equipment',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Main Engine',
              'selected': false
            }
          ]
        },
        {
          'text': 'Fire & Safety Equipment',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Safety Equipment',
              'selected': false
            }
          ]
        },
        {
          'text': 'Navigation & Communication Equipment',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Radio EQ',
              'selected': false
            }
          ]
        },
        {
          'text': 'Pipes & Fitting',
          'opened': true,
          'selected': false
        },
        {
          'text': 'Pumps',
          'opened': true,
          'selected': false
        }
      ],
      triggers: [
        {
          label: 'Calendar Trigger ',
          color: 'warning',
          value: 'CALENDAR'
        },
        {
          label: 'Counter Trigger (Running Hours)',
          color: 'primary',
          value: 'COUNTER'
        }
      ],
      patternCalendar: [
        {
          label: 'Day',
          color: 'success',
          value: 'DAY'
        },
        {
          label: 'Week',
          color: 'warning',
          value: 'WEEK'
        },
        {
          label: 'Month',
          color: 'primary',
          value: 'MONTH'
        }
      ],
      jobOptions: [
        {
          label: 'Job Class ',
          color: 'primary',
          value: 'job_class'
        },
        {
          label: 'Job Type / Index',
          color: 'success',
          value: 'job_type'
        }
      ],
      lsProfile: JSON.parse(localStorage.getItem('profile'))
    }
  },
  computed: {
    'queryVehicleId' () {
      return this.$route.query.vehicleId
    },
    maintenancePermission () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Technical')?.child.find(child => child.menu === 'Maintenance')
    },
    canAdd () {
      return this.maintenancePermission ? this.maintenancePermission?.add : false
    },
    canEdit () {
      return this.maintenancePermission ? this.maintenancePermission?.update : false
    },
    canApprove () {
      return this.maintenancePermission ? this.maintenancePermission?.approval : false
    },
    canReview () {
      return this.maintenancePermission ? this.maintenancePermission?.review : false
    },
    canDelete () {
      return this.maintenancePermission ? this.maintenancePermission?.delete : false
    },
    canExport () {
      return this.maintenancePermission ? this.maintenancePermission?.export : false
    }
  },
  watch: {
    selectAllFleets: {
      deep: true,
      handler: function (checked) {
        this.checkedFleets = []
        if (checked) {
          this.fleets.forEach((f) => this.checkedFleets.push(f))
        }
      }
    }
  },
  methods: {
    ...fleetsActions,
    ...maintenanceActions,
    ...filesActions,
    async getJobTypeList () {
      const params = {
        page: 1,
        perPage: 1000
      }
      this.jobTypeList = []
      const res = await this.getJobType(params)

      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(el => {
            this.jobTypeList.push({
              name: `${el.jobTypeCode} - ${el.jobTypeIndex}`,
              code: el.id
            })
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async getJobClassList () {
      const params = {
        page: 1,
        perPage: 1000
      }
      this.jobClassList = []
      const res = await this.getJobClass(params)

      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(el => {
            this.jobClassList.push({
              name: `${el.jobClassCode} - ${el.jobClass}`,
              code: el.id
            })
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async fetchFleets () {
      this.fleetsLoading = true
      this.fleets = []

      let params = this.$options.filters.cleanObject(this.fleetParams)
      let res = await this.getFleets(params)

      if (res.status) {
        this.fleetsLoading = false
        if (res.data && res.data.length > 0) {
          res.data.forEach(el => {
            el.componentSetup = []

            if ((typeof this.queryVehicleId !== 'undefined' && parseInt(el.id) === parseInt(this.queryVehicleId)) || (typeof this.queryVehicleId === 'undefined' || this.queryVehicleId === '' || this.queryVehicleId === null)) {
              this.fleets.push(el)
            }

            this.fleetEquipments.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    async getInitiationList (page) {
      this.initMaintenance.isLoading = true
      this.initMaintenance.data = []
      this.initMaintenance.params.page = page || 1

      if (this.initMaintenance.selectedJob === 'job_class') {
        this.initMaintenance.params.jobTypeIds = []
      } else {
        this.initMaintenance.params.jobClassIds = []
      }

      const paramsQ = {
        search: this.initMaintenance.params.search,
        perPage: this.initMaintenance.params.perPage,
        page: this.initMaintenance.params.page,
        vehicleIds: this.initMaintenance.params.vehicleIds,
        jobTypeIds: this.initMaintenance.params.jobTypeIds,
        jobClassIds: this.initMaintenance.params.jobClassIds
      }

      if (paramsQ.vehicleIds && paramsQ.vehicleIds.length > 0) {
        let dataTemp = []
        paramsQ.vehicleIds.forEach(el => {
          dataTemp.push(el.code)
        })
        paramsQ.vehicleIds = dataTemp
        paramsQ.vehicleIds = paramsQ.vehicleIds.toString()
      }
      if (paramsQ.jobTypeIds && paramsQ.jobTypeIds.length > 0) {
        let dataTemp = []
        paramsQ.jobTypeIds.forEach(el => {
          dataTemp.push(el.code)
        })
        paramsQ.jobTypeIds = dataTemp
        paramsQ.jobTypeIds = paramsQ.jobTypeIds.toString()
      }
      if (paramsQ.jobClassIds && paramsQ.jobClassIds.length > 0) {
        let dataTemp = []
        paramsQ.jobClassIds.forEach(el => {
          dataTemp.push(el.code)
        })
        paramsQ.jobClassIds = dataTemp
        paramsQ.jobClassIds = paramsQ.jobClassIds.toString()
      }
      if (paramsQ.search === '') {
        delete paramsQ.search
      }

      const res = await this.getInitiation(paramsQ)

      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          this.initMaintenance.data = res.data.data
          this.initMaintenance.meta.perPage = res.data.perPage
          this.initMaintenance.meta.currentPage = res.data.currentPage
          this.initMaintenance.meta.total = res.data.total
          this.initMaintenance.meta.totalPage = res.data.totalPage
        }
        this.initMaintenance.isLoading = false
      } else {
        this.initMaintenance.data = []
        this.initMaintenance.isLoading = false
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async fetchMaintenanceDetail (maintenanceId) {
      const res = await this.getMaintenanceDetail(maintenanceId)
      if (res.status === 'success') {
        if (res.data) {
          let data = { ...res.data, maintenanceId }
          this.setFormInitMaintenance()
          this.checkedFleets = []
          this.maintenanceDetailData = data
          this.checkedFleets.push({ ...data.vehicle, componentSetup: [] })
          this.formInitMaintenance = {
            jobCode: data.jobCode,
            jobClassId: {
              name: data.jobClass.jobClass,
              code: data.jobClass.id
            },
            jobName: data.jobName,
            componentId: data.component.id,
            vehicles: []
          }
          this.nextStep()

          this.componentIdSelected = this.maintenanceDetailData.component.id
          await this.componentSetupCheck()
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    onDeleteInit (dataVal) {
      this.$swal({
        title: 'Delete Maintenance Initiation?',
        text: `Maintenance Initiation will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteInitMaintenanceById(dataVal.id)
          if (res.status !== 'success') {
            if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.getInitiationList()
            this.$swal(
              `Maintenance Initiation deleted!`,
              `Maintenance Initiation successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    setFormInitMaintenance () {
      this.maintenanceDetailData = null
      this.checkedFleets = []
      this.formInitMaintenance = {
        jobCode: '',
        jobClassId: null,
        jobName: '',
        componentId: null,
        vehicles: []
      }

      if (typeof this.queryVehicleId !== 'undefined') {
        this.fleets.filter(fleet => parseInt(fleet.id) === parseInt(this.queryVehicleId)).forEach((f) => this.checkedFleets.push(f))
      }

      this.setDefaultVehicleForm()

      this.showInitModal = true
    },

    setDefaultVehicleForm () {
      this.vehiclesInitForm = {
        vehicleId: null,
        componentSetupId: null,
        jobTypeId: null,
        jobDesc: '',
        trigger: 'CALENDAR',
        calendar: {
          calendarType: 'MONTH',
          lastMaintenanceDate: '',
          interval: null,
          cost: '',
          notes: '',
          fileName: null,
          notifBeforeDays: null,
          isServiceRepair: false
        },
        counter: []
      }
      this.setDefaultCounterForm()
    },
    setDefaultCounterForm () {
      this.counterForm = {
        vehiclePowerId: '',
        lastMaintenanceDate: '',
        interval: null,
        cost: '',
        notes: '',
        fileName: null,
        lastMaintenanceHours: null,
        notifBeforePercents: null,
        isResetRunningHours: false,
        isServiceRepair: false,
        selected: false
      }
    },
    nextStep () {
      const uniqueFleetTypes = [...new Set(this.checkedFleets.map(obj => obj.vehicleType.id)) ].length

      if (uniqueFleetTypes > 1) {
        return alert('Jenis Fleet/Vehicle Harus Sama')
      }

      this.showInitModalNext = true
      this.showInitModal = false
      this.componentIdSelected = null

      this.checkedFleets.forEach((el, i) => {
        this.setDefaultVehicleForm()
        if (this.maintenanceDetailData) {
          let data = this.maintenanceDetailData

          this.vehiclesInitForm = {
            vehicleId: data.vehicle.id,
            componentSetupId: data.componentSetup.id,
            jobTypeId: {
              name: data.jobType.jobTypeCode,
              code: data.jobType.id
            },
            jobDesc: data.jobDesc,
            trigger: data.trigger,
            calendar: {
              calendarType: 'MONTH',
              lastMaintenanceDate: '',
              interval: null,
              cost: '',
              notes: '',
              fileName: null,
              notifBeforeDays: null,
              isServiceRepair: false
            },
            counter: []
          }

          if (data.trigger === 'CALENDAR') {
            this.vehiclesInitForm.calendar = {
              calendarType: data.triggerCalendarType,
              lastMaintenanceDate: data.lastMaintenanceDate,
              interval: data.interval,
              cost: data.cost,
              notes: data.notes,
              fileName: data.file ? data.file.fileName : null,
              notifBeforeDays: data.triggerBeforeDay,
              isServiceRepair: data.serviceRepair
            }
          } else if (data.trigger === 'COUNTER') {
            if (el.powers && el.powers.length > 0) {
              el.powers.forEach((val, j) => {
                this.setDefaultCounterForm()

                if (val.id === data.power.id) {
                  this.counterForm = {
                    vehiclePowerId: data.power.id,
                    lastMaintenanceDate: data.lastMaintenanceDate,
                    interval: data.interval,
                    cost: data.cost,
                    notes: data.notes,
                    fileName: data.file ? data.file.fileName : null,
                    lastMaintenanceHours: data.lastMaintenanceHours,
                    notifBeforePercents: data.triggerBeforeHoursPercent,
                    isResetRunningHours: data.resetRunningHours,
                    isServiceRepair: data.serviceRepair,
                    selected: true
                  }
                }
                this.vehiclesInitForm.counter.push(this.counterForm)
              })
            }
          }
        } else {
          if (el.powers && el.powers.length > 0) {
            el.powers.forEach((val, j) => {
              this.setDefaultCounterForm()
              this.counterForm.vehiclePowerId = val.id
              this.vehiclesInitForm.counter.push(this.counterForm)
            })
          }
        }

        this.vehiclesInitForm.vehicleId = el.id
        this.formInitMaintenance.vehicles.push(this.vehiclesInitForm)
      })
      this.getComponentStruct()
    },
    async getComponentStruct () {
      this.treeInitMaintenance = []
      const paramsQ = {
        vehicleTypeId: 1
      }

      if (this.checkedFleets && this.checkedFleets.length > 0) {
        this.checkedFleets.forEach(el => {
          paramsQ.vehicleTypeId = el.vehicleType.id
        })
      }

      const res = await this.getComponent(paramsQ)

      if (res.status === 'success') {
        if (res.data.maintenanceComponents && res.data.maintenanceComponents.length > 0) {
          const dataTemp = res.data.maintenanceComponents
          dataTemp.forEach((s) => this.treeInitMaintenance.push(this.putStructureObject(s)))
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    putStructureObject (object) {
      let children = []

      if (object.child.length > 0) {
        object.child.forEach((child) =>
          children.push(this.putStructureObject(child))
        )
      }

      if (this.tree.findIndex(val => val.value === object.component.id) === -1) {
        // this.tree.push({id: object.component.id, value: object.component.id, text: object.component.componentName})
      }

      let selected = this.maintenanceDetailData && this.maintenanceDetailData.component ? (this.maintenanceDetailData.component.id === object.component.id) : false
      let disabled = this.maintenanceDetailData

      return {
        id: object.component.id,
        text: object.component.componentName,
        disabled,
        opened: true,
        selected,
        children
      }
    },
    itemClickInitComponent (node) {
      if (node && node.model && node.model.id) {
        this.componentIdSelected = node.model.id
        this.componentSetupCheck()
      }
    },
    async componentSetupCheck () {
      const paramsQ = {
        vehicleIds: [],
      }

      if (this.checkedFleets && this.checkedFleets.length > 0) {
        this.checkedFleets.forEach(el => {
          paramsQ.vehicleIds.push(el.id)
        })
        paramsQ.vehicleIds = paramsQ.vehicleIds.toString()
      }

      const dataNeed = {
        id: this.componentIdSelected,
        params: paramsQ
      }

      const res = await this.checkHasComponentSetup(dataNeed)

      if (res.status === 'success') {
        if (res.data.data && res.data.data.length > 0) {
          if (this.checkedFleets && this.checkedFleets.length > 0) {
            res.data.data.forEach(el => {
              this.checkedFleets.forEach(fleet => {
                if (fleet.id === el.vehicle.id) {
                  fleet.componentSetup = []
                  el.componentSetup.forEach(componentSetup => {
                    fleet.componentSetup.push(componentSetup)
                  })
                }
              })
            })
          }
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async submitFormInit () {
      this.$swal.fire({
        title: 'Processing...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading()
        }
      })

      const payload = JSON.parse(JSON.stringify(this.formInitMaintenance))
      payload.componentId = Number(this.componentIdSelected)
      payload.jobClassId = payload.jobClassId ? payload.jobClassId.code : null
      if (payload.vehicles && payload.vehicles.length > 0) {
        payload.vehicles.forEach((el, i) => {
          let idxFind = this.checkedFleets.findIndex(val => val.id === el.vehicleId)
          if (idxFind !== -1 && this.checkedFleets[idxFind].componentSetup && this.checkedFleets[idxFind].componentSetup.length > 0) {
            el.jobTypeId = el.jobTypeId ? el.jobTypeId.code : null

            if (el.trigger === 'CALENDAR') {
              delete el.counter
              el.calendar.interval = Number(el.calendar.interval)
              el.calendar.notifBeforeDays = Number(el.calendar.notifBeforeDays)
            } else {
              delete el.calendar
              if (el.counter && el.counter.length > 0) {
                let counters = el.counter.filter(counter => counter.selected)
                counters.forEach((val, j) => {
                  val.interval = Number(val.interval)
                  val.lastMaintenanceHours = Number(val.lastMaintenanceHours)
                  val.notifBeforePercents = Number(val.notifBeforePercents)
                })

                el.counter = counters
              }
            }
          } else {
            payload.vehicles.splice(i, 1)
          }
        })
      }

      // if update mode
      if (this.maintenanceDetailData) {
        let editPayload = {
          'jobCode': payload.jobCode,
          'jobClassId': payload.jobClassId,
          'jobName': payload.jobName,
          'componentId': payload.componentId,
          'jobTypeId': payload.vehicles[0]?.jobTypeId,
          'jobDesc': payload.vehicles[0]?.jobDesc,
          'trigger': payload.vehicles[0]?.trigger
        }

        if (payload.vehicles[0]?.trigger === 'CALENDAR') {
          editPayload.calendar = payload.vehicles[0]?.calendar
        } else {
          editPayload.counter = payload.vehicles[0]?.counter[0]
        }

        const res = await this.updateMaintenance({
          id: this.maintenanceDetailData.maintenanceId,
          data: editPayload
        })
        if (res.status === 'success') {
          this.$swal('Update Maintenance Initiation Success!', res.data.message, 'success')
          this.onHandleInit()
          this.$emit('submit')
          this.showInitModalNext = !this.showInitModalNext
        } else {
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      } else {
        const res = await this.saveInitMaintenance([payload])
        if (res.status === 'success') {
          this.$swal('Add Maintenance Initiation Success!', res.data.message, 'success')
          this.onHandleInit()
          this.$emit('submit')
          this.showInitModalNext = !this.showInitModalNext
        } else {
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      }
    },
    async uploadFile (event, i, j) {
      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)

      if (res.status === 'success') {
        if (j !== null) {
          this.formInitMaintenance.vehicles[i].counter[j].fileName = res.data.fileName
        } else {
          this.formInitMaintenance.vehicles[i].calendar.fileName = res.data.fileName
        }
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    viewDetailMaintenance (id) {
      if (typeof id !== 'undefined' && id) {
        this.selectedMaintenanceId = id
        this.viewDetailMaintenanceSidebar = true
      }
    },

    async onHandleInit () {
      await this.fetchFleets()
      if (typeof this.queryVehicleId !== 'undefined') {
        this.initMaintenance.params.vehicleIds = this.fleetEquipments.filter(fleet => parseInt(fleet.code) === parseInt(this.queryVehicleId))
      }
      await this.getInitiationList()
    }
  },
  created () {
    this.getJobTypeList()
    this.getJobClassList()
  },
  async mounted () {
    this.onHandleInit()
  }
}
</script>

<style>
.editr--content ul {
  list-style: circle;
}
</style>
