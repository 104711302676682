<template>
  <b-row>
    <b-col lg="12">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary"
          ><strong>Search & Filter</strong></h4
          >
        </template>
        <template v-slot:body>
          <b-form class="row">
            <b-col cols="12" md="3">
              <b-form-group
                label="Search Shipyard"
                label-for="ship-yard"
              >
                <b-form-input
                  id="shipyard"
                  class="w-100"
                  type="text"
                  placeholder="Search..."
                  v-model="lists.params.name"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="2">
              <b-form-group label="Search" label-for="button_search">
                <b-button type="submit" @click.prevent="getShipyardsData()" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
              </b-form-group>
            </b-col>
          </b-form>
        </template>
      </iq-card>
    </b-col>
    <b-col sm="8">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">Shipyard List</h4>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col cols="12">
              <div class="table-responsive">
                <table class="table mb-0 table-striped table-hover">
                  <thead class="thead-dark text-center">
                  <tr>
                    <th>No.</th>
                    <th>Nama</th>
                    <th>No Tlp.</th>
                    <th>Email</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in lists.data">
                    <td>{{index+1}}.</td>
                    <td>{{ data.name }}</td>
                    <td>{{ data.phone }}</td>
                    <td>{{ data.email }}</td>
                    <td>
                      <span class="text-info mr-1 cursor-pointer" @click="getShipyardDetailData(data.id)"><i class="fa fa-edit" /></span>
                      <span class="text-danger cursor-pointer" @click="deleteShipyardData(data.id)"><i class="fa fa-trash" /></span>
                    </td>
                  </tr>
                  <tr v-if="lists.data.length === 0 && !lists.isLoading">
                    <td colspan="5">
                      No Data
                    </td>
                  </tr>
                  <tr v-if="lists.isLoading">
                    <td colspan="5">
                      Loading
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col class="mt-4">
              <b-pagination
                :value="lists.meta.currentPage"
                v-model="lists.meta.currentPage"
                :total-rows="lists.meta.total"
                @change="getShipyardsData"
                :per-page="lists.meta.perPage"
                first-number
                align="center"
              ></b-pagination>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
    <b-col sm="4">
      <iq-card
        :class="{
      'border border-danger shadow-lg': form.id,
              }"
      >
        <template v-slot:headerTitle>
          <h4 class="card-title">{{form.id ? 'Edit' : 'Add'}} Shipyard</h4>
        </template>
        <template v-slot:body>
          <b-form @submit.prevent="onSave()">
            <b-form-group label="Shipyard Name" label-for="shipyard_name">
              <b-form-input id="Power Name" required v-model="form.name" type="text" placeholder="Shipyard Name" maxlength="100"></b-form-input>
            </b-form-group>
            <b-form-group label="Email" label-for="email">
              <b-form-input id="email" type="email" v-model="form.email" required placeholder="User @" maxlength="100"></b-form-input>
            </b-form-group>
            <b-form-group label="Phone" label-for="phone">
              <b-form-input id="phone" type="number" v-model="form.phone" required maxlength="50"></b-form-input>
            </b-form-group>
            <b-form-group label-for="Address" label="Address">
              <b-form-textarea id="address" v-model="form.address" rows="3" placeholder="Address"></b-form-textarea>
            </b-form-group>
            <b-form-group class="text-right">
              <b-button :disabled="form.saving" type="submit" variant="primary">
                <span>Save</span>
              </b-button>
              <b-button @click="resetForm()" variant="danger" class="iq-bg-danger ml-3">Cancel</b-button>
            </b-form-group>
          </b-form>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import { dockingActions } from '@src/Utils/helper'

export default {
  name: 'DockingMasterDataShipyard',
  data () {
    return {
      lists: {
        isLoading: true,
        data: [],
        params: {
          name: '',
          jobCategoryId: '',
          page: 1,
          perPage: 10,
          isActive: true
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      form: {
        id: null,
        saving: false,
        name: '',
        phone: '',
        address: '',
        email: '',
        active: true,
      }
    }
  },
  mounted () {
    this.getShipyardsData()
  },
  methods: {
    ...dockingActions,
    async getShipyardsData (page = 1) {
      this.lists.params.page = page
      let res = await this.getShipyards(this.lists.params)
      this.lists.isLoading = true

      if (res.status === 'success') {
        if (res.data) {
          this.lists.data = res.data.data
          this.lists.meta.perPage = res.data.perPage
          this.lists.meta.currentPage = res.data.currentPage
          this.lists.meta.total = res.data.total
          this.lists.meta.totalPage = res.data.totalPage
        }
        this.lists.isLoading = false
      } else {
        this.lists.data = []
        this.lists.isLoading = false
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async getShipyardDetailData (id) {
      if (id) {
        const { status, data } = await this.getShipyardDetail({
          id
        })

        if (status === 'success') {
          if (data) {
            this.form.id = data.id
            this.form.name = data.name
            this.form.active = data.active
            this.form.phone = data.phone
            this.form.address = data.address
            this.form.email = data.email
          }
        }
      }
    },
    resetForm () {
      this.form = {
        id: null,
        saving: false,
        name: '',
        phone: '',
        address: '',
        email: '',
        active: true,
      }
    },
    async onSave () {
      this.form.saving = true

      let method = 'saveShipyard'

      if (this.form.id) {
        method = 'updateShipyard'
      }

      const { status } = await this[method](this.form)

      if (status !== 'success') {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        this.form.saving = false
        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      this.resetForm()
      this.getShipyardsData(1)

      this.form.saving = false
    },
    async deleteShipyardData (id) {
      if (id) {
        this.$swal({
          title: 'Yakin menghapus data ?',
          text: `Periksa Kembali Seluruh Data`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Hapus'
        }).then(async (result) => {
          if (result.isConfirmed) {
            const { status, data } = await this.deleteShipyard({
              id
            })

            if (status === 'success') {
              this.$swal(
                `Data Terhapus`,
                '',
                'success'
              )
              await this.getShipyardsData(1)
            } else {
              this.$swal(
                `Oops!`,
                data.message,
                'error'
              )
            }
          }
        })
      }
    },
  }
}
</script>

<style scoped>

</style>
