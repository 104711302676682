var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Equal-width multi-row")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-1",
            modifiers: {
              "collapse-1": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Create equal-width columns that span multiple rows by inserting a "), _c('code', [_vm._v(".w-100")]), _vm._v(" where you want the columns to break to a new line. Make the breaks responsive by mixing the "), _c('code', [_vm._v(".w-100")]), _vm._v(" with some "), _c('a', {
          attrs: {
            "href": "/docs/4.3/utilities/display/"
          }
        }, [_vm._v("responsive display utilities")]), _vm._v(".")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-1"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "media-1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-media>\n  <template v-slot:aside>\n    <b-img :src=\"require('../../assets/images/page-img/15.jpg')\" alt=\"#\"></b-img>\n  </template>\n  <b-media-body>\n    <h5 class=\"mt-0\">Media heading</h5>\n    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.\n  </b-media-body>\n</b-media>\n<b-media>\n    <template v-slot:aside>\n    <b-img :src=\"require('../../assets/images/page-img/16.jpg')\" alt=\"#\"></b-img>\n  </template>\n  <b-media-body>\n    <h5 class=\"mt-0\">Media heading</h5>\n    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.\n    <b-media no-body class=\"mt-3\">\n      <b-media-aside tag=\"a\">\n        <b-img :src=\"require('../../assets/images/page-img/17.jpg')\" class=\"mr-3\" alt=\"#\"></b-img>\n      </b-media-aside>\n      <b-media-body>\n        <h5 class=\"mt-0\">Media heading</h5>\n        Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.\n      </b-media-body>\n    </b-media>\n  </b-media-body>\n</b-media>\n")])])])])]), _c('b-media', {
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-img', {
                attrs: {
                  "src": require('../../assets/images/page-img/15.jpg'),
                  "alt": "#"
                }
              })];
            },
            proxy: true
          }])
        }, [_c('b-media-body', [_c('h5', {
          staticClass: "mt-0"
        }, [_vm._v("Media heading")]), _vm._v(" Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. ")])], 1), _c('b-media', {
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-img', {
                attrs: {
                  "src": require('../../assets/images/page-img/16.jpg'),
                  "alt": "#"
                }
              })];
            },
            proxy: true
          }])
        }, [_c('b-media-body', [_c('h5', {
          staticClass: "mt-0"
        }, [_vm._v("Media heading")]), _vm._v(" Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. "), _c('b-media', {
          staticClass: "mt-3",
          attrs: {
            "no-body": ""
          }
        }, [_c('b-media-aside', {
          attrs: {
            "tag": "a"
          }
        }, [_c('b-img', {
          staticClass: "mr-3",
          attrs: {
            "src": require('../../assets/images/page-img/17.jpg'),
            "alt": "#"
          }
        })], 1), _c('b-media-body', [_c('h5', {
          staticClass: "mt-0"
        }, [_vm._v("Media heading")]), _vm._v(" Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. ")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Order")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-2",
            modifiers: {
              "collapse-2": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Change the order of content in media objects by modifying the HTML itself, or by adding some custom flexbox CSS to set the "), _c('code', [_vm._v("order")]), _vm._v(" property (to an integer of your choosing).")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-2"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "media-2"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-media right-align class=\"mb-5\">\n  <template v-slot:aside>\n    <b-img :src=\"require('../../assets/images/page-img/18.jpg')\" alt=\"#\"></b-img>\n  </template>\n  <b-media-body>\n    <h5 class=\"mt-0 mb-1\">Top-aligned media</h5>\n    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.\n  </b-media-body>\n</b-media>\n<b-media right-align vertical-align=\"center\" class=\"mb-5\">\n  <template v-slot:aside>\n    <b-img :src=\"require('../../assets/images/page-img/19.jpg')\" alt=\"#\"></b-img>\n  </template>\n  <b-media-body>\n    <h5 class=\"mt-0 mb-1\">Center-aligned media</h5>\n    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.\n  </b-media-body>\n</b-media>\n<b-media right-align vertical-align=\"end\">\n  <template v-slot:aside>\n    <b-img :src=\"require('../../assets/images/page-img/20.jpg')\" alt=\"#\"></b-img>\n  </template>\n  <b-media-body>\n    <h5 class=\"mt-0 mb-1\">Bottom-aligned media</h5>\n    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.\n  </b-media-body>\n</b-media>\n")])])])])]), _c('b-media', {
          staticClass: "mb-5",
          attrs: {
            "right-align": ""
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-img', {
                attrs: {
                  "src": require('../../assets/images/page-img/18.jpg'),
                  "alt": "#"
                }
              })];
            },
            proxy: true
          }])
        }, [_c('b-media-body', [_c('h5', {
          staticClass: "mt-0 mb-1"
        }, [_vm._v("Top-aligned media")]), _vm._v(" Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. ")])], 1), _c('b-media', {
          staticClass: "mb-5",
          attrs: {
            "right-align": "",
            "vertical-align": "center"
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-img', {
                attrs: {
                  "src": require('../../assets/images/page-img/19.jpg'),
                  "alt": "#"
                }
              })];
            },
            proxy: true
          }])
        }, [_c('b-media-body', [_c('h5', {
          staticClass: "mt-0 mb-1"
        }, [_vm._v("Center-aligned media")]), _vm._v(" Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. ")])], 1), _c('b-media', {
          attrs: {
            "right-align": "",
            "vertical-align": "end"
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-img', {
                attrs: {
                  "src": require('../../assets/images/page-img/20.jpg'),
                  "alt": "#"
                }
              })];
            },
            proxy: true
          }])
        }, [_c('b-media-body', [_c('h5', {
          staticClass: "mt-0 mb-1"
        }, [_vm._v("Bottom-aligned media")]), _vm._v(" Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. ")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6",
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Media object")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-3",
            modifiers: {
              "collapse-3": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("The images or other media can be aligned top, middle, or bottom. The default is top aligned.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-3"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "media-3"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<b-media class=\"mb-4\">\n  <template v-slot:aside>\n    <b-img :src=\"require('../../assets/images/page-img/21.jpg')\" alt=\"#\"></b-img>\n  </template>\n  <b-media-body>\n    <h5 class=\"mt-0\">Top-aligned media</h5>\n    <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>\n  </b-media-body>\n</b-media>\n<b-media vertical-align=\"center\" class=\"mb-4\">\n  <template v-slot:aside>\n    <b-img :src=\"require('../../assets/images/page-img/22.jpg')\" alt=\"#\"></b-img>\n  </template>\n  <b-media-body>\n    <h5 class=\"mt-0 mb-1\">Center-aligned media</h5>\n    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.\n  </b-media-body>\n</b-media>\n<b-media vertical-align=\"end\">\n  <template v-slot:aside>\n    <b-img :src=\"require('../../assets/images/page-img/23.jpg')\" alt=\"#\"></b-img>\n  </template>\n  <b-media-body>\n    <h5 class=\"mt-0 mb-1\">Bottom-aligned media</h5>\n    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.\n  </b-media-body>\n</b-media>\n")])])])])]), _c('b-media', {
          staticClass: "mb-4",
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-img', {
                attrs: {
                  "src": require('../../assets/images/page-img/21.jpg'),
                  "alt": "#"
                }
              })];
            },
            proxy: true
          }])
        }, [_c('b-media-body', [_c('h5', {
          staticClass: "mt-0"
        }, [_vm._v("Top-aligned media")]), _c('p', [_vm._v("Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.")])])], 1), _c('b-media', {
          staticClass: "mb-4",
          attrs: {
            "vertical-align": "center"
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-img', {
                attrs: {
                  "src": require('../../assets/images/page-img/22.jpg'),
                  "alt": "#"
                }
              })];
            },
            proxy: true
          }])
        }, [_c('b-media-body', [_c('h5', {
          staticClass: "mt-0 mb-1"
        }, [_vm._v("Center-aligned media")]), _vm._v(" Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. ")])], 1), _c('b-media', {
          attrs: {
            "vertical-align": "end"
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-img', {
                attrs: {
                  "src": require('../../assets/images/page-img/23.jpg'),
                  "alt": "#"
                }
              })];
            },
            proxy: true
          }])
        }, [_c('b-media-body', [_c('h5', {
          staticClass: "mt-0 mb-1"
        }, [_vm._v("Bottom-aligned media")]), _vm._v(" Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. ")])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Media list")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-4",
            modifiers: {
              "collapse-4": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Because the media object has so few structural requirements, you can also use these classes on list HTML elements. On your "), _c('code', [_vm._v("<ul>")]), _vm._v(" or "), _c('code', [_vm._v("<ol>")]), _vm._v(", add the "), _c('code', [_vm._v(".list-unstyled")]), _vm._v(" to remove any browser default list styles, and then apply "), _c('code', [_vm._v(".media")]), _vm._v(" to your "), _c('code', [_vm._v("<li>")]), _vm._v("s. As always, use spacing utilities wherever needed to fine tune.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-4"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "media-4"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<ul class=\"list-unstyled\">\n  <b-media tag=\"li\">\n    <template v-slot:aside>\n      <b-img :src=\"require('../../assets/images/page-img/24.jpg')\" alt=\"#\"></b-img>\n    </template>\n    <b-media-body>\n      <h5 class=\"mt-0\">List-based media object</h5>\n      <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>\n    </b-media-body>\n  </b-media>\n  <b-media tag=\"li\" class=\"my-4\">\n    <template v-slot:aside>\n      <b-img :src=\"require('../../assets/images/page-img/15.jpg')\" alt=\"#\"></b-img>\n    </template>\n    <b-media-body>\n      <h5 class=\"mt-0 mb-1\">List-based media object</h5>\n      Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.\n    </b-media-body>\n  </b-media>\n  <b-media tag=\"li\">\n    <template v-slot:aside>\n      <b-img :src=\"require('../../assets/images/page-img/16.jpg')\" alt=\"#\"></b-img>\n    </template>\n    <b-media-body>\n      <h5 class=\"mt-0 mb-1\">List-based media object</h5>\n      Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.\n    </b-media-body>\n  </b-media>\n</ul>\n")])])])])]), _c('ul', {
          staticClass: "list-unstyled"
        }, [_c('b-media', {
          attrs: {
            "tag": "li"
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-img', {
                attrs: {
                  "src": require('../../assets/images/page-img/24.jpg'),
                  "alt": "#"
                }
              })];
            },
            proxy: true
          }])
        }, [_c('b-media-body', [_c('h5', {
          staticClass: "mt-0"
        }, [_vm._v("List-based media object")]), _c('p', [_vm._v("Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.")])])], 1), _c('b-media', {
          staticClass: "my-4",
          attrs: {
            "tag": "li"
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-img', {
                attrs: {
                  "src": require('../../assets/images/page-img/15.jpg'),
                  "alt": "#"
                }
              })];
            },
            proxy: true
          }])
        }, [_c('b-media-body', [_c('h5', {
          staticClass: "mt-0 mb-1"
        }, [_vm._v("List-based media object")]), _vm._v(" Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. ")])], 1), _c('b-media', {
          attrs: {
            "tag": "li"
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-img', {
                attrs: {
                  "src": require('../../assets/images/page-img/16.jpg'),
                  "alt": "#"
                }
              })];
            },
            proxy: true
          }])
        }, [_c('b-media-body', [_c('h5', {
          staticClass: "mt-0 mb-1"
        }, [_vm._v("List-based media object")]), _vm._v(" Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus. ")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }