<template>
  <div>
    <div class="container" style="margin-top: 10px;">
      <router-view/>
    </div>
  </div>
</template>
<script>
import Loader from '../components/xray/loader/Loader'
export default {
  name: 'BlankLayout',
  components: {
    Loader
  },
}
</script>
<style>
  @import url("../assets/css/custom.css");
  .modal-backdrop {
    opacity: .5;
  }
  @media  print
  {
    body
    {
      font-size:70%;
    }
    .no-print, .no-print *
    {
      display: none !important;
    }
  }
</style>
