var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h4', {
          staticClass: "mb-2 mt-2"
        }, [_vm._v("Confirm")]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.info.cases ? _vm.info.cases : 183325))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "ri-task-line"
        })])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h4', {
          staticClass: "mb-2 mt-2"
        }, [_vm._v("Recovered")]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.info.recovered ? _vm.info.recovered : 79908))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "ri-hospital-line"
        })])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h4', {
          staticClass: "mb-2 mt-2"
        }, [_vm._v("Death")]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.info.deaths ? _vm.info.deaths : 7177))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-danger"
        }, [_c('i', {
          staticClass: "ri-gradienter-line"
        })])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    }
  }, [_c('div', {
    staticClass: "iq-card-body P-0 rounded",
    style: "background: url(".concat(require('../../assets/images/page-img/44.jpg'), ") no-repeat scroll center center; background-size: contain; min-height: 127px;")
  })])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('MapAmChart', {
          attrs: {
            "element": "world-map",
            "styles": "height: 400px; position: relative;"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Global record")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-borderless"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Country")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Confirm")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Recover")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Death")])])]), _c('tbody', [_c('tr', [_c('td', [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/small/china.png"),
            "alt": "country-flag"
          }
        }), _vm._v(" China")]), _c('td', [_vm._v("80,967")]), _c('td', [_vm._v("972")]), _c('td', [_vm._v("3248")])]), _c('tr', [_c('td', [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/small/france.png"),
            "alt": "country-flag"
          }
        }), _vm._v(" France")]), _c('td', [_vm._v("10,995")]), _c('td', [_vm._v("245")]), _c('td', [_vm._v("372")])]), _c('tr', [_c('td', [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/small/germany.png"),
            "alt": "country-flag"
          }
        }), _vm._v(" Germany")]), _c('td', [_vm._v("15,320")]), _c('td', [_vm._v("242")]), _c('td', [_vm._v("44")])]), _c('tr', [_c('td', [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/small/iran.png"),
            "alt": "country-flag"
          }
        }), _vm._v(" Iran")]), _c('td', [_vm._v("18,407")]), _c('td', [_vm._v("520")]), _c('td', [_vm._v("1284")])]), _c('tr', [_c('td', [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/small/italy.png"),
            "alt": "country-flag"
          }
        }), _vm._v(" Italy")]), _c('td', [_vm._v("41,035")]), _c('td', [_vm._v("10,600")]), _c('td', [_vm._v("3405")])]), _c('tr', [_c('td', [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/small/netherlands.png"),
            "alt": "country-flag"
          }
        }), _vm._v(" Netherlands")]), _c('td', [_vm._v("2460")]), _c('td', [_vm._v("24")]), _c('td', [_vm._v("76")])]), _c('tr', [_c('td', [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/small/norway.png"),
            "alt": "country-flag"
          }
        }), _vm._v(" Norway")]), _c('td', [_vm._v("789")]), _c('td', [_vm._v("135")]), _c('td', [_vm._v("35")])]), _c('tr', [_c('td', [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/small/south-koria.png"),
            "alt": "country-flag"
          }
        }), _vm._v(" South Korea")]), _c('td', [_vm._v("1200")]), _c('td', [_vm._v("500")]), _c('td', [_vm._v("600")])]), _c('tr', [_c('td', [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/small/spain.png"),
            "alt": "country-flag"
          }
        }), _vm._v(" Spain")]), _c('td', [_vm._v("18,077")]), _c('td', [_vm._v("150")]), _c('td', [_vm._v("831")])]), _c('tr', [_c('td', [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/small/sweden.png"),
            "alt": "country-flag"
          }
        }), _vm._v(" Sweden")]), _c('td', [_vm._v("800")]), _c('td', [_vm._v("250")]), _c('td', [_vm._v("25")])]), _c('tr', [_c('td', [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/small/switzerlands.png"),
            "alt": "country-flag"
          }
        }), _vm._v(" SwizerLand")]), _c('td', [_vm._v("4222")]), _c('td', [_vm._v("125")]), _c('td', [_vm._v("43")])]), _c('tr', [_c('td', [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/small/usa.png"),
            "alt": "country-flag"
          }
        }), _vm._v(" United States")]), _c('td', [_vm._v("14354")]), _c('td', [_vm._v("150")]), _c('td', [_vm._v("277")])]), _c('tr', [_c('td', [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/small/india.png"),
            "alt": "country-flag"
          }
        }), _vm._v(" India")]), _c('td', [_vm._v("197")]), _c('td', [_vm._v("50")]), _c('td', [_vm._v("4")])])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("How Avoid Virus infection")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "media mb-4"
        }, [_c('img', {
          staticClass: "align-self-start mr-3 avatar-80",
          attrs: {
            "src": require("../../assets/images/page-img/45.png"),
            "alt": "blog-img"
          }
        }), _c('div', {
          staticClass: "media-body"
        }, [_c('h5', {
          staticClass: "mt-0"
        }, [_vm._v("Wash your hands frequently")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Regularly and thoroughly clean your hands with an alcohol-based hand rub or wash them with soap and water.")])])]), _c('div', {
          staticClass: "media mb-4"
        }, [_c('img', {
          staticClass: "align-self-start mr-3 avatar-80",
          attrs: {
            "src": require("../../assets/images/page-img/46.png"),
            "alt": "blog-img"
          }
        }), _c('div', {
          staticClass: "media-body"
        }, [_c('h5', {
          staticClass: "mt-0"
        }, [_vm._v("Maintain social distancing")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Maintain at least 1 metre (3 feet) distance between yourself and anyone who is coughing or sneezing.")])])]), _c('div', {
          staticClass: "media mb-4"
        }, [_c('img', {
          staticClass: "align-self-start mr-3 avatar-80",
          attrs: {
            "src": require("../../assets/images/page-img/47.png"),
            "alt": "blog-img"
          }
        }), _c('div', {
          staticClass: "media-body"
        }, [_c('h5', {
          staticClass: "mt-0"
        }, [_vm._v("Avoid touching eyes, nose and mouth")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Hands touch many surfaces and can pick up viruses. Once contaminated, hands can transfer the virus to your eyes, nose or mouth. From there, the virus can enter your body and can make you sick.")])])]), _c('div', {
          staticClass: "media"
        }, [_c('img', {
          staticClass: "align-self-start mr-3 avatar-80",
          attrs: {
            "src": require("../../assets/images/page-img/48.png"),
            "alt": "blog-img"
          }
        }), _c('div', {
          staticClass: "media-body"
        }, [_c('h5', {
          staticClass: "mt-0"
        }, [_vm._v("Practice respiratory hygiene ")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Make sure you, and the people around you, follow good respiratory hygiene. This means covering your mouth and nose with your bent elbow or tissue when you cough or sneeze. Then dispose of the used tissue immediately.")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "row",
    attrs: {
      "id": "contact-slider"
    }
  }, [_c('b-col', {
    attrs: {
      "sm": "6",
      "md": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('img', {
          staticClass: "arrow-img w-auto mb-3",
          attrs: {
            "src": require("../../assets/images/small/china.png"),
            "alt": "iconflag"
          }
        }), _c('h5', {
          staticClass: "contact-number"
        }, [_c('i', {
          staticClass: "ion ion-android-call",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v(" + 1 800 324 2323 ")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Help line China")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('img', {
          staticClass: "arrow-img w-auto mb-3",
          attrs: {
            "src": require("../../assets/images/small/france.png"),
            "alt": "iconflag"
          }
        }), _c('h5', {
          staticClass: "contact-number"
        }, [_c('i', {
          staticClass: "ion ion-android-call",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v(" + 1 800 324 2323 ")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Help line France")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('img', {
          staticClass: "arrow-img w-auto mb-3",
          attrs: {
            "src": require("../../assets/images/small/iran.png"),
            "alt": "iconflag"
          }
        }), _c('h5', {
          staticClass: "contact-number"
        }, [_c('i', {
          staticClass: "ion ion-android-call",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v(" + 1 800 324 2323 ")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Help line Iran")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('img', {
          staticClass: "arrow-img w-auto mb-3",
          attrs: {
            "src": require("../../assets/images/small/germany.png"),
            "alt": "iconflag"
          }
        }), _c('h5', {
          staticClass: "contact-number"
        }, [_c('i', {
          staticClass: "ion ion-android-call",
          attrs: {
            "aria-hidden": "true"
          }
        }), _vm._v(" + 1 800 324 2323 ")]), _c('p', {
          staticClass: "mb-0"
        }, [_vm._v("Help line Germany")])];
      },
      proxy: true
    }])
  })], 1)], 1)]), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "row",
    attrs: {
      "id": "blog-slider"
    }
  }, [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "image-block"
        }, [_c('img', {
          staticClass: "img-fluid w-100",
          attrs: {
            "src": require("../../assets/images/page-img/40.png"),
            "alt": "blog-img"
          }
        })]), _c('div', {
          staticClass: "blog-description"
        }, [_c('div', {
          staticClass: "blog-meta"
        }, [_c('a', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("March 21, 2020")])]), _c('h4', {
          staticClass: "mb-2"
        }, [_vm._v("Containing coronavirus spread comes")]), _c('p', [_vm._v("In the blogpost, the IMF experts observed, \"Success in containing the virus comes at the price of slowing economic activity, no matter whether social distancing and reduced mobility are voluntary or enforced.\"")]), _c('a', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Read More "), _c('i', {
          staticClass: "ri-arrow-right-s-line"
        })])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "image-block"
        }, [_c('img', {
          staticClass: "img-fluid w-100",
          attrs: {
            "src": require("../../assets/images/page-img/40.png"),
            "alt": "blog-img"
          }
        })]), _c('div', {
          staticClass: "blog-description"
        }, [_c('div', {
          staticClass: "blog-meta"
        }, [_c('a', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("March 21, 2020")])]), _c('h4', {
          staticClass: "mb-2"
        }, [_vm._v("Containing coronavirus spread comes")]), _c('p', [_vm._v("In the blogpost, the IMF experts observed, \"Success in containing the virus comes at the price of slowing economic activity, no matter whether social distancing and reduced mobility are voluntary or enforced.\"")]), _c('a', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Read More "), _c('i', {
          staticClass: "ri-arrow-right-s-line"
        })])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "image-block"
        }, [_c('img', {
          staticClass: "img-fluid w-100",
          attrs: {
            "src": require("../../assets/images/page-img/40.png"),
            "alt": "blog-img"
          }
        })]), _c('div', {
          staticClass: "blog-description"
        }, [_c('div', {
          staticClass: "blog-meta"
        }, [_c('a', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("March 21, 2020")])]), _c('h4', {
          staticClass: "mb-2"
        }, [_vm._v("Containing coronavirus spread comes")]), _c('p', [_vm._v("In the blogpost, the IMF experts observed, \"Success in containing the virus comes at the price of slowing economic activity, no matter whether social distancing and reduced mobility are voluntary or enforced.\"")]), _c('a', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Read More "), _c('i', {
          staticClass: "ri-arrow-right-s-line"
        })])])];
      },
      proxy: true
    }])
  })], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }