var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal---".concat(_vm.id),
      "size": "md",
      "title": "Pending Docking",
      "header-bg-variant": "light",
      "header-text-variant": "dark",
      "hide-footer": "",
      "footer-bg-variant": "light",
      "footer-text-variant": "dark"
    },
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit();
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pending Date *",
      "label-for": "pending_date"
    }
  }, [_c('date-picker', {
    attrs: {
      "required": "",
      "id": "pending_date",
      "value-type": "DD-MM-YYYY",
      "type": "date",
      "placeholder": "Select date",
      "format": "DD MMM YYYY"
    },
    model: {
      value: _vm.form.pendingDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pendingDate", $$v);
      },
      expression: "form.pendingDate"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Reason Pending",
      "label-for": "reason_pending"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "reason_pending",
      "rows": "3"
    },
    model: {
      value: _vm.form.reason,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reason", $$v);
      },
      expression: "form.reason"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "ml-2 text-center",
    attrs: {
      "type": "submit",
      "block": "",
      "disabled": _vm.form.saving,
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-calendar mr-2"
  }), _vm._v(" Pending Docking ")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }