<template>
  <b-container v-if="fleetDetail" fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>OPERATIONAL INPUT - {{fleetDetail.name}}</strong></h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col cols="6" md="6">
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Select Month First *">
                      <date-picker v-model="periodMonthYear" value-type="YYYY-MM" @change="generateYearMonthFormParams" type="month" placeholder="Select Month" format="MMM YYYY"></date-picker>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Open">
                      <b-button variant="primary" @click="getFcActivirites()" class="mb-3 mr-1"><i class="fa fa-filter"></i></b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="6" md="6"></b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <iq-card v-for="(activity, index) in form.activities">
          <template v-slot:body>
            <b-button
              v-if="index !== 0"
              class="float-right"
              size="sm"
              title="Delete Certificate"
              variant="danger"
              @click.prevent="removeActivity(index)"
            ><i class="fa fa-trash"></i></b-button>
            <b-row>
              <b-col lg="3">
                <b-row>
                  <b-col md="2"><span class="font-size-24 font-weight-bold text-black">#{{parseInt(1+index)}}</span></b-col>
                  <b-col md="10" class="mt-2">
                    <small style="margin-right: 5px;"> Mother Vessel (Owned) </small>
                    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                      <div class="custom-switch-inner">
                        <input type="checkbox" class="custom-control-input" :class="'bg-success'" :id="'mvOwned' + index" v-model="activity.isMvOwned">
                        <label class="custom-control-label" :for="'mvOwned' + index" data-on-label="Yes" data-off-label="No">
                        </label>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="2"></b-col>
                  <b-col md="10" style="margin-top: 5px;">
                    <template v-if="activity.isMvOwned">
                      <b-form-group label="Select MV *">
                        <v-select v-model="activity.mvId" :options="motherVesselVehicles" label="name" :reduce="(vehicle) => vehicle.code" placeholder="Select Vehicle"></v-select>
                      </b-form-group>
                    </template>
                    <template v-else>
                      <b-form-group label="Input Your MV *">
                        <b-form-input v-model="activity.mvName" type="text" placeholder="Input MV Name"></b-form-input>
                      </b-form-group>
                    </template>
                  </b-col>
                </b-row>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Cargo Loaded on Vessel *" class="mt-5">
                  <b-input-group size="md" append="Ton">
                    <b-form-input v-model="activity.cargoLoaded" type="number"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <hr/>
            <b-row v-for="(detail, indexDetail) in activity.detail">
              <b-col md="1" class="text-right"><span class="font-size-24 font-weight-bold text-black">#{{parseInt(1+indexDetail)}}</span></b-col>
              <b-col md="11" class="mt-2">
                <b-button
                  v-if="indexDetail !== 0"
                  class="float-right"
                  size="sm"
                  title="Delete Certificate"
                  variant="danger"
                  @click.prevent="removeActivityDetail(index, indexDetail)"
                ><i class="fa fa-trash"></i></b-button>
                <b-row>
                  <b-col md="12">
                    <small style="margin-right: 5px;"> With Barge ? </small>
                    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                      <div class="custom-switch-inner">
                        <input type="checkbox" class="custom-control-input" :class="'bg-success'" :id="`withBarge-${index}-${indexDetail}`" v-model="detail.withBarge">
                        <label class="custom-control-label" :for="`withBarge-${index}-${indexDetail}`" data-on-label="Yes" data-off-label="No">
                        </label>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="12" class="mt-1">
                    <template v-if="detail.withBarge">
                      <b-row>
                        <b-col md="3">
                          <div class="form-group">
                            <label>Barge (Owned)</label>
                            <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color ml-2">
                              <div class="custom-switch-inner">
                                <input type="checkbox" class="custom-control-input" :class="'bg-success'" :id="`bgOwned-${index}-${indexDetail}`" v-model="detail.isBargeOwned">
                                <label class="custom-control-label" :for="`bgOwned-${index}-${indexDetail}`" data-on-label="Yes" data-off-label="No">
                                </label>
                              </div>
                            </div>
                            <template v-if="detail.isBargeOwned">
                              <v-select v-model="detail.bargeId" :options="bargeVehicles" label="name" :reduce="(vehicle) => vehicle.code" placeholder="Select Vehicle"></v-select>
                            </template>
                            <template v-else>
                              <b-form-input v-model="detail.bargeName" type="text" placeholder="Input Your Barge"></b-form-input>
                            </template>
                          </div>
                        </b-col>
                        <b-col md="2" class="mr-3">
                          <b-form-group label="A/Side">
                            <date-picker v-model="detail.side" @close="validateAllDatesActivityDetail(index, indexDetail, 'side')" type="datetime" placeholder="Select date time" value-type="DD-MM-YYYY HH:mm" format="DD MMM YYYY HH:mm"></date-picker>
                          </b-form-group>
                        </b-col>
                        <b-col md="2" class="mr-3">
                          <b-form-group label="Comm *">
                            <date-picker v-model="detail.comm" @close="validateAllDatesActivityDetail(index, indexDetail, 'comm')" type="datetime" placeholder="Select date time" value-type="DD-MM-YYYY HH:mm" format="DD MMM YYYY HH:mm"></date-picker>
                          </b-form-group>
                        </b-col>
                        <b-col md="2" class="mr-3">
                          <b-form-group label="Compl *">
                            <date-picker v-model="detail.compl" @close="validateAllDatesActivityDetail(index, indexDetail, 'compl')" type="datetime" placeholder="Select date time" value-type="DD-MM-YYYY HH:mm" format="DD MMM YYYY HH:mm"></date-picker>
                          </b-form-group>
                        </b-col>
                        <b-col md="2" class="mr-3">
                          <b-form-group label="Cast Off *">
                            <date-picker v-model="detail.castOf" @close="validateAllDatesActivityDetail(index, indexDetail, 'castOf')" type="datetime" placeholder="Select date time" value-type="DD-MM-YYYY HH:mm" format="DD MMM YYYY HH:mm"></date-picker>
                          </b-form-group>
                        </b-col>
                        <b-col md="1"></b-col>
                      </b-row>
                      <b-row>
                        <b-col md="2">
                          <b-form-group label="Draft Survey *">
                            <b-input-group size="md" append="Ton">
                              <b-form-input v-model="detail.cargoDraft" @change="calculateDBS(index, indexDetail)" type="number"></b-form-input>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                        <b-col md="2">
                          <b-form-group label="Belt Scale FTS *">
                            <b-input-group size="md" append="Ton">
                              <b-form-input v-model="detail.beltScale" @change="calculateDBS(index, indexDetail)" type="number"></b-form-input>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                        <b-col md="2">
                          <b-form-group label="D B/S *">
                            <b-input-group size="md" append="Ton">
                              <b-form-input v-model="detail.cargoDbs" @change="calculateDBS(index, indexDetail)" type="number"></b-form-input>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                        <b-col md="2">
                          <b-form-group label="Portion Tonnage *">
                            <b-input-group size="md" append="Ton">
                              <b-form-input v-model="detail.portionTonnage" type="number"></b-form-input>
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                        <b-col md="2">
                          <b-form-group label="Origin Cargo *">
                            <b-form-input v-model="detail.cargoOrigin" type="text"></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col md="2">
                          <b-form-group label="Cargo Hold *">
                            <b-form-input v-model="detail.cargoHold" type="text"></b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </template>
                    <b-row>
                      <b-col md="12">
                        <b-alert :show="true" variant="secondary">
                          <div class="iq-alert-text text-center">IDLE TIME & NOT TO COUNT</div>
                        </b-alert>
                      </b-col>
                      <b-col md="7">
                        <b-row>
                          <b-col md="4">
                            <b-form-group label="Idle">
                              <v-select v-model="detail.idleParamType" label="text" :options="paramIdles" :reduce="idle => idle.value" placeholder="Select Idle"></v-select>
                            </b-form-group>
                          </b-col>
                          <template v-if="detail.idleParamType === 'Input Start-Finish'">
                            <b-col md="4">
                              <b-form-group label="Start *">
                                <date-picker v-model="detail.idleParamStart" @close="validateAllDatesActivityDetail(index, indexDetail, 'idleParamStart')" type="datetime" placeholder="Select date time" value-type="DD-MM-YYYY HH:mm" format="DD MMM YYYY HH:mm"></date-picker>
                              </b-form-group>
                            </b-col>
                            <b-col md="4">
                              <b-form-group label="Finish *">
                                <date-picker v-model="detail.idleParamEnd" @close="validateAllDatesActivityDetail(index, indexDetail, 'idleParamEnd')" type="datetime" placeholder="Select date time" value-type="DD-MM-YYYY HH:mm" format="DD MMM YYYY HH:mm"></date-picker>
                              </b-form-group>
                            </b-col>
                          </template>
                          <template v-else-if="!detail.idleParamType"><small>Choose your idle!</small></template>
                          <template v-else>
                            <b-col md="6">
                              <b-row>
                                <b-col md="6">
                                  <b-form-group label="Select Date *">
                                    <date-picker v-model="detail.idleParamEnd" type="datetime" placeholder="Select date time" value-type="DD-MM-YYYY HH:mm" format="DD MMM YYYY HH:mm"></date-picker>
                                  </b-form-group>
                                </b-col>
                                <b-col md="6" class="mt-2">
                                  <b-form-group label="Maneuvering ?">
                                    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                                      <div class="custom-switch-inner">
                                        <input type="checkbox" class="custom-control-input" @change="validateManeuvering(index, indexDetail)" :class="'bg-success'" :id="`connectmaneuvering-${index}-${indexDetail}`" v-model="detail.isManeuvering">
                                        <label class="custom-control-label" :for="`connectmaneuvering-${index}-${indexDetail}`" data-on-label="Yes" data-off-label="No">
                                        </label>
                                      </div>
                                    </div>
                                  </b-form-group>
                                  <b-form-group label="trigger subtraction from ?" v-if="detail.isManeuvering">
                                    <v-select v-model="detail.manuveringReff" label="text" :options="triggerFCCastOff" :reduce="triggerFCCastOff => triggerFCCastOff.value" placeholder="Select Idle"></v-select>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                          </template>
                        </b-row>
                      </b-col>
                      <b-col md="5">
                        <table class="table table-striped table-box-shadow table-light">
                          <tbody>
                          <tr v-if="detail.withBarge">
                            <td width="55%">Barge Cast Off</td>
                            <td class="font-weight-bold" v-html="calculateBargeCastOffDurations(index, indexDetail)"></td>
                          </tr>
                          <tr v-if="detail.withBarge">
                            <td>Barge Alongside</td>
                            <td class="font-weight-bold" v-html="calculateBargeAlongsideDurations(index, indexDetail)"></td>
                          </tr>
                          <tr>
                            <td>
                              <v-select v-model="detail.idleName" label="text" :disabled="detail.isManeuvering" :options="notToCountAndCountIdles" :reduce="notToCount => notToCount.value" placeholder="Select Idle"></v-select>
                            </td>
                            <td class="font-weight-bold" v-html="calculateIdleTimeDurations(index, indexDetail)"></td>
                          </tr>
                          <tr>
                            <td>Draft Survey</td>
                            <td class="font-weight-bold">
                              <b-input-group size="md" append="Minutes">
                                <b-form-input v-model="detail.idleDraftSurveyMinutes" type="number" value="0"></b-form-input>
                              </b-input-group>
                              <b-input-group size="md" append="Second" class="mt-1">
                                <b-form-input v-model="detail.idleDraftSurveySecond" type="number" value="0"></b-form-input>
                              </b-input-group>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                        <!--                        <b-button size="sm" variant="light" class="mb-3 mr-1 pull-right" @click.prevent="addBarge(index)"><i class="ri-add-line"></i> Add Draft Survey</b-button>-->
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Remarks">
                          <b-form-textarea v-model="detail.remarks" id="remarks" rows="2"></b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <hr/>
                  <b-col md="12">
                    <b-button block size="sm" variant="light" class="mb-3 mr-1" @click.prevent="addActivityDetail(index)"><i class="ri-add-line"></i> Add More Data</b-button>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-button block size="sm" variant="warning" class="mb-3 mr-1" @click.prevent="addActivity()"><i class="ri-add-line"></i> Add Vessel (MV)</b-button>
      </b-col>
      <b-col cols="12" md="12" class="pull-right text-center mt-3 mb-5">
        <b-button type="submit" @click="save()" variant="primary"><i class="fa fa-save"></i> Save</b-button>
        <b-button type="submit" @click="$router.go(-1)" variant="none" class="iq-bg-danger ml-3">Cancel</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import moment from 'moment'
import { fleetsActions, maintenanceActions, voyagesActions } from '@src/Utils/helper'

export default {
  name: 'ProfileEdit',
  async mounted () {
    xray.index()
    this.getIdleList()
    this.fetchFleets()
    this.generateYearMonthFormParams()
    await this.fetchFleetDetail(this.vehicleId)
    await this.getFcActivirites()
  },
  data () {
    return {
      fleets: [],
      fleetDetail: null,
      idle: [],
      triggerFCCastOff: [
        { value: 'cast_off', text: 'Cast Off' },
        { value: 'start', text: 'Start Time (Idle)' },
        { value: 'finish', text: 'Finish Time (Idle)' },
        { value: 'fc_drop_anchor', text: 'FC Drop Anchor' },
        { value: 'fc_anchor_up', text: 'FC Anchor Up' },
        { value: 'fc_arrival', text: 'FC Arrival' }
      ],
      lastActivities: [],
      form: {
        months: '',
        years: '',
        activities: []
      },
      periodMonthYear: moment().format('YYYY-MM')
    }
  },
  computed: {
    vehicleId () {
      return this.$route.query.vehicleId
    },
    motherVesselVehicles () {
      return this.fleets.filter(fleet => fleet.vehicleType.name === 'MOTHER VESSEL')
    },
    bargeVehicles () {
      return this.fleets.filter(fleet => fleet.vehicleType.name === 'BARGE')
    },
    notToCountAndCountIdles () {
      return this.idle.filter(idle => idle.type === 'NOT_TO_COUNT' || idle.type === 'TO_COUNT')
    },
    paramIdles () {
      return this.idle.filter(idle => idle.type === 'PARAM')
    }
  },
  methods: {
    ...fleetsActions,
    ...voyagesActions,
    ...maintenanceActions,
    async fetchFleets () {
      this.fleets = []

      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 100000,
        showAll: true,
        active: true,
        search: ''
      })
      let res = await this.getFleets(params)

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach(el => {
            this.fleets.push({
              name: el.name,
              code: el.id,
              vehicleType: el.vehicleType
            })
          })
        }
      }
    },
    async fetchFleetDetail (id) {
      const res = await this.getFleetById(id)

      if (res.status === 'success') {
        this.fleetDetail = res.data
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
      this.loadingForm = false
    },
    async getFcActivirites () {
      this.form.activities = []
      this.lastActivities = []

      if (!this.periodMonthYear) {
        this.$swal(
          `Oops!`,
          'Pastikan memilih tahun dan bulan terlebih dahulu!',
          'error'
        )
        return
      }

      const responseCurrentActivity = await this.getFcPeriod({
        vehicleId: this.vehicleId,
        year: this.form.years,
        month: this.form.months
      })

      if (responseCurrentActivity.status === 'success') {
        if (responseCurrentActivity.data) {
          this.form.activities = responseCurrentActivity.data.activities
        } else {
          this.addActivity()
        }
      } else {
        this.addActivity()
      }

      let dateBeforeCurrent = moment(this.periodMonthYear).subtract(1, 'months')
      let monthBeforeCurrent = dateBeforeCurrent.format('MM')
      let yearBeforeCurrent = dateBeforeCurrent.year()

      const responseBeforeCurrentActivity = await this.getFcPeriod({
        vehicleId: this.vehicleId,
        year: yearBeforeCurrent,
        month: monthBeforeCurrent
      })

      if (responseBeforeCurrentActivity.status === 'success') {
        if (responseBeforeCurrentActivity.data) {
          this.lastActivities = responseBeforeCurrentActivity.data.activities
        }
      }
    },
    async getIdleList () {
      const res = await this.getIdle({
        params: {
          type: 'PARAM,TO_COUNT,NOT_TO_COUNT'
        }
      })

      if (res.status === 'success') {
        if (res.data) {
          this.idle.push({
            type: 'PARAM',
            value: 'No Idle',
            text: 'No Idle'
          })
          this.idle = res.data.map(idle => {
            return {
              type: idle.type,
              value: idle.name,
              text: idle.name
            }
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
      this.loadingForm = false
    },
    addActivity () {
      let activity = {
        isMvOwned: true,
        mvId: null,
        mvName: null,
        cargoLoaded: 0,
        detail: []
      }

      this.form.activities.push(activity)
      this.addActivityDetail(this.form.activities.length - 1)
    },
    removeActivity (index) {
      this.form.activities.splice(index, 1)
    },
    addActivityDetail (activityIndex) {
      this.form.activities[activityIndex].detail.push({
        withBarge: true,
        bargeId: null,
        bargeName: null,
        side: null,
        comm: null,
        compl: null,
        castOf: null,
        cargoDraft: 0,
        beltScale: 0,
        cargoDbs: 0,
        portionTonnage: 0,
        cargoOrigin: null,
        cargoHold: 0,
        idleParamType: 'No Idle',
        idleParamStart: null,
        idleParamEnd: null,
        isManeuvering: false,
        manuveringReff: null,
        manuveringReffTime: null,
        idleDraftSurvey: 0,
        idleDraftSurveyMinutes: 0,
        idleDraftSurveySecond: 0,
        bargeCastOff: {
          start: null,
          end: null,
          duration: 0
        },
        bargeAlongside: {
          start: null,
          end: null,
          duration: 0
        },
        idleName: null,
        idleTime: {
          start: null,
          end: null,
          duration: 0
        },
        remarks: null,
        isBargeOwned: true
      })
    },
    removeActivityDetail (activityIndex, activityDetailIndex) {
      this.form.activities[activityIndex].detail.splice(activityDetailIndex, 1)
    },
    async save () {
      this.form.activities.forEach(activity => {
        if (activity.mvId) {
          activity.mvName = this.motherVesselVehicles.find(mv => mv.code === activity.mvId).name;
        }
      })

      let payload = { ...this.form, vehicleId: this.vehicleId }
      const res = await this.saveFC({
        method: 'post',
        data: payload
      })

      if (res.status === 'success') {
        this.$swal(
          `Data Tersimpan!`,
          'Data Operasional Berhasil Tersimpan',
          'success'
        )
        this.$router.push(`/ops/operational-floating-crane?vehicleId=${this.vehicleId}`)
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },

    getLastActivityDetail (activityIndex, activityDetailIndex) {
      let lastActivityDetail = this.form.activities[activityIndex].detail[activityDetailIndex - 1]

      if (!lastActivityDetail) {
        let lastActivity = this.form.activities[activityIndex - 1]

        if (!lastActivity) {
          lastActivity = this.lastActivities[this.lastActivities.length - 1]
        }

        if (lastActivity) {
          lastActivityDetail = lastActivity.detail[lastActivity.detail.length - 1]
        }
      }

      return lastActivityDetail
    },
    validateAllDatesActivityDetail (activityIndex, activityDetailIndex, inputKey) {
      if (this.form.activities[activityIndex] && this.form.activities[activityIndex].detail[activityDetailIndex]) {
        let activityDetail = this.form.activities[activityIndex].detail[activityDetailIndex]

        // validate A/side
        if (activityDetail.side) {
          if (activityDetail.compl) {
            if (!(this.convertToMoment(activityDetail.side) < this.convertToMoment(activityDetail.compl))) {
              this.$swal(
                `Oops!`,
                'A/side Harus Kurang dari Compl',
                'error'
              )
              activityDetail[inputKey] = ''
              return
            }
          }
          if (activityDetail.castOf) {
            if (!(this.convertToMoment(activityDetail.side) < this.convertToMoment(activityDetail.castOf))) {
              this.$swal(
                `Oops!`,
                'A/side Harus Kurang dari Cast Off',
                'error'
              )
              activityDetail[inputKey] = ''
              return
            }
          }
        }

        // validate compl
        if (activityDetail.compl) {
          if (activityDetail.castOf) {
            if (!(this.convertToMoment(activityDetail.compl) < this.convertToMoment(activityDetail.castOf))) {
              this.$swal(
                `Oops!`,
                'Compl Harus Kurang dari Cast Off',
                'error'
              )
              activityDetail[inputKey] = ''
              return
            }
          }
        }

        // validate comm
        if (activityDetail.comm) {
          if (activityDetail.compl) {
            if (!(this.convertToMoment(activityDetail.comm) < this.convertToMoment(activityDetail.compl))) {
              this.$swal(
                `Oops!`,
                'Comm Harus Kurang dari Compl',
                'error'
              )
              activityDetail[inputKey] = ''
              return
            }
          }
          if (activityDetail.castOf) {
            if (!(this.convertToMoment(activityDetail.comm) < this.convertToMoment(activityDetail.castOf))) {
              this.$swal(
                `Oops!`,
                'Comm Harus Kurang dari Cast Off',
                'error'
              )
              activityDetail[inputKey] = ''
              return
            }
          }
          if (activityDetail.side) {
            if (!(this.convertToMoment(activityDetail.comm) >= this.convertToMoment(activityDetail.side))) {
              this.$swal(
                `Oops!`,
                'Comm Harus Lebih dari sama dengan A/Side',
                'error'
              )
              activityDetail[inputKey] = ''
              return
            }
          }
        }

        // validate start finish idle
        if (activityDetail.idleParamType === 'Input Start-Finish') {
          if (activityDetail.idleParamStart && activityDetail.idleParamEnd) {
            if (!(this.convertToMoment(activityDetail.idleParamEnd) > this.convertToMoment(activityDetail.idleParamStart))) {
              this.$swal(
                `Error Input Start-Finish!`,
                'Finish Idle harus lebih besar dari Start Idle',
                'error'
              )
              activityDetail[inputKey] = ''
              return
            }
          }
          if (activityDetail.side && activityDetail.idleParamEnd) {
            if (!(activityDetail.idleParamEnd > activityDetail.side)) {
              this.$swal(
                `Error Input Start-Finish!`,
                'Finish Idle harus lebih besar dari A/Side',
                'error'
              )
              activityDetail[inputKey] = ''
            }
          }
        }
      }
    },
    validateManeuvering (activityIndex, activityDetailIndex) {
      if (this.form.activities[activityIndex] && this.form.activities[activityIndex].detail[activityDetailIndex]) {
        let activityDetail = this.form.activities[activityIndex].detail[activityDetailIndex]

        if (activityDetail.isManeuvering) {
          activityDetail.idleName = 'Not To Count - FC Maneuvering'
        }
      }
    },
    calculateBargeCastOffDurations (activityIndex, activityDetailIndex) {
      if (this.form.activities[activityIndex] && this.form.activities[activityIndex].detail[activityDetailIndex]) {
        let activityDetail = this.form.activities[activityIndex].detail[activityDetailIndex]
        activityDetail.bargeCastOff.start = null
        activityDetail.bargeCastOff.end = null
        activityDetail.bargeCastOff.duration = 0

        if (!activityDetail.withBarge) {
          return '-'
        }

        if (activityDetail.castOf && activityDetail.compl) {
          if (!(this.convertToMoment(activityDetail.castOf) > this.convertToMoment(activityDetail.compl))) {
            return `<span class="text-danger" style="font-size: 12px">Cast Off Date Must be Greater than Compl Date</span>`
          }

          let generateDurationObject = this.generateDurationObject(activityDetail.compl, activityDetail.castOf)
          activityDetail.bargeCastOff.start = generateDurationObject.start
          activityDetail.bargeCastOff.end = generateDurationObject.end
          activityDetail.bargeCastOff.duration = generateDurationObject.duration
          return generateDurationObject.durationString
        }
      }

      return '-'
    },
    calculateBargeAlongsideDurations (activityIndex, activityDetailIndex) {
      if (this.form.activities[activityIndex] && this.form.activities[activityIndex].detail[activityDetailIndex]) {
        let activityDetail = this.form.activities[activityIndex].detail[activityDetailIndex]
        activityDetail.bargeAlongside.start = null
        activityDetail.bargeAlongside.end = null
        activityDetail.bargeAlongside.duration = 0

        if (!activityDetail.withBarge) {
          return '-'
        }

        if (activityDetail.idleParamType === 'Input Start-Finish' && activityDetail.idleParamType !== 'No Idle') {
          if (activityDetail.side && activityDetail.idleParamEnd) {
            if (!(this.convertToMoment(activityDetail.side) > this.convertToMoment(activityDetail.idleParamEnd))) {
              return `<span class="text-danger" style="font-size: 12px">A/side must be greater than Idle Finish Date</span>`
            }

            let generateDurationObject = this.generateDurationObject(activityDetail.idleParamEnd, activityDetail.side)
            activityDetail.bargeAlongside.start = generateDurationObject.start
            activityDetail.bargeAlongside.end = generateDurationObject.end
            activityDetail.bargeAlongside.duration = generateDurationObject.duration
            return generateDurationObject.durationString
          }
        } else {
          let lastActivityDetail = this.getLastActivityDetail(activityIndex, activityDetailIndex)

          if (!lastActivityDetail) {
            return `<span class="text-danger" style="font-size: 12px">You must choose idle start-finish for counting barge A/Side</span>`
          }

          if (lastActivityDetail.withBarge) {
            if (activityDetail.side && lastActivityDetail.castOf) {
              if (!(this.convertToMoment(lastActivityDetail.castOf) > this.convertToMoment(activityDetail.side))) {
                return `<span class="text-danger" style="font-size: 12px">Cast Off Date on <strong>Last Activity Detail</strong> Must be Greater than A/Side Date</span>`
              }

              let generateDurationObject = this.generateDurationObject(activityDetail.side, lastActivityDetail.castOf)
              activityDetail.bargeAlongside.start = generateDurationObject.start
              activityDetail.bargeAlongside.end = generateDurationObject.end
              activityDetail.bargeAlongside.duration = generateDurationObject.duration
              return generateDurationObject.durationString
            }
          } else if (activityDetail.side && lastActivityDetail.idleParamEnd) {
            if (!(this.convertToMoment(lastActivityDetail.idleParamEnd) > this.convertToMoment(activityDetail.side))) {
              return `<span class="text-danger" style="font-size: 12px">Idle Date on <strong>Last Activity Detail</strong> Must be Greater than A/Side Date</span>`
            }

            let generateDurationObject = this.generateDurationObject(activityDetail.side, lastActivityDetail.idleParamEnd)
            activityDetail.bargeAlongside.start = generateDurationObject.start
            activityDetail.bargeAlongside.end = generateDurationObject.end
            activityDetail.bargeAlongside.duration = generateDurationObject.duration
            return generateDurationObject.durationString
          }
        }
      }

      return '-'
    },
    calculateIdleTimeDurations (activityIndex, activityDetailIndex) {
      if (this.form.activities[activityIndex] && this.form.activities[activityIndex].detail[activityDetailIndex]) {
        let activityDetail = this.form.activities[activityIndex].detail[activityDetailIndex]
        activityDetail.idleTime.start = null
        activityDetail.idleTime.end = null
        activityDetail.idleTime.duration = 0

        if (activityDetail.isManeuvering) {
          let lastActivityDetail = this.getLastActivityDetail(activityIndex, activityDetailIndex)

          if (!lastActivityDetail) {
            return `<span class="text-danger" style="font-size: 12px">You must put start-finish data first</span>`
          }

          let startDate = activityDetail.idleParamEnd
          let endDate = lastActivityDetail.idleParamEnd

          if (activityDetail.manuveringReff === 'cast_off') {
            endDate = lastActivityDetail.castOf
          } else if (activityDetail.manuveringReff === 'start') {
            endDate = lastActivityDetail.idleParamStart
          } else if (activityDetail.manuveringReff === 'end') {
            endDate = lastActivityDetail.idleParamEnd
          }

          if (startDate && endDate) {
            if (!(this.convertToMoment(endDate) > this.convertToMoment(startDate))) {
              return `<span class="text-danger" style="font-size: 12px">Trigger Date on <strong>Last Activity</strong> Must be Greater than Idle Date</span>`
            }

            let generateDurationObject = this.generateDurationObject(startDate, endDate)
            activityDetail.idleTime.start = generateDurationObject.start
            activityDetail.idleTime.end = generateDurationObject.end
            activityDetail.idleTime.duration = generateDurationObject.duration
            return generateDurationObject.durationString
          }
        } else {
          if (activityDetail.idleParamType === 'Input Start-Finish' && activityDetail.idleParamType !== 'No Idle') {
            if (activityDetail.idleParamEnd && activityDetail.idleParamStart) {
              if (!(this.convertToMoment(activityDetail.idleParamEnd) > this.convertToMoment(activityDetail.idleParamStart))) {
                return `<span class="text-danger" style="font-size: 12px">Idle Finish Date Must be Greater than Idle Start Date</span>`
              }

              let generateDurationObject = this.generateDurationObject(activityDetail.idleParamStart, activityDetail.idleParamEnd)
              activityDetail.idleTime.start = generateDurationObject.start
              activityDetail.idleTime.end = generateDurationObject.end
              activityDetail.idleTime.duration = generateDurationObject.duration
              return generateDurationObject.durationString
            }
          } else {
            let lastActivityDetail = this.getLastActivityDetail(activityIndex, activityDetailIndex)

            if (!lastActivityDetail) {
              return `<span class="text-danger" style="font-size: 12px">You must choose idle start-finish for counting idle</span>`
            }

            if (activityDetail.idleParamEnd && lastActivityDetail.idleParamEnd) {
              if (!(this.convertToMoment(lastActivityDetail.idleParamEnd) > this.convertToMoment(activityDetail.idleParamEnd))) {
                return `<span class="text-danger" style="font-size: 12px">Idle Date on <strong>Last Activity Detail</strong> Must be Greater than Idle Date</span>`
              }

              let generateDurationObject = this.generateDurationObject(activityDetail.idleParamEnd, lastActivityDetail.idleParamEnd)
              activityDetail.idleTime.start = generateDurationObject.start
              activityDetail.idleTime.end = generateDurationObject.end
              activityDetail.idleTime.duration = generateDurationObject.duration
              return generateDurationObject.durationString
            }
          }
        }
      }
      return '-'
    },
    calculateDBS (activityIndex, activityDetailIndex) {
      if (this.form.activities[activityIndex] && this.form.activities[activityIndex].detail[activityDetailIndex]) {
        let activityDetail = this.form.activities[activityIndex].detail[activityDetailIndex]

        if (activityDetail.beltScale !== '' && activityDetail.cargoDraft !== '') {
          activityDetail.cargoDbs = parseInt(activityDetail.beltScale) - parseInt(activityDetail.cargoDraft)
        }
      }
    },
    generateYearMonthFormParams () {
      if (this.periodMonthYear) {
        this.form.months = moment(this.periodMonthYear).format('MM')
        this.form.years = moment(this.periodMonthYear).year()
      }
    },
    convertToMoment (date, format = 'DD-MM-YYYY HH:mm') {
      return moment(date, format)
    },
    generateDurationObject (start, end) {
      let difference = moment(end, 'DD-MM-YYYY HH:mm').diff(moment(start, 'DD-MM-YYYY HH:mm'))
      let durationString = ''
      let duration = moment.duration(difference)

      if (Math.round(duration.years()) > 0) {
        durationString += `${Math.round(duration.years())} years `
      }

      if (Math.round(duration.months()) > 0) {
        durationString += `${Math.round(duration.months())} months `
      }

      if (Math.round(duration.weeks()) > 0) {
        durationString += `${Math.round(duration.weeks())} weeks `
      }

      if (Math.round(duration.days()) > 0) {
        durationString += `${Math.round(duration.days())} days `
      }

      if (Math.round(duration.hours()) > 0) {
        durationString += `${Math.round(duration.hours())} hours `
      }

      if (Math.round(duration.minutes()) > 0) {
        durationString += `${Math.round(duration.minutes())} minutes `
      }

      if (Math.round(duration.seconds()) > 0) {
        durationString += `${Math.round(duration.seconds())} seconds `
      }

      return {
        start,
        end,
        duration: difference / 1000,
        durationString
      }
    }
  }
}
</script>
