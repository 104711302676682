var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Search & Filter")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row"
        }, [_c('b-col', {
          attrs: {
            "cols": "6",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Job Category*",
            "label-for": "fleet_category"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.jobCategories,
            "reduce": function reduce(type) {
              return type.value;
            }
          },
          model: {
            value: _vm.lists.params.jobCategoryId,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.params, "jobCategoryId", $$v);
            },
            expression: "lists.params.jobCategoryId"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "6",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search Job",
            "label-for": "search_job"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "search_job",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.lists.params.name,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.params, "name", $$v);
            },
            expression: "lists.params.name"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.getJobsData();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Job List")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', [_vm._v("No.")]), _c('th', [_vm._v("Job Category")]), _c('th', [_vm._v("Job Name")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Action")])])]), _c('tbody', [_vm._l(_vm.lists.data, function (data, index) {
          return _c('tr', [_c('td', [_vm._v(_vm._s(index + 1) + ".")]), _c('td', [_vm._v(_vm._s(data.jobCategory ? data.jobCategory.name : '-'))]), _c('td', [_vm._v(_vm._s(data.name))]), _c('td', [data.active ? _c('b-badge', {
            staticClass: "border border-success text-success",
            attrs: {
              "variant": "none"
            }
          }, [_vm._v("Active")]) : _c('b-badge', {
            staticClass: "border border-danger text-danger",
            attrs: {
              "variant": "none"
            }
          }, [_vm._v("InActive")])], 1), _c('td', [_c('span', {
            staticClass: "text-info mr-1 cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.getJobDetailData(data.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('span', {
            staticClass: "text-danger cursor-pointer",
            on: {
              "click": function click($event) {
                return _vm.deleteJobData(data.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])])]);
        }), _vm.lists.data.length === 0 && !_vm.lists.isLoading ? _c('tr', [_c('td', {
          attrs: {
            "colspan": "5"
          }
        }, [_vm._v(" No Data ")])]) : _vm._e(), _vm.lists.isLoading ? _c('tr', [_c('td', {
          attrs: {
            "colspan": "5"
          }
        }, [_vm._v(" Loading ")])]) : _vm._e()], 2)])])]), _c('b-col', {
          staticClass: "mt-4"
        }, [_c('b-pagination', {
          attrs: {
            "value": _vm.lists.meta.currentPage,
            "total-rows": _vm.lists.meta.total,
            "per-page": _vm.lists.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getJobsData
          },
          model: {
            value: _vm.lists.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.meta, "currentPage", $$v);
            },
            expression: "lists.meta.currentPage"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.form.id
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.form.id ? 'Edit' : 'Add') + " Job")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSave();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Job Name*",
            "label-for": "job_name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "Job Name",
            "required": "",
            "type": "text",
            "placeholder": "Job Name"
          },
          model: {
            value: _vm.form.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "name", $$v);
            },
            expression: "form.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label-for": "status-job",
            "label": "Status, Active / Inactive"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "required": "",
            "switch": "",
            "value": true,
            "unchecked-value": false
          },
          model: {
            value: _vm.form.active,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "active", $$v);
            },
            expression: "form.active"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Job Category *",
            "label-for": "job_id"
          }
        }, [_c('v-select', {
          attrs: {
            "required": "",
            "label": "text",
            "options": _vm.jobCategories,
            "reduce": function reduce(type) {
              return type.value;
            }
          },
          model: {
            value: _vm.form.jobCategoryId,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "jobCategoryId", $$v);
            },
            expression: "form.jobCategoryId"
          }
        })], 1), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "disabled": _vm.form.saving,
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('span', [_vm._v("Save")])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.resetForm();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }