var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "maintenance-summary-board"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Filter Maintenance Board " + _vm._s(_vm.queryVehicleId && _vm.maintenance.params.vehicleIds[0] ? ' - ' + _vm.maintenance.params.vehicleIds[0].name : ''))])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('span', {
          staticClass: "ml-3"
        }, [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-filter",
            modifiers: {
              "collapse-filter": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          attrs: {
            "id": "collapse-filter",
            "visible": ""
          }
        }, [_c('b-row', [_vm.tab !== 'TASK_LIST' ? _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Date Range:",
            "label-for": "Date Range"
          }
        }, [_c('date-picker', {
          attrs: {
            "format": "DD MMM YYYY",
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          on: {
            "change": function change($event) {
              return _vm.onChangeDate();
            }
          },
          model: {
            value: _vm.maintenance.dateRange,
            callback: function callback($$v) {
              _vm.$set(_vm.maintenance, "dateRange", $$v);
            },
            expression: "maintenance.dateRange"
          }
        })], 1)], 1) : _vm._e(), !_vm.queryVehicleId ? _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet/Equipment:",
            "label-for": "fleet-equipment"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "disabled": _vm.queryVehicleId,
            "tag-placeholder": "Category",
            "placeholder": "Search or add a category",
            "label": "name",
            "track-by": "code",
            "options": _vm.fleetEquipments,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.maintenance.params.vehicleIds,
            callback: function callback($$v) {
              _vm.$set(_vm.maintenance.params, "vehicleIds", $$v);
            },
            expression: "maintenance.params.vehicleIds"
          }
        })], 1)], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Choose your Job Filter",
            "label-for": "fleet-equipment"
          }
        }, [_vm._l(_vm.jobOptions, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            staticClass: "custom-radio-color-checked",
            attrs: {
              "inline": "",
              "color": item.color,
              "name": "color",
              "value": item.value,
              "disabled": item.disabled
            },
            model: {
              value: _vm.maintenance.selectedJob,
              callback: function callback($$v) {
                _vm.$set(_vm.maintenance, "selectedJob", $$v);
              },
              expression: "maintenance.selectedJob"
            }
          }, [_vm._v(_vm._s(item.label))])];
        })], 2), _vm.maintenance.selectedJob === 'job_class' ? _c('b-form-group', {
          attrs: {
            "label": "Job Class",
            "label-for": "fleet-equipment"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Search or add a job class",
            "label": "name",
            "track-by": "code",
            "options": _vm.jobClassList,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.maintenance.params.jobClassIds,
            callback: function callback($$v) {
              _vm.$set(_vm.maintenance.params, "jobClassIds", $$v);
            },
            expression: "maintenance.params.jobClassIds"
          }
        })], 1) : _vm._e(), _vm.maintenance.selectedJob === 'job_type' ? _c('b-form-group', {
          attrs: {
            "label": "Job Type",
            "label-for": "fleet-equipment"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Search or add a job type",
            "label": "name",
            "track-by": "code",
            "options": _vm.jobTypeList,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.maintenance.params.jobTypeIds,
            callback: function callback($$v) {
              _vm.$set(_vm.maintenance.params, "jobTypeIds", $$v);
            },
            expression: "maintenance.params.jobTypeIds"
          }
        })], 1) : _vm._e()], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Job Name/Equipment",
            "label-for": "equipment"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "search..."
          },
          model: {
            value: _vm.maintenance.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.maintenance.params, "search", $$v);
            },
            expression: "maintenance.params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getMaintenanceBoard();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "nav-fill mb-3",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": true,
            "href": "#task-list",
            "title": "Task List"
          },
          nativeOn: {
            "click": function click($event) {
              _vm.tab = 'TASK_LIST';
            }
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": false,
            "href": "#finished",
            "title": "Finished"
          },
          nativeOn: {
            "click": function click($event) {
              _vm.tab = 'FINISHED';
            }
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content"
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "task-list"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "white",
      "bg-variant": "blue"
    }
  }, [_c('b-card-title', {
    staticClass: "text-white text-center"
  }, [_c('h1', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.maintenance.meta.towards))])]), _c('blockquote', {
    staticClass: "blockquote mb-0 text-center"
  }, [_c('p', {
    staticClass: "font-size-22"
  }, [_c('strong', [_vm._v("Towards Jobs")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "white",
      "bg-variant": "danger"
    }
  }, [_c('b-card-title', {
    staticClass: "text-white text-center"
  }, [_c('h1', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.maintenance.meta.overdue))])]), _c('blockquote', {
    staticClass: "blockquote mb-0 text-center"
  }, [_c('p', {
    staticClass: "font-size-22"
  }, [_c('strong', [_vm._v("Overdue")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "text-variant": "white",
      "bg-variant": "warning"
    }
  }, [_c('b-card-title', {
    staticClass: "text-white text-center"
  }, [_c('h1', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.maintenance.meta.postponed))])]), _c('blockquote', {
    staticClass: "blockquote mb-0 text-center"
  }, [_c('p', {
    staticClass: "font-size-22"
  }, [_c('strong', [_vm._v("Postponed")])])])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between",
          on: {
            "click": function click($event) {
              _vm.maintenance.params.status = 'MONTHS3';
            }
          }
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h5', {
          staticClass: "card-title mb-2 mt-2 text-primary"
        }, [_c('strong', [_vm._v("Next 3 Month")])]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.maintenance.meta['3months']))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "fa fa-calendar-week"
        })])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between",
          on: {
            "click": function click($event) {
              _vm.maintenance.params.status = 'MONTHS6';
            }
          }
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h5', {
          staticClass: "card-title mb-2 mt-2 text-primary"
        }, [_c('strong', [_vm._v("Next 6 Month")])]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.maintenance.meta['6months']))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "fa fa-calendar-alt"
        })])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between",
          on: {
            "click": function click($event) {
              _vm.maintenance.params.status = 'MONTHS12';
            }
          }
        }, [_c('div', {
          staticClass: "text-left"
        }, [_c('h5', {
          staticClass: "card-title mb-2 mt-2 text-primary"
        }, [_c('strong', [_vm._v("Next 12 Month")])]), _c('h3', {
          staticClass: "mb-0 line-height"
        }, [_vm._v(_vm._s(_vm.maintenance.meta['12months']))])]), _c('div', {
          staticClass: "rounded-circle iq-card-icon bg-success"
        }, [_c('i', {
          staticClass: "fa fa-calendar"
        })])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch pt-3 bg-light"
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "4"
    }
  }), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Select Your Data:"
    }
  }, [_c('v-select', {
    staticClass: "bg-light text-white",
    attrs: {
      "label": "text",
      "options": _vm.statusList,
      "reduce": function reduce(statusList) {
        return statusList.value;
      },
      "append-to-body": "",
      "calculate-position": _vm.withPopper
    },
    model: {
      value: _vm.maintenance.params.status,
      callback: function callback($$v) {
        _vm.$set(_vm.maintenance.params, "status", $$v);
      },
      expression: "maintenance.params.status"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "4"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Task List Data " + _vm._s(_vm.queryVehicleId && _vm.maintenance.params.vehicleIds[0] ? ' - ' + _vm.maintenance.params.vehicleIds[0].name : ''))]), _vm._v(" "), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v(_vm._s(_vm.selectedStatusList.text))])], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "mt-3 mb-3",
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover table-sm"
        }, [_c('thead', {
          staticClass: "text-center"
        }, [_c('tr', [_c('th', [_vm._v("No")]), !_vm.queryVehicleId ? _c('th', [_vm._v("Fleet/Equipment")]) : _vm._e(), _c('th', [_vm._v("Job Name - Code")]), _c('th', [_vm._v("Equipment Name")]), _c('th', [_vm._v("Frequency")]), _c('th', [_vm._v("Last Maintenance")]), _c('th', [_vm._v("Due Date / Hours")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Action")])])]), _c('tbody', [_vm._l(_vm.maintenance.data, function (job, index) {
          return [_c('tr', {
            key: index
          }, [_c('th', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(index + 1))]), !_vm.queryVehicleId ? _c('td', {
            staticClass: "text-center"
          }, [_c('a', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.viewDetailMaintenance(job.id, 'VIEW_DETAIL');
              }
            }
          }, [_vm._v(_vm._s(job.vehicleName) + " - " + _vm._s(job.equipmentName))])]) : _vm._e(), _c('td', {
            staticClass: "pl-3"
          }, [_c('a', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.viewDetailMaintenance(job.id, 'VIEW_DETAIL');
              }
            }
          }, [_vm._v(_vm._s(job.jobName))])]), _c('td', {
            staticClass: "text-center"
          }, [_c('a', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.viewDetailMaintenance(job.id, 'VIEW_DETAIL');
              }
            }
          }, [_vm._v(_vm._s(job.componentName))])]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(job.interval))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(job.lastMaintenanceDate))]), _c('td', {
            staticClass: "text-center"
          }, [_c('span', [_vm._v(_vm._s(job.dueDate))]), job.pendingDate === null || job.pendingDate === '' || job.pendingDate === undefined ? _c('div') : _c('div', [_c('small', [_vm._v("Last Due #1 : " + _vm._s(job.pendingDate))])])]), _c('td', {
            staticClass: "text-center"
          }, [job.status === 'OVERDUE' ? [_c('b-badge', {
            attrs: {
              "id": 'popover-status' + index,
              "variant": "danger d-block"
            }
          }, [_vm._v("OVERDUE / " + _vm._s(job.statusDetail && job.statusDetail.includes('days') ? _vm.generateDayDurations(parseInt(job.statusDetail.replace('days', '').trim())).durationString : job.statusDetail))])] : job.status === 'TOWARDS' ? [_c('b-badge', {
            attrs: {
              "id": 'popover-status' + index,
              "variant": "info d-block"
            }
          }, [_vm._v("TOWARDS")])] : job.status === 'REVIEW' ? [_c('b-badge', {
            attrs: {
              "id": 'popover-status' + index,
              "variant": "warning d-block"
            }
          }, [_vm._v("REVIEW")])] : job.status === 'APPROVE' ? [_c('b-badge', {
            attrs: {
              "id": 'popover-status' + index,
              "variant": "success d-block"
            }
          }, [_vm._v("APPROVE")])] : job.status === 'POSTPONE' ? [_c('b-badge', {
            attrs: {
              "id": 'popover-status' + index,
              "variant": "info d-block"
            }
          }, [_vm._v("TOWARDS (POSTPONE)")])] : job.status === 'NEW' ? [_c('b-badge', {
            attrs: {
              "id": 'popover-status' + index,
              "variant": "primary d-block"
            }
          }, [_vm._v("ACTIVE")])] : [_c('b-badge', {
            attrs: {
              "variant": "light d-block"
            }
          }, [_vm._v(_vm._s(job.status))])]], 2), _c('td', [_c('b-dropdown', {
            attrs: {
              "size": "lg",
              "variant": "link iq-bg-primary iq-border-radius-10",
              "toggle-class": "text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton5",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-pencil-square-o m-0 text-primary"
                })])];
              },
              proxy: true
            }], null, true)
          }, [_vm.canAdd && ['TOWARDS', 'OVERDUE', 'POSTPONE'].includes(job.status) ? _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.viewDetailMaintenance(job.id, 'ON_MAINTENANCE');
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-tasks"
          }), _vm._v(" Maintenance")]) : _vm._e(), ['APPROVE'].includes(job.status) && _vm.canApprove ? _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "info"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.onApprove(job.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-thumbs-up"
          }), _vm._v(" Approve")]) : _vm._e(), ['TOWARDS', 'OVERDUE'].includes(job.status) ? _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.viewDetailMaintenance(job.id, 'ON_CONVERT');
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-tools"
          }), _vm._v(" Convert Repair")]) : _vm._e(), ['TOWARDS', 'OVERDUE'].includes(job.status) ? _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "warning"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.viewDetailMaintenance(job.id, 'ON_POSTPONE');
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-clock"
          }), _vm._v(" Postpone")]) : _vm._e(), ['MAINTENANCE', 'REVIEW'].includes(job.status) && _vm.canReview ? _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "info"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.onReview(job.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-search"
          }), _vm._v(" Review")]) : _vm._e()], 1)], 1), _c('b-sidebar', {
            attrs: {
              "id": "convert_sr",
              "title": "FORM SERVICE & REPAIR",
              "width": "60%",
              "backdrop-variant": "dark",
              "bg-variant": "white",
              "right": "",
              "backdrop": "",
              "shadow": ""
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref) {
                var hide = _ref.hide;
                return [false ? _c('b-row', {
                  staticClass: "m-3"
                }, [_c('b-col', {
                  attrs: {
                    "md": "12"
                  }
                }, [_c('fieldset', {
                  staticClass: "form-group border p-3"
                }, [_c('legend', {
                  staticClass: "w-auto px-2"
                }, [_vm._v("Fire Pump")]), _c('b-row', [_c('b-col', {
                  attrs: {
                    "md": "6"
                  }
                }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Clasification *",
                    "label-for": "clasification"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "options": _vm.clasification,
                    "size": "lg"
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Clasification")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: _vm.selectedClasification,
                    callback: function callback($$v) {
                      _vm.selectedClasification = $$v;
                    },
                    expression: "selectedClasification"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Category *",
                    "label-for": "category"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "options": _vm.category,
                    "size": "lg"
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Category")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: _vm.selectedCategory,
                    callback: function callback($$v) {
                      _vm.selectedCategory = $$v;
                    },
                    expression: "selectedCategory"
                  }
                })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Problem Case *",
                    "label-for": "problem_case"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "options": _vm.problemCase,
                    "size": "lg"
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Problem Case")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: _vm.selectedProblemCase,
                    callback: function callback($$v) {
                      _vm.selectedProblemCase = $$v;
                    },
                    expression: "selectedProblemCase"
                  }
                })], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Kind of Task *",
                    "label-for": "kind_of_task"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "options": _vm.kindTask,
                    "size": "lg"
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Kind of Task")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: _vm.selectedKindTask,
                    callback: function callback($$v) {
                      _vm.selectedKindTask = $$v;
                    },
                    expression: "selectedKindTask"
                  }
                })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Start Date *",
                    "label-for": "start_date"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "type": "date",
                    "id": "start_date"
                  }
                }), _c('small', {
                  staticStyle: {
                    "float": "right"
                  }
                }, [_vm._v("click "), _c('i', {
                  staticClass: "fa fa-calendar"
                }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "End Date *",
                    "label-for": "end_date"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "type": "date",
                    "id": "end_date"
                  }
                }), _c('small', {
                  staticStyle: {
                    "float": "right"
                  }
                }, [_vm._v("click "), _c('i', {
                  staticClass: "fa fa-calendar"
                }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                  attrs: {
                    "label": "Task File *",
                    "label-for": "attactment_file"
                  }
                }, [_c('b-form-file', {
                  attrs: {
                    "plain": "",
                    "id": "attactment_file"
                  }
                }), _c('b-card-text', [_c('small', {
                  staticClass: "text-muted"
                }, [_vm._v(" File Type * : "), _c('b-badge', {
                  attrs: {
                    "variant": "info"
                  }
                }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                  attrs: {
                    "variant": "info"
                  }
                }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1)], 1), _c('b-col', {
                  attrs: {
                    "md": "6"
                  }
                }, [_c('b-form-group', {
                  attrs: {
                    "label": "Job Class *",
                    "label-for": "job_class"
                  }
                }, [_c('b-form-select', {
                  attrs: {
                    "plain": "",
                    "options": _vm.jobClass,
                    "size": "lg"
                  },
                  scopedSlots: _vm._u([{
                    key: "first",
                    fn: function fn() {
                      return [_c('b-form-select-option', {
                        attrs: {
                          "value": null
                        }
                      }, [_vm._v("Select Job Class")])];
                    },
                    proxy: true
                  }], null, true),
                  model: {
                    value: _vm.selectedJobClass,
                    callback: function callback($$v) {
                      _vm.selectedJobClass = $$v;
                    },
                    expression: "selectedJobClass"
                  }
                })], 1), _c('b-form-group', {
                  attrs: {
                    "label": "Job Name",
                    "label-for": "task_name"
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "type": "text"
                  }
                })], 1), _c('b-form-group', {
                  attrs: {
                    "label": "Description",
                    "label-for": "description"
                  }
                }, [_c('b-form-textarea', {
                  attrs: {
                    "id": "description",
                    "rows": "3"
                  }
                })], 1)], 1)], 1)], 1)]), _c('b-col', {
                  staticClass: "mt-3 float-right",
                  attrs: {
                    "md": "12"
                  }
                }, [_c('b-card-text', [_c('small', {
                  staticClass: "text-muted"
                }, [_vm._v("Sign * : "), _c('b-badge', {
                  attrs: {
                    "variant": "warning"
                  }
                }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('b-button', {
                  attrs: {
                    "type": "submit",
                    "variant": "primary"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-save"
                }), _vm._v(" Save")]), _c('b-button', {
                  staticClass: "iq-bg-danger ml-3",
                  attrs: {
                    "type": "submit",
                    "variant": "none"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-close"
                }), _vm._v(" Reset Form")])], 1)], 1) : _vm._e()];
              }
            }], null, true)
          })], 1)];
        })], 2)]), _c('b-sidebar', {
          attrs: {
            "id": "view-detail-maintenance",
            "title": "View Job Maintenance",
            "width": "1300px",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hide = _ref2.hide;
              return [_vm.viewDetailMaintenanceSidebar ? _c('ViewMaintenanceDetail', {
                attrs: {
                  "maintenance-id": _vm.selectedMaintenanceId,
                  "action": _vm.actionMaintenanceSidebar
                },
                on: {
                  "save": function save($event) {
                    return _vm.closeDetailMaintenance();
                  }
                }
              }) : _vm._e()];
            }
          }]),
          model: {
            value: _vm.viewDetailMaintenanceSidebar,
            callback: function callback($$v) {
              _vm.viewDetailMaintenanceSidebar = $$v;
            },
            expression: "viewDetailMaintenanceSidebar"
          }
        })], 1)])], 1), _c('b-row', {
          staticClass: "mt-4"
        }, [_c('b-col', {
          attrs: {
            "col-6": ""
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.maintenance.meta.total) + "   |   Per page: " + _vm._s(_vm.maintenance.meta.perPage) + " ")]), _c('b-col', {
          attrs: {
            "col-6": ""
          }
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.maintenance.meta.total,
            "per-page": _vm.maintenance.meta.perPage,
            "align": "right"
          },
          on: {
            "input": _vm.getMaintenanceList
          },
          model: {
            value: _vm.maintenance.params.page,
            callback: function callback($$v) {
              _vm.$set(_vm.maintenance.params, "page", $$v);
            },
            expression: "maintenance.params.page"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)]), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "finished"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "p-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-card', {
    staticClass: "iq-mb-3",
    attrs: {
      "bg-variant": "default"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-card-title', {
    staticClass: "text-center"
  }, [_c('h3', [_vm._v(_vm._s(_vm.maintenanceFinished.meta.total))])]), _c('blockquote', {
    staticClass: "blockquote mb-0 text-center"
  }, [_c('p', {
    staticClass: "font-size-18"
  }, [_c('strong', [_vm._v("Finished")])])])], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Date Range"
    }
  }, [_c('h5', [_c('strong', [_vm._v(_vm._s(_vm.computedDateRangeText))])])])], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Fleet / Equipment"
    }
  }, [_vm.maintenance.params.vehicleIds.length === 0 ? _c('b-badge', {
    staticClass: "mr-1",
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v(" Semua Fleet ")]) : _vm._e(), _vm._l(_vm.maintenance.params.vehicleIds, function (vehicle, index) {
    return _c('b-badge', {
      key: index,
      staticClass: "mr-1",
      attrs: {
        "variant": "warning"
      }
    }, [_vm._v(" " + _vm._s(vehicle.name) + " ")]);
  })], 2)], 1), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Job"
    }
  }, [_vm.maintenance.selectedJob === 'job_class' ? _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v("Job Class")]) : _vm.maintenance.selectedJob === 'job_type' ? _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v("Job Type")]) : _vm._e(), _vm.maintenance.selectedJob === 'job_class' ? _c('h6', [_c('strong', [_vm._v(_vm._s(_vm.maintenance.params.jobClassIds.map(function (jobClass) {
    return jobClass.name;
  }).join(', ')))])]) : _vm.maintenance.selectedJob === 'job_type' ? _c('h6', [_c('strong', [_vm._v(_vm._s(_vm.maintenance.params.jobTypeIds.map(function (jobType) {
    return jobType.name;
  }).join(', ')))])]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Keyword Search"
    }
  }, [_c('h5', [_c('strong', [_vm._v(_vm._s(_vm.maintenance.params.search || '-'))])])])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover",
          staticStyle: {
            "width": "2000px"
          }
        }, [_c('thead', {
          staticClass: "text-center"
        }, [_c('tr', [_c('td', [_vm._v("No")]), _c('td', [_vm._v("Fleet/Equipment")]), _c('td', [_vm._v("Job Name")]), _c('td', [_vm._v("Component Name")]), _c('td', [_vm._v("Frequency")]), _c('td', [_vm._v("Last Maintenance")]), _c('td', [_vm._v("Due Date / Hours")]), _c('td', [_vm._v("Start Date")]), _c('td', [_vm._v("End Date")]), _c('td', [_vm._v("Cost")]), _c('td', [_vm._v("Notes")]), _c('td', [_vm._v("Port Engineer/PIC")]), _c('td', [_vm._v("File")]), _c('td', [_vm._v("Status")]), _c('td', [_vm._v("Created At")]), _c('td', [_vm._v("Created by")]), _c('td', [_vm._v("Approved by")])])]), _c('tbody', [_vm._l(_vm.maintenanceFinished.data, function (job, index) {
          return [_c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(job.vehicleName))]), _c('td', [_c('router-link', {
            attrs: {
              "to": ""
            }
          }, [_vm._v(_vm._s(job.jobName))])], 1), _c('td', [_vm._v(_vm._s(job.componentName))]), _c('td', [_vm._v(_vm._s(job.interval))]), _c('td', [_vm._v(_vm._s(job.lastMaintenanceDate))]), _c('td', [_c('span', [_vm._v(_vm._s(job.dueDate))])]), _c('td', [_vm._v(_vm._s(job.start))]), _c('td', [_c('span', [_vm._v(_vm._s(job.end) + " ")]), job.frequency === '10.000 H' ? _c('span', [_vm._v("/ " + _vm._s(job.current))]) : _vm._e()]), _c('td', [_vm._v(_vm._s(job.cost))]), _c('td', [_vm._v(_vm._s(job.note))]), _c('td', [_vm._v(_vm._s(job.pic))]), _c('td', [_vm._v("-")]), _c('td', [_c('b-badge', {
            attrs: {
              "variant": "success"
            }
          }, [_vm._v(_vm._s(job.status))])], 1), _c('td', [_vm._v(_vm._s(job.createdAt))]), _c('td', [_vm._v(_vm._s(job.createdBy))]), _c('td', [_vm._v(_vm._s(job.approvedBy))])])];
        })], 2)])])];
      },
      proxy: true
    }])
  }, [_c('b-col', [_c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "col-6": ""
    }
  }, [_vm._v(" Total: " + _vm._s(_vm.maintenanceFinished.meta.total) + "   |   Per page: " + _vm._s(_vm.maintenanceFinished.meta.perPage) + " ")]), _c('b-col', {
    attrs: {
      "col-6": ""
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.maintenanceFinished.meta.total,
      "per-page": _vm.maintenanceFinished.meta.perPage,
      "align": "right"
    },
    on: {
      "input": _vm.getMaintenanceFinishedList
    },
    model: {
      value: _vm.maintenanceFinished.params.page,
      callback: function callback($$v) {
        _vm.$set(_vm.maintenanceFinished.params, "page", $$v);
      },
      expression: "maintenanceFinished.params.page"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }