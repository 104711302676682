var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.miniToggle ? _c('div', {
    staticClass: "sidebar-toggle",
    attrs: {
      "id": "sidebar-toggle"
    }
  }, [_c('i', {
    staticClass: "ri-menu-3-line"
  })]) : _vm._e(), _c('div', {
    staticClass: "avatar chat-profile mr-3",
    class: _vm.mediaClass,
    on: {
      "click": _vm.toggleContent
    }
  }, [_vm._t("media")], 2), _c('div', {
    staticClass: "chat-caption"
  }, [_vm._t("body")], 2), _vm.closeButton ? _c('button', {
    staticClass: "close-btn-res p-3",
    attrs: {
      "type": "submit"
    }
  }, [_c('i', {
    staticClass: "ri-close-fill"
  })]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }