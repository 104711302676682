import { render, staticRenderFns } from "./ProcurementProducts.vue?vue&type=template&id=466a0d11&"
import script from "./ProcurementProducts.vue?vue&type=script&lang=js&"
export * from "./ProcurementProducts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports