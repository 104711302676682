var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Dripicons Icon")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Use Class "), _c('code', [_vm._v("<div data-icon=\"a\" class=\"icon\"></div>")])]), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "a"
          }
        }), _c('span', [_vm._v("4k Fill")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "o"
          }
        }), _c('span', [_vm._v("Up Arrow")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "h"
          }
        }), _c('span', [_vm._v("Down Arrow")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "j"
          }
        }), _c('span', [_vm._v("Left Arrow")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "i"
          }
        }), _c('span', [_vm._v("Right Arrow")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "l"
          }
        }), _c('span', [_vm._v("left")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "k"
          }
        }), _c('span', [_vm._v("Down")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "n"
          }
        }), _c('span', [_vm._v("Up")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "m"
          }
        }), _c('span', [_vm._v("Right")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "f"
          }
        }), _c('span', [_vm._v("Anchor")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "g"
          }
        }), _c('span', [_vm._v("File")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "p"
          }
        }), _c('span', [_vm._v("Note")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "s"
          }
        }), _c('span', [_vm._v("Dribbble")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "K"
          }
        }), _c('span', [_vm._v("Download")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "G"
          }
        }), _c('span', [_vm._v("Sun")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "H"
          }
        }), _c('span', [_vm._v("Haf light")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "F"
          }
        }), _c('span', [_vm._v("Light")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "I"
          }
        }), _c('span', [_vm._v("Voice")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "x"
          }
        }), _c('span', [_vm._v("Notification")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "J"
          }
        }), _c('span'), _vm._v("Page")])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "t"
          }
        }), _c('span', [_vm._v("Battery Empty")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "u"
          }
        }), _c('span', [_vm._v("Battery Full")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "v"
          }
        }), _c('span', [_vm._v("Battery One")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "w"
          }
        }), _c('span', [_vm._v("Battery Two")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "Q"
          }
        }), _c('span', [_vm._v("Cart")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "P"
          }
        }), _c('span', [_vm._v("Card")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "O"
          }
        }), _c('span', [_vm._v("Camera")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "N"
          }
        }), _c('span', [_vm._v("Video")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "S"
          }
        }), _c('span', [_vm._v("Check")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "T"
          }
        }), _c('span', [_vm._v("Angle Down")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "U"
          }
        }), _c('span', [_vm._v("Angle Left")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "V"
          }
        }), _c('span', [_vm._v("Angle Right")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "W"
          }
        }), _c('span', [_vm._v("Angle Up")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "X"
          }
        }), _c('span', [_vm._v("Up Arrow")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "Y"
          }
        }), _c('span', [_vm._v("Clock")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "Z"
          }
        }), _c('span', [_vm._v("Reload")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "0"
          }
        }), _c('span', [_vm._v("Cloud")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "1"
          }
        }), _c('span', [_vm._v("Cloud Download")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "2"
          }
        }), _c('span', [_vm._v("Cloud Upload")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "5"
          }
        }), _c('span', [_vm._v("Arrow")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "5"
          }
        }), _c('span', [_vm._v("Arrow")])])], 1), _c('b-col', {
          attrs: {
            "xl": "2",
            "lg": "3",
            "md": "4",
            "sm": "6"
          }
        }, [_c('b-link', {
          staticClass: "iq-icons-list",
          attrs: {
            "href": "#"
          }
        }, [_c('div', {
          staticClass: "icon",
          attrs: {
            "data-icon": "5"
          }
        }), _c('span', [_vm._v("Arrow")])])], 1), _c('b-col', {
          staticClass: "text-center mt-3",
          attrs: {
            "sm": "12"
          }
        }, [_c('b-button', {
          attrs: {
            "href": "http://demo.amitjakhu.com/dripicons/",
            "target": "blank",
            "variant": "primary"
          }
        }, [_vm._v("View All Icon")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }