import axios from './auth'

const PORT_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/ports`;

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
    async getPorts({ commit }, params = {}) {
        const url = PORT_BASE_URL;

        const method = "get";
        const headers = {};
        const config = {
            url,
            method,
            headers,
            params
        };

        try {
            const response = await axios(config);
            let data = response.data;
            return {
                status: 'success',
                data,
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async getPortById({ commit }, payload) {
        const url = `${PORT_BASE_URL}/${payload}`;

        const method = "get";
        const headers = {};
        const config = {
            url,
            method,
            headers
        };

        try {
            const response = await axios(config);
            let data = response.data.data;
            return {
                status: 'success',
                data,
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async savePort({ commit }, payload) {
        const url = PORT_BASE_URL;

        const method = "post";
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        const config = {
            url,
            method,
            data: payload,
            headers
        };

        try {
            const response = await axios(config);
            let data = response.data.data;
            return {
                status: 'success',
                data,
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async updatePort({ commit }, payload) {
        const url = `${PORT_BASE_URL}/${payload.id}`;

        const method = "put";
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json"
        };
        const config = {
            url,
            method,
            data: payload.data,
            headers
        };

        try {
            const response = await axios(config);
            let data = response.data.data;
            return {
                status: 'success',
                data,
            };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async deletePort({ commit }, payload) {
        const url = `${PORT_BASE_URL}/${payload}`;

        const method = "delete";
        const headers = {};
        const config = {
            url,
            method,
            headers
        };

        try {
            await axios(config);
            return { status: 'success' };
        } catch (e) {
            return {
                status: 'error',
                data: e.response.data
            };
        }
    },
    async exportPort({ commit }, params = {}) {
        const url = `${PORT_BASE_URL}/export`;

        const method = "get";
        const headers = {};
        const config = {
            url,
            method,
            headers,
            params,
            responseType: 'blob'
        };

        try {
            const response = await axios(config);
            let data = response.data;
            return {
              status: 'success',
              data,
            };
          } catch (e) {
            return {
              status: 'error',
              data: e.response.data
            };
          }
    },
}
