var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("DOCKING SCHEDULE")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.showInitDockingFleetModal = true;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        }), _vm._v("   CREATE EMERGENCY DOCKING")]), _c('a', {
          staticClass: "btn btn-primary mr-2",
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              _vm.showSetDockingScheduleModal = true;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-calendar mr-2"
        }), _vm._v("SET SCHEDULE DOCKING")]), _c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.docking_schedule_card",
            modifiers: {
              "docking_schedule_card": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })]), _c('ModalSelectFleets', {
          attrs: {
            "multiple": false
          },
          on: {
            "submit": _vm.onOpenModalCreateDocking
          },
          model: {
            value: _vm.showInitDockingFleetModal,
            callback: function callback($$v) {
              _vm.showInitDockingFleetModal = $$v;
            },
            expression: "showInitDockingFleetModal"
          }
        }), _c('ModalDocking', {
          attrs: {
            "action": _vm.dockingAction,
            "vehicle-id": _vm.selectedVehicleId,
            "docking-id": _vm.selectedDockingId
          },
          on: {
            "cancel": _vm.onCloseModalCreateDocking,
            "submit": _vm.onSubmitCreateDocking
          },
          model: {
            value: _vm.showDockingModal,
            callback: function callback($$v) {
              _vm.showDockingModal = $$v;
            },
            expression: "showDockingModal"
          }
        }), _c('ModalDockingSetSchedule', {
          on: {
            "cancel": function cancel($event) {
              return _vm.onCloseSetDockingScheduleModal();
            },
            "submit": function submit($event) {
              return _vm.onSubmitSetDockingSchedule();
            }
          },
          model: {
            value: _vm.showSetDockingScheduleModal,
            callback: function callback($$v) {
              _vm.showSetDockingScheduleModal = $$v;
            },
            expression: "showSetDockingScheduleModal"
          }
        }), _c('ModalDockingPending', {
          attrs: {
            "docking-id": _vm.selectedDockingId
          },
          on: {
            "cancel": function cancel($event) {
              return _vm.onClosePendingDocking();
            },
            "submit": function submit($event) {
              return _vm.onSubmitPendingDocking();
            }
          },
          model: {
            value: _vm.showPendingDockingModal,
            callback: function callback($$v) {
              _vm.showPendingDockingModal = $$v;
            },
            expression: "showPendingDockingModal"
          }
        })];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "visible": "",
            "id": "docking_schedule_card"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet Category",
            "label-for": "fleet_category"
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "label": "name",
            "options": _vm.vehicleTypes,
            "reduce": function reduce(type) {
              return type.id;
            }
          },
          model: {
            value: _vm.lists.params.vehicleTypeIds,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.params, "vehicleTypeIds", $$v);
            },
            expression: "lists.params.vehicleTypeIds"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Docking Type",
            "label-for": "docking_type"
          }
        }, [_c('v-select', {
          attrs: {
            "options": _vm.dockingTypes
          },
          model: {
            value: _vm.lists.params.dockingType,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.params, "dockingType", $$v);
            },
            expression: "lists.params.dockingType"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_c('v-select', {
          attrs: {
            "options": _vm.dockingStatus
          },
          model: {
            value: _vm.lists.params.dockingStatus,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.params, "dockingStatus", $$v);
            },
            expression: "lists.params.dockingStatus"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Period",
            "label-for": "Period"
          }
        }, [_c('date-picker', {
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "format": "DD MMM YYYY",
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          on: {
            "change": function change($event) {
              return _vm.onFilterChangeRangeDate();
            }
          },
          model: {
            value: _vm.lists.dateRange,
            callback: function callback($$v) {
              _vm.$set(_vm.lists, "dateRange", $$v);
            },
            expression: "lists.dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getDockingScheduleActiveData();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DOCKING TYPE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("REMAINING DAYS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("REMAINING DATE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_vm.lists.loading ? _c('tr', [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "7"
          }
        }, [_vm._v("Loading...")])]) : _vm._l(_vm.lists.data, function (data, index) {
          return _c('tr', {
            key: index,
            class: {
              'bg-danger': ['OVER_SCHEDULE'].includes(data.status),
              'bg-info': ['PROGRESS'].includes(data.status),
              'bg-warning': ['REVIEW'].includes(data.status),
              'bg-primary': ['APPROVE'].includes(data.status)
            }
          }, [_c('th', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(index + 1))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.vehicle.name))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.dockingType))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.remainingDays ? "".concat(data.remainingDays, " days") : '-'))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(data.dockingSchedule))]), ['PROGRESS', 'REVIEW', 'APPROVE'].includes(data.status) ? [_c('td', {
            staticClass: "text-center",
            attrs: {
              "colspan": "2"
            }
          }, [data.status === 'PROGRESS' ? _c('strong', [_vm._v("PROGRESS - CHECK TAB PROGRESS")]) : _vm._e(), data.status === 'REVIEW' ? _c('strong', [_vm._v("NEED REVIEW - CHECK TAB PROGRESS")]) : _vm._e(), data.status === 'APPROVE' ? _c('strong', [_vm._v("NEED APPROVE - CHECK TAB PROGRESS")]) : _vm._e()])] : [_c('td', {
            staticClass: "text-center"
          }, [['ACTIVE'].includes(data.status) ? _c('b-badge', {
            attrs: {
              "variant": "success d-block"
            }
          }, [_vm._v("ACTIVE")]) : ['DUE_DATE'].includes(data.status) ? _c('b-badge', {
            attrs: {
              "variant": "warning d-block"
            }
          }, [_vm._v("DUE DATE")]) : ['PENDING'].includes(data.status) ? _c('b-badge', {
            attrs: {
              "variant": "primary d-block"
            }
          }, [_vm._v("FIRST PENDING")]) : ['OVER_SCHEDULE'].includes(data.status) ? _c('b-badge', {
            attrs: {
              "variant": "light d-block"
            }
          }, [_vm._v("OVER SCHEDULE")]) : _vm._e()], 1), _c('td', {
            staticClass: "text-center"
          }, [_c('b-dropdown', {
            attrs: {
              "size": "lg",
              "variant": "link iq-bg-primary iq-border-radius-10",
              "toggle-class": "text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton5",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-ship m-0 text-primary"
                })])];
              },
              proxy: true
            }], null, true)
          }, [['DUE_DATE', 'PENDING', 'OVER_SCHEDULE'].includes(data.status) ? _c('b-dropdown-item', {
            attrs: {
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.onOpenModalDocking(data, 'CREATE_FROM_SCHEDULE');
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square-o mr-2"
          }), _vm._v("DOCKING")]) : _vm._e(), ['DUE_DATE', 'PENDING', 'OVER_SCHEDULE'].includes(data.status) ? _c('b-dropdown-item', {
            attrs: {
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                return _vm.onOpenModalPendingDocking(data);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-clock mr-2"
          }), _vm._v("PENDING")]) : _vm._e(), _c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "variant": "dark"
            }
          }, [_c('i', {
            staticClass: "fa fa-history mr-2"
          }), _vm._v("HISTORY DOCKING")])], 1)], 1)]], 2);
        })], 2)]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.lists.meta.total,
            "per-page": _vm.lists.meta.perPage,
            "align": "center"
          },
          on: {
            "input": _vm.getDockingScheduleActiveData
          },
          model: {
            value: _vm.lists.params.page,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.params, "page", $$v);
            },
            expression: "lists.params.page"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }