<template>
  <b-modal :id="`modal---${id}`" v-model="model" size="md" title="Pending Docking" header-bg-variant="light" header-text-variant="dark"
           hide-footer
           footer-bg-variant="light" footer-text-variant="dark">
    <b-form @submit.prevent="submit()">
      <b-row>
        <b-col md="12">
          <b-form-group label="Pending Date *" label-for="pending_date">
            <date-picker required id="pending_date" v-model="form.pendingDate" value-type="DD-MM-YYYY" type="date" placeholder="Select date" format="DD MMM YYYY"></date-picker>
          </b-form-group>
          <b-form-group label="Reason Pending" label-for="reason_pending">
            <b-form-textarea v-model="form.reason" id="reason_pending" rows="3"></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" class="text-right">
          <b-button type="submit" block :disabled="form.saving" variant="primary" class="ml-2 text-center"><i class="fa fa-calendar mr-2"></i> Pending Docking </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import { dockingActions, fleetsActions } from '@src/Utils/helper'

export default {
  name: 'ModalDockingPending',
  mounted () {
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'pending-docking'
    },
    vehicleId: {
      type: Number,
      default: null
    },
    dockingId: {
      type: String,
      default: null
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    modalTitle () {
      return `Pending Docking ${this.fleetDetail ? `- ${this.fleetDetail.name}` : ''}`
    }
  },
  watch: {
    model () {
      this.onResetStates()
      if (this.model === true) {
        if (this.vehicleId) {
          this.onInit()
        }
      }
    }
  },
  data () {
    return {
      loading: true,
      fleetDetail: null,
      form: {
        saving: false,
        pendingDate: '',
        reason: ''
      }
    }
  },
  methods: {
    ...fleetsActions,
    ...dockingActions,
    onResetStates () {
      this.loading = false
      this.fleetDetail = null
    },
    resetForm () {
      this.form = {
        saving: false,
        pendingDate: '',
        reason: ''
      }
    },
    cancel () {
      this.resetForm()
      this.$emit('cancel')
    },
    async onInit () {
      this.loading = true
      await Promise.all([
        this.fetchFleetDetail()
      ])
      this.loading = false
    },
    async submit () {
      this.form.saving = true
      const payload = { ...this.form, dockingId: this.dockingId }

      const { status } = await this.createDockingPending(payload)

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      this.form.saving = false
      this.$emit('submit')
    },
    async fetchFleetDetail () {
      const res = await this.getFleetById(this.vehicleId)

      if (res.status === 'success') {
        this.fleetDetail = res.data
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
      this.loadingForm = false
    }
  }
}
</script>

<style scoped>

</style>
