export function numberFormat (number) {
  return new Intl.NumberFormat().format(number)
}

// Convert DMSLat DMSLon to Lan Lot
export function ParseDMS (input) {
  let parts = input.split(/[^\d\w\.]+/)
  let lat = ConvertDMSToDD(parts[0], parts[2], parts[3], parts[4])
  let lng = ConvertDMSToDD(parts[5], parts[7], parts[8], parts[9])

  return {
    Latitude: lat,
    Longitude: lng,
    Position: lat + ',' + lng
  }
}

function ConvertDMSToDD (degrees, minutes, seconds, direction) {
  let dd = Number(degrees) + Number(minutes) / 60 + Number(seconds) / (60 * 60)

  if (direction == 'S' || direction == 'W') {
    dd = dd * -1
  } // Don't do anything for N or E
  return dd
}

export function singleConvertDMSToDD (dms) {
  let parts = dms.split(/[^\d+(\,\d+)\d+(\.\d+)?\w]+/)
  let degrees = parseFloat(parts[0])
  let minutes = parseFloat(parts[1])
  let seconds = parseFloat(parts[2].replace(',', '.'))
  let direction = parts[3]

  console.log('degrees: ' + degrees)
  console.log('minutes: ' + minutes)
  console.log('seconds: ' + seconds)
  console.log('direction: ' + direction)

  let dd = degrees + minutes / 60 + seconds / (60 * 60)

  if (direction == 'S' || direction == 'W') {
    dd = dd * -1
  } // Don't do anything for N or E
  return dd
}

/**
 * Convert longitude/latitude decimal degrees to degrees and minutes
 * DDD to DMS, no seconds
 * @param lat, latitude degrees decimal
 * @param lng, longitude degrees decimal
 */

export function convertDMS (lat, lng) {
  var convertLat = Math.abs(lat)
  var LatDeg = Math.floor(convertLat)
  var LatMin = (Math.floor((convertLat - LatDeg) * 60))
  var LatCardinal = ((lat > 0) ? 'n' : 's')

  var convertLng = Math.abs(lng)
  var LngDeg = Math.floor(convertLng)
  var LngMin = (Math.floor((convertLng - LngDeg) * 60))
  var LngCardinal = ((lng > 0) ? 'e' : 'w')

  return LatDeg + LatCardinal + LatMin + '<br />' + LngDeg + LngCardinal + LngMin
}
