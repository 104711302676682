<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-input v-model="keyword" class="mb-3" placeholder="Search..."></b-input>
      </b-col>
      <b-col md="12" class="mt-4 m-auto">
        <table class="table table-bordered">
          <thead>
          <tr class="bg-warning">
            <th class="">Vehicle</th>
            <th class="">Classification of Loss</th>
            <th class="">Date of Loss</th>
            <th class="">Amount of Loss</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="nonClaims.length === 0">
            <td colspan="4">No Data or Search not found</td>
          </tr>
          <tr
            v-for="(list, index) in nonClaims"
            :key="index"
          >
            <td>
              {{ list.vehicle ? list.vehicle.name : '-' }}
            </td>
            <td>
              {{ list.classification_of_loss ? list.classification_of_loss.name : '-' }}
            </td>
            <td>
              {{ list.date_of_loss }}
            </td>
            <td>
              {{ numberFormat(list.cost_of_loss) }}
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { numberFormat } from '@src/plugins/helpers'
import _ from 'lodash'
export default {
  name: 'SummaryManagementNonClaim',
  props: {
    lists: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    nonClaims () {
      if (!this.lists) {
        return []
      }

      return _.orderBy(this.lists.filter(form => {
        if (!this.keyword) {
          return true
        }

        return (form.vehicle && form.vehicle.name.toString().toLowerCase().includes(this.keyword.toLowerCase())) ||
          (form.classification_of_loss && form.classification_of_loss.name.toString().toLowerCase().includes(this.keyword.toLowerCase())) ||
          (form.date_of_loss.toString().toLowerCase().includes(this.keyword.toLowerCase()))
      }), ['date_of_loss'], ['asc'])
    }
  },
  data () {
    return {
      keyword: ''
    }
  },
  methods: {
    numberFormat (number) {
      return numberFormat(number)
    }
  }
}
</script>

<style scoped>

</style>
