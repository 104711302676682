<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th
          class="text-center"
          v-for="(state, index) in tableData.head"
          :key="index"
          >{{ state }}</th
        >
      </tr>
    </thead>
    <tbody>
      <tr v-for="(data, index) in tableData.body" :key="`data-${index}`">
        <td class="text-center">{{
          currentPage > 1 ? currentPage + index + 1 : index + 1
        }}</td>
        <td
          ><router-link
            :to="{
              path: `profile-candidate/${data.id}`,
              params: { id: data.id },
            }"
            >{{ data.full_name }}</router-link
          ><br /><small style="color: gray"
            >Seaferer Code : {{ data.seaferer_code }}</small
          ></td
        >
        <td class="text-center">{{ data.name_position }}</td>
        <td class="text-center">{{
          data.apply_date
            ? moment(new Date(data.apply_date)).format('DD MMMM YYYY')
            : '-'
        }}</td>
        <td class="text-center">{{
          data.date_interview
            ? moment(new Date(data.date_interview)).format(
                'DD MMMM YYYY HH:MM:ss'
              )
            : '-'
        }}</td>
        <td class="text-center">{{ data.expert || '-' }}</td>
        <td class="text-center">
          <b-badge
            :variant="`${
              data.status === 'candidate'
                ? 'info'
                : data.status === 'expired_interview'
                ? 'danger'
                : data.status === 'waiting_result'
                ? 'success'
                : 'primary'
            } d-block`"
          >
            {{ data.status.split('_').join(' ') }}
          </b-badge>
        </td>
        <td class="text-center">
          <b-dropdown
            size="lg"
            variant="link iq-bg-primary iq-border-radius-10"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <span
                class="dropdown-toggle p-0"
                id="dropdownMenuButton5"
                data-toggle="dropdown"
              >
                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
              </span>
            </template>

            <b-dropdown-item
              href="#"
              v-b-modal.verification_document
              @click="() => setUserId(data)"
              :disabled="data.status === 'checklist' ? false : true"
              ><i class="fa fa-check mr-2"></i>Verification
            </b-dropdown-item>

            <b-dropdown-item
              href="#"
              v-b-modal.setup-interview
              :disabled="data.status === 'candidate' ? false : true"
              @click="() => setUserId(data)"
            >
              <i class="fa fa-calendar mr-2"></i>Set Interview
            </b-dropdown-item>

            <b-dropdown-item
              :disabled="data.status === 'interview' ? false : true"
            >
              <router-link
                :class="
                  data.status === 'interview' ? 'text-dark' : 'text-secondary'
                "
                :to="{
                  path: `/crewing/interview-review/${data.id}`,
                  params: { id: data.id },
                }"
              >
                <i class="fa fa-address-card mr-2"></i>Interview
                Review</router-link
              >
            </b-dropdown-item>

            <b-dropdown-item
              href="#"
              v-b-modal.medical-checkup
              @click="() => setUserId(data)"
              :disabled="data.status === 'mcu' ? false : true"
              ><i class="fas fa-stethoscope mr-2"></i>Medical
              Checkup</b-dropdown-item
            >

            <b-dropdown-item
              v-b-toggle.sign-on
              href="#"
              @click="() => setUserId(data)"
              :disabled="data.status === 'contract' ? false : true"
              ><i class="fas fa-handshake mr-2"></i>Contract / Sign
              On</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              v-b-modal.induction
              @click="() => setUserId(data)"
              :disabled="data.status === 'induction' ? false : true"
              ><i class="fa fa-file mr-2"></i>Induction</b-dropdown-item
            >
            <b-dropdown-item
              href="#"
              v-b-toggle.blacklist-crew
              @click="() => setDataUser(data)"
              ><i class="fa fa-ban mr-2"></i>Black List</b-dropdown-item
            >
          </b-dropdown>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { crewingActions } from '@src/Utils/helper'

export default {
  props: {
    currentPage: {
      type: Number,
      default: false,
    },
    tableData: {
      type: Object,
      default: false,
    },
    value: {
      type: Object,
      default: () => {
        return {
          id: null,
          key: '',
        }
      },
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
      },
    },
  },
  mounted() {},
  methods: {
    ...crewingActions,
    setDataUser(state) {
      this.form.id = state.id
      this.form.key = 'candidate'
    },
    setUserId(state) {
      this.form.id = state.id
      this.form.key = ''
    },
  },
}
</script>
