var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("OPERATION MANAGEMENT")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.import-operational",
            modifiers: {
              "import-operational": true
            }
          }],
          staticClass: "text-secondary float-right mr-4",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-upload"
        }), _vm._v(" Import ")]), _c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-filter",
            modifiers: {
              "collapse-filter": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          attrs: {
            "id": "collapse-filter",
            "visible": ""
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Category:",
            "label-for": "kind-fleet"
          }
        }, [_vm._l(_vm.vehicleTypes, function (item, index) {
          return [_c('b-form-checkbox', {
            key: index,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": ""
            },
            model: {
              value: item.checked,
              callback: function callback($$v) {
                _vm.$set(item, "checked", $$v);
              },
              expression: "item.checked"
            }
          }, [_vm._v(" " + _vm._s(item.name) + " ")])];
        })], 2)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "search_vehicle",
            "label": "Search"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "search_vehicle",
            "placeholder": "search by name"
          },
          model: {
            value: _vm.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "search", $$v);
            },
            expression: "params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Company"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.CompanyName,
            "id": "gender"
          },
          model: {
            value: _vm.params.companyId,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "companyId", $$v);
            },
            expression: "params.companyId"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Category Ownership"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.categoryOwnership,
            "id": "gender"
          },
          model: {
            value: _vm.params.ownership,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "ownership", $$v);
            },
            expression: "params.ownership"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.fetchFleetActivities
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _vm.loading ? _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-center my-5 w-100"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3767018210)
  }) : [_vm.fleetActivities.length < 1 ? _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('p', {
          staticClass: "text-center"
        }, [_vm._v("No fleet activities found.")])];
      },
      proxy: true
    }], null, false, 2002124283)
  }) : [_c('b-row', _vm._l(_vm.fleetActivities, function (f, i) {
    return _c('b-col', {
      key: "f-".concat(i),
      attrs: {
        "sm": "4"
      }
    }, [_c('b-card', {
      staticClass: "iq-mb-3"
    }, [_c('b-card-text', [_c('div', {
      staticClass: "top d-flex justify-content-between"
    }, [_c('div', {
      staticClass: "mb-5",
      staticStyle: {
        "z-index": "9"
      }
    }), _c('div', {
      staticClass: "mb-5",
      staticStyle: {
        "z-index": "9"
      }
    }, [_c('b-dropdown', {
      attrs: {
        "size": "sm",
        "text": "Small",
        "variant": "light",
        "right": ""
      }
    }, [_c('template', {
      slot: "button-content"
    }, [_c('i', {
      staticClass: "fa fa-ellipsis-h"
    })]), _c('b-dropdown-item', {
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.fetchFleetDetail(f.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-edit"
    }), _vm._v(" Edit")]), _c('b-dropdown-item', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteFleet(f);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    }), _vm._v(" Delete")])], 2)], 1), _c('div', {
      staticClass: "boat",
      class: {
        'boat-no-animation': [7, 6].includes(f.vehicleType.id)
      }
    }, [f.vehicleType && f.vehicleType.icon && f.vehicleType.icon.url ? _c('img', {
      attrs: {
        "src": f.vehicleType.icon.url,
        "alt": ""
      }
    }) : _c('img', {
      attrs: {
        "src": require('../../assets/images/Barge.png'),
        "alt": ""
      }
    })]), ![7, 6].includes(f.vehicleType.id) ? _c('div', {
      staticClass: "waves"
    }) : _vm._e()]), _c('div', {
      staticClass: "bottom",
      style: {
        background: [7, 6].includes(f.vehicleType.id) ? '#d4e8ff !important' : '#33ccff'
      }
    }, [![7, 6].includes(f.vehicleType.id) ? _c('div', {
      staticClass: "float"
    }) : _vm._e()])]), _c('b-card-text', [_c('router-link', {
      attrs: {
        "to": {
          path: 'fleet-information',
          query: {
            vehicleId: f.id,
            menuObject: _vm.hashObject(f.modules[2])
          }
        }
      }
    }, [_c('h4', {
      staticClass: "mb-3 text-center text-danger"
    }, [_c('b', [_vm._v(_vm._s(f.name))])])])], 1), _c('b-card-text', [_c('b-row', {
      staticClass: "text-center"
    }, [_vm._l(f.modules, function (m, im) {
      return [m.hasAccess && (_vm.validateTechnicalPermission(m.url) || _vm.validateOperationalPermission(m.url) || _vm.validateReportPermission(m.url)) ? _c('b-col', {
        key: "m-".concat(im),
        staticClass: "mb-3",
        attrs: {
          "cols": "4",
          "md": "3"
        }
      }, [_c('router-link', {
        attrs: {
          "to": {
            path: m.url,
            query: {
              vehicleId: f.id,
              menuObject: _vm.hashObject(m)
            }
          }
        }
      }, [m.icon && m.icon.url ? _c('img', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.top",
          value: m.name,
          expression: "m.name",
          modifiers: {
            "top": true
          }
        }],
        attrs: {
          "src": m.icon.url,
          "alt": "Responsive image"
        }
      }) : _c('img', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.top",
          value: 'Operational',
          expression: "'Operational'",
          modifiers: {
            "top": true
          }
        }],
        attrs: {
          "src": require("../../assets/images/flaticon/operational.png"),
          "alt": "Responsive image"
        }
      }), _c('small', {
        staticStyle: {
          "font-size": "8pt !important"
        }
      }, [_vm._v(_vm._s(m.name))])])], 1) : _vm._e(), m.hasAccess && false ? _c('b-col', {
        key: "m-b-".concat(im),
        attrs: {
          "md": "12"
        }
      }, [_c('router-link', {
        attrs: {
          "to": "/ops/docking"
        }
      }, [_c('b-button', {
        staticClass: "mb-3",
        attrs: {
          "block": "",
          "variant": "success"
        }
      }, [_c('i', {
        staticClass: "fa fa-ship"
      }), _vm._v("Docking Intermediate Survey")])], 1)], 1) : _vm._e()];
    })], 2)], 1)], 1)], 1);
  }), 1), _c('b-row', [!_vm.isHideLoadMore ? _c('b-col', {
    attrs: {
      "md": "auto mx-auto my-3"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "dark px-4 py-3"
    },
    on: {
      "click": function click($event) {
        return _vm.fetchFleetActivitiesLoadMore();
      }
    }
  }, [_vm.isFetchingLoadMore ? _c('b-spinner', {
    staticClass: "mr-2",
    attrs: {
      "small": "",
      "label": "Loading..."
    }
  }) : _vm._e(), _vm._v(" load more")], 1)], 1) : _vm._e()], 1), _c('b-modal', {
    attrs: {
      "id": "edit-fleet",
      "size": "xl",
      "no-close-on-backdrop": true,
      "header-bg-variant": _vm.modal.headerBgVariant,
      "header-text-variant": _vm.modal.headerTextVariant,
      "body-bg-variant": _vm.modal.bodyBgVariant,
      "body-text-variant": _vm.modal.bodyTextVariant,
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('h5', [_vm._v("Edit Your Fleet")]), _c('b-button', {
          attrs: {
            "size": "md",
            "variant": "outline-danger"
          },
          on: {
            "click": close
          }
        }, [_c('i', {
          staticClass: "fa fa-times"
        }), _vm._v(" Close ")])];
      }
    }])
  }, [_c('AddFleet', {
    attrs: {
      "formData": _vm.fleetDetail
    },
    on: {
      "save": function save($event) {
        return _vm.fetchFleetActivities();
      },
      "childAction": function childAction($event) {
        return _vm.$bvModal.hide('edit-fleet');
      }
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "import-operational",
      "title": "Bulk Add Operational Data",
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var hide = _ref2.hide;
        return [_c('b-row', {
          staticClass: "px-5"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('ImportWizardOperational', {
          attrs: {
            "closeSidebar": _vm.closeSidebar
          }
        })], 1)], 1)];
      }
    }])
  })], 1), _vm.fleetDetailMiDataModal ? _c('b-sidebar', {
    attrs: {
      "id": "sidebar-mi",
      "title": "".concat(_vm.fleetDetailMiDataModal.name, " - TOTAL CASE DETAIL"),
      "width": "1350px",
      "backdrop-variant": "dark",
      "right": "",
      "backdrop": "",
      "shadow": ""
    }
  }, [_c('b-row', {
    staticClass: "pt-2 pl-3 pr-3 pb-2"
  }, [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('hr')]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": true,
            "href": "#accident",
            "title": "Accident / Incident"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#recommendation",
            "title": "Recommendation"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#evaluation",
            "title": "Evaluation"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#erm",
            "title": "Enterprise Risk Management"
          }
        })], 1)], 1)];
      },
      proxy: true
    }], null, false, 3080177949)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-3"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "accident"
    }
  }, [_c('SummaryManagementDetailAccidentList', {
    attrs: {
      "data": _vm.fleetDetailMiDataModal.forms
    }
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "recommendation"
    }
  }, [_c('SummaryManagementRecommendations', {
    attrs: {
      "lists": _vm.fleetDetailMiDataModal.recommendations
    }
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "evaluation"
    }
  }, [_c('SummaryManagementEvaluations', {
    attrs: {
      "lists": _vm.fleetDetailMiDataModal.evaluations
    }
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "erm"
    }
  }, [_c('SummaryManagementERM', {
    attrs: {
      "lists": _vm.fleetDetailMiDataModal.erm
    }
  })], 1)], 1)])], 1)], 1) : _vm._e()]]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }