<template>
  <b-container fluid>
    <b-row>
      <b-col md="7">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col md="12">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="'year'">
                    <b-row>
                      <b-col md="3">
                        <h4 style="cursor: pointer; font-weight: bold" class="p-3">2021</h4>
                      </b-col>
                      <b-col md="8" class="text-right">
                        <b-form-group label="Total Seluruh Premium">
                          <h4 style="cursor: pointer; font-weight: bold"> IDR 9.809.729.019,56 </h4>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-collapse id="year" role="tabpanel">
                    <b-row class="pl-4 mt-2">
                      <b-col md="12">
                        <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="'budget-company'">
                          <h4 style="cursor: pointer; font-weight: bold" class="p-3">PT.Transcoal Pacific</h4>
                        </b-card-header>
                        <b-collapse id="budget-company" role="tabpanel">
                          <b-card-body>
                            <b-row>
                              <b-col md="12" class="mb-3">
                                <table class="table table-sm table-striped">
                                  <thead>
                                  <tr>
                                    <th>Component</th>
                                    <th>Value</th>
                                    <th>Vehicle Type (Tug Boat), Oil Barge, Sea Truck</th>
                                  </tr>
                                  <tr>
                                    <td colspan="2">TSI</td>
                                    <td> IDR 351.094.831.538,10 </td>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <td>HM</td>
                                    <td>75%</td>
                                    <td> IDR 263.321.123.653,58 </td>
                                  </tr>
                                  <tr>
                                    <td>IV</td>
                                    <td>25%</td>
                                    <td> IDR 87.773.707.884,53 </td>
                                  </tr>
                                  <tr>
                                    <td>HM Rate</td>
                                    <td>0.952%</td>
                                    <td> USD 280.831,44 </td>
                                  </tr>
                                  <tr>
                                    <td>IV Rate</td>
                                    <td>0.150%</td>
                                    <td> USD 14.401,61 </td>
                                  </tr>
                                  </tbody>
                                  <tfoot>
                                  <tr>
                                    <td colspan="2">Grand Total</td>
                                    <td><strong> IDR 4.339.925.943,83 </strong></td>
                                  </tr>
                                  </tfoot>
                                </table>
                              </b-col>
                              <b-col md="12" class="mb-3">
                                <table class="table table-sm table-striped">
                                  <thead>
                                  <tr>
                                    <th>Component</th>
                                    <th>Value</th>
                                    <th>Vehicle Type (Barge)</th>
                                  </tr>
                                  <tr>
                                    <td colspan="2">TSI</td>
                                    <td>  SGD 3.250.000,00  </td>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                    <td>HM</td>
                                    <td>75%</td>
                                    <td> SGD 2.437.500,00 </td>
                                  </tr>
                                  <tr>
                                    <td>IV</td>
                                    <td>25%</td>
                                    <td> SGD 812.500,00 </td>
                                  </tr>
                                  <tr>
                                    <td>HM Rate</td>
                                    <td>0.952%</td>
                                    <td> SGD 17.818,13 </td>
                                  </tr>
                                  <tr>
                                    <td>IV Rate</td>
                                    <td>0.150%</td>
                                    <td> SGD 1.218,75 </td>
                                  </tr>
                                  </tbody>
                                  <tfoot>
                                  <tr>
                                    <td colspan="2">Grand Total</td>
                                    <td><strong> IDR 205.598.250,00 </strong></td>
                                  </tr>
                                  </tfoot>
                                </table>
                              </b-col>
                            </b-row>
                          </b-card-body>
                        </b-collapse>
                      </b-col>
                    </b-row>
                  </b-collapse>
                </b-card>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <b-col md="5">
        <iq-card class="border border-light shadow-lg">
          <template v-slot:headerTitle>
            <h4 class="card-title">Add Year Budget</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col cols="4">
                <b-form-group label="Year :" label-for="kind-fleet">
                  <v-select label="text" :options="yearOptions" :reduce="(type) => type.value" v-model="year"></v-select>
                </b-form-group>
              </b-col>
              <b-col cols="8">
                <b-form-group label="Company :" label-for="kind-fleet">
                  <v-multiselect v-model="companySelected" placeholder="For Company" label="name" track-by="id" :options="companyOptions">
                    <span slot="noResult">Company not found.</span>
                  </v-multiselect>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Vehicle Type" label-for="category_for">
                  <v-multiselect
                    v-model="vehicleTypeSelected"
                    placeholder="Search categories"
                    label="name"
                    track-by="id"
                    :options="fleet_types"
                    :multiple="true"
                  >
                    <span slot="noResult">Category not found.</span>
                  </v-multiselect>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="TSI" label-for="category_for">
                  <b-input-group size="md" prepend="IDR">
                    <b-form-input type="number"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" class="mt-2 mb-3">
                <hr/>
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>Component</th>
                    <th>Option Value</th>
                    <th>Value</th>
                    <th>#</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <b-form-input type="text"></b-form-input>
                    </td>
                    <td>
                      <v-select label="text" :options="optionValue" :reduce="(type) => type.value" v-model="componentOption"></v-select>
                    </td>
                    <td>
                      <b-form-input type="number"></b-form-input>
                    </td>
                    <td>
                      <b-button variant="warning" class="mb-3 mr-1" size="xs"><i class="ri-add-line"></i> Add</b-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <hr/>
              </b-col>
              <b-col md="12">
                <b-form-group class="text-right">
                  <b-button type="submit" variant="primary"><span>Save</span></b-button>
                  <b-button type="submit" variant="none" class="iq-bg-danger ml-3">Cancel</b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'

export default {
  name: 'ComponentBudget',
  mounted () {
    xray.index()
  }
}
</script>
