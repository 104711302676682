<template>
  <b-container fluid>
    <!-- TOP MENU -->
    <b-row>
      <!-- Card Fleet -->
      <b-col md="12">
        <b-card class="iq-mb-3">
          <b-card-text>
            <InAppMenu :menus="menus"/>
          </b-card-text>
        </b-card>
      </b-col>
      <!-- End Card Fleet -->
    </b-row>
    <!-- TOP MENU END -->
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import InAppMenu from '@src/components/Menu/InAppMenu'

export default {
  name: 'Portal',
  components: { InAppMenu },
  computed: {
    ...mapGetters('Auth', ['profile']),
    menus() {
      const menuId = this.$route.query.menuId
      const foundMenu = this.profile.menu.find(menu => menu.menuId == menuId)

      if (!menuId && !foundMenu) return []
      return foundMenu.child
    }
  },
  mounted () {},
  methods: {

  }
}
</script>
