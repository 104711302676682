var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-user-profile-block1"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "user-details-block"
        }, [_c('div', {
          staticClass: "user-profile text-center"
        }, [_c('img', {
          staticClass: "avatar-130 img-fluid",
          attrs: {
            "src": require("../../assets/images/ship.png"),
            "alt": "profile-img"
          }
        })]), _c('div', {
          staticClass: "text-center mt-3"
        }, [_c('h4', [_c('b', [_vm._v("TB. ETI 201")])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        })], 1)]), _c('table', {
          staticClass: "table table-striped mt-3"
        }, [_c('tbody', [_c('tr', {
          staticClass: "bg-primary"
        }, [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_c('strong', [_vm._v("SHIP PARTICULAR")])])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Type Kapal")])]), _c('td', [_vm._v("TUG BOAT")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Bendera")])]), _c('td', [_vm._v("Indonesia")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Klasifikasi")])]), _c('td', [_vm._v("BKI Class")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Tahun Pembuatan")])]), _c('td', [_vm._v("2021")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Tempat Pembangunan")])]), _c('td', [_vm._v("Samarinda")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Tempat Pendaftaran")])]), _c('td', [_vm._v("Jakarta")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Ukuran Kapal")])]), _c('td', [_vm._v("200 X")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Main Engine")])]), _c('td', [_c('span', [_vm._v("Starboard RH : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_c('strong', [_vm._v("1.250")])])], 1), _c('br'), _c('span', [_vm._v("Portside RH : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_c('strong', [_vm._v("1.250")])])], 1)])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Auxuliary Engine")])]), _c('td', [_c('span', [_vm._v("Starboard RH : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_c('strong', [_vm._v("1.250")])])], 1), _c('br'), _c('span', [_vm._v("Portside RH : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_c('strong', [_vm._v("1.250")])])], 1)])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_vm._v("FINAL SEA TRIAL RECORD")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary h6"
        }, [_vm._v("PRINCIPAL DIMENSION")]), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "L.O.A",
            "label-for": "loa"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "required": ""
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "L.W.L",
            "label-for": "lwl"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "required": ""
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "L.B.P",
            "label-for": "lbp"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "required": ""
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])])], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "B mld",
            "label-for": "bmld"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "required": ""
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "D mld",
            "label-for": "dmld"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "required": ""
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "T",
            "label-for": "t"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "required": ""
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])])], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Main Engine",
            "label-for": "main_engine"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsMerkEngine,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Fleet")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedMerkEngine,
            callback: function callback($$v) {
              _vm.selectedMerkEngine = $$v;
            },
            expression: "selectedMerkEngine"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Auxiliary Engine",
            "label-for": "generator"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsMerkEngine,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Fleet")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedMerkEngine,
            callback: function callback($$v) {
              _vm.selectedMerkEngine = $$v;
            },
            expression: "selectedMerkEngine"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Gearbox",
            "label-for": "gearbox"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsMerkEngine,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Fleet")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedMerkEngine,
            callback: function callback($$v) {
              _vm.selectedMerkEngine = $$v;
            },
            expression: "selectedMerkEngine"
          }
        })], 1)], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary h6"
        }, [_vm._v("FULL LOAD RECORD")]), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Tanggal Percobaan",
            "label-for": "tanggal_percobaan"
          }
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "date",
            "id": "tanggal_percobaan"
          }
        })]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Tempat Percobaan",
            "label-for": "tempat_percobaan"
          }
        }, [_c('b-form-input', {
          attrs: {
            "required": ""
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Kedalaman",
            "label-for": "kedalaman"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "kedalaman"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Jarak Pengukuran",
            "label-for": "jarak_pengukuran"
          }
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text"
          }
        })]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Arah Angin",
            "label-for": "arah_angin"
          }
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text"
          }
        })]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Kecepatan Angin",
            "label-for": "cuaca"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("Knots")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Cuaca",
            "label-for": "cuaca"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("°C")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Sarat Depan",
            "label-for": "sarat_depan"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Sarat Belakang",
            "label-for": "sarat_belakang"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Perbedaan Trim",
            "label-for": "perbedaan_trim"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Tempratur Udara",
            "label-for": "tempratur_udara"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("°C")])])])])], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary h6"
        }, [_vm._v("MUATAN DAN TANGKI")]), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-12",
          attrs: {
            "label": "Muatan",
            "label-for": "muatan"
          }
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text"
          }
        })]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Jumlah Orang Atas Kapal",
            "label-for": "jml_crew"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("Orang")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Tangki Induk BBM",
            "label-for": "tangkiIndukBBM"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("Liter")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Tangki Induk Harian",
            "label-for": "tangkiIndukHarian"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("Liter")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Tangki Induk Oli",
            "label-for": "tangkiIndukOli"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("Liter")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Tangki Harian Oli",
            "label-for": "tangkiHarianOli"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("Liter")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Tangki Air Tawar",
            "label-for": "TangkiAirTawar"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          staticClass: "form-control"
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("Ton")])])])])], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary h6"
        }, [_vm._v("ATTACHMENT SEA TRIAL")]), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Nama File",
            "label-for": "nama_file"
          }
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text"
          }
        })]), _c('b-form-group', {
          staticClass: "col-md-5",
          attrs: {
            "label": "Jumlah Orang Atas Kapal",
            "label-for": "jml_crew"
          }
        }, [_c('div', {
          staticClass: "custom-file"
        }, [_c('input', {
          staticClass: "custom-file-input",
          attrs: {
            "type": "file",
            "id": "validatedCustomFile",
            "required": ""
          }
        }), _c('label', {
          staticClass: "custom-file-label",
          attrs: {
            "for": "validatedCustomFile"
          }
        }, [_vm._v("Browse file...")])]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" File Type * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("3 MB / File")])], 1)]), _c('b-form-group', {
          staticClass: "col-md-1",
          attrs: {
            "label": "Action",
            "label-for": "nama_file"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus pr-0"
        })])], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Nama File",
            "label-for": "nama_file"
          }
        }, [_c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text"
          }
        })]), _c('b-form-group', {
          staticClass: "col-md-5",
          attrs: {
            "label": "Jumlah Orang Atas Kapal",
            "label-for": "jml_crew"
          }
        }, [_c('div', {
          staticClass: "custom-file"
        }, [_c('input', {
          staticClass: "custom-file-input",
          attrs: {
            "type": "file",
            "id": "validatedCustomFile",
            "required": ""
          }
        }), _c('label', {
          staticClass: "custom-file-label",
          attrs: {
            "for": "validatedCustomFile"
          }
        }, [_vm._v("Browse file...")])]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" File Type * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("3 MB / File")])], 1)]), _c('b-form-group', {
          staticClass: "col-md-1",
          attrs: {
            "label": "Action",
            "label-for": "nama_file"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash pr-0"
        })])], 1)], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary h5"
        }, [_vm._v("MAIN ENGINE RECORD")]), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary h6"
        }, [_vm._v("Main Engine - Starboard Side")]), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped table-bordered"
        }, [_c('thead', {
          staticClass: "table-success"
        }, [_c('tr', [_c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v(" NO ")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Load (Percent)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Time (Menit)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "colspan": "6"
          }
        }, [_vm._v("MAIN ENGINE")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("GEARBOX")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Temp. Cover Crankcase (°C)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Interm. Shaft Propeller (°C)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Shaft Propeller (°C)")])]), _c('tr', [_c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("Speed (Knots)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("Engine (RPM)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("Exhaust Temperatur (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("FW Temp (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Temp (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Press (kg/cm2)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Press (Psi)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Temp (°C)")])])]), _c('tbody', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v(" #1"), _c('br'), _c('a', {
          staticClass: "text-danger",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Remove")])]), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "load",
            "name": "load"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "time",
            "name": "time"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "speed",
            "name": "speed"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "engine_rpm",
            "name": "engine_rpm"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "exhaust_temp",
            "name": "exhaust_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "fw_temp",
            "name": "fw_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_temp_me",
            "name": "lo_temp_me"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_press_me",
            "name": "lo_press_me"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_press_gb",
            "name": "lo_press_gb"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_temp_gb",
            "name": "lo_temp_gb"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "temp_cover",
            "name": "temp_cover"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "interm_shaft_propeller",
            "name": "interm_shaft_propeller"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "shaft_propeller",
            "name": "shaft_propeller"
          }
        })], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v(" #2"), _c('br'), _c('a', {
          staticClass: "text-danger",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Remove")])]), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "load",
            "name": "load"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "time",
            "name": "time"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "speed",
            "name": "speed"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "engine_rpm",
            "name": "engine_rpm"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "exhaust_temp",
            "name": "exhaust_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "fw_temp",
            "name": "fw_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_temp_me",
            "name": "lo_temp_me"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_press_me",
            "name": "lo_press_me"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_press_gb",
            "name": "lo_press_gb"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_temp_gb",
            "name": "lo_temp_gb"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "temp_cover",
            "name": "temp_cover"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "interm_shaft_propeller",
            "name": "interm_shaft_propeller"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "shaft_propeller",
            "name": "shaft_propeller"
          }
        })], 1)])])])]), _c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM ")])], 1), _c('hr'), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary h6"
        }, [_vm._v("Main Engine - Port Side")]), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped table-bordered"
        }, [_c('thead', {
          staticClass: "table-success"
        }, [_c('tr', [_c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v(" NO ")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Load (Percent)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Time (Menit)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "colspan": "6"
          }
        }, [_vm._v("MAIN ENGINE")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("GEARBOX")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Temp. Cover Crankcase (°C)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Interm. Shaft Propeller (°C)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Shaft Propeller (°C)")])]), _c('tr', [_c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("Speed (Knots)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("Engine (RPM)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("Exhaust Temperatur (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("FW Temp (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Temp (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Press (kg/cm2)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Press (Psi)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Temp (°C)")])])]), _c('tbody', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v(" #1"), _c('br'), _c('a', {
          staticClass: "text-danger",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Remove")])]), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "load",
            "name": "load"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "time",
            "name": "time"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "speed",
            "name": "speed"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "engine_rpm",
            "name": "engine_rpm"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "exhaust_temp",
            "name": "exhaust_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "fw_temp",
            "name": "fw_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_temp_me",
            "name": "lo_temp_me"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_press_me",
            "name": "lo_press_me"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_press_gb",
            "name": "lo_press_gb"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_temp_gb",
            "name": "lo_temp_gb"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "temp_cover",
            "name": "temp_cover"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "interm_shaft_propeller",
            "name": "interm_shaft_propeller"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "shaft_propeller",
            "name": "shaft_propeller"
          }
        })], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v(" #2"), _c('br'), _c('a', {
          staticClass: "text-danger",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Remove")])]), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "load",
            "name": "load"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "time",
            "name": "time"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "speed",
            "name": "speed"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "engine_rpm",
            "name": "engine_rpm"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "exhaust_temp",
            "name": "exhaust_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "fw_temp",
            "name": "fw_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_temp_me",
            "name": "lo_temp_me"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_press_me",
            "name": "lo_press_me"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_press_gb",
            "name": "lo_press_gb"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_temp_gb",
            "name": "lo_temp_gb"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "temp_cover",
            "name": "temp_cover"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "interm_shaft_propeller",
            "name": "interm_shaft_propeller"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "shaft_propeller",
            "name": "shaft_propeller"
          }
        })], 1)])])])]), _c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM")])], 1), _c('b', [_vm._v("Notes : ")]), _vm._v("RPM bervariasi per kapal, bisa sampai 1600 RPM ")]), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary h5"
        }, [_vm._v("AUXILIARY ENGINE RECORD")]), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary h6"
        }, [_vm._v("Auxiliary Engine - Port Side")]), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped table-bordered"
        }, [_c('thead', {
          staticClass: "table-success"
        }, [_c('tr', [_c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v(" NO ")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Load (Percent)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Engine (RPM)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "colspan": "6"
          }
        }, [_vm._v("ENGINE RECORD")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Temp. Cover Crankcase (°C)")])]), _c('tr', [_c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Press (kg/cm2)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("SW Press (kg/cm2)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Press (kg/cm2)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Temp (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("FW Temp (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("Exh Temp (°C)")])])]), _c('tbody', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v(" #1"), _c('br'), _c('a', {
          staticClass: "text-danger",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Remove")])]), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "load",
            "name": "load"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "engine_rpm",
            "name": "engine_rpm"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_press_1",
            "name": "lo_press_1"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "sw_press",
            "name": "sw_press"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_press_2",
            "name": "lo_press_2"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_temp",
            "name": "lo_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "fw_temp",
            "name": "fw_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "exhaust_temp",
            "name": "exhaust_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "temp_cover",
            "name": "temp_cover"
          }
        })], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v(" #2"), _c('br'), _c('a', {
          staticClass: "text-danger",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Remove")])]), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "load",
            "name": "load"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "engine_rpm",
            "name": "engine_rpm"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_press_1",
            "name": "lo_press_1"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "sw_press",
            "name": "sw_press"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_press_2",
            "name": "lo_press_2"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_temp",
            "name": "lo_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "fw_temp",
            "name": "fw_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "exhaust_temp",
            "name": "exhaust_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "temp_cover",
            "name": "temp_cover"
          }
        })], 1)])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary h6"
        }, [_vm._v("Auxiliary Engine - Starboard Side")]), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped table-bordered"
        }, [_c('thead', {
          staticClass: "table-success"
        }, [_c('tr', [_c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v(" NO ")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Load (Percent)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Engine (RPM)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "colspan": "6"
          }
        }, [_vm._v("ENGINE RECORD")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Temp. Cover Crankcase (°C)")])]), _c('tr', [_c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Press (kg/cm2)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("SW Press (kg/cm2)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Press (kg/cm2)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Temp (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("FW Temp (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("Exh Temp (°C)")])])]), _c('tbody', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v(" #1"), _c('br'), _c('a', {
          staticClass: "text-danger",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Remove")])]), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "load",
            "name": "load"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "engine_rpm",
            "name": "engine_rpm"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_press_1",
            "name": "lo_press_1"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "sw_press",
            "name": "sw_press"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_press_2",
            "name": "lo_press_2"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_temp",
            "name": "lo_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "fw_temp",
            "name": "fw_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "exhaust_temp",
            "name": "exhaust_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "temp_cover",
            "name": "temp_cover"
          }
        })], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v(" #2"), _c('br'), _c('a', {
          staticClass: "text-danger",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Remove")])]), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "load",
            "name": "load"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "engine_rpm",
            "name": "engine_rpm"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_press_1",
            "name": "lo_press_1"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "sw_press",
            "name": "sw_press"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_press_2",
            "name": "lo_press_2"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "lo_temp",
            "name": "lo_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "fw_temp",
            "name": "fw_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "exhaust_temp",
            "name": "exhaust_temp"
          }
        })], 1), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "temp_cover",
            "name": "temp_cover"
          }
        })], 1)])])])])]), _c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM ")])], 1), _c('hr'), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "RECOMMENDATION",
            "label-for": "recomendation"
          }
        }, [_c('wysiwyg')], 1)], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Prepared By - OS",
            "label-for": "prepared_by"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Acknowledge By - INCO Mechanic",
            "label-for": "acknowledge_by"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Acknowledge By - SPT / Mechanic",
            "label-for": "acknowledge_by"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Acknowledge By - KKM TB. ETI 201",
            "label-for": "acknowledge_by"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Acknowledge By - Captain TB. ETI 201",
            "label-for": "acknowledge_by"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text"
          }
        })], 1)], 1), _c('b-button', {
          staticClass: "mt-3",
          attrs: {
            "block": "",
            "variant": "primary",
            "type": "submit"
          }
        }, [_vm._v("Submit Data")])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }