var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', {
    staticClass: "mb-0"
  }, [_vm._v("Sign Up")]), _c('p', [_vm._v("Enter your email address and password to access admin panel.")]), _c('sign-up1-form', {
    attrs: {
      "formType": "jwt"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }