var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("SERVICE & REPAIR DASHBOARD")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-filter",
            modifiers: {
              "collapse-filter": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "visible": "",
            "id": "collapse-filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet Category",
            "label-for": "fleet_category"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Fleet Category",
            "label": "text",
            "track-by": "value",
            "options": _vm.fleetCategory,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.fleetCategorySelect,
            callback: function callback($$v) {
              _vm.fleetCategorySelect = $$v;
            },
            expression: "fleetCategorySelect"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet *",
            "label-for": "Fleet"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.kapal,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Fleet")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedFleets,
            callback: function callback($$v) {
              _vm.selectedFleets = $$v;
            },
            expression: "selectedFleets"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Clasification *",
            "label-for": "clasification"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.clasification,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Clasification")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedClasification,
            callback: function callback($$v) {
              _vm.selectedClasification = $$v;
            },
            expression: "selectedClasification"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Period",
            "label-for": "Period"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": true,
            "href": "#request",
            "title": "REQUEST & PROGRESS"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": false,
            "href": "#finish",
            "title": "FINISH SERVICE & REPAIR"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "iq-edit-list-data"
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "request"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("REQUEST SERVICE & REPAIR")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.add-init",
            modifiers: {
              "add-init": true
            }
          }],
          attrs: {
            "block": "",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.showInitModal = !_vm.showInitModal;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v("   Add Data ")]), _c('b-modal', {
          attrs: {
            "id": "add-init",
            "size": "xl",
            "title": "Add Service & Repair",
            "header-bg-variant": "light",
            "header-text-variant": "dark",
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref) {
              var ok = _ref.ok,
                cancel = _ref.cancel,
                hide = _ref.hide;
              return [_c('b-row', [_c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "iq-bg-danger text-right",
                attrs: {
                  "variant": "none"
                },
                on: {
                  "click": function click($event) {
                    return cancel();
                  }
                }
              }, [_vm._v("Cancel")]), _c('b-button', {
                staticClass: "ml-2 text-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                },
                on: {
                  "click": function click($event) {
                    _vm.showInitModalNext = !_vm.showInitModalNext;
                    _vm.showInitModal = !_vm.showInitModal;
                  }
                }
              }, [_vm._v("Next "), _c('i', {
                staticClass: "fa fa-arrow-right"
              })])], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.showInitModal,
            callback: function callback($$v) {
              _vm.showInitModal = $$v;
            },
            expression: "showInitModal"
          }
        }, [_c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Search fleets..."
          },
          on: {
            "input": _vm.fetchFleets
          },
          model: {
            value: _vm.fleet_params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.fleet_params, "search", $$v);
            },
            expression: "fleet_params.search"
          }
        })], 1)], 1), _vm.loading_fleet ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.fleets.length > 0 ? _c('b-row', _vm._l(_vm.fleets, function (fleet, i) {
          return _c('b-col', {
            key: "fleet-".concat(i),
            staticClass: "text-center py-5 border-top",
            attrs: {
              "md": "3"
            }
          }, [_c('div', {
            staticClass: "custom-control custom-checkbox image-checkbox"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.checkedFleets,
              expression: "checkedFleets"
            }],
            staticClass: "custom-control-input",
            attrs: {
              "id": "ck-fl-".concat(i),
              "type": "checkbox"
            },
            domProps: {
              "value": fleet.id,
              "checked": Array.isArray(_vm.checkedFleets) ? _vm._i(_vm.checkedFleets, fleet.id) > -1 : _vm.checkedFleets
            },
            on: {
              "change": function change($event) {
                var $$a = _vm.checkedFleets,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = fleet.id,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && (_vm.checkedFleets = $$a.concat([$$v]));
                  } else {
                    $$i > -1 && (_vm.checkedFleets = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.checkedFleets = $$c;
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": "ck-fl-".concat(i)
            }
          }, [_c('img', {
            staticClass: "img-fluid",
            attrs: {
              "src": _vm._f("checkPhoto")(fleet.vehicleType.icon.url, require('@src/assets/images/fleet/PusherBarge.png')),
              "alt": "#"
            }
          })]), _c('small', [_c('strong', [_vm._v(_vm._s(fleet.name))])])])]);
        }), 1) : _c('div', {
          staticClass: "text-center my-5"
        }, [_c('p', {
          staticClass: "mb-0 text-muted"
        }, [_vm._v("No fleets data found.")])])]], 2)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET NAME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("LOCATION")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACCIDENT")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL REPAIR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("CLASSIFICATION")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("OFFICE/PIC")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TCP 201")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Lubuk Tutung")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12/06/2021")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("11 Repairs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("Minor : 2 Repair")]), _c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "primary d-block"
          }
        }, [_vm._v("Medium : 6 Repair")]), _c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Major : 3 Repair")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" Asam Asam Office "), _c('br'), _c('small', [_vm._v("PIC : "), _c('strong', [_vm._v("Darmawan")])])]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("Created")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.review_sr",
            modifiers: {
              "review_sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-eye mr-2"
        }), _vm._v("Review & Approve ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.reject-modal",
            modifiers: {
              "reject-modal": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-remove mr-2"
        }), _vm._v("Reject ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.update_sr",
            modifiers: {
              "update_sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square mr-2"
        }), _vm._v("Update Data ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.add-sr",
            modifiers: {
              "add-sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-2"
        }), _vm._v("Add Repair ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.detail_sr",
            modifiers: {
              "detail_sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-2"
        }), _vm._v("Detail ")])], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. TCP 207")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Celukan Bawang")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("21/10/2022")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12 Repairs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("Minor : 10 Repair")]), _c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "primary d-block"
          }
        }, [_vm._v("Medium : 2 Repair")]), _c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Major : 0 Repair")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" Sangatta Office "), _c('br'), _c('small', [_vm._v("PIC : "), _c('strong', [_vm._v("Hendra Ridwan")])])]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Reject")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-check-square mr-2"
        }), _vm._v("Activation ")])], 1)], 1)])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('b-sidebar', {
          attrs: {
            "id": "add-sr",
            "title": "ADD SERVICE REPAIR - TB. TCP 201",
            "width": "90%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hide = _ref2.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Engine Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', [_vm._v("Main Engine 1 - Yanmar 12LAK")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("2.800 RH")])])]), _c('tr', [_c('th', [_vm._v("Main Engine 1 - Yanmar 12LAK")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("2.800 RH")])])]), _c('tr', [_c('th', [_vm._v("Auxuauxiliary Engine 1 - Cummin KW32")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("1.800 RH")])])]), _c('tr', [_c('th', [_vm._v("Auxuauxiliary Engine 1 - Cummin KW32")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("1.800 RH")])])])])])])]), _c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Service & Repair Form")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "10"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "job_name",
                  "type": "text",
                  "placeholder": "Equipmenr Name"
                }
              })], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-button', {
                attrs: {
                  "variant": "success",
                  "block": ""
                }
              }, [_vm._v("SEARCH")])], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('v-jstree', {
                staticClass: "mt-3",
                attrs: {
                  "data": _vm.tree,
                  "show-checkbox": "",
                  "allow-batch": "",
                  "whole-row": "",
                  "item-events": _vm.itemEvents
                },
                on: {
                  "item-click": _vm.itemClick
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_) {
                    return [_c('div', {
                      staticStyle: {
                        "display": "inherit",
                        "width": "200px"
                      },
                      on: {
                        "click": function click($event) {
                          if (!$event.ctrlKey) return null;
                          return _vm.customItemClickWithCtrl.apply(null, arguments);
                        }
                      }
                    }, [!_.model.loading ? _c('i', {
                      class: _.vm.themeIconClasses,
                      attrs: {
                        "role": "presentation"
                      }
                    }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
                  }
                }], null, true)
              })], 1), _c('b-col', {
                attrs: {
                  "md": "8"
                }
              }, [_c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "primary"
                }
              }, [_c('div', {
                staticClass: "custom-control custom-control-inline"
              }, [_c('label', [_c('b', [_vm._v("SELECT JOB FROM :")])])]), _c('div', {
                staticClass: "custom-control custom-control-inline custom-radio"
              }, [_c('input', {
                staticClass: "custom-control-input",
                attrs: {
                  "type": "radio",
                  "name": "number",
                  "value": "one",
                  "id": "fleet_equipment"
                }
              }), _c('label', {
                staticClass: "custom-control-label",
                attrs: {
                  "for": "fleet_equipment"
                }
              }, [_vm._v("From Equipment")])]), _c('div', {
                staticClass: "custom-control custom-control-inline custom-radio"
              }, [_c('input', {
                staticClass: "custom-control-input",
                attrs: {
                  "type": "radio",
                  "name": "number",
                  "value": "two",
                  "id": "general_sr"
                }
              }), _c('label', {
                staticClass: "custom-control-label",
                attrs: {
                  "for": "general_sr"
                }
              }, [_vm._v("Others Service & Repair")])])])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "mt-3 mb-3",
                attrs: {
                  "block": "",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v(" ADD REPAIR JOB ")])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Location")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "30%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Example : Celukan Bawang"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Date Accident")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "30%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Office / Site")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "30%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Example : Asam-Asam"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("PIC Repair")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "30%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text"
                }
              })], 1)])])])]), _c('hr'), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Repair #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Equipment Name",
                  "label-for": "equipmentName"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Repair Title",
                  "label-for": "repairTitle"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Clasification *",
                  "label-for": "clasification"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.clasification,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Clasification")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedClasification,
                  callback: function callback($$v) {
                    _vm.selectedClasification = $$v;
                  },
                  expression: "selectedClasification"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Category *",
                  "label-for": "category"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.category,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Category")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedCategory,
                  callback: function callback($$v) {
                    _vm.selectedCategory = $$v;
                  },
                  expression: "selectedCategory"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Problem Case *",
                  "label-for": "problem_case"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.problemCase,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Problem Case")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedProblemCase,
                  callback: function callback($$v) {
                    _vm.selectedProblemCase = $$v;
                  },
                  expression: "selectedProblemCase"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Kind of Task *",
                  "label-for": "kind_of_task"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.kindTask,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Kind of Task")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedKindTask,
                  callback: function callback($$v) {
                    _vm.selectedKindTask = $$v;
                  },
                  expression: "selectedKindTask"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start Date *",
                  "label-for": "start_date"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start_date"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show ")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Estimation Finish *",
                  "label-for": "estFinish"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end_date"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show ")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Task File *",
                  "label-for": "attactment_file"
                }
              }, [_c('b-form-file', {
                attrs: {
                  "plain": "",
                  "id": "attactment_file"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Description",
                  "label-for": "description"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3"
                }
              })], 1)], 1)], 1), _c('b-row', {
                staticClass: "mt-2"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "pull-right",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash"
              }), _vm._v(" Delete Repair ")])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Repair #2")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Equipment Name",
                  "label-for": "equipmentName"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Repair Title",
                  "label-for": "repairTitle"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Clasification *",
                  "label-for": "clasification"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.clasification,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Clasification")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedClasification,
                  callback: function callback($$v) {
                    _vm.selectedClasification = $$v;
                  },
                  expression: "selectedClasification"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Category *",
                  "label-for": "category"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.category,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Category")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedCategory,
                  callback: function callback($$v) {
                    _vm.selectedCategory = $$v;
                  },
                  expression: "selectedCategory"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Problem Case *",
                  "label-for": "problem_case"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.problemCase,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Problem Case")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedProblemCase,
                  callback: function callback($$v) {
                    _vm.selectedProblemCase = $$v;
                  },
                  expression: "selectedProblemCase"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Kind of Task *",
                  "label-for": "kind_of_task"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.kindTask,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Kind of Task")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedKindTask,
                  callback: function callback($$v) {
                    _vm.selectedKindTask = $$v;
                  },
                  expression: "selectedKindTask"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start Date *",
                  "label-for": "start_date"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start_date"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show ")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Estimation Finish *",
                  "label-for": "estFinish"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end_date"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show ")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Task File *",
                  "label-for": "attactment_file"
                }
              }, [_c('b-form-file', {
                attrs: {
                  "plain": "",
                  "id": "attactment_file"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Description",
                  "label-for": "description"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3"
                }
              })], 1)], 1)], 1), _c('b-row', {
                staticClass: "mt-2"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "pull-right",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash"
              }), _vm._v(" Delete Repair ")])], 1)], 1)], 1), _c('b-button', {
                attrs: {
                  "variant": "success",
                  "block": ""
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v(" ADD REPAIR ")])], 1), _c('b-col', {
                staticClass: "text-right mt-2",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('b-button', {
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save ")]), _c('b-button', {
                staticClass: "iq-bg-danger ml-3",
                attrs: {
                  "type": "submit",
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" Reset Form ")])], 1)], 1)], 1)], 1)], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.showInitModalNext,
            callback: function callback($$v) {
              _vm.showInitModalNext = $$v;
            },
            expression: "showInitModalNext"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "review_sr",
            "title": "REVIEW SERVICE REPAIR - TB. ETI 201",
            "width": "50%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var hide = _ref3.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" FUEL INJECTOR - PEBAIKAN KEBOCORAN FUEL INJECTOR ")])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Kejadian")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Morosi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal Kejadian")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("12 Desember 2022")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Job Number")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("#1025")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Job Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Perbaikan Navigation Light")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Medium")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Welding")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Problem Case")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Material Fatique")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("General Service")])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Cost Estimasi")])]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_c('b', [_vm._v(":")])]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])]), _c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Cost Real")])]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_c('b', [_vm._v(":")])]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.300.000"
                }
              })])])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("PIC")])]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_c('b', [_vm._v(":")])]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Nurul Akbar")])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Attachment")])]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_c('b', [_vm._v(":")])]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('td', {
                staticClass: "align-top",
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Description")])]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "width": "5%"
                }
              }, [_c('b', [_vm._v(":")])]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsJabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsJabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER ")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR ")])], 1), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT ")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE ")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" SENSOR - KALIBRASI SESNOR SONAR ")])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Kejadian")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Morosi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal Kejadian")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("12 Desember 2022")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Job Number")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("#1025")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Job Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Perbaikan Navigation Light")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Medium")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Welding")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Problem Case")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Material Fatique")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("General Service")])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Cost Estimasi")])]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_c('b', [_vm._v(":")])]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])]), _c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Cost Real")])]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_c('b', [_vm._v(":")])]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.300.000"
                }
              })])])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("PIC")])]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_c('b', [_vm._v(":")])]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Nurul Akbar")])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Attachment")])]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_c('b', [_vm._v(":")])]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('td', {
                staticClass: "align-top",
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Description")])]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "width": "5%"
                }
              }, [_c('b', [_vm._v(":")])]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsJabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsJabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER ")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR ")])], 1), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT ")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE ")])], 1)], 1)], 1)]), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "mt-2 pull-right",
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("REVIEW & SUBMIT")])], 1)], 1)];
            }
          }])
        }), _c('b-sidebar', {
          attrs: {
            "id": "detail_sr",
            "title": "DETAIL SERVICE REPAIR - TB. ETI 201",
            "width": "60%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var hide = _ref4.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Engine Information")]), _c('table', {
                staticClass: "table mb-0 table-striped table-success"
              }, [_c('tbody', [_c('tr', [_c('th', [_vm._v("Main Engine 1 - Yanmar 12LAK")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("2.800 RH")])])]), _c('tr', [_c('th', [_vm._v("Main Engine 1 - Yanmar 12LAK")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("2.800 RH")])])]), _c('tr', [_c('th', [_vm._v("Auxuauxiliary Engine 1 - Cummin KW32")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("1.800 RH")])])]), _c('tr', [_c('th', [_vm._v("Auxuauxiliary Engine 1 - Cummin KW32")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("1.800 RH")])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Job Information")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Kejadian")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Morosi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal Kejadian")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("12 Desember")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Job Number")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("#1025")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Job Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Perbaikan Navigation Light")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Medium")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Welding")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Problem Case")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Material Fatique")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("General Service")])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Cost Estimasi")])]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_c('b', [_vm._v(":")])]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 1.500.000")]), _c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Cost Real")])]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_c('b', [_vm._v(":")])]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 1.300.000")])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("PIC")])]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_c('b', [_vm._v(":")])]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Nurul Akbar")])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Attachment")])]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_c('b', [_vm._v(":")])]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Description")])]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_c('b', [_vm._v(":")])]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ")])])])])])])], 1)];
            }
          }])
        }), _c('b-sidebar', {
          attrs: {
            "id": "update_sr",
            "title": "Update Service & Repair",
            "width": "60%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var hide = _ref5.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Engine Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', [_vm._v("Main Engine 1 - Yanmar 12LAK")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("2.800 RH")])])]), _c('tr', [_c('th', [_vm._v("Main Engine 1 - Yanmar 12LAK")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("2.800 RH")])])]), _c('tr', [_c('th', [_vm._v("Auxuauxiliary Engine 1 - Cummin KW32")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("1.800 RH")])])]), _c('tr', [_c('th', [_vm._v("Auxuauxiliary Engine 1 - Cummin KW32")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("1.800 RH")])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Location")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "30%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Example : Celukan Bawang"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Date Accident")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "30%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Office / Site")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "30%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Example : Asam-Asam"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("PIC Repair")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "30%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text"
                }
              })], 1)])])])]), _c('hr'), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Repair #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Equipment Name",
                  "label-for": "equipmentName"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Repair Title",
                  "label-for": "repairTitle"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Clasification *",
                  "label-for": "clasification"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.clasification,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Clasification")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedClasification,
                  callback: function callback($$v) {
                    _vm.selectedClasification = $$v;
                  },
                  expression: "selectedClasification"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Category *",
                  "label-for": "category"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.category,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Category")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedCategory,
                  callback: function callback($$v) {
                    _vm.selectedCategory = $$v;
                  },
                  expression: "selectedCategory"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Problem Case *",
                  "label-for": "problem_case"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.problemCase,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Problem Case")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedProblemCase,
                  callback: function callback($$v) {
                    _vm.selectedProblemCase = $$v;
                  },
                  expression: "selectedProblemCase"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Kind of Task *",
                  "label-for": "kind_of_task"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.kindTask,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Kind of Task")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedKindTask,
                  callback: function callback($$v) {
                    _vm.selectedKindTask = $$v;
                  },
                  expression: "selectedKindTask"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start Date *",
                  "label-for": "start_date"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start_date"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show ")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Estimation Finish *",
                  "label-for": "estFinish"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end_date"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show ")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Task File *",
                  "label-for": "attactment_file"
                }
              }, [_c('b-form-file', {
                attrs: {
                  "plain": "",
                  "id": "attactment_file"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Description",
                  "label-for": "description"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3"
                }
              })], 1)], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Repair #2")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Equipment Name",
                  "label-for": "equipmentName"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Repair Title",
                  "label-for": "repairTitle"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Clasification *",
                  "label-for": "clasification"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.clasification,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Clasification")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedClasification,
                  callback: function callback($$v) {
                    _vm.selectedClasification = $$v;
                  },
                  expression: "selectedClasification"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Category *",
                  "label-for": "category"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.category,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Category")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedCategory,
                  callback: function callback($$v) {
                    _vm.selectedCategory = $$v;
                  },
                  expression: "selectedCategory"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Problem Case *",
                  "label-for": "problem_case"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.problemCase,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Problem Case")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedProblemCase,
                  callback: function callback($$v) {
                    _vm.selectedProblemCase = $$v;
                  },
                  expression: "selectedProblemCase"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Kind of Task *",
                  "label-for": "kind_of_task"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.kindTask,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Kind of Task")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedKindTask,
                  callback: function callback($$v) {
                    _vm.selectedKindTask = $$v;
                  },
                  expression: "selectedKindTask"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start Date *",
                  "label-for": "start_date"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start_date"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show ")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Estimation Finish *",
                  "label-for": "estFinish"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end_date"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show ")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Task File *",
                  "label-for": "attactment_file"
                }
              }, [_c('b-form-file', {
                attrs: {
                  "plain": "",
                  "id": "attactment_file"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Description",
                  "label-for": "description"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3"
                }
              })], 1)], 1)], 1)], 1)]), _c('b-col', {
                staticClass: "text-right",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('b-button', {
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save ")]), _c('b-button', {
                staticClass: "iq-bg-danger ml-3",
                attrs: {
                  "type": "submit",
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" Reset Form ")])], 1)], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("PROGRESS SERVICE & REPAIR")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET NAME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("LOCATION")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACCIDENT")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL REPAIR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("CLASSIFICATION")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("OFFICE/PIC")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TCP 201")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Tanjung Bara")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12/06/2021")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("5 Repairs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("Minor : 2 Repair")]), _c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "primary d-block"
          }
        }, [_vm._v("Medium : 3 Repair")]), _c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Major : 0 Repair")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" Asam Asam Office "), _c('br'), _c('small', [_vm._v("PIC : "), _c('strong', [_vm._v("Darmawan")])])]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.add-activity",
            modifiers: {
              "add-activity": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-comments mr-2"
        }), _vm._v("Update Activity ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.add-sr",
            modifiers: {
              "add-sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-2"
        }), _vm._v("Add Repair ")])], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TCP 201")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Tanjung Bara")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12/06/2021")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("5 Repairs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("Minor : 2 Repair")]), _c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "primary d-block"
          }
        }, [_vm._v("Medium : 3 Repair")]), _c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Major : 0 Repair")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" Asam Asam Office "), _c('br'), _c('small', [_vm._v("PIC : "), _c('strong', [_vm._v("Darmawan")])])]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.add-activity",
            modifiers: {
              "add-activity": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-comments mr-2"
        }), _vm._v("Update Activity ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.add-sr",
            modifiers: {
              "add-sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-2"
        }), _vm._v("Add Repair ")])], 1)], 1)])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('b-sidebar', {
          attrs: {
            "id": "add-activity",
            "title": "UPDATE ACTIVITY",
            "width": "50%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var hide = _ref6.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "power"
                }
              }, [_c('tab-nav', {
                attrs: {
                  "tabs": true
                }
              }, [_c('tab-nav-items', {
                attrs: {
                  "active": true,
                  "href": "#activity",
                  "title": "Activity"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "href": "#logs",
                  "title": "Logs"
                }
              })], 1), _c('div', {
                staticClass: "tab-content",
                attrs: {
                  "id": "myTabContent"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "activity"
                }
              }, [_c('b-row', [_c('b-col', {
                staticClass: "mb-5",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Select Repair List",
                  "label-for": "repair_list"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsDockingJob
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Repair")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedDockingJob,
                  callback: function callback($$v) {
                    _vm.selectedDockingJob = $$v;
                  },
                  expression: "selectedDockingJob"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label-for": "progress_bar"
                }
              }, [_c('h4', {
                staticClass: "mb-2"
              }, [_vm._v("Progrees : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("80%")])], 1), _c('b-progress', {
                attrs: {
                  "value": 80,
                  "variant": "success",
                  "max": "100"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Progress Pekerjaan *",
                  "label-for": "progress_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "progress_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "progress_pekerjaan",
                  "placeholder": "80"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Report Progress",
                  "label-for": "report_progress"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('div', {
                staticClass: "form-group row align-items-center mt-3 m-0"
              }, [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "send-panel float-right"
              }, [_c('div', {
                staticClass: "send-btn"
              }, [_c('b-button', {
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-check"
              }), _vm._v(" Finish ")]), _c('b-button', {
                staticClass: "ml-2",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-comment"
              }), _vm._v(" Update Activity ")])], 1)])])])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "100%"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/6.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/1.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])], 1)], 1), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "logs"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "100%"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("19 Oktober 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Service Repair Finish")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("18 Oktober 2019 15:41")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Update Stage")]), _c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("REVIEW & APPROVE")]), _c('i', {
                staticClass: "fa fa-arrow-circle-right",
                attrs: {
                  "aria-hidden": "true"
                }
              }), _c('b-button', {
                staticClass: "ml-1",
                attrs: {
                  "variant": "danger"
                }
              }, [_vm._v("REJECT")])], 1)]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("System")]), _c('br'), _c('small', [_vm._v("17 Oktober 2019 18:30")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("System mengirim email ke : "), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("aguuungtriilaksono@gmail.com")]), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("nurulakbar93@gmail.com")])], 1)])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("17 Oktober 2019 18:30")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Update Stage")]), _c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("REQUEST")]), _c('i', {
                staticClass: "fa fa-arrow-circle-right",
                attrs: {
                  "aria-hidden": "true"
                }
              }), _c('b-button', {
                staticClass: "ml-1",
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("REVIEW & APPROVE")])], 1)]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("System")]), _c('br'), _c('small', [_vm._v("15 Oktober 2019 16:00")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("System mengirim email ke : "), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("aguuungtriilaksono@gmail.com")]), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("nurulakbar93@gmail.com")])], 1)])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("15 Oktober 2019 20:24")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Mengupdate data service repair")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("15 Oktober 2019 16:00")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Membuat data service repair")])])])])])], 1)], 1)], 1)], 1)], 1)], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "finish"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-info-box d-flex align-items-center p-3"
        }, [_c('div', {
          staticClass: "info-image mr-3"
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/page-img/tb.png"),
            "alt": "image-box"
          }
        })]), _c('div', {
          staticClass: "info-text"
        }, [_c('h3', [_vm._v("34 Fleet")]), _c('span', [_vm._v("Total Repair Fleet")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-info-box d-flex align-items-center p-3"
        }, [_c('div', {
          staticClass: "info-image mr-3"
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/page-img/30.png"),
            "alt": "image-box"
          }
        })]), _c('div', {
          staticClass: "info-text"
        }, [_c('h5', [_vm._v("152 TASK")]), _c('b-badge', {
          staticClass: "mr-2",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("125 Repair On Time")]), _c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("27 Repair Delay")])], 1)])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-info-box d-flex align-items-center p-3"
        }, [_c('div', {
          staticClass: "info-image mr-3"
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/page-img/31.png"),
            "alt": "image-box"
          }
        })]), _c('div', {
          staticClass: "info-text"
        }, [_c('h3', [_vm._v("Rp. 275.011.341")]), _c('span', [_vm._v("Total Cost")])])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("HISTORY SERVICE REPAIR")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET NAME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("CURRENT POSITION")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("RUNNING HOURS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("PIC")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL REPAIR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("MINOR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("MEDIUM")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("MAJOR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("1")]), _c('td', [_vm._v("TB. TCP 201")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Asam-Asam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2.500 H")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Agung Tri Laksono")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("53")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("31")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("22")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-3",
            modifiers: {
              "modal-3": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-list-ol mr-2"
        }), _vm._v("Task List ")])], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("2")]), _c('td', [_vm._v("TB. ETI 203")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Sangatta")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("3.130 H")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Agung Tri Laksono")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("36")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("16")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.modal-3",
            modifiers: {
              "modal-3": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-list-ol mr-2"
        }), _vm._v("Task List ")])], 1)], 1)])])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)])])], 1), _c('b-modal', {
    attrs: {
      "id": "reject-modal",
      "size": "l",
      "title": "Reject (Navigation Equipment - Navigation Light)",
      "ok-title": "Save Changes",
      "cancel-title": "Close"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Reason *",
      "label-for": "reason"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "reason",
      "rows": "2"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }