var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', _vm._l(_vm.charts, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('MorrisChart', {
            attrs: {
              "element": item.type + index,
              "type": item.type,
              "xKeys": item.xKeys,
              "data": item.bodyData,
              "colors": item.colors,
              "yKeys": item.yKeys,
              "labels": item.labels
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }