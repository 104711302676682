var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-self-center",
    on: {
      "click": function click($event) {
        return _vm.openDetail(_vm.items);
      }
    }
  }, [_c('div', {
    staticClass: "iq-email-sender-info"
  }, [_c('div', {
    staticClass: "iq-checkbox-mail"
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    staticClass: "custom-control-input",
    attrs: {
      "type": "checkbox",
      "id": 'index-' + _vm.items.title
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": 'index-' + _vm.items.title
    }
  })])]), _c('span', {
    staticClass: "ri-star-line iq-star-toggle",
    class: _vm.items.favorite ? 'text-warning' : ''
  }), _c('a', {
    staticClass: "iq-email-title",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_vm._v(_vm._s(_vm.items.title))])]), _c('div', {
    staticClass: "iq-email-content"
  }, [_c('a', {
    staticClass: "iq-email-subject",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_vm._v(_vm._s(_vm.items.subject) + "  –  "), _c('span', [_vm._v("@Mackenzieniko - Very cool :) Nicklas, You have a new direct message.")])]), _c('div', {
    staticClass: "iq-email-date"
  }, [_vm._v(_vm._s(_vm.items.emailTime))])]), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "iq-social-media"
  }, [_c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-delete-bin-2-line"
  })])]), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-mail-line"
  })])]), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-file-list-2-line"
  })])]), _c('li', [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "ri-time-line"
  })])])]);

}]

export { render, staticRenderFns }