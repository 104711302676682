<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-6 p-0" :active="true" href="#kanban" title="Kanban"/>
                <tab-nav-items class="col-md-6 p-0" :active="false" href="#dragdrop" title="Drag & Drop"/>
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <!-- Kanban -->
      <tab-content-item :active="true" id="kanban">
        <!-- Top Section !-->
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:body>
                <b-row>
                  <b-col md="12">
                    <kanban-board :stages="stages" :blocks="blocks"></kanban-board>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
      <!-- Drag & Drop -->
      <tab-content-item :active="false" id="dragdrop">
        <!-- Top Section !-->
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:body>
                <div class="row">
                  <div class="col mx-2 px-2 py-3 bg-light border rounded">
                    <h6>Idea</h6>
                    <draggable class="draggable-list" :list="tasks.ideas" group="tasks">
                      <div v-for="(idea, i) in tasks.ideas" :key="i">
                        <div class="bg-white mt-3 p-2 shadow border rounded">
                          <p>{{ idea }}</p>
                        </div>
                      </div>
                    </draggable>
                  </div>
                  <div class="col mx-2 px-2 py-3 bg-light border rounded">
                    <h6>Todo</h6>
                    <draggable class="draggable-list" :list="tasks.todos" group="tasks">
                      <div v-for="(todo, i) in tasks.todos" :key="i">
                        <div class="bg-white mt-3 p-2 shadow border rounded">
                          <p>{{ todo }}</p>
                        </div>
                      </div>
                    </draggable>
                  </div>
                  <div class="col mx-2 px-2 py-3 bg-light border rounded">
                    <h6>In Progress</h6>
                    <draggable
                      class="draggable-list"
                      :list="tasks.inProgress"
                      group="tasks"
                    >
                      <div v-for="(task, i) in tasks.inProgress" :key="i">
                        <div class="bg-white mt-3 p-2 shadow border rounded">
                          <p>{{ task }}</p>
                        </div>
                      </div>
                    </draggable>
                  </div>
                  <div class="col mx-2 px-2 py-3 bg-light border rounded">
                    <h6>Ready to go</h6>
                    <draggable class="draggable-list" :list="tasks.completed" group="tasks">
                      <div v-for="(task, i) in tasks.completed" :key="i">
                        <div class="bg-white mt-3 p-2 shadow border rounded">
                          <p>{{ task }}</p>
                        </div>
                      </div>
                    </draggable>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
    </div>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'

export default {
  name: 'KanbanDragDrop',
  mounted () {
    xray.index()
  },
  data () {
    return {
      stages: ['on-hold', 'in-progress', 'needs-review', 'approved'],
      blocks: [
        {
          id: 1,
          status: 'on-hold',
          title: 'Test'
        }
      ],
      tasks: {
        ideas: ['Migrate codebase to TypeScript'],
        todos: ['Dockerize App', 'Add vue.draggable to project'],
        inProgress: ['Implement Web3 Features', 'Bump to vite.js'],
        completed: []
      }
    }
  }
}
</script>

<style scoped>
.draggable-list {
  min-height: 10vh;
}
.draggable-list > div {
  cursor: pointer;
}
.list-item {
  margin: 10px;
  padding: 40px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
  background: #f44336;
  display: inline-block;
}
.col {
  height: 90vh;
}
</style>
