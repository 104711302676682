var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-progress-bar progress-bar-vertical iq-bg-primary"
        }, [_c('span', {
          staticClass: "bg-primary",
          attrs: {
            "data-percent": "70"
          }
        })]), _c('span', {
          staticClass: "line-height-4"
        }, [_vm._v("10 feb, 2020")]), _c('h4', {
          staticClass: "mb-2 mt-2"
        }, [_vm._v("Hypertensive Crisis")]), _c('p', {
          staticClass: "mb-0 text-secondary line-height"
        }, [_vm._v("Ongoing treatment")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-progress-bar progress-bar-vertical iq-bg-danger"
        }, [_c('span', {
          staticClass: "bg-danger",
          attrs: {
            "data-percent": "50"
          }
        })]), _c('span', {
          staticClass: "line-height-4"
        }, [_vm._v("12 Jan, 2020")]), _c('h4', {
          staticClass: "mb-2 mt-2"
        }, [_vm._v("Osteoporosis")]), _c('p', {
          staticClass: "mb-0 text-secondary line-height"
        }, [_vm._v("Incurable")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-progress-bar progress-bar-vertical iq-bg-warning"
        }, [_c('span', {
          staticClass: "bg-warning",
          attrs: {
            "data-percent": "80"
          }
        })]), _c('span', {
          staticClass: "line-height-4"
        }, [_vm._v("15 feb, 2020")]), _c('h4', {
          staticClass: "mb-2 mt-2"
        }, [_vm._v("Hypertensive Crisis")]), _c('p', {
          staticClass: "mb-0 text-secondary line-height"
        }, [_vm._v("Examination")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "nobody": ""
    }
  }, [_c('div', {
    staticClass: "iq-card-body p-0 rounded",
    style: "background: url(".concat(require('../../assets/images/page-img/38.jpg'), ") no-repeat scroll center center; background-size: contain;  min-height: 146px;")
  })])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height iq-user-profile-block"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "user-details-block"
        }, [_c('div', {
          staticClass: "user-profile text-center"
        }, [_c('img', {
          staticClass: "avatar-130 img-fluid",
          attrs: {
            "src": require("../../assets/images/user/11.png"),
            "alt": "profile-img"
          }
        })]), _c('div', {
          staticClass: "text-center mt-3"
        }, [_c('h4', [_c('b', [_vm._v("Bini Jets")])]), _c('p', [_vm._v("Doctor")]), _c('p', [_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit. In in arcu turpis. Nunc")]), _c('a', {
          staticClass: "btn btn-primary",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Assign")])]), _c('hr'), _c('ul', {
          staticClass: "doctoe-sedual d-flex align-items-center justify-content-between p-0"
        }, [_c('li', {
          staticClass: "text-center"
        }, [_c('h3', {
          staticClass: "counter"
        }, [_vm._v("4500")]), _c('span', [_vm._v("Operations")])]), _c('li', {
          staticClass: "text-center"
        }, [_c('h3', {
          staticClass: "counter"
        }, [_vm._v("3.9")]), _c('span', [_vm._v("Medical Rating")])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block",
      "iq-card-stretch": "",
      "iq-card-height": ""
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Health Curve")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.$route.meta.dark ? _c('ApexChart', {
          attrs: {
            "element": "home-chart-06",
            "chartOption": _vm.darkChart1
          }
        }) : _c('ApexChart', {
          attrs: {
            "element": "home-chart-06",
            "chartOption": _vm.chart1
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "smaill-calender-home"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Nearest Treatment")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('flat-pickr', {
          staticClass: "d-none",
          attrs: {
            "config": _vm.config,
            "value": ""
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height-half",
      "nobody": ""
    }
  }, [_c('div', {
    staticClass: "iq-card-body"
  }, [_c('h6', [_vm._v("APPOINTMENTS")]), _c('h3', [_c('b', [_vm._v("5075")])])]), _c('ApexChart', {
    attrs: {
      "element": "wave-chart-7",
      "chartOption": _vm.chart7,
      "isLive": true
    }
  })], 1), _c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height-half",
      "nobody": ""
    }
  }, [_c('div', {
    staticClass: "iq-card-body"
  }, [_c('h6', [_vm._v("NEW PATIENTS")]), _c('h3', [_c('b', [_vm._v("1200")])])]), _c('ApexChart', {
    attrs: {
      "element": "wave-chart-8",
      "chartOption": _vm.chart8,
      "isLive": true
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "hospital-mgt"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Hospital Management")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "progress mb-3",
          staticStyle: {
            "height": "30px"
          }
        }, [_c('div', {
          staticClass: "progress-bar bg-primary",
          staticStyle: {
            "width": "20%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "15",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }, [_vm._v("OPD")]), _c('div', {
          staticClass: "progress-bar bg-warning",
          staticStyle: {
            "width": "80%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "30",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }, [_vm._v("80%")])]), _c('div', {
          staticClass: "progress mb-3",
          staticStyle: {
            "height": "30px"
          }
        }, [_c('div', {
          staticClass: "progress-bar bg-primary",
          staticStyle: {
            "width": "30%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "15",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }, [_vm._v("Treatment")]), _c('div', {
          staticClass: "progress-bar bg-warning",
          staticStyle: {
            "width": "70%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "30",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }, [_vm._v("70%")])]), _c('div', {
          staticClass: "progress mb-3",
          staticStyle: {
            "height": "30px"
          }
        }, [_c('div', {
          staticClass: "progress-bar bg-primary",
          staticStyle: {
            "width": "60%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "15",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }, [_vm._v("Laboratory Test")]), _c('div', {
          staticClass: "progress-bar bg-warning",
          staticStyle: {
            "width": "40%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "30",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }, [_vm._v("85%")])]), _c('div', {
          staticClass: "progress mb-3",
          staticStyle: {
            "height": "30px"
          }
        }, [_c('div', {
          staticClass: "progress-bar bg-primary",
          staticStyle: {
            "width": "40%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "15",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }, [_vm._v("New Patient")]), _c('div', {
          staticClass: "progress-bar bg-warning",
          staticStyle: {
            "width": "60%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "30",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }, [_vm._v("70%")])]), _c('div', {
          staticClass: "progress mb-3",
          staticStyle: {
            "height": "30px"
          }
        }, [_c('div', {
          staticClass: "progress-bar bg-primary",
          staticStyle: {
            "width": "35%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "15",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }, [_vm._v("Doctors")]), _c('div', {
          staticClass: "progress-bar bg-warning",
          staticStyle: {
            "width": "65%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "30",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }, [_vm._v("95%")])]), _c('div', {
          staticClass: "progress",
          staticStyle: {
            "height": "30px"
          }
        }, [_c('div', {
          staticClass: "progress-bar bg-primary",
          staticStyle: {
            "width": "28%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "15",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }, [_vm._v("Discharge")]), _c('div', {
          staticClass: "progress-bar bg-warning",
          staticStyle: {
            "width": "75%"
          },
          attrs: {
            "role": "progressbar",
            "aria-valuenow": "30",
            "aria-valuemin": "0",
            "aria-valuemax": "100"
          }
        }, [_vm._v("35%")])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Patient Progress")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('ul', {
          staticClass: "patient-progress m-0 p-0"
        }, [_c('li', {
          staticClass: "d-flex mb-3 align-items-center"
        }, [_c('div', {
          staticClass: "media-support-info"
        }, [_c('h6', [_vm._v("Bud Jet")])]), _c('span', {
          staticClass: "badge badge-primary"
        }, [_vm._v("30%")])]), _c('li', {
          staticClass: "d-flex mb-3 align-items-center"
        }, [_c('div', {
          staticClass: "media-support-info"
        }, [_c('h6', [_vm._v("Barney Cull")])]), _c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v("70%")])]), _c('li', {
          staticClass: "d-flex mb-3 align-items-center"
        }, [_c('div', {
          staticClass: "media-support-info"
        }, [_c('h6', [_vm._v("Eric Shun")])]), _c('span', {
          staticClass: "badge badge-danger"
        }, [_vm._v("15%")])]), _c('li', {
          staticClass: "d-flex mb-3 align-items-center"
        }, [_c('div', {
          staticClass: "media-support-info"
        }, [_c('h6', [_vm._v("Rick Shaw")])]), _c('span', {
          staticClass: "badge badge-warning"
        }, [_vm._v("55%")])]), _c('li', {
          staticClass: "d-flex mb-3 align-items-center"
        }, [_c('div', {
          staticClass: "media-support-info"
        }, [_c('h6', [_vm._v("Ben Effit")])]), _c('span', {
          staticClass: "badge badge-info"
        }, [_vm._v("45%")])]), _c('li', {
          staticClass: "d-flex mb-3 align-items-center"
        }, [_c('div', {
          staticClass: "media-support-info"
        }, [_c('h6', [_vm._v("Rick Shaw")])]), _c('span', {
          staticClass: "badge badge-warning"
        }, [_vm._v("55%")])]), _c('li', {
          staticClass: "d-flex mb-3 align-items-center"
        }, [_c('div', {
          staticClass: "media-support-info"
        }, [_c('h6', [_vm._v("Marge Arita")])]), _c('span', {
          staticClass: "badge badge-primary"
        }, [_vm._v("65%")])]), _c('li', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "media-support-info"
        }, [_c('h6', [_vm._v("Barry Cudat")])]), _c('span', {
          staticClass: "badge badge-danger"
        }, [_vm._v("15%")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "pl-0 pr-0"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Patient overview")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('AmChart', {
          attrs: {
            "element": "home-chart-03",
            "type": _vm.radialChat.type,
            "option": _vm.radialChat.data,
            "height": _vm.radialChat.height
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Visits From Countries ")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-details"
        }, [_c('span', {
          staticClass: "title text-dark"
        }, [_vm._v("United States")]), _c('div', {
          staticClass: "percentage float-right text-primary"
        }, [_vm._v("95 "), _c('span', [_vm._v("%")])]), _c('div', {
          staticClass: "iq-progress-bar-linear d-inline-block w-100"
        }, [_c('div', {
          staticClass: "iq-progress-bar"
        }, [_c('span', {
          staticClass: "bg-primary",
          attrs: {
            "data-percent": "95"
          }
        })])])]), _c('div', {
          staticClass: "iq-details mt-4"
        }, [_c('span', {
          staticClass: "title text-dark"
        }, [_vm._v("India")]), _c('div', {
          staticClass: "percentage float-right text-warning"
        }, [_vm._v("75 "), _c('span', [_vm._v("%")])]), _c('div', {
          staticClass: "iq-progress-bar-linear d-inline-block w-100"
        }, [_c('div', {
          staticClass: "iq-progress-bar"
        }, [_c('span', {
          staticClass: "bg-warning",
          attrs: {
            "data-percent": "75"
          }
        })])])]), _c('div', {
          staticClass: "iq-details mt-4"
        }, [_c('span', {
          staticClass: "title text-dark"
        }, [_vm._v("Australia")]), _c('div', {
          staticClass: "percentage float-right text-success"
        }, [_vm._v("55 "), _c('span', [_vm._v("%")])]), _c('div', {
          staticClass: "iq-progress-bar-linear d-inline-block w-100"
        }, [_c('div', {
          staticClass: "iq-progress-bar"
        }, [_c('span', {
          staticClass: "bg-success",
          attrs: {
            "data-percent": "55"
          }
        })])])]), _c('div', {
          staticClass: "iq-details mt-4"
        }, [_c('span', {
          staticClass: "title text-dark"
        }, [_vm._v("Brazil")]), _c('div', {
          staticClass: "percentage float-right text-danger"
        }, [_vm._v("25 "), _c('span', [_vm._v("%")])]), _c('div', {
          staticClass: "iq-progress-bar-linear d-inline-block w-100"
        }, [_c('div', {
          staticClass: "iq-progress-bar"
        }, [_c('span', {
          staticClass: "bg-danger",
          attrs: {
            "data-percent": "25"
          }
        })])])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("New Appointments ")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-dropdown', {
          attrs: {
            "size": "lg p-0",
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "ri-more-fill m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-eye-fill mr-2"
        }), _vm._v("View")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-delete-bin-6-fill mr-2"
        }), _vm._v("Delete")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-pencil-fill mr-2"
        }), _vm._v("Edit")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-printer-fill mr-2"
        }), _vm._v("Print")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "ri-file-download-fill mr-2"
        }), _vm._v("Download")])], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-borderless"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Patient")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Doctor")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Date")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Timing")]), _c('th', {
          attrs: {
            "scope": "col"
          }
        }, [_vm._v("Contact")])])]), _vm._l(_vm.appointments, function (appointment) {
          return _c('tbody', {
            key: appointment.id
          }, [_c('tr', [_c('td', [_vm._v(_vm._s(appointment.patient))]), _c('td', [_vm._v(_vm._s(appointment.doctor))]), _c('td', [_vm._v(_vm._s(appointment.date))]), _c('td', [_vm._v(_vm._s(appointment.timing))]), _c('td', [_vm._v(_vm._s(appointment.contact))])])]);
        })], 2)])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Doctors Lists")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('ul', {
          staticClass: "doctors-lists m-0 p-0"
        }, _vm._l(_vm.doctors, function (doctor) {
          return _c('li', {
            key: doctor.name,
            staticClass: "d-flex mb-4 align-items-center"
          }, [_c('div', {
            staticClass: "user-img img-fluid"
          }, [_c('img', {
            staticClass: "rounded-circle avatar-40",
            attrs: {
              "src": doctor.img,
              "alt": "story-img"
            }
          })]), _c('div', {
            staticClass: "media-support-info ml-3"
          }, [_c('h6', [_vm._v(_vm._s(doctor.name))]), _c('p', {
            staticClass: "mb-0 font-size-12"
          }, [_vm._v(_vm._s(doctor.degree))])]), _c('div', {
            staticClass: "iq-card-header-toolbar d-flex align-items-center"
          }, [_c('b-dropdown', {
            attrs: {
              "size": "lg",
              "variant": "link",
              "toggle-class": "p-0 text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton6",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "ri-more-2-line"
                })])];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            attrs: {
              "href": "#"
            }
          }, [_c('i', {
            staticClass: "ri-eye-line mr-2"
          }), _vm._v("View")]), _c('b-dropdown-item', {
            attrs: {
              "href": "#"
            }
          }, [_c('i', {
            staticClass: "ri-bookmark-line mr-2"
          }), _vm._v("Appointment")])], 1)], 1)]);
        }), 0), _c('a', {
          staticClass: "btn btn-primary d-block mt-3",
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('i', {
          staticClass: "ri-add-line"
        }), _vm._v(" View All Doctors ")])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }