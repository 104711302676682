<template>
  <b-container fluid>
    <b-row>
      <!-- Category -->
      <b-col md="6">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Master Goods Category</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="dark" class="mt-1" v-b-toggle.add-master-goods><i class="fa fa-plus"></i>&nbsp;Add Data</b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="mb-3 mt-4">
                <b-collapse id="add-master-goods">
                  <b-row>
                    <b-col md="3">
                      <b-form-group label="Goods Code">
                        <b-form-input id="fleet_name" class="w-100" v-model="form.code" type="text" placeholder="Goods Code..."></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Category" label-for="serial">
                        <v-select label="text" v-model="form.category" :options="category" :reduce="(type) => type.value" placeholder="select category"></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Status" label-for="serial">
                        <b-form-checkbox name="check-button" v-model="form.status" switch inline>
                          <template v-if="form.status === true || form.status === 'true'">Yes</template>
                          <template v-else>No</template>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-button type="submit" variant="primary" class="mt-2">Add</b-button>
                    </b-col>
                  </b-row>
                </b-collapse>
              </b-col>
              <b-col md="12">
                <hr/>
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th>No</th>
                    <th>Code</th>
                    <th>Category</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                </table>
              </b-col>
              <b-col md="12" class="mt-3">
                <b-pagination v-model="currentPage" :total-rows="rows" align="center"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <!-- End Category -->
      <!-- Units -->
      <b-col md="6">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Master Goods Units</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="dark" class="mt-1" v-b-toggle.add-master-units><i class="fa fa-plus"></i>&nbsp;Add Data</b-button>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" class="mb-3 mt-4">
                <b-collapse id="add-master-units">
                  <b-row>
                    <b-col md="5">
                      <b-form-group label="Unit Name">
                        <b-form-input id="fleet_name" class="w-100" v-model="form.code" type="text" placeholder="Unit Name..."></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Status" label-for="serial">
                        <b-form-checkbox name="check-button" v-model="form.status" switch inline>
                          <template v-if="form.status === true || form.status === 'true'">Yes</template>
                          <template v-else>No</template>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-button type="submit" variant="primary" class="mt-2">Add</b-button>
                    </b-col>
                  </b-row>
                </b-collapse>
              </b-col>
              <b-col md="12">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th>No</th>
                    <th>Units</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                </table>
              </b-col>
              <b-col md="12" class="mt-3">
                <b-pagination v-model="currentPage" :total-rows="rows" align="center"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
      <!-- End Units -->
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'

export default {
  name: 'ComponentCategoryUnits',
  mounted () {
    xray.index()
  },
  data () {
    return {
      rows: 100,
      currentPage: 3,
      fleet_types: [],
      category: [
        { value: '-', text: 'Oil Machine' }
      ],
      form: {
        category: '',
        code: '',
        status: true,
        assignFleet: true,
        vehicleType: []
      }
    }
  }
}
</script>
