<template>
    <b-container fluid>
      <b-row>
        <b-col lg="12">
            <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>REPORT - GENERAL LEDGER</strong></h5>
                </template>
                <template v-slot:headerAction>
                  <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                    <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                        <i class="fa fa-save m-0"></i>
                        </span>
                    </template>
                    <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
                    <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
                  </b-dropdown>
                </template>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12" class="text-center">
                      <h4>PT. CAHAYA MARITIM</h4>
                      <h3>All Journal</h3>
                      <p>Report : 01/01/2021 - 31/12/2021</p>
                      <hr>
                    </b-col>
                    <b-col md="12">
                      <table class="table table-borderless table-sm">
                        <tbody>
                          <tr>
                            <th width="15%">Date</th>
                            <th width="5%">:</th>
                            <th width="80%">22 November 2021</th>
                          </tr>
                          <tr>
                            <th width="15%">Transaction Type</th>
                            <th width="5%">:</th>
                            <th width="80%">Sales Invoice</th>
                          </tr>
                          <tr>
                            <th width="15%">Transaction No</th>
                            <th width="5%">:</th>
                            <th width="80%">9786</th>
                          </tr>
                          <tr>
                            <th width="15%">Description</th>
                            <th width="5%">:</th>
                            <th width="80%">Saldo Awal pelanggan PT. Cahaya Maritim</th>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <b-col md="1">&nbsp;</b-col>
                    <b-col md="11">
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th width="25%" class="text-center">Account No</th>
                            <th width="25%" class="text-center">Account Name</th>
                            <th width="25%" class="text-right">Debit</th>
                            <th width="25%" class="text-right">Credit</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">110301</td>
                            <td class="text-center">Piutang Usaha IDR</td>
                            <th class="text-right">11.789.000</th>
                            <th class="text-right">0</th>
                          </tr>
                          <tr>
                            <td class="text-center">300001</td>
                            <td class="text-center">Equitas Saldo Awal</td>
                            <th class="text-right">0</th>
                            <th class="text-right">11.789.000</th>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <b-col md="12"><hr></b-col>

                    <b-col md="12">
                      <table class="table table-borderless table-sm">
                        <tbody>
                          <tr>
                            <th width="15%">Date</th>
                            <th width="5%">:</th>
                            <th width="80%">28 November 2021</th>
                          </tr>
                          <tr>
                            <th width="15%">Transaction Type</th>
                            <th width="5%">:</th>
                            <th width="80%">Sales Invoice</th>
                          </tr>
                          <tr>
                            <th width="15%">Transaction No</th>
                            <th width="5%">:</th>
                            <th width="80%">11245</th>
                          </tr>
                          <tr>
                            <th width="15%">Description</th>
                            <th width="5%">:</th>
                            <th width="80%">Saldo Awal pelanggan PT. Cahaya Maritim</th>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <b-col md="1">&nbsp;</b-col>
                    <b-col md="11">
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th width="25%" class="text-center">Account No</th>
                            <th width="25%" class="text-center">Account Name</th>
                            <th width="25%" class="text-right">Debit</th>
                            <th width="25%" class="text-right">Credit</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">110301</td>
                            <td class="text-center">Piutang Usaha IDR</td>
                            <th class="text-right">8.725.000</th>
                            <th class="text-right">0</th>
                          </tr>
                          <tr>
                            <td class="text-center">300001</td>
                            <td class="text-center">Equitas Saldo Awal</td>
                            <th class="text-right">0</th>
                            <th class="text-right">8.725.000</th>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <b-col md="12"><hr></b-col>

                    <b-col md="12">
                      <table class="table table-borderless table-sm">
                        <tbody>
                          <tr>
                            <th width="15%">Date</th>
                            <th width="5%">:</th>
                            <th width="80%">30 November 2021</th>
                          </tr>
                          <tr>
                            <th width="15%">Transaction Type</th>
                            <th width="5%">:</th>
                            <th width="80%">Purchase Invoice</th>
                          </tr>
                          <tr>
                            <th width="15%">Transaction No</th>
                            <th width="5%">:</th>
                            <th width="80%">SF/17/1287</th>
                          </tr>
                          <tr>
                            <th width="15%">Description</th>
                            <th width="5%">:</th>
                            <th width="80%">Saldo Awal pemasok CV. Sejahtera Marine</th>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <b-col md="1">&nbsp;</b-col>
                    <b-col md="11">
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th width="25%" class="text-center">Account No</th>
                            <th width="25%" class="text-center">Account Name</th>
                            <th width="25%" class="text-right">Debit</th>
                            <th width="25%" class="text-right">Credit</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">210101</td>
                            <td class="text-center">Piutang Usaha IDR</td>
                            <th class="text-right">0</th>
                            <th class="text-right">8.150.000</th>
                          </tr>
                          <tr>
                            <td class="text-center">300001</td>
                            <td class="text-center">Equitas Saldo Awal</td>
                            <th class="text-right">8.150.000</th>
                            <th class="text-right">0</th>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <b-col md="12"><hr></b-col>

                    <b-col md="12">
                      <table class="table table-borderless table-sm">
                        <tbody>
                          <tr>
                            <th width="15%">Date</th>
                            <th width="5%">:</th>
                            <th width="80%">03 December 2021</th>
                          </tr>
                          <tr>
                            <th width="15%">Transaction Type</th>
                            <th width="5%">:</th>
                            <th width="80%">Purchase Invoice</th>
                          </tr>
                          <tr>
                            <th width="15%">Transaction No</th>
                            <th width="5%">:</th>
                            <th width="80%">INV-MA-9128</th>
                          </tr>
                          <tr>
                            <th width="15%">Description</th>
                            <th width="5%">:</th>
                            <th width="80%">Saldo Awal pemasok PT. Indah</th>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <b-col md="1">&nbsp;</b-col>
                    <b-col md="11">
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th width="25%" class="text-center">Account No</th>
                            <th width="25%" class="text-center">Account Name</th>
                            <th width="25%" class="text-right">Debit</th>
                            <th width="25%" class="text-right">Credit</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">210101</td>
                            <td class="text-center">Piutang Usaha IDR</td>
                            <th class="text-right">0</th>
                            <th class="text-right">12.000.000</th>
                          </tr>
                          <tr>
                            <td class="text-center">300001</td>
                            <td class="text-center">Equitas Saldo Awal</td>
                            <th class="text-right">12.000.000</th>
                            <th class="text-right">0</th>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <b-col md="12"><hr></b-col>
                  </b-row>
                </template>
            </iq-card>
        </b-col>
      </b-row>
    </b-container>
  </template>
  <script>
  import { xray } from '../../config/pluginInit'
  export default {
    name: 'procurementReportGeneralLedger',
    mounted () {
      xray.index()
    },
    data () {
        return {
          pageDetailSalesOrder: false
        }
    }
  }
  </script>
  