<template>
  <b-container v-if="fleetDetail" fluid>
    <!-- Top Section !-->
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">OPERATIONAL OF FLOATING CRANE - {{fleetDetail.name}}</h4>
          </template>
          <template v-slot:headerAction>
            <!--<small>Right Header</small>-->
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="6">
                <b-row>
                  <b-col md="4">
                    <date-picker v-model="filterMonthYear" @change="generateYearMonthParams" type="month" placeholder="Select Month" value-type="YYYY-MM" format="MMM YYYY"></date-picker>
                  </b-col>
                  <b-col md="2">
                    <b-button variant="primary" @click="getVoyageDashboard()" class="mb-3 mr-1"><i class="fa fa-filter"></i></b-button>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6" class="text-right">
                <button class="btn btn-dark mb-3 mr-1"><i class="fa fa-file-excel"></i> Export</button>
                <router-link :to="{ name: 'dashboard.operational-input-fc', query: { vehicleId: queryVehicleId } }">
                  <button class="btn btn-primary mb-3"><i class="fa fa-plus"></i> Add Operational Data</button>
                </router-link>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Top Section !-->
    <!-- Content Section !-->
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>{{fleetDetail.name}}</strong></h4>
          </template>
          <template v-slot:headerAction>
            <small class="mr-3">Periode <strong>{{ moment(filterMonthYear).format('MMMM YYYY') }}</strong> </small>
            <span class="mr-4"><small>Status <b-badge variant="warning" size="sm"><i class="fa fa-lock"></i></b-badge></small></span>
            <!-- <span class="mr-4"><small> | &nbsp;Cargo Loaded On Vessel <strong class="font-size-16">8.412</strong> &nbsp;|&nbsp; </small></span>-->
            <span class="mt-3"><button class="btn btn-info btn-sm mb-3 mr-1"><i class="fa fa-edit"></i> Edit</button></span>
            <span class="mt-3"><button class="btn btn-danger btn-sm mb-3 mr-1"><i class="fa fa-trash"></i> Delete</button></span>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12">
                  <b-alert show variant="warning" v-if="dataFC.length < 1">Data Not yet input</b-alert>
                  <div v-if="loadingData" class="text-center my-5">
                    <b-spinner type="grow" label="Spinning"></b-spinner>
                  </div>
                  <template v-else>
                    <div class="table-responsive tableFixHead">
                      <table class="table mb-0 table-striped" style="width: 2000px;display: block;height: 700px;overflow-y: scroll;">
                        <thead class="thead-dark text-center">
                        <tr>
                          <th scope="col" rowspan="2" style="vertical-align: middle;">No</th>
                          <th scope="col" rowspan="2" style="vertical-align: middle;">Vessel's Name</th>
                          <th scope="col" rowspan="2" style="vertical-align: middle;">Barge</th>
                          <th scope="col" rowspan="2" style="vertical-align: middle;">A/Side</th>
                          <th scope="col" rowspan="2" style="vertical-align: middle;">Comm</th>
                          <th scope="col" rowspan="2" style="vertical-align: middle;">Compl</th>
                          <th scope="col" rowspan="2" style="vertical-align: middle;">Cast Off</th>
                          <th scope="col" colspan="4">Quantity Cargo</th>
                          <th scope="col" rowspan="2" style="vertical-align: middle;">Origin Cargo</th>
                          <th scope="col" rowspan="2" style="vertical-align: middle;">Cargo Hold</th>
                          <th scope="col" rowspan="2" style="vertical-align: middle;">Cargo Loaded on Vessel</th>
                          <th scope="col" rowspan="2" style="vertical-align: middle;">Idle Time</th>
                          <th scope="col" rowspan="2" style="vertical-align: middle;">Not to Count</th>
                          <th scope="col" rowspan="2" style="vertical-align: middle;">To Count</th>
                          <th scope="col" rowspan="2" style="vertical-align: middle;">Remarks</th>
                        </tr>
                        <tr>
                          <th scope="col">Draft Survey</th>
                          <th scope="col">Belt Scale FTS</th>
                          <th scope="col">D B/S</th>
                          <th scope="col">Portion Tonnage</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(data, index) in voyageFcActivities">
                          <tr>
                            <td :rowspan="data.detail.length + 1" style="vertical-align: top;">{{ index+1 }}</td>
                            <td :rowspan="data.detail.length + 1" style="vertical-align: top;">{{ data.mvName }}</td>
                            <td colspan="11" class="active"></td>
                            <td :rowspan="data.detail.length + 1" style="vertical-align: top;"><b-badge class="success font-size-14">{{ data.cargoLoaded ? data.cargoLoaded : 0 }} TON</b-badge></td>
                            <td colspan="4" class="active"></td>
                          </tr>
                          <tr v-for="(detail, indexDetail) in data.detail">
                            <td>barge {{detail.bargeName}}</td>
                            <td>{{detail.side}}</td>
                            <td>{{detail.comm}}</td>
                            <td>{{detail.compl}}</td>
                            <td>{{detail.castOf}}</td>
                            <td>{{detail.cargoDraft}}</td>
                            <td>{{detail.beltScale}}</td>
                            <td>{{detail.cargoDbs}}</td>
                            <td>{{detail.portionTonnage}}</td>
                            <td>{{detail.cargoOrigin}}</td>
                            <td>{{detail.cargoHold}}</td>
                            <td>{{detail.idleTime ? detail.idleTime.durationFormat : '-'}}</td>
                            <td>-</td>
                            <td>-</td>
                            <td><small>
                              {{detail.remarks}}
                            </small></td>
                          </tr>
                        </template>
<!--                          <tr>-->
<!--                            <td colspan="11"></td>-->
<!--                            <td>-->
<!--                              <small>-->
<!--                                <ol>-->
<!--                                  <li>Start: 2-Sep-21 1.40</li>-->
<!--                                  <li>Finish: 2-Sep-21 3.40</li>-->
<!--                                </ol>-->
<!--                              </small>-->
<!--                            </td>-->
<!--                            <td><small>-->
<!--                              <ol>-->
<!--                                <li>Bad Weather: 00.02.00</li>-->
<!--                              </ol>-->
<!--                            </small></td>-->
<!--                            <td>-</td>-->
<!--                            <td>Stop loading ops due to heavy rain & strong wind</td>-->
<!--                          </tr>-->
                        </tbody>
                        <tfoot class="table-info font-weight-bold">
                        <tr>
                          <td><strong>TOTAL</strong></td>
                          <td colspan="6"></td>
                          <td>{{totalCargoDraftSurvey}}</td>
                          <td>{{totalCargoBeltScale}}</td>
                          <td>{{totalCargoDbs}}</td>
                          <td>{{ totalCargoPortionTonnage }}</td>
                          <td colspan="2"></td>
                          <td></td>
                          <td colspan="4"></td>
                        </tr>
                        </tfoot>
                      </table>
                    </div>
                  </template>
                </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>

      <b-col lg="6">
        <iq-card>
          <template v-slot:body>
            <table class="table table-striped">
              <tbody>
              <tr>
                <td>Cargo discharged month to date</td>
                <td class="font-weight-bold">{{ voyageFcSummary.cargoDischargedMonth }} MT</td>
              </tr>
              <tr>
                <td>Total Cargo Vessel Complete Loaded</td>
                <td class="font-weight-bold">{{ voyageFcSummary.totalCargoLoaded }} MT</td>
              </tr>
              <tr>
                <td>Day In Month  / Hrs</td>
                <td class="font-weight-bold">{{ voyageFcSummary.totalHoursInMonth }} Hours</td>
              </tr>
              <tr>
                <td>Gross Hourly Rate</td>
                <td class="font-weight-bold">{{ voyageFcSummary.grossHourlyRate }} Mt/Hours</td>
              </tr>
              <tr>
                <td>Gross Daily Rate</td>
                <td class="font-weight-bold">{{ voyageFcSummary.grossDailyRate }} Mt/Day</td>
              </tr>
              <tr>
                <td>Net Hourly Rate</td>
                <td class="font-weight-bold">{{ voyageFcSummary.netHourlyRate }} Mt/Hours</td>
              </tr>
              <tr>
                <td>Net Daily Rate</td>
                <td class="font-weight-bold">{{ voyageFcSummary.netDailyRate }} Mt/Day</td>
              </tr>
              </tbody>
            </table>
          </template>
        </iq-card>
      </b-col>

      <b-col lg="6">
        <iq-card>
          <template v-slot:body>
            <table class="table table-striped">
              <thead class="table-warning">
              <tr>
                <th>Performance</th>
                <th>Actual</th>
                <th>Plan</th>
                <th>Var</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="(performance) in voyageFcPerformances">
                  <td>{{ performance.name }}</td>
                  <td>{{ performance.actual }}</td>
                  <td>{{ performance.plan }}</td>
                  <td>{{ performance.vars }}</td>
                </tr>
              <tr v-if="voyageFcPerformances.length === 0">
                <td colspan="4">No Data</td>
              </tr>
              </tbody>
            </table>
          </template>
        </iq-card>
      </b-col>

      <b-col lg="4">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>DATA ANALYSIS OF MONTH TO DATE</strong></h4>
          </template>
          <template v-slot:body>
            <table class="table table-striped">
              <thead class="thead-dark">
              <tr>
                <th>DESCRIPTION</th>
                <th>Dd/Hh/Mm</th>
                <th>HOURS</th>
                <th>PERCENTAGE</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(data) in voyageAnalysisOfMonth.data">
                <td>{{ data.name }}</td>
                <td>{{ data.ddHhMm }}</td>
                <td>{{ data.hours }}</td>
                <td>{{ data.percentage }}</td>
              </tr>
              <tr v-if="voyageAnalysisOfMonth.data.length === 0">
                <td colspan="4">No Data</td>
              </tr>
              </tbody>
              <tfoot class="table-info">
              <tr>
                <td class="text-center">Total</td>
                <td>{{ voyageAnalysisOfMonth.total.ddHhMm }}</td>
                <td>{{ voyageAnalysisOfMonth.total.hours }}</td>
                <td>{{ voyageAnalysisOfMonth.total.percentage }}</td>
              </tr>
              </tfoot>
            </table>
          </template>
        </iq-card>
      </b-col>

      <b-col lg="4">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>IDLE TIME			</strong></h4>
          </template>
          <template v-slot:body>
            <table class="table table-striped">
              <thead class="thead-dark">
              <tr>
                <th>DESCRIPTION</th>
                <th>Dd/Hh/Mm</th>
                <th>HOURS</th>
                <th>PERCENTAGE</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(data) in voyageIdleOfMonth.data">
                <td>{{ data.name }}</td>
                <td>{{ data.ddHhMm }}</td>
                <td>{{ data.hours }}</td>
                <td>{{ data.percentage }}</td>
              </tr>
              <tr v-if="voyageIdleOfMonth.data.length === 0">
                <td colspan="4">No Data</td>
              </tr>
              </tbody>
              <tfoot class="table-info">
              <tr>
                <td class="text-center">Total</td>
                <td>{{ voyageIdleOfMonth.total.ddHhMm }}</td>
                <td>{{ voyageIdleOfMonth.total.hours }}</td>
                <td>{{ voyageIdleOfMonth.total.percentage }}</td>
              </tr>
              </tfoot>
            </table>
          </template>
        </iq-card>
      </b-col>

      <b-col lg="4">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>To Account</strong></h4>
          </template>
          <template v-slot:body>
            <table class="table table-striped">
              <thead class="thead-dark">
              <tr>
                <th>DESCRIPTION</th>
                <th>Dd/Hh/Mm</th>
                <th>HOURS</th>
                <th>PERCENTAGE</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(data) in voyageToAccount.data">
                <td>{{ data.name }}</td>
                <td>{{ data.ddHhMm }}</td>
                <td>{{ data.hours }}</td>
                <td>{{ data.percentage }}</td>
              </tr>
              <tr v-if="voyageToAccount.data.length === 0">
                <td colspan="4">No Data</td>
              </tr>
              </tbody>
              <tfoot class="table-info">
              <tr>
                <td class="text-center">Total</td>
                <td>{{ voyageToAccount.total.ddHhMm }}</td>
                <td>{{ voyageToAccount.total.hours }}</td>
                <td>{{ voyageToAccount.total.percentage }}</td>
              </tr>
              </tfoot>
            </table>
          </template>
        </iq-card>
      </b-col>
      <!-- End Card Fleet -->
    </b-row>
    <!-- End Content Section !-->
  </b-container>
</template>
<script>
import {
  fleetsActions,
  maintenanceActions, voyagesActions
} from '@src/Utils/helper'
import moment from 'moment'
export default {
  name: 'Operational Floating Crane',
  computed: {
    'queryVehicleId' () {
      return this.$route.query.vehicleId
    },
    totalCargoDraftSurvey () {
      return this.voyageFcActivities.map(activities => {
        return activities.detail.map(detail => {
          return parseFloat(detail.cargoDraft)
        })
      }).reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0)
    },
    totalCargoBeltScale () {
      return this.voyageFcActivities.map(activities => {
        return activities.detail.map(detail => {
          return parseFloat(detail.beltScale)
        })
      }).reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0)
    },
    totalCargoDbs () {
      return this.voyageFcActivities.map(activities => {
        return activities.detail.map(detail => {
          return parseFloat(detail.cargoDbs)
        })
      }).reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0)
    },
    totalCargoPortionTonnage () {
      return this.voyageFcActivities.map(activities => {
        return activities.detail.map(detail => {
          return parseFloat(detail.portionTonnage)
        })
      }).reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0)
    },
    totalCargoLoadedOnVessel () {
      return this.voyageFcActivities.map(activities => {
        return activities.detail.map(detail => {
          return parseFloat(detail.cargoOrigin) + parseFloat(detail.cargoHold)
        })
      }).reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0)
    }
  },
  data () {
    return {
      fleet: {},
      filterMonthYear: moment().format('YYYY-MM'),
      loadingData: false,
      dataFC: [
        {
          no: '1',
          vessel: [
            {
              name: '',
              barge: [
                {
                  name: ''
                }
              ]
            }
          ]
        }
      ],
      voyageFcParams: {
        year: moment().year(),
        month: moment().format('MM')
      },
      voyageFcActivities: [],
      voyageFcPerformances: [],
      voyageFcSummary: {
        "cargoDischargedMonth": 0,
        "totalCargoLoaded": 0,
        "totalHoursInMonth": 0,
        "grossHourlyRate": 0,
        "grossDailyRate": 0,
        "netHourlyRate": 0,
        "netDailyRate": 0
      },
      voyageAnalysisOfMonth: {
        data: [],
        total: {
          "ddHhMm": '-',
          "percentage": "-",
          "hours": "-"
        }
      },
      voyageIdleOfMonth: {
        data: [],
        total: {
          "ddHhMm": '-',
          "percentage": "-",
          "hours": "-"
        }
      },
      voyageToAccount: {
        data: [],
        total: {
          "ddHhMm": '-',
          "percentage": "-",
          "hours": "-"
        }
      },
      fleetDetail: null
    }
  },
  methods: {
    ...fleetsActions,
    ...voyagesActions,
    ...maintenanceActions,
    async getVoyageDashboard () {
      this.voyageFcActivities = []
      this.voyageFcPerformances = []
      this.voyageFcSummary = {
        "cargoDischargedMonth": 0,
        "totalCargoLoaded": 0,
        "totalHoursInMonth": 0,
        "grossHourlyRate": 0,
        "grossDailyRate": 0,
        "netHourlyRate": 0,
        "netDailyRate": 0
      }
      this.voyageAnalysisOfMonth.data = []
      this.voyageAnalysisOfMonth.total = {
        "ddHhMm": '-',
        "percentage": "-",
        "hours": "-"
      }
      this.voyageIdleOfMonth.data = []
      this.voyageIdleOfMonth.total = {
        "ddHhMm": '-',
        "percentage": "-",
        "hours": "-"
      }
      this.voyageToAccount.data = []
      this.voyageToAccount.total = {
        "ddHhMm": '-',
        "percentage": "-",
        "hours": "-"
      }

      const res = await this.getFcDashboardPeriod({
        vehicleId: this.queryVehicleId,
        ...this.voyageFcParams
      })

      if (res.status === 'success') {
        if (res.data.data) {
          this.voyageFcActivities = res.data.data.activities
          this.voyageFcPerformances = res.data.data.performance
          this.voyageFcSummary = res.data.data.summary

          this.voyageAnalysisOfMonth = res.data.data.analysisOfMonth
          this.voyageIdleOfMonth = res.data.data.idleOfMonth
          this.voyageToAccount = res.data.data.toAccount
        }
      }
    },
    async fetchFleetDetail (id) {
      const res = await this.getFleetById(id)

      if (res.status === 'success') {
        this.fleetDetail = res.data
      } else {
        this.$swal(
          `Oops! Data Vehicle Tidak DItemukan`,
          res.data,
          'error'
        )
      }
    },
    generateYearMonthParams () {
      if (this.filterMonthYear) {
        this.voyageFcParams.month = moment(this.filterMonthYear).format('MM')
        this.voyageFcParams.year = moment(this.filterMonthYear).year()
      }
    }
  },
  async mounted () {
    await this.fetchFleetDetail(this.queryVehicleId)
    await this.getVoyageDashboard()
  }
}
</script>

<style scoped>
</style>
