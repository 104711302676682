var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('StepNavigation', {
    attrs: {
      "current-step": "COST_BREAKDOWN"
    }
  }), _vm.detailMi && _vm.detailSurvey ? _c('form', {
    attrs: {
      "action": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('img', {
          staticClass: "img-fluid w-20",
          attrs: {
            "src": _vm.image,
            "alt": "logo"
          }
        })]), _c('b-col', {
          attrs: {
            "cols": "6",
            "align-self": "center"
          }
        }, [_c('h4', {
          staticClass: "mb-0 float-right text-primary"
        }, [_c('b', [_vm._v(_vm._s(_vm.title))])])])], 1), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('div', {
          staticClass: "table-responsive-sm"
        }, [_c('b-table-simple', [_c('thead', [_c('tr', {
          attrs: {
            "width": "100%"
          }
        }, [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("NO")]), _c('th', {
          attrs: {
            "width": "45%"
          }
        }, [_vm._v("DESCRIPTION / ITEM")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("TOTAL")])])]), _c('tbody', [_vm._l(_vm.listBreakdowns, function (cb, index) {
          return _c('tr', [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(cb.description))]), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "placeholder": "Isi"
            },
            model: {
              value: cb.value,
              callback: function callback($$v) {
                _vm.$set(cb, "value", $$v);
              },
              expression: "cb.value"
            }
          })], 1)]);
        }), _c('tr', {
          staticClass: "iq-bg-info"
        }, [_c('td', {
          staticClass: "text-right",
          attrs: {
            "colspan": "4"
          }
        }, [_c('h5', [_c('b', [_vm._v("SUB TOTAL")])])]), _c('td', {
          attrs: {
            "colspan": "2"
          }
        }, [_c('h5', [_c('b', [_vm._v("IDR. " + _vm._s(_vm.numberFormat(_vm.subTotal)))])])])]), _c('tr', {
          staticClass: "iq-bg-secondary"
        }, [_c('td', {
          staticClass: "text-right",
          attrs: {
            "colspan": "4"
          }
        }, [_c('h5', [_c('b', [_vm._v("DEDUCTIBLE")])])]), _c('td', {
          attrs: {
            "colspan": "2"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "required": ""
          },
          model: {
            value: _vm.form.deductible,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "deductible", $$v);
            },
            expression: "form.deductible"
          }
        }), _vm.detailMi.type_coverage && _vm.detailMi.type_coverage.name === 'Hull & Machinery' ? [_c('br'), _c('small', [_vm._v("Price List Kapal HM x Deductible %")])] : _vm._e()], 2)]), _c('tr', {
          staticClass: "iq-bg-primary"
        }, [_c('td', {
          staticClass: "text-right",
          attrs: {
            "colspan": "4"
          }
        }, [_c('h5', [_c('b', [_vm._v("GRAND TOTAL")])])]), _c('td', {
          attrs: {
            "colspan": "2"
          }
        }, [_c('h5', [_c('b', [_vm._v("IDR. " + _vm._s(_vm.numberFormat(_vm.grandTotal)))])])])])], 2)])], 1)]), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "offset": "6",
            "cols": "6"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "link mr-3"
          }
        }, [_c('i', {
          staticClass: "ri-printer-line"
        }), _vm._v(" Download Print ")]), _c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_vm._v(" Submit ")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 74293587)
  })], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }