var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Xray Color")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-1",
            modifiers: {
              "collapse-1": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Similar to the contextual text color classes, easily set the background of an element to any contextual class. Anchor components will darken on hover, just like the text classes. Background utilities "), _c('strong', [_vm._v("do not set "), _c('code', [_vm._v("color")])]), _vm._v(", so in some cases you’ll want to use "), _c('code', [_vm._v(".text-*")]), _vm._v(" utilities.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-1"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "xray-color1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n  <p class=\"bg-primary pt-5 pb-5 text-center rounded\"></p>\n  <h6>Primary</h6>\n\n  <p class=\"bg-secondary pt-5 pb-5 text-center rounded\"></p>\n  <h6>Secondary</h6>\n\n  <p class=\"bg-success pt-5 pb-5 text-center rounded\"></p>\n  <h6>Success</h6>\n\n  <p class=\"bg-danger pt-5 pb-5 text-center rounded\"></p>\n  <h6>Danger</h6>\n\n  <p class=\"bg-warning pt-5 pb-5 text-center rounded\"></p>\n  <h6>Warning</h6>\n\n  <p class=\"bg-info pt-5 pb-5 text-center rounded\"></p>\n  <h6>Info</h6>\n\n  <p class=\"bg-light pt-5 pb-5 text-center rounded\"></p>\n  <h6>Light</h6>\n\n  <p class=\"bg-dark pt-5 pb-5 text-center rounded\"></p>\n  <h6>Dark</h6>\n  ")])])])])]), _c('b-row', {
          staticClass: "mt-4"
        }, [_c('b-col', {
          staticClass: "text-center",
          attrs: {
            "lg": "2",
            "md": "6"
          }
        }, [_c('p', {
          staticClass: "bg-primary pt-5 pb-5 text-center rounded"
        }), _c('h6', [_vm._v("Primary")])]), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "lg": "2",
            "md": "6"
          }
        }, [_c('p', {
          staticClass: "bg-secondary pt-5 pb-5 text-center rounded"
        }), _c('h6', [_vm._v("Secondary")])]), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "lg": "2",
            "md": "6"
          }
        }, [_c('p', {
          staticClass: "bg-success pt-5 pb-5 text-center rounded"
        }), _c('h6', [_vm._v("Success")])]), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "lg": "2",
            "md": "6"
          }
        }, [_c('p', {
          staticClass: "bg-danger pt-5 pb-5 text-center rounded"
        }), _c('h6', [_vm._v("Danger")])]), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "lg": "2",
            "md": "6"
          }
        }, [_c('p', {
          staticClass: "bg-warning pt-5 pb-5 text-center rounded"
        }), _c('h6', [_vm._v("Warning")])]), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "lg": "2",
            "md": "6"
          }
        }, [_c('p', {
          staticClass: "bg-info pt-5 pb-5 text-center rounded"
        }), _c('h6', [_vm._v("Info")])]), _c('b-col', {
          staticClass: "text-center mt-4",
          attrs: {
            "lg": "2",
            "md": "6"
          }
        }, [_c('p', {
          staticClass: "bg-light pt-5 pb-5 text-center rounded"
        }), _c('h6', [_vm._v("Light")])]), _c('b-col', {
          staticClass: "text-center mt-4",
          attrs: {
            "lg": "2",
            "md": "6"
          }
        }, [_c('p', {
          staticClass: "bg-dark pt-5 pb-5 text-center rounded"
        }), _c('h6', [_vm._v("Dark")])])], 1)];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Xray Color")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-2",
            modifiers: {
              "collapse-2": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Similar to the contextual text color classes, easily set the background of an element to any contextual class. Anchor components will darken on hover, just like the text classes. Background utilities "), _c('strong', [_vm._v("do not set "), _c('code', [_vm._v("color")])]), _vm._v(", so in some cases you’ll want to use "), _c('code', [_vm._v(".text-*")]), _vm._v(" utilities.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-2"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "xray-color2"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<p class=\"iq-bg-primary pt-5 pb-5 text-center rounded font-size-18\">Primary</p>\n\n<p class=\"iq-bg-secondary pt-5 pb-5 text-center rounded font-size-18\">Secondary</p>\n\n<p class=\"iq-bg-success pt-5 pb-5 text-center rounded font-size-18\">Success</p>\n\n<p class=\"iq-bg-danger pt-5 pb-5 text-center rounded font-size-18\">Danger</p>\n\n<p class=\"iq-bg-warning pt-5 pb-5 text-center rounded font-size-18\">Warning</p>\n\n<p class=\"iq-bg-info pt-5 pb-5 text-center rounded font-size-18\">Info</p>\n\n<p class=\"iq-bg-dark pt-5 pb-5 text-center rounded font-size-18\">Dark</p>\n")])])])])]), _c('b-row', {
          staticClass: "mt-4"
        }, [_c('b-col', {
          staticClass: "text-center",
          attrs: {
            "lg": "2",
            "md": "6"
          }
        }, [_c('p', {
          staticClass: "iq-bg-primary pt-5 pb-5 text-center rounded font-size-18"
        }, [_vm._v("Primary")])]), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "lg": "2",
            "md": "6"
          }
        }, [_c('p', {
          staticClass: "iq-bg-secondary pt-5 pb-5 text-center rounded font-size-18"
        }, [_vm._v("Secondary")])]), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "lg": "2",
            "md": "6"
          }
        }, [_c('p', {
          staticClass: "iq-bg-success pt-5 pb-5 text-center rounded font-size-18"
        }, [_vm._v("Success")])]), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "lg": "2",
            "md": "6"
          }
        }, [_c('p', {
          staticClass: "iq-bg-danger pt-5 pb-5 text-center rounded font-size-18"
        }, [_vm._v("Danger")])]), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "lg": "2",
            "md": "6"
          }
        }, [_c('p', {
          staticClass: "iq-bg-warning pt-5 pb-5 text-center rounded font-size-18"
        }, [_vm._v("Warning")])]), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "lg": "2",
            "md": "6"
          }
        }, [_c('p', {
          staticClass: "iq-bg-info pt-5 pb-5 text-center rounded font-size-18"
        }, [_vm._v("Info")])]), _c('b-col', {
          staticClass: "text-center mt-4",
          attrs: {
            "lg": "2",
            "md": "6"
          }
        }, [_c('p', {
          staticClass: "iq-bg-dark pt-5 pb-5 text-center rounded font-size-18"
        }, [_vm._v("Dark")])])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Xray Text Color")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-3",
            modifiers: {
              "collapse-3": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Contextual text classes also work well on anchors with the provided hover and focus states. "), _c('strong', [_vm._v("Note that the "), _c('code', [_vm._v(".text-white")]), _vm._v(" and "), _c('code', [_vm._v(".text-muted")]), _vm._v(" class has no additional link styling beyond underline.")])]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-3"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "xray-text-color1"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<p class=\"text-primary\">.text-primary</p>\n<p class=\"text-secondary\">.text-secondary</p>\n<p class=\"text-success\">.text-success</p>\n<p class=\"text-danger\">.text-danger</p>\n<p class=\"text-warning\">.text-warning</p>\n<p class=\"text-info\">.text-info</p>\n<p class=\"text-light bg-dark\">.text-light</p>\n<p class=\"text-dark\">.text-dark</p>\n<p class=\"text-muted\">.text-muted</p>\n<p class=\"text-white bg-dark\">.text-white</p>\n<p class=\"text-black-50\">.text-black-50</p>\n<p class=\"text-white-50 bg-dark mb-0\">.text-white-50</p>\n")])])])])]), _c('p', {
          staticClass: "text-primary"
        }, [_vm._v(".text-primary")]), _c('p', {
          staticClass: "text-secondary"
        }, [_vm._v(".text-secondary")]), _c('p', {
          staticClass: "text-success"
        }, [_vm._v(".text-success")]), _c('p', {
          staticClass: "text-danger"
        }, [_vm._v(".text-danger")]), _c('p', {
          staticClass: "text-warning"
        }, [_vm._v(".text-warning")]), _c('p', {
          staticClass: "text-info"
        }, [_vm._v(".text-info")]), _c('p', {
          staticClass: "text-light bg-dark"
        }, [_vm._v(".text-light")]), _c('p', {
          staticClass: "text-dark"
        }, [_vm._v(".text-dark")]), _c('p', {
          staticClass: "text-muted"
        }, [_vm._v(".text-muted")]), _c('p', {
          staticClass: "text-white bg-dark"
        }, [_vm._v(".text-white")]), _c('p', {
          staticClass: "text-black-50"
        }, [_vm._v(".text-black-50")]), _c('p', {
          staticClass: "text-white-50 bg-dark mb-0"
        }, [_vm._v(".text-white-50")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Xray Text Color")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-4",
            modifiers: {
              "collapse-4": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Contextual text classes also work well on anchors with the provided hover and focus states. "), _c('strong', [_vm._v("Note that the "), _c('code', [_vm._v(".text-white")]), _vm._v(" and "), _c('code', [_vm._v(".text-muted")]), _vm._v(" class has no additional link styling beyond underline.")])]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-4"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "xray-text-color2"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<p><a href=\"#\" class=\"text-primary\">Primary link</a></p>\n<p><a href=\"#\" class=\"text-secondary\">Secondary link</a></p>\n<p><a href=\"#\" class=\"text-success\">Success link</a></p>\n<p><a href=\"#\" class=\"text-danger\">Danger link</a></p>\n<p><a href=\"#\" class=\"text-warning\">Warning link</a></p>\n<p><a href=\"#\" class=\"text-info\">Info link</a></p>\n<p><a href=\"#\" class=\"text-light bg-dark\">Light link</a></p>\n<p><a href=\"#\" class=\"text-dark\">Dark link</a></p>\n<p><a href=\"#\" class=\"text-muted\">Muted link</a></p>\n<p class=\"mb-0\"><a href=\"#\" class=\"text-white bg-dark\">White link</a></p>\n")])])])])]), _c('p', [_c('a', {
          staticClass: "text-primary",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Primary link")])]), _c('p', [_c('a', {
          staticClass: "text-secondary",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Secondary link")])]), _c('p', [_c('a', {
          staticClass: "text-success",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Success link")])]), _c('p', [_c('a', {
          staticClass: "text-danger",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Danger link")])]), _c('p', [_c('a', {
          staticClass: "text-warning",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Warning link")])]), _c('p', [_c('a', {
          staticClass: "text-info",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Info link")])]), _c('p', [_c('a', {
          staticClass: "text-light bg-dark",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Light link")])]), _c('p', [_c('a', {
          staticClass: "text-dark",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Dark link")])]), _c('p', [_c('a', {
          staticClass: "text-muted",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Muted link")])]), _c('p', {
          staticClass: "mb-0"
        }, [_c('a', {
          staticClass: "text-white bg-dark",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("White link")])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Xray Text Color")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-5",
            modifiers: {
              "collapse-5": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Contextual text classes also work well on anchors with the provided hover and focus states. "), _c('strong', [_vm._v("Note that the "), _c('code', [_vm._v(".text-white")]), _vm._v(" and "), _c('code', [_vm._v(".text-muted")]), _vm._v(" class has no additional link styling beyond underline.")])]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-5"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "xray-text-color3"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<p class=\"iq-bg-primary pl-3 pr-3 pt-2 pb-2 rounded\">Primary link</p>\n<p class=\"iq-bg-secondary pl-3 pr-3 pt-2 pb-2 rounded\">Secondary link</p>\n<p class=\"iq-bg-success pl-3 pr-3 pt-2 pb-2 rounded\">Success link</p>\n<p class=\"iq-bg-danger pl-3 pr-3 pt-2 pb-2 rounded\">Danger link</p>\n<p class=\"iq-bg-warning pl-3 pr-3 pt-2 pb-2 rounded\">Warning link</p>\n<p class=\"iq-bg-info pl-3 pr-3 pt-2 pb-2 rounded\">Info link</p>\n<p class=\"iq-bg-dark pl-3 pr-3 pt-2 pb-2 rounded mb-0\">Dark link</p>\n")])])])])]), _c('p', {
          staticClass: "iq-bg-primary pl-3 pr-3 pt-2 pb-2 rounded"
        }, [_vm._v("Primary link")]), _c('p', {
          staticClass: "iq-bg-secondary pl-3 pr-3 pt-2 pb-2 rounded"
        }, [_vm._v("Secondary link")]), _c('p', {
          staticClass: "iq-bg-success pl-3 pr-3 pt-2 pb-2 rounded"
        }, [_vm._v("Success link")]), _c('p', {
          staticClass: "iq-bg-danger pl-3 pr-3 pt-2 pb-2 rounded"
        }, [_vm._v("Danger link")]), _c('p', {
          staticClass: "iq-bg-warning pl-3 pr-3 pt-2 pb-2 rounded"
        }, [_vm._v("Warning link")]), _c('p', {
          staticClass: "iq-bg-info pl-3 pr-3 pt-2 pb-2 rounded"
        }, [_vm._v("Info link")]), _c('p', {
          staticClass: "iq-bg-dark pl-3 pr-3 pt-2 pb-2 rounded mb-0"
        }, [_vm._v("Dark link")])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Xray Text Color")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-6",
            modifiers: {
              "collapse-6": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("Contextual text classes also work well on anchors with the provided hover and focus states. "), _c('strong', [_vm._v("Note that the "), _c('code', [_vm._v(".text-white")]), _vm._v(" and "), _c('code', [_vm._v(".text-muted")]), _vm._v(" class has no additional link styling beyond underline.")])]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-6"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "xray-text-color4"
          }
        }, [_vm._v("                    "), _c('code', [_vm._v("\n<p><a href=\"#\" class=\"iq-bg-primary pl-3 pr-3 pt-2 pb-2 rounded d-inline-block\">Primary link</a></p>\n<p><a href=\"#\" class=\"iq-bg-secondary pl-3 pr-3 pt-2 pb-2 rounded d-inline-block\">Secondary link</a></p>\n<p><a href=\"#\" class=\"iq-bg-success pl-3 pr-3 pt-2 pb-2 rounded d-inline-block\">Success link</a></p>\n<p><a href=\"#\" class=\"iq-bg-danger pl-3 pr-3 pt-2 pb-2 rounded d-inline-block\">Danger link</a></p>\n<p><a href=\"#\" class=\"iq-bg-warning pl-3 pr-3 pt-2 pb-2 rounded d-inline-block\">Warning link</a></p>\n<p><a href=\"#\" class=\"iq-bg-info pl-3 pr-3 pt-2 pb-2 rounded d-inline-block\">Info link</a></p>\n<p class=\"mb-0\"><a href=\"#\" class=\"iq-bg-dark pl-3 pr-3 pt-2 pb-2 rounded d-inline-block\">Dark link</a></p>\n")])])])])]), _c('p', [_c('a', {
          staticClass: "iq-bg-primary pl-3 pr-3 pt-2 pb-2 rounded d-inline-block",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Primary link")])]), _c('p', [_c('a', {
          staticClass: "iq-bg-secondary pl-3 pr-3 pt-2 pb-2 rounded d-inline-block",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Secondary link")])]), _c('p', [_c('a', {
          staticClass: "iq-bg-success pl-3 pr-3 pt-2 pb-2 rounded d-inline-block",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Success link")])]), _c('p', [_c('a', {
          staticClass: "iq-bg-danger pl-3 pr-3 pt-2 pb-2 rounded d-inline-block",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Danger link")])]), _c('p', [_c('a', {
          staticClass: "iq-bg-warning pl-3 pr-3 pt-2 pb-2 rounded d-inline-block",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Warning link")])]), _c('p', [_c('a', {
          staticClass: "iq-bg-info pl-3 pr-3 pt-2 pb-2 rounded d-inline-block",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Info link")])]), _c('p', {
          staticClass: "mb-0"
        }, [_c('a', {
          staticClass: "iq-bg-dark pl-3 pr-3 pt-2 pb-2 rounded d-inline-block",
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Dark link")])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }