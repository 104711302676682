var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Stock Opname")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "text-right mb-5",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus-circle"
        }), _vm._v(" Add New Opname ")])], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "3%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Name")]), _c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("Date")]), _c('th', [_vm._v("Fleets")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Different")]), _c('th', [_vm._v("Action")])])]), _c('tr', [_c('td'), _c('td', [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "search..."
          }
        })], 1), _c('td', [_c('date-picker', {
          attrs: {
            "type": "date",
            "range": "",
            "placeholder": "Select Date",
            "format": "DD-MM-YYYY"
          }
        })], 1), _c('td', [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Search or add a fleet",
            "label": "name",
            "track-by": "code",
            "options": _vm.fleetEquipments
          },
          model: {
            value: _vm.fleetSelected,
            callback: function callback($$v) {
              _vm.fleetSelected = $$v;
            },
            expression: "fleetSelected"
          }
        })], 1), _c('td', [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.statusGoods,
            "reduce": function reduce(type) {
              return type.value;
            },
            "placeholder": "select category"
          },
          model: {
            value: _vm.statusSelected,
            callback: function callback($$v) {
              _vm.statusSelected = $$v;
            },
            expression: "statusSelected"
          }
        })], 1), _c('td'), _c('td')]), _c('tr', [_c('td', [_vm._v("1")]), _c('td', [_vm._v("Stock Opname Bulan Januari")]), _c('td'), _c('td'), _c('td'), _c('td'), _c('td', [_c('span', [_c('b-button', {
          attrs: {
            "variant": "warning"
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        })])], 1), _c('span', [_c('b-button', {
          attrs: {
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash"
        })])], 1)])])])])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }