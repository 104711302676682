var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "pt-0"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Basic Example")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-1",
            modifiers: {
              "collapse-1": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_c('span', {
          staticClass: "text-danger"
        }, [_vm._v("flatpickr")]), _vm._v(" without any config.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-1"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "basic-datepicker"
          }
        }, [_c('code', [_vm._v("\n<template>\n<flat-pickr class=\"form-control\" :config=\"basicConfig\" placeholder=\"Select Date\"></flat-pickr>\n</template>\n<script>\nexport default {\n  name: 'DatepickerDemo',\ndata(){\n  return {\n      basicConfig: {}\n    }\n   }\n  }\n</script>\n")])])])])]), _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "value": "",
            "config": _vm.basicConfig,
            "placeholder": "Select Date"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "pt-0"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Basic Datetime Example")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-2",
            modifiers: {
              "collapse-2": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_c('span', {
          staticClass: "text-danger"
        }, [_vm._v("flatpickr")]), _vm._v(" with date and time config.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-2"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "basic-datepicker-time"
          }
        }, [_c('code', [_vm._v("\n<template>\n<flat-pickr class=\"form-control\" :config=\"basicTimeConfig\" placeholder=\"Select DateTime\"></flat-pickr>\n</template>\n<script>\nexport default {\n  name: 'DatepickerDemo',\ndata(){\n  return {\n       basicTimeConfig: {\n        enableTime: true,\n        dateFormat: 'Y-m-d H:i'\n      }\n    }\n   }\n  }\n</script>\n")])])])])]), _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "value": "",
            "config": _vm.basicTimeConfig,
            "placeholder": "Select DateTime"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "pt-0"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Basic Mindate Example")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-3",
            modifiers: {
              "collapse-3": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_c('span', {
          staticClass: "text-danger"
        }, [_vm._v("flatpickr")]), _vm._v(" with min date config.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-3"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "basic-Min-datepicker"
          }
        }, [_c('code', [_vm._v("\n<template>\n<flat-pickr class=\"form-control\" :config=\"basicMinTimeConfig\" placeholder=\"Select Date\"></flat-pickr>\n</template>\n<script>\nexport default {\n  name: 'DatepickerDemo',\ndata(){\n  return {\n       basicMinTimeConfig: {\n        minDate: '2020-01'\n      }\n    }\n   }\n  }\n</script>\n")])])])])]), _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "value": "",
            "config": _vm.basicMinTimeConfig,
            "placeholder": "Select Date"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "pt-0"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Basic Maxdate Example")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-4",
            modifiers: {
              "collapse-4": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_c('span', {
          staticClass: "text-danger"
        }, [_vm._v("flatpickr")]), _vm._v(" with max date config.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-4"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "basic-Disable-datepicker"
          }
        }, [_c('code', [_vm._v("\n<template>\n <flat-pickr class=\"form-control\" :config=\"basicMaxDateConfig\" placeholder=\"Select Date\"></flat-pickr>\n</template>\n<script>\nexport default {\n  name: 'DatepickerDemo',\ndata(){\n  return {\n        basicMaxDateConfig: {\n        disable: [ '2020-01-30', '2020-02-21', '2020-03-08', new Date(2025, 4, 9) ],\n        dateFormat: 'Y-m-d'\n      }\n    }\n   }\n  }\n</script>\n")])])])])]), _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "value": "",
            "config": _vm.basicMaxDateConfig,
            "placeholder": "Select Date"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "pt-0"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Basic Multipledate Example")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-5",
            modifiers: {
              "collapse-5": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_c('span', {
          staticClass: "text-danger"
        }, [_vm._v("flatpickr")]), _vm._v(" with multiple date config.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-5"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "basic-Multiple-datepicker"
          }
        }, [_c('code', [_vm._v("\n<template>\n <flat-pickr class=\"form-control\" :config=\"basicMultipleDateConfig\" placeholder=\"Select Date\"></flat-pickr>\n</template>\n<script>\nexport default {\n  name: 'DatepickerDemo',\ndata(){\n  return {\n        basicMultipleDateConfig: {\n        mode: 'multiple',\n        dateFormat: 'Y-m-d'\n      }\n    }\n   }\n  }\n</script>\n")])])])])]), _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "value": "",
            "config": _vm.basicMultipleDateConfig,
            "placeholder": "Select Date"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "pt-0"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Basic Rangedate Example")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-6",
            modifiers: {
              "collapse-6": true
            }
          }],
          staticClass: "text-primary float-right",
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "ri-code-s-slash-line"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('p', [_c('span', {
          staticClass: "text-danger"
        }, [_vm._v("flatpickr")]), _vm._v(" with range date config.")]), _c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-6"
          }
        }, [_c('div', {
          staticClass: "card"
        }, [_c('kbd', {
          staticClass: "bg-dark"
        }, [_c('pre', {
          staticClass: "text-white",
          attrs: {
            "id": "basic-Range-datepicker"
          }
        }, [_c('code', [_vm._v("\n<template>\n  <flat-pickr class=\"form-control\" :config=\"basicRangeDateConfig\" placeholder=\"Select Date\"></flat-pickr>\n</template>\n<script>\nexport default {\n  name: 'DatepickerDemo',\ndata(){\n  return {\n        basicRangeDateConfig: {\n        mode: 'range'\n      }\n    }\n   }\n  }\n</script>\n")])])])])]), _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "value": "",
            "config": _vm.basicRangeDateConfig,
            "placeholder": "Select Date"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }