<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Goods Setup in Fleets</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="4" class="text-right">
                <b-form-input
                  type="text"
                  placeholder="Search fleets..."
                  v-model="fleetParams.search"
                  @input="fetchFleets"
                ></b-form-input>
              </b-col>
              <b-col md="8" class="text-right">
              </b-col>
            </b-row>
            <div v-if="fleetsLoading" class="text-center my-5">
              <b-spinner type="grow" label="Spinning"></b-spinner>
            </div>
            <template v-else>
              <b-row v-if="fleets.length > 0">
                <b-col
                  md="3"
                  class="text-center py-5 border-top"
                  v-for="(fleet, i) in fleets"
                  :key="`fleet-${i}`"
                >
                  <div class="custom-control custom-checkbox image-checkbox">
                    <input
                      :id="`ck-fl-${i}`"
                      type="checkbox"
                      class="custom-control-input"
                      :value="fleet"
                      :disabled="queryVehicleId"
                      v-model="checkedFleets"
                    />
                    <label class="custom-control-label" :for="`ck-fl-${i}`">
                      <img
                        :src="
                            fleet.vehicleType.icon.url
                          "
                        alt="#"
                        class="img-fluid"
                      />
                    </label>
                    <small
                    ><strong>{{ fleet.name }}</strong></small
                    >
                  </div>
                </b-col>
              </b-row>
              <div v-else class="text-center my-5">
                <p class="mb-0 text-muted">No fleets data found.</p>
              </div>
            </template>
            <b-row class="mt-2">
              <b-col md="6">
                <small>Total = 100 | Per Pages = 25 </small>
              </b-col>
              <b-col md="6">
                <b-pagination v-model="currentPage" :total-rows="rows" align="right"></b-pagination>
              </b-col>
              <b-col md="12" class="mt-3">
                <hr/>
                <b-row class="mt-2 mb-2">
                  <b-col md="4">
                    <b-form-group label="Goods" label-for="serial">
                      <v-select label="text" v-model="form.goods" :options="category" :reduce="(type) => type.value" placeholder="select category"></v-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group label="Maximum Limit">
                      <b-form-input id="fleet_name" class="w-100" v-model="form.maximumLimit" type="text" placeholder="Unit Name..."></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group label="Status" label-for="serial">
                      <b-form-checkbox name="check-button" v-model="form.status" switch inline>
                        <template v-if="form.status === true || form.status === 'true'">Yes</template>
                        <template v-else>No</template>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-button type="submit" variant="primary" class="mt-4">Add</b-button>
                  </b-col>
                </b-row>
                <hr/>
              </b-col>
              <b-col md="12" class="mt-3 mb-3">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th width="5%">No.</th>
                    <th>Goods</th>
                    <th>Current Stock</th>
                    <th>Minimum Limit Alert</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>1.</td>
                    <td>PQ3-01 || Piston</td>
                    <td>50</td>
                    <td>20</td>
                    <td>
                      <span><b-button type="submit" variant="warning">history</b-button></span>
                      <span><b-button type="submit" variant="primary"><i class="fa fa-edit"></i></b-button></span>
                      <span><b-button type="submit" variant="danger"><i class="fa fa-trash"></i></b-button></span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="12" class="mb-5">
                <b-pagination v-model="currentPage" :total-rows="rows" align="center"></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import { fleetsActions } from '@src/Utils/helper'

export default {
  name: 'ComponentGoodsSetup',
  data () {
    return {
      rows: 100,
      currentPage: 3,
      fleetsLoading: false,
      fleetParams: {
        page: 1,
        showAll: false,
        active: true,
        search: ''
      },
      fleets: [],
      checkedFleets: [],
      category: [
        { value: '-', text: 'Oil Machine' }
      ],
      form: {
        goods: '',
        maximumLimit: '',
        status: true
      }
    }
  },
  methods: {
    ...fleetsActions,
    async fetchFleets () {
      this.fleetsLoading = true
      this.fleets = []

      let params = this.$options.filters.cleanObject(this.fleetParams)
      let res = await this.getFleets(params)

      if (res.status) {
        this.fleetsLoading = false
        if (res.data && res.data.length > 0) {
          res.data.forEach(el => {
            el.componentSetup = []

            if ((typeof this.queryVehicleId !== 'undefined' && parseInt(el.id) === parseInt(this.queryVehicleId)) || (typeof this.queryVehicleId === 'undefined' || this.queryVehicleId === '' || this.queryVehicleId === null)) {
              this.fleets.push(el)
            }

          })
        }
      }
    }
  },
  computed: {
    'queryVehicleId' () {
      return this.$route.query.vehicleId
    }
  },
  async mounted () {
    await this.fetchFleets()
  },
}
</script>
